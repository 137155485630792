import React, { Component } from "react";
import classes from "./LoaderHidden.css";

class Loader extends Component {
  state = {
    intervalId: null,
    hidden: false
  };

  constructor() {
    super();
    this.timer = this.timer.bind(this);
  }
  componentDidMount() {
    var intervalId = setInterval(this.timer, 300);
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }
  timer() {
    this.setState({hidden:true})
  }
  render() {
    return (
      <div className={[classes.fondo, this.state.hidden ? "d-none" : null].join(" ")}>
      </div>
    );
  }
}
export default Loader;

import React, { useState, useEffect, memo } from 'react';
import * as actions from "../../../../stores/actions/index";
import { useSelector, useDispatch, } from "react-redux";
import { getLan } from "../../../../stores/utils/utilities";
import HeatMapWidget from '../../../../components/ui/tradingviewWidget/HeatMapWidget';
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import * as classes from "./MapaDeCalor.css"

function MapaDeCalor() {
  const dispatch = useDispatch();
  const diccionario = useSelector((state) => state.intl.diccionario);
  const [messages, setMessages] = useState();

  useEffect(() => {
    dispatch(actions.initIntl(44));
  }, []);

  useEffect(() => {
    setMessages(defineMessages(diccionario));
  }, [diccionario]);

  return (
    <IntlProvider locale={getLan()} messages={messages}>
      <React.Fragment>
        <div className="row">
          <div className="col sin_padding_L">
            <div>
              <h1 className={["texto_42", "nunito"].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.cotizacion.mapacalor.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
        </div>
        <div className={["margen_top_50"].join(" ")}>
          <div className={["margin_bottom_10"].join(" ")}>
            <span className="slash">/</span>
            <h2 className={classes.subtitle}>FTSE BIVA Index</h2><br/>
          </div>
          <HeatMapWidget dataSource="FTBIVA" locale={getLan()} hideTVFooter={true}/>
          <div className={["margin_bottom_10", "margin_top_50"].join(" ")}>
            <span className="slash">/</span>
            <h2 className={classes.subtitle}>FTSE4Good BIVA Index</h2><br/>
          </div>
          <HeatMapWidget dataSource="F4GBIVA" locale={getLan()} hideTVFooter={false}/>
        </div>
      </React.Fragment>
    </ IntlProvider>
  )
}

export default memo(MapaDeCalor);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../stores/actions/index";
import LayoutGuia from "../layoutGuiaToggle/LayoutGuia";

export default function EmisionesASG(props) {
  const dispatch = useDispatch();

  const [bonos, setBonos] = useState([]);

  const bonosRedux = useSelector(
    (state) => state.sustentable.clasificacion
  );

  useEffect(() => {
    dispatch(action.getClasificacionAsg());
  }, []);

  useEffect(() => {
    if (bonosRedux) {
      setBonos(bonosRedux);
    }
  }, [bonosRedux]);

  return (
    <>
      <LayoutGuia
        hiddenToggleTitle={true}
        titulo={"Clasificación y emisiones de bonos ASG"}
        mock={bonos}
        colores={["#1ca4c9", "#029791", "#029791", "#017830", "#4fad26"]}
        coloresBonos={["#51ad32", "#009ee2", "#176937", "#009aa8"]}
        coloresInternos={[
          "#009ee2",
          "#009ee2",
          "#009ee2",
          "#009ee2",
          "#009ee2",
          "#009ee2",
          "#009ee2",
        ]}
      />
    </>
  );
}

import React, { Component } from "react";
import classes from "./FilterListadoColocaciones.css";
import { connect } from "react-redux";

import { getLan} from "../../../stores/utils/utilities";
import { Form, Field } from "react-final-form";

import Auxs from "../../../hoc/auxs/Auxs";
import Input from "../input/Input";

import { debounce } from "throttle-debounce";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  options,
  isMultiple = null,
  maxCharacter = null,
  selectLabel,
}) => {
  const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";

  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    label: label,
    isMultiple: isMultiple,
    maxCharacter: maxCharacter,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel,
  };

  return (
    <div className="form-group">
    {label ? (
      <label
        for={input.name}
        className={["texto_14", "montse", "regular"].join(" ")}
      >
        {label}
      </label>
    ) : null}
    <Input config={params} />
  </div>
  );
};

class FilterListadoColocaciones extends Component {
  constructor(props) {
    super(props);
    this.formFilterAvisos = React.createRef();
  }

  state = {
    toggleFilter: false,
    selectedFilters: {
      clave_pizarra: {
        value: [],
        type:null
      },
      emisor: {
        value: [],
        type:null
      },
    },
    form_filters: {
      clave_pizarra: {
        value: ""
      },
      emisor: {
        value: ""
      },
    },
    itemsPerPage: 10
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    let [valuesClavePizarra, valuesEmisor] = this.onGetAllValuesFormFilter();
  
      const params = {
        size: this.state.itemsPerPage,
        page: 1,
        clavePizarra: valuesClavePizarra,
        emisor: valuesEmisor,
        reset: false
      };

      this.props.setParams(params);
      this.props.setPage(1);

      let wasFiltered = false;

      if (
        (valuesClavePizarra && valuesClavePizarra !== "") ||
        (valuesEmisor && valuesEmisor !== "")
      ) {
        wasFiltered = true;
      }

      this.props.setWasFiltered(wasFiltered);
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesClaveCotizacion =
      form.clave_pizarra.value && form.clave_pizarra.value !== "" ? form.clave_pizarra.value : "";
    // Se obtiene el valor de fecha inicio
    const valuesEmisor = form.emisor.value && form.emisor.value !== "" ? form.emisor.value : "";

    return [valuesClaveCotizacion, valuesEmisor];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 1:
        selectedFilters.clave_pizarra.value = form.clave_pizarra.value ? [form.clave_pizarra.value] : [];
        selectedFilters.clave_pizarra.type = type;
        break;
      case 2:
        selectedFilters.emisor.value = form.emisor.value ? [form.emisor.value] : [];
        selectedFilters.emisor.type = type;
        break;
      default:
        return null;
    }
    this.setState({ selectedFilters: selectedFilters});
  };

  onInputSelectedHandler = (event = null, type = null, item = null, eraser = null) => {
    let actualValue = eraser ? null : event;
    eraser = eraser || (actualValue && actualValue === "") ? true : false;

    let form = this.state.form_filters;
    
    if (eraser) {
      actualValue = null;
    } 
    if (type === 1) {
      form.clave_pizarra.value = actualValue;
    
    } else if (type === 2) {
      form.emisor.value = actualValue;
    }

    this.onGetNameStateFilterByInputHandler(type);
    this.setState({
      form_filters: form,
    });


    this.onFilterDataByEvent();
  };

  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [
      this.state.selectedFilters.clave_pizarra,
      this.state.selectedFilters.emisor,
    ];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          if (filter.value.length > 0) {
            
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
                {filter.value}
              </span>
              <span className={classes.filterIconButton} onClick={() => this.onInputSelectedHandler(null, filter.type, filter.value, true)}>
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        } else {
          return null;
        }

        })}
      </div>
    );
  };

  onSubmitFilter = () => {
    return null;
  };

  onResetFilter = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form.clave_pizarra.value = null;
    selectedFilters.clave_pizarra.value = [];
    form.emisor.value = null;
    selectedFilters.emisor.value = [];

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });


    const params = {
      size: this.state.itemsPerPage,
      page: 1,
      clavePizarra: null,
      emisor: null,
      reset: true
    };

    this.props.setParams(params);
  };

  onToggleFilterHandler = () => {
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  componentWillMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }


  printChange = (e, type) => {
    this.callReduxAction(e.target.value, type);
  };

  callReduxAction = (value, type) => {
    this.onInputSelectedHandler(value, type);
  };

  printFrameModelHandler = () => {
    const selectedFilters =
    this.state.selectedFilters.clave_pizarra.value.length > 0 || this.state.selectedFilters.emisor.value.length > 0
      ? this.onPrintSelectedFiltersHandler()
      : null;

    const closeLabel = getLan() === "es" ? "Cerrar Filtros" : "Close";
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";
    return (
      <Auxs>
        <div
          className={[
            "row",
            classes.firstSectionFilterContainer,
            !this.state.toggleFilter ? classes.noBorderRadius : null
          ].join(" ")}
        >
          <div className="col col-lg-3 text-center sin_padding_L">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button type="button" className="btn" onClick={this.onToggleFilterHandler}>
                <i className="fas fa-filter" />
                <span>{this.state.toggleFilter ? filterLabel : closeLabel}</span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={[
            "row",
            classes.secondSectionFilterContainer,
            "margin_bottom_50",
            this.state.toggleFilter ? classes.hidden : null
          ].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitFilter}
              initialValues={{
                clave_pizarra: this.state.form_filters.clave_pizarra.value,
                emisor: this.state.form_filters.emisor.value
              }}
              render={({ handleSubmit, form }) => (
                <form ref={this.formFilterAvisos} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 ">
                    <Field
                    name="clave_pizarra"
                    id="clave_pizarra"
                    type="text"
                    elementType="input"
                    classes="form-control"
                    maxCharacter={12}
                    label={getLan() === "es"
                    ? "Clave pizarra"
                    : "Ticker symbol"}
                    placeholder={
                      getLan() === "es"
                        ? "Buscar por clave pizarra"
                        : "Search by ticker symbol"
                    }
                    component={renderInput}
                    onInputChange={(event) => this.printChange(event, 1)}
                    classes={[classes.inputText].join(" ")}
                  />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 ">
                    <Field
                    name="emisor"
                    id="emisor"
                    type="text"
                    elementType="input"
                    classes="form-control"
                    maxCharacter={100}
                    label={getLan() === "es"
                    ? "Emisor"
                    : "Issuer"}
                    placeholder={
                      getLan() === "es"
                        ? "Buscar por nombre del emisor"
                        : "Search by issuer name"
                    }
                    component={renderInput}
                    onInputChange={(event) => this.printChange(event, 2)}
                    classes={[classes.inputText, "montse"].join(" ")}
                  />
                    </div>
                   
                  </div>
                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };
  

  sectionButtonsHandler = form => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilter()}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandler();
    return (
      <Auxs>
        <div className={["row", classes.margin].join(" ")}>
          <div className="col sin_padding_L">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  return {
    claves_cotizacion: state.avisosReportes.al_claves_cotizacion,
    tipo_avisos: state.avisosReportes.al_tipo_avisos
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterListadoColocaciones);

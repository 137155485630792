import { updateObject } from '../utils/utilities';
import * as actionType from '../actions/actionTypes';

const initalState = {
    showCarousel: false,  
    showButtonCarousel: false,
    actualPageName: null
}

const setGenericos = (state, action) => {
    const newPorperties = {...action.genericos}
    return updateObject(state, newPorperties);
}

const reducer = (state = initalState, action) => {
    switch(action.type){
        case actionType.SET_INIT_GENRICOS_SUCCESS: return setGenericos(state, action);
        default: return state;
    }
}

export default reducer;
import React, { Component } from "react";
import classes from "./BivaCloud.css";
import { connect } from "react-redux";

import Auxs from "../../../hoc/auxs/Auxs";

import * as action from "../../../stores/actions/index";

import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import SliderDocument from "../../../components/ui/sliderDocument/SliderDocument";
import Tabs from "../../../components/ui/tabs/Tabs";
import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";
import { getUrlDocument } from "../../../stores/utils/utilities";
import Forms from "../../../components/ui/modal/forms/Forms";

class BivaCloud extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    tab: 0,
    tabCuotas: 0,
    buttons: {
      showModalSupport: false,
      showModalSignUp: false,
    },
  };

  componentDidMount() {
    this.props.onInitIntl(81);
    this.props.onFolleto();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
  }

  onChangeItemSelected = (index = 0) => {
    let tab = 0;
    if (index !== this.state.tab) {
      tab = index;
      this.setState({
        tab: tab,
      });
    }
  };

  onPrintApisHandler = (apis) => {
    apis = apis.map(itm => {
      return {
        imagen: itm.urlImagen,
        texto_html: itm.descripcion,
        titulo_encabezado: itm.titulo
      }
    })

    return <ToggleBox terminos={apis} key={+new Date()} />;
  };

  onGetLogo = (logo) => {
    let img = (
      <i className={["far fa-file-image", classes.iconClass].join(" ")} />
    );
    if (logo.url && logo.url !== "") {
      let imgPath = null;
      let ruta = null;
      ruta = getUrlDocument(logo.url);

      if (ruta === null) {
        imgPath = require(`../../../assets/icons/${logo.url}`);
      } else {
        imgPath = ruta;
      }
      img = <img src={imgPath} alt="" className={[classes.logo].join(" ")} />;

      return (
        <div
          className={["relativo margin_top_30", classes.container_logo].join(
            " "
          )}
        >
          {img}
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col sin_padding_L">
            <div>
              <h1
                className={[classes.main_title, "texto_42", "nunito"].join(" ")}
              >
                <FormattedMessage
                  id=" "
                  defaultMessage={getLan() === "es" ? logo.title : logo.title}
                />
              </h1>
            </div>
          </div>
        </div>
      );
    }
  };

  onToggleModalHandler = (button) => {
    const buttons = this.state.buttons;
    buttons[button] = !buttons[button];
    this.setState((prevState) => {
      return {
        buttons: buttons,
      };
    });
  };
  onCloseModal() {
    return null;
  }

  render() {
    const logo = this.props.logo ? this.onGetLogo(this.props.logo[0]) : null;

    const form = (
      <div className={[classes.form_blue].join(" ")}>
        <Forms
          modelo="modelo_1"
          tipo_mensaje={1}
          onCloseModal={(e) => this.onCloseModal()}
          area={"operations"}
          optionsSupport={true}
        />
      </div>
    );

    let video = null;

    if (this.state.messages) {
      video = (
        <div className={classes.iframeContainer}>
          <iframe
            className={classes.iframe}
            src={
              "https://www.youtube.com/embed/" +
              this.state.messages["bivacloud.youtube.videoId"]
            }
            frameborder="0"
            title="videos"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      );
    }
    let tabs = null;
    let cuotaApiSelected = null;
    if (this.props.cuotasApis && this.props.cuotasApis.length > 0) {
      let contentTabs = this.props.cuotasApis.map((tab, i) => {
        return (
          <li className="nav-item">
            <div
              className={[
                "nav-link noselect", classes.tab,
                this.state.tabCuotas === i ? "active" : null,
              ].join(" ")}
              aria-current="page"
              onClick={(e) => this.setState({ tabCuotas: i })}
            >
              <span className={"montse texto_16"}>{tab.nombre}</span>
            </div>
          </li>
        );
      });

      cuotaApiSelected = this.props.cuotasApis[this.state.tabCuotas];

      tabs = (
        <div>
          <ul className="nav nav-tabs">{contentTabs}</ul>
        </div>
      );
    }

    let apisToShow = null;
    let apis = cuotaApiSelected ? cuotaApiSelected.apis : [];

    if (apis.length > 0) {
      apisToShow = this.onPrintApisHandler(apis);
    } else {
      apisToShow = (
        <p className="montse texto_16 regular">
          {getLan() === "es"
            ? "No existen resultados para la búsqueda."
            : "No results found."}
        </p>
      );
    }


    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <Auxs>
          {video}

          {/* LOGO */}
          {logo}

          {/* TABS CIRCLE */}
          <div className={["margin_top_50", classes.tabsContainer].join(" ")}>
            <Tabs
              key={+new Date()}
              data={this.props.acercade ? this.props.acercade : []}
              activaTabInicial={this.state.tab}
              tabsDesabilitados={this.state.tab}
              tipo={4}
              clicked={(i) => this.onChangeItemSelected(i)}
            />
          </div>

          {/* ACERCA DE */}
          <div
            className={[
              classes.text_container,
              "row margin_top_50 sin_padding",
            ].join(" ")}
            id="acercade"
          >
            <div
              className={[
                "col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 texto_centro bold nunito texto_30 ",
              ].join(" ")}
            >
              <h2>
                <span>
                  {this.props.acercade
                    ? this.props.acercade[this.state.tab].titulo_encabezado
                    : null}
                </span>
              </h2>
            </div>
            <div
              className={[
                "col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justificado bold nunito sin_padding",
              ].join(" ")}
            >
              <span
                className={[
                  "texto_24_35 justificado montse regular texto_viñeta",
                ].join(" ")}
                dangerouslySetInnerHTML={{
                  __html:
                    this.props.acercade !== null
                      ? this.props.acercade[this.state.tab].texto_html
                      : null,
                }}
              />
            </div>
          </div>

          {/* CUOTAS Y CONTRATACIÓN DE SERVICIOS API’S */}
          <div
            className={[classes.tabs_container, "row margin_top_50"].join(" ")}
            id="cuotas"
          >
            {tabs}
          </div>
          <div
            className={[classes.text_container_blue, "row margin_top_10"].join(
              " "
            )}
          >
            <div
              className={[
                "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  sin_padding texto_centro bold nunito texto_40 texto_blanco",
              ].join(" ")}
            >
              <h2>
                <FormattedMessage
                  id="bivacloud.titulo.cuotas"
                  defaultMessage=" "
                />
              </h2>
            </div>
          </div>
          <div>
            <div className={[""].join(" ")}>
              <SliderDocument
                key={+new Date()}
                datos={cuotaApiSelected ? cuotaApiSelected.cuotas : null}
                tipo={5}
                indice={1}
                banner={
                  this.state.messages
                    ? this.state.messages["bivacloud.cuotas.subtitulo"]
                    : null
                }
              />
            </div>
          </div>

          {/* PRODUCTOS API'S*/}
          <div className={"row margin_top_100"} id="productos">
            <div
              className={[
                "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sin_padding texto_centro bold nunito texto_40 texto_azul",
              ].join(" ")}
            >
              <h2>
                <FormattedMessage
                  id="bivacloud.titulo.productos"
                  defaultMessage=" "
                />
              </h2>
            </div>
          </div>
          <div className="margin_top_30">{apisToShow}</div>

          {/* Registro y soporte*/}
          <div
            className={[classes.text_container_blue, "row margin_top_50"].join(
              " "
            )}
            id="support_signup"
          >
            <div
              className={[
                "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  sin_padding texto_centro bold nunito texto_40 texto_blanco",
              ].join(" ")}
            >
              <h2>
                <FormattedMessage
                  id="bivacloud.titulo.soporte"
                  defaultMessage=" "
                />
              </h2>
            </div>
          </div>

          {form}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    onFolleto: () => {
      dispatch(action.getTabsCuotasApi());
      dispatch(action.getAcercade());
      dispatch(action.getCuotas());
      dispatch(action.getApis());
      dispatch(action.getLogo());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    diccionario: state.intl.diccionario,
    acercade: state.bivacloud.acercade,
    cuotas: state.bivacloud.cuotas,
    cuotasApis: state.bivacloud.cuotasApis,
    apis: state.bivacloud.apis,
    logo: state.bivacloud.logo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BivaCloud);

import React, { Component } from "react";
import classes from "./Autorregulacion.css";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";

import Auxs from "../../../hoc/auxs/Auxs";
import Box from "../../../components/ui/box/Box";
import { getLocal } from "../../../stores/utils/utilities";

class Autorregulacion extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    autorregulacion: {
      links: [
        {
          creationDateInMillis: 1569962013620,
          id: 35999,
          title: "Reglamento interior",
          subtitle: null,
          url: "/documents/20123/4b3f02e6-47b2-15c2-4433-6bb97c2bcbb1"
        },
        {
          creationDateInMillis: 1568123733752,
          id: 36004,
          title: "Manuales de reglas operativas",
          subtitle: null,
          url: "/documents/20123/b766ceed-78f2-7203-11c4-d1fa8bb3c688"
        },
        {
          creationDateInMillis: 1570130890693,
          id: 36009,
          title: "Código de ética y conducta",
          subtitle: null,
          url: "/documents/20123/878a6e31-a2f8-8157-5a23-a4850bd53e9d"
        }
      ]
    }
  };

  componentDidMount() {
    this.props.onInitIntl(1);
    this.props.onDocumentos();
    // lan es el lenguage local el cual depende los formatos currency y fechas
    this.setState({
      lan: getLocal()
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let autorregulacion = null;
    if (this.props.autorregulaciones) {
      autorregulacion = this.props.autorregulaciones.map((autorregulacion, i) => {
        return <Box key={"autorregulacion_" + i} modelo="modelo_1" data={autorregulacion}   />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
                <FormattedMessage id="regulacion.autorregulacion.titulo"  defaultMessage=" "/>
              </h1>
            </div>
            <div className="row margen_top_30">
             {autorregulacion}
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    autorregulaciones: state.documentos.autorregulacion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    },
    onDocumentos: () => {
      dispatch(actions.initAutorregulacionDocumentos());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Autorregulacion);

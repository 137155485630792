import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../stores/actions/index";
import classes from "./Acuerdos.css";

const AcuerdosFirmas = () => {
  const dispatch = useDispatch();

  const [acuerdos, setAcuerdos] = useState([]);

  const acuerdosRedux = useSelector(
    (state) => state.bivaSustentable.acuerdosFirmas
  );

  useEffect(() => {
    dispatch(actions.getAcuerdosFirmas());
  }, []);

  useEffect(() => {
    if (acuerdosRedux) {
      setAcuerdos(acuerdosRedux);
    }
  }, [acuerdosRedux]);

  const createDivs = (divs) => {
    let i = 0;
    return divs.map((itm, indx) => {
      if (i > 4) {
        i = 0;
      }
      return (
        <div
          className={["col-12 sin_padding montse texto_16 "].join(" ")}
          dangerouslySetInnerHTML={{ __html: itm.texto_html }}
        />
      );
    });
  };

  return <div className={classes.margin}><div className={classes.imgBackground}>
    
    {createDivs(acuerdos)}
    
    </div></div>;
};

export default AcuerdosFirmas;

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  emisoras: null,
  valores: null,
  claves: null,
  emisorasSortNombre: null,
  emisorasPreliminar: null,
  emisorasNombrePreliminar: null,
};

const setTramites = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMISORASTRAMITES_SUCCESS:
      return setTramites(state, action);
    case actionTypes.FETCH_VALORESTRAMITES_SUCCESS:
      return setTramites(state, action);
    case actionTypes.FETCH_EMISORASCLAVESTRAMITES_SUCCESS:
      return setTramites(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./GobiernoCorporativo.css";
import { connect } from "react-redux";

import Auxs from "../../../hoc/auxs/Auxs";
import List from "../../../components/ui/list/List";
import * as action from "../../../stores/actions/index";

import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class GobiernoCorporativo extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    comiteConsejo: null,
    comiteAdmision: null,
    comiteListado: null,
    comiteVigilancia: null,
    comiteNormativo: null,
    comiteSanciones: null,
    comiteAuditoria: null
  };
  componentDidMount() {
    this.props.onInitIntl(12);
    this.props.onComites();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }

    if (this.props.comiteConsejo !== prevProps.comiteConsejo) {
      this.setState({
        comiteConsejo: this.props.comiteConsejo
      });
    }
    if (this.props.comiteAdmision !== prevProps.comiteAdmision) {
      this.setState({
        comiteAdmision: this.props.comiteAdmision
      });
    }
    if (this.props.comiteListado !== prevProps.comiteListado) {
      this.setState({
        comiteListado: this.props.comiteListado
      });
    }
    if (this.props.comiteVigilancia !== prevProps.comiteVigilancia) {
      this.setState({
        comiteVigilancia: this.props.comiteVigilancia
      });
    }
    if (this.props.comiteNormativo !== prevProps.comiteNormativo) {
      this.setState({
        comiteNormativo: this.props.comiteNormativo
      });
    }
    if (this.props.comiteSanciones !== prevProps.comiteSanciones) {
      this.setState({
        comiteSanciones: this.props.comiteSanciones
      });
    }
    if (this.props.comiteAuditoria !== prevProps.comiteAuditoria) {
      this.setState({
        comiteAuditoria: this.props.comiteAuditoria
      });
    }
  }
  render() {
    // Consejo
    let listConsejo = null;

    // Comites
    let admision = null;
    let emisoras = null;
    let auditoria = null;
    let mercadeo = null;
    let normativa = null;
    let sanciones = null;

    if (this.state.comiteConsejo) {
      listConsejo = <List data={this.state.comiteConsejo} />;
    }
    if (this.state.comiteAdmision) {
      admision = <List data={this.state.comiteAdmision} />;
    }
    if (this.state.comiteListado) {
      emisoras = <List data={this.state.comiteListado} />;
    }
    if (this.state.comiteAuditoria) {
      auditoria = <List data={this.state.comiteAuditoria} />;
    }
    if (this.state.comiteVigilancia) {
      mercadeo = <List data={this.state.comiteVigilancia} />;
    }
    if (this.state.comiteNormativo) {
      normativa = <List data={this.state.comiteNormativo} />;
    }
    if (this.state.comiteSanciones) {
      sanciones = <List data={this.state.comiteSanciones} />;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1 className={[classes.main_title, "texto_42", "nunito"].join(" ")}>
                  <FormattedMessage id="nosotros.gobiernocorporativo.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.text_container, "text_30", "montse"].join(" ")}>
                <span className="montse texto_16 regular">
                  <FormattedMessage id="nosotros.gobiernocorporativo.introduccion1" defaultMessage=" " />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.consejo_container, classes.margin].join(" ")}>
                <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo2" defaultMessage=" " />
                  </h2>
                </div>
                <div className={classes.list_consejo}>{listConsejo}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.comite_container, classes.margin].join(" ")}>
                <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo3" defaultMessage=" " />
                  </h2>
                </div>
                <div className="row">
                  <div className="col sin_padding_L">
                    <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                      <span className={"slash"}>/</span>
                      <h2>
                        <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo.admision" defaultMessage=" " />
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col sin_padding_L">
                    <div className={[classes.text_container].join(" ")}>
                      <span className="montse texto_16 regular">
                        <FormattedMessage id="nosotros.gobiernocorporativo.introduccion2" defaultMessage=" " />
                        {/* El objeto del comité de admisión de miembros es evaluar y, en su caso, aprobar la admisión de cualquier
                      casa de bolsa que pretenda operar a través del sistema de BIVA, en términos de lo previsto por las
                      disposiciones legales aplicables. Los miembros de dicho comité son designados por el consejo de
                      administración de BIVA, actualmente se encuentra integrado de la siguiente forma: */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="margin_top_15">
                  <div className={classes.list_comite}>{admision}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.comite_container, classes.margin].join(" ")}>
                <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo4" defaultMessage=" " />
                  </h2>
                </div>
                <div className="row">
                  <div className="col sin_padding_L">
                    <div className={[classes.text_container].join(" ")}>
                      <span className="montse texto_16 regular">
                        <FormattedMessage id="nosotros.gobiernocorporativo.introduccion3" defaultMessage=" " />
                        {/* El objeto de del comité de listado de emisoras es evaluar y, en su caso, aprobar la admisión y la
                      inscripción de valores de emisoras en el listado de valores, en términos de lo previsto por las
                      disposiciones legales aplicables. Los miembros de dicho comité son designados por el consejo de
                      administración de BIVA, actualmente se encuentra integrado de la siguiente forma: */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="margin_top_15">
                  <div className={classes.list_comite}>{emisoras}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.comite_container, classes.margin].join(" ")}>
                <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo5" defaultMessage=" " />
                  </h2>
                </div>
                <div className="row">
                  <div className="col sin_padding_L">
                    <div className={[classes.text_container].join(" ")}>
                      <span className="montse texto_16 regular">
                        <FormattedMessage id="nosotros.gobiernocorporativo.introduccion4" defaultMessage=" " />
                        {/* El Comité de Auditoría desempeña las funciones en materia de auditoría que se establecen en las
                      disposiciones aplicables, dentro de las cuales se encuentra: (i) apoyar al consejo de administración, en
                      la definición y actualización de los objetivos, políticas y lineamientos del sistema de control interno,
                      así como en la evaluación y verificación de este último; (ii) coordinar las actividades tendientes a la
                      correcta evaluación de riesgos de BIVA; (iii) dar seguimiento a las actividades de auditoría interna y
                      externa de BIVA; (iii) evaluar el desempeño del auditor externo de BIVA; (iv) discutir los estados
                      financieros de BIVA; (v) requerir a los directivos relevantes y demás empleados de BIVA, reportes
                      relativos a la elaboración de la información financiera y de cualquier otro tipo que estime necesaria para
                      el ejercicio de sus funciones; (vi) informar al consejo de administración sobre cualquier irregularidad
                      detectada con motivo del ejercicio de sus funciones y, en su caso, de las acciones correctivas adoptadas o
                      proponer las que deban aplicarse; y (vii) vigilar que el director general dé cumplimiento a los acuerdos
                      de las asambleas de accionistas y del consejo de administración de la Sociedad. Los miembros de dicho
                      comité son designados por el consejo de administración de BIVA, actualmente se encuentra integrado de la
                      siguiente forma: */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="margin_top_15">
                  <div className={classes.list_comite}>{auditoria}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.comite_container, classes.margin].join(" ")}>
                <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo6" defaultMessage=" " />
                  </h2>
                </div>
                <div className="row">
                  <div className="col sin_padding_L">
                    <div className={[classes.text_container].join(" ")}>
                      <span className="montse texto_16 regular">
                        <FormattedMessage id="nosotros.gobiernocorporativo.introduccion5" defaultMessage=" " />
                        {/* El Comité de Vigilancia de mercados es responsable de implementar los mecanismos necesarios para procurar
                      la integridad del mercado de valores, para lo cual contará con el auxilio del área de vigilancia de
                      mercados de BIVA, para llevar a cabo las labores de revisión permanente de las conductas u operaciones que
                      se realicen en BIVA conforme a sus funciones. Los miembros de dicho comité son designados por el consejo
                      de administración de BIVA, actualmente se encuentra integrado de la siguiente forma: */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="margin_top_15">
                  <div className={classes.list_comite}>{mercadeo}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.comite_container, classes.margin].join(" ")}>
                <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo7" defaultMessage=" " />
                  </h2>
                </div>
                <div className="row">
                  <div className="col sin_padding_L">
                    <div className={[classes.text_container].join(" ")}>
                      <span className="montse texto_16 regular">
                        <FormattedMessage id="nosotros.gobiernocorporativo.introduccion6" defaultMessage=" " />
                        {/* El Comité Normativo realiza las funciones normativas que establecen las disposiciones legales aplicables,
                      y su objeto es establecer y mantener actualizado un marco normativo de carácter autoregulatorio de BIVA.
                      Los miembros de dicho comité son designados por el consejo de administración de BIVA, actualmente se
                      encuentra integrado de la siguiente forma: */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="margin_top_15">
                  <div className={classes.list_comite}>{normativa}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.comite_container, classes.margin].join(" ")}>
                <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage id="nosotros.gobiernocorporativo.subtitulo8" defaultMessage=" " />
                  </h2>
                </div>
                <div className="row">
                  <div className="col sin_padding_L">
                    <div className={[classes.text_container].join(" ")}>
                      <span className="montse texto_16 regular">
                        <FormattedMessage id="nosotros.gobiernocorporativo.introduccion7" defaultMessage=" " />
                        {/* El Comité de Sanciones tiene como objeto conocer y resolver sobre los casos de presuntas violaciones a las
                      normas autoregulatorias de BIVA, a las disposiciones del Reglamento Interior y demás ordenamientos de
                      BIVA, cometidos por las personas obligadas conforme a tales disposiciones, así como e imponer las
                      sanciones correspondientes en caso de resultar procedente. */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="margin_top_15">
                  <div className={classes.list_comite}>{sanciones}</div>
                </div>
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onComites: () => {
      dispatch(action.initBoxConsejo());
      dispatch(action.initBoxAdmision());
      dispatch(action.initBoxListado());
      dispatch(action.initBoxAuditoria());
      dispatch(action.initBoxVigilancia());
      dispatch(action.initBoxNormativo());
      dispatch(action.initBoxSanciones());
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    comiteConsejo: state.cuadriculas.consejo,
    comiteAdmision: state.cuadriculas.comiteAdmision,
    comiteListado: state.cuadriculas.comiteListado,
    comiteVigilancia: state.cuadriculas.comiteVigilancia,
    comiteNormativo: state.cuadriculas.comiteNormativo,
    comiteSanciones: state.cuadriculas.comiteSanciones,
    comiteAuditoria: state.cuadriculas.comiteAuditoria
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GobiernoCorporativo);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  sectores: null,
  sub_sectores: null
};

const setSector = (state, action) => {
  return updateObject(state, action);
};

const setSubSector = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SECTOR_SUCCESS:
      return setSector(state, action);
    case actionTypes.FETCH_SUBSECTOR_SUCCESS:
      return setSubSector(state, action);
    default:
      return state;
  }
};

export default reducer;

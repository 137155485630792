import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getLan } from "../utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setAccordionSeguimientoInversiones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      seguimientoInversiones: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    seguimientoInversiones: data
  };
};

export const getAccordionSeguimientoInversiones = () => {
  const url = `${dominio}/cms/o/documents/aspectos-a-considerar/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionSeguimientoInversiones(response.data));
      })
      .catch(err => dispatch(setAccordionSeguimientoInversiones(err.data)));
  };
};
// =========================================================================

const setAccordionMembresia = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      membresia: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    membresia: data
  };
};

export const getAccordionMembresia = () => {
  const url = `${dominio}/cms/o/documents/cb-membresia/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionMembresia(response.data));
      })
      .catch(err => dispatch(setAccordionMembresia(err.data)));
  };
};
// =========================================================================

const setAccordionTiposPostura = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      tiposPostura: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    tiposPostura: data
  };
};

export const getAccordionTiposPostura = () => {
  const url = `${dominio}/cms/o/documents/desc-tipo-postura/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionTiposPostura(response.data));
      })
      .catch(err => dispatch(setAccordionTiposPostura(err.data)));
  };
};
// =========================================================================

const setAccordionOpelB = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      descOpelB: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    descOpelB: data
  };
};

export const getAccordionDescOpelB = () => {
  const url = `${dominio}/cms/o/documents/desc-opel-b/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionOpelB(response.data));
      })
      .catch(err => dispatch(setAccordionOpelB(err.data)));
  };
};
// =========================================================================

const setAccordionInfoCuotaOperacion = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      infoCuotaOperacion: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    infoCuotaOperacion: data
  };
};

export const getAccordionInfoCuotaOperacion = () => {
  const url = `${dominio}/cms/o/documents/info-cuotas-operacion/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionInfoCuotaOperacion(response.data));
      })
      .catch(err => dispatch(setAccordionInfoCuotaOperacion(err.data)));
  };
};
// =========================================================================

const setAccordionMarketPhases = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      marketPhases: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    marketPhases: data
  };
};

export const getAccordionMarketPhases = () => {
  const url = `${dominio}/cms/o/documents/desc-fases-mercado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionMarketPhases(response.data));
      })
      .catch(err => dispatch(setAccordionMarketPhases(err.data)));
  };
};
// =========================================================================

const setAccordionCalendario = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      calendario: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    calendario: data
  };
};

export const getAccordionCalendario = () => {
  const url = `${dominio}/cms/o/documents/calendario-operacion/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionCalendario(response.data));
      })
      .catch(err => dispatch(setAccordionCalendario(err.data)));
  };
};

export const getAccordionCalendarioEntregas = () => {
  const url = `${dominio}/cms/o/documents/emisoras-calendario-entrega/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionCalendario(response.data));
      })
      .catch(err => dispatch(setAccordionCalendario(err.data)));
  };
};

// =========================================================================

const setAccordionHorarioOpelB = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      horarioOpelB: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    horarioOpelB: data
  };
};

export const getAccordionHorarioOpelB = () => {
  const url = `${dominio}/cms/o/documents/horario-opel-b/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionHorarioOpelB(response.data));
      })
      .catch(err => dispatch(setAccordionHorarioOpelB(err.data)));
  };
};
// =========================================================================

const setAccordionHorarioOpelC = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      horarioOpelC: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    horarioOpelC: data
  };
};

export const getAccordionHorarioOpelC = () => {
  const url = `${dominio}/cms/o/documents/horario-opel-c/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionHorarioOpelC(response.data));
      })
      .catch(err => dispatch(setAccordionHorarioOpelC(err.data)));
  };
};

// =========================================================================

const setAccordionHorarioOpelE = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      horarioOpelE: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    horarioOpelE: data
  };
};

export const getAccordionHorarioOpelE = () => {
  const url = `${dominio}/cms/o/documents/horario-opel-e/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionHorarioOpelE(response.data));
      })
      .catch(err => dispatch(setAccordionHorarioOpelE(err.data)));
  };
};
// =========================================================================

const setAccordionHorarioOpelD = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      horarioOpelD: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    horarioOpelD: data
  };
};

export const getAccordionHorarioOpelD = () => {
  const url = `${dominio}/cms/o/documents/horario-opel-d/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionHorarioOpelD(response.data));
      })
      .catch(err => dispatch(setAccordionHorarioOpelD(err.data)));
  };
};
// =========================================================================

const setAccordionHorarioOpelW = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      horarioOpelW: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    horarioOpelW: data
  };
};

export const getAccordionHorarioOpelW = () => {
  const url = `${dominio}/cms/o/documents/horario-opel-w/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionHorarioOpelW(response.data));
      })
      .catch(err => dispatch(setAccordionHorarioOpelW(err.data)));
  };
};
// =========================================================================

const setAccordionTOpel = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      tOpel: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    tOpel: data
  };
};

export const getAccordionTOpel = () => {
  const url = `${dominio}/cms/o/documents/desc-t-opel/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionTOpel(response.data));
      })
      .catch(err => dispatch(setAccordionTOpel(err.data)));
  };
};
// =========================================================================

const setAccordionFix = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      fix: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    fix: data
  };
};

export const getAccordionFix = () => {
  const url = `${dominio}/cms/o/documents/desc-fix/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionFix(response.data));
      })
      .catch(err => dispatch(setAccordionFix(err.data)));
  };
};
// =========================================================================

const setAccordionOuch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      ouch: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    ouch: data
  };
};

export const getAccordionOuch = () => {
  const url = `${dominio}/cms/o/documents/desc-ouch/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionOuch(response.data));
      })
      .catch(err => dispatch(setAccordionOuch(err.data)));
  };
};

// =========================================================================

const setAccordionOMS = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      oms: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    oms: data
  };
};

export const getAccordionOms = () => {
  const url = `${dominio}/cms/o/documents/proveedores-oms/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionOMS(response.data));
      })
      .catch(err => dispatch(setAccordionOMS(err.data)));
  };
};
// =========================================================================

const setAccordionISVS = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      isvs: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    isvs: data
  };
};

export const getAccordionIsvs = () => {
  const url = `${dominio}/cms/o/documents/proveedores-independientes/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionISVS(response.data));
      })
      .catch(err => dispatch(setAccordionISVS(err.data)));
  };
};
// =========================================================================

const setAccordionCuotasITCH = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      cuotasItch: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    cuotasItch: data
  };
};

export const getAccordionCuotasITCH = () => {
  const url = `${dominio}/cms/o/documents/cuotas-itch/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionCuotasITCH(response.data));
      })
      .catch(err => dispatch(setAccordionCuotasITCH(err.data)));
  };
};
// =========================================================================

const setAccordionTarifasBim = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACCORDION_SUCCESS,
      tarifasBim: []
    };
  }
  return {
    type: actionTypes.FETCH_ACCORDION_SUCCESS,
    tarifasBim: data
  };
};

export const getAccordionTarifasBim = () => {
  const url = `${dominio}/cms/o/documents/tarifas-bim/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAccordionTarifasBim(response.data));
      })
      .catch(err => dispatch(setAccordionTarifasBim(err.data)));
  };
};

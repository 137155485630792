import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import classes from "./Forms.css";
import { Form, Field } from "react-final-form";

import Input from "../../input/Input";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getKeyCaptcha,
  getLan,
  getUrlDocument,
} from "../../../../stores/utils/utilities";
let alerta = null;

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  options,
  isMultiple = null,
  selectLabel,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : "Seleccione una opción",
  };
  return (
    <div className="form-group">
      <Input config={params} />
    </div>
  );
};

let inicioPeticion = false;
let limpiaForms = false;

class Forms extends Component {
  state = {
    avisoLegal: null,
    privacidad: null,
    se_envia: false,
    tieneValorRecaptcha: false,
    tipo_mensaje: 1,
    errorMail: null,
    enviado: null,
    forms: {
      first_name: {
        value: "",
        disabled: false,
      },
      last_name: {
        value: "",
        disabled: false,
      },
      phone: {
        value: "",
        disabled: false,
      },
      email: {
        value: "",
        disabled: false,
      },
      ramos: {
        value: "",
        disabled: false,
      },
      message: {
        value: "",
        disabled: false,
      },
      company: {
        value: "",
        disabled: false,
      },
      subject: {
        value: "",
        disabled: false,
      },
    },
  };

  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.formRef2 = React.createRef();
    this.formRef3 = React.createRef();
    this.formRef4 = React.createRef();
    this.formRef5 = React.createRef();
    this.recaptchaRef = React.createRef();
  }

  onSubmitHandler = (event) => {
    event.preventDefault();
  };

  componentDidMount() {
    this.limpiaCampos();
    if (this.props.tipo_mensaje) {
      let tipo = 1;
      if (this.props.tipo_mensaje === 2) {
        tipo = 2;
      }
      this.setState({
        tipo_mensaje: tipo,
      });
    }
  }

  componentWillUnmount() {
    this.limpiaCampos();
  }

  componentWillReceiveProps(nextProps) {
    inicioPeticion = false;

    let enviado = null;
    let errorMail = null;
    if (nextProps.errorMail) {
      enviado = null;
      errorMail = nextProps.errorMail;
      this.setState({
        errorMail: errorMail,
        enviado: enviado,
        se_envia: false,
      });
    }
    if (nextProps.enviado && nextProps.errorMail === null) {
      errorMail = null;
      this.props.onCloseModal();
      this.limpiaCampos();
      limpiaForms = false;
      this.setState({
        errorMail: errorMail,
        se_envia: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.avisoLegal !== this.state.avisoLegal) {
      this.setState({
        avisoLegal: this.props.avisoLegal,
      });
    }
    if (this.props.privacidad !== this.state.privacidad) {
      this.setState({
        privacidad: this.props.privacidad,
      });
    }
    if (this.props.limpiaForms !== prevProps.limpiaForms) {
      if (this.props.limpiaForms.toString().includes("true")) {
        this.setState({
          enviado: getLan() === "es" ? "Mensaje enviado" : "Message sent",
        });
        this.limpiaCampos();
      }
      limpiaForms = false;
    }
  }

  limpiaCampos() {
    const form = this.state.forms;
    form.first_name.value = "";
    form.last_name.value = "";
    form.phone.value = "";
    form.email.value = "";
    form.company.value = "";
    form.message.value = "";
    this.recaptchaRef.current.reset();

    this.setState({
      forms: form,
      errorMail: null,
      tieneValorRecaptcha: false,
    });
  }

  sendEmail = (event) => {
    event.preventDefault();
    let recaptchaValue = null;

    if (this.recaptchaRef.current !== null) {
      recaptchaValue = this.recaptchaRef.current.getValue();
    }

    let se_envia = true;
    if (recaptchaValue === null || recaptchaValue === "") {
      se_envia = false;
    }

    this.setState({
      se_envia: se_envia,
    });
    let message = event.target.elements.message.value;
    if (this.props.optionsSupport) {
      message = "\n"+event.target.elements.subject.value + ": \n" + message;
    }

    let params = {
      name: event.target.elements.first_name.value,
      lastName: event.target.elements.last_name.value,
      phoneNumber: event.target.elements.phone.value,
      emailAddress: event.target.elements.email.value,
      message: message,
      companyName: event.target.elements.company.value,
    };
    if (this.props.area) {
      this.props.onInitSendEmailArea(params, this.props.area);
    } else {
      this.props.onInitSendEmailAnonimo(params);
    }
  };

  onFormHandler = (modelo) => {
    switch (modelo) {
      case "modelo_1":
        return this.firstModelForm();
      default:
        return this.firstModelForm();
    }
  };

  onChangeHandler = (event, type = null) => {
    let value = null;
    const form = this.state.forms;

    if (type !== 0) {
      value = event.target.value;
    }
    if (type === 1) {
      form.first_name.value = value;
    }
    if (type === 2) {
      form.last_name.value = value;
    }
    if (type === 3) {
      form.phone.value = value;
    }
    if (type === 4) {
      form.email.value = value;
    }
    if (type === 5) {
      form.company.value = value;
    }
    if (type === 6) {
      form.message.value = value;
    }
    if (type === 7) {
      //Subject
      form.subject.value = value;
    }

    this.setState({
      forms: form,
      enviado: null,
    });
  };

  onChangeRecaptcha(value) {
    let recaptchaValue = null;
    let tieneValorRecaptcha = false;
    if (this.recaptchaRef.current !== null) {
      recaptchaValue = this.recaptchaRef.current.getValue();
    }

    if (recaptchaValue !== null && recaptchaValue !== "") {
      tieneValorRecaptcha = true;
    }

    this.setState({
      tieneValorRecaptcha: tieneValorRecaptcha,
    });
  }

  getSelectSupport() {
    let options = [
      { nombre: getLan() === "es" ? "Registro" : "Sign Up", id: getLan() === "es" ? "REGISTRO" : "SIGN UP" },
      { nombre: getLan() === "es" ? "Soporte" : "support", id: getLan() === "es" ? "SOPORTE" : "SUPPORT"}
    ];

    const support = (
      <div className={["form-group", classes.input].join(" ")}>
        <Field
          name="subject"
          id="subject"
          selectLabel={
            getLan() === "es" ? "Selecciona asunto" : "Select a subject"
          }
          label={getLan() === "es" ? "Asunto" : "Subject"}
          elementType="select"
          isMultiple={false}
          classes={"custom-select montse texto_14 regular color_gris"}
          options={options}
          onInputChange={(event) => this.onChangeHandler(event, 7)}
          component={renderInput}
        />
      </div>
    );
    return support;
  }

  firstModelForm = () => {
    let rutaPrivacidad = null;
    if (this.state.privacidad !== null) {
      rutaPrivacidad = getUrlDocument(this.state.privacidad[0].url);
    }
    let rutaAvisoLegal = null;
    if (this.state.avisoLegal !== null) {
      rutaAvisoLegal = getUrlDocument(this.state.avisoLegal[0].url);
    }

    return (
      <Form
        onSubmit={this.sendEmail}
        initialValues={{
          first_name: this.state.forms.first_name.value,
          last_name: this.state.forms.last_name.value,
          phone: this.state.forms.phone.value,
          email: this.state.forms.email.value,
          message: this.state.forms.message.value,
          company: this.state.forms.company.value,
          subject: this.state.forms.subject.value
        }}
        render={(form) => (
          <form
            onSubmit={this.sendEmail}
            id="formEmail"
            ref={this.formRef1}
            className="width_cien"
          >
            <div className="row">
              {alerta}
              <div className="col-12 col-lg-6">
                <div className={["form-group", classes.input].join(" ")}>
                  <Field
                    id="first_name"
                    name="first_name"
                    classes={"montse texto_14_form regular"}
                    type="text"
                    elementType="input"
                    placeholder={getLan() === "es" ? "Nombre" : "Name"}
                    component={renderInput}
                    onInputChange={(event) => this.onChangeHandler(event, 1)}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className={["form-group", classes.input].join(" ")}>
                  <Field
                    id="last_name"
                    name="last_name"
                    classes={"montse texto_14_form regular"}
                    type="text"
                    elementType="input"
                    placeholder={getLan() === "es" ? "Apellido" : "Last name"}
                    component={renderInput}
                    onInputChange={(event) => this.onChangeHandler(event, 2)}
                  />
                </div>
              </div>
            </div>
            <div className={["row"].join(" ")}>
              <div
                className={[
                  "col-12 col-lg-6",
                  this.props.area === "boletin-informativo" ? "d-none" : null,
                ].join(" ")}
              >
                <div className={["form-group", classes.input].join(" ")}>
                  <Field
                    id="phone"
                    name="phone"
                    type="text"
                    classes={"montse texto_14_form regular"}
                    elementType="input"
                    placeholder={getLan() === "es" ? "Teléfono" : "Phone"}
                    component={renderInput}
                    onInputChange={(event) => this.onChangeHandler(event, 3)}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className={["form-group", classes.input].join(" ")}>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    classes={"montse texto_14_form regular"}
                    elementType="input"
                    placeholder={
                      getLan() === "es" ? "Correo electrónico" : "Email"
                    }
                    component={renderInput}
                    value={this.state.forms.email}
                    onInputChange={(event) => this.onChangeHandler(event, 4)}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className={["form-group", classes.input].join(" ")}>
                  <Field
                    id="company"
                    name="company"
                    type="text"
                    elementType="input"
                    placeholder={getLan() === "es" ? "Compañía" : "Company"}
                    classes={"montse texto_14_form regular"}
                    component={renderInput}
                    onInputChange={(event) => this.onChangeHandler(event, 5)}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                {this.props.optionsSupport ? this.getSelectSupport() : null}
              </div>
            </div>

            <div
              className={[
                "row",
                this.props.area === "boletin-informativo" ? "d-none" : null,
              ].join(" ")}
            >
              <div className="col ">
                <div className={["form-group", classes.input].join(" ")}>
                  <Field
                    id="message"
                    name="message"
                    type="textarea"
                    classes={"montse texto_14_form regular"}
                    elementType="textarea"
                    placeholder={getLan() === "es" ? "Mensaje" : "Message"}
                    component={renderInput}
                    onInputChange={(event) => this.onChangeHandler(event, 6)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col ">
                <div className={classes.captchaContainer}>
                  <ReCAPTCHA
                    sitekey={getKeyCaptcha()}
                    onChange={this.onChangeRecaptcha.bind(this)}
                    ref={this.recaptchaRef}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className={classes.copyright}>
                  <p className={"montse texto_14 regular"}>
                    <div className={classes.leftTexto}>
                      {getLan() === "es"
                        ? "Al enviar el mensaje aceptas nuestro "
                        : "By sending the message you agree to "}
                    </div>
                    <div
                      className={[classes.leftTexto, classes.link].join(" ")}
                    >
                      <a
                        href={rutaPrivacidad}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          className={["color_blanco", classes.link].join(" ")}
                        >
                          {getLan() === "es"
                            ? "Aviso de Privacidad "
                            : "Privacy Policy "}
                        </span>
                      </a>
                    </div>
                    <div className={classes.leftTexto}>
                      {" "}
                      {getLan() === "es" ? "y nuestros" : "and our"}{" "}
                    </div>
                    <div
                      className={[classes.leftTexto, classes.link].join(" ")}
                    >
                      <a
                        href={rutaAvisoLegal}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={["color_blanco"].join(" ")}>
                          {getLan() === "es"
                            ? "Términos de Uso"
                            : "Terms of Use"}
                          .
                        </span>
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div className="row margin_top_20">
              <div className="col sin_padding_L">
                <div className={classes.buttonContainer}>
                  {this.sectionButtonsModelOne(
                    form,
                    this.state.tieneValorRecaptcha
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      />
    );
  };

  sectionButtonsModelOne = (form, recaptcha = null) => {
    let disabledButton = false;
    if (
      this.state.tipo_mensaje === 1 ||
      this.props.area === "customer-service"
    ) {
      disabledButton =
        form.values.first_name !== "" &&
        form.values.last_name !== "" &&
        form.values.phone !== "" &&
        form.values.email !== "" &&
        form.values.message !== ""
          ? false
          : true;
    } else if (this.props.area === "boletin-informativo") {
      disabledButton =
        form.values.first_name !== "" &&
        form.values.last_name !== "" &&
        form.values.email !== ""
          ? false
          : true;
    } else if (this.props.area === "ib") {
      disabledButton =
        form.values.first_name !== "" &&
        form.values.last_name !== "" &&
        form.values.email !== "" &&
        form.values.message !== ""
          ? false
          : true;
    } else {
      disabledButton = form.values.message !== "" ? false : true;
    }
    if (this.props.optionsSupport) {
      disabledButton =
      form.values.first_name !== "" &&
      form.values.last_name !== "" &&
      form.values.email !== "" &&
      form.values.message !== "" &&
      form.values.subject !== "" 
        ? false
        : true;
    }
    if (!recaptcha) {
      disabledButton = true;
    }

    return (
      <button
        type="submit"
        className={["btn", classes.sendButton].join(" ")}
        disabled={disabledButton}
      >
        <span className={["nunito", "texto_16"].join(" ")}>
          {getLan() === "es" ? "Enviar mensaje" : "Send message"}
        </span>
        <i className="fas fa-long-arrow-alt-right" />
      </button>
    );
  };

  render() {
    alerta = null;

    if (this.state.errorMail) {
      alerta = (
        <div className={[classes.div_error, "row col-12"].join(" ")}>
          <span
            className={[classes.error, "montse texto_16 regular"].join(" ")}
          >
            {this.state.errorMail}...
          </span>
        </div>
      );
    }
    if (this.state.enviado) {
      alerta = (
        <div className={[classes.div_error, "row col-12"].join(" ")}>
          <span
            className={[classes.enviado, "montse texto_16 regular"].join(" ")}
          >
            {this.state.enviado}...
          </span>
        </div>
      );
    }

    const form = this.props.modelo
      ? this.onFormHandler(this.props.modelo)
      : null;
    return form;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitSendEmailArea: (params, area) => {
      inicioPeticion = true;
      dispatch(action.initSendEmailArea(params, area));
    },
    onInitSendEmailAnonimo: (params) => {
      inicioPeticion = true;
      dispatch(action.initSendEmailAnonimo(params));
    },
  };
};

const mapStateToProps = (state) => {
  let errorMail = null;
  let enviado = null;

  if (state.sendEmail.emailArea) {
    enviado = state.sendEmail.emailArea;
    state.sendEmail.emailArea = null;
    if (enviado) {
      limpiaForms = true;
    }
  } else if (state.sendEmail.emailAnonimo) {
    enviado = state.sendEmail.emailAnonimo;
    state.sendEmail.emailAnonimo = null;
  } else {
    enviado = null;
  }

  if (inicioPeticion && state.sendEmail.errorMail) {
    errorMail = state.sendEmail.errorMail;
    state.sendEmail.errorMail = null;
  } else {
    errorMail = null;
  }

  return {
    errorMail: errorMail,
    limpiaForms: limpiaForms ? +new Date() + "true" : false,
    enviado: enviado,
    avisoLegal: state.menu.avisoLegal,
    privacidad: state.menu.privacidad,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forms);

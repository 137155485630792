import React, { Component } from "react";
import classes from "./AlternativosASG.css";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import * as actions from "../../../../stores/actions/index";

import Accordion from "../../../../components/ui/accordion/Accordion";
import Tabs from "../../../../components/ui/tabs/Tabs";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";
import { getLocal, getLan, valueParamValidation, valueParamPageValidation, valueLanguage, valueValidation} from "../../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import StringUtils from "../../../../stores/utils/StringUtils";
import hojaVerde from "../../../../assets/icons/sustentable/hoja-verde-2.png";
import FilterAlternativosASG from "../../../../components/ui/filterAlternativosASG/FilterAlternativosASG";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisiones/asg/alternativos/xls`;

class AlternativosASG extends Component {
    state = {
        messages: null,
        lan: getLocal(),
        indiceIsin: null,
        emisoraSeleccionada: null,
        indexSelected: 0,
        indexSelectedInicial: 0,
        subFiltros: {
            indicadores: [],
            tipoGrafica: "LineChart"
        },
        tabsEmisoras: [
            {
                icon: "",
                image: "",
                title: getLan() === "es" ? "Listadas en BIVA" : "Listed in BIVA"
            },
            {
                icon: "",
                image: "",
                title: getLan() === "es" ? "Todas" : "All"
            },
            {
                icon: "",
                image: "",
                title: ""
            }
        ],
        activePage: 1,
        itemsPerPage: 10,
        loader: true,
        resourceList: [],
        biva: null,
        isin_seleccionado: null,
        canceladas: null,
        instrumento: null,
        indice_emisora: null,
        sector: null,
        modoListado: null,
        estado: null,
        ramo: null,
        sub_ramo: null,
        nombreEmision: null,
        tabsDesabilitados: [],
        activaTabInicial: null,
        activaTabInicialBono: null,
        claveFilter: null,
        filteredBy: null,
        wasFilteredBySearch: false,
        claveEmisora: null,
        limpiarFiltroGrafica: false,
        emisionASGFicha: null,
        subtab: 0,
        tipoInstrumento: null,
        filterEmisionClave: null,
        flagDetalle: false,
    };

    componentWillMount() {
        this.props.onInitIntl();
        this.initData(true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.initData(false);
        }
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages,
                    tabsEmisoras: [
                        {
                            icon: "",
                            image: "",
                            title: valueLanguage(getLan(), "Todas", "All")
                        },
                        {
                            icon: "",
                            image: "",
                            title: valueLanguage(getLan(), "Listadas en BIVA", "Listed in BIVA")
                        }
                    ],
                });
            }
        }

        if (this.props.emisoras !== prevProps.emisoras) {
            let emisoras = null;
            this.setState({
                loader: false
            });

            emisoras = this.props.emisoras !== null ? this.props.emisoras.data : null; //DATA CONTENT

            let resource = emisoras;
            this.onPageChangeHandler(resource, null, 0);
        }

        if (this.props.emisionASGFicha !== prevProps.emisionASGFicha) {
            let claveEmisora_detalle = null;
            if (this.props.emisionASGFicha !== null && this.props.emisionASGFicha !== undefined) {
                claveEmisora_detalle = this.props.emisionASGFicha.clave;
            }
            this.setState({
                claveEmisora: claveEmisora_detalle,
                emisionASGFicha: this.props.emisionASGFicha,
                loader: false
            });
        }

    }

    initData(getFicha = false) {
        let paramsString = queryString.parse(this.props.location.search);
        let modoListado = null;
        let tipoInstrumento = null;
        let emisora_id = null;
        let clave = null;
        let ficha = null;
        let search_field_param = paramsString["search_field"];
        if (this.props.location.search !== "" && search_field_param === undefined) {
            ficha = paramsString["ficha"] === "null" || paramsString["ficha"] === undefined ? null : JSON.parse(paramsString["ficha"]);
            modoListado = valueParamValidation(paramsString, "modoListado", true, true);
            emisora_id = valueParamValidation(paramsString, "emisora_id", false);
            clave = valueParamValidation(paramsString, "clave", false);
            tipoInstrumento = valueParamValidation(paramsString, "tipoInstrumento", false);

        }
        let page = valueParamPageValidation(paramsString, "page");

        this.setState({
            modoListado: modoListado,
            tipoInstrumento: tipoInstrumento,
            activePage: page > 0 ? page : 1,
            emisoraSeleccionada: emisora_id,
            claveEmisora: clave !== null ? clave : null,
            claveFilter: clave !== null ? clave : null
        });
        const pageActual = page > 0 ? page : 1;

        if (ficha === true && getFicha) {
            this.updateGrandparent(emisora_id, null, ficha);

        } else {
            this.onChangeTabInicial(modoListado, false, pageActual);
            let params = {
                size: this.state.itemsPerPage,
                page: pageActual - 1,
                modoListado: modoListado,
                tipoInstrumento: tipoInstrumento,
                claveResultados: clave,
                emisora: emisora_id,
                claveEmisora: clave !== null ? clave : null
            };
            this.props.onFilterAlternativos(params);
        }
    }

    onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
        let resourceList = [];
        let data = resource ? resource : this.props.emisoras;
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                itemSeleccionado: urlSeleccionado,
                resourceList: resourceList,
            });
        } else if (pageNumber) {
            this.setState({
                resourceList: resourceList,
                activePage: pageNumber,
            });

            this.props.history.push(
                `?modoListado=${this.state.modoListado}&tipoInstrumento=${this.state.tipoInstrumento}&emisora_id=${this.state.emisoraSeleccionada}&clave=${this.state.claveFilter}&ficha=${this.state.flagDetalle}&page=${pageNumber}`
            );
        } else {
            this.setState({
                resourceList: resourceList
            });
        }
    };


    onChangeTabInicial = (index = 0, consulta = false, pageActual = null) => {
        let bivaFilter = null;
        let activePage;
        if (this.state.indexSelectedInicial === index) {
            activePage = this.state.activePage;
        } else {
            activePage = pageActual ? pageActual : 1;
        }
        if (index === 1) {
            bivaFilter = 1;
        }
        if (consulta) {
            this.changeHistory(bivaFilter, 1, this.state.tipoInstrumento, this.state.emisoraSeleccionada, this.state.claveFilter, this.state.flagDetalle);
            this.setState({
                modoListado: bivaFilter,
                activaTabInicial: index,
            });
        }

        this.setState({
            indexSelectedInicial: index,
            activePage: activePage,
            loader: true,
            modoListado: bivaFilter,
            currentTab: index,
            activaTabInicial: index,
        });
    };


    updateGrandparent = (value, subTab = null, ficha = true, clave = null) => {
        let subtab = subTab === null ? 0 : subTab;
        this.setState({
            emisoraSeleccionada: value,
            indexSelected: subtab,
            loader: true,
            subTab: subtab,
            flagDetalle: ficha,
        });

        if (value !== null && value !== undefined && ficha === true) {
            this.props.history.push(
                `?modoListado=${this.state.modoListado}&tipoInstrumento=${this.state.tipoInstrumento}&emisora_id=${value}&ficha=${ficha}&page=${this.state.activePage}`
            );
            this.props.onFilterAlternativosFicha(value);
        } else {
            this.changeHistory(this.state.modoListado, 1, this.state.tipoInstrumento, value, clave, false);
        }
    };

    handlePageChange(pageNum) {
        if (this.state.activePage !== pageNum) {
            this.setState({
                loader: true
            });
            this.onPageChangeHandler(null, pageNum);
        }
    }

    changeHistory(
        modoListado = null,
        page = 1,
        tipoInstrumento = null,
        emisora = null,
        claveResultados = null,
        ficha = null,

    ) {
        this.props.history.push(
            `?modoListado=${modoListado}&tipoInstrumento=${tipoInstrumento}&emisora_id=${emisora}&clave=${claveResultados}&ficha=${ficha}&page=${page ? page : this.state.activePage}`
        );
        let params = {
            size: this.state.itemsPerPage,
            page: page > 0 ? page - 1 : 0,
            modoListado: modoListado,
            tipoInstrumento: tipoInstrumento,
            emisora: emisora,
            claveResultados: claveResultados,

        };
        this.props.onFilterAlternativos(params);
        this.setState({
            loader: true,
            claveEmisora: claveResultados,
            modoListado: modoListado,
            tipoInstrumento: tipoInstrumento,
            claveFilter: claveResultados,
            emisoraSeleccionada: emisora
        });
    }

    changeHistoryReset(
        modoListado = null,
        page = 1,
        tipoInstrumento = null,
        emisora = null,
        claveResultados = null,
        ficha = null,

    ) {
        this.props.history.push(
            `?modoListado=${modoListado}&tipoInstrumento=null&emisora_id=null&clave=null&ficha=false&page=${page ? page : this.state.activePage}`
        );
        let params = {
            size: this.state.itemsPerPage,
            page: page > 0 ? page - 1 : 0,
            modoListado: modoListado,
            tipoInstrumento: null,
            emisora: null,
            claveResultados: null,

        };
        this.props.onFilterAlternativos(params);
        this.setState({
            loader: true,
            claveEmisora: claveResultados,
            modoListado: modoListado,
            tipoInstrumento: tipoInstrumento,
            claveFilter: claveResultados,
            emisoraSeleccionada: emisora
        })
    }
    // FILTER

    onSetInitTabs = (index = null, emisora = null, wasFilteredBySearch = null) => {
        this.setState({
            emisoraSeleccionada: emisora,
            indexSelected: index,
            wasFilteredBySearch: wasFilteredBySearch
        });
    };

    updateGrandparentClaves(id, clave) {
        this.updateGrandparent(id, null, false, clave);
        this.setState({ claveFilter: clave });
    }

    onSetActivePage = activePage => {
        this.setState({
            activePage: activePage
        });
    };

    onSetFilters = ({ modoListado, tipoInstrumento, page, reset = false, valueClave = null }) => {
        this.setState({
            modoListado: modoListado ? [...modoListado] : null,
            tipoInstrumento: tipoInstrumento ? tipoInstrumento : null,
            activePage: page,
            valueClave
        });
        if (reset) {
            this.changeHistoryReset();
        } else {
            this.changeHistory(this.state.modoListado, page, tipoInstrumento, valueClave);
        }
    };

    onSetFilterClave = clave => {
        this.setState({
            claveFilter: clave,
            loader: true,
            flagDetalle: false,
        });

        if (clave !== null && clave !== undefined) {
            this.changeHistory(this.state.modoListado, 1, this.state.tipoInstrumento, null, clave);
        }
    };

    onSetFilteredBy = type => {
        this.setState({
            filteredBy: type
        });
    };

    onSetClaveEmisoraFilter = value => {

        this.setState({
            claveEmisora: value,
            emisoraSeleccionada: value
        });
    };

    onDeleteEmisoraSeleccionadaHandler = () => {
        this.setState({
            claveFilter: null,
            claveEmisora: null,
            emisionASGFicha: null,
            emisoraSeleccionada: null,
            loader: true,
            activePage: 1
        });
        this.changeHistory(this.state.modoListado, 1, this.state.tipoInstrumento, null, null);


    };


    render() {
        let dataToShow = null;
        let contenidoMostrar = null;
        let resultadoDescarga = null;
        let descarga_url = base_url;
        let tabMostrar = null;
        let logoRazonSocial = null;
        const numRows = this.props.emisoras && this.props.emisoras.page ? this.props.emisoras.page.totalElements : 0;
        let emisoras = this.props.emisoras && this.props.emisoras._embedded ? this.props.emisoras._embedded.content : [];

        let loader = null;
        const params = {
            idModoListado: this.state.modoListado,
            idTipoInstrumento: valueValidation(this.state.tipoInstrumento, null, false),
            coincidencias: valueValidation(this.state.claveFilter, false),
            idEmisora: valueValidation(this.state.emisoraSeleccionada, false),
        }
        const queryParams = StringUtils.jsonToQueryString(params);
        descarga_url = descarga_url + queryParams;

        if (this.state.loader) {
            loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
        }

        if (emisoras.length > 0) {
            dataToShow = (
                <Accordion
                    nombre="emisoras"
                    datos={emisoras}
                    icon={""}
                    tipo={7}
                    subtipo={7}
                    isSustentable={true}
                    updateGrandparent={this.updateGrandparent.bind(this)}
                />
            );
            resultadoDescarga = (
                <div className={"row margen_30 margin_bottom_30 margen_top_30"}>
                    <div className={[classes.card_header, "d-none d-md-block col-md-4 col-lg-5 col-xl-8"].join(" ")}>
                        <span className={["regular montse  texto_16 "].join(" ")} />
                    </div>
                    <div className={[classes.card_header2, "col-12 col-md-8 col-lg-7 col-xl-4 row"].join(" ")}>
                        <div
                            className={["col-12 sin_padding", "texto_16 montse regular margin_top_5"].join(
                                " "
                            )}
                        >
                            <a
                                href={descarga_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="row"
                            >
                                <div className={[
                                    classes.titulo,
                                    "col-7 texto_16 montse color_verde_oscuro regular ",
                                ].join(" ")}
                                >
                                    <span className={[
                                        "texto_16 montse color_verde_oscuro regular",
                                    ].join(" ")}
                                    >
                                        {valueLanguage(getLan(), "Listado de emisoras", "List of issuers")}
                                    </span>
                                    <br></br>
                                    <span
                                        className={[
                                            classes.titulo_ZIP,
                                            "semi_bold montse texto_10",
                                        ].join(" ")}
                                    >
                                        {valueLanguage(getLan(), "Formato XLSX", "XLSX file")}
                                    </span>
                                </div>
                                <div className="col-3">
                                    <i className={[
                                        "Boton__icono_derecho__25mg6",
                                        "fas fa-arrow-alt-circle-down fa-3x color_verde_oscuro",
                                    ].join(" ")}
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            );
        } else if (emisoras.length === 0) {
            dataToShow = (
                <p className="montse texto_16 regular">
                    {valueLanguage(getLan(), "No existen resultados para la búsqueda.", "No results found.")}
                </p>
            );
        }

        const emisorasInscritas = (
            <React.Fragment>
                {resultadoDescarga}
                <br />
                {dataToShow}
                <Pagination
                    claseTipo={2}
                    numRows={numRows}
                    paginas={5}
                    itemsPerPage={this.state.itemsPerPage}
                    handlePageChange={this.handlePageChange.bind(this)}
                    activePage={this.state.activePage}
                    isSustentable={true}
                />
            </React.Fragment>
        );

        // Info General 
        const emisoraInfo2 = <Accordion isSustentable={true} datos={this.props.emisionASGFicha} nombre="emisoras" icon={""} tipo={24} />;
        // Info General 1
        const emisoraInfo1 = <Accordion isSustentable={true} datos={this.props.emisionASGFicha} nombre="emisoras" icon={""} tipo={24} subtipo={7} />;

        const emisoraInfoFechas = <Accordion isSustentable={true} datos={this.props.emisionASGFicha} nombre="fechas" icon={""} tipo={24} subtipo={2} />;
        const emisoraInfoDespacho = <Accordion isSustentable={true} datos={this.props.emisionASGFicha} nombre="fechas" icon={""} tipo={24} subtipo={4} />;

        if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada !== null && this.state.flagDetalle === true) {

            descarga_url = `${this.state.emisoraSeleccionada}/xls`;

            resultadoDescarga = (
                <div className={"row margen_30 margin_bottom_30 margen_top_30"}>
                    <div className={[classes.card_header, "d-none d-md-block col-md-4 col-lg-5 col-xl-8"].join(" ")}>
                        <span className={["regular montse  texto_16 "].join(" ")} />
                    </div>
                    <div className={[classes.card_header2, "col-12 col-md-8 col-lg-7 col-xl-4 row"].join(" ")}>
                        <div
                            className={["col-12 sin_padding", "texto_16 montse regular margin_top_5"].join(
                                " "
                            )}
                        >
                            <a
                                href={descarga_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="row"
                            >
                                <div className={[
                                    classes.titulo,
                                    "col-5 texto_16 montse color_verde_oscuro regular ",
                                ].join(" ")}
                                >
                                    <span className={[
                                        "texto_16 montse color_verde_oscuro regular",
                                    ].join(" ")}
                                    >
                                        {valueLanguage(getLan(), "Exportar Ficha", "Export data sheet")}
                                    </span>
                                    <br></br>
                                    <span
                                        className={[
                                            classes.titulo_ZIP,
                                            "semi_bold montse texto_10",
                                        ].join(" ")}
                                    >
                                        {valueLanguage(getLan(), "Formato XLSX", "XLSX file")}
                                    </span>
                                </div>
                                <div className="col-3">
                                    <i className={[
                                        "Boton__icono_derecho__25mg6",
                                        "fas fa-arrow-alt-circle-down fa-3x color_verde_oscuro",
                                    ].join(" ")}
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            );

            logoRazonSocial = (
                <div className={["width_cien ", classes.vertical_div].join(" ")}>
                    <div className={[classes.titulo_25pct, classes.vertical].join(" ")}>
                        <div className={["width_cien", classes.vertical_div].join(" ")}>
                            <img
                                className={["width_cien", classes.vertical_img].join(" ")}
                                src={this.props.emisionASGFicha ? this.props.emisionASGFicha.logo : null}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={[classes.titulo_75pct, classes.vertical].join(" ")}>
                        <div className={["width_cien text-center", classes.vertical_div].join(" ")}>
                            <span className={["texto_30 nunito", classes.vertical].join(" ")}>
                                {valueValidation(this.props.emisionASGFicha, this.props.emisionASGFicha ? this.props.emisionASGFicha.fiduciario : null)}
                            </span>
                        </div>
                    </div>
                </div>
            )

            contenidoMostrar = (
                <div className="margen_top_50">
                    {logoRazonSocial}
                    {resultadoDescarga}
                    {emisoraInfo1} 
                    {emisoraInfoFechas}
                    {emisoraInfo2} 
                    {emisoraInfoDespacho}
                </div>
            );
        } else {
            contenidoMostrar = <div className="margen_50">{emisorasInscritas}</div>;
            tabMostrar = (
                <div className="row margen_top_30">
                    <div className={["col-12 col-lg-1 sin_padding texto_16 nunito semi_bold text-center", classes.tabsContainer, classes.myCol].join(" ")}>
                        <div className={classes.vertical_div}>
                            <span className={classes.vertical_content}>
                                {valueLanguage(getLan(), "Mostrar", "View")}
                            </span>
                        </div>
                    </div>
                    <div className={["col-12 col-lg-11 sin_padding", classes.myCol].join(" ")}>
                        <Tabs
                            data={this.state.tabsEmisoras}
                            tipo={1}
                            clicked={i => this.onChangeTabInicial(i, true)}
                            activaTabInicial={this.state.activaTabInicial}
                            isSustentable={true}
                        />
                    </div>
                </div>
            );
        }

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
                <React.Fragment>
                    {loader}
                    <div
                        className={[
                            "main_subtitle",
                            "texto_40 ",
                            "nunito",
                            "containerTituloSg",
                            "sin_padding",
                        ].join(" ")}
                    >
                        <img
                            src={hojaVerde}
                            alt="bivasg"
                            className={["slash", "imgSg"].join(" ")}
                        />
                        <h1 className={"tituloSg nunito texto_42 bold texto_verde_oscuro"}>
                            {" "}
                            <FormattedHTMLMessage id="asg.alternativos.subtitulo" defaultMessage=" " />
                        </h1>
                    </div>
                    <div className="margin_bottom_20 texto_16 montse regular">
                        <FormattedHTMLMessage id="asg.alternativos.introduccion" defaultMessage=" " />
                    </div>
                    {tabMostrar}

                    <div className="row margen_top_30 margin_bottom_20">
                        <div className="col sin_padding">
                            <FilterAlternativosASG
                                setInitTabs={(index, emisora, wasFilteredBySearch) => this.onSetInitTabs(index, emisora, wasFilteredBySearch)}
                                setActivePage={activePage => this.onSetActivePage(activePage)}
                                setFilters={filterParams => this.onSetFilters(filterParams)}
                                updateEmisorasParent={(id, clave) => this.updateGrandparentClaves(id, clave)}
                                emisora_id={this.state.emisoraSeleccionada}
                                biva={this.state.modoListado}
                                claveEmisora={this.state.claveEmisora}
                                setFilterClave={clave => this.onSetFilterClave(clave)}
                                wasFilteredBy={type => this.onSetFilteredBy(type)}
                                onDeleteEmisoraSelecionada={() => this.onDeleteEmisoraSeleccionadaHandler()}
                                borraClaveFiltro={param => this.onSetClaveEmisoraFilter(param)}
                            />
                        </div>
                    </div>
                    <div className="margen_50">{contenidoMostrar}</div>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitIntl: () => {
            dispatch(actions.initIntl(108));
        },
        onFilterAlternativos: params => {
            dispatch(actions.filterAlternativosASG(params));
        },
        onFilterAlternativosFicha: (emisoraId) => {
            dispatch(actions.initAlternativosASGFicha(emisoraId));
        }

    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        emisoras: state.bivaSustentable.alternativosASG,
        emisionASGFicha: state.bivaSustentable.alternativosASGFicha,
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AlternativosASG)
);

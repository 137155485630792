import React, { Component } from "react";
// import classes from "./Membresia.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";
import { classes } from "istanbul-lib-coverage";
import Box from "../../../components/ui/box/Box";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
class Membresia extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {

    let solicitud = null;
    if (this.props.solicitudes) {
      solicitud = this.props.solicitudes.map((solicitud, i) => {
        return <Box key={"solicitud_" + i} modelo="modelo_1" data={solicitud}   />;
      });
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="operaciones.casasdebolsa.membresia.titulo1" defaultMessage=" " />
                  
                </h1>
              </div>
            </div>
          </div>
          <div className="">
            <Accordion
              datos={this.props.accordion}
              nombre={"cursos"}
              icon={""}
              imagen_icono={"tablaCheck.svg"}
              tipo={6}
              titulo_encabezado_estatico={getLan() === "es" ? "Información" : "Information"}
              className={classes.accordion}
              icons_tamaño={false}
            />
          </div>
          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2><FormattedHTMLMessage id="operaciones.casasdebolsa.membresia.subtitulo1" defaultMessage=" " /></h2>
              </div>
            </div>
            <div className="row">
              <div className={""}>
                {solicitud}
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    accordion: state.accordion.membresia,
    solicitudes: state.documentos.solicitudMiembroBiva
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(26));
      dispatch(action.getAccordionMembresia());
      dispatch(action.getSolicitudMiembroBiva());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Membresia);

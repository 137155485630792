import React, { Component } from "react";
import classes from "./FilterAvisosOfertasPublicas.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Form, Field } from "react-final-form";

import Auxs from "../../../hoc/auxs/Auxs";
import Input from "../input/Input";

import * as actions from "../../../stores/actions/index";
import { getLan } from "../../../stores/utils/utilities";

const renderInput = ({
  input,
  id = null,
  label,
  clases = null,
  options = null,
  type = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel,
  disabled = false,
  onInputChange
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      disabled: disabled,
      checked: checked,
      className: clases ? clases : null,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    type: type,
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };

  return (
    <div className="form-group">
      {label ? (
        <label
          className={[elementType === "checkbox" ? classes.myLabel : null, "texto_14", "montse", "regular"].join(" ")}
          for={input.name}
        >
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterAvisosOfertasPublicas extends Component {
  constructor(props) {
    super(props);
    this.formFilterAvisos = React.createRef();
  }

  state = {
    toggleFilter: false,
    selectedFilters: {
      claves_cotizacion: [],
      instrumentos: [],
      serie: []
    },
    form_filters: {
      claves_cotizacion: {
        value: ""
      },
      instrumentos: {
        value: ""
      },
      serie: {
        value: ""
      }
    },
    pivotInput: false,
    itemsPerPage: 10,
    claves_cotizacion: [],
    instrumentos: [],
    series: [],
    claves_cotizacion_copy: [],
    instrumentos_copy: [],
    series_copy: []
  };

  componentDidMount() {
    this.props.onFilterClavesCotizacion(null,this.props.tipoBono);
    this.props.onFilterIntrumentos(null,this.props.tipoBono);
  }

  componentDidUpdate(prevProps) {
    if (this.props.claves_cotizacion !== prevProps.claves_cotizacion) {
      this.setState({
        claves_cotizacion: this.props.claves_cotizacion,
      });
    }
    if (this.props.instrumentos !== prevProps.instrumentos) {
      this.setState({
        instrumentos: this.props.instrumentos,
        instrumentos_copy: this.props.instrumentos
      });
    }
    if (this.props.series !== prevProps.series) {
      this.setState({
        series: this.props.series,
        series_copy: this.props.series
      });
    }
    if (this.props.tipoBono !== prevProps.tipoBono){
      if (this.props.tipoBono !==null && prevProps.tipoBono !== null){
        if (this.props.tipoBono.length!== prevProps.tipoBono.length){
          this.onResetFilter();
          this.props.onFilterClavesCotizacion(null,this.props.tipoBono);
          this.props.onFilterIntrumentos(null,this.props.tipoBono);
        }
      }
    }
  }

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type) => {
    let newValue = null;
    let newLabel = null;

    newValue = type === 1 ? value.split("_")[0] : value;
    newLabel = type === 1 ? value.split("_")[1] : value;
    return [newValue, newLabel];
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    newValues = options.split("_")[0];
    return newValues;
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [valuesClaveCotizacion, valuesInstrumentos, valuesSerie] = this.onGetAllValuesFormFilter();

    const params = {
      size: this.state.itemsPerPage,
      page: 1,
      claveCotizacion: valuesClaveCotizacion,
      idTipoInstrumento: valuesInstrumentos,
      serie: valuesSerie,
      reset: false
    };

    this.props.setParams(params);

    let wasFiltered = false;
    // Se invoca la funcion del componente padre (Emisoras.js) para setear los valores correspondientes
    // Configurando valores para campo clave cotización
    this.props.setClaveCotizacionFilter(valuesClaveCotizacion);
    // Configurando valores para campo instrumentos
    this.props.setInstrumentosFilter(valuesInstrumentos);
    // Configurando valores para campo serie
    this.props.setSerieFilter(valuesSerie);

    if (valuesClaveCotizacion !== "" || valuesInstrumentos !== "" || valuesSerie !== "") {
      wasFiltered = true;
    }

    this.props.setWasFiltered(wasFiltered);
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de clave cotizacion
    const valuesClaveCotizacion =
      form.claves_cotizacion.value && form.claves_cotizacion.value !== "" ? form.claves_cotizacion.value : "";
    // Se obtiene el valor del tipo de instrumento
    const valuesInstrumentos =
      form.instrumentos.value && form.instrumentos.value !== "" ? this.onSplitValueHandler(form.instrumentos.value) : "";
    // Se obtiene el valor de serie
    const valuesSerie = form.serie.value && form.serie.value !== "" ? form.serie.value : "";

    return [valuesClaveCotizacion, valuesInstrumentos, valuesSerie];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["claves_cotizacion", form.claves_cotizacion.value, selectedFilters.claves_cotizacion];
      case 1:
        return ["instrumentos", form.instrumentos.value, selectedFilters.instrumentos];
      case 2:
        return ["serie", form.serie.value, selectedFilters.serie];
    }
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (values = null) => {
    return values && values !== "" ? values : null;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];
    if (values !== "") {
      const auxLabel = type === 2 ? `Serie ${label}` : label;
      newFilterValues.push({ label: auxLabel, value: values, type: type });
    }

    return newFilterValues;
  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (event, type = null) => {
    let actualValue = event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newValue = null;
    let newLabel = null;

    let pivotInput = this.state.pivotInput;

    [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue, type);

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(actualValue);
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [valuesClaveCotizacion, valuesInstrumentos, valuesSerie] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      // Clave cotización
      if (!pivotInput || pivotInput === "firstpath") {
        // Configurando el campo clave cotizacion como campo pivote
        pivotInput = "firstpath";
        // Limpiando valores para campo instrumentos
        form.instrumentos.value = "";
        selectedFilters.instrumentos = [];
        // Filtrando valores para campo instrumentos
        this.props.onFilterIntrumentos(valuesClaveCotizacion,this.props.tipoBono);

        if (valuesClaveCotizacion === "") {
          pivotInput = null;
        }
      }
      // Cuando instrumentos es el campo pivotInput
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];
      // this.setState({
      //   instrumentos: [],
      //   series: []
      // });
      // Filtrando valores para campo serie
      this.props.onFilterSeries(valuesClaveCotizacion, valuesInstrumentos,this.props.tipoBono);
    } else if (type === 1) {
      // Instrumentos
      if (!pivotInput || pivotInput === "secondpath") {
        // Configurando el campo instrumento como campo pivote
        pivotInput = "secondpath";
        // Limpiando valores para campo claves cotizacion
        form.claves_cotizacion.value = "";
        selectedFilters.claves_cotizacion = [];
        // Filtrando valores para campo clave cotizacion
        this.props.onFilterClavesCotizacion(valuesInstrumentos,this.props.tipoBono);

        if (valuesInstrumentos === "") {
          pivotInput = null;
        }
      }
      // Cuando claves cotización es el campo pivotInput
      // Limpiando valores para campo serie
      this.setState({
        series: []
      });
      form.serie.value = "";
      selectedFilters.serie = [];
      // Filtrando valores para campo serie
      this.props.onFilterSeries(valuesClaveCotizacion, valuesInstrumentos,this.props.tipoBono);
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      pivotInput: pivotInput
    });

    this.onFilterDataByEvent();
  };

  onDeleteSelectedFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let pivotInput = this.state.pivotInput;

    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

    newStateValues = this.onChangeStateHandler();
    newFilterValues = this.onChangeFilterHandler(filterValues, "", item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [valuesClaveCotizacion, valuesInstrumentos, valuesSerie] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (item.type === 0) {
      // Claves cotización
      if (pivotInput === "firstpath") {
        // Limpiando valores para campo instrumento
        form.instrumentos.value = "";
        selectedFilters.instrumentos = [];
        // Filtrando valores para campo instrumento
        this.props.onFilterIntrumentos(null, this.props.tipoBono);

        pivotInput = false;
      }
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];
      // Filtrando valores para campo serie
      this.props.onFilterSeries(null, valuesInstrumentos,this.props.tipoBono);
    } else if (item.type === 1) {
      // instrumentos
      if (pivotInput === "secondpath") {
        // Limpando valores para campo clave cotización
        form.claves_cotizacion.value = "";
        selectedFilters.claves_cotizacion = [];
        // Filtrando valores para campo clave cotización
        this.props.onFilterClavesCotizacion(null, this.props.tipoBono);

        pivotInput = false;
      }
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];
      // Filtrando valores para campo serie
      this.props.onFilterSeries(valuesClaveCotizacion,null,this.props.tipoBono);
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      pivotInput: pivotInput
    });

    this.onFilterDataByEvent();
  };

  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [
      ...this.state.selectedFilters.claves_cotizacion,
      ...this.state.selectedFilters.instrumentos,
      ...this.state.selectedFilters.serie
    ];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>{filter.label}</span>
              <span className={classes.filterIconButton} onClick={() => this.onDeleteSelectedFilterHandler(filter)}>
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onSubmitFilter = () => {
    // Pass
  };

  onResetFilter = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    const pivotInput = false;

    // Limpiando valores para campo clave cotización
    form.claves_cotizacion.value = "";
    selectedFilters.claves_cotizacion = [];
    // Limpiando valores para campo instrumentos
    form.instrumentos.value = "";
    selectedFilters.instrumentos = [];
    // Limpiando valores para campo serie
    form.serie.value = "";
    selectedFilters.serie = [];

    const params = {
      size: this.state.itemsPerPage,
      page: 1,
      claveCotizacion: null,
      idTipoInstrumento: null,
      serie: null,
      reset: true
    };

    this.props.setParams(params);
    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      pivotInput: pivotInput
    });
  };

  onToggleFilterHandler = () => {
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  onSelectOptionsHandler = (options = null, type = null) => {
    let opt = [];
    options.forEach(option => {
      opt.push({
        nombre: type === 0 ? option : option.nombre,
        id: type === 0 ? option : `${option.id}_${option.nombre}`
      });
    });
    return opt;
  };

  printFrameModelHandler = () => {
    const selectedFilters =
      this.state.selectedFilters.claves_cotizacion.length > 0 || this.state.selectedFilters.instrumentos.length > 0
        ? this.onPrintSelectedFiltersHandler()
        : null;
    return (
      <Auxs>
        <div
          className={["row", classes.firstSectionFilterContainer, this.state.toggleFilter ? classes.noBorderRadius : null].join(
            " "
          )}
        >
          <div className="col col-lg-3 text-center sin_padding_L">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button type="button" className="btn" onClick={this.onToggleFilterHandler}>
                <i className="fas fa-filter" />
                <span>
                  <span>
                    {!this.state.toggleFilter
                      ? getLan() === "es"
                        ? "Filtrar"
                        : "Filter"
                      : getLan() === "es"
                      ? "Cerrar filtros"
                      : "Close"}
                  </span>
                </span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={[
            "row",
            classes.secondSectionFilterContainer,
            "margin_bottom_50",
            !this.state.toggleFilter ? classes.hidden : null
          ].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    let clavesCotizacionOptions =
      this.state.claves_cotizacion && this.state.claves_cotizacion.length > 0
        ? this.onSelectOptionsHandler(this.state.claves_cotizacion, 0)
        : [];

    let instrumentosOptions =
      this.state.instrumentos && this.state.instrumentos.length > 0 ? this.onSelectOptionsHandler(this.state.instrumentos) : [];

    let seriesOptions =
      this.state.series && this.state.series.length > 0 ? this.onSelectOptionsHandler(this.state.series, 0) : [];
    if (clavesCotizacionOptions.length === 0) {
      if (this.props.claves_cotizacion === this.props.claves_cotizacion_copy) {
        clavesCotizacionOptions =
          this.state.claves_cotizacion_copy && this.state.claves_cotizacion_copy.length > 0
            ? this.onSelectOptionsHandler(this.state.claves_cotizacion_copy, 0)
            : [];
      }
    }

    if (instrumentosOptions.length === 0) {
      if (this.props.instrumentos === this.state.instrumentos_copy) {
        instrumentosOptions =
          this.state.instrumentos_copy && this.state.instrumentos_copy.length > 0
            ? this.onSelectOptionsHandler(this.state.instrumentos_copy)
            : [];
      }
    }
    if (seriesOptions.length === 0) {
      if (this.props.series === this.state.series_copy) {
        seriesOptions =
          this.state.series_copy && this.state.series_copy.length > 0
            ? this.onSelectOptionsHandler(this.state.series_copy, 0)
            : [];
      }
    }
    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitFilter}
              initialValues={{
                claves_cotizacion: this.state.form_filters.claves_cotizacion.value,
                instrumentos: this.state.form_filters.instrumentos.value,
                serie: this.state.form_filters.serie.value
              }}
              render={({
                optionsClavesCotizacion = clavesCotizacionOptions,
                optionsInstrumentos = instrumentosOptions,
                optionsSerie = seriesOptions,
                handleSubmit,
                form
              }) => (
                <form ref={this.formFilterAvisos} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-4">
                      <Field
                        name="claves_cotizacion"
                        id="claves_cotizacion"
                        elementType="select"
                        clases="custom-select"
                        label={getLan() === "es" ? "Clave de cotización" : "Ticker"}
                        options={optionsClavesCotizacion}
                        onInputChange={event => this.onInputSelectedHandler(event, 0)}
                        component={renderInput}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                      <Field
                        name="instrumentos"
                        id="instrumentos"
                        elementType="select"
                        clases="custom-select"
                        label={getLan() === "es" ? "Instrumentos" : "Security type"}
                        options={optionsInstrumentos}
                        onInputChange={event => this.onInputSelectedHandler(event, 1)}
                        component={renderInput}
                      />
                    </div>
                    <div
                      className={[
                        "col-12 col-sm-6 col-lg-4",

                        (!this.state.form_filters.claves_cotizacion.value ||
                          this.state.form_filters.claves_cotizacion.value === "") &&
                        (!this.state.form_filters.instrumentos.value || this.state.form_filters.instrumentos.value === "")
                          ? classes.hidden
                          : null
                      ].join(" ")}
                    >
                      <Field
                        name="serie"
                        id="serie"
                        elementType="select"
                        clases="custom-select"
                        label="Serie"
                        options={optionsSerie}
                        onInputChange={event => this.onInputSelectedHandler(event, 2)}
                        component={renderInput}
                      />
                    </div>
                  </div>
                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  sectionButtonsHandler = form => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilter()}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandler();
    return (
      <Auxs>
        <div className={["row", this.props.isSustentable? classes.sustentable : null].join(" ")}>
          <div className="col sin_padding_L">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  return {
    claves_cotizacion: state.listadoValores.claves_cotizacion,
    instrumentos: state.listadoValores.instrumentos,
    series: state.listadoValores.series
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterClavesCotizacion: (clave = null,tipoBono = null) => {
      dispatch(actions.filterClavesCotizacion(clave,tipoBono));
    },
    onFilterIntrumentos: (clave = null, tipoBono = null) => {
      dispatch(actions.filterTipoIntrumentos(clave,tipoBono));
    },
    onFilterSeries: (clave, idTipoValor ,tipoBono = null) => {
      dispatch(actions.filterSeries(clave, idTipoValor,tipoBono));
    },
    onFilterAvisosOfertasPublicas: params => {
      dispatch(actions.filterAvisosOfertasPublicas(params));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterAvisosOfertasPublicas)
);

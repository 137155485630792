import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./NuevaSeccion.css";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Tabs from "../../../components/ui/tabs/Tabs";
import Pagination from "../../../components/ui/pagination/Pagination";
import FilterSic from "../../../components/ui/filterSic/FilterSic";
import Loader from "../../../components/loader/Loader";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/sic/descargar`;

const tabsSic = [
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Todas" : "All"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Listadas en BIVA" : "Listed in BIVA"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Canceladas" : "Cancelled"
    }
];

const tabsInfo = [
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Historia" : "General information"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Proceso BIVA" : "Corporate action notice"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Casas Bolsa" : "Listed securities"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Documentos" : "Listed securities"
    }
];

class NuevaSeccion extends Component {
    state = {
        messages: null,
        lan: getLocal(),
        emisoraSeleccionada_id: null,
        emisoraSeleccionada: null,
        sicDetail: null,
        indexSelected: 0,
        logo: null,
        razonSocial: null,
        itemsPerPage: 10,
        activePage: 1,
        resourceList: [],
        loader: true,
        numRows: 0,
        biva: false,
        canceladas: false,
        tipoValor: null,
        tipoInstrumento: null,
        clave: null,
        claveEmisoraSeleccionada: null,
        tab: 0,
        subTab: 0,
        wasFiltered: false,
        tabsDeshabilitados: [],
        fechaInicio: null,
        fechaFin: null,
        tipoDerecho: null
    };

    handlePageChange(pageNumber) {
        if (this.state.activePage !== pageNumber) {
            this.setState({
                loader: true
            });

            this.onPageChangeHandler(null, pageNumber);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages
                });
            }
        }
        if (this.props.sicDetail !== prevProps.sicDetail) {
            let canceladas = false;
            let tabsDeshabilitados = this.state.tabsDeshabilitados;
            if (this.props.sicDetail !== null && this.props.sicDetail !== undefined) {
                if (
                    this.props.sicDetail.estatus === "Cancelada" ||
                    this.props.sicDetail.estatus === "Listado preventivo"
                ) {
                    canceladas = true;
                    tabsDeshabilitados = [2];
                }
                if (this.props.sicDetail.estatus === "Activa") {
                    tabsDeshabilitados = [];
                }
            }
            this.setState({
                sicDetail: this.props.sicDetail,
                canceladas:
                    this.props.sicDetail &&
                        this.props.sicDetail.estatus &&
                        this.props.sicDetail.estatus === "Activa"
                        ? false
                        : true,
                tabsDeshabilitados: tabsDeshabilitados,
                loader: false
            });
        }
        if (this.props.cotizaciones !== prevProps.cotizaciones) {
            this.setState({
                loader: false
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let cotizaciones = null;
        let numRows = null;

        if (nextProps.cotizaciones && nextProps.cotizaciones.content) {
            cotizaciones = nextProps.cotizaciones.content;
            numRows = nextProps.cotizaciones.totalElements;
        }

        this.setState({
            numRows: numRows
        });
        let resource = cotizaciones;
        this.onPageChangeHandler(resource, null, 0);
    }

    componentDidMount() {
        // let resource = this.props.cotizaciones;
        this.props.onInitIntl();

        let paramsString = queryString.parse(this.props.location.search);
        // `?tipoValor&mercadoPrincipal&tab=&subTab=&clave=&emisora=&page=`
        let tipoValor =
            paramsString["tipoValor"] !== undefined &&
                paramsString["tipoValor"] !== "null"
                ? paramsString["tipoValor"].split(",")
                : null;
        let mercadoPrincipal =
            paramsString["mercadoPrincipal"] !== undefined &&
                paramsString["mercadoPrincipal"] !== "null"
                ? paramsString["mercadoPrincipal"]
                : null;
        let tab =
            paramsString["tab"] !== undefined && paramsString["tab"] !== "null"
                ? parseInt(paramsString["tab"])
                : null;
        let subTab =
            paramsString["subTab"] !== undefined && paramsString["subTab"] !== "null"
                ? parseInt(paramsString["subTab"])
                : null;
        let clave =
            paramsString["clave"] !== undefined && paramsString["clave"] !== "null"
                ? paramsString["clave"]
                : null;
        let emisora =
            paramsString["emisora"] !== undefined &&
                paramsString["emisora"] !== "null"
                ? paramsString["emisora"]
                : null;
        let page =
            paramsString["page"] !== undefined && paramsString["page"] !== "null"
                ? parseInt(paramsString["page"])
                : 1;
        let tipoDerecho =
            paramsString["tipoDerecho"] !== undefined &&
                paramsString["tipoDerecho"] !== "null"
                ? paramsString["tipoDerecho"].split(",")
                : null;
        let fechaInicio =
            paramsString["fechaInicio"] !== undefined &&
                paramsString["fechaInicio"] !== "null"
                ? paramsString["fechaInicio"]
                : null;
        let fechaFin =
            paramsString["fechaFin"] !== undefined &&
                paramsString["fechaFin"] !== "null"
                ? paramsString["fechaFin"]
                : null;
        if (emisora !== null) {
            // this.props.onInitEmisorasDetail(emisora);
            this.updateGrandparent(emisora, clave, false);
        }
        let indexSelected = 0;
        if (+tab !== 0) {
            indexSelected = +tab;
        }
        if (subTab !== null) {
            subTab = subTab;
        }
        this.setState({
            emisoraSeleccionada_id: emisora,
            emisoraSeleccionada: emisora,
            indexSelected: indexSelected,
            biva: tab === 1 ? true : false,
            canceladas: tab === 2 ? true : false,
            tipoValor: tipoValor,
            tipoInstrumento: mercadoPrincipal,
            clave: clave,
            claveEmisoraSeleccionada: clave,
            tab: tab ? tab : 0,
            indexSelected: tab ? tab : 0,
            subTab: subTab,
            activePage: page > 0 ? page : 1,
            tabsDeshabilitados: this.state.canceladas === true ? [2] : [],
            tipoDerecho: tipoDerecho
        });
        if (emisora) {
            page = 1;
        }
        this.props.onInitSic(
            this.state.itemsPerPage,
            page > 0 ? page - 1 : 0,
            tab === 0 ? null : tab === 1 ? true : null,
            tab === 0 ? null : tab === 2 ? true : null,
            tipoValor,
            mercadoPrincipal,
            emisora,
            clave
        );
    }

    onPageChangeHandler = (
        resource = null,
        pageNumber = null,
        seleccionado = null
    ) => {
        let data = resource ? resource : this.props.cotizaciones;

        let resourceList = [];
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                resourceList: resourceList,
                itemSeleccionado: urlSeleccionado
            });
        } else if (pageNumber) {
            this.setState({
                activePage: pageNumber,
                resourceList: resourceList
            });
            this.changeHistory(
                this.state.tipoValor,
                this.state.tipoInstrumento,
                this.state.biva ? 1 : this.state.canceladas ? 2 : 0, // this.state.tab,
                this.state.clave,
                this.state.emisoraSeleccionada,
                pageNumber,
                this.state.subTab,
                this.state.tipoDerecho,
                this.state.fechaInicio,
                this.state.fechaFin,
                this.state.biva,
                this.state.canceladas
            );
        } else {
            this.setState({
                resourceList: resourceList
            });
        }
    };

    componentWillMount() { }

    changeHistory(
        tipoValor = null,
        mercadoPrincipal = null,
        tab = 0,
        clave = null,
        emisoraSeleccionada = null,
        page = null,
        subTab = 0,
        tipoDerecho = null,
        fechaInicio = null,
        fechaFin = null,
        biva = null,
        canceladas = null
    ) {
        this.props.history.push(
            `?tipoValor=${tipoValor}&mercadoPrincipal=${mercadoPrincipal}&tab=${tab}&subTab=${subTab}&clave=${clave}&emisora=${emisoraSeleccionada}&page=${page ? page : this.state.activePage
            }&tipoDerecho=${tipoDerecho}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`
        );
        this.setState({
            loader: true
        });

        let bivaParam = biva;
        let canceladasParam = canceladas;
        if (tab !== null && tab !== undefined && biva === -1 && canceladas === -1) {
            bivaParam = tab === 1 ? true : null;
            canceladasParam = tab === 2 ? true : null;
        } else {
            bivaParam = biva !== null && biva !== undefined ? biva : null;
            canceladasParam =
                canceladas !== null && canceladas !== undefined ? canceladas : null;
        }

        this.props.onInitSic(
            this.state.itemsPerPage,
            page > 0 ? page - 1 : 0,
            bivaParam,
            canceladasParam,
            tipoValor,
            mercadoPrincipal,
            emisoraSeleccionada,
            clave
        );
    }

    changeHistoryReset(biva, canceladas, tab) {
        this.props.history.push(
            `?tipoValor=${null}&mercadoPrincipal=${null}&tab=${null}&subTab=${null}&clave=${null}&emisora=${null}&page=1&tipoDerecho=${null}&fechaInicio=${null}&fechaFin=${null}`
        );
        this.props.onInitSic(
            this.state.itemsPerPage,
            0,
            biva ? true : false,
            canceladas ? true : false,
            null,
            null,
            null,
            null
        );
    }

    onChangeTabInicial = (index = 0, consulta = false) => {
        let activePage = null;
        let bivaFilter = true;
        let canceladasFilter = true;
        let tabsDesabilitados = [];
        if (this.state.tab === index) {
            activePage = this.state.activePage;
        } else {
            activePage = 1;
        }
        if (index === 0) {
            bivaFilter = false;
            tabsDesabilitados = [];
            canceladasFilter = false;
        } else if (index === 1) {
            bivaFilter = true;
            tabsDesabilitados = [];
            canceladasFilter = false;
        } else if (index === 2) {
            bivaFilter = false;
            canceladasFilter = true;
            tabsDesabilitados = [1, 3];
        }
        this.props.history.push(
            `?instrumento=${this.state.instrumento}&tipo_valor=${this.state.tipo_valor
            }&sector=${this.state.sector}&sub_sector=${this.state.sub_sector}&ramo=${this.state.ramo
            }&sub_ramo=${this.state.sub_ramo
            }&emisora_id=${null}&canceladas=${canceladasFilter}&biva=${bivaFilter}&page=${this.state.activePage
            }`
        );

        this.setState({
            tab: index,
            activePage: activePage,
            loader: true,
            biva: bivaFilter,
            canceladas: canceladasFilter,
            tabsDesabilitados: tabsDesabilitados,
            currentTab: index,
            emisoraSeleccionada: null
        });
        this.changeHistory(
            null, //this.state.tipoValor,
            null, //this.state.tipoInstrumento,
            index,
            null, //this.state.clave,
            this.state.emisoraSeleccionada,
            1,
            this.state.subTab,
            null, //this.state.tipoDerecho,
            null, //this.state.fechaInicio,
            null, //this.state.fechaFin
            bivaFilter,
            canceladasFilter
        );
    };

    onChangeItemSelected = (index = 0) => {
        let subTab = 0;
        if (index !== this.state.subTab) {
            if (this.state.emisoraSeleccionada !== null) {
                subTab = index;
                this.setState({
                    subTab: subTab,
                    activePage: 1
                });
                this.changeHistory(
                    this.state.tipoValor,
                    this.state.tipoInstrumento,
                    this.state.tab,
                    this.state.clave,
                    this.state.emisoraSeleccionada,
                    1,
                    subTab,
                    this.state.tipoDerecho,
                    this.state.fechaInicio,
                    this.state.fechaFin,
                    this.state.tab === 1 ? true : null,
                    this.state.tab === 2 ? true : null
                );
            }
        }
    };

    updateGrandparent = (id = null, clave_ = null, consulta = true) => {
        let claveEmisora = clave_;
        if (id && clave_ === null) {
            claveEmisora = this.state.resourceList.find(itm => itm.id === id)
                ? this.state.resourceList.find(itm => itm.id === id).clave
                : null;
        }
        this.setState({
            emisoraSeleccionada: id,
            clave: claveEmisora,
            claveEmisoraSeleccionada: claveEmisora,
            indexSelected: 0,
            subTab: 0
        });
        if (consulta) {
            this.changeHistory(
                null,
                null,
                this.state.tab ? this.state.tab : 0,
                claveEmisora,
                id ? id : null,
                null,
                0,
                null,
                null,
                null,
                this.state.tab === 1 ? true : null,
                this.state.tab === 2 ? true : null
            );
        }
        if (id) {
            this.props.onInitEmisorasDetail(id);
        }
    };

    onSetLoader = loader => {
        this.setState({
            loader: loader
        });
    };

    onSetPage = page => {
        this.setState({
            activePage: page
        });
    };
    onSetTipoInstrumento = (tipoInstrumento, tipo = null) => {
        this.setState({
            tipoInstrumento: tipoInstrumento
        });
    };

    onSetTipoValor = tipoValor => {
        this.setState({
            tipoValor: tipoValor
        });
        let tip = null;
        if (
            tipoValor !== null &&
            tipoValor.length !== undefined &&
            tipoValor.length === 0
        ) {
            tip = -1;
        } else {
            tip = tipoValor;
        }
    };

    onSetEmisora = (emisora, tipo, clave_ = null) => {
        let emisoraSeleccionada = null;
        let clave = null;
        let claveEmisoraSeleccionada = null;
        if (tipo === 1) {
            emisoraSeleccionada = emisora;
            claveEmisoraSeleccionada = clave_;
            clave = clave_;
        }
        if (tipo === 2) {
            clave = emisora;
            claveEmisoraSeleccionada = emisora;
        }

        this.setState({
            emisoraSeleccionada_id: emisoraSeleccionada,
            emisoraSeleccionada: emisora,
            claveEmisoraSeleccionada: claveEmisoraSeleccionada,
            clave: clave,
            loader: true
        });
    };

    onSetLimpiarFiltros = changeTab => {
        this.setState({
            emisoraSeleccionada_id: null,
            claveEmisoraSeleccionada: null,
            activePage: changeTab ? 1 : this.state.activePage,
            tipoValor: null,
            tipoInstrumento: null
        });
    };

    setWasFiltered = wasFiltered => {
        this.setState({
            wasFiltered: wasFiltered
        });
    };

    onSetFilters = ({
        tipoValor = null,
        tipoInstrumento = null,
        tab = null,
        fechaInicio = null,
        fechaFin = null,
        emisoraSeleccionada = null,
        clave = null,
        subTab = null,
        page = null,
        tipoDerecho = null,
        biva = null,
        canceladas = null,
        reset = false
    }) => {
        this.setState({
            emisoraSeleccionada: emisoraSeleccionada,
            biva: biva, //tab === 1 ? true : false,
            canceladas: canceladas, //tab === 2 ? true : false,
            tipoValor: tipoValor,
            tipoInstrumento: tipoInstrumento,
            clave: clave,
            claveEmisoraSeleccionada: clave,
            tab: tab,
            subTab: subTab,
            activePage: page,
            tabsDeshabilitados: this.state.canceladas === true ? [2] : [],
            tipoDerecho: tipoDerecho,
            indexSelected: subTab !== null ? subTab : tab
        });
        if (reset) {
            this.changeHistoryReset(biva, canceladas, tab);
        } else {
            this.changeHistory(
                tipoValor ? (tipoValor.length > 0 ? tipoValor : null) : null,
                tipoInstrumento,
                tab,
                clave,
                emisoraSeleccionada,
                page,
                subTab,
                tipoDerecho,
                fechaInicio,
                fechaFin,
                biva,
                canceladas //nuevos no se
            );
        }
    };

    onSetInitTabs = (
        index = null,
        emisora = null,
        wasFilteredBySearch = null
    ) => {
        this.setState({
            indexSelected: index,
            emisoraSeleccionada: emisora,
            wasFilteredBySearch: wasFilteredBySearch
        });
    };

    render() {
        let contenidoMostrar = null;
        let contenidoMostrarTab = null;
        let tabMostrar = null;
        let descarga_url = base_url;
        let resultadoDescarga = null;

        descarga_url = `${descarga_url}?biva=${this.state.biva}`;
        descarga_url = `${descarga_url}&canceladas=${this.state.canceladas}`;
        descarga_url = this.state.tipoValor && this.state.tipoValor.length > 0
            ? `${descarga_url}&tipoInstrumento=${this.state.tipoValor}`
            : descarga_url;
        descarga_url = this.state.tipoInstrumento
            ? `${descarga_url}&mercadoPrincipal=${this.state.tipoInstrumento}`
            : descarga_url;

        if (this.props.cotizaciones &&
            this.props.cotizaciones.content &&
            this.props.cotizaciones.content.length > 0) {
            resultadoDescarga = (
                <div className={"row margen_30"}>
                    <div className={[classes.card_header, classes.titulo_60pct].join(" ")}>
                        <span className={["regular montse texto_16 "].join(" ")} />
                    </div>
                    <div className={[classes.card_header2, classes.titulo_40pct].join(" ")}>
                        <span
                            className={[classes.titulo_75pct, "texto_16 montse regular"].join(
                                " "
                            )}
                        >
                            <a
                                href={descarga_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="row"
                            >
                                <span
                                    className={[
                                        classes.titulo,
                                        "texto_16 montse color_azul regular"
                                    ].join(" ")}
                                >
                                    {getLan() === "es" ? "Listado de emisoras" : "Issuers listing"}
                                    <i
                                        className={[
                                            "fas fa-arrow-alt-circle-down fa-lg",
                                            "Boton__icono_derecho__25mg6"
                                        ].join(" ")}
                                    />
                                </span>
                                <span
                                    className={[
                                        classes.titulo_ZIP,
                                        "semi_bold montse texto_10"
                                    ].join(" ")}
                                >
                                    {getLan() === "es" ? "Formato XLS" : "XLS file"}
                                </span>
                            </a>
                        </span>
                    </div>
                </div>
            );
        }
        let loader = null;
        if (this.state.loader) {
            loader = (
                <Loader loaderDisable={disable => this.setState({ loader: disable })} />
            );
        }

        const numRows = this.state.numRows ? this.state.numRows : 0;
        let filtro = null;
        filtro = (
            <div className="margen_top_30">
                <FilterSic
                    itemsPerPage={this.state.itemsPerPage}
                    setTipoInstrumento={(tipoInstrumento, tipo) =>
                        this.onSetTipoInstrumento(tipoInstrumento, tipo)
                    }
                    setTipoValor={tipoValor => this.onSetTipoValor(tipoValor)}
                    setPage={page => this.onSetPage(page)}
                    setEmisora={(emisora, tipo, clave) =>
                        this.onSetEmisora(emisora, tipo, clave)
                    }
                    tab={this.state.tab}
                    setLimpiarFiltros={changeTab => this.onSetLimpiarFiltros(changeTab)}
                    setWasFiltered={wasFiltered => this.setWasFiltered(wasFiltered)}
                    setFilters={filterParams => this.onSetFilters(filterParams)}
                    setInitTabs={(index, emisora, wasFilteredBySearch) =>
                        this.onSetInitTabs(index, emisora, wasFilteredBySearch)
                    }
                    updateEmisoraParent={(id, clave) => this.updateGrandparent(id, clave)}
                    location={this.props.location}
                    claveEmisoraSeleccionada={this.state.claveEmisoraSeleccionada}
                    emisoraSeleccionada={this.state.emisoraSeleccionada}
                />
            </div>
        );
        const cotizaciones = (
            <React.Fragment>
                {/* <Accordion
                    datos={this.state.resourceList}
                    nombre="sic"
                    icon={""}
                    tipo={7}
                    subtipo={3}
                    updateGrandparent={this.updateGrandparent.bind(this)}
                /> */}
                {/*  CARDS */}
                <div className={["row"].join(" ")}>
                    <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                        <div className={[classes.card, "card"].join(" ")}>
                            <img src="https://biva.mx/static/media/logo1.25245d17.png" className="card-img-top" alt="..." />
                            <div className={[classes.card_body, "card-body"].join(" ")}>
                                <h5 className="card-title nunito texto_24 semi-bold">BIVA</h5>
                                <p className="card-text montse texto_16 ">FIBRA</p>
                                <p className="card-text montse texto_16">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <a href="#" className={[classes.button, "btn btn-primary texto_16 montse"].join(" ")}>Conoce más</a>
                            </div>
                        </div>
                    </div>

                    <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                        <div className={[classes.card_2, "card"].join(" ")}>
                            {/* <img src="https://jupiterbootstoacc01.blob.core.windows.net/data-maintenance/biva_1749.png" className="card-img-top" alt="..." /> */}
                            <img src="https://www.fibrahd.com.mx/images/logo.png" className="card-img-top margin_top_5 margin_left_5 margin_derecha_10" alt="..." height="200px" width="200px" />
                            <div className={[classes.card_body, "card-body margin_top_10"].join(" ")}>
                                <h5 className="card-title nunito texto_24 semi-bold">FIBRA HD</h5>
                                <p className={[classes.content_peach, "montse texto_16 texto_centro border_radius5 margen_top_10"].join(" ")}>FIBRA</p>
                                <p className="card-text montse texto_16">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                <a href="#" className={[classes.button, "btn btn-primary texto_16 montse"].join(" ")}>Conoce más</a>
                            </div>
                        </div>
                    </div>

                    <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                        <div className={[classes.card, "card"].join(" ")}>
                            <img src="https://www.fibradanhos.com.mx/assets/img/LOGO.png" className="card-img-top" alt="..." height="215" />
                            <div className={[classes.card_body_sustentable, "card-body"].join(" ")}>
                                <h5 className="card-title nunito texto_24 semi-bold">DANHOS FIBRA</h5>
                                {/* <p className="card-text montse texto_16 ">FIBRA</p> */}
                                <p className="card-text montse texto_14">Monto tentativo a emitir:</p>
                                <p className="card-text montse texto_20">$ 999,123,000.00</p>
                                <p className="card-text montse texto_14">Fecha tentativa de emisión:</p>
                                <p className="card-text montse texto_18">30/11/2020</p>
                                <a href="#" className={[classes.button, "btn btn-primary texto_16 montse"].join(" ")}>Conoce más</a>
                                <p className={[classes.content_peach, "montse texto_16 texto_centro border_radius5 margen_top_10"].join(" ")}>FIBRA</p>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <br />
                <br />
                <br /> */}
                {/* <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}> */}
                <div className={[classes.card_sustentable, "card margen_50"].join(" ")}>
                    <img src="https://www.coxenergy.com/img/logos/logo-footer.svg" className="card-img-top" alt="..." height="215" />
                    <div className={[classes.card_body_sustentable, "card-body"].join(" ")}>
                        <p className={[classes.content_green, "montse texto_16 texto_centro border_radius5"].join(" ")}>ACCIONES</p>
                        <h5 className="card-title nunito texto_24 semi-bold margen_top_10">COX ENERGY</h5>
                        <p className="card-text montse texto_16 ">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a href="#" className={[classes.button, "btn btn-primary montse texto_16"].join(" ")}>Conoce más</a>
                            &nbsp;
                            <i className={[classes.leaf, "fa fa-leaf fa-lg slash_green"].join(" ")} aria-hidden="true"></i>
                    </div>
                </div>

                <Pagination
                    claseTipo={2}
                    numRows={numRows}
                    paginas={5}
                    itemsPerPage={this.state.itemsPerPage}
                    handlePageChange={this.handlePageChange.bind(this)}
                    activePage={this.state.activePage}
                />
            </React.Fragment>
        );
        //Razon Social, Listado
        const sicInfo = (
            <Accordion
                datos={this.state.sicDetail}
                nombre="sic"
                icon={""}
                tipo={8}
                subtipo={11}
                titulo={getLan() === "es" ? "Razón Social" : "Corporate name"}
                titulo_encabezado={getLan() === "es" ? "Importe Emisión" : "Corporate name"}
                titulo_encabezado_2={getLan() === "es" ? "Fecha Emisión" : "Corporate name"}
                titulo_info={this.state.sicDetail ? this.state.sicDetail.razonSocial : null} />
        );

        // SitioWeb, Informacion financiera,Eventos Relevantes
        const sicInfo2 = (
            <Accordion
                datos={this.state.sicDetail}
                nombre="sic"
                icon={""}
                tipo={8}
                subtipo={9}
                titulo={getLan() === "es" ? "Sitio Web " : "Corporate name"}
                titulo_info={this.state.sicDetail ? this.state.sicDetail.sitioWeb : null} />
        );
        // Actividad Economica
        const sicInfo3 = (
            <Accordion
                datos={this.state.sicDetail}
                nombre="sic"
                icon={""}
                tipo={8}
                subtipo={8}
                titulo={getLan() === "es" ? "Historia" : "Corporate name"}
                titulo_info={this.state.nombreCalificadora ? "Historia" : "En aquel tiempo ..."} />
        );

        const sicInfo4 = (
            <Accordion
                datos={this.state.sicDetail}
                nombre="sic"
                icon={""}
                tipo={8}
                subtipo={8}
                titulo={getLan() === "es" ? "Proceso Alta en BIVA" : "Corporate name"}
                titulo_info={this.state.nombreCalificadora ? "Proceso Alta en BIVA" : "Descripción del proceso exitoso de Alta en BIVA"} />
        );

        const sicInfo5 = (
            <Accordion
                datos={this.state.sicDetail}
                nombre="sic"
                icon={""}
                tipo={8}
                subtipo={8}
                titulo={getLan() === "es" ? "Casas de Bolsa Colocadoras" : "Corporate name"}
                titulo_info={this.state.nombreCalificadora ? "Monex" : "Monex"} />
        );

        const sicInfo6 = (
            <Accordion
                datos={this.state.sicDetail}
                nombre="sic"
                icon={""}
                tipo={8}
                subtipo={10}
                titulo={getLan() === "es" ? "Documentos" : "Corporate name"}
            // titulo_info={this.state.nombreCalificadora ? "Formato" :  "Formato" }
            />
        );

        const video = (
            <div className="margin_top_30 width_cien" id="video">
                <iframe
                    width="100%"
                    height="415"
                    src={
                        // "https://www.youtube.com/embed/" + this.state.selectedVideo
                        "https://www.youtube.com/embed/PpZ5ew0DB2U"
                    }
                    frameBorder="0"
                    title="videos"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                />
            </div>
        );

        tabMostrar = (
            <div>
                <div className="row margen_top_10">
                    <br />
                    {/* <div
                        className={[
                            "texto_16 nunito semi_bold",
                            classes.tabsContainer,
                            classes.myCol
                        ].join(" ")}
                    >
                        {getLan() === "es" ? "Clave:" : "Ticker:"}
                        <span
                            className={[
                                "texto_16 nunito semi_bold color_azul",
                                classes.myCol
                            ].join(" ")}
                        >
                            &nbsp;
              {this.state.sicDetail ? this.state.sicDetail.clave : null}
                        </span>
                    </div> */}
                    <div className={["col sin_padding", classes.myCol].join(" ")}>
                        <Tabs
                            key={+new Date()}
                            data={tabsInfo}
                            sub={true}
                            activaTabInicial={this.state.subTab}
                            tabsDesabilitados={this.state.tabsDeshabilitados}
                            tipo={1}
                            clicked={i => this.onChangeItemSelected(i)}
                        />
                    </div>
                </div>
            </div>
        );

        if (
            this.state.indexSelected === 0 &&
            this.state.emisoraSeleccionada === null
        ) {
            contenidoMostrar = (
                <div className="margen_50">
                    {/* {resultadoDescarga} */}
                    <br />
                    {this.props.cotizaciones &&
                        this.props.cotizaciones.content &&
                        this.props.cotizaciones.content.length > 0 ? (
                            cotizaciones
                        ) : (
                            <p className="montse texto_16 regular">
                                {getLan() === "es"
                                    ? "No existen resultados para la búsqueda."
                                    : "No results found."}
                            </p>
                        )}
                </div>
            );

            tabMostrar = null;
            // (
            //     <div className="row margen_top_10">
            //         <div
            //             className={[
            //                 "texto_16 nunito semi_bold",
            //                 classes.tabsContainer,
            //                 classes.myCol
            //             ].join(" ")}
            //         >
            //             {getLan() === "es" ? "Mostrar:" : "View:"}
            //         </div>
            //         <div className={["col sin_padding", classes.myCol].join(" ")}>
            //             <Tabs
            //                 key={"tab2"}
            //                 data={tabsSic}
            //                 activaTabInicial={this.state.indexSelected}
            //                 tipo={1}
            //                 clicked={i => this.onChangeTabInicial(i)}
            //             />
            //         </div>
            //     </div>
            // );
        } else if (
            this.state.indexSelected === 1 &&
            this.state.emisoraSeleccionada === null
        ) {
            contenidoMostrar = (
                <div className="margen_50">
                    {resultadoDescarga}
                    <br />
                    {this.props.cotizaciones &&
                        this.props.cotizaciones.content &&
                        this.props.cotizaciones.content.length > 0 ? (
                            cotizaciones
                        ) : (
                            <p className="montse texto_16 regular">
                                {getLan() === "es"
                                    ? "No existen resultados para la búsqueda."
                                    : "No results found."}
                            </p>
                        )}
                </div>
            );

            tabMostrar = (
                <div className="row margen_top_10">
                    <div
                        className={[
                            "texto_16 nunito semi_bold",
                            classes.tabsContainer,
                            classes.myCol
                        ].join(" ")}
                    >
                        {getLan() === "es" ? "Mostrar:" : "View:"}
                    </div>
                    <div className={["col sin_padding", classes.myCol].join(" ")}>
                        <Tabs
                            key={"tab3"}
                            data={tabsSic}
                            activaTabInicial={this.state.indexSelected}
                            tipo={1}
                            clicked={i => this.onChangeTabInicial(i)}
                        />
                    </div>
                </div>
            );
        } else if (
            this.state.indexSelected === 2 &&
            this.state.emisoraSeleccionada === null
        ) {
            contenidoMostrar = (
                <div className="margen_50">
                    {resultadoDescarga}
                    <br />
                    {this.props.cotizaciones &&
                        this.props.cotizaciones.content &&
                        this.props.cotizaciones.content.length > 0 ? (
                            cotizaciones
                        ) : (
                            <p className="montse texto_16 regular">
                                {getLan() === "es"
                                    ? "No existen resultados para la búsqueda."
                                    : "No results found."}
                            </p>
                        )}
                </div>
            );

            tabMostrar = (
                <div className="row margen_top_10">
                    <div
                        className={[
                            "texto_16 nunito semi_bold",
                            classes.tabsContainer,
                            classes.myCol
                        ].join(" ")}
                    >
                        {getLan() === "es" ? "Mostrar:" : "View:"}
                    </div>
                    <div className={["col sin_padding", classes.myCol].join(" ")}>
                        <Tabs
                            key={"tab4"}
                            data={tabsSic}
                            activaTabInicial={this.state.indexSelected}
                            tipo={1}
                            clicked={i => this.onChangeTabInicial(i)}
                        />
                    </div>
                </div>
            );
        } else if (
            this.state.subTab === 0 &&
            this.state.emisoraSeleccionada !== null
        ) {
            let titulo1 = "Descargar Prospecto";
            let titulo2 = "Descargar Presentación";
            contenidoMostrar = (
                <div className="margen_50">

                    {/* {sicInfo} {/* Razon social, importe, fecha */}
                    {/* {sicInfo2} Sitio Web */}
                    {/* {sicInfo3} Historia */}
                    {/* {sicInfo4} Proceso alta BIVA */}
                    {/* {sicInfo5} Casas Bolsa */}
                    {/* {sicInfo6} Documentos */}
                    {/* {video} */}

                    {/*  CARDS */}
                    {/* <div className={["row"].join(" ")}>
                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                            <div className={[classes.card, "card"].join(" ")}>
                                <img src="https://biva.mx/static/media/logo1.25245d17.png" className="card-img-top" alt="..." />
                                <div className={[classes.card_body, "card-body"].join(" ")}>
                                    <h5 className="card-title nunito texto_24 semi-bold">BIVA</h5>
                                    <p className="card-text montse texto_16 ">FIBRA</p>
                                    <p className="card-text montse texto_16">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className={[classes.button, "btn btn-primary texto_16 montse"].join(" ")}>Conoce más</a>
                                </div>
                            </div>
                        </div>

                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                            <div className={[classes.card, "card"].join(" ")}>
                                <img src="https://www.fibrahd.com.mx/images/logo.png" className="card-img-top" alt="..." height="215" />
                                <div className={[classes.card_body, "card-body"].join(" ")}>
                                    <h5 className="card-title nunito texto_24 semi-bold">FIBRA HD</h5>
                                    <p className={[classes.content_peach, "montse texto_16 texto_centro border_radius5 margen_top_10"].join(" ")}>FIBRA</p>
                                    <p className="card-text montse texto_16">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className={[classes.button, "btn btn-primary texto_16 montse"].join(" ")}>Conoce más</a>
                                </div>
                            </div>
                        </div>

                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                            <div className={[classes.card, "card"].join(" ")}>
                                <img src="https://www.fibradanhos.com.mx/assets/img/LOGO.png" className="card-img-top" alt="..." height="215" />
                                <div className={[classes.card_body_sustentable, "card-body"].join(" ")}>
                                    <h5 className="card-title nunito texto_24 semi-bold">DANHOS FIBRA</h5>
                                    <p className="card-text montse texto_16">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className={[classes.button, "btn btn-primary texto_16 montse"].join(" ")}>Conoce más</a>
                                    <p className={[classes.content_peach, "montse texto_16 texto_centro border_radius5 margen_top_10"].join(" ")}>FIBRA</p>
                                </div>
                            </div>
                        </div>

                    </div> */}

                    {/* <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}> */}

                    {/* <div className={[classes.card_sustentable, "card margen_50"].join(" ")}>
                        <img src="https://www.coxenergy.com/img/logos/logo-footer.svg" className="card-img-top" alt="..." height="215" />
                        <div className={[classes.card_body_sustentable, "card-body"].join(" ")}>
                            <p className={[classes.content_green, "montse texto_16 texto_centro border_radius5"].join(" ")}>ACCIONES</p>
                            <h5 className="card-title nunito texto_24 semi-bold margen_top_10">COX ENERGY</h5>
                            <p className="card-text montse texto_16 ">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="#" className={[classes.button, "btn btn-primary montse texto_16"].join(" ")}>Conoce más</a>
                            &nbsp;
                            <i className={[classes.leaf, "fa fa-leaf fa-lg slash_green"].join(" ")} aria-hidden="true"></i>
                        </div>
                    </div> */}


                    {/* DETALLE */}
                    <div className={["row margin_top_10"].join(" ")}>
                        <div className={["col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                            <div className={[].join(" ")}>
                                <img src="https://www.coxenergy.com/img/logos/logo-header.svg" className="card-img-top" height="200px" width="200px" alt="..." />
                                <div className={[classes.card_header_gray, "card-body"].join(" ")}>
                                    {/* <h5 className="card-title nunito texto_24 semi-bold">Cox Energy América, S.A.B. de C.V.</h5> */}
                                    <a href="https://www.coxenergy.com/en/home" target="_blank" rel="noopener noreferrer">
                                    <h5 className="card-title nunito texto_24 semi-bold">Cox Energy América, S.A.B. de C.V.</h5>
                  </a>
                                </div>
                                <div className={[classes.content_peach, "card-body"].join(" ")}>
                                    <h5 className="nunito texto_16 semi-bold">ACCIONES</h5>
                                </div>
                            </div>
                            <div className={[,].join(" ")}>
                                <div className={["card-body row"].join(" ")}>
                                    <div className={["col-12 col-sm-12 col-md-12 col-xl-12 ", classes.tamaño_titulos,].join(" ")}>
                                        {/* <h5 className="card-title nunito texto_24 semi-bold">Importe</h5> */}
                                        <p className="card-text montse texto_14 semi_bold color_gris">Monto tentativo a emitir :</p>
                                        <p className="card-text montse texto_42 bold color_azul">$ 999,123,000.00</p>
                                        <p className="card-text montse texto_14 semi_bold color_gris">Fecha tentativa de emisión:</p>
                                        <p className="card-text montse texto_24 bold color_azul"> 09/11/2020</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={["col-12 col-sm-12 col-md-12 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                            {video}
                        </div>
                    </div>

                    {/* Importe / Fecha  */}
                    {/* <div className={[classes.content_blue,].join(" ")}>
                        <div className={["card-body row"].join(" ")}>
                        <div className={["col-12 col-sm-12 col-md-12 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                            <h5 className="card-title nunito texto_24 semi-bold">Importe</h5>
                            <p className="card-text montse texto_24 bold ">$ 123,000.00</p>
                        </div>
                        <div className={["col-12 col-sm-12 col-md-12 col-xl-6", classes.tamaño_titulos,].join(" ")}>
                            <h5 className="card-title nunito texto_24 semi-bold">Fecha</h5>
                            <p className="card-text montse texto_24 bold "> 09/11/2020</p>
                        </div>
                        </div>
                    </div> */}

                    {/* { video} */}
                    {/* <div
                        className={[
                            "col-12 col-sm-12 col-md-12 col-xl-6 sin_padding",
                            classes.tamaño_titulos,
                        ].join(" ")}
                    >
                        <div className={classes.poligono} />
                        <div className={classes.contenido_poligono}>
                            <h5 className="card-title nunito texto_24 semi-bold">Historia</h5>
                            <p className="card-text montse texto_16 ">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div> */}

                    {/* Casas Bolsa Colocadoras */}
                    <div className={[classes.content_gray, "margin_top_50"].join(" ")}>

                        <div className={["card-body"].join(" ")}>
                            <h5 className="card-title nunito texto_24 semi-bold">Casas de Bolsa colocadoras</h5>
                            <div className={["row"].join(" ")}>
                                <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                    <p className="card-text montse texto_16 ">Monex</p>
                                </div>
                                <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                    <p className="card-text montse texto_16 ">Contacto: Gustavo Vargas</p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={[classes.content_blue, "texto_24 nunito bold  card-body row margin_top_50"].join(" ")}>
                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                            <div className={["sin_padding no_borders"].join(" ")} id="headingOne">
                                <h2 className={["mb-0 nowrap"].join(" ")}>
                                    <a href={this.props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                                        <div className="card_body">
                                            <span className={["texto_18 montse color_blanco semi_bold"].join(" ")}>{titulo1}</span>
                                            <br />
                                            <span className={"texto_10 montse semi_bold color_blanco"}>
                                                {getLan() === "es"
                                                    ? `Formato ${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()}`
                                                    : `${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()} format`}
                                            </span>
                                        </div>
                                        <div className="">
                                            <i className={["fas fa-arrow-alt-circle-down fa-sm", "Boton__icono_derecho__25mg6"].join(" ")} />
                                        </div>
                                    </a>
                                </h2>
                            </div>
                        </div>
                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"].join(" ")}>
                            <div className={["sin_padding no_borders"].join(" ")} id="headingOne">
                                <h2 className={["mb-0 nowrap"].join(" ")}>
                                    <a href={this.props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                                        <div className="card_body">
                                            <span className={["texto_18 montse color_blanco semi_bold"].join(" ")}>{titulo2}</span>
                                            <br />
                                            <span className={"texto_10 montse semi_bold color_blanco"}>
                                                {getLan() === "es"
                                                    ? `Formato ${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()}`
                                                    : `${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()} format`}
                                            </span>
                                        </div>
                                        <div className="">
                                            <i className={["fas fa-arrow-alt-circle-down fa-sm", "Boton__icono_derecho__25mg6"].join(" ")} />
                                        </div>
                                    </a>
                                </h2>
                            </div>
                        </div>
                    </div>

                    {/* Historia */}
                    <div className={[classes.content_peach_border_right, "margin_top_10"].join(" ")}>

                        <div className={["card-body"].join(" ")}>
                            <h5 className="card-title nunito texto_24 semi-bold texto_azul">Historia</h5>
                            <p className="card-text montse texto_16 texto_gris">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <img src="https://oroinformacion.com/wp-content/uploads/2019/04/paneles-solares-fotovoltaicos-700x375.jpg" className="card-img-top" alt="..." />
                        </div>
                    </div>

                    {/* Proceso Alta BIVA */}
                    <div className={[classes.content_blue_border_right, "margin_top_50"].join(" ")}>

                        <div className={["card-body"].join(" ")}>
                            <h5 className="card-title nunito texto_24 semi-bold texto_azul">Proceso de alta en BIVA</h5>
                            <p className="card-text montse texto_16 texto_gris ">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <img src="https://biva.mx/cms/documents/20123/5120ecd0-a58e-5c96-d645-4354b14cb180" className="card-img-top" alt="..." />
                        </div>
                    </div>

                </div >
            );
            tabMostrar = null;

            {/* DETALLE 2 */ }
            {/* <div className={["row margin_top_300"].join(" ")}>
                        <div className={["col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                            <div className={[].join(" ")}>
                                <img src="https://www.coxenergy.com/img/logos/logo-header.svg" className="card-img-top" height="200px" width="200px" alt="..." />
                                <div className={[classes.card_header_gray, "card-body"].join(" ")}>
                                    <h5 className="card-title nunito texto_24 semi-bold">Cox Energy América, S.A.B. de C.V.</h5>
                                </div>
                                <div className={[classes.content_green, "card-body"].join(" ")}>
                                    <h5 className="nunito texto_16 semi-bold">ACCIONES</h5>
                                </div>
                            </div>
                            <div className={[,].join(" ")}>
                                <div className={["card-body row"].join(" ")}>
                                    <div className={["col-12 col-sm-12 col-md-12 col-xl-12 ", classes.tamaño_titulos,].join(" ")}>
                                        <p className="card-text montse texto_42 bold color_azul">$ 999,123,000.00</p>
                                        <p className="card-text montse texto_24 bold color_gris"> 09/11/2020</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={["col-12 col-sm-12 col-md-12 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                            {video}
                        </div>

                        {contenidoMostrarTab}
                    // </div>
                // </div >

            // );
            (
                <div className={[classes.content_gray, "margin_top_10"].join(" ")}>

                    <div className={["card-body"].join(" ")}>
                        <h5 className="card-title nunito texto_24 semi-bold">Historia</h5>
                        <p className="card-text montse texto_16 ">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div>
            );  */}
        } else if (
            this.state.subTab === 1 &&
            this.state.emisoraSeleccionada !== null
        ) {
            contenidoMostrar = (
                <div className={["row"].join(" ")}>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        <div className={[].join(" ")}>
                            <img src="https://www.coxenergy.com/img/logos/logo-header.svg" className="card-img-top" height="200px" width="200px" alt="..." />
                            <div className={[classes.card_header_gray, "card-body"].join(" ")}>
                                <h5 className="card-title nunito texto_24 semi-bold">Cox Energy América, S.A.B. de C.V.</h5>
                            </div>
                            <div className={[classes.content_green, "card-body"].join(" ")}>
                                <h5 className="nunito texto_16 semi-bold">ACCIONES</h5>
                            </div>
                        </div>
                        <div className={[,].join(" ")}>
                            <div className={["card-body row"].join(" ")}>
                                <div className={["col-12 col-sm-12 col-md-12 col-xl-12 ", classes.tamaño_titulos,].join(" ")}>
                                    {/* <h5 className="card-title nunito texto_24 semi-bold">Importe</h5> */}
                                    <p className="card-text montse texto_14 semi_bold color_gris">Monto tentativo a emitir :</p>
                                    <p className="card-text montse texto_42 bold color_azul">$ 999,123,000.00</p>
                                    <p className="card-text montse texto_14 semi_bold color_gris">Fecha tentativa de emisión:</p>
                                    <p className="card-text montse texto_24 bold color_azul"> 09/11/2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={["col-12 col-sm-12 col-md-12 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        {video}
                    </div>
                </div>
            );
            contenidoMostrarTab = (
                <div className={[classes.content_green_border_right, "margin_top_10"].join(" ")}>

                    <div className={["card-body"].join(" ")}>
                        <h5 className="card-title nunito texto_24 semi-bold texto_azul">Proceso de alta en BIVA</h5>
                        <p className="card-text montse texto_16 texto_gris">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <img src="https://biva.mx/cms/documents/20123/5120ecd0-a58e-5c96-d645-4354b14cb180" className="card-img-top" alt="..." />
                    </div>
                </div>
            );
        } else if (
            this.state.subTab === 2 &&
            this.state.emisoraSeleccionada !== null
        ) {
            contenidoMostrar = (
                <div className={["row"].join(" ")}>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        <div className={[].join(" ")}>
                            <img src="https://www.coxenergy.com/img/logos/logo-header.svg" className="card-img-top" height="200px" width="200px" alt="..." />
                            <div className={[classes.card_header_gray, "card-body"].join(" ")}>
                                <h5 className="card-title nunito texto_24 semi-bold">Cox Energy América, S.A.B. de C.V.</h5>
                            </div>
                            <div className={[classes.content_green, "card-body"].join(" ")}>
                                <h5 className="nunito texto_16 semi-bold">ACCIONES</h5>
                            </div>
                        </div>
                        <div className={[,].join(" ")}>
                            <div className={["card-body row"].join(" ")}>
                                <div className={["col-12 col-sm-12 col-md-12 col-xl-12 ", classes.tamaño_titulos,].join(" ")}>
                                    {/* <h5 className="card-title nunito texto_24 semi-bold">Importe</h5> */}
                                    <p className="card-text montse texto_14 semi_bold color_gris">Monto tentativo a emitir :</p>
                                    <p className="card-text montse texto_42 bold color_azul">$ 999,123,000.00</p>
                                    <p className="card-text montse texto_14 semi_bold color_gris">Fecha tentativa de emisión:</p>
                                    <p className="card-text montse texto_24 bold color_azul"> 09/11/2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={["col-12 col-sm-12 col-md-12 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        {video}
                    </div>
                </div>
            );
            contenidoMostrarTab = (
                <div className={[classes.content_blue_border_right, "margin_top_10"].join(" ")}>

                    <div className={["card-body"].join(" ")}>
                        <h5 className="card-title nunito texto_24 semi-bold texto_azul">Casas de Bolsa colocadoras</h5>
                        <div className={["row"].join(" ")}>
                            <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                <p className="card-text montse texto_16 ">Monex</p>
                            </div>
                            <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                <p className="card-text montse texto_16 ">Contacto: Gustavo Vargas</p>
                                {/* <i class="fas fa-phone"></i> */}
                            </div>
                        </div>
                        <img src="https://oroinformacion.com/wp-content/uploads/2019/04/paneles-solares-fotovoltaicos-700x375.jpg" className="card-img-top" alt="..." />
                    </div>
                </div>
            );
        } else if (
            this.state.subTab === 3 &&
            this.state.emisoraSeleccionada !== null
        ) {
            let titulo1 = "Descargar Prospecto";
            let titulo2 = "Descargar Presentación";
            contenidoMostrar = (
                <div className={["row"].join(" ")}>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        <div className={[].join(" ")}>
                            <img src="https://www.coxenergy.com/img/logos/logo-header.svg" className="card-img-top" height="200px" width="200px" alt="..." />
                            <div className={[classes.card_header_gray, "card-body"].join(" ")}>
                                <h5 className="card-title nunito texto_24 semi-bold">Cox Energy América, S.A.B. de C.V.</h5>
                            </div>
                            <div className={[classes.content_green, "card-body"].join(" ")}>
                                <h5 className="nunito texto_16 semi-bold">ACCIONES</h5>
                            </div>
                        </div>
                        <div className={[,].join(" ")}>
                            <div className={["card-body row"].join(" ")}>
                                <div className={["col-12 col-sm-12 col-md-12 col-xl-12 ", classes.tamaño_titulos,].join(" ")}>
                                    {/* <h5 className="card-title nunito texto_24 semi-bold">Importe</h5> */}
                                    <p className="card-text montse texto_14 semi_bold color_gris">Monto tentativo a emitir :</p>
                                    <p className="card-text montse texto_42 bold color_azul">$ 999,123,000.00</p>
                                    <p className="card-text montse texto_14 semi_bold color_gris">Fecha tentativa de emisión:</p>
                                    <p className="card-text montse texto_24 bold color_azul"> 09/11/2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={["col-12 col-sm-12 col-md-12 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        {video}
                    </div>
                </div>
            );
            contenidoMostrarTab = (
                <div className={[classes.content_blue_border_right, "texto_24 nunito bold  card-body row margin_top_50"].join(" ")}>
                    <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                        <div className={["sin_padding no_borders"].join(" ")} id="headingOne">
                            <h2 className={["mb-0 nowrap"].join(" ")}>
                                <a href={this.props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                                    <div className="card_body">
                                        <span className={["texto_18 montse texto_azul semi_bold"].join(" ")}>{titulo1}</span>
                                        <br />
                                        <span className={"texto_10 montse semi_bold texto_gris"}>
                                            {getLan() === "es"
                                                ? `Formato ${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()}`
                                                : `${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()} format`}
                                        </span>
                                    </div>
                                    <div className="">
                                        <i className={["fas fa-arrow-alt-circle-down fa-sm", "Boton__icono_derecho__25mg6"].join(" ")} />
                                    </div>
                                </a>
                            </h2>
                        </div>
                    </div>
                    <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"].join(" ")}>
                        <div className={["sin_padding no_borders"].join(" ")} id="headingOne">
                            <h2 className={["mb-0 nowrap"].join(" ")}>
                                <a href={this.props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                                    <div className="card_body">
                                        <span className={["texto_18 montse texto_azul semi_bold"].join(" ")}>{titulo2}</span>
                                        <br />
                                        <span className={"texto_10 montse semi_bold texto_gris"}>
                                            {getLan() === "es"
                                                ? `Formato ${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()}`
                                                : `${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()} format`}
                                        </span>
                                    </div>
                                    <div className="">
                                        <i className={["fas fa-arrow-alt-circle-down fa-sm", "Boton__icono_derecho__25mg6"].join(" ")} />
                                    </div>
                                </a>
                            </h2>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages}>
                <React.Fragment>
                    {loader}

                    <h1 className={"texto_42 nunito regular"}>
                        <FormattedHTMLMessage
                            id="empresas.sic.titulo"
                            defaultMessage=" Próximas Colocaciones"
                        />
                        {/* 
          Sistema internacional de cotizaciones (SIC) */}
                    </h1>
                    <div className="margen_50 texto_16 montse regular">
                        <FormattedHTMLMessage
                            id="empresas.sic.introduccion"
                            defaultMessage="Selecciona el perfil de una emisora para consultar su información general y documentos"
                        />

                        {/* Selecciona el perfil de una emisora para consultar su informacion
          general, documentos y agenda de derechos. */}
                    </div>
                    {filtro}

                    <br />
                    {/* {tabMostrar} */}
                    <br />
                    <div className="margen_50">
                        {contenidoMostrar}
                        {tabMostrar}
                        {contenidoMostrarTab}
                    </div>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitSic: (
            size,
            page,
            biva,
            canceladas,
            tipoValor,
            mercadoPrincipal,
            emisora,
            clave
        ) => {
            dispatch(
                actions.initSic(
                    size,
                    page,
                    biva,
                    canceladas,
                    tipoValor,
                    mercadoPrincipal,
                    emisora,
                    clave
                )
            );
        },
        onInitEmisorasDetail: empresaId => {
            dispatch(actions.initEmisorasDetail(empresaId));
        },
        onInitIntl: () => {
            dispatch(actions.initIntl(54));
        }
    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        cotizaciones: state.emisoras.cotizaciones,
        sicDetail: state.emisoras.emisoraDetalle
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NuevaSeccion));

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  requisitos: null,
  emisoras_requisitoss: null,
  periodos_requisitos: null,
  capital_social: null
};

const setRequisitos = (state, action) => {
  return updateObject(state, action);
};

const setEmisorasRequisitos = (state, action) => {
  return updateObject(state, action);
};

const setPeriodosRequisitos = (state, action) => {
  return updateObject(state, action);
};

const setCapitalSocial = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REQUISITOS_SUCCESS:
      return setRequisitos(state, action);
    case actionTypes.FETCH_EMISORASREQUISITOS_SUCCESS:
      return setEmisorasRequisitos(state, action);
    case actionTypes.FETCH_PERIODOSREQUISITOS_SUCCESS:
      return setPeriodosRequisitos(state, action);
    case actionTypes.FETCH_CAPITALSOCIAL_SUCCESS:
      return setCapitalSocial(state, action);
    default:
      return state;
  }
};

export default reducer;

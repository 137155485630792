import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";
const initialState = {
    acercade: null,
    cuotas: null,
    cuotasApi: null,
    apis: null,
    logo: null
};

const setCloud = (state, action) => {
    return updateObject(state, action);
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACERCADE_SUCCESS:
            return setCloud(state, action);
        case actionTypes.FETCH_CUOTAS_CLOUD_SUCCESS:
            return setCloud(state, action);
        case actionTypes.FETCH_CUOTAS_API_CLOUD_SUCCESS:
            return setCloud(state, action);
        case actionTypes.FETCH_APIS_SUCCESS:
            return setCloud(state, action);
        case actionTypes.FETCH_LOGO_SUCCESS:
            return setCloud(state, action);
        default:
            return state;
    }
};

export default reducer;
import React, { Component } from "react";
import classes from "./SliderDocument.css";
import Modelo1 from "./modelo1/Modelo1";
import Modelo2 from "./modelo2/Modelo2";
import Cursos from "./cursos/Cursos";
import EspacioPublicitario from "./espacioPublicitario/EspacioPublicitario";
import Cuotas from "./cuotas/Cuotas";

class SliderDocument extends Component {
  componentDidMount() { }

  render() {
    if (this.props.tipo === 1) {

      if (this.props.datos !== null) {
        return (
          <div className={classes.fondo}>
            <Modelo1
              datos={this.props.datos}
              tipo={this.props.tipo}
              tipo_imagen={this.props.tipo_imagen}
              titulo_slider={this.props.titulo_slider}
            />
          </div>
        );

      }
      return null;


    } else if (this.props.tipo === 2) {
      if (this.props.datos !== null) {
        return (
          <div className={classes.fondo}>
            <Modelo2 datos={this.props.datos} />
          </div>
        );

      }
      return null;
    } else if (this.props.tipo === 3) {
      let cursos_ = null;
      if (this.props.datos) {
        // cursos_ = this.props.datos.map((item, i) => {
        return (
          <div className="margen_top_20">
            <div className="">
              <div className={classes.fondo}>
                <Cursos datos={this.props.datos} indice={this.props.indice} />
              </div>
            </div>
          </div>
        );
        // });
      }
      return <div>{cursos_}</div>;
    }
    else if (this.props.tipo === 4) {
      // ESPACIO PUBLICITARIO HOME
      let cursos_ = null;
      if (this.props.datos) {
        // cursos_ = this.props.datos.map((item, i) => {
        return (
          <div className="margen_top_20">
            <div className={classes.fondo2}>
              <EspacioPublicitario datos={this.props.datos} indice={this.props.indice} />
            </div>
          </div>
        );
        // });
      }
      return <div>{cursos_}</div>;
    }
    else if (this.props.tipo === 5) {
      if (this.props.datos !== null) {
        return (
          <div className={classes.fondoCuotas}>
            <Cuotas
              datos={this.props.datos}
              tipo={this.props.tipo}
              tipo_imagen={this.props.tipo_imagen}
              titulo_slider={this.props.titulo_slider}
              banner={this.props.banner}
              justificado={this.props.justificado}
            />
          </div>
        );

      }
      return null;
    }

  }
}

export default SliderDocument;

import React, { Component } from "react";
import * as action from "../../../../stores/actions/index";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import classes from "./SalaPrensa.css";
import Box from "../../../../components/ui/box/Box";
import { getRealDateMillies, getUrlDocument, getLan } from "../../../../stores/utils/utilities";
import { getLocal } from "../../../../stores/utils/utilities";
import Loader from "../../../../components/loader/Loader";

class SalaPrensa extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    loader: false,
    eventos: null,
  };
  componentDidMount() {
    if (this.props.eventoId && this.props.salaPrensa) {
      let eventos = this.props.salaPrensa.find(itm => itm.id === parseInt(this.props.eventoId))
      this.setState({
        salaPrensa: eventos
      })
    }
  }

  componentDidUpdate(prevProps) {
   
    if (this.props.eventoId !== prevProps.eventoId) {
      window.scrollTo(0, 0);

    }
    if (this.props.salaPrensa !== prevProps.salaPrensa) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    let descarga = null;
    var prensa = null;
    let fecha = null;
    let urlImagen = null;
    let title = null;
    let shortDescription = null;
    if (this.state.salaPrensa !== null && this.state.salaPrensa !== undefined) {
      prensa = this.state.salaPrensa;
      shortDescription = prensa.shortDescription;
      title = prensa.title;
      urlImagen = getUrlDocument(prensa.pictureRelativeUrl);
      fecha = (
        <FormattedDate value={getRealDateMillies(prensa.creationDateInMillis)} year="numeric" month="long" day="2-digit" />
      );

      const link = [
        {
          leftIcon: "far fa-file-pdf",
          title: getLan() === "es" ? "Descarga el boletín" : "Download the newsletter",
          subtitle:  getLan() === "es" ? "Formato PDF" : "PDF format",
          rightIcon: "fas fa-arrow-down",
          url: getUrlDocument(prensa.documentRelativeUrl)
        }
      ];
      descarga = <Box modelo="modelo_1" data={link[0]} />;
      let loader = null;
      if (this.state.loader) {
        loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
      }
      return (
          <React.Fragment>
            {loader}
            <div className={this.state.loader ? "d-none" : null}>
              <div className={[classes.regresar_div, "cursor"].join(" ")}>
                <div className={classes.centrar_div}>
                  <div onClick={e => this.props.eventSelected(null)}>
                    <div className={[classes.icono_regresar_div].join(" ")}>
                      <i className={["fas fa-arrow-circle-left color_azul", classes.icono2].join(" ")} />
                    </div>
                    <div className={[classes.texto_regresar_div].join(" ")}>
                      <span className={["color_azul texto_14 montse negrita"].join(" ")}>
                        {getLan() === "es" ? "Regresar a prensa" : "Return"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <img className={classes.imagen} src={urlImagen} alt={title} />
              <div className={[classes.titulo_div].join(" ")}>
                <span className={["color_azul texto_30", "slash"].join(" ")}>/</span>
                <span className={["nunito texto_30 regular"].join(" ")}>{title}</span>
              </div>
              <br />

              <div className={[classes.div_contenido].join(" ")}>
                <span className={"color_azul nunito semi_bold texto_16"}>
                {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                </span>
                <p className={"montse texto_16 regular"}>{fecha}</p>
              </div>
              <div className={[classes.div_contenido].join(" ")}>
                <span className={"color_azul nunito semi_bold texto_16"}>
                  {getLan() === "es" ? "Descripción" : "Description"}
                </span>
                <p className={["montse texto_16 regular"]} dangerouslySetInnerHTML={{ __html: shortDescription }} />
              </div>
              {descarga}
            </div>
          </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    salaPrensa: state.salaPrensa.salaPrensa,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitSalaPrensaById: id => {
      dispatch(action.initSalaPrensaById(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalaPrensa);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";

import classes from "./Empresas.css";
import NotFound from "../../components/notFound/NotFound";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";
import DocumentMeta from "react-document-meta";
// paginas
import Listarse from "./listarse/Listarse";
import ProcesoListarse from "./comoListarse/procesoListado/ProcesoListado";
import Requisitos from "./comoListarse/requisitosListado/RequisitosListarse";
import FasesIpo from "./comoListarse/fasesIpo/FasesIpo";
import CambioListado from "./cambioListado/CambioListado";
import Colocaciones from "./colocaciones/Colocaciones";
import AccesoDiv from "./accesoDiv/AccesoDiv";
import SeguimientoSolicitudes from "./tramitesListado/seguimientoSolicitudes/SeguimientoSolicitudes";
import AvisosOfertaPublica from "./tramitesListado/avisosOfertaPublica/AvisosOfertaPublica";
import DocumentosDefinitivos from "./tramitesListado/documentosDefinitivos/DocumentosDefinitivos";
import Cuotas from "./cuotas/Cuotas";
import ProspectosColocacion from "./tramitesListado/prospectosColocacion/ProspectosColocacion";
import Preliminares from "./tramitesListado/prospectosColocacion/preliminares/Preliminares"
import { getLan } from "../../stores/utils/utilities";

const metaEs = {
  title: "Portal BIVA - Listarse en BIVA",
  description:
    "Selecciona el perfil de una emisora para consultar su información general, cotización, documentos y valores listados. Consulta los documentos enviados a BIVA por las emisoras y otros participantes del mercado de valores. Selecciona el perfil de una emisora para consultar su información general, documentos y valores listados. Anualmente verificamos que las emisoras de acciones o títulos de crédito que las representen (excepto SAPIB) cumplan con los requisitos establecidos en el Reglamento interior de BIVA. BIVA cuenta con un sistema de divulgación de información de valores (DIV) a través del cual los participantes del mercado pueden realizar sus solicitudes de trámites de listado, así como dar cumplimiento a las obligaciones de mantenimiento. Damos a conocer los instrumentos y los tiempos de respuesta para las solicitudes del listado presentadas en forma electrónica a través del sistema de divulgación de información (DIV).",
  meta: {
    name: {
      keywords:
        "proceso de listado, compañias, inscritas, requisitos de listado, fases IPO, tramite de listado, seguimiento de solitudes, banco de información, agenda de derechos, sic, participacion, emisión, calificadoras, requisitos, mantenimiento, listado, listado de valores, proceso de listado, requisitos, cuotas, fases, ipo, tramites, trámites, prospectos, colocación, avisos, ofertas, ofertas públicas, div, fondos, siefores, eventos, relevantes, concentrado, concentrado de precios, información, corporativa",
    },
  },
};

const metaEn = {
  title: "Portal BIVA - Listing in BIVA",
  description:
    "Select the issuer profile to consult general information, quotation, documents and listed securities. Consult documents sent to BIVA by issuers and other market participants. Select the issuer profile to consult general information, quotation, documents and listed securities. Annually we verify that listed issuers of shares or credit instruments they represent (with the exception of Stock Investment Promotion Companies, SAPIB) comply with the listing requirements set forth by BIVA's internal regulation. BIVA has a system for the disclosure of securities' information (DIV) through which market participants make their requests for listing, as well as complying with maintenance obligations. We publish the times we take to comment on the electronic documents through the DIV system.",
  meta: {
    name: {
      keywords:
        "Listing process, companies, HOW TO GET LISTED?, Listing requirements, IPO Stagesphases, switches, issuance, rating, agencies, maintance requirements, securities listing, listing, listed, process, requirements, fees, ipo, stagesphases, switches, applications, prspectus, public, offerings, div, mutual funds, daily, prices, historical, information, siedores,relevant, events",
    },
  },
};

class Listado extends Component {
  state = {
    menu: null,
    id_menu_activo: null,
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage: "listado_de_valores",
    };

    this.props.onInitGenericos(params);
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu,
      });
    } else {
      this.setState({
        id_menu_activo: menu,
      });
    }
  };

  render() {
    const routes = (
      <Switch>
        <Route
          path="/listado_de_valores"
          exact
          component={Listarse}
        />
        <Route
          path="/listado_de_valores/por_que_listarse"
          exact
          component={Listarse}
        />

        <Route path="/listado_de_valores" exact component={ProcesoListarse} />
        <Route
          path="/listado_de_valores/como_listarse"
          exact
          component={ProcesoListarse}
        />
        <Route
          path="/listado_de_valores/como_listarse/proceso_de_listado"
          exact
          component={ProcesoListarse}
        />
        <Route
          path="/listado_de_valores/como_listarse/requisitos_de_listado"
          exact
          component={Requisitos}
        />
        <Route
          path="/listado_de_valores/como_listarse/fases_ipo"
          exact
          component={FasesIpo}
        />
        <Route
          path="/listado_de_valores/como_listarse/cuotas"
          exact
          component={Cuotas}
        />
        <Route
          path="/listado_de_valores/cambio_de_listado"
          exact
          component={CambioListado}
        />
        <Route
          path="/listado_de_valores/colocaciones"
          exact
          component={Colocaciones}
        />
        <Route
          path="/listado_de_valores/tramites_de_listado"
          exact
          component={SeguimientoSolicitudes}
        />
        <Route
          path="/listado_de_valores/tramites_de_listado/seguimiento_a_solicitudes"
          exact
          component={SeguimientoSolicitudes}
        />
        <Route
          path="/listado_de_valores/tramites_de_listado/prospectos_de_colocacion"
          exact
          component={ProspectosColocacion}
        />
        <Route
          path="/listado_de_valores/tramites_de_listado/prospectos_de_colocacion/preliminares"
          exact
          component={Preliminares}
        />
         <Route
          path="/listado_de_valores/tramites_de_listado/prospectos_de_colocacion/definitivos"
          exact
          component={ProspectosColocacion}
        />
        <Route
          path="/listado_de_valores/tramites_de_listado/avisos_de_ofertas_publicas"
          exact
          component={AvisosOfertaPublica}
        />
        <Route
          path="/listado_de_valores/tramites_de_listado/documentos_definitivos"
          exact
          component={DocumentosDefinitivos}
        />
        <Route
          path="/listado_de_valores/acceso_al_sistema_div"
          exact
          component={AccesoDiv}
        />
        <Route component={NotFound} />
      </Switch>
    );
    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
        <div className="container">
          <div className="row">
            <div className={["d-none"].join(" ")}>
              <Sidebar
                classIcon={["fas fa-gavel"].join(" ")}
                setMenu={(menu, tipo) => this.onSetMenu(menu, tipo)}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30 padding_bottom_200">
              <div className="row margin_bottom_30">
                <div className="col-12 sin_padding_L">
                  <div className={classes.breadcums}>
                    <p>
                      <Breadcrum
                        idPage={this.state.id_menu_activo}
                        menu={this.state.menu}
                      />
                    </p>
                  </div>
                </div>
              </div>
              {routes}
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitGenericos: (params) => {
      dispatch(action.initGenericos(params));
    },
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
  };
};

export default connect(null, mapDispatchToProps)(Listado);

import React, { Component } from "react";
import classes from "./Conectividad.css";
import Accordion from "../../../components/ui/accordion/Accordion";

import Auxs from "../../../hoc/auxs/Auxs";
import icono1 from "../../../assets/icons/conectividad1.svg";
import icono2 from "../../../assets/icons/conectividad2.svg";
import icono3 from "../../../assets/icons/conectividad3.svg";

import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Conectividad extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let topel = null;
    let fix = null;
    let ouch = null;

    if (this.props.tOpel) {
      topel = (
        <div className={[" margen_top_50"].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.tOpel}
            nombre={"opel"}
            icon={""}
            imagen_icono={"lap1.svg"}
            tipo={6}
            titulo_encabezado_estatico={"T- OPEL"}
          />
        </div>
      );
    }
    if (this.props.fix) {
      fix = (
        <div className={[" margen_top_50"].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.fix}
            nombre={"FIX"}
            icon={""}
            imagen_icono={"lap2.svg"}
            tipo={6}
            titulo_encabezado_estatico={"FIX"}
          />
        </div>
      );
    }
    if (this.props.ouch) {
      ouch = (
        <div className={[" margen_top_50 margin_bottom_100"].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.ouch}
            nombre={"OUCH"}
            icon={""}
            imagen_icono={"lap3.svg"}
            tipo={6}
            titulo_encabezado_estatico={"OUCH"}
          />
        </div>
      );
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="margin_bottom_200">
            <div className="row">
              <div className="">
                <div>
                  <h1 className={["nunito", "texto_42"].join(" ")}>
                    <FormattedHTMLMessage id="operaciones.conectividad.titulo1" defaultMessage=" " />
                  </h1>
                </div>
              </div>
            </div>
            <div className="row margen_top_30">
              <span className={"texto_16 montse regular"}>
                <FormattedHTMLMessage id="operaciones.conectividad.introduccion1" defaultMessage=" " />
              </span>
            </div>
            <div className="row margen_top_30">
              <div className={classes.icono}>
                <img src={icono1} alt="conectividad" />
              </div>
              <div className={classes.texto_icono}>
                <span className={"texto_16 montse regular"}>
                  <FormattedHTMLMessage id="operaciones.conectividad.medio1" defaultMessage=" " />
                </span>
              </div>
            </div>
            <div className="row margen_top_30">
              <div className={classes.icono}>
                <img src={icono2} alt="conectividad" />
              </div>
              <div className={classes.texto_icono}>
                <span className={"texto_16 montse regular"}>
                  <FormattedHTMLMessage id="operaciones.conectividad.medio2" defaultMessage=" " />
                </span>
              </div>
            </div>
            <div className="row margen_top_30">
              <div className={classes.icono}>
                <img src={icono3} alt="conectividad" />
              </div>
              <div className={classes.texto_icono}>
                <span className={"texto_16 montse regular"}>
                  <FormattedHTMLMessage id="operaciones.conectividad.medio3" defaultMessage=" " />
                </span>
              </div>
            </div>
            <div className={"accordion"} id={"accordion"}>
              {topel}
              {fix}
              {ouch}
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    tOpel: state.accordion.tOpel,
    fix: state.accordion.fix,
    ouch: state.accordion.ouch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(35));
      dispatch(action.getAccordionTOpel());
      dispatch(action.getAccordionFix());
      dispatch(action.getAccordionOuch());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conectividad);

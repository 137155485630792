import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Home2.css";
import * as action from "../../stores/actions/index";
import Breadcrumb from "../../components/ui/breadcrum/Breadcrum";
import Banner from "../../components/banner/Banner";
import HomeLinks from "../../components/ui/homeLinks/HomeLinks";
import Boletin from "../../components/ui/boletin/Boletin";
import { getLocal, getLan } from "../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import DocumentMeta from "react-document-meta";
import Auxs from "../../hoc/auxs/Auxs";
import TabsVideos from "../../components/ui/tabsVideos/TabsVideos";
import TiempoReal from "../../components/ui/tiempoReal/TiempoReal"

const metaEs = {
  title: "Portal BIVA - Bolsa Institucional de Valores",
  description:
    "Bolsa Institucional de Valores, comúnmente conocida como BIVA, es la segunda bolsa de valores en México, ubicada en Ciudad de México. BIVA comenzó operaciones el 25 de julio de 2018 y opera los mismos instrumentos de la otra bolsa en México, la Bolsa Mexicana de Valores: acciones, deudas, garantías y algunos instrumentos mexicanos específicos como CKDs y FIBRAs. BIVA tiene como propósito contribuir al crecimiento del mercado de valores a través de la innovación, el uso de tecnología de punta y mayor accesibilidad. Atrayendo un mayor número de participantes.",
  meta: {
    name: {
      keywords:
        "biva, bolsa de valores, ckds, CKDs, fibras, FIBRAs, acciones, Acciones, deudas, garantías, bolsa valores, Bolsa valores mexico, méxico, bolsa, valores, bmv, nueva, nueva bolsa de valores, la mejor bolsa de valores, invierte, invertir, mercado, mercado de valores, beneficios, oferta, innovación, tecnología, operación, operaciones, bursatil, bursatiles, índices, bim, plataforma BIM",
    },
  },
};
const metaEn = {
  title: "Portal BIVA - Bolsa Institucional de Valores",
  description:
    "BIVA's goal is to contribute to the growth of the stock exchange through innovation, state-of-the-art technology, and accessibility. It seeks to attract a greater number of participants.",
  meta: {
    name: {
      keywords:
        "biva, stock exchange, ckds, CKDs, fibras, FIBRAs, stocks, shares, debts, guarantees, stock exchange, stock exchange mexico, mexico, stock exchange, bmv, new, new stock exchange, the best stock exchange, invest, invest, market, stock market, benefits, offer, innovation, technology, operation, operations, stock market, stock market, indices, bim, BIM platform",
    },
  },
};

class Home2 extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    diccionarioHome: null,
  };

  componentDidMount() {
    this.setState({
      diccionarioHome: null,
    });
    const params = { showCarousel: true, showButtonCarousel: true };
    this.props.onInitGenericos(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionarioHome !== this.state.diccionarioHome) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionarioHome) {
        const messages = defineMessages(this.props.diccionarioHome);
        this.setState({
          diccionarioHome: this.props.diccionarioHome,
          messages: messages,
        });
      }
    }
  }

  render() {
    let podcast = null;
    if(this.state.messages && this.state.messages[
      "podcast"
    ]) {
      podcast = <div className={classes.nombre}  dangerouslySetInnerHTML={{ __html:  this.state.messages[
      "podcast"
    ] }} />
    }
    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
        <IntlProvider
          locale={this.state.lan}
          messages={this.state.messages}
          defaultMessage=" "
        >
          <React.Fragment>
            <div className={classes.fondo}>
              <Banner />
              <Breadcrumb />
              <div id="video"/>
              <HomeLinks />
              {podcast}
              <div className="margen_top_80, container">
                {/* <Informativo
                  tab1={"ESPACIO BIVA"}
                  tab2={"BIBLIOTECA DIGITAL"}
                  tipo={1}
                /> */}
                {/* <EspacioBiva />  */}
                <Auxs>
                  <div className="row">
                    <div className="col sin_padding_L">
                      <h1 className={["nunito", "texto_42"].join(" ")}>
                        <FormattedHTMLMessage id="invierte.espaciobiva.titulo" defaultMessage=" " />
                      </h1>
                    </div>
                  </div>
                  <div className="margen_top_50">
                    < div className="row margen_top_30">
                      <div className={["col sin_padding", classes.myCol].join(" ")} >
                        <TabsVideos history={this.props.history} location={this.props.location} maxResults={50} itemsPerPage={12} />
                      </div>
                    </div>
                  </div>
                </Auxs>
              </div>

              <div className={["margen_top_50 margin_bottom_50", classes.indices].join(" ")}>
                <TiempoReal />
              </div>

              <Boletin /> 
             
            </div>
          </React.Fragment>
        </IntlProvider>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitGenericos: (params) => {
      dispatch(action.initGenericos(params));
      dispatch(action.getTabsCategorias());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    diccionarioHome: state.intl.diccionarioHome,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home2);

import React, { Component } from "react";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Cuadricula from "../../../components/ui/cuadricula/Cuadricula";
import Boton from "../../../components/ui/boton/Boton";
import CuadroOpel from "../../../components/ui/cuadroOpel/CuadroOpel";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class ModelosNegociacion extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
    this.props.onCuadriculas();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let cuadrosOpel = null;
    if (this.props.opel) {
      cuadrosOpel = this.props.opel.map(item => {
        let cuadro = (
          <CuadroOpel
            icono={item.imagen}
            titulo={item.titulo_encabezado}
            texto={item.texto_html}
            textoArriba={item.subtitulo}
          />
        );
        return cuadro;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div>
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <span className={"texto_16 montse regular"}>
              <FormattedHTMLMessage id="operaciones.modelosdenegociacion.introduccion1" defaultMessage=" " />
            </span>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row width_cien">
                <Cuadricula
                  datos={this.props.beneficios}
                  coloreados={[]}
                  saltarse={[]}
                  enBlanco={[]}
                  quitar={[]}
                  modulo={2}
                  tipo={1}
                />
            </div>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.subtitulo2" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row">
              <span className={"texto_16 montse regular"}>
                <FormattedHTMLMessage id="operaciones.modelosdenegociacion.introduccion2" defaultMessage=" " />
              </span>
            </div>
            <div className="row margen_top_30">{cuadrosOpel}</div>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.subtitulo3" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row margin_bottom_200">
              <div className={""}>
                <Boton texto={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.boton.cuotas"] : " "} 
                tipo={2} 
                ruta={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.boton.cuotas.enlace"] : " "} />
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    opel: state.cuadriculas.opel,
    beneficios: state.cuadriculas.beneficiosModelos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(28));
    },
    onCuadriculas: () => {
      dispatch(action.initBoxBeneficiosModelosNegocio());
      dispatch(action.initBoxOpel());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelosNegociacion);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  mercados: null,
  instrumentos: null,
  claves: null
};

const setProspectos = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MERCADOPROSPECTOS_SUCCESS:
      return setProspectos(state, action);
    case actionTypes.FETCH_INSTRUMENTOSPROSPECTOS_SUCCESS:
      return setProspectos(state, action);
    default:
      return state;
  }
};

export default reducer;

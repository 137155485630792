import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();


const setInitBanner = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANNER_SUCCESS,
      banner: null
    };
  }
  return {
    type: actionTypes.FETCH_BANNER_SUCCESS,
    banner:data
  };
};

export const initBanner = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/content/banner/lang/"+lang)
      .then(response => {
        dispatch(setInitBanner(response.data));
      })
      .catch(err => dispatch(setInitBanner(err.data)));
  };
};

import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getLan, getKeyYoutube } from "../utils/utilities";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;
const contexto = "/cms/o/documents";
const lang = getLan();

// =========================================================================

//biva-sostenible/biblioteca-digital/bibliografia
// =========================================================================

const setBibliografia = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BIBLIOGRAFIA_SUCCESS,
      bibliografia: [],
    };
  }
  return {
    type: actionTypes.FETCH_BIBLIOGRAFIA_SUCCESS,
    bibliografia: data,
  };
};

export const getBibliografia = (params = null) => {
  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}${contexto}/bibliografia-biva-sostenible/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url + queryString)
      .then((response) => {
        dispatch(setBibliografia(response.data));
      })
      .catch((err) => dispatch(setBibliografia(err.data)));
  };
};

// =========================================================================

const getImagenesAcuerdos = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACUERDOSIMAGENES_SUCCESS,
      acuerdos_img: null,
    };
  }
  return {
    type: actionTypes.FETCH_ACUERDOSIMAGENES_SUCCESS,
    acuerdos_img: data,
  };
};

export const getImgAcuerdos = () => {
  let url = `${dominio}${contexto}/acuerdos-biva-sostenible/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(getImagenesAcuerdos(response.data));
      })
      .catch((err) => dispatch(getImagenesAcuerdos(err.data)));
  };
};

const setCitaMaria = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACUERDOSIMAGENES_SUCCESS,
      cita_maria: null,
    };
  }
  return {
    type: actionTypes.FETCH_ACUERDOSIMAGENES_SUCCESS,
    cita_maria: data,
  };
};

export const getCitaMaria = () => {
  let url = `${dominio}${contexto}/cita-bivasg/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setCitaMaria(response.data));
      })
      .catch((err) => dispatch(setCitaMaria(err.data)));
  };
};

const setBannerBiblioteca = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACUERDOSIMAGENES_SUCCESS,
      bannerBiblioteca: null,
    };
  }
  return {
    type: actionTypes.FETCH_ACUERDOSIMAGENES_SUCCESS,
    bannerBiblioteca: data,
  };
};

export const getBannerBibliotecaAsg = () => {
  let url = `${dominio}${contexto}/banner-biblioteca-bivasg/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setBannerBiblioteca(response.data));
      })
      .catch((err) => dispatch(setBannerBiblioteca(err.data)));
  };
};

const setBannerASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANNERASG_SUCCESS,
      banner: null,
    };
  }
  return {
    type: actionTypes.FETCH_BANNERASG_SUCCESS,
    banner: data,
  };
};

export const getBannerAsg = () => {
  let url = `${dominio}${contexto}/banner-bivasg/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setBannerASG(response.data));
      })
      .catch((err) => dispatch(setBannerASG(err.data)));
  };
};

const setBannerAcerca360 = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANNER360_SUCCESS,
      banner360: null,
    };
  }
  return {
    type: actionTypes.FETCH_BANNER360_SUCCESS,
    banner360: data,
  };
};

export const getBannerAcerca360 = () => {
  let url = `${dominio}${contexto}/banner-biva360/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setBannerAcerca360(response.data));
      })
      .catch((err) => dispatch(setBannerAcerca360(err.data)));
  };
};

const setButton360 = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BUTTONS360_SUCCESS,
      botones360: null,
    };
  }
  return {
    type: actionTypes.FETCH_BUTTONS360_SUCCESS,
    botones360: data,
  };
};

export const getButton360 = () => {
  let url = `${dominio}${contexto}/enlaces-biva360/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setButton360(response.data));
      })
      .catch((err) => dispatch(setButton360(err.data)));
  };
};

const setTogglesAliadosSoluciones = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ALIADOS_SOLUCIONES_SUCCESS,
      aliadosSoluciones: [],
    };
  }
  return {
    type: actionTypes.FETCH_ALIADOS_SOLUCIONES_SUCCESS,
    aliadosSoluciones: data,
  };
};

export const getTogglesAliadosSoluciones = (page) => {
  let url = `${dominio}${contexto}/${page}-bivasg/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setTogglesAliadosSoluciones(response.data));
      })
      .catch((err) => dispatch(setTogglesAliadosSoluciones(err.data)));
  };
};

const setEsg = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ESG_SUCCESS,
      esgContainers: [],
    };
  }
  return {
    type: actionTypes.FETCH_ESG_SUCCESS,
    esgContainers: data,
  };
};

export const getEsgContainers = (page) => {
  let url = `${dominio}${contexto}/${page}-biva360/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setEsg(response.data));
      })
      .catch((err) => dispatch(setEsg(err.data)));
  };
};

const setReporteo = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_REPORTEO_SUCCESS,
      reporteo: [],
    };
  }
  return {
    type: actionTypes.FETCH_REPORTEO_SUCCESS,
    reporteo: data,
  };
};

export const getPlataformaReporteo = () => {
  let url = `${dominio}${contexto}/plataforma-esg/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setReporteo(response.data));
      })
      .catch((err) => dispatch(setReporteo(err.data)));
  };
};

const setAcuerdosFirmas = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACUERDOS_FIRMAS_SUCCESS,
      acuerdosFirmas: [],
    };
  }
  return {
    type: actionTypes.FETCH_ACUERDOS_FIRMAS_SUCCESS,
    acuerdosFirmas: data,
  };
};

export const getAcuerdosFirmas = () => {
  let url = `${dominio}${contexto}/acuerdos-biva-sostenible/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setAcuerdosFirmas(response.data));
      })
      .catch((err) => dispatch(setAcuerdosFirmas(err.data)));
  };
};

// =========================================================================
//EMISIONES ASG
// =========================================================================
export const filterEmisionesASG = ({
  size,
  page,
  // instrumento,
  modoListado,
  // sector,
  // estado,
  tipoBono,
  emisora,
  claveResultados,
}) => {
  let url = `${dominio}/emisiones/emisiones-asg/listado?size=${size}&page=${page}`;
  url =
    modoListado && modoListado !== null
      ? `${url}&idModoListado=${modoListado}`
      : url;
  url =
    tipoBono && tipoBono.length > 0
      ? `${url}&idTipoBono=${[...tipoBono]}`
      : url;
  url = emisora ? `${url}&idEmisora=${emisora}` : url;
  url = claveResultados ? `${url}&clave=${claveResultados}` : url;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setEmisionesASG(response.data));
      })
      .catch((err) => dispatch(setEmisionesASG(err.data)));
  };
};

const setEmisionesASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISIONES_ASG_SUCCESS,
      emisionesASG: [],
    };
  }
  return {
    type: actionTypes.FETCH_EMISIONES_ASG_SUCCESS,
    emisionesASG: data,
  };
};

// DETALLE FICHA EMISIONES ASG
const setEmisionASGDetalle = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISION_ASG_DETALLE_SUCCESS,
      emisionASGDetalle: null,
    };
  }
  return {
    type: actionTypes.FETCH_EMISION_ASG_DETALLE_SUCCESS,
    emisionASGDetalle: data,
  };
};

export const initEmisionASGDetalle = (empresaId) => {
  let url = `${dominio}/emisiones/emisiones-asg/${empresaId}/ficha`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setEmisionASGDetalle(response.data));
      })
      .catch((err) => dispatch(setEmisionASGDetalle(err.data)));
  };
};
// =========================================================================
//SIC ETF ASG
// =========================================================================
export const filterSicEtfASG = ({
  size,
  page,
  modoListado,
  pais,
  mercado,
  emisora,
  claveResultados,
}) => {

  let url = `${dominio}/emisiones/asg/sic-etf?size=${size}&page=${page}`;
  url = modoListado && modoListado !== null ? `${url}&idModoListado=${modoListado}` : url;
  url = pais && pais !== null ? `${url}&idPais=${pais}` : url;
  url = mercado && mercado !== null ? `${url}&idMercado=${mercado}` : url;
  url = emisora ? `${url}&idEmisora=${emisora}` : url;
  url = claveResultados ? `${url}&coincidencias=${claveResultados}` : url;

  return (dispatch) => {

    axios
      .get(url)
      .then((response) => {
        dispatch(setSicEtfASG(response.data));
      })
      .catch((err) => dispatch(setSicEtfASG(err.data)));
  };
};

const setSicEtfASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SIC_ETF_ASG_SUCCESS,
      sicEtfASG: [],
    };
  }
  return {
    type: actionTypes.FETCH_SIC_ETF_ASG_SUCCESS,
    sicEtfASG: data,
  };
};

// DETALLE FICHA SIC ETF ASG
const setSicEtfASGDetalle = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SIC_ETF_ASG_DETALLE_SUCCESS,
      sicEtfASGDetalle: null,
    };
  }
  return {
    type: actionTypes.FETCH_SIC_ETF_ASG_DETALLE_SUCCESS,
    sicEtfASGDetalle: data,
  };
};

export const initSicEtfASGDetalle = (empresaId) => {
  let url = empresaId;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setSicEtfASGDetalle(response.data));
      })
      .catch((err) => dispatch(setSicEtfASGDetalle(err.data)));
  };
};

const setImagesTeam = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TEAM_SUCCESS,
      team: [],
    };
  }
  return {
    type: actionTypes.FETCH_TEAM_SUCCESS,
    team: data,
  };
};

export const getTeamSg = () => {
  const url = `${dominio}/cms/o/documents/bivasg-equipo/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setImagesTeam(response.data));
      })
      .catch((err) => dispatch(setImagesTeam(err.data)));
  };
};
// =========================================================================

// Filter Claves EMISIONES ASG
const setFilterClavesEmisionesASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVESEMISIONES_ASG_SUCCESS,
      listadoClaves: [],
    };
  }
  return {
    type: actionTypes.FETCH_CLAVESEMISIONES_ASG_SUCCESS,
    listadoClaves: data,
  };
};

export const filterEmisionesASGByClave = (clave) => {
  let url = `${dominio}/emisiones/emisiones-asg/busqueda/${clave}`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterClavesEmisionesASG(response.data));
      })
      .catch((err) => dispatch(setFilterClavesEmisionesASG(err.data)));
  };
};

export const filterSicEtfASGByClave = (clave, modoListado) => {
  let url = `${dominio}/emisiones/asg/sic-etf/claves?coincidencias=${clave}`;
  url = modoListado && modoListado !== null ? `${url}&idModoListado=${modoListado}` : url;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterClavesEmisionesASG(response.data));
      })
      .catch((err) => dispatch(setFilterClavesEmisionesASG(err.data)));
  };
};

export const filterPaisesSicEtfASG = (mercado = null) => {
  let url = `${dominio}/emisiones/asg/sic-etf/pais`;
  url = mercado && mercado !== null ? `${url}?idMercado=${mercado}` : url;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterPaisesSicEtfASG(response.data));
      })
      .catch((err) => dispatch(setFilterPaisesSicEtfASG(err.data)));
  };
};

export const filterMercadosSicEtfASG = (pais = null) => {
  let url = `${dominio}/emisiones/asg/sic-etf/mercado`;
  url = pais && pais !== null ? `${url}?idPais=${pais}` : url;
    return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterMercadosSicEtfASG(response.data));
      })
      .catch((err) => dispatch(setFilterMercadosSicEtfASG(err.data)));
  };
};
const setFilterPaisesSicEtfASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PAIS_SIC_ETF_ASG_SUCCESS,
      paises: [],
    };
  }
  return {
    type: actionTypes.FETCH_PAIS_SIC_ETF_ASG_SUCCESS,
    paises: data,
  };
};
const setFilterMercadosSicEtfASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MERCADO_SIC_ETF_ASG_SUCCESS,
      mercados: [],
    };
  }
  return {
    type: actionTypes.FETCH_MERCADO_SIC_ETF_ASG_SUCCESS,
    mercados: data,
  };
};
// =========================================================================
// Inicia: Alternativos ASG
// =========================================================================
export const filterAlternativosASG = ({
  size,
  page,
  modoListado,
  tipoInstrumento,
  claveResultados,
}) => {

  let url = `${dominio}/emisiones/asg/alternativos?size=${size}&page=${page}`;
  url = modoListado && modoListado !== null ? `${url}&idModoListado=${modoListado}` : url;
  url = tipoInstrumento && tipoInstrumento !== null ? `${url}&idTipoInstrumento=${tipoInstrumento}` : url;
  url = claveResultados ? `${url}&coincidencias=${claveResultados}` : url;

  return (dispatch) => {

    axios
      .get(url)
      .then((response) => {
        dispatch(setAlternativosASG(response.data));
      })
      .catch((err) => dispatch(setAlternativosASG(err.data)));
  };
};

const setAlternativosASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ALTERNATIVOS_ASG_SUCCESS,
      alternativosASG: [],
    };
  }
  return {
    type: actionTypes.FETCH_ALTERNATIVOS_ASG_SUCCESS,
    alternativosASG: data,
  };
};

const setAlternativosASGFicha = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ALTERNATIVOS_ASG_FICHA_SUCCESS,
      alternativosASGFicha: null,
    };
  }
  return {
    type: actionTypes.FETCH_ALTERNATIVOS_ASG_FICHA_SUCCESS,
    alternativosASGFicha: data,
  };
};

const setFilterTiposInstrumentoAlternativosASG = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ALTERNATIVOS_ASG_TIPO_INSTRUMENTO_SUCCESS,
      tiposInstrumento: [],
    };
  }
  return {
    type: actionTypes.FETCH_ALTERNATIVOS_ASG_TIPO_INSTRUMENTO_SUCCESS,
    tiposInstrumento: data,
  };
};

export const initAlternativosASGFicha = (empresaId) => {
  let url = empresaId;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setAlternativosASGFicha(response.data));
      })
      .catch((err) => dispatch(setAlternativosASGFicha(err.data)));
  };
};

export const filterAlternativosASGByClave = (clave, modoListado) => {
  let url = `${dominio}/emisiones/asg/alternativos/claves?coincidencias=${clave}`;
  url = modoListado && modoListado !== null ? `${url}&idModoListado=${modoListado}` : url;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterClavesEmisionesASG(response.data));
      })
      .catch((err) => dispatch(setFilterClavesEmisionesASG(err.data)));
  };
};

export const filterTiposInstrumentoAlternativosASG = (tipoInstrumento = null) => {
  let url = `${dominio}/emisiones/asg/alternativos/tipos-instrumento`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterTiposInstrumentoAlternativosASG(response.data));
      })
      .catch((err) => dispatch(setFilterTiposInstrumentoAlternativosASG(err.data)));
  };
};
// =========================================================================
// Termina: Alternativos ASG
// =========================================================================

const setObjetivos360 = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TEAM_SUCCESS,
      objetivos: [],
    };
  }
  return {
    type: actionTypes.FETCH_TEAM_SUCCESS,
    objetivos: data,
  };
};

export const getObjetivos360 = () => {
  const url = `${dominio}/cms/o/documents/bivasg-360-objetivos/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setObjetivos360(response.data));
      })
      .catch((err) => dispatch(setObjetivos360(err.data)));
  };
};
const setObjetivosGuia = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_OBJETIVOSGUIA_SUCCESS,
      objetivosGuia: [],
    };
  }
  return {
    type: actionTypes.FETCH_OBJETIVOSGUIA_SUCCESS,
    objetivosGuia: data,
  };
};

export const getObjetivosGuia = () => {
  const url = `${dominio}/cms/o/documents/guia-asg-objetivos/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setObjetivosGuia(response.data));
      })
      .catch((err) => dispatch(setObjetivosGuia(err.data)));
  };
};
const setCapitulosGuia = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CAPITULOSGUIA_SUCCESS,
      capitulosGuia: [],
    };
  }
  return {
    type: actionTypes.FETCH_CAPITULOSGUIA_SUCCESS,
    capitulosGuia: data,
  };
};

export const getCapitulosGuia = () => {
  const url = `${dominio}/cms/o/documents/guia-asg-enlaces/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setCapitulosGuia(response.data));
      })
      .catch((err) => dispatch(setCapitulosGuia(err.data)));
  };
};
const setMaterialidad = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MATERIALIDAD_SUCCESS,
      materialidad: [],
    };
  }
  return {
    type: actionTypes.FETCH_MATERIALIDAD_SUCCESS,
    materialidad: data,
  };
};

export const getMaterialidad = () => {
  const url = `${dominio}/cms/o/documents/guia-asg-materialidad/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setMaterialidad(response.data));
      })
      .catch((err) => dispatch(setMaterialidad(err.data)));
  };
};


const setContextoGeneral = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CONTEXTO_GENERAL_SUCCESS,
      contextoGeneral: [],
    };
  }
  return {
    type: actionTypes.FETCH_CONTEXTO_GENERAL_SUCCESS,
    contextoGeneral: data,
  };
};

export const getContextoGeneral = () => {
  const url = `${dominio}/cms/o/documents/guia-asg-contexto/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setContextoGeneral(response.data));
      })
      .catch((err) => dispatch(setContextoGeneral(err.data)));
  };
};

const setDespliegueCultura = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DESPLIEGUECULTURA_SUCCESS,
      despliegueCultura: [],
    };
  }
  return {
    type: actionTypes.FETCH_DESPLIEGUECULTURA_SUCCESS,
    despliegueCultura: data,
  };
};

export const getDespliegueCultura = () => {
  const url = `${dominio}/cms/o/documents/guia-asg-despliegue/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setDespliegueCultura(response.data));
      })
      .catch((err) => dispatch(setDespliegueCultura(err.data)));
  };
};

const setClasificacionAsg = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLASIFICACIONASG_SUCCESS,
      clasificacion: [],
    };
  }
  return {
    type: actionTypes.FETCH_CLASIFICACIONASG_SUCCESS,
    clasificacion: data,
  };
};

export const getClasificacionAsg = () => {
  const url = `${dominio}/cms/o/documents/guia-asg-clasificacion/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setClasificacionAsg(response.data));
      })
      .catch((err) => dispatch(setClasificacionAsg(err.data)));
  };
};

const setReporteoAsg = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_REPORTEOASG_SUCCESS,
      reporteoAsg: [],
    };
  }
  return {
    type: actionTypes.FETCH_REPORTEOASG_SUCCESS,
    reporteoAsg: data,
  };
};

export const getReporteoAsg = () => {
  const url = `${dominio}/cms/o/documents/guia-asg-reporteo/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setReporteoAsg(response.data));
      })
      .catch((err) => dispatch(setReporteoAsg(err.data)));
  };
};



const setLinkGuia = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_LINK_GUIA_SUCCESS,
      guiaLink: [],
    };
  }
  return {
    type: actionTypes.FETCH_LINK_GUIA_SUCCESS,
    guiaLink: data,
  };
};

export const getLinkGuiaAsg = () => {
  const url = `${dominio}/cms/o/documents/guia-asg/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setLinkGuia(response.data));
      })
      .catch((err) => dispatch(setLinkGuia(err.data)));
  };
};

const setCuestionario = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUESTIONARIO_SUCCESS,
      esgContainers: [],
    };
  }
  return {
    type: actionTypes.FETCH_CUESTIONARIO_SUCCESS,
    esgContainers: data,
  };
};

export const getCuestionario = (page) => {
  let url = `${dominio}${contexto}/${page}/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setCuestionario(response.data));
      })
      .catch((err) => dispatch(setCuestionario(err.data)));
  };
};

const setDocuments = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUESTIONARIO_SUCCESS,
      documentos: [],
    };
  }
  return {
    type: actionTypes.FETCH_CUESTIONARIO_SUCCESS,
    documentos: data,
  };
};

export const getDocumentsCuestionario = (page) => {
  let url = `${dominio}${contexto}/${page}/lang/${lang}`;

  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setDocuments(response.data));
      })
      .catch((err) => dispatch(setDocuments(err.data)));
  };
};

// ===============================================================================
const setCuestionariosAmafore = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUESTIONARIO_SUCCESS,
      cuestionarios: []
    };
  }
  return {
    type: actionTypes.FETCH_CUESTIONARIO_SUCCESS,
    cuestionarios: data
  };
};

export const getCuestionariosAmafore = (params) => {
  
  const queryParams = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/asg/cuestionarios-amafore` + queryParams;


  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCuestionariosAmafore(response.data));
      })
      .catch(err => dispatch(setCuestionariosAmafore(err.data)));
  };
};

// Filter Claves Cuestionario AMAFORE
const setFilterClaveCuestionarioAmafore = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVES_AMAFORE_SUCCESS,
      listadoClaves: [],
    };
  }
  return {
    type: actionTypes.FETCH_CLAVES_AMAFORE_SUCCESS,
    listadoClaves: data,
  };
};

export const filterCuestionariAmaforeByClave = (clave, modoListado) => {
  let url = `${dominio}/asg/cuestionarios-amafore/claves-cotizacion?coincidencias=${clave}`;
  url = modoListado && modoListado !== null ? `${url}&modoListado=${modoListado}` : url;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterClaveCuestionarioAmafore(response.data));
      })
      .catch((err) => dispatch(setFilterClaveCuestionarioAmafore(err.data)));
  };
};

export const filterEjerciciosAmafore = (mercado = null, modoListado = null) => {
  let url = `${dominio}/asg/cuestionarios-amafore/ejercicios`;
  url = mercado && mercado !== null ? `${url}?idMercado=${mercado}` : url;
  url = modoListado && modoListado !== null ? `${url}?modoListado=${modoListado}` : url;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterEjerciciosAmafore(response.data));
      })
      .catch((err) => dispatch(setFilterEjerciciosAmafore(err.data)));
  };
};

const setFilterEjerciciosAmafore = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EJERCICIOS_AMAFORE_SUCCESS,
      ejercicios: [],
    };
  }
  return {
    type: actionTypes.FETCH_EJERCICIOS_AMAFORE_SUCCESS,
    ejercicios: data,
  };
};

export const filterSectoresAmafore = (pais = null, modoListado = null) => {
  let url = `${dominio}/asg/cuestionarios-amafore/sectores`;
  url = pais && pais !== null ? `${url}?idPais=${pais}` : url;
  url = modoListado && modoListado !== null ? `${url}?modoListado=${modoListado}` : url;
  return (dispatch) => {
  axios
    .get(url)
    .then((response) => {
      dispatch(setFilterSectoresAmafore(response.data));
    })
    .catch((err) => dispatch(setFilterSectoresAmafore(err.data)));
  };
};

const setFilterSectoresAmafore = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SECTORES_AMAFORE_SUCCESS,
      sectores: [],
    };
  }
  return {
    type: actionTypes.FETCH_SECTORES_AMAFORE_SUCCESS,
    sectores: data,
  };
};

export const filterTiposEmisoraAmafore = (mercado = null, modoListado = null) => {
  let url = `${dominio}/asg/cuestionarios-amafore/tipos-emisora`;
  url = mercado && mercado !== null ? `${url}?idMercado=${mercado}` : url;
  url = modoListado && modoListado !== null ? `${url}?modoListado=${modoListado}` : url;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setFilterTiposEmisoraAmafore(response.data));
      })
      .catch((err) => dispatch(setFilterTiposEmisoraAmafore(err.data)));
  };
};

const setFilterTiposEmisoraAmafore = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPOSEMISORA_AMAFORE_SUCCESS,
      tiposEmisora: [],
    };
  }
  return {
    type: actionTypes.FETCH_TIPOSEMISORA_AMAFORE_SUCCESS,
    tiposEmisora: data,
  };
};

export const catalogoSectoresAmafore = (pais = null) => {
  let url = `${dominio}/asg/cuestionarios-amafore/sectores?todos=true`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setCatalogoSectoresAmafore(response.data));
      })
      .catch((err) => dispatch(setCatalogoSectoresAmafore(err.data)));
  };
};

const setCatalogoSectoresAmafore = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CATALOGO_SECTORES_AMAFORE_SUCCESS,
      catalogoSectores: [],
    };
  }
  console.log();
  return {
    type: actionTypes.FETCH_CATALOGO_SECTORES_AMAFORE_SUCCESS,
    catalogoSectores: data,
  };
};

export const catalogoTiposEmisoraAmafore = (mercado = null) => {
  let url = `${dominio}/asg/cuestionarios-amafore/tipos-emisora?todos=true`;
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        dispatch(setCatalogoTiposEmisoraAmafore(response.data));
      })
      .catch((err) => dispatch(setCatalogoTiposEmisoraAmafore(err.data)));
  };
};

const setCatalogoTiposEmisoraAmafore = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CATALOGO_TIPOSEMISORA_AMAFORE_SUCCESS,
      catalogoTiposEmisora: [],
    };
  }
  return {
    type: actionTypes.FETCH_CATALOGO_TIPOSEMISORA_AMAFORE_SUCCESS,
    catalogoTiposEmisora: data,
  };
};
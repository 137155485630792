import React, { Component } from "react";
import classes from "./Cruces.css";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import Auxs from "../../../../hoc/auxs/Auxs";
import Modal from "../../../../components/ui/modal/Modal";
import BackDrop from "../../../../components/ui/backdrop/BakcDrop";
import Boton from "../../../../components/ui/boton/Boton";
import OperacionBox from "../../../../components/ui/operacionBox/OperacionBox";
import Tabs from "../../../../components/ui/tabs/Tabs";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";

class Cruces extends Component {
  state = {
    showModal: false,
    datos: [],
    tabSeleccionado: 0,
    messages: null,
    lan: getLocal(),
    tabs: [
      {
        image: "",
        icon: "",
        title: ""
      },
      {
        image: "",
        icon: "",
        title: ""
      },
      {
        image: "",
        icon: "",
        title: ""
      }
    ]
  };
  componentDidMount() {
    this.props.onInitIntl();
    this.props.onInitTotal();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
          tabs: [
            {
              image: "",
              icon: "",
              title: messages["informacionmercado.cruces.titulo.total"]
            },
            {
              image: "",
              icon: "",
              title: messages["informacionmercado.cruces.intencionales"]
            },
            {
              image: "",
              icon: "",
              title: messages["informacionmercado.cruces.excepcionales"]
            }
          ]
        });
      }
    }
    if (this.props.totales !== prevProps.totales) {
      this.setState({
        datos: [this.props.totales]
      });
    }

    if (this.props.intencionales !== prevProps.intencionales) {
      this.setState({
        datos: [this.props.intencionales]
      });
    }

    if (this.props.excepcionales !== prevProps.excepcionales) {
      this.setState({
        datos: [this.props.excepcionales]
      });
    }
  }

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  onChangeItemSelected = (index = 0) => {
    let datos = null;
    if (index === 0) {
      //totales
      this.props.onInitTotal();
      if (this.props.totales) {
        datos = [this.props.totales];
      }
    } else if (index === 1) {
      //Intencionales
      this.props.onInitIntencional();
      if (this.props.intencionales) {
        datos = [this.props.intencionales];
      }
    } else {
      //Excepcionales
      this.props.onInitExcepcional();
      if (this.props.excepcionales) {
        datos = [this.props.excepcionales];
      }
    }

    this.setState({
      datos: datos,
      tabSeleccionado: index
    });
  };

  render() {
    const modal = (
      <React.Fragment>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} onCloseModal={this.onToggleModalHandler} area={"operations"} />
      </React.Fragment>
    );

    let tabMostrar = (
      <div className="margen_top_30">
        <div className={["row", classes.tabsContainer].join(" ")}>
          <div className={["texto_14 montse regular", classes.myCol].join(" ")}>{getLan() === "es" ? "Mostrar" : "View"}:</div>
          <div className={["col", classes.myCol2].join(" ")}>
            <Tabs data={this.state.tabs} tipo={1} clicked={i => this.onChangeItemSelected(i)} />
          </div>
        </div>
      </div>
    );


    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.cruces.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          {tabMostrar}

          <div className="row margen_top_50">
              <OperacionBox data={this.state.datos} tipo={7} />
          </div>
          <div className={""}>
            <span className={"texto_12 montse regular"}>
              <FormattedHTMLMessage id="informacionmercado.cruces.frecuenciaactualizacion" defaultMessage=" " />
            </span>
            <br />
          </div>

          <div className="row margen_top_50 margin_bottom_300">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={""}>
                  <FormattedHTMLMessage id="informacionmercado.cruces.interesado" defaultMessage=" " />
                </h2>
              </div>
            </div>

            <div className="row margen_top_30">
              <Boton
                onClick={this.onToggleModalHandler.bind(this)}
                tipo={4}
                texto={this.state.messages ? this.state.messages["informacionmercado.cruces.interesado.solicita"] :""}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>
          </div>

          {modal}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    totales: state.informacionMercado.crucesTotales,
    intencionales: state.informacionMercado.crucesIntencionales,
    excepcionales: state.informacionMercado.crucesExcepcionales
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(49));
    },
    onInitTotal: () => {
      dispatch(action.initCrucesTotal());
    },
    onInitIntencional: () => {
      dispatch(action.initCrucesIntencionales());
    },
    onInitExcepcional: () => {
      dispatch(action.initCrucesExcepcionales());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cruces);

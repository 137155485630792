import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../stores/actions/index";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../assets/gif/loader.gif";
import { getLan } from "../../../../stores/utils/utilities";

class DocumentosProspectos extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: false,
    tramiteSeleccionado: null,
    idTramite: null,
    documentosProspectos: null
  };

  componentWillReceiveProps(nextProps) {
    let documentosProspectos = null;
    let idTramite = null;

    this.setState({
      loader: false,
      documentosProspectos: []
    });

    if (nextProps.documentosProspectos && nextProps.documentosProspectos.content) {
      documentosProspectos = nextProps.documentosProspectos.content;
    }
    if (nextProps.idTramite) {
      idTramite = nextProps.idTramite;
    }
    let resource = documentosProspectos;
    this.onPageChangeHandler(resource, null, 0, idTramite);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentosProspectos !== this.props.documentosProspectos) {
      this.setState({
        documentosProspectos: this.props.documentosProspectos
      });
    }
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null, idTramite = null) => {
    let data = resource ? resource : this.state.documentosProspectos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.props.onInitDocumentosProspectos(
        this.state.itemsPerPage,
        pageNumber - 1,
        this.props.tramiteSeleccionado,
        this.props.idTramite,
        this.props.tiposBonosASG
      );

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    this.setState({
      loader: true
    });
    this.props.onInitDocumentosProspectos(this.state.itemsPerPage, 0, this.props.tramiteSeleccionado, this.props.idTramite, this.props.tiposBonosASG);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = (
        <div className="row margin_top_10">
          <div className="col-12 montse texto_16 regular">
            <img src={Loader} alt="" />
          </div>
        </div>
      );
    }
    let contenidoMostrar = null;
    let documentosProspectos = null;
    if (this.state.documentosProspectos && this.state.documentosProspectos.content) {
      documentosProspectos = this.state.documentosProspectos.content;
    }
    if (
      this.state.documentosProspectos !== null &&
      this.state.documentosProspectos.length !== undefined &&
      this.state.documentosProspectos.length === 0
    ) {
      contenidoMostrar = (
        <div className="row margin_top_10">
          <p className="col-12 montse texto_16 regular">
            {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
          </p>
        </div>
      );
    }
    const numRows = this.state.documentosProspectos ? this.state.documentosProspectos.totalElements : 0;

    return (
      <React.Fragment>
        <div id="prospectos" className="margen_50">
          {loader}
          <Accordion datos={documentosProspectos} nombre="documentosProspectos" icon={""} tipo={15} subtipo={5} />
          {contenidoMostrar}
          <div className={"row"}>
            <div className={"col-12 sin_padding"}>
              <Pagination
                claseTipo={2}
                numRows={numRows}
                paginas={5}
                itemsPerPage={this.state.itemsPerPage}
                handlePageChange={this.handlePageChange.bind(this)}
                activePage={this.state.activePage}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitDocumentosProspectos: (size, page, emisoraId, idTramite, tipoBono) => {
      dispatch(actions.initDocumentosProspectos(size, page, emisoraId, idTramite, tipoBono));
    }
  };
};

const mapStateToProps = state => {
  return {
    documentosProspectos: state.listadoValores.documentosProspectos
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentosProspectos);

import React, { Component } from "react";
import classes from "./Boletin.css";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import Modal from "../../../components/ui/modal/Modal";
import { FormattedMessage } from "react-intl";

class Boletin extends Component {
  state = {
    showModal: false
  };
  componentDidMount() {}

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };
  render() {
    const modal = (
      <React.Fragment>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal
          showModal={this.state.showModal}
          onCloseModal={this.onToggleModalHandler}
          tipo_mensaje={2}
          area={"boletin-informativo"}
        />
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className={classes.fondo}>
          <div className="row height_cien">
            <div className="width_cien height_cien">
              <div className="row relativo">
                <div className={[classes.izq].join(" ")}>
                  <div className={classes.textoAlineado}>
                    <span className={["texto_18 color_blanco montse", classes.boletinText].join(" ")}>
                    <FormattedMessage id="home.boletin.titulo" defaultMessage=" " />

                    </span>
                    <i className="far fa-newspaper color_blanco texto_18" />
                  </div>
                </div>
                <div className={[classes.izq2].join(" ")}>
                  <div className={[classes.suscribete, "noselect"].join(" ")} onClick={this.onToggleModalHandler.bind(this)}>
                    <span className={"color_azul texto_18 montse noselect"}>
                      <FormattedMessage id="home.boletin.suscripcion" defaultMessage=" " />
                    </span>
                  </div>
                </div>
                <div className={[classes.contactanos]}>
                  <div className={classes.contactanosContenedor}>
                    <div className={classes.contactanosAlineado}>
                      <span className={"color_blanco texto_18 montse"}>
                    <FormattedMessage id="home.contactanos.titulo" defaultMessage=" " />
                        </span>{" "}
                      <span className={"color_blanco texto_18 montse"}><FormattedMessage id="home.contactanos.datos" defaultMessage=" " /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modal}
      </React.Fragment>
    );
  }
}
export default Boletin;

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import classes from "./Afores.css";

import FilterConcentradoPreciosAfores from "../../../components/ui/filterConcentradoPreciosAfores/FilterConcentradoPreciosAfores"
import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
/* import { withRouter } from "react-router-dom"; */
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
const dominio = `${window.location.protocol}//${window.location.host}`;

class ConcentradoPreciosAfores extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    razonSocial: null,
    clave: null,
    numeroTramite: null,
    datos: null,
    emisoraSeleccionada: null,
    tipoValorSeleccionado: null,
    fecha_inicio: null,
    fecha_fin: null,
    page: 0,
    detalles: [],
    urlForRss: `${dominio}/emisoras/tramites/rss`,
    tipoDocumento: null,
    pageDocumento: null,
    showRssModal: false,
    verDocumentos: false,
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.afores !== prevProps.afores) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let afores = null;
    let detalles = null;


    if (nextProps.afores && nextProps.afores.content) {
      afores = nextProps.afores.content;
    }

    if (nextProps.concentradoPrecios !== this.props.concentradoPrecios) {
      if (nextProps.concentradoPrecios) {
        detalles = nextProps.concentradoPrecios
      }
    } else {
      detalles = this.props.concentradoPrecios;
    }

    this.setState({
      detalles: detalles
    });

    let resource = afores;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.afores;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
      this.props.history.push(
        `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${pageNumber}&empresaId=${null}&razonSocial=${null}&clave=${null}&numeroTramite=${null}&clave_seleccionada=${
        this.state.clave
        }&emisora_id=${this.state.emisoraSeleccionada}&valores=${this.state.tipoValorSeleccionado}&fecha_ini=${
        this.state.fecha_inicio
        }&fecha_f=${this.state.fecha_fin}`
      );
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.changeHistory(
        this.state.claveAfore,
        this.state.claveSiefore,
        this.state.tipoSiefore,
        this.state.clasificacionSiefore,
        this.state.fecha_inicio,
        this.state.fecha_fin,
        pageNumber);
      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let claveAfore = null;
    let claveSiefore = null;
    let tipoSiefore = null;
    let clasificacionSiefore = null;
    let page = null;
    let fechaInicio = null;
    let fechaFin = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      claveAfore = paramsString["claveAfore"] === "null" ? null : paramsString["claveAfore"];
      claveSiefore = paramsString["claveSiefore"] === "null" ? null : paramsString["claveSiefore"];
      tipoSiefore = paramsString["tipoSiefore"] !== undefined && paramsString["tipoSiefore"] !== "null" ? paramsString["tipoSiefore"] : null;
      clasificacionSiefore = paramsString["clasificacionSiefore"] !== undefined && paramsString["clasificacionSiefore"] !== "null" ? paramsString["clasificacionSiefore"] : null;
      page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
      fechaInicio = paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
      fechaFin = paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    }
    if (claveSiefore !== null) {
      this.setState({
        tramiteDetalle: claveSiefore
      });

    } 
      this.props.onInitAfores(
        this.state.itemsPerPage,
        parseInt(page) > 0 ? parseInt(page) - 1 : 0,
        claveAfore,
        claveSiefore,
        tipoSiefore,
        clasificacionSiefore,
        fechaInicio,
        fechaFin
      );

      this.setState({
        claveAfore: claveAfore,
        claveSiefore: claveSiefore,
        tipoSiefore: tipoSiefore,
        clasificacionSiefore: clasificacionSiefore,
        fecha_inicio: fechaInicio,
        fecha_fin: fechaFin,
        activePage: parseInt(page) > 0 ? parseInt(page) : 1,
      });
    
  }

  updateGrandparentListado = (
    empresaId,
    razonSocial,
    clave,
    numeroTramite,
    tramiteId = null,
    tipoDocumento = null,
    numEnvio = null,
    page = null,
    consulta = null
  ) => {
    this.props.onInitConcentradoPrecios(empresaId);
    let datos = {
      id: empresaId,
      numeroTramite: numeroTramite,
      clave: clave,
      razonSocial: razonSocial
    };

    this.setState({
      tramiteSeleccionado: empresaId,
      razonSocial: razonSocial,
      clave: clave,
      numeroTramite: numeroTramite,
      datos: datos,
      tramiteDetalle: empresaId,
      verDocumentos: true
    });
    if (consulta === null) {
      this.props.history.push(
        `?tramiteId=${
        tramiteId ? tramiteId : empresaId ? empresaId : null
        }&tipoDocumento=${tipoDocumento}&numEnvio=${numEnvio}&page=${
        page === null ? 1 : page
        }&empresaId=${empresaId}&razonSocial=${razonSocial}&clave=${clave}&numeroTramite=${numeroTramite}&verDocumentos=${true}`
      );
    }
  };

  updateGrandparent = value => {
    this.props.onInitConcentradoPrecios(value);
    this.setState({
      tramiteDetalle: value,
      detalles: []
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetClaveAforeHandler = claveAfore => {
    this.setState({
      claveAfore: claveAfore
    });
  };

  onSetClaveSieforeHandler = claveSiefore => {
    this.setState({
      claveSiefore: claveSiefore
    });
  };

  onSetTipoSieforeHandler = tipoSiefore => {
    this.setState({
      tipoSiefore: tipoSiefore
    });
  };

  onSetClasificacionSieforeHandler = clasificacionSiefore => {
    this.setState({
      clasificacionSiefore: clasificacionSiefore
    });
  };

  onSetFechaInicio = fechaInicio => {
    this.setState({
      fecha_inicio: fechaInicio
    });
  }

  onSetFechaFin = fechaFin => {
    this.setState({
      fecha_fin: fechaFin
    });
  }

  onSetWasFilteredHandler = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered,
      loader:true
    });
  };

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  onSetFilters = ({
    claveAfore = null,
    claveSiefore = null,
    tipoSiefore = null,
    clasificacionSiefore = null,
    fechaInicio = null,
    fechaFin = null,
    wasFiltered = null,
    page = null,
    reset = false
  }) => {
    this.setState({
      claveAfore: claveAfore ? claveAfore : null,
      claveSiefore: claveSiefore ? claveSiefore : null,
      tipoSiefore: tipoSiefore ? tipoSiefore : null,
      clasificacionSiefore: clasificacionSiefore ? clasificacionSiefore : null,
      fechaInicio: fechaInicio ? fechaInicio : null,
      fechaFin: fechaFin ? fechaFin : null,
      wasFiltered: wasFiltered
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claveAfore, claveSiefore, tipoSiefore, clasificacionSiefore, fechaInicio, fechaFin, page);
    }
  }

  changeHistory(claveAfore = null, claveSiefore = null, tipoSiefore = null, clasificacionSiefore = null, fechaInicio = null, fechaFin = null, page = null) {
    this.props.history.push(
      `?claveAfore=${claveAfore}&claveSiefore=${claveSiefore}&tipoSiefore=${tipoSiefore}&clasificacionSiefore=${clasificacionSiefore}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&page=${page ? page : this.state.activePage}`
    );

    this.props.onInitAfores(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      claveAfore,
      claveSiefore,
      tipoSiefore,
      clasificacionSiefore,
      fechaInicio,
      fechaFin
    );
  }

  changeHistoryReset() {
    this.props.history.push(
      `?claveAfore=${null}&claveSiefore=${null}&tipoSiefore=${null}&clasificacionSiefore=${null}}&fechaInicio=${null}&fechaFin=${null}&page=1`
    );
    this.props.onInitAfores(
      this.state.itemsPerPage,
      0,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let afores = null;
    if (this.props.afores && this.props.afores.content) {
      afores = this.props.afores.content;
    }
    const numRows = this.props.afores ? this.props.afores.totalElements : 0;

    let dataShow = null;
    dataShow = this.props.afores && this.props.afores.length === undefined ? (
      <React.Fragment>
<Accordion
            datos={afores}
            nombre="afores"
            icon={""}
            tipo={18}
            titulo={getLan() === "es" ? "Información de precios" : "Price information"}
            subtipo={2}
            detallesProspectos={this.state.detalles}
            updateGrandparentListado={this.updateGrandparentListado.bind(this)}
            updateGrandparent={this.updateGrandparent.bind(this)}
            esTramite={true}
            fondo={false}
          />
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
      </React.Fragment>
     ) : (
        <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda.": "No results found."}</p>
      );
    let contenidoMostrar = (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>
          <FormattedHTMLMessage id="empresas.siefores.concentrado.titulo1" defaultMessage=" " /></h1>
        <div className="margen_top_30 texto_16 montse regular">
          <FormattedHTMLMessage id="empresas.siefores.concentrado.introduccion1" defaultMessage=" " />
        </div>
        <br />
        <FilterConcentradoPreciosAfores
          itemsPerPage={this.state.itemsPerPage}
          setPage={page => this.onSetPage(page)}
          onSetClaveAfore={claveAfore =>
            this.onSetClaveAforeHandler(claveAfore)
          }
          onSetClaveSiefore={claveSiefore =>
            this.onSetClaveSieforeHandler(claveSiefore)
          }
          onSetTipoSiefore={tipoSiefore =>
            this.onSetTipoSieforeHandler(tipoSiefore)
          }
          onSetClasificacionSiefore={clasificacionSiefore =>
            this.onSetClasificacionSieforeHandler(clasificacionSiefore)
          }
          onSetWasFiltered={wasFiltered =>
            this.onSetWasFilteredHandler(wasFiltered)
          }
          onSetFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
          onSetFechaFin={fechaFin => this.onSetFechaFin(fechaFin)}
          setFilters={filterParams => this.onSetFilters(filterParams)}
        />
        <br />

        <div className="margen_50">
          {" "}
          {dataShow}
        </div>
      </React.Fragment>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          {contenidoMostrar}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitAfores: (size, page) => {
      dispatch(actions.initAforesConcentradoPreciosGeneral(size, page));
    },
    onInitConcentradoPrecios: clave => {
      dispatch(actions.initAforesConcentradoPrecios(clave));
    },
    onFetchRssDocument: () => {
      dispatch(actions.fetchRssSeguimientosUrlDocument());
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(73));
    }
  };
};

const mapStateToProps = state => {
  return {
    afores: state.afores.afores,
    concentradoPrecios: state.afores.concentradoPrecios,
    diccionario: state.intl.diccionario
  };
};

export default (
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConcentradoPreciosAfores)
);

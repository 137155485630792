import React, { Component } from "react";
import classes from "./FilterAvisosGenerales.css";
import { connect } from "react-redux";

import { getLan, changeDateFormat } from "../../../stores/utils/utilities";
import { Form, Field } from "react-final-form";

import Auxs from "../../../hoc/auxs/Auxs";
import Input from "../input/Input";

import * as actions from "../../../stores/actions/index";

const renderInput = ({
  input,
  id = null,
  label,
  clases = null,
  options = null,
  type = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel,
  onInputChange
}) => {
  const chooseOptionLabel =
    getLan() === "es" ? "Seleccione una opción" : "Select an option";

  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      checked: checked,
      className: clases ? clases : null,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    type: type,
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel
  };

  return (
    <div className="form-group">
      {label ? (
        <label
          className={[elementType === "checkbox" ? classes.myLabel : null, "texto_14 montse regular"].join(" ")}
          for={input.name}
        >
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterAvisosGenerales extends Component {
  constructor(props) {
    super(props);
    this.formFilterAvisos = React.createRef();
  }

  state = {
    toggleFilter: false,
    selectedFilters: {
      clave_cotizacion: [],
      fechas: []
    },
    form_filters: {
      clave_cotizacion: {
        value: ""
      },
      fecha_inicio: {
        value: ""
      },
      fecha_fin: {
        value: ""
      },
      errorDate: false
    },
    itemsPerPage: 10
  };

  componentDidMount() {
    const idTipoAvisoVigilancia = this.props.idTipoAvisoVigilancia;
    this.props.onFilterClavesCotizacion(idTipoAvisoVigilancia);
  }

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    let [
      valuesClaveCotizacion,
      valuesFechaInicio,
      valuesFechaFin
    ] = this.onGetAllValuesFormFilter();

    /* [valuesFechaInicio, valuesFechaFin] = changeDateFormat(valuesFechaInicio, valuesFechaFin); */

    if (!this.state.form_filters.errorDate) {
      const params = {
        size: this.state.itemsPerPage,
        page: 0,
        idTipoAvisoVigilancia: this.props.idTipoAvisoVigilancia,
        claveCotizacion: valuesClaveCotizacion,
        fechaInicio: valuesFechaInicio,
        fechaFin: valuesFechaFin
      };
      this.props.onFilterAvisos(params);

      this.props.setPage(1);
    }

    let wasFiltered = false;
    // Se invocan a las funciones el componente padre (BancoInformacion.js) para setear los valores correspondientes
    // Configurando el valor del campo clave cotizacion
    this.props.setClaveCotizacion(valuesClaveCotizacion);
    // Configurando el valor del campo fecha inicio
    this.props.setFechaInicio(valuesFechaInicio);
    // Configurando el valor del campo fecha fin
    this.props.setFechaFin(valuesFechaFin);

    if (
      valuesClaveCotizacion && valuesClaveCotizacion !== "" ||
      valuesFechaInicio && valuesFechaInicio !== "" ||
      valuesFechaFin && valuesFechaFin !== ""
    ) {
      wasFiltered = true;
    }

    this.props.setWasFiltered(wasFiltered);
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesClaveCotizacion =
      form.clave_cotizacion.value && form.clave_cotizacion.value !== ""
        ? form.clave_cotizacion.value
        : "";
    // Se obtiene el valor de fecha inicio
    const valuesFechaInicio =
      form.fecha_inicio.value && form.fecha_inicio.value !== ""
        ? form.fecha_inicio.value
        : "";
    // Se obtiene el valor de fecha fin
    const valuesFechaFin =
      form.fecha_fin.value && form.fecha_fin.value !== ""
        ? form.fecha_fin.value
        : "";

    return [valuesClaveCotizacion, valuesFechaInicio, valuesFechaFin];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return [
          "clave_cotizacion",
          form.clave_cotizacion.value,
          selectedFilters.clave_cotizacion
        ];
      case 1:
        return [
          "fecha_inicio",
          form.fecha_inicio.value,
          selectedFilters.fechas,
          "fechas"
        ];
      case 2:
        return [
          "fecha_fin",
          form.fecha_fin.value,
          selectedFilters.fechas,
          "fechas"
        ];
    }
  };

  // Retorna el valor, etiqueta y tipo  de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newValue = null;
    let newLabel = null;

    if (type === 1 || type === 2) {
      // Fechas inicio o fin
      [newValue] = changeDateFormat(value);
      newLabel = type === 1 ? "fecha_inicio" : "fecha_fin";
    } else {
      // Resto de campos
      newValue = value;
      newLabel = value;
    }

    return [newValue, newLabel];
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (
    filterOptions = null,
    values = null,
    label = null,
    type = null
  ) => {
    let newFilterValues = [];

    if (type === 0) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      // Fechas
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(
        option => option.label === label
      );
      if (filterExists) {
        newFilterValues = newFilterValues.filter(
          option => option.label !== label
        );
        if (values && values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      } else {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    }
    return newFilterValues;
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (values !== "") {
      newStateValues = values;
    }
    return newStateValues;
  };

  onInputSelectedHandler = (
    event = null,
    type = null,
    item = null,
    eraser = null
  ) => {
    let actualValue = eraser ? null : event.target.value;
    eraser = eraser || (actualValue && actualValue === "") ? true : false;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let errorDate = form.errorDate;

    let newValue = null;
    let newLabel = null;

    if (eraser) {
      actualValue = "";
      newValue = "";
      newLabel = item.label;
      type = item.type;
    } else {
      // Solo para los campos distintos a tipo instrumento y tipo valor
      [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(
        actualValue,
        type
      );
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [
      inputName,
      stateValues,
      filterValues,
      otherInputName
    ] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(
      filterValues,
      newValue,
      newLabel,
      type
    );

    form[`${inputName}`].value = newStateValues;
    selectedFilters[
      `${otherInputName ? otherInputName : inputName}`
    ] = newFilterValues;

    if (type === 1) {
      // Fecha inicio
      // Se verifican si las fechas son validas
      if (actualValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          actualValue,
          form.fecha_fin.value
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 2) {
      // fecha Fin
      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          form.fecha_inicio.value,
          actualValue
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [
      ...this.state.selectedFilters.clave_cotizacion,
      ...this.state.selectedFilters.fechas
    ];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
                {filter.type !== 0 ? filter.value : filter.label}
              </span>
              <span
                className={classes.filterIconButton}
                onClick={() =>
                  this.onInputSelectedHandler(null, null, filter, true)
                }
              >
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onSubmitFilter = () => {
    // Pass
  };

  onResetFilter = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form.clave_cotizacion.value = "";
    selectedFilters.clave_cotizacion = [];
    form.fecha_inicio.value = "";
    form.fecha_fin.value = "";
    selectedFilters.fechas = [];
    form.errorDate = false;

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  onToggleFilterHandler = () => {
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  onSelectOptionsHandler = (options = null, type = null) => {
    let opt = [];
    if (type === 0) {
      options.forEach(option => {
        opt.push({
          nombre: option,
          id: option
        });
      });
    }
    return opt;
  };

  printFrameModelHandler = () => {
    const selectedFilters =
      this.state.selectedFilters.clave_cotizacion.length > 0 ||
      this.state.selectedFilters.fechas.length > 0
        ? this.onPrintSelectedFiltersHandler()
        : null;

    const closeLabel = getLan() === "es" ? "Cerrar Filtros" : "Close";
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";
    return (
      <Auxs>
        <div
          className={[
            "row",
            classes.firstSectionFilterContainer,
            !this.state.toggleFilter ? classes.noBorderRadius : null
          ].join(" ")}
        >
          <div className="col col-lg-3 text-center sin_padding_L">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button
                type="button"
                className="btn"
                onClick={this.onToggleFilterHandler}
              >
                <i className="fas fa-filter"></i>
                <span>
                  {!this.state.toggleFilter ? closeLabel : filterLabel}
                </span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={[
            "row",
            classes.secondSectionFilterContainer,
            // "margin_bottom_50",
            this.state.toggleFilter ? classes.hidden : null
          ].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    const clavesCotizacionOptions = this.props.claves_cotizacion
      ? this.onSelectOptionsHandler(this.props.claves_cotizacion, 0)
      : [];

    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitFilter}
              initialValues={{
                clave_cotizacion: this.state.form_filters.clave_cotizacion
                  .value,
                fecha_inicio: this.state.form_filters.fecha_inicio.value,
                fecha_fin: this.state.form_filters.fecha_fin.value
              }}
              render={({
                optionsClavesCotizacion = clavesCotizacionOptions,
                handleSubmit,
                form
              }) => (
                <form ref={this.formFilterAvisos} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <Field
                        name="clave_cotizacion"
                        id="clave_cotizacion"
                        elementType="select"
                        clases="custom-select"
                        label={
                          getLan() === "es" ? "Clave cotización" : "Ticker"
                        }
                        options={optionsClavesCotizacion}
                        onInputChange={event =>
                          this.onInputSelectedHandler(event, 0)
                        }
                        component={renderInput}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                      <div className={classes.datesSection}>
                        <div className="row">
                          <div
                            className={[
                              "col-12 col-sm-12 col-md-6",
                              "col-lg-6",
                              classes.no_col_padding
                            ].join(" ")}
                          >
                            <Field
                              name="fecha_inicio"
                              id="fecha_inicio"
                              type="date"
                              elementType="date"
                              clases={classes.fechaInicio}
                              label={
                                getLan() === "es"
                                  ? "Desde"
                                  : "From"
                              }
                              onInputChange={event =>
                                this.onInputSelectedHandler(event, 1)
                              }
                              component={renderInput}
                            />
                          </div>
                          <div
                            className={[
                              "col-12 col-sm-12 col-md-6",
                              "col-lg-6",
                              classes.no_col_padding
                            ].join(" ")}
                          >
                            <Field
                              name="fecha_fin"
                              id="fecha_fin"
                              type="date"
                              elementType="date"
                              clases={classes.fechaFin}
                              label={getLan() === "es" ? "Hasta" : "To"}
                              onInputChange={event =>
                                this.onInputSelectedHandler(event, 2)
                              }
                              component={renderInput}
                            />
                          </div>
                        </div>
                        <div
                          className={[
                            classes.errorContainer,
                            !this.state.form_filters.errorDate
                              ? classes.hidden
                              : null
                          ].join(" ")}
                        >
                          <p className={"texto_14 montse regular"}>
                            {getLan() === "es"
                              ? "La fecha final debe ser mayor a la fecha inicial."
                              : "End date must be greater than start date."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  sectionButtonsHandler = form => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(
              " "
            )}
            onClick={() => this.onResetFilter()}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandler();
    return (
      <Auxs>
        <div className="row margin_bottom_30">
          <div className="col sin_padding">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  return {
    claves_cotizacion: state.avisosReportes.ar_claves_cotizacion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterAvisos: params => {
      dispatch(actions.filterAvisos(params));
    },
    onFilterClavesCotizacion: (idTipoAvisoVigilancia = null) => {
      dispatch(actions.filterArClavesCotizacion(idTipoAvisoVigilancia));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterAvisosGenerales);

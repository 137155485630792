let dominio = process.env.REACT_APP_DOMINIO;
let numLlamado = 0;

async function transformToSearchSymbolResultItem(symbol) {
	let symbolSplit = symbol.split("_");
	let currentDayInfo = symbolSplit[3] === 'H' || (symbolSplit[3] === 'D' && symbolSplit[2] === '1');
	let symbolResultItem = {
		ticker: symbolSplit[0],
		symbol: symbolSplit[0],
		isin: symbolSplit[1],
		quantity: symbolSplit[2],
		timeMetric: symbolSplit[3],
		description: symbol,
		exchange: 'BIVA',
		type: 'stock',
		has_intraday: currentDayInfo,
		has_daily: !currentDayInfo
	};
	return symbolResultItem;
}

async function consultartDatosParaGrafica(isin = null, timeMetric = null, quantity = null) {
	let url = dominio + "/quotes/instrument/isin/" + isin + "/period/" + timeMetric + "/quantity/" + quantity;
	try {
		if (isin) {
			const response = await fetch(url);
			return response.json();
		}
    } catch(error) {
		console.error("Request error", error);
    }
}

export default {
    onReady: (callback) => {
		const configurationData = {
			exchanges: [{
				value: 'BIVA',
				name: 'BIVA',
				desc: 'BIVA',
			}]
		};
		setTimeout(() => callback(configurationData));
    },
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    },
    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
		numLlamado = 0;
		const symbolItem = await transformToSearchSymbolResultItem(symbolName);
		if (!symbolItem) {
			console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('Cannot resolve symbol');
			return;
		}
		// Symbol information object
		const symbolInfo = {
			symbolItem: symbolItem,
			ticker: symbolItem.ticker,
			name: symbolItem.symbol,
			description: symbolItem.symbol,
			type: symbolItem.type,
			visible_plots_set: 'ohlcv',
			session: '0830-1500:23456',
			timezone: "America/Mexico_City",
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: 100,
			has_daily: !symbolItem.has_intraday,
			has_intraday: symbolItem.has_intraday,
			has_seconds: symbolItem.has_intraday,
			has_empty_bars: true,
			volume_precision: 0,
			data_status: 'delayed_streaming',
			delay: 900
		};
		onSymbolResolvedCallback(symbolInfo);
	},
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		try {
			if (numLlamado > 0) {
				onHistoryCallback([], { noData: true });
			} else {
				const data = await consultartDatosParaGrafica(symbolInfo.symbolItem.isin, symbolInfo.symbolItem.timeMetric, symbolInfo.symbolItem.quantity);
				if (data === undefined || data.timeSeries === undefined || data.timeSeries.length < 1) {
					onHistoryCallback([], { noData: true });
					return;
				}
				numLlamado++;
				let bars = [];
				data.timeSeries.forEach(bar => {
						bars = [...bars, {
							time: bar.dateInMillis,
							low: parseFloat(bar.low),
							high: parseFloat(bar.high),
							open: parseFloat(bar.open),
							close: parseFloat(bar.close),
							volume: parseFloat(bar.volume)
						}];
				});
				console.log(`[getBars]: returned ${bars.length} bar(s)`);
				onHistoryCallback(bars, { noData: false });
			}
		} catch (error) {
			console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
    },
    unsubscribeBars: (subscriberUID) => {
    },
};
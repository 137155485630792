import React from "react";
import EsgContainer from "../esg/EsgContainer";
import CuestionarioAmafore from "../asg/cuestionarioAmafore/CuestionarioAmafore";

 
export default function CuestionarioHomologado(props) {
  return <>
    <EsgContainer page="bivaasg-cuestionario-homologado" isCuestionario={true}/>
    <div className="container margin_top_50">
      <CuestionarioAmafore location={props.location} history={props.history} />
    </div>
  </>;
};

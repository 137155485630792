import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./Indice.css";
import * as action from "../../../../stores/actions/index";
import Auxs from "../../../../hoc/auxs/Auxs";
import Boton from "../../../../components/ui/boton/Boton";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import {
  formatDecimal,
  getLan,
  getLocal,
  getUrlDocument,
} from "../../../../stores/utils/utilities";
import Charts from "../../../../components/ui/charts/Charts";
import hojaVerde from "../../../../assets/icons/sustentable/hoja-verde.png";

const lan = getLocal();

const Indice = (props) => {
  const dispatch = useDispatch();

  const [messages, setMessages] = useState(null);
  const [indices, setIndices] = useState(null);

  const [doc, setDoc] = useState(false);
  const [dataSheet, setDataSheet] = useState(false);
  const [fichaTecnica, setFichaTecnica] = useState(false);

  const indicesRedux = useSelector((state) => state.indice.indices);
  const traduccionRedux = useSelector((state) => state.intl.diccionario);

  const docRedux = useSelector((state) => state.documentos.ultimoDia);
  const dataSheetRedux = useSelector((state) => state.documentos.dataSheet);
  const fichaTecnicaRedux = useSelector(
    (state) => state.documentos.fichaTecnica
  );

  useEffect(() => {
    dispatch(action.initIntl(42));
    dispatch(action.initUltimoDiaMesBiva(1));
    dispatch(action.initUltimoDiaDocumentos());
    dispatch(action.getDataSheetInformacionGeneral());
    dispatch(action.initFichaTecnicaDocumento());
  }, []);

  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);

  useEffect(() => {
    if (docRedux) {
      setDoc(docRedux);
    }
  }, [docRedux]);
  useEffect(() => {
    if (dataSheetRedux) {
      setDataSheet(dataSheetRedux);
    }
  }, [dataSheetRedux]);

  useEffect(() => {
    if (fichaTecnicaRedux) {
      setFichaTecnica(fichaTecnicaRedux);
    }
  }, [fichaTecnicaRedux]);

  useEffect(() => {
    if (indicesRedux) {
      let indiceSelected = null;
      const FTSE4Good = 1;
      if (indicesRedux.length > 0) {
        indiceSelected = indicesRedux[FTSE4Good];
      }
      setIndices(indiceSelected);
    }
  }, [indicesRedux]);

  useEffect(() => {
    if (indicesRedux) {
      let indiceSelected = null;
      const FTSE4Good = 1;
      if (indicesRedux.length > 0) {
        indiceSelected = indicesRedux[FTSE4Good];
      }
      setIndices(indiceSelected);
    }
  }, [indicesRedux]);

  const paintGrap = (indice, width = null, height = null, titulo = null) => {
    let data = [];
    let timeSeries = indice.timeSeries;

    for (let key in timeSeries) {
      const date = new Date(timeSeries[key].dateInMillis);
      data.push([date, parseFloat(timeSeries[key].close)]);
    }

    data = data.reverse();
    if (data.length === 0) {
      data.push([new Date(), parseFloat(indice.lastPrice)]);
    }
    return (
        <div className={"row"}>
          <div className={"col-12 col-lg-4 col-xl-3 sin_padding"}>
            <div className={["row", classes.price].join(" ")}>
              <div className="col-12 col-lg-12 sin_padding">
                <div className={classes.titulo_ftse_contenedor}>
                  <div className={""}>
                    <span
                      className={[
                        "row",
                        "texto_blanco texto_50",
                        "nunito bold",
                      ].join(" ")}
                    >
                      {titulo}
                    </span>
                    <span
                      className={[
                        "montse row",
                        "texto_azul texto_50",
                      ].join(" ")}
                    >
                      {formatDecimal(indice.lastPrice)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-9">
            {data && data.length > 0 ? (
              <Charts
                data={data}
                chartType="LineChart"
                key={+new Date()}
                width={width ? width : "190px"}
                height={height ? height : "120px"}
                colorLabels={"#FFF"}
                onlyClose={true}
              />
            ) : null}
          </div>
        </div>
    );
  };

  let ruta = null;
  if (fichaTecnica) {
    ruta = getUrlDocument(fichaTecnica[0].url);
  }


  let botones = null;
  if (dataSheet) {
    botones = dataSheet.map((dataSheet, i) => {
      let isCMS = false;
      let ruta = getUrlDocument(dataSheet.url);
      if (ruta.includes("/cms/")) {
        if (ruta.includes("?")) {
          ruta = `${ruta}&download=true`;
          isCMS = true;
        } else {
          ruta = `${ruta}?download=true`;
          isCMS = true;
        }
      }
      return (
        <Boton
          tipo={isCMS ? 6 : 1}
          texto={dataSheet.title}
          icono_derecho="fas fa-long-arrow-alt-right"
          ruta={ruta}
          colorBoton={i % 2 ? "blanco_verde" : "verde"}
        />
      );
    });
  }

  return (
    <IntlProvider locale={lan} messages={messages}>
      <Auxs>
        <div className={["row", classes.backGroundIndex].join(" ")}>
          <div className={"container"}>

            <div className={[classes.fondoIndice].join(" ")}>
              <div className={classes.container_index}>
                {indices
                  ? paintGrap(
                    indices,
                    "100%",
                    "350px",
                    getLan() === "es"
                      ? "Índice FTSE4Good BIVA"
                      : "FTSE4Good BIVA Index"
                  )
                  : null}
              </div>
            </div>

            <div className="row margen_top_30">
              <div className="col sin_padding_L">
                <div className={[classes.text_container].join(" ")}>
                  <span className="montse texto_16 regular texto_blanco">
                    <FormattedHTMLMessage
                      id="informacionmercado.inidice.informacion.introduccion"
                      defaultMessage=" "
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={["container", classes.sg].join(" ")}>
          <div className="row margen_top_50">
            <p>
              <span
                className={[
                  "texto_16 montse regular",
                  classes.contenedorTexto,
                ].join(" ")}
              >
                <FormattedHTMLMessage
                  id="informacionmercado.inidice.informacion.ftserussell"
                  defaultMessage=" "
                />
              </span>
            </p>
          </div>

          <div className="row margen_top_30 margin_bottom_300">
            <div className="col-12 sin_padding_L">
            <div
                  className={[
                    "main_subtitle",
                    "nunito",
                    "containerTituloSg",
                    "sin_padding"
                  ].join(" ")}
                >
                  <img
                    src={hojaVerde}
                    alt="bivasg"
                    className={["slash", "imgSg"].join(" ")}
                  />
                  <h2 className={"tituloSg nunito texto_30 bold texto_verde_oscuro"}>{messages ? messages[`informacionmercado.inidice.mayorinformacion`] : null}</h2>
                </div>

           
            </div>

            <div className="row margen_top_30">{botones}</div>
          </div>
        </div>

      </Auxs>
    </IntlProvider>
  );
};

export default Indice;

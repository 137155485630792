import React, { Component } from "react";
import classes from "./TabsVideos.css";

import Auxs from "../../../hoc/auxs/Auxs";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
import Tabs from "../tabs/Tabs";
import Loader from "../../loader/Loader";
import queryString from "query-string";
import Pagination from "../../../components/ui/pagination/Pagination";

class TabsVideos extends Component {
  state = {
    tabId: null,
    messages: null,
    lan: getLocal(),
    categorias: [],
    loader: true,
    selectedVideo: "",
    videos: [],
    tab: 0,
    tabSelected: null,
    itemsPerPage: 9,
    activePage: 1,
    paginacion: null,
  };

  componentDidMount() {
    this.props.onInitIntl();

    this.initData();
  }

  initData(){
    let paramsString = queryString.parse(this.props.location.search);
    const tab =
    paramsString["tab"] !== undefined &&
    paramsString["tab"] !== "null" &&
    paramsString["tab"] !== null
      ? paramsString["tab"]
      : null;
      
    this.setState({ tab: tab });
    this.selectTab(this.props.tabs, tab);

  }

  componentDidUpdate(prevProps) {

    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.tabs !== prevProps.tabs) {
      this.selectTab(this.props.tabs, this.state.tab);
    }

    if (this.props.nuevos !== prevProps.nuevos) {
      if (this.props.nuevos.length > 0) {
        let lista_ver = [];
        const videos = this.props.nuevos ? this.props.nuevos.filter(video => video.snippet.thumbnails.default !== undefined) : [];
          let index_final = this.state.itemsPerPage;
          let index_inicial = 0;
          lista_ver = videos.slice(index_inicial, index_final);
        this.setState({
          loader: false,
          videos: videos,
          lista_ver: lista_ver,
          selectedVideo:
            lista_ver && lista_ver.length > 0
              ? lista_ver[0].id.videoId
              : null,
          activePage: 1 
        });
      }
    }
    if (this.props.paginacionVideos !== prevProps.paginacionVideos) {
      this.setState({paginacion: this.props.paginacionVideos})
    }
    if (this.props.paginacionVideosByField !== prevProps.paginacionVideosByField) {
      this.setState({paginacion: this.props.paginacionVideosByField})
    }
    if (this.props.videosByField !== prevProps.videosByField) {
      if (this.props.videosByField.length > 0) {
        let lista_ver = [];
        const videos = this.props.videosByField ? this.props.videosByField : [];
          let index_final = this.state.itemsPerPage;
          let index_inicial = 0;
          lista_ver = videos.slice(index_inicial, index_final);
        this.setState({
          loader: false,
          videos: videos,
          lista_ver: lista_ver,
          selectedVideo:
            lista_ver && lista_ver.length > 0
              ? lista_ver[0].id.videoId
              : null,
          activePage: 1 
        });
      
      }
    }
   
  }

  selectTab = (tabs, tabinitUrl) => {
    const tabInit = tabinitUrl;
    let tab = [];
    let tabSelected = 0;
    let fieldId = 0;
    if (tabs) {
      tab = tabs;
      for (let i = 0; i < tabs.length; i++) {
        const element = tabs[i];
        if (element.text === tabInit) {
          tabSelected = i;
          break;
        }
      }
      fieldId = tab[tabSelected].fieldId;
      if (fieldId === "0") {
        this.props.getNuevosVideos(this.props.maxResults ? this.props.maxResults : null);
      } else {
        this.props.getVideos(tab[tabSelected].fieldId, this.props.maxResults ? this.props.maxResults : null);
      }
    }
    this.setState({ categorias: tab, tabSelected: tabSelected, tabId: fieldId, activePage: 1 });
  }

  changeCurrentVideo = video => {
    this.setState({ selectedVideo: video.id.videoId });

    this.props.history.push("#video");
  };

  onChangeItemSelected = (index = 0, id) => {
    this.setState({
      tabId : id,
      tab: index,
      loader: true,
      videos: [],
      activePage: 1
    });
    if (id === "0") {
      if (this.props.nuevos.length > 0) {
        this.setState({
          loader: false,
          videos: this.props.nuevos,
          selectedVideo: this.props.nuevos[0].id.videoId
        });
      }else{
        this.setState({
            loader: false,
          });
      }
    } else {
      this.props.getVideos(id, this.props.maxResults ? this.props.maxResults : null);
    }
  };
  
  handlePageChange(pageNumber) {
      if (pageNumber !== this.state.activePage) {
        const videos = this.state.videos;
        let lista_ver = [];
        let index_final = pageNumber * this.state.itemsPerPage;
        let index_inicial = index_final - this.state.itemsPerPage;
        lista_ver = videos.slice(index_inicial, index_final);
        this.setState({
          activePage: pageNumber,
          lista_ver: lista_ver,
        });
      }
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader loaderDisable={disable => this.setState({ loader: disable })} />
      );
    }
    let tabs = null;
    let videos = null;
    if (this.state.categorias.length > 0) {
      const color = this.props.location.pathname.includes("biva-sostenible") ? "verde" : "azul";
      tabs = (
        <Tabs
          key={this.props.tab}
          data={this.state.categorias}
          tipo={0}
          clicked={(i, id) => this.onChangeItemSelected(i, id)}
          activaTabInicial={this.state.tabSelected}
          color={color}
        />
      );
    }
    if (this.state.videos.length > 0) {
      videos = this.state.lista_ver.map(video => {
        return (
          <div
            className={["col-6 col-md-4"].join(" ")}
            onClick={this.changeCurrentVideo.bind(this, video)}
          >
            <div className={[classes.containerDiv].join(" ")}>
              <div className={["row", classes.container].join(" ")}>
                <div
                  className={[
                    " sin_padding col-12 sin_padding",
                    classes.videoContainer
                  ].join(" ")}
                >
                  <div className={classes.thumbanil}>
                    <img src={video.snippet.thumbnails.medium.url} alt="" />
                  </div>
                </div>
                <div
                  className={[
                    " sin_padding col-12 sin_padding",
                    classes.textoVideoContainer
                  ].join(" ")}
                >
                  <div
                    className={[" sin_padding", classes.contenedorTitulo].join(
                      " "
                    )}
                  >
                    <span
                      className={[
                        classes.titulo_video,
                        "color_azul montse texto_18"
                      ].join(" ")}
                    >
                      {video.snippet.title}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    let paginador = (
      <div className={"col-12 sin_padding"}>
        <Pagination
          claseTipo={2}
          numRows={
            this.state.videos.length 
          }
          paginas={5}
          itemsPerPage={this.state.itemsPerPage ? this.state.itemsPerPage : 12}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </div>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className=" width_cien" id="video">
            <iframe
              width="100%"
              height="415"
              src={"https://www.youtube.com/embed/" + this.state.selectedVideo}
              frameBorder="0"
              title="videos"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
          <div className=" width_cien margin_top_30">
            {tabs}
            <div className={["row  relativo", classes.fondo].join(" ")}>
              {videos}
              {paginador}
            </div>
            {loader}
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    tabs: state.videos.tabs,
    nuevos: state.videos.nuevosVideos ? state.videos.nuevosVideos.items : [],
    paginacionVideos: state.videos.nuevosVideos,
    paginacionVideosByField: state.videos.videosByField,
    videosByField: state.videos.videosByField
      ? state.videos.videosByField.items
      : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(75));
    },
    getNuevosVideos: maxresults => {
      dispatch(action.getNuevosVideos(maxresults));
    },
    getVideos: (field, maxresults)=> {
      dispatch(action.getVideosByField(field, maxresults));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsVideos);

import React, { Component } from "react";

import Auxs from "../../../hoc/auxs/Auxs";
import classes from "./QueremosEscucharte.css";
import Boton from "../../../components/ui/boton/Boton";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import Modal from "../../../components/ui/modal/Modal";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";

class QueremosEscucharte extends Component {
  state = {
    showModal: false,
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl(15);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };



  render() {
    const modal = (
      <Auxs>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} tipo_mensaje={2} onCloseModal={this.onToggleModalHandler} />
      </Auxs>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">

      <Auxs>
        <div className="row">
          <div className="col sin_padding_L">
            <h1 className={["nunito", "texto_42"].join(" ")}><FormattedMessage id="nosotros.capitalhumano.queremosescucharte.titulo1" defaultMessage=" " /></h1>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={classes.textSection}>
              <p className={["montse", "texto_16"].join(" ")}>
              <FormattedMessage id="nosotros.capitalhumano.queremosescucharte.introduccion1" defaultMessage=" " />
              
              </p>
            </div>
          </div>
        </div>

        <div className="margen_top_30">
          <div className={" row"}>
            <div className="row sin_padding">
              <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
                <div className={"icono_izquierdo"}>
                  <i className={["fas fa-phone-alt icon"].join(" ")} />
                </div>
                <div className="col sin_padding_L">
                  <p className={["montse texto_16 negrita"].join(" ")}><FormattedMessage id="nosotros.capitalhumano.queremosescucharte.titulo.comunicate" defaultMessage=" " /></p>
                  <p className={"color_azul semi_bold montse texto_16"}><FormattedMessage id="nosotros.capitalhumano.queremosescucharte.numero.comunicate" defaultMessage=" " /></p>
                </div>
              </div>
              <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
                <div className={"icono_izquierdo"}>
                  <i className={["far fa-envelope icon"].join(" ")} />
                </div>
                <div className="col sin_padding_L">
                  <p className={["montse texto_16 negrita"].join(" ")}><FormattedMessage id="nosotros.capitalhumano.queremosescucharte.titulo.correo" defaultMessage=" " /></p>
                  <p className={"color_azul semi_bold montse texto_16"}><FormattedMessage id="nosotros.capitalhumano.queremosescucharte.direccion.correo" defaultMessage=" " /></p>
                </div>
              </div>
              <div className="row col-12 sin_padding_L margen_top_30">
                <div className={classes.margenes_boton}>
                  <Boton
                    onClick={this.onToggleModalHandler.bind(this)}
                    tipo={4}
                    texto={getLan() === "es" ?  "Enviar mensaje" : "Send message"}
                    icono_derecho="fas fa-long-arrow-alt-right"
                    colorBoton={""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {modal}
      </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueremosEscucharte);
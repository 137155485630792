import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils/utilities';

const initialState = {
    casas: null
}

const setInitCasas = (state, action) => {
    return updateObject(state, action);
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_CASAS_SUCCESS: return setInitCasas(state, action);
        default: return state;
    }
}

export default reducer;


import React, { Component } from "react";
import classes from "./FilterDocumentosDefinitivos.css";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";

import Auxs from "../../../hoc/auxs/Auxs";
import Input from "../input/Input";

import * as actions from "../../../stores/actions/index";
import { getLan } from "../../../stores/utils/utilities";

const idForClavesWarrant = [5];

const renderInput = ({
  input,
  id = null,
  label,
  clases = null,
  options = null,
  type = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel,
  isMultiple = null,
  onInputChange
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      checked: checked,
      className: clases ? clases : null,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    type: type,
    label: label,
    isMultiple: isMultiple,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };

  return (
    <div className="form-group">
      {label ? (
        <label
          className={[elementType === "checkbox" ? classes.myLabel : null, "texto_14", "montse", "regular"].join(" ")}
          for={input.name}
        >
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterDocumentosDefinitivos extends Component {
  constructor(props) {
    super(props);
    this.formFilterAvisos = React.createRef();
  }

  state = {
    toggleFilter: true,
    selectedFilters: {
      claves_cotizacion: [],
      instrumentos: [],
      tipo_documentos: [],
      serie: [],
      claves_warrant: []
    },
    form_filters: {
      claves_cotizacion: {
        value: ""
      },
      claves_warrant: {
        value: ""
      },
      instrumentos: {
        value: ""
      },
      tipo_documentos: {
        value: []
      },
      serie: {
        value: ""
      },
      errorDate: false
    },
    itemsPerPage: 10,
    idExists: false,
    tiposBonoASG:[]
  };

  componentDidMount() {
    this.props.onFilterClavesCotizacion(this.props.tipoBono);
    this.setState({
      tiposBonoASG: this.props.tipoBono,
    });
  }

  componentDidUpdate(prevProps) {
    let emisoraSic = false;
    let emisoraFondo = false;
  
    if (prevProps.instrumentos !== this.props.instrumentos) {
      if (this.props.instrumentos !== null && this.props.instrumentos !== undefined && this.props.instrumentos.length > 0) {
        const emisoraSicObj = this.props.instrumentos.find(item => [17, 18, 19].includes(item.id));
        const emisoraFondoObj = this.props.instrumentos.find(item => [21].includes(item.id));
        if (emisoraSicObj) {
          emisoraSic = true;
        }
        if (emisoraFondoObj) {
          emisoraFondo = true;
        }
        this.props.setEmisoraFondo(emisoraFondo);
        this.props.setEmisoraSic(emisoraSic);
      }
    }
    
    if (prevProps.tipoBono !== this.props.tipoBono){
      this.onResetFilter();
      this.props.onFilterClavesCotizacion(this.props.tipoBono);
    }
    
  }
  
  onCheckIfIdForWarrantExistsHandler = (value, type) => {
    let exists = false;
    let val = null;

    val = +value;
    idForClavesWarrant.forEach(item => {
      if (item === val) exists = true;
    });

    return exists;
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type) => {
    let newValue = null;
    let newLabel = null;
    let newClave = null;

    if (type === 2 || type === 4) {
      newValue = newLabel = value;
    } else if (type === 0) {
      newValue = value.split("_")[0];
      newLabel = value.split("_")[1];
      newClave = value.split("_")[2];
    } else {
      newValue = value.split("_")[0];
      newLabel = value.split("_")[1];
    }

    return [newValue, newLabel, newClave];
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;
    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };
  // Retorna el id
  onSplitLabelHandler = options => {
    let newValues = null;
    if (!Array.isArray(options)) {
      newValues = options.split("_")[2];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la clave de cotizacion
    const valuesClavesCotizacion =
      form.claves_cotizacion.value && form.claves_cotizacion.value !== ""
        ? this.onSplitValueHandler(form.claves_cotizacion.value)
        : "";
    const claveCotizacion =
      form.claves_cotizacion.value && form.claves_cotizacion.value !== ""
        ? this.onSplitLabelHandler(form.claves_cotizacion.value)
        : "";
    // Se obtiene el valor de instrumentos
    const valuesInstrumentos =
      form.instrumentos.value && form.instrumentos.value !== "" ? this.onSplitValueHandler(form.instrumentos.value) : "";
    // Se obtiene el valor de clave warrant
    const valuesClaveWarrant =
      form.claves_warrant.value && form.claves_warrant.value !== "" ? this.onSplitValueHandler(form.claves_warrant.value) : "";
    // Se obtiene el valor de tipo de document
    const valuesTipoDocumento =
      form.tipo_documentos.value && form.tipo_documentos.value.length > 0
        ? this.onSplitValueHandler(form.tipo_documentos.value)
        : [];
    // Se obtiene el valor de serie
    const valuesSerie = form.serie.value && form.serie.value !== "" ? this.onSplitValueHandler(form.serie.value) : "";

    return [valuesClavesCotizacion, valuesInstrumentos, valuesClaveWarrant, valuesTipoDocumento, valuesSerie, claveCotizacion];
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    const [
      valuesClavesCotizacion,
      valuesInstrumentos,
      valuesClaveWarrant,
      valuesTipoDocumento,
      valuesSerie,
      claveCotizacion
    ] = this.onGetAllValuesFormFilter();

    let wasFiltered = false;
    if (
      valuesClavesCotizacion !== "" ||
      valuesInstrumentos !== "" ||
      valuesClaveWarrant !== "" ||
      valuesTipoDocumento !== "" ||
      valuesSerie !== ""
    ) {
      wasFiltered = true;
    }

    // Configurando valores para campo clave cotizacion, campo instrumentos, campo clave warrants, campo tipo documento, campo serie, primera página de la busqueda,
    this.props.setParams(
      valuesClavesCotizacion,
      valuesInstrumentos,
      valuesSerie,
      valuesTipoDocumento,
      valuesClaveWarrant,
      1,
      wasFiltered,
      claveCotizacion,
      false
    );
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["claves_cotizacion", form.claves_cotizacion.value, selectedFilters.claves_cotizacion];
      case 1:
        return ["instrumentos", form.instrumentos.value, selectedFilters.instrumentos];
      case 2:
        return ["serie", form.serie.value, selectedFilters.serie];
      case 3:
        return ["tipo_documentos", form.tipo_documentos.value, selectedFilters.tipo_documentos];
      case 4:
        return ["claves_warrant", form.claves_warrant.value, selectedFilters.claves_warrant];
      default:
        break;
    }
    
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (type !== 3) {
      if (values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];
      const stateExists = newStateValues.find(option => option === values);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== values);
      } else {
        if (values !== "") {
          newStateValues.push(values);
        } else {
          newStateValues = [];
        }
      }
    }

    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];

    if (type !== 3) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExist = newFilterValues.find(option => option.label === label);
      if (filterExist) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
      } else {
        if (values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        } else {
          newFilterValues = [];
        }
      }
    }

    return newFilterValues;
  };

  onInputSelectedHandler = (event, type = null, dateType = null) => {
    let actualValue = type !== 3 ? event.target.value : event.target.options;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let idExists = this.state.idExists;

    let newValue = null;
    let newLabel = null;
    let newClave = null;

    // Solo para los campos distintos a tipo documentoinstrumento y tipo valor
    if (type !== 3) {
      [newValue, newLabel, newClave] = this.onGetValueLabelAndTypeHandler(actualValue, type);
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

    // Solo para el campo tipo de documento
    if (type === 3) {
      const options = [...actualValue];
      const indexSelected = actualValue.selectedIndex && actualValue.selectedIndex !== -1 ? actualValue.selectedIndex : 0;

      newValue = options[indexSelected].value;
      newLabel = options[indexSelected].value.split("_")[1];

      actualValue = newValue;
    }

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);

    const auxLabel = type === 0 ? newClave : newLabel;
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, auxLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClavesCotizacion,
      valuesInstrumentos,
      valuesClaveWarrant,
      valuesTipoDocumento,
      valuesSerie
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      // Clave cotización
      // Limpiando valores para campo instrumentos
      form.instrumentos.value = "";
      selectedFilters.instrumentos = [];
      //Limpiando valores para campo clave warrant
      form.claves_warrant.value = "";
      selectedFilters.claves_warrant = [];
      // Limpiando valores para campo tipo documento
      form.tipo_documentos.value = [];
      selectedFilters.tipo_documentos = [];
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];

      if (valuesClavesCotizacion !== "") {
        // Filtrando valores para campo instrumento
        this.props.onFilterIntrumentos(valuesClavesCotizacion,this.props.tipoBono );
      }

      idExists = false;
      // Setenado label para componente padre
      this.props.setClaveCotizacionLabel(auxLabel);
    } else if (type === 1) {
      // Instrumentos
      //Limpiando valores para campo clave warrant
      form.claves_warrant.value = "";
      selectedFilters.claves_warrant = [];
      // Limpiando valores para campo tipo documento
      form.tipo_documentos.value = [];
      selectedFilters.tipo_documentos = [];
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];

      let exists = null;
      if (valuesInstrumentos !== "") {
        // Filtrando valores para campo tipo documento
        this.props.onFilterTipoDocumentos(valuesClavesCotizacion, valuesInstrumentos,null,null,this.props.tipoBono);
        // Validando si el valor seleccionado es una clave warrant
        exists = this.onCheckIfIdForWarrantExistsHandler(valuesInstrumentos);

        if (exists) {
          idExists = true;
          this.props.onFilterClavesWarrant(valuesClavesCotizacion, valuesInstrumentos, this.props.tipoBono);
        } else {
          this.props.onFilterSeries(valuesClavesCotizacion, valuesInstrumentos, null, this.props.tipoBono);
          idExists = false;
        }
      } else {
        idExists = false;
      }
    } else if (type === 2) {
      // Serie
      // Limpiando valores para campo tipo documento
      form.tipo_documentos.value = [];
      selectedFilters.tipo_documentos = [];

      if (idExists) {
        this.props.onFilterTipoDocumentos(valuesClavesCotizacion, valuesInstrumentos, valuesSerie, valuesClaveWarrant, this.props.tipoBono);
      } else if (valuesSerie !== "") {
        this.props.onFilterTipoDocumentos(valuesClavesCotizacion, valuesInstrumentos, valuesSerie, null, this.props.tipoBono);
      }
    } else if (type === 3) {
      // Tipo Documentos
      // Pass
    } else if (type === 4) {
      // Clave warrant
      if (idExists) {
        // Limpiando valores para campo tipo documento
        form.tipo_documentos.value = [];
        selectedFilters.tipo_documentos = [];
        // Filtrando valores para campo tipo documento
        this.props.onFilterTipoDocumentos(valuesClavesCotizacion, valuesInstrumentos, null, valuesClaveWarrant,this.props.tipoBono);
        // Limpiando valores para campo serie
        form.serie.value = "";
        selectedFilters.serie = [];
        // Filtrando valores para campo serie
        this.props.onFilterSeries(valuesClavesCotizacion, valuesInstrumentos, valuesClaveWarrant,this.props.tipoBono);
      }
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      idExists: idExists
    });

    this.onFilterDataByEvent();
  };

  onDeleteSelectedFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let idExists = this.state.idExists;

    let newStateValues = null;
    let newFilterValues = null;

    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

    const auxValue = item.type !== 3 ? "" : item.value;
    newStateValues = this.onChangeStateHandler(stateValues, auxValue, item.type);

    newFilterValues = this.onChangeFilterHandler(filterValues, auxValue, item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    if (item.type === 0) {
      // Claves cotización
      // Limpiando valores para campo instrumentos
      form.instrumentos.value = "";
      selectedFilters.instrumentos = [];
      //Limpiando valores para campo clave warrant
      form.claves_warrant.value = "";
      selectedFilters.claves_warrant = [];
      // Limpiando valores para campo tipo documento
      form.tipo_documentos.value = [];
      selectedFilters.tipo_documentos = [];
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];

      idExists = false;
      // Setenado label para componente padre
      this.props.setClaveCotizacionLabel(null);
    } else if (item.type === 1) {
      //Limpiando valores para campo clave warrant
      form.claves_warrant.value = "";
      selectedFilters.claves_warrant = [];
      // Limpiando valores para campo tipo documento
      form.tipo_documentos.value = [];
      selectedFilters.tipo_documentos = [];
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];

      idExists = false;
    } else if (item.type === 2) {
      // Serie
      // Limpiando valores para campo tipo documento
      form.tipo_documentos.value = [];
      selectedFilters.tipo_documentos = [];
    } else if (item.type === 3) {
      // Tipo documentos
      // Pass
    } else if (item.type === 4) {
      // Claves warrant
      // Limpiando valores para campo tipo documento
      form.tipo_documentos.value = [];
      selectedFilters.tipo_documentos = [];
      // Limpiando valores para campo serie
      form.serie.value = "";
      selectedFilters.serie = [];
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      idExists: idExists
    });

    this.onFilterDataByEvent();
  };

  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [
      ...this.state.selectedFilters.claves_cotizacion,
      ...this.state.selectedFilters.instrumentos,
      ...this.state.selectedFilters.tipo_documentos,
      ...this.state.selectedFilters.serie,
      ...this.state.selectedFilters.claves_warrant
    ];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>{ filter.label!==null ? filter.label : filter.value}</span>
              <span className={classes.filterIconButton} onClick={() => this.onDeleteSelectedFilterHandler(filter)}>
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onSubmitFilter = () => {
    // Pass
  };

  onResetFilter = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    let idExists = this.state.idExists;

    // Limpiando valores para campo clave cotización
    form.claves_cotizacion.value = "";
    selectedFilters.claves_cotizacion = [];
    // Limpiando valores para campo instrumentos
    form.instrumentos.value = "";
    selectedFilters.instrumentos = [];
    //Limpiando valores para campo clave warrant
    form.claves_warrant.value = "";
    selectedFilters.claves_warrant = [];
    // Limpiando valores para campo tipo documento
    form.tipo_documentos.value = [];
    selectedFilters.tipo_documentos = [];
    // Limpiando valores para campo serie
    form.serie.value = "";
    selectedFilters.serie = [];

    idExists = false;

    this.props.setClaveCotizacionLabel(null);
    this.props.setParams(null, null, null, null, null, 1, false, null, true);

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      idExists: idExists
    });
  };

  onToggleFilterHandler = () => {
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  onSelectOptionsHandler = (options = null, type = null, label = null) => {

    let opt = [];

    if (type === 0) {
      options.forEach(option => {
        opt.push({
          nombre: option,
          id: `${option}`
        });
      });
    } else if (type !== 2 && type !== 4) {
      options.forEach(option => {
        opt.push({
          nombre: type === 0 ? option.clave : option.nombre,
          id: `${option.id}_${option.nombre}`
        });
      });
    } else {
      options.forEach(option => {
        opt.push({
          nombre: option,
          id: option
        });
      });
    }
    return opt;
  };

  printFrameModelHandler = () => {
    const selectedFilters =
      this.state.selectedFilters.claves_cotizacion.length > 0 ? this.onPrintSelectedFiltersHandler() : null;
    return (
      <Auxs>
        <div
          className={["row", classes.firstSectionFilterContainer, this.state.toggleFilter ? classes.noBorderRadius : null].join(
            " "
          )}
        >
          <div className="col col-lg-3 text-center sin_padding_L">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button type="button" className="btn" onClick={this.onToggleFilterHandler}>
                <i className="fas fa-filter" />

                <span>
                  {this.state.toggleFilter
                    ? getLan() === "es"
                      ? "Cerrar Filtros"
                      : "Close"
                    : getLan() === "es"
                      ? "Filtrar"
                      : "Filter"}
                </span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={["row", classes.secondSectionFilterContainer, !this.state.toggleFilter ? classes.hidden : null].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    const clavesCotizacionOptions = this.props.claves_cotizacion
       ? this.onSelectOptionsHandler(this.props.claves_cotizacion, 0)
      : [];

    const instrumentosOptions = this.props.instrumentos ? this.onSelectOptionsHandler(this.props.instrumentos) : [];

    const tipoDocumentosOptions = this.props.tipoDocumentos ? this.onSelectOptionsHandler(this.props.tipoDocumentos) : [];

    const serieOptions = this.props.series ? this.onSelectOptionsHandler(this.props.series, 2) : [];

    const clavesWarrantOptions = this.props.claves_warrant ? this.onSelectOptionsHandler(this.props.claves_warrant, 4) : [];

    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitFilter}
              initialValues={{
                claves_cotizacion: this.state.form_filters.claves_cotizacion.value,
                instrumentos: this.state.form_filters.instrumentos.value,
                tipo_documentos: this.state.form_filters.tipo_documentos.value,
                serie: this.state.form_filters.serie.value,
                claves_warrant: this.state.form_filters.claves_warrant.value
              }}
              render={({
                optionsClavesCotizacion = clavesCotizacionOptions,
                optionsInstrumentos = instrumentosOptions,
                optionsTipoDocumentos = tipoDocumentosOptions,
                optionsSerie = serieOptions,
                optionsClavesWarrant = clavesWarrantOptions,
                handleSubmit,
                form
              }) => (
                  <form ref={this.formFilterAvisos} onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4  ">
                        <Field
                          name="claves_cotizacion"
                          id="claves_cotizacion"
                          elementType="select"
                          clases="custom-select"
                          label={getLan() === "es" ? "Clave cotizacion" : "Ticker"}
                          options={optionsClavesCotizacion}
                          onInputChange={event => this.onInputSelectedHandler(event, 0)}
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          "col-12 col-sm-12 col-md-6 col-lg-4",
                          !this.state.form_filters.claves_cotizacion.value ||
                            this.state.form_filters.claves_cotizacion.value === ""
                            ? classes.hidden
                            : null
                        ].join(" ")}
                      >
                        <Field
                          name="instrumentos"
                          id="instrumentos"
                          elementType="select"
                          clases="custom-select"
                          label={getLan() === "es" ? "Instrumento" : "Security type"}
                          options={optionsInstrumentos}
                          onInputChange={event => this.onInputSelectedHandler(event, 1)}
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          "col-12 col-sm-12 col-md-6 col-lg-4",
                          this.state.idExists ||
                            !this.state.form_filters.instrumentos.value ||
                            this.state.form_filters.instrumentos.value === "" || this.state.form_filters.instrumentos.value.toString().startsWith('1_') || this.state.form_filters.instrumentos.value.toString().startsWith('21_')
                            ? classes.hidden
                            : null
                        ].join(" ")}
                      >
                        <Field
                          name="serie"
                          id="serie"
                          elementType="select"
                          clases="custom-select"
                          label="Serie"
                          options={optionsSerie}
                          onInputChange={event => this.onInputSelectedHandler(event, 2)}
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          "col-12 col-sm-12 col-md-6 col-lg-4",
                          !this.state.form_filters.claves_cotizacion.value ||
                            this.state.form_filters.claves_cotizacion.value === "" ||
                            !this.state.idExists
                            ? classes.hidden
                            : null
                        ].join(" ")}
                      >
                        <Field
                          name="claves_warrant"
                          id="claves_warrant"
                          elementType="select"
                          clases="custom-select"
                          label={getLan() === "es" ? "Clave warrant" : "Warrant key"}
                          options={optionsClavesWarrant}
                          onInputChange={event => this.onInputSelectedHandler(event, 4)}
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          "col-12 col-sm-12 col-md-6 col-lg-4",
                          !this.state.form_filters.claves_cotizacion.value ||
                            this.state.form_filters.claves_cotizacion.value === "" ||
                            !this.state.form_filters.instrumentos.value ||
                            this.state.form_filters.instrumentos.value === ""
                            ? classes.hidden
                            : null
                        ].join(" ")}
                      >
                        <Field
                          name="tipo_documentos"
                          id="tipo_documentos"
                          elementType="select"
                          clases="custom-select"
                          label={getLan() === "es" ? "Tipo documento" : "Document type"}
                          isMultiple={true}
                          options={optionsTipoDocumentos}
                          onInputChange={event => this.onInputSelectedHandler(event, 3)}
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          "col-12 col-sm-12 col-md-6 col-lg-4",
                          !this.state.form_filters.claves_warrant.value || this.state.form_filters.claves_warrant.value === ""
                            ? classes.hidden
                            : null
                        ].join(" ")}
                      >
                        <Field
                          name="serie"
                          id="serie"
                          elementType="select"
                          clases="custom-select"
                          label="Serie"
                          options={optionsSerie}
                          onInputChange={event => this.onInputSelectedHandler(event, 2)}
                          component={renderInput}
                        />
                      </div>
                    </div>
                    <hr />
                    {this.sectionButtonsHandler(form)}
                  </form>
                )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  sectionButtonsHandler = form => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilter()}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandler();
    return (
      <Auxs>
        <div className={[(this.props.isSustentable ? classes.sustentable : null), "row"].join(" ")}>
          <div className="col margin_bottom_50 sin_padding">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  return {
    claves_cotizacion: state.listadoValores.ddClavesCotizacion,
    instrumentos: state.listadoValores.ddInstrumentos,
    tipoDocumentos: state.listadoValores.ddTipoDocumentos,
    series: state.listadoValores.ddSerie,
    claves_warrant: state.listadoValores.ddClavesWarrant
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterClavesCotizacion: (tipoBono = null) => {
      dispatch(actions.filterDdClavesCotizacion(tipoBono));
    },
    onFilterIntrumentos: (clave = null, tipoBono = null) => {
      dispatch(actions.filterDdInstrumentos(clave,tipoBono));
    },
    onFilterTipoDocumentos: (claveCotizacionId, instrumentoId, serieId, claveWarrant, tipoBono = null) => {
      dispatch(actions.filterDdTipoDocumentos(claveCotizacionId, instrumentoId, serieId, claveWarrant,tipoBono));
    },
    onFilterSeries: (claveCotizacionId, instrumento, claveWarrant,tipoBono = null) => {
      dispatch(actions.filterDdSerie(claveCotizacionId, instrumento, claveWarrant, tipoBono));
    },
    onFilterClavesWarrant: (claveCotizacion, tiposDocumentos, tipoBono = null) => {
      dispatch(actions.filterDdClavesWarrant(claveCotizacion, tiposDocumentos, tipoBono));
    },
    onFilterDocumentosDefinitivos: params => {
      dispatch(actions.filterDocumentosDefinitivos(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterDocumentosDefinitivos);

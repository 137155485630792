import React, { Component } from "react";
import classes from "./ToggleBox.css";

import Auxs from "../../../hoc/auxs/Auxs";
import { getUrlDocument } from "../../../stores/utils/utilities";

class ToggleBox extends Component {
  state = {
    toggleBox: []
  };

  componentDidMount() {
    const items = [...Array(this.props.terminos.length)].map(() => false);

    this.setState({
      toggleBox: items
    });
  }

  onToggleBoxHandler = itemIndex => {
    this.setState(prevState => {
      const boxState = [...prevState.toggleBox];
      boxState[itemIndex] = !boxState[itemIndex];
      return { toggleBox: boxState };
    });
  };

  showToggleBoxes = terminos => {
    return terminos.map((termino, index) => {
      let itemIndex = index;

      let img = <i className={[termino.word ? "fas fa-book" : "far fa-file-image", classes.iconClass].join(" ")} />;
      if (termino.imagen && termino.imagen !== "") {
        let imgPath = null;
        let ruta = null;
        ruta = getUrlDocument(termino.imagen);

        if (ruta === null) {
          imgPath = require(`../../../assets/icons/${termino.imagen}`);
        } else {
          imgPath = ruta;
        }
        img = <img src={imgPath} alt="" className={classes.imgClass} />;
      }

      const classForMoveToLeft = this.props.moveToLeftClass ? classes.moveLeftButtonTwo : classes.moveLeftButton;

      return (
        <div className={classes.toggleBoxContainer} key={index}>
          <div className="row">
            <div className={["col", "col-lg-1", "d-none", "d-lg-block"].join(" ")} />
            <div className="col col-sm-12 col-sm-12 col-md-12 col-lg-11 sin_padding">
              <div
                className={[
                  "montse",
                  "texto_16",
                  classes.descriptionContainer,
                  this.state.toggleBox[index] ? classes.showInfo : classes.hideInfo
                ].join(" ")}
              >
                <div className="textContainer" dangerouslySetInnerHTML={{ __html: termino.texto_html ? termino.texto_html : termino.definition ? termino.definition: null }}></div>
              </div>
            </div>
          </div>
          <div className={["row", this.state.toggleBox[index] ? classes.hideInfoRow : null].join(" ")}>
            <div className="col-2 col-sm-2 col-md-3 col-lg-2 text-center">
              <div
                className={[classes.imgContainer, this.state.toggleBox[index] ? classes.hideInfo : classes.showInfo].join(" ")}
              >
                {img}
              </div>
            </div>
            <div className={["col-10 col-sm-10 col-md-9 col-lg-8 sin_padding_R", classes.padding_r].join(" ")}>
              <div
                className={[
                  "nunito",
                  "texto_30",
                  classes.titleContainer,
                  this.state.toggleBox[index] ? classes.hideInfo : classes.showInfo
                ].join(" ")}
              >
                <p>
                  {/* {termino.titulo_encabezado} */}
                  {termino.titulo_encabezado ? termino.titulo_encabezado : termino.word ? termino.word : null}{" "}
                  <span className={["nunito", "texto_16", classes.terminoSubtitle].join(" ")}>
                    {termino.subtitulo ? termino.subtitulo : null}
                  </span>
                </p>
              </div>
            </div>
            <div
              className={[
                "col",
                "col-lg-2",
                "text-center",
                "d-none",
                "d-lg-block",
                this.state.toggleBox[index] ? classForMoveToLeft : classes.moveRigthButton
              ].join(" ")}
            >
              <div className={[classes.toggleButtonContainer, this.props.isVerde ? classes.toggleButtonBkgVerde : classes.toggleButtonBkg].join(" ")}>
                <div className={[classes.fondoIcono].join(" ")}></div>
                <i
                  className={[this.state.toggleBox[index] ? "fas fa-times-circle" : "fas fa-plus-circle"].join(" ")}
                  onClick={() => this.onToggleBoxHandler(itemIndex)}
                />
              </div>
            </div>
          </div>
          <div className={["row relativo"]}>
            <div
              className={[
                "col",
                "col-lg-12",
                "text-center",
                "d-block",
                "d-lg-none",
                classes.arrowContainer,
                this.state.toggleBox[index] ? classes.divArrowContainer : null
              ].join(" ")}
              onClick={() => this.onToggleBoxHandler(itemIndex)}
            >
              <i className={[this.state.toggleBox[index] ? "fas fa-chevron-down" : "fas fa-chevron-up"]} />
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const toggleBoxes = this.props.terminos ? this.showToggleBoxes(this.props.terminos) : null;
    return (
      <Auxs>
        <div className="row">
          <div className="col" ref="toggleBoxMainContainer">
            {toggleBoxes}
          </div>
        </div>
      </Auxs>
    );
  }
}

export default ToggleBox;

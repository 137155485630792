import React, { useState } from "react";
import LightweightChart from "../lightweightCharts/LightweightChart";
import Filter from "../filterGraficaHistoricoBiva/FilterGraficaHistoricoBiva";
import classes from "./GraficaHistoricoBiva.css";
import { getMonth, getLan } from "../../../stores/utils/utilities";
import PropTypes from 'prop-types';

const valoresIndicadores = [
  { nombre: getLan() === "es" ? "Participación de mercado" : "Market Share", id: "participacionBIVA", tipo:0 },
  { nombre: getLan() === "es" ? "Volumen" : "Volume", id: "volumen", tipo:0 },
  { nombre: getLan() === "es" ? "Acumulado de cruces" : "Crosses accumulated", id: "crucesTotal", tipo:1 },
  { nombre: getLan() === "es" ? "Importe" : "Amount", id: "importe", tipo:0 },
  { nombre: getLan() === "es" ? "Acumulado de bloques" : "Blocks accumulated", id: "bloques", tipo:0 },
  { nombre: getLan() === "es" ? "Acumulado de cruces" : "Crosses accumulated", id: "crucesTotal", tipo:1 },
  { nombre: getLan() === "es" ? "Acumulado de cruces" : "Crosses accumulated", id: "crucesIntencional", tipo:1 },
  { nombre: getLan() === "es" ? "Acumulado de cruces" : "Crosses accumulated", id: "crucesExcepcional", tipo:1 }
];

const crucesIndicadores = [
  { nombre: "Total", id: "crucesTotal" },
  { nombre: getLan() === "es" ? "Intencionales" : "Intentionals", id: "crucesIntencional" },
  { nombre: getLan() === "es" ? "Excepcionales" : "Exceptionals", id: "crucesExcepcional" }
];

export default function GraficaHistoricoBiva(props) {
  let [tipoIndicador, setTipoIndicador] = useState("participacionBIVA");
  let [cruceSeleccionado, setCruceSeleccionado] = useState("crucesTotal");

  function seleccionCruce (idCruce) {
    setTipoIndicador(idCruce);
    setCruceSeleccionado(idCruce);
  };

  let tituloGrafica = null;
  let botonesCruces = null;
  let isCruceSeleccionado = false;
  const valores = [...valoresIndicadores];
  const item = valores.find(tipv => tipv.id === tipoIndicador);

  if (item) {
    tituloGrafica = item.nombre;
    if (item.tipo === 1 && cruceSeleccionado) {
      isCruceSeleccionado = true;
    }
  }

  if (isCruceSeleccionado) {
    botonesCruces = crucesIndicadores.map((item, i) => {
      return (
        <div
          key={"cruces" + i}
          onClick={() => seleccionCruce(item.id)}
          className={[classes.botonCruce, tipoIndicador === item.id ? classes.btnActivo : null].join(" ")}
        >
          <span className={"montse texto_16 regular"}>{item.nombre}</span>{" "}
        </div>
      );
    });
  }
  let textoIndicador = (isCruceSeleccionado ? crucesIndicadores.find(cruces => cruces.id === cruceSeleccionado).nombre : item.nombre);
  return (
    <>
      <Filter modelo="modelo_1" tramiteId={""} setTipoIndicador={tipoIndicador => setTipoIndicador(tipoIndicador)} />
      <div className={["row margen_top_30", classes.contenedorGrafica].join(" ")}>
        <div className="row"></div>
        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 sin_padding_RL"}> <span className={"texto_30 nunito regular nowrap"}>{tituloGrafica}</span> </div>
        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 sin_padding_RL float-right"}> <div className={" float-right"}>{botonesCruces}</div> </div>

        {dibujarGrafica(transformarDatosGrafica(props.datos, tipoIndicador), tipoIndicador, textoIndicador)}
      </div>
    </>
  );
}

GraficaHistoricoBiva.propTypes = {
  datos: PropTypes.arrayOf(PropTypes.shape({
      fecha: PropTypes.string,
      volumen: PropTypes.string,
      importe: PropTypes.string,
      participacionBIVA: PropTypes.string,
      bloques: PropTypes.string,
      crucesTotal: PropTypes.string,
      crucesIntencional: PropTypes.string,
      crucesExcepcional: PropTypes.string
    })),
};

function dibujarGrafica(datos, tipoIndicador, textoIndicador) {
  if (datos.length !== 0) {
    let esPorcentaje = false;
    let esCurrency = false;
    let tituloV;
    
    if (tipoIndicador === "participacionBIVA") {
      tituloV = "%";
      esPorcentaje = true;
      
    } else if (tipoIndicador === "volumen") {
      tituloV = getLan() === "es" ? "Titulos" : "Titles";
        
    } else {
      tituloV = getLan() === "es" ? "Pesos Mexicanos" : "Mexican pesos";
      esCurrency = true;
    }
    const tiempo = +new Date();
    let legend = textoIndicador + ' - ' + tituloV;
    let graphElements = [{legend: legend, data: datos}];

    return (
      <div className={("row", classes.width100)}>
        <div className="col-sm-12 margin_top_30">
          <LightweightChart
            key={tiempo}
            elements={graphElements}
            timeVisible={false}
            isPercentage={esPorcentaje}
            isPointMarkersVisible={true}
            isCurrency={esCurrency}
          />
        </div>
      </div>
    );
  }
}

function transformarDatosGrafica(datos, tipoIndicador) {
  let datosGrafica = [];
  if (datos) {
    datos.map(item => {
      let fecha_x = item.fecha.split("-");
      let anio = fecha_x[1];
      let mes = getMonth(fecha_x[0]);
      let date = new Date(parseInt("20" + anio), parseInt(mes) - 1, 1);
      let time = Math.round(date / 1000);
      
      if (tipoIndicador === "participacionBIVA") {
        datosGrafica.push({
          time: time,
          value: parseFloat(item[tipoIndicador])/100,
        });
      } else {
        datosGrafica.push({
          time: time,
          value: parseFloat(item[tipoIndicador])
        });
      }
    });
  }
  return datosGrafica;
}
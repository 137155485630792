import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import StringUtils from "../../../stores/utils/StringUtils";
import classes from "./Result.css";
import Download from "../../ui/download/Download";
import { v4 as uuid } from 'uuid';


class Result extends Component {
  state = {};

  constructor() {
    super();
  }

  render() {
    if (this.props.data === null) {
      return null;
    } 
    const data = this.props.data;
    const list = data.map((itm, i) => {
      let documento = null;
      const urlDocumento = itm.documento ? itm.documento : null;
      if (urlDocumento) {
          documento =  <div className={[classes.documento, classes.linkDocumento].join(" ")}>
            <Download key={uuid()} docType={itm.docType} url={urlDocumento} fileName={itm.fileName} archivos_list={itm.archivosXbrl} isSearch={true} />
        </div>
      }
      let params = {
          emisora_id: itm.id,
          emisora: itm.id,
          empresaId: itm.id,
          tramiteId: itm.id,
          subTab:0,
          page:1,
          clave:itm.clave,
          razonSocial: itm.razonSocial ? itm.razonSocial : null,
          numeroTramite: itm.numeroTramite ? itm.numeroTramite : null,
          numeroFideicomiso: itm.numeroFideicomiso ? itm.numeroFideicomiso : null
      }
      if ( this.props.urlRedirect === "/empresas/emisoras_inscritas/banco_de_informacion" ) {
        params = { clave : itm.clave}  
      } else if ( this.props.urlRedirect === "/biva-sostenible/emisiones-asg/emisiones") {
        params = { clave : itm.nombre}  
      } else if ( this.props.urlRedirect === "/listado_de_valores/tramites_de_listado/avisos_de_ofertas_publicas") {
        params = { claveCotizacion : itm.clave}  
      }
      const queryParams = StringUtils.jsonToQueryString(params);
      const ruta = this.props.urlRedirect + queryParams;

      const navigate = (ruta) => {
        if(ruta !== this.props.history.location.pathname + this.props.history.location.search){
          this.props.history.push(ruta);
          window.location.reload();
        } else {
          this.props.hiddenSearch();
        }
      }

      if(itm.numeroFideicomiso != null) { 
        return (
          <div className={[classes.data, "texto_blanco nunito"].join(" ")} key={this.props.key+"_"+i}>
            <a className={classes.link} onClick={() => navigate(ruta)}>
              <div className={classes.clave}>{itm.clave}</div>
            </a>
            Fideicomiso {itm.numeroFideicomiso}
          </div>
        );
      } else if(this.props.urlRedirect != null && this.props.urlRedirect != "") { 
        return (
          <div className={[classes.data, "texto_blanco nunito"].join(" ")} key={this.props.key+"_"+i}>
            <a className={classes.link} onClick={() => navigate(ruta)}>
              <div className={classes.clave}>{itm.clave}</div>
              <div className={classes.nombre}  dangerouslySetInnerHTML={{ __html: itm.nombre }} ></div>
            </a>
            {documento}
          </div>
        );
      }

      return (
        <div className={[classes.data, "texto_blanco nunito"].join(" ")} key={this.props.key+"_"+i}>
          <div className={""}>
            <div className={classes.clave}>{itm.clave}</div>
            <div className={classes.nombre}  dangerouslySetInnerHTML={{ __html: itm.nombre }} />
          </div>
          {documento}
        </div>
      );
    });
    return (
      <div ref={this.props.innerRef} 
        className={[classes.row, "col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3"].join(" ")}
      >
        <div
          className={[classes.titulo, "texto_azul bold montse texto_18"].join(
            " "
          )}
        >
          {this.props.titulo}
        </div>
        {list}
      </div>
    );
  }
}

export default withRouter(Result);

import React, { Component } from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import classes from "./ConcentradoPreciosModal.css";

import * as actions from "../../../stores/actions/index";

import Auxs from "../../../hoc/auxs/Auxs";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import Box from "../../../components/ui/box/Box";
import Input from "../../../components/ui/input/Input";
import { getLan } from "../../../stores/utils/utilities";
import { CopyToClipboard } from "react-copy-to-clipboard";
import GraficaHistoricoFondos from "./GraficaHistoricoFondos";

const optionsAvisosOfertasPublicas = [
  { label: getLan() === "es" ? "Capitales" : "Equity", value: 1 },
  { label: getLan() === "es" ? "Deuda" : "Debt", value: 2 },
];

const optionsCalificadoras = [
  {
    label: getLan() === "es" ? "Eventos Relevantes." : "Relevant Events",
    value: 38,
  },
  {
    label: getLan() === "es" ? "Reportes mensuales" : "Monthly Report.",
    value: 14,
  },
];

const renderInput = ({
  input,
  id = null,
  label,
  classes = null,
  elementType,
  placeholder = null,
  onInputChange,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? classes : null,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    label: label,
    elementType: elementType,
  };
  return (
    <Auxs>
      {label && input.type !== "checkbox" ? (
        <label for={input.name}>{label}</label>
      ) : null}
      <Input config={params} />
    </Auxs>
  );
};

class ConcentradoPreciosModal extends Component {
  state = {
    showGraphicModal: false,
    modalTitle: null,
    baseUrl: null,
    emisoraId: null,
    tipoInstrumento: null,
    url: null,
    optionType: null,
    copied: false,
    options: [],
    origen: {
      tipoDocumento: [],
      tipoDocumentoListado: [],
    },
  };

  componentDidMount() {
    this.props.onHistoricoBiva();

    this.setState({
      modalTitle: this.props.modalTitle,
      baseUrl: this.props.urlRss,
      url: this.props.urlRss,
      optionType: this.props.optionType ? this.props.optionType : null,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claveFondo !== this.props.claveFondo) {
      this.setState({
        claveFondo: this.props.claveFondo,
      });
    }
  }

  componentWillUpdate(prevProp) {
    if (prevProp.showGraphicModal !== this.props.showGraphicModal) {
      this.onToggleRssModalHandler();
    }
  }

  onToggleRssModalHandler = () => {
    this.setState((prevState) => {
      return {
        showGraphicModal: !prevState.showGraphicModal,
      };
    });
  };

  onCopyUrlHandler = () => {
    this.setState({
      copied: true,
    });

    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 1500);
  };

  onSubmitDataHandler = () => {};

  onPrintOptionsOneAndThreeRssModalHandler = (option) => {
    const marketType = getLan() === "es" ? "Tipo de mercado" : "Market type";
    const notificationType =
      getLan() === "es" ? "Tipo de notificación" : "Notification type";
    const subTitle = option === 1 ? marketType : notificationType;

    let options = [];
    if (option === 1) {
      options = optionsAvisosOfertasPublicas.map((option) => {
        return this.onPrintCheckboxInputsHandler(option);
      });
    } else {
      options = optionsCalificadoras.map((option) => {
        return this.onPrintCheckboxInputsHandler(option);
      });
    }

    return (
      <Auxs>
        <div className="row">
          <div className="col">
            <h2 className={"nunito texto_30 regular"}>
              {getLan() === "es"
                ? "Seleccione las opciones para suscribirse."
                : "Select what you want to subscribe."}
            </h2>
          </div>
        </div>
        <div className="row margin_top_10">
          <div className="col">
            {/* <p className={"montse texto_14 regular"}>{subTitle}</p> */}
            <div className={classes.inputsContainer}>
              <div className={classes.inputsContainer2}>
                <Form
                  onSubmit={() => this.onSubmitDataHandler()}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">{options}</div>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Auxs>
    );
  };

  onPrintOptionsTwoRssModalHandler = () => {
    const options = this.props.optionsInputs ? this.onPrepareDataHandler() : [];

    const noc = Math.round(options.length / 10);
    const ipc = Math.round(options.length / noc);

    return (
      <Auxs>
        <div className="row">
          <div className="col-12">
            <h2 className={"nunito texto_30 regular"}>
              {getLan() === "es"
                ? "Seleccione las opciones para suscribirse."
                : "Select what you want to subscribe."}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={classes.inputsContainer}>
              <Form
                onSubmit={() => this.onSubmitDataHandler()}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.scrollContainer}>
                      {[...Array(noc)].map((_, i) => {
                        const init = ipc * i;
                        const end = ipc + init;
                        const opt = options.slice(init, end);

                        return (
                          <div className={classes.myColWdith}>
                            {opt.map((o) => {
                              return (
                                <p>{this.onPrintCheckboxInputsHandler(o)}</p>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </Auxs>
    );
  };

  onPrepareDataHandler = () => {
    const options = this.props.optionsInputs;
    let updateOptionsInputs = [];

    options.forEach((option) => {
      let nombre = option.nombre;
      let origen = option.origen;
      let id = option.id.toString().replace(/,/g, "_");

      updateOptionsInputs.push({ nombre: nombre, id: id, origen: origen });
    });
    return updateOptionsInputs;
  };

  onManageValuesHandler = (optionsValues, type = null, value) => {
    let options = [...optionsValues];

    if (options.length > 0) {
      const exists = options.find((option) => option === value);
      if (exists) {
        options = options.filter((option) => option !== value);
      } else {
        options.push(value);
      }
    } else {
      options.push(value);
    }
    return options;
  };

  onReplaceCharacterHandler = (value) => {
    return value.toString().replace(/_/g, ",").split(",");
  };

  onChangeInputsHandler = (event) => {
    const value = event.target.value;
    const optionType = this.state.optionType;
    const tipoOrigen = this.state.origen;

    let opt = [];
    let url = this.state.baseUrl;

    let options = [...this.state.options];
    let optionTdl = [...tipoOrigen.tipoDocumentoListado];
    let optionTd = [...tipoOrigen.tipoDocumento];

    if (optionType === 1) {
      options = this.onManageValuesHandler(options, optionType, value);
      if (options.length > 0) {
        url = `${url}?idTipoMercado=${[...options]}`;
      }
    } else if (optionType === 2) {
      const ids = value.split("-")[0];
      const origen = value.split("-")[1];

      if (origen === "listado") {
        // Tipo de origen = listado
        optionTdl = this.onManageValuesHandler(optionTdl, optionType, ids);
        if (optionTdl.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTdl);

          // url = ` ${url}?tipoDocumentoListado=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumentoListado=${[...opt]}`
            : `${url}?tipoDocumentoListado=${[...opt]}`;
        }

        if (optionTd.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTd);

          // url = `${url}${optionTdl.length > 0 ? "&" : "?"}tipoDocumento=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumento=${[...opt]}`
            : `${url}?tipoDocumento=${[...opt]}`;
        }

        tipoOrigen.tipoDocumentoListado = optionTdl;
      } else if (origen === "mantenimiento") {
        // Tipo de origen = Mantenimiento
        optionTd = this.onManageValuesHandler(optionTd, optionType, ids);
        if (optionTd.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTd);

          // url = `${url}?tipoDocumento=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumento=${[...opt]}`
            : `${url}?tipoDocumento=${[...opt]}`;
        }

        if (optionTdl.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTdl);

          // url = `${url}${optionTd.length > 0 ? "&" : "?"}tipoDocumentoListado=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumentoListado=${[...opt]}`
            : `${url}?tipoDocumentoListado=${[...opt]}`;
        }

        tipoOrigen.tipoDocumento = optionTd;
      }
    } else if (optionType === 3) {
      options = this.onManageValuesHandler(options, optionType, value);
      if (options.length > 0) {
        url = `${url}?tipoDocumento=${[...options]}`;
      }
    }

    this.setState({
      options: options,
      origen: tipoOrigen,
      url: url,
    });
  };

  onPrintCheckboxInputsHandler = (input) => {
    return (
      <span className={classes.labelCheck}>
        <Field
          name="options"
          id={input.label ? input.label : input.nombre}
          type="checkbox"
          elementType="checkbox"
          value={
            input.value
              ? input.value
              : `${input.id}-${input.origen}-${input.nombre}`
          }
          classes={["form-check-input"].join(" ")}
          label={input.label ? input.label : input.nombre}
          onInputChange={this.onChangeInputsHandler}
          component={renderInput}
        />
      </span>
    );
  };

  onPrintModalHandler = () => {
    const show = this.props.showClassModel1
      ? classes.showModel1
      : classes.showModel2;

      let grafica = null;
      if (this.state.showGraphicModal) {
        grafica= (
          <GraficaHistoricoFondos
          key={0}
          datos={this.props.biva}
          tituloGrafica={this.state.tituloGrafica}
          tabSeleccionado={0}
          messages={this.props.messages}
          claveFondo={this.props.claveFondo}
          paramsForm={this.props.paramsForm}
        />
        )
      }
    return (
      <Auxs>
        <BackDrop
          show={this.state.showGraphicModal}
          clicked={this.onToggleRssModalHandler}
        />
        <div
          className={[
            "row",
            classes.rssModalContainer,
            this.state.showGraphicModal ? show : classes.hidden,
          ].join(" ")}
        >
          <div className="col col-lg-12">
            <div className="row">
              {/* <div className="col text-center">{this.onPrintHeaderRssModalHandler()}</div> */}
              {/* <Grafica datos={this.props.biva} messages={this.props.messages}/>
               */}
             {grafica}
            </div>
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const rssModal = this.onPrintModalHandler();
    return rssModal;
  }
}

const mapStateToProps = (state) => {
  return {
    optionsInputs: state.rss.rss,
    urlRssDocument: state.rss.rssUrlDocument,
    biva: state.informacionMercado.historicoBiva,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchRssDocumentSeguimientos: () => {
      dispatch(actions.fetchRssSeguimientosUrlDocument());
    },
    onFetchRssDocumentProspectos: () => {
      dispatch(actions.fetchRssProspectosUrlDocument());
    },
    onFetchRssDocumentAvisos: () => {
      dispatch(actions.fetchRssAvisosUrlDocument());
    },
    onFetchRssDocumentDocumentos: () => {
      dispatch(actions.fetchRssEmpresasUrlDocument());
    },
    onFetchRssDocumentBancos: () => {
      dispatch(actions.fetchRssBancosUrlDocument());
    },
    onFetchRssDocumentCalificadoras: () => {
      dispatch(actions.fetchRssCalificadorasUrlDocument());
    },
    onHistoricoBiva: () => {
      dispatch(actions.initHistoricoBiva());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConcentradoPreciosModal);

import React, { Component } from "react";

import Boton from '../../../components/ui/boton/Boton';
class Estrategicas extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
        <h1 className={"texto_42 nunito regular"}>Herramientas tecnológicas</h1>
        </div>

        <div className="margen_top_50">
          <div className="row">
            <div className="col" />
            <p>En BIVA hacemos disponibles herramientas tecnológicas y plataformas amigables que permitan a cualquier 
persona incursionar y aprender de manera didáctica acerca del funcionamiento del mercado de valores.</p>
          </div>
        </div>

        <div className="margen_top_50">
          <div className="row">
            <div className="col">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>¿Te interesa conocer nuestro simulador de inversiones?</h2>
              </div>
            </div>
          </div>
          <div className="row">
          <Boton
              ruta={"/noexiste"}
              tipo={2}
              texto={"Simulador de inversiones"}
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Estrategicas;

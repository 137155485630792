import React, { Component } from "react";
import { connect } from "react-redux";

import classes from './ColocacionDetalle.css'
// import Toggle from "./toggle/Toggle";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLocal, getLan, getRealDateMilliesDayMonthYear } from "../../../stores/utils/utilities";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import * as actions from "../../../stores/actions/index";
import LoaderBar from "../../../components/loader/LoaderBar";

class ColocacionDetalle extends Component {
    state = {
    };

    componentDidMount() {
        this.props.onInitDocumentos(this.props.empresaId);
        this.props.onInitColocadores(this.props.empresaId)
    }
    render() {
        let titulo1 = "Descargar Prospecto";
        let titulo2 = "Descargar Presentación";

        const video = (
            <div className="margin_top_30 width_cien" id="video">
                <iframe
                    width="100%"
                    height="415"
                    src={
                        // "https://www.youtube.com/embed/" + this.state.selectedVideo
                        "https://www.youtube.com/embed/PpZ5ew0DB2U"
                    }
                    frameBorder="0"
                    title="videos"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                />
            </div>
        );
        return (
            <Auxs>
                <div className={["row margin_top_10"].join(" ")}>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        <div className={[].join(" ")}>
                            <img src={this.props.urlLogoEmisora} className="card-img-top" height="200px" width="200px" alt="..." />
                            <div className={[classes.card_header_gray, "card-body"].join(" ")}>
                                {/* <h5 className="card-title nunito texto_24 semi-bold">Cox Energy América, S.A.B. de C.V.</h5> */}
                                <a href={this.props.urlSitioWeb} target="_blank" rel="noopener noreferrer">
                                    <h5 className="card-title nunito texto_24 semi-bold">{this.props.razonSocial}</h5>
                                </a>
                            </div>
                            <div className={[this.props.sustentable ? classes.content_green : classes.content_peach, "card-body"].join(" ")}>
                                {/* <div className={[classes., "card-"].join(" ")}> */}
                                <h5 className="nunito texto_16 semi-bold">{this.props.tipoInstrumento}</h5>
                            </div>
                        </div>
                        <div className={[,].join(" ")}>
                            <div className={["card-body row"].join(" ")}>
                                <div className={["col-12 col-sm-12 col-md-12 col-xl-12 ", classes.tamaño_titulos,].join(" ")}>
                                    {/* <h5 className="card-title nunito texto_24 semi-bold">Importe</h5> */}
                                    <p className="card-text montse texto_14 semi_bold color_gris">Monto tentativo a emitir :</p>
                                    <p className="card-text montse texto_42 bold color_azul">$ {this.props.montoTentativoEmision}</p>
                                    <p className="card-text montse texto_14 semi_bold color_gris">Fecha tentativa de emisión:</p>
                                    <p className="card-text montse texto_24 bold color_azul"> {getRealDateMilliesDayMonthYear(this.props.fechaTentativaEmision)}</p>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* <div className={["col-12 col-sm-12 col-md-12 col-xl-6 ", classes.tamaño_titulos,].join(" ")}>
                        {video}
                    </div> */}
                </div>

                {/* Casas Bolsa Colocadoras */}
                {this.props.intermediarios ? (
                    <div className={[classes.content_gray, "margin_top_50"].join(" ")}>
                        <div className={["card-body"].join(" ")}>
                            <h5 className="card-title nunito texto_24 semi-bold">Casas de Bolsa colocadoras</h5>
                            <div className={["row"].join(" ")}>
                                {this.props.intermediarios.map((dato, i) => (
                                    <React.Fragment>
                                        <div className={["col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  sin_padding"].join(" ")}>
                                            <p className="card-text montse texto_16 ">{dato.nombre}</p>
                                        </div>
                                        {
                                            dato.informacionContacto ? (
                                                dato.informacionContacto.map((contacto, i) => (
                                                    <React.Fragment>
                                                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                                            <p className="card-text montse texto_16 ">Contacto: {contacto.nombreCompleto}</p>
                                                        </div>
                                                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                                            <p className="card-text montse texto_16 ">email: {contacto.email}</p>
                                                        </div>
                                                        <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                                            <p className="card-text montse texto_16 ">Teléfono: {contacto.telefono}</p>
                                                        </div>
                                                    </React.Fragment>
                                                ))) : null
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null
                }

                {/* DOCS Prospecto y Presentacion*/}
                <div className={[classes.content_blue, "texto_24 nunito bold  card-body row margin_top_50"].join(" ")}>
                    {this.props.documentos ? (
                        this.props.documentos.map((doc, i) => (
                            <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                                <div className={["sin_padding no_borders"].join(" ")} id="headingOne">
                                    <h2 className={["mb-0 nowrap"].join(" ")}>
                                        <a href={doc.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                                            <div className="card_body">
                                                <span className={["texto_18 montse color_blanco semi_bold"].join(" ")}>
                                                    {(doc.tipoDocumentoListado.id === 21) ? titulo1 : titulo2}
                                                </span>
                                                <br />
                                                <span className={"texto_10 montse semi_bold color_blanco"}>
                                                    {getLan() === "es"
                                                        ? `Formato ${this.props.docType === undefined ? "PDF" : this.props.docType.toUpperCase()}`
                                                        : `${doc.docType === undefined ? "PDF" : doc.docType.toUpperCase()} format`}
                                                </span>
                                            </div>
                                            <div className="">
                                                <i className={["fas fa-arrow-alt-circle-down fa-sm", "Boton__icono_derecho__25mg6"].join(" ")} />
                                            </div>
                                        </a>
                                    </h2>
                                </div>
                            </div>
                        ))
                    ) : (<LoaderBar />)}
                </div>

                {/* Historia */}
                {/* <div className={[classes.content_peach_border_right, "margin_top_10"].join(" ")}>

                    <div className={["card-body"].join(" ")}>
                        <h5 className="card-title nunito texto_24 semi-bold texto_azul">Historia</h5>
                        <p className="card-text montse texto_16 texto_gris">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <img src="https://oroinformacion.com/wp-content/uploads/2019/04/paneles-solares-fotovoltaicos-700x375.jpg" className="card-img-top" alt="..." />
                    </div>
                </div> */}

                {/* Proceso Alta BIVA */}
                {/* <div className={[classes.content_blue_border_right, "margin_top_50"].join(" ")}>

                    <div className={["card-body"].join(" ")}>
                        <h5 className="card-title nunito texto_24 semi-bold texto_azul">Proceso de alta en BIVA</h5>
                        <p className="card-text montse texto_16 texto_gris ">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <img src="https://biva.mx/cms/documents/20123/5120ecd0-a58e-5c96-d645-4354b14cb180" className="card-img-top" alt="..." />
                    </div>
                </div> */}
            </Auxs >
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onInitDocumentos: empresaId => {
            dispatch(actions.getDocumentos(empresaId));
        },
        onInitColocadores: empresaId => {
            dispatch(actions.getIntermediarios(empresaId));
        },
        onInitIntl: () => {
            dispatch(actions.initIntl(54));
        }
    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        documentos: state.colocaciones.documentos,
        intermediarios: state.colocaciones.intermediarios,
    };
};



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ColocacionDetalle));

import React, { Component } from "react";

import Accordion from "../../../../components/ui/accordion/Accordion";
import Boton from "../../../../components/ui/boton/Boton";
import listado_json from "../../../../json//contactanos/capital.json";

class Capital extends Component {
  render() {
    return (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>Contáctanos</h1>
        <div className={"margen_top_50"}>
          <Accordion
            datos={listado_json.datos}
            nombre="listado"
            icon={"fas fa-phone"}
            tipo={2}
          />
        </div>

        <div className="margen_top_100">
          <span className={"color_azul texto_24"}>/</span>{" "}
          <span className={"texto_24 nunito regular"}>
            ¿Si deseas aplicar para alguna vacante? Ingresa tus datos en
          </span>
          <div className={"margen_top_50"}>
          <Boton
            tipo={1}
            texto={"Vacantes"}
            icono_derecho="fas fa-long-arrow-alt-right"
            colorBoton={""}
            ruta={"https://biva.mx/es/web/portal-biva/vacantes"}
          />{" "}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Capital;

import React, { Component } from "react";
import Accordion from "../../../components/ui/accordion/Accordion";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class Preguntas extends Component {

  state = {
    messages: null,
    preguntas: null,
    lan: getLocal()
  };
  componentDidMount() {
    this.props.onInitIntl(17);
    this.props.onPreguntas();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.preguntas !== prevProps.preguntas) {
        this.setState({
          preguntas: this.props.preguntas
        });
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}><FormattedMessage id="nosotros.auditorio.titulo1" defaultMessage=" " /></h1>
        <div className={"margen_top_50"}>
        <Accordion
          datos={this.state.preguntas}
          nombre="listado"
          icon={""}
          tipo={1}
        />
        </div>

      </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onPreguntas: () => {
      dispatch(action.initPreguntasFrecuentes());
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    preguntas: state.documentos.preguntasFrecuentes
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preguntas);
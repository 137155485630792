import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  emailArea: null,
  emailAnonimo: null,
  errorMail: null,
};

const setInitSendEmailArea = (state, action) => {
  return updateObject(state, action);
};

const setInitSendEmailAnonimo = (state, action) => {
  return updateObject(state, action);
};

const setInitErrorSendAnonimo = (state, action) => {
  return updateObject(state, action);
};
const setInitErrorSendArea = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SENDEMAILAREA_SUCCESS:
      return setInitSendEmailArea(state, action);
    case actionTypes.FETCH_SENDEMAILANONIMO_SUCCESS:
      return setInitSendEmailAnonimo(state, action);
    case actionTypes.FETCH_SENDEMAILANONIMO_ERROR:
      return setInitErrorSendAnonimo(state, action);
    case actionTypes.FETCH_SENDEMAILAREA_ERROR:
      return setInitErrorSendArea(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import { connect } from "react-redux";

import EventosList from "../../../../components/eventos/Eventos";
import Filter from "../../../../components/ui/filter/Filter";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import Evento from "./Noticia";

import * as action from "../../../../stores/actions/index";
import queryString from "query-string";

class Noticias extends Component {
  state = {
    loader: null,
    messages: null,
    lan: getLocal(),
    page:1,
    eventoId: null
  };
  componentDidMount() {
    this.props.onInitIntl(10);

    let paramsString = queryString.parse(this.props.location.search);
    let fechaInicio =
    paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
     let fechaFin =
    paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    let id = paramsString["id"] !== undefined && paramsString["id"] !== "null" ? paramsString["id"] : null;

    this.setState({
      page : page && page > 0 ? page : 1,
      eventoId: id
    });

    this.props.onInitOtrasNoticias(fechaInicio, fechaFin);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  onEventSelected(eventoId) {
    this.setState({ eventoId: eventoId });
    if (eventoId === null) {
      let timeD = +new Date();
      this.setState({loader:timeD})
      this.props.history.push(
        "?page=1"
      );
    }else{
      this.props.history.push(
        `?id=${eventoId}&page=${1}`
      );
    }
  }

  onPageSelected(page){
    if (this.state.page !== page) {
      this.setState({page: page})
    }
  }

  render() {
    let eventos_container = null;
    if (this.state.eventoId) {
      // eventos_container = (
      //   <Evento key={+new Date()} eventoId={this.state.eventoId} eventSelected={eventoId => this.onEventSelected(eventoId)} tipo={2} />
      // );
      eventos_container=(<Evento key={+new Date()} eventoId={this.state.eventoId} otrasNoticias={this.props.otrasNoticias} eventSelected={(eventoId) => {this.onEventSelected(eventoId)}} tipo={2}/>);

    } else {
      const noticias = this.props.otrasNoticias ? (
        <EventosList
          datos={this.props.otrasNoticias}
          es_evento={false}
          redirecciona={"/nosotros/acerca_de/noticias/"}
          eventSelected={eventoId => this.onEventSelected(eventoId)}
          pageSelected={(page) => this.onPageSelected(page)} 
          activePage={this.state.page} 
        />
      ) : (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
      eventos_container = (
        <>
          <div className="row margen_top_50">
            <div className="col sin_padding">
              <Filter modelo="modelo_1" target="otrasNoticias" loaderDisabled={this.state.loader}  history={this.props.history} page={this.state.page} />
            </div>
          </div>
          <div className="row margen_top_50">
            <div className="col sin_padding">{noticias}</div>
          </div>
        </>
      );
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <React.Fragment>
          <div className="row">
            <div className="col sin_padding">
              <h1 className={"nunito regular texto_42"}>
                <FormattedMessage id="nosotros.acercade.noticias.titulo1" defaultMessage=" " />
              </h1>
            </div>
          </div>
          {eventos_container}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    otrasNoticias: state.noticias.otrasNoticias,
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onInitOtrasNoticias: (fechaInicio, fechaFin) => {
      dispatch(action.initOtrasNoticias(fechaInicio, fechaFin));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Noticias);

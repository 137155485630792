import React, { Component } from "react";
import classes from "./Calendario.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";
import Boton from "../../../components/ui/boton/Boton";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Calendario extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null,
    calendario: [
      {
        titulo: "Dias festivos 2019",
        texto_html:
          "<ul><li>1 de enero.</li><li>El primer lunes de febrero en conmemoraci&oacute;n.</li><li>El tercer lunes de marzo en conmemoraci&oacute;n del 5 de febrero, es decir, el 4 de febrero</li><li>18 y 19 de abril.</li><li>1 de mayo.</li><li>16 de septiembre.</li><li>El 2 de noviembre. Adicionalmente, el tercer lunes de dicho mes en conmemoraci&oacute;n del 20 de noviembre, es decir, el 18 de noviembre.</li><li>12 y 25 de diciembre</li></ul>"
      }
    ],
    opel_e: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p>Instrumentos de renta variable nacional e internacional</p><table style='width: 603px;'><tbody><tr><th style='width: 129px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 460px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 460px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:00 - 8:30&nbsp;</td><td style='width: 460px;'>Subasta de apertura</td></tr><tr><td style='width: 129px;'>8:00 - 14:50</td><td style='width: 460px;'>Subastas de mantenimiento (SUB-RM). Duraci&oacute;n de 1 hora cada subasta</td></tr><tr><td style='width: 129px;'>8:00 - 14:50</td><td style='width: 460px;'>Subastas por inactividad (SUB-20). Duraci&oacute;n de 1 hora cada subasta</td></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 460px;'>Mercado continuo para valores listados en BIVA</td></tr><tr><td style='width: 129px;'>8:30 - 15:00</td><td style='width: 460px;'>Mercado continuo para valores que cotizan en el mercado secundario</td></tr><tr><td style='width: 129px;'>14:50 - 15:00</td><td style='width: 460px;'>Subasta de cierre para valores listados en BIVA</td></tr></tbody></table><p></p><p>Certificados burs&aacute;tiles fiduciarios de desarrollo</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 462px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:30 - 14:00</td><td style='width: 462px;'>Subasta CCD&rsquo;s</td></tr></tbody></table>"
      }
    ],
    opel_c: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p></p><p>Instrumentos de renta variable nacional e internacional</p><table style='width: 432px;'><tbody><tr><th style='width: 154px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 264px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 154px;'>8:00 - 14:50</td><td style='width: 264px;'>Ingreso de postura al precio de cierre</td></tr><tr><td style='width: 154px;'>15:00</td><td style='width: 264px;'>Ejecuci&oacute;n de operaciones al precio de cierre</td></tr></tbody></table>"
      }
    ],
    opel_b: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p></p><p>Instrumentos de renta variable nacional e internacional</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 462px;'>Mercado continuo bloques</td></tr></tbody></table>"
      }
    ],
    opel_w: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p>T&iacute;tulos opcionales (Warrants)</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><p><strong>Horario (CST)</strong></p></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 462px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:00 - 8:30</td><td style='width: 462px;'>Subasta de apertura</td></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 462px;'>Mercado continuo para valores listados en BIVA</td></tr><tr><td style='width: 129px;'>8:30 - 15:00</td><td style='width: 462px;'>Mercado continuo para valores que cotizan en el mercado secundario</td></tr><tr><td style='width: 129px;'>14:50 - 15:00</td><td style='width: 462px;'>Subasta de cierre para valores listados en BIVA</td></tr></tbody></table>"
      }
    ],
    opel_d: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p>Instrumentos de deuda</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><p><strong>Horario (CST)</strong></p></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 462px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:00 - 8:30</td><td style='width: 462px;'>Subasta de apertura</td></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 462px;'>Mercado continuo para valores listados en BIVA</td></tr><tr><td style='width: 129px;'>8:30 - 15:00</td><td style='width: 462px;'>Mercado continuo para valores que cotizan en el mercado secundario</td></tr><tr><td style='width: 129px;'>8:00 - 14:50</td><td style='width: 462px;'>Subastas de mantenimiento (SUB-RM). Duraci&oacute;n de 1 hora cada subasta</td></tr><tr><td style='width: 129px;'>14:50 - 15:00</td><td style='width: 462px;'>Subasta de cierre para valores listados en BIVA</td></tr></tbody></table>"
      }
    ]
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let calendario = null;
    let calendarioB = null;
    let calendarioC = null;
    let calendarioE = null;
    let calendarioD = null;
    let calendarioW = null;
    if (this.props.calendario) {
      calendario = (
        <div className={[" margen_top_50", classes.accordion].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.calendario}
            nombre={"calendario"}
            icon={""}
            imagen_icono={"calendario.svg"}
            tipo={6}
            titulo_encabezado_estatico={
              this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.calendario"] : ""
            }
            titulo_encabezado_2_estatico={""}
            className={classes.accordion}
          />
        </div>
      );
    }
    if (this.props.calendarioE) {
      calendarioE = (
        <div className={[" margen_top_50", classes.accordion].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.calendarioE}
            nombre={"opele"}
            icon={""}
            imagen_icono={"opel-e.svg"}
            tipo={6}
            titulo_encabezado_estatico={
              this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opele"] : ""
            }
            titulo_encabezado_2_estatico={
              this.state.messages
                ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opele.subtitulo"]
                : ""
            }
            className={classes.accordion}
          />
        </div>
      );
    }
    if (this.props.calendarioC) {
      calendarioC = (
        <div className={[" margen_top_50", classes.accordion].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.calendarioC}
            nombre={"opelc"}
            icon={""}
            imagen_icono={"opel-c.svg"}
            tipo={6}
            titulo_encabezado_estatico={
              this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opelc"] : ""
            }
            titulo_encabezado_2_estatico={
              this.state.messages
                ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opelc.subtitulo"]
                : ""
            }
            className={classes.accordion}
          />
        </div>
      );
    }
    if (this.props.calendarioB) {
      calendarioB = (
        <div className={[" margen_top_50", classes.accordion].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.calendarioB}
            nombre={"opelb"}
            icon={""}
            imagen_icono={"opel-b.svg"}
            tipo={6}
            titulo_encabezado_estatico={
              this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opelb"] : ""
            }
            titulo_encabezado_2_estatico={
              this.state.messages
                ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opelb.subtitulo"]
                : ""
            }
            className={classes.accordion}
          />
        </div>
      );
    }
    if (this.props.calendarioW) {
      calendarioW = (
        <div className={[" margen_top_50", classes.accordion].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.calendarioW}
            nombre={"opelw"}
            icon={""}
            imagen_icono={"opel-w.svg"}
            tipo={6}
            titulo_encabezado_estatico={
              this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opelw"] : ""
            }
            titulo_encabezado_2_estatico={
              this.state.messages
                ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opelw.subtitulo"]
                : ""
            }
            className={classes.accordion}
          />
        </div>
      );
    }
    if (this.props.calendarioD) {
      calendarioD = (
        <div className={[" margen_top_50", classes.accordion].join(" ")}>
          <Accordion
            sinPadre={true}
            nombreParent={"accordion"}
            datos={this.props.calendarioD}
            nombre={"opeld"}
            icon={""}
            imagen_icono={"opel-d.svg"}
            tipo={6}
            titulo_encabezado_estatico={
              this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opeld"] : ""
            }
            titulo_encabezado_2_estatico={
              this.state.messages
                ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.opeld.subtitulo"]
                : ""
            }
            className={classes.accordion}
          />
        </div>
      );
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.calendarioyhorario.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <p className={" texto_16 montse regular"}>
              <FormattedHTMLMessage id="operaciones.modelosdenegociacion.calendarioyhorario.introduccion1" defaultMessage=" " />
            </p>
          </div>
          <div className={"accordion"} id={"accordion"}>
            {calendario}
            {calendarioE}
            {calendarioC}
            {calendarioB}
            {calendarioW}
            {calendarioD}
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage
                    id="operaciones.modelosdenegociacion.calendarioyhorario.subtitulo1"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            <div className="row margen_top_30">
              <Boton
                tipo={2}
                texto={
                  this.state.messages
                    ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.boton.estadisticas"]
                    : " "
                }
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
                ruta={
                  this.state.messages
                    ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.boton.estadisticas.enlace"]
                    : " "
                }
              />
              <Boton
                tipo={2}
                texto={
                  this.state.messages
                    ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.boton.cuotas"]
                    : " "
                }
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
                ruta={
                  this.state.messages
                    ? this.state.messages["operaciones.modelosdenegociacion.calendarioyhorario.boton.cuotas.enlace"]
                    : " "
                }
              />
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    calendario: state.accordion.calendario,
    calendarioB: state.accordion.horarioOpelB,
    calendarioC: state.accordion.horarioOpelC,
    calendarioD: state.accordion.horarioOpelD,
    calendarioE: state.accordion.horarioOpelE,
    calendarioW: state.accordion.horarioOpelW
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(31));
      dispatch(action.getAccordionCalendario());
      dispatch(action.getAccordionHorarioOpelB());
      dispatch(action.getAccordionHorarioOpelC());
      dispatch(action.getAccordionHorarioOpelD());
      dispatch(action.getAccordionHorarioOpelE());
      dispatch(action.getAccordionHorarioOpelW());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendario);

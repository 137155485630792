import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";

import classes from "./Informacion.css";
import NotFound from "../../components/notFound/NotFound";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";

// paginas
import ProductosBiva from "./productosBiva/ProductosBiva";
import InformacionGeneral from "./indices/informacionGeneral/InformacionGeneral";
import Metodologias from "./indices/metodologia/Metodologia";
import UltimoDia from "./estadisticas/ultimoDia/UltimoDia";
import UltimoMes from "./estadisticas/ultimoMes/UltimoMes";
import OperacionBloques from "./estadisticas/operacionBloques/OperacionBloques";
import Cruces from "./estadisticas/cruces/Cruces";
import Historico from "./historico/Historico";
import Cuotas from "./cuotas/Cuotas";
import CotizacionEmisoras from "./cotizacionEmisoras/CotizacionEmisoras";
import ConectividadProveedores from "./conectividadProveedores/ConectividadProveedores";
import Suspensiones from "./suspensiones/Suspensiones";
import DocumentMeta from "react-document-meta";
import { getLan } from "../../stores/utils/utilities";
import MapaDeCalor from "./cotizacionEmisoras/mapaDeCalor/MapaDeCalor";

const metaEs = {
  title: "Portal BIVA - Actividad de mercado",
  description:
    "Estadisticas, operación último día, operación último mes, Operación bloques, operación cruces, historico, market data, productos BIVA, cuotas, conectividad con proveedores, cotización de emisoras, suspensiones, índices, información general, metodología",
  meta: {
    name: {
      keywords:
        "Estadisticas, operación último día, operación último mes, Operación bloques, operación cruces, historico, market data, productos BIVA, cuotas, conectividad con proveedores, cotización de emisoras, suspensiones, índices, información general, metodología",
    },
  },
};

const metaEn = {
  title: "Portal BIVA - Market data",
  description:
    "Statistics, last day, last month, blocks trading, cross trading, historical, market data, products, fees, connectivity with vendors, issuer quotations, trading halt, indexes, general information, methodology",
  meta: {
    name: {
      keywords:
        "Statistics, last day, last month, blocks trading, cross trading, historical, market data, products, fees, connectivity with vendors, issuer quotations, trading halt, indexes, general information, methodology",
    },
  },
};

class Informacion extends Component {
  state = {
    menu: null,
    id_menu_activo: null,
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage: "mercado",
    };

    this.props.onInitGenericos(params);
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu,
      });
    } else {
      this.setState({
        id_menu_activo: menu,
      });
    }
  };
  render() {
    const routes = (
      <Switch>
        <Route path="/informacion_de_mercado" exact component={UltimoDia} />
        <Route
          path="/informacion_de_mercado/market_data"
          exact
          component={ProductosBiva}
        />
        <Route
          path="/informacion_de_mercado/market_data/productos_biva"
          exact
          component={ProductosBiva}
        />
        <Route
          path="/informacion_de_mercado/market_data/cuotas"
          exact
          component={Cuotas}
        />
        <Route
          path="/informacion_de_mercado/market_data/conectividad_con_proveedores"
          exact
          component={ConectividadProveedores}
        />
        <Route
          path="/informacion_de_mercado/indices"
          exact
          component={InformacionGeneral}
        />
        <Route
          path="/informacion_de_mercado/indices/informacion_general"
          exact
          component={InformacionGeneral}
        />
        <Route
          path="/informacion_de_mercado/indices/metodologia"
          exact
          component={Metodologias}
        />
        <Route
          path="/informacion_de_mercado/estadisticas"
          exact
          component={UltimoDia}
        />
        <Route
          path="/informacion_de_mercado/estadisticas/operacion_ultimo_dia"
          exact
          component={UltimoDia}
        />
        <Route
          path="/informacion_de_mercado/estadisticas/operacion_ultimo_mes"
          exact
          component={UltimoMes}
        />
        <Route
          path="/informacion_de_mercado/estadisticas/operacion_bloques"
          exact
          component={OperacionBloques}
        />
        <Route
          path="/informacion_de_mercado/estadisticas/operacion_cruces"
          exact
          component={Cruces}
        />
        <Route
          path="/informacion_de_mercado/estadisticas/historico"
          exact
          component={Historico}
        />
        <Route
          path="/informacion_de_mercado/cotizacion_emisoras"
          exact
          component={CotizacionEmisoras}
        />
        <Route
          path="/informacion_de_mercado/cotizacion_emisoras/mapa_de_calor"
          exact
          component={MapaDeCalor}
        />
        <Route
          path="/informacion_de_mercado/suspensiones"
          exact
          component={Suspensiones}
        />
        <Route component={NotFound} />
      </Switch>
    );

    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
        <div className="container">
          <div className="row">
            <div className={["d-none"].join(" ")}>
              <Sidebar
                classIcon={["fas fa-gavel"].join(" ")}
                setMenu={(menu, tipo) => this.onSetMenu(menu, tipo)}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30 padding_bottom_200">
              <div className="row margin_bottom_30">
                <div className="col-12 sin_padding_L">
                  <div className={classes.breadcums}>
                    <p>
                      <Breadcrum
                        idPage={this.state.id_menu_activo}
                        menu={this.state.menu}
                      />
                    </p>
                  </div>
                </div>
              </div>
              {routes}
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitGenericos: (params) => {
      dispatch(action.initGenericos(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(Informacion);

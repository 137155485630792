import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import classes from "./Fondos.css";

import FilterConcentradoPreciosFondo from "../../../components/ui/filterConcentradoPreciosFondo/FilterConcentradoPreciosFondo";
import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
/* import { withRouter } from "react-router-dom"; */
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
const dominio = `${window.location.protocol}//${window.location.host}`;

class ConcentradoPrecios extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    razonSocial: null,
    clave: null,
    numeroTramite: null,
    datos: null,
    emisoraSeleccionada: null,
    tipoValorSeleccionado: null,
    fecha_inicio: null,
    fecha_fin: null,
    page: 0,
    detalles: [],
    urlForRss: `${dominio}/emisoras/tramites/rss`,
    tipoDocumento: null,
    pageDocumento: null,
    showRssModal: false,
    verDocumentos: false,
    claveAfore: false,
    claveSiefore: false,
    tipoSiefore: false,
    clasificacionSiefore: false,
    wasFiltered: false,
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.fondos !== prevProps.fondos) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let fondos = null;
    let detalles = null;

    if (nextProps.fondos && nextProps.fondos.content) {
      fondos = nextProps.fondos.content;
    }

    if (nextProps.concentradoPrecios !== this.props.concentradoPrecios) {
      if (nextProps.concentradoPrecios) {
        detalles = nextProps.concentradoPrecios;
      }
    } else {
      detalles = this.props.concentradoPrecios;
    }

    this.setState({
      detalles: detalles
    });

    let resource = fondos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.fondos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        loader: true,
        resourceList: resourceList
      });

    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {

      this.changeHistory(
        this.state.claveOperadora,
        this.state.claveFondo,
        this.state.tipoFondo,
        this.state.clasificacionFondo,
        this.state.fecha_inicio,
        this.state.fecha_fin,
        pageNumber);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let claveOperadora = null;
    let claveFondo = null;
    let tipoFondo = null;
    let clasificacionFondo = null;
    let page = null;
    let fechaInicio = null;
    let fechaFin = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      claveOperadora = paramsString["claveOperadora"] === "null" ? null : paramsString["claveOperadora"];
      claveFondo = paramsString["claveFondo"] === "null" ? null : paramsString["claveFondo"];
      tipoFondo = paramsString["tipoFondo"] !== undefined && paramsString["tipoFondo"] !== "null" ? paramsString["tipoFondo"] : null;
      clasificacionFondo = paramsString["clasificacionFondo"] !== undefined && paramsString["clasificacionFondo"] !== "null" ? paramsString["clasificacionFondo"] : null;
      page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
      fechaInicio = paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
      fechaFin = paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    }
    if (claveFondo !== null) {
      this.setState({
        tramiteDetalle: claveFondo
      });
/*       this.props.onInitConcentradoPrecios(claveFondo);
      this.updateGrandparent(claveFondo); */
      
/*       this.updateGrandparentListado(
        tramiteId,
        razonSocial,
        clave,
        numeroTramite,
        tramiteId,
        tipoDocumento,
        numEnvio,
        page,
        false
      ); */
    } 
      this.props.onInitFondos(
        this.state.itemsPerPage,
        parseInt(page) > 0 ? parseInt(page) - 1 : 0,
        claveOperadora,
        claveFondo,
        tipoFondo,
        clasificacionFondo,
        fechaInicio,
        fechaFin
      );

      this.setState({
        claveOperadora: claveOperadora,
        claveFondo: claveFondo,
        tipoFondo: tipoFondo,
        clasificacionFondo: clasificacionFondo,
        fecha_inicio: fechaInicio,
        fecha_fin: fechaFin,
        activePage: parseInt(page) > 0 ? parseInt(page) : 1,
      });
    
  }

  /* updateGrandparentListado = (
    empresaId,
    razonSocial,
    clave,
    numeroTramite,
    tramiteId = null,
    tipoDocumento = null,
    numEnvio = null,
    page = null,
    consulta = null
  ) => {
    this.props.onInitConcentradoPrecios(empresaId);
    let datos = {
      id: empresaId,
      numeroTramite: numeroTramite,
      clave: clave,
      razonSocial: razonSocial
    };

    this.setState({
      tramiteSeleccionado: empresaId,
      razonSocial: razonSocial,
      clave: clave,
      numeroTramite: numeroTramite,
      datos: datos,
      tramiteDetalle: empresaId,
      verDocumentos: true
    });
    if (consulta === null) {
      this.setState({ loader: true });
      this.props.history.push(
        `?tramiteId=${
          tramiteId ? tramiteId : empresaId ? empresaId : null
        }&tipoDocumento=${tipoDocumento}&numEnvio=${numEnvio}&page=${
          page === null ? 1 : page
        }&empresaId=${empresaId}&razonSocial=${razonSocial}&clave=${clave}&numeroTramite=${numeroTramite}&verDocumentos=${true}`
      );
    }
  }; */

  updateGrandparent = value => {
    this.props.onInitConcentradoPrecios(value);
    this.setState({
      tramiteDetalle: value,
      detalles: []
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetValor = valor => {
    this.setState({
      tipoValorSeleccionado: valor
    });
  };

  onSetFechaInicio = fecha => {
    this.setState({
      fecha_inicio: fecha
    });
  };

  onSetFechaFin = fecha => {
    this.setState({
      fecha_fin: fecha
    });
  };

  onSetTipoDocumento = (tipoDocumento, consulta) => {
    this.setState({
      tipoDocumento: tipoDocumento
    });
  };

  onSetPageDocumento = page => {
    this.setState({
      pageDocumento: page
    });
  };

  onSetEmisora = (emisora, tipo, consulta = false) => {
    let emisoraSeleccionada = null;
    let clave = null;

    if (tipo === 1) {
      emisoraSeleccionada = emisora;
    }
    if (tipo === 2) {
      clave = emisora;
    }

    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      clave: clave
    });
    if (consulta) {
      this.props.onInitFondos(
        this.state.itemsPerPage,
        0
        /* emisoraSeleccionada,
        this.state.tipoValorSeleccionado,
        this.state.fecha_inicio,
        this.state.fecha_fin,
        clave */
      );
    }
  };
  onSetClaveOperadoraHandler = claveOperadora => {
    this.setState({
      claveOperadora: claveOperadora
    });
  };

  onSetClaveFondoHandler = claveFondo => {
    this.setState({
      claveFondo: claveFondo
    });
  };

  onSetTipoFondohandler = tipoFondo => {
    this.setState({
      tipoFondo: tipoFondo
    });
  };

  onSetClasificacionFondo = clasificacionFondo => {
    this.setState({
      clasificacionFondo: clasificacionFondo
    });
  };

  onSetFechaInicio = fechaInicio => {
    this.setState({
      fecha_inicio: fechaInicio
    });
  };

  onSetFechaFin = fechaFin => {
    this.setState({
      fecha_fin: fechaFin
    });
  };

  onSetWasFilteredHandler = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered,
      loader: true
    });
  };

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  onSetFilters = ({
    claveOperadora = null,
    claveFondo = null,
    tipoFondo = null,
    clasificacionFondo = null,
    fechaInicio = null,
    fechaFin = null,
    wasFiltered = null,
    page = null,
    reset = false
  }) => {
    this.setState({
      claveOperadora: claveOperadora ? claveOperadora : null,
      claveFondo: claveFondo ? claveFondo : null,
      tipoFondo: tipoFondo ? tipoFondo : null,
      clasificacionFondo: clasificacionFondo ? clasificacionFondo : null,
      fechaInicio: fechaInicio ? fechaInicio : null,
      fechaFin: fechaFin ? fechaFin : null,
      wasFiltered: wasFiltered
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claveOperadora, claveFondo, tipoFondo, clasificacionFondo, fechaInicio, fechaFin, page);
    }
  }

  changeHistory(claveOperadora = null, claveFondo = null, tipoFondo = null, clasificacionFondo = null, fechaInicio = null, fechaFin = null, page = null) {
    this.props.history.push(
      `?claveOperadora=${claveOperadora}&claveFondo=${claveFondo}&tipoFondo=${tipoFondo}&clasificacionFondo=${clasificacionFondo}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&page=${page ? page : this.state.activePage}`
    );

    this.props.onInitFondos(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      claveOperadora,
      claveFondo,
      tipoFondo,
      clasificacionFondo,
      fechaInicio,
      fechaFin
    );
  }

  changeHistoryReset() {
    this.props.history.push(
      `?claveOperadora=${null}&claveFondo=${null}&tipoFondo=${null}&clasificacionFondo=${null}}&fechaInicio=${null}&fechaFin=${null}&page=1`
    );
    this.props.onInitFondos(
      this.state.itemsPerPage,
      0,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    let fondos = null;
    if (this.props.fondos && this.props.fondos.content) {
      fondos = this.props.fondos.content;
    }
    const numRows = this.props.fondos ? this.props.fondos.totalElements : 0;

    let dataShow = null;
    dataShow = this.props.fondos && this.props.fondos.length === undefined ? (
      <React.Fragment>
        <Accordion
          datos={fondos}
          nombre="fondos"
          icon={""}
          tipo={18}
          titulo={getLan() === "es" ? "Información de precios" : "Price information"}
          subtipo={2}
          detallesProspectos={this.state.detalles}
    /*       updateGrandparentListado={this.updateGrandparentListado.bind(this)} */
          updateGrandparent={this.updateGrandparent.bind(this)}
          esTramite={true}
          fondo={true}
        />
        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    ) : (
      <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda.": "No results found."}</p>
    );
    let contenidoMostrar = (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>
          <FormattedHTMLMessage id="empresas.fondos.concentrado.titulo1" defaultMessage=" " />
        </h1>
        <div className="margen_top_30 texto_16 montse regular">
          <FormattedHTMLMessage id="empresas.fondos.concentrado.introduccion1" defaultMessage=" " />
        </div>
        <br />
        <FilterConcentradoPreciosFondo
          itemsPerPage={this.state.itemsPerPage}
          setPage={page => this.onSetPage(page)}
          onSetClaveOperadora={claveOperadora => this.onSetClaveOperadoraHandler(claveOperadora)}
          onSetClaveFondo={claveFondo => this.onSetClaveFondoHandler(claveFondo)}
          onSetTipoFondo={tipoFondo => this.onSetTipoFondohandler(tipoFondo)}
          onSetClasificacionFondo={clasificacionFondo => this.onSetClasificacionFondo(clasificacionFondo)}
          onSetWasFiltered={wasFiltered => this.onSetWasFilteredHandler(wasFiltered)}
          onSetFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
          onSetFechaFin={fechaFin => this.onSetFechaFin(fechaFin)}
          setFilters={filterParams => this.onSetFilters(filterParams)}
        />
        <br />
        <div className="margen_50">{dataShow}</div>
      </React.Fragment>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          {contenidoMostrar}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitFondos: (size, page, claveOperadora, claveFondo, tipo, clasificacion, fechaInicio, fechaFin) => {
      dispatch(actions.initFondosConcentradoPreciosGeneral(size, page, claveOperadora, claveFondo, tipo, clasificacion, fechaInicio, fechaFin));
    },
    onInitConcentradoPrecios: clave => {
      dispatch(actions.initFondosConcentradoPrecios(clave));
    },
    onFetchRssDocument: () => {
      dispatch(actions.fetchRssSeguimientosUrlDocument());
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(70));
    }
  };
};

const mapStateToProps = state => {
  return {
    fondos: state.fondos.fondos,
    concentradoPrecios: state.fondos.concentradoPrecios,
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConcentradoPrecios);

import React, { Component } from "react";
import classes from "./Modelo2.css";
import Slider from "react-slick";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getUrlDocument } from "../../../../stores/utils/utilities";

const url_api = process.env.REACT_APP_DOMINIO;
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 2,
  autoplay: false,
  speed: 200,
  autoplaySpeed: 0,
  cssEase: "linear",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    }
  ]
};

class Modelo2 extends Component {
  componentDidMount() {
  }

  render() {
    if (this.props.datos) {
      const contenido = this.props.datos.map((dato, i) => {
        const imageUrl = `${getUrlDocument(dato.relativeUrl)}`;
        let style = {
          backgroundImage: `url(${imageUrl})`
        };
        return (
          <div className={[classes.margen].join(" ")} key={"slider2-"+i}>
            <div className={["col", classes.titulo].join(" ")}>
            <div  style={style} className={[classes.imagen_titulo]} />
{/* 
              <img
                className={classes.imagen_titulo}
                src={getUrlDocument(dato.relativeUrl)}
                alt=""
              /> */}
              <span className={"texto_16 montse regular"}>
              <FormattedHTMLMessage id="invertir.comoinvertir.monto" defaultMessage=" " /> {dato.montoApertura}
              </span>
            </div>
            <div className={["col", classes.gris_medio].join(" ")}>
              <span className={"texto_16 montse regular"}>
                <FormattedHTMLMessage id="invertir.comoinvertir.fees" defaultMessage=" " /> 
              </span>
            </div>
            <div className={["col", classes.contenido_inversion].join(" ")}>
              <div className={["col", classes.col_flex].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                    <FormattedHTMLMessage id="invertir.comoinvertir.0a1" defaultMessage=" " /> 
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionHasta1M ? (
                      dato.comisionHasta1M
                    ) : (
                      <hr className={classes.hr_vacio} />
                    )}
                  </span>
                </div>
              </div>

              <div className={["col", classes.col_flex].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                    <FormattedHTMLMessage id="invertir.comoinvertir.1a3" defaultMessage=" " /> 
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionHasta3M ? (
                      dato.comisionHasta3M
                    ) : (
                      <hr className={classes.hr_vacio} />
                    )}
                  </span>
                </div>
              </div>

              <div className={["col", classes.col_flex].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                     <FormattedHTMLMessage id="invertir.comoinvertir.3a5" defaultMessage=" " /> 
                    
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionHasta5M ? (
                      dato.comisionHasta5M
                    ) : (
                      <hr className={classes.hr_vacio} />
                    )}
                  </span>
                </div>
              </div>

              <div className={["col", classes.col_flex].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                     <FormattedHTMLMessage id="invertir.comoinvertir.5a10" defaultMessage=" " /> 
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionHasta10M ? (
                      dato.comisionHasta10M
                    ) : (
                      <hr className={classes.hr_vacio} />
                    )}
                  </span>
                </div>
              </div>

              <div className={["col", classes.col_flex].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                     <FormattedHTMLMessage id="invertir.comoinvertir.mas10" defaultMessage=" " /> 
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionMax ? (
                      dato.comisionMax
                    ) : (
                      <hr className={classes.hr_vacio} />
                    )}
                  </span>
                </div>
              </div>

              <div className={["col", classes.col_flex].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                    <FormattedHTMLMessage id="invertir.comoinvertir.administrativas" defaultMessage=" " /> 
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionAdministrativa}
                  </span>
                </div>
              </div>

              <div className={["col", classes.col_flex].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                   <FormattedHTMLMessage id="invertir.comoinvertir.saldo" defaultMessage=" " /> 
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionSaldoPromedio}
                  </span>
                </div>
              </div>

              <div className={["col", classes.col_flex2].join(" ")}>
                <div className={classes.subtitulo}>
                  <span
                    className={["texto_16 montse regular color_azul"].join(" ")}
                  >
                    <FormattedHTMLMessage id="invertir.comoinvertir.custodia" defaultMessage=" " /> 
                  </span>
                </div>
                <div>
                  <span className={["texto_16 montse regular", classes.dato_variable].join(" ")}>
                    {dato.comisionCustodia}
                  </span>
                </div>
              </div>
            </div>
            <div className={["col"].join(" ")}>
              <div className={""}>
                <span className={["texto_12 montse regular", classes.nota].join(" ")}>
                <FormattedHTMLMessage id="invertir.comoinvertir.notaPorcentaje" defaultMessage=" " /> 
                </span>
              </div>
            </div>
          </div>
        );
      });

      return <Slider {...settings}>{contenido}</Slider>;
    }
  }
}

export default Modelo2;

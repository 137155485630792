import React, { Component } from "react";
import classes from "./Filter.css";
import { Form, Field } from "react-final-form";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import { getLan, formatDate, changeDateFormat } from "../../../stores/utils/utilities";
import Loader from "../../../components/loader/Loader";
import queryString from "query-string";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  options,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : "Seleccione una opción"
  };
  return (
    <div className="form-group">
      {label ? <label for={input.name}>{label}</label> : null}
      <Input config={params} />
    </div>
  );
};

class Filter extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    loader: true,
    toggleFilter: false,
    selectedFiltersModelOne: [],
    selectedFilters: {
      fechaInicio: [],
      fechaFin: []
    },
    form_modelo_1: {
      fechaInicio: {
        value: null,
        disabled: false
      },
      fechaFin: {
        value: null,
        disabled: true
      },
      error: null
    },
    target: null
  };

  componentDidMount() {
    const form1 = { ...this.state.form_modelo_1 };

    form1.fechaInicio.value = "";
    form1.fechaFin.value = "";

    this.setState({
      form_modelo_1: form1,
      target: this.props.target
    });

    if (this.props.loaderDisabled) {
      this.setState({
        loader: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.otrasNoticias !== prevProps.otrasNoticias) {
      this.setState({
        loader: false
      });
    }
    if (this.props.eventos !== prevProps.eventos) {
      this.setState({
        loader: false
      });
    }
    if (this.props.salaPrensa !== prevProps.salaPrensa) {
      this.setState({
        loader: false
      });
    }

    if (this.props.loaderDisabled !== prevProps.loaderDisabled) {
      this.setState({
        loader: false
      });
    }
    if (this.props.page !== prevProps.page) {
      const form1 = { ...this.state.form_modelo_1 };

      this.setState({
        activePage: this.props.page
      });
      this.changeHistory(form1.fechaInicio.value,form1.fechaFin.value,this.props.page)
    }
  }

  onToggleFilterHandler = () => {
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  formModelHandler = modelo => {
    switch (modelo) {
      case "modelo_1":
        return this.printFrameModelOneHandler();
      default:
        return null;
    }
  };
  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [...this.state.selectedFilters.fechaInicio, ...this.state.selectedFilters.fechaFin];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>{filter.label}</span>
              <span className={classes.filterIconButton} onClick={() => this.onDeleteSelectedFilterHandler(filter)}>
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onDeleteSelectedFilterHandler = item => {
    let form = this.state.form_modelo_1;
    let selectedFilters = this.state.selectedFilters;

    form[`${item.type}`].value = null;
    selectedFilters[`${item.type}`] = [];

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });
    let fecha_inicio = this.state.form_modelo_1.fechaInicio.value;
    let fecha_fin = this.state.form_modelo_1.fechaFin.value;
    if (item.type === "fechaInicio") {
      fecha_inicio = null;
    }
    if (item.type === "fechaFin") {
      fecha_fin = null;
    }

    this.onFilterDataByEvent(fecha_inicio, fecha_fin);
  };

  printFrameModelOneHandler = () => {
    const selectedFilters =
      this.state.selectedFilters.fechaInicio.length > 0 || this.state.selectedFilters.fechaFin.length > 0
        ? this.onPrintSelectedFiltersHandler()
        : null;
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    return (
      <React.Fragment>
        {loader}
        <div
          className={["row", classes.firstSectionFilterContainer, this.state.toggleFilter ? classes.noBorderRadius : null].join(
            " "
          )}
        >
          <div className="col col-lg-3 text-center sin_padding">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button type="button" className="btn" onClick={this.onToggleFilterHandler}>
                <i className="fas fa-filter" />
                <span>
                  <span>
                    {!this.state.toggleFilter
                      ? getLan() === "es"
                        ? "Filtrar"
                        : "Filter"
                      : getLan() === "es"
                      ? "Cerrar filtros"
                      : "Close"}
                  </span>
                </span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={[
            "row",
            classes.secondSectionFilterContainer,
            "margin_bottom_50",
            !this.state.toggleFilter ? "d-none" : null
          ].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </React.Fragment>
    );
  };

  onPrintSubFiltersHandler = () => {
    return (
      <div className={classes.modelo_uno_container}>
        <Form
          onSubmit={this.onSubmitModelOneHandler}
          initialValues={{
            fecha_inicio: this.state.form_modelo_1.fechaInicio.value,
            fecha_fin: this.state.form_modelo_1.fechaFin.value
          }}
          render={form => (
            <form ref={this.formRef1} onSubmit={this.onSubmitModelOneHandler}>
              <div className="row">
                <div className="col sin_padding">
                  <div className={["texto_14", "montse", "regular"]}>
                    <span className={["montse", "texto_14"].join(" ")}>
                      {" "}
                      {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                    </span>
                  </div>
                  <div className={[classes.fields, "montse", "texto_14"].join(" ")}>
                    <div className="row">
                      <div className={["col-12", "col-sm-12 col-md-8 col-lg-7 col-xl-5 sin_padding"].join(" ")}>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 sin_padding">
                            <Field
                              name="fecha_inicio"
                              id="fecha_inicio"
                              type="date"
                              classes={classes.fecha_inicio}
                              label={getLan() === "es" ? "Fecha inicio" : "From"}
                              onInputChange={this.onSelectedFiltersModelOne}
                              component={renderInput}
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 sin_padding">
                            <Field
                              name="fecha_fin"
                              id="fecha_fin"
                              type="date"
                              classes={classes.fecha_fin}
                              label={getLan() === "es" ? "Fecha fin" : "To"}
                              onInputChange={this.onSelectedFiltersModelOne}
                              component={renderInput}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-lg-12">
                        <div className={classes.errorContainer}>
                          {this.state.form_modelo_1.error ? (
                            <p className={"texto_14 montse regular"}>
                              {getLan() === "es"
                                ? "La fecha final debe ser mayor a la fecha inicial."
                                : "End date must be greater than start date."}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {this.sectionButtonsModelOne(form)}
            </form>
          )}
        />
      </div>
    );
  };

  sectionButtonsModelOne = form => {
    let disabledButton = false;
    //   this.state.form_modelo_1.fechaInicio.value !== "" && this.state.form_modelo_1.fechaFin.value !== "" ? false : true;

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFiltersModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onFilterRequestHandler = (target, fecha_inicio = null, fecha_fin = null) => {
    this.setState({ loader: true });
    if (target === "videos") {
      this.props.onFilterVideos(fecha_inicio, fecha_fin);
    } else if (target === "eventos") {
      this.props.onInitEvento(fecha_inicio, fecha_fin);
    } else if (target === "otrasNoticias") {
      this.props.onInitOtrasNoticias(fecha_inicio, fecha_fin);
    } else if (target === "salaPrensa") {
      this.props.onInitSalaPrensa(fecha_inicio, fecha_fin);
    }
  };

  onSubmitModelOneHandler = event => {
    event.preventDefault();
  };

  onFilterDataByEvent = (fecha_inicio, fecha_fin) => {
    let error = false;

    if (fecha_fin && fecha_inicio && fecha_fin < fecha_inicio) {
      error = true;
    }

    const form1 = { ...this.state.form_modelo_1 };
    form1.error = error;
    this.setState({
      form_modelo_1: form1
    });

    if (!error) {
      this.onFilterRequestHandler(this.state.target, fecha_inicio, fecha_fin);
    }
  };

  onResetFiltersModelOneHandler = form => {
    form.form.reset();
    const selectedFilters = this.state.selectedFilters;
    selectedFilters.fechaInicio = [];
    selectedFilters.fechaFin = [];

    const form1 = { ...this.state.form_modelo_1 };
    form1.fechaInicio.value = "";
    form1.fechaFin.value = "";
    form1.error = null;

    this.onFilterRequestHandler(this.state.target);

    this.changeHistory(null,null,1)

    this.setState({
      selectedFiltersModelOne: [],
      form_modelo_1: form1
    });
  };

  onSelectedFiltersModelOne = event => {
    const form1 = { ...this.state.form_modelo_1 };
    let label = event.target.name.replace("_", " ");
    let value = event.target.value;
    let selectedFilters = this.state.selectedFilters;

    let fecha_inicio = form1.fechaInicio;
    let fecha_fin = form1.fechaFin;
    if (label === "fecha inicio") {
      fecha_inicio.value = value;
      selectedFilters.fechaInicio = [];
      if (value !== "" && value !== null) {
        selectedFilters.fechaInicio.push({ label: changeDateFormat(value), value: value, type: "fechaInicio" });
      }
      this.changeHistory(value, fecha_fin.value, 1 );
    } else if (label === "fecha fin") {
      fecha_fin.value = value;
      selectedFilters.fechaFin = [];
      if (value !== "" && value !== null) {
        selectedFilters.fechaFin.push({ label: changeDateFormat(null, value), value: value, type: "fechaFin" });
      }
      this.changeHistory(fecha_inicio.value, value, 1 );

    }

    form1.fechaInicio.value = fecha_inicio.value;
    form1.fechaFin.value = fecha_fin.value;

    this.setState({
      form_modelo_1: form1,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent(form1.fechaInicio.value, form1.fechaFin.value);
  };


  changeHistory(fechaInicio = null, fechaFin, page) {
    this.props.history.push(
      `?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&page=${page}`
    );
  }

  onDeleteFiltersModelOne = filterName => {
    const form1 = { ...this.state.form_modelo_1 };
    const filterList = this.state.selectedFiltersModelOne;
    let fecha_inicio = form1.fechaInicio;
    let fecha_fin = form1.fechaFin;

    if (filterName === "fecha inicio") {
      fecha_inicio.value = "";
    } else {
      fecha_fin.value = "";
    }

    form1.fechaInicio.value = fecha_inicio.value;
    form1.fechaFin.value = fecha_fin.value;
    const updateFilters = filterList.filter(item => item.label !== filterName);

    this.setState({
      form_modelo_1: form1,
      selectedFiltersModelOne: updateFilters
    });

    this.onFilterRequestHandler(this.state.target, fecha_inicio.value, fecha_fin.value);
  };

  printSeletedFilterModelOne = () => {
    return this.state.selectedFiltersModelOne.map(filter => {
      return (
        <span key={filter.label} className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>{filter.label}</span>
          <span className={classes.filterIconButton} onClick={() => this.onDeleteFiltersModelOne(filter.label)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      );
    });
  };

  render() {
    const filter = this.props.modelo ? this.formModelHandler(this.props.modelo) : null;

    return filter;
  }
}
const mapStateToProps = state => {
  return {
    otrasNoticias: state.noticias.otrasNoticias,
    eventos: state.eventos.eventos,
    salaPrensa: state.salaPrensa.salaPrensa
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterVideos: (fec_ini, fec_fin) => {
      dispatch(actions.initVideosInstituto(fec_ini, fec_fin));
    },
    onInitEvento: (fec_ini, fec_fin) => {
      dispatch(actions.initEventos(fec_ini, fec_fin));
    },
    onInitOtrasNoticias: (fec_ini, fec_fin) => {
      dispatch(actions.initOtrasNoticias(fec_ini, fec_fin));
    },
    onInitSalaPrensa: (fec_ini, fec_fin) => {
      dispatch(actions.initSalaPrensa(fec_ini, fec_fin));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);

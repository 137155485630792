import React, { Component } from "react";
import classes from "./FilterProspectos.css";
import { Form, Field } from "react-final-form";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  onInputChange,
  label,
  placeholder = null,
  options,
  checked = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      {label && input.type !== "radio" && input.type !== "checkbox" ? (
        <label className={["montse", "texto_14", "regular"].join(" ")} for={input.name}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterProspectos extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    esconderAutoComplete: false,
    toggleFilters: false,
    disableEnableButtons: true,
    selectedFilters: {
      instrumentos: [],
      mercados: []
    },
    form_filters: {
      instrumentos: {
        value: ""
      },
      mercados: {
        value: ""
      }
    },
    form_clave: {
      clave: {
        value: ""
      }
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    clave: null,
    mercados:[]
  };

  componentWillMount() {
    this.props.onFilterMercadosProspectos(this.props.tipoBono);
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  componentDidMount() {
      let paramsString = queryString.parse(this.props.location.search);
  
      let emisora = paramsString["emisora"] !== undefined && paramsString["emisora"] !== "null" ? paramsString["emisora"] : null;
      let clave = paramsString["clave"] !== undefined && paramsString["clave"] !== "null" ? paramsString["clave"] : null;
      if (emisora) {
        this.setState({
          toggleInputs: true,
          disableEnableButtons: false,
          typeClaveSelected: 1,
          valueClave: clave,
        });
      }else {
        this.setState({
          disableEnableButtons: this.props.isSustentable ? false : true,
        });
      }
    
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
    
  }
  componentWillUnmount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.mercados !== this.props.mercados) {
        let tipoMercado = [];
        if (this.props.mercados.length !== undefined) {
          tipoMercado = this.getTiposMercadoTab(this.props.mercados);
        }

        this.setState({
          mercados: tipoMercado,
        });
    }
  }

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible
    });
  }

  getTiposMercadoTab(mercados) {
    let tipoMercado = [];
    mercados.map(item => {
      let nombre = null;
      if (getLan() !== "es") {
        switch (item.id) {
          case "1":
            nombre = "Equity";
            break;
          case "2":
            nombre = "Debt";
            break;
          default:
            nombre = item.nombre;
            break;
        }
      } else {
        nombre = item.nombre;
      }
      let tipoMercado_ = { id: item.id, nombre: nombre };
        tipoMercado.push(tipoMercado_);
      return tipoMercado_;
    });
    return tipoMercado;
  }

  onPrintFrameHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : this.showInputHandler();
    return (
      <div className={["row", this.props.isSustentable ? classes.sustentable : null].join(" ")}>
        <div className="col sin_padding">
          <div className="row">
            <div className={["col-3", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar",
                  classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar filtros"
                        : "Close"
                      : getLan() === "es"
                      ? "Filtrar"
                      : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-8", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col-2 col-sm-1",
                "col-lg-1",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters ? classes.no_border_search_button_model_one : null
                ].join(" ")}
              >
                <button
                  type="buttom"
                  className={["btn"].join(" ")}
                  /* disabled={this.state.disableEnableButtons} */
                  disabled
                >
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  onPrepareOptionHandler = type => {
    let options = [];
    if (type && type.length > 0) {
      type.forEach(element => {
        options.push({
          nombre: element.nombre,
          id: `${element.id}_${element.nombre}`
        });
      });
    }
    return options;
  };

  onPrintValoresHandler = tipo => {
    return (
      <Field
        name="mercados"
        id={tipo.nombre}
        type="radio"
        elementType="radio2"
        value={tipo.id}
        classes="form-check-input"
        label={tipo.nombre}
        checked={this.state.form_filters.mercados.value === tipo.id}
        onInputChange={event => this.onInputSelectedHandler(event, 0)}
        component={renderInput}
      />
    );
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              mercados: this.state.form_filters.mercados.value,
              instrumentos: this.state.form_filters.instrumentos.value
            }}
            render={({
              optionsInstrumentos = this.props.instrumentos ? this.onPrepareOptionHandler(this.props.instrumentos) : [],
              optionsMercados = this.state.mercados ? this.onPrepareOptionHandler(this.state.mercados) : [],
              form
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterModelOneHandler}>
                <div className="row">
                  <div className="col-12 col-sm-12  col-md-7 col-lg-5">
                    <div className="row">
                      <div className="col-12 sin_padding_L sin_padding_R">
                        <span className="montse texto_14 regular color_negro">{getLan() === "es" ? "Mercado" : "Market"}</span>
                      </div>
                      <div className={["col-12 sin_padding_L sin_padding_R", classes.padding_izquierdo].join(" ")}>
                        {optionsMercados.map(tipo => {
                          return <div className={classes.radioB}>{this.onPrintValoresHandler(tipo)}</div>;
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className={[
                      "col-12 col-sm-12  col-md-5 col-lg-7",
                      !this.state.form_filters.mercados.value || this.state.form_filters.mercados.value === ""
                        ? classes.hidden
                        : null
                    ].join(" ")}
                  >
                    <Field
                      name="instrumentos"
                      id="instrumentos"
                      label={getLan() === "es" ? "Instrumento" : "Security type"}
                      type="select"
                      elementType="select"
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsInstrumentos}
                      onInputChange={event => this.onInputSelectedHandler(event, 1)}
                      component={renderInput}
                    />
                  </div>
                </div>
                <hr />
                {this.onSectionButtonHandler(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  onSectionButtonHandler = form => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterHandler()}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterModelOneHandler = event => {
    event.preventDefault();
  };

  onSubmitSearchModelOneHandler = event => {
    event.preventDefault();
  };

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = value => {
    this.onInputChangeModelOne(value);
  };

  onInputChangeModelOne = (event = null, eraser = null, tipo = null) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;

    if (value !== "") {
      this.props.onFilterClaves(value, this.props.tipoBono);
    } else {
      disabled = true;
    }

    if (tipo) {
      this.props.setEmisora(null, 3);
    }

    if (eraser) {
      this.setState(prevState => {
        return {
          toggleInputs: !prevState.toggleInputs,
          emisoraSeleccionada: null,
          claveSeleccionada: null,
          form_clave: form_clave,
          disableEnableButtons: true
        };
      });

      this.onFilterDataByEvent(null, null);
    } else {
      this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
    }
  };

  onResetFilterHandler = () => {
    const form_filter = this.state.form_filters;
    const form_clave = this.state.form_clave;
    const selectedFilters = this.state.selectedFilters;

    form_filter.mercados.value = "";
    selectedFilters.mercados = [];
    form_filter.instrumentos.value = "";
    selectedFilters.instrumentos = [];
    form_clave.clave.value = "";

    this.props.setEmisora(null);
    this.props.setInstrumento(null);
    this.props.setMercado(null);
    this.props.setPage(1);

    const params = {
      page: 1,
      mercado: null,
      instrumento: null,
      emisora: null,
      clave: null,
      reset: true
    };

    this.props.setParams(params);

    this.setState({
      form_filters: form_filter,
      form_clave: form_clave,
      selectedFilters: selectedFilters
    });
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onInputModelOneChange = event => {
    const value = event.target.value;
    const disabled = value ? false : true;
    this.setState({
      disableEnableButtons: disabled
    });
  };

  onFilterInstrumentoHandler = () => {
    this.props.onFilterInstrumentos(null);
  };

  onFilterTipoValorHandler = (instrumento = null, tipoValor) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterDocumentos = (mantenimientoId = null, listadoId = null) => {
    const params = {
      tipoInformacion: mantenimientoId,
      tipoInformacionListado: listadoId
    };
    this.props.onFilterTipoDocumentos(params);
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = value => {
    let newValue = null;
    let newLabel = null;

    newValue = value.split("_")[0];
    newLabel = value.split("_")[1];

    return [newValue, newLabel];
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor del campo mercados
    const valuesMercados =
      form.mercados.value && form.mercados.value !== "" ? this.onSplitValueHandler(form.mercados.value) : "";

    // Se obtiene el valor del campo minstrumentos
    const valuesInstrumentos =
      form.instrumentos.value && form.instrumentos.value !== "" ? this.onSplitValueHandler(form.instrumentos.value) : "";

    return [valuesMercados, valuesInstrumentos];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["mercados", form.mercados.value, selectedFilters.mercados];
      case 1:
        return ["instrumentos", form.instrumentos.value, selectedFilters.instrumentos];
    }
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = (emisora = null, clave = null) => {
    const [valuesMercados, valuesInstrumentos] = this.onGetAllValuesFormFilter();

    const params = {
      page: 1,
      mercado: valuesMercados,
      instrumento: valuesInstrumentos,
      emisora: emisora,
      clave: clave,
      reset: false
    };

    this.props.setParams(params);

    const wasFiltered = valuesMercados !== "" || valuesInstrumentos !== "" ? true : false;
    // Se invoca la funcion del componente padre (ProspectosColocacion.js) para setear los valores correspondientes
    // Configurando los valores para mercado
    this.props.setMercado(valuesMercados);
    // Configurando los valores para instrumentos
    this.props.setInstrumento(valuesInstrumentos);

    this.props.setPage(1);
    this.props.wasFiltered(wasFiltered);
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (values = null) => {
    return values && values !== "" ? values : null;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];
    if (values && values !== "") {
      newFilterValues.push({ label: label, value: values, type: type });
    }
    return newFilterValues;
  };

  onInputSelectedHandler = (event, type = null) => {
    let actualValue = event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newValue = null;
    let newLabel = null;

    [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue);

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(actualValue);
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
    const [valuesMercados, valuesInstrumentos] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      // Mercados
      // Limpiando valores para campo instrumentos
      form.instrumentos.value = "";
      selectedFilters.instrumentos = [];
      // Filtrando campo instrumentos
      this.props.onFilterInstrumentos(valuesMercados, this.state.emisoraSeleccionada, this.state.clave,this.props.tipoBono);
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  onDeleteFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler();
    newFilterValues = this.onChangeFilterHandler(filterValues, null, item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
    const [valuesMercados, valuesInstrumentos] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (item.type === 0) {
      // Mercados
      // Limpiando valores para campo instrumentos
      form.instrumentos.value = "";
      selectedFilters.instrumentos = [];
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.selectedFilters.instrumentos, ...this.state.selectedFilters.mercados];

    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map(item => (
          <div className={classes.filter_container_model_one} key={`${item.nombre}-${item.id}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{item.label}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.props.setEmisora(null, 3);

      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null
      });
    }
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => (
      <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item, item, 1)}>
        {item}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let emisoraSeleccionada = null;
    let claveSeleccionada = null;
    if (option === 0) {
      this.props.setEmisora(clave, 2);
      claveSeleccionada = clave;
    } else {
      emisoraSeleccionada = id;
      this.props.setEmisora(emisoraSeleccionada, 1);
    }
    this.props.onFilterInstrumentos(this.state.form_filters.mercados.value, emisoraSeleccionada, claveSeleccionada, this.props.tipoBono);

    this.setState({
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: emisoraSeleccionada,
      clave: claveSeleccionada
    });

    this.onFilterDataByEvent(emisoraSeleccionada, clave);
  };

  onPrintOptionSelectedHandler = value => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
        <span className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onInputChangeModelOne(null, true, true)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.listadoClaves ? this.onPrintAutoCompleteHandler() : null;

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {getLan() === "es" ? `Ver todos los resultados para: ${value}` : `See all results for: ${value}`}
        </li>
      ) : (
        <li className="list-group-item">
          {getLan() === "es" ? "No existen resultados para la búsqueda:" : "There are no results for the search:"} {value}
        </li>
      );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div className={[classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null, this.props.isSustentable ? classes.sustentable : null].join(" ")}>
          <Form
            onSubmit={this.onSubmitSearchModelOneHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value
            }}
            render={() => (
              <form ref={this.formSearch} onSubmit={this.onSubmitSearchModelOneHandler}>
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  placeholder={getLan() === "es" ? "Buscar por clave de cotización" : "Search by ticker"}
                  component={renderInput}
                  onInputChange={event => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              !this.state.form_clave.clave.value || this.state.form_clave.clave.value === "" ? classes.hidden : null,
              this.state.esconderAutoComplete ? classes.hidden : null
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const filter = this.onPrintFrameHandler();
    return filter;
  }
}

const mapStateToProps = state => {
  return {
    instrumentos: state.prospectos.instrumentos,
    listadoClaves: state.prospectos.claves,
    mercados: state.prospectos.mercados
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitProspectosColocacion: (size, page, mercado, instrumento, emisora, clave) => {
      dispatch(actions.initProspectosColocacion(size, page, mercado, instrumento, emisora, clave));
    },
    onFilterInstrumentos: (mercado, emisora, clave, tipoBono) => {
      dispatch(actions.initValoresProspectos(mercado, emisora, clave, tipoBono));
    },
    onFilterClaves: (clave, tipoBono) => {
      dispatch(actions.initClavesProspectos(clave, tipoBono));
    },

    onFilterMercadosProspectos: (emisora, tipoBono) => {
      dispatch(actions.initMercadoProspectos(emisora, tipoBono));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterProspectos);

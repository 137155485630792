import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

const setTipoDocumento = data => {
  return {
    type: actionTypes.FETCH_TIPODOCUMENTOS_SUCCESS,
    tipo_documento: data
  };
};

export const filterTipoDocumentos = (emisoraId, tipoInformacionId, tipo) => {
  const base_url = `${dominio}/emisoras/empresas/${emisoraId}`;
  const url =
    tipo === "listado"
      ? `${base_url}/tipo-documento?tipoInformacionListado=${tipoInformacionId}`
      : `${base_url}/tipo-documento?tipoInformacion=${tipoInformacionId}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoDocumento(response.data));
      })
      .catch(err => console.log(err));
  };
};

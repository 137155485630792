import React, { Component } from "react";
import classes from "./Tooltip.css";

// Only for use jquery in this document.
const $ = window.$;

class Tooltip extends Component {
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    const iconClass = this.props.icon
      ? this.props.icon
      : "far fa-question-circle";

    const position = this.props.position ? this.props.position : "bottom";

    return (
      <i
        className={[iconClass, classes.icon].join(" ")}
        data-toggle="tooltip"
        data-placement={position}
        title={this.props.title}
      />
    );
  }
}

export default Tooltip;

import React, { Component } from "react";
import { connect } from "react-redux";
import Items from "./items/Items";

class Sidebar extends Component {
  state={
    genericos : null,
    menu : null
  }

  componentDidUpdate(nextProps){
      if(this.props.menu !== nextProps.menu){
        this.props.setMenu(this.props.menu,1)
      }

      if(this.props.genericos !== nextProps.genericos){
        this.props.setMenu(this.props.genericos.idPage,2)
        if (this.props.menu) {
        this.props.setMenu(this.props.menu,1)
        }
      }
  }

  getMainMenu = () => {
    let menu = null;
    if(this.props.genericos.actualPageName){
      const actualPage = this.props.genericos.actualPageName;
      const idPage = this.props.genericos.idPage;
      const existMenu = this.props.menu.menu.find(menu => menu.id === idPage)
      if (existMenu) {
        menu = <Items data={existMenu} iconClass={this.props.classIcon}/>
      }else{
        menu = this.props.menu.menu.map(item => {
          return actualPage.includes(item.id) ? <Items data={item} iconClass={this.props.classIcon}/> : null;
        });
      }
    }
    return menu;
  };

  render() {
    const mainmenu = this.props.menu ? this.getMainMenu() : null;
    return (
      <div className="wrapper">
        <nav id="sidebar">
          <div className="sidebar-header">{mainmenu}</div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  if (state.genericos) {
    state.genericos.actualPageName = window.location.pathname;
  }
  return {
    menu: state.menu.menu,
    genericos: state.genericos
  };
};

export default connect(mapStateToProps)(Sidebar);

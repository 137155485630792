import React, { Component } from "react";
// import classes from "./Itch.css";
import Box from "../../../../components/ui/box/Box";
import Cuadricula from "../../../../components/ui/cuadricula/Cuadricula";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../../stores/utils/utilities";

class Itch extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    cuadricula:null,
    descarga: {
      leftIcon: "far fa-file-pdf",
      title: "Consulta a detalle las especificaciones",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url:
        "https://biva.mx/documents/30877/172203/BIVA+X-External+ITCH+Specification+v1.07.pdf/7e9900d0-1e14-5639-a748-852760bb206c"
    },
    contratar1: {
      leftIcon: "far fa-file-pdf",
      title: "Solicitar acceso a canales electrónicos",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url:
        "https://biva.mx/documents/30877/172203/BIVA+Solicitud+Acceso+a+Canales+Electr%C3%B3nicos+Market+Data+v2.pdf/807e1b53-89f2-4317-e295-b70f30ffc980"
    },
    contratar2: {
      leftIcon: "far fa-file-pdf",
      title: "Descargar contrato Market Data",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url: "https://biva.mx/documents/30877/172203/BIVA+Contrato+Market+Data.pdf/06353704-30af-d48a-fb08-abefeb23f81d"
    },
    contratar3: {
      leftIcon: "far fa-file-pdf",
      title: "Descargar anexo contrato Market Data",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url: "https://biva.mx/documents/30877/172203/BIVA+Anexos+Contrato+Market+Data.pdf/06061d2c-29ec-00dd-5559-b552255812ad"
    },
    cuadricula2: [
      {
        titulo: "BIVA total ",
        texto: "Último hecho + básico + profundidad completa del libro.",
        clase: "normal",
        icono_url: "biva_total.svg"
      },
      {
        titulo: "BIVA basico ",
        texto: "Último hecho + mejor postura de compra venta.",
        clase: "normal",
        icono_url: "biva_basico.svg"
      },
      {
        titulo: "BIVA último hecho",
        texto: "Precio y volumen de la última operación concertada.",
        clase: "normal",
        icono_url: "biva_ultimo.svg"
      },
      {
        titulo: "BIVA noticias",
        texto: "Noticias de mercado y eventos relevantes de emisoras.",
        clase: "normal",
        icono_url: "biva_noticias.svg"
      }
    ]
  };

  componentDidMount() {
    this.props.initCuadriculaConexionItch();
    if (this.props.messages) {
        this.setState({
          messages: this.props.messages
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.messages !== prevProps.messages) {
      //se obtiene el diccionario del endpoint de traducciones
        this.setState({
          messages: this.props.messages,
        });
    }
    if (this.props.cuadricula !== prevProps.cuadricula) {
      //se obtiene el diccionario del endpoint de traducciones
        this.setState({
          cuadricula: this.props.cuadricula,
        });
    }
  }

  render() {
    let contratos = null;
    if (this.props.contrato) {
      contratos = this.props.contrato.map((contrato, i) => {
        return <Box key={"contrato" + i} modelo="modelo_1" data={contrato}  />;
      });
    }
    let especificacionesItch = null;
    if (this.props.especificacionesItch) {
      especificacionesItch = this.props.especificacionesItch.map((especificacion, i) => {
        return <Box key={"especificacion" + i} modelo="modelo_1" data={especificacion}  />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <div className=" margen_top_30">
            <span className={"montse texto_16"}>
              <FormattedHTMLMessage id="informacionmercado.productos.itch.desc" defaultMessage=" " />
            </span>
          </div>
          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedHTMLMessage id="informacionmercado.productos.itch.tiposconexion" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className={["maren_top_30"]}>
              <Cuadricula
                datos={this.state.cuadricula}
                tipo={7}
                coloreados={[]}
                saltarse={[5]}
                enBlanco={[]}
                quitar={[]}
                modulo={3}
              />
            </div>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="informacionmercado.productos.itch.conocesespec" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row">
              <div className={""}>
                {especificacionesItch}
                {/* <Box modelo="modelo_1" data={this.state.descarga} /> */}
              </div>
            </div>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  {" "}
                  <FormattedHTMLMessage id="informacionmercado.productos.itch.contratar" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row">
              <p className={"montse texto_18 regular "}>
                <FormattedHTMLMessage id="informacionmercado.productos.itch.contratar.desc" defaultMessage=" " />
              </p>
              <div className={"row margen_top_30"}>
                {contratos}
                {/* <Box modelo="modelo_1" data={this.state.contratar1} />
                <Box modelo="modelo_1" data={this.state.contratar2} />
                <Box modelo="modelo_1" data={this.state.contratar3} /> */}
              </div>
            </div>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    cuadricula: state.cuadriculas.conexionItch,
    contrato: state.documentos.enlacesContratarItch,
    especificacionesItch: state.documentos.enlacesEspecificacionesItch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initCuadriculaConexionItch: () => {
      dispatch(action.initCuadriculaConexionItch());
      dispatch(action.getEnlacesContratarItch());
      dispatch(action.getEnlacesEspecificacionesItch());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Itch);

import axios from "axios";
import * as actionTypes from "./actionTypes";

import { getLan } from "../utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();
const setContactos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CONTACTOS_SUCCESS,
      contactos: []
    };
  }
  return {
    type: actionTypes.FETCH_CONTACTOS_SUCCESS,
    contactos: data
  };
};

export const onGetContactos = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/contactanos/lang/" + lang)
      .then(response => {
        dispatch(setContactos(response.data));
      })
      .catch(err => dispatch(setContactos(err.data)));
  };
};

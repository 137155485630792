import React, { Component } from "react";
import classes from "./Item.css";
import { getUrlDocument } from "../../../../stores/utils/utilities";

class Item extends Component {
  componentDidMount() {
  }

  render() {
    let item = null;
    if (this.props.data) {
      if (this.props.es_video !== undefined && this.props.es_video === true) {
        item = (
          <div className={classes.video_container}>
            <iframe
              className={classes.iframe}
              src={"https://www.youtube.com/embed/"+this.props.data.snippet.resourceId.videoId}
              frameborder="0"
              title="videos"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        );
      } else if(this.props.es_video !== undefined && this.props.es_video === false) {
        let ruta = null;
        if (this.props.data.url) {
          ruta = getUrlDocument(this.props.data.url);
        }

  
        item = (
          <div className={classes.video_container}>
            <img src={ruta} alt="auditorio_BIVA" className={classes.imagen}/>
          </div>
        );
      } else if (this.props.data.es_video) {
        item = (
          <div className={classes.video_container}>
            <iframe
              className={classes.iframe}
              src={this.props.data.url_video}
              frameborder="0"
              title="videos"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        );
      } else {
        if (this.props.data.url) {
          item = (
            <div className={classes.video_container}>
              <img src={getUrlDocument(this.props.data.url)} alt="auditorio_BIVA" className={classes.imagen}/>
            </div>
          );
        }
      }
    }

    return (
      <React.Fragment>
       {item}
      </React.Fragment>
    );
  }
}

export default Item;

import React, { Component } from "react";
import classes from "./QueEstaPasando.css";
import { connect } from "react-redux";
import Items from "./items/Items";
import Images from "./items/Images";
import Pdfs from "./items/Pdfs";
import * as action from "../../../stores/actions/index";
import { withRouter } from "react-router-dom";
import Fade from "react-reveal/Fade";
import {
  getLan,
  getLocal,
  getUrlDocument,
} from "../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";

class QueEstaPasando extends Component {
  state = {
    tabActivo: 1,
    selectedVideo: {},
    selectedImage: {},
    imagenes: [],
    selectedPdf: "",
    pdfs: [],
    lista_ver: [],
    pdfUrl: null,
    pdfArticulo: null,
    pdfRevista: null,
    pdfEscrito: null,
    pdfBlog: null,
    pdfLibro: null,
    pdfDiccionario: null,
    messages: null,
    lan: getLocal(),
    tabActiveTemp: null,
  };
  componentDidMount() {
    this.props.onDocumentos();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.videos !== this.props.videos) {
      if (this.props.videos !== null) {
        this.setState({
          lista_ver: this.props.videos,
          selectedVideo: this.props.videos[0].contentDetails.videoId,
        });
      }
      this.setState({ tabActiveTemp: +new Date() });

    }
    if (prevProps.imagenes !== this.props.imagenes) {
      if (this.props.imagenes !== null) {
        this.setState({
          imagenes:
            this.props.imagenes.length > 0
              ? this.props.imagenes.slice(0, 6)
              : [],
          selectedImage:
            this.props.imagenes.length > 0 ? this.props.imagenes[0] : "",
        });
      }
      this.setState({ tabActiveTemp: +new Date() });
    }
    if (prevProps.pdfs !== this.props.pdfs) {
      if (this.props.pdfs !== null) {
        this.setState({
          pdfs: this.props.pdfs.length > 0 ? this.props.pdfs.slice(0, 6) : [],
          selectedPdf: this.props.pdfs.length > 0 ? this.props.pdfs[0] : "",
        });
      }
      this.setState({ tabActiveTemp: +new Date() });

    }
    if (this.props.diccionarioIntl !== prevProps.diccionarioIntl) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionarioIntl) {
        const messages = defineMessages(this.props.diccionarioIntl);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.state.tabActiveTemp !== prevState.tabActiveTemp) {
      const showVideos =
        this.state.lista_ver && this.state.lista_ver.length > 0 ? true : false;
      const showImages =
        this.state.imagenes && this.state.imagenes.length > 0 ? true : false;
      let tabActive = 1;
      if (!showVideos) {
        tabActive = 2;
      }
      if (!showImages) {
        tabActive = 3;
      }

      this.setState({ tabActivo: tabActive });
    }
  }
  changeTab(event, tab) {
    this.setState({
      tabActivo: tab,
    });
  }

  changeCurrentItem = (item, type = 1) => {
    this.setState({ tabActive: type });
    switch (type) {
      case 1:
        this.setState({ selectedVideo: item.contentDetails.videoId });

        break;
      case 2:
        this.setState({ selectedImage: item });

        break;

      default:
        this.setState({ selectedPdf: item });

        break;
    }
  };

  changePage() {
    const { history } = this.props;
    history.push("/instituto_biva/bivateca");
  }

  changePdf = (pdf) => {
    // this.setState({ selectedVideo: video.contentDetails.videoId });
  };
  viewMore = (e, tabActive) => {
    const { history } = this.props;
    this.setState({ tabActivo: tabActive });
    e.preventDefault();
    switch (tabActive) {
      case 1:
        history.push("/instituto_biva/embajador_biva_universitario?videos");

        break;
      case 2:
        history.push("/instituto_biva/embajador_biva_universitario?imagenes");

        break;

      default:
        history.push("/instituto_biva/embajador_biva_universitario?documentos");

        break;
    }
  };

  render() {
    let showVideos =
      this.state.lista_ver && this.state.lista_ver.length > 0 ? true : false;
    let showImages =
      this.state.imagenes && this.state.imagenes.length > 0 ? true : false;
    let showPdfs = this.state.pdfs && this.state.pdfs.length > 0 ? true : false;
    let tabActive = this.state.tabActivo;

    if (!showImages && !showPdfs) {
      return <></>;
    }

    let contenido = null;
    let listado = null;
    if (tabActive === 1) {
      contenido = (
        <div className={classes.informacion_container}>
          <Fade right delay={600} key={this.state.selectedVideo}>
            <iframe
              width="100%"
              height="415"
              src={"https://www.youtube.com/embed/" + this.state.selectedVideo}
              frameBorder="0"
              title="videos"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </Fade>
        </div>
      );

      listado = (
        <div className={classes.list_container}>
          <ul className={["lsit-group sin_padding", classes.scroll].join(" ")}>
            {this.state.lista_ver.map((video, i) => {
              if (
                video.snippet.thumbnails &&
                video.snippet.thumbnails.default
              ) {
                return (
                  <Fade left delay={100 * (i + 1)} key={i + "_" + i}>
                    <Items
                      key={i}
                      item={video}
                      tipo={1}
                      onItemSelected={() => this.changeCurrentItem(video, 1)}
                    />
                  </Fade>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      );
    } else if (tabActive === 2) {
      const ruta = getUrlDocument(this.state.selectedImage.url_descarga);
      let style = {
        backgroundImage: `url(${ruta})`,
        zIndex: 0
      };
      contenido = (
        <div className={classes.informacion_container}>
          <Fade right delay={600} key={this.state.selectedImage.url_descarga}>
          <div  style={style} className={classes.image} />
          </Fade>
        </div>
      );

      listado = (
        <div className={classes.list_container}>
          <ul className={["list-group sin_padding", classes.scroll].join(" ")}>
            {this.state.imagenes.map((video, i) => {
              return (
                <Fade left delay={100 * (i + 1)} key={i + "_" + i}>
                  <Images
                    key={i}
                    item={video}
                    tipo={2}
                    onItemSelected={() => this.changeCurrentItem(video, 2)}
                  />
                </Fade>
              );
            })}
          </ul>
        </div>
      );
    } else {
      listado = (
        <div className={classes.list_container}>
          <ul className={["list-group sin_padding", classes.scroll].join(" ")}>
            {this.state.pdfs.map((item, i) => {
              return (
                <Fade left delay={100 * (i + 1)} key={i + "_" + i}>
                  <Pdfs
                    key={i}
                    item={item}
                    onItemSelected={() => this.changeCurrentItem(item, 3)}
                  />
                </Fade>
              );
            })}
          </ul>
        </div>
      );

      let pdfUrl = this.state.pdfUrl;
      if (this.state.pdfUrl && this.state.pdfUrl.includes("?download=true")) {
        pdfUrl = pdfUrl.replace("?download=true", "");
      }
      contenido = (
        <div
          className={[classes.informacion_container2].join(" ")}
          key={pdfUrl}
        >
          <Fade right>
            <iframe
              src={getUrlDocument(this.state.selectedPdf.url_descarga)}
              frameborder="0"
              title="pdf"
            />
          </Fade>
        </div>
      );
    }

    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <React.Fragment>
        <div className="row margen_top_50">
              <div className="col sin_padding_L">
                <div className={["main_subtitle"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage
                      id="institutobiva.embajador.subtitulo7"
                      defaultMessage=" "
                    />
                  </h2>
                </div>
              </div>
            </div>
          <div className={[classes.fondo].join(" ")}>
            <div className={["container", classes.contenedor].join(" ")}>
              <div className={[classes.row, classes.contenedor].join(" ")}>
                <div
                  className={[
                    "row",
                    classes.vertical,
                    classes.contenedor,
                    tabActive === 2 ? classes.fondo_blanco : null,
                  ].join(" ")}
                >
                  <div
                    className={[
                      "col-12 col-sm-12 col-md-12 col-xl-6 sin_padding",
                      classes.tamaño_titulos,
                    ].join(" ")}
                  >
                    <div className={classes.poligono} />
                    <div className={classes.contenido_poligono}>
                      <div
                        className={[
                          showVideos ? null : "d-none",
                          classes.tab,
                          1 === tabActive ? classes.tabActivo : null,
                        ].join(" ")}
                        onClick={(e) => this.changeTab(e, 1)}
                      >
                        <span
                          className={[
                            classes.texto_vertical,
                            "nunito texto_35 color_blanco nunito noselect",
                          ].join(" ")}
                        >
                          <FormattedMessage
                            id="institutobiva.embajador.subtitulo4"
                            defaultMessage=" "
                          />
                        </span>
                      </div>
                      <div
                        className={[
                          showImages ? null : "d-none",
                          classes.tab,
                          2 === tabActive ? classes.tabActivo : null,
                        ].join(" ")}
                        onClick={(e) => this.changeTab(e, 2)}
                      >
                        <span
                          className={[
                            classes.texto_vertical,
                            "nunito texto_35 color_blanco nunito noselect",
                          ].join(" ")}
                        >
                          <FormattedMessage
                            id="institutobiva.embajador.subtitulo5"
                            defaultMessage=" "
                          />
                        </span>
                      </div>
                      <div
                        className={[
                          showPdfs ? null : "d-none",
                          classes.tab,
                          3 === tabActive ? classes.tabActivo : null,
                        ].join(" ")}
                        onClick={(e) => this.changeTab(e, 3)}
                      >
                        <span
                          className={[
                            classes.texto_vertical,
                            "nunito texto_35 color_blanco nunito noselect",
                          ].join(" ")}
                        >
                          <FormattedMessage
                            id="institutobiva.embajador.subtitulo6"
                            defaultMessage=" "
                          />
                        </span>
                      </div>
                      <span
                        onClick={(e) => this.viewMore(e, tabActive)}
                        className={[
                          "color_blanco nunito texto_16",
                          classes.more,
                        ].join(" ")}
                      >
                        {getLan() === "es" ? "Ver mas" : "View more"}
                      </span>
                      <div className={classes.contenido_tab}> {listado}</div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-xl-6 sin_padding">
                    {contenido}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDocumentos: () => {
      dispatch(action.initIntl(5));
      dispatch(action.getVideosEmbajador(6));
      dispatch(action.getImagenesQueEstaPasando());
      dispatch(action.getDocumentosQueEstaPasando());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    diccionarioIntl: state.intl.diccionario,
    videos: state.videos.videos_embajador
      ? state.videos.videos_embajador.items
      : null,
    imagenes: state.imagenes.queEstaPasando,
    pdfs: state.documentos.queEstaPasando,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QueEstaPasando)
);

import React, { Component } from "react";
import * as action from "../../../stores/actions/index";
import { connect } from "react-redux";

import Content from "./content/Content";
import Blog from "./blog/Blog";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLan, getLocal } from "../../../stores/utils/utilities";
import StringUtils from "../../../stores/utils/StringUtils";
import queryString from "query-string";
import Paginations from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import classes from "./Blogs.css";


class Blogs extends Component {
  state = {
    filter: {
      yearMonth: null,
      date: null,
      keyword: null,
      lang: getLan() !== "es" ? "en" : "es",
    },
    itemsPerPage: 10,
    blogSelected: null,
    messages: null,
    equipo: [],
    lan: getLocal(),
    activePage: 1,
    loader: true,
  };
  componentDidMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let id =
      paramsString["id"] !== undefined && paramsString["id"] !== "null"
        ? paramsString["id"]
        : null;
    if (id) {
      this.setState({ blogSelected: id });
    } else {
      this.props.onInitIntl(95);
      this.getBlogs();
    }
  }

  getBlogs(page = null) {
    const pageSelected = page ? page : this.state.activePage;
    const params = {
      yearMonth: this.state.filter.yearMonth,
      date: this.state.filter.date,
      keyword: this.state.filter.keyword,
      lang: this.state.filter.lang,
      page: pageSelected - 1,
      size: this.state.itemsPerPage,
    };
    this.props.getBlogs(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.location.search !== prevProps.location.search) {
      let paramsString = queryString.parse(this.props.location.search);

      let id =
        paramsString["id"] !== undefined && paramsString["id"] !== "null"
          ? paramsString["id"]
          : null;
      this.setState({ blogSelected: id });
      if (id === null) {
        this.props.onInitIntl(95);
        this.getBlogs();
      }
    }
    if (this.props.blogs !== prevProps.blogs) {
      this.setState({ loader: false });
    }
  }

  selectedBlog(id) {
    this.setState({ blogSelected: id });
    const params = {
      id: id,
    };
    const queryParams = StringUtils.jsonToQueryString(params);

    this.props.history.push(`/nosotros/acerca_de/blogs` + queryParams);
  }

  onBack() {
    this.selectedBlog(null);
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      loader: true,
    });
    this.getBlogs(pageNumber);
  }

  render() {
    let contenido = null;
    if (this.state.blogSelected) {
      contenido = (
        <Blog id={this.state.blogSelected} onBack={(e) => this.onBack()} />
      );
    } else if (this.props.blogs) {
      const blogs = this.props.blogs.map((blog, i) => {
        return (
          <div className={["col-12 col-md-6 col-lg-4 col-xl-3", classes.blog].join(" ")} key={"blog" + i}>
            <Content blog={blog} selectedBlog={(id) => this.selectedBlog(id)} />
          </div>
        );
      });
      let loader = null;
      if (this.state.loader) {
        loader = (
          <Loader
            loaderDisable={(disable) => this.setState({ loader: disable })}
          />
        );
      }
      contenido = (
        <>
          {loader}
          <h1 className={"nunito texto_42 regular"}>
          <FormattedHTMLMessage
              id="blog.titulo"
              defaultMessage=" "
            />
          </h1>
          <div className="margen_top_30 texto_16 montse regular">
            <FormattedHTMLMessage id="blog.introduccion" defaultMessage=" " />
          </div>
          <div className="margen_top_50 row">{blogs}</div>
          <div className="margen_top_30">
            <Paginations
              claseTipo={2}
              numRows={
                this.props.blogsContent
                  ? this.props.blogsContent.totalElements
                  : 0
              }
              paginas={
                this.props.blogsContent ? this.props.blogsContent.totalPages : 0
              }
              itemsPerPage={this.state.itemsPerPage}
              handlePageChange={this.handlePageChange.bind(this)}
              activePage={this.state.activePage}
            />
          </div>
        </>
      );
    }

    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <React.Fragment>{contenido}</React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    getBlogs: (params) => {
      dispatch(action.getBlogsLifeRay(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    diccionario: state.intl.diccionario,
    blogs: state.blog.blogs ? state.blog.blogs.content : [],
    blogsContent: state.blog.blogs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);

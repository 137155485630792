import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  alianzas_edu: null,
  alianzas_states: null,
  alianzas_emp: null,
  procesoInvertir: null,
  casaBolsaBiva: null,
  casaBolsaOtras: null,
  procesoListado: null,
  auditorium: null,
  queEstaPasando: null,
  procesoLisadoSIC: null
};

const setImagenes = (state, action) => {
  return updateObject(state, action);
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALIANZASEMPIMAGENES_SUCCES:
      return setImagenes(state, action);
    case actionTypes.FETCH_ALIANZASSTATESIMAGENES_SUCCES:
      return setImagenes(state, action);
    case actionTypes.FETCH_ALIANZASEDUIMAGENES_SUCCES:
      return setImagenes(state, action);
    case actionTypes.FETCH_IMAGENES_SUCCES:
      return setImagenes(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./FilterEmisoras.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { getLan } from "../../../stores/utils/utilities";
import { debounce } from "throttle-debounce";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  options,
  isMultiple = null,
  maxCharacter = null,
  selectLabel
}) => {
  const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    isMultiple: isMultiple,
    maxCharacter: maxCharacter,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel
  };
  return (
    <div className="form-group">
      {label ? (
        <label for={input.name} className={["texto_14", "montse", "regular"].join(" ")}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterEmisoras extends Component {
  constructor(props) {
    super(props);
    this.formEmisoras = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    esconderAutoComplete: false,
    toggleFilters: false,
    disableEnableButtons: true,
    selectedFilters: {
      instrumentos: [],
      tipo_valor: [],
      sector: [],
      sub_sector: [],
      ramos: [],
      sub_ramos: []
    },
    form_filters: {
      instrumentos: {
        value: []
      },
      tipo_valor: {
        value: []
      },
      sector: {
        value: ""
      },
      sub_sector: {
        value: ""
      },
      ramos: {
        value: ""
      },
      sub_ramos: {
        value: ""
      }
    },
    form_clave: {
      clave: {
        value: []
      }
    },
    selectPivot: false,
    selectSubPivot: false,
    prevInputSelected: false,
    toggleInputs: false,
    itemsPerPage: 10,
    valueClave: null,
    claveEmisora: null,
    filteredBySearch: false,
    typeClaveSelected: null
  };

  componentWillMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  componentDidMount() {
    this.onFilterInstrumentoHandler();
    this.onFilterSectorHandler();
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claveEmisora !== this.props.claveEmisora) {
      let disableEnableButtons = true;
      let toggleFilters = false;
      if (this.props.claveEmisora) {
        disableEnableButtons = false;
      }
      this.setState({
        typeClaveSelected: 1,
        valueClave: this.props.claveEmisora,
        disableEnableButtons: disableEnableButtons,
        toggleFilters: toggleFilters
      });
    }

    if (prevProps.biva !== this.props.biva || prevProps.canceladas !== this.props.canceladas) {
      this.onResetFilterHandler(null, false); // false para que no haga llamado a carga de resultados, viene por cambio de tab, sólo resetea valores
      this.onInputChangeHandler(null, false, false, false); // para borrar busqueda aproximada en cambio de tab
      this.setState({
        typeClaveSelected: 0,
        valueClave: null,
        disableEnableButtons: true,
        toggleFilters: false,
        toggleInputs: this.props.claveEmisora && this.props.claveEmisora !== "" ? true : false
      });
    }
  }

  onDeleteClaveEmisoraHandler = () => {
    this.props.onDeleteEmisoraSelecionada();

    const [
      valuesTipoInstrumentos,
      valuesTipoValor,
      valuesSector,
      valuesSubSector,
      valuesRamos,
      valuesSubRamos
    ] = this.onGetAllValuesFormFilter();

    // let params = {
    //   size: this.state.itemsPerPage,
    //   page: 0,
    //   instrumento: valuesTipoInstrumentos,
    //   tipo_valor: valuesTipoValor,
    //   sector: valuesSector,
    //   sub_sector: valuesSubSector,
    //   ramo: valuesRamos,
    //   sub_ramo: valuesSubRamos,
    //   biva: this.props.biva,
    //   canceladas: this.props.canceladas
    // };

    this.props.setInitTabs(this.props.biva ? 1 : this.props.canceladas ? 2 : 0, null, false);
    // this.props.onFilterEmisoras(params);

    let paramsForParent = {
      instrumento: valuesTipoInstrumentos,
      tipo_valor: valuesTipoValor,
      sector: valuesSector,
      sub_sector: valuesSubSector,
      ramo: valuesRamos,
      sub_ramo: valuesSubRamos,
      page: 1,
      reset: false
    };

    this.props.setFilters(paramsForParent);

    this.setState({
      claveEmisora: null,
      toggleInputs: false
    });
  };

  showContainerEmisoraSleccionadaHandler = () => {
    const clave = this.props.claveEmisora;
    return (
      <Auxs>
        <div className={classes.filter_container_emisora_seleccionada}>
          <div className={classes.filter_emisora_seleccionada}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{clave}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteClaveEmisoraHandler()} />
            </span>
          </div>
        </div>
      </Auxs>
    );
  };

  printFrameHandler = () => {
    const showContainer =
      this.props.claveEmisora && !this.state.filteredBySearch
        ? this.showContainerEmisoraSleccionadaHandler()
        : this.showInputHandler();

    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : showContainer;

    const closeLabel = getLan() === "es" ? "Cerrar filtros" : "Close";
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";

    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col-4 col-sm-3", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar",
                  classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>{this.state.toggleFilters ? closeLabel : filterLabel}</span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-8", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col-2 col-md-1",
                "col-lg-1",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters ? classes.no_border_search_button_model_one : null
                ].join(" ")}
              >
                <button type="buttom" className={["btn"].join(" ")} disabled>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? "d-none" : null].join(" ")}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  onPrepareOptionHandler = type => {
    let options = [];
    if (type && type.length > 0) {
      type.forEach(element => {
        options.push({
          nombre: element.nombre,
          id: `${element.id}_${element.nombre}`
        });
      });
    }
    return options;
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              instrumento: this.state.form_filters.instrumentos.value,
              tipo_valor: this.state.form_filters.tipo_valor.value,
              sector: this.state.form_filters.sector.value,
              sub_sector: this.state.form_filters.sub_sector.value,
              ramos: this.state.form_filters.ramos.value,
              sub_ramos: this.state.form_filters.sub_ramos.value
            }}
            render={({
              optionsInstrumentos = this.props.instrumentos ? this.onPrepareOptionHandler(this.props.instrumentos) : [],
              optionsTipoValor = this.props.tipo_valor ? this.onPrepareOptionHandler(this.props.tipo_valor) : [],
              optionsSector = this.props.sectores ? this.onPrepareOptionHandler(this.props.sectores) : [],
              optionsSubSector = this.props.sub_sectores ? this.onPrepareOptionHandler(this.props.sub_sectores) : [],
              optionsRamos = this.props.ramos ? this.onPrepareOptionHandler(this.props.ramos) : [],
              optionsSubRamos = this.props.sub_ramos ? this.onPrepareOptionHandler(this.props.sub_ramos) : [],
              form
            }) => (
                <form ref={this.formEmisoras} onSubmit={this.onSubmitFilterHandler}>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 ">
                      <Field
                        name="instrumento"
                        id="instrumento"
                        label={getLan() === "es" ? "Instrumento" : "Security type"}
                        elementType="select"
                        type="select"
                        classes="custom-select"
                        isMultiple={true}
                        options={optionsInstrumentos}
                        onInputChange={event => this.onInputSelectedHandler(event, 0)}
                        component={renderInput}
                      />
                    </div>
                    <div
                      className={[
                        "col-12 col-sm-6 col-md-6 col-lg-3",
                        optionsTipoValor.length === 0 || this.state.form_filters.instrumentos.value.length === 0
                          ? classes.hidden
                          : null
                      ].join(" ")}
                    >
                      <Field
                        name="tipo_valor"
                        id="tipo_valor"
                        label={getLan() === "es" ? "Tipo valor" : "Instrument type"}
                        elementType="select"
                        type="select"
                        classes="custom-select"
                        options={optionsTipoValor}
                        isMultiple={true}
                        onInputChange={event => this.onInputSelectedHandler(event, 1)}
                        component={renderInput}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 ">
                      <Field
                        name="sector"
                        id="sector"
                        label="Sector"
                        elementType="select"
                        type="select"
                        classes="custom-select"
                        options={optionsSector}
                        onInputChange={event => this.onInputSelectedHandler(event, 2)}
                        component={renderInput}
                      />
                    </div>
                    <div
                      className={[
                        "col-12 col-sm-6 col-md-6 col-lg-3",
                        optionsSubSector.length === 0 ||
                          !this.state.form_filters.sector.value ||
                          this.state.form_filters.sector.value === ""
                          ? classes.hidden
                          : null
                      ].join(" ")}
                    >
                      <Field
                        name="sub_sector"
                        id="sub_sector"
                        label="Sub sector"
                        elementType="select"
                        classes="custom-select"
                        options={optionsSubSector}
                        onInputChange={event => this.onInputSelectedHandler(event, 3)}
                        component={renderInput}
                      />
                    </div>
                    <div
                      className={[
                        "col-12 col-sm-6 col-md-6 col-lg-3",
                        optionsRamos.length === 0 ||
                          !this.state.form_filters.sub_sector.value ||
                          this.state.form_filters.sub_sector.value === ""
                          ? classes.hidden
                          : null
                      ].join(" ")}
                    >
                      <Field
                        name="ramos"
                        id="ramos"
                        label={getLan() === "es" ? "Ramo" : "Branch"}
                        elementType="select"
                        classes="custom-select"
                        options={optionsRamos}
                        onInputChange={event => this.onInputSelectedHandler(event, 4)}
                        component={renderInput}
                      />
                    </div>
                    <div
                      className={[
                        "col-12 col-sm-6 col-md-6 col-lg-3",
                        optionsRamos.length === 0 ||
                          !this.state.form_filters.sector.value ||
                          this.state.form_filters.sector.value === "" ||
                          !this.state.form_filters.sub_sector.value ||
                          this.state.form_filters.sub_sector.value === "" ||
                          !this.state.form_filters.ramos.value ||
                          this.state.form_filters.ramos.value === ""
                          ? classes.hidden
                          : null
                      ].join(" ")}
                    >
                      <Field
                        name="sub_ramos"
                        id="sub_ramos"
                        label={getLan() === "es" ? "Sub ramo" : "Sub branch"}
                        elementType="select"
                        classes="custom-select"
                        options={optionsSubRamos}
                        onInputChange={event => this.onInputSelectedHandler(event, 5)}
                        component={renderInput}
                      />
                    </div>
                  </div>
                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
          />
        </div>
      </div>
    );
  };

  sectionButtonsHandler = form => {
    const clearLabel = getLan() === "es" ? "Limpiar" : "Clear";
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterHandler = event => {
    event.preventDefault();
  };

  onSubmitSearchHandler = event => {
    event.preventDefault();
  };

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = value => {
    this.onInputChangeHandler(value);
  };

  onInputChangeHandler = (event = null, eraser = null, consulta = true, borraClaveFiltro = true) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;
    let filteredBySearch = false;

    if (value && value !== "") {
      this.props.onFilterEmisorasByClave(value, this.props.biva, this.props.canceladas);
      filteredBySearch = true;
      this.props.setActivePage(1);
    } else {
      disabled = true;
      let params = {
        size: this.state.itemsPerPage,
        page: 0,
        instrumento: this.state.form_filters.instrumentos.value && this.state.form_filters.instrumentos.value !== "" ? this.onSplitValueHandler(this.state.form_filters.instrumentos.value) : "",
        tipo_valor: this.state.form_filters.tipo_valor.value && this.state.form_filters.tipo_valor.value !== "" ? this.onSplitValueHandler(this.state.form_filters.tipo_valor.value) : "",
        sector: this.state.form_filters.sector.value && this.state.form_filters.sector.value !== "" ? this.onSplitValueHandler(this.state.form_filters.sector.value) : "",
        sub_sector: this.state.form_filters.sub_sector.value && this.state.form_filters.sub_sector.value !== "" ? this.onSplitValueHandler(this.state.form_filters.sub_sector.value) : "",
        ramo: this.state.form_filters.ramos.value && this.state.form_filters.ramos.value !== "" ? this.onSplitValueHandler(this.state.form_filters.ramos.value) : "",
        sub_ramo: this.state.form_filters.sub_ramos.value && this.state.form_filters.sub_ramos.value !== "" ? this.onSplitValueHandler(this.state.form_filters.sub_ramos.value) : "",
        biva: this.props.biva,
        canceladas: this.props.canceladas
      };
      if (consulta) {
        this.props.onFilterEmisoras(params);
      }
      if (borraClaveFiltro) {
        this.props.borraClaveFiltro(null);
        this.props.updateEmisorasParent(null);
        this.props.wasFilteredBy(null);
        this.props.setFilterClave(null);
        this.props.setActivePage(1);
        this.props.onDeleteEmisoraSelecionada();
        this.props.setInitTabs(this.props.currentTab, null, false);
      }
    }

    if (eraser) {
      this.setState(prevState => {
        return {
          toggleInputs: !prevState.toggleInputs,
          form_clave: form_clave,
          disableEnableButtons: disabled,
          valueClave: true,
          filteredBySearch: filteredBySearch
        };
      });
    } else {
      this.setState({
        form_clave: form_clave,
        disableEnableButtons: disabled,
        filteredBySearch: filteredBySearch
      });
    }
  };

  onResetFilterHandler = (form = null, changeTab = true) => {
    const form_filter = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    const selectPivot = false;
    const selectSubPivot = false;
    const prevInputSelected = false;

    form_filter.instrumentos.value = [];
    selectedFilters.instrumentos = [];
    form_filter.tipo_valor.value = [];
    selectedFilters.tipo_valor = [];
    form_filter.sector.value = "";
    selectedFilters.sector = [];
    form_filter.sub_sector.value = "";
    selectedFilters.sub_sector = [];
    form_filter.ramos.value = "";
    selectedFilters.ramos = [];
    form_filter.sub_ramos.value = "";
    selectedFilters.sub_ramos = [];

    this.setState({
      form: form_filter,
      selectPivot: selectPivot,
      selectSubPivot: selectSubPivot,
      prevInputSelected: prevInputSelected,
      selectedFilters: selectedFilters
    });

    /* this.onFilterDataByEvent(); */

    // Filtrando el instrumento
    this.onFilterInstrumentoHandler();
    // Filtrando sector
    this.onFilterSectorHandler();
    if (changeTab) {
      const paramsForParent = {
        instrumento: null,
        tipo_valor: null,
        sector: null,
        sub_sector: null,
        ramo: null,
        sub_ramo: null,
        reset: true,
        page: 1
      };
      this.props.setFilters(paramsForParent);
    }
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onFilterInstrumentoHandler = (sector = null, subSector = null, ramo = null, subRamo = null) => {
    const params = {
      sector: sector,
      subSector: subSector,
      ramo: ramo,
      subRamo: subRamo,
      biva: this.props.biva,
      canceladas: this.props.canceladas
    };
    this.props.onFilterInstrumentos(params);
  };

  onFilterTipoValorHandler = (instrumento = null, sector = null, subSector = null, ramo = null, subRamo = null) => {
    const params = {
      instrumento: instrumento,
      sector: sector,
      subSector: subSector,
      ramo: ramo,
      subRamo: subRamo,
      biva: this.props.biva,
      canceladas: this.props.canceladas
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterSectorHandler = (instrumento = null, tipoValor = null) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor,
      biva: this.props.biva,
      canceladas: this.props.canceladas
    };
    this.props.onFilterSector(params);
  };

  onFilterSubSectorHandler = (sector = null, instrumento = null, tipoValor = null) => {
    const params = {
      sector: sector,
      instrumento: instrumento,
      tipoValor: tipoValor,
      biva: this.props.biva,
      canceladas: this.props.canceladas
    };
    this.props.onFilterSubSectores(params);
  };

  onFilterRamosHandler = (sector = null, subSector = null, instrumento = null, tipoValor = null) => {
    const params = {
      sector: sector,
      subSector: subSector,
      instrumento: instrumento,
      tipoValor: tipoValor,
      biva: this.props.biva,
      canceladas: this.props.canceladas
    };
    this.props.onFilterRamos(params);
  };

  onFilterSubRamosHandler = (sector = null, subSector = null, ramos = null, instrumento = null, tipoValor = null) => {
    const params = {
      sector: sector,
      subSector: subSector,
      ramos: ramos,
      instrumento: instrumento,
      tipoValor: tipoValor,
      biva: this.props.biva,
      canceladas: this.props.canceladas
    };
    this.props.onFilterSubRamos(params);
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor del tipo de instrumento
    const valuesTipoInstrumentos =
      form.instrumentos.value && form.instrumentos.value.length > 0 ? this.onSplitValueHandler(form.instrumentos.value) : [];
    // Se obtiene el valor de tipo valor
    const valuesTipoValor =
      form.tipo_valor.value && form.tipo_valor.value.length > 0 ? this.onSplitValueHandler(form.tipo_valor.value) : [];
    // Se obtiene el valor del sector
    const valuesSector = form.sector.value && form.sector.value !== "" ? this.onSplitValueHandler(form.sector.value) : "";
    // Se obtiene el valor del subSector
    const valuesSubSector =
      form.sub_sector.value && form.sub_sector.value !== "" ? this.onSplitValueHandler(form.sub_sector.value) : "";
    // Se obtiene el valor de ramos
    const valuesRamos = form.ramos.value && form.ramos.value !== "" ? this.onSplitValueHandler(form.ramos.value) : "";
    // Se obtiene el valor de Subramos
    const valuesSubRamos =
      form.sub_ramos.value && form.sub_ramos.value !== "" ? this.onSplitValueHandler(form.sub_ramos.value) : "";

    return [valuesTipoInstrumentos, valuesTipoValor, valuesSector, valuesSubSector, valuesRamos, valuesSubRamos];
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    const [
      valuesTipoInstrumentos,
      valuesTipoValor,
      valuesSector,
      valuesSubSector,
      valuesRamos,
      valuesSubRamos
    ] = this.onGetAllValuesFormFilter();

    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      instrumento: valuesTipoInstrumentos,
      tipo_valor: valuesTipoValor,
      sector: valuesSector,
      sub_sector: valuesSubSector,
      ramo: valuesRamos,
      sub_ramo: valuesSubRamos,
      biva: this.props.biva,
      canceladas: this.props.canceladas
    };
    /* this.props.onFilterEmisoras(params); */

    // Se invoca la funcion del componente padre (Emisoras.js) para setear los valores correspondientes
    // Configurando el valor de la emisora seleccionada
    // Valores para el campo instrumento
    let instrumento = null;
    if (valuesTipoInstrumentos && valuesTipoInstrumentos.length > 0) {
      instrumento = valuesTipoInstrumentos;
    }
    // Valores para el campo tipo valor
    let tipoValor = null;
    if (valuesTipoValor && valuesTipoValor.length > 0) {
      tipoValor = valuesTipoValor;
    }
    // Valores para el campo sector
    let sector = null;
    if (valuesSector && valuesSector !== "") {
      sector = valuesSector;
    }
    // Valores para le campo sub sector
    let subSector = null;
    if (valuesSubSector && valuesSubSector !== "") {
      subSector = valuesSubSector;
    }
    // Valores para campo ramos
    let ramo = null;
    if (valuesRamos && valuesRamos !== "") {
      ramo = valuesRamos;
    }
    // Valores para campo sub ramos
    let subRamo = null;
    if (valuesSubRamos && valuesSubRamos !== "") {
      subRamo = valuesSubRamos;
    }

    const paramsForParent = {
      instrumento: instrumento,
      tipo_valor: tipoValor,
      sector: sector,
      sub_sector: subSector,
      ramo: ramo,
      sub_ramo: subRamo,
      page: 1,
      reset: false
    };

    this.props.setFilters(paramsForParent);
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = value => {
    let newValue = null;
    let newLabel = null;

    newValue = value.split("_")[0];
    newLabel = value.split("_")[1];

    return [newValue, newLabel];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["instrumentos", form.instrumentos.value, selectedFilters.instrumentos];
      case 1:
        return ["tipo_valor", form.tipo_valor.value, selectedFilters.tipo_valor];
      case 2:
        return ["sector", form.sector.value, selectedFilters.sector];
      case 3:
        return ["sub_sector", form.sub_sector.value, selectedFilters.sub_sector];
      case 4:
        return ["ramos", form.ramos.value, selectedFilters.ramos];
      case 5:
        return ["sub_ramos", form.sub_ramos.value, selectedFilters.sub_ramos];
    }
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (type !== 0 && type !== 1) {
      if (values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];
      const stateExists = newStateValues.find(option => option === values);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== values);
      } else {
        if (values !== "") {
          newStateValues.push(values);
        } else {
          newStateValues = [];
        }
      }
    }

    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];

    if (type !== 0 && type !== 1) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExist = newFilterValues.find(option => option.label === label);
      if (filterExist) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
      } else {
        if (values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        } else {
          newFilterValues = [];
        }
      }
    }

    return newFilterValues;
  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (event, type = null) => {
    let actualValue = type !== 0 && type !== 1 ? event.target.value : event.target.options;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newValue = null;
    let newLabel = null;

    let selectPivot = this.state.selectPivot;
    let selectSubPivot = this.state.selectSubPivot;
    let prevInputSelected = this.state.prevInputSelected;

    // Solo para los campos distintos a tipo instrumento y tipo valor
    if (type !== 0 && type !== 1) {
      [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue);
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

    // Solo para el campo tipo de instrumento y tipo valor
    if (type === 0 || type === 1) {
      const options = [...actualValue];
      const indexSelected = actualValue.selectedIndex && actualValue.selectedIndex !== -1 ? actualValue.selectedIndex : 0;

      newValue = options[indexSelected].value;
      newLabel = options[indexSelected].value.split("_")[1];

      actualValue = newValue;
    }

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesTipoInstrumentos,
      valuesTipoValor,
      valuesSector,
      valuesSubSector,
      valuesRamos,
      valuesSubRamos
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      // Tipo instrumentos
      if (valuesTipoInstrumentos.length > 0) {
        // Cuando el campo tipo instrumento se selecciona de primero
        if (!selectPivot || selectPivot === "firstpath") {
          selectPivot = "firstpath";
          // Limpiando valores para campo tipo valor
          form.tipo_valor.value = [];
          selectedFilters.tipo_valor = [];
          // Filtrando el campo tipo valor
          this.onFilterTipoValorHandler(valuesTipoInstrumentos);
          // Limpiando valores para campo sector
          form.sector.value = "";
          selectedFilters.sector = [];
          // Filtrando el campo sector
          this.onFilterSectorHandler(valuesTipoInstrumentos);
          // Limpiando valores campo ramos
          form.sub_sector.value = "";
          selectedFilters.sub_sector = [];
          // Limpiando valores campo ramos
          form.ramos.value = "";
          selectedFilters.ramos = [];
          // Limpiando valores campo sub sector
          form.sub_ramos.value = "";
          selectedFilters.sub_ramos = [];
        } else if (selectPivot === "secondpath") {
          if (!selectSubPivot || selectSubPivot === "instrumento") {
            selectSubPivot = "instrumento";
            // Limpiando valores para campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
            // Filtrando el campo tipo valor
            this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector);
            // Limpiando los valores para campo subsector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
            // Filtrando el campo subsector
            this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos);
            // Limpiando valores campo ramo
            form.ramos.value = "";
            selectedFilters.ramos = [];
            // Limpiando valores campo sub ramos
            form.sub_ramos.value = "";
            selectedFilters.sub_ramos = [];
          } else if (selectSubPivot === "subsector") {
            // Limpiando valores para campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
            // Filtrando el campo tipo valor
            this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector, valuesSubSector, valuesRamos, valuesSubRamos);
            // Limpiando valores campo ramo
            // form.ramos.value = "";
            // selectedFilters.ramos = [];
            // this.onFilterRamosHandler(valuesSector, valuesSubSector, valuesTipoInstrumentos, valuesTipoValor);
          }
        }
      } else {
        if (selectPivot === "firstpath") {
          selectPivot = null;
          selectSubPivot = null;
          prevInputSelected = null;
          // Limpiando valores para campo tipo valor
          form.tipo_valor.value = [];
          selectedFilters.tipo_valor = [];
          // Limpiando valores para campo sector
          form.sector.value = "";
          selectedFilters.sector = [];
          // Filtrando el campo sector
          this.onFilterSectorHandler();
          // Limpiando valores campo ramos
          form.ramos.value = "";
          selectedFilters.ramos = [];
          // Limpiando valores campo sub sector
          form.sub_ramos.value = "";
          selectedFilters.sub_ramos = [];
        } else if (selectPivot === "secondpath") {
          if (selectSubPivot === "instrumento") {
            selectSubPivot = null;
            // Limpiando valores para campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
            // Limpiando los valores para campo subsector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
            // Filtrando el campo subsector
            this.onFilterSubSectorHandler(valuesSector);
          } else if (selectSubPivot === "subsector") {
            // Limpiando valores para campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
          }
        }
      }
    } else if (type === 1) {
      // Tipo valor
      if (valuesTipoValor.length > 0) {
        if (selectPivot === "firstpath") {
          if (!selectSubPivot || selectSubPivot === "tipoValor") {
            selectSubPivot = "tipoValor";
            // Limpiando valores para campo sector
            form.sector.value = "";
            selectedFilters.sector = [];
            // Filtrando el campo sector
            this.onFilterSectorHandler(valuesTipoInstrumentos, valuesTipoValor);
            // Limpiando los valores para campo subsector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
          } else if (selectSubPivot === "sector") {
            // Seleccionando tipo valor como penultimo campo
            prevInputSelected = prevInputSelected === "subsector" ? "subsector" : "tipoValor";
            if (!prevInputSelected || prevInputSelected !== "subsector") {
              // Limpiando los valores para campo subsector
              form.sub_sector.value = "";
              selectedFilters.sub_sector = [];
              // Filtrando campo sub_sector
              this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos, valuesTipoValor);
            }
          }
        } else if (selectPivot === "secondpath") {
          if (selectSubPivot === "instrumento") {
            // Seleccionando tipo valor como penultimo campo
            prevInputSelected = prevInputSelected === "subsector" ? "subsector" : "tipoValor";
            if (!prevInputSelected || prevInputSelected !== "subsector") {
              // Limpiando los valores para campo subsector
              form.sub_sector.value = "";
              selectedFilters.sub_sector = [];
              // Filtrando campo sub_sector
              this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos, valuesTipoValor);
            }
          }
        }
      } else {
        if (selectPivot === "firstpath") {
          if (selectSubPivot === "tipoValor") {
            selectSubPivot = null;
            prevInputSelected = null;
            // Limpiando valores para campo sector
            form.sector.value = "";
            selectedFilters.sector = [];
            // Filtrando el campo sector
            this.onFilterSectorHandler(valuesTipoInstrumentos);
            // Limpiando los valores para campo subsector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
          } else if (selectSubPivot === "sector") {
            if (prevInputSelected !== "subsector") {
              // Limpiando los valores para campo subsector
              form.sub_sector.value = "";
              selectedFilters.sub_sector = [];
              // Filtrando campo sub_sector
              this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos);
              prevInputSelected = null;
            }
          }
        } else if (selectPivot === "secondpath") {
          if (selectSubPivot === "instrumento") {
            if (prevInputSelected !== "subsector") {
              // Limpiando los valores para campo subsector
              form.sub_sector.value = "";
              selectedFilters.sub_sector = [];
              // Filtrando campo sub_sector
              this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos);
              prevInputSelected = null;
            }
          }
        }
      }
    } else if (type === 2) {
      // Sector
      // Limpiando valores para campo ramos
      form.ramos.value = "";
      selectedFilters.ramos = [];
      // Limpiando valores para campo sub ramos
      form.sub_ramos.value = "";
      selectedFilters.sub_ramos = [];
      if (valuesSector !== "") {
        if (!selectPivot || selectPivot === "secondpath") {
          selectPivot = "secondpath";
          //Limpiando valores para campo instrumentos
          form.instrumentos.value = [];
          selectedFilters.instrumentos = [];
          // Filtrando campo instrumento
          this.onFilterInstrumentoHandler(valuesSector);
          // Limpiando valores para campo sub sector
          form.sub_sector.value = "";
          selectedFilters.sub_sector = [];
          this.onFilterSubSectorHandler(valuesSector);
        } else if (selectPivot === "firstpath") {
          if (!selectSubPivot || selectSubPivot === "sector") {
            selectSubPivot = "sector";
            // Limpiando valores de campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
            // Filtrando campo tipo valor
            this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector);
            // Limpiando valores de campo sub sector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
            // Filtrando campo sub sector
            this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos);
          } else if (selectSubPivot === "tipoValor") {
            // Limpiando valores de campo sub sector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
            // Filtrando campo sub sector
            this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos, valuesTipoValor);
          }
        }
      } else {
        if (selectPivot === "secondpath") {
          selectPivot = null;
          selectSubPivot = null;
          prevInputSelected = null;
          //Limpiando valores para campo instrumentos
          form.instrumentos.value = [];
          selectedFilters.instrumentos = [];
          // Filtrando campo instrumento
          this.onFilterInstrumentoHandler();
          // Limpiando valores para campo sub sector
          form.sub_sector.value = "";
          selectedFilters.sub_sector = [];
        } else if (selectPivot === "firstpath") {
          if (selectSubPivot === "sector") {
            selectSubPivot = null;
            // Limpiando valores de campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
            // Filtrando campo tipo valor
            this.onFilterTipoValorHandler(valuesTipoInstrumentos);
            // Limpiando valores de campo sub sector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
          } else if (selectSubPivot === "tipoValor") {
            // Limpiando valores de campo sub sector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
          }
        }
      }
    } else if (type === 3) {
      // Sub sector
      if (valuesSubSector !== "") {
        // Limpiado valors de campo ramos
        form.ramos.value = "";
        selectedFilters.ramos = [];
        //Filtrando campo ramos
        this.onFilterRamosHandler(valuesSector, valuesSubSector, valuesTipoInstrumentos, valuesTipoValor);
        // Limpiado valors de campo sub ramos
        form.sub_ramos.value = "";
        selectedFilters.sub_ramos = [];
        if (selectPivot === "secondpath") {
          if (!selectSubPivot || selectSubPivot === "subsector") {
            selectSubPivot = "subsector";
            // Limpiando valores campo instrumentos
            form.instrumentos.value = [];
            selectedFilters.instrumentos = [];
            // Filtrando campo instrumentos
            this.onFilterInstrumentoHandler(valuesSector, valuesSubSector);
            // limpiando valores campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
          } else if (selectSubPivot === "instrumento") {
            // Seleccionando subsector como penultimo campo
            prevInputSelected = prevInputSelected === "tipoValor" ? "tipoValor" : "subsector";
            if (!prevInputSelected || prevInputSelected !== "tipoValor") {
              // limpiando valores campo tipo valor
              form.tipo_valor.value = [];
              selectedFilters.tipo_valor = [];
              // Filtrando campo tipo valor
              this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector, valuesSubSector);
            }
          }
        } else if (selectPivot === "firstpath") {
          if (selectSubPivot === "sector") {
            // Seleccionando subsector como penultimo campo
            prevInputSelected = prevInputSelected === "tipoValor" ? "tipoValor" : "subsector";
            if (!prevInputSelected || prevInputSelected !== "tipoValor") {
              // limpiando valores campo tipo valor
              form.tipo_valor.value = [];
              selectedFilters.tipo_valor = [];
              // Filtrando campo tipo valor
              this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector, valuesSubSector);
            }
          }
        }
      } else {
        // Limpiado valors de campo ramos
        form.ramos.value = "";
        selectedFilters.ramos = [];
        // Limpiado valors de campo sub ramos
        form.sub_ramos.value = "";
        selectedFilters.sub_ramos = [];
        if (selectPivot === "secondpath") {
          if (selectSubPivot === "subsector") {
            selectSubPivot = null;
            // Limpiando valores campo instrumentos
            form.instrumentos.value = [];
            selectedFilters.instrumentos = [];
            // Filtrando campo instrumentos
            this.onFilterInstrumentoHandler(valuesSector);
            // limpiando valores campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
          } else if (selectSubPivot === "instrumento") {
            if (prevInputSelected !== "tipoValor") {
              // limpiando valores campo tipo valor
              form.tipo_valor.value = [];
              selectedFilters.tipo_valor = [];
            }
          }
        } else if (selectPivot === "firstpath") {
          if (prevInputSelected !== "tipoValor") {
            // limpiando valores campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
            // Filtrando campo tipo valor
            this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector, valuesSubSector);
            prevInputSelected = null;
          }
        }
      }
    } else if (type === 4) {
      // Ramos
      if (valuesRamos !== "") {
        // Limpiando valores campo sub ramo
        form.sub_ramos.value = "";
        selectedFilters.sub_ramos = [];
        // Filtrando valores campo sub ramos
        this.onFilterSubRamosHandler(valuesSector, valuesSubSector, valuesRamos, valuesTipoInstrumentos, valuesTipoValor);
        //
        if (selectPivot === "secondpath") {
          if (!selectSubPivot || selectSubPivot === "ramo") {
            selectSubPivot = "ramo";
            // Limpiando valores campo instrumentos
            form.instrumentos.value = [];
            selectedFilters.instrumentos = [];
            // Filtrando campo instrumentos
            this.onFilterInstrumentoHandler(valuesSector, valuesSubSector, valuesRamos);
            // limpiando valores campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
          } else if (selectSubPivot === "instrumento") {
            // Seleccionando subsector como penultimo campo
            prevInputSelected = prevInputSelected === "tipoValor" ? "tipoValor" : "ramo";
            if (!prevInputSelected || prevInputSelected !== "tipoValor") {
              // limpiando valores campo tipo valor
              form.tipo_valor.value = [];
              selectedFilters.tipo_valor = [];
              // Filtrando campo tipo valor
              this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector, valuesSubSector, valuesRamos); //AGREGAR RAMO
            }
          }
          else if (selectSubPivot === "subsector") {
            // Limpiando valores campo instrumentos
            form.instrumentos.value = [];
            selectedFilters.instrumentos = [];
            // Filtrando campo instrumentos
            this.onFilterInstrumentoHandler(valuesSector, valuesSubSector, valuesRamos);
            // limpiando valores campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];

          }
        } else if (selectPivot === "firstpath") {
          if (selectSubPivot === "subsector") {
            // Seleccionando subsector como penultimo campo
            prevInputSelected = prevInputSelected === "subsector" ? "subsector" : "ramo";
            if (!prevInputSelected || prevInputSelected !== "subsector") {
              // limpiando valores campo tipo valor
              form.tipo_valor.value = [];
              selectedFilters.tipo_valor = [];
              // Filtrando campo tipo valor
              this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector, valuesSubSector, valuesRamos);
            }
          }
        }
        //
      } else {
        // Limpiando valores campo sub ramo
        form.sub_ramos.value = "";
        selectedFilters.sub_ramos = [];
        // Limpiando valores campo instrumentos
        form.instrumentos.value = [];
        selectedFilters.instrumentos = [];
        // Filtrando campo instrumentos
        this.onFilterInstrumentoHandler(valuesSector, valuesSubSector, valuesRamos);

      }
      // // Limpiando valores campo instrumentos
      // form.instrumentos.value = [];
      // selectedFilters.instrumentos = [];
      // // Filtrando campo instrumentos
      // this.onFilterInstrumentoHandler(valuesSector, valuesSubSector, valuesRamos);
    } else if (type === 5) {
      if (valuesSubRamos !== "") {
        if (selectPivot === "secondpath") {
          if (!selectSubPivot || selectSubPivot === "subramo") {
            selectSubPivot = "subramo";
            // Limpiando valores campo instrumentos
            form.instrumentos.value = [];
            selectedFilters.instrumentos = [];
            // Filtrando campo instrumentos
            this.onFilterInstrumentoHandler(valuesSector, valuesSubSector, valuesRamos, valuesSubRamos);
            // limpiando valores campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
          } else if (selectSubPivot === "subsector") {
            // Seleccionando subsector como penultimo campo
            prevInputSelected = prevInputSelected === "tipoValor" ? "tipoValor" : "ramo";
            // if (!prevInputSelected || prevInputSelected !== "tipoValor") {
            // Limpiando valores campo instrumentos
            form.instrumentos.value = [];
            selectedFilters.instrumentos = [];
            // Filtrando campo instrumentos
            this.onFilterInstrumentoHandler(valuesSector, valuesSubSector, valuesRamos, valuesSubRamos);

            // limpiando valores campo tipo valor
            form.tipo_valor.value = [];
            selectedFilters.tipo_valor = [];
            // Filtrando campo tipo valor
            // this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector, valuesSubSector, valuesRamos, valuesSubRamos); //AGREGAR RAMO Y SUBRAMO
            // }
          }
        }
      } else {
        // Limpiando valores campo sub ramo
        // form.sub_ramos.value = "";
        // selectedFilters.sub_ramos = [];
        // // Limpiando valores campo instrumentos
        form.instrumentos.value = [];
        selectedFilters.instrumentos = [];
        // Filtrando campo instrumentos
        this.onFilterInstrumentoHandler(valuesSector, valuesSubSector, valuesRamos, valuesSubRamos);

      }


    }

    this.setState({
      form_filters: form,
      selectPivot: selectPivot,
      selectSubPivot: selectSubPivot,
      selectedFilters: selectedFilters,
      prevInputSelected: prevInputSelected
    });

    this.onFilterDataByEvent();
  };

  // Elimina el elemento seleccionado (estado y en la barra de filtros)
  onDeleteFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let selectPivot = this.state.selectPivot;
    let selectSubPivot = this.state.selectSubPivot;
    let prevInputSelected = this.state.prevInputSelected;

    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

    const auxValue = item.type !== 0 && item.type !== 1 ? "" : item.value;
    newStateValues = this.onChangeStateHandler(stateValues, auxValue, item.type);

    newFilterValues = this.onChangeFilterHandler(filterValues, auxValue, item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    let [
      valuesTipoInstrumentos,
      valuesTipoValor,
      valuesSector,
      valuesSubSector,
      valuesRamos,
      valuesSubRamos
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (item.type === 0) {
      // Instrumentos
      // Cuando el campo tipo instrumento se selecciona de primero
      if (selectPivot === "firstpath") {
        if (valuesTipoInstrumentos.length === 0) {
          selectPivot = false;
          selectSubPivot = false;
          prevInputSelected = false;
        } else {
          // Filtrando campo tipo valor
          this.onFilterTipoValorHandler(valuesTipoInstrumentos);
        }
        // Limpiando valores para campo tipo valor
        form.tipo_valor.value = [];
        selectedFilters.tipo_valor = [];
        // Limpiando valores para campo sector
        form.sector.value = "";
        selectedFilters.sector = [];
        // Filtrando el campo sector
        this.onFilterSectorHandler(valuesTipoInstrumentos);
        // Limpiando valores para campo sub sector
        form.sub_sector.value = "";
        selectedFilters.sub_sector = [];
        // Limpiando valores para campo ramos
        form.ramos.value = "";
        selectedFilters.ramos = [];
        // Limpiando valores para campo sub ramos
        form.sub_ramos.value = "";
        selectedFilters.sub_ramos = [];
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "instrumento") {
          selectSubPivot = false;
          // Limpiando valores para campo tipo valor
          form.tipo_valor.value = [];
          selectedFilters.tipo_valor = [];
          // Limpiando los valores para campo subsector
          form.sub_sector.value = "";
          selectedFilters.sub_sector = [];
          // Filtrando el campo subsector
          this.onFilterSubSectorHandler(valuesSector);
          // Limpiando valores para campo ramos
          form.ramos.value = "";
          selectedFilters.ramos = [];
          // Limpiando valores para campo sub ramos
          form.sub_ramos.value = "";
          selectedFilters.sub_ramos = [];
        } else if (selectSubPivot === "subsector") {
          // Limpiando valores para campo tipo valor
          form.tipo_valor.value = [];
          selectedFilters.tipo_valor = [];
        }
      }
    } else if (item.type === 1) {
      // Tipo Valor
      if (selectPivot === "firstpath") {
        if (selectSubPivot === "tipoValor") {
          if (valuesTipoValor.length === 0) {
            selectSubPivot = false;
          }
          // Limpiando valores para campo sector
          form.sector.value = "";
          selectedFilters.sector = [];
          // Filtrando el campo sector
          this.onFilterSectorHandler(valuesTipoInstrumentos, valuesTipoValor);
          // Limpiando valores para campo sub sector
          form.sub_sector.value = "";
          selectedFilters.sub_sector = [];
          // Limpiando valores para campo ramos
          form.ramos.value = "";
          selectedFilters.ramos = [];
          // Limpiando valores para campo sub ramos
          form.sub_ramos.value = "";
          selectedFilters.sub_ramos = [];
        } else if (selectSubPivot === "sector") {
          if (prevInputSelected !== "subsector") {
            // Limpiando los valores para campo subsector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
            // Filtrando campo sub_sector
            this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos);
            // Limpiando valores para campo ramos
            form.ramos.value = "";
            selectedFilters.ramos = [];
            // Limpiando valores para campo sub ramos
            form.sub_ramos.value = "";
            selectedFilters.sub_ramos = [];

            prevInputSelected = false;
          }
        }
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "instrumento") {
          if (prevInputSelected !== "subsector") {
            // Limpiando los valores para campo subsector
            form.sub_sector.value = "";
            selectedFilters.sub_sector = [];
            // Filtrando campo sub_sector
            this.onFilterSubSectorHandler(valuesSector, valuesTipoInstrumentos);
            // Limpiando valores para campo ramos
            form.ramos.value = "";
            selectedFilters.ramos = [];
            // Limpiando valores para campo sub ramos
            form.sub_ramos.value = "";
            selectedFilters.sub_ramos = [];

            prevInputSelected = false;
          }
        }
      }
    } else if (item.type === 2) {
      // Sector
      if (selectPivot === "secondpath") {
        selectPivot = false;
        selectSubPivot = false;
        prevInputSelected = false;
        //Limpiando valores para campo instrumentos
        form.instrumentos.value = [];
        selectedFilters.instrumentos = [];
        // Filtrando campo instrumento
        this.onFilterInstrumentoHandler();
        // Limpiando valores para campo sub sector
        form.sub_sector.value = "";
        selectedFilters.sub_sector = [];
        // Limpiando valores para campo ramos
        form.ramos.value = "";
        selectedFilters.ramos = [];
        // Limpiando valores para campo sub ramos
        form.sub_ramos.value = "";
        selectedFilters.sub_ramos = [];
      } else if (selectPivot === "firstpath") {
        if (selectSubPivot === "sector") {
          selectSubPivot = false;
          // Limpiando valores de campo tipo valor
          form.tipo_valor.value = [];
          selectedFilters.tipo_valor = [];
          // Filtrando campo tipo valor
          this.onFilterTipoValorHandler(valuesTipoInstrumentos);
          // Limpiando valores de campo sub sector
          form.sub_sector.value = "";
          selectedFilters.sub_sector = [];
          // Limpiando valores para campo ramos
          form.ramos.value = "";
          selectedFilters.ramos = [];
          // Limpiando valores para campo sub ramos
          form.sub_ramos.value = "";
          selectedFilters.sub_ramos = [];
        } else if (selectSubPivot === "tipoValor") {
          // Limpiando valores de campo sub sector
          form.sub_sector.value = "";
          selectedFilters.sub_sector = [];
          // Limpiando valores para campo ramos
          form.ramos.value = "";
          selectedFilters.ramos = [];
          // Limpiando valores para campo sub ramos
          form.sub_ramos.value = "";
          selectedFilters.sub_ramos = [];
        }
      }
    } else if (item.type === 3) {
      // Sub sector
      if (selectSubPivot === "instrumento") {
        if (prevInputSelected !== "tipoValor") {
          // Limpiando valores campo tipo valor
          form.tipo_valor.value = "";
          selectedFilters.tipo_valor = [];
          // Filtrando campo tipo valor
          this.onFilterTipoValorHandler(valuesTipoInstrumentos, valuesSector);

          prevInputSelected = false;
        }
      } else if (selectSubPivot === "subsector") {
        // Limpiando valores campo instrumento
        form.instrumentos.value = "";
        selectedFilters.instrumentos = [];
        // Filtrando campo instrumentos
        this.onFilterInstrumentoHandler(valuesSector);
        // Limpiando valores campo tipo valor
        form.tipo_valor.value = "";
        selectedFilters.tipo_valor = [];
      }
      // Limpiando valores para campo ramos
      form.ramos.value = "";
      selectedFilters.ramos = [];
      // Limpiando valores para campo sub ramos
      form.sub_ramos.value = "";
      selectedFilters.sub_ramos = [];
    }

    this.setState({
      form_filters: form,
      selectPivot: selectPivot,
      selectSubPivot: selectSubPivot,
      selectedFilters: selectedFilters,
      prevInputSelected: prevInputSelected
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [
      ...this.state.selectedFilters.instrumentos,
      ...this.state.selectedFilters.tipo_valor,
      ...this.state.selectedFilters.sector,
      ...this.state.selectedFilters.sub_sector,
      ...this.state.selectedFilters.ramos,
      ...this.state.selectedFilters.sub_ramos
    ];

    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map((item, i) => (
          <div className={classes.filter_container_model_one} key={`${item.label}-${i}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{item.label}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = () => {
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => (
      <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}>
        {item.clave}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let claveFilter = null;

    if (option === 0) {
      claveFilter = clave;

      const params = {
        clave: claveFilter,
        size: this.state.itemsPerPage,
        page: 0
      };

      this.props.setInitTabs(0, null, false);
      this.props.onFetchAllEmisorasByClave(params);
    } else {
      claveFilter = id;
      this.props.setInitTabs(0, id, true);
      this.props.updateEmisorasParent(id);
    }

    this.props.setFilterClave(claveFilter);
    this.props.wasFilteredBy(0);

    this.setState({ typeClaveSelected: option, valueClave: clave });
  };

  onPrintOptionSelectedHandler = value => {
    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? "d-none" : null].join(" ")}>
        <span className={[classes.filterContainer, this.state.valueClave === null ? "d-none" : null].join(" ")}>
          <span className={[[classes.filterText, "montse texto_14 regular"].join(" ")].join(" ")}>
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onInputChangeHandler(null, true)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.listadoClaves ? this.onPrintAutoCompleteHandler() : null;

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {getLan() === "es" ? "Ver todos los resultados para: " : "See all results for: "} {value}
        </li>
      ) : (
          <li className="list-group-item"> {getLan() === "es" ? "No existen resultados para la búsqueda:" : "There are no results for the search: "} {value}</li>
        );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div className={[classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null].join(" ")}>
          <Form
            onSubmit={this.onSubmitSearchHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value
            }}
            render={() => (
              <form ref={this.formSearch} onSubmit={this.onSubmitSearchHandler}>
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  maxCharacter={12}
                  placeholder={getLan() === "es" ? "Buscar por clave de cotización o ISIN" : "Search by ticker or ISIN"}
                  component={renderInput}
                  onInputChange={event => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              this.state.form_clave.clave.value === null || this.state.form_clave.clave.value.length === 0
                ? classes.hidden
                : null,
              this.state.esconderAutoComplete ? classes.hidden : null
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const filter = this.printFrameHandler();
    return filter;
  }
}

const mapStateToProps = state => {
  return {
    instrumentos: state.instrumentos.instrumentos,
    tipo_valor: state.tipoValor.tipoValor,
    sectores: state.sectorSubSector.sectores,
    sub_sectores: state.sectorSubSector.sub_sectores,
    ramos: state.ramoSubRamo.ramos,
    sub_ramos: state.ramoSubRamo.sub_ramos,
    listadoClaves: state.emisoras.listadoClaves
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterInstrumentos: params => {
      dispatch(actions.filterInstrumentos(params));
    },
    onFilterTipoValor: params => {
      dispatch(actions.filterTipoValor(params));
    },
    onFilterSector: params => {
      dispatch(actions.filterSector(params));
    },
    onFilterSubSectores: params => {
      dispatch(actions.filterSubSector(params));
    },
    onFilterRamos: params => {
      dispatch(actions.filterRamos(params));
    },
    onFilterSubRamos: params => {
      dispatch(actions.filterSubRamos(params));
    },
    onFilterEmisoras: filterParams => {
      dispatch(actions.filterEmisoras(filterParams));
    },
    onFilterEmisorasByClave: (clave, biva, canceladas) => {
      dispatch(actions.filterEmisorasByClave(clave, biva, canceladas));
    },
    onFetchAllEmisorasByClave: params => {
      dispatch(actions.fetchAllEmisorasByClave(params));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterEmisoras)
);

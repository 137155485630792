import React, { Component } from "react";
import classes from "./Modelo1.css";
import Slider from "react-slick";
import { getLan, getUrlDocument } from "../../../../stores/utils/utilities";

import imagen1 from "../../../../assets/images/fondo_carousel/azul.png";
import imagen2 from "../../../../assets/images/fondo_carousel/naranja.png";
import imagen3 from "../../../../assets/images/fondo_carousel/morado.png";
import imagen4 from "../../../../assets/images/fondo_carousel/azul2.png";
import imagen5 from "../../../../assets/images/fondo_carousel/rosa.png";
import imagen6 from "../../../../assets/images/fondo_carousel/naranja2.png";
import Pagination from "../../pagination/Pagination";

const settings = {
  accessibility: true,
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  speed: 200,
  autoplaySpeed: 0,
  cssEase: "linear",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        className: "center",
        centerMode: true,
      },
    },
  ],
};

class Modelo1 extends Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  state = {
    itemsPerPage: 1,
    activePage: 1,
  };

  slickGoTo(page) {
    if (this.slider) {
      this.slider.slickGoTo(page);
    }
  }

  // metodo llamado desde paginado, el cual manda como parametro el numero de pagina la cual fue solicitada
  // int pageNumber
  // guarda estado activePage y lista a mostrar
  // RM
  handlePageChange(pageNumber) {
    this.slickGoTo(pageNumber - 1);
    this.setState({
      activePage: pageNumber,
    });
  }

  handlePageChangeSlider(pageNumber) {
    this.slickGoTo(pageNumber);
    this.setState({
      activePage: pageNumber+1,
    });
  }

  componentDidMount() {}

  render() {
    let imagen_fondo = imagen1;
    let diseño = [];
    let esconde = [];

    if (this.props.datos) {
      const titulo_slider = this.props.titulo_slider
        ? this.props.titulo_slider
        : "";

      function escogeImagen(tipo, url = null, mostrar = true) {
        let ruta = null;
        ruta = getUrlDocument(url);
        if (!mostrar) {
          esconde.push(classes.esconde);
        }

        imagen_fondo = ruta;
        switch (tipo) {
          case 1:
            // Articulos academicos
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen1;
            diseño = classes.diseno1;

            break;
          case 2:
            //Revistas
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen2;
            diseño = classes.diseno2;

            break;
          case 3:
            //Escritos
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen3;
            diseño = classes.diseno3;

            break;
          case 4:
            //Blog
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen4;
            diseño = classes.diseno4;

            break;
          case 5:
            //Libros
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen5;
            diseño = classes.diseno5;

            break;
          case 6:
            //Diccionario financiero
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen6;
            diseño = classes.diseno6;

            break;

          default:
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen1;
            diseño = classes.diseno1;

            break;
        }
      }

      const contenido = this.props.datos.map((dato, i) => {
        escogeImagen(
          this.props.tipo_imagen,
          dato.imagen_portada,
          dato.mostrar_info_portada
        );

        let style = {
          backgroundImage: `url(${imagen_fondo})`,
        };
        let url_descarga = null;
        if (dato.url_descarga) {
          url_descarga = getUrlDocument(dato.url_descarga, true);
        }
        return (
          <div className={[classes.margen, diseño].join(" ")}>
            <div className={classes.fondo1} style={style}>
              <div className={[classes.titulo, esconde].join(" ")}>
                <span className={["texto_blanco texto_16 nunito"].join(" ")}>
                  {dato.titulo}
                </span>
              </div>

              <div
                className={[
                  classes.titulo_slider,
                  esconde,
                  "nunito semi_bold texto_22",
                ].join(" ")}
              >
                {titulo_slider}
              </div>
            </div>
            <div className={classes.centrado}>
              <div className={[classes.contenedor_fecha].join(" ")}>
                <span
                  className={["color_azul texto_16 nunito semi_bold"].join(" ")}
                >
                  {dato.fecha}
                </span>
              </div>
              <div
                className={[
                  classes.contenedor_descripcion,
                  " texto_16 montse regular",
                ].join(" ")}
              >
                {dato.descripcion}
              </div>
              <div
                className={[
                  classes.contenedor_descarga,
                  " texto_16 montse regular row",
                ].join(" ")}
              >
                <a
                  href={url_descarga}
                  rel="noopener noreferrer"
                  className="row"
                >
                  <div className="col-8 sin_padding_L">
                    <span className={"texto_16 montse color_azul semi_bold"}>
                      {getLan() === "es" ? "Descargar" : "Download"}
                    </span>
                    <br />
                    <span className={"texto_10 montse semi_bold color_gris"}>
                      {getLan() === "es" ? "Formato PDF" : "PDF format"}
                    </span>
                  </div>
                  <div className="col-4">
                    <i
                      className={[
                        classes.icono,
                        "icon_azul fas fa-arrow-down",
                      ].join(" ")}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        );
      });

      return (
        <>
          <Slider ref={(c) => (this.slider = c)} {...settings} afterChange={this.handlePageChangeSlider.bind(this)}>
            {contenido}
          </Slider>
          <Pagination
            key={this.props.datos.length}
            numRows={this.props.datos ? this.props.datos.length : 0}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            
            activePage={this.state.activePage}
          />
        </>
      );
    }
  }
}

export default Modelo1;

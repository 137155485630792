import axios from "axios";
import * as actionTypes from "./actionTypes";

import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();


const setIndice = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_INDICE_SUCCESS,
      indice: []
    };
  }
  return {
    type: actionTypes.FETCH_INDICE_SUCCESS,
    indice:data
  };
};
const setIndices = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_INDICES_SUCCESS,
      indices: []
    };
  }
  return {
    type: actionTypes.FETCH_INDICES_SUCCESS,
    indices:data
  };
};

export const initIndice = () => {
  return dispatch => {
    axios
      .get(dominio+"/quotes/biva")
      .then(response => {
        dispatch(setIndice(response.data[0]));
        //indices es para llenar en informacion general
        dispatch(setIndices(response.data));
      })
      .catch(err => dispatch(setIndice(err.data)));
  };
};

// ============================================

const setIndiceTiempoReal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_INDICETIEMPOREAL_SUCCESS,
      indiceTiempoReal: null
    };
  }
  return {
    type: actionTypes.FETCH_INDICETIEMPOREAL_SUCCESS,
    indiceTiempoReal:data
  };
};

export const initIndiceTiempoReal = () => {
  let url = `${dominio}/cms/o/documents/informacion-indice/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setIndiceTiempoReal(response.data[0]));
      })
      .catch(err => dispatch(setIndiceTiempoReal(err.data)));
  };
};

import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getLan } from "../utils/utilities";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

export const getColocaciones = (
  size = null,
  page = null,
    // instrumento,
    // tipo_valor,
    // sector,
    // sub_sector,
    // ramo,
    // sub_ramo,
    // biva,
    // canceladas
  ) => {
    let url = `${dominio}/emisoras/colocaciones?size=${size}&page=${page}`;
  
    // url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumento=${[...instrumento]}` : url;
    // url = tipo_valor && tipo_valor.length > 0 ? `${url}&tipoValor=${[...tipo_valor]}` : url;
    // url = sector ? `${url}&sector=${sector}` : url;
    // url = sub_sector ? `${url}&subsector=${sub_sector}` : url;
    // url = ramo ? `${url}&ramo=${ramo}` : url;
    // url = sub_ramo ? `${url}&subramo=${sub_ramo}` : url;
    // url = biva ? `${url}&biva=${biva}` : url;
    // url = canceladas ? `${url}&canceladas=${canceladas}` : url;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setColocaciones(response.data));
        })
        .catch(err => dispatch(setColocaciones(err.data)));
    };
  };
  
  const setColocaciones = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_COLOCACIONES_SUCCESS,
        colocaciones: []
      };
    }
    return {
      type: actionTypes.FETCH_COLOCACIONES_SUCCESS,
      colocaciones: data
    };
  };



const setDocumentos = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_COLOCACIONESDOCS_SUCCESS,
        documentos: null
      };
    }
    return {
      type: actionTypes.FETCH_COLOCACIONESDOCS_SUCCESS,
      documentos: data
    };
  };
  
  export const getDocumentos = empresaId => {
    let url = `${dominio}/emisoras/colocaciones/${empresaId}/documentos`;
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setDocumentos(response.data));
        })
        .catch(err =>  dispatch(setDocumentos(err.data)));
    };
  };


  const setIntermediarios = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_COLOCACIONESINTERMEDIARIOS_SUCCESS,
        intermediarios: null
      };
    }
    return {
      type: actionTypes.FETCH_COLOCACIONESINTERMEDIARIOS_SUCCESS,
      intermediarios: data
    };
  };
  
  export const getIntermediarios = empresaId => {
    let url = `${dominio}/emisoras/colocaciones/${empresaId}/intermediarios-colocadores`;
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setIntermediarios(response.data));
        })
        .catch(err =>  dispatch(setIntermediarios(err.data)));
    };
  };


  // FILTER TIPO INSTRUMENTO
  const setInstrumentos = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_COLOCACIONES_TIPOINSTRUMENTO_SUCCESS,
        instrumentos: null
      };
    }
    return {
      type: actionTypes.FETCH_COLOCACIONES_TIPOINSTRUMENTO_SUCCESS,
      instrumentos: data
    };
  };
  
  export const getInstrumentos = () => {
    let url = `${dominio}/emisoras/colocaciones/tipos-instrumento`;
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setInstrumentos(response.data));
        })
        .catch(err =>  dispatch(setInstrumentos(err.data)));
    };
  };
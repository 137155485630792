import React from "react";
import classes from "./BackgroundContainer.css";
import hojaBlanca from "../../../../assets/icons/sustentable/hoja-blanca.png";


const createImages = (divsImagenes) => {
  let imagenesContainer = null;
  if (divsImagenes.length === 1) {
    imagenesContainer = divsImagenes[0].imagenes.map((imagen) => {
      return (
        <>
          <div className="col-md-6 col-lg-4 margin_top_10">
            <div className="row">
              <div className="sin_padding col-4 text-center">
                <img src={imagen.imagen} className={classes.imagenIcono} />
              </div>
              <div className="sin_padding col-8 margin_top_10">
                <div
                  className={["montse texto_14 texto_blanco"].join(" ")}
                  dangerouslySetInnerHTML={{ __html: imagen.texto_html }}
                />
              </div>
            </div>
          </div>
        </>
      );
    });
  }
  return imagenesContainer;
};

const createDivs = (divs) => {
  let imagenes = [];
  let imagenesDiv = [];
  let indiceTitulo = 0;
  divs.forEach((itm, indice) => {
    if (
      (itm.titulo_encabezado != null && itm.titulo_encabezado != "") ||
      (itm.titulo_encabezado === "" && itm.imagen === null)
    ) {
      if (imagenesDiv.length > 0) {
        imagenes.push({ indiceTitulo: indiceTitulo, imagenes: imagenesDiv });
        imagenesDiv = [];
      }
      indiceTitulo = indice;
    } else {
      imagenesDiv.push(itm);
      if (indice === divs.length - 1 && imagenesDiv.length > 0) {
        imagenes.push({ indiceTitulo: indiceTitulo, imagenes: imagenesDiv });
      }
    }
  });

  let i = 0;
  return divs.map((itm, indx) => {
    if (i > 4) {
      i = 0;
    }
    const divsImagenes = imagenes.filter(
      (itmImg) => itmImg.indiceTitulo === indx
    );

    let div = null;
    if (
      (itm.titulo_encabezado != null && itm.titulo_encabezado != "") ||
      (itm.titulo_encabezado === "" && itm.imagen === null)
    ) {
      div = (
        <div className={["row", classes.transition].join(" ")}>
          <div className="container">
            <div
              className={[
                "col-12 sin_padding",
                itm.titulo_encabezado ? null : "d-none",
              ].join(" ")}
            >
              <div
                className={[
                  "main_subtitle",
                  "texto_40 ",
                  "nunito",
                  "texto_blanco sin_padding",
                  classes.containerTitulo,
                ].join(" ")}
              >
                <img
                  src={hojaBlanca}
                  alt="bivasg"
                  className={["slash", classes.img].join(" ")}
                />
                <h1 className={classes.titulo}>{itm.titulo_encabezado}</h1>
              </div>
            </div>
            <div
              className={[
                "col-12  montse texto_16  texto_blanco",
              ].join(" ")}
              dangerouslySetInnerHTML={{ __html: itm.texto_html }}
            />
            <div className="row">
              {divsImagenes.length > 0 ? createImages(divsImagenes) : null}
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }

    i++;
    return (
        div
    );
  });
};


const BackgroundContainer = (props) => {
  return (
    <div className={classes.animated} >
      <div className={[classes.imagen].join(" ")}  />
      {createDivs(props.divs)}
    </div>
  );
};

export default BackgroundContainer;

import React, { Component } from "react";
import { connect } from "react-redux";
import Toggle from "../../../components/ui/toggle/Toggle";
import Cuadricula from "../../../components/ui/cuadricula/Cuadricula";
import Box from "../../../components/ui/box/Box";
import * as actions from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
import classes from "./Acerca.css";

class Acerca extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    cuadriculaGrid: null,
    cuadriculaHistoria: null,
    cuadriculaCrecer: null,
    cuadriculaOperacion: null,
    cuadriculaLima: null,
    cuadriculaMercado: null
  };
  componentDidMount() {
    this.props.onInitIntl(14);

    this.props.onFolleto();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }

    if (prevProps.cuadriculaGrid !== this.props.cuadriculaGrid) {
      this.setState({
        cuadriculaGrid: this.props.cuadriculaGrid
      });
    }
    if (prevProps.cuadriculaHistoria !== this.props.cuadriculaHistoria) {
      this.setState({
        cuadriculaHistoria: this.props.cuadriculaHistoria
      });
    }
    if (prevProps.cuadriculaCrecer !== this.props.cuadriculaCrecer) {
      this.setState({
        cuadriculaCrecer: this.props.cuadriculaCrecer
      });
    }
    if (prevProps.cuadriculaOperacion !== this.props.cuadriculaOperacion) {
      this.setState({
        cuadriculaOperacion: this.props.cuadriculaOperacion
      });
    }
    if (prevProps.cuadriculaLima !== this.props.cuadriculaLima) {
      this.setState({
        cuadriculaLima: this.props.cuadriculaLima
      });
    }
    if (prevProps.cuadriculaMercado !== this.props.cuadriculaMercado) {
      this.setState({
        cuadriculaMercado: this.props.cuadriculaMercado
      });
    }
  }

  render() {
    let cuadricula1 = null;
    let cuadricula2 = null;
    let cuadricula3 = null;
    let cuadricula4 = null;
    let cuadricula5 = null;
    if (this.state.cuadriculaGrid) {
      cuadricula1 = this.state.cuadriculaGrid;
    }
    if (this.state.cuadriculaCrecer) {
      cuadricula2 = this.state.cuadriculaCrecer;
    }
    if (this.state.cuadriculaOperacion) {
      cuadricula3 = this.state.cuadriculaOperacion;
    }
    if (this.state.cuadriculaLima) {
      cuadricula4 = this.state.cuadriculaLima;
    }
    if (this.state.cuadriculaMercado) {
      cuadricula5 = this.state.cuadriculaMercado;
    }
    let folleto = null;
    if (this.props.folleto) {
      folleto = this.props.folleto.map((item, i) => {
        return <Box key={"folleto_" + i} modelo="modelo_1" data={item} />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <React.Fragment>
          <h1 className={"nunito regular texto_42"}>
            <FormattedMessage id="nosotros.acercade.titulo1" defaultMessage=" " />
          </h1>

          <div className={"row margen_top_50"}>
            <Cuadricula datos={cuadricula1} coloreados={[5]} saltarse={[]} enBlanco={[]} quitar={[]} modulo={3} />
          </div>
          <div className="row margen_top_50 col-sm-12 sin_padding_L">
            <span className="texto_16 montse regular">
              <FormattedMessage id="nosotros.acercade.introduccion1" defaultMessage=" " />
            </span>
          </div>
          <div className={["margen_top_50 col", classes.fondo_comillas].join(" ")}>
            <div className={["row", classes.vertircal_div, classes.tamano_550].join(" ")}>
              <i className={["fas fa-quote-left", classes.comillas_izq].join(" ")} />
              <span
                className={[
                  "row color_azul texto_30 nunito italic",
                  classes.tamano_550,
                  classes.texto_comas,
                  classes.vertical
                ].join(" ")}
              >
                <FormattedMessage id="nosotros.acercade.txtcomillas" defaultMessage=" " />
                <i class={["fas fa-quote-right", classes.comillas_der].join(" ")} />
              </span>
            </div>
          </div>
          <div className="row margen_top_50">
            <span className="texto_16 montse regular">
              <FormattedMessage id="nosotros.acercade.introduccion2" defaultMessage=" " />
            </span>
          </div>
          <div className="row margen_top_50">{folleto}</div>
          <div className="margen_top_50 ">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedMessage id="nosotros.acercade.subtitulo1" defaultMessage=" " />
              </h2>
            </div>
            <div className="margen_top_450">
              <Toggle datos={this.state.cuadriculaHistoria} />
            </div>
          </div>

          <div className={"margen_top_50"} />

          <div className="margen_top_50 ">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedMessage id="nosotros.acercade.subtitulo2" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className="margen_top_20">
            <span className={"montse texto_16 regular"}>
              {" "}
              <FormattedHTMLMessage id="nosotros.acercade.introduccion3" defaultMessage=" " />
            </span>
            <br />
            {/* <span className={"montse texto_16 regular"}>
          <FormattedMessage id="nosotros.acercade.titulo1" defaultMessage=" " />
           La incursión de BIVA en el mercado de valores aporta los siguientes beneficios:
          </span> */}
            <div className="row margen_top_50 relativo">
              <Cuadricula datos={cuadricula2} coloreados={[]} saltarse={[7]} enBlanco={[]} quitar={[]} modulo={3} />
            </div>
          </div>

          <div className="row margen_top_50 ">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedMessage id="nosotros.acercade.subtitulo3" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className="margen_top_20">
            <span className={"montse texto_16 regular"}>
              <FormattedMessage id="nosotros.acercade.introduccion4" defaultMessage=" " />
            </span>
          </div>

          <div className="margen_top_50 ">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedMessage id="nosotros.acercade.subtitulo4" defaultMessage=" " />
              </h2>
            </div>

            <div className="margen_top_20">
              <span className={"montse texto_16 regular"}>
                <FormattedMessage id="nosotros.acercade.introduccion5" defaultMessage=" " />
              </span>
              <br />
            </div>
            <div className="row margen_top_50 relativo">
              <Cuadricula datos={cuadricula3} coloreados={[1, 3, 4, 6]} saltarse={[]} enBlanco={[2]} quitar={[5]} modulo={3} />
            </div>
            <div className="row margen_top_100 relativo">
              <Cuadricula
                datos={cuadricula4}
                coloreados={[1, 3, 4, 5, 6]}
                saltarse={[]}
                enBlanco={[2]}
                quitar={[]}
                modulo={3}
              />
            </div>
            <div className="row margen_top_100 relativo margin_bottom_200">
              <Cuadricula datos={cuadricula5} coloreados={[1, 3, 4, 6]} saltarse={[]} enBlanco={[2]} quitar={[5]} modulo={3} />
            </div>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    folleto: state.documentos.about_us,
    diccionario: state.intl.diccionario,
    cuadriculaGrid: state.cuadriculas.grid,
    cuadriculaHistoria: state.cuadriculas.historia,
    cuadriculaCrecer: state.cuadriculas.crecer,
    cuadriculaOperacion: state.cuadriculas.operacion,
    cuadriculaLima: state.cuadriculas.lima,
    cuadriculaMercado: state.cuadriculas.mercado
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    },
    onFolleto: () => {
      dispatch(actions.initAboutDocumentos());

      dispatch(actions.initBoxGrid());
      dispatch(actions.initBoxHistoria());
      dispatch(actions.initBoxCrecer());
      dispatch(actions.initBoxOperacion());
      dispatch(actions.initBoxLima());
      dispatch(actions.initBoxMercado());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Acerca);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
    fondos: null,
    fondoDetail: null,
    fondoDocs: null,
    informacion: null,
    concentradoPrecios: null,
    clavesOperadora: null,
    clavesFondo: null,
    tiposFondo: null,
    clasificacionesFondo: null,
    tiposDocumento: null,
    series: null,
    fondosHistoricoGrafica: null,
    seriesHistorico: null,
    clavesFondoHistorico: null,
    periodo: null,
    ejercicio: null,
    composicionCartera: null,
    composicionUrlCsv: null,
};

const setFondos = (state, action) => {
    return updateObject(state, action);
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FONDOS_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSDETAIL_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSDOCS_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSINFORMACIONCORPORATIVA_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCONCENTRADOPRECIOSGRAL_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCVEOPERADORA_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCVEFONDO_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSTIPOFONDO_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCLASIFICACIONFONDO_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCVEOPERADORAINFORMACIONCORPORATIVA_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCVEFONDOINFORMACIONCORPORATIVA_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSTIPODOCUMENTO_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSSERIE_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_HISTORICOCONCENTRADOPRECIOS_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSPERIODO_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSEJERCICIO_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSURLCSV_SUCCESS:
            return setFondos(state, action);
        case actionTypes.FETCH_FONDOSCOMPOSICIONCARTERA_SUCCESS:
            return setFondos(state, action);

        default:
            return state;
    }
};

export default reducer;
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";
import BtnArriba from "../../components/btnArriba/BtnArriba";
import "./App.css";

import Layout from "../../hoc/layout/Layout";
import Home from "../home2/Home2";
import Nosotros from "../nosotros/Nosotros";
import Alianzas from "../alianzas/Alianzas";
import Regulacion from "../regulacion/Regulacion";
import Instituto from "../instituto/Instituto";
import Invertir from "../invertir/Invertir";
import Operaciones from "../operaciones/Operaciones";
import Empresas from "../empresas/Empresas";
import Listado from "../empresas/Listado";
import Informacion from "../informacion/Informacion";
import NotFound from "../../components/notFound/NotFound";
import Avisos from "../avisos/Avisos";
import Cloud from "../cloud/Cloud";
import Glosario from "../glosario/Glosario";
import Sustentable from "../sustentable/Sustentable";

class App extends Component {
  componentDidMount() {
    this.props.onInitMenu();
    this.props.onInitFooter();
    this.props.onInitTrkd();
    this.props.onInitNoticiasBiva();
    this.props.onInitTrkd();
    this.props.onInitActivas("topInstrument");
    this.props.onInitIndice();
    this.props.onInitBanner();
  }

  render() {
    const routes = (
      <Switch>
        <Route path="/" exact component={Home} />
        {/* <Route path="/home2" exact component={Home2} /> */}
        <Route path="/nosotros" component={Nosotros} />
        <Route path="/alianzas" component={Alianzas} />
        <Route path="/regulacion" component={Regulacion} />
        <Route path="/instituto_biva" component={Instituto} />
        <Route path="/glosario-financiero" component={Glosario} />
        <Route path="/invertir" component={Invertir} />
        <Route path="/operaciones" component={Operaciones} />
        <Route path="/empresas" component={Empresas} />
        <Route path="/listado_de_valores" component={Listado} />
        <Route path="/avisos_y_reportes" component={Avisos} />
        <Route path="/informacion_de_mercado" component={Informacion} />
        <Route path="/biva_cloud" component={Cloud} />
        <Route path="/biva-sostenible" component={Sustentable}/>
        <Route path="/bivasg" component={Sustentable}/>
        <Route component={NotFound} />
      </Switch>
    );

    return (
        <Layout currentPage="">
          {routes}
          <BtnArriba />
          <div
            id="contenedorHeader"
          ></div>
        </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitMenu: () => dispatch(action.initMenu()),
    onInitFooter: () => dispatch(action.initFooter()),
    onInitNoticiasBiva: () => dispatch(action.initNoticiasBiva()),
    onInitTrkd: () => dispatch(action.initTrkd()),
    onInitActivas: keyWord => dispatch(action.initActivas(keyWord)),
    onInitIndice: () => dispatch(action.initIndice()),
    onInitBanner: () => dispatch(action.initBanner())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);

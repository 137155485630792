import React, { Component } from "react";
import classes from "./FilterAvisosDerecho.css";
import { Form, Field } from "react-final-form";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import { getLan } from "../../../stores/utils/utilities";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiple = null,
  maxDate = null,
  max = null,
  options,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      max: maxDate,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    isDisabled: disabled,
    max: max,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      {label ? (
        <label for={input.name} className={["texto_14", "montse", "regular"].join(" ")}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

const idsTipoInformacion = [16, 17];

const idsTiposDocumento = [1, 2, 53, 66, 79, 156, 157, 158, 159, 160, 161, 162, 168, 169];

class FilterAvisosDerecho extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    toggleFilters: false,
    disableEnableButtons: true,
    selectPivot: false,
    selectSubPivot: false,
    prevInputSelected: false,
    selectedFilters: {
      tipo_derecho: [],
      fechas: []
    },
    form_filters: {
      tipo_derecho: {
        value: ""
      },
      fecha_inicio: {
        value: "",
        disabled: false
      },
      fecha_fin: {
        value: "",
        disabled: false
      },
      errorDate: false,
      typeInfo: null
    },
    idIsFoundByInfo: false,
    idIsFoundByDoc: false
  };

  componentDidMount() {
    this.props.onInitTipoDerechoAgendaFilter(this.props.emisoraSeleccionada);
  }

  onPrintFrameHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : null;

    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col-3", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar Filtros"
                        : "Close"
                      : getLan() === "es"
                      ? "Filtrar"
                      : "Filter"}
                    </span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-9", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div
                className={[
                  classes.filters_selected_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_filters_selected : classes.border_filters_selected
                ].join(" ")}
              >
                {input}
              </div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              tipo_derecho: this.state.form_filters.tipo_derecho.value,
              fecha_inicio: this.state.form_filters.fecha_inicio.value,
              fecha_fin: this.state.form_filters.fecha_fin.value
            }}
            render={({
              optionsClavesEmisoras = this.props.tipo_derecho ? this.prepareOptionsHandler(this.props.tipo_derecho) : [],
              form
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterModelOneHandler}>
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-4">
                    <Field
                      name="tipo_derecho"
                      id="tipo_derecho"
                      label={getLan() === "es" ? "Tipo de derecho" : "Corporate action type"}
                      elementType="select"
                      classes="custom-select"
                      options={optionsClavesEmisoras}
                      onInputChange={event => this.onInputSelectHandler(event, 0)}
                      component={renderInput}
                    />
                  </div>

                  <div className="col-12 col-sm-12 col-lg-6">
                    <div className="row">
                      <div className={["col", "col-lg-6 sin_padding", classes.no_col_padding].join(" ")}>
                        <Field
                          name="fecha_inicio"
                          id="fecha_inicio"
                          type="date"
                          maxDate="2999-12-31"
                          classes={classes.fechaInicio}
                          disabled={this.state.form_filters.fecha_inicio.disabled}
                          label={getLan() === "es" ?  "Fecha de publicación" : "Publication date"}
                          onInputChange={event => this.onInputSelectHandler(event, 4)}
                          component={renderInput}
                        />
                      </div>
                      <div className={["col", "col-lg-6 sin_padding", classes.no_col_padding].join(" ")}>
                        <Field
                          name="fecha_fin"
                          id="fecha_fin"
                          type="date"
                          classes={classes.fechaFin}
                          label={getLan() === "es" ?  "Fecha fin" : "To"}
                          maxDate="2999-12-31"
                          disabled={this.state.form_filters.fecha_fin.disabled}
                          onInputChange={event => this.onInputSelectHandler(event, 5)}
                          component={renderInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                {this.sectionButtonsModelOne(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  prepareOptionsHandler = options => {
    let newOptions = [];

    options.forEach(option => {
      let id = null;
      let nombre = null;

      if (option.tipo) {
        id = option.tipo ? `${option.id}_${option.nombre}_${option.tipo}` : `${option.id}_${option.clave}`;
        nombre = option.clave ? option.clave : option.nombre;
      } else if (option.nombre || option.clave) {
        id = option.nombre ? `${option.id}_${option.nombre}` : `${option.id}_${option.clave}`;
        nombre = option.clave ? option.clave : option.nombre;
      } else {
        id = option;
        nombre = option;
      }

      newOptions.push({
        id: id,
        nombre: nombre
      });
    });

    return newOptions;
  };

  sectionButtonsModelOne = form => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterModelOneHandler = event => {};

  // Limpia el formulario restablece los estados de los inputs a sus valores por defecto
  onResetFilterModelOneHandler = form => {
    form.reset();

    const selectPivot = false;
    const selectSubPivot = false;
    const prevInputSelected = false;
    const idIsFoundByInfo = false;
    const idIsFoundByDoc = false;

    const form_filter = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form_filter.tipo_derecho.value = "";
    selectedFilters.tipo_derecho = [];

    form_filter.fecha_inicio.value = "";
    form_filter.fecha_inicio.disabled = false;
    form_filter.fecha_fin.value = "";
    form_filter.fecha_fin.disabled = false;
    selectedFilters.fechas = [];

    form.typeInfo = null;
    form.errorDate = false;

    this.setState({
      form: form_filter,
      selectedFilters: selectedFilters,
      selectPivot: selectPivot,
      selectSubPivot: selectSubPivot,
      prevInputSelected: prevInputSelected,
      idIsFoundByInfo: idIsFoundByInfo,
      idIsFoundByDoc: idIsFoundByDoc
    });

    this.props.setFechaInicio(null);
    this.props.setFechaFin(null);
    this.props.setTipoDerecho(null);
    this.props.setPage(1);

  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onFilterDataByEvent = () => {
    const [valuesTipoDerecho, valuesFechaInicio, valuesFechaFin] = this.onGetAllValuesFormFilter();

    this.props.onInitAvisosDerecho(
      this.props.itemsPerPage,
      0,
      this.props.emisoraSeleccionada,
      valuesTipoDerecho,
      valuesFechaInicio,
      valuesFechaFin
    );

    // Se invocan a las funciones el componente padre (AvisosDerecho.js) para setear los valores correspondientes
    // Configurando el valor de la emisora seleccionada

    // Configurando el valor de tipo de documento seleccionado
    valuesTipoDerecho && valuesTipoDerecho.length > 0
      ? this.props.setTipoDerecho(valuesTipoDerecho)
      : this.props.setTipoDerecho(null);

    // Configurando el valor de fecha inicio seleccionado
    valuesFechaInicio && valuesFechaInicio.length > 0
      ? this.props.setFechaInicio(valuesFechaInicio)
      : this.props.setFechaInicio(null);

    // Configurando el valor de fecha fin seleccionado
    valuesFechaFin && valuesFechaFin.length > 0 ? this.props.setFechaFin(valuesFechaFin) : this.props.setFechaFin(null);

    // Configurando la primera página de la busqueda
    this.props.setPage(1);
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesTipoDerecho =
      form.tipo_derecho.value && form.tipo_derecho.value !== "" ? this.onSplitValueHandler(form.tipo_derecho.value) : null;

    // Se obtiene el valor de fecha inicio
    const valuesFechaInicio = form.fecha_inicio.value && form.fecha_inicio.value !== "" ? form.fecha_inicio.value : null;
    // Se obtiene el valor de fecha fin
    const valuesFechaFin = form.fecha_fin.value && form.fecha_fin.value !== "" ? form.fecha_fin.value : null;

    return [valuesTipoDerecho, valuesFechaInicio, valuesFechaFin];
  };

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValid = (inicio, fin) => {
    return inicio > fin;
  };

  // Verifica si el valor recibido ya sea de tipo de informacion o tipo de documento
  // pertenesca a unos de los ids en "idsTipoInformacion" o idsTiposDocumento
  onCheckIfIdsExists = (type, value) => {
    let exists = false;
    let val = null;

    if (type === 2) {
      val = +value;
      idsTipoInformacion.forEach(item => {
        if (item === val) exists = true;
      });
    } else {
      val = [...value];
      val.forEach(v => {
        idsTiposDocumento.forEach(item => {
          v = +v;
          if (item === v) exists = true;
        });
      });
    }
    return exists;
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = (type, value) => {
    const selectedFilters = this.state.selectedFilters;
    switch (type) {
      case 0:
        return ["tipo_derecho", value, selectedFilters.tipo_derecho];
      case 4:
        return ["fecha_inicio", value, selectedFilters.fechas, "fechas"];
      case 5:
        return ["fecha_fin", value, selectedFilters.fechas, "fechas"];
      default:
        break;
    }
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (type === 0 || type === 2) {
      if (values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];
      const stateExists = newStateValues.find(option => option === values);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== values);
      } else {
        if (values !== "") {
          newStateValues.push(values);
        } else {
          newStateValues = [];
        }
      }
    }

    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];
    if (type === 0 || type === 2) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExist = newFilterValues.find(option => option.label === label);
      if (filterExist) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
      } else {
        if (values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        } else {
          newFilterValues = [];
        }
      }
    }

    return newFilterValues;
  };

  // Retorna el valor, etiqueta y tipo  de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newValue = null;
    let newLabel = null;
    let newType = null;
    if (type === 4 || type === 5) {
      // Fechas inicio o fin
      newValue = value;
      newLabel = type === 4 ? "fecha_inicio" : "fecha_fin";
    } else if (type === 6 || type === 7) {
      // Periodo o ejercicio
      newValue = value;
      newLabel = type === 6 ? "periodo" : "ejercicio";
    } else {
      // Resto de campo
      newValue = value.split("_")[0];
      newLabel = value.split("_")[1];
      newType = type === 2 ? value.split("_")[2] : null;
    }

    return [newValue, newLabel, newType];
  };

  onInputSelectHandler = (event, type = null) => {
    let actualValue = type !== 1 && type !== 3 ? event.target.value : event.target.options;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newValue = null;
    let newLabel = null;
    let newType = null;
    if (type !== 1 && type !== 3) {
      [newValue, newLabel, newType] = this.onGetValueLabelAndTypeHandler(
        actualValue,
        type
      );
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(type, actualValue);

    let selectPivot = this.state.selectPivot;
    let selectSubPivot = this.state.selectSubPivot;
    let prevInputSelected = this.state.prevInputSelected;
    let idIsFoundByInfo = this.state.idIsFoundByInfo;
    let idIsFoundByDoc = this.state.idIsFoundByDoc;

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = type !== 4 && type !== 5 ? this.onChangeStateHandler(stateValues, actualValue, type) : stateValues;
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesTipoDerecho,
      valuesTipoInstrumentos,
      valuesTipoInformacion,
      valuesTipoDocumentos
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);
    if (type === 0) {
      // Clave emisoras
      if (newValue !== "") {
        if (!selectPivot || selectPivot === "firstpath") {
          // Cuando "emisora" es el campo pivote
          selectPivot = "firstpath";
          form.typeInfo = null;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento o tipo documento
          if (!selectSubPivot || selectSubPivot === "emisora") {
            selectSubPivot = "emisora";
            prevInputSelected = null;
            // Limpiando valores para tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(valuesTipoDerecho, newType, valuesTipoInformacion);
            // Limpiando valores para campo tipo documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(valuesTipoDerecho, newType, valuesTipoInformacion);
          } else if (selectSubPivot === "instrumento") {
            prevInputSelected = prevInputSelected === "documento" ? "documento" : "emisora";
            if (!prevInputSelected || prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(valuesTipoDerecho, newType, valuesTipoInformacion, valuesTipoInstrumentos);
            }
          } else if (selectSubPivot === "documento") {
            prevInputSelected = prevInputSelected === "instrumento" ? "instrumento" : "emisora";
            if (!prevInputSelected || prevInputSelected !== "instrumento") {
              // Limpiando valores para tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(valuesTipoDerecho, newType, valuesTipoInformacion, valuesTipoDocumentos);
            }
          }
        }
      } else {
        if (!selectPivot || selectPivot === "firstpath") {
          // Cuando "emisora" es el campo pivote
          selectPivot = null;
          selectSubPivot = null;
          form.typeInfo = null;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectPivot === "secondpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento
          if (!selectSubPivot || selectSubPivot === "emisora") {
            selectSubPivot = null;
            // Habilitando fechas en caso de estar deshabilitadas
            form.fecha_inicio.disabled = false;
            form.fecha_fin.disabled = false;
          } else if (selectSubPivot === "informacion") {
            if (prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(null, newType, valuesTipoInformacion, valuesTipoInstrumentos);
              prevInputSelected = null;
            }
          }
        } else if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento o tipo documento
          if (!selectSubPivot || selectSubPivot === "emisora") {
            selectSubPivot = null;
            prevInputSelected = null;
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento solo con valor de la clave de emisora
            this.onFilterInstrumentoHandler(null, newType, valuesTipoInformacion);
            // Limpiando valores para campo tipo documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(null, newType, valuesTipoInformacion);
          } else if (selectSubPivot === "instrumento") {
            if (prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(null, newType, valuesTipoInformacion, valuesTipoInstrumentos);
              prevInputSelected = null;
            }
          } else if (selectSubPivot === "documento") {
            if (prevInputSelected !== "instrumento") {
              // Limpiando valores para tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(null, newType, valuesTipoInformacion, valuesTipoDocumentos);
              prevInputSelected = null;
            }
          }
        }
      }
    } else if (type === 4) {
    } else if (type === 5) {
    } else if (type === 6) {
    } else if (type === 7) {
    }
    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      selectPivot: selectPivot,
      selectSubPivot: selectSubPivot,
      prevInputSelected: prevInputSelected,
      idIsFoundByDoc: idIsFoundByDoc,
      idIsFoundByInfo: idIsFoundByInfo
    });

    this.onFilterDataByEvent();
  };

  onDeleteFilterHandler = item => {
    let form = this.state.form_filters;
    let selectPivot = this.state.selectPivot;
    let selectSubPivot = this.state.selectSubPivot;
    let selectedFilters = this.state.selectedFilters;
    let prevInputSelected = this.state.prevInputSelected;

    let newStateValues = null;
    let newFilterValues = null;

    let idIsFoundByInfo = this.state.idIsFoundByInfo;
    let idIsFoundByDoc = this.state.idIsFoundByDoc;

    let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(item.type);

    const auxValue = item.type !== 1 && item.type !== 3 ? "" : item.value;
    newStateValues = this.onChangeStateHandler(stateValues, auxValue, item.type);

    newFilterValues = this.onChangeFilterHandler(filterValues, auxValue, item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;
    // Se obtienen todos los valores de los campos del formulario.
    let [
      valuesTipoDerecho,
      valuesTipoInstrumentos,
      valuesTipoInformacion,
      valuesTipoDocumentos
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (item.type === 0) {
      // Calves emisoras

      if (selectPivot === "firstpath") {
       
        // Habilitando fechas en caso de estar deshabilitadas
        form.fecha_inicio.disabled = false;
        form.fecha_fin.disabled = false;
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "emisora") {
          form.typeInfo = null;
          selectSubPivot = false;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } 
      } else if (selectPivot === "thirdpath") {
        if (selectSubPivot === "emisora") {
          selectSubPivot = false;
        } else if (selectSubPivot === "instrumento") {
          prevInputSelected = false;
         
        } else if (selectSubPivot === "documento") {
          if (prevInputSelected !== "instrumento") {
            // Limpiando valores para campo tipo instrumento
         
            prevInputSelected = false;
          }
        }
      }
    } else if (item.type === 1) {
      // Tipo instrumentos
      // En caso de no existir valores selecionados para tipos de instrumentos se asigna null a la variable
      valuesTipoInstrumentos = valuesTipoInstrumentos && valuesTipoInstrumentos.length > 0 ? valuesTipoInstrumentos : null;

      const newType = form.typeInfo;

      if (selectPivot === "firstpath") {
        // Verificando si tipo de informacion fue seleccionado primero a tipo instrumento en le primer camino
        if (selectSubPivot === "instrumento") {
          if (!valuesTipoInstrumentos || valuesTipoInstrumentos.length === 0) {
            selectSubPivot = false;
            prevInputSelected = false;
          }
          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectSubPivot === "informacion") {
          if (prevInputSelected !== "documento") {
            // Limpiando valores para campo tipo documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(valuesTipoDerecho, newType, valuesTipoInformacion, valuesTipoInstrumentos);
            if (!valuesTipoInstrumentos || valuesTipoInstrumentos.length === 0) {
              prevInputSelected = false;
            }
          }
        }
      } else if (selectPivot === "secondpath") {
        if (!valuesTipoInstrumentos || valuesTipoInstrumentos.length === 0) {
          selectPivot = false;
        }
        selectSubPivot = false;
        // Limpiando valores para campo tipo de documento
        form.tipo_documento.value = "";
        selectedFilters.tipo_documento = [];
        // Limpiando valores para campo emisoras
        form.tipo_derecho.value = "";
        selectedFilters.tipo_derecho = [];
        // Filtrado de emisoras por tipo de instrumento
        this.onFilterClavesEmisorasHandler(null, null, null, valuesTipoInstrumentos);
        // Limpiando valores para campo tipo informacion
      

        // Limpiando valores de periodo y ejercicio
        form.ejercicio.value = "";
        form.periodo.value = "";
        selectedFilters.periodos = [];
        idIsFoundByInfo = null;
        idIsFoundByDoc = null;

        // Habilitando fechas en caso de estar deshabilitadas
        form.fecha_inicio.disabled = false;
        form.fecha_fin.disabled = false;
      } else if (selectPivot === "thirdpath") {
        if (selectSubPivot === "instrumento") {
          if (!valuesTipoInstrumentos || valuesTipoInstrumentos.length === 0) {
            selectSubPivot = false;
            prevInputSelected = false;
          }
          // Limpiando valores para campo emisoras
          form.tipo_derecho.value = "";
          selectedFilters.tipo_derecho = [];
          // Filtrado de emisoras por tipo de instrumento
          this.onFilterClavesEmisorasHandler(newType, null, valuesTipoInformacion, valuesTipoInstrumentos);
          // Limpiando valores para campo tipo documento
          form.tipo_documento.value = "";
          selectedFilters.tipo_documento = [];
          // Filtrado de tipo de documento
          this.onFilterDocumentosHandler(null, newType, valuesTipoInformacion, valuesTipoInstrumentos);
        } else if (selectSubPivot === "emisora") {
          if (prevInputSelected !== "documento") {
            // Limpiando valores para campo tipo documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(valuesTipoDerecho, newType, valuesTipoInformacion, valuesTipoInstrumentos);
            prevInputSelected = false;
          }
        } else if (selectSubPivot === "documento") {
          if (prevInputSelected !== "emisora") {
            // Limpiando valores para campo emisoras
            form.tipo_derecho.value = "";
            selectedFilters.tipo_derecho = [];
            // Filtrado de emisoras por tipo de instrumento
            this.onFilterClavesEmisorasHandler(newType, valuesTipoDocumentos, valuesTipoInformacion, valuesTipoInstrumentos);
            prevInputSelected = false;
          }
        }
      }
    } else if (item.type === 2) {
      // Tipo de información
      // Limpiando valores para campo tipo documento
      form.tipo_documento.value = "";
      selectedFilters.tipo_documento = [];

      if (selectPivot === "firstpath") {
        // Verificando si tipo de informacion fue seleccionado primero a tipo instrumento en le primer camino
        if (selectSubPivot === "informacion") {
          selectSubPivot = false;
          // Limpiando valores para campo tipo instrumento
          form.tipo_instrumento.value = "";
          selectedFilters.tipo_instrumento = [];

          // Filtrado de tipo de informacion por ermisora
          this.onFilterInstrumentoHandler(valuesTipoDerecho);
        }
        idIsFoundByInfo = false;
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "informacion") {
          // Limpiando valores para campo emisoras
          form.tipo_derecho.value = "";
          selectedFilters.tipo_derecho = [];
          // Filtrado de emisoras por tipo de instrumento
          this.onFilterClavesEmisorasHandler(null, null, null, valuesTipoInstrumentos);
          selectSubPivot = false;
        }
      } else if (selectPivot === "thirdpath") {
        selectSubPivot = false;
        // Limpiando valores para campo tipo instrumento
        form.tipo_instrumento.value = "";
        selectedFilters.tipo_instrumento = [];
        // Filtrado de tipo de informacion por ermisora
        this.onFilterInstrumentoHandler();
        // Limpiando valores para campo emisoras
        form.tipo_derecho.value = "";
        selectedFilters.tipo_derecho = [];
        // Filtrado de emisoras por tipo de instrumento
        this.onFilterClavesEmisorasHandler();
      }
    } else if (item.type === 3) {
      // Tipo de documento
      const newType = form.typeInfo;

      if (selectPivot === "thirdpath") {
        if (selectSubPivot === "documento") {
          // Limpiando valores para campo tipo instrumento
          form.tipo_instrumento.value = "";
          selectedFilters.tipo_instrumento = [];
          // Filtrado de tipo de informacion por ermisora
          this.onFilterInstrumentoHandler(null, newType, valuesTipoInformacion);
          // Limpiando valores para campo emisoras
          form.tipo_derecho.value = "";
          selectedFilters.tipo_derecho = [];
          // Filtrado de emisoras por tipo de instrumento
          this.onFilterClavesEmisorasHandler(newType, null, valuesTipoInformacion);
          prevInputSelected = false;
        } else if (selectSubPivot === "emisora") {
          if (prevInputSelected !== "instrumento") {
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(valuesTipoDerecho, newType, valuesTipoInformacion);
            if (valuesTipoDocumentos && valuesTipoDocumentos.length === 0) {
              prevInputSelected = false;
            }
          }
        }
      } else if (selectPivot === "firstpath") {
        if (selectSubPivot === "informacion") {
          if (prevInputSelected !== "instrumento") {
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(valuesTipoDerecho, newType, valuesTipoInformacion, valuesTipoDocumentos);
            if (valuesTipoDocumentos && valuesTipoDocumentos.length === 0) {
              prevInputSelected = false;
            }
          }
        }
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "informacion") {
          if (!prevInputSelected || prevInputSelected !== "emisora") {
            // Limpiando valores para campo emisoras
            form.tipo_derecho.value = "";
            selectedFilters.tipo_derecho = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(newType, valuesTipoDocumentos, valuesTipoInformacion, valuesTipoInstrumentos);

            if (valuesTipoDocumentos && valuesTipoDocumentos.length === 0) {
              prevInputSelected = false;
            }
          }
        }
      }
      idIsFoundByDoc = false;
    } else if (item.type === 4) {
      // Fecha inicio
      const disableValue = form.fecha_fin.value.length === 0 ? false : true;
      form.periodo.disabled = disableValue;
      form.ejercicio.disabled = disableValue;
    } else if (item.type === 5) {
      // Fecha fin
      const disableValue = form.fecha_inicio.value.length === 0 ? false : true;
      form.periodo.disabled = disableValue;
      form.ejercicio.disabled = disableValue;
    } else if (item.type === 6) {
      // Periodos
      const disableValue = form.ejercicio.value.length === 0 ? false : true;
      form.fecha_inicio.disabled = disableValue;
      form.fecha_fin.disabled = disableValue;
    } else if (item.type === 7) {
      // Ejercicio
      const disableValue = form.periodo.value.length === 0 ? false : true;
      form.fecha_inicio.disabled = disableValue;
      form.fecha_fin.disabled = disableValue;
    }

    this.setState({
      form_filters: form,
      selectPivot: selectPivot,
      selectSubPivot: selectSubPivot,
      prevInputSelected: prevInputSelected,
      idIsFoundByInfo: idIsFoundByInfo,
      idIsFoundByDoc: idIsFoundByDoc,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.selectedFilters.tipo_derecho, ...this.state.selectedFilters.fechas];
    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map(item => {
          const valueToshow =
            item.type === 4 || item.type === 5 || item.type === 6 || item.type === 7 ? item.value : item.label;
          return (
            <div className={classes.filter_container_model_one} key={`filter${item.nombre}-${item.id}`}>
              <span className={["montse", classes.filter_text_model_one].join(" ")}>{valueToshow}</span>
              <span className={classes.filter_icon_button_model_one}>
                <i className="far fa-times-circle" onClick={() => this.onDeleteFilterHandler(item)} />
              </span>
            </div>
          );
        })}
      </div>
    );
    return filters;
  };

  render() {
    const filter = this.props.modelo ? this.onPrintFrameHandler() : null;

    return filter;
  }
}

const mapStateToProps = state => {
  return {
    tipo_derecho: state.agenda.tipos_derecho_filter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitTipoDerechoAgendaFilter: idEmisora => {
      dispatch(actions.initTipoDerechoAgendaFilter(idEmisora));
    },
    onInitAvisosDerecho: (size, page, emisoraId, tipoDerecho, fechaInicio, fechaFin) => {
      dispatch(actions.initSicAvisosDerecho(size, page, emisoraId, tipoDerecho, fechaInicio, fechaFin));
    },
    onFilterTipoDocumentos: params => {
      dispatch(actions.initBancoTipoDocumento(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterAvisosDerecho);

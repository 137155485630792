import axios from "axios";
import * as actionTypes from "./actionTypes";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;

const setSolicitudes = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SOLICITUDES_SUCCESS,
      solicitudes: [],
    };
  }
  return {
    type: actionTypes.FETCH_SOLICITUDES_SUCCESS,
    solicitudes: data
  };
};
const setSolicitudesSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SOLICITUDES_SUCCESS,
      solicitudesSearch: [],
    };
  }
  return {
    type: actionTypes.FETCH_SOLICITUDES_SUCCESS,
    solicitudesSearch: data
  };
};

export const initSolicitudes = (
  size,
  page,
  emisora_id = null,
  valor = null,
  fechaInicio = null,
  fechaFin = null,
  clave = null,
  idTramite = null,
  tipoBono = null
) => {

  let url = `${dominio}/emisoras/tramites`;

  const params = {
    size: size,
    page: page,
    idEmisora: emisora_id,
    idTipoValor: valor,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
    clave: clave,
    idTramite: idTramite,
    tipoBonoAsg: tipoBono
  }

  const queryString = StringUtils.jsonToQueryString(params);

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setSolicitudes(response.data));
        dispatch(setSolicitudesSearch(response.data));
      })
      .catch(err => {
          dispatch(setSolicitudes(err.data))
          dispatch(setSolicitudesSearch(err.data))
        }
      );
  };
};


const setPreliminares = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PRELIMINARES_SUCCESS,
      preliminares: null
    };
  }
  return {
    type: actionTypes.FETCH_PRELIMINARES_SUCCESS,
    preliminares: data
  };
};
export const getProspectosPreliminares = (
  size,
  page,
  emisora_id = null,
  valor = null,
  clave = null,
  nombreEmisora = null
) => {
  const params = {
    size: size,
    page: page,
    idEmisora: emisora_id,
    idTipoValor: valor,
    clave: clave != null ? encodeURIComponent(clave) : clave,
    nombreEmisora: nombreEmisora != null ? encodeURIComponent(nombreEmisora) : nombreEmisora
  }

  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/emisoras/prospectos-preliminares/tramites` + queryString;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSolicitudes(response.data));
        dispatch(setPreliminares(response.data));
      })
      .catch(err => {
        dispatch(setSolicitudes(err.data))
        dispatch(setPreliminares(err.data))
      });
  };
};

const setClavesCotizacion = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVECOTIZACION_SUCCESS,
      claves_cotizacion: null
    };
  }
  return {
    type: actionTypes.FETCH_CLAVECOTIZACION_SUCCESS,
    claves_cotizacion: data
  };
};

export const filterClavesCotizacion = (idTipoValor = null, tipoBono = null) => {
  let url = `${dominio}/emisoras/avisos-oferta-publica/claves-cotizacion`;
  url = idTipoValor !== null || tipoBono !== null ? `${url}?` : url;
  url = idTipoValor && idTipoValor !== "" ? `${url}&idTipoInstrumento=${idTipoValor}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesCotizacion(response.data));
      })
      .catch(err => dispatch(setClavesCotizacion(err.data)));
  };
};

const setTipoInstrumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPOINSTRUMENTOS_SUCCESS,
      instrumentos: null
    };
  }
  return {
    type: actionTypes.FETCH_TIPOINSTRUMENTOS_SUCCESS,
    instrumentos: data
  };
};

export const filterTipoIntrumentos = (idClaveCotizacion = null,tipoBono = null) => {
  let url = `${dominio}/emisoras/avisos-oferta-publica/tipos-instrumento`;
  url = idClaveCotizacion !== null || tipoBono !== null ? `${url}?` : url;
  url = idClaveCotizacion && idClaveCotizacion !== "" ? `${url}&claveCotizacion=${encodeURIComponent(idClaveCotizacion)}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoInstrumentos(response.data));
      })
      .catch(err => dispatch(setTipoInstrumentos(err.data)));
  };
};

const setSeries = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SERIE_SUCCESS,
      series: null
    };
  }
  return {
    type: actionTypes.FETCH_SERIE_SUCCESS,
    series: data
  };
};

export const filterSeries = (idClaveCotizacion = null, idTipoInstrumento = null,tipoBono = null) => {
  let url = `${dominio}/emisoras/avisos-oferta-publica/series`;
  url = idClaveCotizacion !== null || idTipoInstrumento !== null || tipoBono !== null ? `${url}?` : url;
  url = idClaveCotizacion && idClaveCotizacion !== "" ? `${url}&claveCotizacion=${encodeURIComponent(idClaveCotizacion)}` : url;
  url = idTipoInstrumento && idTipoInstrumento !== "" ? `${url}&idTipoInstrumento=${idTipoInstrumento}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSeries(response.data));
      })
      .catch(err => dispatch(setSeries(err.data)));
  };
};

const setSolicitudesDetalle = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SOLICITUDESDETALLE_SUCCESS,
      solicitudesDetalle: null
    };
  }
  return {
    type: actionTypes.FETCH_SOLICITUDESDETALLE_SUCCESS,
    solicitudesDetalle: data
  };
};

export const initSolicitudesDetalle = tramiteId => {
  let url = `${dominio}/emisoras/tramites/${tramiteId}/detalle`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSolicitudesDetalle(response.data));
      })
      .catch(err => dispatch(setSolicitudesDetalle(err.data)));
  };
};

const setDocumentosListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOSLISTADO_SUCCESS,
      documentos: []
    };
  }

  return {
    type: actionTypes.FETCH_DOCUMENTOSLISTADO_SUCCESS,
    documentos: data
  };
};

export const initDocumentosListado = (size, page, tramiteId, tipoDocumento, numEnvio = null, tipo = 1) => {
  let urlPath = `${dominio}/emisoras/tramites/${tramiteId}/documentos`;
    if (tipo === 2) {
      urlPath = `${dominio}/emisoras/prospectos-preliminares/tramites/${tramiteId}/documentos`;
  }
  const params = {
    size: size,
    page: page,
    idTipoDocumentoListado: tipoDocumento,
    numeroEnvio: numEnvio
  }

  const queryString = StringUtils.jsonToQueryString(params);
  return dispatch => {
    axios
      .get(urlPath + queryString)
      .then(response => {
        dispatch(setDocumentosListado(response.data));
      })
      .catch(err => dispatch(setDocumentosListado(err.data)));
  };
};

const setDdClavesCotizacion = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DDCLAVESCOTIZACION_SUCCESS,
      ddClavesCotizacion: null
    };
  }
  return {
    type: actionTypes.FETCH_DDCLAVESCOTIZACION_SUCCESS,
    ddClavesCotizacion: data
  };
};

export const filterDdClavesCotizacion = (tipoBono=null) => {
  let url = `${dominio}/emisoras/documentos-definitivos/claves`;
  url = tipoBono && tipoBono.length > 0
  ? `${url}?tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDdClavesCotizacion(response.data));
      })
      .catch(err => dispatch(setDdClavesCotizacion(err.data)));
  };
};

const setDdInstrumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DDINSTRUMENTO_SUCCESS,
      ddInstrumentos: null
    };
  }
  return {
    type: actionTypes.FETCH_DDINSTRUMENTO_SUCCESS,
    ddInstrumentos: data
  };
};

export const filterDdInstrumentos = (idClaveCotizacion, tipoBono=null) => {
  let url = `${dominio}/emisoras/documentos-definitivos/tipos-instrumento`;
  url = idClaveCotizacion ? `${url}?clave=${encodeURIComponent(idClaveCotizacion)}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDdInstrumentos(response.data));
      })
      .catch(err => dispatch(setDdInstrumentos(err.data)));
  };
};

const setDdTipoDocumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DDTIPODOCUMENTOS_SUCCESS,
      ddTipoDocumentos: null
    };
  }
  return {
    type: actionTypes.FETCH_DDTIPODOCUMENTOS_SUCCESS,
    ddTipoDocumentos: data
  };
};

export const filterDdTipoDocumentos = (
  claveCotizacionId = null,
  idTipoInstrumento = null,
  serie = null,
  claveWarrant = null,
  tipoBono = null
) => {
  console.log(serie);
  let url = `${dominio}/emisoras/documentos-definitivos/tipos-documento`;
  url = claveCotizacionId ? `${url}?clave=${encodeURIComponent(claveCotizacionId)}` : url;
  url = idTipoInstrumento ? `${url}&idTipoInstrumento=${idTipoInstrumento}` : url;
  url = serie ? `${url}&serie=${serie}` : url;
  url = claveWarrant ? `${url}&claveWarrant=${claveWarrant}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDdTipoDocumentos(response.data));
      })
      .catch(err => dispatch(setDdTipoDocumentos(err.data)));
  };
};

const setDdSerie = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DDSERIE_SUCCESS,
      ddSerie: null
    };
  }
  return {
    type: actionTypes.FETCH_DDSERIE_SUCCESS,
    ddSerie: data
  };
};

export const filterDdSerie = (idClaveCotizacion = null, idTipoInstrumento = null, claveWarrant = null, tipoBono = null) => {
  let url = `${dominio}/emisoras/documentos-definitivos/series`;
  url = idClaveCotizacion ? `${url}?clave=${encodeURIComponent(idClaveCotizacion)}` : url;
  url = idTipoInstrumento ? `${url}&idTipoInstrumento=${idTipoInstrumento}` : url;
  url = claveWarrant ? `${url}&claveWarrant=${claveWarrant}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDdSerie(response.data));
      })
      .catch(err => dispatch(setDdSerie(err.data)));
  };
};

const setDdWarrant = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DDCLAVESWARRANT_SUCCESS,
      ddClavesWarrant: null
    };
  }
  return {
    type: actionTypes.FETCH_DDCLAVESWARRANT_SUCCESS,
    ddClavesWarrant: data
  };
};

export const filterDdClavesWarrant = (idClaveCotizacion, idTipoInstrumento, tipoBono = null) => {
  let url = `${dominio}/emisoras/documentos-definitivos/warrants`;
  url = idClaveCotizacion ? `${url}?clave=${encodeURIComponent(idClaveCotizacion)}` : url;
  url = idTipoInstrumento ? `${url}&idTipoInstrumento=${idTipoInstrumento}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDdWarrant(response.data));
      })
      .catch(err => dispatch(setDdWarrant(err.data)));
  };
};

export const filterAvisosOfertasPublicas = ({ size, page, claveCotizacion = null, idTipoInstrumento = null, serie = null, tipoBono = null }) => {
  let url = `${dominio}/emisoras/avisos-oferta-publica?size=${size}&page=${page >= 0 ? page : 0}`;
  url = claveCotizacion && claveCotizacion !== "" ? `${url}&claveCotizacion=${encodeURIComponent(claveCotizacion)}` : url;
  url = idTipoInstrumento && idTipoInstrumento !== [""] ? `${url}&idTipoInstrumento=${idTipoInstrumento}` : url;
  url = serie && serie !== "" ? `${url}&serie=${serie}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAvisosOfertasPublicas(response.data));
      })
      .catch(err => dispatch(setAvisosOfertasPublicas(err.data)));
  };
};

const setAvisosOfertasPublicas = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SUCCESS,
      avisosOfertasPublicas: []
    };
  }
  return {
    type: actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SUCCESS,
    avisosOfertasPublicas: data
  };
};


export const searchAvisosOfertasPublicas = (size, page, palabraClave = null) => {
  let url = `${dominio}/emisoras/avisos-oferta-publica`;
  const params = {
    size: size,
    page: page,
    coincidencias: palabraClave
  }
  const queryParams = StringUtils.jsonToQueryString(params);


  return dispatch => {
    axios
      .get(url + queryParams)
      .then(response => {
        dispatch(setAvisosOfertasPublicasSearch(response.data));
      })
      .catch(err => dispatch(setAvisosOfertasPublicasSearch(err.data)));
  };
};

const setAvisosOfertasPublicasSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SUCCESS,
      avisosOfertasPublicasSearch: []
    };
  }
  return {
    type: actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SUCCESS,
    avisosOfertasPublicasSearch: data
  };
};

export const initDocumentosDefinitivos = (size, page) => {
  let url = `${dominio}/emisoras/documentos-definitivos/prev?size=${size}&page=${page}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDocumentosDefinitivos(response.data));
      })
      .catch(err => dispatch(setDocumentosDefinitivos(err.data)));
  };
};

const setDocumentosDefinitivos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOSDEFINITIVOS_SUCCESS,
      documentosDefinitivos: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOSDEFINITIVOS_SUCCESS,
    documentosDefinitivos: data
  };
};

export const filterDocumentosDefinitivos = ({
  size,
  page,
  idEmisora,
  idTipoInstrumento,
  serie,
  idTipoDocumentoListado,
  claveWarrant,
  tipoBono
}) => {
  let url = `${dominio}/emisoras/documentos-definitivos?size=${size}&page=${page >= 0 ? page : 0}`;
  url = idEmisora && idEmisora !== "" ? `${url}&clave=${encodeURIComponent(idEmisora)}` : `${url}&clave=${0}`;
  url = idTipoInstrumento && idTipoInstrumento !== "" ? `${url}&idTipoInstrumento=${idTipoInstrumento}` : url;
  url = serie && serie !== "" ? `${url}&serie=${serie}` : url;
  url =
    idTipoDocumentoListado && idTipoDocumentoListado.length > 0
      ? `${url}&idTipoDocumento=${[...idTipoDocumentoListado]}`
      : url;
  url = claveWarrant && claveWarrant !== "" ? `${url}&claveWarrant=${claveWarrant}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDocumentosDefinitivos(response.data));
      })
      .catch((err) => dispatch(setDocumentosDefinitivos(err.data)));
  };
};

const setProspectosColocacion = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PROSPECTOSCOLOCACION_SUCCESS,
      prospectos: []
    };
  }
  return {
    type: actionTypes.FETCH_PROSPECTOSCOLOCACION_SUCCESS,
    prospectos: data
  };
};

export const initProspectosColocacion = (size, page, mercado = null, instrumento = null, emisora = null, clave = null, tipoBono = null) => {
  let url = `${dominio}/emisoras/prospectos?size=${size}&page=${page >= 0 ? page : 0}`;
  url = mercado ? `${url}&idTipoMercado=${mercado}` : url;
  url = instrumento ? `${url}&idTipoInstrumento=${instrumento}` : url;
  url = clave ? `${url}&clave=${encodeURIComponent(clave)}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setProspectosColocacion(response.data));
      })
      .catch(err => dispatch(setProspectosColocacion(err.data)));
  };
};

const setDocumentosProspectos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOSPROSPECTOS_SUCCESS,
      documentosProspectos: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOSPROSPECTOS_SUCCESS,
    documentosProspectos: data
  };
};

export const initDocumentosProspectos = (size, page, emisoraId, tramiteId = null, tipoBono = null) => {
  let url = `${dominio}/emisoras/prospectos/${emisoraId}/documentos?size=${size}&page=${page}`;
  url = tramiteId ? `${url}&idTramite=${tramiteId}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDocumentosProspectos(response.data));
      })
      .catch((err) => dispatch(setDocumentosProspectos(err.data)));
  };
};

const setDetalleProspectos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DETALLEPROSPECTOS_SUCCESS,
      detalleProspectos: null
    };
  }
  return {
    type: actionTypes.FETCH_DETALLEPROSPECTOS_SUCCESS,
    detalleProspectos: data
  };
};

export const initDetalleProspectos = (emisoraId, tipoBono = null) => {
  let url = `${dominio}/emisoras/prospectos/${emisoraId}/detalle`;
  url = tipoBono && tipoBono.length > 0
  ? `${url}?tipoBonoAsg=${[...tipoBono]}`
  : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDetalleProspectos(response.data));
      })
      .catch((err) => dispatch(setDetalleProspectos(err.data)));
  };
};
// ========================================================

const setTipoDocumentoSolicitud = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SOLICITUDESTIPODOC_SUCCESS,
      tipoDocumento: []
    };
  }

  return {
    type: actionTypes.FETCH_SOLICITUDESTIPODOC_SUCCESS,
    tipoDocumento: data
  };
};

export const initTipoDocumentoSolicitud = tramiteId => {
  let url = `${dominio}/emisoras/tramites/${tramiteId}/tipo-documento-listado`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoDocumentoSolicitud(response.data));
      })
      .catch(err => dispatch(setTipoDocumentoSolicitud(err.data)));
  };
};
// ========================================================

const setNumeroEnvios = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SOLICITUDESTIPODOC_SUCCESS,
      numEnvios: []
    };
  }

  return {
    type: actionTypes.FETCH_SOLICITUDESTIPODOC_SUCCESS,
    numEnvios: data
  };
};

export const initNumeroEnvios = tramiteId => {
  let url = `${dominio}/emisoras/tramites/${tramiteId}/numero-envio`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setNumeroEnvios(response.data));
      })
      .catch(err => dispatch(setNumeroEnvios(err.data)));
  };
};


// ========================================================

const setColocaciones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_COLOCACIONESLISTADO_SUCCESS,
      colocaciones: []
    };
  }

  return {
    type: actionTypes.FETCH_COLOCACIONESLISTADO_SUCCESS,
    colocaciones: data
  };
};

export const getColocacionesListado = (params = null) => {
  const queryParams = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/cms/o/colocaciones` + queryParams;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setColocaciones(response.data));
      })
      .catch(err => dispatch(setColocaciones(err.data)));
  };
};


// ========================================================

// TIPOS BONOS

const setTiposBono = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPOS_BONO_PROSPECTOS_ASG,
      tiposBonoASG: []
    };
  }

  return {
    type: actionTypes.FETCH_TIPOS_BONO_PROSPECTOS_ASG,
    tiposBonoASG: data
  };
};

export const getTiposBono = () => {
  let url = `${dominio}/emisoras/prospectos/tipos-bono-asg`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTiposBono(response.data));
      })
      .catch(err => dispatch(setTiposBono(err.data)));
  };
};

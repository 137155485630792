import React, { Component } from "react";
import classes from "./Item.css";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";
import { getUrlDocument } from "../../../stores/utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;

class Item extends Component {
  componentDidMount(){
  }
  render() {
    let anio = null;
    let categoria = null;

    if (!this.props.es_evento) {
      anio = new Intl.DateTimeFormat("es-mx", { year: "numeric" }).format(
        new Date(this.props.fecha)
      );
      categoria = this.props.datos.category;
    }
    let mes = new Intl.DateTimeFormat("es-mx", { month: "short" }).format(
      new Date(this.props.fecha)
    );
    mes = mes.replace(".", "");

    const imageUrl = `${getUrlDocument(this.props.datos.pictureRelativeUrl)}`;
    let style = {
      backgroundImage: `url(${imageUrl})`
    };
    const item = (
      <div
        className={[
          classes.contenedor_item,
          "col-sm-12- col-md-12 col-lg-5 sin_padding"
        ].join(" ")}
      >
        <div onClick={(e) => this.props.eventSelected(this.props.id)} >
          <div className={[classes.contenedor_foto].join(" ")}>
            <div className="imagen">
              <div  style={style} className={[classes.imagen_noticia]} />
            </div>
            <div className={[classes.contenido_noticia, "row"].join(" ")}>
              <div className={[" text-center", classes.div_fecha].join(" ")}>
                <p className={classes.fecha_p}>
                  <span
                    className={[
                      classes.fecha,
                      "nunito color_azul texto_24 negrita text-center"
                    ].join(" ")}
                  >
                    <FormattedDate value={this.props.fecha} day="numeric" />
                  </span>
                </p>
                <p className={classes.fecha_p2}>
                  <span
                    className={[
                      classes.fecha,
                      "nunito color_azul texto_12 negrita text-center"
                    ].join(" ")}
                  >
                    {mes}
                  </span>
                </p>
                <p className={classes.fecha_p3}>
                  <span
                    className={[
                      classes.fecha,
                      "nunito color_azul texto_12 negrita text-center"
                    ].join(" ")}
                  >
                    {anio}
                  </span>
                </p>
              </div>
              <div className={[classes.div_noticia].join(" ")}>
                <span
                  className={[
                    classes.categoria,
                    "montse texto_12 color_azul"
                  ].join(" ")}
                >
                  {categoria}
                </span>
                <br />
                <span
                  className={[
                    classes.titulo,
                    "nunito texto_24 texto_izquierda negrita color_negro"
                  ].join(" ")}
                >
                  {this.props.datos.title}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return item;
  }
}

export default Item;

import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import classes from "./Tabs.css";
import Tabs from "../../../../components/ui/tabs/Tabs";
import Grafica from "../../../../components/ui/graficaHistoricoCasaBolsa/GraficaHistoricoCasaBolsa";
import { getLan } from "../../../../stores/utils/utilities";

let tabs = [
  {
    value: 0,
    image: "",
    icon: "",
    title: ""
  },
  {
    value: 1,
    image: "",
    icon: "",
    title: ""
  }
];

class CasasBolsa extends Component {
  state = {
    datos: null,
    tabs: null,
    tabSeleccionado: 0,
    tituloGrafica: null,
    tabs: tabs
  };

  componentDidMount() {
    this.setState({
      tituloGrafica: tabs[0].title
    });
    if (this.props.messages) {
      let tabs = [
        {
          value: 0,
          image: "",
          icon: "",
          title: this.props.messages ? this.props.messages["informacionmercado.historico.casasdebolsa.acumulada"] : ""
        },
        {
          value: 1,
          image: "",
          icon: "",
          title: this.props.messages ? this.props.messages["informacionmercado.historico.biva.participacion"] : ""
        }
      ];
      this.setState({ tabs: tabs, tituloGrafica: tabs && tabs.length > 0 ? tabs[0].title : "" });

    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.total !== prevProps.total) {
      this.setState({
        datos: this.props.total
      });
    }

    if (this.props.local !== prevProps.local) {
      this.setState({
        datos: this.props.local
      });
    }

    if (this.props.sic !== prevProps.sic) {
      this.setState({
        datos: this.props.sic
      });
    }
    if (this.props.messages !== prevProps.messages) {
      let tabs = [
        {
          value: 0,
          image: "",
          icon: "",
          title: this.props.messages ? this.props.messages["informacionmercado.historico.casasdebolsa.acumulada"] : ""
        },
        {
          value: 1,
          image: "",
          icon: "",
          title: this.props.messages ? this.props.messages["informacionmercado.historico.biva.participacion"] : ""
        }
      ];
      this.setState({ tabs: tabs, tituloGrafica: tabs && tabs.length > 0 ? tabs[0].title : "" });
    }
  }
  onChangeItemSelected = (index = 0) => {
    this.setState({
      tituloGrafica: this.state.tabs[index].title,
      tabSeleccionado: index
    });
  };

  render() {
    let tabMostrar = (
      <div className="margen_top_30">
        <div className={["row", classes.tabsContainer].join(" ")}>
          <div className={["texto_14 montse regular", classes.myCol].join(" ")}>
            {getLan() === "es" ? "Mostrar:" : "View:"}{" "}
          </div>
          <div className={["col", classes.myCol2].join(" ")}>
            <Tabs data={this.state.tabs} tipo={1} clicked={i => this.onChangeItemSelected(i)} />
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {tabMostrar}
        <div className="row margen_top_30">
          <Grafica
          key={this.state.tabSeleccionado}
            datos={this.props.biva}
            tituloGrafica={this.state.tituloGrafica}
            tabSeleccionado={this.state.tabSeleccionado}
            messages={this.props.messages}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    total: state.informacionMercado.historicoCasaBolsaTotal,
    sic: state.informacionMercado.historicoCasaBolsaSic,
    local: state.informacionMercado.historicoCasaBolsaLocal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    oninitHistoricoTotal: () => {
      dispatch(action.initHistoricoCasaBolsaTotal());
    },
    onInitCasaLocal: () => {
      dispatch(action.initHistoricoCasaBolsaLocal());
    },
    onInitCasaSic: () => {
      dispatch(action.initHistoricoCasaBolsaSic());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CasasBolsa);

import React from "react";
import NumberFormat from "react-number-format";
import { FormattedNumber, FormattedDate } from "react-intl";
import numeral from "numeral";

const local =
  localStorage.getItem("local") !== null
    ? localStorage.getItem("local")
    : localStorage.getItem("idioma") === null
      ? "es-MX"
      : localStorage.getItem("idioma") === "es"
        ? "es-mx"
        : "en-US";

export const getLocal = () => {
  return local;
};
export const getUrlDocument = (url, descarga = false, forceDownload = false) => {
  if (url === undefined || url === null) {
    return "";
  }
  let path = url;
  if (path !== "/") {
    path = url;
    let liga_pagina = false;
    if (path.includes("http")) {
      liga_pagina = true;
    }
  }

  if (descarga) {
    if (path.includes("/cms/")) {
      if (path.includes("?")) {
        path = `${path}&download=true`;
      } else {
        path = `${path}?download=true`;
      }
    }
  }
  if (forceDownload) {
    if (path.includes("?")) {
      path = `${path}&download=true`;
    } else {
      path = `${path}?download=true`;
    }
  }

  return path;
};
export const getTypeLink = (url) => {
  let type = "_self"
  if (url === undefined || url === null) {
    return "";
  }
  let path = url;
  let liga_pagina = false;
  if (path !== "/") {
    path = url;
    if (path.includes("http")) {
      liga_pagina = true;
    }
  }
  if (liga_pagina) {
    type = "_blank"
  }

  return type;
};
export const getKeyCaptcha = () => {
  const key = process.env.REACT_APP_KEY_GOOGLE_CAPTCHA;
  return key;
};

export const getKeyYoutube = () => {
  const key = process.env.REACT_APP_KEY_YOUTUBE;
  return key;
};

export const getLan = () => {
  let language = localStorage.getItem("idioma");

  if (language === null) {
    localStorage.setItem("idioma", "es");
    localStorage.setItem("local", "es-MX");
    language = "es";
  }
  return language;
};

export const updateObject = (oldProperties, newProperties) => {
  return {
    ...oldProperties,
    ...newProperties
  };
};

export const getInitDate = (days = 30, fecha_inicio = null, fecha_fin = null) => {
  const initDate = new Date();
  const endDate = new Date();
  let fini = null;
  let ffin = null;

  if (fecha_inicio) {
    fini = new Date(fecha_inicio);
    fini = fini.setDate(fini.getDate() - days);
    console.log(fini);
  } else {
    initDate.setDate(initDate.getDate() - days);
    fini = initDate.toISOString().slice(0, 10);
  }

  if (fecha_fin) {
    ffin = new Date(fecha_fin);
  } else {
    ffin = endDate.toISOString().slice(0, 10);
  }
  return { inicio: fini, fin: ffin };
};

export const getRealDate = (date = null) => {
  let dateReturn = null;
  if (date) {
    dateReturn = new Date(date + "T00:00:00-06:00").toDateString("yyyy-MM-dd");
  }
  return dateReturn;
};

export const getRealDateMillies = (date = null) => {
  let dateReturn = null;
  if (date) {
    dateReturn = new Date(date).toDateString("yyyy-MM-dd");
  }
  return dateReturn;
};

export const getRealDateMilliesDayMonthYear = (date = null) => {
  let dateReturn = null;
  if (date) {
    dateReturn = new Date(date).toLocaleString("en-GB").split(",")[0];
  }
  return dateReturn;
};

export const getRealDateMilliesDayMonthYearLocale = (date = null) => {
  let dateReturn = null;
  if (date) {
    dateReturn = new Date(date).toISOString().split('T')[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
  }
  return dateReturn;
};

export const getRealDateMilliesHourMinSeg = (date = null) => {
  let dateReturn = null;
  if (date) {
    dateReturn = new Date(date).toLocaleString("en-GB").split(",")[1];
  }
  return dateReturn;
};

export const getRealDateMilliesDayMonthYearTimeZone = (date = null) => {
  let dateReturn = null;
  let hour = null;
  let min = null;
  if (date !== null && date !== undefined) {
    dateReturn = new Date(date).toLocaleString("en-GB").split(",")[0];
    hour = new Date(date)
      .toLocaleString("en-GB")
      .split(",")[1]
      .split(":")[0];
    min = new Date(date)
      .toLocaleString("en-GB")
      .split(",")[1]
      .split(":")[1];
    dateReturn = dateReturn + " " + hour + ":" + min;
  }
  return dateReturn;
};

export const getRealDateIgnoreTimeIfEmpty = (date = null, idTipoDocumentoASG = null) => {
  let dateReturn = getRealDateMilliesDayMonthYearTimeZone(date);
  if(dateReturn !== null && dateReturn.includes('00:00') || idTipoDocumentoASG === 2){
    dateReturn = dateReturn.substring(0, dateReturn.length - 7);
   }
  return dateReturn;
};

export const changeDateFormat = (fechaInicio = null, fechaFin = null) => {
  const fecha_inicio = fechaInicio
    ? fechaInicio
      .split("-")
      .reverse()
      .join("-")
    : null;
  const fecha_fin = fechaFin
    ? fechaFin
      .split("-")
      .reverse()
      .join("-")
    : null;
  return [fecha_inicio, fecha_fin];
};

export const formatDate = (date = null) => {
  if (date) {
    // yyyy-mm-dd => dd/mm/yyyy
    let dArr = date.split("-"); // example input "2010-01-18"
    return dArr[2] + "/" + dArr[1] + "/" + dArr[0].substring(0, 4); //example out: "18/01/10"
  }
  return "";
};
export const formatDateReverse = (date = null) => {
  if (date) {
    // dd/mm/yyyy => yyyy-mm-dd 
    let dArr = date.split("/"); // example input "2010-01-18"
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0].substring(0, 4); //example out: "2020/12/31"
  }
  return "";
};

export const formatDateReverse2 = (date = null) => {
  if (date) {
    // mm/dd/yyyy => yyyy-mm-dd 
    let dArr = date.split("/"); // example input "2010-18-01"
    return dArr[2].substring(0, 4) + "-" + dArr[1] + "-" + dArr[0]; //example out: "2020-12-31"
  }
  return "";
};

export const localDateMillies = (date = null) => {
  let fecha = date;
  if (date === null) {
    fecha = new Date();
  } else {
    fecha = new Date(date);
  }
  return fecha.toLocaleString();
};

export const formatDecimal = value => {
  return <NumberFormat value={value} thousandSeparator={true} displayType={"text"} decimalScale={2} fixedDecimalScale={2} />;
};

export const formatNumber = value => {
  return <NumberFormat value={value} thousandSeparator={true} displayType={"text"} decimalScale={2} fixedDecimalScale={0} />;
};

export const formatInt = value => {
  return <NumberFormat value={value} thousandSeparator={true} displayType={"text"} />;
};

export const formatNumberDecimal6 = value => {
  return <NumberFormat value={value} thousandSeparator={true} displayType={"text"} decimalScale={6} fixedDecimalScale={1} />;
};

export const formatPercentage = value => {
  let valor = value / 100;
  return (
    <NumberFormat
      value={valor}
      thousandSeparator={true}
      displayType={"text"}
      decimalScale={2}
      fixedDecimalScale={2}
      suffix={"%"}
    />
  );
};

export const formatDateDiaMes = date => {
  let fechaReturns = "";
  if (date === null || date === undefined) {
    fechaReturns = " ";
  } else {
    let mes = new Intl.DateTimeFormat(local, { month: "short" }).format(date);
    mes = mes.replace(".", "");
    let dia = new Intl.DateTimeFormat(local, { day: "2-digit" }).format(date);
    fechaReturns = dia + " " + mes;
  }
  return fechaReturns;
};

export const formatDateDiaMesAno = date => {
  let fechaReturns = "";
  if (date === null || date === undefined) {
    fechaReturns = " ";
  } else {
    let mes = new Intl.DateTimeFormat(local, { month: "long" }).format(date);
    mes = mes.replace(".", "");
    let dia = new Intl.DateTimeFormat(local, { day: "2-digit" }).format(date);
    let ano = new Intl.DateTimeFormat(local, { year: "numeric" }).format(date);
    fechaReturns = dia + " de " + mes + " " + ano;
  }
  return fechaReturns;
};

export const formatDate_ddMMyyy_millies = (value, tipo = null) => {
  let date = null;
  if (tipo === null) {
    date = <FormattedDate value={new Date(value)} year="numeric" month="long" day="2-digit" />;
  } else if (tipo === 1) {
    date = <FormattedDate value={new Date(value + "T00:00:00-06:00")} year="numeric" month="long" day="2-digit" />;
  } else {
    date = <FormattedDate value={new Date(value + "T00:00:00-12:00")} year="numeric" month="long" day="2-digit" />;
  }

  // let mes = new Intl.DateTimeFormat(local, { month: "short" }).format(date);
  // mes = mes.replace(".", "");
  // let dia = new Intl.DateTimeFormat(local, { day: "2-digit" }).format(date);
  return date;
};

export const getMonth = month => {
  month = month.toString().toLowerCase();
  switch (month) {
    case "ene":
      return "01";
    case "feb":
      return "02";
    case "mar":
      return "03";
    case "abr":
      return "04";
    case "may":
      return "05";
    case "jun":
      return "06";
    case "jul":
      return "07";
    case "ago":
      return "08";
    case "sep":
      return "09";
    case "oct":
      return "10";
    case "nov":
      return "11";
    case "dic":
      return "12";
    default:
      return "01";
  }
};

export const getDateForMonth = (mes, anio) => {
  let date = new Date();
  date.setFullYear(parseInt(anio), parseInt(mes) - 1, 1);
  date = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  );


  return date;
};

export const setColorIndice = value => {
  let number = parseFloat(value);
  if (number > 0) {
    return "texto_verde";
  } else if (number < 0) {
    return "texto_rojo";
  } else {
    return "color_gris";
  }
};

export const setColorEstatus = value => {
  if (value === "Cancelada") {
    return "texto_rojo";
  }
  if (value === "Listado preventivo") {
    return "texto_amarillo";
  } else {
    return null;
  }
};

export const setFormatCurrency = value => {
  return <FormattedNumber currency="USD" value={value} />;
};

export const setFormatCurrencyString = value => {
  return value === undefined
    ? "" // make controlled
    : numeral(value).format("$0,0.00");
};

export const setFormatCurrencyStringTwo = value => {
  return value === null || value === undefined
    ? "" // make controlled
    : numeral(value).format("$ 0,0.00");
};

export const setFormatCurrencyStringThree = value => {
  return value === null || value === undefined
    ? "" // make controlled
    : numeral(value).format("$0,0.00");
};
export const setFormatCurrencyStringOrZero = value => {
  return value === null || value === undefined
    ? "$0.00" // make controlled
    : numeral(value).format("$0,0.00");
};

export const setFormatNumberString = value => {
  return value === undefined
    ? "" // make controlled
    : numeral(value).format("0,0.00");
};

export const setFormatNumberIntString = value => {
  return value === undefined
    ? ""
    : numeral(value).format("#,###");
};

export const setFormatPercentString = value => {
  let value_percent = value / 100;

  return value_percent === undefined
    ? "" // make controlled
    : numeral(value_percent).format("0[.]00%");
};

export const setFormatPercent = value => {
  let value_percent = value / 100;
  return <FormattedNumber value={value_percent} style={`percent`} minimumFractionDigits={2} />;
};

export const setSigno = value => {
  let signo = null;
  if (value > 0.1) {
    signo = "+";
  }
  //  else if (value < 0) {
  //   signo = "-";
  // } else {
  //   signo = "";
  // }

  return signo;
};

export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  var zoneNumber = (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2)
  return zoneNumber;
}

export const sumaMes = (fecha, meses) => {
  var e = new Date(fecha);
  //se le suma la zona horaria para obtener el dia correcto
  e.setMinutes(e.getMinutes() + e.getTimezoneOffset())
  e.setMonth(e.getMonth() + meses)
  return e;
}

export const restaMes = (fecha, meses) => {
  //no se le suma, se le resta zona horaria para obtener dia correcto
  var e = new Date(fecha);
  e.setMonth(e.getMonth() - meses)
  return e;
}

export const sumaAnio = (fecha, anios) => {
  var e = new Date(fecha);
  //se le suma la zona horaria para obtener el dia correcto
  e.setFullYear(e.getFullYear() + anios)
  return e;
}

export const restaAnio = (fecha, anios) => {
  //no se le suma, se le resta zona horaria para obtener dia correcto
  var e = new Date(fecha);
  e.setMinutes(e.getMinutes() - e.getTimezoneOffset())

  e.setFullYear(e.getFullYear() - anios)
  return e;
}

export const sumaDia = (fecha, dia) => {
  var e = new Date(fecha);
  //se le suma la zona horaria para obtener el dia correcto
  e.setMinutes(e.getMinutes() + e.getTimezoneOffset())
  e.setDate(e.getDate() + dia)
  let anio = e.getFullYear();
  let mes = (e.getMonth() + 1) < 10 ? "0" + (e.getMonth() + 1) : (e.getMonth() + 1);
  let day = e.getDate() < 10 ? "0" + e.getDate() : e.getDate();
  return anio + "-" + mes + "-" + day;
}

export const validateLimitDateXMonths = (fecha_inicio, fecha_fin, meses) => {
  var fecha_min = new Date(fecha_fin);
  fecha_min.setMinutes(fecha_min.getMinutes() + fecha_min.getTimezoneOffset())
  fecha_min.setMonth(fecha_min.getMonth() - meses)
  fecha_min = getRealDateMilliesDayMonthYear(fecha_min);
  fecha_min = formatDateReverse2(fecha_min);
  if (fecha_inicio < fecha_min) { // 
    return true; //fecha no válida , mayor a 6 meses de antigüedad
  } else {
    return false; //fecha válida , NO mayor a 6 meses de antigüedad
  }
}

export const validateLimitDateXYears = (fecha_inicio, years) => {
  //se le suma zona horaria para obtener dia correcto
  var fecha_min = new Date();
  // fecha_min.setMonth(fecha_min.getMonth() - meses);
  fecha_min.setMinutes(fecha_min.getMinutes() + fecha_min.getTimezoneOffset())
  fecha_min.setFullYear(fecha_min.getFullYear() - years)
  fecha_min = getRealDateMilliesDayMonthYear(fecha_min);
  fecha_min = formatDateReverse2(fecha_min);
  if (fecha_inicio < fecha_min) {
    return true; //fecha no válida , mayor a 5 años de antigüedad
  } else {
    return false; //fecha válida , NO mayor a 5 años de antigüedad
  }

}

export const valueParamValidation = (paramsString, value, isMultiple = false, isInt = false) => {
  let validatedValue = null;
  if (isInt){
    validatedValue = paramsString[value] === "null" || paramsString[value] === "" || paramsString[value] === undefined ? null : parseInt(paramsString[value].split(","));
  }else if (isMultiple) { //Si tiene que regresar un valor de un array
    validatedValue = paramsString[value] === "null" || paramsString[value] === undefined ? null : paramsString[value].split(",");
  } else {
    validatedValue = paramsString[value] !== undefined && paramsString[value] !== "null" && paramsString[value] !== "undefined" ? paramsString[value] : null;
  }
  return validatedValue;
}

export const valueParamBooleanValidation = (paramsString, value) => {
  let validatedValue = null;

  //Si tiene que regresar un valor nulo
  validatedValue = paramsString[value] !== undefined && paramsString[value] !== "null" ? paramsString[value] : false;
  return validatedValue;
}

export const valueParamPageValidation = (paramsString, value) => {
  let validatedValue = paramsString[value] !== undefined && paramsString[value] !== "null" ? parseInt(paramsString[value]) : 1;
  return validatedValue;
}

export const getIndices = () => {
  return ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
}

export const valueLanguage = (lan, esValue, enValue) => {
  return lan === "es" ? esValue : enValue;
}

export const valueValidation = (value, returnValue, isArray = false) => {
  let validatedValue = null;
  if (returnValue) {
    validatedValue = value ? returnValue : null;
  } else if (isArray) { //Si tiene que regresar un valor de un array
    validatedValue = value ? value : [];
  } else {
    validatedValue = value ? value : null;
  }
  return validatedValue;
}

export const valueValidationZero = (value, returnValue = false) => {
  let validatedValue = null;
  if (returnValue) {
    validatedValue = value && value.length > 0 ? returnValue : null;
  } else {
    validatedValue = value && value.length > 0 ? value : null;
  }
  return validatedValue;
}
import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../../stores/actions/index";

import classes from "./Item.css";
import Auxs from "../../../../../hoc/auxs/Auxs";
import {
  getLocal,
  getUrlDocument,
} from "../../../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import Pagination from "../../../../../components/ui/pagination/Pagination";

class Item extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    itemSelected: null,
    itemsPerPage: 9,
    activePage: 1,
    lista_ver: [],
  };
  componentDidMount() {
    if (this.props.datos) {
      this.setState({
        lista_ver: this.props.datos,
        itemSelected: this.props.datos[0],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.datos !== prevProps.datos) {
      let lista_ver = [];
      let index_final = this.state.itemsPerPage;
      let index_inicial = 0;
      lista_ver = this.props.datos.slice(index_inicial, index_final);
      this.setState({
        lista_ver: lista_ver,
        itemSelected: this.props.datos[0],
      });
    }
  }

  changeCurrentVideo = (video) => {
    this.setState({ itemSelected: video });
    let path =
      this.props.history.location.pathname + this.props.history.location.search;
    this.props.history.push(path);
  };
  changeCurrentItem = (video) => {
    this.setState({ itemSelected: video });
    let path =
      this.props.history.location.pathname + this.props.history.location.search;
    this.props.history.push(path);
  };

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      let lista_ver = [];
      let index_final = pageNumber * this.state.itemsPerPage;
      let index_inicial = index_final - this.state.itemsPerPage;
      lista_ver = this.props.datos.slice(index_inicial, index_final);
      this.setState({
        activePage: pageNumber,
        lista_ver: lista_ver,
      });
    }
  }

  render() {
    let thumbnails = null;
    let visualization = null;

    let paginador = (
      <div className={"col-12 sin_padding"}>
        <Pagination
          claseTipo={2}
          numRows={this.props.datos ? this.props.datos.length : 0}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </div>
    );

    if (this.props.tipo_imagen === 1) {
      //videos
      let videoId = null;
      if (this.state.itemSelected) {
        videoId =
          this.state.itemSelected.snippet !== null &&
          this.state.itemSelected.snippet !== undefined
            ? this.state.itemSelected.snippet.resourceId.videoId
            : null;
      }

      visualization = (
        <iframe
          width="100%"
          height="415"
          src={"https://www.youtube.com/embed/" + videoId}
          frameBorder="0"
          title="item"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      );

      if (this.state.lista_ver.length > 0) {
        thumbnails = this.state.lista_ver.map((video) => {
          if (video.snippet.thumbnails.default === undefined) {
            return <></>;
          }
          return (
            <div
              className={["col-12 col-sm-6 col-md-4"].join(" ")}
              onClick={this.changeCurrentVideo.bind(this, video)}
            >
              <div className={[classes.containerDiv].join(" ")}>
                <div className={["row", classes.container].join(" ")}>
                  <div
                    className={[
                      " sin_padding col-12 sin_padding",
                      classes.videoContainer,
                    ].join(" ")}
                  >
                    <div className={classes.thumbanil}>
                      <div
                        className={
                          video.snippet.thumbnails &&
                          video.snippet.thumbnails.medium !== undefined
                            ? "d-none"
                            : video.snippet.thumbnails.default !== undefined
                            ? "d-none"
                            : null
                        }
                      >
                        {" "}
                      </div>
                      <img
                        className={
                          video.snippet.thumbnails &&
                          video.snippet.thumbnails.medium !== undefined
                            ? null
                            : video.snippet.thumbnails.default !== undefined
                            ? null
                            : "d-none"
                        }
                        src={
                          video.snippet.thumbnails &&
                          video.snippet.thumbnails.medium !== undefined
                            ? video.snippet.thumbnails.medium.url
                            : video.snippet.thumbnails.default !== undefined
                            ? video.snippet.thumbnails.default.url
                            : null
                        }
                        alt={video.snippet.title}
                      />
                    </div>
                  </div>
                  <div
                    className={[
                      " sin_padding col-12 sin_padding",
                      classes.textoVideoContainer,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        " sin_padding",
                        classes.contenedorTitulo,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          classes.titulo_video,
                          "color_azul montse texto_18",
                        ].join(" ")}
                      >
                        {video.snippet.title}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      }
    }

    if (this.props.tipo_imagen === 2) {
      //Imagenes
      let urlImage = null;
      if (this.state.itemSelected) {
        urlImage = getUrlDocument(this.state.itemSelected.imagen_portada);
        visualization = (
          <img
            src={urlImage}
            alt={this.state.itemSelected.titulo}
            className={classes.image}
          />
        );
      }
      if (this.state.lista_ver.length > 0) {
        thumbnails = this.state.lista_ver.map((video) => {
          const urlImageCover = getUrlDocument(video.imagen_portada);
          if (!video.imagen_portada) {
            return null;
          }
          return (
            <div
              className={["col-12 col-sm-6 col-md-4"].join(" ")}
              onClick={this.changeCurrentItem.bind(this, video)}
            >
              <div className={[classes.containerDiv].join(" ")}>
                <div className={["row", classes.container].join(" ")}>
                  <div
                    className={[
                      " sin_padding col-12 sin_padding",
                      classes.videoContainer,
                    ].join(" ")}
                  >
                    <div className={classes.thumbanil}>
                      <img src={urlImageCover} alt={video.titulo} />
                    </div>
                  </div>
                  <div
                    className={[
                      " sin_padding col-12 sin_padding",
                      classes.textoVideoContainer,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        " sin_padding",
                        classes.contenedorTitulo,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          classes.titulo_video,
                          "color_azul montse texto_18",
                        ].join(" ")}
                      >
                        {video.titulo}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      }
    }
    if (this.props.tipo_imagen === 3) {
      //Documentos
      let urlImage = null;
      if (this.state.itemSelected) {
        urlImage = getUrlDocument(this.state.itemSelected.url_descarga);

        visualization = (
          <iframe
            className={classes.pdf}
            src={urlImage}
            frameborder="0"
            title="pdf"
          />
        );
      }

      if (this.state.lista_ver.length > 0) {
        thumbnails = this.state.lista_ver.map((video) => {
          const urlImageCover = getUrlDocument(video.imagen_portada);
          if (!video.imagen_portada) {
            return null;
          }
          return (
            <div
              className={["col-12 col-sm-6 col-md-4"].join(" ")}
              onClick={this.changeCurrentItem.bind(this, video)}
            >
              <div className={[classes.containerDiv].join(" ")}>
                <div className={["row", classes.container].join(" ")}>
                  <div
                    className={[
                      " sin_padding col-12 sin_padding",
                      classes.videoContainer,
                    ].join(" ")}
                  >
                    <div className={classes.thumbanil}>
                      <img src={urlImageCover} alt={video.titulo} />
                    </div>
                  </div>
                  <div
                    className={[
                      " sin_padding col-12 sin_padding",
                      classes.textoVideoContainer,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        " sin_padding",
                        classes.contenedorTitulo,
                      ].join(" ")}
                    >
                      <span
                        className={[
                          classes.titulo_video,
                          "color_azul montse texto_18",
                        ].join(" ")}
                      >
                        {video.titulo}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
      }
    }
    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <Auxs>
          <div className="row">
            <div className="col-12 sin_padding">
              <div>
                <h1
                  className={[classes.main_title, "texto_42", "nunito"].join(
                    " "
                  )}
                >
                  <FormattedMessage
                    id="institutobiva.bivateca.videos.titulo1"
                    defaultMessage=" "
                  />
                </h1>
              </div>
            </div>
            <div className="margin_top_30 width_cien" id="item">
              {visualization}
            </div>
            <div
              className={["row  relativo margin_top_30", classes.fondo].join(
                " "
              )}
            >
              {thumbnails}
              {paginador}
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    videos: state.videos.videos_instituto,
    diccionario: state.intl.diccionario,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";
const initialState = {
    indices: null,
    palabras: null,
    palabrasFilter:null
};

const setDiccionario = (state, action) => {
    return updateObject(state, action);
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INDICES_DICCIONARIO_SUCCESS:
            return setDiccionario(state, action);
        case actionTypes.FETCH_PALABRAS_SUCCESS:
            return setDiccionario(state, action);
        default:
            return state;
    }
};

export default reducer;
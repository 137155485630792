import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";

import FilterListadoColocaciones from "../../../components/ui/filterListadoColocaciones/FilterListadoColocaciones";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";

class Colocaciones extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    clavePizarra: null,
    emisor: null,
    wasFiltered: false,
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl(94);
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }

    if (prevProps.avisos_listados !== this.props.avisos_listados) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let avisos_listados = null;

    if (nextProps.avisos_listados && nextProps.avisos_listados.content) {
      avisos_listados = nextProps.avisos_listados.content;
    }
    let resource = avisos_listados;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.avisos_listados;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
      this.changeHistory(
        this.state.clavePizarra,
        this.state.emisor,
        pageNumber
      );
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let clavePizarra =
      paramsString["clavePizarra"] !== undefined && paramsString["clavePizarra"] !== "null"
        ? paramsString["clavePizarra"]
        : null;
    let emisor =
      paramsString["emisor"] !== undefined && paramsString["emisor"] !== "null" ? paramsString["emisor"] : null;
    
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    let params = {
      size: this.state.itemsPerPage,
      page: parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      clavePizarra: clavePizarra,
      emisor: emisor,
      lang: getLan()
    };
    this.props.getColocaciones(params);

    this.setState({
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
      clavePizarra: clavePizarra,
      emisor: emisor,
      loader: true
    });
  }

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered,
      loader: true
    });
  };
  onSetParams = ({clavePizarra, emisor, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(clavePizarra, emisor, page);
    }
  };

  changeHistory(clavePizarra = null, emisor = null, page = null) {

    this.props.history.push(
      `?clavePizarra=${clavePizarra}&emisor=${emisor}&page=${
        page ? page : this.state.activePage
      }`
    );

    this.setState({
      loader: true,
      clavePizarra: clavePizarra,
      emisor: emisor
    });
    const params = {
      size: this.state.itemsPerPage,
      page: page > 0 ? page - 1 : 0,
      clavePizarra: clavePizarra,
      emisor: emisor,
      lang: getLan()
    };

    this.props.getColocaciones(params);

  }

  changeHistoryReset() {
    this.props.history.push(`?clavePizarra=${null}&emisor=${null}&page=1`);

    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      clavePizarra: null,
      emisor: null,
      lang: getLan(),
    };

    this.setState({
      loader: true,
      clavePizarra: null,
      emisor: null
    });

    this.props.getColocaciones(params);

  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let contenidoMostrar = null;
    let avisos_listados = null;
    if (this.props.avisos_listados && this.props.avisos_listados.content) {
      avisos_listados = this.props.avisos_listados.content;
      const numRows = this.props.avisos_listados ? this.props.avisos_listados.totalElements : 0;

      contenidoMostrar = (
        <React.Fragment>
          <Accordion datos={avisos_listados} nombre="avisos_listados" icon={""} tipo={12} subtipo={14} />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );
    } else if (this.state.wasFiltered) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda.": "No results found."}</p>;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}

          <h1 className={["nunito", "texto_42"].join(" ")}>
            <FormattedMessage id="colocaciones.titulo" defaultMessage=" " />
          </h1>
          <br />
          <FilterListadoColocaciones
            setPage={page => this.onSetPage(page)}
            setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
            setParams={filterParams => this.onSetParams(filterParams)}
          />
          <div className="margen_50 margin_bottom_200">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getColocaciones: params => {
      dispatch(actions.getColocacionesListado(params));
    },
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    }
  };
};

const mapStateToProps = state => {
  return {
    avisos_listados: state.listadoValores.colocaciones,
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Colocaciones);

import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getInitDate } from "../utils/utilities";
import { getLan } from "../utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setInitSalaPrensa = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SALAPRENSA_SUCCESS,
      salaPrensa: []
    };
  }
  return {
    type: actionTypes.FETCH_SALAPRENSA_SUCCESS,
    salaPrensa: data
  };
};

export const initSalaPrensa = (fecha_inicio = null, fecha_fin = null) => {
  const from = fecha_inicio; 
  const to = fecha_fin ? fecha_fin : new Date().toISOString().slice(0, 10);
  
  let url = dominio + "/cms/o/content/pressreleases/lang/" + lang ;//+ "?from=" + from + "&to=" + to;

  url = from ? url.includes("?") ? `${url}&from=${from}` : `${url}?from=${from}` : url;
  url = to ? url.includes("?") ? `${url}&to=${to}` : `${url}?to=${to}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInitSalaPrensa(response.data));
      })
      .catch(err => dispatch(setInitSalaPrensa(err.data)));
  };
};

const setInitSalaPrensaById = salaPrensaUniq => {
  return {
    type: actionTypes.FETCH_SALAPRENSABYID_SUCCESS,
    salaPrensaUniq: salaPrensaUniq
  };
};

export const initSalaPrensaById = id => {
  let url = dominio + "/cms/o/content/pressreleases/" + id + "/lang/" + lang;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInitSalaPrensaById(response.data));
      })
      .catch(err => console.log(err));
  };
};

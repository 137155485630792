import React, { Component } from "react";
import classes from "./UltimoMes.css";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";

import Auxs from "../../../../hoc/auxs/Auxs";
import Tabs from "../../../../components/ui/tabs/Tabs";
import Modal from "../../../../components/ui/modal/Modal";
import BackDrop from "../../../../components/ui/backdrop/BakcDrop";
import Boton from "../../../../components/ui/boton/Boton";

import CasasBolsas from "./tabs/CasasBolsa";
import Biva from "./tabs/Biva";
import EmisorasOperadas from "./tabs/EmisorasOperadas";
import Box from "../../../../components/ui/box/Box";

import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan, getUrlDocument } from "../../../../stores/utils/utilities";

class UltimoMes extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    tab: 0,
    showModal: false,
    tabs_informacion: [
      {
        image: "biva_v.svg",
        icon: "biva_v.svg",
        title: ""
      },
      {
        image: "casas_bolsa.svg",
        icon: "casas_bolsa.svg",
        title: ""
      },
      {
        image: "doc.svg",
        icon: "doc.svg",
        title: ""
      }
    ]
  };
  componentDidMount() {
    this.props.onInitIntl();
    this.props.onDocumento();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
          tabs_informacion: [
            {
              image: "biva_v.svg",
              icon: "biva_v.svg",
              title: messages["informacionmercado.ultimomes.biva"]
            },
            {
              image: "casas_bolsa.svg",
              icon: "casas_bolsa.svg",
              title: messages["informacionmercado.ultimomes.casasbolsa"]
            },
            {
              image: "doc.svg",
              icon: "doc.svg",
              title: messages["informacionmercado.ultimomes.masoperadas"]
            }
          ]
        });
      }
    }
  }

  onChangeItemSelected = (index = 0) => {
    let tab = 0;
    tab = index;
    if (index === 0) {
      //CasasBolsas
    } else if (index === 1) {
      //biva
    } else {
      //reporte
    }
    this.setState({
      tab: tab
    });
  };

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  render() {
    let ruta = null;

    const tabMostrar = (
      <div className="row margen_top_30">
        <div className={["col-sm-12 sin_padding_L sin_padding_R", classes.myCol].join(" ")}>
          <Tabs data={this.state.tabs_informacion} tipo={3} clicked={i => this.onChangeItemSelected(i)} />
        </div>
      </div>
    );

    let tabSeleccionado = null;
    if (this.state.tab === 0) {
      tabSeleccionado = <Biva />;
    } else if (this.state.tab === 1) {
      tabSeleccionado = <CasasBolsas />;
    } else {
      tabSeleccionado = <EmisorasOperadas />;
    }

    const modal = (
      <React.Fragment>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} onCloseModal={this.onToggleModalHandler} area={"operations"} />
      </React.Fragment>
    );

    let ruta_btn = "/cms/o/operaciones/reportes/mensual/biva";
    let ruta_url = "/cms/o/statistics/ultimo-mes/biva/csv ";
    let show = true;

    if (this.state.tab === 1) {
      //Casa de bolsa
      ruta_btn = "/cms/o/operaciones/reportes/mensual/casadebolsa";

    }
    if (this.state.tab === 2) {
      show = false;
      ruta_url = "/cms/o/statistics/ultimo-mes/emisoras/csv";
    }
    let btnDescarga = null;

    if (show) {
      btnDescarga = (
        <Boton
          tipo={6}
          texto={this.state.messages ? this.state.messages["informacionmercado.ultimomes.btn2"] : ""}
          icono_derecho="fas fa-long-arrow-alt-right"
          colorBoton={"blanco"}
          ruta={ruta_btn}
        />
      );
    }

    let box = null;
    ruta = getUrlDocument(ruta_url);
    if (ruta.includes("?")) {
      ruta = `${ruta}&download=true`;
    } else {
      ruta = `${ruta}?download=true`;
    }

    const box2 = {
      leftIcon: "far fa-file-excel",
      title: getLan() === "es" ? "Descargar operación último mes" : "Download Last month trading",
      rightIcon: "fas fa-arrow-down",
      url: ruta
    };

    box = <Box key={"enlace"} modelo="modelo_1" data={box2} formato="XLS" />;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.ultimomes.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>

          <div className=" margen_top_50">{tabMostrar}</div>
          <div className=" margen_top_100">{tabSeleccionado}</div>

          <div className="row margen_top_50 margin_bottom_300">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={""}>
                  <FormattedHTMLMessage id="informacionmercado.ultimomes.personalizado" defaultMessage=" " />
                </h2>
              </div>
            </div>

            <div className="row margen_top_30">
              <Boton
                onClick={this.onToggleModalHandler.bind(this)}
                tipo={4}
                texto={this.state.messages ? this.state.messages["informacionmercado.ultimomes.btn1"] : ""}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />

              {btnDescarga}
            </div>
          </div>

          {modal}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    doc: state.documentos.ultimoMes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(50));
    },
    onDocumento: () => {
      dispatch(action.initUltimoMesDocumento());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UltimoMes);

import React, { Component } from "react";
import { formatDate, getUrlDocument } from "../../../../stores/utils/utilities";
import classes from "./Content.css";
import textura from "../../../../assets/images/textura.png"

const dominio = process.env.REACT_APP_DOMINIO;

class Content extends Component {
  state = {
    loader: false,
    eventos: null,
  };

  render() {
    const blogProps = this.props.blog;
    const ruta = getUrlDocument(blogProps.urlImagenMiniatura);
    let style = {
      backgroundImage: ruta !== "" ? `url(${ruta})` : `url(${textura})`,
      zIndex: 0,
    };
    return (
      <React.Fragment>
        <div className={classes.contenedor} onClick={e => this.props.selectedBlog(blogProps.id) }>
          <div
            style={style}
            className={[classes.miniatura, classes.divImagen, ruta === "" ? classes.sinImagen : null].join(" ")}
          />
          <div className={[classes.fecha, "text-right"].join(" ")}>
            <span className="texto_azul texto_12 montse">{formatDate(blogProps.fecha)}</span>
          </div>
          <div className={[classes.titulo, "text-center"].join(" ")}>
            <span className="texto_negro bold texto_16 nunito">{blogProps.titulo}</span>
          </div>
          <div className={[classes.descripcion, "text-justify"].join(" ")}>
            <span className="texto_negro montse texto_12">{blogProps.descripcion}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Content;

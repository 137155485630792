import React, { Component } from "react";
import Boton from "../../../../components/ui/boton/Boton";
import Stepper from "../../../../components/ui/stepper/Stepper";

import ipoJson from "../../../../json/empresas/ipo.json";

import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
class FasesIpo extends Component {
  state = {
    messages: null,
    lan: getLocal(),
  };
  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let stepper = null;
    if (this.props.fases) {
      let content = this.props.fases[0].content;
      content = JSON.parse(content);
      stepper = <Stepper data={content} tipo={1} />;
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <div className="row ">
            <div className="col-12 sin_padding_L">
              <div className={[""].join(" ")}>
                <h2 className="nunito texto_42 regular">
                <FormattedHTMLMessage id="empresas.listadodevalores.comolistarse.fasesipo.titulo1" defaultMessage=" " />

                </h2>
              </div>
            </div>
          </div>

          <div className="row margen_top_50">
            {stepper}
            {/* <Stepper data={ipoJson} tipo={1} /> */}
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                <FormattedHTMLMessage id="empresas.listadodevalores.comolistarse.fasesipo.subtitulo1" defaultMessage=" " />
                 </h2>
              </div>
            </div>

            <Boton
              ruta={"/empresas/listado_de_valores/como_listarse/cuotas"}
              tipo={2}
              texto={this.state.messages ? this.state.messages["empresas.listadodevalores.comolistarse.fasesipo.btn1"] : ""}
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    fases: state.toggleBox.toggleBoxFasesIpo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(62));
      dispatch(action.getToggleBoxFasesIpo());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FasesIpo);

import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import Grafica from "../../../../components/ui/graficaHistoricoBiva/GraficaHistoricoBiva";


class Biva extends Component {
  componentDidMount(){
    this.props.onHistoricoBiva();
  }

  render() {
    return (
      <React.Fragment>
        <div className="row margen_top_30">
        <Grafica datos={this.props.biva} messages={this.props.messages}/>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onHistoricoBiva: () => {
      dispatch(action.initHistoricoBiva());
    }
  };
};

const mapStateToProps = state => {
  return {
    biva: state.informacionMercado.historicoBiva
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Biva);

import React, { Component } from "react";
import Accordion from "../../../../components/ui/accordion/Accordion";
import listado_json from "../../../../json/contactanos/regulacion.json";

class Regulacion extends Component {
  render() {
    return (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>Contáctanos</h1>
        <div className={"margen_top_50"}>
        <Accordion
          datos={listado_json.datos}
          nombre="listado"
          icon={"fas fa-phone"}
          tipo={2}
        />
        </div>

      </React.Fragment>
    );
  }
}

export default Regulacion;

import React, { Component } from "react";

import classes from "./CuadroOpel.css";
import { getUrlDocument } from "../../../stores/utils/utilities";
 
class CuadroOpel extends Component {


  render() {

    let img = <i className={["far", "fa-file-image", classes.icon].join(" ")} />;
    if(this.props.icono && this.props.icono !==""){
      let imgPath = getUrlDocument(this.props.icono);
      // let imgPath = require(`../../../assets/icons/${this.props.icono}`);
      img = <img src={imgPath} alt="" className={classes.icono} />
    }

    return (
      <React.Fragment>
        <div className={classes.cuadro}>
            <div className={[classes.texto, classes.margin_15].join(" ")}><span className={"color_azul nunito texto_16 regular"}>{this.props.textoArriba}</span></div>
            <div className={[classes.texto, classes.margin_15].join(" ")}><span className={"texto_30 nunito negrita"}>{this.props.titulo}</span></div>
            <div className={[classes.texto, classes.margin_15].join(" ")}>{img}</div>


        <div className={classes.cuadro_azul}>
        <div className={[classes.texto, classes.vertircal_div].join(" ")}><span className={["texto_16 montse semi_bold texto_blanco", classes.vertical].join(" ")}>{this.props.texto}</span></div>

        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CuadroOpel;

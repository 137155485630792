import React, { Component } from "react";
import classes from "./InformacionGeneral.css";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import Auxs from "../../../../hoc/auxs/Auxs";
import Boton from "../../../../components/ui/boton/Boton";
import SymbolOverviewWidget from "../../../../components/ui/tradingviewWidget/SymbolOverviewWidget";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLan, getLocal, getUrlDocument } from "../../../../stores/utils/utilities";
import Box from "../../../../components/ui/box/Box";

class InformacionGeneral extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    isMarketData: false
  };

  componentDidMount() {
    this.props.oninitUltimoDiaMesBiva();
    this.props.onDocumento();
    this.props.onFicha();

    const pathname = this.props.location.pathname;
    let isMarketData = false
    if (pathname && pathname.includes("informacion_de_mercado/indices/informacion_general")) {
      isMarketData = true;
    }
    this.setState({ isMarketData: isMarketData });

  }
  componentWillMount(){
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let ruta = null;
    if (this.props.fichaTecnica) {
      ruta = getUrlDocument(this.props.fichaTecnica[0].url);
    }

    let indices = [];
    let descarga = null;

    if (this.props.infoGeneral) {
      let descarga_ultimo = this.props.infoGeneral.map((infoGeneral, i) => {
        return <Box key={"infoGeneral" + i} modelo="modelo_1" data={infoGeneral} />;
      });

      descarga = <div className={"row width_cien"}>{descarga_ultimo}</div>;
    }
    let botones = null;
    if (this.props.dataSheet) {
      botones = this.props.dataSheet.map((dataSheet, i) => {
        let isCMS = false;
        let ruta = getUrlDocument(dataSheet.url);
        if (ruta.includes("/cms/")) {
          if (ruta.includes("?")) {
            ruta = `${ruta}&download=true`;
            isCMS = true;
          } else {
            ruta = `${ruta}?download=true`;
            isCMS = true;
          }
        }
        return (
            
          <Boton tipo={isCMS ? 6 : 1} texto={dataSheet.title} icono_derecho="fas fa-long-arrow-alt-right" ruta={ruta} colorBoton={ i % 2 ? "blanco" : ""} />
        );
      });
    }
    const bivaIndices = ["FTSE:FTBIVA|1D|MXN","FTSE:F4GBIVA|1D|MXN"];
    bivaIndices.forEach((indice)=>{
      let symbolJSON = [[indice]];
      let graph = (
        <div className={["col-xs-12 col-sm-12 col-md-4", classes.contenedorGrafica].join(" ")}>
          <SymbolOverviewWidget symbols={symbolJSON} locale={getLan()}/>
        </div>
      );
      indices.push(graph);
    });

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.inidice.informacion.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          <div className="row margen_top_30">
            <div className="col sin_padding_L">
              <div className={[classes.text_container].join(" ")}>
                <span className="montse texto_16 regular">
                  <FormattedHTMLMessage id="informacionmercado.inidice.informacion.introduccion" defaultMessage=" " />
                </span>
              </div>
            </div>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={""}>
                  <FormattedHTMLMessage
                    id="informacionmercado.inidice.informacion.subtitulo.nuestrosindices"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            {indices}
          </div>
          {descarga}
          <div className="row margen_top_50">
            <p>
              <span className={["texto_16 montse regular", classes.contenedorTexto].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.inidice.informacion.ftserussell" defaultMessage=" " />
              </span>
            </p>
          </div>

          <div className="row margen_top_30 margin_bottom_300">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={""}>
                  <FormattedHTMLMessage id="informacionmercado.inidice.mayorinformacion" defaultMessage=" " />
                </h2>
              </div>
            </div>

            <div className="row margen_top_30">
              {botones}
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    oninitUltimoDiaMesBiva: () => {
      dispatch(action.initUltimoDiaMesBiva(1));
    },
    onDocumento: () => {
      dispatch(action.initUltimoDiaDocumentos());
      dispatch(action.getDataSheetInformacionGeneral());
      dispatch(action.getDocumentosInfoGeneral());
    },
    onFicha: () => {
      dispatch(action.initFichaTecnicaDocumento());
    },
    onInitIntl: () => {
      dispatch(action.initIntl(42));
    }
  };
};

const mapStateToProps = state => {
  let indice = null;
  if (state.indice.indices) {
    indice = state.indice.indices;
  }
  return {
    diccionario: state.intl.diccionario,
    indice: indice,
    doc: state.documentos.ultimoDia,
    dataSheet: state.documentos.dataSheet,
    infoGeneral: state.documentos.infoGeneral,
    fichaTecnica: state.documentos.fichaTecnica
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformacionGeneral);

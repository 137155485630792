import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import classes from "./Cursos.css";
import * as action from "../../../stores/actions/index";
import Auxs from "../../../hoc/auxs/Auxs";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
import GaleriaCursos from "./galeriaCursos/GaleriaCursos";
import Cart from "./cart/Cart";
import Pagination from "../../../components/ui/pagination/Pagination";

const lan = getLocal();

const Cursos = (props) => {
  const dispatch = useDispatch();

  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [numRows, setNumRows] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [messages, setMessages] = useState(null);
  const [cursos, setCursos] = useState([]);

  const traduccionRedux = useSelector((state) => state.intl.diccionario);
  const cursosRedux = useSelector((state) => state.cursos.cursos);

  useEffect(() => {
    const params = {
      page: 0,
      size: itemsPerPage,
      tags:props.tag
    };
    dispatch(action.getCursos(params));
    dispatch(action.initIntl(8));
  }, []);
  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);

  useEffect(() => {
    if (cursosRedux) {
      const embedded = cursosRedux["_embedded"];
      const page = cursosRedux["page"];
      setCursos(embedded ? embedded.cursoResourceList : []);
      setNumRows(page ? page.totalElements : 0);
    }
  }, [cursosRedux]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const params = {
      page: pageNumber > 0 ? pageNumber - 1 : 0,
      size: itemsPerPage,
      tags:props.tag
    };
    dispatch(action.getCursos(params));
  };

  return (
    <IntlProvider locale={lan} messages={messages}>
      <Auxs>
        <div className="row">
          <div className="col sin_padding_L">
            <div>
              <h1
                className={[classes.main_title, "texto_42", "nunito"].join(" ")}
              >
                <FormattedMessage
                  id="institutobiva.cursos.titulo2"
                  defaultMessage=" "
                />
              </h1>
            </div>
          </div>
          <div className="row margin_top_30">
            <span
              className={["texto_16 montse regular", classes.texto_introduccion].join(" ")}
              dangerouslySetInnerHTML={{
                __html:
                  messages !== null
                    ? messages["institutobiva.cursos.introduccion.desccurso"]
                    : null,
              }}
            />
          </div>
        </div>
        <div className={["margin_top_200", props.type === 2 ? classes.blueBack : null].join(" ")}>
          <Cart />
          <GaleriaCursos data={cursos} type={props.type}/>
        </div>
        <div className="">
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={itemsPerPage}
            handlePageChange={handlePageChange.bind(this)}
            activePage={activePage}
          />
        </div>
      </Auxs>
    </IntlProvider>
  );
};

export default Cursos;

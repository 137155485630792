import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import classes from "./Bibliografia.css";
import Auxs from "../../../../hoc/auxs/Auxs";
import { getLocal } from "../../../../stores/utils/utilities";
import { IntlProvider, defineMessages } from "react-intl";
import Item from "./item/Item";
import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";
import FilterBibliografia from "./FilterBibliografia";
import hojaVerde from "../../../../assets/icons/sustentable/hoja-verde-2.png";

class Bibliografia extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    data: [],
    tipo_imagen: null,
    itemsPerPage: 9,
    activePage: 1,
    numRows: 1,
    dataView: [],
    loader: true,
    palabraClave: null,
  };

  componentDidMount() {
    this.initData(true);
  }

  initData(init = false) {
    if (init) {
      this.props.onInitIntl(6);
    }
    this.props.getBibliografia();

    this.setState({
      articulosAcademicosFlag: true,
      diccionarioFlag: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionarioIntl !== prevProps.diccionarioIntl) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionarioIntl) {
        const messages = defineMessages(this.props.diccionarioIntl);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.bibliografia !== prevProps.bibliografia) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.bibliografia.slice(index_inicial, index_final);

        this.setState({
          loader: false,
          dataView: lista_ver,
          data: this.props.bibliografia,
          tipo_imagen: 1,
        });
    }


  }

  handlePageChange(pageNum) {
    if (pageNum !== this.state.activePage) {
      let lista_ver = [];
      let index_final = pageNum * this.state.itemsPerPage;
      let index_inicial = index_final - this.state.itemsPerPage;
      lista_ver = this.state.data.slice(index_inicial, index_final);
      this.setState({
        activePage: pageNum,
        dataView: lista_ver,
      });
    }
  }

  onSetPalabraClave = (keyWord) => {
    this.setState({
      palabraClave: keyWord,
    });
  };
  onSetPage = (page = 0) => {
    this.setState({
      activePage: page,
    });
  };
  onSetParams = ({ keyword, from, to, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(keyword, from, to, page);
    }
  };

  changeHistory(keyword) {
    this.setState({
      loader: true,
    });

    const params = {
      keyword: keyword
    }
    this.getData(params);
  }

  changeHistoryReset() {
    this.getData(null);
  }

  getData(params) {
      this.props.getBibliografia(params);
  }

  onSetWasFiltered = (wasFiltered) => {
    this.setState({
      wasFiltered: wasFiltered,
    });
  };

  render() {
    let titulo_imagen = "";
    let loader = null;
    
    if (this.state.loader) {
      loader = (
        <Loader
          loaderDisable={(disable) => this.setState({ loader: disable })}
        />
      );
    }
    return (
      <IntlProvider
        messages={this.state.messages}
        locale={this.state.lan}
        defaultMessage=" "
      >
        <Auxs>
          {loader}

          <div className={"row"}>
            <div
                            className={[
                            "main_subtitle",
                            "nunito",
                            "containerTituloSg",
                            "sin_padding"
                            ].join(" ")}
                            >
                            <img
                            src={hojaVerde}
                            alt="bivasg"
                            className={["slash", "imgSg"].join(" ")}
                            />
                            <h1 className={["tituloSg texto_42 nunito"].join(" ")}>
                            Bibliografía
                            </h1>
                            </div>
          </div>
          <div className={["row"].join(" ")}>
            <div className={["col-12 sin_padding_L"].join(" ")}>
              <FilterBibliografia
                setPalabraClave={(palabra_calve) =>
                  this.onSetPalabraClave(palabra_calve)
                }
                setWasFiltered={(wasFiltered) =>
                  this.onSetWasFiltered(wasFiltered)
                }
                setParams={(filterParams) => this.onSetParams(filterParams)}
                setPage={(page) => this.onSetPage(page)}
              />
            </div>

            <div className={["col-12 sin_padding_L", classes.grid].join(" ")}>
              <Item
                key={+new Date()}
                datos={this.state.dataView}
                tipo={1}
                tipo_imagen={this.state.tipo_imagen}
                titulo_slider={titulo_imagen}
              />
            </div>
            <div className={"col-12 sin_padding"}>
              <Pagination
                claseTipo={2}
                numRows={this.state.data.length}
                paginas={5}
                itemsPerPage={this.state.itemsPerPage}
                handlePageChange={this.handlePageChange.bind(this)}
                activePage={this.state.activePage}
                isSustentable={true}
              />
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    getBibliografia: (params) => {
      dispatch(action.getBibliografia(params));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    bibliografia: state.bivaSustentable.bibliografia,
    diccionarioIntl: state.intl.diccionario,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bibliografia);

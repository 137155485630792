import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  seguimientoInversiones: null,
  membresia: null,
  tiposPostura: null,
  descOpelB: null,
  infoCuotaOperacion: null,
  marketPhases: null,
  calendario: null,
  horarioOpelB: null,
  horarioOpelC: null,
  horarioOpelD: null,
  horarioOpelE: null,
  horarioOpelW: null,
  tOpel: null,
  fix: null,
  ouch: null,
  isvs: null,
  oms: null,
  cuotasItch: null,
  tarifasBim: null,
};

const setAccordions = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCORDION_SUCCESS:
      return setAccordions(state, action);
    default:
      return state;
  }
};

export default reducer;

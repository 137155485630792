import axios from "axios";
import StringUtils from "../utils/StringUtils";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO !== undefined && process.env.REACT_APP_DOMINIO !== "" ? process.env.REACT_APP_DOMINIO : window.location.origin;

// =========================================================================

const setEmisorasBanco = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORASBANCOS_SUCCESS,
      emisoras_bancos: null
    };
  }
  return {
    type: actionTypes.FETCH_EMISORASBANCOS_SUCCESS,
    emisoras_bancos: data
  };
};

export const filterEmisorasBanco = ({
  type,
  tipoDocumento,
  tipoInformacion,
  tipoInstrumento,
  instrumentosAlternativos = false,
  modoListado
}) => {
  let isListado = type === "listado";
  let isMantenimiento = type === "mantenimiento";
  let url = new URL(`${dominio}/emisoras/banco-informacion/claves`);

  if (modoListado && modoListado.length > 0) {
    url.searchParams.set('modoListado', modoListado);
  }
  if (tipoInformacion && tipoInformacion.length > 0) {
    if (isListado) {
      url.searchParams.set('tipoInformacionListado', tipoInformacion);
    } else if(isMantenimiento){
      url.searchParams.set('tipoInformacion', tipoInformacion);
    }
  }
  if (tipoDocumento && tipoDocumento.length > 0) {
    if (isListado) {
      url.searchParams.set('tipoDocumentoListado', tipoDocumento);
    } else if(isMantenimiento){
      url.searchParams.set('tipoDocumento', tipoDocumento);
    }
  }
  if (tipoInstrumento && tipoInstrumento.length > 0) {
    if (instrumentosAlternativos) {
      url.searchParams.set('tipoInstrumentoAlternativo', [...tipoInstrumento]);
    } else {
      url.searchParams.set('tipoInstrumento', [...tipoInstrumento]);
    }
  }

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisorasBanco(response.data));
      })
      .catch(err => dispatch(setEmisorasBanco(err.data)));

  };
};

// =========================================================================

const getTipoInformacion = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_TIPOINFORMACION_SUCCESS,
      tipos_informacion: null
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_TIPOINFORMACION_SUCCESS,
    tipos_informacion: data
  };
};

export const initTiposInformacionBanco = ({ empresa, tipoInstrumento, instrumentosAlternativos=false, modoListado}) => {
  let url = new URL(`${dominio}/emisoras/banco-informacion/tipo-informacion`);
  if (modoListado && modoListado.length > 0) {
    url.searchParams.set('modoListado', modoListado);
  }
  if (empresa) {
    url.searchParams.set('empresa', empresa);
  }
  if (tipoInstrumento && tipoInstrumento.length > 0) {
    if (instrumentosAlternativos) {
      url.searchParams.set('tipoInstrumentoAlternativo', [...tipoInstrumento]);
    } else {
      url.searchParams.set('tipoInstrumento', [...tipoInstrumento]);
    }
  }

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getTipoInformacion(response.data));
      })
      .catch(err => dispatch(getTipoInformacion(err.data)));
  };
};

// =========================================================================
const getBancoTipoDocumento = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_TIPODOCUMENTO_SUCCESS,
      tipos_documentos: null
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_TIPODOCUMENTO_SUCCESS,
    tipos_documentos: data
  };
};

export const initBancoTipoDocumento = ({
  empresa,
  type,
  tipoInformacion,
  tipoInstrumento,
  instrumentosAlternativos=false,
  modoListado
}) => {
  let url = new URL(`${dominio}/emisoras/banco-informacion/tipo-documento`);
  if (modoListado && modoListado.length > 0) {
    url.searchParams.set('modoListado', modoListado);
  }
  if (empresa) {
    url.searchParams.set('empresa', empresa);
  }
  if (tipoInformacion && tipoInformacion.length > 0) {
    if (type === "listado") {
      url.searchParams.set('tipoInformacionListado', tipoInformacion);
    } else {
      url.searchParams.set('tipoInformacion', tipoInformacion);
    }
  }
  if (tipoInstrumento && tipoInstrumento.length > 0) {
    if (instrumentosAlternativos) {
      url.searchParams.set('tipoInstrumentoAlternativo', [...tipoInstrumento]);
    } else {
      url.searchParams.set('tipoInstrumento', [...tipoInstrumento]);
    }
  }

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getBancoTipoDocumento(response.data));
      })
      .catch(err => dispatch(getBancoTipoDocumento(err.data)));
  };
};

// =========================================================================

const getBancoTipoInstrumento = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_TIPOISTRUMENTO_SUCCESS,
      tipos_instrumentos: null
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_TIPOISTRUMENTO_SUCCESS,
    tipos_instrumentos: data
  };
};

export const initBancoTipoInstrumento = ({
  empresa,
  type,
  tipoInformacion,
  tipoDocumento,
  modoListado
}) => {
  let isListado = type === "listado";
  let url = new URL(`${dominio}/emisoras/banco-informacion/tipo-instrumento`);
  if (modoListado && modoListado.length > 0) {
    url.searchParams.set('modoListado', modoListado);
  }
  if (empresa) {
    url.searchParams.set('empresa', empresa);
  }
  if (type) {
    if (tipoInformacion && tipoInformacion.length > 0) {
      if (isListado) {
        url.searchParams.set('tipoInformacionListado', tipoInformacion);
      } else {
        url.searchParams.set('tipoInformacion', tipoInformacion);
      }
    }
    if (tipoDocumento && tipoDocumento.length > 0) {
      if (isListado) {
        url.searchParams.set('tipoDocumentoListado', tipoDocumento);
      } else {
        url.searchParams.set('tipoDocumento', tipoDocumento);
      }
    }
  }

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getBancoTipoInstrumento(response.data));
      })
      .catch(err => dispatch(getBancoTipoInstrumento(err.data)));
  };
};
// =========================================================================

const getBancoByClave = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_BYCLAVE_SUCCESS,
      claves: null
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_BYCLAVE_SUCCESS,
    claves: data
  };
};

export const initBancoByClave = clave => {
  return dispatch => {
    axios
      .get(`${dominio}/emisoras/banco-informacion/claves?clave=${clave}`)
      .then(response => {
        dispatch(getBancoByClave(response.data));
      })
      .catch(err => dispatch(getBancoByClave(err.data)));

  };
};

export const initBancoByClaveEventosRelevante = (params) => {
  const queryParams = StringUtils.jsonToQueryString(params);
  return dispatch => {
    axios
      .get(`${dominio}/emisoras/banco-informacion/claves${queryParams}`)
      .then(response => {
        dispatch(getBancoByClave(response.data));
      })
      .catch(err => dispatch(getBancoByClave(err.data)));

  };
};
// =========================================================================

const getAllBancosByClave = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_ALLBYCLAVE_SUCCESS,
      claves: null
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_ALLBYCLAVE_SUCCESS,
    claves: data
  };
};

export const initAllBancosByClave = clave => {
  return dispatch => {
    axios
      .get(`${dominio}/emisoras/empresas/${clave}`)
      .then(response => {
        dispatch(getAllBancosByClave(response.data));
      })
      .catch(err => dispatch(getAllBancosByClave(err.data)));
  };
};
// =========================================================================

export const initBancoInformacion = ({
  size = null,
  page = null,
  emisora = null,
  tipoInstrumento = null,
  tipoInformacion = null,
  type = null,
  tipoDocumento = null,
  fechaInicio = null,
  fechaFin = null,
  periodo = null,
  ejercicio = null,
  instrumentosAlternativos = false,
  clave = null,
  modoListado = null
}) => {
  let url = new URL(`${dominio}/emisoras/banco-informacion?size=${size}&page=${page}`);
  if (clave === null) {
    let isListado = type === "listado";
    let isMantenimiento = type === "mantenimiento";
    if (isListado) {
      if (tipoInformacion && tipoInformacion.length > 0) {
        url.searchParams.set('tipoInformacionListado', tipoInformacion);
      }
      if (tipoDocumento && tipoDocumento.length > 0) {
        url.searchParams.set('tipoDocumentoListado', tipoDocumento);
      }
    } else if (isMantenimiento) {
      if (tipoInformacion && tipoInformacion.length > 0) {
        url.searchParams.set('tipoInformacion', tipoInformacion);
      }
      if (tipoDocumento && tipoDocumento.length > 0) {
        url.searchParams.set('tipoDocumento', tipoDocumento);
      }
    }
    if (tipoInstrumento && tipoInstrumento.length > 0) {
      if (instrumentosAlternativos) {
        url.searchParams.set('tipoInstrumentoAlternativo', tipoInstrumento);
      } else {
        url.searchParams.set('tipoInstrumento', tipoInstrumento);
      }
    }
    if (fechaInicio && fechaInicio.length > 0) {
      url.searchParams.set('fechaInicio', fechaInicio);
    }
    if (fechaFin && fechaFin.length > 0) {
      url.searchParams.set('fechaFin', fechaFin);
    }
    if (periodo && periodo.length > 0) {
      url.searchParams.set('periodo', periodo);
    }
    if (ejercicio && ejercicio.length > 0) {
      url.searchParams.set('ejercicio', ejercicio);
    }
    if (emisora && emisora !== "") {
      url.searchParams.set('empresa', emisora);
    }
    if (modoListado && modoListado.length > 0) {
      url.searchParams.set('modoListado', modoListado);
    }
  }
  if (clave && clave !== "") {
    url.searchParams.set('clave', clave);
  }
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setBancoInformacion(response.data));
      })
      .catch(err => dispatch(setBancoInformacion(err.data)));
  };
};

const setBancoInformacion = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_SUCCESS,
      bancoInformacion: []
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_SUCCESS,
    bancoInformacion: data
  };
};
// =========================================================================

export const initBancoInformacionSearch = ({
  size = null,
  page = null,
  clave = null
}) => {
  
  const params = {
    size: size,
    page: page,
    clave: clave
  }
  const queryParams = StringUtils.jsonToQueryString(params);
  
  const url = `${dominio}/emisoras/banco-informacion${queryParams}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setBancoInformacionSearch(response.data));
      })
      .catch(err => dispatch(setBancoInformacionSearch(err.data)));
  };
};

const setBancoInformacionSearch = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_SUCCESS,
      bancoInformacionSearch: []
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_SUCCESS,
    bancoInformacionSearch: data
  };
};

// =========================================================================

const setPeriodosBancoInformacion = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PERIODOSBI_SUCCESS,
      periodos_bi: []
    };
  }
  return {
    type: actionTypes.FETCH_PERIODOSBI_SUCCESS,
    periodos_bi: data
  };
};

export const fetchPeriodosBancoInformacion = ({
  tipoInformacion = null,
  tipoDocumento = null,
  tipoInstrumento = null,
  tipoInstrumentoAlternativo = null,
  clave = null,
  modoListado = null
}) => {
  if(tipoInstrumentoAlternativo && tipoInstrumentoAlternativo.length === 0){
    tipoInstrumentoAlternativo = "1,2,3,4";
  }
  const params = {
    tipoInformacion: tipoInformacion && tipoInformacion !== "" ? tipoInformacion : null,
    tipoDocumento: tipoDocumento && tipoDocumento !== "" ? tipoDocumento : null,
    tipoInstrumento: tipoInstrumento && tipoInstrumento !== "" ? tipoInstrumento : null,
    tipoInstrumentoAlternativo: tipoInstrumentoAlternativo,
    clave: clave && clave !== "" ? clave : null,
    modoListado: modoListado
  }
  const queryParams = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/emisoras/banco-informacion/periodo` + queryParams;


  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPeriodosBancoInformacion(response.data));
      })
      .catch(err => dispatch(setPeriodosBancoInformacion(err.data)));
  };
};

// =========================================================================

const setModoListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MODO_LISTADO_SUCCESS,
      modoListado: []
    };
  }
  return {
    type: actionTypes.FETCH_MODO_LISTADO_SUCCESS,
    modoListado: data
  };
};

export const fetchModoListado = (lan) => {
  if (lan === 'es') {
    return {
      type: actionTypes.FETCH_MODO_LISTADO_SUCCESS,
      modoListado: [{"id":1,"nombre":"Listado BIVA"},{"id":3,"nombre":"Registro Secundario"}]
    };
  }
  return {
    type: actionTypes.FETCH_MODO_LISTADO_SUCCESS,
    modoListado: [{"id":1,"nombre":"BIVA Listing"},{"id":3,"nombre":"Secondary Registration"}]
  };
};

const setEjerciciosBancoInformacion = (data = []) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EJERCICIOSBI_SUCCESS,
      ejercicios_bi: null
    };
  }
  return {
    type: actionTypes.FETCH_EJERCICIOSBI_SUCCESS,
    ejercicios_bi: data
  };
};

export const fetchEjerciciosBancoInformacion = ({
  tipoInformacion = null,
  tipoDocumento = null,
  periodo = null,
  tipoInstrumento = null,
  tipoInstrumentoAlternativo = null,
  clave = null,
  modoListado = null
}) => {
  if(tipoInstrumentoAlternativo && tipoInstrumentoAlternativo.length === 0){
    tipoInstrumentoAlternativo = "1,2,3,4";
  }
  const params = {
    tipoInformacion: tipoInformacion && tipoInformacion !== "" ? tipoInformacion : null,
    tipoDocumento: tipoDocumento && tipoDocumento !== "" && tipoDocumento.length > 0  ? tipoDocumento : null,
    periodo: periodo && periodo !== "" ? periodo : null,
    tipoInstrumento: tipoInstrumento && tipoInstrumento !== "" ? tipoInstrumento : null,
    tipoInstrumentoAlternativo: tipoInstrumentoAlternativo,
    clave: clave && clave !== "" ? clave : null,
    modoListado: modoListado
  }
  
  const queryParams = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/emisoras/banco-informacion/ejercicio` + queryParams;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjerciciosBancoInformacion(response.data));
      })
      .catch(err => dispatch(setEjerciciosBancoInformacion(err.data)));
  };
};



// =========================================================================

const setReportesSustentabilidad = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_SUCCESS,
      bancoInformacion: []
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_SUCCESS,
    bancoInformacion: data
  };
};

export const getReportesSustentabilidad = (params) => {
  
  const queryParams = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/emisiones/emisiones-asg/documentos` + queryParams;


  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setReportesSustentabilidad(response.data));
      })
      .catch(err => dispatch(setReportesSustentabilidad(err.data)));
  };
};

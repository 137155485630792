import React, { Component } from "react";
import classes from "./FilterBivateca.css";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import { getLan } from "../../../../stores/utils/utilities";
import { Form, Field } from "react-final-form";

import Auxs from "../../../../hoc/auxs/Auxs";
import Input from "../../../../components/ui/input/Input";

import * as actions from "../../../../stores/actions/index";

const renderInput = ({
  input,
  id = null,
  label,
  clases = null,
  options = null,
  type = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel,
  onInputChange
}) => {
  const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";

  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      checked: checked,
      className: clases ? clases : ["form-control", classes.inputText].join(" "),
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    type: type,
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel
  };

  return (
    <div className="form-group">
      {label ? (
        <label
          className={[elementType === "checkbox" ? classes.myLabel : null, "texto_14 montse regular"].join(" ")}
          for={input.name}
        >
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterAnunciosBiva extends Component {
  constructor(props) {
    super(props);
    this.formFilterAvisos = React.createRef();
  }

  state = {
    toggleFilter: false,
    selectedFilters: {
      palabra_clave: [],
      fechas: []
    },
    form_filters: {
      palabra_clave: {
        value: ""
      },
      errorDate: false
    },
    itemsPerPage: 10
  };

  componentDidMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  printChange = e => {
    this.callReduxAction(e.target);
  };

  callReduxAction = value => {
    let evento = {};
    evento.target = value;
    this.onInputSelectedHandler(evento, 0);
  };

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    let [valuesPalabraClave, valuesFechaInicio, valuesFechaFin] = this.onGetAllValuesFormFilter();

    /* [valuesFechaInicio, valuesFechaFin] = changeDateFormat(valuesFechaInicio, valuesFechaFin); Se elimina para dejar el formato yyy-mm-dd*/

    if (!this.state.form_filters.errorDate) {
      const params = {
        size: this.state.itemsPerPage,
        page: 0,
        keyword: valuesPalabraClave,
        from: valuesFechaInicio,
        to: valuesFechaFin,
        reset: false
      };
      this.props.setParams(params);

      this.props.setPage(1);

      let wasFiltered = false;
      // Se invocan a las funciones el componente padre (BancoInformacion.js) para setear los valores correspondientes
      // Configurando el valor del campo palabra clave
      this.props.setPalabraClave(valuesPalabraClave);

      if (
        (valuesPalabraClave && valuesPalabraClave !== "") ||
        (valuesFechaInicio && valuesFechaInicio !== "") ||
        (valuesFechaFin && valuesFechaFin !== "")
      ) {
        wasFiltered = true;
      }

      this.props.setWasFiltered(wasFiltered);
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesPalabraClave = form.palabra_clave.value && form.palabra_clave.value !== "" ? form.palabra_clave.value : "";
    // Se obtiene el valor de fecha inicio

    return [valuesPalabraClave];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    if (type === 0) {
        return ["palabra_clave", form.palabra_clave.value, selectedFilters.palabra_clave];
    }
    
  };

  // Retorna el valor, etiqueta y tipo  de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newValue = null;
    let newLabel = null;

    const labelForTextInput = getLan() === "es" ? "Palabra clave" : "Keyword";

    if (type === 0) {
      // Resto de campos
      newValue = value;
      newLabel = `${labelForTextInput}: ${value}`;
    }

    return [newValue, newLabel];
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];

    if (type === 0) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      // Fechas
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(option => option.label === label);
      if (filterExists) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
        if (values && values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      } else {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    }
    return newFilterValues;
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (values !== "") {
      newStateValues = values;
    }
    return newStateValues;
  };

  onInputSelectedHandler = (event = null, type = null, item = null, eraser = null) => {
    let actualValue = eraser ? null : event.target.value;
    eraser = eraser || (actualValue && actualValue === "") ? true : false;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newValue = null;
    let newLabel = null;

    if (eraser) {
      actualValue = "";
      newValue = "";
      newLabel = item.label;
      type = item.type;
    } else {
      // Solo para los campos distintos a tipo instrumento y tipo valor
      [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue, type);
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;


    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [...this.state.selectedFilters.palabra_clave, ...this.state.selectedFilters.fechas];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
                {filter.type !== 0 ? filter.value : filter.label}
              </span>
              <span className={classes.filterIconButton} onClick={() => this.onInputSelectedHandler(null, null, filter, true)}>
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onSubmitFilter = () => {
    // Pass
  };

  onResetFilter = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form.palabra_clave.value = "";
    selectedFilters.palabra_clave = [];
    form.errorDate = false;

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });
    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      keyword: null,
      from: null,
      to: null,
      reset: true
    };
    this.props.setParams(params);
    this.props.setPage(1);

    // this.onFilterDataByEvent();
  };

  onToggleFilterHandler = () => {
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  onSelectOptionsHandler = (options = null, type = null) => {
    let opt = [];
    if (type === 0) {
      options.forEach(option => {
        opt.push({
          nombre: option,
          id: option
        });
      });
    }
    return opt;
  };

  printFrameModelHandler = () => {
    const selectedFilters =
      this.state.selectedFilters.palabra_clave.length > 0 || this.state.selectedFilters.fechas.length > 0
        ? this.onPrintSelectedFiltersHandler()
        : null;

    const closeLabel = getLan() === "es" ? "Cerrar Filtros" : "Close";
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";
    return (
      <Auxs>
        <div
          className={[
            "row",
            classes.firstSectionFilterContainer,
            !this.state.toggleFilter ? classes.noBorderRadius : null
          ].join(" ")}
        >
          <div className="col col-lg-3 text-center sin_padding_L">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button type="button" className="btn" onClick={this.onToggleFilterHandler}>
                <i className="fas fa-filter" />
                <span>{this.state.toggleFilter ? filterLabel : closeLabel}</span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={[
            "row",
            classes.secondSectionFilterContainer,
            // "margin_bottom_50",
            this.state.toggleFilter ? classes.hidden : null
          ].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    const clavesCotizacionOptions = this.props.claves_cotizacion
      ? this.onSelectOptionsHandler(this.props.claves_cotizacion, 0)
      : [];

    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitFilter}
              initialValues={{
                palabra_clave: this.state.form_filters.palabra_clave.value,
              }}
              render={({ optionsClavesCotizacion = clavesCotizacionOptions, handleSubmit, form }) => (
                <form ref={this.formFilterAvisos} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <Field
                        name="palabra_clave"
                        id="palabra_clave"
                        elementType="input"
                        label={getLan() === "es" ? "Palabra clave" : "Keyword"}
                        onInputChange={event => this.printChange(event)}
                        // onInputChange={event =>
                        //   this.onInputSelectedHandler(event, 0)
                        // }
                        component={renderInput}
                      />
                    </div>
                  </div>
                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  sectionButtonsHandler = form => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilter()}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandler();
    return (
      <Auxs>
        <div className="row margin_bottom_30">
          <div className="col sin_padding">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFilterOperacionesAvisos: params => {
      dispatch(actions.filterOperacionesAvisos(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(FilterAnunciosBiva);

import React, { Component } from "react";
import classes from "./FilterComposicionCartera.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { changeDateFormat } from "../../../stores/utils/utilities";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import { getLan, validateLimitDateXMonths, validateLimitDateXYears } from "../../../stores/utils/utilities";
const renderInput = ({
    input,
    id = null,
    classes = null,
    elementType = "input",
    label,
    onInputChange,
    placeholder = null,
    options,
    isMultiple = null,
    selectLabel
}) => {
    const params = {
        input: {
            ...input,
            id: id ? id : input.name,
            placeholder: placeholder,
            className: classes ? [classes, "form-control"].join(" ") : "form-control",
            onChange: e => {
                input.onChange(e);
                onInputChange && onInputChange(e);
            }
        },
        label: label,
        elementType: elementType,
        options: options,
        isMultiple: isMultiple,
        selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
    };
    return (
        <div className="form-group">
            <label className={["montse", "texto_14", "regular"].join(" ")}>
                {label}
            </label>
            <Input config={params} />
        </div>
    );
};

class FilterComposicionCartera extends Component {
    constructor(props) {
        super(props);
        this.formRef1 = React.createRef();
    }

    state = {
        toggleFilters: true,
        disableEnableButtons: true,
        selectedFilters: {
            clave_operadora: [],
            clave_fondo: [],
            tipo_fondo: [],
            clasificacion_fondo: [],
            fechas: [],
            serie: []
        },
        form_filters: {
            clave_operadora: {
                value: ""
            },
            clave_fondo: {
                value: ""
            },
            tipo_fondo: {
                value: ""
            },
            clasificacion_fondo: {
                value: ""
            },
            fecha_inicio: {
                value: ""
            },
            fecha_fin: {
                value: ""
            },
            serie: {
                value: []
            },
            errorDate: false,
            errorLimitDate: false,
            errorLimitDateYear: false
        },
        inputPivot: false,
        inputSubPivot: false,
        prevInputSelected: false
    };

    componentDidMount() {
        this.onFilterClaveOperadoraHandler();
        // this.onFilterClaveFondoHandler();
        // this.onFilterTipoFondoHandler();
        // this.onFilterClasificacionFondoHandler();
    }

    onFilterClaveOperadoraHandler = (
        claveFondo = null,
        tipoFondo = null,
        clasificacionFondo = null
    ) => {
        let params = {
            claveFondo: claveFondo,
            tipoFondo: tipoFondo,
            clasificacionFondo: clasificacionFondo,
            precios: true
        };
        this.props.onFilterClaveOperadora(params);
    };

    onFilterClaveFondoHandler = (
        claveOperadora = null,
        tipoFondo = null,
        clasificacionFondo = null
    ) => {
        let params = {
            claveOperadora: claveOperadora,
            tipoFondo: tipoFondo,
            clasificacionFondo: clasificacionFondo,
            precios: true
        };
        this.props.onFilterClaveFondo(params);
    };

    onFilterTipoFondoHandler = (
        claveOperadora = null,
        claveFondo = null,
        clasificacionFondo = null
    ) => {
        let params = {
            claveOperadora: claveOperadora,
            claveFondo: claveFondo,
            clasificacionFondo: clasificacionFondo,
            precios: true
        };
        this.props.onFilterTipoFondo(params);
    };

    onFilterClasificacionFondoHandler = (
        claveOperadora = null,
        claveFondo = null,
        tipoFondo = null
    ) => {
        let params = {
            claveOperadora: claveOperadora,
            claveFondo: claveFondo,
            tipoFondo: tipoFondo,
            precios: true
        };
        this.props.onFilterClasificacionFondo(params);
    };


    onFilterSerieFondoHandler = (
        claveOperadora = null,
        claveFondo = null,
        tipoFondo = null,
        clasificacionFondo = null
    ) => {
        let params = {
            claveOperadora: claveOperadora,
            claveFondo: claveFondo,
            tipoFondo: tipoFondo,
            clasificacionFondo: clasificacionFondo,
            precios: true
        };
        this.props.onFilterSerie(params);
    };

    printFrameHandler = () => {
        const input = this.showFiltersSelectedHandler();
        return (
            <div className="row">
                <div className="col sin_padding">
                    <div className="row">
                        <div
                            className={[
                                "col",
                                "col-lg-3",
                                "text-center",
                                classes.no_col_padding_model_one
                            ].join(" ")}
                        >
                            <div
                                className={[
                                    "texto_btn_cerrar", classes.toggle_button_container_model_one,
                                    this.state.toggleFilters
                                        ? classes.no_borders_filter_button_model_one
                                        : null
                                ].join(" ")}
                                onClick={
                                    this.state.disableEnableButtons ? this.onToggleFilters : null
                                }
                            >
                                <button
                                    type="button"
                                    className="btn"
                                    disabled={!this.state.disableEnableButtons}
                                >
                                    <i className="fas fa-filter" />
                                    <span>
                                        {this.state.toggleFilters
                                            ? getLan() === "es"
                                                ? "Cerrar filtros"
                                                : "Close"
                                            : getLan() === "es"
                                                ? "Filtrar"
                                                : "Filter"}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div
                            className={[
                                "col",
                                "col-lg-9",
                                classes.no_col_padding_model_one
                            ].join(" ")}
                        >
                            <div className={classes.filters_selected_container_model_one}>
                                {input}
                            </div>
                        </div>
                    </div>
                    <div
                        className={[
                            "row",
                            classes.main_filter_container_model_one,
                            !this.state.toggleFilters ? classes.hidden : null
                        ].join(" ")}
                    >
                        <div
                            className={["col", classes.no_col_padding_model_one].join(" ")}
                        >
                            {this.printModel()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    printModel = () => {
        return (
            <div className={classes.filters_container_model_one}>
                <div className={classes.filter_container_model_one}>
                    <Form
                        onSubmit={this.onSubmitFilterHandler}
                        initialValues={{
                            clave_operadora: this.state.form_filters.clave_operadora.value,
                            clave_fondo: this.state.form_filters.clave_fondo.value,
                            tipo_fondo: this.state.form_filters.tipo_fondo.value,
                            clasificacion_fondo: this.state.form_filters.clasificacion_fondo
                                .value,
                            fecha_inicio: this.state.form_filters.fecha_inicio.value,
                            fecha_fin: this.state.form_filters.fecha_fin.value,
                            serie: this.state.form_filters.serie.value
                        }}
                        render={({
                            optionsClaveOperadora = this.props.clave_operadora
                                ? this.onPrepareOptionHandler(this.props.clave_operadora)
                                : [],
                            optionsClaveFondo = this.props.clave_fondo
                                ? this.onPrepareOptionHandler(this.props.clave_fondo)
                                : [],
                            optionsTipoFondo = this.props.tipo_fondo
                                ? this.onPrepareOptionHandler(this.props.tipo_fondo)
                                : [],
                            optionsClasificacionFondo = this.props.clasificacion_fondo
                                ? this.onPrepareOptionHandler(this.props.clasificacion_fondo)
                                : [],
                            optionsSerie = this.props.serie ? this.onPrepareOptionHandler(this.props.serie) : [],
                            form
                        }) => (
                                <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-3  ">
                                            <Field
                                                name="clave_operadora"
                                                id="clave_operadora"
                                                label={getLan() === "es" ? "Clave de operadora" : "Operator "}
                                                type="select"
                                                elementType="select"
                                                classes="custom-select"
                                                options={optionsClaveOperadora}
                                                onInputChange={event =>
                                                    this.onInputSelectedHandler(event, 0)
                                                }
                                                component={renderInput}
                                            />
                                        </div>
                                        <div className={["col-12 col-sm-12 col-md-6 col-lg-3", !this.state.form_filters.clave_operadora.value ||
                                            this.state.form_filters.clave_operadora.value === ""
                                            ? classes.hidden
                                            : null
                                        ].join(" ")}>
                                            <Field
                                                name="clave_fondo"
                                                id="clave_fondo"
                                                label={getLan() === "es" ? "Clave de fondo" : "Ticker fund"}
                                                type="select"
                                                elementType="select"
                                                classes="custom-select"
                                                options={optionsClaveFondo}
                                                onInputChange={event =>
                                                    this.onInputSelectedHandler(event, 1)
                                                }
                                                component={renderInput}
                                            />
                                        </div>
                                        {/* <div className="col-12 col-sm-12 col-md-6 col-lg-3  ">
                                            <Field
                                                name="tipo_fondo"
                                                id="tipo_fondo"
                                                label={getLan() === "es" ? "Tipo de fondo" : "Fund type"}
                                                type="select"
                                                elementType="select"
                                                classes="custom-select"
                                                options={optionsTipoFondo}
                                                onInputChange={event =>
                                                    this.onInputSelectedHandler(event, 2)
                                                }
                                                component={renderInput}
                                            />
                                        </div>
                                        <div className={["col-12 col-sm-12 col-md-6 col-lg-3"].join(" ")}>
                                            <Field
                                                name="clasificacion_fondo"
                                                id="clasificacion_fondo"
                                                label={getLan() === "es" ? "Clasificación de fondo" : "Fund classification"}
                                                type="select"
                                                elementType="select"
                                                classes="custom-select"
                                                options={optionsClasificacionFondo}
                                                onInputChange={event =>
                                                    this.onInputSelectedHandler(event, 3)
                                                }
                                                component={renderInput}
                                            />
                                        </div>
                                        <div
                                            className={[
                                                "col-12 col-sm-6 col-md-6 col-lg-3",
                                                // optionsSerie.length === 0 ||
                                                !this.state.form_filters.clave_fondo.value ||
                                                    this.state.form_filters.clave_fondo.value === ""
                                                    ? classes.hidden
                                                    : null
                                            ].join(" ")}
                                        >
                                            <Field
                                                name="serie"
                                                id="serie"
                                                label={getLan() === "es" ? "Serie" : "Series"}
                                                elementType="select"
                                                type="select"
                                                classes="custom-select"
                                                isMultiple={true}
                                                options={optionsSerie}
                                                onInputChange={event => this.onInputSelectedHandler(event, 6)}
                                                component={renderInput}
                                            />
                                        </div>
                                        <div className={["col-12 col-sm-12 col-md-12 col-lg-6 sin_padding",
                                            !this.state.form_filters.clave_fondo.value ||
                                                this.state.form_filters.clave_fondo.value === ""
                                                ? "offset-lg-6"
                                                : "offset-lg-3"].join(" ")}>
                                            <div className="row">
                                                <div
                                                    className={["col-12 col-sm-12 col-md-6 col-lg-6"].join(" ")}
                                                >
                                                    <Field
                                                        name="fecha_inicio"
                                                        id="fecha_inicio"
                                                        type="date"
                                                        classes={classes.fechaInicio}
                                                        label={getLan() === "es" ? "Fecha inicio" : "From"}

                                                        onInputChange={event =>
                                                            this.onInputSelectedHandler(event, 4)
                                                        }
                                                        component={renderInput}
                                                    />
                                                </div>
                                                <div
                                                    className={["col-12 col-sm-12 col-md-6 col-lg-6"].join(" ")}
                                                >
                                                    <Field
                                                        name="fecha_fin"
                                                        id="fecha_fin"
                                                        type="date"
                                                        classes={classes.fechaFin}
                                                        label={getLan() === "es" ? "Fecha fin" : "To"}
                                                        onInputChange={event =>
                                                            this.onInputSelectedHandler(event, 5)
                                                        }
                                                        component={renderInput}
                                                    />
                                                </div>
                                                <div
                                                    className={[
                                                        classes.errorContainer,
                                                        !this.state.form_filters.errorDate
                                                            ? classes.hidden
                                                            : null
                                                    ].join(" ")}
                                                >
                                                    <p className={"texto_14 montse regular"}>
                                                        {getLan() === "es"
                                                            ? "La fecha final debe ser mayor a la fecha inicial."
                                                            : "End date must be greater than start date."}
                                                    </p>
                                                </div>
                                                <div
                                                    className={[
                                                        classes.errorContainer,
                                                        !this.state.form_filters.errorLimitDate
                                                            ? classes.hidden
                                                            : null
                                                    ].join(" ")}
                                                >
                                                    <p className={"texto_14 montse regular"}>
                                                        {getLan() === "es"
                                                            ? "La fecha final debe estar en un rango no mayor a 6 meses de la fecha inicial."
                                                            : "End date must be greater and within 6 months from start date."}
                                                    </p>
                                                </div>
                                                <div
                                                    className={[
                                                        classes.errorContainer,
                                                        !this.state.form_filters.errorLimitDateYear
                                                            ? classes.hidden
                                                            : null
                                                    ].join(" ")}
                                                >
                                                    <p className={"texto_14 montse regular"}>
                                                        {getLan() === "es"
                                                            ? "La fecha inicial debe estar en un rango no mayor a 5 años de antigüedad."
                                                            : "Start date must be within 5 years from today."}
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <hr />
                                    {this.sectionButtonHandler(form)}
                                </form>
                            )}
                    />
                </div>
            </div>
        );
    };

    sectionButtonHandler = form => {
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 offset-lg-8 text-center">
                    <button
                        type="button"
                        className={[
                            "btn",
                            classes.button_model_one,
                            "montse",
                            "texto_14"
                        ].join(" ")}
                        onClick={() => this.onResetFilterHandler()}
                    >
                        <i className="fas fa-redo-alt" />
                        {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
                    </button>
                </div>
            </div>
        );
    };

    // Verifica si las fechas ingresadas soon validas para realizar la busqueda
    onCheckIfDatesAreValidHandler = (inicio, fin) => {
        return inicio > fin;
    };

    // Permite ocultar o mostrar la seccion del formulario del filtro
    onToggleFilters = () => {
        this.setState(prevState => {
            return {
                toggleFilters: !prevState.toggleFilters
            };
        });
    };

    onSubmitFilterHandler = event => {
        event.preventDefault();
    };

    // Limpia le formulario
    onResetFilterHandler = () => {
        const form = this.state.form_filters;
        const selectedFilters = this.state.selectedFilters;

        // Limpiando el filtro clave_operadora
        form.clave_operadora.value = "";
        selectedFilters.clave_operadora = [];
        // Limpiando el filtro clave_fondo
        form.clave_fondo.value = "";
        selectedFilters.clave_fondo = [];
        // Limpiando el filtro tipo_fondo
        form.tipo_fondo.value = "";
        selectedFilters.tipo_fondo = [];
        // Limpiando el filtro clasificacion_fondo
        form.clasificacion_fondo.value = "";
        selectedFilters.clasificacion_fondo = [];
        // Limpiando el filtro clasificacion_fondo
        form.serie.value = "";
        selectedFilters.serie = [];
        // Se limpia errorData para validacion de fechas
        form.fecha_inicio.value = "";
        form.fecha_inicio.disabled = false;
        form.fecha_fin.value = "";
        form.fecha_fin.disabled = false;
        selectedFilters.fechas = [];
        form.errorDate = false;
        form.errorLimitDate = false;
        form.errorLimitDateYear = false;

        let inputPivot = false;
        let inputSubPivot = false;
        let prevInputSelected = false;

        this.onFilterClaveOperadoraHandler();
        this.onFilterClaveFondoHandler();
        // this.onFilterTipoFondoHandler();
        // this.onFilterClasificacionFondoHandler();
        // this.onFilterSerieFondoHandler();

        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters,
            inputPivot: inputPivot,
            inputSubPivot: inputSubPivot,
            prevInputSelected: prevInputSelected
        });

        this.onFilterDataByEvent(true);
        
    };

    // Formatea los valores de los campos select
    onPrepareOptionHandler = type => {
        let options = [];
        if (type && type.length > 0) {
            type.forEach(element => {
                options.push({
                    nombre: element.nombre,
                    id: `${element.id}_${element.nombre}`
                });
            });
        }
        return options;
    };

    // Retorna el id
    onSplitValueHandler = options => {
        let newValues = null;

        if (!Array.isArray(options)) {
            newValues = options.split("_")[0];
            return newValues;
        } else {
            newValues = [];
            options.forEach(option => {
                newValues.push(option.split("_")[0]);
            });
            return [...newValues];
        }
        // let newValues = null;

        // newValues = options.split("_")[0];
        // return newValues;
    };

    // Retorna el valor, etiqueta de una valor recibido como parametro
    onGetValueLabelAndTypeHandler = (value, type) => {
        let newValue = null;
        let newLabel = null;

        if (type !== 4 && type !== 5) {
            newValue = value.split("_")[0];
            newLabel = value.split("_")[1];
        } else {
            // Fechas inicio o fin
            [newValue] = changeDateFormat(value);
            newLabel = type === 4 ? "fecha_inicio" : "fecha_fin";
        }

        return [newValue, newLabel];
    };

    // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
    onGetNameStateFilterByInputHandler = type => {
        const form = this.state.form_filters;
        const selectedFilters = this.state.selectedFilters;

        switch (type) {
            case 0:
                return [
                    "clave_operadora",
                    form.clave_operadora.value,
                    selectedFilters.clave_operadora
                ];
            case 1:
                return [
                    "clave_fondo",
                    form.clave_fondo.value,
                    selectedFilters.clave_fondo
                ];
            case 2:
                return [
                    "tipo_fondo",
                    form.tipo_fondo.value,
                    selectedFilters.tipo_fondo
                ];
            case 3:
                return [
                    "clasificacion_fondo",
                    form.clasificacion_fondo.value,
                    selectedFilters.clasificacion_fondo
                ];
            case 4:
                return [
                    "fecha_inicio",
                    form.fecha_inicio.value,
                    selectedFilters.fechas,
                    "fechas"
                ];
            case 5:
                return [
                    "fecha_fin",
                    form.fecha_fin.value,
                    selectedFilters.fechas,
                    "fechas"
                ];
            case 6:
                return ["serie", form.serie.value, selectedFilters.serie];
        }
    };

    // Retorna todos los valores de los campos del formulario.
    onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
        const form = this.state.form_filters;

        if (inputName && newStateValues) {
            form[`${inputName}`].value = newStateValues;
        }

        // Se obtiene el valor del campo clave_operadora
        const valuesClaveOperadora =
            form.clave_operadora.value && form.clave_operadora.value !== ""
                ? this.onSplitValueHandler(form.clave_operadora.value)
                : "";
        // Se obtiene el valor del campo clave_fondo
        const valuesClaveFondo =
            form.clave_fondo.value && form.clave_fondo.value !== ""
                ? this.onSplitValueHandler(form.clave_fondo.value)
                : "";
        // Se obtiene el valor del campo tipo_fondo
        const valuesTipoFondo =
            form.tipo_fondo.value && form.tipo_fondo.value !== ""
                ? this.onSplitValueHandler(form.tipo_fondo.value)
                : "";
        // Se obtiene el valor del campo clasificacion_fondo
        const valuesClasificacionFondo =
            form.clasificacion_fondo.value && form.clasificacion_fondo.value !== ""
                ? this.onSplitValueHandler(form.clasificacion_fondo.value)
                : "";
        // Se obtiene el valor del campo fecha inicio
        const valuesFechaInicio =
            form.fecha_inicio.value && form.fecha_inicio.value !== ""
                ? form.fecha_inicio.value
                : "";
        // Se obtiene el valor del campo fecha fin
        const valuesFechaFin =
            form.fecha_fin.value && form.fecha_fin.value !== ""
                ? form.fecha_fin.value
                : "";
        // Se obtiene el valor del campo serie
        const valuesSerie = form.serie.value && form.serie.value.length > 0 ? this.onSplitValueHandler(form.serie.value) : [];
        // form.serie.value && form.serie.value !== ""
        //     ? this.onSplitValueHandler(form.serie.value)
        //     : "";

        return [
            valuesClaveOperadora,
            valuesClaveFondo,
            valuesTipoFondo,
            valuesClasificacionFondo,
            valuesFechaInicio,
            valuesFechaFin,
            valuesSerie
        ];
    };

    // Agrega o elimina valores de estado para un campo del formulario
    onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
        let newStateValues = null;
        if (type !== 6) {
            if (values && values !== "") {
                newStateValues = values;
            }
        } else {
            newStateValues = [...inputOptions];
            const stateExists = newStateValues.find(option => option === values);
            if (stateExists) {
                newStateValues = newStateValues.filter(option => option !== values);
            } else {
                if (values !== "") {
                    newStateValues.push(values);
                } else {
                    newStateValues = [];
                }
            }
        }
        return newStateValues;
    };

    // Agrega o elimina filtros a la barra de filtro seleccionados
    onChangeFilterHandler = (
        filterOptions = null,
        values = null,
        label = null,
        type = null
    ) => {
        let newFilterValues = [];

        if (type !== 4 && type !== 5 && type !== 6) {
            if (values && values !== "") {
                newFilterValues.push({
                    label: label,
                    value: values,
                    type: type
                });
            }
        } else {
            newFilterValues = [...filterOptions];
            const filterExists = newFilterValues.find(
                option => option.label === label
            );
            if (filterExists) {
                newFilterValues = newFilterValues.filter(
                    option => option.label !== label
                );
                // if (values && values !== "") {
                //     newFilterValues.push({ label: label, value: values, type: type });
                // }
            } else {
                // newFilterValues.push({ label: label, value: values, type: type });
                if (values !== "") {
                    newFilterValues.push({ label: label, value: values, type: type });
                } else {
                    newFilterValues = [];
                }
            }
        }

        return newFilterValues;
    };

    onFilterDataByEvent = (reset = false) => {
        // Se obtienen todos los valores de los campos del formulario (Filtro)
        const [
            valuesClaveOperadora,
            valuesClaveFondo,
            valuesTipoFondo,
            valuesClasificacionFondo,
            valuesFechaInicio,
            valuesFechaFin,
            valuesSerie
        ] = this.onGetAllValuesFormFilter();

        const params = {
            // size: this.props.itemsPerPage,
            page: 1,
            claveOperadora: valuesClaveOperadora,
            claveFondo: valuesClaveFondo,
            reset: reset
            // tipoFondo: valuesTipoFondo,
            // clasificacionFondo: valuesClasificacionFondo,
            // serie: valuesSerie,
            // fechaInicio: valuesFechaInicio,
            // fechaFin: valuesFechaFin

        };
        // this.props.setFilters(params);

        // this.props.setPage(1);

        let wasFiltered = false;
        if (!this.state.form_filters.errorDate && !this.state.form_filters.errorLimitDate && !this.state.form_filters.errorLimitDateYear) {

            this.props.setFilters(params);

            this.props.setPage(1);

            // Se invoca la funcion del componente padre (ConcentradoPreciosFondo.js) para setear los valores correspondientes
            // Configurando valor del campo clave_operadora
            this.props.onSetClaveOperadora(valuesClaveOperadora);
            // Configurando valor del campo clave_fondo
            this.props.onSetClaveFondo(valuesClaveFondo);
            // Configurando valor del campo tipo_fondo
            this.props.onSetTipoFondo(valuesTipoFondo);
            // Configurando valor del campo clasificacion_fondo
            this.props.onSetClasificacionFondo(valuesClasificacionFondo);
            // Configurando valor del campo clasificacion_fondo
            this.props.onSetSerie(valuesSerie);
            // Configurando valor del campo fecha_inicio
            this.props.onSetFechaInicio(valuesFechaInicio);
            // Configurando valor del campo fecha_fin
            this.props.onSetFechaFin(valuesFechaFin);

            if (
                (valuesClaveOperadora !== "" ||
                    valuesClaveFondo !== "" ||
                    valuesTipoFondo !== "" ||
                    valuesClasificacionFondo !== "",
                    valuesSerie !== "",
                    valuesFechaInicio !== "",
                    valuesFechaFin !== "")
            ) {
                wasFiltered = true;
            }
            this.props.onSetWasFiltered(wasFiltered);
        }

    };

    // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
    onInputSelectedHandler = (
        event = null,
        type = null,
        item = null,
        eraser = null
    ) => {
        let actualValue = eraser ? event : event.target.value;

        let form = this.state.form_filters;
        let selectedFilters = this.state.selectedFilters;
        let inputPivot = this.state.inputPivot;
        let inputSubPivot = this.state.inputSubPivot;
        let prevInputSelected = this.state.prevInputSelected;
        // let errorDate = form.errorDate;
        // let errorLimitDate = form.errorLimitDate;
        // let errorLimitDateYear = form.errorLimitDateYear;

        let newValue = null;
        let newLabel = null;
        if (eraser) {
            actualValue = "";
            newValue = "";
            newLabel = item.label;
            type = item.type;
        } else {
            // Solo para los campos distintos a tipo instrumento y tipo valor
            [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(
                actualValue,
                type
            );
        }

        let newStateValues = null;
        let newFilterValues = null;
        let [
            inputName,
            stateValues,
            filterValues,
            otherInputName
        ] = this.onGetNameStateFilterByInputHandler(type);

        // Solo para el campo serie
        // if (type === 6 ) {
        //     const options = [...actualValue];
        //     const indexSelected = actualValue.selectedIndex && actualValue.selectedIndex !== -1 ? actualValue.selectedIndex : 0;

        //     newValue = options[indexSelected].value;
        //     newLabel = options[indexSelected].value.split("_")[1];

        //     actualValue = newValue;
        // }

        // Configura el estado y el filtro seleccionado para cada input
        newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
        newFilterValues = this.onChangeFilterHandler(
            filterValues,
            newValue,
            newLabel,
            type
        );
        // form[`${inputName}`].value = newStateValues;
        // selectedFilters[`${inputName}`] = newFilterValues;

        form[`${inputName}`].value = newStateValues;
        selectedFilters[
            `${otherInputName ? otherInputName : inputName}`
        ] = newFilterValues;

        // Se obtienen todos los valores de los campos del formulario (Filtro)
        const [
            valuesClaveOperadora,
            valuesClaveFondo,
            valuesTipoFondo,
            valuesClasificacionFondo,
            // valuesFechaInicio,
            // valuesFechaFin
        ] = this.onGetAllValuesFormFilter(inputName, newStateValues);
        if (type === 0) {
            // Clave Operadora
            // Cuando el campo clave_operadora es seleccionado de primero
            if (!inputPivot || inputPivot === "firstPath") {
                // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
                if (eraser) {
                    inputPivot = false;
                    inputSubPivot = false;
                    prevInputSelected = false;
                } else {
                    // Se configura a null inputPivot si el valor de clave_operadora es vacio
                    inputPivot = valuesClaveOperadora !== "" ? "firstPath" : null;
                }
                // Limpiando valores campo clave_fondo
                form.clave_fondo.value = "";
                selectedFilters.clave_fondo = [];
                // Filtrando valores para campo clave_fondo
                this.onFilterClaveFondoHandler(valuesClaveOperadora);
                // Limpiando valores campo tipo_fondo
                // form.tipo_fondo.value = "";
                // selectedFilters.tipo_fondo = [];
                // // Filtrando valores para campo tipo_fondo
                // this.onFilterTipoFondoHandler(valuesClaveOperadora);
                // // Limpiando valores campo clasificacion_fondo
                // form.clasificacion_fondo.value = "";
                // selectedFilters.clasificacion_fondo = [];
                // // Filtrando valores para campo clasificacion_fondo
                // this.onFilterClasificacionFondoHandler(valuesClaveOperadora);
            // } else if (inputPivot === "secondPath") {
                // if (!inputSubPivot || inputSubPivot === "clave_operadora") {
                //     // Cuando el campo clave_operadora es seleccionado de segundo
                //     // Cuando se elimina el filtro desde la barrra de filtros seleccionados
                //     if (eraser) {
                //         inputSubPivot = false;
                //         prevInputSelected = false;
                //     } else {
                //         // Se configura a null inputSubPivot si el valor de clave_operadora es vacio
                //         inputSubPivot =
                //             valuesClaveOperadora !== "" ? "clave_operadora" : null;
                //     }
                //     // Limpiando valores campo tipo siefore
                //     form.tipo_fondo.value = "";
                //     selectedFilters.tipo_fondo = [];
                //     // Filtrando valores para campo tipo siefore
                //     this.onFilterTipoFondoHandler(valuesClaveOperadora, valuesClaveFondo);
                //     // Limpiando valores campo clasificacion siefore
                //     form.clasificacion_fondo.value = "";
                //     selectedFilters.clasificacion_fondo = [];
                //     // Filtrando valores para campo clasificacion_fondo
                //     this.onFilterClasificacionFondoHandler(
                //         valuesClaveOperadora,
                //         valuesClaveFondo
                //     );
                // } else if (inputSubPivot === "tipo_fondo") {
                //     // Cuando el campo tipo_fondo es seleccionado de segundo y el campo clave_operadora de tercero
                //     // Si se el campo clave_operadora se setea a vacio.
                //     if (eraser && prevInputSelected === "clave_operadora") {
                //         prevInputSelected = false;
                //     } else {
                //         // Se verifica si el campo clave_operadora es el penultimo seleccionado.
                //         prevInputSelected =
                //             prevInputSelected === "clasificacion_fondo"
                //                 ? "clasificacion_fondo"
                //                 : "clave_operadora";
                //     }
                //     // Si el campo clave_afore es el penultimo seleccionado se filtra el campo clasificacion_siefore
                //     if (
                //         !prevInputSelected ||
                //         prevInputSelected !== "clasificacion_fondo"
                //     ) {
                //         // Limpiando valores campo clasificacion_fondo
                //         form.clasificacion_fondo.value = "";
                //         selectedFilters.clasificacion_fondo = [];
                //         // Filtrand valores para campo clasificacion_fondo
                //         this.onFilterClasificacionFondoHandler(
                //             valuesClaveOperadora,
                //             valuesClaveFondo,
                //             valuesTipoFondo
                //         );
                //     }
                // } else if (inputSubPivot === "clasificacion_fondo") {
                //     // Cuando el campo clasificacion_fondo es seleccionado de segundo y el campo clave_operadora de tercero
                //     // Si se el campo clave_operadora se setea a vacio.
                //     if (eraser && prevInputSelected === "clave_operadora") {
                //         prevInputSelected = false;
                //     } else {
                //         // Se verifica si el campo clave_operadora es el penultimo seleccionado.
                //         prevInputSelected =
                //             prevInputSelected === "tipo_fondo"
                //                 ? "tipo_fondo"
                //                 : "clave_operadora";
                //     }
                //     // Si el campo clave_afore es el penultimo seleccionado se filtra el campo tipo_siefore
                //     if (!prevInputSelected || prevInputSelected !== "tipo_fondo") {
                //         // Limpiando valores campo tipo_fondo
                //         form.tipo_fondo.value = "";
                //         selectedFilters.tipo_fondo = [];
                //         // Filtrand valores para campo tipo_fondo
                //         this.onFilterTipoFondoHandler(
                //             valuesClaveOperadora,
                //             valuesClaveFondo,
                //             valuesClasificacionFondo
                //         );
                //     }
                // }
            // } else if (inputPivot === "thirdPath") {
            //     if (!inputSubPivot || inputSubPivot === "clave_operadora") {
            //         // Cuando el campo clave_operadora es seleccionado de segundo
            //         // Cuando se elimina el filtro desde la barrra de filtros seleccionados
            //         if (eraser) {
            //             inputSubPivot = false;
            //             prevInputSelected = false;
            //         } else {
            //             // Se configura a null inputSubPivot si el valor de clave_operadora es vacio
            //             inputSubPivot =
            //                 valuesClaveOperadora !== "" ? "clave_operadora" : null;
            //         }
            //         // Limpiando valores campo clave_fondo
            //         form.clave_fondo.value = "";
            //         selectedFilters.clave_fondo = [];
            //         // Filtrando valores para campo clave_fondo
            //         this.onFilterClaveFondoHandler(valuesClaveOperadora, valuesTipoFondo);
            //         // Limpiando valores campo clavesificacion_fondo
            //         form.clasificacion_fondo.value = "";
            //         selectedFilters.clasificacion_fondo = [];
            //         // Filtrando valores para campo clavesificacion_fondo
            //         this.onFilterClasificacionFondoHandler(
            //             valuesClaveOperadora,
            //             null,
            //             valuesTipoFondo
            //         );
            //     } else if (inputSubPivot === "clave_fondo") {
            //         // Cuando el campo clave_fondo es seleccionado de segundo y el campo clave_operadora de tercero
            //         // Si se el campo clave_operadora se setea a vacio.
            //         if (eraser && prevInputSelected === "clave_operadora") {
            //             prevInputSelected = false;
            //         } else {
            //             // Se verifica si el campo clave_operadora es el penultimo seleccionado.
            //             prevInputSelected =
            //                 prevInputSelected === "clasificacion_fondo"
            //                     ? "clasificacion_fondo"
            //                     : "clave_operadora";
            //         }
            //         // Si el campo clave_afore es el penultimo seleccionado se filtra el campo clasificacion_siefore.
            //         if (
            //             !prevInputSelected ||
            //             prevInputSelected !== "clasificacion_fondo"
            //         ) {
            //             // Limpiando valores campo clasificacion siefore
            //             form.clasificacion_fondo.value = "";
            //             selectedFilters.clasificacion_fondo = [];
            //             // Filtrando valores para campo clasificacion siefore
            //             this.onFilterClasificacionFondoHandler(
            //                 valuesClaveOperadora,
            //                 valuesClaveFondo,
            //                 valuesTipoFondo
            //             );
            //         }
            //     } else if (inputSubPivot === "clasificacion_fondo") {
            //         // Cuando el campo clasificacion_fondo es seleccionado de segundo y el campo clave_operadora de tercero
            //         // Si se el campo clave_operadora se setea a vacio.
            //         if (eraser && prevInputSelected === "clave_operadora") {
            //             prevInputSelected = false;
            //         } else {
            //             // Se verifica si el campo clave_operadora es el penultimo seleccionado.
            //             prevInputSelected =
            //                 prevInputSelected === "clave_fondo"
            //                     ? "clave_fondo"
            //                     : "clave_operadora";
            //         }
            //         // Si el campo clave_afore es el penultimo seleccionado se filtra el campo clave_siefore.
            //         if (!prevInputSelected || prevInputSelected !== "clave_fondo") {
            //             // Limpiando valores campo clave_fondo
            //             form.clave_fondo.value = "";
            //             selectedFilters.clave_fondo = [];
            //             // Filtrando valores para campo clave_fondo
            //             this.onFilterClaveFondoHandler(
            //                 valuesClaveOperadora,
            //                 valuesTipoFondo,
            //                 valuesClasificacionFondo
            //             );
            //         }
            //     }
            // } else if (inputPivot === "fourthPath") {
            //     if (!inputSubPivot || inputSubPivot === "clave_operadora") {
            //         // Cuando el campo clave_operadora es seleccionado de segundo
            //         // Cuando se elimina el filtro desde la barrra de filtros seleccionados
            //         if (eraser) {
            //             inputSubPivot = false;
            //         } else {
            //             // Se configura a null inputSubPivot si el valor de clave_operadora es vacio
            //             inputSubPivot =
            //                 valuesClaveOperadora !== "" ? "clave_operadora" : null;
            //         }
            //         // Limpiando valores campo clave_fondo
            //         form.clave_fondo.value = "";
            //         selectedFilters.clave_fondo = [];
            //         // Filtrando valores para campo clave_fondo
            //         this.onFilterClaveFondoHandler(
            //             valuesClaveOperadora,
            //             null,
            //             valuesClasificacionFondo
            //         );
            //         // Limpiando valores campo tipo_fondo
            //         form.tipo_fondo.value = "";
            //         selectedFilters.tipo_fondo = [];
            //         // Filtrando valores para campo tipo_fondo
            //         this.onFilterTipoFondoHandler(
            //             valuesClaveOperadora,
            //             null,
            //             valuesClasificacionFondo
            //         );
            //     } else if (inputSubPivot === "clave_fondo") {
            //         // Cuando el campo clave_fondo es seleccionado de segundo y el campo clave_operadora de tercero
            //         // Si se el campo clave_operadora se setea a vacio.
            //         if (eraser && prevInputSelected === "clave_operadora") {
            //             prevInputSelected = false;
            //         } else {
            //             // Se verifica si el campo clave_afore es el penultimo seleccionad.
            //             prevInputSelected =
            //                 prevInputSelected === "tipo_fondo"
            //                     ? "tipo_fondo"
            //                     : "clave_operadora";
            //         }
            //         // Si el campo clave_afore es el penultimo se filtra el campo tipo_siefore
            //         if (!prevInputSelected || prevInputSelected !== "tipo_fondo") {
            //             // Limpiando valores campo tipo siefore
            //             form.tipo_fondo.value = "";
            //             selectedFilters.tipo_fondo = [];
            //             // Filtrand valores para campo tipo siefore
            //             this.onFilterTipoFondoHandler(
            //                 valuesClaveOperadora,
            //                 valuesClaveFondo,
            //                 valuesClasificacionFondo
            //             );
            //         }
            //     } else if (inputSubPivot === "tipo_fondo") {
            //         // Cuando el campo tipo_fondo es seleccionado de segundo y el campo clave_operadora de tercero
            //         // Si se el campo clave_operadora se setea a vacio.
            //         if (eraser && prevInputSelected === "clave_operadora") {
            //             prevInputSelected = false;
            //         } else {
            //             // Se verifica si el campo clave_operadora es el penultimo seleccionado.
            //             prevInputSelected =
            //                 prevInputSelected === "clave_fondo"
            //                     ? "clave_fondo"
            //                     : "clave_operadora";
            //         }
            //         // Si el campo clave_operadora es el penultimo se filtra el campo clave_fondo
            //         if (!prevInputSelected || prevInputSelected !== "clave_fondo") {
            //             // Limpiando valores campo clave siefore
            //             form.clave_fondo.value = "";
            //             selectedFilters.clave_fondo = [];
            //             // Filtrando valores para campo clave siefore
            //             this.onFilterClaveFondoHandler(
            //                 valuesClaveOperadora,
            //                 valuesTipoFondo,
            //                 valuesClasificacionFondo
            //             );
            //         }
            //     }
            }
        } else if (type === 1) {
            // Clave Fondo
            // Cuando el campo clave_fondo es seleccionado de primero
            if (!inputPivot || inputPivot === "secondPath") {
                // Cuando se elimina el filtro desde la barrra de filtros seleccionados
                if (eraser) {
                    inputPivot = false;
                    inputSubPivot = false;
                    prevInputSelected = false;
                } else {
                    // Se configura a null inputPivot si el valor de clave_fondo es vacio
                    inputPivot = valuesClaveFondo !== "" ? "secondPath" : null;
                }
                // Limpiando valores campo clave_operadora
                // form.clave_operadora.value = "";
                // selectedFilters.clave_operadora = [];
                // // Filtrando valores para campo clave_operadora
                // this.onFilterClaveOperadoraHandler(valuesClaveFondo);
                // // Limpiando valores campo tipo siefore
                // form.tipo_fondo.value = "";
                // selectedFilters.tipo_fondo = [];
                // Filtrando valores para campo tipo siefore
                // this.onFilterTipoFondoHandler(null, valuesClaveFondo);
                // // Limpiando valores campo clasificacion_fondo
                // form.clasificacion_fondo.value = "";
                // selectedFilters.clasificacion_fondo = [];
                // // Filtrando valores para campo clasificacion_fondo
                // this.onFilterClasificacionFondoHandler(null, valuesClaveFondo);
                // // Limpiando valores campo serie
                // form.serie.value = "";
                // selectedFilters.serie = [];
                // // Filtrando valores para campo serie
                // this.onFilterSerieFondoHandler(null, valuesClaveFondo);
            } else if (inputPivot === "firstPath") {
                if (!inputSubPivot || inputSubPivot === "clave_fondo") {
                    // Cuando el campo clave_fondo es seleccionado de segundo
                    // Cuando se elimina el filtro desde la barrra de filtros seleccionados
                    if (eraser) {
                        inputSubPivot = false;
                        prevInputSelected = false;
                    } else {
                        // Se configura a null inputSubPivot si el valor de clave afore es vacio
                        inputSubPivot = valuesClaveFondo !== "" ? "clave_fondo" : null;
                    }
                    // Limpiando valores campo tipo siefore
                    form.tipo_fondo.value = "";
                    selectedFilters.tipo_fondo = [];
                    // Filtrando valores para campo tipo siefore
                    // this.onFilterTipoFondoHandler(valuesClaveOperadora, valuesClaveFondo);
                    // // Limpiando valores campo clasificacion_fondo
                    // form.clasificacion_fondo.value = "";
                    // selectedFilters.clasificacion_fondo = [];
                    // // Filtrando valores para campo clasificacion_fondo
                    // this.onFilterClasificacionFondoHandler(
                    //     valuesClaveOperadora,
                    //     valuesClaveFondo
                    // );
                    // // Limpiando valores campo serie
                    // form.serie.value = "";
                    // selectedFilters.serie = [];
                    // // Filtrando valores para campo serie
                    // this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                } else if (inputSubPivot === "tipo_fondo") {
                    // Cuando el campo tipo_fondo es seleccionado de segundo y el campo clave_fondo de tercero
                    // Si se el campo clave_fondo se setea a vacio.
                    if (eraser && prevInputSelected === "clave_fondo") {
                        prevInputSelected = false;
                    } else {
                        // Se verifica si el campo clave_fondo es el penultimo seleccionado.
                        prevInputSelected =
                            prevInputSelected === "clasificacion_fondo"
                                ? "clasificacion_fondo"
                                : "clave_fondo";
                    }
                    // Si el campo clave_siefore es el penultimo se filtra el campo clasificacion_siefore
                    if (
                        !prevInputSelected ||
                        prevInputSelected !== "clasificacion_fondo"
                    ) {
                        // Limpiando valores campo clasificacion_fondo
                        form.clasificacion_fondo.value = "";
                        selectedFilters.clasificacion_fondo = [];
                        // Filtrando valores para campo clasificacion_fondo
                        this.onFilterClasificacionFondoHandler(
                            valuesClaveOperadora,
                            valuesClaveFondo,
                            valuesTipoFondo
                        );
                        // Limpiando valores campo serie
                        form.serie.value = "";
                        selectedFilters.serie = [];
                        // Filtrando valores para campo serie
                        this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                    }
                } else if (inputSubPivot === "clasificacion_fondo") {
                    // Cuando el campo clasificacion_fondo es seleccionado de segundo y el campo clave_fondo de tercero
                    // Si se el campo clave_fondo se setea a vacio.
                    if (eraser && prevInputSelected === "clave_fondo") {
                        prevInputSelected = false;
                    } else {
                        // Se verifica si el campo clave_fondo es el penultimo seleccionado.
                        prevInputSelected =
                            prevInputSelected === "tipo_fondo" ? "tipo_fondo" : "clave_fondo";
                    }

                    // Si el campo clave_siefore es el penultimo se filtra el campo tipo_siefore
                    if (!prevInputSelected || prevInputSelected !== "tipo_fondo") {
                        // Limpiando valores campo tipo siefore
                        form.tipo_fondo.value = "";
                        selectedFilters.tipo_fondo = [];
                        // Filtrando valores para campo tipo siefore
                        this.onFilterTipoFondoHandler(
                            valuesClaveOperadora,
                            valuesClaveFondo,
                            valuesClasificacionFondo
                        );
                        // Limpiando valores campo serie
                        form.serie.value = "";
                        selectedFilters.serie = [];
                        // Filtrando valores para campo serie
                        this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                    }
                }
            } else if (inputPivot === "thirdPath") {
                if (!inputSubPivot || inputSubPivot === "clave_fondo") {
                    // Cuando el campo clave_fondo es seleccionado de segundo
                    // Cuando se elimina el filtro desde la barrra de filtros seleccionados
                    if (eraser) {
                        inputSubPivot = false;
                        prevInputSelected = false;
                    } else {
                        // Se configura a null inputSubPivot si el valor de clave afore es vacio
                        inputSubPivot = valuesClaveFondo !== "" ? "clave_fondo" : null;
                    }
                    // Limpiando valores campo clave_operadora
                    form.clave_operadora.value = "";
                    selectedFilters.clave_operadora = [];
                    // Filtrando valores para campo clave_operadora
                    this.onFilterClaveOperadoraHandler(valuesClaveFondo, valuesTipoFondo);
                    // Limpiando valores campo clasificacion_fondo
                    form.clasificacion_fondo.value = "";
                    selectedFilters.clasificacion_fondo = [];
                    // Filtrando valores para campo clasificacion_fondo
                    this.onFilterClasificacionFondoHandler(
                        null,
                        valuesClaveFondo,
                        valuesTipoFondo
                    );
                    // Limpiando valores campo serie
                    form.serie.value = "";
                    selectedFilters.serie = [];
                    // Filtrando valores para campo serie
                    this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                } else if (inputSubPivot === "clave_operadora") {
                    // Cuando el campo clave_operadora es seleccionado de segundo y el campo clave_fondo de tercero
                    // Si se el campo clave_fondo se setea a vacio.
                    if (eraser && prevInputSelected === "clave_fondo") {
                        prevInputSelected = false;
                    } else {
                        // Se verifica si el campo clave_fondo es el penultimo seleccionado.
                        prevInputSelected =
                            prevInputSelected === "clasificacion_fondo"
                                ? "clasificacion_fondo"
                                : "clave_fondo";
                    }
                    // Si el campo clave_siefore es el penultimo se filtr el campo clasificacion_siefore
                    if (
                        !prevInputSelected ||
                        prevInputSelected !== "clasificacion_fondo"
                    ) {
                        // Limpiando valores campo clasificacion_fondo
                        form.clasificacion_fondo.value = "";
                        selectedFilters.clasificacion_fondo = [];
                        // Filtrando valores para campo clasificacion_fondo
                        this.onFilterClasificacionFondoHandler(
                            valuesClaveOperadora,
                            valuesClaveFondo,
                            valuesTipoFondo
                        );
                        // Limpiando valores campo serie
                        form.serie.value = "";
                        selectedFilters.serie = [];
                        // Filtrando valores para campo serie
                        this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                    }
                } else if (inputSubPivot === "clasificacion_fondo") {
                    // Cuando el campo clasificacion_fondo es seleccionado de segundo y el campo clave_fondo de tercero
                    // Si se el campo clave_fondo se setea a vacio.
                    if (eraser && prevInputSelected === "clave_fondo") {
                        prevInputSelected = false;
                    } else {
                        // Se verifica si el campo clave_fondo es el penultimo seleccionado.
                        prevInputSelected =
                            prevInputSelected === "clave_operadora"
                                ? "clave_operadora"
                                : "clave_fondo";
                    }
                    // Si el campo clave_siefore es el penultimo se filtra el campo clave_afore
                    if (!prevInputSelected || prevInputSelected !== "clave_operadora") {
                        // Limpiando valores campo clave_operadora
                        form.clave_operadora.value = "";
                        selectedFilters.clave_operadora = [];
                        // Filtrando valores para campo clave_operadora
                        this.onFilterClaveOperadoraHandler(
                            valuesClaveFondo,
                            valuesTipoFondo,
                            valuesClasificacionFondo
                        );
                        // Limpiando valores campo serie
                        form.serie.value = "";
                        selectedFilters.serie = [];
                        // Filtrando valores para campo serie
                        this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                    }
                }
            } else if (inputPivot === "fourthPath") {
                if (!inputSubPivot || inputSubPivot === "clave_fondo") {
                    // Cuando el campo clave_operadora es seleccionado de segundo
                    // Cuando se elimina el filtro desde la barrra de filtros seleccionados
                    if (eraser) {
                        inputSubPivot = false;
                        prevInputSelected = false;
                    } else {
                        // Se configura a null inputSubPivot si el valor de clave_operadora es vacio
                        inputSubPivot = valuesClaveFondo !== "" ? "clave_fondo" : null;
                    }
                    // Limpiando valores campo clave_operadora
                    form.clave_operadora.value = "";
                    selectedFilters.clave_operadora = [];
                    // Filtrando valores para campo clave_operadora
                    this.onFilterClaveOperadoraHandler(
                        valuesClaveFondo,
                        null,
                        valuesClasificacionFondo
                    );
                    // Limpiando valores campo tipo_fondo
                    form.tipo_fondo.value = "";
                    selectedFilters.tipo_fondo = [];
                    // Filtrando valores para campo tipo_fondo
                    this.onFilterTipoFondoHandler(
                        null,
                        valuesClaveFondo,
                        valuesClasificacionFondo
                    );
                    // Limpiando valores campo serie
                    form.serie.value = "";
                    selectedFilters.serie = [];
                    // Filtrando valores para campo serie
                    this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                } else if (inputSubPivot === "clave_operadora") {
                    // Cuando el campo clave_operadora es seleccionado de segundo y el campo clave_fondo de tercero
                    // Si se el campo clave_fondo se setea a vacio.
                    if (eraser && prevInputSelected === "clave_fondo") {
                        prevInputSelected = false;
                    } else {
                        // Se verifica si el campo clave_fondo es el penultimo seleccionado.
                        prevInputSelected =
                            prevInputSelected === "tipo_fondo" ? "tipo_fondo" : "clave_fondo";
                    }
                    // Si el campo clave_fondo es el penultimo se filtra el campo tipo_fondo
                    if (!prevInputSelected || prevInputSelected !== "tipo_fondo") {
                        // Limpiando valores campo tipo siefore
                        form.tipo_fondo.value = "";
                        selectedFilters.tipo_fondo = [];
                        // Filtrand valores para campo tipo siefore
                        this.onFilterTipoFondoHandler(
                            valuesClaveOperadora,
                            valuesClaveFondo,
                            valuesClasificacionFondo
                        );
                        // Limpiando valores campo serie
                        form.serie.value = "";
                        selectedFilters.serie = [];
                        // Filtrando valores para campo serie
                        this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                    }
                } else if (inputSubPivot === "tipo_fondo") {
                    // Cuando el campo tipo_fondo es seleccionado de segundo y el campo clave_operadora de tercero
                    // Si se el campo clave_fondo se setea a vacio.
                    if (eraser && prevInputSelected === "clave_fondo") {
                        prevInputSelected = false;
                    } else {
                        // Se verifica si el campo clave_fondo es el penultimo seleccionado.
                        prevInputSelected =
                            prevInputSelected === "clave_operadora"
                                ? "clave_operadora"
                                : "clave_fondo";
                    }
                    // Si el campo clave_fondo es el penultimo se filtra el campo clave_operadora
                    if (!prevInputSelected || prevInputSelected !== "clave_operadora") {
                        // Limpiando valores campo clave_operadora
                        form.clave_operadora.value = "";
                        selectedFilters.clave_operadora = [];
                        // Filtrando valores para campo clave_operadora
                        this.onFilterClaveOperadoraHandler(
                            valuesClaveFondo,
                            valuesTipoFondo,
                            valuesClasificacionFondo
                        );
                        // Limpiando valores campo serie
                        form.serie.value = "";
                        selectedFilters.serie = [];
                        // Filtrando valores para campo serie
                        this.onFilterSerieFondoHandler(null, valuesClaveFondo);
                    }
                }
            }
        // } else if (type === 2) {
        //     // tipo de siefore
        //     // Cuando el campo tipo_fondo es seleccionado de primero
        //     if (!inputPivot || inputPivot === "thirdPath") {
        //         // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //         if (eraser) {
        //             inputPivot = false;
        //             inputSubPivot = false;
        //             prevInputSelected = false;
        //         } else {
        //             // Se configura a null inputPivot si el valor de tipo_fondo es vacio
        //             inputPivot = valuesTipoFondo !== "" ? "thirdPath" : null;
        //         }
        //         // Limpiando valores campo clave_operadora
        //         form.clave_operadora.value = "";
        //         selectedFilters.clave_operadora = [];
        //         // Filtrando valores para campo clave_operadora
        //         this.onFilterClaveOperadoraHandler(null, valuesTipoFondo);
        //         // Limpiando valores campo clave_fondo
        //         form.clave_fondo.value = "";
        //         selectedFilters.clave_fondo = [];
        //         // Filtrando valores para campo clave_fondo
        //         this.onFilterClaveFondoHandler(null, valuesTipoFondo);
        //         // Limpiando valores campo clasificacion_fondo
        //         form.clasificacion_fondo.value = "";
        //         selectedFilters.clasificacion_fondo = [];
        //         // Filtrando valores para campo clasificacion_fondo
        //         this.onFilterClasificacionFondoHandler(null, null, valuesTipoFondo);
        //     } else if (inputPivot === "firstPath") {
        //         if (!inputSubPivot || inputSubPivot === "tipo_fondo") {
        //             // Cuando el campo tipo_fondo es seleccionado de segundo
        //             // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //             if (eraser) {
        //                 inputSubPivot = false;
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se configura a null inputSubPivot si el valor de tipo_fondo es vacio
        //                 inputSubPivot = valuesTipoFondo !== "" ? "tipo_fondo" : null;
        //             }
        //             // Limpiando valores campo clave_fondo
        //             form.clave_fondo.value = "";
        //             selectedFilters.clave_fondo = [];
        //             // Filtrando valores para campo clave_fondo
        //             this.onFilterClaveFondoHandler(valuesClaveOperadora, valuesTipoFondo);
        //             // Limpiando valores campo clasificacion_fondo
        //             form.clasificacion_fondo.value = "";
        //             selectedFilters.clasificacion_fondo = [];
        //             // Filtrando valores para campo clasificacion_fondo
        //             this.onFilterClasificacionFondoHandler(
        //                 valuesClaveOperadora,
        //                 null,
        //                 valuesTipoFondo
        //             );
        //         } else if (inputSubPivot === "clave_fondo") {
        //             // Cuando el campo clave_fondo es seleccionado de segundo y el campo tipo_fondo de tercero
        //             // Si se el campo tipo_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "tipo_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo tipo_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clasificacion_fondo"
        //                         ? "clasificacion_fondo"
        //                         : "tipo_fondo";
        //             }
        //             // Si el campo tipo_fondo es el penultimo se filtra el campo clasificacion_fondo
        //             if (
        //                 !prevInputSelected ||
        //                 prevInputSelected !== "clasificacion_fondo"
        //             ) {
        //                 // Limpiando valores campo clasificacion_fondo
        //                 form.clasificacion_fondo.value = "";
        //                 selectedFilters.clasificacion_fondo = [];
        //                 // Filtrando valores para campo clasificacion_fondo
        //                 this.onFilterClasificacionFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesClaveFondo,
        //                     valuesTipoFondo
        //                 );
        //             }
        //         } else if (inputSubPivot === "clasificacion_fondo") {
        //             // Cuando el campo clasificacion_fondo es seleccionado de segundo y el campo tipo_fondo de tercero
        //             // Si se el campo tipo_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "tipo_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo tipo_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_fondo"
        //                         ? "clave_fondo"
        //                         : "tipo_fondo";
        //             }
        //             // Si el campo tipo_fondo es el penultimo se filtra el campo clave_fondo
        //             if (!prevInputSelected || prevInputSelected !== "clave_fondo") {
        //                 // Limpiando valores campo clave_fondo
        //                 form.clave_fondo.value = "";
        //                 selectedFilters.clave_fondo = [];
        //                 // Filtrando valores para campo clave_fondo
        //                 this.onFilterClaveFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         }
        //     } else if (inputPivot === "secondPath") {
        //         if (!inputSubPivot || inputSubPivot === "tipo_fondo") {
        //             // Cuando el campo clave_fondo es seleccionado de segundo
        //             // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //             if (eraser) {
        //                 inputSubPivot = false;
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se configura a null inputSubPivot si el valor de tipo_fondo es vacio
        //                 inputSubPivot = valuesTipoFondo !== "" ? "tipo_fondo" : null;
        //             }
        //             // Limpiando valores campo clave_operadora
        //             form.clave_operadora.value = "";
        //             selectedFilters.clave_operadora = [];
        //             // Filtrando valores para campo clave_fondo
        //             this.onFilterClaveOperadoraHandler(valuesClaveFondo, valuesTipoFondo);
        //             // Limpiando valores campo clasificacion_fondo
        //             form.clasificacion_fondo.value = "";
        //             selectedFilters.clasificacion_fondo = [];
        //             // Filtrando valores para campo clasificacion_fondo
        //             this.onFilterClasificacionFondoHandler(
        //                 null,
        //                 valuesClaveFondo,
        //                 valuesTipoFondo
        //             );
        //         } else if (inputSubPivot === "clave_operadora") {
        //             // Cuando el campo clave_operadora es seleccionado de segundo y el campo tipo_fondo de tercero
        //             // Si se el campo tipo_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "tipo_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo tipo_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clasificacion_fondo"
        //                         ? "clasificacion_fondo"
        //                         : "tipo_fondo";
        //             }
        //             // Si el campo tipo_siefore es el penultimo se filtra el campo cclasificacion_siefore
        //             if (
        //                 !prevInputSelected ||
        //                 prevInputSelected !== "clasificacion_fondo"
        //             ) {
        //                 // Limpiando valores campo clasificacion_fondo
        //                 form.clasificacion_fondo.value = "";
        //                 selectedFilters.clasificacion_fondo = [];
        //                 // Filtrando valores para campo clasificacion_fondo
        //                 this.onFilterClasificacionFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesClaveFondo,
        //                     valuesTipoFondo
        //                 );
        //             }
        //         } else if (inputSubPivot === "clasificacion_fondo") {
        //             // Cuando el campo clasificacion_fondo es seleccionado de segundo y el campo tipo_fondo de tercero
        //             // Si se el campo tipo_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "tipo_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo tipo_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_operadora"
        //                         ? "clave_operadora"
        //                         : "tipo_fondo";
        //             }
        //             // Si el campo tipo_fondo es el penultimo se filtra el campo clave_operadora
        //             if (!prevInputSelected || prevInputSelected !== "clave_operadora") {
        //                 // Limpiando valores campo clave_operadora
        //                 form.clave_operadora.value = "";
        //                 selectedFilters.clave_operadora = [];
        //                 // Filtrando valores para campo clave_fondo
        //                 this.onFilterClaveOperadoraHandler(
        //                     valuesClaveFondo,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         }
        //     } else if (inputPivot === "fourthPath") {
        //         if (!inputSubPivot || inputSubPivot === "tipo_fondo") {
        //             // Cuando el campo clave_fondo es seleccionado de segundo
        //             // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //             if (eraser) {
        //                 inputSubPivot = false;
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se configura a null inputSubPivot si el valor de tipo_fondo es vacio
        //                 inputSubPivot = valuesTipoFondo !== "" ? "tipo_fondo" : null;
        //             }
        //             // Limpiando valores campo clave_operadora
        //             form.clave_operadora.value = "";
        //             selectedFilters.clave_operadora = [];
        //             // Filtrando valores para campo clave_operadora
        //             this.onFilterClaveOperadoraHandler(
        //                 null,
        //                 valuesTipoFondo,
        //                 valuesClasificacionFondo
        //             );
        //             // Limpiando valores campo clave_fondo
        //             form.clave_fondo.value = "";
        //             selectedFilters.clave_fondo = [];
        //             // Filtrando valores para campo clave_fondo
        //             this.onFilterClaveFondoHandler(
        //                 null,
        //                 valuesTipoFondo,
        //                 valuesClasificacionFondo
        //             );
        //         } else if (inputSubPivot === "clave_operadora") {
        //             // Cuando el campo clave_operadora es seleccionado de segundo y el campo tipo_fondo de tercero
        //             // Si se el campo tipo_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "tipo_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo tipo_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_fondo"
        //                         ? "clave_fondo"
        //                         : "tipo_fondo";
        //             }
        //             // Si el campo tipo_fondo es el penultimo se filtra el campo clave_fondo
        //             if (!prevInputSelected || prevInputSelected !== "clave_fondo") {
        //                 // Limpiando valores campo clave_fondo
        //                 form.clave_fondo.value = "";
        //                 selectedFilters.clave_fondo = [];
        //                 // Filtrando valores para campo clave_fondo
        //                 this.onFilterClaveFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         } else if (inputSubPivot === "clave_fondo") {
        //             // Cuando el campo clasificacion_fondo es seleccionado de segundo y el campo tipo_fondo de tercero
        //             // Si se el campo tipo_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "tipo_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo tipo_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_operadora"
        //                         ? "clave_operadora"
        //                         : "tipo_fondo";
        //             }
        //             // Si el campo tipo_fondo es el penultimo se filtra el campo clave_operadora
        //             if (!prevInputSelected || prevInputSelected !== "clave_operadora") {
        //                 // Limpiando valores campo clave_operadora
        //                 form.clave_operadora.value = "";
        //                 selectedFilters.clave_operadora = [];
        //                 // Filtrando valores para campo clave_operadora
        //                 this.onFilterClaveOperadoraHandler(
        //                     valuesClaveFondo,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         }
        //     }
        // } else if (type === 3) {
        //     // Clasificacion Siefore
        //     // Cuando el campo clasificacion_fondo es seleccionado de primero
        //     if (!inputPivot || inputPivot === "fourthPath") {
        //         // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //         if (eraser) {
        //             inputPivot = false;
        //             inputSubPivot = false;
        //             prevInputSelected = false;
        //         } else {
        //             // Se configura a null inputPivot si el valor de clasificacion_fondo es vacio
        //             inputPivot = valuesClasificacionFondo !== "" ? "fourthPath" : null;
        //         }
        //         // Limpiando valores campo clave_operadora
        //         form.clave_operadora.value = "";
        //         selectedFilters.clave_operadora = [];
        //         // Filtrando valores para campo clave_operadora
        //         this.onFilterClaveOperadoraHandler(
        //             null,
        //             null,
        //             valuesClasificacionFondo
        //         );
        //         // Limpiando valores campo clave_fondo
        //         form.clave_fondo.value = "";
        //         selectedFilters.clave_fondo = [];
        //         // Filtrando valores para campo clave_fondo
        //         this.onFilterClaveFondoHandler(null, null, valuesClasificacionFondo);
        //         // Limpiando valores campo tipo_fondo
        //         form.tipo_fondo.value = "";
        //         selectedFilters.tipo_fondo = [];
        //         // Filtrando valores para campo tipo_fondo
        //         this.onFilterTipoFondoHandler(null, null, valuesClasificacionFondo);
        //     } else if (inputPivot === "firstPath") {
        //         if (!inputSubPivot || inputSubPivot === "clasificacion_fondo") {
        //             // Cuando el campo clasificacion_fondo es seleccionado de segundo
        //             // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //             if (eraser) {
        //                 inputSubPivot = false;
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se configura a null inputSubPivot si el valor de clasificacion_fondo es vacio
        //                 inputSubPivot =
        //                     valuesClasificacionFondo !== "" ? "clasificacion_fondo" : null;
        //             }
        //             // Limpiando valores campo clave_fondo
        //             form.clave_fondo.value = "";
        //             selectedFilters.clave_fondo = [];
        //             // Filtrando valores para campo clave_fondo
        //             this.onFilterClaveFondoHandler(
        //                 valuesClaveOperadora,
        //                 null,
        //                 valuesClasificacionFondo
        //             );
        //             // Limpiando valores campo tipo_fondo
        //             form.tipo_fondo.value = "";
        //             selectedFilters.tipo_fondo = [];
        //             // Filtrando valores para campo tipo_fondo
        //             this.onFilterTipoFondoHandler(
        //                 valuesClaveOperadora,
        //                 null,
        //                 valuesClasificacionFondo
        //             );
        //         } else if (inputSubPivot === "clave_fondo") {
        //             // Cuando el campo clave_operadora es seleccionado de segundo y el campo clasificacion_fondo de tercero
        //             // Si se el campo clasificacion_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "clasificacion_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo clasificacion_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "tipo_fondo"
        //                         ? "tipo_fondo"
        //                         : "clasificacion_fondo";
        //             }
        //             // Si el campo clasificacion_fondo es el penultimo se filtra el campo tipo_fondo
        //             if (!prevInputSelected || prevInputSelected !== "tipo_fondo") {
        //                 // Limpiando valores campo tipo_fondo
        //                 form.tipo_fondo.value = "";
        //                 selectedFilters.tipo_fondo = [];
        //                 // Filtrando valores para campo tipo_fondo
        //                 this.onFilterTipoFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesClaveFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         } else if (inputSubPivot === "tipo_fondo") {
        //             // Cuando el campo tipo_fondo es seleccionado de segundo y el campo clasificacion_fondo de tercero
        //             // Si se el campo clasificacion_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "clasificacion_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo clasificacion_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_fondo"
        //                         ? "clave_fondo"
        //                         : "clasificacion_fondo";
        //             }
        //             // Si el campo clasificacion_fondo es el penultimo se filtra el campo clave_fondo
        //             if (!prevInputSelected || prevInputSelected !== "clave_fondo") {
        //                 // Limpiando valores campo clave_fondo
        //                 form.clave_fondo.value = "";
        //                 selectedFilters.clave_fondo = [];
        //                 // Filtrando valores para campo clave_fondo
        //                 this.onFilterClaveFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         }
        //     } else if (inputPivot === "secondPath") {
        //         if (!inputSubPivot || inputSubPivot === "clasificacion_fondo") {
        //             // Cuando el campo clasificacion_fondo es seleccionado de segundo
        //             // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //             if (eraser) {
        //                 inputSubPivot = false;
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se configura a null inputSubPivot si el valor de clasificacion_fondo es vacio
        //                 inputSubPivot =
        //                     valuesClasificacionFondo !== "" ? "clasificacion_fondo" : null;
        //             }
        //             // Limpiando valores campo clave_operadora
        //             form.clave_operadora.value = "";
        //             selectedFilters.clave_operadora = [];
        //             // Filtrando valores para campo clave_operadora
        //             this.onFilterClaveOperadoraHandler(
        //                 valuesClaveFondo,
        //                 null,
        //                 valuesClasificacionFondo
        //             );
        //             // Limpiando valores campo tipo_fondo
        //             form.tipo_fondo.value = "";
        //             selectedFilters.tipo_fondo = [];
        //             // Filtrando valores para campo tipo_fondo
        //             this.onFilterTipoFondoHandler(
        //                 null,
        //                 valuesClaveFondo,
        //                 valuesClasificacionFondo
        //             );
        //         } else if (inputSubPivot === "clave_operadora") {
        //             // Cuando el campo clave_operadora es seleccionado de segundo y el campo clasificacion_fondo de tercero
        //             // Si se el campo clasificacion_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "clasificacion_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo clasificacion_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "tipo_fondo"
        //                         ? "tipo_fondo"
        //                         : "clasificacion_fondo";
        //             }
        //             // Si el campo clasificacion_fondo es el penultimo se filtra el campo tipo_fondo
        //             if (!prevInputSelected || prevInputSelected !== "tipo_fondo") {
        //                 // Limpiando valores campo tipo_fondo
        //                 form.tipo_fondo.value = "";
        //                 selectedFilters.tipo_fondo = [];
        //                 // Filtrando valores para campo tipo_fondo
        //                 this.onFilterTipoFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesClaveFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         } else if (inputSubPivot === "tipo_fondo") {
        //             // Cuando el campo tipo_fondo es seleccionado de segundo y el campo clasificacion_fondo de tercero
        //             // Si se el campo clasificacion_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "clasificacion_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo clasificacion_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_operadora"
        //                         ? "clave_operadora"
        //                         : "clasificacion_fondo";
        //             }
        //             // Si el campo clasificacion_fondo es el penultimo se filtra el campo clave_operadora
        //             if (!prevInputSelected || prevInputSelected !== "clave_operadora") {
        //                 // Limpiando valores campo clave_operadora
        //                 form.clave_operadora.value = "";
        //                 selectedFilters.clave_operadora = [];
        //                 // Filtrando valores para campo clave_operadora
        //                 this.onFilterClaveOperadoraHandler(
        //                     valuesClaveFondo,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         }
        //     } else if (inputPivot === "thirdPath") {
        //         if (!inputSubPivot || inputSubPivot === "clasificacion_fondo") {
        //             // Cuando el campo clasificacion_fondo es seleccionado de segundo
        //             // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        //             if (eraser) {
        //                 inputSubPivot = false;
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se configura a null inputSubPivot si el valor de clasificacion_fondo es vacio
        //                 inputSubPivot =
        //                     valuesClasificacionFondo !== "" ? "clasificacion_fondo" : null;
        //             }
        //             // Limpiando valores campo clave_operadora
        //             form.clave_operadora.value = "";
        //             selectedFilters.clave_operadora = [];
        //             // Filtrando valores para campo clave_operadora
        //             this.onFilterClaveOperadoraHandler(
        //                 null,
        //                 valuesTipoFondo,
        //                 valuesClasificacionFondo
        //             );
        //             // Limpiando valores campo clave_fondo
        //             form.clave_fondo.value = "";
        //             selectedFilters.clave_fondo = [];
        //             // Filtrando valores para campo clave_fondo
        //             this.onFilterClaveFondoHandler(
        //                 null,
        //                 valuesTipoFondo,
        //                 valuesClasificacionFondo
        //             );
        //         } else if (inputSubPivot === "clave_fondo") {
        //             // Cuando el campo clave_fondo es seleccionado de segundo y el campo clasificacion_fondo de tercero
        //             // Si se el campo clasificacion_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "clasificacion_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo clasificacion_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_operadora"
        //                         ? "clave_operadora"
        //                         : "clasificacion_fondo";
        //             }
        //             // Si el campo clasificacion_fondo es el penultimo se filtra el campo clave_operadora
        //             if (!prevInputSelected || prevInputSelected !== "clave_operadora") {
        //                 // Limpiando valores campo clave_operadora
        //                 form.clave_operadora.value = "";
        //                 selectedFilters.clave_operadora = [];
        //                 // Filtrando valores para campo clave_operadora
        //                 this.onFilterClaveOperadoraHandler(
        //                     valuesClaveFondo,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         } else if (inputSubPivot === "clave_operadora") {
        //             // Cuando el campo clave_operadora es seleccionado de segundo y el campo clasificacion_fondo de tercero
        //             // Si se el campo clasificacion_fondo se setea a vacio.
        //             if (eraser && prevInputSelected === "clasificacion_fondo") {
        //                 prevInputSelected = false;
        //             } else {
        //                 // Se verifica si el campo clasificacion_fondo es el penultimo seleccionado.
        //                 prevInputSelected =
        //                     prevInputSelected === "clave_fondo"
        //                         ? "clave_fondo"
        //                         : "clasificacion_fondo";
        //             }
        //             // Si el campo clasificacion_fondo es el penultimo se filtra el campo clave_fondo
        //             if (!prevInputSelected || prevInputSelected !== "clave_fondo") {
        //                 // Limpiando valores campo clave_fondo
        //                 form.clave_fondo.value = "";
        //                 selectedFilters.clave_fondo = [];
        //                 // Filtrando valores para campo clave_fondo
        //                 this.onFilterClaveFondoHandler(
        //                     valuesClaveOperadora,
        //                     valuesTipoFondo,
        //                     valuesClasificacionFondo
        //                 );
        //             }
        //         }
        //     }
        // } else if (type === 4) {
        //     errorDate = false;

        //     // Fecha inicio
        //     // Se verifican si las fechas son validas
        //     if (actualValue !== "" && form.fecha_fin.value !== "") {
        //         errorDate = this.onCheckIfDatesAreValidHandler(
        //             actualValue,
        //             form.fecha_fin.value
        //         );
        //         errorLimitDate = validateLimitDateXMonths(actualValue, form.fecha_fin.value, 6);
        //         form.errorLimitDate = errorLimitDate;
        //         errorLimitDateYear = validateLimitDateXYears(actualValue, 5);
        //         form.errorLimitDateYear = errorLimitDateYear;
        //     } else {
        //         errorDate = false;
        //         // errorLimitDateYear = validateLimitDateXYears(actualValue, 5);
        //         // form.errorLimitDateYear = errorLimitDateYear;
        //     }
        //     form.errorDate = errorDate;


        // } else if (type === 5) {
        //     errorDate = false;

        //     //Fecha fin
        //     // Se verifican si las fechas son validas
        //     if (actualValue !== "" && form.fecha_inicio.value !== "") {
        //         errorDate = this.onCheckIfDatesAreValidHandler(
        //             form.fecha_inicio.value,
        //             actualValue
        //         );
        //         errorLimitDate = validateLimitDateXMonths(form.fecha_inicio.value, actualValue, 6);
        //         form.errorLimitDate = errorLimitDate;
        //     } else {
        //         errorDate = false;
        //     }
        //     form.errorDate = errorDate;


        // } else if (type === 6) {
        //     errorDate = false;

        //     //Fecha fin
        //     // Se verifican si la sfechas osn validas
        //     if (actualValue !== "" && form.fecha_inicio.value !== "") {
        //         errorDate = this.onCheckIfDatesAreValidHandler(
        //             form.fecha_inicio.value,
        //             actualValue
        //         );
        //     } else {
        //         errorDate = false;
        //     }
        //     form.errorDate = errorDate;
        
        this.onFilterDataByEvent();
        }

        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters,
            inputPivot: inputPivot,
            inputSubPivot: inputSubPivot,
            prevInputSelected: prevInputSelected
        });

        // this.onFilterDataByEvent();
    };

    showFiltersSelectedHandler = () => {
        const selectedFilters = this.state.selectedFilters;
        const filterList = [
            ...selectedFilters.clave_operadora,
            ...selectedFilters.clave_fondo,
            ...selectedFilters.tipo_fondo,
            ...selectedFilters.clasificacion_fondo,
            ...selectedFilters.fechas,
            ...selectedFilters.serie
        ];
        let filters = null;
        filters = (
            <div
                className={classes.filter_container_outside_model_one}
                id="allFitersContainers"
            >
                {filterList.map((item, i) => (
                    <div
                        className={classes.filter_container_model_one}
                        key={`${item.label}-${item.id}-${i}`}
                    >
                        <span className={["montse", classes.filter_text_model_one].join(" ")}>
                            {item.type === 4 || item.type === 5 ? item.value : item.label}
                        </span>
                        <span className={classes.filter_icon_button_model_one}>
                            <i
                                className="far fa-times-circle"
                                onClick={() =>
                                    this.onInputSelectedHandler(null, null, item, true)
                                }
                            />
                        </span>
                    </div>
                ))}
            </div>
        );
        return filters;
    };

    render() {
        return this.printFrameHandler();
    }
}

const mapStateToProps = state => {
    return {
        clave_operadora: state.fondos.clavesOperadora,
        clave_fondo: state.fondos.clavesFondo,
        // tipo_fondo: state.fondos.tiposFondo,
        // clasificacion_fondo: state.fondos.clasificacionesFondo,
        // serie: state.fondos.series
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterClaveOperadora: params => {
            dispatch(actions.initClavesOperadoraFondosComposicionCartera(params));
        },
        onFilterClaveFondo: params => {
            dispatch(actions.initClavesFondosComposicionCartera(params));
        },
        // onFilterTipoFondo: params => {
        //     dispatch(actions.initTiposFondos(params));
        // },
        // onFilterClasificacionFondo: params => {
        //     dispatch(actions.initClasificacionesFondos(params));
        // },
        // onFilterSerie: params => {
        //     dispatch(actions.initSerieFondos(params));
        // },
        onFilterFondos: (size, page, claveEmisora, clave, tipo, clasificacion, fechaInicio, fechaFin) => {
            dispatch(
                actions.initFondosConcentradoPreciosGeneral(
                    size,
                    page,
                    claveEmisora,
                    clave,
                    tipo,
                    clasificacion,
                    fechaInicio,
                    fechaFin
                )
            );
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FilterComposicionCartera)
);
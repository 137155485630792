import React, { Component } from "react";
// import classes from "./Fases.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";
import { classes } from "istanbul-lib-coverage";
import Box from "../../../components/ui/box/Box";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Fases extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null,
    descarga: {
      leftIcon: "far fa-file-pdf",
      title: "Descargar manual operativo",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url: "https://biva.mx/documents/30877/34256/BIVA+Manual+de+Reglas+Operativas.pdf/d9391d6f-4bf4-5e76-3b33-19a1f89f0d5f"
    }
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let boxes = null;
    if (this.props.documentos) {
      boxes = this.props.documentos.map((documento, i) => {
        return <Box key={"documento_" + i} modelo="modelo_1" data={documento}   />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="operaciones.modelosdenegociacion.fasesdelmercado.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="">
            <Accordion
              datos={this.props.accordion}
              nombre={"cursos"}
              icon={""}
              imagen_icono={"graficaLap.svg"}
              tipo={6}
              titulo_encabezado_estatico={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.fasesdelmercado.sistemaopel"] : " "}
              className={classes.accordion}
            />
          </div>
          <div className="row margen_top_50 margin_bottom_100">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2><FormattedHTMLMessage id="operaciones.modelosdenegociacion.fasesdelmercado.subtitulo1" defaultMessage=" " /></h2>
              </div>
            </div>
            <div className="row">
              <div className={""}>
              {boxes}
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    accordion: state.accordion.marketPhases,
    documentos: state.documentos.marketPhase
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(32));
      dispatch(action.getAccordionMarketPhases());
      dispatch(action.getDocMarketPhase());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fases);

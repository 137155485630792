import React, { Component } from "react";
import classes from "./FilterBancosInformacion.css";
import { Form, Field } from "react-final-form";
import { changeDateFormat, getLan } from "../../../stores/utils/utilities";
import { debounce } from "throttle-debounce";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import Input from "../input/Input";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiple = null,
  maxDate = null,
  max = null,
  options,
  selectLabel
}) => {
  const chooseOptionLabel =
    getLan() === "es" ? "Seleccione una opción" : "Select an option";
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      max: maxDate,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    isDisabled: disabled,
    max: max,
    options: options,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel
  };
  return (
    <div className="form-group">
      {label ? (
        <label
          for={input.name}
          className={["texto_14", "montse", "regular"].join(" ")}
        >
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

const idsTipoInformacion = [16, 17];

const idsTiposDocumento = [
  185,
  182,
  17,
  168,
  53,
  170,
  186,
  169,
  2,
  1,
  156,
  157,
  158,
  184,
  159,
  160,
  161,
  162,
  79,
  66,
  170
];

function valueUndefinedOrEmpty(value) {
  return value === undefined || value === "";
}

class FilterBancosInformacion extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    toggleFilters: false,
    esconderAutoComplete: true,
    selectPivot: false,
    disableEnableButtons: true,
    prevInputSelected: false,
    selectSubPivot: false,
    selectedFilters: {
      tipo_informacion: [],
      modoListado: [],
      claves_emisoras: [],
      tipo_instrumento: [],
      tipo_documento: [],
      periodos: [],
      fechas: [],
    },
    form_filters: {
      modoListado: {
          value: []
      },
      tipo_instrumento: {
        value: []
      },
      claves_emisoras: {
        value: ""
      },
      tipo_documento: {
        value: []
      },
      tipo_informacion: {
        value: ""
      },
      periodo: {
        value: "",
        disabled: false
      },
      ejercicio: {
        value: "",
        disabled: false
      },
      fecha_inicio: {
        value: "",
        disabled: false
      },
      fecha_fin: {
        value: "",
        disabled: false
      },
      errorDate: false,
      typeInfo: null
    },
    idIsFoundByDoc: false,
    idIsFoundByInfo: false,
    form_clave: {
      clave: {
        value: ""
      }
    },
  };

  componentDidMount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
    this.onFilterModoListadoHandler();
    this.onFilterClavesEmisorasHandler();
    this.onFilterInformacionHander();
    this.onFilterInstrumentoHandler();
  }

  componentWillUnmount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  componentWillMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  callReduxAction = value => {
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    this.setState({ form_clave: form_clave});
    this.props.onFilterBancosByClave(
      value
    );
  };

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible
    });
  }

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  onPrintFrameHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : null; //this.showInputHandler()
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";
    const closeLabel = getLan() === "es" ? "Cerrar Filtros" : "Close";

    return (
      <div className={"row"}>
        <div className={"col sin_padding"}>
          <div className={"row"}>
            <div
              className={[
                "col", "col-lg-3", "text-center", classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  "texto_btn_cerrar",
                  classes.toggle_button_container_model_one, this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={
                  this.state.disableEnableButtons ? this.onToggleFilters : null
                }
              >
                <button
                  className="btn"
                  type="button"
                  disabled={!this.state.disableEnableButtons}
                >
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters ? closeLabel : filterLabel}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={[
                "col col-lg-9",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  classes.filters_selected_container_model_one, this.state.toggleFilters
                    ? classes.no_borders_filter_filters_selected : classes.border_filters_selected
                ].join(" ")}
              >
                {input}
              </div>
            </div>
          </div>
          <div
            className={[
              "row",
              classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null
            ].join(" ")}
          >
            <div
              className={["col", classes.no_col_padding_model_one].join(" ")}
            >
              {this.modelOne()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <br/>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              modoListado: this.state.form_filters.modoListado.value,
              tipo_instrumento: this.state.form_filters.tipo_instrumento.value,
              claves_emisoras: this.state.form_filters.claves_emisoras.value,
              tipo_documento: this.state.form_filters.tipo_documento.value,
              tipo_informacion: this.state.form_filters.tipo_informacion.value,
              fecha_inicio: this.state.form_filters.fecha_inicio.value,
              fecha_fin: this.state.form_filters.fecha_fin.value,
              ejercicio: this.state.form_filters.ejercicio.value,
              periodo: this.state.form_filters.periodo.value
            }}
            render={({
              optionsModoListado = this.props.modoListado
                ? this.prepareOptionsHandler(this.props.modoListado) : [],
              optionsClavesEmisoras = this.props.claves_emisoras
                ? this.prepareOptionsHandler(this.props.claves_emisoras) : [],
              optionsInstrumentos = this.props.tipo_instrumento
                ? this.prepareOptionsHandler(this.props.tipo_instrumento) : [],
              optionsTipoInformacion = this.props.tipo_informacion
                ? this.prepareOptionsHandler(this.props.tipo_informacion) : [],
              optionsTipoDocumento = this.props.tipo_documento
                ? this.prepareOptionsHandler(this.props.tipo_documento) : [],
              optionsPeriodo = this.props.periodos
                ? this.prepareOptionsHandler(this.props.periodos) : [],
              optionsEjercicios = this.props.ejercicios
                ? this.prepareOptionsHandler(this.props.ejercicios) : [],
              form
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterModelOneHandler}>
                <div className={"row"}>

                  <div className="col-6 col-sm-6 col-md-6 col-lg-4 form-group">
                    <label className={["montse texto_14 regular"].join(" ")}>
                    {getLan() === "es" ? "Modalidad de listado" : "Listing mode"}
                    </label>
                    {!valueUndefinedOrEmpty(optionsModoListado)?optionsModoListado.map((item) => (
                    <div className={["form-group"].join(" ")} key={"modoListado"+item.id}>
                      <input type="radio" style={{}}
                        name="modoListado" id={item.id} value={item.id}
                        checked={this.state.form_filters.modoListado.value == item.id}
                        onChange={event => this.onInputSelectHandler(event, 8)}/>
                      <label htmlFor={item.id} className='texto_14 montse' style={{paddingLeft: '1rem', marginTop: '10px'}}>
                      {item.nombre}
                      </label>
                    </div>
                    )):null}
                  </div>
                  <div className={"col-12 col-sm-6 col-md-6 col-lg-4  "}>
                    <Field
                      id="claves_emisoras"
                      name="claves_emisoras"
                      label={getLan() === "es" ? "Emisoras" : "Ticker"}
                      classes="custom-select"
                      elementType="select"
                      options={optionsClavesEmisoras}
                      onInputChange={event =>
                        this.onInputSelectHandler(event, 0)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className={"col-12 col-sm-6 col-md-6 col-lg-4"}>
                    <Field
                      id="tipo_instrumento"
                      name="tipo_instrumento"
                      label={ getLan() === "es" ? "Instrumento" : "Security type" }
                      type="select"
                      elementType="select"
                      classes="custom-select"
                      isMultiple={true}
                      options={optionsInstrumentos}
                      onInputChange={event =>
                        this.onInputSelectHandler(event, 1)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className={"col-12 col-sm-12 col-md-6 col-lg-4"}>
                    <div className={"row"}>
                      <div
                        className={[
                          "col-6 col-md-6", "col-lg-6", "sin_padding"
                        ].join(" ")}
                      >
                        <Field
                          name="fecha_inicio"
                          type="date"
                          id="fecha_inicio"
                          classes={classes.fechaInicio}
                          maxDate="2999-12-31"
                          disabled={
                            this.state.form_filters.fecha_inicio.disabled
                          }
                          label={getLan() === "es" ? "Fecha inicial" : "From"}
                          onInputChange={event =>
                            this.onInputSelectHandler(event, 4)
                          }
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          "col-6 col-sm-6 col-md-6", "col-lg-6", "sin_padding_R"
                        ].join(" ")}
                      >
                        <Field
                          label={getLan() === "es" ? "Fecha fin" : "To"}
                          id="fecha_fin"
                          name="fecha_fin"
                          classes={classes.fechaFin}
                          type="date"
                          maxDate="2999-12-31"
                          disabled={this.state.form_filters.fecha_fin.disabled}
                          onInputChange={event =>
                            this.onInputSelectHandler(event, 5)
                          }
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          classes.errorContainer,
                          !this.state.form_filters.errorDate
                            ? classes.hidden
                            : null
                        ].join(" ")}
                      >
                        <p className={"texto_14 montse regular"}>
                          {getLan() === "es" ? "La fecha final debe ser mayor a la fecha inicial." : "End date must be greater than start date."}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={"col-12 col-sm-6 col-md-6 col-lg-4 "}>
                    <Field
                      label={ getLan() === "es" ? "Tipo de información"   : "Information type" }
                      id="tipo_informacion"
                      name="tipo_informacion"
                      elementType="select"
                      classes="custom-select"
                      options={optionsTipoInformacion}
                      onInputChange={event =>
                        this.onInputSelectHandler(event, 2)
                      }
                      component={renderInput}
                    />
                  </div>

                  <div
                    className={[ "col-12", "col-sm-6 col-md-6 col-lg-4", 
                    !this.state.form_filters.tipo_informacion.value || this.state.form_filters.tipo_informacion.value === "" ? classes.hidden : null
                    ].join(" ")}
                  >
                    <Field
                      id="tipo_documento"
                      name="tipo_documento"
                      label={ getLan() === "es" ? "Tipo de documento" : "Document type"}
                      type="select"
                      classes="custom-select"
                      elementType="select"
                      options={optionsTipoDocumento}
                      isMultiple={true}
                      onInputChange={event =>
                        this.onInputSelectHandler(event, 3)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                      <div
                        className={[
                          "col",
                          "col-lg-6",
                          "sin_padding",
                          !this.state.idIsFoundByInfo ? classes.hidden : null
                        ].join(" ")}
                      >
                        <Field
                          name="periodo"
                          id="periodo"
                          label={getLan() === "es" ? "Periodo" : "Period"}
                          elementType="select"
                          type="select"
                          classes="custom-select"
                          disabled={this.state.form_filters.periodo.disabled}
                          options={optionsPeriodo}
                          onInputChange={event =>
                            this.onInputSelectHandler(event, 6)
                          }
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          "col",
                          "col-lg-6",
                          "sin_padding_R",
                          this.state.idIsFoundByInfo ||
                          this.state.idIsFoundByDoc
                            ? null
                            : classes.hidden
                        ].join(" ")}
                      >
                        <Field
                          name="ejercicio"
                          id="ejercicio"
                          label={getLan() === "es" ? "Ejercicio" : "Year"}
                          elementType="select"
                          type="select"
                          disabled={this.state.form_filters.ejercicio.disabled}
                          classes="custom-select"
                          options={optionsEjercicios}
                          onInputChange={event =>
                            this.onInputSelectHandler(event, 7)
                          }
                          component={renderInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                {this.sectionButtonsModelOne(form)}
              </form>
            )}
          />
      </div>
    );
  };

  prepareOptionsHandler = options => {
    let newOptions = [];

    options.forEach(option => {
      let id = null;
      let nombre = null;

      if (option.tipo) {
        id = option.tipo
          ? `${option.id}_${option.nombre}_${option.tipo}`
          : `${option.id}_${option.clave}`;
        nombre = option.clave ? option.clave : option.nombre;
      } else if (option.nombre || option.clave) {
        id = option.nombre
          ? `${option.id}_${option.nombre}`
          : `${option.id}_${option.clave}`;
        nombre = option.clave ? option.clave : option.nombre;
      } else {
        id = option;
        nombre = option;
      }

      newOptions.push({
        id: id,
        nombre: nombre
      });
    });

    return newOptions;
  };

  sectionButtonsModelOne = form => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14"
            ].join(" ")}
            onClick={() => this.onResetFilterModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  onSubmitSearchModelOneHandler = event => {
    event.preventDefault();
  };


  onSubmitFilterModelOneHandler = event => {};

  // Limpia el formulario restablece los estados de los inputs a sus valores por defecto
  onResetFilterModelOneHandler = form => {
    form.reset();

    const selectPivot = false;
    const selectSubPivot = false;
    const prevInputSelected = false;
    const idIsFoundByInfo = false;
    const idIsFoundByDoc = false;

    const form_filter = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form_filter.claves_emisoras.value = "";
    selectedFilters.claves_emisoras = [];
    form_filter.tipo_instrumento.value = [];
    selectedFilters.tipo_instrumento = [];
    form_filter.tipo_informacion.value = "";
    selectedFilters.tipo_informacion = [];
    form_filter.tipo_documento.value = [];
    selectedFilters.tipo_documento = [];
    form_filter.fecha_inicio.value = "";
    form_filter.fecha_inicio.disabled = false;
    form_filter.fecha_fin.value = "";
    form_filter.fecha_fin.disabled = false;
    selectedFilters.fechas = [];
    form_filter.periodo.value = "";
    form_filter.periodo.disabled = false;
    form_filter.ejercicio.value = "";
    form_filter.ejercicio.disabled = false;
    selectedFilters.periodos = [];
    form_filter.modoListado.value = "";
    selectedFilters.modoListado = [];

    form.typeInfo = null;
    form.errorDate = false;

    this.setState({
      form: form_filter,
      selectedFilters: selectedFilters,
      selectPivot: selectPivot,
      selectSubPivot: selectSubPivot,
      prevInputSelected: prevInputSelected,
      idIsFoundByInfo: idIsFoundByInfo,
      idIsFoundByDoc: idIsFoundByDoc
    });

    // this.onFilterDataByEvent();
    this.onFilterClavesEmisorasHandler();
    this.onFilterInformacionHander();
    this.onFilterInstrumentoHandler();

    const params = {
      emisora: null,
      tipoInstrumento: null,
      tipoInformacion: null,
      type: null,
      tipoDocumento: null,
      fechaInicio: null,
      fechaFin: null,
      periodo: null,
      ejercicio: null,
      size: this.props.itemsPerPage,
      page: 1,
      reset: true
    };

    this.props.setFilters(params);
    this.props.setPage(1);
  };      

  onFilterModoListadoHandler = (
      emisora = null,
      type = null,
      tipoDocumento = null,
      tipoInformacion = null,
      tipoInstrumento = null
    ) => {
    let params = {
      emisora: emisora,
      type: type,
      tipoDocumento: tipoDocumento,
      tipoInformacion: tipoInformacion,
      tipoInstrumento: tipoInstrumento
    };

    this.props.onFilterModoListado(params);
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onFilterClavesEmisorasHandler = (
    type = null,
    tipoDocumento = null,
    tipoInformacion = null,
    tipoInstrumento = null,
    modoListado = null
  ) => {
    let params = {
      type: type,
      tipoDocumento: tipoDocumento,
      tipoInformacion: tipoInformacion,
      tipoInstrumento: tipoInstrumento,
      modoListado: modoListado
    };

    this.props.onFilterClavesEmisoras(params);
  };

  onFilterInformacionHander = (empresa = null, tipoInstrumento = null, modoListado = null) => {
    const params = {
      empresa: empresa,
      tipoInstrumento: tipoInstrumento,
      modoListado: modoListado
    };
    this.props.onFilterAllTipoInformacion(params);
  };

  onFilterInstrumentoHandler = (
    empresa = null,
    type = null,
    tipoInformacion = null,
    tipoDocumento = null,
    modoListado = null
  ) => {
    const params = {
      empresa: empresa,
      type: type,
      tipoInformacion: tipoInformacion,
      tipoDocumento: tipoDocumento,
      modoListado: modoListado
    };
    this.props.onFilterInstrumentos(params);
  };

  onFilterTipoValorHandler = (instrumento = null, tipo_documento) => {
    const params = {
      instrumento: instrumento,
      tipo_documento: tipo_documento
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterDocumentosHandler = (
    empresa = null,
    type = null,
    tipoInformacion = null,
    tipoInstrumento = null,
    modoListado = null
  ) => {
    const params = {
      empresa: empresa,
      type: type,
      tipoInformacion: tipoInformacion,
      tipoInstrumento: tipoInstrumento,
      modoListado: modoListado
    };
    this.props.onFilterTipoDocumentos(params);
  };

  onFilterDataByEvent = () => {
    const [
      valuesEmisoras,
      valuesTipoInstrumentos,
      valuesTipoInformacion,
      valuesTipoDocumentos,
      valuesFechaInicio,
      valuesFechaFin,
      valuesPeriodo,
      valuesEjercicio,
      valuesModoListado
    ] = this.onGetAllValuesFormFilter();

    const params = {
      size: this.props.itemsPerPage,
      page: 1,
      emisora: valuesEmisoras,
      tipoInstrumento: valuesTipoInstrumentos,
      tipoInformacion: valuesTipoInformacion,
      type: this.state.form_filters.typeInfo,
      tipoDocumento: valuesTipoDocumentos,
      fechaInicio: valuesFechaInicio,
      fechaFin: valuesFechaFin,
      periodo: valuesPeriodo,
      ejercicio: valuesEjercicio,
      modoListado: valuesModoListado
    };

    if (!this.state.form_filters.errorDate) {
      this.props.setFilters(params);
    }

    let wasFiltered = true;
    // Se invocan a las funciones el componente padre (BancoInformacion.js) para setear los valores correspondientes
    // Configurando el valor de la emisora seleccionada
    if (valuesEmisoras && valuesEmisoras !== "") {
      this.props.setEmisora(valuesEmisoras);
      wasFiltered = true;
    } else {
      this.props.setEmisora(null);
    }
    // Configurando el valor de tipo de instrumento seleccionado
    if (valuesTipoInstrumentos && valuesTipoInstrumentos.length > 0) {
      this.props.setTipoInstrumento(valuesTipoInstrumentos);
      wasFiltered = true;
    } else {
      this.props.setTipoInstrumento(null);
    }
    // Configurando el valor de tipo de informacion seleccionado
    if (valuesTipoInformacion && valuesTipoInformacion !== "") {
      this.props.setTipoInformacion(valuesTipoInformacion);
      wasFiltered = true;
    } else {
      this.props.setTipoInformacion(null);
    }
    // Configurando el valor de tipo de documento seleccionado
    if (valuesTipoDocumentos && valuesTipoDocumentos.length > 0) {
      this.props.setTipoDocumento(valuesTipoDocumentos);
      wasFiltered = true;
    } else {
      this.props.setTipoDocumento(null);
    }
    // Configurando el valor de fecha inicio seleccionado
    if (valuesFechaInicio && valuesFechaInicio.length > 0) {
      this.props.setFechaInicio(valuesFechaInicio);
      wasFiltered = true;
    } else {
      this.props.setFechaInicio(null);
    }
    // Configurando el valor de fecha fin seleccionado
    if (valuesFechaFin && valuesFechaFin.length > 0) {
      this.props.setFechaFin(valuesFechaFin);
      wasFiltered = true;
    } else {
      this.props.setFechaFin(null);
    }
    // Configurando el valor de periodo seleccionado
    if (valuesPeriodo && valuesPeriodo.length > 0) {
      this.props.setPeriodo(valuesPeriodo);
      wasFiltered = true;
    } else {
      this.props.setPeriodo(null);
    }
    // Configurando el valor de ejercicio seleccionado
    if (valuesEjercicio && valuesEjercicio.length > 0) {
      this.props.setEjercicio(valuesEjercicio);
      wasFiltered = true;
    } else {
      this.props.setEjercicio(null);
    }
    // Configurando el valor de modo listado
    if (valuesModoListado && valuesModoListado.length > 0) {
      this.props.setModoListado(valuesModoListado);
      wasFiltered = true;
    } else {
      this.props.setModoListado(null);
    }
    // Configurando el tipo de informacion seleccionado (listado o mantenimiento)
    this.state.form_filters.typeInfo && this.state.form_filters.typeInfo !== ""
      ? this.props.setType(this.state.form_filters.typeInfo)
      : this.props.setType(null);

    // Configurando la primera página de la busqueda
    this.props.setPage(1);

    //Configurando si se ha realizado algun filtrado en el componente padre
    this.props.wasFiltered(wasFiltered);
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesEmisoras =
      form.claves_emisoras.value && form.claves_emisoras.value !== ""
        ? this.onSplitValueHandler(form.claves_emisoras.value)
        : "";
    // Se obtiene el valor del tipo de instrumento
    const valuesTipoInstrumentos =
      form.tipo_instrumento.value.length &&
      form.tipo_instrumento.value.length > 0
        ? this.onSplitValueHandler(form.tipo_instrumento.value)
        : [];
    // Se obtiene el valor del tipo de informacion
    const valuesTipoInformacion =
      form.tipo_informacion.value && form.tipo_informacion.value !== ""
        ? this.onSplitValueHandler(form.tipo_informacion.value)
        : "";
    // Se obtiene el valor del tipo de informacion
    const valuesTipoDocumentos =
      form.tipo_documento.value.length && form.tipo_documento.value.length > 0
        ? this.onSplitValueHandler(form.tipo_documento.value)
        : [];
    // Se obtiene el valor de fecha inicio
    const valuesFechaInicio =
      form.fecha_inicio.value && form.fecha_inicio.value !== ""
        ? form.fecha_inicio.value
        : "";
    // Se obtiene el valor de fecha fin
    const valuesFechaFin =
      form.fecha_fin.value && form.fecha_fin.value !== ""
        ? form.fecha_fin.value
        : "";
    // Se obtiene el valor de periodo
    const valuesPeriodo =
      form.periodo.value && form.periodo.value !== ""
        ? this.onSplitValueHandler(form.periodo.value)
        : "";
    // Se obtiene el valor de ejercicio
    const valuesEjercicio =
      form.ejercicio.value && form.ejercicio.value !== ""
        ? this.onSplitValueHandler(form.ejercicio.value)
        : "";
    // Se obtiene el valor de modo listado
    const valuesModoListado =
    form.modoListado.value && form.modoListado.value !== ""
      ? this.onSplitValueHandler(form.modoListado.value)
      : "";

    return [
      valuesEmisoras,
      valuesTipoInstrumentos,
      valuesTipoInformacion,
      valuesTipoDocumentos,
      valuesFechaInicio,
      valuesFechaFin,
      valuesPeriodo,
      valuesEjercicio,
      valuesModoListado
    ];
  };

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Verifica si el valor recibido ya sea de tipo de informacion o tipo de documento
  // pertenesca a unos de los ids en "idsTipoInformacion" o idsTiposDocumento
  onCheckIfIdsExists = (type, value) => {
    let exists = false;
    let val = null;

    if (type === 2) {
      val = +value;
      idsTipoInformacion.forEach(item => {
        if (item === val) exists = true;
      });
    } else {
      // val = [...value];
      val= value[0].split(",");
      val.forEach(v => {
        idsTiposDocumento.forEach(item => {
          v = +v;
          if (item === v) exists = true;
        });
      });
    }
    return exists;
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return [
          "claves_emisoras",
          form.claves_emisoras.value,
          selectedFilters.claves_emisoras
        ];
      case 1:
        return [
          "tipo_instrumento",
          form.tipo_instrumento.value,
          selectedFilters.tipo_instrumento
        ];
      case 2:
        return [
          "tipo_informacion",
          form.tipo_informacion.value,
          selectedFilters.tipo_informacion
        ];
      case 3:
        return [
          "tipo_documento",
          form.tipo_documento.value,
          selectedFilters.tipo_documento
        ];
      case 4:
        return [
          "fecha_inicio",
          form.fecha_inicio.value,
          selectedFilters.fechas,
          "fechas"
        ];
      case 5:
        return [
          "fecha_fin",
          form.fecha_fin.value,
          selectedFilters.fechas,
          "fechas"
        ];
      case 6:
        return [
          "periodo",
          form.periodo.value,
          selectedFilters.periodos,
          "periodos"
        ];
      case 7:
        return [
          "ejercicio",
          form.ejercicio.value,
          selectedFilters.periodos,
          "periodos"
        ];
      case 8:
        return [
          "modoListado",
          form.modoListado.value,
          selectedFilters.modoListado
        ];
    }
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (type !== 1 && type !== 3) {
      if (values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];
      const stateExists = newStateValues.find(option => option === values);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== values);
      } else {
        if (values !== "") {
          newStateValues.push(values);
        } else {
          newStateValues = [];
        }
      }
    }

    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (
    filterOptions = null,
    values = null,
    label = null,
    type = null
  ) => {
    let newFilterValues = [];

    if (type === 0 || type === 2 || type === 8) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else if (type === 4 || type === 5 || type === 6 || type === 7) {
      // Fechas y periodos
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(
        option => option.label === label
      );
      if (filterExists) {
        newFilterValues = newFilterValues.filter(
          option => option.label !== label
        );
        if (values && values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      } else {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(
        option => option.label === label
      );
      if (filterExists) {
        newFilterValues = newFilterValues.filter(
          option => option.label !== label
        );
      } else {
        if (values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        } else {
          newFilterValues = [];
        }
      }
    }
    return newFilterValues;
  };

  // Retorna el valor, etiqueta y tipo  de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newValue = null;
    let newLabel = null;
    let newType = null;

    if (type === 4 || type === 5) {
      // Fechas inicio o fin
      [newValue] = changeDateFormat(value);
      newLabel = type === 4 ? "fecha_inicio" : "fecha_fin";
    } else if (type === 6 || type === 7) {
      // Periodo o ejercicio
      newValue = value;
      newLabel = type === 6 ? "periodo" : "ejercicio";
    } else {
      // Resto de campo
      newValue = value.split("_")[0];
      newLabel = value.split("_")[1];
      newType = type === 2 ? value.split("_")[2] : null;
    }

    return [newValue, newLabel, newType];
  };

  onInputSelectHandler =  (event, type = null) => {
    let actualValue =
      type !== 1 && type !== 3 ? event.target.value : event.target.options;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let errorDate = form.errorDate;

    let newValue = null;
    let newLabel = null;
    let newType = null;

    // Solo para los campos distintos a tipo instrumento y tipo documento.
    if (type !== 1 && type !== 3) {
      [newValue, newLabel, newType] = this.onGetValueLabelAndTypeHandler(
        actualValue,
        type
      );
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [
      inputName,
      stateValues,
      filterValues,
      otherInputName
    ] = this.onGetNameStateFilterByInputHandler(type);

    let selectPivot = this.state.selectPivot;
    let selectSubPivot = this.state.selectSubPivot;
    let prevInputSelected = this.state.prevInputSelected;
    let idIsFoundByInfo = this.state.idIsFoundByInfo;
    let idIsFoundByDoc = this.state.idIsFoundByDoc;

    // Solo para el campo tipo de instrumento y tipo de documento.
    if (type === 1 || type === 3) {
      const options = [...actualValue];
      const indexSelected =
        actualValue.selectedIndex && actualValue.selectedIndex !== -1
          ? actualValue.selectedIndex
          : 0;
      newValue = options[indexSelected].value;
      newLabel = options[indexSelected].value.split("_")[1];

      actualValue = newValue;
    }

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(
      filterValues,
      newValue,
      newLabel,
      type
    );

    form[`${inputName}`].value = newStateValues;
    selectedFilters[
      `${otherInputName ? otherInputName : inputName}`
    ] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesEmisoras,
      valuesTipoInstrumentos,
      valuesTipoInformacion,
      valuesTipoDocumentos,
      valuesFechaInicio,
      valuesFechaFin,
      valuesPeriodo,
      valuesEjercicio,
      valuesModoListado
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);
    
    if (type === 8) {
      //Modo listado

      form.claves_emisoras.value = "";
      selectedFilters.claves_emisoras = [];
      this.onFilterClavesEmisorasHandler(null, null, null, null, valuesModoListado);

      form.tipo_instrumento.value = "";
      selectedFilters.tipo_instrumento = [];
      // Filtrado de tipo de instrumento solo con valor de la clave de emisora
      this.onFilterInstrumentoHandler(null, null, null, null, valuesModoListado);

      // Limpiando valores para campo tipo informacion
      form.tipo_informacion.value = "";
      selectedFilters.tipo_informacion = [];
      // Filtrado de tipo de informacion solo con valor de la clave de emisora
      this.onFilterInformacionHander(null, null, valuesModoListado);

      // Limpiando valores de periodo y ejercicio
      form.ejercicio.value = "";
      form.periodo.value = "";
      selectedFilters.periodos = [];
      idIsFoundByInfo = null;
      idIsFoundByDoc = null;

      // Habilitando fechas en caso de estar deshabilitadas
      form.fecha_inicio.disabled = false;
      form.fecha_fin.disabled = false;
    } else if (type === 0) {
      // Clave emisoras
      if (valuesEmisoras !== "") {
        if (!selectPivot || selectPivot === "firstpath") {
          // Cuando "emisora" es el campo pivote
          selectPivot = "firstpath";
          form.typeInfo = null;

          // Limpiando valores para campo9 tipo instrumento
          form.tipo_instrumento.value = "";
          selectedFilters.tipo_instrumento = [];
          // Filtrado de tipo de instrumento solo con valor de la clave de emisora
          this.onFilterInstrumentoHandler(valuesEmisoras, null, null, null, valuesModoListado);

          // Limpiando valores para campo tipo informacion
          form.tipo_informacion.value = "";
          selectedFilters.tipo_informacion = [];
          // Filtrado de tipo de informacion solo con valor de la clave de emisora
          this.onFilterInformacionHander(valuesEmisoras, null, valuesModoListado);

          // Limpiando valores de periodo y ejercicio
          form.ejercicio.value = "";
          form.periodo.value = "";
          selectedFilters.periodos = [];
          idIsFoundByInfo = null;
          idIsFoundByDoc = null;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectPivot === "secondpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento
          if (!selectSubPivot || selectSubPivot === "emisora") {
            selectSubPivot = "emisora";
            form.typeInfo = null;

            // Limpiando valores para campo tipo informacion
            form.tipo_informacion.value = "";
            selectedFilters.tipo_informacion = [];
            // Filtrado de tipo de informacion
            this.onFilterInformacionHander(
              valuesEmisoras,
              valuesTipoInstrumentos,
              valuesModoListado
            );

            // Limpiando valores de periodo y ejercicio
            form.ejercicio.value = "";
            form.periodo.value = "";
            selectedFilters.periodos = [];
            idIsFoundByInfo = null;
            idIsFoundByDoc = null;

            // Habilitando fechas en caso de estar deshabilitadas
            form.fecha_inicio.disabled = false;
            form.fecha_fin.disabled = false;
          } else if (selectSubPivot === "informacion") {
            // Se obtiene el tipo de informacion (listado o mantenimiento) Usado para secondpath
            newType = form.typeInfo;

            prevInputSelected =
              prevInputSelected === "documento" ? "documento" : "emisora";
            if (!prevInputSelected || prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                valuesTipoInstrumentos,
                valuesModoListado
              );
            }
          }
        } else if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento o tipo documento
          if (!selectSubPivot || selectSubPivot === "emisora") {
            selectSubPivot = "emisora";
            prevInputSelected = null;
            // Limpiando valores para tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion, null, valuesModoListado
            );
            // Limpiando valores para campo tipo documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion,
              null,
              valuesModoListado
            );
          } else if (selectSubPivot === "instrumento") {
            prevInputSelected =
              prevInputSelected === "documento" ? "documento" : "emisora";
            if (!prevInputSelected || prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                valuesTipoInstrumentos,
                valuesModoListado
              );
            }
          } else if (selectSubPivot === "documento") {
            prevInputSelected =
              prevInputSelected === "instrumento" ? "instrumento" : "emisora";
            if (!prevInputSelected || prevInputSelected !== "instrumento") {
              // Limpiando valores para tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                valuesTipoDocumentos, valuesModoListado
              );
            }
          }
        }
      } else {
        if (!selectPivot || selectPivot === "firstpath") {
          // Cuando "emisora" es el campo pivote
          selectPivot = null;
          selectSubPivot = null;
          form.typeInfo = null;

          // Limpiando valores para campo tipo documento
          form.tipo_documento.value = "";
          selectedFilters.tipo_documento = [];

          // Limpiando valores para campo tipo instrumento
          form.tipo_instrumento.value = "";
          selectedFilters.tipo_instrumento = [];
          // Filtrado de tipo de instrumento sin valores (default)
          this.onFilterInstrumentoHandler(null,null,null,null, valuesModoListado);

          // Limpiando valores para campo tipo informacion
          form.tipo_informacion.value = "";
          selectedFilters.tipo_informacion = [];
          // Filtrado de tipo de informacion sin valores (default)
          this.onFilterInformacionHander(null, null, valuesModoListado);

          // Limpiando valores de periodo y ejercicio
          form.ejercicio.value = "";
          form.periodo.value = "";
          selectedFilters.periodos = [];
          idIsFoundByInfo = null;
          idIsFoundByDoc = null;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectPivot === "secondpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento
          if (!selectSubPivot || selectSubPivot === "emisora") {
            selectSubPivot = null;

            // Limpiando valores para campo tipo informacion
            form.tipo_informacion.value = "";
            selectedFilters.tipo_informacion = [];
            // Filtrado de tipo de informacion
            this.onFilterInformacionHander(null, valuesTipoInstrumentos, valuesModoListado);

            // Limpiando valores de periodo y ejercicio
            form.ejercicio.value = "";
            form.periodo.value = "";
            selectedFilters.periodos = [];
            idIsFoundByInfo = null;
            idIsFoundByDoc = null;

            // Habilitando fechas en caso de estar deshabilitadas
            form.fecha_inicio.disabled = false;
            form.fecha_fin.disabled = false;
          } else if (selectSubPivot === "informacion") {
            if (prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                null,
                newType,
                valuesTipoInformacion,
                valuesTipoInstrumentos,
                valuesModoListado
              );
              prevInputSelected = null;
            }
          }
        } else if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento o tipo documento
          if (!selectSubPivot || selectSubPivot === "emisora") {
            selectSubPivot = null;
            prevInputSelected = null;
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento solo con valor de la clave de emisora
            this.onFilterInstrumentoHandler(
              null,
              newType,
              valuesTipoInformacion, null, valuesModoListado
            );
            // Limpiando valores para campo tipo documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(
              null,
              newType,
              valuesTipoInformacion,
              null,
              valuesModoListado
            );
          } else if (selectSubPivot === "instrumento") {
            if (prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                null,
                newType,
                valuesTipoInformacion,
                valuesTipoInstrumentos,
                valuesModoListado
              );
              prevInputSelected = null;
            }
          } else if (selectSubPivot === "documento") {
            if (prevInputSelected !== "instrumento") {
              // Limpiando valores para tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(
                null,
                newType,
                valuesTipoInformacion,
                valuesTipoDocumentos, valuesModoListado
              );
              prevInputSelected = null;
            }
          }
        }
      }
    } else if (type === 1) {
      // Instrumento
      newType = form.typeInfo;

      if (valuesTipoInstrumentos.length > 0) {
        if (!selectPivot || selectPivot === "secondpath") {
          // Cuando "instrumento" es el campo pivote
          selectPivot = "secondpath";
          form.typeInfo = null;

          // Limpiando valores para campo emisoras
          form.claves_emisoras.value = "";
          selectedFilters.claves_emisoras = [];
          // Filtrado de emisoras por tipo de instrumento
          this.onFilterClavesEmisorasHandler(
            null,
            null,
            null,
            valuesTipoInstrumentos, valuesModoListado
          );
          // Limpiando valores para campo tipo de documento
          form.tipo_documento.value = "";
          selectedFilters.tipo_documento = [];
          // Limpiando valores para campo tipo informacion
          form.tipo_informacion.value = "";
          selectedFilters.tipo_informacion = [];
          // Filtrado de tipo de informacion
          this.onFilterInformacionHander(null, valuesTipoInstrumentos, valuesModoListado);
          // Limpiando valores de periodo y ejercicio
          form.ejercicio.value = "";
          form.periodo.value = "";
          selectedFilters.periodos = [];
          idIsFoundByInfo = null;
          idIsFoundByDoc = null;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectPivot === "firstpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de informacion
          if (!selectSubPivot || selectSubPivot === "instrumento") {
            selectSubPivot = "instrumento";
            form.typeInfo = null;
            // Limpiando valores para campo tipo de documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Limpiando valores para campo tipo informacion
            form.tipo_informacion.value = "";
            selectedFilters.tipo_informacion = [];
            // Filtrado de tipo de informacion
            this.onFilterInformacionHander(
              valuesEmisoras,
              valuesTipoInstrumentos, valuesModoListado
            );
            // Limpiando valores de periodo y ejercicio
            form.ejercicio.value = "";
            form.periodo.value = "";
            selectedFilters.periodos = [];
            idIsFoundByInfo = null;
            idIsFoundByDoc = null;

            // Habilitando fechas en caso de estar deshabilitadas
            form.fecha_inicio.disabled = false;
            form.fecha_fin.disabled = false;
            // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento
          } else if (selectSubPivot === "informacion") {
            prevInputSelected =
              prevInputSelected === "documento" ? "documento" : "instrumento";
            if (!prevInputSelected || prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo de documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                valuesTipoInstrumentos,
                valuesModoListado
              );
            }
          }
        } else if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de documentos o emisoras
          if (!selectSubPivot || selectSubPivot === "instrumento") {
            selectSubPivot = "instrumento";

            // Limpiando valores para campo emisoras
            form.claves_emisoras.value = "";
            selectedFilters.claves_emisoras = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(
              newType,
              null,
              valuesTipoInformacion,
              valuesTipoInstrumentos, valuesModoListado
            );

            // Limpiando valores para campo tipo de documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(
              null,
              newType,
              valuesTipoInformacion,
              valuesTipoInstrumentos,
              valuesModoListado
            );
          } else if (selectSubPivot === "emisora") {
            prevInputSelected =
              prevInputSelected === "documento" ? "documento" : "instrumento";
            if (!prevInputSelected || prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo de documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                valuesTipoInstrumentos,
                valuesModoListado
              );
            }
          } else if (selectSubPivot === "documento") {
            prevInputSelected =
              prevInputSelected === "emisora" ? "emisora" : "instrumento";
            if (!prevInputSelected || prevInputSelected !== "emisora") {
              // Limpiando valores para campo emisoras
              form.claves_emisoras.value = "";
              selectedFilters.claves_emisoras = [];
              // Filtrado de emisoras
              this.onFilterClavesEmisorasHandler(
                newType,
                valuesTipoDocumentos,
                valuesTipoInformacion,
                valuesTipoInstrumentos, valuesModoListado
              );
            }
          }
        }
      } else {
        if (!selectPivot || selectPivot === "secondpath") {
          selectPivot = null;
          selectSubPivot = null;
          form.typeInfo = null;

          // Limpiando valores para campo tipo de documento
          form.tipo_documento.value = "";
          selectedFilters.tipo_documento = [];
          // Limpiando valores para campo emisoras
          form.claves_emisoras.value = "";
          selectedFilters.claves_emisoras = [];
          // Filtrado de emisoras
          this.onFilterClavesEmisorasHandler(null,null,null,null, valuesModoListado);

          // Limpiando valores para campo tipo informacion
          form.tipo_informacion.value = "";
          selectedFilters.tipo_informacion = [];
          // Filtrado de tipo de informacion
          this.onFilterInformacionHander(null, null, valuesModoListado);

          // Limpiando valores de periodo y ejercicio
          form.ejercicio.value = "";
          form.periodo.value = "";
          selectedFilters.periodos = [];
          idIsFoundByInfo = null;
          idIsFoundByDoc = null;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectPivot === "firstpath") {
          // Aplica solo para cuando no se ha seleccionado previamente un valor de tipo de informacion
          if (selectSubPivot === "instrumento") {
            selectSubPivot = null;
            form.typeInfo = null;
            // Limpiando valores para campo tipo informacion
            form.tipo_informacion.value = "";
            selectedFilters.tipo_informacion = [];
            // Filtrado de tipo de informacion
            this.onFilterInformacionHander(valuesEmisoras, null, valuesModoListado);

            // Limpiando valores de periodo y ejercicio
            form.ejercicio.value = "";
            form.periodo.value = "";
            selectedFilters.periodos = [];
            idIsFoundByInfo = null;
            idIsFoundByDoc = null;

            // Habilitando fechas en caso de estar deshabilitadas
            form.fecha_inicio.disabled = false;
            form.fecha_fin.disabled = false;
          } else if (selectSubPivot === "informacion") {
            if (prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo de documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                null,
                valuesModoListado
              );
              prevInputSelected = null;
            }
          }
        } else if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de documentos o emisoras
          if (!selectSubPivot || selectSubPivot === "instrumento") {
            selectSubPivot = null;
            // Limpiando valores para campo emisoras
            form.claves_emisoras.value = "";
            selectedFilters.claves_emisoras = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(
              newType,
              null,
              valuesTipoInformacion,
              null, valuesModoListado
            );
            // Limpiando valores para campo tipo de documento
            form.tipo_documento.value = "";
            selectedFilters.tipo_documento = [];
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(
              null,
              newType,
              valuesTipoInformacion,
              null,
              valuesModoListado
            );
          } else if (selectSubPivot === "emisora") {
            if (prevInputSelected !== "documento") {
              // Limpiando valores para campo tipo de documento
              form.tipo_documento.value = "";
              selectedFilters.tipo_documento = [];
              // Filtrado de tipo de documento
              this.onFilterDocumentosHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                null,
                valuesModoListado
              );
              prevInputSelected = null;
            }
          } else if (selectSubPivot === "documento") {
            if (prevInputSelected !== "emisora") {
              // Limpiando valores para campo emisoras
              form.claves_emisoras.value = "";
              selectedFilters.claves_emisoras = [];
              // Filtrado de emisoras
              this.onFilterClavesEmisorasHandler(
                newType,
                valuesTipoDocumentos,
                valuesTipoInformacion,
                null, valuesModoListado
              );
              prevInputSelected = null;
            }
          }
        }
      }
    } else if (type === 2) {
      // Tipo de información
      let idsExists = false;

      // Limpiando valores para campo tipo documento
      form.tipo_documento.value = "";
      selectedFilters.tipo_documento = [];

      // Limpiando valores de periodo y ejercicio
      form.ejercicio.value = "";
      form.periodo.value = "";
      selectedFilters.periodos = [];
      idIsFoundByInfo = null;
      idIsFoundByDoc = null;

      // Habilitando fechas en caso de estar deshabilitadas
      form.fecha_inicio.disabled = false;
      form.fecha_fin.disabled = false;

      if (valuesTipoInformacion !== "") {
        form.typeInfo = newType;
        // Verifica si el valor seleccionado para este campos es de información anual o trimestral
        idsExists = this.onCheckIfIdsExists(type, valuesTipoInformacion);
        if (idsExists) {
          const params = {
            tipoInformacion: valuesTipoInformacion,
            tipoDocumento: null,
            clave: form.claves_emisoras.value ? form.claves_emisoras.value.split("_")[1] : [],
            modoListado: valuesModoListado
          };
          this.props.onFilterPeriodos(params);
          this.props.onFilterEjercicios(params);
          idIsFoundByInfo = true;
        } else {
          idIsFoundByInfo = false;
        }

        if (!selectPivot || selectPivot === "thirdpath") {
          selectPivot = "thirdpath";
          // Limpiando valores para campo emisoras
          form.claves_emisoras.value = "";
          selectedFilters.claves_emisoras = [];
          // Filtrado de emisoras
          this.onFilterClavesEmisorasHandler(
            newType,
            null,
            valuesTipoInformacion,
            null, valuesModoListado
          );
          // Limpiando valores para campo tipo instrumento
          form.tipo_instrumento.value = "";
          selectedFilters.tipo_instrumento = [];
          // Filtrado de tipo de instrumento
          this.onFilterInstrumentoHandler(null, newType, valuesTipoInformacion, null, valuesModoListado);
          // Filtrado de tipo de documento
          this.onFilterDocumentosHandler(null, newType, valuesTipoInformacion, null, valuesModoListado);
        } else if (selectPivot === "firstpath") {
          // Limpiando valores para campo tipo documento
          form.tipo_documento.value = "";
          selectedFilters.tipo_documento = [];

          // Aplica solo para cuando No se ha seleccionado previamente un valor de tipo de instrumento
          if (!selectSubPivot || selectSubPivot === "informacion") {
            selectSubPivot = "informacion";
            // Filtrado de tipo de documento por emisora  tipo de información
            this.onFilterDocumentosHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion,
              null,
              valuesModoListado
            );
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento por emisora y tipo de información
            this.onFilterInstrumentoHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion, null, valuesModoListado
            );
          } else if (selectSubPivot === "instrumento") {
            // Filtrado de tipo de instrumento por emisora y tipo de información y tipo de informacion
            this.onFilterDocumentosHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion,
              valuesTipoInstrumentos,
              valuesModoListado
            );
          }
        } else if (selectPivot === "secondpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de emisora
          if (!selectSubPivot || selectSubPivot === "informacion") {
            selectSubPivot = "informacion";
            // Limpiando valores para campo emisoras
            form.claves_emisoras.value = "";
            selectedFilters.claves_emisoras = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(
              newType,
              null,
              valuesTipoInformacion,
              valuesTipoInstrumentos, valuesModoListado
            );
            // Filtrado de tipo de documentos
            this.onFilterDocumentosHandler(
              null,
              newType,
              valuesTipoInformacion,
              valuesTipoInstrumentos,
              valuesModoListado
            );
          } else if (selectSubPivot === "emisora") {
            // Filtrado de tipo de documentos
            this.onFilterDocumentosHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion,
              valuesTipoInstrumentos,
              valuesModoListado
            );
          }
        }
      } else {
        form.typeInfo = null;
        idIsFoundByInfo = false;

        if (!selectPivot || selectPivot === "thirdpath") {
          selectPivot = null;
          selectSubPivot = null;
          prevInputSelected = null;
          // Limpiando valores para campo emisoras
          form.claves_emisoras.value = "";
          selectedFilters.claves_emisoras = [];
          // Filtrado de emisoras
          this.onFilterClavesEmisorasHandler(null,null,null,null, valuesModoListado);
          // Limpiando valores para campo tipo instrumento
          form.tipo_instrumento.value = "";
          selectedFilters.tipo_instrumento = [];
          // Filtrado de tipo de instrumento
          this.onFilterInstrumentoHandler(null, null, null, null, valuesModoListado);
        } else if (selectPivot === "firstpath") {
          // Aplica solo para cuando no se ha seleccionado previamente un valor de tipo de instrumento
          if (selectSubPivot === "informacion") {
            selectSubPivot = null;
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento por emisora y tipo de información
            this.onFilterInstrumentoHandler(valuesEmisoras, null, null, null, valuesModoListado);
          }
        } else if (selectPivot === "secondpath") {
          // Aplica solo para cuando No se ha seleccionado previamente un valor de emisora
          if (selectSubPivot === "informacion") {
            selectSubPivot = null;
            // Limpiando valores para campo emisoras
            form.claves_emisoras.value = "";
            selectedFilters.claves_emisoras = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(
              null,
              null,
              null,
              valuesTipoInstrumentos, valuesModoListado
            );
          }
          form.typeInfo = null;
        }
      }
    } else if (type === 3) {
      // Tipo de documento
      let idsExists = false;
      newType = form.typeInfo;

      if (valuesTipoDocumentos.length > 0) {
        // Verifica si el valor seleccionado para este campos es de información anual
        idsExists = this.onCheckIfIdsExists(type, valuesTipoDocumentos);
        const params = {
          tipoInformacion: valuesTipoInformacion,
          tipoDocumento: valuesTipoDocumentos,
          clave: form.claves_emisoras.value ? form.claves_emisoras.value.split("_")[1] : [],
          modoListado: valuesModoListado
        };
        if (!idIsFoundByInfo && idsExists) {
          idIsFoundByDoc = true;
        } else {
          idIsFoundByDoc = false;
          this.props.onFilterPeriodos(params);
        }
        this.props.onFilterEjercicios(params);

        // Limpiando valores de periodo y ejercicio
        form.ejercicio.value = "";
        selectedFilters.periodos = [];

        // Habilitando fechas en caso de estar deshabilitadas
        form.fecha_inicio.disabled = false;
        form.fecha_fin.disabled = false;

        if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se han seleccionado anteriormente valores para emisoras o tipo de documento
          if (!selectSubPivot || selectSubPivot === "documento") {
            selectSubPivot = "documento";
            // Limpiando valores para campo emisoras
            form.claves_emisoras.value = "";
            selectedFilters.claves_emisoras = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(
              newType,
              valuesTipoDocumentos,
              valuesTipoInformacion,
              null, valuesModoListado
            );
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(
              null,
              newType,
              valuesTipoInformacion,
              valuesTipoDocumentos, valuesModoListado
            );
          } else if (selectSubPivot === "emisora") {
            prevInputSelected =
              prevInputSelected === "instrumento" ? "instrumento" : "documento";
            if (!prevInputSelected || prevInputSelected !== "instrumento") {
              // Limpiando valores para campo tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                valuesTipoDocumentos, valuesModoListado
              );
            }
          } else if (selectSubPivot === "instrumento") {
            prevInputSelected =
              prevInputSelected === "emisora" ? "emisora" : "documento";
            if (!prevInputSelected || prevInputSelected !== "emisora") {
              // Limpiando valores para campo emisoras
              form.claves_emisoras.value = "";
              selectedFilters.claves_emisoras = [];
              // Filtrado de emisoras
              this.onFilterClavesEmisorasHandler(
                newType,
                valuesTipoDocumentos,
                valuesTipoInformacion,
                valuesTipoDocumentos, valuesModoListado
              );
            }
          }
        } else if (selectPivot === "firstpath") {
          if (selectSubPivot === "informacion") {
            prevInputSelected =
              prevInputSelected === "instrumento" ? "instrumento" : "documento";
            if (!prevInputSelected || prevInputSelected !== "instrumento") {
              // Limpiando valores para campo tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion,
                valuesTipoDocumentos, valuesModoListado
              );
            }
          }
        } else if (selectPivot === "secondpath") {
          if (selectSubPivot === "informacion") {
            prevInputSelected =
              prevInputSelected === "emisora" ? "emisora" : "documento";
            if (!prevInputSelected || prevInputSelected !== "emisora") {
              // Limpiando valores para campo emisoras
              form.claves_emisoras.value = "";
              selectedFilters.claves_emisoras = [];
              // Filtrado de emisoras
              this.onFilterClavesEmisorasHandler(
                newType,
                valuesTipoDocumentos,
                valuesTipoInformacion,
                valuesTipoInstrumentos, valuesModoListado
              );
            }
          }
        }
      } else {
        if (selectPivot === "thirdpath") {
          // Aplica solo para cuando No se han seleccionado anteriormente valores para emisoras o tipo de documento
          if (!selectSubPivot || selectSubPivot === "documento") {
            selectSubPivot = null;

            // Limpiando valores para campo emisoras
            form.claves_emisoras.value = "";
            selectedFilters.claves_emisoras = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(
              newType,
              null,
              valuesTipoInformacion,
              null, valuesModoListado
            );
            // Limpiando valores para campo tipo instrumento
            form.tipo_instrumento.value = "";
            selectedFilters.tipo_instrumento = [];
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(
              null,
              newType,
              valuesTipoInformacion, null, valuesModoListado
            );
          } else if (selectSubPivot === "emisora") {
            if (prevInputSelected !== "instrumento") {
              // Limpiando valores para campo tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion, null, valuesModoListado
              );
              prevInputSelected = null;
            }
          } else if (selectSubPivot === "instrumento") {
            if (prevInputSelected !== "emisora") {
              // Limpiando valores para campo emisoras
              form.claves_emisoras.value = "";
              selectedFilters.claves_emisoras = [];
              // Filtrado de emisoras
              this.onFilterClavesEmisorasHandler(
                newType,
                null,
                valuesTipoInformacion,
                valuesTipoDocumentos, valuesModoListado
              );
              prevInputSelected = null;
            }
          }
        } else if (selectPivot === "firstpath") {
          if (selectSubPivot === "informacion") {
            if (prevInputSelected !== "instrumento") {
              // Limpiando valores para campo tipo instrumento
              form.tipo_instrumento.value = "";
              selectedFilters.tipo_instrumento = [];
              // Filtrado de tipo de instrumento
              this.onFilterInstrumentoHandler(
                valuesEmisoras,
                newType,
                valuesTipoInformacion, null, valuesModoListado
              );
              prevInputSelected = null;
            }
          }
        } else if (selectPivot === "secondpath") {
          if (selectSubPivot === "informacion") {
            if (prevInputSelected !== "emisora") {
              // Limpiando valores para campo emisoras
              form.claves_emisoras.value = "";
              selectedFilters.claves_emisoras = [];
              // Filtrado de emisoras
              this.onFilterClavesEmisorasHandler(
                newType,
                null,
                valuesTipoInformacion,
                valuesTipoInstrumentos, valuesModoListado
              );
              prevInputSelected = null;
            }
          }
        }
      }
    } else if (type === 4) {
      // Fecha inicio
      // Se verifica si los valores actuales para las fechas son distintos de vacio, de ser así se bloquean los
      // campos de periodo y ejercicio
      const disableValue =
        actualValue === "" &&
        (!form.fecha_fin.value || form.fecha_fin.value === "")
          ? false
          : true;
      form.ejercicio.disabled = disableValue;
      form.periodo.disabled = disableValue;

      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          actualValue,
          form.fecha_fin.value
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 5) {
      // Fecha fin
      // Se verifica si los valores actuales para las fechas son distintos de vacio, de ser así se bloquean los
      // campos de periodo y ejercicio
      const disableValue =
        actualValue === "" &&
        (!form.fecha_inicio.value || form.fecha_inicio.value === "")
          ? false
          : true;
      form.ejercicio.disabled = disableValue;
      form.periodo.disabled = disableValue;

      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          form.fecha_inicio.value,
          actualValue
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 6) {
      // Periodo
      // Se verifica si los valores actuales para periodos y ejercicio no estan vacios, de ser así se bloquean los
      // campos de fechas
      const disableValue =
        actualValue === "" &&
        (!form.ejercicio.value || form.ejercicio.value === "")
          ? false
          : true;
        form.fecha_fin.disabled = disableValue;
        form.fecha_inicio.disabled = disableValue;

      // Periodo actualiza valores de Ejercicio
        // Limpiando valores campo sub ramo
        selectedFilters.ejercicio = [];
        form.ejercicio.value = "";
        // Filtrando valores ejercicio
        let params = {
          tipoInformacion: valuesTipoInformacion,
          tipoDocumento: valuesTipoDocumentos,
          periodo: valuesPeriodo,
          clave: form.claves_emisoras.value ? form.claves_emisoras.value.split("_")[1] : [],
          modoListado: valuesModoListado
        };
        this.props.onFilterEjercicios(params);

    } else if (type === 7) {
      // Ejercicio
      // Se verifica si los valores actuales para ejercicios y periodos no estan vacios, de ser así se bloquean los
      // campos de fechas
      const disableValue =
        actualValue === "" && (!form.periodo.value || form.periodo.value === "")
          ? false
          : true;
          form.fecha_fin.disabled = disableValue;
      form.fecha_inicio.disabled = disableValue;
    }

    this.setState({
      selectPivot: selectPivot,
      selectedFilters: selectedFilters,
      prevInputSelected: prevInputSelected,
      selectSubPivot: selectSubPivot,
      idIsFoundByDoc: idIsFoundByDoc,
      form_filters: form,
      idIsFoundByInfo: idIsFoundByInfo
    });

    this.onFilterDataByEvent();
  };

  onDeleteFilterHandler = item => {
    let form = this.state.form_filters;
    let selectSubPivot = this.state.selectSubPivot;
    let selectPivot = this.state.selectPivot;
    let prevInputSelected = this.state.prevInputSelected;
    let selectedFilters = this.state.selectedFilters;

    let newFilterValues = null;
    let newStateValues = null;

    let idIsFoundByDoc = this.state.idIsFoundByDoc;
    let idIsFoundByInfo = this.state.idIsFoundByInfo;

    let [
      inputName,
      stateValues,
      filterValues,
      otherInputName
    ] = this.onGetNameStateFilterByInputHandler(item.type);

    const auxValue = item.type !== 1 && item.type !== 3 ? "" : item.value;
    newStateValues = this.onChangeStateHandler(
      stateValues,
      auxValue,
      item.type
    );

    newFilterValues = this.onChangeFilterHandler(
      filterValues,
      auxValue,
      item.label,
      item.type
    );
    form[`${inputName}`].value = newStateValues;
    selectedFilters[
      `${otherInputName ? otherInputName : inputName}`
    ] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario.
    let [
      valuesEmisoras,
      valuesTipoInstrumentos,
      valuesTipoInformacion,
      valuesTipoDocumentos,
      valuesModoListado
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (item.type === 0) {
      // Claves emisoras
      const newType = form.typeInfo;

      if (selectPivot === "firstpath") {
        // Limpiando valores para campo tipo instrumento
        selectedFilters.tipo_instrumento = [];
        form.tipo_instrumento.value = "";
        // Filtrado de tipo de instrumento sin valores (default)
        this.onFilterInstrumentoHandler(null,null,null,null, valuesModoListado);

        // Limpiando valores para campo tipo informacion
        selectedFilters.tipo_informacion = [];
        form.tipo_informacion.value = "";
        // Filtrado de tipo de información sin valores (default)
        this.onFilterInformacionHander(null, null, valuesModoListado);

        // Limpiando valores para campo tipo documento
        selectedFilters.tipo_documento = [];
        form.tipo_documento.value = "";
        form.typeInfo = null;
        selectPivot = false;

        // Limpiando valores de periodo y ejercicio
        selectedFilters.periodos = [];
        idIsFoundByInfo = null;
        idIsFoundByDoc = null;
        form.ejercicio.value = "";
        form.periodo.value = "";

        // Habilitando fechas en caso de estar deshabilitadas
        form.fecha_inicio.disabled = false;
        form.fecha_fin.disabled = false;
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "emisora") {
          form.typeInfo = null;
          // Limpiando valores tipo de informacion
          form.tipo_informacion.value = "";
          selectedFilters.tipo_informacion = [];
          // Filtrado de tipo de instrumento por emisora y tipo de información
          this.onFilterInformacionHander(null, valuesTipoInstrumentos, valuesModoListado);

          selectSubPivot = false;

          // Limpiando valores de periodo y ejercicio
          form.ejercicio.value = "";
          form.periodo.value = "";
          selectedFilters.periodos = [];
          idIsFoundByInfo = null;
          idIsFoundByDoc = null;

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_inicio.disabled = false;
          form.fecha_fin.disabled = false;
        } else if (selectSubPivot === "informacion") {
          if (prevInputSelected !== "documento") {
            // Limpiando valores para campo tipo documento
            selectedFilters.tipo_documento = [];
            form.tipo_documento.value = "";
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(
              null,
              newType,
              valuesTipoInformacion,
              valuesTipoInstrumentos,
              valuesModoListado
            );
            prevInputSelected = false;
          }
        }
      } else if (selectPivot === "thirdpath") {
        if (selectSubPivot === "emisora") {
          // Limpiando valores para campo tipo instrumento
          selectedFilters.tipo_instrumento = [];
          form.tipo_instrumento.value = "";
          // Filtrado de tipo de instrumento
          this.onFilterInstrumentoHandler(null, newType, valuesTipoInformacion,null, valuesModoListado);

          // Limpiando valores para campo tipo documento
          selectedFilters.tipo_documento = [];
          form.tipo_documento.value = "";
          // Filtrado de tipo de documento
          this.onFilterDocumentosHandler(null, newType, valuesTipoInformacion, null, valuesModoListado);

          selectSubPivot = false;
        } else if (selectSubPivot === "instrumento") {
          prevInputSelected = false;
          // Limpiando valores para campo tipo documento
          form.tipo_documento.value = "";
          selectedFilters.tipo_documento = [];
          // Filtrado de tipo de documento
          this.onFilterDocumentosHandler(
            null,
            newType,
            valuesTipoInformacion,
            valuesTipoInstrumentos,
            valuesModoListado
          );
        } else if (selectSubPivot === "documento") {
          if (prevInputSelected !== "instrumento") {
            // Limpiando valores para campo tipo instrumento
            selectedFilters.tipo_instrumento = [];
            form.tipo_instrumento.value = "";
            prevInputSelected = false;
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(
              null,
              newType,
              valuesTipoInformacion,
              valuesTipoDocumentos,
              valuesModoListado
            );
          }
        }
      }
    } else if (item.type === 1) {
      // Tipo instrumentos
      // En caso de no existir valores selecionados para tipos de instrumentos se asigna null a la variable
      valuesTipoInstrumentos =
        valuesTipoInstrumentos && valuesTipoInstrumentos.length > 0
          ? valuesTipoInstrumentos
          : null;


      const newType = form.typeInfo;
      if (selectPivot === "firstpath") {
        // Verificando si tipo de informacion fue seleccionado primero a tipo instrumento en le primer camino
        if (selectSubPivot === "instrumento") {
          if (!valuesTipoInstrumentos || valuesTipoInstrumentos.length === 0) {
            prevInputSelected = false;
            selectSubPivot = false;
          }
          // Limpiando valores para campo tipo informacion
          form.tipo_informacion.value = "";
          selectedFilters.tipo_informacion = [];
          // Filtrado de tipo de informacion
          this.onFilterInformacionHander(
            valuesEmisoras,
            valuesTipoInstrumentos, valuesModoListado
          );
          // Limpiando valores para campo tipo documento
          form.tipo_documento.value = "";
          selectedFilters.tipo_documento = [];

          // Limpiando valores de periodo y ejercicio
          selectedFilters.periodos = [];
          idIsFoundByDoc = null;
          idIsFoundByInfo = null;
          form.ejercicio.value = "";
          form.periodo.value = "";

          // Habilitando fechas en caso de estar deshabilitadas
          form.fecha_fin.disabled = false;
          form.fecha_inicio.disabled = false;
        } else if (selectSubPivot === "informacion") {
          if (prevInputSelected !== "documento") {
            // Limpiando valores para campo tipo documento
            selectedFilters.tipo_documento = [];
            form.tipo_documento.value = "";
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion,
              valuesTipoInstrumentos,
              valuesModoListado
            );
            if (
              !valuesTipoInstrumentos ||
              valuesTipoInstrumentos.length === 0
            ) {
              prevInputSelected = false;
            }
          }
        }
      } else if (selectPivot === "secondpath") {
        if (!valuesTipoInstrumentos || valuesTipoInstrumentos.length === 0) {
          selectPivot = false;
        }

        selectSubPivot = false;
        // Limpiando valores para campo tipo de documento
        selectedFilters.tipo_documento = [];
        form.tipo_documento.value = "";
        // Limpiando valores para campo emisoras
        selectedFilters.claves_emisoras = [];
        form.claves_emisoras.value = "";
        // Filtrado de emisoras por tipo de instrumento
        this.onFilterClavesEmisorasHandler(
          null,
          null,
          null,
          valuesTipoInstrumentos, valuesModoListado
        );
        // Limpiando valores para campo tipo informacion
        form.tipo_informacion.value = "";
        selectedFilters.tipo_informacion = [];
        // Filtrado de tipo de informacion por tipo instrumento
        this.onFilterInformacionHander(null, valuesTipoInstrumentos, valuesModoListado);

        // Limpiando valores de periodo y ejercicio
        selectedFilters.periodos = [];
        idIsFoundByDoc = null;
        idIsFoundByInfo = null;
        form.periodo.value = "";
        form.ejercicio.value = "";

        // Habilitando fechas en caso de estar deshabilitadas
        form.fecha_fin.disabled = false;
        form.fecha_inicio.disabled = false;
      } else if (selectPivot === "thirdpath") {
        if (selectSubPivot === "instrumento") {
          if (!valuesTipoInstrumentos || valuesTipoInstrumentos.length === 0) {
            prevInputSelected = false;
            selectSubPivot = false;
          }
          // Limpiando valores para campo emisoras
          selectedFilters.claves_emisoras = [];
          form.claves_emisoras.value = "";
          // Filtrado de emisoras por tipo de instrumento
          this.onFilterClavesEmisorasHandler(
            newType,
            null,
            valuesTipoInformacion,
            valuesTipoInstrumentos, valuesModoListado
          );
          // Limpiando valores para campo tipo documento
          selectedFilters.tipo_documento = [];
          form.tipo_documento.value = "";
          // Filtrado de tipo de documento
          this.onFilterDocumentosHandler(
            null,
            newType,
            valuesTipoInformacion,
            valuesTipoInstrumentos,
            valuesModoListado
          );
        } else if (selectSubPivot === "emisora") {
          if (prevInputSelected !== "documento") {
            // Limpiando valores para campo tipo documento
            selectedFilters.tipo_documento = [];
            form.tipo_documento.value = "";
            prevInputSelected = false;
            // Filtrado de tipo de documento
            this.onFilterDocumentosHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion,
              valuesTipoInstrumentos,
              valuesModoListado
            );
          }
        } else if (selectSubPivot === "documento") {
          if (prevInputSelected !== "emisora") {
            // Limpiando valores para campo emisoras
            selectedFilters.claves_emisoras = [];
            form.claves_emisoras.value = "";
            prevInputSelected = false;
            // Filtrado de emisoras por tipo de instrumento
            this.onFilterClavesEmisorasHandler(
              newType,
              valuesTipoDocumentos,
              valuesTipoInformacion,
              valuesTipoInstrumentos, valuesModoListado
            );
          }
        }
      }
    } else if (item.type === 2) {
      // Tipo de información
      // Limpiando valores para campo tipo documento
      selectedFilters.tipo_documento = [];
      form.tipo_documento.value = "";
      // Ocultando campos de periodo y ejercicio

      if (selectPivot === "firstpath") {
        // Verificando si tipo de informacion fue seleccionado primero a tipo instrumento en le primer camino
        if (selectSubPivot === "informacion") {
          selectSubPivot = false;
          // Limpiando valores para campo tipo instrumento
          selectedFilters.tipo_instrumento = [];
          form.tipo_instrumento.value = "";

          // Filtrado de tipo de informacion por ermisora
          this.onFilterInstrumentoHandler(valuesEmisoras, null,null,null, valuesModoListado);

        }
        idIsFoundByInfo = false;
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "informacion") {
          // Limpiando valores para campo emisoras
          selectedFilters.claves_emisoras = [];
          form.claves_emisoras.value = "";
          // Filtrado de emisoras por tipo de instrumento
          this.onFilterClavesEmisorasHandler(
            null,
            null,
            null,
            valuesTipoInstrumentos, valuesModoListado
          );
          selectSubPivot = false;
        }

        // Limpiando valores de periodo y ejercicio
        selectedFilters.periodos = [];
        form.ejercicio.value = "";
        idIsFoundByDoc = false;
        form.periodo.value = "";
        idIsFoundByInfo = false;
      } else if (selectPivot === "thirdpath") {
        selectSubPivot = false;
        // Limpiando valores para campo tipo instrumento
        selectedFilters.tipo_instrumento = [];
        form.tipo_instrumento.value = "";

        // Filtrado de tipo de informacion por ermisora
        this.onFilterInstrumentoHandler(null,null,null,null, valuesModoListado);

        // Limpiando valores para campo emisoras
        form.claves_emisoras.value = "";
        selectedFilters.claves_emisoras = [];

        // Filtrado de emisoras por tipo de instrumento
        this.onFilterClavesEmisorasHandler(null,null,null,null, valuesModoListado);

        // Limpiando valores de periodo y ejercicio
        form.ejercicio.value = "";
        form.periodo.value = "";
        selectedFilters.periodos = [];
        idIsFoundByInfo = false;
        idIsFoundByDoc = false;
      }
    } else if (item.type === 3) {
      // Tipo de documento
      const newType = form.typeInfo;

      if (selectPivot === "thirdpath") {
        if (selectSubPivot === "documento") {
          // Limpiando valores para campo tipo instrumento
          form.tipo_instrumento.value = "";
          selectedFilters.tipo_instrumento = [];
          // Filtrado de tipo de informacion por ermisora
          this.onFilterInstrumentoHandler(null, newType, valuesTipoInformacion,null, valuesModoListado);

          // Limpiando valores para campo emisoras
          form.claves_emisoras.value = "";
          selectedFilters.claves_emisoras = [];
          // Filtrado de emisoras por tipo de instrumento
          this.onFilterClavesEmisorasHandler(
            newType,
            null,
            valuesTipoInformacion,
            null, valuesModoListado
          );
          prevInputSelected = false;
        } else if (selectSubPivot === "emisora") {
          if (prevInputSelected !== "instrumento") {
            // Limpiando valores para campo tipo instrumento
            selectedFilters.tipo_instrumento = [];
            form.tipo_instrumento.value = "";
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion, null, valuesModoListado
            );

            if (valuesTipoDocumentos && valuesTipoDocumentos.length === 0) {
              prevInputSelected = false;
            }
          }
        }
      } else if (selectPivot === "firstpath") {
        if (selectSubPivot === "informacion") {
          if (prevInputSelected !== "instrumento") {
            // Limpiando valores para campo tipo instrumento
            selectedFilters.tipo_instrumento = [];
            form.tipo_instrumento.value = "";
            // Filtrado de tipo de instrumento
            this.onFilterInstrumentoHandler(
              valuesEmisoras,
              newType,
              valuesTipoInformacion,
              valuesTipoDocumentos, valuesModoListado
            );
            if (valuesTipoDocumentos && valuesTipoDocumentos.length === 0) {
              prevInputSelected = false;
            }
          }
        }
      } else if (selectPivot === "secondpath") {
        if (selectSubPivot === "informacion") {
          if (!prevInputSelected || prevInputSelected !== "emisora") {
            // Limpiando valores para campo emisoras
            form.claves_emisoras.value = "";
            selectedFilters.claves_emisoras = [];
            // Filtrado de emisoras
            this.onFilterClavesEmisorasHandler(
              newType,
              valuesTipoDocumentos,
              valuesTipoInformacion,
              valuesTipoInstrumentos, valuesModoListado
            );

            if (valuesTipoDocumentos && valuesTipoDocumentos.length === 0) {
              prevInputSelected = false;
            }
          }
        }
      }
      if (valuesTipoDocumentos && valuesTipoDocumentos.length === 0) {
        idIsFoundByDoc = false;
      }
    } else if (item.type === 4) {
      // Fecha inicio
      const disableValue =
        !form.fecha_fin.value || form.fecha_fin.value === "" ? false : true;
        form.ejercicio.disabled = disableValue;
        form.errorDate = false;
        form.periodo.disabled = disableValue;
    } else if (item.type === 5) {
      // Fecha fin
      const disableValue =
        !form.fecha_inicio.value || form.fecha_inicio.value === "" ? false : true;
      form.ejercicio.disabled = disableValue;
      form.periodo.disabled = disableValue;
      form.errorDate = false;
    } else if (item.type === 6) {
      // Periodos
      const disableValue =
        !form.ejercicio.value || form.ejercicio.value === "" ? false : true;
        form.fecha_fin.disabled = disableValue;
      form.fecha_inicio.disabled = disableValue;
    } else if (item.type === 7) {
      // Ejercicio
      const disableValue = !form.ejercicio.value || form.periodo.value === "" ? false : true;
      form.fecha_fin.disabled = disableValue;
      form.fecha_inicio.disabled = disableValue;
    } else if (item.type === 8) {
        // Limpiando valores para campo emisoras
        form.claves_emisoras.value = "";
        selectedFilters.claves_emisoras = [];
        this.onFilterClavesEmisorasHandler(null,null,null,null,null);
        // Limpiando valores para campo tipo instrumento
        selectedFilters.tipo_instrumento = [];
        form.tipo_instrumento.value = "";
        this.onFilterInstrumentoHandler(null,null,null,null, null);
        // Limpiando valores para campo tipo informacion
        selectedFilters.tipo_informacion = [];
        form.tipo_informacion.value = "";
        this.onFilterInformacionHander(null, null, null);
        // Limpiando valores para campo tipo documento
        selectedFilters.tipo_documento = [];
        form.tipo_documento.value = "";
        form.typeInfo = null;
        selectPivot = false;
        // Limpiando valores de periodo y ejercicio
        selectedFilters.periodos = [];
        idIsFoundByInfo = null;
        idIsFoundByDoc = null;
        form.ejercicio.value = "";
        form.periodo.value = "";
        // Habilitando fechas en caso de estar deshabilitadas
        form.fecha_inicio.disabled = false;
        form.fecha_fin.disabled = false;
    }

    this.setState({
      selectedFilters: selectedFilters,
      selectPivot: selectPivot,
      form_filters: form,
      prevInputSelected: prevInputSelected,
      selectSubPivot: selectSubPivot,
      idIsFoundByDoc: idIsFoundByDoc,
      idIsFoundByInfo: idIsFoundByInfo,
    });
    this.onFilterDataByEvent();
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => {
      return (
        <li
          className="list-group-item"
          onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}
        >
          {item.clave}
        </li>
      )});
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let emisoraSeleccionada = id;
    let claveSeleccionada = clave;
    const form_clave = this.state.form_clave;
    let disableEnableButtons = clave !== null ? false:  true;
    if (option === 0) {
      form_clave.clave.value = clave;
    } 
      this.props.setClave(clave);
      this.props.setPage(1);

    this.setState({
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: emisoraSeleccionada,
      clave: claveSeleccionada,
      disableEnableButtons: disableEnableButtons,
      form_clave: form_clave
    });
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;
    const listado = this.props.listadoClaves ? this.onPrintAutoCompleteHandler() : null;
    const textFound = getLan() === "es" ? "Ver todos los resultados para" : "See all results for";
    const textNotFound = getLan() === "es" ? "No existen resultados para la búsqueda" : "There are no search results";

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className={"list-group-item"}
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {`${textFound}: ${value}`}
        </li>
      ) : (
        <li className={"list-group-item"}>{`${textNotFound}: ${value}`}</li>
      );
    return (
      <>
        {this.onPrintOptionSelectedHandler()}
        <div className={[ classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null].join(" ")}>
          <Form
            onSubmit={this.onSubmitSearchModelOneHandler}
            initialValues={{ search_field: this.state.form_clave.clave.value }}
            render={() => (
              <form ref={this.formSearch} onSubmit={this.onSubmitSearchModelOneHandler}
              >
                <Field
                  placeholder={getLan() === "es" ? "Buscar por clave de cotización" : "Search by ticker"}
                  id="search_field"
                  name="search_field"
                  elementType="input"
                  type="text"
                  component={renderInput}
                  onInputChange={event => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              "list-group",
              classes.autoCompleteContainer,
              this.state.form_clave.clave.value === null || this.state.form_clave.clave.value === "" ? classes.hidden : null,
              this.state.esconderAutoComplete ? classes.hidden : null
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </>
    );
  };

  onPrintOptionSelectedHandler = value => {
    let infoShow = null;
    const textFound =
      getLan() === "es" ? "Ver todos los resultados para" : "See all results for";
    if (this.state.typeClaveSelected === 0) {
      infoShow = `${textFound}: ${this.state.valueClave}`;
    } else if (this.state.valueClave && this.state.valueClave !== "") {
      infoShow = this.state.valueClave;
    }

    return (
      <div className={[ classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null ].join(" ")}>
        <span className={classes.filterContainer}>
          <span
            className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {infoShow}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onClaveSelectedHandler(null, null, 0)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };
  
  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [
      ...this.state.selectedFilters.modoListado,
      ...this.state.selectedFilters.claves_emisoras,
      ...this.state.selectedFilters.tipo_instrumento,
      ...this.state.selectedFilters.tipo_informacion,
      ...this.state.selectedFilters.tipo_documento,
      ...this.state.selectedFilters.fechas,
      ...this.state.selectedFilters.periodos
    ];
    filters = (
      <div id="allFitersContainers" className={classes.filter_container_outside_model_one} >
        {filterList.map(item => {
          const valueToshow = item.type === 4 || item.type === 5 || item.type === 6 || item.type === 7 ? item.value : item.label;
          return (
            <div key={`${item.nombre}-${item.id}`} className={classes.filter_container_model_one} >
              <span className={["montse", classes.filter_text_model_one].join(" ")}>
                {valueToshow}
              </span>
              <span className={classes.filter_icon_button_model_one}>
                <i
                  className="far fa-times-circle"
                  onClick={() => this.onDeleteFilterHandler(item)}
                />
              </span>
            </div>
          );
        })}
      </div>
    );
    return filters;
  };

  render() {
    const filtro = this.props.modelo ? this.onPrintFrameHandler() : null;

    return filtro;
  }
}

const mapStateToProps = state => {
  return {
    modoListado: state.bancosInformacion.modoListado ? state.bancosInformacion.modoListado : [],
    listadoClaves: state.bancosInformacion.claves ? state.bancosInformacion.claves : [],
    bancoInformacion: state.bancosInformacion.bancoInformacion,
    claves_emisoras: state.bancosInformacion.emisoras_bancos,
    tipo_documento: state.bancosInformacion.tipos_documentos,
    tipo_informacion: state.bancosInformacion.tipos_informacion,
    periodos: state.bancosInformacion.periodos_bi,
    ejercicios: state.bancosInformacion.ejercicios_bi,
    tipo_instrumento: state.bancosInformacion.tipos_instrumentos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitBancoInformacion: params => {
      dispatch(actions.initBancoInformacion(params));
    },
    onFilterModoListado: params => {
      dispatch(actions.fetchModoListado(getLan()));
    },
    onFilterClavesEmisoras: params => {
      dispatch(actions.filterEmisorasBanco(params));
    },
    onFilterPeriodos: params => {
      dispatch(actions.fetchPeriodosBancoInformacion(params));
    },
    onFilterEjercicios: params => {
      dispatch(actions.fetchEjerciciosBancoInformacion(params));
    },
    onFilterTipoDocumentos: params => {
      dispatch(actions.initBancoTipoDocumento(params));
    },
    onFilterAllTipoInformacion: params => {
      dispatch(actions.initTiposInformacionBanco(params));
    },
    onFilterBancosByClave: clave => {
      dispatch(actions.initBancoByClave(clave));
    },
    onFilterInstrumentos: params => {
      dispatch(actions.initBancoTipoInstrumento(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterBancosInformacion);

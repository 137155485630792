import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import Accordion from "../../../components/ui/accordion/Accordion";
import { getRealDateMilliesDayMonthYear } from "../../../stores/utils/utilities";

// import Filter from "../../../components/ui/filterSuspensiones/FilterSuspensiones";
import Pagination from "../../../components/ui/pagination/Pagination";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class Suspensiones extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    indexSelected: 0,
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    periodos: null,
    capital_social: null,
    fechaInicio: null,
    fechaFin: null,
    meses: null
  };

  componentWillMount() {
    this.props.onInitIntl();

    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      periodo: this.state.periodos,
      capitalSocial: this.state.capital_social
    };

    this.props.onInitSuspensiones(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.suspensiones !== prevProps.suspensiones) {
      let suspensiones = null;

      suspensiones = this.props.suspensiones;
      let resource = suspensiones;
      this.onPageChangeHandler(resource, 0, 0);
    }
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let index_final = null;
    let index_inicial = null;
    let data = resource ? resource : this.props.datos;
    let resourceList = [];

    if (pageNumber) {
      index_final = pageNumber * this.state.itemsPerPage;
      index_inicial = index_final - this.state.itemsPerPage;
    } else {
      index_final = this.state.itemsPerPage;
      index_inicial = 0;
    }
    resourceList = data.slice(index_inicial, index_final);

    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.props.history.push("#suspensiones");
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.onPageChangeHandler(this.props.suspensiones, pageNumber);
    }
  }

  onSetPeriodosFilter = periodos => {
    this.setState({
      periodos: periodos
    });
  };

  onSetCapitalSocialFilter = capital_social => {
    this.setState({
      capital_social: capital_social
    });
  };

  onSetMeses = meses => {
    this.setState({
      meses: meses
    });
    this.changeMeses(null, null, meses);
  };
  onSetFechaInicio = fechaInicio => {
    this.setState({
      fechaInicio: fechaInicio
    });
    this.changeMeses(fechaInicio, null, null);
  };
  onSetFechaFin = fechaFin => {
    this.setState({
      fechaFin: fechaFin
    });
    this.changeMeses(null, fechaFin, null);
  };

  onCheckIfDatesAreValid = (inicio, fin) => {
    var f_inicio = new Date(
      inicio
        .split("-")
        .reverse()
        .join("-")
    );
    var f_fin = new Date(
      fin
        .split("-")
        .reverse()
        .join("-")
    );
    let validacion = f_inicio >= f_fin;
    return validacion;
  };

  changeMeses = (fecha_inicial = null, fecha_fin = null, periodo = null) => {
    let datos = this.props.suspensiones;
    let datos_resultado = [];
    if (fecha_inicial) {
      if (this.state.fechaFin) {
        datos.map((suspension, i) => {
          let mayor = this.onCheckIfDatesAreValid(
            getRealDateMilliesDayMonthYear(suspension.haltDate)
              .split("/")
              .join("-"),
            fecha_inicial
          );
          let menor = this.onCheckIfDatesAreValid(
            this.state.fechaFin.split("/").join("-"),
            getRealDateMilliesDayMonthYear(suspension.haltDate)
              .split("/")
              .join("-")
          );

          if (mayor && menor) {
            datos_resultado.push(suspension);
          }
          return null;
        });
      } else {
        datos.map((suspension, i) => {
          let mayor = this.onCheckIfDatesAreValid(
            getRealDateMilliesDayMonthYear(suspension.haltDate)
              .split("/")
              .join("-"),
            fecha_inicial.split("/").join("-")
          );
          if (mayor) {
            datos_resultado.push(suspension);
          }
          return null;
        });
      }
    } else if (fecha_fin) {
      if (this.state.fechaInicio) {
        datos.map((suspension, i) => {
          let mayor = this.onCheckIfDatesAreValid(
            getRealDateMilliesDayMonthYear(suspension.haltDate)
              .split("/")
              .join("-"),
            this.state.fechaInicio.split("/").join("-")
          );
          let menor = this.onCheckIfDatesAreValid(
            fecha_fin.split("/").join("-"),
            getRealDateMilliesDayMonthYear(suspension.haltDate)
              .split("/")
              .join("-")
          );

          if (mayor && menor) {
            datos_resultado.push(suspension);
          }
          return null;
        });
      } else {
        datos.map((suspension, i) => {
          let menor = this.onCheckIfDatesAreValid(
            fecha_fin.split("/").join("-"),
            getRealDateMilliesDayMonthYear(suspension.haltDate)
              .split("/")
              .join("-")
          );
          if (menor) {
            datos_resultado.push(suspension);
          }
          return null;
        });
      }
    } else if (periodo) {
      let today = new Date();
      switch (parseInt(periodo)) {
        case 1:
          today.setMonth(today.getMonth() - 1);
          today = getRealDateMilliesDayMonthYear(+today)
            .split("/")
            .join("-");
          datos.map((suspension, i) => {
            let mayor = this.onCheckIfDatesAreValid(
              getRealDateMilliesDayMonthYear(suspension.haltDate)
                .split("/")
                .join("-"),
              today
            );
            if (mayor) {
              datos_resultado.push(suspension);
            }
            return null;
          });

          break;
        case 2:
          today.setMonth(today.getMonth() - 3);
          today = getRealDateMilliesDayMonthYear(+today)
            .split("/")
            .join("-");
          datos.map((suspension, i) => {
            let mayor = this.onCheckIfDatesAreValid(
              getRealDateMilliesDayMonthYear(suspension.haltDate)
                .split("/")
                .join("-"),
              today
            );
            if (mayor) {
              datos_resultado.push(suspension);
            }
            return null;
          });
          break;
        case 3:
          today.setMonth(today.getMonth() - 6);
          today = getRealDateMilliesDayMonthYear(+today)
            .split("/")
            .join("-");
          datos.map((suspension, i) => {
            let mayor = this.onCheckIfDatesAreValid(
              getRealDateMilliesDayMonthYear(suspension.haltDate)
                .split("/")
                .join("-"),
              today
            );
            if (mayor) {
              datos_resultado.push(suspension);
            }
            return null;
          });

          break;

        default:
          break;
      }
    }
    if (fecha_inicial !== null || fecha_fin !== null || periodo !== null) {
      if (datos_resultado.length === 1 && datos_resultado[0] === undefined) {
        datos_resultado = null;
      }
      this.setState({
        resourceList: datos_resultado
      });
    }
  };

  render() {
    let contenidoMostrar = null;

    let suspensiones_result = null;
    if (this.state.resourceList) {
      suspensiones_result = this.state.resourceList;
    }
    const numRows = this.props.suspensiones ? this.props.suspensiones.length : 0;
    const suspensiones = (
      <React.Fragment>
        {/* <Filter
          modelo="modelo_1"
          setMeses={meses => this.onSetMeses(meses)}
          setFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
          setFechaFin={fechaFin => this.onSetFechaFin(fechaFin)}
        /> */}

        <div className="margen_top_30 width_cien" >
          <Accordion datos={suspensiones_result} nombre="suspensiones" icon={""} tipo={17} subtipo={11} />
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </div>
      </React.Fragment>
    );
    contenidoMostrar = <div className="margen_50">{suspensiones}</div>;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={"texto_42 nunito regular"} id="suspensiones">
          <FormattedHTMLMessage id="informacionmercado.suspensiones.titulo" defaultMessage=" " />
          </h1>
          <div className="margen_top_50">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitSuspensiones: params => {
      dispatch(actions.initSuspensiones(params));
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(43));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    suspensiones: state.informacionMercado.suspensiones
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Suspensiones);

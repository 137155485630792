import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  solicitudes: null,
  solicitudesSearch: null,
  documentos: null,
  avisosOfertasPublicas: null,
  documentosDefinitivos: null,
  prospectos: null,
  documentosProspectos: null,
  detalleProspectos: null,
  solicitudesDetalle: null,
  claves_cotizacion: null,
  instrumentos: null,
  series: null,
  tipoDocumento: null,
  ddClavesCotizacion: null,
  ddInstrumentos: null,
  ddTipoDocumentos: null,
  ddSerie: null,
  ddClavesWarrant: null,
  numEnvios: null,
  preliminares: [],
  colocaciones: [],
  avisosOfertasPublicasSearch: [],
  tiposBonoASG: [],
};

const setSolicitudes = (state, action) => {
  return updateObject(state, action);
};

const setSolicitudesTipoDocumento = (state, action) => {
  return updateObject(state, action);
};

const setSolicitudesDetalle = (state, action) => {
  return updateObject(state, action);
};
const setDocumentosListado = (state, action) => {
  return updateObject(state, action);
};

const setAvisosOfertasPublicas = (state, action) => {
  return updateObject(state, action);
};

const setDocumentosDefinitivos = (state, action) => {
  return updateObject(state, action);
};

const setProspectosColocacion = (state, action) => {
  return updateObject(state, action);
};

const setDocumentosProspectos = (state, action) => {
  return updateObject(state, action);
};

const setDetalleProspectos = (state, action) => {
  return updateObject(state, action);
};

const setClavesCotizacion = (state, action) => {
  return updateObject(state, action);
};

const setTipoInstrumentos = (state, action) => {
  return updateObject(state, action);
};

const setSeries = (state, action) => {
  return updateObject(state, action);
};

const setDdClavesCotizacion = (state, action) => {
  return updateObject(state, action);
};

const setDdInstrumentos = (state, action) => {
  return updateObject(state, action);
};

const setDdTipoDocumentos = (state, action) => {
  return updateObject(state, action);
};

const setDdSerie = (state, action) => {
  return updateObject(state, action);
};

const setDdWarrant = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SOLICITUDES_SUCCESS:
      return setSolicitudes(state, action);
    case actionTypes.FETCH_SOLICITUDESDETALLE_SUCCESS:
      return setSolicitudesDetalle(state, action);
    case actionTypes.FETCH_DOCUMENTOSLISTADO_SUCCESS:
      return setDocumentosListado(state, action);
    case actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SUCCESS:
      return setAvisosOfertasPublicas(state, action);
    case actionTypes.FETCH_DOCUMENTOSDEFINITIVOS_SUCCESS:
      return setDocumentosDefinitivos(state, action);
    case actionTypes.FETCH_PROSPECTOSCOLOCACION_SUCCESS:
      return setProspectosColocacion(state, action);
    case actionTypes.FETCH_DOCUMENTOSPROSPECTOS_SUCCESS:
      return setDocumentosProspectos(state, action);
    case actionTypes.FETCH_DETALLEPROSPECTOS_SUCCESS:
        return setDetalleProspectos(state, action);
    case actionTypes.FETCH_CLAVECOTIZACION_SUCCESS:
      return setClavesCotizacion(state, action);
    case actionTypes.FETCH_PRELIMINARES_SUCCESS:
      return setClavesCotizacion(state, action);
    case actionTypes.FETCH_TIPOINSTRUMENTOS_SUCCESS:
      return setTipoInstrumentos(state, action);
    case actionTypes.FETCH_SERIE_SUCCESS:
      return setSeries(state, action);
    case actionTypes.FETCH_DDCLAVESCOTIZACION_SUCCESS:
      return setDdClavesCotizacion(state, action);
    case actionTypes.FETCH_DDINSTRUMENTO_SUCCESS:
      return setDdInstrumentos(state, action);
    case actionTypes.FETCH_DDTIPODOCUMENTOS_SUCCESS:
      return setDdTipoDocumentos(state, action);
    case actionTypes.FETCH_DDSERIE_SUCCESS:
      return setDdSerie(state, action);
    case actionTypes.FETCH_DDCLAVESWARRANT_SUCCESS:
      return setDdWarrant(state, action);
    case actionTypes.FETCH_SOLICITUDESTIPODOC_SUCCESS:
      return setSolicitudesTipoDocumento(state, action);
    case actionTypes.FETCH_COLOCACIONESLISTADO_SUCCESS:
      return setSolicitudesTipoDocumento(state, action);
    case actionTypes.FETCH_TIPOS_BONO_PROSPECTOS_ASG:
      return setSolicitudes(state, action);
    default:
      return state;
  }
};

export default reducer;

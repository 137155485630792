import axios from "axios";
import * as actionTypes from "./actionTypes";

import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();
const setInitCasa = data => {
    return{
        type: actionTypes.FETCH_CASAS_SUCCESS,
        casas: data
    }
};

export const initCasas = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/costos-cb/lang/" + lang)
      .then(response => {
        dispatch(setInitCasa(response.data));
      })
      .catch(err => console.log(err));
  };
};

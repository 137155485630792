import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import classes from "./QueEstaPasandoDocs.css";
import Auxs from "../../../../hoc/auxs/Auxs";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import Item from "./item/Item";
import Pagination from "../../../../components/ui/pagination/Pagination";
import Back from "../../../../components/ui/back/Back";
import Loader from "../../../../components/loader/Loader";
import queryString from "query-string";

class QueEstaPasandoDocs extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    data: [],
    tipo_imagen: null,
    activePage: 1,
    numRows: 1,
    dataView: [],
    loader: true,
    palabraClave: null,
  };

  componentDidMount() {
    this.initData(true);
  }

  initData(init = false) {
    if (init) {
      this.props.onInitIntl(5);
    }
    let paramsString = queryString.parse(this.props.location.search);

    let videosFlag = paramsString["videos"] === null ? true : false;
    let imagenesFlag = paramsString["imagenes"] === null ? true : false;
    let documentosFlag = paramsString["documentos"] === null ? true : false;

    if (videosFlag || imagenesFlag || documentosFlag) {
      let tipo_imagen = null;
      if (videosFlag) {
        this.props.getVideosEmbajador();
        tipo_imagen = 1;
      }
      if (imagenesFlag) {
        this.props.getImagenesQueEstaPasando();
        tipo_imagen = 2;
      }
      if (documentosFlag) {
        this.props.getDocumentosQueEstaPasando();
        tipo_imagen = 3;
      }

      this.setState({
        videosFlag: videosFlag,
        imagenesFlag: imagenesFlag,
        documentosFlag: documentosFlag,
        tipo_imagen: tipo_imagen,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionarioIntl !== prevProps.diccionarioIntl) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionarioIntl) {
        const messages = defineMessages(this.props.diccionarioIntl);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.videos !== prevProps.videos) {
      if (this.state.videosFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        let videos = this.props.videos.filter(video => video.snippet.thumbnails.default !== undefined);
        lista_ver = videos.slice(index_inicial, index_final);
        this.setState({
          loader: false,
          dataView: lista_ver,
          tipo_imagen: 1,
          data: this.props.videos,
        });
      }
    }
    if (this.props.imagenes !== prevProps.imagenes) {
      if (this.state.imagenesFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.imagenes.slice(index_inicial, index_final);

        this.setState({
          loader: false,
          dataView: lista_ver,
          tipo_imagen: 2,
          data: this.props.imagenes,
        });
      }
    }
    if (this.props.pdfs !== prevProps.pdfs) {
      if (this.state.documentosFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.pdfs.slice(index_inicial, index_final);

        this.setState({
          loader: false,
          dataView: lista_ver,
          tipo_imagen: 3,
          data: this.props.pdfs,
        });
      }
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      let lista_ver = [];
      let index_final = pageNumber * this.state.itemsPerPage;
      let index_inicial = index_final - this.state.itemsPerPage;
      lista_ver = this.state.data.slice(index_inicial, index_final);
      this.setState({
        activePage: pageNumber,
        dataView: lista_ver,
      });
    }
  }

  onSetPalabraClave = (palabra_calve) => {
    this.setState({
      palabraClave: palabra_calve,
    });
  };
  onSetPage = (page) => {
    this.setState({
      activePage: page,
    });
  };
  onSetParams = ({ keyword, from, to, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(keyword, from, to, page);
    }
  };

  changeHistoryReset() {
    this.getData(null);
  }

  changeHistory(keyword = null) {
    this.setState({
      loader: true,
    });

    this.getData(keyword);
  }

  getData(params) {
    if (this.props.articulosAcademicosFlag) {
      this.props.onArticulosAcademicos(params);
    }
    if (this.props.videosFlag) {
      this.props.getVideosEmbajador(params);
    }
    if (this.props.documentosFlag) {
      this.props.getDocumentosQueEstaPasando(params);
    }
    if (this.props.ImagenesFlag) {
      this.props.getImagenesQueEstaPasando(params);
    }
  }

  render() {
    let titulo = "institutobiva.bivateca.documentos.test";
    let titulo_imagen = "";
    switch (this.state.tipo_imagen) {
      case 1:
        titulo = "institutobiva.embajador.subtitulo4";
        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.embajador.subtitulo4"]
            : null;
        break;
      case 2:
        titulo = "institutobiva.embajador.subtitulo5";
        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.embajador.subtitulo5"]
            : null;

        break;
      case 3:
        titulo = "institutobiva.embajador.subtitulo6";

        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.embajador.subtitulo6"]
            : null;
        break;

      default:
        break;
    }
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader
          loaderDisable={(disable) => this.setState({ loader: disable })}
        />
      );
    }
    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <Auxs>
          {loader}

          <Back show={true} onBack={(e) => this.props.onBack(e)} />
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1
                  className={[classes.main_title, "texto_42", "nunito"].join(
                    " "
                  )}
                >
                  <FormattedMessage
                    id={"institutobiva.bivateca.documentos.titulo1"}
                    defaultMessage=" "
                  />
                </h1>
              </div>
            </div>
          </div>
          <div className={["row"].join(" ")}>
            <div className="col sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage id={titulo} defaultMessage=" " />
                </h2>
              </div>
            </div>

            <div className={["col-12 sin_padding"].join(" ")}>
              <Item
                datos={this.state.data}
                tipo={1}
                tipo_imagen={this.state.tipo_imagen}
                titulo_slider={titulo_imagen}
                history={this.props.history}
              />
            </div>
            
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    getVideosEmbajador: () => {
      dispatch(action.getVideosEmbajador());
    },
    getImagenesQueEstaPasando: () => {
      dispatch(action.getImagenesQueEstaPasando());
    },
    getDocumentosQueEstaPasando: () => {
      dispatch(action.getDocumentosQueEstaPasando());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    diccionarioIntl: state.intl.diccionario,
    videos: state.videos.videos_embajador
      ? state.videos.videos_embajador.items
      : null,
    imagenes: state.imagenes.queEstaPasando,
    pdfs: state.documentos.queEstaPasando,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QueEstaPasandoDocs);

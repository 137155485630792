import React, { Component } from "react";
import classes from "./Item.css";

import imagen1 from "../../../../../assets/images/fondo_carousel/azul.png";
import imagen2 from "../../../../../assets/images/fondo_carousel/naranja.png";
import imagen3 from "../../../../../assets/images/fondo_carousel/morado.png";
import imagen4 from "../../../../../assets/images/fondo_carousel/azul2.png";
import imagen5 from "../../../../../assets/images/fondo_carousel/rosa.png";
import imagen6 from "../../../../../assets/images/fondo_carousel/naranja2.png";
import { getLan, getUrlDocument } from "../../../../../stores/utils/utilities";



class Item extends Component {

  render() {
    let imagen_fondo = imagen1;
    let esconde = [];
    let diseño = [];

    if (this.props.datos) {
      const titulo_slider = this.props.titulo_slider ? this.props.titulo_slider : "";

      function escogeImagen(tipo, url = null, mostrar = true) {
        let ruta = null;
        ruta = getUrlDocument(url);
        if (!mostrar) {
          esconde.push(classes.esconde);
        }

        imagen_fondo = ruta;
        switch (tipo) {
          case 1:
            // Articulos academicos
            diseño = classes.diseno1;
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen1;

            break;
          case 2:
            //Revistas
            diseño = classes.diseno2;
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen2;

            break;
          case 3:
            //Escritos
            diseño = classes.diseno3;
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen3;

            break;
          case 4:
            //Blog
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen4;
            diseño = classes.diseno4;

            break;
          case 5:
            //Libros
            diseño = classes.diseno5;
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen5;

            break;
          case 6:
            //Diccionario financiero
            diseño = classes.diseno6;
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen6;

            break;

          default:
            imagen_fondo = ruta !== null && ruta !== undefined ? ruta : imagen1;
            diseño = classes.diseno1;

            break;
        }
      }

      const contenidoDiv = this.props.datos.map((dato, i) => {
        let url_descarga = null;
        escogeImagen(this.props.tipo_imagen, dato.imagen_portada, dato.mostrar_info_portada);

        let style = {
          backgroundImage: `url(${imagen_fondo})`
        };
        if (dato.url_descarga) {
          url_descarga = getUrlDocument(dato.url_descarga, true);
        }
        return (
          <div className={[classes.margen, diseño].join(" ")}>
            <div className={classes.fondo1} style={style}>
              <div className={[classes.titulo, esconde].join(" ")}>
                <span className={["texto_blanco texto_16 nunito"].join(" ")}>{dato.titulo}</span>
              </div>

              <div className={[classes.titulo_slider, esconde, "nunito semi_bold texto_22"].join(" ")}>{titulo_slider}</div>
            </div>
            <div className={classes.centrado}>
              <div className={[classes.contenedor_fecha].join(" ")}>
                <span className={["color_verde_oscuro texto_16 nunito semi_bold"].join(" ")}>{dato.fecha}</span>
              </div>
              <div className={[classes.contenedor_descripcion, " texto_16 montse regular"].join(" ")}>{dato.descripcion}</div>
              <div className={[classes.contenedor_descarga, " texto_16 montse regular row"].join(" ")}>
                <a href={url_descarga}  rel="noopener noreferrer" className="row">
                  <div className="col-8 sin_padding_L">
                    <span className={"texto_16 montse color_verde_oscuro semi_bold"}>
                      {getLan() === "es" ? "Descargar" : "Download"}
                    </span>
                    <br />
                    <span className={"texto_10 montse semi_bold color_gris"}>
                    {getLan() === "es" ? "Formato PDF" : "PDF format"}

                    </span>
                  </div>
                  <div className="col-4">
                    <i className={[classes.icono, "icon_verde fas fa-arrow-down"].join(" ")} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        );
      });

      return contenidoDiv;
    }
  }
}

export default Item;

import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import axios from "axios";
const dominio = process.env.REACT_APP_DOMINIO;
const fullGraphUrl = `${document.location.origin}/informacion_de_mercado/cotizacion_emisoras`;

export default function TickerTapeWidget(props) {
  const container = useRef();
  const defaultSymbols = [
    {"title": "FTBIVA","proName": "FTSE:FTBIVA"},
    {"title": "F4GBIVA","proName": "FTSE:F4GBIVA"}
  ];

  let transformResponse = (elements) => {
    let symbolsTVFormat = [];
    elements.forEach((item) => {
      let instrument = item.instrument.split(" ");
      let clave = instrument[0];
      let serie = instrument[1];
      let symbol = "BIVA:" + clave + (serie && serie !== "*" ? "/" + serie : "");
      let tapeElement =  {"title": item.instrument, "proName": symbol};
      symbolsTVFormat = [...symbolsTVFormat, tapeElement];
    });
    return symbolsTVFormat;
  }

  let drawTickerTape = (symbols) => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `{
      "symbols": ${JSON.stringify(symbols)},
      "showSymbolLogo": true,
      "isTransparent": true,
      "largeChartUrl": ${JSON.stringify(fullGraphUrl)},
      "displayMode": "regular",
      "colorTheme": "light",
      "locale": ${JSON.stringify(props.locale)},
      "customer": "bivacom"
    }`;
    container.current.appendChild(script);
  };

  useEffect(() => {
    let symbolsToAdd = [];
    let gainRequest = axios.get(`${dominio}/stock-exchange/BIVA/instruments/top/10/gain-percentage`)
      .catch(error => console.error(error));
    let lossRequest = axios.get(`${dominio}/stock-exchange/BIVA/instruments/top/10/loss-percentage`)
      .catch(error => console.error(error));
    Promise.all([gainRequest, lossRequest])
      .then(([resGain, resLoss])=> {
        if (resGain) {
          symbolsToAdd = [...symbolsToAdd, ...transformResponse(resGain.data)];
        }
        if (resLoss) {
          symbolsToAdd = [...symbolsToAdd, ...transformResponse(resLoss.data)];
        }
      }).finally(() => {
        drawTickerTape([...defaultSymbols, ...symbolsToAdd])
    });
  }, []);

  return (
    <div ref={container} />
  );

}

TickerTapeWidget.propTypes = {
  locale: PropTypes.string
}

import React, { Component } from "react";
import classes from "./Bloques.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Cuadricula from "../../../components/ui/cuadricula/Cuadricula";
import Accordion from "../../../components/ui/accordion/Accordion";
import Boton from "../../../components/ui/boton/Boton";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
// import bloquesJson from "../../../json/bloques.json"

class Bloques extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
      <Auxs>
        <div className="row">
          <div className="">
            <div >
              <h1 className={["nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="operaciones.modelosdenegociacion.operaciondebloques.titulo1" defaultMessage=" " />
                
              </h1>
            </div>
          </div>
        </div>
        <div className="row margen_top_30 texto_16 montse regular">
          <p className={" texto_16 montse regular"}>
          <FormattedHTMLMessage id="operaciones.modelosdenegociacion.operaciondebloques.introduccion1" defaultMessage=" " />
          </p>
        </div>
       
        <div className="margen_top_50" />
        <div className="row margen_top_50">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
              <FormattedHTMLMessage id="operaciones.modelosdenegociacion.operaciondebloques.subtitulo1" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className="row margen_top_30">
            <Cuadricula
              datos={this.props.box}
              coloreados={[]}
              saltarse={[5]}
              enBlanco={[]}
              quitar={[]}
              modulo={3}
              tipo={6} 
              justificado={true}//justificado
            />
          </div>
        </div>

        <div className={[" margen_top_50", classes.accordion].join(" ")}>
          <Accordion
            // datos={bloquesJson}
            datos={this.props.opelb}
            nombre={"cursos"}
            icon={""}
            imagen_icono={"opel-b.svg"}
            tipo={6}
            tipoBotonDescarga={2}
            // titulo_encabezado={"OPEL - B"}
            titulo_encabezado_estatico={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.operaciondebloques.opelb"] : " "}
            className={classes.accordion}
            titulo_encabezado_2_estatico={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.operaciondebloques.opelb.desc"] : " "}
          />
        </div>

        <div className="row margen_top_50">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
              <FormattedHTMLMessage id="operaciones.modelosdenegociacion.operaciondebloques.subtitulo2" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className="row margen_top_30 margin_bottom_200">
              <Boton
                tipo={2}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
                texto={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.operaciondebloques.boton.cuotas"] : " "}
              ruta={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.operaciondebloques.boton.cuotas.enlace"] : " "}
              />
              <Boton
                tipo={2}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
                texto={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.operaciondebloques.boton.estadisticas"] : " "}
              ruta={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.operaciondebloques.boton.estadisticas.enlace"] : " "}
              />
          </div>
        </div>
      </Auxs>
      </IntlProvider>
      );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    opelb: state.accordion.descOpelB,
    box: state.cuadriculas.caracteristicasOperacion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(29));
      dispatch(action.initBoxCaracteristicasOperacion());
      dispatch(action.getAccordionDescOpelB());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bloques);


import React, { Component } from "react";
import classes from "./Cursos.css";
import Slider from "react-slick";
import Boton from "../../boton/Boton";
import { getLan, getUrlDocument } from "../../../../stores/utils/utilities";
import Pagination from "../../pagination/Pagination";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  speed: 200,
  autoplaySpeed: 0,
  cssEase: "linear",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        className: "center",
        centerMode: true,
        centerPadding: "60px",
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      }
    }
  ]
};

class Cursos extends Component {
  state = {
    cursoSeleccionado: "",
    itemsPerPage: 1,
    activePage: 1,
  };

  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
  }

  seleccionaCurso(curso) {
    this.setState({
      cursoSeleccionado: curso
    });
  }

  slickGoTo(page) {
    if (this.slider) {
      this.slider.slickGoTo(page);
    }
  }

  handlePageChange(pageNumber) {
    this.slickGoTo(pageNumber - 1);
    this.setState({
      activePage: pageNumber,
    });
  }

  handlePageChangeSlider(pageNumber) {
    this.slickGoTo(pageNumber);
    this.setState({
      activePage: pageNumber+1,
    });
  }

  render() {
    if (this.props.datos) {
      const contenido = this.props.datos.map((dato, i) => {
        let ruta = null;
        ruta = getUrlDocument(dato.imagen);
        let style = {
          backgroundImage: `url(${ruta})`
        };
        let titulo = dato.titulo;
        const limitCaracters = 100;
        if (titulo.length > limitCaracters) {
          let res = titulo.substring(0, limitCaracters);
          res = res.substr(-1) === " " ? titulo.substring(0, limitCaracters - 1) : res;
          titulo = res +"...";
        }
        return (
          <div className={["card", classes.card].join(" ")}>
            <div className={[classes.ribbon, dato.nuevo ? null : "d-none"].join(" ")}>
              <span className={"nunito texto_10 bold"}>{getLan() === "es" ? "Nuevo" : "New"}</span>
            </div>
            <div className={["card-body text-center", classes.card_body_container].join(" ")}>
              <p className="card-text bold nunito texto_18">{titulo}</p>
              <p className={[" text-center margen_top_30", classes.sedeCard].join(" ")}>
                <span className={"texto_gris montse texto_16 regular"}>{dato.sede}</span>
              </p>
              <div className={["text-center", classes.imagenCard].join(" ")}>
                 <div  style={style} className={[classes.imagen]} />
              </div>
              <div className={["text-center margin_top_20", classes.center, classes.botonMas].join(" ")}>
                <Boton
                  tipo={4}
                  texto={getLan() === "es" ? "Conoce más" : "Learn more"}
                  icono_derecho="fas fa-long-arrow-alt-right"
                  colorBoton={""}
                  onClick={() => this.seleccionaCurso(dato)}
                  idModal={"#exampleModal" + this.props.indice}
                />
              </div>
            </div>
          </div>
        );
      });

      let imagenSeleccionada = "";
      let tituloSeleccionado = "";
      let detalleSeleecionado = "";
      let sedeSeleeccionada = "";
      let rutaTemario = "";
      let rutaRegistro = "";

      if (this.state.cursoSeleccionado !== null) {
        const cursoSeleccionado = { ...this.state.cursoSeleccionado };
        if (cursoSeleccionado.titulo) {
          tituloSeleccionado = cursoSeleccionado.titulo;
        }
        if (cursoSeleccionado.imagen) {
          imagenSeleccionada = getUrlDocument(cursoSeleccionado.imagen);
        }

        if (cursoSeleccionado.detalle) {
          detalleSeleecionado = cursoSeleccionado.detalle;
        }
        if (cursoSeleccionado.sede) {
          sedeSeleeccionada = cursoSeleccionado.sede;
        }
        if (cursoSeleccionado.temario) {
          rutaTemario = getUrlDocument(cursoSeleccionado.temario);
        }

        if (cursoSeleccionado.registro) {
          rutaRegistro = getUrlDocument(cursoSeleccionado.registro);
        }
      }
      let modal = null;
      if (this.state.cursoSeleccionado !== null) {

        let style = {
          backgroundImage: `url(${imagenSeleccionada})`
        };
        modal = (
          <div
            className="modal fade bd-example-modal-lg"
            id={"exampleModal" + this.props.indice}
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog modal-lg  modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>

                  <div className={["text-center margin_top_10"].join(" ")}>
                    <div className="">
                      <div  style={style} className={[classes.imagen]} />
                    </div>
                    <p className="bold nunito texto_18">{tituloSeleccionado}</p>
                    <p className=" text-center margen_top_30">
                      <span className={"texto_gris montse texto_16 regular"}>{sedeSeleeccionada}</span>
                    </p>
                    <p className=" text-center margen_top_30">
                    <div
                    className={["texto_gris montse texto_16 regular", classes.card_body].join(" ")}
                    dangerouslySetInnerHTML={{ __html: detalleSeleecionado }}
                    />
                      <span className={"texto_gris montse texto_16 regular"}>
                        
                        </span>
                    </p>
                  </div>

                  <div className={classes.contenedor_botones}>
                    <div className={rutaTemario === null || rutaTemario === "" ? "v-none" : null}>
                      <Boton
                        ruta={rutaTemario}
                        tipo={1}
                        texto={getLan() === "es" ? "Ver temario" : "Overview"}
                        icono_derecho="fas fa-long-arrow-alt-right"
                        colorBoton={""}
                      />
                    </div>
                    <div className={rutaRegistro === null || rutaRegistro === "" ? "v-none" : null}>
                      <Boton
                        ruta={rutaRegistro.includes("@") ? null : rutaRegistro}
                        tipo={rutaRegistro.includes("@") ? 5 : 1}
                        correo={rutaRegistro.includes("@") ? rutaRegistro : null}
                        texto={getLan() === "es" ? "Registro" : "Register now"}
                        icono_derecho="fas fa-long-arrow-alt-right"
                        colorBoton={""}
                      />
                    </div>
                    <div>
                      <Boton
                        tipo={4}
                        texto={getLan() === "es" ? "Cerrar" : "Close"}
                        icono_derecho="fas fa-long-arrow-alt-right"
                        colorBoton={""}
                        dataDismiss={"modal"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return (
        <React.Fragment>
          <Slider ref={(c) => (this.slider = c)} {...settings} afterChange={this.handlePageChangeSlider.bind(this)}>{contenido}</Slider>
          <Pagination
            key={this.props.datos.length}
            numRows={this.props.datos ? this.props.datos.length : 0}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
          {modal}
        </React.Fragment>
      );
    }
  }
}

export default Cursos;

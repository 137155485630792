import React, { Component } from "react";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import classes from "./Rss.css";

import * as actions from "../../stores/actions/index";

import Auxs from "../../hoc/auxs/Auxs";
import BakcDrop from "../../components/ui/backdrop/BakcDrop";
import Box from "../../components/ui/box/Box";
import Input from "../../components/ui/input/Input";
import { getLan } from "../../stores/utils/utilities";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from 'prop-types';
import StringUtils from "../../stores/utils/StringUtils";

const optionsAvisosOfertasPublicas = [
  { label: getLan() === "es" ? "Capitales" : "Equity", value: 1 },
  { label: getLan() === "es" ? "Deuda" : "Debt", value: 2 },
];

const optionsCalificadoras = [
  {
    label: getLan() === "es" ? "Eventos Relevantes." : "Relevant Events",
    value: 38,
  },
  {
    label: getLan() === "es" ? "Reportes mensuales" : "Monthly Report.",
    value: 14,
  },
];

const renderInput = ({
  input,
  id = null,
  label,
  classes = null,
  elementType,
  placeholder = null,
  onInputChange,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? classes : null,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    label: label,
    elementType: elementType,
  };
  return (
    <Auxs>
      {label && input.type !== "checkbox" ? (
        <label for={input.name}>{label}</label>
      ) : null}
      <Input config={params} />
    </Auxs>
  );
};

class Rss extends Component {
  state = {
    showRssModal: false,
    modalTitle: null,
    baseUrl: null,
    emisoraId: null,
    tipoInstrumento: null,
    url: null,
    optionType: null,
    copied: false,
    options: [],
    origen: {
      tipoDocumento: [],
      tipoDocumentoListado: [],
    },
    customStyle: undefined,
  };

  componentDidMount() {
    const rssType = this.props.rssType;
    switch (rssType) {
      case 0:
        this.props.onFetchRssDocumentSeguimientos();
        break;
      case 1:
        this.props.onFetchRssDocumentProspectos();
        break;
      case 2:
        this.props.onFetchRssDocumentAvisos();
        break;
      case 3:
        this.props.onFetchRssDocumentDocumentos();
        break;
      case 4:
        this.props.onFetchRssDocumentBancos();
        break;
      case 5:
        this.props.onFetchRssDocumentCalificadoras();
        break;
      case 6:
        this.props.onFetchRssDocumentAvisosGenerales();
        break;
      case 7:
        this.props.onFetchRssDocumentAvisosSuspension();
        break;
      case 8:
        this.props.onFetchRssCuestionarioASGDocumentos();
        break;
      default:
        break;
    }

    this.setState({
      modalTitle: this.props.modalTitle,
      baseUrl: this.props.urlRss,
      url: this.props.urlRss,
      optionType: this.props.optionType ? this.props.optionType : null,
      customStyle: this.props.customStyle,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.urlRss !== this.props.urlRss) {
      this.setState({
        baseUrl: this.props.urlRss,
        url: this.props.urlRss,
      });
    }

    if (prevProps.modalTitle !== this.props.modalTitle) {
      this.setState({
        modalTitle: this.props.modalTitle
      });
    }
  }

  componentWillUpdate(prevProp) {
    if (prevProp.showRssModal !== this.props.showRssModal) {
      this.onToggleRssModalHandler();
    }
  }

  onToggleRssModalHandler = () => {
    this.setState((prevState) => {
      return {
        showRssModal: !prevState.showRssModal,
      };
    });
  };

  onCopyUrlHandler = () => {
    this.setState({
      copied: true,
    });

    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 1500);
  };

  onSetOptionsRssModalHandler = (option) => {
    switch (option) {
      case 1:
      case 3:
        return this.onPrintOptionsOneAndThreeRssModalHandler(option);
      case 2:
        return this.props.optionsInputs ? (
          this.onPrintOptionsTwoRssModalHandler()
        ) : (
            <p>
              {getLan() === "es"
                ? "No existen información."
                : "No results found."}
            </p>
          );
      case 4:
        return this.props.multipleOptionsInputs ? (
          this.onPrintMultipleFiltersRssModalHandler()
        ) : (
            <p>
              {getLan() === "es"
                ? "No existen información."
                : "No results found."}
            </p>
          );
      default:
        return null;
    }
  };

  onSubmitDataHandler = () => { };

  onPrintOptionsOneAndThreeRssModalHandler = (option) => {
    const marketType = getLan() === "es" ? "Tipo de mercado" : "Market type";
    const notificationType =
      getLan() === "es" ? "Tipo de notificación" : "Notification type";
    const subTitle = option === 1 ? marketType : notificationType;

    let options = [];
    if (option === 1) {
      options = optionsAvisosOfertasPublicas.map((option) => {
        return this.onPrintCheckboxInputsHandler(option);
      });
    } else {
      options = optionsCalificadoras.map((option) => {
        return this.onPrintCheckboxInputsHandler(option);
      });
    }

    return (
      <Auxs>
        <div className="row">
          <div className="col">
            <h2 className={"nunito texto_30 regular"}>
              {getLan() === "es"
                ? "Seleccione las opciones para suscribirse."
                : "Select what you want to subscribe."}
            </h2>
          </div>
        </div>
        <div className="row margin_top_10">
          <div className="col">
            {/* <p className={"montse texto_14 regular"}>{subTitle}</p> */}
            <div className={classes.inputsContainer}>
              <div className={classes.inputsContainer2}>
                <Form
                  onSubmit={() => this.onSubmitDataHandler()}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">{options}</div>
                      </div>
                    </form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Auxs>
    );
  };

  onPrintMultipleFiltersRssModalHandler = () => {
    const options = this.props.multipleOptionsInputs;
    let inputs = [];
    options.forEach((opt) => {
      if (opt.datos === undefined || opt.datos === null) {
        return;
      }
      inputs.push({titulo:opt.titulo, html:this.buildRssFilterInputs(opt.datos, opt.queryParam), queryParam: opt.queryParam});
    });
    return (
      <Auxs>
        <div className="row">
          <div className="col-12">
            <h2 className={"nunito texto_30 regular"}>
              {getLan() === "es"
                ? "Seleccione las opciones para suscribirse."
                : "Select what you want to subscribe."}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form
              onSubmit={() => this.onSubmitDataHandler()}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {inputs.map((input, index) => {
                    return (
                      <>
                        <span className="montse texto_16">{input.titulo}</span>
                        <div className={classes.inputsContainer}>
                          {input.html}
                        </div>
                      </>
                    );
                  })}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  }

  buildRssFilterInputs = (options, group) => {
    const noc = Math.round(options.length / 10);
    const ipc = Math.round(options.length / noc);
    
    return [...Array(noc)].map((_, i) => {
      const init = ipc * i;
      const end = ipc + init;
      const opt = options.slice(init, end);

      return (
        <div className={classes.scrollContainer2} key={"container_rss_checkbox_" + group + "_" + i}>
          <div className={["row"].join(" ")}>
            {opt.map((o) => {
              return (
                <div className="col-12 col-md-6 col-lg-6 col-xl-6" key={"rss_checkbox_" + group + "_" + o.value}>{this.onPrintCheckboxInputsMultipleFiltersHandler(o, group)}</div>
              );
            })}
          </div>
        </div>
      );
    });
  }

  onPrintCheckboxInputsMultipleFiltersHandler = (input, group) => {
    return (
      <Field
        name="options"
        id={input.label ? input.label : input.nombre}
        type="checkbox"
        elementType="checkbox_plain"
        value={
          input.value
            ? input.value
            : `${input.id}-${group}-${input.nombre}`
        }
        classes={["form-check-input"].join(" ")}
        label={input.label ? input.label : input.nombre}
        onInputChange={this.onChangeInputsHandler}
        component={renderInput}
      />
    );
  };

  onPrintOptionsTwoRssModalHandler = () => {
    const options = this.props.optionsInputs ? this.onPrepareDataHandler() : [];

    const noc = Math.round(options.length / 10);
    const ipc = Math.round(options.length / noc);

    return (
      <Auxs>
        <div className="row">
          <div className="col-12">
            <h2 className={"nunito texto_30 regular"}>
              {getLan() === "es"
                ? "Seleccione las opciones para suscribirse."
                : "Select what you want to subscribe."}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={classes.inputsContainer}>
              <Form
                onSubmit={() => this.onSubmitDataHandler()}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes.scrollContainer}>
                      {[...Array(noc)].map((_, i) => {
                        const init = ipc * i;
                        const end = ipc + init;
                        const opt = options.slice(init, end);

                        return (
                          <div className={classes.myColWdith}>
                            {opt.map((o) => {
                              return (
                                <p>{this.onPrintCheckboxInputsHandler(o)}</p>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </Auxs>
    );
  };

  onPrepareDataHandler = () => {
    const options = this.props.optionsInputs;
    let updateOptionsInputs = [];

    options.forEach((option) => {
      let nombre = option.nombre;
      let origen = option.origen;
      let id = option.id.toString().replace(/,/g, "_");

      updateOptionsInputs.push({ nombre: nombre, id: id, origen: origen });
    });
    return updateOptionsInputs;
  };

  onManageValuesHandler = (optionsValues, type = null, value) => {
    let options = [...optionsValues];

    if (options.length > 0) {
      const exists = options.find((option) => option === value);
      if (exists) {
        options = options.filter((option) => option !== value);
      } else {
        options.push(value);
      }
    } else {
      options.push(value);
    }
    return options;
  };

  onReplaceCharacterHandler = (value) => {
    return value.toString().replace(/_/g, ",").split(",");
  };

  onChangeInputsHandler = (event) => {
    const value = event.target.value;
    const optionType = this.state.optionType;
    const tipoOrigen = this.state.origen;

    let opt = [];
    let url = this.state.baseUrl;

    let options = [...this.state.options];
    let optionTdl = [...tipoOrigen.tipoDocumentoListado];
    let optionTd = [...tipoOrigen.tipoDocumento];

    if (optionType === 1) {
      options = this.onManageValuesHandler(options, optionType, value);
      if (options.length > 0) {
        url = `${url}?idTipoMercado=${[...options]}`;
      }
    } else if (optionType === 2) {
      const ids = value.split("-")[0];
      const origen = value.split("-")[1];

      if (origen === "listado") {
        // Tipo de origen = listado
        optionTdl = this.onManageValuesHandler(optionTdl, optionType, ids);
        if (optionTdl.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTdl);

          // url = ` ${url}?tipoDocumentoListado=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumentoListado=${[...opt]}`
            : `${url}?tipoDocumentoListado=${[...opt]}`;
        }

        if (optionTd.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTd);

          // url = `${url}${optionTdl.length > 0 ? "&" : "?"}tipoDocumento=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumento=${[...opt]}`
            : `${url}?tipoDocumento=${[...opt]}`;
        }

        tipoOrigen.tipoDocumentoListado = optionTdl;
      } else if (origen === "mantenimiento") {
        // Tipo de origen = Mantenimiento
        optionTd = this.onManageValuesHandler(optionTd, optionType, ids);
        if (optionTd.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTd);

          // url = `${url}?tipoDocumento=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumento=${[...opt]}`
            : `${url}?tipoDocumento=${[...opt]}`;
        }

        if (optionTdl.length > 0) {
          opt = this.onReplaceCharacterHandler(optionTdl);

          // url = `${url}${optionTd.length > 0 ? "&" : "?"}tipoDocumentoListado=${[...opt]}`;
          url = url.includes("?")
            ? `${url}&tipoDocumentoListado=${[...opt]}`
            : `${url}?tipoDocumentoListado=${[...opt]}`;
        }

        tipoOrigen.tipoDocumento = optionTd;
      }
    } else if (optionType === 3) {
      options = this.onManageValuesHandler(options, optionType, value);
      if (options.length > 0) {
        url = `${url}?tipoDocumento=${[...options]}`;
      }
    } else if (optionType === 4) {
      options = this.onManageValuesHandler(options, optionType, value);
      console.log(options, "---", optionType, value);
      if (options.length > 0) {
        let params = {};
        options.forEach((option) => {
          let optionParts = option.split("-");
          let value = optionParts[0];
          let queryParam = optionParts[1];
          if (!Object.hasOwn(params, queryParam)) {
            params[queryParam] = value;
          } else {
            params[queryParam] = params[queryParam] + "," + value;
          }
        });
        url = `${url}` + StringUtils.jsonToQueryString(params);
      }
    }

    this.setState({
      options: options,
      origen: tipoOrigen,
      url: url,
    });
  };

  onPrintCheckboxInputsHandler = (input) => {
    return (
      <span className={classes.labelCheck}>
        <Field
          name="options"
          id={input.label ? input.label : input.nombre}
          type="checkbox"
          elementType="checkbox"
          value={
            input.value
              ? input.value
              : `${input.id}-${input.origen}-${input.nombre}`
          }
          classes={["form-check-input"].join(" ")}
          label={input.label ? input.label : input.nombre}
          onInputChange={this.onChangeInputsHandler}
          component={renderInput}
        />
      </span>
    );
  };

  onPrintHeaderRssModalHandler = () => {
    return (
      <div className={classes.rssModalHeader}>
        <div className="row">
          <div className="col col-lg-2 offset-lg-10">
            <span
              onClick={this.onToggleRssModalHandler}
              className={"montse texto_30 regular"}
            >
              {getLan() === "es" ? "Cerrar" : "Close"}
              <i className="far fa-times-circle" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  onPrintBodyRssModalHandler = () => {
    const options = this.props.optionType
      ? this.onSetOptionsRssModalHandler(this.props.optionType)
      : null;

    return (
      <div
        className={[
          classes.rssModalBody,
          options !== 2
            ? classes.marginModaBodyForModel1
            : classes.marginModaBodyForModel2,
        ].join(" ")}
      >
        <div
          className={[
            this.props.optionType === 4 ? classes.bodyFirstSection2: classes.bodyFirstSection,
            this.props.optionType ? classes.firstSectionHeigth : null,
          ].join(" ")}
        >
          {options}
        </div>
        <div className={classes.bodySecondSection}>
          <h1 className="nunito">{this.state.modalTitle}</h1>
          <div className={classes.urlContainer}>
            <span className={"montse texto_16 regular"}>{this.state.url}</span>
          </div>
          <div className="row">
            <div className="col col-lg-6 text-center">
              <button
                type="button"
                className={["btn", classes.button].join(" ")}
                onClick={this.onToggleRssModalHandler}
              >
                <span className={"montse texto_16 regular"}>
                  {getLan() === "es" ? "Cerrar" : "Close"}
                </span>
              </button>
            </div>
            <div className="col col-lg-6 text-center">
              <CopyToClipboard text={this.state.url}>
                <button
                  disabled={this.state.copied}
                  type="button"
                  className={["btn", classes.button].join(" ")}
                  onClick={this.onCopyUrlHandler}
                >
                  <span className={"montse texto_16 regular"}>
                    {getLan() === "es" ? "Copiar Link" : "Copy link"}
                  </span>
                </button>
              </CopyToClipboard>
              <span className={["nunito", classes.copyText].join(" ")}>
                {this.state.copied ? "Link copiado" : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onPrintFooterRssModalHandler = () => {
    const box = this.props.urlRssDocument ? (
      <Box modelo="modelo_1" data={this.props.urlRssDocument[0]} />
    ) : (
        <p className={"montse texto_16 regular texto_centro margin_top_30"}>{getLan() === "es" ? "Documento no disponible." : "Document not available."}</p>
      );
    return (
      <div className={classes.rssModalFooter}>
        <div className="row">
          <div className="col text-center">
            <h1 className="nunito">
              {getLan() === "es"
                ? "¿Tienes dudas de cómo funciona?"
                : "How to set it up?"}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={classes.documentContainer}>{box}</div>
          </div>
        </div>
      </div>
    );
  };

  onPrintModelRssModalHandler = () => {
    const show = this.props.showClassModel1
      ? classes.showModel1
      : classes.showModel2;
    let instrumentMessage = null;
    if (this.props.instrumentMessage) {
      instrumentMessage = (
        <div className="row">
          <div className="col">
            <div
              className={[
                classes.marginModaBodyForModel1,
                classes.paddingMessage, "justificado"
              ].join(" ")}
            >
              <span className={"montse texto_16 regular"}>
                {getLan() === "es"
                  ? '"En caso de haber seleccionado una opción en el filtro Instrumento, únicamente recibirás notificaciones RSS de los documentos enviados por las emisoras con listados de esos instrumentos"'
                  : '"If you select an option in Security type filter, you will only receive RSS notifications of the documents sent by issuer with those listed instruments"'}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Auxs>
        <BakcDrop
          show={this.state.showRssModal}
          clicked={this.onToggleRssModalHandler}
        />
        <div
          className={[
            "row",
            this.state.customStyle ? null : classes.rssModalContainer,
            this.state.showRssModal ? show : classes.hidden,
          ].join(" ")}
          style={{...this.state.customStyle}}
        >
          <div className="col col-lg-12">
            <div className="row">
              <div className="col">{this.onPrintBodyRssModalHandler()}</div>
            </div>
            {instrumentMessage}
            <div className="row">
              <div className="col"><div
                className={[
                  classes.marginModaBodyForModel1,
                  classes.paddingMessage, "justificado"
                ].join(" ")}
              >
                <span className={"montse texto_16 regular"}>
                {getLan() === "es"
                  ? 'Nota: Al suscribirte recibirás las publicaciones realizadas en los últimos 10 días naturales, incluyendo el día en curso.'
                  : 'Note: RSS Feed subscription offers posts from the last 10 days, including the current day.'}
                </span>
                </div></div>
            </div>
            <hr />
            <div className="row">
              <div className="col">{this.onPrintFooterRssModalHandler()}</div>
            </div>
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const rssModal = this.onPrintModelRssModalHandler();
    return rssModal;
  }
}

Rss.propTypes = {
  modalTitle: PropTypes.string,
  urlRss: PropTypes.string,
  urlRssDocument: PropTypes.array,
  optionType: PropTypes.number,
  customStyle: PropTypes.object,
  rssType: PropTypes.number,
  instrumentMessage: PropTypes.string,
  showClassModel1: PropTypes.bool,
  showRssModal: PropTypes.bool,
  optionsInputs: PropTypes.array,
  multipleOptionsInputs: PropTypes.object,
  onFetchRssDocumentSeguimientos: PropTypes.func,
  onFetchRssDocumentProspectos: PropTypes.func,
  onFetchRssDocumentAvisos: PropTypes.func,
  onFetchRssDocumentDocumentos: PropTypes.func,
  onFetchRssDocumentBancos: PropTypes.func,
  onFetchRssDocumentCalificadoras: PropTypes.func,
  onFetchRssDocumentAvisosGenerales: PropTypes.func,
  onFetchRssDocumentAvisosSuspension: PropTypes.func,
  onFetchRssCuestionarioASGDocumentos: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    optionsInputs: state.rss.rss,
    urlRssDocument: state.rss.rssUrlDocument,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchRssDocumentSeguimientos: () => {
      dispatch(actions.fetchRssSeguimientosUrlDocument());
    },
    onFetchRssDocumentProspectos: () => {
      dispatch(actions.fetchRssProspectosUrlDocument());
    },
    onFetchRssDocumentAvisos: () => {
      dispatch(actions.fetchRssAvisosUrlDocument());
    },
    onFetchRssDocumentDocumentos: () => {
      dispatch(actions.fetchRssEmpresasUrlDocument());
    },
    onFetchRssDocumentBancos: () => {
      dispatch(actions.fetchRssBancosUrlDocument());
    },
    onFetchRssDocumentCalificadoras: () => {
      dispatch(actions.fetchRssCalificadorasUrlDocument());
    },
    onFetchRssDocumentAvisosGenerales: () => {
      dispatch(actions.fetchRssAvisosGeneralesUrlDocument());
    },
    onFetchRssDocumentAvisosSuspension: () => {
      dispatch(actions.fetchRssAvisosSuspensionUrlDocument());
    },
    onFetchRssCuestionarioASGDocumentos: () => {
      dispatch(actions.fetchRssCuestionarioASGUrlDocument());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rss);

import React, { Component } from "react";
import classes from "./Cuotas.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";
import Boton from "../../../components/ui/boton/Boton";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Cuotas extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null,
    accordion: [
      {
        titulo: "Medios de acceso al sistema OPEL",
        texto_html:
          "<table style='height: min-content; width: 100%;'><tbody><tr><th style='width: 212px;'></th><th style='width: 116px;'></th><th style='width: 80px; text-align: left;'><strong>Costo</strong></th></tr><tr><td style='width: 212px;'><ul><li>T-OPEL</li></ul></td><td style='width: 116px; text-align: left;'>por usuario</td><td style='width: 80px; text-align: left;'>$300 usd</td></tr><tr><td style='width: 212px;'><ul><li>Sesi&oacute;n FIX, DMA-FIX </li></ul></td><td style='width: 116px; text-align: left;'>por sesi&oacute;n</td><td style='width: 80px; text-align: left;'>$1850 usd</td></tr><tr><td style='width: 212px;'><ul><li>Sesi&oacute;n OUCH-DMA-OUCH</li></ul></td><td style='width: 116px; text-align: left;'>por sesi&oacute;n</td><td style='width: 80px; text-align: left;'>$1850 usd</td></tr><tr><td style='width: 212px;'><ul><li>Sesi&oacute;n FIX, DMA-FIX &amp; OUCH-DMA-OUCH</li></ul></td><td style='width: 116px; text-align: left;'>3a sesi&oacute;n en adelante</td><td style='width: 80px; text-align: left;'>$700 usd</td></tr></tbody></table>"
      },
      {
        titulo: "Tarifas de operación",
        texto_html:
          "<table style='height: min-content; width: 100%;'><tbody><tr><th style='width: 157px; text-align: left;'><span style='font-weight: 400;'></span></th><th style='width: 202px; text-align: left;'><strong>Monto operado por mes</strong></th><th style='width: 161px; text-align: left;'><strong>Factor</strong></th></tr><tr><td style='width: 157px; text-align: left;'><ul><li>Grupo 1</li></ul></td><td style='width: 202px; text-align: left;'>0 - $1,000,000,000.00</td><td style='width: 161px; text-align: left;'>0.0040%</td></tr><tr><td style='width: 157px; text-align: left;'><ul><li>Grupo 2</li></ul></td><td style='width: 202px; text-align: left;'>$1,000,000,000.01 - $5,000,000,000.00</td><td style='width: 161px; text-align: left;'>0.0032%</td></tr><tr><td style='width: 157px; text-align: left;'><ul><li>Grupo 3</li></ul></td><td style='width: 202px; text-align: left;'>$5,000,000,000.01 -en adelante</td><td style='width: 161px; text-align: left;'>0.0030%</td></tr></tbody></table>"
      }
    ]
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.cuotas.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <p className={" texto_16 montse regular"}>
              <FormattedHTMLMessage id="operaciones.modelosdenegociacion.cuotas.introduccion1" defaultMessage=" " />
            </p>
          </div>

          <div className={[" margen_top_50", classes.accordion].join(" ")}>
            <Accordion
              datos={this.props.infoCuotaOperacion}
              nombre={"cursos"}
              icon={""}
              imagen_icono={"membresia.svg"}
              tipo={6}
              titulo_encabezado_estatico={
                this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.cuotas.etiqueta.membresia"] : ""
              }
              className={classes.accordion}
              texto_derecha_encabezado_titulo={
                this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.cuotas.etiqueta.mensualidad"] : ""
              }
              texto_derecha_encabezado={
                this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.cuotas.monto.mensualidad"] : ""
              }
            />
          </div>

          <div className="row margen_top_50 margin_bottom_200">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.cuotas.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row ">
              <Boton
                ruta={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.cuotas.etiqueta.solicitud.enlace"] : " "}
                tipo={2}
                texto={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.cuotas.etiqueta.solicitud"] : " "}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    infoCuotaOperacion: state.accordion.infoCuotaOperacion
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(30));
      dispatch(action.getAccordionInfoCuotaOperacion());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cuotas);

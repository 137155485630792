import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  emisiones: null,
  tipoInstrumento: null,
  modoListado: null,
  estado: null,
  sector: null,
  listadoClaves: null,
  emisionDetalle: null,
  listadoClavesAgenda: null,
  prospectos: null,
  listadoClavesProspectos: null,
  reportesASG: null,
  listadoClavesReportesASG: null,
  ejercicio: null,
  modoListadoAgenda: null,
  tipoInstrumentoAgenda: null,
  gastos: null,
  listadoClavesGastos: null,
  periodo: null,
  listadoClavesAnexoAA: null,
  anexoaa: null,
  listadoClavesValuaciones: null,
  valuaciones: null,
  costos: null,
  comiteTecnico: null
};

const setEmisiones = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMISIONES_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_TIPO_INSTRUMENTO_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_MODO_LISTADO_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_ESTADO_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_SECTORIA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_CLAVESEMISIONES_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_EMISIONDETALLE_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_COMITE_TECNICO_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_CLAVESAGENDAINSALT_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_PROSPECTOS_IA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_CLAVES_PROSPECTOS_IA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_REPORTES_ASG_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_CLAVES_REPORTES_ASG_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_EJERCICIO_REPORTES_ASG_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_MODO_LISTADO_AGENDA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_TIPO_INSTRUMENTO_AGENDA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_GASTOS_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_CLAVES_GASTOS_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_EJERCICIO_GASTOS_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_PERIODO_GASTOS_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_ANEXO_AA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_CLAVES_ANEXO_AA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_EJERCICIO_ANEXO_AA_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_PERIODO_ANEXO_AA_SUCCESS:
      return setEmisiones(state, action);
      case actionTypes.FETCH_VALUACIONES_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_CLAVES_VALUACIONES_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_EJERCICIO_VALUACIONES_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_PERIODO_VALUACIONES_SUCCESS:
      return setEmisiones(state, action);
    case actionTypes.FETCH_COSTOS_SUCCESS:
      return setEmisiones(state, action);
    default:
      return state;
  }
};

export default reducer;

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

// ======================ULTIMO DIA/ ULTIMO MES===================================================

const getUltimoDiaBiva = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMODIABIVA_SUCCESS,
      ultimoDiaBiva: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMODIABIVA_SUCCESS,
    ultimoDiaBiva: data
  };
};

const getUltimoMesBiva = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESBIVA_SUCCESS,
      ultimoMesBiva: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESBIVA_SUCCESS,
    ultimoMesBiva: data
  };
};

export const initUltimoDiaMesBiva = tipo => {
  if (tipo === 1) {
    //   ultimoDia
    let url = `${dominio}/cms/o/statistics/ultimo-dia/biva`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoDiaBiva(response.data));
        })
        .catch(err => dispatch(getUltimoDiaBiva(err.data)));
    };
  } else if (tipo === 2) {
    //   ultimoMes

    let url = `${dominio}/cms/o/statistics/ultimo-mes/biva`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoMesBiva(response.data));
        })
        .catch(err => dispatch(getUltimoMesBiva(err.data)));
    };
  }
  return null;
};

// ======================CASA BOLSA DIA/ CASA DE BOLSA MES===================================================

const getUltimoDiaCasaBolsa = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMODIACASABOLSA_SUCCESS,
      ultimoDiaCasabolsa: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMODIACASABOLSA_SUCCESS,
    ultimoDiaCasabolsa: data
  };
};

const getUltimoMesCasaBolsa = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESCASABOLSA_SUCCESS,
      ultimoMesCasabolsa: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESCASABOLSA_SUCCESS,
    ultimoMesCasabolsa: data
  };
};

export const initUltimoDiaMesCasaBolsa = tipo => {
  if (tipo === 1) {
    let url = `${dominio}/cms/o/statistics/ultimo-dia/casas-de-bolsa`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoDiaCasaBolsa(response.data));
        })
        .catch(err => dispatch(getUltimoDiaCasaBolsa(err.data)));
    };
  } else if (tipo === 2) {
    //ultimo mes
    let url = `${dominio}/cms/o/statistics/ultimo-mes/casas-de-bolsa`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoMesCasaBolsa(response.data));
        })
        .catch(err => dispatch(getUltimoMesCasaBolsa(err.data)));
    };
  }
  return null;
};

// =========================================================================

const getUltimoDiaEmisorasTotal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMODIAEMISORASTOTAL_SUCCESS,
      ultimoDiaEmisorasTotal: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMODIAEMISORASTOTAL_SUCCESS,
    ultimoDiaEmisorasTotal: data
  };
};

const getUltimoMesEmisorasTotal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESEMISORASTOTAL_SUCCESS,
      ultimoMesEmisorasTotal: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESEMISORASTOTAL_SUCCESS,
    ultimoMesEmisorasTotal: data
  };
};

export const initUltimoDiaMesEmisoraTotal = tipo => {
  if (tipo === 1) {
    let url = `${dominio}/cms/o/statistics/ultimo-dia/emisoras`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoDiaEmisorasTotal(response.data));
        })
        .catch(err => dispatch(getUltimoDiaEmisorasTotal(err.data)));
    };
  } else if (tipo === 2) {
    //ultimo mes
    let url = `${dominio}/cms/o/statistics/ultimo-mes/emisoras`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoMesEmisorasTotal(response.data));
        })
        .catch(err => dispatch(getUltimoMesEmisorasTotal(err.data)));
    };
  }
  return null;
};
// =========================================================================

const getUltimoDiaEmisorasLocal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMODIAEMISORASLOCAL_SUCCESS,
      ultimoDiaEmisorasLocal: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMODIAEMISORASLOCAL_SUCCESS,
    ultimoDiaEmisorasLocal: data
  };
};

const getUltimoMesEmisorasLocal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESEMISORASLOCAL_SUCCESS,
      ultimoMesEmisorasLocal: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESEMISORASLOCAL_SUCCESS,
    ultimoMesEmisorasLocal: data
  };
};

export const initUltimoDiaMesEmisoraLocal = tipo => {
  if (tipo === 1) {
    let url = `${dominio}/cms/o/statistics/ultimo-dia/emisoras/local`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoDiaEmisorasLocal(response.data));
        })
        .catch(err => dispatch(getUltimoDiaEmisorasLocal(err.data)));
    };
  } else if (tipo === 2) {
    //ultimo mes
    let url = `${dominio}/cms/o/statistics/ultimo-mes/emisoras/local`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoMesEmisorasLocal(response.data));
        })
        .catch(err => dispatch(getUltimoMesEmisorasLocal(err.data)));
    };
  }
  return null;
};

// =========================================================================

const getUltimoDiaEmisorasSic = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMODIAEMISORASSIC_SUCCESS,
      ultimoDiaEmisorasSic: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMODIAEMISORASSIC_SUCCESS,
    ultimoDiaEmisorasSic: data
  };
};

const getUltimoMesEmisorasSic = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESEMISORASSIC_SUCCESS,
      ultimoMesEmisorasSic: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESEMISORASSIC_SUCCESS,
    ultimoMesEmisorasSic: data
  };
};

export const initUltimoDiaMesEmisoraSic = tipo => {
  if (tipo === 1) {
    let url = `${dominio}/cms/o/statistics/ultimo-dia/emisoras/sic`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoDiaEmisorasSic(response.data));
        })
        .catch(err => dispatch(getUltimoDiaEmisorasSic(err.data)));
    };
  } else if (tipo === 2) {
    //ultimo mes
    let url = `${dominio}/cms/o/statistics/ultimo-mes/emisoras/sic`;

    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getUltimoMesEmisorasSic(response.data));
        })
        .catch(err => dispatch(getUltimoMesEmisorasSic(err.data)));
    };
  }
  return null;
};

// =========================================================================

const getUltimoMesCasaBolsaLocal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESCASABOLSALOCAL_SUCCESS,
      ultimoMesCasabolsaLocal: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESCASABOLSALOCAL_SUCCESS,
    ultimoMesCasabolsaLocal: data
  };
};

export const initUltimoMesCasaBolsaLocal = () => {
  let url = `${dominio}/cms/o/statistics/ultimo-mes/casas-de-bolsa/local`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getUltimoMesCasaBolsaLocal(response.data));
      })
      .catch(err => dispatch(getUltimoMesCasaBolsaLocal(err.data)));
  };
};

// =========================================================================

const getUltimoMesCasaBolsaSic = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ULTIMOMESCASABOLSASIC_SUCCESS,
      ultimoMesCasabolsaSic: []
    };
  }
  return {
    type: actionTypes.FETCH_ULTIMOMESCASABOLSASIC_SUCCESS,
    ultimoMesCasabolsaSic: data
  };
};

export const initUltimoMesCasaBolsaSic = () => {
  let url = `${dominio}/cms/o/statistics/ultimo-mes/casas-de-bolsa/sic`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getUltimoMesCasaBolsaSic(response.data));
      })
      .catch(err => dispatch(getUltimoMesCasaBolsaSic(err.data)));
  };
};

// =========================================================================
// =============================CRUCES===========================================

const getCrucesTotal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CRUCESTOTALES_SUCCESS,
      crucesTotales: null
    };
  }
  return {
    type: actionTypes.FETCH_CRUCESTOTALES_SUCCESS,
    crucesTotales: data
  };
};

export const initCrucesTotal = () => {
  let url = `${dominio}/cms/o/statistics/cruces`;
  // let url = `https://uat-www.biva.mx/cms/o/statistics/cruces`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getCrucesTotal(response.data));
      })
      .catch(err => dispatch(getCrucesTotal(err.data)));
  };
};

// =========================================================================

const getCrucesIntencionales = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CRUCESINTENCIONALES_SUCCESS,
      crucesIntencionales: null
    };
  }
  return {
    type: actionTypes.FETCH_CRUCESINTENCIONALES_SUCCESS,
    crucesIntencionales: data
  };
};

export const initCrucesIntencionales = () => {
  let url = `${dominio}/cms/o/statistics/cruces/intencional`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getCrucesIntencionales(response.data));
      })
      .catch(err => dispatch(getCrucesIntencionales(err.data)));
  };
};
// =========================================================================

const getCrucesExcepcionales = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CRUCESEXCEPCIONALES_SUCCESS,
      crucesExcepcionales: null
    };
  }
  return {
    type: actionTypes.FETCH_CRUCESEXCEPCIONALES_SUCCESS,
    crucesExcepcionales: data
  };
};

export const initCrucesExcepcionales = () => {
  let url = `${dominio}/cms/o/statistics/cruces/excepcional`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getCrucesExcepcionales(response.data));
      })
      .catch(err => dispatch(getCrucesExcepcionales(err.data)));
  };
};

// ===========================HISTORICO BIVA==============================================

const getHistoricoBiva = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_HISTORICOBIVA_SUCCESS,
      historicoBiva: []
    };
  }
  return {
    type: actionTypes.FETCH_HISTORICOBIVA_SUCCESS,
    historicoBiva: data
  };
};

export const initHistoricoBiva = () => {
  let url = `${dominio}/cms/o/statistics/historico/biva`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getHistoricoBiva(response.data));
      })
      .catch(err => dispatch(getHistoricoBiva(err.data)));
  };
};

// ===========================HISTORICO CASA DE BOLSA==============================================

const getHistoricoCasaBolsaTotal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_HISTORICOCASABOLSATOTAL_SUCCESS,
      historicoCasaBolsaTotal: []
    };
  }
  return {
    type: actionTypes.FETCH_HISTORICOCASABOLSATOTAL_SUCCESS,
    historicoCasaBolsaTotal: data
  };
};

export const initHistoricoCasaBolsaTotal = () => {
  let url = `${dominio}/cms/o/statistics/historico/casas-de-bolsa`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getHistoricoCasaBolsaTotal(response.data));
      })
      .catch(err => dispatch(getHistoricoCasaBolsaTotal(err.data)));
  };
};

// ===============================================================

const getHistoricoCasaBolsaLocal = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_HISTORICOCASABOLSALOCAL_SUCCESS,
      historicoCasaBolsaLocal: []
    };
  }
  return {
    type: actionTypes.FETCH_HISTORICOCASABOLSALOCAL_SUCCESS,
    historicoCasaBolsaLocal: data
  };
};

export const initHistoricoCasaBolsaLocal = () => {
  let url = `${dominio}/cms/o/statistics/historico/casas-de-bolsa/local`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getHistoricoCasaBolsaLocal(response.data));
      })
      .catch(err => dispatch(getHistoricoCasaBolsaLocal(err.data)));
  };
};

// ===============================================================

const getHistoricoCasaBolsaSic = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_HISTORICOCASABOLSASIC_SUCCESS,
      historicoCasaBolsaSic: []
    };
  }
  return {
    type: actionTypes.FETCH_HISTORICOCASABOLSASIC_SUCCESS,
    historicoCasaBolsaSic: data
  };
};

export const initHistoricoCasaBolsaSic = () => {
  let url = `${dominio}/cms/o/statistics/historico/casas-de-bolsa/sic`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getHistoricoCasaBolsaSic(response.data));
      })
      .catch(err => dispatch(getHistoricoCasaBolsaSic(err.data)));
  };
};

// ===============================================================

//SUSPENSIONES

const getSuspensiones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SUSPENSIONES_SUCCESS,
      suspensiones: null
    };
  }
  return {
    type: actionTypes.FETCH_SUSPENSIONES_SUCCESS,
    suspensiones: data
  };
};

export const initSuspensiones = ({ size, page, periodo, capitalSocial }) => {
  const base_url = `${dominio}/quotes/suspensions`;

  let url = `${base_url}?size=${size}&page=${page}`;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = capitalSocial ? `${url}&capitalSocial=${[...capitalSocial]}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getSuspensiones(response.data));
      })
      .catch(err => dispatch(getSuspensiones(err.data)));
  };
};

// ===============================================================

//COTIZACION DE EMISORAS

const getCotizaciones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
      cotizaciones: null
    };
  }
  return {
    type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
    cotizaciones: data
  };
};

export const initCotizacionEmisoras = (size, page, market, instrument) => {
  const base_url = `${dominio}/quotes/quote`;

  let url = `${base_url}?size=${size}&page=${page}`;
  url = market !== null && market !== "" && market > 0 ? `${url}&market=${market}` : url;
  url = instrument !== null && instrument !== "" && instrument !== 0 ? `${url}&instrument=${instrument}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getCotizaciones(response.data));
      })
      .catch(err => dispatch(getCotizaciones(err.data)));
  };
};

  const getCotizacionesById = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
        cotizaciones: null
      };
    }
    return {
      type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
      cotizaciones: data
    };
  };
  
  export const initCotizacionEmisorasById = (size, page, id) => {
    const url = `${dominio}/emisoras/empresas/${id}/emisiones?todos=true&cotizacion=true`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getCotizacionesById(response.data));
        })
        .catch(err => dispatch(getCotizacionesById(err.data)));
    };
  };

  const getCotizacionesClaves = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
        claves: null
      };
    }
    return {
      type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
      claves: data
    };
  };
  
  export const initCotizacionEmisorasClaves = (size, page, startsWith) => {
    const base_url = `${dominio}/emisoras/empresas/busqueda/${startsWith}`;
    let url = `${base_url}?size=${size}&page=${page}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getCotizacionesClaves(response.data));
        })
        .catch(err => dispatch(getCotizacionesClaves(err.data)));
    };
  };

// ===============================================================
//Perfil emisora

const getEmisoraId = (data, isin) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
      emisoraId: null
    };
  }
  return {
    type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
    emisoraId: data
  };
};

export const initEmisoraId = isin => {
  const url = `${dominio}/emisoras/empresas/isin/${isin}`;
  return dispatch => {
    axios
    .get(url)
    .then(response => {
        dispatch(getEmisoraId(response.data, isin));
      })
      .catch(err => dispatch(getEmisoraId(err.data, isin)));
  };
};

// ===============================================================

//INSTRUMENTOS COTIZACION EMISORAS

const getInstrumentos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
      instrumentos: null
    };
  }
  return {
    type: actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS,
    instrumentos: data
  };
};

export const initInstrumentosCotizacionEmisoras = market => {
  let mercado = null;
  if (parseInt(market) === 0) {
    mercado = null;
  } else if (market === 3) {
    mercado = [3, 4];
  } else {
    mercado = market;
  }

  let base_url = `${dominio}/emisoras/tipo-valor/cotizables`;

  base_url = mercado ? `${base_url}?mercado=${[...mercado]}` : base_url;

  return dispatch => {
    axios
      .get(base_url)
      .then(response => {
        dispatch(getInstrumentos(response.data));
      })
      .catch(err => dispatch(getInstrumentos(err.data)));
  };
};


// ===============================================================

const getOperacionesMercado = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_OPERACIONESMERCADO_SUCCESS,
        operaciones: []
      };
    }
    return {
      type: actionTypes.FETCH_OPERACIONESMERCADO_SUCCESS,
      operaciones: data
    };
  };
  
  export const initOperacionesMercado = () => {
    let url = `${dominio}/cms/o/statistics/bloques`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getOperacionesMercado(response.data));
        })
        .catch(err => dispatch(getOperacionesMercado(err.data)));
    };
  };
import React from "react";
import classes from "./Capitulo1.css";
import img1 from "../../../assets/images/SSDG/S-WEB-Goal-1.png";
import img2 from "../../../assets/images/SSDG/S-WEB-Goal-2.png";
import img3 from "../../../assets/images/SSDG/S-WEB-Goal-3.png";
import img4 from "../../../assets/images/SSDG/S-WEB-Goal-4.png";
import img5 from "../../../assets/images/SSDG/S-WEB-Goal-5.png";
import img6 from "../../../assets/images/SSDG/S-WEB-Goal-6.png";
import img7 from "../../../assets/images/SSDG/S-WEB-Goal-7.png";
import img8 from "../../../assets/images/SSDG/S-WEB-Goal-8.png";
import img9 from "../../../assets/images/SSDG/S-WEB-Goal-9.png";
import img10 from "../../../assets/images/SSDG/S-WEB-Goal-10.png";
import img11 from "../../../assets/images/SSDG/S-WEB-Goal-11.png";
import img12 from "../../../assets/images/SSDG/S-WEB-Goal-12.png";
import img13 from "../../../assets/images/SSDG/S-WEB-Goal-13.png";
import img14 from "../../../assets/images/SSDG/S-WEB-Goal-14.png";
import img15 from "../../../assets/images/SSDG/S-WEB-Goal-15.png";
import img16 from "../../../assets/images/SSDG/S-WEB-Goal-16.png";
import img17 from "../../../assets/images/SSDG/S-WEB-Goal-17.png";
import img18 from "../../../assets/images/SSDG/S-WEB-Goal-18.png";
import Recursos from "./animaciones/Recursos";
import Capitulo from "../guia/capitulos/Capitulo";

const imgs = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
];
const getImagenes = imgs.map((itm) => {
  let style = {
    backgroundImage: `url(${itm})`,
  };

  return (
    <div
      style={style}
      className={[classes.miniatura, classes.divImagen].join(" ")}
    />
  );
});
const Capitulo1 = () => {
  return (
    <>
      <div className="row">
        <div className="col sin_padding_L">
          <div className={"margen_top_10"}>
            <h1 className={["nunito", "texto_42"].join(" ")}>
              {/* <FormattedHTMLMessage id="invertir.comoinvertir.titulo1" defaultMessage=" " /> */}
              Capitulo 1
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sin_padding_L">
          <div
            className={[
              "nunito",
              "texto_30",
              "margen_top_50",
              "main_subtitle",
            ].join(" ")}
          >
            <span className={"slash"}>/</span>
            <h2>Contexto General</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col sin_padding_L">
          <div
            className={[
              classes.text_container,
              "text_30",
              "montse",
              "margen_top_10",
            ].join(" ")}
          >
            <p className={"montse texto_16 regular"}>
              Hoy en día los mercados financieros han tenido que evolucionar
              frente al compromiso y la valoración de los factores ESG durante
              las últimas décadas.
            </p>
          </div>
        </div>
      </div>

      <div className="row margin_top_30">
        <div className="col-12 sin_padding_L">
          <div
            className={[classes.text_container, "montse", "margen_top_10"].join(
              " "
            )}
          >
            <p className={"montse texto_18 regular bold"}>
              Tendencias en los mercados de capital global con integración ESG:
            </p>
          </div>
        </div>
        <div className="col-12 sin_padding_L">
          <div
            className={[
              classes.textos_html,
              "montse texto_16",
              "margen_top_10",
            ].join(" ")}
          >
            <ol>
              <li>
                Expansión de la responsabilidad fiduciaria para mayores
                beneficios.
              </li>
              <li>
                Estrategias con visión de largo plazo en los reportes de
                analistas.{" "}
              </li>
              <li>Formación de capital sostenible.</li>
              <li>Gobierno Corporativo actualizado y consolidado.</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="row margin_top_30">
        <div className="col-12 sin_padding_L">
          <div
            className={[classes.text_container, "montse", "margen_top_10"].join(
              " "
            )}
          >
            <p className={"montse texto_18 regular bold"}>
              Tres objetivos principales de la IOSCO:
            </p>
          </div>
        </div>
        <div className="col-12 sin_padding_L">
          <div
            className={[
              classes.textos_html,
              "montse texto_16",
              "margen_top_10",
            ].join(" ")}
          >
            Asegurar que el mercado es justo, eficiente y transparente, proteger
            a las y los inversionistas, y reducir el riesgo sistémico.
          </div>
        </div>
      </div>

      <div className="row margin_top_30">
        <div className="col-12 sin_padding_L">
          <div
            className={[classes.text_container, "montse", "margen_top_10"].join(
              " "
            )}
          >
            <p className={"montse texto_18 regular bold"}>
              Instituciones y regulaciones más influyentes:
            </p>
          </div>
        </div>
        <div className="col-12 sin_padding_L">
          <div
            className={[
              classes.textos_html,
              "montse texto_16",
              "margen_top_10",
            ].join(" ")}
          >
            <ul>
              <li>
                La Autoridad Europea de Valores y Mercados (ESMA por sus siglas
                en inglés).
              </li>
              <li>
                La Comisión de Bolsa y Valores de Estados Unidos (SEC por sus
                siglas en inglés){" "}
              </li>
              <li>
                La Regulación sobre Finanzas sostenibles de la Unión Europea.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row margin_top_30">
        <div className="col sin_padding_L">
          <div
            className={[
              "nunito",
              "texto_30",
              "margen_top_50",
              "main_subtitle",
            ].join(" ")}
          >
            <span className={"slash"}>/</span>
            <h2>Objetivo de Desarrollo Sostenible (ODS)</h2>
          </div>
        </div>

        <div className="col-12 sin_padding_L">
          <div className={[classes.textos_html, "montse texto_16"].join(" ")}>
            El Informe de Desarrollo Sostenible que se publica anualmente por la
            Sustainable Development Solutions Network (SDSN), condensa
            indicadores numéricos para cada uno de los 17 Objetivos de
            Desarrollo Sostenible (ODS) y genera un modelo analítico para
            calificar el estado de la Agenda 2030 en cada país.
          </div>
        </div>
      </div>
      <div className="row margen_top_20">{getImagenes}</div>

      <div className="row margin_top_30">
        <div className="col sin_padding_L">
          <div
            className={[
              "nunito",
              "texto_30",
              "margen_top_50",
              "main_subtitle",
            ].join(" ")}
          >
            <span className={"slash"}>/</span>
            <h2>Objetivos de la guía</h2>
          </div>
        </div>

        <div className="col-12 sin_padding_L margin_top_10">
          <div
            className={[classes.text_container, "montse", "margen_top_10"].join(
              " "
            )}
          >
            <p className={"montse texto_16 regular bold"}>
              1. Cambiar paradigmas
            </p>
          </div>
        </div>
        <div className="col-12 sin_padding_L">
          <div
            className={[
              classes.textos_html,
              "montse texto_16",
              "margen_top_10",
            ].join(" ")}
          >
            La gestión centrada en aspectos ESG genera eficiencias y ventajas
            competitivas para las empresas; les permite anticiparse a
            regulaciones y reducir sus riesgos. 
          </div>
        </div>

        <div className="col-12 sin_padding_L margin_top_10">
          <div
            className={[classes.text_container, "montse", "margen_top_10"].join(
              " "
            )}
          >
            <p className={"montse texto_16 regular bold"}>
              2. Desarrollar capacidades para la gestión ESG
            </p>
          </div>
        </div>
        <div className="col-12 sin_padding_L">
          <div
            className={[
              classes.textos_html,
              "montse texto_16",
              "margen_top_10",
            ].join(" ")}
          >
            Los temas ESG son multidisciplinarios, sistémicos y de alto enfoque
            técnico.
          </div>
        </div>

        <div className="col-12 sin_padding_L margin_top_10">
          <div
            className={[classes.text_container, "montse", "margen_top_10"].join(
              " "
            )}
          >
            <p className={"montse texto_16 regular bold"}>
              3. Ofrecer un documento
            </p>
          </div>
        </div>
        <div className="col-12 sin_padding_L">
          <div
            className={[
              classes.textos_html,
              "montse texto_16",
              "margen_top_10",
            ].join(" ")}
          >
            Guiar a las empresas a la aplicación de la visión de sostenibilidad
            aterrizada a la gestión estratégica y operativa de una organización
            ya sea pequeña, mediana o grande
          </div>
        </div>
      </div>

      <div className="row margin_top_30">
        <Recursos></Recursos>
      </div>
      <div className="row margin_top_30">
        <Capitulo />
      </div>
    </>
  );
};

export default Capitulo1;

import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

  // =========================================================================


  // Toggle box para embajador biva
const setEmbajadorBiva = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        embajadorBiva: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      embajadorBiva: data
    };
  };
  
  export const getEmbajadorBivaToggleBox = () => {
    const url = `${dominio}/cms/o/documents/toggle-embajador-univ/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setEmbajadorBiva(response.data));
        })
        .catch(err => dispatch(setEmbajadorBiva(err.data)));
    };
  };
  
  // =========================================================================


  const setComoInvertir = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        comoInvertir: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      comoInvertir: data
    };
  };
  
  export const getComoInvertirToggleBox = () => {
    const url = `${dominio}/cms/o/documents/como-invertir/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setComoInvertir(response.data));
        })
        .catch(err => dispatch(setComoInvertir(err.data)));
    };
  };

  // =========================================================================
  // =========================================================================


  const setInstrumentoCapital = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        instrumentosCapital: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      instrumentosCapital: data
    };
  };
  
  export const getInstrumentosCapitalToggleBox = () => {
    const url = `${dominio}/cms/o/documents/instrumentos-capitales/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setInstrumentoCapital(response.data));
        })
        .catch(err => dispatch(setInstrumentoCapital(err.data)));
    };
  };

  // =========================================================================


  const setInstrumentoDeuda = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        instrumentosDeuda: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      instrumentosDeuda: data
    };
  };
  
  export const getInstrumentosDeudaToggleBox = () => {
    const url = `${dominio}/cms/o/documents/instrumentos-deuda/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setInstrumentoDeuda(response.data));
        })
        .catch(err => dispatch(setInstrumentoDeuda(err.data)));
    };
  };

    // =========================================================================


  const setNegociacion = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        negociacionBiva: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      negociacionBiva: data
    };
  };
  
  export const getNegociacionBivaToggleBox = () => {
    const url = `${dominio}/cms/o/documents/negociacion-biva/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setNegociacion(response.data));
        })
        .catch(err => dispatch(setNegociacion(err.data)));
    };
  };
    // =========================================================================


  const setConectividadBivaTotal = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        conectividadBivaTotal: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      conectividadBivaTotal: data
    };
  };
  
  export const getToggleBoxConectividadBivaTotal = () => {
    const url = `${dominio}/cms/o/documents/proveedores-biva-total/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setConectividadBivaTotal(response.data));
        })
        .catch(err => dispatch(setConectividadBivaTotal(err.data)));
    };
  };
    // =========================================================================


  const setConectividadBivaBasico = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        conectividadBivaBasico: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      conectividadBivaBasico: data
    };
  };
  
  export const getToggleBoxConectividadBivaBasico = () => {
    const url = `${dominio}/cms/o/documents/proveedores-biva-basico/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setConectividadBivaBasico(response.data));
        })
        .catch(err => dispatch(setConectividadBivaBasico(err.data)));
    };
  };
    // =========================================================================


  const setConectividadUltimoHecho = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        conectividadBivaUltimoHecho: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      conectividadBivaUltimoHecho: data
    };
  };
  
  export const getToggleBoxConectividadUltHecho = () => {
    const url = `${dominio}/cms/o/documents/proveedores-biva-ult-hecho/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setConectividadUltimoHecho(response.data));
        })
        .catch(err => dispatch(setConectividadUltimoHecho(err.data)));
    };
  };
    // =========================================================================


  const setConectividadNoticias = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        conectividadBivaNoticias: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      conectividadBivaNoticias: data
    };
  };
  
  export const getToggleBoxConectividadNoticias = () => {
    const url = `${dominio}/cms/o/documents/proveedores-biva-noticias/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setConectividadNoticias(response.data));
        })
        .catch(err => dispatch(setConectividadNoticias(err.data)));
    };
  };

      // =========================================================================


      const setConectividadProveedores = data => {
        if (data === undefined || data === null) {
          return {
            type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
            conectividadProveedores: []
          };
        }
        return {
          type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
          conectividadProveedores: data
        };
      };
      
      export const getToggleBoxConectividadProveedores = () => {
        const url = `${dominio}/cms/o/documents/toggle-conectividad-proveedores/lang/${lang}`;
      
        return dispatch => {
          axios
            .get(url)
            .then(response => {
              dispatch(setConectividadProveedores(response.data));
            })
            .catch(err => dispatch(setConectividadProveedores(err.data)));
        };
      };
    // =========================================================================


  const setPorQueListarse = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxPorQueListarse: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxPorQueListarse: data
    };
  };
  
  export const getToggleBoxPorQueListarse = () => {
    const url = `${dominio}/cms/o/documents/toggle-por-que-listarse/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setPorQueListarse(response.data));
        })
        .catch(err => dispatch(setPorQueListarse(err.data)));
    };
  };
    // =========================================================================


  const setBeneficiosListarse = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxBeneficiosListarse: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxBeneficiosListarse: data
    };
  };
  
  export const getToggleBoxBeneficiosListarse = () => {
    const url = `${dominio}/cms/o/documents/beneficios-listarse/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setBeneficiosListarse(response.data));
        })
        .catch(err => dispatch(setBeneficiosListarse(err.data)));
    };
  };
    // =========================================================================


  const setInstrumentosListadoDeuda = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxInstrumentosListadoDeuda: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxInstrumentosListadoDeuda: data
    };
  };
  
  export const getToggleBoxInstrumentosListadoDeuda = () => {
    const url = `${dominio}/cms/o/documents/instrumentos-listado-deuda/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setInstrumentosListadoDeuda(response.data));
        })
        .catch(err => dispatch(setInstrumentosListadoDeuda(err.data)));
    };
  };
    // =========================================================================


  const setInstrumentosListadoCapitales = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxInstrumentosListadoCapitales: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxInstrumentosListadoCapitales: data
    };
  };
  
  export const getToggleBoxInstrumentosListadoCapitales = () => {
    const url = `${dominio}/cms/o/documents/instrumentos-listado-capitales/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setInstrumentosListadoCapitales(response.data));
        })
        .catch(err => dispatch(setInstrumentosListadoCapitales(err.data)));
    };
  };

    // =========================================================================


  const setListadoSab = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxListadoSab: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxListadoSab: data
    };
  };
  
  export const getToggleBoxListadoSab = () => {
    const url = `${dominio}/cms/o/documents/toggle-listado-sab/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setListadoSab(response.data));
        })
        .catch(err => dispatch(setListadoSab(err.data)));
    };
  };

    // =========================================================================


  const setListadoSapib = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxListadoSapib: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxListadoSapib: data
    };
  };
  
  export const getToggleBoxListadoSapib = () => {
    const url = `${dominio}/cms/o/documents/toggle-listado-sapib/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setListadoSapib(response.data));
        })
        .catch(err => dispatch(setListadoSapib(err.data)));
    };
  };

    // =========================================================================


  const setListadoDeuda = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxListadoDeuda: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxListadoDeuda: data
    };
  };
  
  export const getToggleBoxListadoDeuda = () => {
    const url = `${dominio}/cms/o/documents/toggle-listado-deuda/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setListadoDeuda(response.data));
        })
        .catch(err => dispatch(setListadoDeuda(err.data)));
    };
  };

    // =========================================================================


  const setListadoFibra = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxListadoFibra: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxListadoFibra: data
    };
  };
  
  export const getToggleBoxListadoFibra = () => {
    const url = `${dominio}/cms/o/documents/toggle-listado-fibra/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setListadoFibra(response.data));
        })
        .catch(err => dispatch(setListadoFibra(err.data)));
    };
  };

    // =========================================================================


  const setFasesIpo = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxFasesIpo: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxFasesIpo: data
    };
  };
  
  export const getToggleBoxFasesIpo = () => {
    const url = `${dominio}/cms/o/documents/json-fases-ipo/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setFasesIpo(response.data));
        })
        .catch(err => dispatch(setFasesIpo(err.data)));
    };
  };

    // =========================================================================


  const setProcesoCambioListado = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
        toggleBoxProcesoCambioListado: []
      };
    }
    return {
      type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
      toggleBoxProcesoCambioListado: data
    };
  };
  
  export const getToggleBoxProcesoCambioListado = () => {
    const url = `${dominio}/cms/o/documents/json-proceso-cambio-listado/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(setProcesoCambioListado(response.data));
        })
        .catch(err => dispatch(setProcesoCambioListado(err.data)));
    };
  };

      // =========================================================================


      const setListadoInstrumentos = data => {
        if (data === undefined || data === null) {
          return {
            type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
            toggleBoxListadoInstrumentos: []
          };
        }
        return {
          type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
          toggleBoxListadoInstrumentos: data
        };
      };
      
      export const getToggleBoxListadoInstrumentos= () => {
        const url = `${dominio}/cms/o/documents/toggle-listar-sic/lang/${lang}`;
      
        return dispatch => {
          axios
            .get(url)
            .then(response => {
              dispatch(setListadoInstrumentos(response.data));
            })
            .catch(err => dispatch(setListadoInstrumentos(err.data)));
        };
      };

      // =========================================================================


      const setInformacionEmisora = data => {
        if (data === undefined || data === null) {
          return {
            type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
            toggleBoxInformacionEmisora: []
          };
        }
        return {
          type: actionTypes.FETCH_TOGGLEBOX_SUCCESS,
          toggleBoxInformacionEmisora: data
        };
      };
      
      export const getToggleBoxInformacionEmisora= () => {
        const url = `${dominio}/cms/o/documents/toggle-informacion-emisora/lang/${lang}`;
        return dispatch => {
          axios
            .get(url)
            .then(response => {
              dispatch(setInformacionEmisora(response.data));
            })
            .catch(err => dispatch(setInformacionEmisora(err.data)));
        };
      };
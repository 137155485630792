import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

const setTrkd = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TRKD_SUCCESS,
      trkd: null
    };
  }
  return {
    type: actionTypes.FETCH_TRKD_SUCCESS,
    trkd:data
  };
};

export const initTrkd = () => {
  return dispatch => {
    axios
      .get(dominio+"/quotes/ticker")
      .then(response => {
        dispatch(setTrkd(response.data));
      })
      .catch(err => dispatch(setTrkd(err.data)));
  };
}; 

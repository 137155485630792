import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../stores/actions/index";

import LayoutGuia from "../layoutGuiaToggle/LayoutGuia";

export default function ContextoGeneral(props) {
  const dispatch = useDispatch();

  const [contexto, setContexto] = useState([]);

  const contextoRedux = useSelector(
    (state) => state.sustentable.contextoGeneral
  );

  useEffect(() => {
    dispatch(action.getContextoGeneral());
  }, []);

  useEffect(() => {
    if (contextoRedux) {
      setContexto(contextoRedux);
    }
  }, [contextoRedux]);

  return (
    <>
      <LayoutGuia
        hiddenToggleTitle={false}
        titulo={"Contexto General"}
        mock={contexto}
        colores={["#009ee2", "#029b78", "#93c023", "#ccd428"]}
        coloresInternos={[
          "#1ca4c9",
          "#029791",
          "#029791",
          "#017830",
          "#4fad26",
        ]}
      />
    </>
  );
}

import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Calificadoras.css";
import Accordion from "../../../components/ui/accordion/Accordion";
import Tabs from "../../../components/ui/tabs/Tabs";
import * as actions from "../../../stores/actions/index";
import queryString from "query-string";

import Pagination from "../../../components/ui/pagination/Pagination";
import ReporteMensual from "./ReporteMensual";
import Loader from "../../../components/loader/Loader";

import EventosRelevantes from "./EventosRelevantes";

import Rss from "../../rss/Rss";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
const dominio = `${window.location.protocol}//${window.location.host}`;

const tabsInfo = [
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Perfil" : "Profile"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Eventos Relevantes" : "Relevant events"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Reporte Mensual" : "Monthly report"
  }
];

class Calificadoras extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    calificadoraSeleccionada: null,
    indexSelected: 0,
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tab: 0,
    urlForRss: `${dominio}/emisoras/empresas/calificadoras/rss`,
    showRssModal: false,
    nombreCalificadora: null
  };

  componentWillMount() {
    this.props.onInitIntl();
    this.onChangeItemSelected();
    let paramsString = queryString.parse(this.props.location.search);
    let calificadora =
      paramsString["calificadora"] !== undefined && paramsString["calificadora"] !== "null"
        ? paramsString["calificadora"]
        : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    let tab = paramsString["tab"] !== undefined && paramsString["tab"] !== "null" ? parseInt(paramsString["tab"]) - 1 : 0;

    if (calificadora && tab === 0) {
      this.setState({
        calificadoraSeleccionada: calificadora,
        tab: tab,
        indexSelected: tab
      });
      this.props.onInitCalificadoraDetail(calificadora);
    } else {
      this.setState({
        activePage: page,
        calificadoraSeleccionada: calificadora,
        tab: tab,
        indexSelected: tab
      });
      this.props.oninitCalificadoras(this.state.itemsPerPage, page >= 1 ? page - 1 : 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.calificadoras !== prevProps.calificadoras) {
      this.setState({
        loader: false
      });
    }
    if (this.props.calificadoraDetail !== prevProps.calificadoraDetail) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let calificadoras = null;

    if (nextProps.calificadoras && nextProps.calificadoras.content) {
      calificadoras = nextProps.calificadoras.content;
    }
    let resource = calificadoras;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.calificadoras;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.changeHistory(null, pageNumber);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onChangeItemSelected = (index = 0) => {
    if (this.state.calificadoraSeleccionada !== null && index !== this.state.indexSelected) {
      this.changeHistory(this.state.calificadoraSeleccionada, this.state.activePage, index);
    }
    this.setState({ indexSelected: index, tab: index});
  };

  updateGrandparent = value => {
    this.changeHistory(value, 1);
    let nombreCalificadora= null;
    if (value !== null) {
      nombreCalificadora = this.state.resourceList.find(itm => itm.id === value)
        ? this.state.resourceList.find(itm => itm.id === value).nombre
        : null;
    }
    this.setState({
      calificadoraSeleccionada: value,
      nombreCalificadora : nombreCalificadora
    });
  };

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  changeHistory(calificadora = null, page, tab = 0) {
    tab = tab + 1;
    this.props.history.push(
      `?calificadora=${calificadora}&tab=${tab ? tab : this.state.indexSelected + 1}&page=${page ? page : null}`
    );

    this.setState({
      loader: true
    });
    if (calificadora && tab === 1) {
      this.props.onInitCalificadoraDetail(calificadora);
    } else if(calificadora === null && tab === 1){
      this.props.oninitCalificadoras(this.state.itemsPerPage, page >= 1 ? page - 1 : 0);
    }
  }

  render() {
    let contenidoMostrar = null;
    let tabMostrar = null;
    let rss = null;

    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let calificadoras_result = null;
    let calificadoras = null;
    if (this.props.calificadoras && this.props.calificadoras.content) {
      calificadoras_result = this.props.calificadoras.content;
      const numRows = this.props.calificadoras ? this.props.calificadoras.totalElements : 0;

      calificadoras = (
        <React.Fragment>
          <Accordion
            datos={calificadoras_result}
            nombre="calificadoras"
            icon={""}
            tipo={7}
            subtipo={1}
            updateGrandparent={this.updateGrandparent.bind(this)}
          />
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );
    } else {
      calificadoras = (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
    }
    //Razón Social
    const calificadoraInfo0 = (
      <Accordion datos={this.props.calificadoraDetail} nombre="emisoras" icon={""} tipo={8} subtipo={6}  titulo={getLan() === "es" ? "Razón Social" : "Corporate name"} titulo_info={this.props.calificadoraDetail ? this.props.calificadoraDetail.nombre : null} />
    );
    //Nombre Contacto,Telefono
    const calificadoraInfo = (
      <Accordion datos={this.props.calificadoraDetail} nombre="calificadoras" icon={""} tipo={8} subtipo={3} />
    );
    // Direccion
    const calificadoraInfo2 = (
      <Accordion datos={this.props.calificadoraDetail} nombre="emisoras" icon={""} tipo={8} subtipo={6}  titulo={getLan() === "es" ? "Dirección" : "Address"} titulo_info={this.props.calificadoraDetail ? this.props.calificadoraDetail.direccion : null} />
    );
    // Sitio Web
    const calificadoraInfo3 = (
      <Accordion datos={this.props.calificadoraDetail} nombre="emisoras" icon={""} tipo={8} subtipo={7} titulo={getLan() === "es" ? "Sitio web" : "Website"} titulo_info={this.props.calificadoraDetail ? this.props.calificadoraDetail.sitioWeb:null} />
    );

    tabMostrar = (
      <div>
        { this.props.calificadoraDetail ? this.props.calificadoraDetail.logo ? (
              <img className={classes.titulo_25pct}  src={this.props.calificadoraDetail ? this.props.calificadoraDetail.logo : null} alt={this.state.nombreCalificadora}/>)
              :(<span className={["texto_16_17 nunito bold"].join(" ")}>{this.state.nombreCalificadora}</span>)
           :(<span className={["texto_16_17 nunito bold"].join(" ")}>{this.state.nombreCalificadora}</span>) }
        <br />
        <div className="row margen_top_30">
          <br />
          <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
            {getLan() === "es" ? "Mostrar:" : "View:"}
          </div>
          <div className={["col", classes.myCol].join(" ")}>
            <Tabs data={tabsInfo} tipo={1} clicked={i => this.onChangeItemSelected(i)} activaTabInicial={this.state.tab} />
          </div>
        </div>
      </div>
    );

    if (this.state.indexSelected === 0 && this.state.calificadoraSeleccionada === null) {
      contenidoMostrar = <div className="margen_50">{calificadoras}</div>;

      tabMostrar = null;
    } else if (this.state.indexSelected === 0 && this.state.calificadoraSeleccionada !== null) {
      contenidoMostrar = (
        <div className="margen_50">
          {calificadoraInfo0} {/* Razón Social */}
          {calificadoraInfo} {/* Nombre contacto, Telefono */}
          {calificadoraInfo2} {/* Direccion */}
          {calificadoraInfo3} {/* Sitio Web */}
        </div>
      );
    } else if (this.state.indexSelected === 1 && this.state.calificadoraSeleccionada !== null) {
      rss = (
        <div>
          <button className={["btn btn-link margen_50"].join(" ")} type="button" onClick={this.onShowRssModalHandler}>
            <div className="margen_50 texto_16 montse regular texto_naranja">
              {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
              <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </button>

          <Rss
            showRssModal={this.state.showRssModal}
            modalTitle={getLan() === "es" ? "Calificadoras" : "Rating agencies"}
            urlRss={this.state.urlForRss}
            optionType={3}
            rssType={5}
          />
        </div>
      );
      contenidoMostrar = (
        <div className="margen_50">
          <EventosRelevantes calificadoraSeleccionada={this.state.calificadoraSeleccionada} location={this.props.location} history={this.props.history} tab={this.state.tab} setLoader={loader => this.setState({loader: loader})} />
        </div>
      );
    } else if (this.state.indexSelected === 2 && this.state.calificadoraSeleccionada !== null) {
      rss = (
        <div>
          <button className={["btn btn-link margen_50"].join(" ")} type="button" onClick={this.onShowRssModalHandler}>
            <div className="margen_50 texto_16 montse regular texto_naranja">
              {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
              <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </button>

          <Rss
            showRssModal={this.state.showRssModal}
            showClassModel1={true}
            modalTitle={getLan() === "es" ? "Calificadoras" : "Rating agancies"}
            urlRss={this.state.urlForRss}
            optionType={3}
            rssType={5}
          />
        </div>
      );
      contenidoMostrar = (
        <React.Fragment>
          <ReporteMensual calificadoraSeleccionada={this.state.calificadoraSeleccionada} location={this.props.location} history={this.props.history} tab={this.state.tab} setLoader={loader => this.setState({loader: loader})}/>
        </React.Fragment>
      );
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}

          <h1 className={"texto_42 nunito regular"}>
            <FormattedHTMLMessage id="empresas.calificadoras.titulo1" defaultMessage=" " />
          </h1>
          {rss}
          {tabMostrar}
          <br />
          <div className="margen_50">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(actions.initIntl(56));
    },
    oninitCalificadoras: (size, page) => {
      dispatch(actions.initCalificadoras(size, page));
    },
    onInitCalificadoraDetail: calificadoraId => {
      dispatch(actions.initCalificadoraDetail(calificadoraId));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    calificadoras: state.emisoras.calificadoras,
    eventosCalificadoras: state.emisoras.eventosCalificadoras,
    calificadoraDetail: state.emisoras.calificadoraDetail
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calificadoras);

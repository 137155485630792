import React from "react";
import classes from "./Construccion.css";
import imagen from "../../assets/images/construcion.png";
import Accordion from "../ui/accordion/Accordion";
import FilterBancosInformacion from "../ui/filterBancosInformacion/FilterBancosInformacion";

const date = +new Date();

const json = {
  content: [
    {
      id: 131249,
      idEmisora: 3869,
      idDocumento: 131249,
      clave: "XXXX",
      fechaPublicacion: date,
      docType: "PDF",
      fileName: "biva_euat1139681.pdf",
      archivos: [
        {
          url: "{contexto_almacenaje}/data-maintenance/biva_euat1139681.pdf",
          fileType: "pdf",
          fileName: "biva_euat1139681.pdf",
        },
      ],
      tipoDocumento: "Descripción...",
      nombreArchivo: "/x.pdf",
    },
    {
      id: 131248,
      idEmisora: 3869,
      idDocumento: 131248,
      clave: "XXXX",
      fechaPublicacion: date,
      docType: "PDF",
      fileName: "biva_euat1139680.pdf",
      archivos: [
        {
          url: "{contexto_almacenaje}/data-maintenance/biva_euat1139680.pdf",
          fileType: "pdf",
          fileName: "biva_euat1139680.pdf",
        },
      ],
      tipoDocumento: "Descripción...",
      nombreArchivo: "/x.pdf",
    },
    {
      id: 131247,
      idEmisora: 3869,
      idDocumento: 131247,
      clave: "XXXX",
      fechaPublicacion: date,
      docType: "PDF",
      fileName: "biva_euat1139679.pdf",
      archivos: [
        {
          url: "{contexto_almacenaje}/data-maintenance/biva_euat1139679.pdf",
          fileType: "pdf",
          fileName: "biva_euat1139679.pdf",
        },
      ],
      tipoDocumento: "Descripción...",
      nombreArchivo: "/x.pdf",
    },
    {
      id: 131245,
      idEmisora: 3869,
      idDocumento: 131245,
      clave: "XXXX",
      fechaPublicacion: date,
      docType: "PDF",
      fileName: "biva_euat1139677.pdf",
      archivos: [
        {
          url: "{contexto_almacenaje}/data-maintenance/biva_euat1139677.pdf",
          fileType: "pdf",
          fileName: "biva_euat1139677.pdf",
        },
      ],
      tipoDocumento: "Descripción...",
      nombreArchivo: "/x.pdf",
    },
  ],
  number: 0,
  size: 10,
  totalElements: 347,
  totalPages: 35,
};

export default function Construccion(props) {
  return (
    <>
      <div>
        <div className={classes.blur}>
          <h1 className={"texto_42 nunito regular"}>{props.titulo}</h1>

          <div className="row">
            <div className="col-12 sin_padding">
              <FilterBancosInformacion modelo="modelo_1" />
            </div>
          </div>
          <Accordion
            datos={json.content}
            nombre="emisoras"
            icon={""}
            tipo={13}
          />
        </div>

        <div className={classes.imgContainer}>
          <div className={classes.center}>
            <div className={classes.imagen}>
              <img src={imagen} alt="Building..." />
            </div>
            <div className={classes.mensaje}>
              <span className="texto_42 nunito bold">{props.mensaje}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
const base_url = `${dominio}/emisoras/tipo-instrumento`;

const setTipoValor = (data = null) => {
  return {
    type: actionTypes.FETCH_TIPOVALOR_SUCCESS,
    tipoValor: data
  };
};

export const filterTipoValor = ({ instrumento = null, sector = null, subSector = null, ramo = null, subRamo = null, biva = null, canceladas = null }) => {
  let url = base_url;
  url = `${url}/${[...instrumento]}/tipo-valor`;
  url = sector ? `${url}?sector=${sector}` : url;
  url = subSector ? `${url}${sector ? "&" : "?"}subsector=${subSector}` : url;
  url = ramo ? `${url}${sector ? "&" : "?"}ramo=${ramo}` : url;
  url = subRamo ? `${url}${sector ? "&" : "?"}subramo=${subRamo}` : url;
  url = biva ? `${url}${sector || subSector ? "&" : "?"}biva=${biva}` : url;
  url = canceladas ? `${url}${sector || subSector ? "&" : "?"}canceladas=${canceladas}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoValor(response.data));
      })
      .catch(() => dispatch(setTipoValor()));
  };
};

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
const base_url = `${dominio}/avisos`;

// Avisos Suspencion, Avisos Generales vigilancia de mercado y listados
const setAvisos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AVISOS_SUCCESS,
      avisos: null
    };
  }
  return {
    type: actionTypes.FETCH_AVISOS_SUCCESS,
    avisos: data
  };
};

export const filterAvisos = ({
  size,
  page,
  idTipoAvisoVigilancia,
  claveCotizacion,
  fechaInicio,
  fechaFin
}) => {
  let url = `${base_url}/vigilancia?size=${size}&page=${page}`;
  url = idTipoAvisoVigilancia
    ? `${url}&idTipoAvisoVigilancia=${idTipoAvisoVigilancia}`
    : url;
  url =
    claveCotizacion && claveCotizacion !== ""
      ? `${url}&claveCotizacion=${claveCotizacion}`
      : url;
  url =
    fechaInicio && fechaInicio !== ""
      ? `${url}&fechaInicio=${fechaInicio}`
      : url;
  url = fechaFin && fechaFin !== "" ? `${url}&fechaFin=${fechaFin}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAvisos(response.data));
      })
      .catch(err => dispatch(setAvisos(err.data)));
  };
};

const setClavesCotizacion = data => {
  return {
    type: actionTypes.FETCH_ARCLAVESCOTIZACION_SUCCESS,
    ar_claves_cotizacion: data
  };
};

export const filterArClavesCotizacion = idTipoAvisoVigilancia => {
  let url = `${base_url}/vigilancia/claves-cotizacion`;
  url = idTipoAvisoVigilancia
    ? `${url}?idTipoAvisoVigilancia=${idTipoAvisoVigilancia}`
    : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesCotizacion(response.data));
      })
      .catch(err => console.log(err));
  };
};

// Avisos listados
const setAvisosListados = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AVISOSLISTADOS_SUCCESS,
      avisos_listados: []
    };
  }
  return {
    type: actionTypes.FETCH_AVISOSLISTADOS_SUCCESS,
    avisos_listados: data
  };
};

export const filterAvisosListados = ({
  size,
  page,
  claveCotizacion,
  fechaInicio,
  fechaFin,
  tipoAvisoListado
}) => {
  let url = `${base_url}/listado?size=${size}&page=${page}`;
  url = claveCotizacion && claveCotizacion !== "" ? `${url}&claveCotizacion=${claveCotizacion}` : url;
  url = fechaInicio && fechaInicio !== "" ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin && fechaFin !== "" ? `${url}&fechaFin=${fechaFin}` : url;
  url = tipoAvisoListado && tipoAvisoListado.length > 0
    ? `${url}&tipoAvisoListado=${[...tipoAvisoListado]}`
    : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAvisosListados(response.data));
      })
      .catch(err => dispatch(setAvisosListados(err.data)));
  };
};

const setAlClavesCotizacion = data => {
  return {
    type: actionTypes.FETCH_ALCLAVESCOTIZACION_SUCCESS,
    al_claves_cotizacion: data
  };
};

export const filterAlClavesCotizacion = () => {
  let url = `${base_url}/listado/claves-cotizacion`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAlClavesCotizacion(response.data));
      })
      .catch(err => console.log(err));
  };
};

const setAlTipoAvisos = data => {
  return {
    type: actionTypes.FETCH_ALTIPOAVISOS_SUCCESS,
    al_tipo_avisos: data
  };
};

export const filterAlTipoAvisos = () => {
  let url = `${base_url}/listado/tipos-aviso-listado`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAlTipoAvisos(response.data));
      })
      .catch(err => console.log(err));
  };
};

//  Operaciones Avisos y Reportes

const setOpearcionesAvisos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_OPERACIONESAVISOS_SUCCESS,
      operaciones_avisos: []
    };
  }
  return {
    type: actionTypes.FETCH_OPERACIONESAVISOS_SUCCESS,
    operaciones_avisos: data
  };
};

export const filterOperacionesAvisos = ({ size, page, keyword, from, to }) => {
  let url = `${dominio}/cms/o/operaciones/avisos/lang/es?page=${page}&size=${size}`;

  url = keyword && keyword !== "" ? `${url}&keyword=${keyword}` : url;
  url = from && from !== "" ? `${url}&from=${from}` : url;
  url = to && to !== "" ? `${url}&to=${to}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setOpearcionesAvisos(response.data));
      })
      .catch(err => dispatch(setOpearcionesAvisos(err.data)));
  };
};


//  Operaciones Avisos y Reportes

const setAnunciosBiva = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ANUNCIOSBIVA_SUCCESS,
      anunciosBiva: []
    };
  }
  return {
    type: actionTypes.FETCH_ANUNCIOSBIVA_SUCCESS,
    anunciosBiva: data
  };
};

export const filterAnunciosBiva = ({ size, page, keyword, from, to }) => {
  let url = `${dominio}/avisos/anuncio-biva?page=${page}&size=${size}`;

  url = keyword && keyword !== "" ? `${url}&asuntoAnuncioBiva=${keyword}` : url;
  url = from && from !== "" ? `${url}&fechaInicio=${from}` : url;
  url = to && to !== "" ? `${url}&fechaFin=${to}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAnunciosBiva(response.data));
      })
      .catch(err => dispatch(setAnunciosBiva(err.data)));
  };
};
//  Operaciones Reportes

const setOperacionesReportes = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_OPERACIONESREPORTES_SUCCESS,
      operaciones_reportes: null
    };
  }
  return {
    type: actionTypes.FETCH_OPERACIONESREPORTES_SUCCESS,
    operaciones_reportes: data
  };
};

export const filterOperacionesReportes = ({
  size,
  page,
  keyword,
  fileTypes,
  from,
  to
}) => {
  let url = `${dominio}/cms/o/operaciones/reportes/lang/es?page=${page}&size=${size}`;

  url = keyword && keyword !== "" ? `${url}&keyword=${keyword}` : url;
  url =
    fileTypes && fileTypes.length > 0
      ? `${url}&fileTypes=${[...fileTypes]}`
      : url;
  url = from && from !== "" ? `${url}&from=${from}` : url;
  url = to && to !== "" ? `${url}&to=${to}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setOperacionesReportes(response.data));
      })
      .catch(err => dispatch(setOperacionesReportes(err.data)));
  };
};

const setTipoArchivos = data => {
  return {
    type: actionTypes.FETCH_TIPOARCHIVOS_SUCCESS,
    tipo_archivos: data
  };
};

export const filterTipoArchivos = () => {
  let url = `${dominio}/cms/o/operaciones/reportes/tipos-archivo`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoArchivos(response.data));
      })
      .catch(err => console.log(err));
  };
};

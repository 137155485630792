import axios from "axios";
import StringUtils from "../utils/StringUtils";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

const setEmisoras = data => { 
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
      emisorasNomemisorasbrePreliminar: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
    emisoras: data
  };
};

const setEmisorasSortNombre = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
      emisorasSortNombre: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
    emisorasSortNombre: data
  };
};


export const initEmisorasTramites = (ordenamiento = null, tipoBono = null) => {
  let url = `${dominio}/emisoras/tramites/empresas`;
//   url = `${url}/${[...instrumento]}/tipo-valor`;

if (ordenamiento) {
  url = `${url}?orden=${ordenamiento}`;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;
  return dispatch => {
    axios
    .get(url)
    .then(response => {
      dispatch(setEmisorasSortNombre(response.data));
    })
    .catch(err => console.log(err));
  };
}else{
  url = tipoBono && tipoBono.length > 0
  ? `${url}?tipoBonoAsg=${[...tipoBono]}`
  : url;

}
  
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisoras(response.data));
      })
      .catch(err => console.log(err));
  };
};

const setEmisorasPreliminar = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
      emisorasPreliminar: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
    emisorasPreliminar: data
  };
};

export const initEmisorasTramitesPreliminar = (nombre = null) => {
  let url = `${dominio}/emisoras/prospectos-preliminares/claves`;

  url = nombre ? `${url}?nombreEmisora=${encodeURIComponent(nombre)}` : url;
  
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisorasPreliminar(response.data));
      })
      .catch(err => dispatch(setEmisorasPreliminar(err.data)));
  };
};

const setNombresEmisorasPreliminar = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
      emisorasNombrePreliminar: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORASTRAMITES_SUCCESS,
    emisorasNombrePreliminar: data
  };
};

export const initEmisorasNombresTramitesPreliminar = (clave = null) => {
  let url = `${dominio}/emisoras/prospectos-preliminares/razones-sociales`;

  url = clave ? `${url}?clave=${encodeURIComponent(clave)}` : url;
  
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setNombresEmisorasPreliminar(response.data));
      })
      .catch(err => dispatch(setNombresEmisorasPreliminar(err.data)));
  };
};

// =====================================================

const setValoresTramites = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VALORESTRAMITES_SUCCESS,
      valores: []
    };
  }
  return {
    type: actionTypes.FETCH_VALORESTRAMITES_SUCCESS,
    valores: data
  };
};

export const initValoresTramites = (emisora = null,  tipoBono = null) => {
  let url = `${dominio}/emisoras/tramites/empresas/${emisora}/valores`;

  if (emisora === null) {
    url = `${dominio}/emisoras/tramites/valores`;
  }
  url = tipoBono && tipoBono.length > 0
  ? `${url}?tipoBonoAsg=${[...tipoBono]}`
  : url;

//   url = emisora ? `${url}?idEmisora=${emisora}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setValoresTramites(response.data));
      })
      .catch(err => dispatch(setValoresTramites(err.data)));

  };
};

export const initValoresTramitesPreliminar = (clave = null, nombreEmisora = null) => {
  let url = `${dominio}/emisoras/prospectos-preliminares/tipos-valor`;

  const params = {
    clave: clave != null ? encodeURIComponent(clave) : clave,
    nombreEmisora: nombreEmisora != null ? encodeURIComponent(nombreEmisora) : nombreEmisora
  }
 
  const queryParams = StringUtils.jsonToQueryString(params);

  return dispatch => {
    axios
      .get(url + queryParams)
      .then(response => {
        dispatch(setValoresTramites(response.data));
      })
      .catch(err => dispatch(setValoresTramites(err.data)));

  };
};



// =====================================================

const setClavesEmisorasTramites = (data = null) => {
  return {
    type: actionTypes.FETCH_EMISORASCLAVESTRAMITES_SUCCESS,
    claves: data
  };
};

export const initClavesTramites = (clave = null) => {
  let url = `${dominio}/emisoras/tramites/empresas`;


  url = clave ? `${url}?clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesEmisorasTramites(response.data));
      })
      .catch(err => dispatch(setClavesEmisorasTramites()));

  };
};
export const initClavesPreliminar = (clave = null) => {
  let url = `${dominio}/emisoras/prospectos-preliminares/claves`;

  url = clave ? `${url}?clave=${encodeURIComponent(clave)}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesEmisorasTramites(response.data));
      })
      .catch(err => dispatch(setClavesEmisorasTramites()));

  };
};

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

// =========================================================================

const getMercadosSic = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MERCADOSSIC_SUCCESS,
      mercados: []
    };
  }
  return {
    type: actionTypes.FETCH_MERCADOSSIC_SUCCESS,
    mercados: data
  };
};

export const initMercadosSic = (
  tipoInstrumento = null,
  biva = null,
  canceladas = null,
  empresa = null
) => {
  let url = `${dominio}/emisoras/sic/mercado`;
  if (
    (tipoInstrumento !== null && tipoInstrumento.length > 0) ||
    biva !== null ||
    canceladas !== null ||
    empresa !== null
  ) {
    url = `${url}?`;
  }
  url =
    tipoInstrumento !== null && tipoInstrumento.length > 0
      ? `${url}&tipoInstrumento=${tipoInstrumento}`
      : url;
  url = biva !== null && biva === true ? `${url}&biva=${biva}` : url;
  url =
    canceladas !== null && canceladas === true
      ? `${url}&canceladas=${canceladas}`
      : url;
  url = empresa ? `${url}&empresa=${empresa}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getMercadosSic(response.data));
      })
      .catch(err => dispatch(getMercadosSic(err.data)));
  };
};

// =========================================================================

const getClavesSic = (data = null) => {
  return {
    type: actionTypes.FETCH_CLAVESSIC_SUCCESS,
    claves: data
  };
};

export const initClavesSic = (clave = null, biva = null, canceladas = null) => {
  let url = `${dominio}/emisoras/sic/busqueda/${clave}`;
  if (biva !== null || canceladas !== null) {
    url = `${url}?`;
  }
  url = biva !== null && biva === true ? `${url}&biva=${biva}` : url;
  url =
    canceladas !== null && canceladas === true
      ? `${url}&canceladas=${canceladas}`
      : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getClavesSic(response.data));
      })
      .catch(err => dispatch(getClavesSic()));
  };
};

import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setInitFormadorMercado = data => {
  return {
    type: actionTypes.FETCH_FORMADOR_MERCADO_SUCCESS,
    formadorMercado: data
  };
};

export const initFormadorMercado = () => {
  return dispatch => {
    axios
      .get(dominio + "/content/formador_mercado")
      .then(response => {
        dispatch(setInitFormadorMercado(response.data));
      })
      .catch(err => console.log(err));
  };
};


// =========================================================

const setMarketMaker = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FORMADOR_MERCADO_SUCCESS,
      marketMakers: null
    };
  }
  return {
    type: actionTypes.FETCH_FORMADOR_MERCADO_SUCCESS,
    marketMakers: data
  };
};

export const getMarketMakers= () => {
  let url = `${dominio}/cms/o/documents/formadores-mercado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setMarketMaker(response.data));
      })
      .catch(err => dispatch(setMarketMaker(err.data)));
  };
};
// =========================================================

const setInfoMarketMaker = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FORMADOR_MERCADO_SUCCESS,
      infoMarketMakers: null
    };
  }
  return {
    type: actionTypes.FETCH_FORMADOR_MERCADO_SUCCESS,
    infoMarketMakers: data
  };
};

export const getInfoMarketMakers= (maker) => {
  let url = `${dominio}/cms/o/documents/market-maker/${maker}/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInfoMarketMaker(response.data));
      })
      .catch(err => dispatch(setInfoMarketMaker(err.data)));
  };
};
import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Colocaciones.css";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Tabs from "../../../components/ui/tabs/Tabs";
import Pagination from "../../../components/ui/pagination/Pagination";
// import FilterSic from "../../../components/ui/filterSic/FilterSic";
// import EmisionesSic from "./EmisionesSic";
import Loader from "../../../components/loader/Loader";
// import AvisosDerecho from "./AvisosDerecho";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan, getRealDateMilliesDayMonthYear } from "../../../stores/utils/utilities";
import Card from "../../../components/ui/card/Card";
import ColocacionDetalle from "../../invertir/colocaciones/ColocacionDetalle"
import FilterColocaciones from "../../../components/ui/filterColocaciones/FilterColocaciones";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/sic/descargar`;

const tabsSic = [
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Todas" : "All"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Listadas en BIVA" : "Listed in BIVA"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Canceladas" : "Cancelled"
    }
];

const tabsInfo = [
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Historia" : "General information"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Proceso BIVA" : "Corporate action notice"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Casas Bolsa" : "Listed securities"
    },
    {
        image: "",
        icon: "",
        title: getLan() === "es" ? "Documentos" : "Listed securities"
    }
];

class Colocaciones extends Component {
    state = {
        messages: null,
        lan: getLocal(),
        emisoraSeleccionada_id: null,
        emisoraSeleccionada: null,
        sicDetail: null,
        indexSelected: 0,
        logo: null,
        razonSocial: null,
        itemsPerPage: 10,
        activePage: 1,
        resourceList: [],
        loader: true,
        numRows: 0,
        biva: false,
        canceladas: false,
        tipoValor: null,
        tipoInstrumento: null,
        clave: null,
        claveEmisoraSeleccionada: null,
        tab: 0,
        subTab: 0,
        wasFiltered: false,
        tabsDeshabilitados: [],
        fechaInicio: null,
        fechaFin: null,
        tipoDerecho: null,
        montoTentativoEmision: null,
        fechaTentativaEmision: null,
        sustentable: null,
        urlSitioWeb: null,
        id: null
    };

    handlePageChange(pageNumber) {
        if (this.state.activePage !== pageNumber) {
            this.setState({
                loader: true
            });

            this.onPageChangeHandler(null, pageNumber);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages
                });
            }
        }
        if (this.props.sicDetail !== prevProps.sicDetail) {
            let canceladas = false;
            let tabsDeshabilitados = this.state.tabsDeshabilitados;
            if (this.props.sicDetail !== null && this.props.sicDetail !== undefined) {
                if (
                    this.props.sicDetail.estatus === "Cancelada" ||
                    this.props.sicDetail.estatus === "Listado preventivo"
                ) {
                    canceladas = true;
                    tabsDeshabilitados = [2];
                }
                if (this.props.sicDetail.estatus === "Activa") {
                    tabsDeshabilitados = [];
                }
            }
            this.setState({
                sicDetail: this.props.sicDetail,
                canceladas:
                    this.props.sicDetail &&
                        this.props.sicDetail.estatus &&
                        this.props.sicDetail.estatus === "Activa"
                        ? false
                        : true,
                tabsDeshabilitados: tabsDeshabilitados,
                loader: false
            });
        }
        if (this.props.colocaciones !== prevProps.colocaciones) {
            
            // let item = this.props.colocaciones.content.find(itm => itm.empresa.id === this.state.emisoraSeleccionada)
            //     ? itm
            //     : null;
            let item = null;
            this.props.colocaciones.content.map((dato, i) => (
                item = dato.id === this.state.id ? dato : item
                // item = dato.id === 1 ? dato : item


                // item = dato.empresa.id === this.state.emisoraSeleccionada ? dato : item
                // item = dato.empresa ? dato.empresa.id === this.state.emisoraSeleccionada ? dato : item : null
            ));
            
            this.setState({
                id: item ? item.id : null,
                emisoraSeleccionada: item ? item.empresa.id : this.state.emisoraSeleccionada,
                clave: item ? item.empresa.clave : this.state.clave,
                claveEmisoraSeleccionada: item ? item.empresa.clave : this.state.clave,
                razonSocial: item ? item.empresa.nombre : null,
                logo: item ? item.urlLogoEmisora : null,
                urlSitioWeb: item ? item.urlSitioWeb : null,
                tipoInstrumento: item ? item.tipoInstrumento.nombre : null,
                montoTentativoEmision: item ? item.montoTentativoEmision : null,
                fechaTentativaEmision: item ? item.fechaTentativaEmision : null,
                sustentable: item ? item.sustentable : null
                // this.setState({
                //         loader: false
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let colocaciones = null;
        let numRows = null;

        if (nextProps.colocaciones && nextProps.colocaciones.content) {
            colocaciones = nextProps.colocaciones.content;
            numRows = nextProps.colocaciones.totalElements;
        }

        this.setState({
            numRows: numRows
        });
        let resource = colocaciones;
        this.onPageChangeHandler(resource, null, 0);
    }

    componentDidMount() {
        // let resource = this.props.colocaciones;
        this.props.onInitIntl();

        let paramsString = queryString.parse(this.props.location.search);
        let tipoInstrumento =
            paramsString["tipoInstrumento"] !== undefined &&
                paramsString["tipoInstrumento"] !== "null"
                ? paramsString["tipoInstrumento"]
                : null;
        let clave =
            paramsString["clave"] !== undefined && paramsString["clave"] !== "null"
                ? paramsString["clave"]
                : null;
        let emisora =
            paramsString["emisora"] !== undefined &&
                paramsString["emisora"] !== "null"
                ? paramsString["emisora"]
                : null;
        let page =
            paramsString["page"] !== undefined && paramsString["page"] !== "null"
                ? parseInt(paramsString["page"])
                : 1;
        let fechaInicio =
            paramsString["fechaInicio"] !== undefined &&
                paramsString["fechaInicio"] !== "null"
                ? paramsString["fechaInicio"]
                : null;
        let fechaFin =
            paramsString["fechaFin"] !== undefined &&
                paramsString["fechaFin"] !== "null"
                ? paramsString["fechaFin"]
                : null;
        let sustentable =
            paramsString["sustentable"] !== undefined && paramsString["sustentable"] !== "null"
                ? paramsString["sustentable"]
                : null;
        let id =
            paramsString["id"] !== undefined && paramsString["id"] !== "null"
                ? parseInt(paramsString["id"])
                : null;
        if (emisora !== null) {
            this.updateCardDetail(emisora, clave, id);
        }
       
        this.setState({
            emisoraSeleccionada_id: emisora,
            emisoraSeleccionada: emisora,
            tipoInstrumento: tipoInstrumento,
            clave: clave,
            claveEmisoraSeleccionada: clave,
            activePage: page > 0 ? page : 1,
            sustentable: sustentable,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            id: id
        });
        if (emisora) {
            page = 1;
        }

        this.props.onInitColocaciones(
            this.state.itemsPerPage,
            page > 0 ? page - 1 : 0
        )
    }

    onPageChangeHandler = (
        resource = null,
        pageNumber = null,
        seleccionado = null
    ) => {
        let data = resource ? resource : this.props.cotizaciones;

        let resourceList = [];
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                resourceList: resourceList,
                itemSeleccionado: urlSeleccionado
            });
        } else if (pageNumber) {
            this.setState({
                activePage: pageNumber,
                resourceList: resourceList
            });
            this.changeHistory(
                this.state.tipoValor,
                this.state.tipoInstrumento,
                this.state.biva ? 1 : this.state.canceladas ? 2 : 0, // this.state.tab,
                this.state.clave,
                this.state.emisoraSeleccionada,
                pageNumber,
                this.state.subTab,
                this.state.tipoDerecho,
                this.state.fechaInicio,
                this.state.fechaFin,
                this.state.biva,
                this.state.canceladas
            );
        } else {
            this.setState({
                resourceList: resourceList
            });
        }
    };

    componentWillMount() { }

    changeHistory(
        sustentable = null,
        tipoInstrumento = null,
        clave = null,
        emisoraSeleccionada = null,
        page = null,
        fechaInicio = null,
        fechaFin = null,
        id = null
    ) {
        
        this.props.history.push(
            `?sustentable=${sustentable}&tipoInstrumento=${tipoInstrumento}&clave=${clave}&emisora=${emisoraSeleccionada}&page=${page ? page : this.state.activePage
            }&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&id=${id}`
        );
        this.setState({
            loader: true
        });

        this.props.onInitColocaciones(
            this.state.itemsPerPage,
            page > 0 ? page - 1 : 0,
            // tipoInstrumento,
            // emisoraSeleccionada,
            // clave
        );
    }

    changeHistoryReset() {
        this.props.history.push(
            `?sustentable=${null}&tipoInstrumento=${null}&clave=${null}&emisora=${null}&page=1&fechaInicio=${null}&fechaFin=${null}`
        );
        this.props.onInitColocaciones(
            this.state.itemsPerPage,
            0,
            // biva ? true : false,
            // canceladas ? true : false,
            // null,
            // null,
            // null,
            // null
        );
    }

    // selectEmisora = (id = null, clave = null, consulta = true) => {
    selectEmisora = (id = null, clave_ = null, consulta = false, nombre = null, sustentable = null, tipoInstrumento = null, fechaTentativaEmision = null, montoTentativoEmision = null) => {
        // let claveEmisora = clave_;
        // if (id && clave_ === null) {
        //     claveEmisora = this.state.resourceList.find(itm => itm.id === id)
        //         ? this.state.resourceList.find(itm => itm.id === id).clave
        //         : null;
        // }

        // if (consulta) {
        //     this.changeHistory(
        //         null,
        //         null,
        //         this.state.tab ? this.state.tab : 0,
        //         claveEmisora,
        //         id ? id : null,
        //         null,
        //         0,
        //         null,
        //         null,
        //         null,
        //         this.state.tab === 1 ? true : null,
        //         this.state.tab === 2 ? true : null
        //     );
        // }
        if (id) {
            // this.props.onInitEmisorasDetail(id);
            // this.props.onInitDocumentos(id);
            // this.props.onInitColocadores(id)
        }
        this.setState({
            emisoraSeleccionada: id,
            // clave: claveEmisora,
            // claveEmisoraSeleccionada: claveEmisora,
            // indexSelected: 0,
            // subTab: 0
        });
    };

    updateCardDetail = (emisoraId = null, clave = null, id= null) => {
        
        this.changeHistory(
            null,
            null,
            clave,
            emisoraId ? emisoraId : null,
            0,
            this.state.fechaInicio,
            this.state.fechaFin,
            id
        );

    };

    updateGrandParentSelectedCard = (id = null, emisoraId = null, razonSocial = null, clave = null, urlLogoEmisora = null, urlSitioWeb = null, tipoInstrumento = null, montoTentativoEmision = null, fechaTentativaEmision = null, sustentable = null, consulta = true) => {
    
        this.setState({
            id: id,
            emisoraSeleccionada: emisoraId,
            clave: clave,
            claveEmisoraSeleccionada: clave,
            indexSelected: 0,
            subTab: 0,
            razonSocial: razonSocial,
            logo: urlLogoEmisora,
            urlSitioWeb: urlSitioWeb,
            tipoInstrumento: tipoInstrumento,
            montoTentativoEmision: montoTentativoEmision,
            fechaTentativaEmision: fechaTentativaEmision,
            sustentable: sustentable

        });
        if (consulta) {
            this.changeHistory(
                sustentable,
                null,
                clave,
                emisoraId ? emisoraId : null,
                0,
                this.state.fechaInicio,
                this.state.fechaFin,
                id
            );

        }
    };

    onSetLoader = loader => {
        this.setState({
            loader: loader
        });
    };

    onSetPage = page => {
        this.setState({
            activePage: page
        });
    };
    onSetTipoInstrumento = (tipoInstrumento, tipo = null) => {
        this.setState({
            tipoInstrumento: tipoInstrumento
        });
    };

    onSetFechaInicio = fecha => {
        this.setState({
            fecha_inicio: fecha
        });
    };

    onSetFechaFin = fecha => {
        this.setState({
            fecha_fin: fecha
        });
    };

    onSetEmisora = (emisora, tipo, clave_ = null) => {
        let emisoraSeleccionada = null;
        let clave = null;
        let claveEmisoraSeleccionada = null;
        if (tipo === 1) {
            emisoraSeleccionada = emisora;
            claveEmisoraSeleccionada = clave_;
            clave = clave_;
        }
        if (tipo === 2) {
            clave = emisora;
            claveEmisoraSeleccionada = emisora;
        }

        this.setState({
            emisoraSeleccionada_id: emisoraSeleccionada,
            emisoraSeleccionada: emisora,
            claveEmisoraSeleccionada: claveEmisoraSeleccionada,
            clave: clave,
            loader: true
        });
    };

    onSetLimpiarFiltros = changeTab => {
        this.setState({
            emisoraSeleccionada_id: null,
            claveEmisoraSeleccionada: null,
            activePage: changeTab ? 1 : this.state.activePage,
            tipoInstrumento: null,
            fechaInicio: null,
            fechaFin: null,
            sustentable: false,
        });
    };

    setWasFiltered = wasFiltered => {
        this.setState({
            wasFiltered: wasFiltered
        });
    };

    onSetFilters = ({
        sustentable = null,
        tipoInstrumento = null,
        fechaInicio = null,
        fechaFin = null,
        emisoraSeleccionada = null,
        clave = null,
        page = null,
        reset = false,
        id = null,
    }) => {
        this.setState({
            id: id,
            emisoraSeleccionada: emisoraSeleccionada,
            tipoInstrumento: tipoInstrumento,
            clave: clave,
            claveEmisoraSeleccionada: clave,
            activePage: page,
            sustentable: sustentable,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin
        });
        if (reset) {
            this.changeHistoryReset();
        } else {
            this.changeHistory(
                sustentable,
                tipoInstrumento,
                clave,
                emisoraSeleccionada,
                page,
                fechaInicio,
                fechaFin
            );
        }
    };

    onSetInitTabs = (
        index = null,
        emisora = null,
        wasFilteredBySearch = null
    ) => {
        this.setState({
            indexSelected: index,
            emisoraSeleccionada: emisora,
            wasFilteredBySearch: wasFilteredBySearch
        });
    };

    render() {
        let contenidoMostrar = null;
        let loader = null;
        if (this.state.loader) {
            loader = (
                <Loader loaderDisable={disable => this.setState({ loader: disable })} />
            );
        }

        const numRows = this.state.numRows ? this.state.numRows : 0;
        let filtro = null;
        filtro = (
            <div className="margen_top_30">
                <FilterColocaciones
                    itemsPerPage={this.state.itemsPerPage}
                    setTipoInstrumento={(tipoInstrumento, tipo) =>
                        this.onSetTipoInstrumento(tipoInstrumento, tipo)
                    }
                    // setTipoValor={tipoValor => this.onSetTipoValor(tipoValor)}
                    setPage={page => this.onSetPage(page)}
                    setEmisora={(emisora, tipo, clave) =>
                        this.onSetEmisora(emisora, tipo, clave)
                    }

                    setLimpiarFiltros={changeTab => this.onSetLimpiarFiltros(changeTab)}
                    setWasFiltered={wasFiltered => this.setWasFiltered(wasFiltered)}
                    setFilters={filterParams => this.onSetFilters(filterParams)}
                    setInitTabs={(index, emisora, wasFilteredBySearch) =>
                        this.onSetInitTabs(index, emisora, wasFilteredBySearch)
                    }
                    updateEmisoraParent={(id, clave) => this.updateGrandparentSelectedCard(id, clave)}
                    location={this.props.location}
                    claveEmisoraSeleccionada={this.state.claveEmisoraSeleccionada}
                    emisoraSeleccionada={this.state.emisoraSeleccionada}
                    onSetFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
                    onSetFechaFin={fechaFin => this.onSetFechaFin(fechaFin)}
                />
            </div>
        );
        const cotizaciones = (

            <React.Fragment>
                
                {this.props.colocaciones ? (
                    // </div>
                    <div className={["row"].join(" ")}>
                        <Card
                            datos={this.props.colocaciones.content}
                            updateGrandParentSelectedCard={this.updateGrandParentSelectedCard.bind(this)}
                            setCard={cardParams => this.onSetCard(cardParams)} />

                    </div>
                ) : null}
                
                <Pagination
                    claseTipo={2}
                    numRows={numRows}
                    paginas={5}
                    itemsPerPage={this.state.itemsPerPage}
                    handlePageChange={this.handlePageChange.bind(this)}
                    activePage={this.state.activePage}
                />
            </React.Fragment>
        );

        if (
            this.state.indexSelected === 0 &&
            this.state.emisoraSeleccionada === null
        ) {
            contenidoMostrar = (
                <div className="margen_50">
                    <br />
                    {this.props.colocaciones &&
                        this.props.colocaciones.content &&
                        this.props.colocaciones.content.length > 0 ? (
                            cotizaciones
                        ) : (
                            <p className="montse texto_16 regular">
                                {getLan() === "es"
                                    ? "No existen resultados para la búsqueda."
                                    : "No results found."}
                            </p>
                        )}
                </div>
            );

        } else if (
            this.state.subTab === 0 &&
            this.state.emisoraSeleccionada !== null
        ) {
            contenidoMostrar = (
                <div className="margen_50">
                    <ColocacionDetalle
                        empresaId={this.state.emisoraSeleccionada}
                        razonSocial={this.state.razonSocial}
                        tipoInstrumento={this.state.tipoInstrumento}
                        montoTentativoEmision={this.state.montoTentativoEmision}
                        fechaTentativaEmision={this.state.fechaTentativaEmision}
                        sustentable={this.state.sustentable}
                        urlSitioWeb={this.state.urlSitioWeb}
                        urlLogoEmisora={this.state.logo}
                    />
                </div>


            );

        }

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages}>
                <React.Fragment>
                    {loader}

                    <h1 className={"texto_42 nunito regular"}>
                        <FormattedHTMLMessage
                            id="empresas.sic.titulo"
                            defaultMessage=" Próximas Colocaciones"
                        />
                        {/* 
          Sistema internacional de cotizaciones (SIC) */}
                    </h1>
                    <div className="margen_50 texto_16 montse regular">
                        <FormattedHTMLMessage
                            id="invierte.colocaciones.introduccion"
                            defaultMessage="Selecciona el perfil de una emisora para consultar su información general y documentos"
                        />
                    </div>
                    {filtro}
                    <div className="margen_50">
                        {contenidoMostrar}
                    </div>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitColocaciones: (
            size,
            page
            // biva,
            // canceladas,
            // tipoValor,
            // mercadoPrincipal,
            // emisora,
            // clave
        ) => {
            dispatch(
                actions.getColocaciones(
                    size,
                    page
                    // biva,
                    // canceladas,
                    // tipoValor,
                    // mercadoPrincipal,
                    // emisora,
                    // clave
                )
            );
        },
        onInitIntl: () => {
            dispatch(actions.initIntl(54));
        }
    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        colocaciones: state.colocaciones.colocaciones,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Colocaciones));

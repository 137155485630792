import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// import EventosList from "../../../components/eventos/Eventos";
// import eventos_json from '../../../json/eventos.json'

import Noticia from './noticia/Noticia';
import Noticias from './noticia/Noticias';

class NoticiasPage extends Component {
  render() {
    const routes = (
      <Switch>
        <Route path="/nosotros/acerca_de/noticias" exact  component={Noticias} />
        <Route path="/nosotros/acerca_de/noticias/:noticiaId"  component={Noticia} />
      </Switch>
    );
    return (
      <React.Fragment>
            {routes}
      </React.Fragment>
    );
  }
}

export default NoticiasPage;

import React from "react";
import classes from "./Carta.css";

export default function Carta(props) {
  let indx = 0;
  let cartas = props.data.map((carta) => {
    indx = indx + 1;
    if (indx > 4) {
      indx = 1;
    }
    const claseCarta = [classes.cara, "text-center"];
    claseCarta.push(`${classes["color" + indx]}`);
    const claseColor = [
      `${classes["color" + indx]}`,
      classes.cara,
      classes.detras,
      "text-left",
    ];
    return (
      <div className={classes.carta_box}>
        <div className={classes.carta}>
          <div className={claseCarta.join(" ")}>
            <span className="nunito texto_18 texto_blanco  bold noselect">
              {carta.cara1}
            </span>
          </div>
          <div className={claseColor.join(" ")}>
            <span className="montse texto_16 texto_blanco text-left regular noselect">
              {carta.cara2}
            </span>
          </div>
        </div>
      </div>
    );
  });

  return <>{cartas}</>;
}

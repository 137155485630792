import React, { Component } from "react";
import { connect } from "react-redux";

import Filter from "../../../../components/ui/filter/Filter";
import Box from "../../../../components/ui/box/Box";
import EventosList from "../../../../components/eventos/Eventos";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import Evento from './SalaPrensa';

import * as action from "../../../../stores/actions/index";
import queryString from "query-string";

class Publicaciones extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    eventoId: null,
    loader: null,
    page:1
  };

  componentDidMount() {
    this.props.onInitIntl(9);
    this.props.onDocumentos();

    let paramsString = queryString.parse(this.props.location.search);
    let fechaInicio =
    paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
     let fechaFin =
    paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    let id =
    paramsString["id"] !== undefined && paramsString["id"] !== "null" ? paramsString["id"] : null;
    
    this.setState({page : page && page > 0 ? page : 1,
      eventoId: id})
  
    this.props.onInitSalaPrensa(fechaInicio, fechaFin);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  onEventSelected(eventoId) {
    this.setState({ eventoId: eventoId });
    if (eventoId === null) {
      let timeD = +new Date();
      this.setState({loader:timeD})
      this.props.history.push(
        "?page=1"
      );
    }else{
      this.props.history.push(
        `?id=${eventoId}&page=${1}`
      );
    }
  }

  onPageSelected(page){
    if (this.state.page !== page) {
      this.setState({page: page})
    }
  }

  render() {
    let documentos = null;

    if (this.props.documentos) {
      documentos = this.props.documentos.map((doc, i) => {
        return <Box key={"prensa_" + i} modelo="modelo_1" data={doc} />;
      });
    }

    const salaPrensa =
      this.props.salaPrensa && this.props.salaPrensa.length !== undefined && this.props.salaPrensa.length > 0 ? (
        <EventosList datos={this.props.salaPrensa} es_evento={false} redirecciona={"/nosotros/acerca_de/sala_prensa/"} eventSelected={(eventoId) => this.onEventSelected(eventoId)} pageSelected={(page) => this.onPageSelected(page)} activePage={this.state.page} />
      ) : (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );

    let eventos_container = null;
    if (this.state.eventoId) {
      eventos_container = (
        <Evento key={+new Date()} eventoId={this.state.eventoId} salaPrensa={this.props.salaPrensa} eventSelected={eventoId => this.onEventSelected(eventoId)} tipo={3} />
      );
    } else {
      eventos_container = (
        <>
          <div className="row margen_top_50">
            <div className="col sin_padding">
              <Filter modelo="modelo_1" target="salaPrensa" loaderDisabled={this.state.loader}  history={this.props.history} page={this.state.page} />
            </div>
          </div>
          <div className="row margen_top_30">{documentos}</div>
          <div className="row margen_top_50">
            <div className="col sin_padding">{salaPrensa}</div>
          </div>
        </>
      );
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <React.Fragment>
          <div className="row">
            <div className="col sin_padding">
              <h1 className={"nunito regular texto_42"}>
                <FormattedMessage id="nosotros.acercade.saladeprensa.titulo1" defaultMessage=" " />{" "}
              </h1>
            </div>
          </div>
          {eventos_container}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    salaPrensa: state.salaPrensa.salaPrensa,
    documentos: state.documentos.prensa,
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onInitSalaPrensa: (fechaInicio, fechaFin) => {
      dispatch(action.initSalaPrensa(fechaInicio, fechaFin));
    },
    onDocumentos: () => {
      dispatch(action.initPrensaDocumentos());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Publicaciones);

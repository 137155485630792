import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  avisos: null,
  ar_claves_cotizacion: null,
  avisos_listados: null,
  al_claves_cotizacion: null,
  al_tipo_avisos: null,
  operaciones_avisos: null,
  tipo_archivos: null,
  anunciosBiva: null,
  operaciones_reportes: null
};

const setAvisos = (state, action) => {
  return updateObject(state, action);
};

const setArClavesCotizacion = (state, action) => {
  return updateObject(state, action);
};

const setAvisosListados = (state, action) => {
  return updateObject(state, action);
};

const setAlClavesCotizacion = (state, action) => {
  return updateObject(state, action);
};

const setAlTipoAvisos = (state, action) => {
  return updateObject(state, action);
};

const setOperacionesAvisos = (state, action) => {
  return updateObject(state, action);
};

const setTipoArchivos = (state, action) => {
  return updateObject(state, action);
};

const setOperacionesReportes = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_AVISOS_SUCCESS:
      return setAvisos(state, action);
    case actionTypes.FETCH_ARCLAVESCOTIZACION_SUCCESS:
      return setArClavesCotizacion(state, action);
    case actionTypes.FETCH_AVISOSLISTADOS_SUCCESS:
      return setAvisosListados(state, action);
    case actionTypes.FETCH_ALCLAVESCOTIZACION_SUCCESS:
      return setAlClavesCotizacion(state, action);
    case actionTypes.FETCH_ALTIPOAVISOS_SUCCESS:
      return setAlTipoAvisos(state, action);
    case actionTypes.FETCH_OPERACIONESAVISOS_SUCCESS:
      return setOperacionesAvisos(state, action);
    case actionTypes.FETCH_ANUNCIOSBIVA_SUCCESS:
      return setOperacionesAvisos(state, action);
    case actionTypes.FETCH_TIPOARCHIVOS_SUCCESS:
      return setTipoArchivos(state, action);
    case actionTypes.FETCH_OPERACIONESREPORTES_SUCCESS:
      return setOperacionesReportes(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./FilterRequisitosMantenimiento.css";
import { Form, Field } from "react-final-form";
import { getLan } from "../../../stores/utils/utilities";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  options,
  maxCharacter = null,
  isMultiple = null,
  selectLabel
}) => {
  const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";

  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes && elementType === "checkbox" ? classes : [classes, "form-control"].join(" "),
      ref: ref,
      disabled: disabled,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    isMultiple: isMultiple,
    maxCharacter: maxCharacter,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel
  };
  return (
    <div className="form-group">
      {label && elementType !== "checkbox" ? (
        <label for={input.name} className={["texto_14", "montse", "regular"].join(" ")}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterRequisitosMantenimiento extends Component {
  constructor(props) {
    super(props);
    this.formEmisoras = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    esconderAutoComplete: false,
    toggleFilters: false,
    disableEnableButtons: true,
    selectedFilters: {
      periodos: [],
      capital_social: []
    },
    form_filters: {
      periodos: {
        value: ""
      },
      capital_social: {
        value: []
      }
    },
    form_clave: {
      clave: {
        value: ""
      }
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    itemsPerPage: 5
  };

  componentWillMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }
  componentDidMount() {
    this.props.onFilterCapitalSocial();
    this.props.onFilterPeriodosRequisitos();
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible
    });
  }

  printFrameHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : this.showInputHandler();

    const closeLabel = getLan() === "es" ? "Cerrar Filtros" : "Close";
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";

    return (
      <div className="row margin_bottom_30">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col-4", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>{this.state.toggleFilters ? closeLabel : filterLabel}</span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-8", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col-2",
                "col-lg-1",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters ? classes.no_border_search_button_model_one : null
                ].join(" ")}
              >
                <button
                  type="buttom"
                  className={["btn"].join(" ")}
                  /* disabled={this.state.disableEnableButtons} */
                  disabled
                >
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  onSelectOptionsHandler = type => {
    let options = [];
    type.forEach(element => {
      options.push({
        nombre: element.id,
        id: `${element.id}_Periodo`
      });
    });
    return options;
  };

  modelOne = () => {
    const capital_social = this.props.capital_social ? this.onPrintCheckboxCapitalSocial() : null;
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              periodos: this.state.form_filters.periodos.value,
              capital_social: this.state.form_filters.capital_social.value
            }}
            render={({
              optionsPeriodos = this.props.periodos ? this.onSelectOptionsHandler(this.props.periodos) : [],
              form
            }) => (
              <form ref={this.formEmisoras} onSubmit={this.onSubmitFilterHandler}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-lg-4">
                    <Field
                      name="periodos"
                      id="periodos"
                      label={getLan() === "es" ? "Periodos" : "Period"}
                      elementType="select"
                      type="select"
                      classes="custom-select"
                      options={optionsPeriodos}
                      onInputChange={event => this.onInputSelectedHandler(event, 0)}
                      component={renderInput}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-lg-8 ">
                    <div className="row">
                      <div className="col">
                        <span className={"texto_14 montse regular"}>{getLan() === "es" ? "Cumplimiento" : "Complies"}</span>
                      </div>
                    </div>
                    <div className={["row", classes.checkContainer].join(" ")}>{capital_social}</div>
                  </div>
                </div>
                <hr />
                {this.sectionButtonHandler(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  onPrintCheckboxCapitalSocial = () => {
    let options = [...this.props.capital_social];

    return options.map(option => {
      return <div className="float_left margen_derecha_30">{this.onPrintCapitalSocialHandler(option)}</div>;
    });
  };

  onPrintCapitalSocialHandler = option => {
    let newLabel = option.desc;
    if (getLan() !== "es") {
      if (option.id === "C") {
        newLabel = "YES";
      } else if (option.id === "NC") {
        newLabel = "NO";
      } else if (option.id === "NA") {
        newLabel = "Not applicable";
      } else if (option.id === "NR") {
        newLabel = "Not reported";
      }
    }

    return (
      <Field
        name="capital_social"
        id={option.desc}
        type="checkbox"
        elementType="checkbox"
        value={`${option.id}_${option.desc}`}
        classes="form-check-input"
        label={newLabel}
        onInputChange={event => this.onInputSelectedHandler(event, 1)}
        component={renderInput}
      />
    );
  };

  sectionButtonHandler = form => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";

    return (
      <div className="row">
        <div className="col col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterHandler = event => {
    // Pass
  };

  onSubmitSearchHandler = () => {
    // Pass
  };

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = value => {
    this.onInputChangeHandler(value);
  };

  onInputChangeHandler = (event = null, eraser = null) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;
    if (value !== "") {
      this.props.onFilterEmisorasRequisitos(value);
      if (value === null) {
        const params = {
          size: this.state.itemsPerPage,
          page: 0,
          periodo: null,
          capitalSocial: null
        };

        this.props.onFilterRequisitos(params);
      }
    } else {
      const params = {
        size: this.state.itemsPerPage,
        page: 0,
        periodo: null,
        capitalSocial: null
      };
      this.props.onFilterRequisitos(params);
      disabled = true;
    }

    if (eraser) {
      this.setState(prevState => {
        return {
          toggleInputs: !prevState.toggleInputs,
          form_clave: form_clave,
          disableEnableButtons: true
        };
      });
    } else {
      this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
    }
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor del tipo de periodo
    const valuesPeriodos =
      form.periodos.value && form.periodos.value !== "" ? this.onSplitValueHandler(form.periodos.value) : "";
    // Se obtiene el valor de tipo valor
    const valuesCapitalSocial =
      form.capital_social.value && form.capital_social.value.length > 0
        ? this.onSplitValueHandler(form.capital_social.value)
        : [];

    return [valuesPeriodos, valuesCapitalSocial];
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    const [valuesPeriodos, valuesCapitalSocial] = this.onGetAllValuesFormFilter();

    const params = {
      size: this.state.itemsPerPage,
      page: 1,
      periodo: valuesPeriodos,
      capitalSocial: valuesCapitalSocial,
      reset: false
    };

    // Se invoca la funcion del componente padre (Emisoras.js) para setear los valores correspondientes
    // Configurando el valor de la emisora seleccionada
    this.props.setPeriodosFilter(valuesPeriodos);
    this.props.setCapitalSocialFilter(valuesCapitalSocial);
    this.props.setPage(1);
    this.props.setParams(params);
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = value => {
    let newValue = null;
    let newLabel = null;

    newValue = value.split("_")[0];
    newLabel = value.split("_")[1];

    return [newValue, newLabel];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["periodos", form.periodos.value, selectedFilters.periodos];
      case 1:
        return ["capital_social", form.capital_social.value, selectedFilters.capital_social];
    }
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null, label = null) => {
    let newStateValues = null;

    if (type !== 1) {
      if (values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];

      const newValue = label ? `${values}_${label}` : values;
      const stateExists = newStateValues.find(option => option === newValue);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== newValue);
      } else {
        if (newValue !== "") {
          newStateValues.push(newValue);
        } else {
          newStateValues = [];
        }
      }
    }
    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];

    if (type !== 1) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExist = newFilterValues.find(option => option.label === label);
      if (filterExist) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
      } else {
        if (values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        } else {
          newFilterValues = [];
        }
      }
    }

    return newFilterValues;
  };

  onInputSelectedHandler = (event, type = null) => {
    let actualValue = event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue);

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);

    newLabel = type === 0 ? newValue : newLabel;
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [valuesPeriodos, valuesCapitalSocial] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      if (valuesPeriodos !== "") {
        // Pass
      } else {
        // Pass
      }
    } else if (type === 1) {
      if (valuesCapitalSocial.length > 0) {
        // Pass
      } else {
        // Pass
      }
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.props.wasFiltered(true);
    this.onFilterDataByEvent();
  };

  onDeleteFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

    const auxValue = item.type === 0 ? "" : item.value;
    newStateValues = this.onChangeStateHandler(stateValues, auxValue, item.type, item.label);

    newFilterValues = this.onChangeFilterHandler(filterValues, auxValue, item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  // Limpia el formulario y establece valores por defecto
  onResetFilterHandler = form => {
    const form_filters = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form_filters.periodos.value = "";
    selectedFilters.periodos = [];
    form_filters.capital_social.value = [];
    selectedFilters.capital_social = [];

    this.setState({
      form_filters: form_filters,
      selectedFilters: selectedFilters
    });

    this.props.wasFiltered(false);
    this.onFilterDataByEvent();

    const params = {
      size: this.state.itemsPerPage,
      page: 1,
      periodo: null,
      capitalSocial: null,
      reset: true
    };

    // Se invoca la funcion del componente padre (requisitosMantenimiento.js) para setear los valores correspondientes
    // Configurando el valor de la emisora seleccionada
    this.props.setParams(params);
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.selectedFilters.periodos, ...this.state.selectedFilters.capital_social];

    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map((item, i) => (
          <div className={classes.filter_container_model_one} key={`${item.label}-${i}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{item.label}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = () => {
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.emisoras_requisitos;
    return listado.map(item => (
      <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.id, 1)}>
        {item.desc}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, type = 0) => {
    let ids = [];
    if (type === 0) {
      const options = [...this.props.emisoras_requisitos];
      options.forEach(option => {
        ids.push(option.id);
      });
    } else {
      ids = [clave];
    }

    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      clave: ids
    };

    this.onToggleFilterInputOptionSelected();
    this.props.onFilterRequisitosByEmisora(params);
    this.setState({ typeClaveSelected: type, valueClave: clave });
  };

  onPrintOptionSelectedHandler = value => {
    const textFound = getLan() === "es" ? "Ver todos los resultados para" : "See all results for";

    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
        <span className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {this.state.typeClaveSelected === 0 ? `${textFound}: ${this.state.valueClave}` : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onInputChangeHandler(null, true)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.emisoras_requisitos ? this.onPrintAutoCompleteHandler() : null;

    const textFound = getLan() === "es" ? "Ver todos los resultados para" : "See all results for";
    const textNotFound = getLan() === "es" ? "No existen resultados para la búsqueda" : "There are no search results";

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {`${textFound}: ${value}`}
        </li>
      ) : (
        <li className="list-group-item">{`${textNotFound}: ${value}`}</li>
      );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div className={[classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null].join(" ")}>
          <Form
            onSubmit={this.onSubmitSearchHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value
            }}
            render={() => (
              <form ref={this.formSearch} onSubmit={this.onSubmitSearchHandler}>
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  maxCharacter={8}
                  placeholder={getLan() === "es" ? "Buscar por clave de cotización" : "Search by ticker"}
                  component={renderInput}
                  onInputChange={event => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              !this.state.form_clave.clave.value || this.state.form_clave.clave.value === "" ? classes.hidden : null,
              this.state.esconderAutoComplete ? classes.hidden : null
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const filter = this.printFrameHandler();
    return filter;
  }
}

const mapStateToProps = state => {
  return {
    periodos: state.requisitosMantenimiento.periodos_requisitos,
    capital_social: state.requisitosMantenimiento.capital_social,
    emisoras_requisitos: state.requisitosMantenimiento.emisoras_requisitos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterRequisitos: params => {
      dispatch(actions.filterRequisitos(params));
    },
    onFilterPeriodosRequisitos: () => {
      dispatch(actions.filterPeriodosRequisitos());
    },
    onFilterCapitalSocial: () => {
      dispatch(actions.filterCapitalSocial());
    },
    onFilterEmisorasRequisitos: clave => {
      dispatch(actions.filterEmisorasRequisitos(clave));
    },
    onFilterRequisitosByEmisora: params => {
      dispatch(actions.filterRequisitosByEmisora(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterRequisitosMantenimiento);

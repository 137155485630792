import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../stores/actions/index";
import classes from "./OperacionBox.css";
import Charts from "../charts/Charts";
import Boton from "../boton/Boton";
import { FormattedHTMLMessage } from "react-intl";

import {
  formatNumber,
  formatDateDiaMes,
  getMonth,
  setColorIndice,
  setFormatPercent,
  setSigno,
  formatDecimal,
  getLan,
  getUrlDocument,
  formatInt
} from "../../../stores/utils/utilities";

import linea from "../../../assets/icons/linea.svg";

class OperacionBox extends Component {
    state = {
        isin_selected: "",
        key_selected: ""
    }
  componentDidUpdate(prevProps) {
    if (this.props.emisoraId !== prevProps.emisoraId && this.props.emisoraId !== null) {
      if (this.props.emisoraId !== null && this.props.emisoraId !== "") {
        let id = this.props.emisoraId.id;
        let clave = this.props.emisoraId.clave;
        if (id !== "null" && id !== "") {
            if (this.state.isin_selected.startsWith("MX")) {
                this.props.history.push(
                  `/empresas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${id}&canceladas=null&biva=null&page=1`
                );
            }else{
                const claveEmisora_split = this.state.key_selected.split(".");
                this.props.history.push(
                    `/empresas/emisoras_inscritas/sic?tipoValor=null&mercadoPrincipal=null&tab=0&subTab=0&clave=${clave}&emisora=${id}&page=1&tipoDerecho=null&fechaInicio=null&fechaFin=null`
                  );
            }
        }
      }
    }
    if (this.props.showModal !== prevProps.showModal) {
      if (this.props.showModal !== null) {
        let split_showModal = this.props.showModal.split(",");
        if (split_showModal[0] === "true") {
          window.$("#modal").modal("show");
        }
      }
    }
  }

  onEmisoraPerfil(event, isin, key) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({isin_selected: isin, key_selected: key})

    this.props.onEmisoraId(isin);
  }
  componentDidMount() {}
  render() {
    let boxes = null;
    if (this.props.es_indice && this.props.data && this.props.tipo === 1) {
      const box = this.props.data;
     boxes = box.map((item_indice, idx_) =>  {
        let ruta = null;
        if (this.props.urlDescarga) {
          ruta = getUrlDocument(this.props.urlDescarga[0].url);
          if (ruta.includes("/cms")) {
            if (ruta.includes("?")) {
              ruta = `${ruta}&download=true`;
            }else{
              ruta = `${ruta}?download=true`;
            }
          }
        }
        let min = 0;
        let max = 0;
        let previo = 0;
  
        if (item_indice.low) {
          min = item_indice.low;
        }
        if (item_indice.high) {
          max = item_indice.high;
        }
        if (item_indice.open) {
          previo = item_indice.open;
        }
  
        return (
          <div className={classes.boxContainerIndice} key={"indice_"+idx_}>
            <div className={["row", classes.separacion_titulo].join(" ")}>
              <span className="col-12 nunito texto_30 regular color_azul">
                  {item_indice.displayName}
              </span>
            </div>
            <div className={["row", classes.separacion].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                <FormattedHTMLMessage
                  id="informacionmercado.inidice.informacion.subtitulo.nuestrosindices.ftsebiva.ultimo"
                  defaultMessage=" "
                />
              </span>
              <span className="col-12 nunito texto_24 bold">{item_indice.lastPrice}</span>
            </div>
            <div className={["row", classes.separacion].join(" ")}>
              <div className="col-6 col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    <FormattedHTMLMessage
                      id="informacionmercado.inidice.informacion.subtitulo.nuestrosindices.ftsebiva.varpct"
                      defaultMessage=" "
                    />
                  </span>
                  <span
                    className={["col-12 nunito texto_30 regular color_verde", setColorIndice(item_indice.percentageOfChange)].join(" ")}
                  >
                    {" "}
                    {setSigno(item_indice.percentageOfChange)}
                    {setFormatPercent(item_indice.percentageOfChange)}
                  </span>
                </div>
              </div>
              <div className="col-6 col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    <FormattedHTMLMessage
                      id="informacionmercado.inidice.informacion.subtitulo.nuestrosindices.ftsebiva.var"
                      defaultMessage=" "
                    />
                  </span>
                  <span className={["col-12 nunito texto_30 regular color_verde", setColorIndice(item_indice.netChange)].join(" ")}>
                    {setSigno(item_indice.netChange)}
                    {item_indice.netChange}
                  </span>
                </div>
              </div>
            </div>
            <div className={["row", classes.separacion].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                <FormattedHTMLMessage
                  id="informacionmercado.inidice.informacion.subtitulo.nuestrosindices.ftsebiva.previo"
                  defaultMessage=" "
                />
              </span>
              <span className="col-12 nunito texto_24 bold">{previo}</span>
            </div>
            <div className={["row", classes.separacion].join(" ")}>
              <div className="col-6 col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    <FormattedHTMLMessage
                      id="informacionmercado.inidice.informacion.subtitulo.nuestrosindices.ftsebiva.max"
                      defaultMessage=" "
                    />
                  </span>
                  <span className="col-12 nunito texto_24 bold">{max}</span>
                </div>
              </div>
              <div className="col-6 col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    <FormattedHTMLMessage
                      id="informacionmercado.inidice.informacion.subtitulo.nuestrosindices.ftsebiva.min"
                      defaultMessage=" "
                    />
                  </span>
                  <span className="col-12 nunito texto_24 bold">{min}</span>
                </div>
              </div>
            </div>
          </div>
        );

      })


    } else if (this.props.es_indice && this.props.data && this.props.tipo === 2) {
      //cotizaciones emisoras
      boxes = this.props.data.map((box, i) => {
        let indicadores = [];
        let porcentaje = 0;

        let ruta = null;
        if (this.props.urlDescarga) {
          ruta = this.props.urlDescarga[0].url;
          let liga_pagina = false;
          if (ruta.includes("http")) {
            liga_pagina = true;
          }

          if (!liga_pagina) {
            ruta = getUrlDocument(this.props.urlDescarga[0].url);
          } else {
            ruta = this.props.urlDescarga[0].url;
          }
        }
        let min = 0;
        let max = 0;
        let previo = 0;
        let negativo = false;

        if (box.low) {
          min = box.low;
        }
        if (box.high) {
          max = box.high;
        }
        if (box.open) {
          previo = box.open;
        }

        indicadores = [
          ["Nombre", "Participación"],
          ["Participación", parseFloat(box.percentageOfChange ? box.percentageOfChange : 0)],
          ["", 100 - parseFloat(box.percentageOfChange ? box.percentageOfChange : 0)]
        ];

        if (box.percentageOfChange) {
          if (box.percentageOfChange < 0) {
            negativo = true;
            indicadores = [
              ["Nombre", "Participación"],
              ["Participación", 100 - parseFloat(box.percentageOfChange ? box.percentageOfChange : 0) * -1],
              ["", parseFloat(box.percentageOfChange ? box.percentageOfChange * -1 : 0)]
            ];
          }
        }
        porcentaje = box.percentageOfChange ? formatNumber(box.percentageOfChange) : 0;
        let isin = null;
        let key_item = box.key;
        if (box.isin) {
          isin = box.isin;
        }
        return (
          <div key={"box" + i} className={classes.boxContainer8}>
            <div className={["row", classes.separacion].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                {getLan() === "es" ? "Emisora" : "Issuer"}
              </span>
              <span className="col-12 nunito texto_24 bold">{box.displayName}</span>
            </div>
            <div className={["row", classes.separacion].join(" ")}>

          <div className="col-12 sin_padding_L">
              <div className="row">
                <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                  {getLan() === "es" ? "Importe" : "Amount"}
                </span>
                <span className="col-12 nunito texto_24 bold">{box.accumulatedAmount ? formatDecimal(box.accumulatedAmount) : formatDecimal(0)}</span>
              </div>
            </div>
          </div>
            <div className={["row", classes.separacion].join(" ")}>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {getLan() === "es" ? "Último" : "Last"}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{formatDecimal(box.lastPrice)}</span>
                </div>
              </div>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {getLan() === "es" ? "Previo" : "Prev"}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{formatDecimal(previo)}</span>
                </div>
              </div>
            </div>

            <div className={["row", classes.separacion].join(" ")}>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {getLan() === "es" ? "Máximo" : "Max"}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{formatDecimal(max)}</span>
                </div>
              </div>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {getLan() === "es" ? "Mínimo" : "Min"}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{formatDecimal(min)}</span>
                </div>
              </div>
            </div>

            <div className={["row", classes.separacion].join(" ")}>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {getLan() === "es" ? "Volumen" : "Volume"}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{ box.volume  ? formatInt(box.volume) : 0}</span>
                </div>
              </div>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {getLan() === "es" ? "OPS" : "OPS"}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{box.operations ? formatInt(box.operations) : formatInt(0) }</span>
                </div>
              </div>
            </div>
           

            <div className={["row", classes.separacion].join(" ")}>
              
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {getLan() === "es" ? "Variación neta" : "Net Var"}
                  </span>
                  <span className="col-12 nunito texto_24 bold">
                    {setSigno(box.netChange)}
                    {formatDecimal(box.netChange)}
                  </span>
                </div>
              </div>
            </div>

            <div className={["row", indicadores ? null : "d-none"].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.titulo_grafica].join(" ")}>
                {getLan() === "es" ? " Variación %" : "Var %"}
              </span>
              <div className="col-12">
                <table className={classes.charts}>
                  <tbody>
                    <tr>
                      <td className="donutCell">
                        <div id="donutchart1" className="donutDiv">
                          <Charts
                            graph_id={"grafica1"}
                            data={indicadores}
                            tieneFiltro={false}
                            es_operacion={true}
                            es_negativo={negativo}
                          />
                        </div>
                        <div className="centerLabel">{porcentaje}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className={classes.descarga2}>
              <div className={"row"}>
                <div className={classes.buttonContainer}>
                  <div onClick={event => this.onEmisoraPerfil(event, isin, key_item)}>
                    <span className="montse texto_16">
                      {getLan() === "es" ? "Consultar perfil" : "View profile"}
                      <i className="fas fa-long-arrow-alt-right" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.props.data && this.props.data.length > 0) {
      boxes = this.props.data.map((box, i) => {
        let claseBox = classes.boxContainer;
        let indicadores = [];
        let porcentaje = null;
        let titulo = null;
        let lugar = null;
        let descarga = null;
        let imagen = null;
        let tituloHechos = null;
        let tituloVolumen = null;
        let hechos = null;
        let volumen = null;
        let volumen_tipo4 = null;
        let importe = null;
        let fecha = null;
        let tituloEmisora = null;

        if (this.props.tipo === 1) {
          titulo = getLan() === "es" ? "Operación" : "Trading";
          tituloHechos = getLan() === "es" ? "Hechos" : "Trades";
          tituloVolumen = getLan() === "es" ? "Volumen" : "Volume";
          hechos = formatNumber(box.hechos);
          volumen = formatNumber(box.volumen);
          indicadores = [
            ["Nombre", "Participación"],
            ["Participación", parseFloat(box.participacion)],
            ["", 100 - parseFloat(box.participacion)]
          ];
          porcentaje = box.participacion;
          importe = formatNumber(box.importe);

          let ruta = null;
          if (this.props.urlDescarga) {
            ruta = this.props.urlDescarga;
          }

          descarga = (
            <div className={classes.descarga}>
              <a href={ruta}  rel="noopener noreferrer">
                <div className={[classes.fondo, classes.vertical_div].join(" ")}>
                  <div className={[classes.centrado, classes.vertical].join(" ")}>
                    <div className={["row", classes.texto_descarga].join(" ")}>
                      <span className={["color_azul montse texto_16 regular col-12"].join(" ")}>
                        {getLan() === "es" ? "Ver reporte de cierre diario" : "See daily closeout report"}
                      </span>
                      <span className={"montse texto_10 semi_bold col-12 color_gris"}>{getLan() === "es" ? "Formato XLS" : "XLS format"} </span>
                    </div>
                    <div className={classes.boton_descarga}>
                      <i className="fas fa-arrow-alt-circle-down fa-lg" />
                    </div>
                  </div>
                </div>
              </a>
            </div>
          );
        }

        if (this.props.tipo === 2) {
          indicadores = [
            ["Nombre", "Participación"],
            ["participacionDeMercado", parseFloat(box.participacionDeMercado)],
            ["", 100 - parseFloat(box.participacionDeMercado)]
          ];

          lugar = box.posicion;
          porcentaje = formatNumber(box.participacionDeMercado);
          imagen = null;
          tituloHechos = getLan() === "es" ? "Hechos totales" : "Total trades";
          tituloVolumen = getLan() === "es" ? "Clave corta" : "Short Id";
          hechos = formatNumber(box.hechos);
          volumen = box.casaDeBolsa;
          importe = formatNumber(box.importe);

          if (this.props.top) {
            if (lugar > this.props.top) {
              return null;
            }
          }
        }

        if (this.props.tipo === 3) {
          claseBox = classes.boxContainer2;
          indicadores = [
            ["Nombre", "Participación"],
            ["Participación", parseFloat(box.participacion)],
            ["", 100 - parseFloat(box.participacion)]
          ];

          porcentaje = box.participacion;
          imagen = null;
          tituloHechos = getLan() === "es" ? "Lugar" : "Place";
          tituloVolumen = getLan() === "es" ? "Emisora" : "Issuer";
          hechos = box.posicion;
          volumen = box.emisora;
          importe = formatNumber(box.importe);

          if (this.props.top) {
            if (+box.posicion > this.props.top || box.posicion === undefined) {
              return null;
            }
          }
        }

        if (this.props.tipo === 4) {
          //ultimo mes
          claseBox = classes.boxContainer3;
          indicadores = [
            ["Nombre", "Participación"],
            ["Participación", parseFloat(box.participacion)],
            ["", 100 - parseFloat(box.participacion)]
          ];

          porcentaje = box.participacion;
          imagen = null;
          tituloHechos = getLan() === "es" ? "Fecha" : "Date";
          tituloVolumen = getLan() === "es" ? "Hechos" : "Trades";

          let fecha_x = box.fecha;
          fecha_x = fecha_x.split("-");
          let dia = fecha_x[0];
          let mes = getMonth(fecha_x[1]);
          let date = new Date();
          date.setFullYear(date.getFullYear(), parseInt(mes) - 1, parseInt(dia));

          hechos = formatDateDiaMes(date);
          // hechos = box.fecha;
          volumen = formatNumber(box.hechos);
          importe = formatNumber(box.importe);
          volumen_tipo4 = formatNumber(box.volumen);

          if (this.props.top) {
            if (+box.posicion > this.props.top || box.posicion === undefined) {
              return null;
            }
          }
        }

        if (this.props.tipo === 5) {
          // ultimo mes casa bolsa
          claseBox = classes.boxContainer2;

          indicadores = [
            ["Nombre", "Participación"],
            ["participacionDeMercado", parseFloat(box.porcentaje)],
            ["", 100 - parseFloat(box.porcentaje)]
          ];

          lugar = null;
          porcentaje = box.porcentaje;
          imagen = null;
          tituloHechos = getLan() === "es" ? "Lugar" : "Place";
          tituloVolumen = getLan() === "es" ? "Clave corta" : "Short key";
          hechos = box.lugar;
          volumen = box.casaDeBolsa;
          importe = formatNumber(box.importeOperado);

          if (box.lugar === undefined) {
            return null;
          }
        }

        if (this.props.tipo === 6) {
          // bloques
          claseBox = classes.boxContainer4;
          indicadores = null;

          volumen_tipo4 = formatNumber(box.volumen);
          importe = formatNumber(box.importe);
        }

        if (this.props.tipo === 7) {
          // cruces
          claseBox = classes.boxContainer5;
          indicadores = null;

          volumen_tipo4 = formatNumber(box.volumen);
          importe = formatNumber(box.importe);

          let fecha_x = box.fecha;
          fecha_x = fecha_x.split("-");
          let dia = fecha_x[0];
          let mes = getMonth(fecha_x[1]);
          let date = new Date();
          date.setFullYear(date.getFullYear(), parseInt(mes) - 1, parseInt(dia));

          fecha = formatDateDiaMes(date);

          // fecha = box.fecha
        }

        return (
          <div className={claseBox} key={"box" + i}>
            <div className={["row", classes.separacion, titulo ? null : "d-none"].join(" ")}>
              <span className="col-12 nunito texto_30 regular">{titulo}</span>
            </div>
            {tituloEmisora}
            {imagen}
            <div className={["row", classes.separacion, lugar ? null : "d-none"].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                {getLan() === "es" ? "Lugar" : "Place"}
              </span>
              <span className="col-12 nunito texto_24 bold">{lugar}</span>
            </div>
            <div className={["row", classes.separacion, tituloHechos ? null : "d-none"].join(" ")}>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {tituloHechos}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{hechos}</span>
                </div>
              </div>
              <div className="col-sm-6 sin_padding_L">
                <div className="row">
                  <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                    {tituloVolumen}
                  </span>
                  <span className="col-12 nunito texto_24 bold">{volumen}</span>
                </div>
              </div>
            </div>
            <div className={["row", classes.separacion, this.props.tipo === 7 ? null : "d-none"].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                {getLan() === "es" ? "Fecha" : "Date"}
              </span>
              <span className="col-12 nunito texto_24 bold">{fecha}</span>
            </div>
            <div
              className={[
                "row",
                classes.separacion,
                this.props.tipo === 4 || this.props.tipo === 6 || this.props.tipo === 7 ? null : "d-none"
              ].join(" ")}
            >
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                {getLan() === "es" ? "Volumen" : "Volume"}
              </span>
              <span className="col-12 nunito texto_24 bold">{volumen_tipo4}</span>
            </div>
            <div className={["row", classes.separacion].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.separacion_titulo].join(" ")}>
                {getLan() === "es" ? "Importe" : "Amount"}
              </span>
              <span className="col-12 nunito texto_24 bold">{importe}</span>
            </div>
            <div className={["row", indicadores ? null : "d-none"].join(" ")}>
              <span className={["col-12 color_azul nunito texto_16 semi_bold", classes.titulo_grafica].join(" ")}>
                {getLan() === "es" ? "Participación de mercado %" : "Market share %"}
              </span>
              <div className="col-12">
                <table className={classes.charts}>
                  <tbody>
                    <tr>
                      <td className="donutCell">
                        <div id="donutchart1" className="donutDiv">
                          <Charts graph_id={"grafica1"} data={indicadores} tieneFiltro={false} es_operacion={true} />
                        </div>
                        <div className="centerLabel">{porcentaje}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {descarga}
          </div>
        );
      });
    }

    const modal = (
      <div
        className="modal fade bd-example-modal-lg"
        id={"modal"}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="margen_top_30 text-center">
                <span className="nunito texto_22">No encontramos ninguna emisión</span>
              </div>
              <div className={"float-right margen_top_50"}>
                <Boton
                  tipo={4}
                  texto={"Cerrar"}
                  icono_derecho="fas fa-long-arrow-alt-right"
                  colorBoton={""}
                  dataDismiss={"modal"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className={this.props.es_indice ? null : classes.contenedor_principal}>{boxes}</div>

        {modal}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onEmisoraId: isin => {
      dispatch(actions.initEmisoraId(isin));
    }
  };
};

const mapStateToProps = state => {
  let showModal = null;
  let emisoraId = null;
  if (state.informacionMercado.emisoraId !== null) {
    let time = +new Date();
    const emisora_split = state.informacionMercado.emisoraId;
    emisoraId = state.informacionMercado.emisoraId;
    if (emisora_split[0] === "null") {
      showModal = true + "," + time;
    } else {
      showModal = false + "," + time;
    }
  }
  state.informacionMercado.emisoraId = null;
  return {
    emisoraId: emisoraId,
    showModal: showModal
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OperacionBox)
);

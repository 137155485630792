import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import Accordion from "../../../components/ui/accordion/Accordion";
import { getLocal } from "../../../stores/utils/utilities";

class Estrategicas extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onDocumentos();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    const item = <Accordion datos={this.props.alianzas} nombre="estrategias" icon={""} tipo={5} />;
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={"texto_42 nunito regular"}>
          <FormattedMessage id="alianzas.alianzasEstrategicas.titulo" defaultMessage=" " />
            
          </h1>
          <div className="margen_top_50 margin_bottom_200">{item}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    alianzas: state.documentos.alianzas
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDocumentos: () => {
      dispatch(actions.initAlianzasDocumentos());
      dispatch(actions.initIntl(2));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Estrategicas);

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

const setTipoInformacion = data => {
  return {
    type: actionTypes.FETCH_TIPOINFORMACION_SUCCESS,
    tipo_informacion: data
  };
};

export const filterTipoInformacion = (emisoraId) => {
  return dispatch => {
    axios
      .get(`${dominio}/emisoras/empresas/${emisoraId}/tipo-informacion`)
      .then(response => {
        dispatch(setTipoInformacion(response.data));
      })
      .catch(err => console.log(err));
  };
};


const setAllTipoInformacion = data => {
  return {
    type: actionTypes.FETCH_ALLTIPOINFORMACION_SUCCESS,
    tipos_informacion: data
  };
};

export const initAllTipoInformacion = () => {
  return dispatch => {
    axios
      .get(`${dominio}/emisoras/tipo-informacion`)
      .then(response => {
        dispatch(setAllTipoInformacion(response.data));
      })
      .catch(err => console.log(err));
  };
};


const setTipoDocumento = data => {
  return {
    type: actionTypes.FETCH_TIPODOCUMENTO_TIPOINFORMACION_SUCCESS,
    tipos_documentos: data
  };
};

export const initTipoDocumentoByTipoInformacion = (tipoInfoId) => {
  return dispatch => {
    axios
      .get(`${dominio}/emisoras/tipo-informacion/${tipoInfoId}/tipo-documento`)
      .then(response => {
        dispatch(setTipoDocumento(response.data));
      })
      .catch(err => console.log(err));
  };
};
import React, { Component } from "react";
import classes from "./FilterInformacionCorporativaFondo.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { changeDateFormat } from "../../../stores/utils/utilities";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import { getLan } from "../../../stores/utils/utilities";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiple = null,
  options,
  defaultValue = null,
  checked = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes && elementType === "checkbox" ? classes : [classes, "form-control"].join(" "),
      ref: ref,
      disabled: disabled,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };

  return (
    <div className="form-group">
      {label && input.type !== "radio" && input.type !== "checkbox" ? (
        <label for={input.name} className={["texto_14", "montse", "regular"].join(" ")}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterInformacionCorporativaFondo extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    toggleFilters: true,
    disableEnableButtons: true,
    selectedFilters: {
      clave_operadora: [],
      clave_fondo: [],
      tipo_documento: [],
      fechas: [],
      periodo: [],
      ejercicio: []
    },
    form_filters: {
      clave_operadora: {
        value: ""
      },
      clave_fondo: {
        value: ""
      },
      tipo_documento: {
        value: []
      },
      fecha_inicio: {
        value: ""
      },
      fecha_fin: {
        value: ""
      },
      periodo: {
        value: ""
      },
      ejercicio: {
        value: ""
      },
      errorDate: false
    },
    inputPivot: false,
    periodoShow: false,
    ejercicioShow: false
  };

  componentDidMount() {
    this.onFilterClaveOperadoraHandler();
    this.onFilterClaveFondoHandler();
    this.onFilterTipoDocumentoHandler();
  }

  onFilterClaveOperadoraHandler = (claveSiefore = null, tipoDocumento = null) => {
    let params = {
      empresa: claveSiefore,
      tipoDocumento: tipoDocumento
    };
    this.props.onFilterClaveOperadora(params);
  };

  onFilterClaveFondoHandler = (claveAfore = null, tipoDocumento = null) => {
    let params = {
      empresaAsociada: claveAfore,
      tipoDocumento: tipoDocumento
    };
    this.props.onFilterClaveFondo(params);
  };

  onFilterTipoDocumentoHandler = (claveAfore = null, claveSiefore = null) => {
    let params = {
      empresaAsociada: claveAfore,
      empresa: claveSiefore
    };
    this.props.onFilterTipoDocumentos(params);
  };

  onFilterPeriodoHandler = (tipoDocumento = null, ejercicio = null) => {
    let params = {
      tipoDocumento: tipoDocumento,
      ejercicio: ejercicio
    };
    this.props.onFilterPeriodo(params);
  };

  onFilterEjercicioHandler = (tipoDocumento = null, periodo = null) => {
    let params = {
      tipoDocumento: tipoDocumento,
      periodo: periodo
    };
    this.props.onFilterEjercicio(params);
  };

  printFrameHandler = () => {
    const input = this.showFiltersSelectedHandler();
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col-4 col-sm-3", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar filtros"
                        : "Close"
                      : getLan() === "es"
                        ? "Filtrar"
                        : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div className={["col", "col-lg-9", classes.no_col_padding_model_one].join(" ")}>
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.printModel()}</div>
          </div>
        </div>
      </div>
    );
  };

  printModel = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              clave_operadora: this.state.form_filters.clave_operadora.value,
              clave_fondo: this.state.form_filters.clave_fondo.value,
              tipo_documento: this.state.form_filters.tipo_documento.value,
              fecha_inicio: this.state.form_filters.fecha_inicio.value,
              fecha_fin: this.state.form_filters.fecha_fin.value,
              periodo: this.state.form_filters.periodo.value,
              ejercicio: this.state.form_filters.ejercicio.value
            }}
            render={({
              optionsClaveOperadora = this.props.clave_operadora ? this.onPrepareOptionHandler(this.props.clave_operadora) : [],
              optionsClaveFondo = this.props.clave_fondo ? this.onPrepareOptionHandler(this.props.clave_fondo) : [],
              optionsDocumento = this.props.tipo_documento ? this.onPrepareOptionHandler(this.props.tipo_documento) : [],
              optionsPeriodo = this.props.periodo ? this.onPrepareOptionHandler(this.props.periodo) : [],
              optionsEjercicio = this.props.ejercicio ? this.onPrepareOptionHandler(this.props.ejercicio) : [],
              form
            }) => (
                <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <Field
                        name="clave_operadora"
                        id="clave_operadora"
                        label={getLan() === "es" ? "Clave de operadora" : "Operator "}
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        options={optionsClaveOperadora}
                        onInputChange={event => this.onInputSelectedHandler(event, 0)}
                        component={renderInput}
                      />
                    </div>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-4"].join(" ")}>
                      <Field
                        name="clave_fondo"
                        id="clave_fondo"
                        label={getLan() === "es" ? "Clave de fondo" : "Ticker fund"}
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        options={optionsClaveFondo}
                        onInputChange={event => this.onInputSelectedHandler(event, 1)}
                        component={renderInput}
                      />
                    </div>
                    <div className={["col-12 col-sm-12 col-md-12 col-lg-4 sin_padding"].join(" ")}>
                      <div className="row">
                        <div className={["col", "col-lg-6"].join(" ")}>
                          <Field
                            name="fecha_inicio"
                            id="fecha_inicio"
                            type="date"
                            classes={classes.fechaInicio}
                            label={getLan() === "es" ? "Fecha inicio" : "From"}
                            onInputChange={event => this.onInputSelectedHandler(event, 3)}
                            component={renderInput}
                          />
                        </div>
                        <div className={["col", "col-lg-6"].join(" ")}>
                          <Field
                            name="fecha_fin"
                            id="fecha_fin"
                            type="date"
                            classes={classes.fechaFin}
                            label={getLan() === "es" ? "Fecha fin" : "To"}
                            onInputChange={event => this.onInputSelectedHandler(event, 4)}
                            component={renderInput}
                          />
                        </div>
                        <div
                          className={[classes.errorContainer, !this.state.form_filters.errorDate ? classes.hidden : null].join(
                            " "
                          )}
                        >
                          <p className={"texto_14 montse regular"}>
                            {getLan() === "es"
                              ? "La fecha final debe ser mayor a la fecha inicial."
                              : "End date must be greater than start date."}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={[
                        "col-12 col-sm-12 col-md-6 col-lg-4",
                        optionsDocumento !== null && optionsDocumento.length !== undefined && optionsDocumento.length > 0
                          ? null
                          : "d-none"
                      ].join(" ")}
                    >
                      {/* <span className="montse texto_14 regular color_negro">
                        {getLan() === "es" ? "Tipo de documento" : "Document type"}
                      </span>
                      {optionsDocumento.map(est => {
                        return <div className={["row", classes.margin_check].join(" ")}>{this.onPrintTipoDocumentos(est)}</div>;
                      })} */}
                      <Field
                        name="tipo_documento"
                        id="tipo_documento"
                        label={getLan() === "es" ? "Tipo de documento" : "Document type"}
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        options={optionsDocumento}
                        onInputChange={event => this.onInputSelectedHandler(event, 2)}
                        component={renderInput}
                      />
                    </div>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-4", !this.state.periodoShow ? classes.hidden : null].join(" ")}>
                      <Field
                        name="periodo"
                        id="periodo"
                        label={getLan() === "es" ? "Periodo" : "Period"}
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        options={optionsPeriodo}
                        onInputChange={event => this.onInputSelectedHandler(event, 5)}
                        component={renderInput}
                      />
                    </div>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-4", !this.state.ejercicioShow ? classes.hidden : null].join(" ")}>
                      <Field
                        name="ejercicio"
                        id="ejercicio"
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        label={getLan() === "es" ? "Año" : "Year"}
                        options={optionsEjercicio}
                        onInputChange={event => this.onInputSelectedHandler(event, 6)}
                        component={renderInput}
                      />
                    </div>

                  </div>

                  <hr />
                  {this.sectionButtonHandler(form)}
                </form>
              )}
          />
        </div>
      </div>
    );
  };

  // onPrintTipoDocumentos = tipo => {
  //   const form = this.state.form_filters;
  //   const optionValue = `${tipo.id}_${tipo.nombre}`;


  //   return (
  //     <Field
  //       name="tipo_documento"
  //       id={tipo.nombre}
  //       type="checkbox"
  //       elementType="checkbox"
  //       value={optionValue}
  //       classes="form-check-input"
  //       label={tipo.nombre}
  //       checked={form.tipo_documento.value.find(item => item === optionValue)}
  //       onInputChange={event => this.onInputSelectedHandler(event, 2)}
  //       component={renderInput}
  //     />
  //   );
  // };

  sectionButtonHandler = form => {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterHandler()}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Verifica si el valor recibido ya sea de tipo de documento
  // pertenesca a unos de los ids en "idsTipoInformacion" o idsTiposDocumento
  onCheckIfIdsExists = (value) => {
    let showPeriodo = false;
    let showEjercicio = false;

    // NINGUNO
    // {id: "89,38", nombre: "Eventos relevantes"}
    // id: "163", nombre: "Otra información"}
    // EJERCICIO
    // {id: "82", nombre: "Información financiera anual"}
    // PERIODO Y EJERCICIO
    // {id: "164", nombre: "Estados financieros trimestrales"} 
    // {id: "145", nombre: "Información para el público inversionista"}
    // {id: "146", nombre: "Información para el público inversionista por serie"}
    // {id: "19001", nombre: "Operaciones de compra-venta de activos objeto de inversión"}
    // {id: "19002", nombre: "Cartera de inversión"}
    // {id: "19003", nombre: "Precio actualizado de valuación de las acciones"}

    if (["164", "145", "146", "19001", "19002", "19003"].includes(value)) {
      showPeriodo = true;
      showEjercicio = true;
    } else if (["82"].includes(value)) {
      showPeriodo = false;
      showEjercicio = true;
    }
    else {
      showPeriodo = false;
      showEjercicio = false;
    }
    return [showPeriodo, showEjercicio];
  };

  // Permite ocultar o mostrar la seccion del formulario del filtro
  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onSubmitFilterHandler = event => {
    event.preventDefault();
  };

  // Limpia le formulario
  onResetFilterHandler = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    // Limpiando el filtro clave_operadora
    form.clave_operadora.value = "";
    selectedFilters.clave_operadora = [];
    // Limpiando el filtro clave_fondo
    form.clave_fondo.value = "";
    selectedFilters.clave_fondo = [];
    // Limpiando el filtro tipo_documento
    form.tipo_documento.value = [];
    selectedFilters.tipo_documento = [];
    // Limpiando el filtro fecha_inicio y fecha_fin
    form.fecha_inicio.value = "";
    form.fecha_fin.value = "";
    selectedFilters.fechas = [];
    // Se limpia errorData para validacion de fechas
    form.errorDate = false;
    // Limpiando el filtro periodo
    form.periodo.value = "";
    selectedFilters.periodo = [];
    // Limpiando el filtro ejercicio
    form.ejercicio.value = "";
    selectedFilters.ejercicio = [];

    let inputPivot = false;

    this.onFilterClaveOperadoraHandler();
    this.onFilterClaveFondoHandler();
    this.onFilterTipoDocumentoHandler();


    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      inputPivot: inputPivot,
      periodoShow:false,
      ejercicioShow:false

    });

    this.onFilterDataByEvent();
  };

  // Formatea los valores de los campos select
  onPrepareOptionHandler = type => {
    let options = [];
    if (type && type.length > 0) {
      type.forEach(element => {
        options.push({
          nombre: element.nombre,
          id: `${element.id}_${element.nombre}`
        });
      });
    }
    return options;
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type) => {
    let newValue = null;
    let newLabel = null;

    if (type !== 3 && type !== 4) {
      newValue = value.split("_")[0];
      newLabel = value.split("_")[1];
    } else {
      // Fechas inicio o fin
      [newValue] = changeDateFormat(value);
      newLabel = type === 3 ? "fecha_inicio" : "fecha_fin";
    }

    return [newValue, newLabel];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["clave_operadora", form.clave_operadora.value, selectedFilters.clave_operadora];
      case 1:
        return ["clave_fondo", form.clave_fondo.value, selectedFilters.clave_fondo];
      case 2:
        return ["tipo_documento", form.tipo_documento.value, selectedFilters.tipo_documento];
      case 3:
        return ["fecha_inicio", form.fecha_inicio.value, selectedFilters.fechas, "fechas"];
      case 4:
        return ["fecha_fin", form.fecha_fin.value, selectedFilters.fechas, "fechas"];
      case 5:
        return ["periodo", form.periodo.value, selectedFilters.periodo];
      case 6:
        return ["ejercicio", form.ejercicio.value, selectedFilters.ejercicio];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor del campo clave operadora
    const valuesClaveOperadora =
      form.clave_operadora.value && form.clave_operadora.value !== ""
        ? this.onSplitValueHandler(form.clave_operadora.value)
        : "";
    // Se obtiene el valor del campo clave fondo
    const valuesClaveFondo =
      form.clave_fondo.value && form.clave_fondo.value !== "" ? this.onSplitValueHandler(form.clave_fondo.value) : "";
    // Se obtiene el valor del campo tipo documento
    const valuesTipoDocumento =
      form.tipo_documento.value && form.tipo_documento.value.length > 0
        ? this.onSplitValueHandler(form.tipo_documento.value)
        : [];
    // Se obtiene el valor del campo fecha inicio
    const valuesFechaInicio = form.fecha_inicio.value && form.fecha_inicio.value !== "" ? form.fecha_inicio.value : "";
    // Se obtiene el valor del campo fecha fin
    const valuesFechaFin = form.fecha_fin.value && form.fecha_fin.value !== "" ? form.fecha_fin.value : "";

    // Se obtiene el valor del periodo
    const valuesPeriodo = form.periodo.value && form.periodo.value !== "" ? this.onSplitValueHandler(form.periodo.value) : "";
    // Se obtiene el valor del ejercicio
    const valuesEjercicio = form.ejercicio.value && form.ejercicio.value !== "" ? this.onSplitValueHandler(form.ejercicio.value) : "";

    return [valuesClaveOperadora, valuesClaveFondo, valuesTipoDocumento, valuesFechaInicio, valuesFechaFin, valuesPeriodo, valuesEjercicio];
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    // if (type !== 2) {
    if (values && values !== "") {
      newStateValues = values;
    }
    // } else {
    //   newStateValues = [];
    //   newStateValues = [...inputOptions];
    //   const stateExists = newStateValues.find(option => option === values);
    //   if (stateExists) {
    //     newStateValues = newStateValues.filter(option => option !== values);
    //   } else {
    //     if (values && values !== "") {
    //       newStateValues.push(values);
    //     } else {
    //       newStateValues = [];
    //     }
    //   }
    // }

    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];
    if (type === 0 || type === 1 || type === 2 || type === 5 || type === 6) {
      if (values && values !== "") {
        newFilterValues.push({
          label: label,
          value: values,
          type: type
        });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(option => option.label === label);
      if (filterExists) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
        if (values && values !== "" && (type === 3 || type === 4)) {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      } else {
        if (values && values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      }
    }

    return newFilterValues;
  };

  onFilterDataByEvent = () => {
    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveOperadora,
      valuesClaveFondo,
      valuesTipoDocumento,
      valuesFechaInicio,
      valuesFechaFin,
      valuesPeriodo,
      valuesEjercicio
    ] = this.onGetAllValuesFormFilter();

    const params = {
      size: this.props.itemsPerPage,
      page: 1,
      claveOperadora: valuesClaveOperadora,
      claveFondo: valuesClaveFondo,
      tipoDocumento: valuesTipoDocumento,
      fechaInicio: valuesFechaInicio,
      fechaFin: valuesFechaFin,
      ejercicio: valuesEjercicio,
      periodo: valuesPeriodo
    };

    this.props.setFilters(params);


    /* this.props.setPage(1); */

    let wasFiltered = false;
    if (!this.state.form_filters.errorDate) {
      // Se invoca la funcion del componente padre (ConcentradoPreciosAfores.js) para setear los valores correspondientes
      // Configurando valor del campo clave_operadora
      this.props.onSetClaveOperadora(valuesClaveOperadora);
      // Configurando valor del campo clave_fondo
      this.props.onSetClaveFondo(valuesClaveFondo);
      // Configurando valor del campo tipo_documento
      this.props.onSetTipoDocumento(valuesTipoDocumento);
      // Configurando valor del campo fecha_inicio
      this.props.onSetFechaInicio(valuesFechaInicio);
      // Configurando valor del campo fecha_fin
      this.props.onSetFechaFin(valuesFechaFin);
      if (
        (valuesClaveOperadora !== "" || valuesClaveFondo !== "" || valuesTipoDocumento !== "" || valuesFechaInicio !== "",
          valuesFechaFin !== "" || valuesEjercicio !== "" || valuesPeriodo !== "")
      ) {
        wasFiltered = true;
      }
      this.props.onSetWasFiltered(wasFiltered);
    }
  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (event = null, type = null, item = null, eraser = null) => {
    let actualValue = eraser ? event : event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let inputPivot = this.state.inputPivot;
    let errorDate = form.errorDate;

    let newValue = null;
    let newLabel = null;

    if (eraser) {
      // actualValue = item.type === 2 ? item.value : "";
      actualValue = "";
      newValue = "";
      newLabel = item.label;
      type = item.type;
    } else {
      // Solo para los campos distintos a tipo instrumento y tipo valor
      [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue, type);
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(filterValues, actualValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoDocumento,
      valuesFechaInicio,
      valuesFechaFin,
      valuesPeriodo,
      valuesEjercicio
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    let periodoShow = this.state.periodoShow;
    let ejercicioShow = this.state.ejercicioShow;

    if (type === 0) {
      // Clave Operadora
      // Cuando el campo clave afore es seleccionado de primero
      if (!inputPivot || inputPivot === "firstPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
        if (eraser) {
          inputPivot = false;
        } else {
          // Se configura a null inputPivot si el valor de clave_operadora es vacio
          inputPivot = valuesClaveAfore !== "" ? "firstPath" : null;
        }
        // Limpiando valores campo clave_fondo
        form.clave_fondo.value = "";
        selectedFilters.clave_fondo = [];
        // Filtrando valores para campo clave_fondo
        this.onFilterClaveFondoHandler(valuesClaveAfore);
      } else if (inputPivot === "secondPath") {
        // Pass
      }
    } else if (type === 1) {
      // Clave Siefore
      // Cuando el campo clave_fondo es seleccionado de primero
      if (!inputPivot || inputPivot === "secondPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
        if (eraser) {
          inputPivot = false;
        } else {
          // Se configura a null inputPivot si el valor de clave_fondo es vacio
          inputPivot = valuesClaveSiefore !== "" ? "secondPath" : null;
        }
        // Limpiando valores campo clave_operadora
        form.clave_operadora.value = "";
        selectedFilters.clave_operadora = [];
        // Filtrando valores para campo clave_operadora
        this.onFilterClaveOperadoraHandler(valuesClaveSiefore);
      } else if (inputPivot === "firstPath") {
        // Pass
      }
    } else if (type === 2) {
      // Limpiando valores de periodo y ejercicio
      form.ejercicio.value = "";
      form.periodo.value = "";
      selectedFilters.periodo = [];
      selectedFilters.ejercicio = [];

      if (valuesTipoDocumento !== "") {
        [periodoShow, ejercicioShow] = this.onCheckIfIdsExists(valuesTipoDocumento);
        if (periodoShow) {
          this.onFilterPeriodoHandler(valuesTipoDocumento, null);
        }
        if (ejercicioShow) {
          this.onFilterEjercicioHandler(valuesTipoDocumento, null);
        }
      }

    } else if (type === 3) {
      // Fecha inicio
      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(actualValue, form.fecha_fin.value);
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 4) {
      //Fecha fin
      // Se verifican si las fechas son validas
      if (actualValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(form.fecha_inicio.value, actualValue);
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 5) {
      //Periodo 
      this.onFilterEjercicioHandler(valuesTipoDocumento, valuesPeriodo);
    } else if (type === 6) {
      //Ejercicio
      this.onFilterPeriodoHandler(valuesTipoDocumento, valuesEjercicio);
    }
    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      inputPivot: inputPivot,
      periodoShow: periodoShow,
      ejercicioShow: ejercicioShow
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    const selectedFilters = this.state.selectedFilters;
    const filterList = [
      ...selectedFilters.clave_operadora,
      ...selectedFilters.clave_fondo,
      ...selectedFilters.tipo_documento,
      ...selectedFilters.fechas,
      ...selectedFilters.periodo,
      ...selectedFilters.ejercicio
    ];
    let filters = null;
    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map((item, i) => (
          <div className={classes.filter_container_model_one} key={`${item.label}-${item.id}-${i}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>
              {item.type === 3 || item.type === 4 ? item.value : item.label}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onInputSelectedHandler(null, null, item, true)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  render() {
    return this.printFrameHandler();
  }
}

const mapStateToProps = state => {
  return {
    clave_operadora: state.fondos.clavesOperadora,
    clave_fondo: state.fondos.clavesFondo,
    tipo_documento: state.fondos.tiposDocumento,
    periodo: state.fondos.periodo,
    ejercicio: state.fondos.ejercicio
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterClaveOperadora: params => {
      dispatch(actions.initClavesOperadoraInformacionCorporativa(params));
    },
    onFilterClaveFondo: params => {
      dispatch(actions.initClavesFondosInformacionCorporativa(params));
    },
    onFilterTipoDocumentos: params => {
      dispatch(actions.initTipoDocumentoFondos(params));
    },
    onFilterPeriodo: params => {
      dispatch(actions.initPeriodoFondos(params));
    },
    onFilterEjercicio: params => {
      dispatch(actions.initEjercicioFondos(params));
    },
    onFilterFondos: (size, page, claveOperadora, claveFondo, tipoDocumento, fechaInicio, fechaFin) => {
      dispatch(
        actions.initFondosInformacionCorporativa(size, page, claveOperadora, claveFondo, tipoDocumento, fechaInicio, fechaFin)
      );
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterInformacionCorporativaFondo)
);

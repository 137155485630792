import React, { Component } from "react";

// import classes from './Accordion.css'
import Toggle from "./toggle/Toggle";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLan } from "../../../stores/utils/utilities";

class Accordion extends Component {
  // import listado_json from "../../json/listado_json.json";
  // import Accordion from '../../components/ui/accordion/Accordion';
  // <Accordion datos={listado_json.datos} nombre="listado"/>
  state = {
    tipoInstrumento: null
  };

  updateParent(value) {
    this.props.updateGrandparent(value);
  }

  updateParentInstrumentos(value, numeroFideicomiso) {
    this.props.updateGrandparentInstrumentos(value, numeroFideicomiso);
  }

  updateParentListado(empresaId, razonSocial, clave, numeroTramite) {
    this.props.updateGrandparentListado(empresaId, razonSocial, clave, numeroTramite);
  }

  clickHandlerTramite(idTramite, numeroTramite, emisora_id, clave, razonSocial) {
    this.props.clickHandlerTramite(idTramite, numeroTramite, emisora_id, clave, razonSocial);
  }

  render() {
    let item = null;
    let encabezadoTipoInstrumento = null;
    if (this.props.datos) {
      let tamaño_datos = this.props.datos.length;
      let titulo_info = "";
      let titulo_encabezado = "";
      let titulo_encabezado_estatico = "";
      let titulo_encabezado_2 = "";
      let titulo_encabezado_2_estatico = "";
      let titulo = "";
      let tipoInstrumento = null;
      if (tamaño_datos !== undefined) {
        tamaño_datos = tamaño_datos - 1;
        item = this.props.datos.map((dato, i) => {
          if (this.props.titulo_encabezado) {
            titulo_encabezado = this.props.titulo_encabezado;
          } else {
            if (dato.titulo_encabezado) {
              titulo_encabezado = dato.titulo_encabezado;
            }

          }

          if (this.props.titulo_encabezado) {
            titulo_encabezado_2 = this.props.titulo_encabezado_2;
          } else {
            if (dato.titulo_encabezado_2) {
              titulo_encabezado_2 = dato.titulo_encabezado_2;
            }
          }
          if (this.props.titulo_encabezado_estatico) {
            titulo_encabezado_estatico = this.props.titulo_encabezado_estatico;
          }
          if (this.props.titulo_encabezado_2_estatico) {
            titulo_encabezado_2 = this.props.titulo_encabezado_2_estatico;
          }

          if (this.props.titulo_info) {
            titulo_info = this.props.titulo_info;
          }

          let imagen_icono = "";
          if (dato.imagen_icono || this.props.imagen_icono) {
            imagen_icono = this.props.imagen_icono ? this.props.imagen_icono : "";
            if (imagen_icono === "") {
              imagen_icono = dato.imagen_icono ? dato.imagen_icono : "";
            }
          }
          let imagen_url = "";
          if (dato.pictureUrl) {
            imagen_url = dato.pictureUrl;
          } else {
            if (dato.imagen) {
              imagen_url = dato.imagen;
            }
          }

          if (this.props.titulo) {
            titulo = this.props.titulo;
          } else {
            if (dato.title) {
              titulo = dato.title;
            } else {
              titulo = dato.titulo;
            }
          }
          let idTramite = null;
          if (dato.idTramite) {
            idTramite = dato.idTramite
          }

          let idEmpresa = null;
          let nombreEmpresa = null;

          if (dato.emisora) {
            if (dato.emisora.id) {
              idEmpresa = dato.emisora.id;
            }
            if (dato.emisora.id === undefined && dato.id !== null) {
              idEmpresa = dato.id;
            }
            if (dato.emisora.nombre) {
              nombreEmpresa = dato.emisora.nombre;
            }
          } else if (dato.empresa) {
            if (dato.empresa.id) {
              idEmpresa = dato.empresa.id;
            }
            if (dato.empresa.nombre) {
              nombreEmpresa = dato.empresa.nombre;
            }
          } else if (dato.idEmpresa) {
            idEmpresa = dato.idEmpresa;
          } else {
            idEmpresa = dato.id;
            nombreEmpresa = dato.nombre;
          }
          if (dato.idEmisora) {
            idEmpresa = dato.idEmisora;
          }

          if (this.props.esTramite) {
            idEmpresa = dato.id;
          }

          if (dato.id && this.props.esPreliminar) {
            idTramite = dato.id
          }
          if (dato.emisora && this.props.esPreliminar) {
            idEmpresa = dato.emisora.id;
          }

          let tipoValor = null;
          if (dato.tipoValor) {
            tipoValor = dato.tipoValor;
            if (dato.tipoValor.nombre) {
              tipoValor = dato.tipoValor.nombre;
            }
          }
          let tipoAviso = null;
          if (dato.tipoDocumentoListado) {
            tipoAviso = dato.tipoDocumentoListado.nombre;
          }
          if (dato.tipoDocumento) {
            tipoAviso = dato.tipoDocumento;
          }
          if (dato.tipoAviso) {
            tipoAviso = dato.tipoAviso;
          }
          let razonSocialListado = null;
          let claveAviso = null;
          if (dato.empresa) {
            razonSocialListado = dato.empresa.nombre;
            claveAviso = dato.empresa.clave;
          }
          if (dato.emisora && this.props.esPreliminar) {
            razonSocialListado = dato.emisora.nombre;
            claveAviso = dato.emisora.clave;
          }
          let mercado = null;
          let idMercado = null;
          if (dato.tipoMercado) {
            mercado = dato.tipoMercado.nombre;
            idMercado = dato.tipoMercado.id;
          } else {
            mercado = dato.mercado;
          }
          let tipoDerecho = null;
          if (dato.tipoDerecho) {
            tipoDerecho = dato.tipoDerecho;
            if (dato.tipoDerecho.nombre) {
              tipoDerecho = dato.tipoDerecho.nombre;
            }
          }
          let detallesTramite = null;
          if (this.props.detallesProspectos) {
            detallesTramite = this.props.detallesProspectos;

          } else if (this.props.detalles) {
            this.props.detalles.forEach(function (detalle) {
              if (dato.id === detalle[0].idTramite) {
                detallesTramite = detalle[0];
              }
            });
          }

          let numeroTramiteSeleccionado;
          if (this.props.numeroTramiteSeleccionado) {
            numeroTramiteSeleccionado = this.props.numeroTramiteSeleccionado;
          }

          let texto_html = null;
          if (dato.desc) {
            texto_html = dato.desc;
          } else {
            if (dato.texto_html) {
              texto_html = dato.texto_html;
            }
          }
          let sinIcono = false;
          if (sinIcono !== undefined && sinIcono !== null) {
            sinIcono = this.props.sinIcono;
          }
          let proveedores = null;
          if (dato.proveedores) {
            proveedores = dato.proveedores;
          }

          if (tipoInstrumento !== dato.tipoInstrumento) {
            tipoInstrumento = dato.tipoInstrumento;
            encabezadoTipoInstrumento = (
              <div>
                <span className={["texto_30 nunito regular"].join(" ")}>{tipoInstrumento}</span>
              </div>
            );
          } else {
            encabezadoTipoInstrumento = null;
          }
          if (this.props.instrumentosAlternativos) {
            encabezadoTipoInstrumento = null;
          }
          let documentos = null;
          if (this.props.documentos) {
            documentos = this.props.documentos;
          }

          let fechaSuspension = null;

          if (dato.fechaSuspension) {
            fechaSuspension = dato.fechaSuspension;
          } else if (dato.haltDate) {
            fechaSuspension = dato.haltDate;
          }
          let fechaLevantamiento = null;
          if (dato.fechaLevantamiento) {
            fechaLevantamiento = dato.fechaLevantamiento;
          } else if (dato.liftingDate) {
            fechaLevantamiento = dato.liftingDate;
          }

          let clave = null;
          let serie = null;
          let emisora = null;
          let status = null;
          if (dato.serie !== null && dato.serie !== undefined) {
            serie = dato.serie;
          }

          if (dato.clave !== null && dato.clave !== undefined) {
            clave = dato.clave;
          }
          if (dato.displayName !== null && dato.displayName !== undefined) {
            // let clave_split = dato.displayName.split(" ");
            clave = dato.displayName;
            // serie = clave_split[1];
          }

          if (dato.emisora !== null && dato.emisora !== undefined) {
            emisora = dato.emisora;
          }
          if (dato.businessName !== null && dato.businessName !== undefined) {
            emisora = dato.businessName;
          }

          if (dato.estatus !== null && dato.estatus !== undefined) {
            status = dato.estatus;
          }
          if (dato.status !== null && dato.status !== undefined) {
            status = dato.status;
          }
          let numeroEnvio = null;
          if (dato.numeroEnvio) {
            numeroEnvio = dato.numeroEnvio;
          }
          let subtitulo = null;
          if (dato.subtitulo != null) {
            subtitulo = dato.subtitulo;
          }

          let icons_tamaño = false;
          if (this.props.icons_tamaño) {
            icons_tamaño = this.props.icons_tamaño;
          }
          let email = null;
          if (dato.email) {
            email = dato.email;
          }
          if (this.props.email) {
            email = this.props.email;
          }
          let etiquetaBoton = "";
          if (dato.etiquetaBoton) {
            etiquetaBoton = dato.etiquetaBoton;
          }
          if (this.props.etiquetaBoton) {
            etiquetaBoton = this.props.etiquetaBoton;
          }

          let nombreParent = null;
          if (this.props.nombre) {
            nombreParent = this.props.nombre;
          }

          if (this.props.nombreParent) {
            nombreParent = this.props.nombreParent;
          }
          let tipoBotonDescarga = 1;
          if (this.props.tipoBotonDescarga) {
            tipoBotonDescarga = this.props.tipoBotonDescarga;
          }
          let sic = null;
          if (this.props.sic) {
            sic = this.props.sic;
          }

          let documentosDisponibles = this.props.documentosDisponibles ? this.props.documentosDisponibles : false;
          if (dato.documentosDisponibles) {
            documentosDisponibles = dato.documentosDisponibles
          }

          let date = null;
          if (dato.fechaColocacion) {
            date = dato.fechaColocacion;
          }
          let intermediary = null;
          if (dato.intermediarioColocador) {
            intermediary = dato.intermediarioColocador;
          }
          if (dato.claveDePizarra) {
            clave = dato.claveDePizarra;
          }
          let descripcion = dato.descripcion;
          if (dato.emisor) {
            descripcion = dato.emisor;
          }
          if (dato.descripcion) {
            descripcion = dato.descripcion;
          }
          let nombreArchivo = dato.nombreArchivo;
          if (dato.nombreArchivo == undefined && dato.nombreArchivo == null) {
            nombreArchivo = dato.url;
          }
          let tipoDocumento = dato.tipoDocumento;
          if (dato.tipoDocumento == undefined && dato.tipoDocumento == null) {
            tipoDocumento = dato.descripcion;
          }
          if (this.props.tipo === 5 && this.props.subtipo === 2) {
            subtitulo = getLan() === "es" ? "Leer más" : "Show more";
          }
          let ficha = null;
          if (dato._links) {
            if (dato._links.ficha) {
              ficha = dato._links.ficha.href;
            }
          }
          return (
            <Auxs>
              {encabezadoTipoInstrumento}
              <Toggle
                dato={dato}
                tipoBotonDescarga={tipoBotonDescarga}
                area={dato.area}
                idTramite={idTramite}
                date={date}
                intermediary={intermediary}
                titulo={titulo}
                subtitulo={subtitulo}
                icons_tamaño={icons_tamaño}
                texto={texto_html}
                telefono={dato.telefono}
                email={email}
                key={i}
                nombre_padre={nombreParent}
                id={this.props.nombre + i}
                icon={this.props.icon}
                imagen_icono={imagen_icono}
                titulo_info={titulo_info}
                titulo_encabezado={titulo_encabezado}
                titulo_encabezado_2={titulo_encabezado_2}
                titulo_encabezado_estatico={titulo_encabezado_estatico}
                titulo_encabezado_2_estatico={titulo_encabezado_2_estatico}
                tipo={this.props.tipo}
                subtipo={this.props.subtipo}
                imagen={imagen_url}
                indice={i}
                indice_emisora={this.props.indice_emisora}
                ultimo_dato={tamaño_datos}
                url={dato.url}
                urlBoton={dato.urlBoton}
                descarga_url={dato.descargar_url}
                etiquetaBoton={etiquetaBoton}
                mensaje_boton={dato.mensaje_boton}
                descarga_url_2={dato.descargar_url_2}
                mensaje_boton_2={dato.mensaje_boton_2}
                texto_descarga={dato.texto_descarga}
                texto_descarga_2={dato.texto_descarga_2}
                texto_derecha_encabezado_titulo={
                  this.props.texto_derecha_encabezado_titulo
                }
                texto_derecha_encabezado={this.props.texto_derecha_encabezado}
                bolsa={dato.bolsa}
                fechaListado={
                  this.props.fechaListado !== undefined &&
                    this.props.fechaListado !== null
                    ? this.props.fechaListado
                    : dato.fechaListado !== undefined &&
                      dato.fechaListado !== null
                      ? dato.fechaListado
                      : null
                }
                sitioWeb={dato.sitioWeb}
                ramo={dato.ramo}
                subramo={dato.subramo}
                sector={dato.sector}
                subsector={dato.subsector}
                direccion={dato.direccion}
                actividadEconomica={dato.actividadEconomica}
                logo={dato.logo}
                razonSocial={
                  this.props.razonSocial !== undefined &&
                    this.props.razonSocial !== null
                    ? this.props.razonSocial
                    : dato.razonSocial !== undefined &&
                      dato.razonSocial !== null
                      ? dato.razonSocial
                      : null
                }
                tipoDocumento={tipoDocumento}
                fechaCreacion={dato.fechaCreacion}
                fechaCancelacion={dato.fechaCancelacion}
                serie={serie}
                fechaEmision={dato.fechaEmision}
                fechaVencimiento={dato.fechaVencimiento}
                isin={dato.isin}
                representanteComun={dato.representanteComun}
                clave={clave}
                fechaEx={dato.fechaEx}
                fechaRegistro={dato.fechaRegistro}
                fechaPago={dato.fechaPago}
                tipoDerecho={tipoDerecho}
                estatus={status}
                nombre={nombreEmpresa}
                pais={dato.pais ? dato.pais : dato.paisOrigen}
                mercadoPrincipalDescripcion={dato.mercadoPrincipalDescripcion ? dato.mercadoPrincipalDescripcion : dato.mercadoPrincipal}
                tasaInteres={dato.tasaInteres}
                rutaLogo={dato.rutaLogo}
                nombreContacto={dato.nombreContacto}
                fechaPublicacion={dato.fechaPublicacion}
                periodo={dato.periodo}
                capitalSocial={dato.capitalSocial}
                observaciones={dato.observaciones}
                emisora={emisora}
                idEmisora={idEmpresa}
                nombreArchivo={nombreArchivo}
                fileName={dato.fileName}
                numeroTramite={dato.numeroTramite}
                tituloConsultaDoc={this.props.tituloConsultaDoc}
                tipoValor={tipoValor}
                tipoAviso={tipoAviso}
                razonSocialListado={razonSocialListado}
                claveAviso={claveAviso}
                urlDocumento={dato.urlDocumento}
                fechaRecepcion={dato.fechaRecepcion}
                mercado={mercado}
                descripcion={descripcion}
                numeroTramiteSeleccionado={numeroTramiteSeleccionado}
                comentario={dato.comentario}
                listado={dato.listado}
                detallesTramite={detallesTramite}
                claveCotizacion={dato.claveCotizacion}
                fecha={dato.fecha}
                fechaSolicitud={dato.fechaSolicitud}
                creationDateInMillis={dato.creationDateInMillis}
                docType={dato.docType}
                contacto={dato.contacto}
                claveWarrant={dato.claveWarrant}
                proveedores={proveedores}
                tipoInstrumento={dato.tipoInstrumento}
                idTipoInstrumento={dato.idTipoInstrumento}
                fideicomitentes={dato.fideicomitentes}
                documentos={documentos}
                nombreDocumento={dato.nombreDocumento}
                modoListado={dato.modoListado}
                fechaSuspension={fechaSuspension}
                fechaLevantamiento={fechaLevantamiento}
                updateParent={this.updateParent.bind(this)}
                updateParentInstrumentos={this.updateParentInstrumentos.bind(this)}
                updateParentListado={this.updateParentListado.bind(this)}
                clickHandlerTramite={this.clickHandlerTramite.bind(this)}
                nombreEmision={this.props.nombreEmision}
                idMercado={idMercado}
                sinIcono={sinIcono}
                documentosDisponibles={documentosDisponibles}
                eventosRelevantesURL={dato.eventosRelevantesURL}
                informacionFinancieraURL={dato.informacionFinancieraURL}
                fiduciario={dato.fiduciario}
                numeroEnvio={numeroEnvio}
                cliente={this.props.cliente}
                idPublicacion={dato.idPublicacion}
                claveFondo={dato.claveFondo}
                claveOperadora={dato.claveOperadora}
                claveSiefore={dato.claveSiefore}
                claveAfore={dato.claveAfore}
                fechaPrecio={dato.fechaPrecio}
                fondo={this.props.fondo}
                idFondo={dato.idFondo}
                noEmisor={dato.noEmisor}
                proporcion={dato.proporcion}
                sic={sic}
                seccion={dato.seccion}
                fechaListadoBIVA={dato.fechaListadoBIVA}
                fechaVencimientoPerpetua={dato.fechaVencimientoPerpetua}
                moneda={dato.moneda}
                monedaMontoColocado={dato.monedaMontoColocado}
                monedaMontoMaximo={dato.monedaMontoMaximo}
                sectoresInversion={dato.sectoresInversion}
                montoColocado={dato.montoColocado}
                titulosAutorizados={dato.titulosAutorizados}
                montoMaximo={dato.montoMaximo}
                titulosEmitidos={dato.titulosEmitidos}
                fideicomitente={dato.fideicomitente}
                numeroFideicomiso={dato.numeroFideicomiso}
                isLlamadaCapital={dato.isLlamadaCapital}
                plazo={dato.plazo}
                emisiones={dato.emisiones}
                derechos={dato.derechos}
                instrumento={dato.instrumento}
                dici={dato.dici}
                prospecto={dato.prospecto}
                suplemento={dato.suplemento}
                anexos={dato.anexos}
                ejercicio={dato.ejercicio}
                grupoPerteneciente={dato.grupoPerteneciente}
                valuadorIndependiente={dato.valuadorIndependiente}
                auditorExterno={dato.auditorExterno}
                sectorPreponderante={dato.sectorPreponderante}
                esPreliminar={this.props.esPreliminar}
                typePage={this.props.typePage}
                isPng={this.props.isPng}
                titulosEnCirculacion={
                  dato.titulosEnCirculacion
                    ? dato.titulosEnCirculacion
                    : dato.accionesEnCirculacion
                      ? dato.accionesEnCirculacion
                      : null
                }
                tipoBono={dato.tipoBono}
                subtipoBono={dato.subtipoBono}
                montoEmision={dato.montoEmision}
                numeroTitulos={dato.numeroTitulos}
                plazoEmision={dato.plazoEmision}
                idEmision={dato.idEmision}
                isSustentable={this.props.isSustentable}
                tiposBonosASG={this.props.tiposBonosASG}
                ficha={ficha}
              />
            </Auxs>
          );
        });
      } else {
        let dato = this.props.datos;
        let detallesTramite = null;
        if (this.props.detallesProspectos) {
          detallesTramite = this.props.detallesProspectos;

        } else if (this.props.detalles) {
          this.props.detalles.forEach(function (detalle) {
            if (dato.id === detalle[0].idTramite) {
              detallesTramite = detalle[0];
            }
          });
        }
        if (this.props.titulo) {
          titulo = this.props.titulo;
        } else {
          if (dato.title) {
            titulo = dato.title;
          } else {
            titulo = dato.titulo;
          }
        }

        let sinIcono = false;
        if (sinIcono !== undefined && sinIcono !== null) {
          sinIcono = this.props.sinIcono;
        }

        if (this.props.titulo_info) {
          titulo_info = this.props.titulo_info;
        }

        let subtitulo = null;
        if (dato.subtitulo) {
          subtitulo = dato.subtitulo;
        }

        let icons_tamaño = false;
        if (this.props.icons_tamaño) {
          icons_tamaño = this.props.icons_tamaño;
        }
        let etiquetaBoton = "";
        if (dato.etiquetaBoton) {
          etiquetaBoton = dato.etiquetaBoton;
        }
        if (this.props.etiquetaBoton) {
          etiquetaBoton = this.props.etiquetaBoton;
        }

        let nombreParent = null;
        if (this.props.nombre) {
          nombreParent = this.props.nombre;
        }

        if (this.props.nombreParent) {
          nombreParent = this.props.nombreParent;
        }

        let tipoBotonDescarga = 1;
        if (this.props.tipoBotonDescarga) {
          tipoBotonDescarga = this.props.tipoBotonDescarga;
        }
        let idTramite = dato.idTramite;
        if (dato.id && this.props.esTramite) {
          idTramite = dato.id
        }

        if (dato.id && this.props.esPreliminar) {
          idTramite = dato.id
        }
        let idEmpresa = dato.id;
        if (dato.emisora && this.props.esPreliminar) {
          idEmpresa = dato.emisora.id;
        }
        if (dato.idEmisora) {
          idEmpresa = dato.idEmisora;
        }
        let date = null;
        if (dato.fechaColocacion) {
          date = dato.fechaColocacion;
        }
        let intermediary = null;
        if (dato.intermediarioColocador) {
          intermediary = dato.intermediarioColocador;
        }
        let ficha = null;
        if (dato._links) {
          if (dato._links.ficha) {
            ficha = dato._links.ficha.href;
          }
        }

        return (
          <Toggle
            dato={dato}
            area={dato.area}
            tipoBotonDescarga={tipoBotonDescarga}
            intermediary={intermediary}
            date={date}
            titulo={titulo}
            titulo_info={titulo_info}
            subtitulo={subtitulo}
            sinIcono={sinIcono}
            icons_tamaño={icons_tamaño}
            texto={dato.texto_html}
            telefono={dato.telefono}
            email={dato.email}
            /*  key={i} */
            nombre_padre={nombreParent}
            /*  id={this.props.nombre + i} */
            icon={this.props.icon}
            tipo={this.props.tipo}
            subtipo={this.props.subtipo}
            imagen={dato.imagen}
            /* indice={i} */
            indice_emisora={this.props.indice_emisora}
            url={dato.url}
            descarga_url={dato.descargar_url}
            etiquetaBoton={etiquetaBoton}
            mensaje_boton={dato.mensaje_boton}
            descarga_url_2={dato.descargar_url_2}
            mensaje_boton_2={dato.mensaje_boton_2}
            texto_descarga={dato.texto_descarga}
            texto_descarga_2={dato.texto_descarga_2}
            texto_derecha_encabezado_titulo={
              this.props.texto_derecha_encabezado_titulo
            }
            texto_derecha_encabezado={this.props.texto_derecha_encabezado}
            bolsa={dato.bolsa}
            fechaListado={
              this.props.fechaListado !== undefined &&
                this.props.fechaListado !== null
                ? this.props.fechaListado
                : dato.fechaListado !== undefined && dato.fechaListado !== null
                  ? dato.fechaListado
                  : null
            }
            sitioWeb={dato.sitioWeb}
            ramo={dato.ramo}
            subramo={dato.subramo}
            sector={dato.sector}
            subsector={dato.subsector}
            direccion={dato.direccion}
            actividadEconomica={dato.actividadEconomica}
            logo={dato.logo}
            razonSocial={
              this.props.razonSocial !== undefined &&
                this.props.razonSocial !== null
                ? this.props.razonSocial
                : dato.razonSocial !== undefined && dato.razonSocial !== null
                  ? dato.razonSocial
                  : null
            }
            tipoDocumento={dato.tipoDocumento}
            fechaCreacion={dato.fechaCreacion}
            fechaCancelacion={dato.fechaCancelacion}
            serie={dato.serie}
            fechaEmision={dato.fechaEmision}
            fechaVencimiento={dato.fechaVencimiento}
            isin={dato.isin}
            representanteComun={dato.representanteComun}
            clave={dato.clave}
            fechaEx={dato.fechaEx}
            fechaRegistro={dato.fechaRegistro}
            fechaPago={dato.fechaPago}
            tipoDerecho={dato.tipoDerecho}
            estatus={dato.estatus}
            nombre={dato.nombre}
            pais={dato.pais ? dato.pais : dato.paisOrigen}
            mercadoPrincipalDescripcion={dato.mercadoPrincipalDescripcion ? dato.mercadoPrincipalDescripcion : dato.mercadoPrincipal}
            tasaInteres={dato.tasaInteres}
            rutaLogo={dato.rutaLogo}
            nombreContacto={dato.nombreContacto}
            fechaPublicacion={dato.fechaPublicacion}
            periodo={dato.periodo}
            capitalSocial={dato.capitalSocial}
            observaciones={dato.observaciones}
            emisora={dato.emisora}
            idEmisora={idEmpresa}
            nombreArchivo={dato.nombreArchivo}
            fileName={dato.fileName}
            numeroTramite={dato.numeroTramite}
            tituloConsultaDoc={this.props.tituloConsultaDoc}
            urlDocumento={dato.urlDocumento}
            fechaRecepcion={dato.fechaRecepcion}
            descripcion={dato.descripcion}
            idTramite={idTramite}
            comentario={dato.comentario}
            listado={dato.listado}
            detallesTramite={detallesTramite}
            contacto={dato.contacto}
            docType={dato.docType}
            razonComercial={dato.razonComercial}
            updateParent={this.updateParent.bind(this)}
            updateParentInstrumentos={this.updateParentInstrumentos.bind(this)}
            updateParentListado={this.updateParentListado.bind(this)}
            documentosDisponibles={dato.documentosDisponibles}
            eventosRelevantesURL={dato.eventosRelevantesURL}
            informacionFinancieraURL={dato.informacionFinancieraURL}
            fechaPrecio={dato.fechaPrecio}
            proporcion={dato.proporcion}
            seccion={dato.seccion}
            fechaListadoBIVA={dato.fechaListadoBIVA}
            fechaVencimientoPerpetua={dato.fechaVencimientoPerpetua}
            moneda={dato.moneda}
            monedaMontoColocado={dato.monedaMontoColocado}
            monedaMontoMaximo={dato.monedaMontoMaximo}
            sectoresInversion={dato.sectoresInversion}
            montoColocado={dato.montoColocado}
            montoMaximo={dato.montoMaximo}
            titulosEmitidos={dato.titulosEmitidos}
            titulosAutorizados={dato.titulosAutorizados}
            fideicomitente={dato.fideicomitente}
            numeroFideicomiso={dato.numeroFideicomiso}
            isLlamadaCapital={dato.isLlamadaCapital}
            plazo={dato.plazo}
            emisiones={dato.emisiones}
            derechos={dato.derechos}
            tipoInstrumento={dato.tipoInstrumento}
            fiduciario={dato.fiduciario}
            instrumento={dato.instrumento}
            dici={dato.dici}
            prospecto={dato.prospecto}
            suplemento={dato.suplemento}
            anexos={dato.anexos}
            ejercicio={dato.ejercicio}
            grupoPerteneciente={dato.grupoPerteneciente}
            valuadorIndependiente={dato.valuadorIndependiente}
            auditorExterno={dato.auditorExterno}
            sectorPreponderante={dato.sectorPreponderante}
            esPreliminar={this.props.esPreliminar}
            titulosEnCirculacion={
              dato.titulosEnCirculacion
                ? dato.titulosEnCirculacion
                : dato.accionesEnCirculacion
                  ? dato.accionesEnCirculacion
                  : null
            }
            typePage={this.props.typePage}
            isPng={this.props.isPng}
            tipoBono={dato.tipoBono}
            subtipoBono={dato.subtipoBono}
            montoEmision={dato.montoEmision}
            numeroTitulos={dato.numeroTitulos}
            plazoEmision={dato.plazoEmision}
            idEmision={dato.idEmision}
            isSustentable={this.props.isSustentable}
            tiposBonosASG={this.props.tiposBonosASG}
            ficha={ficha}
          />
        );
      }
    } else if (this.props.integrantes) {
      return <Toggle integrantes={this.props.integrantes} titulo={this.props.titulo} tipo={this.props.tipo} />;
    }

    if (this.props.sinPadre) {
      return item
    }
    return (
      <div className={"accordion"} id={this.props.nombreParent ? this.props.nombreParent : this.props.nombre}>
        {item}
      </div>
    );
  }
}

export default Accordion;

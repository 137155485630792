import React, { Component } from "react";
import classes from "./Proveedores.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";
import Box from "../../../components/ui/box/Box";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Proveedores extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let boxes = null;
    if (this.props.lineamientos) {
      boxes = this.props.lineamientos.map((lineamiento, i) => {
        return <Box key={"lineamiento_" + i} modelo="modelo_1" data={lineamiento}   />;
      });
    }
    let timeKey = +new Date();
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="operaciones.proveedores.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <p className={" texto_16 montse regular"}>
              <FormattedHTMLMessage id="operaciones.proveedores.introduccion1" defaultMessage=" " />
            </p>
          </div>
          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.proveedores.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>

          <div className={[" margen_top_50", classes.accordion].join(" ")}>
            <div className={"accordion"} id={"accordion"}>
              <Accordion
                sinPadre={true}
                datos={this.props.oms}
                nombre={"oms"}
                icon={""}
                nombreParent={"accordion"}
                imagen_icono={"lap4.svg"}
                tipo={6}
                titulo_encabezado_estatico={this.state.messages ? this.state.messages["operaciones.proveedores.oms"] : " "}
                titulo_encabezado_2_estatico={
                  this.state.messages ? this.state.messages["operaciones.proveedores.oms.desc"] : " "
                }
                className={classes.accordion}
              />

              <div className={[" margen_top_50", classes.accordion].join(" ")}>
                <Accordion
                  sinPadre={true}
                  nombreParent={"accordion"}
                  datos={this.props.isvs}
                  nombre={"isv"}
                  icon={""}
                  imagen_icono={"lap5.svg"}
                  tipo={6}
                  titulo_encabezado_estatico={this.state.messages ? this.state.messages["operaciones.proveedores.isv"] : " "}
                  titulo_encabezado_2_estatico={
                    this.state.messages ? this.state.messages["operaciones.proveedores.isv.desc"] : " "
                  }
                  className={classes.accordion}
                />
              </div>
            </div>
          </div>

          <div className="row margen_top_50 margin_bottom_100">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.proveedores.subtitulo2" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row margen_top_30">{boxes}</div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    lineamientos: state.documentos.lineamientos,
    oms: state.accordion.oms,
    isvs: state.accordion.isvs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(36));
      dispatch(action.getLineamientosCertificacion());
      dispatch(action.getAccordionOms());
      dispatch(action.getAccordionIsvs());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Proveedores);

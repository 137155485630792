import React, { Component } from "react";
import $ from "jquery";
import classes from "./Breadcrum.css";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LoaderHidden from "../../loader/LoaderHidden";

function irArriba(duration, elementId = null) {
let topElement = "0px";
if (elementId) {
  //si existe un hash, buscará el elemento con el id y te posicionara en el, eliminando QueryStrings
  topElement = document.getElementById(elementId.replace("#","").split("?")[0]);
  if (topElement) {
    topElement = (parseFloat(topElement.offsetTop)) + "px"
  }else{
    topElement = "0px"
  }
}
  $("body, html")
    .stop()
    .animate(
      {
        scrollTop: topElement
      },
      duration
    );
}

class Breadcrum extends Component {
  state = {
    menu: null
  };

  componentDidUpdate(nextProps) {
    if (this.props.menu !== this.state.menu) {
      this.setState({
        menu: this.props.menu
      });
    }
  }

  render() {
    let location = window.location.pathname;
    if (window.location.hash && window.location.hash !== "") {
      irArriba(700, window.location.hash);
    }else{
      irArriba(700);
    }

    let nivel0 = null;
    let nivel1 = null;
    let nivel2 = null;
    let nivel3 = null;
    let nivel4 = null;
    let clase_primero = "margen_left_5 semi_bold montse texto_10 color_azul";
    let classe_ultimo = "margen_left_5 semi_bold montse texto_10 color_gris nowrap";
    if (this.props.menu && this.props.idPage) {
      nivel0 = (
        <React.Fragment>
          <NavLink to={"/"} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
            <span className={clase_primero}>
              <FormattedMessage id="inicio" defaultMessage=" " />
            </span>
          </NavLink>
          <span className={classe_ultimo}> > </span>
        </React.Fragment>
      );
      const menu_seleccionado = this.props.menu.menu.find(menu => menu.id === this.props.idPage);
      if (menu_seleccionado) {
        if (menu_seleccionado.to === location) {
          nivel1 = (
            <a href={menu_seleccionado.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
              <span className={classe_ultimo}>{menu_seleccionado.name}</span>
            </a>
          );
        } else {
          nivel1 = (
            <NavLink to={menu_seleccionado.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
              <span className={clase_primero}>{menu_seleccionado.name}</span>
            </NavLink>
          );
        }

        const submenu_seleccionado = menu_seleccionado.submenu.find(sub_menu => sub_menu.to === location);
        if (submenu_seleccionado !== undefined && submenu_seleccionado.to === location) {
          nivel2 = (
            <React.Fragment>
              <span className={classe_ultimo}> > </span>
              <a href={submenu_seleccionado.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                <span className={classe_ultimo}>{submenu_seleccionado.name}</span>
              </a>
            </React.Fragment>
          );
        } else if (submenu_seleccionado && submenu_seleccionado.submenu === undefined) {
          nivel2 = (
            <React.Fragment>
              <span className={classe_ultimo}> > </span>
              <a href={submenu_seleccionado.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                <span className={classe_ultimo}>{submenu_seleccionado.name}</span>
              </a>
            </React.Fragment>
          );
        } else {
          menu_seleccionado.submenu.map((sub, i) => {
            if (sub.submenu !== undefined && sub.submenu.length > 0) {
              const submenu_hijo_seleccionado = sub.submenu.find(sub_menu => sub_menu.to === location);
              if (submenu_hijo_seleccionado && submenu_hijo_seleccionado.submenu === undefined) {
                nivel2 = (
                  <React.Fragment>
                    <span className={classe_ultimo}> > </span>
                    <NavLink to={sub.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                      <span className={clase_primero}>{sub.name}</span>
                    </NavLink>
                  </React.Fragment>
                );
                nivel3 = (
                  <React.Fragment>
                    <span className={classe_ultimo}> > </span>
                    <a href={submenu_hijo_seleccionado.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                      <span className={classe_ultimo}>{submenu_hijo_seleccionado.name}</span>
                    </a>
                  </React.Fragment>
                );
              } else {
                sub.submenu.map((sub_nieto, i) => {
                  if (sub_nieto.to === location) {
                    nivel2 = (
                      <React.Fragment>
                        <span className={classe_ultimo}> > </span>
                        <NavLink to={sub.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                          <span className={clase_primero}> {sub.name}</span>
                        </NavLink>
                      </React.Fragment>
                    );
                    nivel3 = (
                      <React.Fragment>
                        <span className={classe_ultimo}> > </span>
                        <a href={sub_nieto.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                          <span className={classe_ultimo}>{sub_nieto.name}</span>
                        </a>
                      </React.Fragment>
                    );
                  } else if (sub_nieto.submenu !== undefined && sub_nieto.submenu.length > 0) {
                    // const submenu_nieto_seleccionado = sub_nieto.to === location ? true : false;
                    const submenu_nieto_seleccionado = sub_nieto.submenu.find(sub_menu => sub_menu.to === location);

                    if (submenu_nieto_seleccionado) {
                      nivel2 = (
                        <React.Fragment>
                          <span className={classe_ultimo}> > </span>
                          <NavLink to={sub.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                            <span className={clase_primero}> {sub.name}</span>
                          </NavLink>
                        </React.Fragment>
                      );
                      nivel3 = (
                        <React.Fragment>
                          <span className={classe_ultimo}> > </span>
                          <NavLink to={sub_nieto.to} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
                            <span className={clase_primero}> {sub_nieto.name}</span>
                          </NavLink>
                        </React.Fragment>
                      );
                      nivel4 = (
                        <React.Fragment>
                          <span className={classe_ultimo}> > </span>
                          <a
                            href={submenu_nieto_seleccionado.to}
                            className={[classes.link2].join(" ")}
                            onClick={this.props.onClick}
                          >
                            <span className={classe_ultimo}>{submenu_nieto_seleccionado.name}</span>
                          </a>
                        </React.Fragment>
                      );
                      return null;
                    }
                  }
                  return null;
                });
              }
            }

            return null;
          });
        }
      }
    } else {
    }
    let dateKey = +new Date();
    return (
      <React.Fragment>
        <LoaderHidden key={dateKey} />
        {nivel0}
        {nivel1}
        {nivel2}
        {nivel3}
        {nivel4}
      </React.Fragment>
    );
  }
}

export default Breadcrum;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../stores/actions/index";
import classes from "./Biva360.css";
import Carousel from "../../../components/ui/carouselBanner/Carousel";
import { defineMessages, IntlProvider } from "react-intl";
import { NavLink } from "react-router-dom";
import { getLocal } from "../../../stores/utils/utilities";

const lan = getLocal();

const Biva360 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getBannerAcerca360());
    dispatch(actions.getButton360());
    dispatch(actions.getObjetivos360());
    dispatch(actions.initIntl(99));
  }, []);

  const [messages, setMessages] = useState(null);
  const [botones, setBotones] = useState([]);
  const [objetivos, setObjetivos] = useState([]);
  const [banner, setBanner] = useState([]);
  const traduccionRedux = useSelector((state) => state.intl.diccionario);
  const bannerRedux = useSelector((state) => state.bivaSustentable.banner360);
  const objetivosRedux = useSelector((state) => state.bivaSustentable.objetivos);
  const botonesRedux = useSelector(
    (state) => state.bivaSustentable.botones360
  );

  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);

  useEffect(() => {
    if (botonesRedux) {
      setBotones(botonesRedux);
    }
  }, [botonesRedux]);
  useEffect(() => {
    if (objetivosRedux) {
      setObjetivos(objetivosRedux);
    }
  }, [objetivosRedux]);
  useEffect(() => {
    if (bannerRedux) {
      setBanner(bannerRedux);
    }
  }, [bannerRedux]);

  const getBotones = () => {
    return botones.map((itm, i) => {
      const imgn = itm.imagen && itm.imagen !== "" ? itm.imagen : null;
      return (
        <div className="col-12 col-md-6" key={i + "btn_aliados"}>
          <NavLink to={itm.urlBoton}>
            <div className={classes.cuadroBanner}>
              {imgn ? <img src={imgn} alt="" /> : null}
            </div>
          </NavLink>
        </div>
      );
    });
  };
  const getObjetivos = () => {
    return objetivos.map((itm, i) => {
      const imgn = itm.imagen && itm.imagen !== "" ? itm.imagen : null;
      return (
        <div className={[classes.objObjetivo, "text-center"].join(" ")} key={"objetivo" + (i + 1)}>
          <div className={classes.img}>
            {imgn ? <img src={imgn} alt="" /> : null}
          </div>
          <div className={classes.number}>
            <span className="nunito bold">{(i+1)}</span>
          </div>
          <div className={classes.sort}>
          <i className={["fas fa-sort-down", classes.caretBottom].join(" ")}></i>
          <i className={["fas fa-caret-right", classes.caretRight].join(" ")}></i>
          </div>
          <div className={classes.text_objetivo}>
          <div
            className={[" montse texto_16"].join(" ")}
            dangerouslySetInnerHTML={{ __html: itm.texto_html ? itm.texto_html : itm.texto }}
          />
          </div>
        </div>
      );
    });
  };

  return (
    <IntlProvider locale={lan} messages={messages}>
      <>
        <div className={[classes.fondo].join(" ")}>
          <Carousel data={banner} height={300} sizeOption="contain" positiony={150} isImageBackground={false} />
        </div>



        <div className={[classes.intro, "margin_top_50"].join(" ")}>
          <div
            className={[classes.textos_html, "montse texto_16 margin_top_30"].join(" ")}
            dangerouslySetInnerHTML={{
              __html: messages ? messages["biva360.introduccion"] : null,
            }}
          />
        </div>
        <div className={["margin_top_30"].join(" ")}>
          <div className={["container"].join(" ")}>
            {getObjetivos()}
          </div>
        </div>
        <div className={["margin_top_30"].join(" ")}>
          <div className={[classes.fondoBlanco, "row"].join(" ")}>
            <div className={["container", classes.alto].join(" ")}>
              <div className={["row", classes.alto].join(" ")}>
                {getBotones()}
              </div>
            </div>
          </div>
        </div>
      </>
    </IntlProvider>
  );
};

export default Biva360;

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import FilterAvisosDerecho from "../../../components/ui/filterAvisosDerecho/FilterAvisosDerecho";
import Loader from "../../../components/loader/Loader";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { getLan } from "../../../stores/utils/utilities";

let fechaInicio_temp = null,
  page_temp = 1,
  fechaFin_temp = null,
  tipoDerecho_temp = null;

class AvisosDerecho extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: false,
    emisoraSeleccionada: null,
    clave: null,
    fechaInicio: null,
    fechaFin: null,
    tipoDerecho: null
  };

  componentWillReceiveProps(nextProps) {
    let avisosDerecho = null;

    this.setState({
      loader: false
    });
    if (nextProps.avisosDerecho && nextProps.avisosDerecho.content) {
      avisosDerecho = nextProps.avisosDerecho.content;
    }
    let resource = avisosDerecho;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.avisosDerecho;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
      this.changeHistory(this.state.emisoraSeleccionada, this.state.clave, this.state.tipoDerecho,this.state.fechaInicio, this.state.fechaFin,1, pageNumber);
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      // this.props.onInitAvisosDerecho(
      //   this.state.itemsPerPage,
      //   pageNumber - 1,
      //   this.props.emisoraSeleccionada,
      //   this.state.tipoDerecho,
      //   this.state.fechaInicio,
      //   this.state.fechaFin
      // );

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    // emisora=1854&tipoDerecho=null&fechaInicio=null&fechaFin=null&subTab=1&page=1
    let paramsString = queryString.parse(this.props.location.search);

    let emisora = paramsString["emisora"] !== undefined && paramsString["emisora"] !== "null" ? paramsString["emisora"] : null;
    let clave = paramsString["clave"] !== undefined && paramsString["clave"] !== "null" ? paramsString["clave"] : null;
    let tipoDerecho = paramsString["tipoDerecho"] !== undefined && paramsString["tipoDerecho"] !== "null" ? paramsString["tipoDerecho"].split(",") : null;
    let fechaInicio = paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
    let fechaFin = paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    let subTab = paramsString["subTab"] !== undefined && paramsString["subTab"] !== "null" ? parseInt(paramsString["subTab"]) : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : null;

    tipoDerecho_temp = tipoDerecho;
    fechaInicio_temp = fechaInicio;
    fechaFin_temp = fechaFin;
    page_temp = page;

    let emisoraSeleccionada = null;
    if (emisora !== null) {
      emisoraSeleccionada = null;
    }else{
      emisoraSeleccionada = this.props.emisoraSeleccionada;
    }
    
    this.setState({ emisoraSeleccionada: emisoraSeleccionada, clave: clave });
    this.changeHistory(emisora, clave, tipoDerecho, fechaInicio, fechaFin, subTab, page);
    // this.props.onInitAvisosDerecho(this.state.itemsPerPage, 0, this.props.emisoraSeleccionada, null, null, null);
  }
  
  componentDidUpdate(prevProps){
    if (this.props.emisoraSeleccionada !== prevProps.emisoraSeleccionada) {
        this.setState({
          emisoraSeleccionada: this.props.emisoraSeleccionada
        })
    }
    if (this.props.claveEmisoraSeleccionada !== prevProps.claveEmisoraSeleccionada) {
        this.setState({
          clave: this.props.claveEmisoraSeleccionada
        })
    }
  }

  onSetTipoDerecho = tipoDerecho => {
    this.setState({
      tipoDerecho: tipoDerecho
    });
    tipoDerecho_temp = tipoDerecho;
    this.changeHistory(tipoDerecho === null ? -1 : tipoDerecho, null, null, 1, null);
  };

  onSetFechaInicio = fecha_inicio => {
    this.setState({
      fechaInicio: fecha_inicio
    });

    fechaInicio_temp = fecha_inicio;
    this.changeHistory(null, fecha_inicio === null ? -1 : fecha_inicio, null, 1, null);
  };

  onSetFechafin = fecha_fin => {
    this.setState({
      fechaFin: fecha_fin
    });

    fechaFin_temp = fecha_fin;

    this.changeHistory(null, null, fecha_fin === null ? -1 : fecha_fin, 1, null);
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
    page_temp = page;
    this.changeHistory(null, null, null, 1, page);
  };

  changeHistory(emisora = null, clave = null, tipoDerecho = null, fechaInicio = null, fechaFin = null, tab = 0, page = null) {
    let tipoDerecho2 = tipoDerecho_temp === null ?  tipoDerecho !== null ? tipoDerecho : null : tipoDerecho_temp;
    let fechaInicio2 = fechaInicio_temp === null ? fechaInicio !== null ? fechaInicio : null : fechaInicio_temp; 
    let fechaFin2 = fechaFin_temp === null ? fechaFin !== null ? fechaFin : null : fechaFin_temp; 
    let page2 = page_temp === null ? page !== null ? page : null : page_temp; 
    
    let tab2 = 1;

    this.props.history.push(
      `?emisora=${ emisora ? emisora :
        this.state.emisoraSeleccionada
      }&clave=${ clave ? clave : this.state.clave}&tipoDerecho=${tipoDerecho2}&fechaInicio=${fechaInicio2}&fechaFin=${fechaFin2}&subTab=${tab2}&page=${
        page ? page : this.state.activePage}`
    );
    this.props.onInitAvisosDerecho(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      this.props.emisoraSeleccionada,
      tipoDerecho2,
      fechaInicio2,
      fechaFin2
    );
  }

  render() {
    let showData = null;
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let avisosDerecho = this.props.avisosDerecho && this.props.avisosDerecho.content ? this.props.avisosDerecho.content : [];
    if (avisosDerecho.length > 0) {
      showData = <Accordion datos={avisosDerecho} nombre="sicAvisos" icon={""} tipo={12} subtipo={5} />;
    } else if (avisosDerecho.length === 0) {
      showData = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }
    const numRows = this.props.avisosDerecho ? this.props.avisosDerecho.totalElements : 0;

    return (
      <React.Fragment>
        {loader}
        <div className="">
          <FilterAvisosDerecho
            emisoraSeleccionada={this.props.emisoraSeleccionada}
            modelo="modelo_1"
            itemsPerPage={this.state.itemsPerPage}
            setTipoDerecho={tipoDerecho => this.onSetTipoDerecho(tipoDerecho)}
            setFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
            setFechaFin={fechaFin => this.onSetFechafin(fechaFin)}
            setPage={page => this.onSetPage(page)}
          />
        </div>
        <div className="margen_top_30">
          {showData}
        </div>

        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitAvisosDerecho: (size, page, emisoraId, tipoDerecho, fechaInicio, fechaFin) => {
      dispatch(actions.initSicAvisosDerecho(size, page, emisoraId, tipoDerecho, fechaInicio, fechaFin));
    }
  };
};

const mapStateToProps = state => {
  return {
    avisosDerecho: state.emisoras.avisosDerecho
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AvisosDerecho)
);

import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Cuotas.css";
import * as actions from "../../../stores/actions/index";
import Tabs from "../../../components/ui/tabs/Tabs";
import { Form, Field } from "react-final-form";
import Box from "../../../components/ui/box/Box";
import { FormattedMessage } from "react-intl";
import { formatDate_ddMMyyy_millies } from "../../../stores/utils/utilities";

import Input from "../../../components/ui/input/Input";
import numeral from "numeral";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";

const ids_typeValue_exceptions = [22, 23, 30, 31, 32];

const tabs_cuotas = [
  {
    image: "listado.svg",
    icon: "istado.svg",
    title: getLan() === "es" ? "Listado" : "Listing",
  },
  {
    image: "mantenimiento.svg",
    icon: "mantenimiento.svg",
    title: getLan() === "es" ? "Mantenimiento" : "Maintenance",
  },
];

let formatPrice = (value) =>
  value === undefined
    ? "" // make controlled
    : numeral(value).format("$0,0.00");

const renderInput = ({
  input,
  id = null,
  label,
  classes = null,
  options,
  elementType,
  placeholder = null,
  checked = null,
  onInputChange,
  selectLabel,
  disabled,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? classes : null,
      checked: checked,
      disabled: disabled,

      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel
      ? selectLabel
      : getLan() === "es"
      ? "Seleccione una opción"
      : "Select option",
  };
  return <Input config={params} />;
};

const renderInput2 = ({
  input,
  id = null,
  label,
  classes = null,
  options,
  elementType,
  placeholder = null,
  checked = null,
  onInputChange,
  selectLabel,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? classes : null,
      checked: checked,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel
      ? selectLabel
      : getLan() === "es"
      ? "Seleccione una opción"
      : "Select option",
  };
  return (
    <div className="form-group">
      {label ? (
        <label className={"montse texto_12"} for={input.name}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class Cuotas extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    indexSelected: 0,
    messages: null,
    lan: getLocal(),
    fecha_minima: null,
    fecha_maxima: null,
    mostrar_resultado: false,
    needParams: false,
    mantenimiento: false,
    listado: true,
    mantenimientoList: [],
    listadoList: [],
    combo_data: [],
    tiposMercado: [],
    titulo_card: getLan() === "es" ? "Costos de listado" : "Listing fees",
    errorFecha: false,
    errorTipoValor: false,
    errorMonto: false,
    radioSelected: null,
    tipoMercado: {
      value: "",
      disabled: false,
    },
    tipo_valor: {
      value: "",
      disabled: false,
    },
    monto_emision: {
      value: null,
      disabled: false,
      show: true,
    },
    fecha_de: {
      value: "",
      disabled: false,
      show: true,
    },
    fecha_hasta: {
      value: "",
      disabled: false,
      show: true,
    },
    dias_cobro: {
      value: 0,
      disabled: false,
    },
    quota: {
      value: null,
      disabled: true,
    },
    escondeFechas: false,
    escondeDiasCobro: false,
    errorFechaInicialMayor: false,
    errorFechaInicialMenor: false,
    errorFechaFinalMayor: false,
    errorFechaFinalMenor: false,
  };

  componentDidMount() {
    this.props.onInitIntl();
    this.props.onInitAllTipoMercado();
    this.props.onCuotas();
    let today = new Date();

    let fechaIni = today.toISOString().split("T")[0];
    let anio = today.getFullYear();
    let ultimo_dia_anio = anio + "-12-31";
    this.fechaCambia(null, fechaIni, ultimo_dia_anio);

    let fecha_maxima = anio + "-12-31";
    let fecha_minima = anio + "-01-01";
    this.setState({
      fecha_minima: fecha_minima,
      fecha_maxima: fecha_maxima,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (prevState.indexSelected !== this.state.indexSelected) {
      if (this.props.tipoMercado) {
        let tipoMercado = [];
        let radioSelected = null;

        if (this.props.tipoMercado.length !== undefined) {
          tipoMercado = this.getTiposMercadoTab(this.props.tipoMercado);
        }
        if (tipoMercado) {
          radioSelected = tipoMercado[0].id;
          if (radioSelected !== this.state.radioSelected) {
            this.props.onInitQuotas(radioSelected);
          }
        }
        this.setState({
          tiposMercado: tipoMercado,
          radioSelected: radioSelected,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let quota = null;
    let quotas = [];
    let tipoMercado = [];
    let listado = [];
    let mantenimiento = [];
    let combo_data = [];

    if (nextProps.quotas) {
      quotas = nextProps.quotas;
    }

    if (nextProps.quota && !this.setState.mostrar_resultado) {
      quota = nextProps.quota.quota;
    }

    quotas.map((item, i) => {
      if (item.quotaType === "LISTADO") {
        listado.push(item);
      } else {
        mantenimiento.push(item);
      }
      return null;
    });
    if (this.state.listado) {
      combo_data = listado;
    } else {
      combo_data = mantenimiento;
    }

    let radioSelected = null;
    if (nextProps.tipoMercado) {
      if (nextProps.tipoMercado.length !== undefined) {
        tipoMercado = [];
        tipoMercado = this.getTiposMercadoTab(nextProps.tipoMercado);
      }
      if (this.state.radioSelected === null) {
        radioSelected = tipoMercado[0].id;
      } else {
        radioSelected = this.state.radioSelected;
      }
      if (radioSelected !== this.state.radioSelected) {
        this.props.onInitQuotas(radioSelected);
      }
    }
    let monto_emision = null;
    if (this.state.monto_emision.value !== null) {
      monto_emision = this.state.monto_emision.value.toString();
      monto_emision = monto_emision.replace("$", "");
      monto_emision =
        monto_emision === null
          ? "" // make controlled
          : numeral(monto_emision).format("$0,0.00");
    }
    this.setState({
      quota: {
        value: quota,
        disabled: true,
      },
      monto_emision: {
        value: monto_emision,
        disabled: true,
        show: this.state.monto_emision.show,
      },
      mantenimientoList: mantenimiento,
      listadoList: listado,
      combo_data: combo_data,
      tiposMercado: tipoMercado,
      radioSelected: radioSelected,
    });
  }

  getTiposMercadoTab(mercados) {
    let tipoMercado = [];
    mercados.map((item) => {
      let nombre = null;
      if (getLan() !== "es") {
        switch (item.id) {
          case "1":
            nombre = "Equity";
            break;
          case "2":
            nombre = "Debt";
            break;
          default:
            nombre = item.nombre;
            break;
        }
      } else {
        nombre = item.nombre;
      }
      let tipoMercado_ = { id: item.id, nombre: nombre };
      if (this.state.indexSelected === 1 && item.id !== "3,4") {
        tipoMercado.push(tipoMercado_);
      } else if (this.state.indexSelected === 0) {
        tipoMercado.push(tipoMercado_);
      }
      return tipoMercado_;
    });
    return tipoMercado;
  }

  onChangeItemSelected = (index = 0) => {
    let listado = null;
    let mantenimiento = null;
    let combo_data = null;
    let titulo = "";
    if (index === 0) {
      //listado
      listado = true;
      mantenimiento = false;
      combo_data = this.state.listadoList;
      titulo = getLan() === "es" ? "Costos de listado" : "Listing fees";
    } else {
      //mantenimiento
      listado = false;
      mantenimiento = true;
      combo_data = this.state.mantenimientoList;
      titulo =
        getLan() === "es" ? "Costos de mantenimiento" : "Maintenance fees";
    }
    this.setState({
      indexSelected: index,
      listado: listado,
      mantenimiento: mantenimiento,
      combo_data: combo_data,
      titulo_card: titulo,
      mostrar_resultado: false,
      needParams: false,
    });
  };

  limpiarForm = (form) => {
    form.reset();

    let radioSelected = null;
    let tiposMercado = null;
    if (this.state.tiposMercado) {
      tiposMercado = this.state.tiposMercado;
      radioSelected = tiposMercado[0].id;
      if (radioSelected !== this.state.radioSelected) {
        this.props.onInitQuotas(radioSelected);
      }
    }

    this.setState({
      radioSelected: radioSelected,
      tipoMercado: {
        value: radioSelected,
        disabled: false,
      },
      tipo_valor: {
        value: "",
        disabled: false,
      },
      monto_emision: {
        value: null,
        disabled: false,
        show: false,
      },
      fecha_de: {
        value: "",
        disabled: false,
      },
      fecha_hasta: {
        value: "",
        disabled: false,
      },
      dias_cobro: {
        value: 0,
        disabled: false,
      },
      quota: {
        value: null,
        disabled: true,
      },
      mostrar_resultado: false,
      needParams: false,
    });
  };

  tipoValorChange = (event) => {
    var option = this.state.combo_data.find(
      (option) => option.id === parseInt(event.target.value)
    );
    const monto_emision = { ...this.state.monto_emision };
    let today = new Date();

    let fechaIni = today.toISOString().split("T")[0];

    let anio = today.getFullYear();
    let ultimo_dia_anio = anio + "-12-31";
    this.fechaCambia(null, fechaIni, ultimo_dia_anio);

    let needParams = false;
    let escondeFechas = false;
    let escondeDiasCobro = false;
    if (option !== undefined) {
      needParams = option.needParams;
      if (needParams) {
        monto_emision.show = true;
        monto_emision.value = null;
      }
      if (parseInt(option.id) === 12) {
        monto_emision.show = false;
        monto_emision.value = null;
      }
      if (parseInt(option.id) === 9) {
        let primer_dia_anio = anio + "-01-01";

        let diasDif = this.fechaCambia(null, primer_dia_anio, ultimo_dia_anio);

        const dias_cobro = this.state.dias_cobro;
        monto_emision.show = true;
        monto_emision.value = null;
        dias_cobro.value = diasDif;
        dias_cobro.disabled = true;
        escondeDiasCobro = true;
        escondeFechas = true;
        this.setState({
          dias_cobro: dias_cobro,
        });
      }
    }
    this.setState({
      escondeDiasCobro: escondeDiasCobro,
      escondeFechas: escondeFechas,
      mostrar_resultado: false,
      tipo_valor: {
        value: event.target.value,
        disabled: false,
      },
      monto_emision: monto_emision,
      needParams: needParams,
      quota: {
        value: null,
        disabled: true,
      },
    });
  };
  montoChange = (event) => {
    this.setState({
      mostrar_resultado: false,
      monto_emision: {
        value: event.target.value,
        disabled: false,
        show: true,
      },
      quota: {
        value: null,
        disabled: true,
      },
    });
  };

  onRadioChange = (event) => {
    this.setState({
      radioSelected: event.target.value,
      tipoMercado: {
        value: event.target.value,
        disabled: false,
      },
      tipo_valor: {
        value: "",
        disabled: false,
      },
      monto_emision: {
        value: null,
        disabled: false,
        show: false,
      },
      dias_cobro: {
        value: 0,
        disabled: false,
      },
      quota: {
        value: null,
        disabled: true,
      },
      mostrar_resultado: false,
      needParams: false,
    });

    let today = new Date();

    let fechaIni = today.toISOString().split("T")[0];

    let anio = today.getFullYear();
    let ultimo_dia_anio = anio + "-12-31";
    this.fechaCambia(null, fechaIni, ultimo_dia_anio);

    this.props.onInitQuotas(event.target.value);
  };

  fechaCambia = (event, fecha_inicio, fecha_fin) => {
    var diff = 0;
    if (fecha_inicio !== "" && fecha_fin !== "") {
      var fechaI = new Date(fecha_inicio).getTime();
      var fechaF = new Date(fecha_fin).getTime();

      diff = fechaF - fechaI;
      if (this.state.listado) {
        diff = diff / (1000 * 60 * 60 * 24);
      } else {
        diff = diff / (1000 * 60 * 60 * 24) + 1;
      }
      if (diff < 0) {
        diff = 0;
      }
    }

    let monto_emision = null;
    if (this.state.monto_emision.value !== null) {
      monto_emision = this.state.monto_emision.value.toString();
      monto_emision = monto_emision.replace("$", "");
      monto_emision =
        monto_emision === null
          ? "" // make controlled
          : numeral(monto_emision).format("$0,0.00");
    }
    const monto_emision_form = this.state.monto_emision;
    monto_emision_form.value = monto_emision;
    this.setState({
      mostrar_resultado: false,
      fecha_de: {
        value: fecha_inicio,
        disabled: false,
      },
      fecha_hasta: {
        value: fecha_fin,
        disabled: false,
      },
      dias_cobro: {
        value: diff,
        disabled: true,
      },
      quota: {
        value: null,
        disabled: true,
      },
      monto_emision: monto_emision_form,
    });
    return diff;
  };
  montoSinFormato = (monto) => {
    let monto_return = monto.replace("$", "");
    monto_return = monto_return.split(",").join("");
    monto_return = parseFloat(monto_return);
    return monto_return;
  };

  calcular = (event) => {
    let monto_emision = 0;
    if (this.state.monto_emision.show) {
      if (this.state.monto_emision.value !== null) {
        monto_emision = this.state.monto_emision.value.toString();
        monto_emision = this.montoSinFormato(monto_emision);
      }
    }
    this.props.onInitQuotasCalculation(
      this.state.needParams,
      this.state.tipo_valor.value,
      monto_emision,
      this.state.dias_cobro.value
    );
    this.setState({
      mostrar_resultado: true,
    });
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    let error = false,
      errorFecha = false,
      errorTipoValor = false,
      errorMonto = false,
      errorFechaInicialMayor = false,
      errorFechaInicialMenor = false,
      errorFechaFinalMayor = false,
      errorFechaFinalMenor = false;

    const fecha_inicio = this.state.fecha_de.value
      ? this.state.fecha_de.value
      : null;
    const fecha_fin = this.state.fecha_hasta.value
      ? this.state.fecha_hasta.value
      : null;
    //22 corto plazo
    //23 deuda estructurada a corto plazo
    const conditionTypeValue = this.isTypeValueCondition(
      this.state.tipo_valor.value
    );
    if (this.getErrorDate(1, fecha_inicio, conditionTypeValue)) {
      error = true;
      errorFechaInicialMenor = true;
    } else if (this.getErrorDate(2, fecha_inicio, conditionTypeValue)) {
      error = true;
      errorFechaInicialMayor = true;
    }

    if (this.getErrorDate(1, fecha_fin, conditionTypeValue)) {
      error = true;
      errorFechaFinalMenor = true;
    } else if (this.getErrorDate(2, fecha_fin, conditionTypeValue)) {
      error = true;
      errorFechaFinalMayor = true;
    }

    if (
      (fecha_fin < fecha_inicio ||
        fecha_fin === null ||
        fecha_inicio === null) &&
      this.state.needParams
    ) {
      error = true;
      errorFecha = true;
      this.setState({
        errorFecha: true,
      });
    } else {
      errorFecha = false;
    }
    if (this.state.tipo_valor.value === "") {
      error = true;

      errorTipoValor = true;
    } else {
      errorTipoValor = false;
    }

    let monto_emision = 0;

    if (this.state.monto_emision.value !== null && this.state.needParams) {
      monto_emision = this.state.monto_emision.value.toString();
      // monto_emision = monto_emision.replace(",", "").replace("$", "");
      monto_emision = this.montoSinFormato(monto_emision);
      if (!this.state.monto_emision.show) {
        monto_emision = 0;
      }
    }
    if (
      monto_emision < 1 &&
      this.state.needParams &&
      this.state.monto_emision.show
    ) {
      error = true;

      errorMonto = true;
    } else {
      errorMonto = false;
    }

    this.setState({
      errorFecha: errorFecha,
      errorFechaInicialMayor: errorFechaInicialMayor,
      errorFechaInicialMenor: errorFechaInicialMenor,
      errorFechaFinalMayor: errorFechaFinalMayor,
      errorFechaFinalMenor: errorFechaFinalMenor,
      errorTipoValor: errorTipoValor,
      errorMonto: errorMonto,
    });

    if (!error) {
      this.calcular(event);
    }
    return false;
  };

  isTypeValueCondition(typeValue = null) {
    if (typeValue) {
      if (ids_typeValue_exceptions.find((item) => item === +typeValue)) {
        return true;
      }
    }
    return false;
  }

  isMinorDate(date) {
    return date < this.state.fecha_minima;
  }
  isMayorDate(date) {
    return date > this.state.fecha_maxima;
  }

  getErrorDate(type, date, typeValue) {
    if (type === 1) {
      if (
        typeValue === false &&
        this.isMinorDate(date) &&
        this.state.needParams
      ) {
        return true;
      }
    }
    if (type === 2) {
      if (
        typeValue === false &&
        this.isMayorDate(date) &&
        this.state.needParams
      ) {
        return true;
      }
    }

    return false;
  }

  render() {
    let formulario = null;

    const tabMostrar = (
      <div className="row margen_top_30">
        <div
          className={[
            "col-sm-12 sin_padding_L sin_padding_R",
            classes.myCol,
          ].join(" ")}
        >
          <Tabs
            data={tabs_cuotas}
            tipo={3}
            clicked={(i) => this.onChangeItemSelected(i)}
          />
        </div>
      </div>
    );
    let errorFecha = null;
    if (this.state.errorFecha) {
      errorFecha = (
        <div className="col-sm-12 sin_padding_L">
          <span className={classes.error}>
            {getLan() === "es"
              ? "Las fechas son campos obligatorios / fecha 'Hasta' no puede ser menor que fecha 'De'"
              : "Dates are required fields / Date 'To' cannot be less than date 'From"}
          </span>
        </div>
      );
    }

    let errorFechaInicialMenor = null;
    if (this.state.errorFechaInicialMenor) {
      errorFechaInicialMenor = (
        <div className="col-sm-12 sin_padding_L">
          <span className={classes.error}>
            {getLan() === "es"
              ? "La fecha 'De' no puede ser menor a "
              : "Date 'From' cannot be less than "}
            {formatDate_ddMMyyy_millies(this.state.fecha_minima, 2)}
          </span>
        </div>
      );
    }
    let errorFechaInicialMayor = null;
    if (this.state.errorFechaInicialMayor) {
      errorFechaInicialMayor = (
        <div className="col-sm-12 sin_padding_L">
          <span className={classes.error}>
            {getLan() === "es"
              ? "La fecha 'De' no puede ser mayor a "
              : "Date 'From' must be greater than "}{" "}
            {formatDate_ddMMyyy_millies(this.state.fecha_maxima, 2)}
          </span>
        </div>
      );
    }
    let errorFechaFinalMenor = null;
    if (this.state.errorFechaFinalMenor) {
      errorFechaFinalMenor = (
        <div className="col-sm-12 sin_padding_L">
          <span className={classes.error}>
            {getLan() === "es"
              ? "La fecha 'Hasta' no puede ser menor a "
              : "Date 'To' cannot be less than"}{" "}
            {formatDate_ddMMyyy_millies(this.state.fecha_minima, 2)}
          </span>
        </div>
      );
    }
    let errorFechaFinalMayor = null;
    if (this.state.errorFechaFinalMayor) {
      errorFechaFinalMayor = (
        <div className="col-sm-12 sin_padding_L">
          <span className={classes.error}>
            {getLan() === "es"
              ? "La fecha 'Hasta' no puede ser mayor a"
              : "Date 'To' must not be greater than "}{" "}
            {formatDate_ddMMyyy_millies(this.state.fecha_maxima, 2)}
          </span>
        </div>
      );
    }

    let errorTipoValor = null;
    if (this.state.errorTipoValor) {
      errorTipoValor = (
        <div
          className={["col-sm-12 sin_padding_L", classes.error_div].join(" ")}
        >
          <span className={classes.error}>
            {getLan() === "es"
              ? "Selecciona un tipo de valor"
              : "Select a Instrument type"}
          </span>
        </div>
      );
    }

    let errorMonto = null;
    if (this.state.errorMonto) {
      errorMonto = (
        <div
          className={["col-sm-12 sin_padding_L", classes.error_div].join(" ")}
        >
          <span className={classes.error}>
            {getLan() === "es"
              ? "Monto de emisión/capital contable debe ser mayor a '$0.00'"
              : "The amount of equity to prove must be over '$0.00'"}
          </span>
        </div>
      );
    }

    let class_esconder = null;
    let class_esconder_resultado = null;
    if (this.state.needParams === false) {
      class_esconder = "d-none";
    }

    if (!this.state.mostrar_resultado) {
      class_esconder_resultado = "d-none";
    }

    formulario = (
      <Form
        onSubmit={this.onSubmitHandler}
        initialValues={{
          tipoMercado: this.state.tipoMercado.value,
          tipo_valor: this.state.tipo_valor.value,
          monto_emision: this.state.monto_emision.value,
          fecha_de: this.state.fecha_de.value,
          fecha_hasta: this.state.fecha_hasta.value,
          dias_cobro: this.state.dias_cobro.value,
          quota: this.state.quota.value
            ? parseFloat(this.state.quota.value.replace("$", "")) > 0
              ? this.state.quota.value
              : "$ 0.00"
            : "$ 0.00",
        }}
        render={({ form }) => (
          <form ref={this.formRef1} onSubmit={this.onSubmitHandler}>
            <div className={classes.search_container}>
              <div className="input-group margin_top_30">
                <div className="row">
                  <div className="col-sm-12 sin_padding_L">
                    <div
                      className={[
                        "row",
                        classes.form_group_container,
                        classes.radioContainer,
                      ].join(" ")}
                    >
                      <p
                        className={[
                          "col-sm-12 sin_padding_L sin_padding_R montse texto_14",
                        ].join(" ")}
                      >
                        {getLan() === "es" ? "Tipo de mercado" : "Market type"}{" "}
                      </p>
                      <div className={["form-group form-check "].join(" ")}>
                        {this.state.tiposMercado.map((item, i) => (
                          <div
                            className={[
                              "col-sm-12 col-xl-6 sin_padding_L sin_padding_R",
                              classes.radios,
                            ].join(" ")}
                          >
                            <Field
                              name="itemes"
                              id={i}
                              type="radio"
                              elementType="radio2"
                              value={item.id}
                              classes="form-check-input"
                              label={item.nombre}
                              checked={
                                this.state.radioSelected.toString() === item.id
                              }
                              onInputChange={(event) =>
                                this.onRadioChange(event)
                              }
                              component={renderInput}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={[
                        classes.form_group_container,
                        classes.margen_message,
                        "form-group form-check row",
                        this.state.indexSelected === 0 ? "d-none" : null,
                      ].join(" ")}
                    >
                      <span className="texto_12 montse regular">
                        *
                        {getLan() === "es"
                          ? "Mercado SIC no tiene costo de mantenimiento"
                          : "SIC Market has no maintenance fees"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-group ">
                <div className="row">
                  <div className="col-sm-12 sin_padding_L">
                    <div
                      className={[
                        "form-group form-check row",
                        classes.form_group_container,
                      ].join(" ")}
                    >
                      <p className="col-sm-12 sin_padding_L montse texto_14">
                        {getLan() === "es"
                          ? "Tipo de valor"
                          : "Instrument type"}{" "}
                      </p>
                      <div className="col-sm-12 sin_padding">
                        <Field
                          name="tipo_valor"
                          id="tipo_valor"
                          label="tipo_valor"
                          elementType="select"
                          type="select"
                          classes={[
                            "custom-select montse texto_14",
                            classes.max_width,
                            classes.texto_montse,
                          ].join(" ")}
                          options={this.state.combo_data}
                          onInputChange={(event) => this.tipoValorChange(event)}
                          component={renderInput}
                        />
                      </div>
                      {errorTipoValor}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={[
                  "input-group ",
                  class_esconder,
                  this.state.monto_emision.show ? null : "d-none",
                ].join(" ")}
              >
                <div className="row">
                  <div className="col-sm-12 sin_padding_L">
                    <div
                      class={[
                        "form-group form-check row",
                        classes.form_group_container,
                      ].join(" ")}
                    >
                      <p className="col-sm-12 sin_padding_L montse texto_14">
                        {getLan() === "es"
                          ? "Monto de emisión/capital contable"
                          : "Issued amount/shareholder’s equity"}{" "}
                      </p>
                      <Field
                        name="monto_emision"
                        id="monto_emision"
                        classes={["form-control", classes.max_width].join(" ")}
                        type="text"
                        format={formatPrice}
                        elementType="input"
                        formatOnBlur
                        placeholder="$0.00"
                        component={renderInput}
                        onInputChange={(event) => this.montoChange(event)}
                      />
                      {errorMonto}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={[
                  "input-group ",
                  class_esconder,
                  this.state.escondeFechas ? "d-none" : null,
                ].join(" ")}
              >
                <div className="row">
                  <div className="col-sm-12 sin_padding_L">
                    <div
                      class={[
                        "form-group form-check row",
                        classes.form_group_container,
                      ].join(" ")}
                    >
                      <p className="col-sm-12 sin_padding_L montse texto_14">
                        {getLan() === "es" ? "Fecha" : "Date"}{" "}
                      </p>
                      <div className="row">
                        <div className={[classes.fechas_container].join(" ")}>
                          {" "}
                          <Field
                            name="fecha_de"
                            id="fecha_de"
                            placeholder="Date"
                            type="date"
                            classes={classes.fecha_inicio}
                            label={getLan() === "es" ? "De:" : "From:"}
                            onInputChange={(event) =>
                              this.fechaCambia(
                                event,
                                event.target.value,
                                this.state.fecha_hasta.value
                              )
                            }
                            component={renderInput2}
                          />
                        </div>
                        <div className={[classes.fechas_container].join(" ")}>
                          <Field
                            name="fecha_hasta"
                            id="fecha_hasta"
                            type="date"
                            classes={classes.fecha_fin}
                            label={getLan() === "es" ? "Hasta:" : "To:"}
                            onInputChange={(event) =>
                              this.fechaCambia(
                                event,
                                this.state.fecha_de.value,
                                event.target.value
                              )
                            }
                            component={renderInput2}
                          />
                        </div>
                      </div>
                      {errorFecha}
                      {errorFechaInicialMenor}
                      {errorFechaInicialMayor}
                      {errorFechaFinalMenor}
                      {errorFechaFinalMayor}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={[
                  "input-group ",
                  class_esconder,
                  this.state.escondeDiasCobro ? "d-none" : null,
                ].join(" ")}
              >
                <div className="row">
                  <div className="col-sm-12 sin_padding_L">
                    <div
                      class={[
                        "form-group form-check row",
                        classes.form_group_container,
                      ].join(" ")}
                    >
                      <p className="col-sm-12 sin_padding_L montse texto_14">
                        {getLan() === "es"
                          ? "Días de cobro"
                          : "Receivable dates"}{" "}
                      </p>

                      <Field
                        name="dias_cobro"
                        id="dias_cobro"
                        classes={["form-control", classes.max_width].join(" ")}
                        type="text"
                        elementType="input"
                        placeholder=""
                        disabled
                        component={renderInput}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={["input-group ", class_esconder_resultado].join(" ")}
              >
                <div className="row">
                  <div className="col-12 col-sm-12 sin_padding_L">
                    <div
                      class={[
                        "form-group form-check row",
                        classes.form_group_container,
                      ].join(" ")}
                    >
                      <p className="col-12 col-sm-12 sin_padding_L montse texto_14">
                        {getLan() === "es"
                          ? "Cuota (no incluye IVA)"
                          : "Fee (does not include VAT)"}{" "}
                      </p>

                      <Field
                        name="quota"
                        id="quota"
                        classes={[
                          "form-control",
                          classes.max_width,
                          classes.cuota_resultado,
                        ].join(" ")}
                        type="text"
                        elementType="input"
                        placeholder=""
                        disabled
                        component={renderInput}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-group margin_top_30 margin_bottom_30">
                <div className="row">
                  <div className="col-sm-12 sin_padding_L">
                    <div
                      class={[
                        "form-group form-check row",
                        classes.form_group_container,
                      ].join(" ")}
                    >
                      <div className="float-left">
                        <button
                          type="button"
                          className={[
                            "btn",
                            classes.button_limpiar,
                            classes.button,
                            "nunito",
                            "texto_16 bold",
                          ].join(" ")}
                          onClick={() => this.limpiarForm(form)}
                        >
                          {getLan() === "es" ? "Limpiar" : "Clear"}
                        </button>
                      </div>
                      <div className="float-left">
                        <button
                          type="submit"
                          className={[
                            "btn",
                            classes.button_calcular,
                            classes.button,
                            "nunito",
                            "texto_16 bold",
                          ].join(" ")}
                        >
                          {getLan() === "es" ? "Calcular" : "Calculate"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    );

    let cuotas = null;
    if (this.props.cuotas) {
      cuotas = this.props.cuotas.map((cuota, i) => {
        return <Box key={"cuota" + i} modelo="modelo_1" data={cuota} />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={"texto_42 nunito regular"}>
            <FormattedMessage
              id="empresas.listadodevalores.comolistarse.cuotas.titulo1"
              defaultMessage=" "
            />{" "}
          </h1>
          <div className="margen_top_50">{tabMostrar}</div>
          <div className="margen_top_50">
            <div className={["card", classes.card].join(" ")}>
              <div className={["card-header", classes.card_header].join(" ")}>
                {" "}
                <span className={"nunito texto_16"}>
                  {" "}
                  {this.state.titulo_card}{" "}
                </span>
              </div>
              <div className={classes.card_body}>{formulario}</div>
            </div>
          </div>

          <div
            className={[
              "row margen_top_50",
              this.props.cuotas ? null : "d-none",
            ].join(" ")}
          >
            <div className="col">
              <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedMessage
                    id={
                      this.state.indexSelected === 0
                        ? "empresas.listadodevalores.comolistarse.cuotas.subtitulo1"
                        : "empresas.listadodevalores.comolistarse.cuotas.subtitulo2"
                    }
                    defaultMessage=" "
                  />{" "}
                </h2>
              </div>
            </div>
            <div className="row width_cien margen_top_30">{cuotas}</div>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitAllTipoMercado: () => {
      dispatch(actions.initAllTipoMercado());
    },
    onInitQuotas: (idTipoMercado) => {
      dispatch(actions.initQuotas(idTipoMercado));
    },
    onInitQuotasCalculation: (neddParams, id, monto, dias) => {
      dispatch(actions.initQuotasCalculation(neddParams, id, monto, dias));
    },
    onCuotas: () => {
      dispatch(actions.getEnlacesCuotaListado());
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(63));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    diccionario: state.intl.diccionario,
    quotas: state.emisoras.quotas,
    quota: state.emisoras.quota,
    tipoMercado: state.emisoras.tipoMercado,
    cuotas: state.documentos.enlacesCuotaListado,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cuotas);

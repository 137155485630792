import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../stores/actions/index";
import classes from "./ProspectosColocacion.css";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";
import FilterProspectos from "../../../../components/ui/filterProspectos/FilterProspectos";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import Rss from "../../../rss/Rss";
import queryString from "query-string";
import TabASG from "../../../sustentable/tabASG/TabASG";
import TituloAsg from "../../../sustentable/ui/tituloAsg/TituloAsg";


const dominio = `${window.location.protocol}//${window.location.host}`;

class ProspectosColocacion extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    razonSocial: null,
    clave: null,
    numeroTramite: 0,
    tipoValor: null,
    datos: null,
    emisoraSeleccionada: null,
    mercado: null,
    instrumento: null,
    urlForRss: `${dominio}/emisoras/prospectos/rss`,
    documentos: null,
    detalles: null,
    wasFiltered: false,
    messages: null,
    lan: getLocal(),
    asg : false,
    tiposBonosDisable:[],
    tiposBonosInit:[],
    tiposBonos:[]

  };

  componentWillMount() {
    this.props.onInitIntl();
    
    let paramsString = queryString.parse(this.props.location.search);
    let instrumento =
      paramsString["instrumento"] !== undefined && paramsString["instrumento"] !== "null" ? paramsString["instrumento"] : null;
    let mercado = paramsString["mercado"] !== undefined && paramsString["mercado"] !== "null" ? paramsString["mercado"] : null;
    let emisora = paramsString["emisora"] !== undefined && paramsString["emisora"] !== "null" ? paramsString["emisora"] : null;
    let clave = paramsString["clave"] !== undefined && paramsString["clave"] !== "null" ? paramsString["clave"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    let tipoBono = paramsString["tipoBono"] === "null" || paramsString["tipoBono"] === undefined ? null : paramsString["tipoBono"].split(",");  
    
    let asg = false;
    if( this.props.location.pathname === "/biva-sostenible/prospectos"){
      asg = true;
      this.props.onInitTiposBonosASG();
      if (tipoBono === null) 
        tipoBono=[1,2,3,4];
    }
    this.setState({
      mercado: mercado,
      instrumento: instrumento,
      clave: clave,
      emisoraSeleccionada: emisora,
      activePage: page ,
      tiposBonos: tipoBono,
      asg:asg,
    });
    this.props.onInitProspectosColocacion(
      this.state.itemsPerPage,
      page -1 ,
      mercado,
      instrumento,
      emisora,
      clave,
      tipoBono,
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.prospectos !== prevProps.prospectos) {
      if (this.state.emisoraSeleccionada !== null && this.props.prospectos.length !== 0) {
        this.updateGrandparentListado(this.props.prospectos.content[0].id);
      }
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let prospectos = null;
    let documentos = null;
    let detalles = null;

    if (nextProps.prospectos && nextProps.prospectos.content) {
      prospectos = nextProps.prospectos.content;
    }
    if (nextProps.documentos !== this.props.documentos) {
      documentos = nextProps.documentos;
    }
    if (nextProps.detalleProspectos !== this.props.detalleProspectos) {
      if (nextProps.detalleProspectos) {
        detalles = nextProps.detalleProspectos;
      }
    } else {
      detalles = this.props.detalleProspectos;
    }

    this.setState({
      documentos: documentos,
      detalles: detalles,
    });

    let resource = prospectos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.prospectos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.changeHistory(
        pageNumber,
        this.state.mercado,
        this.state.instrumento,
        this.state.emisoraSeleccionada,
        this.state.clave,
        this.state.tiposBonos
      );

      this.onPageChangeHandler(null, pageNumber);
    }
  }
  
  updateGrandparentListado = (empresaId, razonSocial, clave, numeroTramite, tipoValor) => {
    let datos = {
      id: empresaId,
      numeroTramite: numeroTramite,
      tipoValor: tipoValor,
      clave: clave,
      razonSocial: razonSocial
    };
    this.props.onInitDetalleProspectos(empresaId, this.state.tiposBonos);
    this.setState({
      tramiteSeleccionado: empresaId,
      razonSocial: razonSocial,
      numeroTramite: numeroTramite !== undefined ? numeroTramite : this.state.numeroTramite,
      tipoValor: tipoValor,
      datos: datos,
      detalles: null
    });
  };

  onSetMercado = mercado => {
    this.setState({
      mercado: mercado
    });
  };

  onSetInstrumento = instrumento => {
    this.setState({
      instrumento: instrumento
    });
  };

  onSetEmisora = (emisora, tipo) => {
    let emisoraSeleccionada = null;
    let clave = null;

    if (tipo === 1) {
      emisoraSeleccionada = emisora;
    }
    if (tipo === 2) {
      clave = emisora;
    }

    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      clave: clave
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  onSetTiposBono= (tipos) => {
    this.changeHistory(1, this.state.mercado,this.state.instrumento,this.state.emisoraSeleccionada, this.state.clave, tipos);
    this.onSetPage(1);

    this.setState({
      tiposBonos: tipos,
    });
  };

  onSetParams = ({ page, mercado, instrumento, emisora, clave, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(page, mercado, instrumento, emisora, clave, this.state.tiposBonos);
    }
  };

  changeHistory(page = null, mercado = null, instrumento = null, emisora = null, clave = null, tipoBono= null) {
    this.props.history.push(
      `?mercado=${emisora ? null : mercado}&instrumento=${emisora ? null : instrumento
      }&emisora=${emisora}&clave=${clave}&tipoBono=${tipoBono}&page=${page ? page : this.state.activePage}`
    );

    this.setState({
      loader: true
    });
    this.props.onInitProspectosColocacion(
      this.state.itemsPerPage,
      page >= 1 ? page - 1 : 0,
      emisora ? null : mercado,
      emisora ? null : instrumento,
      emisora,
      clave,
      tipoBono,
    );
  }

  changeHistoryReset() {
    this.props.history.push(`?mercado=${null}&instrumento=${null}&emisora=${null}&clave=${null}&page=${1}`);
    this.setState({
      loader: true
    });
    this.props.onInitProspectosColocacion(this.state.itemsPerPage, 0, null, null, null, null,this.state.tiposBonos);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let contenidoMostrar = null;
    let tabBonoASGMostrar = null;

    let prospectos = this.props.prospectos && this.props.prospectos.content ? this.props.prospectos.content : [];
      if (prospectos.length > 0){
      prospectos = this.props.prospectos.content;

      const numRows = this.props.prospectos ? this.props.prospectos.totalElements : 0;

      contenidoMostrar = (
        <React.Fragment>
          <Accordion
            datos={prospectos}
            nombre="prospectos"
            icon={""}
            tipo={15}
            subtipo={4}
            documentos={this.state.documentos}
            detallesProspectos={this.state.detalles}
            numeroTramiteSeleccionado={this.state.numeroTramiteSeleccionado}
            updateGrandparentListado={this.updateGrandparentListado.bind(this)}
            tiposBonosASG={this.state.tiposBonos}
            isSustentable={this.state.asg ? true : false}
          />
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
            isSustentable={this.state.asg ? true : false}
          />
        </React.Fragment>
      );
    }else if (prospectos.length === 0) {
      contenidoMostrar = <p className="montse texto_16 regular"> {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    } else {
      if (this.state.wasFiltered) {
        contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
      }
    }

    if (this.state.asg === true){
    tabBonoASGMostrar = (
      <div className="row margen_top_30">
        <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
          <div className={classes.vertical_div}>
                        <span className={classes.vertical_content}>
                        {getLan() === "es" ? "Mostrar" : "View"}:
                        </span>
          </div>
        </div>
        <div className={["col sin_padding", classes.myCol].join(" ")}>
          <TabASG     
            tiposBonosInicial={this.state.tiposBonosInicial}
            tiposBonos={this.state.tiposBonos}
            setTiposBono={tipos => this.onSetTiposBono(tipos)}
          />
        </div>
      </div>
    );
  }


    let filtro = (
      <div className="margen_top_30">
        <FilterProspectos
          itemsPerPage={this.state.itemsPerPage}
          setInstrumento={instrumento => this.onSetInstrumento(instrumento)}
          setMercado={mercado => this.onSetMercado(mercado)}
          setPage={page => this.onSetPage(page)}
          setEmisora={(emisora, tipo) => this.onSetEmisora(emisora, tipo)}
          tab={this.state.indexSelected}
          wasFiltered={wasFilteres => this.onSetWasFiltered(wasFilteres)}
          setParams={filterParams => this.onSetParams(filterParams)}
          location={this.props.location}
          tipoBono={this.state.tiposBonos}
          isSustentable={this.state.asg ? true : false}
        />
      </div>
    );

    const titleAsg = this.state.messages && this.state.messages !== undefined ? this.state.messages["empresas.listadodevalores.tramites.prospectos.titulo1"] + " ASG" : null;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
         
            {this.state.asg === true ?  
            <TituloAsg titulo={titleAsg} />
            :  <h1 className={"texto_42 nunito regular"}><FormattedHTMLMessage id="empresas.listadodevalores.tramites.prospectos.titulo1" defaultMessage=" " /> </h1>  }
                  
          <div className="margen_top_30 texto_16 montse regular">
            <FormattedHTMLMessage id="empresas.listadodevalores.tramites.prospectos.introduccion1" defaultMessage=" " />
          </div>
          {tabBonoASGMostrar}
          {filtro}
          <br />
          <button
            className={["btn btn-link margen_50", classes.btn_link].join(" ")}
            type="button"
            onClick={this.onShowRssModalHandler}
          >
            <div className="margen_50 texto_16 montse regular texto_naranja">
              {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
              <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </button>
          <Rss
            showRssModal={this.state.showRssModal}
            modalTitle={getLan() === "es" ? "Prospectos de colocación" : "Prospectus"}
            urlRss={this.state.urlForRss}
            rssType={1}
            showClassModel1
          />
          <div id="prospectos" className="margen_50">
            {contenidoMostrar}
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitSolicitudes: (size, page) => {
      dispatch(actions.initSolicitudes(size, page));
    },
    onInitProspectosColocacion: (size, page, mercado, instrumento, emisora, clave, tipoBono) => {
      dispatch(actions.initProspectosColocacion(size, page, mercado, instrumento, emisora, clave, tipoBono));
    },
    onInitDocumentosProspectos: (size, page, emisoraId, tipoBono) => {
      dispatch(actions.initDocumentosProspectos(size, page, emisoraId, tipoBono));
    },
    onInitDetalleProspectos: (emisoraId, tipoBono) => {
      dispatch(actions.initDetalleProspectos(emisoraId, tipoBono));
    },
    onInitTiposBonosASG: () => {
        dispatch(actions.getTiposBono());
      },
    onInitIntl: () => {
      dispatch(actions.initIntl(65));
    }
  };
};

const mapStateToProps = state => {
  return {
    solicitudes: state.listadoValores.solicitudes,
    documentos: state.listadoValores.documentosProspectos,
    prospectos: state.listadoValores.prospectos,
    detalleProspectos: state.listadoValores.detalleProspectos,
    diccionario: state.intl.diccionario,
    tiposBonoASG: state.listadoValores.tiposBonoASG
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProspectosColocacion);
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Evento from './evento/Evento';
import Eventos_ from './evento/Eventos';

class Eventos extends Component {
  render() {
    const routes = (
      <Switch>
        <Route path="/nosotros/acerca_de/eventos" exact  component={Eventos_} />
        <Route path="/nosotros/acerca_de/eventos/evento/:eventoId"  component={Evento} />
      </Switch>
    );
    return (
      <React.Fragment>
            {routes}
      </React.Fragment>
    );
  }
}

export default Eventos;

import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import classes from "./Recursos.css";
import flechaAzul from "../../../../assets/images/sustentable/flecha-azul.png";
const triggerScene = false;

const Recursos = () => {
  return (
    <>
      <Controller>
        <Scene
          indicators={triggerScene}
          reverse={true}
          triggerHook="onLeave"
          duration={6000}
          pin
        >
          {(progress) => (
            <div className="col sin_padding">
              <div
                className={[
                  "nunito",
                  "texto_30",
                  "margen_top_50",
                  "main_subtitle",
                ].join(" ")}
              >
                <span className={"slash"}>/</span>
                <h2> Recursos de la Guía</h2>
              </div>
              <div className="col-12 sin_padding" id="trigger">
                <div className="row">
                  <div
                    className={[
                      "col-12 col-md-6",
                      classes.containerRecuadro,
                      classes.ocultarImagen,
                    ].join(" ")}
                  >
                    <Timeline totalProgress={progress} duration={6000} paused>
                      <Tween
                        from={{ x: "0%", rotation: 0, duration: 0 }}
                        to={{ x: "0%", rotation: 310 }}
                      >
                        <img src={flechaAzul} alt="" />
                      </Tween>
                    </Timeline>
                    <div
                      id="trigger2"
                      className={[
                        classes.recuadro,
                        classes.cuadro1,
                        "align-self-center",
                      ].join(" ")}
                    >
                      <span
                        className={[
                          "texto_18 color_blanco nunito bold",
                          classes.textoRecuadro,
                        ].join(" ")}
                      >
                        A. Principios
                      </span>
                    </div>
                    <div
                      className={[
                        classes.recuadro,
                        classes.cuadro2,
                        "align-self-center",
                      ].join(" ")}
                    >
                      <span
                        className={[
                          "texto_18 color_blanco nunito bold",
                          classes.textoRecuadro,
                        ].join(" ")}
                      >
                        B. Visión de sostenibilidad en la empresa
                      </span>
                    </div>
                    <div
                      className={[
                        classes.recuadro,
                        classes.cuadro3,
                        "align-self-center",
                      ].join(" ")}
                    >
                      <span
                        className={[
                          "texto_18 color_blanco nunito bold",
                          classes.textoRecuadro,
                        ].join(" ")}
                      >
                        C. Del propósito a la estrategia
                      </span>
                    </div>
                    <div
                      className={[
                        classes.recuadro,
                        classes.cuadro4,
                        "align-self-center",
                      ].join(" ")}
                    >
                      <span
                        className={[
                          "texto_18 color_blanco nunito bold",
                          classes.textoRecuadro,
                        ].join(" ")}
                      >
                        D. De la estrategia a la acción
                      </span>
                    </div>
                    <div
                      className={[
                        classes.recuadro,
                        classes.cuadro5,
                        "align-self-center",
                      ].join(" ")}
                    >
                      <span
                        className={[
                          "texto_18 color_blanco nunito bold",
                          classes.textoRecuadro,
                        ].join(" ")}
                      >
                        E. Estructuras, liderazgo y cultura
                      </span>
                    </div>

                    <div
                      className={[
                        classes.recuadro,
                        classes.cuadro6,
                        "align-self-center",
                      ].join(" ")}
                    >
                      <span
                        className={[
                          "texto_18 color_blanco nunito bold",
                          classes.textoRecuadro,
                        ].join(" ")}
                      >
                        F. Medición y rendición de cuentas
                      </span>
                    </div>
                  </div>

                  <div
                    className={[
                      "col-12 col-md-6",
                      classes.containerRecuadro,
                      classes.padding,
                    ].join(" ")}
                  >
                    <div className={classes.contenedorTexto}>
                      <Timeline
                        totalProgress={progress * 1.3}
                        duration={6000}
                        paused
                      >
                        <Tween
                          from={{
                            opacity: 1,
                            duration: 0,
                            position: "absolute",
                          }}
                          to={{
                            duration: 200,
                            display: "none",
                            delay: 1,
                            opacity: 0,
                            ease: "Circ.easeOutExpo",
                          }}
                        >
                          <div className={classes.texto}>
                            <div className="row">
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.text_container,
                                    "montse",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <p className={"montse texto_18 regular bold"}>
                                    Principios{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.textos_html,
                                    "montse texto_16",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <ol>
                                    <li>
                                      Enfoque expansivo de responsabilidad.
                                    </li>
                                    <li>
                                      Propósito de trascendencia y generación de
                                      valor.
                                    </li>
                                    <li>Gestión integral de riesgos. </li>
                                    <li>
                                      Transparencia y rendición de cuentas
                                    </li>
                                    <li>
                                      Adaptación al cambio y mejora continua.
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tween>
                        <Tween
                          from={{
                            opacity: 0,
                            duration: 0,
                          }}
                          to={{
                            display: "none",
                            ease: "Circ.easeOutExpo",
                            duration: 200,
                            opacity: 1,
                            delay: 1,
                          }}
                        >
                          <div className={classes.texto}>
                            <div className="row">
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.text_container,
                                    "montse",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <p className={"montse texto_18 regular bold"}>
                                    Visión de sostenibilidad en la empresa
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.textos_html,
                                    "montse texto_16",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <span>
                                    La sostenibilidad es una visión dinámica y
                                    de largo plazo que se basa en la mejora
                                    continua para lograr la transformación de la
                                    empresa.{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tween>
                        <Tween
                          from={{
                            duration: 0,
                            opacity: 0,
                          }}
                          to={{
                            delay: 1,
                            duration: 200,
                            display: "none",
                            ease: "Circ.easeOutExpo",
                            opacity: 1,
                          }}
                        >
                          <div className={classes.texto}>
                            <div className="row">
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.text_container,
                                    "montse",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <p className={"montse texto_18 regular bold"}>
                                    Del propósito a la estrategia
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.textos_html,
                                    "montse texto_16",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <span>Es necesario: </span>
                                  <ul>
                                    <li>Dialogo con grupos de interés</li>
                                    <li>Análisis de riesgo </li>
                                    <li>Planificación estratégica </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tween>
                        <Tween
                          from={{
                            opacity: 0,
                            duration: 0,
                          }}
                          to={{
                            delay: 0.2,
                            opacity: 1,
                            duration: 200,
                            display: "none",
                            ease: "Circ.easeOutExpo",
                          }}
                        >
                          <div className={classes.texto}>
                            <div className="row">
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.text_container,
                                    "montse",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <p className={"montse texto_18 regular bold"}>
                                    De la estrategia a la acción
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.textos_html,
                                    "montse texto_16",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <span>Herramientas: </span>
                                  <ul>
                                    <li>Desarrollo de capacidades</li>
                                    <li>Pirámide de gestión </li>
                                    <li>Planes de acción </li>
                                    <li>
                                      Evaluación, transparencia y comunicación
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tween>
                        <Tween
                          from={{
                            opacity: 0,
                            duration: 0,
                          }}
                          to={{
                            delay: 0.3,
                            opacity: 1,
                            duration: 200,
                            display: "none",
                            ease: "Circ.easeOutExpo",
                          }}
                        >
                          <div className={classes.texto}>
                            <div className="row">
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.text_container,
                                    "montse",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <p className={"montse texto_18 regular bold"}>
                                    Estructuras, liderazgo y cultura
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.textos_html,
                                    "montse texto_16",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <span>Se define como:</span>
                                  <ul>
                                    <li>
                                      El consejo y el comité de sostenibilidad.
                                    </li>
                                    <li>Liderazgo y cultura organizacional.</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tween>
                        <Tween
                          from={{
                            opacity: 0,
                            duration: 0,
                          }}
                          to={{
                            delay: 0.4,
                            opacity: 1,
                            duration: 200,
                            ease: "Circ.easeOutExpo",
                          }}
                        >
                          <div className={classes.texto}>
                            <div className="row">
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.text_container,
                                    "montse",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <p className={"montse texto_18 regular bold"}>
                                    Medición y rendición de cuentas
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 sin_padding_L">
                                <div
                                  className={[
                                    classes.textos_html,
                                    "montse texto_16",
                                    "margen_top_10",
                                  ].join(" ")}
                                >
                                  <span>Es necesario contar con:</span>
                                  <ul>
                                    <li>Indicadores</li>
                                    <li>
                                      Mecanismos de transparencia y reporteo
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tween>
                      </Timeline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Scene>
      </Controller>
    </>
  );
};

export default Recursos;

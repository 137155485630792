import React from "react";
import hojaVerde from "../../../../assets/icons/sustentable/hoja-verde-2.png";

const TituloAsg = (props) => {
  if (props.titulo === null || props.titulo === undefined) {
    return null;
  }
  return (
    <div
      className={[
        "main_subtitle",
        "texto_40 ",
        "nunito",
        "containerTituloSg",
        "sin_padding",
      ].join(" ")}
    >
      <img
        src={hojaVerde}
        alt="bivasg"
        className={["slash", "imgSg"].join(" ")}
      />
      <h1 className={"tituloSg nunito texto_42 bold texto_verde_oscuro"}>
        {props.titulo}
      </h1>
    </div>
  );
};

export default TituloAsg;

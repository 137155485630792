import React, { Component } from "react";
import classes from "./ComoInvertir.css";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";

import Auxs from "../../../hoc/auxs/Auxs";
import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";
import SliderDocument from "../../../components/ui/sliderDocument/SliderDocument";

import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getUrlDocument } from "../../../stores/utils/utilities";

class ComoInvertir extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };
  componentDidMount() {
    this.props.onInitIntl(20);
    this.props.onToggleBox();
    this.props.onImagenProceso();
  }
  onPrintTerminosHandler = terminos => {
    return <ToggleBox terminos={terminos} />;
  };
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.togglebox !== prevProps.togglebox) {
      //se obtiene el diccionario del endpoint de traducciones
      this.setState({
        togglebox: this.props.togglebox
      });
    }
  }

  render() {
    let terminos = null;
    let urlImagen = null;

    // try {
    if (this.state.togglebox) {
      terminos = this.state.togglebox ? this.onPrintTerminosHandler(this.state.togglebox) : null;
    }
    if (this.props.imagenProceso) {
      urlImagen = this.props.imagenProceso ? getUrlDocument(this.props.imagenProceso[0].url) : null;
    }
    // } catch (error) {
    // }

    const casas_bolsa = this.props.casas ? <SliderDocument tipo={2} datos={this.props.casas} /> : null;
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={"margen_top_30"}>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.text_container, "text_30", "montse", "margen_top_50"].join(" ")}>
                <p className={"montse texto_16 regular"}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.introduccion1" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.terminosContainer, "margen_top_50"].join(" ")}>{terminos}</div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["nunito", "texto_30", "margen_top_50", "main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="invertir.comoinvertir.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className="margen_top_30">
                {this.props.imagenProceso ? <img src={urlImagen} className={classes.imagenProceso} alt="" /> : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={"margen_top_100"}>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.titulo2" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["montse", "texto_16", "margen_top_30", classes.text_container].join(" ")}>
                <p className={"montse texto_16 regular"}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.introduccion2" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={"margen_top_50"}>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.titulo3" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div
                className={["montse", "texto_16", "margen_top_30", classes.text_container, classes.list_container].join(" ")}
              >
                <FormattedHTMLMessage id="invertir.comoinvertir.introduccion3" defaultMessage=" " />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["margen_top_50"].join(" ")}>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.titulo4" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["montse", "texto_16 regular", "margen_top_30", classes.text_container].join(" ")}>
                <p className={"montse texto_16 regular"}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.introduccion4" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className="margen_top_30">
                {casas_bolsa}
                <p className={["montse", "texto_12"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.contenidoinformativo" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["margen_top_50"].join(" ")}>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.titulo5" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["montse", "texto_16", "margen_top_30", classes.text_container].join(" ")}>
                <p className={"montse texto_16 regular"}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.introduccion5" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["margen_top_50"].join(" ")}>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.titulo6" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["montse", "texto_16", "margen_top_30", classes.text_container].join(" ")}>
                <p className={"montse texto_16 regular"}>
                  <FormattedHTMLMessage id="invertir.comoinvertir.introduccion6" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    casas: state.casas.casas,
    diccionario: state.intl.diccionario,
    togglebox: state.toggleBox.comoInvertir,
    imagenProceso: state.imagenes.procesoInvertir
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onToggleBox: () => {
      dispatch(action.getComoInvertirToggleBox());
    },
    onImagenProceso: () => {
      dispatch(action.initImagenesComoInvertir());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComoInvertir);

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

// =========================================================================

const getMercadosProspectos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MERCADOPROSPECTOS_SUCCESS,
      mercados: []
    };
  }
  return {
    type: actionTypes.FETCH_MERCADOPROSPECTOS_SUCCESS,
    mercados: data
  };
};

export const initMercadoProspectos = (tipoBono = null) => {
  let url = `${dominio}/emisoras/prospectos/tipos-mercado`;
  url = tipoBono && tipoBono.length > 0
  ? `${url}?tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getMercadosProspectos(response.data));
      })
      .catch(err => dispatch(getMercadosProspectos(err.data)));
  };
};

// =========================================================================

// =========================================================================

const getValoresProspectos = data => {
  
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MERCADOPROSPECTOS_SUCCESS,
      instrumentos: []
    };
  }
  return {
    type: actionTypes.FETCH_MERCADOPROSPECTOS_SUCCESS,
    instrumentos: data
  };
};

export const initValoresProspectos = (tipoMercado = null, emisora = null, clave = null, tipoBono = null) => {
  let url = `${dominio}/emisoras/prospectos/tipos-instrumento`;
  url = tipoMercado !== null || emisora !== null || clave !== null ? `${url}?` : url;

  url = tipoMercado ? `${url}&idTipoMercado=${tipoMercado}` : url;
  url = clave ? `${url}&clave=${clave}` : url;
  url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getValoresProspectos(response.data));
      })
      .catch(err => dispatch(getValoresProspectos(err.data)));
  };
};

// =========================================================================
// =========================================================================

const getClavesProspectos = (data = null)=> {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MERCADOPROSPECTOS_SUCCESS,
      claves: []
    };
  }
    return {
      type: actionTypes.FETCH_MERCADOPROSPECTOS_SUCCESS,
      claves: data
    };
  };
  
  export const initClavesProspectos = (clave = null, tipoBono = null) => {
    let url = `${dominio}/emisoras/prospectos/claves`;
    url = clave ? `${url}?clave=${encodeURIComponent(clave)}` : url;
    url = tipoBono && tipoBono.length > 0
  ? `${url}&tipoBonoAsg=${[...tipoBono]}`
  : url;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getClavesProspectos(response.data));
        })
        .catch(err => dispatch(getClavesProspectos(err.data)));
    };
  };
  
  // =========================================================================
  
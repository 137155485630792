import React, { Component } from "react";
import classes from "./Emisiones.css";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Accordion from "../../../components/ui/accordion/Accordion";
import Tabs from "../../../components/ui/tabs/Tabs";
import Table from "../../../components/empresas/Table";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import { setColorEstatus } from "../../../stores/utils/utilities";
import { getLocal, getLan, formatNumber } from "../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import botonSuma from "../../../assets/icons/botonMas.svg";
import FilterInstrumentosAlternativos from "../../../components/ui/filterInstrumentosAlternativos/FilterInstrumentosAlternativos";
import StringUtils from "../../../stores/utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones/xls`;
let base_url_comite = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones/comite-tecnico/xls`;
let ficha_url = `${dominio}/emisoras/instrumento-alternativo/v2/ficha/`;

class Emisiones extends Component {
    state = {
        messages: null,
        lan: getLocal(),
        indiceIsin: null,
        emisoraSeleccionada: null,
        indexSelected: 0,
        indexSelectedInicial: 0,
        subFiltros: {
            indicadores: [],
            tipoGrafica: "LineChart"
        },
        tabsEmisoras: [
            {
                icon: "",
                image: "",
                title: ""
            },
            {
                icon: "",
                image: "",
                title: ""
            },
            {
                icon: "",
                image: "",
                title: ""
            }
        ],
        tabsInfo: [
            {
                icon: "",
                image: "",
                title: ""
            },
            {
                icon: "",
                image: "",
                title: ""
            },
            {
                icon: "",
                image: "",
                title: ""
            },
            {
                icon: "",
                image: "",
                title: ""
            }
        ],
        activePage: 1,
        itemsPerPage: 10,
        loader: true,
        resourceList: [],
        biva: null,
        isin_seleccionado: null,
        canceladas: null,
        instrumento: null,
        indice_emisora: null,
        sector: null,
        modoListado: null,
        estado: null,
        ramo: null,
        sub_ramo: null,
        nombreEmision: null,
        tabsDesabilitados: [],
        activaTabInicial: null,
        claveFilter: null,
        filteredBy: null,
        wasFilteredBySearch: false,
        claveEmisora: null,
        limpiarFiltroGrafica: false,
        emisoraDetalle: null,
        subtab: 0
    };

    componentWillMount() {
        this.props.onInitIntl();
        this.props.onFetchRssinputs();

        let paramsString = queryString.parse(this.props.location.search);

        let instrumento = null;
        let modoListado = null;
        let sector = null;
        let estado = null;
        let ramo = null;
        let sub_ramo = null;
        let emisora_id = null;
        let biva = null;
        let canceladas = null;
        let activaTabInicial = null;
        let numeroFideicomiso = null;
        let tabsDesabilitados = [];
        let subTab = null;
        let search_field_param = paramsString["search_field"];
        if (this.props.location.search !== "" && search_field_param === undefined) {
            instrumento = paramsString["instrumento"] === "null" || paramsString["instrumento"] === "" || paramsString["instrumento"] === undefined ? null : paramsString["instrumento"].split(",");
            modoListado = paramsString["modoListado"] === "null" || paramsString["modoListado"] === "" || paramsString["modoListado"] === undefined ? null : paramsString["modoListado"].split(",");
            sector = paramsString["sector"] === "null"  || paramsString["sector"] === "" || paramsString["sector"] === undefined ? null : paramsString["sector"].split(",");
            estado = paramsString["estado"] === "null"  || paramsString["estado"] === "" || paramsString["estado"] === undefined ? null : paramsString["estado"].split(",");
            emisora_id = paramsString["emisora_id"] === "null"  || paramsString["emisora_id"] === "" || paramsString["emisora_id"] === undefined ? null : paramsString["emisora_id"];
            numeroFideicomiso = paramsString["estado"] === "numeroFideicomiso"  || paramsString["numeroFideicomiso"] === "" || paramsString["numeroFideicomiso"] === undefined ? null : paramsString["numeroFideicomiso"];
        }
        let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
      
        if (page === -1 || page === null) {
            page = 1;
        }

        this.setState({
            numeroFideicomiso: numeroFideicomiso,
            instrumento: instrumento,
            modoListado: modoListado,
            sector: sector,
            estado: estado,
            activePage: page > 0 ? page : 1,
            emisoraSeleccionada: emisora_id
        });

        if (emisora_id) {
            this.updateGrandparent(emisora_id, canceladas, biva, subTab, numeroFideicomiso);
            this.onChangeItemSelected(subTab ? subTab : 0);
        } else {
            if (this.props.location.search === "") {
                this.onChangeTabInicial();
            }
            let params = {
                size: this.state.itemsPerPage,
                page: page > 1 ? page - 1 : 0,
                instrumento: instrumento,
                modoListado: modoListado,
                sector: sector,
                estado: estado,
                ramo: ramo,
                sub_ramo: sub_ramo,
                biva: biva,
                canceladas: canceladas
            };
            this.props.onFilterEmisoras(params);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages,
                    tabsEmisoras: [
                        {
                            icon: "",
                            image: "",
                            title: messages["empresas.emisorasinscritas.tab.todas"]
                        },
                        {
                            icon: "",
                            image: "",
                            title: messages["empresas.emisorasinscritas.tab.biva"]
                        },
                        {
                            icon: "",
                            image: "",
                            title: messages["empresas.emisorasinscritas.tab.canceladas"]
                        }
                    ],
                    tabsInfo: [
                        {
                            icon: "",
                            image: "",
                            title: messages["empresas.emisorasinscritas.emisora.infogral"]
                        },
                        {
                            icon: "",
                            image: "",
                            title: messages["empresas.emisorasinscritas.emisora.cotizacion"]
                        },
                        {
                            icon: "",
                            image: "",
                            title: messages["empresas.emisorasinscritas.emisora.documentos"]
                        },
                        {
                            icon: "",
                            image: "",
                            title: messages["empresas.emisorasinscritas.emisora.valoreslistados"]
                        }
                    ]
                });
            }
        }
        if (this.props.emisoras !== prevProps.emisoras) {
            let emisoras = null;
            this.setState({
                loader: false
            });

            emisoras = this.props.emisoras !== null ? this.props.emisoras.content : null;

            let resource = emisoras;
            this.onPageChangeHandler(resource, null, 0);
        }
        if (this.props.indiceIsin !== prevProps.indiceIsin) {
            this.setState({
                indiceIsin: this.props.indiceIsin
            });
        }
        if (this.props.emisoraDetalle !== prevProps.emisoraDetalle) {
            let cancelada_detalle = false;
            let tabsDesabilitados_detalle = this.state.tabsDesabilitados;
            let claveEmisora_detalle = null;
            if (this.props.emisoraDetalle !== null && this.props.emisoraDetalle !== undefined) {
                claveEmisora_detalle = this.props.emisoraDetalle.clave;
                if (this.props.emisoraDetalle.estatus === "Cancelada") {
                    cancelada_detalle = true;
                    tabsDesabilitados_detalle = [1, 3];
                } else if (this.props.emisoraDetalle.estatus === "Listado preventivo") {
                    tabsDesabilitados_detalle = [1, 3];
                }
                if (this.props.emisoraDetalle.estatus === "Activa") {
                    tabsDesabilitados_detalle = [];
                }
            }
            this.setState({
                emisoraSeleccionada: this.props.emisoraDetalle ? this.props.emisoraDetalle.id : null,
                canceladas: cancelada_detalle,
                claveEmisora: claveEmisora_detalle,
                tabsDesabilitados: tabsDesabilitados_detalle,
                emisoraDetalle: this.props.emisoraDetalle,
                loader: false
            });
        }
        if (this.props.financialReport !== prevProps.financialReport) {
            this.setState({
                loader: false
            });
        }

        
        if (this.props.documentos !== prevProps.documentos) {
            this.setState({
                loader: false
            });
        }
       
        if (prevProps.valoresListados !== this.props.valoresListados) {
            this.setState({
                loader: false
            });
            if (
                this.props.valoresListados && this.props.valoresListados.content && this.props.valoresListados.content.length !== undefined
            ) {
                this.props.onInitFinancialReport(this.props.valoresListados.content[0].isin);
                if (
                    this.props.valoresListados !== null && prevProps.valoresListados !== null &&
                    this.props.valoresListados.content.length !== undefined && prevProps.valoresListados !== this.props.valoresListados
                ) {
                    this.setState({
                        isin_seleccionado: this.props.valoresListados.content[0].isin
                    });
                }
            } else {
                this.setState({
                    isin_seleccionado: null
                });
            }
        }
    }

    onSetInitTabs = (index = null, emisora = null, wasFilteredBySearch = null) => {
        this.setState({
            emisoraSeleccionada: emisora,
            indexSelected: index,
            wasFilteredBySearch: wasFilteredBySearch
        });
    };

    onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
        let resourceList = [];
        let data = resource ? resource : this.props.emisoras;
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                itemSeleccionado: urlSeleccionado,
                resourceList: resourceList,
            });
        } else if (pageNumber) {
            this.setState({
                resourceList: resourceList,
                activePage: pageNumber,
            });

            this.props.history.push(
                `?instrumento=${this.state.instrumento}&modoListado=${this.state.modoListado}&sector=${this.state.sector}&estado=${this.state.estado
                }&emisora_id=${this.state.emisoraSeleccionada}&page=${pageNumber}`
            );
        } else {
            this.setState({
                resourceList: resourceList
            });
        }
    };

    onChangeItemSelected = (index = 0) => {
        let subTab = 0;
        let activePage = null;
        if (this.state.indexSelected === index) {
            activePage = this.state.activePage;
        } else {
            activePage = 1;
        }

        if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada !== null) {
            if (index === 1) {
                this.setState({ loader: false });
            }

            this.props.onInitEmisorasDetail(this.state.emisoraSeleccionada, this.state.numeroFideicomiso);
            this.props.onInitComiteTecnico(this.state.emisoraSeleccionada, this.state.numeroFideicomiso);
            this.props.onInitValoresListados(this.state.itemsPerPage, 0, this.state.emisoraSeleccionada);
            if (this.props.valoresListados && this.props.valoresListados.length !== undefined && this.props.valoresListados > 0) {
                this.props.onInitFinancialReport(this.props.valoresListados.content[0].isin);
            }
        }

        if (this.state.emisoraSeleccionada !== null) {
            subTab = index;
            this.setState({
                subTab: subTab
            });
            this.changeHistory(this.state.instrumento, this.state.modoListado, this.state.sector, this.state.estado, this.state.ramo, this.state.sub_ramo, subTab, activePage, this.state.biva, this.state.canceladas, this.state.emisoraSeleccionada);
        }
        this.setState({
            indexSelected: index,
            activePage: activePage
        });
    };

    onChangeTabInicial = (index = 0, consulta = false) => {
        let bivaFilter = true;
        let activePage = null;
        let tabsDesabilitados = null;
        let canceladasFilter = true;
        if (this.state.indexSelectedInicial === index) {
            activePage = this.state.activePage;
        } else {
            activePage = 1;
        }
        if (index === 0) {
            bivaFilter = false;
            canceladasFilter = false;
        } else if (index === 1) {
            bivaFilter = true;
            canceladasFilter = false;
        } else if (index === 2) {
            bivaFilter = false;
            canceladasFilter = true;
            tabsDesabilitados = [1, 3];
        }

        if (consulta) {
            this.changeHistory(null, null, null, null, null, null, 0, 1, bivaFilter, canceladasFilter, this.state.emisoraSeleccionada);
            this.setState({
                instrumento: null,
                modoListado: null,
                sector: null,
                estado: null,
                ramo: null,
                sub_ramo: null
            });
        }
        this.setState({
            indexSelectedInicial: index,
            activePage: activePage,
            loader: true,
            biva: bivaFilter,
            canceladas: canceladasFilter,
            tabsDesabilitados: tabsDesabilitados,
            currentTab: index
        });
    };

    updateGrandparentClaves(id, clave){
        this.updateGrandparent(id);
        this.setState({ claveFilter: clave });
    }

    updateGrandparent = (value, canceladas = null, biva = null, subTab = null, numeroFideicomiso = null) => {
        let subtab = subTab === null ? 0 : subTab;
        this.setState({
            emisoraSeleccionada: value,
            numeroFideicomiso: numeroFideicomiso,
            indexSelected: subtab,
            loader: true,
            subTab: subtab
        });

        this.props.history.push(
            `?instrumento=${this.state.instrumento}&modoListado=${this.state.modoListado}&sector=${this.state.sector}&estado=${this.state.estado
            }&emisora_id=${value}&numeroFideicomiso=${numeroFideicomiso}&page=${this.state.activePage}`
        );

        if (numeroFideicomiso === null) {
            let params = {
                size: this.state.itemsPerPage,
                page: 0,
                instrumento: this.state.instrumento,
                modoListado: this.state.modoListado,
                sector: this.state.sector,
                estado: this.state.estado,
                ramo: this.state.ramo,
                sub_ramo: this.state.sub_ramo,
                biva: this.state.biva,
                canceladas: this.state.canceladas,
                empresa: value
            };
            this.props.onFilterEmisoras(params);
            this.setState({ emisoraSeleccionada: null });
        }else{
            this.props.onInitEmisorasDetail(value, numeroFideicomiso);
            this.props.onInitComiteTecnico(value, numeroFideicomiso);
        }
    };



    onSetPeriodoIndicador = indicadores => {
        const subFiltros = { ...this.state.subFiltros };
        subFiltros.indicadores = indicadores;

        this.setState({
            subFiltros: subFiltros
        });
    };

    onSetIndicadorHandler = indicadores => {
        const subFiltros = { ...this.state.subFiltros };
        if (indicadores === null || indicadores.length === 0) {
            subFiltros.indicadores = [];
        } else {
            subFiltros.indicadores = indicadores;
        }

        this.setState({
            subFiltros: subFiltros
        });
    };

   
    onSetIndice = indice => {
        this.setState({
            indice_emisora: indice
        });
    };

    onSetTipoGraficaHandler = tipo => {
        const subFiltros = { ...this.state.subFiltros };
        subFiltros.tipoGrafica = tipo;

        this.setState({
            subFiltros: subFiltros
        });
    };


    onSetIsin = isin => {
        this.props.onIndiceEmisora(isin);
        this.setState({
            isin_seleccionado: isin
        });
        this.props.onInitRatiosReport(isin); //TODO: Cambiar valor por ISIN de filtro
    };

        
    onSetLimpiarFiltrosGrafica = value => {
        this.setState({
            limpiarFiltroGrafica: value
        });
    };


    onSetNombreEmision = nombre => {
        this.setState({
            nombreEmision: nombre,
            loader: false
        });
    };

    onReset = val => {
        const subFiltros = { ...this.state.subFiltros };
        subFiltros.tipoGrafica = "LineChart";
        subFiltros.indicadores = [];
        this.setState({
            subFiltros: subFiltros
        });
    };


    handlePageChange(pageNum) {
        if (this.state.activePage !== pageNum) {
            this.setState({
                loader: true
            });

            if (this.state.filteredBy === 0) {
                let params = {
                    clave: this.state.claveFilter,
                    size: this.state.itemsPerPage,
                    page: pageNum - 1
                };
                this.props.onFetchAllEmisorasByClave(params);
            } else {
                let params = {
                    size: this.state.itemsPerPage,
                    page: pageNum - 1,
                    instrumento: this.state.instrumento,
                    modoListado: this.state.modoListado,
                    numeroFideicomiso: this.state.numeroFideicomiso,
                    sector: this.state.sector,
                    estado: this.state.estado,
                    ramo: this.state.ramo,
                    sub_ramo: this.state.sub_ramo,
                    biva: this.state.biva,
                    canceladas: this.state.canceladas
                };
                this.props.onFilterEmisoras(params);
            }

            this.onPageChangeHandler(null, pageNum);
        }
    }

    onSetActivePage = activePage => {
        this.setState({
            activePage: activePage
        });
    };

    onSetFilters = ({ instrumento, modoListado, sector, estado, ramo, sub_ramo, page, reset = false, valueClave=null }) => {
        this.setState({
            instrumento: instrumento ? [...instrumento] : null,
            modoListado: modoListado ? [...modoListado] : null,
            sector: sector ? [...sector] : null,
            estado: estado ? [...estado] : null,
            ramo: ramo,
            sub_ramo: sub_ramo,
            activePage: page,
            valueClave
        });
        if (reset) {
            this.changeHistoryReset();
        } else {
            this.changeHistory(instrumento, modoListado, sector, estado, ramo, sub_ramo, 0, page, this.state.biva, this.state.canceladas, valueClave);
        }
    };

    onSetFilterClave = clave => {
        this.setState({
            claveFilter: clave,
            loader: true
        });
    };

    onSetFilteredBy = type => {
        this.setState({
            filteredBy: type
        });
    };

    onSetClaveEmisoraFilter = value => {
        this.props.history.push(
            `?instrumento=${this.state.instrumento}&modoListado=${this.state.modoListado}&sector=${this.state.sector}&estado=${this.state.estado
            }&emisora_id=${null}&page=${this.state.page}`
        );

        this.setState({
            claveEmisora: value,
            emisoraSeleccionada: value
        });
    };

    onDeleteEmisoraSeleccionadaHandler = () => {
        this.props.history.push(
            `?instrumento=${this.state.instrumento}&modoListado=${this.state.modoListado}&sector=${this.state.sector}&estado=${this.state.estado
            }&emisora_id=${null}&page=${this.state.page}`
        );

        this.setState({
            claveEmisora: null,
            emisoraDetalle: null,
            emisoraSeleccionada: null,
            nombreEmision: null,
            indiceIsin: null,
            loader: true,
            activePage: 1
        });

    };

    changeHistory(
        instrumento = null,
        modoListado = null,
        sector = null,
        estado = null,
        page = 1,
        emisora=null
    ) {
        this.props.history.push(
            `?instrumento=${instrumento}&modoListado=${modoListado}&sector=${sector}&estado=${estado}&emisora_id=${emisora
            }&page=${page ? page : this.state.activePage}`
        );
        let params = {
            size: this.state.itemsPerPage,
            page: page > 0 ? page - 1 : 0,
            instrumento: instrumento,
            modoListado: modoListado,
            sector: sector,
            estado: estado
        };
        this.props.onFilterEmisoras(params);
        this.setState({ loader: true });
    }

    changeHistoryReset() {
        this.props.history.push(
            `?instrumento=${null}&modoListado=${null}&sector=${null}&estado=${null}&emisora_id=${null}&page=1`
        );
        this.setState({
            loader: true
        });
        let params = {
            size: this.state.itemsPerPage,
            page: 0,
            instrumento: null,
            modoListado: null,
            sector: null,
            estado: null,
        };
        this.props.onFilterEmisoras(params);
    }

    render() {
        let dataToShow = null;
        let contenidoMostrar = null;
        let instrumentosColapsable = null;
        let resultadoDescarga = null;
        let descarga_url = base_url;
        let descarga_url_comite = base_url_comite;
        let tabMostrar = null;
        const numRows = this.props.emisoras ? this.props.emisoras.totalElements : 0;
        let emisoras = this.props.emisoras && this.props.emisoras.content ? this.props.emisoras.content : [];
        let loader = null;

        const params = {
            tipoInstrumento:this.state.instrumento && this.state.instrumento.length > 0 ? this.state.instrumento : null,
            modoListado: this.state.modoListado && this.state.modoListado.length > 0 ? this.state.modoListado : null,
            sector:this.state.sector && this.state.sector.length > 0 ? this.state.sector : null,
            estatus: this.state.estado && this.state.estado.length > 0 ? this.state.estado : null,
            clave: this.state.claveFilter
        }
        const queryParams = StringUtils.jsonToQueryString(params);
        descarga_url = descarga_url + queryParams;
        descarga_url_comite = descarga_url_comite + queryParams;

        if (this.state.loader) {
            loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
        }

        if (emisoras.length > 0) {
            dataToShow = (
                <Accordion
                    nombre="emisoras"
                    datos={emisoras}
                    icon={""}
                    tipo={7}
                    subtipo={4}
                    updateGrandparent={this.updateGrandparent.bind(this)}
                    updateGrandparentInstrumentos={(clave, numeroFideicomiso) => this.updateGrandparent(clave, this.state.canceladas, this.state.biva, this.state.subTab, numeroFideicomiso)}
                    instrumentosAlternativos={true}
                />
            );
            resultadoDescarga = (
              <div className={"row margen_30"}>
                <div className={[classes.card_header, "d-none d-md-block col-md-4 col-lg-5 col-xl-8"].join(" ")}>
                  <span className={["regular montse texto_16 "].join(" ")} />
                </div>
                <div className={[classes.card_header2, "col-12 col-md-8 col-lg-7 col-xl-4 row"].join(" ")}>
                  <div className={[getLan() === "es" ? "col-12 col-sm-7" : "col-12 col-sm-5", "sin_padding", "texto_16 montse regular"].join(" ")}
                  >
                    <a
                      href={descarga_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="row"
                    >
                      <div
                        className={[
                          classes.titulo,
                          "texto_16 montse color_azul regular ",
                        ].join(" ")}
                      >
                        <span
                          className={[
                            "texto_16 montse color_azul regular",
                          ].join(" ")}
                        >
                            {getLan() === "es"
                            ? "Listado de emisoras"
                            : "List of issuers"}
                        </span>
                          <i
                            className={[
                            "Boton__icono_derecho__25mg6",
                            "fas fa-arrow-alt-circle-down fa-lg",
                            ].join(" ")}
                          />
                      </div>
                      <span
                        className={[
                          classes.titulo_ZIP,
                          "semi_bold montse texto_10",
                        ].join(" ")}
                      >
                        {getLan() === "es" ? 
                        "Formato XLS" : 
                        "XLS file"}
                      </span>
                    </a>
                  </div>
                  <div className={[getLan() === "es" ? "col-12 col-sm-5" : "col-12 col-sm-7", "sin_padding", "texto_16 montse regular"].join(" ")}
                  >
                    <a
                      href={descarga_url_comite}
                      target="_blank"
                      className="row"
                      rel="noopener noreferrer"
                    >
                      <div
                        className={[
                          classes.titulo, "texto_16",
                          "montse color_azul regular",
                        ].join(" ")}
                      >
                        <span
                          className={[
                            "texto_16 montse color_azul regular ",
                          ].join(" ")}
                        >
                            {getLan() === "es"
                                ? "Comité técnico"
                                : "Technical committee"}
                        </span>
                          <i
                            className={[
                              "fas fa-arrow-alt-circle-down fa-lg ",
                              "Boton__icono_derecho__25mg6",
                            ].join(" ")}
                          />
                      </div>
                      <span
                        className={[
                          classes.titulo_ZIP,
                          "semi_bold montse texto_10",
                        ].join(" ")}
                      >
                        {getLan() === "es" ? "Formato XLS" : "XLS file"}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            );
        } else if (emisoras.length === 0) {
            dataToShow = (
                <p className="montse texto_16 regular">
                    {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
                </p>
            );
            resultadoDescarga = null;
        }

        const emisorasInscritas = (
            <React.Fragment>
                {resultadoDescarga}
                <br />
                {dataToShow}
                <Pagination
                    claseTipo={2}
                    numRows={numRows}
                    paginas={5}
                    itemsPerPage={this.state.itemsPerPage}
                    handlePageChange={this.handlePageChange.bind(this)}
                    activePage={this.state.activePage}
                />
            </React.Fragment>
        );
        // Montos
        const emisoraInfo2 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras" icon={""} tipo={21} subtipo={2} />;

        // Derechos
        const emisoraDerechos = (this.props.emisoraDetalle && this.props.emisoraDetalle.derechos ? 
                    <Accordion datos={this.props.emisoraDetalle} nombre="derechos" icon={""} tipo={21} subtipo={4} /> : null);
        // Info General
        const emisoraInfo3 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras" icon={""} tipo={21} />;
        // Valuador y Auditor
        const emisoraInfo6 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras" icon={""} tipo={21} subtipo={3} />;
        // Fideicomitente Personas Relevantes
        const emisoraInfo4 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras" icon={""} tipo={21} subtipo={1} />;
         // Consejeros y Funcionarios
    const emisoraComite = (
        <Accordion
          integrantes={this.props.comiteTecnico}
          nombre="emisoraComite"
          icon={""}
          tipo={23}
          titulo={getLan() === "es" ? "Ver Integrantes" : "View members"}
        />
      );
        // Consejeros y Funcionarios
        const emisoraInfo5 = (
            <Accordion
                integrantes={this.props.emisoraIntegrantes}
                nombre="emisoraIntegrantes"
                icon={""}
                tipo={10}
                titulo={getLan() === "es" ? "Ver Integrantes" : "View members"}
            />
        );

        let emisoraCotizacion = null;
        // if (this.state.indiceIsin) {
        //accordion que estara sobre arriba de la grafica
        emisoraCotizacion = (
            <div className="margin_top_30">
                <Accordion
                    key={this.state.nombreEmision}
                    datos={this.state.indiceIsin ? this.state.indiceIsin : [0]}
                    titulo={this.state.nombreEmision ? this.state.nombreEmision : null}
                    razonSocial={this.state.emisoraDetalle ? this.state.emisoraDetalle.razonSocial : null}
                    indice_emisora={this.state.indiceIsin} p
                    fechaListado={this.state.emisoraDetalle !== null ? this.state.emisoraDetalle.fechaListado : null}
                    nombre="emisoras"
                    icon={""}
                    tipo={11}
                />
            </div>
        );
        // }

        let cotizacion = null;
        let resumenFinanciero = null;

        if (this.state.isin_seleccionado !== null) {
            cotizacion = <Table tipo={2} datos={this.props.ratiosReport} />;
        } else {
            cotizacion = null;
        }

        if (this.props.financialReport) {
            resumenFinanciero = <Table tipo={1} datos={this.props.financialReport} />;
        }

        let estatus = null;
        if (this.state.emisoraDetalle) {
            if (this.props.emisoraDetalle.estatus === "Cancelada" || this.props.emisoraDetalle.estatus === "Listado preventivo") {
                if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada !== null) {
                    estatus = (
                        <span
                            className={[
                                classes.titulo_25pct,
                                setColorEstatus(this.props.emisoraDetalle.estatus),
                                "texto_14 nunito semi_bold"
                            ].join(" ")}
                        >
                            <i className={["fas fa-circle fa-xs"].join(" ")} />
              &nbsp;
                            {this.props.emisoraDetalle.estatus}
                        </span>
                    );
                } else {
                    estatus = null;
                }
            }
        }
        tabMostrar = (
            <div>
                <div className={["width_cien ", classes.vertical_div].join(" ")}>
                    <div className={[classes.titulo_25pct, classes.vertical].join(" ")}>
                        <div className={["width_cien ", classes.vertical_div].join(" ")}>
                            <img
                                className={["width_cien ", classes.vertical_img].join(" ")}
                                src={this.props.emisoraDetalle ? this.props.emisoraDetalle.logo : null}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={[classes.titulo_75pct, classes.vertical].join(" ")}>
                        <div className={["width_cien ", classes.vertical_div].join(" ")}>
                            <span className={["texto_30 nunito ", classes.vertical].join(" ")}>
                                {this.props.emisoraDetalle ? this.props.emisoraDetalle.razonSocial : null}
                            </span>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row margen_top_30">
                    <br />
                    <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
                        {getLan() === "es" ? "Clave" : "Ticker"}:&nbsp;
            <span className={["texto_16 nunito semi_bold color_azul", classes.myCol].join(" ")}>
                            {this.props.emisoraDetalle ? this.props.emisoraDetalle.clave : null}
                        </span>
                    </div>
                    <div className={["col sin_padding", classes.myCol].join(" ")}>
                        <Tabs
                            data={this.state.tabsInfo}
                            key={this.state.tabsDesabilitados}
                            sub={true}
                            tipo={1}
                            tabsDesabilitados={this.state.tabsDesabilitados}
                            activaTabInicial={this.state.subTab}
                            clicked={i => this.onChangeItemSelected(i)}
                            wasFilteredBySearch={this.state.wasFilteredBySearch}
                        />
                    </div>
                </div>
                <br />
                {estatus}
            </div>
        );

        if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada === null) {
            contenidoMostrar = <div className="margen_50">{emisorasInscritas}</div>;
           
            instrumentosColapsable = (
                <div className={["col-12 row margin_top_20"].join(" ")}>
                    <div className={["col-md-1 col-sm-1", classes.left, this.props.tipo === 2 ? classes.imagen_minimo : null].join(" ")}>
                        <a
                            className={["", classes.botonLinkSuma].join(" ")}
                            data-toggle="collapse"
                            href={".collapseExample"}
                            role="button"
                            aria-expanded="false"
                            aria-controls={"collapseExample"}
                        >
                            <img src={botonSuma} alt="" className={classes.btnSuma} />
                        </a>
                        <div
                            className={[
                                "d-md-none d.lg-none  d-xl-none",
                                classes.linea
                            ].join(" ")}
                        ></div>
                    </div>
                    <div className={["col-md-11 vertical_center", classes.right].join(" ")}>
                        <span className={["nunito texto_24 regular", classes.tituloStep].join(" ")}>
                            <FormattedHTMLMessage id="instrumentosalternativos.header1" defaultMessage=" " />
                        </span>


                        <div
                            className={["collapse", "collapseExample", "margin_top_15", classes.contenido].join(" ")}
                            id={"collapseExample"}
                        >

                            <div className="margin_bottom_20 texto_16 montse regular">
                                <FormattedHTMLMessage id="instrumentosalternativos.descripcion" defaultMessage=" " />
                            </div>

                            <div className={["margin_bottom_20", "texto_24", "nunito"].join(" ")}>
                                <h2>
                                    <FormattedHTMLMessage id="instrumentosalternativos.subtitulo.ckdcerpi" defaultMessage=" " />
                                </h2>
                            </div>
                            <div className="margin_bottom_20 texto_16 montse regular">
                                <FormattedHTMLMessage id="instrumentosalternativos.descripcion.ckdcerpi" defaultMessage=" " />
                            </div>

                            <div className={["margin_bottom_20", "texto_24", "nunito"].join(" ")}>
                                <h2>
                                    <FormattedHTMLMessage id="instrumentosalternativos.subtitulo.fibra" defaultMessage=" " />
                                </h2>
                            </div>
                            <div className="margin_bottom_20 texto_16 montse regular">
                                <FormattedHTMLMessage id="instrumentosalternativos.descripcion.fibra" defaultMessage=" " />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada !== null) {
            // } else if (this.state.indexSelected === 1 && this.state.emisoraSeleccionada !== null) {
            //     const bolsa = (
            //         <span className={[classes.titulo_75pct, "texto_30 nunito"].join(" ")}>
            //             {/* {this.props.emisoraDetalle ? this.props.emisoraDetalle.bolsa : null} */}
            //   BIVA
            //         </span>
            //     );
            //     let graficas = null;
            //     if (this.state.canceladas === true) {
            //         graficas = null;
            //         contenidoMostrar = null;
            //     } else {
            //         if (this.state.isin_seleccionado) {
            //             graficas = (
            //                 <React.Fragment>
            //                     <ChartFilter
            //                         filtros={this.state.subFiltros}
            //                         filtroIndicadores={this.state.subFiltros.indicadores}
            //                         key={this.state.isin_seleccionado}
            //                         isin={this.state.isin_seleccionado}
            //                         setIndice={indice => this.onSetIndice(indice)}
            //                         setLimpiarFiltros={value => this.onSetLimpiarFiltrosGrafica(value)}
            //                     />
            //                     <span className={["texto_14 montse regular", classes.margin_left].join(" ")}>
            //                         {getLan() === "es"
            //                             ? "*La información presentada cuenta con un retraso de 15-20 minutos y pertenece a la operación en BIVA."
            //                             : "*This information is delayed by 20 minutes and belongs to BIVA."}
            //                     </span>
            //                 </React.Fragment>
            //             );
            //         }
            //         contenidoMostrar = (
            //             <div className="margen_50">
            //                 {bolsa}
            //                 <SubFiltersCotizacion
            //                     limpiarFiltros={this.state.limpiarFiltroGrafica}
            //                     key={this.state.emisoraSeleccionada}
            //                     emisoraId={this.state.emisoraSeleccionada}
            //                     emisoraDetalle={this.state.emisoraDetalle ? this.state.emisoraDetalle.clave : this.props.emisoraDetalle ? this.props.emisoraDetalle.clave : null}
            //                     setNombreEmision={nombreEmision => this.onSetNombreEmision(nombreEmision)}
            //                     setIndicadores={indicadores => this.onSetIndicadorHandler(indicadores)}
            //                     setTipoGrafica={tipo => this.onSetTipoGraficaHandler(tipo)}
            //                     setIsin={isin => this.onSetIsin(isin)}
            //                     tipoGraficaValue={this.state.subFiltros.tipoGrafica}
            //                     nombreEmision={this.state.nombreEmision}
            //                     setReset={valor => this.onReset(valor)}
            //                     setLimpiarFiltros={value => this.onSetLimpiarFiltrosGrafica(value)}
            //                 />
            //                 {emisoraCotizacion}
            //                 {graficas}
            //                 {cotizacion}
            //             </div>
            //         );
            //     }
            // } else if (this.state.indexSelected === 2 && this.state.emisoraSeleccionada !== null) {
            //     //   contenidoMostrar = <DocumentosEmisoras emisoraSeleccionada={this.state.emisoraSeleccionada} biva={this.state.biva} canceladas={this.state.canceladas} />;
            // } else if (this.state.indexSelected === 3 && this.state.emisoraSeleccionada !== null) {
            //     if (this.state.canceladas === true) {
            //         contenidoMostrar = null;
            //     } else {
            //         // contenidoMostrar = <ValoresListadosEmisoras emisoraSeleccionada={this.state.emisoraSeleccionada} biva={this.state.biva} canceladas={this.state.canceladas} />;
            //     }
            descarga_url = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones/ficha/${this.state.emisoraSeleccionada}/xls?numeroFideicomiso=${this.state.numeroFideicomiso}`;

            resultadoDescarga = (
                <div className={"row margen_30 margin_bottom_30"}>
                    <div className={[classes.card_header, "d-none d-md-block col-md-4 col-lg-5 col-xl-8"].join(" ")}>
                        <span className={["regular montse  texto_16 "].join(" ")} />
                    </div>
                     <div className={[classes.card_header2, "col-12 col-md-8 col-lg-7 col-xl-4 row"].join(" ")}>
                        <div
                            className={["col-7 sin_padding", "texto_16 montse regular"].join(
                                " "
                            )}
                        >
                            <a
                                href={descarga_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="row"
                                >
                                <div className={[
                                    classes.titulo,
                                    "texto_16 montse color_azul regular ",
                                    ].join(" ")}
                                >
                                    <span className={[
                                        "texto_16 montse color_azul regular",
                                    ].join(" ")}
                                    >
                                    {getLan() === "es" ?  "Exportar Ficha" : "Export data sheet"}
                                    </span>
                                    <i className={[
                                        "Boton__icono_derecho__25mg6",
                                        "fas fa-arrow-alt-circle-down fa-lg",
                                        ].join(" ")}
                                    />
                                </div>
                                <span
                                    className={[
                                    classes.titulo_ZIP,
                                    "semi_bold montse texto_10",
                                    ].join(" ")}
                                >
                                    {getLan() === "es" ?  "Formato XLS" : "XLS file"}
                                </span>
                             </a>
                        </div>
                    </div>
                </div>
            );

            contenidoMostrar = (
                <div className="margen_top_50">
                    {resultadoDescarga}
                    {emisoraInfo3} {/* Info General */}
                    {emisoraInfo6} {/*Valuador y Auditor */}
                    {emisoraInfo4} {/* Fideicomitente Personas Relevantes*/}
                    {emisoraComite} {/* Comite técnico*/}
                    {/* {emisoraInfo5} Consejeros y Funcionarios */}
                    {emisoraInfo2} {/* Montos*/}
                    {emisoraDerechos} {/* Derechos*/}
                    {/* {resumenFinanciero} Resumen Financiero */}
                </div>
            );
        }

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
                <React.Fragment>
                    {loader}

                    <h1 className={"texto_42 nunito regular"}>
                        {" "}
                        <FormattedHTMLMessage id="instrumentosalternativos.titulo" defaultMessage="Instrumentos Alternativos" />
                    </h1>
                    {instrumentosColapsable}

                    <div className="margin_top_50">
                        {/* <div className="col sin_padding_L"> */}
                        <div className={["main_subtitle2", "texto_30", "nunito"].join(" ")}>
                            <span className={"slash"}>/</span>
                            <h2>
                                <FormattedHTMLMessage id="instrumentosalternativos.emisiones" defaultMessage="Emisiones" />
                            </h2>
                        </div>
                        {/* </div> */}
                    </div>
                    {/* <div className="row"> */}
                    <div className="margin_bottom_20 texto_16 montse regular">
                        <FormattedHTMLMessage id="instrumentosalternativos.emisiones.descripcion" defaultMessage=" " />
                    </div>
                    {/* </div> */}

                    <div className="row margin_bottom_20">
                        <div className="col sin_padding">
                            <FilterInstrumentosAlternativos
                                setInitTabs={(index, emisora, wasFilteredBySearch) => this.onSetInitTabs(index, emisora, wasFilteredBySearch)}
                                setActivePage={activePage => this.onSetActivePage(activePage)}
                                setFilters={filterParams => this.onSetFilters(filterParams)}
                                currentTab={this.state.indexSelectedInicial}
                                updateEmisorasParent={(id, clave) => this.updateGrandparentClaves(id, clave)}
                                updateGrandparentInstrumentos={(clave, numeroFideicomiso) => this.updateGrandparent(clave, null, null, null, numeroFideicomiso)}
                                emisora_id={this.state.emisoraSeleccionada}
                                biva={this.state.biva}
                                canceladas={this.state.canceladas}
                                setFilterClave={clave => this.onSetFilterClave(clave)}
                                wasFilteredBy={type => this.onSetFilteredBy(type)}
                                claveEmisora={this.state.claveEmisora}
                                onDeleteEmisoraSelecionada={() => this.onDeleteEmisoraSeleccionadaHandler()}
                                borraClaveFiltro={param => this.onSetClaveEmisoraFilter(param)}
                            />
                        </div>
                    </div>
                    <div className="margen_50">{contenidoMostrar}</div>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitIntl: () => {
            dispatch(actions.initIntl(83));
        },
        onFilterEmisoras: params => {
            dispatch(actions.filterEmisiones(params));
        },
        onInitEmisorasDetail: (emisoraId, numeroFideicomiso) => {
            dispatch(actions.initEmisionDetalle(emisoraId, numeroFideicomiso));
        },
        onInitComiteTecnico: (emisoraId, numeroFideicomiso) => {
            dispatch(actions.getComiteTecnico(emisoraId, numeroFideicomiso));
        },
        onInitEmisorasIntegrantes: emisoraId => {
            dispatch(actions.initEmisorasIntegrantes(emisoraId));
        },
        onInitValoresListados: (size, page, emisoraId) => {
            // dispatch(actions.initValoresListados(size, page, emisoraId));
            // dispatch(actions.initValoresListadosMin(emisoraId));
        },
        onInitFinancialReport: isin => {
            dispatch(actions.initFinancialReport(isin));
        },
        onInitBancoInformacion: params => {
            dispatch(actions.initBancoInformacion(params));
        },
        onInitRatiosReport: isin => {
            dispatch(actions.initRatiosReport(isin));
        },
        onFetchRssinputs: () => {
            dispatch(actions.fetchAllRssInputs());
        },
        onIndiceEmisora: isin => {
            dispatch(actions.getIndiceEmisora(isin));
        },
        onFetchAllEmisorasByClave: params => {
            dispatch(actions.fetchAllEmisorasByClave(params));
        }
    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        emisoras: state.instrumentosAlternativos.emisiones,
        comiteTecnico: state.instrumentosAlternativos.comiteTecnico,
        bancoInformacion: state.emisoras.bancoInformacion,
        emisoraDetalle: state.instrumentosAlternativos.emisionDetalle,
        emisoraIntegrantes: state.emisoras.emisoraIntegrantes,
        documentos: state.emisoras.documentos,
        valoresListados: state.emisoras.valoresListados,
        valoresListadosMin: state.emisoras.valoresListadosMin,
        financialReport: state.emisoras.financialReport,
        ratiosReport: state.emisoras.ratiosReport,
        indiceIsin: state.emisoras.indiceIsin,
        tipo_informacion: state.tipoInformacion.tipo_informacion,
        graficaInstrument: state.emisoras.graficaInstrument
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Emisiones)
);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";
import classes from "./Nosotros.css";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";
import NotFound from "../../components/notFound/NotFound";

// Pages
import Acerca from "./acerca/Acerca";
import Blogs from "./blogs/Blogs";
import Equipo from "./equipo/Equipo";
import GobiernoCorporativo from "./gobiernoCorporativo/GobiernoCorporativo";
import EventosPage from "./eventos/Eventos";
import NoticiasPage from "./noticias/NoticiasPage";
import SalaPrensa from "./salaPrensa/SalaPrensa";
import ContactanosOperaciones from "./contactanos/operaciones/Operaciones";
import ContactanosEmpresas from "./contactanos/empresas/Empresas";
import ContactanosRegulacion from "./contactanos/regulacion/Regulacion";
import ContactanosComunicacion from "./contactanos/comunicacion/Comunicacion";
import ContactanosServicio from "./contactanos/servicio/Servicio";
import ContactanosCapital from "./contactanos/capital/Capital";
import PreguntasFrecuentes from "./preguntas_frecuentes/Preguntas";
import QueremosEscucharte from "./queremosEscucharte/QueremosEscucharte";
import Auditorio from "./auditorio/Auditorio";
import BivaSustentable from "./bivaSustentable/BivaSustentable";

import DocumentMeta from "react-document-meta";
import { getLan } from "../../stores/utils/utilities";

const metaEs = {
  title: "Portal BIVA - Nosotros",
  description:
    "Bolsa Institucional de Valores, comúnmente conocida como BIVA, es la segunda bolsa de valores en México, ubicada en Ciudad de México. BIVA comenzó operaciones el 25 de julio de 2018 y opera los mismos instrumentos de la otra bolsa en México, la Bolsa Mexicana de Valores: acciones, deudas, garantías y algunos instrumentos mexicanos específicos como CKDs y FIBRAs. BIVA tiene como propósito contribuir al crecimiento del mercado de valores a través de la innovación, el uso de tecnología de punta y mayor accesibilidad. Atrayendo un mayor número de participantes.",
  meta: {
    name: {
      keywords:
        "biva, market place, auditorio, auditorio biva, bolsa de valores, ckds, CKDs, fibras, FIBRAs, acciones, Acciones, deudas, garantías, bolsa valores, Bolsa valores mexico, méxico, bolsa, valores, bmv, nueva, nueva bolsa de valores, la mejor bolsa de valores, invierte, invertir, mercado, mercado de valores, beneficios, oferta, innovación, tecnología, operación, operaciones, bursatil, bursatiles, índices, bim, plataforma BIM, gobierno corporativo, noticias, sala de prensa, capital humano, contactanos, preguntas, frecuentes",
    },
  },
};
const metaEn = {
  title: "Portal BIVA - About us",
  description:
    "BIVA's goal is to contribute to the growth of the stock exchange through innovation, state-of-the-art technology, and accessibility. It seeks to attract a greater number of participants.",
  meta: {
    name: {
      keywords:
        "biva, market place, auditorium, auditorium biva, stock exchange, ckds, CKDs, fibras, FIBRAs, stocks, shares, debts, guarantees, stock exchange, stock exchange mexico, mexico, stock exchange, bmv, new, new stock exchange, the best stock exchange, invest, invest, market, stock market, benefits, offer, innovation, technology, operation, operations, stock market, stock market, indices, bim, BIM platform, news, corporate, governance, corporate governance, press, press room, talen, faq, team",
    },
  },
};


class Nosotros extends Component {
  state = {
    menu: null,
    id_menu_activo: null
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage:"nosotros"
    };

    this.props.onInitGenericos(params);
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu
      });
    }else{
      this.setState({
        id_menu_activo: menu
      });
      
    }
  };
  
  render() {
    const routes = (
      <Switch>
        <Route path="/nosotros/" exact component={Acerca} />
        <Route path="/nosotros/acerca_de" exact component={Acerca} />
        <Route path="/nosotros/acerca_de/blogs" exact component={Blogs} />
        <Route path="/nosotros/acerca_de/equipo" component={Equipo} />
        <Route
          path="/nosotros/acerca_de/gobierno_corporativo"
          component={GobiernoCorporativo}
        />
         <Route
          path="/nosotros/capital_humano"
          component={QueremosEscucharte}
        />
        <Route
          path="/nosotros/capital_humano/queremos_escucharte"
          component={QueremosEscucharte}
        />
        <Route path="/nosotros/acerca_de/eventos" component={EventosPage} />
        <Route path="/nosotros/acerca_de/noticias" component={NoticiasPage} />
        <Route path="/nosotros/acerca_de/sala_prensa" component={SalaPrensa} />
        <Route path="/nosotros/biva_sustentable" exact component={BivaSustentable} />
        <Route
          path="/nosotros/contactanos"
          component={ContactanosOperaciones}
        />
        <Route
          path="/nosotros/contactanos/operaciones"
          component={ContactanosOperaciones}
        />
        <Route
          path="/nosotros/contactanos/empresas"
          component={ContactanosEmpresas}
        />
        <Route
          path="/nosotros/contactanos/regulacion_cumplimiento"
          component={ContactanosRegulacion}
        />
        <Route
          path="/nosotros/contactanos/comunicacion"
          component={ContactanosComunicacion}
        />
        <Route
          path="/nosotros/contactanos/servicio_cliente"
          component={ContactanosServicio}
        />
        <Route
          path="/nosotros/contactanos/capital_humano"
          component={ContactanosCapital}
        />
        <Route
          path="/nosotros/preguntas_frecuentes"
          component={PreguntasFrecuentes}
        />
        <Route
          path="/nosotros/capital_humano"
          component={QueremosEscucharte}
        />
        <Route
          path="/nosotros/capital_humano/careers"
          component={QueremosEscucharte}
        />
        <Route
          path="/nosotros/capital_humano/queremos_escucharte"
          component={QueremosEscucharte}
        />
        <Route path="/nosotros/auditorio" component={Auditorio} />

        <Route path="/nosotros/biva_sustentable" component={Equipo} />
        <Route path="/nosotros/biva_sustentable/compromiso_objetivos" component={Equipo} />
        <Route path="/nosotros/biva_sustentable/estrategia" component={Equipo} />

        <Route path="/nosotros/newsletter" component={PreguntasFrecuentes} />
        
        <Route component={NotFound} />
      </Switch>
    );

  
    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
      <div className="container">
        <div className="row">
          <div className={["d-none"].join(" ")}>
            <Sidebar classIcon={["fas fa-gavel"].join(" ")} setMenu={(menu,tipo) => this.onSetMenu(menu,tipo)}/>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30 padding_bottom_200">
            <div className="row margin_bottom_30">
              <div className="col-12 sin_padding_L">
                <div className={classes.breadcums}>
                  <p>
                    <Breadcrum   idPage={this.state.id_menu_activo} menu={this.state.menu}/>
                  </p>
                </div>
              </div>
            </div>
            {routes}
          </div>
        </div>
      </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitGenericos: params => {
      dispatch(action.initGenericos(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Nosotros);

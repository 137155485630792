import React, { Component } from "react";
import classes from "./AvisosAnuncios.css";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";

import Tabs from "../../../components/ui/tabs/Tabs";

import AvisosGenerales from "../vigilanciaMercados/AvisosGenerales";
import AnunciosBiva from "../anunciosBiva/AnunciosBiva";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getUrlDocument } from "../../../stores/utils/utilities";

class AvisosAnuncios extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    tab: 0,
    showModal: false,
    tabs: [
      {
        title: "Avisos generales"
      },
      {
        title: "Anuncios BIVA"
      }
    ]
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
          tabs: [
            {
              title: messages ? messages["avisosyreportes.listado.avisosgenerales.titulo"] : ""
            },
            {
              title: messages ? messages["avisosyreportes.listado.anunciosBiva.titulo"] : ""
            }
          ]
        });
      }
    }
  }

  onChangeItemSelected = (index = 0) => {
    this.setState({
      tab: index
    });
  };

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  render() {
    const tabMostrar = (
      <div className="row margen_top_30">
        <div className={["col-sm-12 sin_padding", classes.myCol].join(" ")}>
          <Tabs data={this.state.tabs} tipo={2} clicked={i => this.onChangeItemSelected(i)} />
        </div>
      </div>
    );

    let ruta = null;
    if (this.props.doc) {
      ruta = getUrlDocument(this.props.doc[0].url);

      if (ruta.includes("/cms")) {
        if (ruta.includes("?")) {
          ruta = `${ruta}&download=true`;
        }else{
          ruta = `${ruta}?download=true`;
        }
      }
      
    }

    let tabSeleccionado = null;
    if (this.state.tab === 0) {
      tabSeleccionado = <AvisosGenerales {...this.props}/>;
    } else  {
      tabSeleccionado = <AnunciosBiva  {...this.props}/>;
    } 

  

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <div className="row">
            <div className="col sin_padding">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="informacionmercado.ultimodia.titulo" defaultMessage=" " />
             </h1>
            </div>
          </div>

          <div className=" margen_top_30">{tabMostrar}</div>
          <div className=" margen_top_30">{tabSeleccionado}</div>

          

        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(3));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvisosAnuncios);

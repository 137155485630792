import React, { Component } from "react";
import classes from "./HomeLinks.css";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import SliderDocument from "../sliderDocument/SliderDocument";
import { getUrlDocument } from "../../../stores/utils/utilities";

class HomeLinks extends Component {
  state = {
    espacio: []
  };
  componentDidMount() {
    this.props.onDocumentos();
  }

  componentDidUpdate(prevProps) {
    if (this.props.espacio !== prevProps.espacio) {
      this.setState({
        espacio: this.props.espacio
      });
    }
  }

  render() {
    let espacios = null;

    let indexImage = 0;
    espacios = this.state.espacio.map((item, i) => {
      if (indexImage > 4) {
        indexImage = 0;
      }
      indexImage++;
      let claseImagen = null;

      switch (indexImage) {
        case 1:
          claseImagen = classes.imagen1;
          break;

        case 2:
          claseImagen = classes.imagen2;
          break;

        case 3:
          claseImagen = classes.imagen3;
          break;

        case 4:
          claseImagen = classes.imagen4;
          break;

        default:
          break;
      }

      let rutaImagen = null;
      if (item.pictureRelativeUrl) {
        rutaImagen = getUrlDocument(item.pictureRelativeUrl)
      }
      let dynamicProps=[];
      let variable = item.effect;
      dynamicProps[variable] = true;
      if (item.link.includes('http')) {
        return (
          <Fade duration={3000} {...dynamicProps}>
            <div className={[classes.imagen, claseImagen].join(" ")}>
              <a href={item.link}  rel="noopener noreferrer">
                <img src={rutaImagen} alt="" />
                <div class={classes.fondoDegradado} />
                <div class={classes.tituloImagen}>
                  <span className={["color_blanco nunito texto_40 bold"].join(" ")}>{item.title}</span>
                </div>
              </a>
            </div>
          </Fade>
        );
      }
      return (
        <Fade duration={3000} {...dynamicProps}>
          <div className={[classes.imagen, claseImagen].join(" ")}>
            <NavLink to={item.link} className={[].join(" ")}>
              <img src={rutaImagen} alt="" />
              <div class={classes.fondoDegradado} />
              <div class={classes.tituloImagen}>
                <span className={["color_blanco nunito texto_40 bold"].join(" ")}>{item.title}</span>
              </div>
            </NavLink>
          </div>
        </Fade>
      );
    });

    return (
      <React.Fragment>
        {/* {espacios} */}
        <SliderDocument
                datos={this.state.espacio ? this.state.espacio : []}
                tipo={4}
              />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDocumentos: () => {
      dispatch(action.initBoxEspacio());
    }
  };
};

const mapStateToProps = state => {
  return {
    espacio: state.cuadriculas.espacio
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeLinks);

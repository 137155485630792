import axios from "axios";
import * as actionTypes from "./actionTypes";
import StringUtils from "../utils/StringUtils";


const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/fondos`;

const setFondos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOS_SUCCESS,
      fondos: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOS_SUCCESS,
    fondos: data
  };
};

const setFondosDetail = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSDETAIL_SUCCESS,
      fondoDetail: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSDETAIL_SUCCESS,
    fondoDetail: data
  };
};

export const initFondosDetail = clave => {
  let url = `${dominio}/emisoras/fondos/${clave}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFondosDetail(response.data));
      })
      .catch(err => dispatch(setFondosDetail(err.data)));
  };
};

export const initFondosDocs = clave => {
  let url = `${dominio}/emisoras/prospectos/${clave}/documentos-fondo`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFondosDocs(response.data));
      })
      .catch(err => dispatch(setFondosDocs(err.data)));
  };
};

const setFondosDocs = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSDETAIL_SUCCESS,
      fondoDocs: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSDETAIL_SUCCESS,
    fondoDocs: data
  };
};

export const filterFondos = (size, page, claveEmisora = null, claveFondo = null, tipo = null, clasificacion = null) => {
  let url = `${base_url}?size=${size}&page=${page}`;

  url = claveEmisora && claveEmisora !== "" ? `${url}&claveEmisora=${claveEmisora}` : url;
  url = claveFondo && claveFondo !== "" ? `${url}&clave=${claveFondo}` : url;
  url = tipo && tipo !== "" ? `${url}&tipo=${tipo}` : url;
  url = clasificacion && clasificacion !== "" ? `${url}&clasificacion=${clasificacion}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFondos(response.data));
      })
      .catch(err => dispatch(setFondos(err.data)));
  };
};

const setFondosInformacionCorporativa = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSINFORMACIONCORPORATIVA_SUCCESS,
      informacion: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSINFORMACIONCORPORATIVA_SUCCESS,
    informacion: data
  };
};

export const initFondosInformacionCorporativa = (
  size,
  page,
  claveOperadora = null,
  claveFondo = null,
  tipoDocumento = null,
  fechaInicio = null,
  fechaFin = null,
  periodo = null,
  ejercicio = null
) => {
  let url = `${dominio}/emisoras/fondos/informacion-corporativa?size=${size}&page=${page}`;
  url = claveOperadora && claveOperadora !== "" ? `${url}&empresaAsociada=${claveOperadora}` : url;
  url = claveFondo && claveFondo !== "" ? `${url}&empresa=${claveFondo}` : url;
  url = tipoDocumento && tipoDocumento !== "" && tipoDocumento.length > 0 ? `${url}&tipoDocumento=${tipoDocumento}` : url;
  url = fechaInicio ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin ? `${url}&fechaFin=${fechaFin}` : url;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFondosInformacionCorporativa(response.data));
      })
      .catch(err => dispatch(setFondosInformacionCorporativa(err.data)));
  };
};

const setFondosConcentradoPrecios = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
      concentradoPrecios: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
    concentradoPrecios: data
  };
};

export const initFondosConcentradoPrecios = clave => {
  let url = `${dominio}/emisoras/fondos/precios/${clave}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFondosConcentradoPrecios(response.data));
      })
      .catch(err => dispatch(setFondosConcentradoPrecios(err.data)));
  };
};

const setFondosConcentradoPreciosGeneral = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
      fondos: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
    fondos: data
  };
};

export const initFondosConcentradoPreciosGeneral = (
  size,
  page,
  claveEmisora = null,
  claveFondo = null,
  tipo = null,
  clasificacion = null,
  fechaInicio = null,
  fechaFin = null
) => {
  let url = `${base_url}/precios?size=${size}&page=${page}`;

  url = claveEmisora && claveEmisora !== "" ? `${url}&claveEmisora=${claveEmisora}` : url;
  url = claveFondo && claveFondo !== "" ? `${url}&clave=${claveFondo}` : url;
  url = tipo && tipo !== "" ? `${url}&tipo=${tipo}` : url;
  url = clasificacion && clasificacion !== "" ? `${url}&clasificacion=${clasificacion}` : url;
  url = fechaInicio ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin ? `${url}&fechaFin=${fechaFin}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFondosConcentradoPreciosGeneral(response.data));
      })
      .catch(err => dispatch(setFondosConcentradoPreciosGeneral(err.data)));
  };
};


const setFondosConcentradoPreciosHistorico = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
      fondos: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
    fondos: data
  };
};

export const initFondosConcentradoPreciosHistorico = (
  size,
  page,
  claveEmisora = null,
  claveFondo = null,
  tipo = null,
  clasificacion = null,
  serie = null,
  fechaInicio = null,
  fechaFin = null
) => {
  let url = `${base_url}/precios/historico`;
  let params = {
    size: size === "" ? null : size,
    page: page === "" ? null : page,
    claveEmisora: claveEmisora === "" ? null : claveEmisora,
    clave: claveFondo === "" ? null : claveFondo,
    tipo: tipo === "" ? null : tipo,
    clasificacion: clasificacion === "" ? null : clasificacion,
    serie: serie && serie.length && serie.length > 0 && serie[0] !== "" ? [...serie] : null,
    fechaInicio: fechaInicio === "" ? null : fechaInicio,
    fechaFin: fechaFin === "" ? null : fechaFin
  }
  const queryString = StringUtils.jsonToQueryString(params);

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setFondosConcentradoPreciosHistorico(response.data));
      })
      .catch(err => dispatch(setFondosConcentradoPreciosHistorico(err.data)));
  };
};

const setFondosComposicionCarteraUrlCsv = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSURLCSV_SUCCESS,
      composicionUrlCsv: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSURLCSV_SUCCESS,
    composicionUrlCsv: data
  };
};

export const initFondosComposicionCarteraUrlCsv = (
  claveEmisora = null,
  claveFondo = null,
) => {
  let url = `${base_url}/composicion-cartera/archive`;
  if (claveEmisora && claveFondo) {
      let params = {
        idOperadora: claveEmisora,
        idFondo: claveFondo,
      }
      const queryString = StringUtils.jsonToQueryString(params);
      return dispatch => {
        axios
          .get(url + queryString)
          .then(response => {
            dispatch(setFondosComposicionCarteraUrlCsv(response.data));
          })
          .catch(err => dispatch(setFondosComposicionCarteraUrlCsv(err.data)));
      };
  }else{
      return dispatch => {
        dispatch(setFondosComposicionCarteraUrlCsv(null));
      }
  }
};

const setFondosComposicionCartera = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCOMPOSICIONCARTERA_SUCCESS,
      composicionCartera: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCOMPOSICIONCARTERA_SUCCESS,
    composicionCartera: data
  };
};

export const initFondosComposicionCartera = (
  // size,
  // page,
  urlCsv = null
) => {
    if (urlCsv) {
        
  let url = `${dominio}${urlCsv}`;
  // let url = `${urlCsv}`;
  // let params = {
  //     size:size   === "" ? null : size,
  //     page:page   === "" ? null : page,
  //   urlCsv: urlCsv === "" ? null : urlCsv.url
  // }
  // const queryString = StringUtils.jsonToQueryString(params);
  return dispatch => {
    axios
      // .type(Blob)
      .get(url)
      .then(response => {
        dispatch(setFondosComposicionCartera(response.data));
        console.log(response.data);
        console.log(response.status);
        console.log(response.statusText);
        console.log(response.headers);
        console.log(response.config);
      })
      .catch(err => dispatch(setFondosComposicionCartera(err.data)));
  };
}else{
    return dispatch => {
        dispatch(setFondosComposicionCartera(null));
      }
}

};
//=====================================FILTERS=================================================

const setClavesOperadoraFondos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCVEOPERADORA_SUCCESS,
      clavesOperadora: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCVEOPERADORA_SUCCESS,
    clavesOperadora: data
  };
};

export const initClavesOperadoraFondos = ({
  claveFondo = null,
  tipoFondo = null,
  clasificacionFondo = null,
  precios = null
}) => {
  let preciostxt = "";
  if (precios) {
    preciostxt = "precios/";
  }
  let url = `${dominio}/emisoras/fondos/${preciostxt}clave-operadora?1=1`;

  url = claveFondo && claveFondo !== "" ? `${url}&clave=${claveFondo}` : url;
  url = tipoFondo && tipoFondo !== "" ? `${url}&tipo=${tipoFondo}` : url;
  url = clasificacionFondo && clasificacionFondo !== "" ? `${url}&clasificacion=${clasificacionFondo}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesOperadoraFondos(response.data));
      })
      .catch(err => dispatch(setClavesOperadoraFondos(err.data)));
  };
};

export const initClavesOperadoraFondosComposicionCartera = () => {

  let url = `${dominio}/emisoras/fondos/composicion-cartera/clave-operadora?1=1`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesOperadoraFondos(response.data));
      })
      .catch(err => dispatch(setClavesOperadoraFondos(err.data)));
  };
};

//================================================================

const setClavesFondos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCVEFONDO_SUCCESS,
      clavesFondo: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCVEFONDO_SUCCESS,
    clavesFondo: data
  };
};

export const initClavesFondos = ({ claveOperadora = null, tipoFondo = null, clasificacionFondo = null, precios = null }) => {
  let preciostxt = "";
  if (precios) {
    preciostxt = "precios/";
  }
  let url = `${dominio}/emisoras/fondos/${preciostxt}clave-fondo?1=1`;

  url = claveOperadora && claveOperadora !== "" ? `${url}&claveEmisora=${claveOperadora}` : url;
  url = tipoFondo && tipoFondo !== "" ? `${url}&tipo=${tipoFondo}` : url;
  url = clasificacionFondo && clasificacionFondo !== "" ? `${url}&clasificacion=${clasificacionFondo}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesFondos(response.data));
      })
      .catch(err => dispatch(setClavesFondos(err.data)));
  };
};

export const initClavesFondosComposicionCartera = ({ claveOperadora = null}) => {
  let url = `${dominio}/emisoras/fondos/composicion-cartera/clave-fondo?1=1`;

  url = claveOperadora && claveOperadora !== "" ? `${url}&claveEmisora=${claveOperadora}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesFondos(response.data));
      })
      .catch(err => dispatch(setClavesFondos(err.data)));
  };
};

//==========================================================================

const setTiposFondos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCVEFONDO_SUCCESS,
      tiposFondo: null
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCVEFONDO_SUCCESS,
    tiposFondo: data
  };
};

export const initTiposFondos = ({ claveOperadora = null, claveFondo = null, clasificacionFondo = null, precios = null }) => {
  let preciostxt = "";
  if (precios) {
    preciostxt = "precios/";
  }
  let url = `${dominio}/emisoras/fondos/${preciostxt}tipo-fondo?1=1`;
  url = claveOperadora && claveOperadora !== "" ? `${url}&claveEmisora=${claveOperadora}` : url;
  url = claveFondo && claveFondo !== "" ? `${url}&clave=${claveFondo}` : url;
  url = clasificacionFondo && clasificacionFondo !== "" ? `${url}&clasificacion=${clasificacionFondo}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTiposFondos(response.data));
      })
      .catch(err => dispatch(setTiposFondos(err.data)));
  };
};

//==============================================================================

const setClasificacionesFondos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCLASIFICACIONFONDO_SUCCESS,
      clasificacionesFondo: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCLASIFICACIONFONDO_SUCCESS,
    clasificacionesFondo: data
  };
};

export const initClasificacionesFondos = ({ claveOperadora = null, claveFondo = null, tipoFondo = null, precios = null }) => {
  let preciostxt = "";
  if (precios) {
    preciostxt = "precios/";
  }
  let url = `${dominio}/emisoras/fondos/${preciostxt}clasificacion-fondo?1=1`;
  url = claveOperadora && claveOperadora !== "" ? `${url}&claveEmisora=${claveOperadora}` : url;
  url = claveFondo && claveFondo !== "" ? `${url}&clave=${claveFondo}` : url;
  url = tipoFondo && tipoFondo !== "" ? `${url}&tipo=${tipoFondo}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClasificacionesFondos(response.data));
      })
      .catch(err => dispatch(setClasificacionesFondos(err.data)));
  };
};

//=============================================================================================

//=============================================================================================

const setClavesFondosInformacionCorporativa = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCVEFONDOINFORMACIONCORPORATIVA_SUCCESS,
      clavesFondo: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCVEFONDOINFORMACIONCORPORATIVA_SUCCESS,
    clavesFondo: data
  };
};

export const initClavesFondosInformacionCorporativa = ({ empresaAsociada = null, tipoDocumento = null }) => {
  let url = `${dominio}/emisoras/fondos/informacion-corporativa/clave-fondo?1=1`;

  url = empresaAsociada && empresaAsociada !== "" ? `${url}&empresaAsociada=${empresaAsociada}` : url;
  url = tipoDocumento && tipoDocumento !== "" ? `${url}&tipo=${tipoDocumento}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesFondosInformacionCorporativa(response.data));
      })
      .catch(err => dispatch(setClavesFondosInformacionCorporativa(err.data)));
  };
};

//================================================================

const setClavesOperadoraInformacionCorporativa = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCVEOPERADORAINFORMACIONCORPORATIVA_SUCCESS,
      clavesOperadora: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCVEOPERADORAINFORMACIONCORPORATIVA_SUCCESS,
    clavesOperadora: data
  };
};

export const initClavesOperadoraInformacionCorporativa = ({ empresa = null, tipoDocumento = null }) => {
  let url = `${dominio}/emisoras/fondos/informacion-corporativa/clave-operadora?1=1`;

  url = empresa && empresa !== "" ? `${url}&empresa=${empresa}` : url;
  url = tipoDocumento && tipoDocumento !== "" ? `${url}&tipo=${tipoDocumento}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesOperadoraInformacionCorporativa(response.data));
      })
      .catch(err => dispatch(setClavesOperadoraInformacionCorporativa(err.data)));
  };
};

//================================================================

const setTipoDocumento = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSTIPODOCUMENTO_SUCCESS,
      tiposDocumento: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSTIPODOCUMENTO_SUCCESS,
    tiposDocumento: data
  };
};

export const initTipoDocumentoFondos = ({ empresaAsociada = null, empresa = null }) => {
  let url = `${dominio}/emisoras/afores/informacion-corporativa/tipo-documento?1=1`;

  url = empresaAsociada && empresaAsociada !== "" ? `${url}&empresaAsociada=${empresaAsociada}` : url;
  url = empresa && empresa !== "" ? `${url}&empresa=${empresa}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoDocumento(response.data));
      })
      .catch(err => dispatch(setTipoDocumento(err.data)));
  };
};

//==============================================================================

const setSerieFondos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSSERIE_SUCCESS,
      series: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSSERIE_SUCCESS,
    series: data
  };
};

export const initSerieFondos = ({ claveOperadora = null, claveFondo = null, tipoFondo = null, clasificacionFondo = null, precios = null }) => {
  let preciostxt = "";
  if (precios) {
    preciostxt = "precios/";
  }
  let url = `${dominio}/emisoras/fondos/precios/serie?1=1`;
  url = claveOperadora && claveOperadora !== "" ? `${url}&claveEmisora=${claveOperadora}` : url;
  url = claveFondo && claveFondo !== "" ? `${url}&clave=${claveFondo}` : url;
  url = tipoFondo && tipoFondo !== "" ? `${url}&tipo=${tipoFondo}` : url;
  url = clasificacionFondo && clasificacionFondo !== "" ? `${url}&clasificacion=${clasificacionFondo}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSerieFondos(response.data));
      })
      .catch(err => dispatch(setSerieFondos(err.data)));
  };
};
//================================================================

const setPeriodo = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSPERIODO_SUCCESS,
      periodo: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSPERIODO_SUCCESS,
    periodo: data
  };
};

export const initPeriodoFondos = ({ tipoDocumento = null, ejercicio = null }) => {
  let url = `${dominio}/emisoras/fondos/informacion-corporativa/periodo?1=1`;

  url = tipoDocumento && tipoDocumento !== "" ? `${url}&tipoDocumento=${tipoDocumento}` : url;
  url = ejercicio && ejercicio !== "" ? `${url}&ejercicio=${ejercicio}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPeriodo(response.data));
      })
      .catch(err => dispatch(setPeriodo(err.data)));
  };
};

//==============================================================================

const setEjercicio = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSEJERCICIO_SUCCESS,
      ejercicio: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSEJERCICIO_SUCCESS,
    ejercicio: data
  };
};

export const initEjercicioFondos = ({ tipoDocumento = null, periodo = null }) => {
  let url = `${dominio}/emisoras/fondos/informacion-corporativa/ejercicio?1=1`;

  url = tipoDocumento && tipoDocumento !== "" ? `${url}&tipoDocumento=${tipoDocumento}` : url;
  url = periodo && periodo !== "" ? `${url}&periodo=${periodo}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjercicio(response.data));
      })
      .catch(err => dispatch(setEjercicio(err.data)));
  };
};

// ===========================HISTORICO CONCENTRADO PRECIOS==============================================

const setFondosConcentradoPreciosHistoricoGrafica = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
      fondosHistoricoGrafica: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS,
    fondosHistoricoGrafica: data
  };
};

export const initFondosConcentradoPreciosHistoricoGrafica = (
  size,
  page,
  claveEmisora = null,
  claveFondo = null,
  tipo = null,
  clasificacion = null,
  serie = null,
  fechaInicio = null,
  fechaFin = null
) => {
  let url = `${base_url}/precios/historico`;
  let params = {
    size: size === "" ? null : size,
    page: page === "" ? null : page,
    claveEmisora: claveEmisora === "" ? null : claveEmisora,
    clave: claveFondo === "" ? null : claveFondo,
    tipo: tipo === "" ? null : tipo,
    clasificacion: clasificacion === "" ? null : clasificacion,
    serie: serie && serie.length && serie.length > 0 && serie[0] !== "" ? [...serie] : null,
    fechaInicio: fechaInicio === "" ? null : fechaInicio,
    fechaFin: fechaFin === "" ? null : fechaFin
  }
  const queryString = StringUtils.jsonToQueryString(params);
  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setFondosConcentradoPreciosHistoricoGrafica(response.data));
      })
      .catch(err => dispatch(setFondosConcentradoPreciosHistoricoGrafica(err.data)));
  };
};

//==========================SERIES CONCENTRADO PRECIO HISTORICO====================================================

const setSerieFondosHistorico = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSSERIE_SUCCESS,
      seriesHistorico: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSSERIE_SUCCESS,
    seriesHistorico: data
  };
};

export const initSerieFondosHistorico = ({ claveOperadora = null, claveFondo = null, tipoFondo = null, clasificacionFondo = null }) => {

  let url = `${dominio}/emisoras/fondos/precios/serie`;

  let params = {
    claveEmisora: claveOperadora === "" ? null : claveOperadora,
    clave: claveFondo === "" ? null : claveFondo
  }
  const queryString = StringUtils.jsonToQueryString(params);

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setSerieFondosHistorico(response.data));
      })
      .catch(err => dispatch(setSerieFondosHistorico(err.data)));
  };
};

//======================FONDOS HISTORICO==========================================

const setClavesFondosHistorico = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FONDOSCVEFONDO_SUCCESS,
      clavesFondoHistorico: []
    };
  }
  return {
    type: actionTypes.FETCH_FONDOSCVEFONDO_SUCCESS,
    clavesFondoHistorico: data
  };
};

export const initClavesFondosHistorico = ({ claveOperadora = null, tipoFondo = null, clasificacionFondo = null }) => {

  let url = `${dominio}/emisoras/fondos/precios/clave-fondo`;

  let params = {
    claveEmisora: claveOperadora === "" ? null : claveOperadora,
    tipo: tipoFondo === "" ? null : tipoFondo,
    clasificacion: clasificacionFondo === "" ? null : clasificacionFondo
  }
  const queryString = StringUtils.jsonToQueryString(params);

  return dispatch => {
    axios
      .get(url + queryString)
      .then(response => {
        dispatch(setClavesFondosHistorico(response.data));
      })
      .catch(err => dispatch(setClavesFondosHistorico(err.data)));
  };
};
import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../../stores/actions/index";
import OperacionBox from "../../../../../components/ui/operacionBox/OperacionBox";
import Box from "../../../../../components/ui/box/Box";
import Tabs from "../../../../../components/ui/tabs/Tabs";
import classes from "./Tabs.css";
import { FormattedHTMLMessage } from "react-intl";
import { getLan } from "../../../../../stores/utils/utilities";

const tabs = [
  {
    image: "",
    icon: "",
    title: "Top 5"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Top 5 local" : "Local Top 5"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ?  "Top 5 SIC" : "SIC Top 5"
  }
];

class EmisorasOperadas extends Component {
  state = {
    opcionTop: 0,
    datos: null,
    top: 5
  };

  componentDidMount() {
    this.props.oninitUltimoDiaMesEmisoraTotal();
  }

  componentDidUpdate(nextProps) {
    if (this.props.total !== nextProps.total) {
      this.setState({
        datos: this.props.total
      });
    }

    if (this.props.local !== nextProps.local) {
      this.setState({
        datos: this.props.local
      });
    }

    if (this.props.sic !== nextProps.sic) {
      this.setState({
        datos: this.props.sic
      });
    }
  }

  onChangeItemSelected = (index = 0) => {
    let datos = null;
    if (index === 0) {
      //top 5
      this.props.oninitUltimoDiaMesEmisoraTotal();
      if (this.props.total) {
        datos = this.props.total;
      }
    } else if (index === 1) {
      //top 5 local
      this.props.oninitUltimoDiaMesEmisoraLocal();
      if (this.props.local) {
        datos = this.props.local;
      }
    } else {
      //top 5 SIC
      this.props.oninitUltimoDiaMesEmisoraSic();
      if (this.props.sic) {
        datos = this.props.sic;
      }
    }

    this.setState({
      opcionTop: index,
      datos: datos
    });
  };

  render() {
    const box2 = {
      leftIcon: "far fa-file-excel",
      title: getLan() === "es" ?  "Descargar operación último día" : "Download Last day trading",
      subtitle: "Formato XLS",
      rightIcon: "fas fa-arrow-down",
      url: this.props.descargaDocumento
    };
    let tabMostrar = (
      <div className="margen_top_30">
        <div className={["row", classes.tabsContainer].join(" ")}>
          <div className={["texto_14 montse regular", classes.myCol].join(" ")}>{getLan() === "es" ? "Mostrar:" : "View:"}</div>
          <div className={["col", classes.myCol2].join(" ")}>
            <Tabs data={tabs} tipo={1} clicked={i => this.onChangeItemSelected(i)} />
          </div>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        {tabMostrar}
        <div className="row margen_top_30">
          <OperacionBox data={this.state.datos} tipo={3} top={this.state.top} />
        </div>
        <div className={""}>
          <span className={"texto_12 montse regular"}>{getLan() === "es" ? "*Notas." : "*Notes."}</span>
          <br />
        </div>

        <div className="row margin_top_30">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2 className={""}>
                <FormattedHTMLMessage id="informacionmercado.ultimodia.interesado.reporte" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <Box modelo="modelo_1" data={box2} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    local: state.informacionMercado.ultimoDiaEmisorasLocal,
    sic: state.informacionMercado.ultimoDiaEmisorasSic,
    total: state.informacionMercado.ultimoDiaEmisorasTotal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    oninitUltimoDiaMesEmisoraLocal: () => {
      dispatch(action.initUltimoDiaMesEmisoraLocal(1));
    },
    oninitUltimoDiaMesEmisoraSic: () => {
      dispatch(action.initUltimoDiaMesEmisoraSic(1));
    },
    oninitUltimoDiaMesEmisoraTotal: () => {
      dispatch(action.initUltimoDiaMesEmisoraTotal(1));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmisorasOperadas);

import React from "react";
import Construccion from "../../../components/construccion/Construccion";

export default function Certificaciones() {
  return (
    <Construccion
      titulo="Certificaciones y opiniones"
      mensaje="Pronto podrás consultar las certificaciones y opiniones"
    />
  );
}

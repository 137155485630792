import React, { Component } from "react";

import classes from './CardColocaciones.css'
import Auxs from "../../../hoc/auxs/Auxs";
import { getLocal, getLan, getRealDateMilliesDayMonthYear } from "../../../stores/utils/utilities";

class CardColocaciones extends Component {
    handleClickSelectCard(event) {
        this.props.updateParentSelectedCard(this.props.id, this.props.emisora, this.props.razonSocial, this.props.clave, this.props.urlLogoEmisora, this.props.urlSitioWeb, this.props.tipoInstrumento, this.props.montoTentativoEmision, this.props.fechaTentativaEmision, this.props.sustentable);
    }

    render() {
        const props = this.props;
        return (
            <Auxs>
                <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4  sin_padding"].join(" ")}>
                    <div className={[this.props.sustentable ? classes.card_sustentable : classes.card, "card"].join(" ")}>
                        {/* <img src="https://jupiterbootstoacc01.blob.core.windows.net/data-maintenance/biva_1749.png" className="card-img-top" alt="..." /> */}
                        <div className={[classes.padding, "card-body margin_top_10"].join(" ")}>
                            {/* <img src="https://www.fibrahd.com.mx/images/logo.png" className="card-img-top margin_top_5 margin_left_5 margin_derecha_10" alt="..." height="200px" width="200px" /> */}
                            <img src={this.props.urlLogoEmisora} className="card-img-top margin_top_5 margin_left_5 margin_derecha_10" alt="..." height="200px" width="200px" />
                        </div>
                        <div className={[this.props.sustentable ? classes.card_body_sustentable : classes.card_body, "card-body margin_top_10"].join(" ")}>
                            <h5 className="card-title nunito texto_24 bold center">{this.props.razonSocial}</h5>
                            <p className={[this.props.sustentable ? classes.content_green : classes.content_peach, "montse texto_16 texto_centro border_radius5 margen_top_10"].join(" ")}>{this.props.tipoInstrumento}</p>
                            <p className="card-text montse texto_14">Monto tentativo a emitir:</p>
                            <p className="card-text montse texto_20 semi_bold">$ {this.props.montoTentativoEmision}</p>
                            <p className="card-text montse texto_14">Fecha tentativa de emisión:</p>
                            <p className="card-text montse texto_18 semi_bold">{getRealDateMilliesDayMonthYear(this.props.fechaTentativaEmision)}</p>
                            {this.props.serie !== null ?
                                <div>
                                    <p className="card-text montse texto_14">Serie:</p>
                                    <p className="card-text montse texto_18 semi_bold">{this.props.serie}</p>
                                </div>
                                : null}
                            <h2 className={["mb-0", classes.titulo_texto16_17].join(" ")}>
                                <button
                                    className={["btn btn-primary texto_centro", classes.btn_link2].join(" ")}
                                    type="button"
                                    onClick={this.handleClickSelectCard.bind(this)}
                                >
                                    <span className={[classes.titulo_texto16_17, "regular montse texto_16_17"].join(" ")}>Conoce más</span>
                                </button>
                                {this.props.sustentable ?
                                    // &nbsp;
                                    <i className={[classes.leaf, "fa fa-leaf fa-lg slash_green"].join(" ")} aria-hidden="true"></i> : null}
                            </h2>
                        </div>
                    </div>
                </div>
            </Auxs>
        );
    }
}
export default (CardColocaciones);

import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import classes from "./Contenido.css";

class Contenido extends Component {
  render() {
    let footer = null;
    let datos = null;
    if (this.props.footer) {
      const menu_ = [...this.props.footer.menu];
      datos = menu_;
      let footer_menu = [];
      datos.map((item, i) => {
        let datos_footer = [];

        item.submenu.map((submenu, indexSubMenu2) => {
          if (submenu.submenu === undefined) {
            datos_footer.push(submenu);
          } else {
            submenu.submenu.map(subsub => {
              datos_footer.push(subsub);
              return null;
            });
            return null;
          }
          return null;
        });
        footer_menu.push(datos_footer);
        return null;
      });
      footer = footer_menu.map((item, indexSubmenu) => {
        return (
          <div key={indexSubmenu} className={classes.contenedorSubmenu}>
            <ul className={classes.ul}>
              {item.map((submenu, indexSubMenu2) => {
                return (
                  <li key={indexSubMenu2} className={classes.li}>
                    <NavLink className={classes.linkSubmenu} to={submenu.to}>
                      <span
                        className={[
                          classes.textoLinkSubmenu,
                          "nunito texto_16  color_blanco",
                        ].join(" ")}
                      >
                        {submenu.name}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        <div className={classes.contenedorFooter}>{footer}</div>
      </React.Fragment>
    );
  }
}

const mapStateToPorps = state => {
  return {
    footer: state.menu.footer
  };
};

export default connect(mapStateToPorps)(Contenido);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";

import classes from "./Alianzas.css";
import NotFound from "../../components/notFound/NotFound";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";
import Estrategicas from "./estrategicas/Estrategicas";
import Organismos from './organismos/Organismos';
import Estatales from './estatales/Estatales';

class Alianzas extends Component {
  state = {
    menu: null,
    id_menu_activo: null
  };

  componentDidMount() {
    this.props.onInitIntl(2);

    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage:"alianzas"
    };

    this.props.onInitGenericos(params);
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu
      });
    }else{
      this.setState({
        id_menu_activo: menu
      });
      
    }
  };

  render() {
    const routes = (
      <Switch>
        <Route path="/instituto_biva/acuerdos_alianzas" exact component={Estrategicas}/>
        <Route path="/alianzas/convenio_asociaciones/organismos" exact component={Organismos}/>
        <Route path="/instituto_biva/convenio_asociaciones/organismos" exact component={Organismos}/>
        <Route path="/alianzas/convenio_asociaciones/estatales" exact component={Estatales}/>
        <Route path="/instituto_biva/convenio_asociaciones/estatales" exact component={Estatales}/>
        <Route component={NotFound} />
      </Switch>
    );

    return (
      <div className="container">
        <div className="row">
          <div className={["d-none"].join(" ")}>
            <Sidebar classIcon={["fas fa-gavel"].join(" ")} setMenu={(menu,tipo) => this.onSetMenu(menu,tipo)}/>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30">
            <div className="row margin_bottom_30">
              <div className="col-12 sin_padding_L">
                <div className={classes.breadcums}>
                  <p>
                    <Breadcrum   idPage={this.state.id_menu_activo} menu={this.state.menu}/>
                  </p>
                </div>
              </div>
            </div>
            {routes}
          </div>
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onInitGenericos: params => {
      dispatch(action.initGenericos(params));
    }
  };
};


export default connect(
  null,
  mapDispatchToProps
)(Alianzas);

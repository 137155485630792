import React, { Component } from "react";
import Container from "./Container";

class Patrimonio extends Component {
   render(){
        return (
            <Container type={"patrimonio"} location={this.props.location} history={this.props.history}/>
        );
    }
}

export default Patrimonio;
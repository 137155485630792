import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";
import classes from "./Regulacion.css";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";
import NotFound from "../../components/notFound/NotFound";

// Pages
import Autorregulacion from "./autorregulacion/Autorregulacion";
import Normatividad from "./normatividad/Normatividad";

class Regulacion extends Component {
  state = {
    menu: null,
    id_menu_activo: null
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage: "regulacion"
    };

    this.props.onInitGenericos(params);
  }


  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu
      });
    }else{
      this.setState({
        id_menu_activo: menu
      });
      
    }
  };

  render() {
    const routes = (
      <Switch>
        <Route path="/regulacion/autorregulacion" exact component={Autorregulacion} />
        <Route path="/regulacion/normatividad" exact component={Normatividad} />
        <Route component={NotFound} />
      </Switch>
    );

    return (
      <div className="container">
        <div className="row">
          <div className={["d-none"].join(" ")}>
            <Sidebar classIcon={["fas fa-gavel"].join(" ")} setMenu={(menu,tipo) => this.onSetMenu(menu,tipo)}/>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30 padding_bottom_200">
            <div className="row margin_bottom_30">
              <div className="col-12 sin_padding_L">
                <div className={classes.breadcums}>
                  <p>
                    <Breadcrum   idPage={this.state.id_menu_activo} menu={this.state.menu}/>
                  </p>
                </div>
              </div>
            </div>
            {routes}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitGenericos: params => {
      dispatch(action.initGenericos(params));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Regulacion);

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/afores`;

const setAfores = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AFORES_SUCCESS,
      afores: []
    };
  }
  return {
    type: actionTypes.FETCH_AFORES_SUCCESS,
    afores: data
  };
};

const setAforesDetail = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AFORESDETAIL_SUCCESS,
      aforeDetail: []
    };
  }
  return {
    type: actionTypes.FETCH_AFORESDETAIL_SUCCESS,
    aforeDetail: data
  };
};

export const initAforesDetail = claveSiafore => {
  let url = `${dominio}/emisoras/afores/${claveSiafore}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAforesDetail(response.data));
      })
      .catch(err => console.log(err));
  };
};

export const filterAfores = (
  size,
  page,
  claveEmisora = null,
  clave = null,
  tipo = null,
  clasificacion = null,
  fechaInicio = null,
  fechaFin = null
) => {
  let url = `${base_url}?size=${size}&page=${page}`;

  url =
    claveEmisora && claveEmisora !== ""
      ? `${url}&claveEmisora=${claveEmisora}`
      : url;
  url = clave && clave !== "" ? `${url}&clave=${clave}` : url;
  url = tipo && tipo !== "" ? `${url}&tipo=${tipo}` : url;
  url =
    clasificacion && clasificacion !== ""
      ? `${url}&clasificacion=${clasificacion}`
      : url;
  url =
    fechaInicio && fechaInicio !== ""
      ? `${url}&fechaInicio=${fechaInicio}`
      : url;
  url = fechaFin && fechaFin !== "" ? `${url}&fechaFin=${fechaFin}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAfores(response.data));
      })
      .catch(err => dispatch(setAfores(err.data)));
  };
};

const setAforesInformacionCorporativa = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AFORESINFORMACIONCORPORATIVA_SUCCESS,
      informacion: []
    };
  }
  return {
    type: actionTypes.FETCH_AFORESINFORMACIONCORPORATIVA_SUCCESS,
    informacion: data
  };
};

export const initAforesInformacionCorporativa = (
  size,
  page,
  claveAfore = null,
  claveSiefore = null,
  tipoDocumento = null,
  fechaInicio = null,
  fechaFin = null) => {

  let url = `${dominio}/emisoras/afores/informacion-corporativa?size=${size}&page=${page}`;
  url = claveAfore && claveAfore !== "" ? `${url}&empresaAsociada=${claveAfore}` : url;
  url = claveSiefore && claveSiefore !== "" ? `${url}&empresa=${claveSiefore}` : url;
  url = tipoDocumento && tipoDocumento !== "" && tipoDocumento.length > 0 ? `${url}&tipoDocumento=${tipoDocumento}` : url;
  url = fechaInicio && fechaInicio !== "" ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin && fechaFin !== "" ? `${url}&fechaFin=${fechaFin}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAforesInformacionCorporativa(response.data));
      })
      .catch(err => dispatch(setAforesInformacionCorporativa(err.data)));
  };
};

const setAforesConcentradoPrecios = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AFORESCONCENTRADOPRECIOS_SUCCESS,
      concentradoPrecios: null
    };
  }
  return {
    type: actionTypes.FETCH_AFORESCONCENTRADOPRECIOS_SUCCESS,
    concentradoPrecios: data
  };
};

export const initAforesConcentradoPrecios = clave => {
  let url = `${dominio}/emisoras/afores/precios/${clave}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAforesConcentradoPrecios(response.data));
      })
      .catch(err => console.log(err));
  };
};


const setAforesConcentradoPreciosGeneral = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AFORES_SUCCESS,
      afores: []
    };
  }
  return {
    type: actionTypes.FETCH_AFORES_SUCCESS,
    afores: data
  };
};

export const initAforesConcentradoPreciosGeneral = (
  size,
  page,
  claveEmisora = null,
  clave = null,
  tipo = null,
  clasificacion = null,
  fechaInicio = null,
  fechaFin = null
) => {
  let url = `${base_url}/precios?size=${size}&page=${page}`;

  url =
    claveEmisora && claveEmisora !== ""
      ? `${url}&claveEmisora=${claveEmisora}`
      : url;
  url = clave && clave !== "" ? `${url}&clave=${clave}` : url;
  url = tipo && tipo !== "" ? `${url}&tipo=${tipo}` : url;
  url =
    clasificacion && clasificacion !== ""
      ? `${url}&clasificacion=${clasificacion}`
      : url;
  url =
    fechaInicio && fechaInicio !== ""
      ? `${url}&fechaInicio=${fechaInicio}`
      : url;
  url = fechaFin && fechaFin !== "" ? `${url}&fechaFin=${fechaFin}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAforesConcentradoPreciosGeneral(response.data));
      })
      .catch(err => dispatch(setAforesConcentradoPreciosGeneral(err.data)));
  };
};

//=======================FILTERS================================================

const setClavesAfore = (data = null) => {
  return {
    type: actionTypes.FETCH_AFORESCVEAFORE_SUCCESS,
    clavesAfore: data
  };
};

export const initClavesAfore = ({
  claveSiefore = null,
  tipoSiefore = null,
  clasificacionSiefore = null,
  precios = false
}) => {
  let preciostxt = "";
  if (precios) {
    preciostxt ='precios/'
  } 
  let url = `${dominio}/emisoras/afores/${preciostxt}clave-afore?1=1`;

  url =
    claveSiefore && claveSiefore !== "" ? `${url}&clave=${claveSiefore}` : url;
  url = tipoSiefore && tipoSiefore !== "" ? `${url}&tipo=${tipoSiefore}` : url;
  url =
    clasificacionSiefore && clasificacionSiefore !== ""
      ? `${url}&clasificacion=${clasificacionSiefore}`
      : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesAfore(response.data));
      })
      .catch(err => dispatch(setClavesAfore()));
  };
};

//================================================================

const setClavesSiefore = (data = null) => {
  return {
    type: actionTypes.FETCH_AFORESCVESIEFORE_SUCCESS,
    clavesSiefore: data
  };
};

export const initClavesSiefore = ({
  claveAfore = null,
  tipoSiefore = null,
  clasificacionSiefore = null,
  precios = false
}) => {
  let preciostxt = "";
  if (precios) {
    preciostxt ='precios/'
  } 
  let url = `${dominio}/emisoras/afores/${preciostxt}clave-siefore?1=1`;

  url =
    claveAfore && claveAfore !== "" ? `${url}&claveEmisora=${claveAfore}` : url;
  url = tipoSiefore && tipoSiefore !== "" ? `${url}&tipo=${tipoSiefore}` : url;
  url =
    clasificacionSiefore && clasificacionSiefore !== ""
      ? `${url}&clasificacion=${clasificacionSiefore}`
      : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesSiefore(response.data));
      })
      .catch(err => dispatch(setClavesSiefore()));
  };
};

//==========================================================================

const setTiposSiefore = (data = null) => {
  return {
    type: actionTypes.FETCH_AFORESTIPOSIEFORE_SUCCESS,
    tiposSiefore: data
  };
};

export const initTiposSiefore = ({
  claveAfore = null,
  claveSiefore = null,
  clasificacionSiefore = null,
  precios = false
}) => {
  let preciostxt = "";
  if (precios) {
    preciostxt ='precios/'
  } 
  let url = `${dominio}/emisoras/afores/${preciostxt}tipo-siefore?1=1`;

  url =
    claveAfore && claveAfore !== "" ? `${url}&claveEmisora=${claveAfore}` : url;
  url =
    claveSiefore && claveSiefore !== "" ? `${url}&clave=${claveSiefore}` : url;
  url =
    clasificacionSiefore && clasificacionSiefore !== ""
      ? `${url}&clasificacion=${clasificacionSiefore}`
      : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTiposSiefore(response.data));
      })
      .catch(err => dispatch(setTiposSiefore()));
  };
};

//==============================================================================

const setClasificacionesSiefore = (data = null) => {
  return {
    type: actionTypes.FETCH_AFORESCLASIFICACION_SUCCESS,
    clasificacionesSiefore: data
  };
};

export const initClasificacionesSiefore = ({
  claveAfore = null,
  claveSiefore = null,
  tipoSiefore = null,
  precios = false
}) => {
  let preciostxt = "";
  if (precios) {
    preciostxt ='precios/'
  } 
  let url = `${dominio}/emisoras/afores/${preciostxt}clasificacion-siefore?1=1`;

  url =
    claveAfore && claveAfore !== "" ? `${url}&claveEmisora=${claveAfore}` : url;
  url =
    claveSiefore && claveSiefore !== "" ? `${url}&clave=${claveSiefore}` : url;
  url = tipoSiefore && tipoSiefore !== "" ? `${url}&tipo=${tipoSiefore}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClasificacionesSiefore(response.data));
      })
      .catch(err => dispatch(setClasificacionesSiefore()));
  };
};

//=============================================================================================

const setClavesAforeInformacionCorporativa = (data = null) => {
  return {
    type: actionTypes.FETCH_AFORESCVEAFOREINFORMACIONCORPORATIVA_SUCCESS,
    clavesAfore: data
  };
};

export const initClavesAforeInformacionCorporativa = ({
  empresa = null,
  tipoDocumento = null
}) => {
  let url = `${dominio}/emisoras/afores/informacion-corporativa/clave-afore?1=1`;

  url = empresa && empresa !== "" ? `${url}&empresa=${empresa}` : url;
  url = tipoDocumento && tipoDocumento !== "" ? `${url}&tipo=${tipoDocumento}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesAforeInformacionCorporativa(response.data));
      })
      .catch(err => dispatch(setClavesAforeInformacionCorporativa()));
  };
};

//================================================================

const setClavesSieforeInformacionCorporativa = (data = null) => {
  return {
    type: actionTypes.FETCH_AFORESCVESIEFOREINFORMACIONCORPORATIVA_SUCCESS,
    clavesSiefore: data
  };
};

export const initClavesSieforeInformacionCorporativa = ({
  empresaAsociada = null,
  tipoDocumento = null
}) => {
  let url = `${dominio}/emisoras/afores/informacion-corporativa/clave-siefore?1=1`;

  url = empresaAsociada && empresaAsociada !== "" ? `${url}&empresaAsociada=${empresaAsociada}` : url;
  url = tipoDocumento && tipoDocumento !== "" ? `${url}&tipo=${tipoDocumento}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesSieforeInformacionCorporativa(response.data));
      })
      .catch(err => dispatch(setClavesSieforeInformacionCorporativa()));
  };
};

//================================================================

const setTipoDocumento = (data = null) => {
  return {
    type: actionTypes.FETCH_AFORESTIPODOCUMENTO_SUCCESS,
    tiposDocumento: data
  };
};

export const initTipoDocumentoSiefores = ({
  empresaAsociada = null,
  empresa = null
}) => {
  let url = `${dominio}/emisoras/afores/informacion-corporativa/tipo-documento?1=1`;

  url = empresaAsociada && empresaAsociada !== "" ? `${url}&empresaAsociada=${empresaAsociada}` : url;
  url = empresa && empresa !== "" ? `${url}&empresa=${empresa}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoDocumento(response.data));
      })
      .catch(err => dispatch(setTipoDocumento()));
  };
};
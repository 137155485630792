import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

const setEmailArea = emailArea => {
  return {
    type: actionTypes.FETCH_SENDEMAILAREA_SUCCESS,
    emailArea: emailArea
  };
};


const getErrorSendAnonimo = error => {
    if (error.response !== undefined) {
        return {
            type: actionTypes.FETCH_SENDEMAILANONIMO_ERROR,
            errorMail: error.response.data
          };
    } else {
        return error;
    }

};

const getErrorSendArea = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SENDEMAILAREA_ERROR,
      errorMail: null
    };
  }
  return {
    type: actionTypes.FETCH_SENDEMAILAREA_ERROR,
    errorMail: data.response.data
  };
};


export const initSendEmailArea = (params, area = null) => {
  let url = dominio + "/cms/o/contact/" + area + "/";

  return dispatch => {
    axios
      .post(url, params)
      .then(response => {
        dispatch(setEmailArea(response.data));
      })
      .catch(err => {dispatch(getErrorSendArea(err))});
  };
};

const setEmailAnonimo = emailAnonimo => {
  return {
    type: actionTypes.FETCH_SENDEMAILANONIMO_SUCCESS,
    emailAnonimo: emailAnonimo
  };
};

export const initSendEmailAnonimo = params => {
  let url = dominio + "/cms/o/contact/we-want-to-hear-you/";
  return dispatch => {
    axios
      .post(url, params)
      .then(response => {
        dispatch(setEmailAnonimo(response.data));
      })
      .catch(err => {dispatch(getErrorSendAnonimo(err))});
  };
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import classes from "./subFiltersCotizacion.css";

import Auxs from "../../../hoc/auxs/Auxs";
import Input from "../input/Input";
import Loader from "../../../components/loader/Loader";

import { getLan } from "../../../stores/utils/utilities";


const renderInput = ({
  input,
  id = null,
  label,
  classes = null,
  options = null,
  defaultValue = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel = null,
  onInputChange
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? [classes, input.type === "checkbox" ? classes.checkPeriodo : ""].join(" ") : null,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : "Seleccione una opción"
  };
  return (
    <Auxs>
      {label && input.type !== "radio" && input.type !== "checkbox" ? (
        <label className={"texto_14 montse regular"} for={input.name}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </Auxs>
  );
};

class SubFiltersCotizacion extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    loader: true,
    toggleFilters: true,
    selectedFilters: {
      valorListado: [],
    },
    emisoraDetalle: null,
    valores_listados: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.valores_listados !== prevProps.valores_listados || this.props.emisoraDetalle !== prevProps.emisoraDetalle ) {
      const selectedFilters = {
        tipoGrafica: [],
        valorListado: [],
        indicadores: []
      };
      let emisoraDetalle = this.props.emisoraDetalle;

      let valores_listados = this.props.valores_listados;

      if (valores_listados === null) {
        valores_listados = [];
      } else {
        valores_listados = valores_listados.content;
      }
      let isin = "";
      let value = "";
      let label = "";
      if (valores_listados && valores_listados.length > 0) {
        isin = `${valores_listados[0].isin}`;
        value = `${valores_listados[0].isin}_${
          valores_listados[0].claveWarrant ? valores_listados[0].claveWarrant : emisoraDetalle
        }-${valores_listados[0].serie}_${valores_listados[0].claveTipoValor}`;
        label = `${valores_listados[0].claveTipoValor} ${
          valores_listados[0].claveWarrant ? valores_listados[0].claveWarrant : emisoraDetalle
        } ${valores_listados[0].serie}`;
        selectedFilters.valorListado.push({
          label: label,
          value: value,
          isin: isin
        });
        this.props.setNombreEmision(label);
        this.setState({
          loader: false,
          valores_listados: this.props.valores_listados
        });
      }

      this.setState({
        selectedFilters: selectedFilters
      });
      this.props.setIsin(isin);
    }
  }

  onSubmitSubFiltersHandler = values => {
  };

  onInputSelectedHandler = event => {
    const value = event.target.value;
    const selectedFilters = this.state.selectedFilters;
    selectedFilters.valorListado = [];
    let labelEmisora = null;
    if (value !== "") {
      const valueValorListado = value.split("_")[0];
      const labelValorListado = value.split("_")[1];
      const claveTipoValor = value.split("_")[2];
      selectedFilters.valorListado.push({
        label: `${claveTipoValor} ${labelValorListado}`,
        value: value,
        isin: valueValorListado
      });
      labelEmisora = labelValorListado;
    }
    this.props.setNombreEmision(labelEmisora);
    this.setState({
      selectedFilters: selectedFilters
    });
    let isinSeleccionado = null;
    if (selectedFilters.valorListado.length > 0) {
      isinSeleccionado = selectedFilters.valorListado[0].isin;
    }
    this.props.setIsin(isinSeleccionado);
  };

  onPrepareOptionsValoresListdoHandler = () => {
    let valores_listados = this.props.valores_listados_min;

    let options = [];
    options =
      valores_listados.length > 0
        ? valores_listados.map(item => {
          const valueIsin = item.id.split("_")[0];
          const claveTipoValor = item.nombre.split(" ")[0];
          return {
            id: `${item.id}_${claveTipoValor}`,
            nombre: item.nombre,
            isin: valueIsin
          };
        })
        : [];

    return options;
  };

  printSubFilterHandler = () => {
    return (
      <Auxs>
        <div
          className={["row", classes.secondSectionFilterContainer, !this.state.toggleFilters ? classes.hidden : null].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    const options = this.props.valores_listados_min ? this.onPrepareOptionsValoresListdoHandler() : [];

    let initValuesPerDes = {};
    const initialValues = {
      valores_listados:
        this.state.selectedFilters.valorListado.length > 0 ? this.state.selectedFilters.valorListado[0].value : "",
      ...initValuesPerDes
    };

    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={values => this.onSubmitSubFiltersHandler(values)}
              initialValues={initialValues}
              render={({ optionsValoresListados = options, handleSubmit, form }) => (
                <form ref={this.formRef} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col col-lg-6">
                      <Field
                        name="valores_listados"
                        id="valores_listados"
                        elementType="select"
                        classes="custom-select"
                        label={getLan() === "es" ? "Valores listados" : "Listed securities"}
                        options={optionsValoresListados}
                        onInputChange={event => this.onInputSelectedHandler(event)}
                        component={renderInput}
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const filters = this.printSubFilterHandler();
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    return (
      <Auxs>
        {loader}
        <div className="row">
          <div className="col sin_padding">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  return {
    valores_listados: state.emisoras.valoresListados,
    valores_listados_min: state.emisoras.valoresListadosMin
  };
};

export default connect(mapStateToProps)(SubFiltersCotizacion);

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import FilterRequisitosMantenimiento from "../../../components/ui/filterRequisitosMantenimiento/FilterRequisitosMantenimiento";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";

class RequisitosMantenimiento extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    indexSelected: 0,
    itemsPerPage: 5,
    activePage: 1,
    resourceList: [],
    loader: true,
    periodos: null,
    capital_social: null,
    filtered: false
  };

  componentWillMount() {
    this.props.onInitIntl();
    let paramsString = queryString.parse(this.props.location.search);

    let periodos = null;
    let capitalSocial = null;
    let page = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      periodos =
        paramsString["periodos"] === "" || paramsString["periodos"] === "null" ? null : paramsString["periodos"].split(",");
      capitalSocial =
        paramsString["capitalSocial"] === "null" || paramsString["capitalSocial"] === ""
          ? null
          : paramsString["capitalSocial"].split(",");
      page = paramsString["page"] === "null" ? 1 : parseInt(paramsString["page"]);
    }

    const params = {
      size: this.state.itemsPerPage,
      page: parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      periodo: periodos,
      capitalSocial: capitalSocial
    };
    this.props.onInitRequisitos(params);
    this.setState({
      periodo: periodos,
      capital_social: capitalSocial,
      activePage: parseInt(page) > 0 ? parseInt(page) : 1
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.requisitos !== prevProps.requisitos) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let requisitos = null;

    if (nextProps.requisitos && nextProps.requisitos.content) {
      requisitos = nextProps.requisitos.content;
    }
    let resource = requisitos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.requisitos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
      this.changeHistory(this.state.periodos, this.state.capital_social, pageNumber);
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      const params = {
        size: this.state.itemsPerPage,
        page: pageNumber - 1,
        periodo: this.state.periodos ? this.state.periodos : null,
        capitalSocial: this.state.capital_social ? this.state.capital_social : null
      };

      this.props.onInitRequisitos(params);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onSetPeriodosFilter = periodos => {
    this.setState({
      periodos: periodos
    });
  };

  onSetCapitalSocialFilter = capital_social => {
    this.setState({
      capital_social: capital_social
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  setWasFiltered = value => {
    this.setState({
      filtered: value
    });
  };

  onSetParams = ({ periodo, capitalSocial, page, reset = false }) => {
    this.setState({
      periodo: periodo,
      capitalSocial: capitalSocial
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(periodo, capitalSocial, page);
    }
  };

  changeHistory(periodo = null, capitalSocial = null, page = null) {
    this.props.history.push(`?periodos=${periodo}&capitalSocial=${capitalSocial}&page=${page ? page : this.state.activePage}`);

    this.setState({
      loader: true
    });
    let params = {
      size: this.state.itemsPerPage,
      page: page > 0 ? page - 1 : 0,
      periodo: periodo,
      capitalSocial: capitalSocial
    };
    this.props.onInitRequisitos(params);
  }

  changeHistoryReset() {
    this.props.history.push(`?periodos=${null}&capitalSocial=${null}&page=1`);
    let params = {
      size: this.state.itemsPerPage,
      page: 0,
      periodo: null,
      capitalSocial: null
    };
    this.props.onInitRequisitos(params);
  }

  render() {
    let contenidoMostrar = null;
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let dataToShow = null;
    let requisitos_result = this.props.requisitos && this.props.requisitos.content ? this.props.requisitos.content : [];

    if (requisitos_result.length > 0) {
      dataToShow = <Accordion datos={requisitos_result} nombre="requisitos" icon={""} tipo={12} subtipo={6} />;
    } else {
      dataToShow = (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
    }

    const numRows = this.props.requisitos ? this.props.requisitos.totalElements : 0;

    const requisitos = (
      <React.Fragment>
        {dataToShow}
        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
    contenidoMostrar = <div className="margen_50">{requisitos}</div>;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}

          <h1 className={"texto_42 nunito regular"}>
            <FormattedHTMLMessage id="empresas.requisitosdemantenimiento.titulo1" defaultMessage=" " />
          </h1>
          <div className="margen_top_30 margin_bottom_30 texto_16 montse regular">
            <FormattedHTMLMessage id="empresas.requisitosdemantenimiento.introduccion1" defaultMessage=" " />
          </div>
          <br />
          <FilterRequisitosMantenimiento
            setPeriodosFilter={periodos => this.onSetPeriodosFilter(periodos)}
            setCapitalSocialFilter={capital_social => this.onSetCapitalSocialFilter(capital_social)}
            wasFiltered={value => this.setWasFiltered(value)}
            setPage={page => this.onSetPage(page)}
            setParams={filterParams => this.onSetParams(filterParams)}
          />
          <div className="margen_50">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitRequisitos: params => {
      dispatch(actions.filterRequisitos(params));
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(57));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    requisitos: state.requisitosMantenimiento.requisitos
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequisitosMantenimiento)
);

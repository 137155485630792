import React, { Component } from "react";
import classes from "./Toggle.css";

import Items from "./items/Items";

class Toggle extends Component {
  render() {
    let item = null;

    if (this.props.datos) {
      item = this.props.datos.map((dato, i) => {
        return (
          <div key={i} className={[classes.grid_push_box].join(" ")}>
            <Items anio={dato.titulo_encabezado} texto={dato.texto_html} />
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        <div className={[classes.grid_push_box_container].join(" ")}>
          {item}
        </div>
      </React.Fragment>
    );
  }
}

export default Toggle;

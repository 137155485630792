import React from "react";
import classes from "./Input.css";
import Select from "react-select";
import Auxs from "../../../hoc/auxs/Auxs";
import check from "../../../assets/icons/radioCheck.svg";
import unCheck from "../../../assets/icons/radioUncheck.svg";
import check_box from "../../../assets/icons/check_box.svg";
import { getLan } from "../../../stores/utils/utilities";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
      <div>
      <span className="montse bold texto_12 color_gris">{data.sublabel}</span>
      <span className="nunito bold texto_16">{data.label}</span>
      </div>
      <span style={groupBadgeStyles} className="nunito">{data.options.length}</span>
    </div>
  );

export const colourStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: "white",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 14,
    color: "#676F93 !important",
    borderColor: "#EAEDFA",
    borderRadius: 6
  }),
  singleValue: styles => ({
    ...styles,
    color: "#676F93"
  }),
  label: () => {
    return {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: 14,
      color: "#676F93 !important"
    };
  },
  noOptionsMessage: Props => {
    return {
      fontFamily: "Montserrat",
      fontSize: 14,
      color: "#676F93 !important",
      textAlign: "center"
    };
  },
  option: (styles, { isSelected }) => {
    return {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: 14,
      //   borderBottom: "1px solid #00aeef !important",
      padding: 5,
      paddingLeft: 10,
      cursor: "pointer",
      backgroundColor: isSelected ? "#0558DB" : "#FFF",
      color: isSelected ? "#FFF" : "#676F93",
      "&:hover": {
        color: "#ffffff !important",
        backgroundColor: "#0558DB !important"
      }
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      border: "1px solid #EAEDFA",
      borderRadius: 20,
      backgroundColor: "#FFF"
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#00aeef"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#00aeef",
    display: "none",
    ":hover": {
      backgroundColor: "#00aeef",
      color: "#FFF"
    }
  })
};

const Input = props => {
  let inputElement = null;

  switch (props.config.elementType) {
    case "input":
      inputElement = (
        <input
          {...props.config.input}
          maxLength={props.config.maxCharacter}
          autoComplete="off"
        />
      );
      break;
    case "date":
      inputElement = (
        <input
          {...props.config.input}
          type="date"
          disabled={props.config.isDisabled}
          key={props.config.key}
        />
      );
      break;
    case "number":
      inputElement = <input {...props.config.input} min="0" />;
      break;
    case "period":
      inputElement = <input {...props.config.input} min="0" />;
      break;
    case "select":
      if (props.config.isMultiple) {
        inputElement = (
          <select
            {...props.config.input}
            type={props.config.type}
            value={props.config.input.value}
            multiple
            size="3"
          >
            {props.config.selectLabel ? (
              <option value="">{props.config.selectLabel}</option>
            ) : null}
            {props.config.options.map(option => {
              return (
                <option
                  value={option.id}
                  key={option.id}
                  className={"montse texto_14"}
                >
                  {option.nombre ? option.nombre : option.name}
                </option>
              );
            })}
          </select>
        );
      } else if (props.config.isSearch) {
        inputElement = (
          <Select
            styles={colourStyles}
            searchable
            isClearable
            options={props.config.options}
            getOptionLabel={option =>
              props.config.campoLabel2
                ? option[props.config.campoLabel] +
                  " - " +
                  option[props.config.campoLabel2]
                : option[props.config.campoLabel]
            }
            getOptionValue={option => option}
            onInputChange={props.onInputChange}
            onChange={props.config.input.onChange}
            placeholder={props.config.selectLabel}
            value={
              props.config.options.find(
                option => option.id === props.config.input.value
              )
                ? props.config.options.find(
                    option => option.id === props.config.input.value
                  )
                : props.config.input.value ? props.config.input.value : ""
            }
          />
        );
      } else if (props.config.isSingle) {
        inputElement = (
          <Select
            styles={colourStyles}
            options={props.config.options}
            getOptionLabel={option =>
              props.config.campoLabel2
                ? option[props.config.campoLabel] +
                  " - " +
                  option[props.config.campoLabel2]
                : option[props.config.campoLabel]
            }
            getOptionValue={option => option}
            onInputChange={props.onInputChange}
            onChange={props.config.input.onChange}
            placeholder={props.config.selectLabel}
            value={props.config.input.value}
            isClearable={props.config.isClearable}
          />
        );
      } else if (props.config.isMultiSelect) {
        inputElement = (
          <Select
            styles={colourStyles}
            isMulti={true}
            options={props.config.options}
            getOptionLabel={option =>
              props.config.campoLabel2
                ? option[props.config.campoLabel] +
                  " - " +
                  option[props.config.campoLabel2]
                : option[props.config.campoLabel]
            }
            getOptionValue={option => option}
            onInputChange={props.onInputChange}
            onChange={props.config.input.onChange}
            placeholder={props.config.selectLabel}
            noOptionsMessage={() =>
              getLan() === "es" ? "No hay opciones" : "No options"
            }
            value={props.config.input.value}
            isClearable={true}
          />
        );
        if (props.config.isGroup) {
          inputElement = (
            <Select
              styles={colourStyles}
              isMulti={true}
              options={props.config.options}
              getOptionLabel={option =>
                props.config.campoLabel2
                  ? option[props.config.campoLabel] +
                    " - " +
                    option[props.config.campoLabel2]
                  : option[props.config.campoLabel]
              }
              getOptionValue={option => option}
              onInputChange={props.onInputChange}
              onChange={props.config.input.onChange}
              placeholder={props.config.selectLabel}
              formatGroupLabel={formatGroupLabel}
              noOptionsMessage={() =>
                getLan() === "es" ? "No hay opciones" : "No options"
              }
              value={props.config.input.value}
              isClearable={true}
            />
          );
        }
      } else {
        inputElement = (
          <select
            {...props.config.input}
            value={props.config.input.value}
            disabled={props.config.isDisabled}
          >
            {props.config.selectLabel ? (
              <option value="">{props.config.selectLabel}</option>
            ) : null}
            {props.config.options.map(option => {
              return (
                <option
                  value={option.id}
                  key={
                    (option.nombre ? option.nombre : option.name) + option.id
                  }
                  className={"montse texto_14"}
                >
                  {option.nombre ? option.nombre : option.name}
                </option>
              );
            })}
          </select>
        );
      }
      break;

    case "select2":
      inputElement = (
        <select {...props.config.input} value={props.config.input.value}>
          {props.config.selectLabel ? (
            <option value="">{props.config.selectLabel}</option>
          ) : null}
          {props.config.options.map(option => {
            return (
              <option
                value={[option.id, option.tipo]}
                key={option.id + option.tipo}
                className={"montse texto_14"}
              >
                {option.nombre ? option.nombre : option.name}
              </option>
            );
          })}
        </select>
      );

      break;
    case "select3":
      inputElement = (
        <select {...props.config.input} value={props.config.input.value}>
          {props.config.options.map(option => {
            return (
              <option
                value={[option.id, option.tipo]}
                key={option.id + option.tipo}
                className={"montse texto_14"}
              >
                {option.nombre ? option.nombre : option.name}
              </option>
            );
          })}
        </select>
      );

      break;
    case "checkbox":
      inputElement = (
        <Auxs>
          <input
            type="checkbox"
            {...props.config.input}
            disabled={props.config.isDisabled}
          />
          <label
            className="form-check-label texto_14 montse"
            htmlFor={props.config.label}
          >
            {props.config.label}
          </label>
        </Auxs>
      );
      break;
    case "checkbox_plain":
      inputElement = (
        <Auxs>
          <input
            type="checkbox"
            {...props.config.input}
            disabled={props.config.isDisabled}
          />
          <label
            className="texto_14 montse"
            htmlFor={props.config.label}
          >
            {props.config.label}
          </label>
        </Auxs>
      );
      break;
    case "checkbox_cuadro":
      inputElement = (
        <div className={[classes.checkCuadro].join(" ")}>
          <input
            {...props.config.input}
            type="checkbox"
            disabled={props.config.isDisabled}
          />
          <label
            className={[
              " texto_14 regular",
              classes.textoCheck,
              props.config.input.checked ? "texto_azul" : "texto_gris"
            ].join(" ")}
            htmlFor={props.config.input.id}
          >
            <img
              className={classes.imgCheck}
              src={props.config.input.checked ? check_box : unCheck}
              alt=""
            />
            {props.config.label}
          </label>
        </div>
      );
      break;
    case "radio":
      inputElement = (
        <div className={["form-check", classes.radio].join(" ")}>
          <input {...props.config.input} />
          <label
            className={[
              " texto_14 regular color_gris montse",
              classes.textoCheck
            ].join(" ")}
            htmlFor={props.config.input.id}
          >
            {props.config.label}
          </label>
        </div>
      );
      break;
    case "radio2":
      inputElement = (
        <div className={["form-check", classes.radio2].join(" ")}>
          <input {...props.config.input} />
          <label
            className={[
              " texto_14 regular color_gris montse",
              classes.textoCheck
            ].join(" ")}
            htmlFor={props.config.input.id}
          >
            {" "}
            {props.config.label}
          </label>
        </div>
      );
      break;
    case "radio_cuadro":
      inputElement = (
        <div className={["form-check", classes.radio].join(" ")}>
          <input {...props.config.input} />

          <label
            className={[
              "montse texto_14 regular",
              classes.textoCheck,
              props.config.input.checked ? "texto_azul" : "texto_gris"
            ].join(" ")}
            htmlFor={props.config.input.id}
          >
            <img
              className={classes.imgCheck}
              src={props.config.input.checked ? check : unCheck}
              alt=""
            />
            {props.config.label}
          </label>
        </div>
      );
      break;
    case "textarea":
      inputElement = <textarea {...props.config.input} />;
      break;
    default:
      inputElement = <input {...props.config.input} />;
  }
  return inputElement;
};

export default Input;

import React, { Component } from "react";
import classes from "./Fotos.css";

import Pagination from "../pagination/Pagination";
import { getUrlDocument } from "../../../stores/utils/utilities";

// imagenes={this.props.imagenes_api} =  datos
// icono={"fas fa-plus"} = icono que se mostrara en el hover
// tipo={2} = 1- tipo imagebes o 2- tipo videos
// seleccionImagen = metodo que manda a llamar
// tienePaginado = true o false
// tipoPaginado = 1-bolitas 2-normal
class Fotos extends Component {
  state = {
    itemsPerPage: 16,
    selectedVideo: null,
    activePage: 1,
    lista_ver: []
  };

  componentWillReceiveProps(nextProps) {
    let videos = nextProps.imagenes;
    if (videos) {
      let lista_ver = [];
      let index_final = this.state.itemsPerPage;
      let index_inicial = 0;
      lista_ver = videos.slice(index_inicial, index_final);
      this.setState({ lista_ver: lista_ver, selectedVideo: videos[0].url });
    }
  } 

  componentDidMount() {
    let videos = this.props.imagenes;
    if (videos) {
      let lista_ver = [];
      let index_final = this.state.itemsPerPage;
      let index_inicial = 0;
      lista_ver = videos.slice(index_inicial, index_final);
      this.setState({ lista_ver: lista_ver, selectedVideo: videos[0].url });
    }
  }

  handlePageChange(pageNumber) {
    let lista_ver = [];
    let index_final = pageNumber * this.state.itemsPerPage;
    let index_inicial = index_final - this.state.itemsPerPage;
    lista_ver = this.props.imagenes.slice(index_inicial, index_final);
    this.setState({
      activePage: pageNumber,
      lista_ver: lista_ver
    });
  }

  render() {
    let numRows = 0;

    let imagenes = [];
    let videos = [];
    let paginas = [];
    let clase_hover = [];
    let tieneHover =
      this.props.tieneHover !== undefined ? this.props.tieneHover : true;

    if (!tieneHover) {
      clase_hover.push(classes.noHover);
    }
    if (this.props.imagenes) {
      numRows = this.props.imagenes.length;

      if (this.props.tipo === 1) {
        imagenes = this.props.imagenes.map((img, i) => {
          const ruta = getUrlDocument(img.url);
        const style = {
          backgroundImage: `url(${ruta})`
        };
          return (
            <div className={[classes.imagenes, classes.margenImagenes].join(" ")} key={"image"+i}>
              <div  className={[classes.image].join(" ")}  style={style}></div>
              <div
                className={[classes.middle, clase_hover].join(" ")}
                onClick={e => this.props.seleccionImagen(e, img)}
              >
                <i className={[this.props.icono, classes.icono].join(" ")} />
              </div>
            </div>
          );
        });
      } else if (this.props.tipo === 3) {
        imagenes = this.props.imagenes.map((img, i) => {
          let ruta = "/";
          if (img.url !== "") {
            ruta =getUrlDocument(img.url);
          }

          return (
            <div className={classes.imagenes} key={"image"+i}>
              <a
                href={ruta}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <img
                  src={getUrlDocument(img.image)}
                  alt={ruta}
                  className={[
                    classes.image,
                    classes.image_gris,
                    classes.imagenes_pequeñas
                  ].join(" ")}
                />
                <div
                  className={[classes.middle, clase_hover].join(" ")}
                  onClick={e => this.props.seleccionImagen(e, img)}
                >
                  <i className={[this.props.icono, classes.icono].join(" ")} />
                </div>
              </a>
            </div>
          );
        });
      }else if (this.props.tipo === 4) {
        //tipo imagenes desde endpoint y videos

        imagenes = this.props.imagenes.map((img, i) => {
          let ruta = null;
          if (img.url) {
          ruta= getUrlDocument(img.url);
          }
          const style = {
            backgroundImage: `url(${ruta})`
          };
          return (
            <div className={[classes.imagenes, classes.margenImagenes].join(" ")} key={"image"+i}>
              <div  className={[classes.image].join(" ")}  style={style}></div>

             
              <div
                className={[classes.middle, clase_hover].join(" ")}
                onClick={e => this.props.seleccionImagen(e, img, false)}
              >
                <i className={[this.props.icono, classes.icono].join(" ")} />
              </div>
            </div>
          );
        });
        if (this.props.videos && this.props.videos.items && this.props.videos.items.length > 0) {
          videos = this.props.videos.items.map((img, i) => {
            if (img.snippet.thumbnails.default === undefined) {
              return null
            }
            let ruta = null;
            ruta = img.snippet.thumbnails.default.url
  
            return (
              <div className={classes.imagenes}  key={"video"+i}>
                <img
                  src={ruta}
                  alt={"video" + i}
                  className={[classes.image].join(" ")}
                />
                <div
                  className={[classes.middle, clase_hover].join(" ")}
                  onClick={e => this.props.seleccionImagen(e, img, true)}
                >
                  <i className={[this.props.icono, classes.icono].join(" ")} />
                </div>
              </div>
            );
          });
        }
      }else if (this.props.tipo === 5) {
        imagenes = this.props.imagenes.map((img, i) => {
          let ruta = "/";
          if (img.url_reenvio !== "") {
            ruta = img.url_reenvio;
          }

          return (
            <div className={classes.imagenes_2} key={"image"+i}>
              <a
                href={ruta}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <img
                  src={img.url}
                  alt={ruta}
                  className={[
                    classes.image,
                    classes.image_gris,
                    classes.imagenes_pequeñas
                  ].join(" ")}
                />
                <div
                  className={[classes.middle, clase_hover].join(" ")}
                  onClick={e => this.props.seleccionImagen(e, img)}
                >
                  <i className={[this.props.icono, classes.icono].join(" ")} />
                </div>
              </a>
            </div>
          );
        });
      } else {
        imagenes = this.state.lista_ver.map((img, i) => {
          if (img.snippet.thumbnails && img.snippet.thumbnails.default ) {
            let videoId = null;
            if (img.contentDetails) {
              videoId = img.contentDetails.videoId;
            } else if (img.snippet.resourceId) {
              videoId = img.snippet.resourceId.videoId;
            } else {
              videoId = img.id.videoId;
            }
            
            return (
              <div className={classes.videos} key={"image"+i}>
                <img
                  src={img.snippet.thumbnails.default.url}
                  alt={"imagen" + i}
                  className={[classes.image, classes.video].join(" ")}
                />
                <div className={[classes.titulo_video, "montse regular"].join(" ")}>{img.snippet.title}</div>
                <a
                  href={"https://www.youtube.com/watch?v=" + videoId}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={[
                      classes.middle,
                      classes.middle_video,
                      clase_hover
                    ].join(" ")}
                  >
                    <i
                      className={[this.props.icono, classes.icono].join(" ")}
                    />
                  </div>
                </a>
              </div>
            );
          } else{
            return null;
          }
        });

        paginas = (
          <Pagination
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        );
      }
    }

    return (
      <React.Fragment>
        <div className={["row", classes.relativo].join(" ")}>{imagenes}{videos}</div>
        <div className={["row", classes.relativo].join(" ")}>{paginas}</div>
      </React.Fragment>
    );
  }
}

export default Fotos;

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import classes from "./AvisosSuspension.css";
import Tabs from "../../../components/ui/tabs/Tabs";
import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";

import FilterAvisosSuspencion from "../../../components/ui/filterAvisosSuspencion/FilterAvisosSuspencion";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import Rss from "../../rss/Rss";
const dominio = `${window.location.protocol}//${window.location.host}`;

const tabsEmisoras = [
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Todos" : "All"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Avisos de suspensión" : "Trading halt announcement"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Avisos de levantamiento" : "Trade resumption announcement"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Avisos de mantenimiento" : "Maintenance Announcement"
  }
];

class AvisosSuspension extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: false,
    idTipoAvisoVigilancia: null,
    messages: null,
    claveCotizacion: null,
    fechaInicio: null,
    fechaFin: null,
    wasFiltered: false,
    lan: getLocal(),
    urlForRss: `${dominio}/avisos/vigilancia/rss`,
    showRssModal: false,
    rssTitle: null
  };

  componentDidMount() {
    this.props.onInitIntl(3);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let avisosSuspension = null;

    this.setState({
      loader: false
    });
    if (nextProps.avisosSuspension && nextProps.avisosSuspension.content) {
      avisosSuspension = nextProps.avisosSuspension.content;
    }
    let resource = avisosSuspension;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (
    resource = null,
    pageNumber = null,
    seleccionado = null
  ) => {
    let data = resource ? resource : this.props.avisosSuspension;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.props.history.push("#avisos");
      this.setState({
        loader: true
      });
      let params = {
        size: this.state.itemsPerPage,
        page: pageNumber - 1,
        claveCotizacion: this.state.claveCotizacion,
        fechaInicio: this.state.fechaInicio,
        fechaFin: this.state.fechaFin,
        idTipoAvisoVigilancia: this.state.idTipoAvisoVigilancia
      };
      this.props.onInitFilterAvisosSuspension(params);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    this.onChangeItemSelected();
    let params = {
      size: this.state.itemsPerPage,
      page: 0,
      claveCotizacion: null,
      fechaInicio: null,
      fechaFin: null,
      idTipoAvisoVigilancia: this.state.idTipoAvisoVigilancia
    };
    this.props.onInitFilterAvisosSuspension(params);
  }

  onChangeItemSelected = (index = 0) => {
    let urlForRss = `${dominio}/avisos/vigilancia/rss`;
    let activePage = null;
    let idTipoAvisoVigilancia = null;
    let rssTitle = null;
    if (this.state.indexSelected === index) {
      activePage = this.state.activePage;
    } else {
      activePage = 1;
    }
    if (index === 0) {
      idTipoAvisoVigilancia = null;
      rssTitle = getLan() === "es" ? "Todos" : "All";
    } else if (index === 1) {
      idTipoAvisoVigilancia = 327; //Suspensión
      urlForRss = urlForRss + `?idTipoAvisoVigilancia=${idTipoAvisoVigilancia}`;
      rssTitle = getLan() === "es" ? "Avisos de suspensión" : "Trading halt announcement";
    } else if (index === 2) {
      idTipoAvisoVigilancia = 328; //Levantamiento
      urlForRss = urlForRss + `?idTipoAvisoVigilancia=${idTipoAvisoVigilancia}`;
      rssTitle = getLan() === "es" ? "Avisos de levantamiento" : "Trade resumption announcement";
    } else if (index === 3) {
      idTipoAvisoVigilancia = 329; //Mantenimiento
      urlForRss = urlForRss + `?idTipoAvisoVigilancia=${idTipoAvisoVigilancia}`;
      rssTitle = getLan() === "es" ? "Avisos de mantenimiento" : "Maintenance Announcement";
    }

    let params = {
      size: this.state.itemsPerPage,
      page: 0,
      claveCotizacion: null,
      fechaInicio: null,
      fechaFin: null,
      idTipoAvisoVigilancia: idTipoAvisoVigilancia,
    };
    this.props.onInitFilterAvisosSuspension(params);

    this.setState({
      indexSelected: index,
      activePage: activePage,
      loader: true,
      idTipoAvisoVigilancia: idTipoAvisoVigilancia,
      urlForRss: urlForRss,
      rssTitle: rssTitle
    });
  };

  onSetClaveCotizacion = clave_cotizacion => {
    this.setState({
      claveCotizacion: clave_cotizacion
    });
  };

  onSetFechaIncio = fecha_inicio => {
    this.setState({
      fechaInicio: fecha_inicio
    });
  };

  onSetFechaFin = fecha_fin => {
    this.setState({
      fechaFin: fecha_fin
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    let contenidoMostrar = null;
    let avisosSuspension = null;
    if (this.props.avisosSuspension && this.props.avisosSuspension.content) {
      avisosSuspension = this.props.avisosSuspension.content;
      const numRows = this.props.avisosSuspension
        ? this.props.avisosSuspension.totalElements
        : 0;

      contenidoMostrar = (
        <React.Fragment>
          {loader}

          <Accordion
            datos={avisosSuspension}
            nombre="avisosSuspension"
            icon={""}
            tipo={12}
            subtipo={8}
          />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );
    } else if (
      this.state.wasFiltered
    ) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }

    const tabMostrar = (
      <div className="row margen_top_30">
        <div
          className={[
            "texto_16 nunito semi_bold",
            classes.tabsContainer,
            classes.myCol
          ].join(" ")}
        >
          {getLan() === "es" ? "Mostrar:" : "View"}
          
        </div>
        <div className={["col", classes.myCol].join(" ")}>
          <Tabs
            data={tabsEmisoras}
            tipo={1}
            clicked={i => this.onChangeItemSelected(i)}
          />
        </div>
      </div>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={["nunito", "texto_42"].join(" ")}>
            <FormattedMessage
              id="avisosyreportes.vigilanciademercados.avisosdesuspension.titulo"
              defaultMessage=" "
            />
          </h1>
          <FilterAvisosSuspencion
            idTipoAvisoVigilancia={this.state.idTipoAvisoVigilancia}
            setClaveCotizacion={clave_cotizacion =>
              this.onSetClaveCotizacion(clave_cotizacion)
            }
            setFechaInicio={fecha_inicio => this.onSetFechaIncio(fecha_inicio)}
            setFechaFin={fecha_fin => this.onSetFechaFin(fecha_fin)}
            setPage={page => this.onSetPage(page)}
            setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
          />
          <button
            className={["btn btn-link margen_top_30", classes.btn_link].join(" ")}
            type="button"
            onClick={this.onShowRssModalHandler}
          >
            <div className="texto_16 montse regular texto_naranja">
              {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
            <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </button>

          <Rss
            showRssModal={this.state.showRssModal}
            modalTitle={this.state.rssTitle}
            urlRss={this.state.urlForRss}
            rssType={7}
            showClassModel1
          />
          <div id="avisos">
            {tabMostrar}
            <br />
            <div className="margen_50 margin_bottom_200">{contenidoMostrar}</div>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitFilterAvisosSuspension: params => {
      dispatch(actions.filterAvisos(params));
    },
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    }
  };
};

const mapStateToProps = state => {
  return {
    avisosSuspension: state.avisosReportes.avisos,
    diccionario: state.intl.diccionario
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AvisosSuspension);

import React, { Component } from "react";
import classes from "./RequisitosListado.css";

import requisitosJson from "../../../../json/empresas/requisitos.json";
import Accordion from "../../../../components/ui/accordion/Accordion";
import Boton from "../../../../components/ui/boton/Boton";
import imagenAcciones from "../../../../assets/icons/acciones.svg";
import imagenDeuda from "../../../../assets/icons/deuda.svg";
import imagenFormato from "../../../../assets/icons/tarjeta.svg";
import imagenFibras from "../../../../assets/icons/fibras.svg";
import imagenSic from "../../../../assets/icons/sic.svg";

import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
class RequisitosListado extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <div className="row ">
            <div className="col-12 sin_padding_L">
              <div className={[""].join(" ")}>
                <h2 className="nunito texto_42 regular">
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.requisitosdelistado.titulo1"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <img src={imagenAcciones} alt="acciones" className={classes.icono} />
            <div className="col">
              <span className={["nunito texto_30 regular", classes.titulo].join(" ")}>
                <FormattedHTMLMessage
                  id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo1"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className="">
            <Accordion
              datos={this.props.sab ? this.props.sab : []}
              nombre={"sab"}
              icon={""}
              imagen_icono={""}
              tipo={6}
              titulo_encabezado_estatico={
                this.state.messages ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.sab"] : ""
              }
              sinIcono={true}
              className={classes.accordion}
            />
          </div>

          <div className="">
            <Accordion
              datos={this.props.sapib ? this.props.sapib : []}
              nombre={"SAPIB"}
              icon={""}
              imagen_icono={""}
              tipo={6}
              titulo_encabezado_estatico={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.sapib"]
                  : ""
              }
              sinIcono={true}
              className={classes.accordion}
            />
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={["texto_30", "nunito", "regular"].join(" ")}>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo2"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>

            <Boton
              ruta={"/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=1&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=null&canceladas=false&biva=false&subTab=0&page=1"}
              tipo={2}
              texto={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.btn1"]
                  : ""
              }
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>

          <div className="row margen_top_80">
            <img src={imagenDeuda} alt="acciones" className={classes.icono} />
            <div className="col">
              <span className={["nunito texto_30 regular", classes.titulo].join(" ")}>
                <FormattedHTMLMessage
                  id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo3"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className=" margen_top_50">
            <Accordion
              datos={this.props.deuda ? this.props.deuda : []}
              nombre={"deuda1"}
              icon={""}
              imagen_icono={""}
              tipo={6}
              sinIcono={true}
              titulo_encabezado_estatico={" "}
              className={classes.accordion}
            />
          </div>

          <div className="row margen_top_30">
            <p className={"montse texto_16 regular"}>
              <FormattedHTMLMessage
                id="empresas.listadodevalores.comolistarse.requisitosdelistado.introduccion1"
                defaultMessage=" "
              />
              {/* 
            *Para los valores que son adquiridos por inversionistas calificados,
            se debe contar con dos dictámenes de diferentes calificadoras. Los
            valores estructurados están exentos de este requisito. */}
            </p>
            {/* <p className={"montse texto_16 regular"}>
            Tratándose de certificados bursátiles fiduciarios, se deberá
            entregar la información del fideicomiso y de los órganos
            representativos de éste; así como, en su caso, la información del
            fideicomitente y/o administrador de los activos.
          </p> */}
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo4"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>

            <Boton
              ruta={"/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=6&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=null&canceladas=null&biva=null&subTab=0&page=1"}
              tipo={2}
              texto={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.btn1"]
                  : ""
              }
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>

          <div className="row margen_top_80">
            <img src={imagenFibras} alt="acciones" className={classes.icono} />
            <div className="col">
              <span className={["nunito texto_30 regular", classes.titulo].join(" ")}>
                <FormattedHTMLMessage
                  id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo5"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className=" margen_top_50">
            <Accordion
              datos={this.props.fibra ? this.props.fibra : []}
              nombre={"fibras"}
              icon={""}
              imagen_icono={""}
              tipo={6}
              titulo_encabezado_estatico={" "}
              sinIcono={true}
              className={classes.accordion}
            />
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo6"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>

            <Boton
              ruta={"/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=2&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=null&canceladas=false&biva=false&subTab=0&page=1"}
              tipo={2}
              texto={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.btn1"]
                  : ""
              }
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>

          <div className="row margen_top_80">
            <img src={imagenSic} alt="acciones" className={classes.icono} />
            <div className="col">
              <span className={["nunito texto_30 regular", classes.titulo].join(" ")}>
                <FormattedHTMLMessage
                  id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo8"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className=" margen_top_50">
            <Accordion
              datos={this.props.instrumentos ? this.props.instrumentos : []}
              nombre={"instrumentos"}
              icon={""}
              imagen_icono={""}
              tipo={6}
              titulo_encabezado_estatico={" "}
              sinIcono={true}
              className={classes.accordion}
            />
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo9"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>

            <Boton
              ruta={"/empresas/emisoras_inscritas/sic?tipoValor=null&mercadoPrincipal=null&tab=0&subTab=null&clave=null&emisora=null&page=1&tipoDerecho=null&fechaInicio=null&fechaFin=null"}
              tipo={2}
              texto={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.btn1"]
                  : ""
              }
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>

          {/* DEPOSITO */}
          <div className="row margen_top_80">
            {/* <img src={imagenSic} alt="acciones" className={classes.icono} /> */}
            <i className={["fa fa-lock fa-2x ", classes.icono].join("")}></i>
            <div className="col">
              <span className={["nunito texto_30 regular", classes.titulo].join(" ")}>
                <FormattedHTMLMessage id="empresas.listadodevalores.cambiodelistado.titulo2" defaultMessage=" " />
              </span>
            </div>
          </div>
          <div className={"margen_top_30"}>
            <span className={"montse texto_16 regular"}>
              <FormattedHTMLMessage id="empresas.listadodevalores.cambiodelistado.introdeposito" defaultMessage=" " />
            </span>
          </div>

          <div className={["col-12 col-sm-5", "sin_padding", "texto_16 montse regular margen_top_30"].join(" ")}>
            <a href={"https://cloudfront.indeval.com.mx/docs/guias-operativas/es/guia_GuiaRapidaWebSec_es.pdf"} target="_blank" className="row" rel="noopener noreferrer">
              <div className={[classes.titulo, "texto_16", "montse color_azul regular",].join(" ")} >
                <span className={["texto_16 montse color_azul regular ",].join(" ")} >
                  {getLan() === "es" ? "Guía rápida WebSec" : "WebSec quick guide"}
                </span>
                <i className={["fas fa-arrow-alt-circle-down fa-lg ", "Boton__icono_derecho__25mg6",].join(" ")} />
              </div>
            </a>
          </div>

          <div className={"margen_top_30"}>
            <span className={"montse texto_16 regular"}>
              <FormattedHTMLMessage id="empresas.listadodevalores.cambiodelistado.footerdeposito" defaultMessage=" " />
            </span>
          </div>

          {/*  FIN DEPOSITO*/}
          <div className="row margen_top_80">
            <img src={imagenFormato} alt="acciones" className={classes.icono} />
            <div className="col">
              <span className={["nunito texto_30 regular", classes.titulo].join(" ")}>
                <FormattedHTMLMessage
                  id="empresas.listadodevalores.comolistarse.requisitosdelistado.subtitulo7"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className=" margen_top_50">
            <Accordion
              datos={this.props.fsCap ? this.props.fsCap : []}
              nombre={"Capitales"}
              icon={""}
              imagen_icono={""}
              etiquetaBoton={getLan() === "es" ? "Descargar documento" : "Download document"}
              tipo={6}
              subtipo={2}
              titulo_encabezado_estatico={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.Capitales"]
                  : ""
              }
              sinIcono={true}
              className={classes.accordion}
            />
          </div>

          <div className=" margen_top_50">
            <Accordion
              datos={this.props.fsDeuda ? this.props.fsDeuda : []}
              nombre={"Deuda"}
              icon={""}
              imagen_icono={""}
              tipo={6}
              subtipo={2}
              etiquetaBoton={getLan() === "es" ? "Descargar documento" : "Download document"}
              sinIcono={true}
              titulo_encabezado_estatico={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.Deuda"]
                  : ""
              }
              className={classes.accordion}
            />
          </div>
          <div className=" margen_top_50 margin_bottom_300">
            <Accordion
              datos={this.props.fsSic ? this.props.fsSic : []}
              nombre={"SIC"}
              icon={""}
              imagen_icono={""}
              tipo={6}
              subtipo={2}
              titulo_encabezado_estatico={
                this.state.messages
                  ? this.state.messages["empresas.listadodevalores.comolistarse.requisitosdelistado.sicbiva"]
                  : ""
              }
              sinIcono={true}
              className={classes.accordion}
              etiquetaBoton={getLan() === "es" ? "Descargar documento" : "Download document"}
            />
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    fsCap: state.documentos.enlacesListadoFsCap,
    fsDeuda: state.documentos.enlacesListadoFsDeuda,
    fsSic: state.documentos.enlacesListadoFsSic,
    sab: state.toggleBox.toggleBoxListadoSab,
    sapib: state.toggleBox.toggleBoxListadoSapib,
    deuda: state.toggleBox.toggleBoxListadoDeuda,
    fibra: state.toggleBox.toggleBoxListadoFibra,
    instrumentos: state.toggleBox.toggleBoxListadoInstrumentos,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(64));
      dispatch(action.getEnlacesListadoFsCap());
      dispatch(action.getEnlacesListadoFsDeuda());
      dispatch(action.getEnlacesListadoFsSic());
      dispatch(action.getToggleBoxListadoSab());
      dispatch(action.getToggleBoxListadoSapib());
      dispatch(action.getToggleBoxListadoDeuda());
      dispatch(action.getToggleBoxListadoFibra());
      dispatch(action.getToggleBoxListadoInstrumentos());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequisitosListado);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";
import classes from "./Sustentable.css";
import NotFound from "../../components/notFound/NotFound";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";
import { getLan } from "../../stores/utils/utilities";
import DocumentMeta from "react-document-meta";

// paginas
import Emisiones from "./emisionesAsg/Emisiones";
import Guia from "./guia/Guia";
import Trazabilidad from "./trazabilidad/Trazabilidad";
import Estructura from "./estructura/Estructura";
import Materialidad from "./asg/Materialidad";
import BibliotecaDigital from "./bibliotecaDigital/BibliotecaDigital";
import Bibliografia from "./bibliotecaDigital/bibliografia/Bibliografia";
import Indices from "./indices/indice/Indice";
import Glosario from "./glosario/Glosario";
import Taxonomias from "./taxonomia/Taxonomias";
import Siglas from "./siglas/Siglas";
import Introduccion from "./introduccion/Introduccion";
import Biva360 from "./biva360/Biva360";
import Aliados from "./aliadosSoluciones/Aliados";
import Soluciones from "./aliadosSoluciones/Soluciones";
import EsgScan from "./esg/EsgScan";
import EsgExperience from "./esg/EsgExperience";
import Educacion from "./educacion/Educacion";
import Capitulo1 from "./capitulo1/Capitulo1";
import Acuerdos from "./acuerdosFirmas/AcuerdosFirmas";
import EmisionesASG from "./emisionesAsg/EmisionesASG";
import ContextoGeneral from "./contextoGeneral/ContextoGeneral";
import Amafore from "./amafore/Amafore";
import ReportesSustentabilidad from "./reportesSustentabilidad/ReportesSustentabilidad";
import Certificaciones from "./certificacionesOpiniones/CertificacionesOpiniones";
import ProspectosColocacion from "../empresas/tramitesListado/prospectosColocacion/ProspectosColocacion";
import DocumentosDefinitivos from "../empresas/tramitesListado/documentosDefinitivos/DocumentosDefinitivos";
import AvisosOfertaPublica from "../empresas/tramitesListado/avisosOfertaPublica/AvisosOfertaPublica";
import SeguimientoSolicitudes from "../empresas/tramitesListado/seguimientoSolicitudes/SeguimientoSolicitudes";
import CuestionarioHomologado from "./cuestionarios/CuestionarioHomologado";
import CuestionarioGenero from "./cuestionarios/CuestionarioGenero";
import SicEtfASG from "./asg/sicEtf/SicEtfASG";
import AlternativosASG from "./asg/alternativos/AlternativosASG";

const metaEs = {
  title: "Portal BIVA - BIVA Sustentable",
  description:
    "Estadisticas, operación último día, operación último mes, Operación bloques, operación cruces, historico, market data, productos BIVA, cuotas, conectividad con proveedores, cotización de emisoras, suspensiones, índices, información general, metodología",
  meta: {
    name: {
      keywords:
        "asg, esg, emisiones asg, prospectos, colocación, avisos, ofertas, ofertas públicas",
    },
  },
};

const metaEn = {
  title: "Portal BIVA - BIVA ESG",
  description:
    "Statistics, last day, last month, blocks trading, cross trading, historical, market data, products, fees, connectivity with vendors, issuer quotations, trading halt, indexes, general information, methodology",
  meta: {
    name: {
      keywords:
        "asg, esg, asg issuances",
    },
  },
};
const PAGE_CONTEXT = "/biva-sostenible";
class Informacion extends Component {
  state = {
    id_menu_activo: null,
    menu: null,
    isIntroduction: false,
  };

  componentDidMount() {
    this.initData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.initData();
    }
  }

  initData() {
    const actualPage = this.props.location.pathname;
    let isIntroduction = false;
    if (
      actualPage === PAGE_CONTEXT + "/introduccion" ||
      actualPage === "/bivasg" ||
      actualPage === "/biva-sostenible/guia-de-sostenibilidad" ||
      actualPage === "/biva-sostenible/360/esg-scan" ||
      actualPage === "/biva-sostenible/360/esg-experience" ||
      actualPage === "/biva-sostenible/iniciativa-biva/acuerdos-y-firmas" ||
      actualPage === "/biva-sostenible/reporteo" ||
      actualPage === "/biva-sostenible/cuestionario-de-genero" ||
      actualPage === "/biva-sostenible/cuestionario-homologado" ||
      actualPage === "/biva-sostenible/iniciativa-biva/indices"
    ) {
      isIntroduction = true;
    }
    this.setState({
      isIntroduction: isIntroduction,
      id_menu_activo: "sustentable",
    });

    this.props.onInitGenericos(this.getParams(actualPage));
  }

  getParams(actualPage) {
    return {
      showButtonCarousel: false,
      showCarousel: false,
      idPage: "sustentable",
      actualPageName: actualPage,
    };
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu,
      });
    } else {
      this.setState({
        id_menu_activo: menu,
      });
    }
  };

  render() {
    const routes = (
      <Switch>
        <Route path={"/bivasg"} exact component={Introduccion} />
        <Route
          path={"/biva-sostenible/introduccion"}
          exact
          component={Introduccion}
        />
        <Route
          path="/biva-sostenible/guia-de-sostenibilidad"
          exact
          component={Guia}
        />
        <Route
          path="/biva-sostenible/despliegue-y-cultura-organizacional"
          exact
          component={Estructura}
        />
        <Route
          path="/biva-sostenible/materialidad-y-planeacion-estrategica"
          exact
          component={Materialidad}
        />
        <Route
          path="/biva-sostenible/trazabilidad-transparencia-y-reporteo"
          exact
          component={Trazabilidad}
        />
        <Route
          path="/biva-sostenible/emisiones-asg"
          exact
          component={Emisiones}
        />
        <Route
          path="/biva-sostenible/emisiones-asg/emisiones"
          exact
          component={EmisionesASG}
        />
        <Route
          exact
          path="/biva-sostenible/prospectos/"
          component={ProspectosColocacion}
        />
        <Route
          exact
          path="/biva-sostenible/documentos-definitivos/"
          component={DocumentosDefinitivos}
        />
        <Route
          exact
          path="/biva-sostenible/avisos-oferta-publica/"
          component={AvisosOfertaPublica}
        />
        <Route
          exact
          path="/biva-sostenible/seguimiento-a-solicitudes/"
          component={SeguimientoSolicitudes}
        />
        <Route
          path="/biva-sostenible/emisiones-asg/sic-etf/"
          exact
          component={SicEtfASG}
        />
        <Route
          path="/biva-sostenible/emisiones-asg/alternativos/"
          exact
          component={AlternativosASG}
        />
        <Route
          path="/biva-sostenible/biblioteca-digital"
          exact
          component={BibliotecaDigital}
        />
        <Route
          path="/biva-sostenible/biblioteca-digital/bibliografia"
          exact
          component={Bibliografia}
        />
        <Route
          path="/biva-sostenible/iniciativa-biva/indices"
          exact
          component={Indices}
        />
        <Route
          path="/biva-sostenible/iniciativa-biva/acuerdos-y-firmas"
          exact
          component={Acuerdos}
        />
        <Route
          path="/biva-sostenible/biblioteca-digital/glosario"
          exact
          component={Glosario}
        />
        <Route
          path="/biva-sostenible/biblioteca-digital/taxonomias-assesments-y-frameworks"
          exact
          component={Taxonomias}
        />
        <Route
          path="/biva-sostenible/biblioteca-digital/siglas-y-acronimos"
          exact
          component={Siglas}
        />
        <Route path={"/biva-sostenible/360"} exact component={Biva360} />
        <Route
          path={"/biva-sostenible/360/aliados"}
          exact
          component={Aliados}
        />
        <Route
          path={"/biva-sostenible/360/soluciones"}
          exact
          component={Soluciones}
        />
        <Route
          path={"/biva-sostenible/360/esg-scan"}
          exact
          component={EsgScan}
        />
        <Route
          path={"/biva-sostenible/360/esg-experience"}
          exact
          component={EsgExperience}
        />
        <Route path={"/biva-sostenible/reporteo"} exact component={EsgExperience} />
        <Route path={"/biva-sostenible/contexto-general"} exact component={ContextoGeneral} />
        <Route
          path={"/biva-sostenible/capitulo-1"}
          exact
          component={Capitulo1}
        />
        <Route
          path={"/biva-sostenible/educacion-profesional-sg"}
          exact
          component={Educacion}
        />
        <Route
          path={"/biva-sostenible/cuestionario-amafore"}
          exact
          component={Amafore}
        />
        <Route
          path={"/biva-sostenible/reportes-de-sustentabilidad"}
          exact
          component={ReportesSustentabilidad}
        />
        <Route
          path={"/biva-sostenible/certificaciones-y-opiniones"}
          exact
          component={Certificaciones}
        />
        <Route
          path={"/biva-sostenible/cuestionario-de-genero"}
          exact
          component={CuestionarioGenero}
        />
        <Route
          path={"/biva-sostenible/cuestionario-homologado"}
          exact
          component={CuestionarioHomologado}
        />
        <Route component={NotFound} />
      </Switch>
    );

    let page = null;
    const pageBody = (
      <div className={"row"}>
        <div className={["d-none "].join(" ")}>
          <Sidebar
            classIcon={["fas fa-gavel "].join(" ")}
            setMenu={(menu, tipo) => this.onSetMenu(menu, tipo)}
          />
        </div>
        <div
          className={[
            this.state.isIntroduction
              ? "sin_padding"
              : "margen_top_30 padding_bottom_200",
            "col-sm-12 col-md-12 col-lg-12",
          ].join(" ")}
        >
          <div
            className={[
              "row",
              this.state.isIntroduction
                ? classes.breadcrumb
                : "margin_bottom_30",
            ].join(" ")}
          >
            <div className={"col-12 sin_padding_L"}>
              <div className={classes.breadcumb}>
                <p>
                  <Breadcrum
                    idPage={this.state.id_menu_activo}
                    menu={this.state.menu}
                  />
                </p>
              </div>
            </div>
          </div>
          {routes}
        </div>
      </div>
    );

    if (this.state.isIntroduction) {
      page = pageBody;
    } else {
      page = <div className={"container"}>{pageBody}</div>;
    }

    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
        {page}
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitGenericos: (params) => {
      dispatch(action.initGenericos(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(Informacion);

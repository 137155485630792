import React, { Component } from "react";
import classes from "./Videos.css";

class Videos extends Component {
  state = {
    videoSeleccionado: null,
    descripcion_video: "",
    titulo_video: "",
    videoId: ""
  };

  changeCurrentVideo(event, video) {
    event.preventDefault();

    this.setState({
      videoSeleccionado: video,
      descripcion_video: video.snippet.description,
      titulo_video: video.snippet.title,
      videoId: video.snippet.resourceId.videoId
    });
  }
  componentDidMount() {
    if (this.props.videos) {
      let i = 0;
      while (this.props.videos[i].snippet.thumbnails === undefined) {
        i ++;
      }
      let video = this.props.videos[i];

      this.setState({
        videoSeleccionado: video,
        descripcion_video: video.snippet.description,
        titulo_video: video.snippet.title,
        videoId: video.snippet.resourceId.videoId
      });
    }
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.videos ) {
      let i = 0;
      while (nextProps.videos[i].snippet.thumbnails === undefined) {
        i ++;
      }
      let video = nextProps.videos[i];

      this.setState({
        videoSeleccionado: video,
        descripcion_video: video.snippet.description,
        titulo_video: video.snippet.title,
        videoId: video.snippet.resourceId.videoId
      });
    }
  }
  render() {
    let videos = [];
    let reproductor = [];
    if (this.props.videos) {
      videos = this.props.videos.map((video, i) => {
        if (video.snippet.thumbnails !== undefined && video.snippet.thumbnails.default) {
        return (
          <div key={i+"Video"}
            className={classes.contenedor_video}
            onClick={e => this.changeCurrentVideo(e, video)}
          >
            <div className={classes.videos_medio}>
              <div className="col">
                <img
                  src={video.snippet.thumbnails.default.url}
                  alt={"imagen" + i}
                  className={classes.item_video}
                />
              </div>
              <div className="col">
                <div className={classes.padding_top}>
                  <span className="texto_blanco montse texto_16">
                    {video.snippet.title}
                    {/* Video */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;

      });
      reproductor = (
        <div className={classes.video_container}>
          <iframe
            width="100%"
            height="380px"
            src={"https://www.youtube.com/embed/" + this.state.videoId}
            frameBorder="0"
            title="videos"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className={["col-sm-12  margin_bottom_50", classes.relativo, this.props.videos.length === 1  ? null: "col-md-9"].join(" ")}>
          <div>{reproductor}</div>
          <div className={classes.descripcion_video}>
            <span className={"texto_16 montse texto_blanco"}>
              {this.state.descripcion_video}
            </span>
          </div>
        </div>
        <div
          className={[
            "col-sm-12 ",
            this.props.videos.length === 1 ? "d-none" : "col-md-3",
            classes.relativo,
            classes.items_videos
          ].join(" ")}
        >
          {videos}
        </div>
      </React.Fragment>
    );
  }
}

export default Videos;

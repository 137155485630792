import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  diccionario: null,
  diccionarioHome: null,
};

const setIntl = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INTL_SUCCESS:
      return setIntl(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./FilterSic.css";
import { Form, Field } from "react-final-form";
import queryString from "query-string";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import { getLan } from "../../../stores/utils/utilities";

import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";
import { withRouter } from "react-router-dom";

const tiposValores = [
  {
    id: 17,
    nombre: getLan() === "es" ? "SIC capitales" : "SIC Equity"
  },
  {
    id: 19,
    nombre: getLan() === "es" ? "SIC deuda" : "SIC Debt"
  },
  {
    id: 18,
    nombre: getLan() === "es" ? "SIC ETF´s" : "SIC ETFs"
  }
];

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  options,
  maxCharacter = null,
  isMultiple = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes && elementType === "checkbox" ? classes : [classes, "form-control"].join(" "),
      ref: ref,
      disabled: disabled,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    isMultiple: isMultiple,
    maxCharacter: maxCharacter,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      {label && elementType !== "checkbox" ? (
        <label for={input.name} className={["texto_14", "montse", "regular"].join(" ")}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterSic extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.escFunction = this.escFunction.bind(this);

  }

  state = {
    esconderAutoComplete: false,
    cotizaciones: null,
    tab: null,
    toggleFilters: false,
    disableEnableButtons: true,
    selectedFilters: {
      tipoValor: [],
      instrumentos: []
    },
    form_filters: {
      instrumentos: {
        value: ""
      },
      tipoValor: {
        value: []
      }
    },
    form_clave: {
      clave: {
        value: ""
      }
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    clave: null,
    tipoValor: [],
    biva: true,
    canceladas: false
  };


  componentDidMount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
    if (this.props.claveEmisoraSeleccionada) {
      this.setState({
        valueClave: this.props.claveEmisoraSeleccionada,
        toggleInputs: this.props.claveEmisoraSeleccionada ? true : false
      })

    }
    let paramsString = queryString.parse(this.props.location.search);
    let emisora = paramsString["emisora"] !== undefined && paramsString["emisora"] !== "null" ? paramsString["emisora"] : null;
    let clave = paramsString["clave"] !== undefined && paramsString["clave"] !== "null" ? paramsString["clave"] : null;
    let tab =
    paramsString["tab"] !== undefined && paramsString["tab"] !== "null"
      ? parseInt(paramsString["tab"])
      : null;

    if (clave) {
      this.setState({
        toggleInputs: true,
        toggleFilters: false,
        disableEnableButtons: false,
        typeClaveSelected: emisora ? 1 : 0,
        valueClave: clave,
       
      });
    } 

    let biva = tab === 1 ? true : false
    let canceladas = tab === 2 ? true : false
    this.setState({
      biva: biva,
      canceladas: canceladas,
    });
    this.props.onFilterMercados(null, biva, canceladas,null );
  }
  componentWillUnmount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.tab !== prevProps.tab) {
      let biva = this.props.tab === 1 ? true : false
      let canceladas = this.props.tab === 2 ? true : false
      this.setState({
        biva: biva,
        canceladas: canceladas,
      });
      this.props.onFilterMercados(null, biva, canceladas,null );
    }
    if ( this.props.claveEmisoraSeleccionada && this.props.claveEmisoraSeleccionada !== prevProps.claveEmisoraSeleccionada) {
      this.setState({
        valueClave: this.props.claveEmisoraSeleccionada,
        toggleInputs: this.props.claveEmisoraSeleccionada && this.props.claveEmisoraSeleccionada !== "" ? true : false,
        toggleFilters: this.props.claveEmisoraSeleccionada && this.props.claveEmisoraSeleccionada !== "" ? false : true,
        typeClaveSelected: 0,
        disableEnableButtons: this.props.claveEmisoraSeleccionada && this.props.claveEmisoraSeleccionada !== "" ? false : true,
      })
    }
    if ( this.props.emisoraSeleccionada && this.props.emisoraSeleccionada !== prevProps.emisoraSeleccionada) {
      this.setState({
        toggleInputs: this.props.emisoraSeleccionada && this.props.emisoraSeleccionada !== "" ? true : false,
        toggleFilters: this.props.emisoraSeleccionada && this.props.emisoraSeleccionada !== "" ? false : true,
        typeClaveSelected: 1,
        disableEnableButtons: this.props.emisoraSeleccionada && this.props.emisoraSeleccionada !== "" ? false : true,
      })
    }
  }

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible
    });
  }


  componentWillMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.tipoValor === []) {
      this.setState({
        tipoValor: nextProps.tipoValor
      });
    }

    if (this.state.tab !== nextProps.tab) {
      let biva = false;
      let canceladas = false;

      if (nextProps.tab === 1) {
        biva = true;
      }
      if (nextProps.tab === 2) {
        canceladas = true;
      }
      this.onResetFilterHandler(biva, canceladas, false); // false para que no haga llamado a carga de resultados, viene por cambio de tab, sólo resetea valores
      this.inputChangeHandler(null, false, false, biva, canceladas, true); // para borrar busqueda aproximada en cambio de tab

      this.setState({
        tab: nextProps.tab,
        biva: biva,
        canceladas: canceladas,
        toggleInputs: this.props.emisoraSeleccionada && this.props.emisoraSeleccionada !== "" ? true : false,
      });

    }
  }

  printFrameHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : this.showInputHandler();
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col-3", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar Filtros"
                        : "Close"
                      : getLan() === "es"
                        ? "Filtrar"
                        : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-8", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col-1",
                "col-lg-1",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters ? classes.no_border_search_button_model_one : null
                ].join(" ")}
              >
                <button type="buttom" className={["btn"].join(" ")} disabled>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  onPrintValoresHandler = tipo => {
    const nombre = tipo.nombre.replace(" ", "_");
    const value = `${tipo.id}_${nombre}`;

    return (
      <div className={"margen_derecha_30"}>
        <Field
          name="tipoValor"
          id={tipo.nombre}
          type="checkbox"
          elementType="checkbox"
          value={value}
          classes="form-check-input"
          label={tipo.nombre}
          onInputChange={event => this.onInputSelectedHandler(event, 1)}
          component={renderInput}
        />
      </div>
    );
  };

  onSelectOptionsHandler = type => {
    let options = [];
    type.forEach(element => {
      options.push({
        nombre: element.nombre,
        id: `${element.id}_${element.codigoMercado}_${element.nombre}`
      });
    });
    return options;
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              tipoValor: this.state.form_filters.tipoValor.value,
              instrumentos: this.state.form_filters.instrumentos.value
            }}
            render={({
              optionsInstrumentos = this.props.instrumentos ? this.onSelectOptionsHandler(this.props.instrumentos) : [],
              form
            }) => (
                <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="row">
                        <div className="col-12 sin_padding_L sin_padding_R">
                          <span className="montse texto_14 regular color_negro">
                            {getLan() === "es" ? "Tipo de valor" : "Instrument type"}
                          </span>
                        </div>
                        <div className={["col-12 sin_padding_L sin_padding_R", classes.padding_izquierdo].join(" ")}>
                          {tiposValores.map(tipo => {
                            return <div className="float_left">{this.onPrintValoresHandler(tipo)}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                    <div className={["col-12", "col-sm-12 col-md-6 col-lg-6"].join(" ")}>
                      <Field
                        name="instrumentos"
                        id="instrumentos"
                        label={getLan() === "es" ? "Mercado principal" : "Principal market"}
                        type="select"
                        elementType="select"
                        classes="custom-select montse texto_14 regular color_gris"
                        options={optionsInstrumentos}
                        onInputChange={event => this.onInputSelectedHandler(event, 0)}
                        component={renderInput}
                      />
                    </div>
                  </div>
                  <hr />
                  {this.sectionButtonHandler(form)}
                </form>
              )}
          />
        </div>
      </div>
    );
  };

  sectionButtonHandler = form => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterHandler()}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterHandler = event => {
    event.preventDefault();
    // Pass
  };

  onSubmitSearchModelOneHandler = (event) => {
    event.preventDefault();

  };

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = value => {
    this.inputChangeHandler(value);
  };

  inputChangeHandler = (event = null, eraser = null, tipo = null, biva=null, canceladas=null,tipoValor=false) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;

    if (value && value !== "") {
      this.props.onFilterSicByClave(value, this.state.biva, this.state.canceladas);
    } else {
      disabled = true;
    }

    if (tipo) {
      this.props.setEmisora(null, 3, null);
      // this.props.updateEmisoraParent(null, null);
      this.setState({
        emisoraSeleccionada: null,
        clave: null,
      })
      // this.onResetFilterHandler(null, null, false);
      this.props.onFilterMercados(tipoValor ? null : this.onSplitValueHandler(this.state.form_filters.tipoValor.value), biva === null ? this.state.biva: biva, canceladas === null ? this.state.canceladas:canceladas, null);
    
    }

    if (eraser) {
      const [valuesInstrumentos, valuesTipoValor] = this.onGetAllValuesFormFilter();

      const params = {
        size: this.props.itemsPerPage,
        page: 1,
        tipoValor: valuesTipoValor,
        tipoInstrumento: valuesInstrumentos,
        biva:this.state.biva,
        canceladas:this.state.canceladas,
        emisoraSeleccionada: null,
        clave: null,
        tab: this.props.tab
      };
      this.props.setFilters(params);
      this.props.setEmisora(null, 3, null);
      this.props.setPage(1);
      this.props.setInitTabs(this.props.tab, null, false);

      this.setState(prevState => {
        return {
          toggleInputs: !prevState.toggleInputs,
          emisoraSeleccionada: null,
          claveSeleccionada: null,
          form_clave: form_clave,
          disableEnableButtons: disabled
        };
      });
    } else {
      this.setState({
        form_clave: form_clave,
        disableEnableButtons: disabled
      });
    }
  };

  onResetFilterHandler = (biva = null, canceladas = null, changeTab = true) => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    form.instrumentos.value = "";
    selectedFilters.instrumentos = [];
    form.tipoValor.value = [];
    selectedFilters.tipoValor = [];
    this.props.setTipoInstrumento(null, 1);
    this.props.setTipoValor(null);
    this.props.setLimpiarFiltros(changeTab);
    if (changeTab) {
      const params = {
        size: this.props.itemsPerPage,
        page: 1,
        tipoValor: null,
        tipoInstrumento: null,
        biva: biva ? biva : this.state.biva,
        canceladas: canceladas ? canceladas : this.state.canceladas,
        emisoraSeleccionada: null,
        clave: null,
        tab: biva ? 1 : canceladas ? 2 : 0,
        reset: true
      };

      this.props.setFilters(params);
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };



  onFilterTipoValorHandler = (instrumento = null, tipoValor) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor
    };
    this.props.onFilterTipoValor(params);
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newValue = null;
    let newLabel = null;

    newValue = type === 0 ? value.split("_")[0] : value;
    newLabel = type === 0 ? value.split("_")[2] : `${value.split("_")[1]}_${value.split("_")[2]}`;

    return [newValue, newLabel];
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = () => {
    const form = this.state.form_filters;

    // Se obtiene el valor de instrumentos
    const valuesInstrumentos =
      form.instrumentos.value && form.instrumentos.value !== "" ? this.onSplitValueHandler(form.instrumentos.value) : "";

    // Se obtiene el valor de tipo valor
    const valuesTipoValor =
      form.tipoValor.value && form.tipoValor.value.length > 0 ? this.onSplitValueHandler(form.tipoValor.value) : [];

    return [valuesInstrumentos, valuesTipoValor];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["instrumentos", form.instrumentos.value, selectedFilters.instrumentos];
      case 1:
        return ["tipoValor", form.tipoValor.value, selectedFilters.tipoValor];
    }
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    const [valuesInstrumentos, valuesTipoValor] = this.onGetAllValuesFormFilter();
    const params = {
      size: this.props.itemsPerPage,
      page: 1,
      tipoValor: valuesTipoValor,
      tipoInstrumento: valuesInstrumentos,
      biva:this.state.biva,
      canceladas:this.state.canceladas,
      emisoraSeleccionada: this.state.emisoraSeleccionada,
      clave: this.state.clave,
      tab: this.props.tab
    };
    this.props.setFilters(params);

    let wasFiltered = false;
    // Se invoca la funcion del componente padre (Sic.js) para setear los valores correspondientes
    // Configurando el valor del campo instrumento selecionado
    const instrumento = valuesInstrumentos !== "" ? valuesInstrumentos : null;
    this.props.setTipoInstrumento(instrumento, 1);
    wasFiltered = true;
    // Configurando el valor del campo tipo valor selecionado
    const tipoValor = valuesTipoValor && valuesTipoValor.length > 0 ? valuesTipoValor : null;
    this.props.setTipoValor(valuesTipoValor);
    wasFiltered = true;

    this.props.setPage(1);
    this.props.setWasFiltered(wasFiltered);
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null, label = null) => {
    let newStateValues = null;

    if (type === 0) {
      if (values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];

      const stateExists = newStateValues.find(option => option === values);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== values);
      } else {
        if (values !== "") {
          newStateValues.push(values);
        } else {
          newStateValues = [];
        }
      }
    }
    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];

    if (type !== 1) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExist = newFilterValues.find(option => option.label === label);
      if (filterExist) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
      } else {
        if (values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        } else {
          newFilterValues = [];
        }
      }
    }

    return newFilterValues;
  };

  onInputSelectedHandler = (event, type = null) => {
    let actualValue = event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue, type);

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);

    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });
    if (inputName !== "instrumentos") {
      this.props.onFilterMercados(this.onSplitValueHandler(this.state.form_filters.tipoValor.value), this.state.biva, this.state.canceladas, null);
    }
    this.onFilterDataByEvent();
  };

  onDeleteFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

    const auxValue = item.type === 0 ? "" : item.value;
    newStateValues = this.onChangeStateHandler(stateValues, auxValue, item.type, item.label);

    newFilterValues = this.onChangeFilterHandler(filterValues, auxValue, item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.selectedFilters.instrumentos, ...this.state.selectedFilters.tipoValor];

    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map(item => (
          <div className={classes.filter_container_model_one} key={`${item.nombre}-${item.id}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{item.label}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.props.setEmisora(null, 3, null);

      this.props.onFilterMercados(this.state.form_filters.tipoValor.value, this.state.biva, this.state.canceladas, null);

      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null
      });
    }
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => (
      <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}>
        {item.clave}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let emisoraSeleccionada = null;
    let claveSeleccionada = null;
    if (option === 0) {
      claveSeleccionada = clave;
      this.props.setEmisora(claveSeleccionada, 2, null);
      this.props.updateEmisoraParent(null, clave);

    } else {
      emisoraSeleccionada = id;
      claveSeleccionada = clave;
      this.props.setEmisora(emisoraSeleccionada, 1, clave);
      this.props.updateEmisoraParent(id, clave);
    }
    // this.props.onInitSic(this.props.itemsPerPage, 0, null, null, null, null, emisoraSeleccionada, claveSeleccionada);

    this.setState({
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: id,
      clave: clave
    });
  };

  onPrintOptionSelectedHandler = value => {
    const textFound = getLan() === "es" ? "Ver todos los resultados para" : "See all results for";
    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
        <span className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {this.state.typeClaveSelected === 0 ? `${textFound}: ${this.state.valueClave}` : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.inputChangeHandler(null, true, true)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.listadoClaves ? this.onPrintAutoCompleteHandler() : null;

    const textFound = getLan() === "es" ? "Ver todos los resultados para" : "See all results for";
    const textNotFound = getLan() === "es" ? "No existen resultados para la búsqueda" : "There are no search results";

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {`${textFound}: ${value}`}
        </li>
      ) : (
          <li className="list-group-item">{`${textNotFound}: ${value}`}</li>
        );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div className={[classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null].join(" ")}>
          <Form
            onSubmit={this.onSubmitSearchModelOneHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value
            }}
            render={() => (
              <form ref={this.formSearch} onSubmit={this.onSubmitSearchModelOneHandler}>
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  maxCharacter="12"
                  placeholder={getLan() === "es" ? "Buscar por clave de cotización o ISIN" : "Search by ticker or ISIN"}
                  component={renderInput}
                  onInputChange={event => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              !this.state.form_clave.clave.value || this.state.form_clave.clave.value === "" ? classes.hidden : null,
              this.state.esconderAutoComplete ? classes.hidden : null

            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const filter = this.printFrameHandler();
    return filter;
  }
}

const mapStateToProps = state => {
  return {
    instrumentos: state.sic.mercados,
    listadoClaves: state.sic.claves
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitSic: (size, page, biva, canceladas, tipoValor, mercadoPrincipal, emisora, clave) => {
      dispatch(actions.initSic(size, page, biva, canceladas, tipoValor, mercadoPrincipal, emisora, clave));
    },
    onFilterMercados: (tipoInstrumento, biva, canceladas, empresa) => {
      dispatch(actions.initMercadosSic(tipoInstrumento, biva, canceladas, empresa));
    },
    onFilterSicByClave: (clave, biva, canceladas) => {
      dispatch(actions.initClavesSic(clave, biva, canceladas));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterSic)
);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
    afores: null,
    aforeDetail: null,
    informacion: null,
    concentradoPrecios: null,
    clavesAfore: null,
    clavesSiefore: null,
    tiposSiefore: null,
    clasificacionesSiefore: null,
    tiposDocumento: null
};

const setAfores = (state, action) => {
    return updateObject(state, action);
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_AFORES_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESDETAIL_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESINFORMACIONCORPORATIVA_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESCONCENTRADOPRECIOS_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESCONCENTRADOPRECIOSGRAL_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESCVEAFORE_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESCVESIEFORE_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESTIPOSIEFORE_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESCLASIFICACION_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESCVEAFOREINFORMACIONCORPORATIVA_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESCVESIEFOREINFORMACIONCORPORATIVA_SUCCESS:
            return setAfores(state, action);
        case actionTypes.FETCH_AFORESTIPODOCUMENTO_SUCCESS:
            return setAfores(state, action);
        default:
            return state;
    }
};

export default reducer;

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  ultimoDiaBiva: null,
  ultimoDiaCasaBolsa: null,
  ultimoDiaEmisorasTotal: null,
  ultimoDiaEmisorasLocal: null,
  ultimoDiaEmisorasSic: null,

  ultimoMesBiva: null,
  ultimoMesCasaBolsa: null,
  ultimoMesCasaBolsaLocal: null,
  ultimoMesCasabolsaSic: null,
  ultimoMesEmisorasTotal: null,
  ultimoMesEmisorasLocal: null,
  ultimoMesEmisorasSic: null,

  crucesTotales: null,
  crucesIntencionales: null,
  crucesExcepcionales: null,

  historicoBiva: null,
  historicoCasaBolsaTotal: null,
  historicoCasaBolsaLocal: null,
  historicoCasaBolsaSic: null,

  cotizaciones: null,
  suspensiones: null,
  instrumentos: null,
  emisoraId: null,
  operaciones: null,
  claves: []
};

const setInformacionMercado = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ULTIMODIABIVA_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMODIACASABOLSA_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMODIAEMISORASTOTAL_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMODIAEMISORASLOCAL_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMODIAEMISORASSIC_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMOMESBIVA_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMOMESCASABOLSA_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMOMESEMISORASTOTAL_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMOMESEMISORASLOCAL_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMOMESEMISORASSIC_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMOMESCASABOLSALOCAL_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_ULTIMOMESCASABOLSASIC_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_CRUCESTOTALES_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_CRUCESINTENCIONALES_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_CRUCESEXCEPCIONALES_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_HISTORICOBIVA_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_HISTORICOCASABOLSATOTAL_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_HISTORICOCASABOLSALOCAL_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_HISTORICOCASABOLSASIC_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_COTIZACIONESEMISORAS_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_SUSPENSIONES_SUCCESS:
      return setInformacionMercado(state, action);
    case actionTypes.FETCH_OPERACIONESMERCADO_SUCCESS:
      return setInformacionMercado(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
// import classes from "./Bim.css";
import Box from "../../../../components/ui/box/Box";
import Cuadricula from "../../../../components/ui/cuadricula/Cuadricula";
import { FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
class Bim extends Component {
  state = {
    box1: {
      leftIcon: "far fa-file-pdf",
      title: "Solicitud claves de acceso BIM",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url:
        "https://biva.mx/documents/30877/172203/BIVA+Solicitud+de+Claves+de+Acceso+a+BIM.pdf/c5d33954-eacb-084e-0b4d-da7ca02c2816"
    },
    box2: {
      leftIcon: "far fa-file-pdf",
      title: "Descargar contrato BIM",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url: "https://biva.mx/documents/30877/172203/BIVA+Contrato+BIM.pdf/eb5c0e3b-671a-4d9d-bb51-82d8d66b7935"
    },

    cuadricula222323: [
      {
        texto: "Información de mercado en tiempo real",
        clase: "normal",
        icono_url: "tiempo.svg"
      },
      {
        texto: "Estadísticas, gráficas, reportes y cálculos",
        clase: "normal",
        icono_url: "estadistica_grafica.svg"
      },
      {
        texto: "Información del mercado BIVA y otras bolsas",
        clase: "normal",
        icono_url: "grafica_mercado.svg"
      },
      {
        texto: "Profundidad total del mercado",
        clase: "normal",
        icono_url: "profundidad.svg"
      },
      {
        texto: "Disponibilidad y reportes de información intradía",
        clase: "normal",
        icono_url: "disponibilidad.svg"
      },
      {
        texto: "Monitoreo operativo y desempeño tecnológico de los medios de acceso",
        clase: "normal",
        icono_url: "monitoreo2.svg"
      }
    ]
  };
  componentDidMount() {
    this.props.initCuadriculaBeneficiosBIM();
  }
  componentDidUpdate(prevProps) {
    if (this.props.cuadriculaBim !== prevProps.cuadriculaBim) {
      //se obtiene el diccionario del endpoint de traducciones
      this.setState({
        cuadriculaBim: this.props.cuadriculaBim
      });
    }
  }

  render() {
    let video = null;
    if (this.props.video) {
      let idVideo = "";
      if (this.props.video.length > 0) {
        idVideo = this.props.video[0].url.split("=")[1];
      }
      video = (
        <div className="row margen_top_50">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedHTMLMessage id="informacionmercado.productos.bim.comofunciona" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className={["col-12 sin_padding_L sin_padding_R", this.props.video ? null : "dp-none"].join(" ")}>
            <iframe
              title="BIM"
              width="100%"
              height="476px"
              src={`https://www.youtube.com/embed/${idVideo}`}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        </div>
      );
    }
    let contratos = null;
    if (this.props.contratoBim) {
      contratos = this.props.contratoBim.map((contrato, i) => {
        return <Box key={"contratoBim" + i} modelo="modelo_1" data={contrato}  />;
      });
    }
    return (
      <React.Fragment>
        <div className=" margen_top_30">
          <span className={"montse texto_16"}>
            <FormattedHTMLMessage id="informacionmercado.productos.bim.desc" defaultMessage=" " />
          </span>
        </div>
        <div className="row margen_top_50">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle  nunito"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2 className="">
                <FormattedHTMLMessage id="informacionmercado.productos.bim.beneficios" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className={["maren_top_30"]}>
            {/* <Cuadricula
              datos={this.state.cuadriculaBim}
              coloreados={[]}
              saltarse={[]}
              enBlanco={[]}
              quitar={[]}
              modulo={3}
              tipo={5}
            /> */}
             <Cuadricula
              datos={this.state.cuadriculaBim}
              coloreados={[]}
              saltarse={[]}
              enBlanco={[]}
              quitar={[]}
              modulo={3}
              tipo={6}
            />
          </div>
        </div>

        {video}

        <div className={"margen_top_30"}>
          <span className={"nunito texto_30 regular"}>
            <FormattedHTMLMessage id="informacionmercado.productos.bim.comofunciona.subtitulo" defaultMessage=" " />
          </span>
          <p>
            <span className={"montse texto_ 16 regular"}>
              <FormattedHTMLMessage id="informacionmercado.productos.bim.comofunciona.desc" defaultMessage=" " />
            </span>
          </p>
        </div>

        <div className="row margen_top_50">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedHTMLMessage id="informacionmercado.productos.bim.interesado" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className="row">
            <p className={"montse texto_18 regular "}>
              <FormattedHTMLMessage id="informacionmercado.productos.bim.interesado.desc" defaultMessage=" " />
            </p>
            <div className={"row margen_top_30"}>
              {contratos}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    cuadriculaBim: state.cuadriculas.beneficiosBim,
    contratoBim: state.documentos.enlacesContratarBim,
    video: state.videos.videoBIM
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initCuadriculaBeneficiosBIM: () => {
      dispatch(action.initCuadriculaBeneficiosBIM());
      dispatch(action.initVideoBIM());
      dispatch(action.getEnlacesContratarBim());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bim);

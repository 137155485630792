import React, { Component } from "react";
// import classes from "./Reportes.css";
import Box from "../../../../components/ui/box/Box";
import { FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";

class Reportes extends Component {
  state = {
    box1: {
      leftIcon: "far fa-file-pdf",
      title: "Solicitar reporte especializado",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url: "/"
    }
  };
  componentDidMount(){
    this.props.getEnlacesReporteEspecializado();
  }

  render() {
    let contratos = null;
    if (this.props.contratoReporteEspecializado) {
      contratos = this.props.contratoReporteEspecializado.map((contrato, i) => {
        return <Box key={"contratoReporteEspecializado" + i} modelo="modelo_1" data={contrato}  />;
      });
    }
    return (
      <React.Fragment>
        <div className=" margen_top_30">
          <span className={"montse texto_16"}>
            <FormattedHTMLMessage id="informacionmercado.productos.personalizado.desc" defaultMessage=" " />
          </span>
        </div>

        <div className="row margen_top_50">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedHTMLMessage id="informacionmercado.productos.personalizado.interesado" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className="row">
            <p className={"montse texto_18 regular "}>
              <FormattedHTMLMessage id="informacionmercado.productos.personalizado.interesado.desc" defaultMessage=" " />
            </p>
            <div className={"col-12 sin_padding_L margen_top_30"}>
             {contratos}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    contratoReporteEspecializado: state.documentos.enlacesReporteEspecializado,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEnlacesReporteEspecializado: () => {
      dispatch(action.getEnlacesReporteEspecializado());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reportes);

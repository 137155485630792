import React, { Component } from "react";
import loaderBar from "../../assets/gif/loader.gif";
import { getLan } from "../../stores/utils/utilities";

class LoaderBar extends Component {
    state = {
        intervalId: null,
        hidden: false
    };

    constructor() {
        super();
        this.timer = this.timer.bind(this);
    }
    componentDidMount() {
        var intervalId = setInterval(this.timer, 3000);
        this.setState({ intervalId: intervalId });
    }
    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }
    timer() {
        this.setState({ hidden: true })
    }
    render() {
        return (
            <>
            {this.state.hidden ?(
                  <span className={["texto_16_17 montse regular color_negro"].join(" ")}>
                        {getLan() === "es" ? "No existen resultados para la búsqueda" : "There are no results for the search"}
                    </span>
            ):
                   (<div>
                        <img src={loaderBar} alt="" />
                    </div>)
            }
            </>

        );
    }
}
export default LoaderBar;

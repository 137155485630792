import React, { Component } from "react";
import classes from "./Metodologia.css";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import Auxs from "../../../../hoc/auxs/Auxs";
import Box from "../../../../components/ui/box/Box";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../../stores/utils/utilities";
class Metodologia extends Component {
  state = {
    showModal: false,
    messages: null,
    lan: getLocal()
  };
  componentDidMount() {
    this.props.onInitIntl();
    this.props.onDocumentosMetodologias();
  }
  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let boxes = null;
    if (this.props.metodologias) {
      boxes = this.props.metodologias.map((metodologia, i) => {
        return <Box key={"metodologia_" + i} modelo="modelo_1" data={metodologia}   />;
      });
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="informacionmercado.inidice.metodologia.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          <div className="row margen_top_30">
            <div className="col sin_padding_L">
              <div className={[classes.text_container].join(" ")}>
                <span className="montse texto_16 regular">
                <FormattedHTMLMessage id="informacionmercado.inidice.metodologia.introduccion" defaultMessage=" " /></span>
              </div>
            </div>
          </div>

          <div className="row margen_top_50 margin_bottom_300">{boxes}</div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onDocumentosMetodologias: () => {
      dispatch(action.initMetodologiasDocumentos());
    },
    onInitIntl: () => {
      dispatch(action.initIntl(41));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    metodologias: state.documentos.metodologias
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Metodologia);

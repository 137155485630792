import React, { Component } from "react";
import classes from "./RedesSociales.css";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import Modal from "../../../components/ui/modal/Modal";

class RedesSociales extends Component {
  state = {
    showModal: false,
    showRedSocial: true,
    width: 0,
    height: 0
  };
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 768) {
      if (this.state.showRedSocial) {
        this.setState({
          showRedSocial: false
        });
      }
    } else {
      if (!this.state.showRedSocial) {
        this.setState({
          showRedSocial: true
        });
      }
    }
  }

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  escondeRedSocial() {
    this.setState({
      showRedSocial: !this.state.showRedSocial
    });
  }

  render() {
    const modal = (
      <React.Fragment>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal
          showModal={this.state.showModal}
          onCloseModal={this.onToggleModalHandler}
          tipo_mensaje={2}
          area={"customer-service"}
        />
      </React.Fragment>
    );

    let colorIconClass = classes.white;
    return (
      <React.Fragment>
        <div
          className={[
            "row",
            classes.botones_list,
            classes.contenedor_social,
            this.state.showRedSocial ? classes.mostrar : classes.esconder
          ].join(" ")}
        >
          <div className={classes.btnRS} onClick={this.escondeRedSocial.bind(this)}>
            <i className={[this.state.showRedSocial ? "fas fa-chevron-left" : "fas fa-chevron-right", classes.arrow].join(" ")} />
          </div>
          <div className={[classes.botones_list]}>
            <a
              className={[classes.icon, classes.social, classes.tw, colorIconClass].join(" ")}
              href="https://twitter.com/BIVAMX"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.twitter_icon}>
                <i className={["fab fa-twitter", colorIconClass].join(" ")} />
              </div>
            </a>
            <a
              className={[classes.icon, classes.social, classes.fb, colorIconClass].join(" ")}
              href="https://www.facebook.com/bivamx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.twitter_icon}>
                <i className={["fab fa-facebook-f", colorIconClass].join(" ")} />
              </div>
            </a>
            <a
              className={[classes.icon, classes.social, classes.youtube, colorIconClass].join(" ")}
              href="https://www.youtube.com/channel/UCTkKbvqTmH0Znzlpbzzu8Fg/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.twitter_icon}>
                <i className={["fab fa-youtube", colorIconClass].join(" ")} />
              </div>
            </a>
            <a
              className={[classes.icon, classes.social, classes.insta, colorIconClass].join(" ")}
              href="https://instagram.com/biva_mx?igshid=quhgbi2n3c5w"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.twitter_icon}>
                <i className={["fab fa-instagram", colorIconClass].join(" ")} />
              </div>
            </a>
            <a
              className={[classes.icon, classes.social, classes.in, colorIconClass].join(" ")}
              href="https://www.linkedin.com/company/biva-mx/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.twitter_icon}>
                <i className={["fab fa-linkedin-in", colorIconClass].join(" ")} />
              </div>
            </a>
            <div
              className={[classes.icon, classes.social, classes.mail, colorIconClass].join(" ")}
              onClick={this.onToggleModalHandler.bind(this)}
            >
              <div className={classes.twitter_icon}>
                <i className={["far fa-envelope", colorIconClass].join(" ")} />
              </div>
            </div>
          </div>
        </div>
        {modal}
      </React.Fragment>
    );
  }
}

export default RedesSociales;

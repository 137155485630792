import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
const base_url = `${dominio}/emisoras/tipo-instrumento`;

const setInstrumentos = (data = null) => {
  return {
    type: actionTypes.FETCH_INSTRUMENTOS_SUCCESS,
    instrumentos: data
  };
};

export const filterInstrumentos = ({sector = null, subSector = null, ramo = null, subRamo = null, biva = null, canceladas = null}) => {
  let url = base_url;
  url = sector ? `${url}?sector=${sector}` : url;
  url = subSector ? `${url}${sector ? "&" : "?"}subsector=${subSector}` : url;
  url = ramo ? `${url}${sector ? "&" : "?"}ramo=${ramo}` : url;
  url = subRamo ? `${url}${sector ? "&" : "?"}subramo=${subRamo}` : url;
  url = biva ? `${url}${sector ? "&" : "?"}biva=${biva}` : url;
  url = canceladas ? `${url}${sector || biva ? "&" : "?"}canceladas=${canceladas}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInstrumentos(response.data));
      })
      .catch(() => dispatch(setInstrumentos()));
  };
};


import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../stores/actions/index";
import Loader from "../../../components/loader/Loader";
import Pagination from "../../../components/ui/pagination/Pagination";
import Accordion from "../../../components/ui/accordion/Accordion";
import { getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";
import axios from "axios";
import classes from "../../../components/ui/filterAvisosOfertasPublicas/FilterAvisosOfertasPublicas.css";
import { colourStyles } from "../../../components/ui/input/Input";
import Select from "react-select";

const obtenerTextos = () => {
  if (getLan() === "es"){
    return {
      selectPlaceholder: "Seleccione una opción",
      filterBtnText: "Filtrar",
      filterBtnCloseText: "Cerrar filtros",
      tickerSelectLabel: "Clave de cotización",
      listingSelectLabel: "Modalidad de listado",
      bondSelectLabel: "Tipo de bono",
      documentSelectLabel: "Tipo de Documento",
      yearSelectLabel: "Ejercicio",
      fromSelectLabel: "Fecha inicial",
      toSelectLabel: "Fecha fin",
      dateValidationErrorLabel: "La fecha final debe ser mayor a la fecha inicial.",
      clearBtnText: " Limpiar",
      noResultsText: "No existen resultados para la búsqueda."
    }
  } else {
    return {
      selectPlaceholder: "Select an option",
      filterBtnText: "Filter",
      filterBtnCloseText: "Close",
      tickerSelectLabel: "Ticker",
      listingSelectLabel: "Listing mode",
      bondSelectLabel: "Bond type",
      documentSelectLabel: "Document type",
      yearSelectLabel: "Year",
      fromSelectLabel: "From",
      toSelectLabel: "To",
      dateValidationErrorLabel: "End date must be greater than start date.",
      clearBtnText: " Clear",
      noResultsText: "No results found."
    }
  }
}

export default function ReportesSustentabilidad(props) {
  const dispatch = useDispatch();
  const dominio = process.env.REACT_APP_DOMINIO;
  const catalogoClavesASGEndpoint = '/emisiones/emisiones-asg/documentos/claves';
  const catalogoTiposDeBonoEndpoint = '/emisiones/emisiones-asg/tipos-bono';
  const catalogoTiposDeDocumentoASGEndpoint = '/emisiones/emisiones-asg/documentos/tipo-documento-filtrado';
  const catalogoEjerciciosASGEndpoint = '/emisiones/emisiones-asg/documentos/ejercicios';
  const defaultIdTipoDocumentoASG = "6,8,5,4,1,2,3";
  let [clave, setClave] = useState("");
  let [modalidadListado, setModalidadListado] = useState(new Array());
  let [tipoBono, setTipoBono] = useState(new Array());
  let [tipoDocumento, setTipoDocumento] = useState(defaultIdTipoDocumentoASG);
  let [ejercicio, setEjercicio] = useState("");
  let [fechaInicio, setFechaInicio] = useState("");
  let [fechaFin, setFechaFin] = useState("");
  let [filters, setFilters] = useState(new Array());
  let [toggleFilter, setToggleFilter] = useState(false);
  let [reset, setReset] = useState(new Date().getTime());
  const [isHomologadoPage, setIsHomologado] = useState(false);
  const [bancoInformacion, setBanco] = useState([]);
  const reportesRedux = useSelector(
    (state) => state.bancosInformacion.bancoInformacion
  );
  const itemsPerPage = 10;
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const textos = obtenerTextos();

  //Claves
  let [catalogoClaves, setCatalogoClaves] = useState([]);
  useEffect(() => {
    if (valueUndefinedOrEmpty(clave)) {
      axios
        .get(dominio+catalogoClavesASGEndpoint+queryFilterString())
        .then(response => {
          setCatalogoClaves(response.data);
        })
        .catch(err => setCatalogoClaves(err.data));
    }
  }, [modalidadListado, tipoBono, tipoDocumento, ejercicio, fechaInicio, fechaFin, reset])
  const getSelectedOptionClaveEjercicio = (catalogo, valor) => {
    let selected = catalogo.find((option) => {
      return option === valor;
    });
    if (selected === undefined){
      return null;
    }
    return {"value": selected, "label":selected}
  }

  //Modalidad de listado
  const [catalogoModalidadDeListado, setCatalogoModalidadDeListado] = useState([]);
  const catalogoModalidadDeListadoES = [{"id":1,"value":"Listado BIVA"},
                                        {"id":3,"value":"Registro secundario"},
                                        {"id":0,"value":"Otros"}]
  const catalogoModalidadDeListadoEN = [{"id":1,"value":"BIVA Listing"},
                                        {"id":3,"value":"Secondary Registration"},
                                        {"id":0,"value":"Others"}]
  useEffect(() => {
    if(getLan() === "es") {
      setCatalogoModalidadDeListado(catalogoModalidadDeListadoES);
    } else {
      setCatalogoModalidadDeListado(catalogoModalidadDeListadoEN);
    }
  }, [])
  const handleModalidadListadoChange = (value) => {
    let index = modalidadListado.indexOf(value)
    let updatedModalidadListado = new Array();
    updatedModalidadListado = updatedModalidadListado.concat(modalidadListado);
    index < 0 ? updatedModalidadListado.push(value) : updatedModalidadListado.splice(index, 1);
    setModalidadListado(updatedModalidadListado);
    if (updatedModalidadListado.length===0) {
      setFilters(removeKeyFromList("modalidadListado", filters));
    }
  };
  const clearModalidadListado = () => {
    setModalidadListado(new Array());
    catalogoModalidadDeListado.map((item) => {
      let input = document.querySelector('input[id="'+item.value+'"]');
      if (input !== null) {
        input.checked = false
      }
    });
  }

  //Tipo de bono
  const [catalogoTiposDeBono, setCatalogoTiposDeBono] = useState([]);
  const catalogoTiposDeBonoEN = [{"id":1,"desc":"Green Bond"},
                                 {"id":2,"desc":"Social Bond"},
                                 {"id":3,"desc":"Sustainability Bond"},
                                 {"id":4,"desc":"Sustainability-Linked Bond"}]
  useEffect(() => {
    if(getLan() !== "es") {
      setCatalogoTiposDeBono(catalogoTiposDeBonoEN);
    }else {
      axios
        .get(dominio+catalogoTiposDeBonoEndpoint)
        .then(response => {
          setCatalogoTiposDeBono(response.data);
        })
        .catch(err => setCatalogoTiposDeBono(err.data));
    }
  }, [])
  const handleTipoBonoChange = (value) => {
    let index = tipoBono.indexOf(value)
    let updatedTipoBono = new Array();
    updatedTipoBono = updatedTipoBono.concat(tipoBono);
    index < 0 ? updatedTipoBono.push(value) : updatedTipoBono.splice(index, 1);
    setTipoBono(updatedTipoBono);
    if (updatedTipoBono.length===0) {
      setFilters(removeKeyFromList("tipoBono", filters));
    }
  };
  const clearTipoBono = () => {
    setTipoBono(new Array());
    catalogoTiposDeBono.map((item) => {
      let input = document.querySelector('input[id="'+item.desc+'"]');
      if (input !== null) {
        input.checked = false
      }
    });
  }

  //Tipo Documento
  let [catalogoTipoDocumento, setCatalogoTipoDocumento] = useState([]);
  useEffect(() => {
    if (valueUndefinedOrEmpty(tipoDocumento) || tipoDocumento === defaultIdTipoDocumentoASG) {
      axios
        .get(dominio+catalogoTiposDeDocumentoASGEndpoint+queryFilterString())
        .then(response => {
          setCatalogoTipoDocumento(response.data);
        })
        .catch(err => setCatalogoTipoDocumento(err.data));
    }
  }, [modalidadListado, tipoBono, clave, fechaInicio, fechaFin, reset])
  const getSelectedOptionTipoDocumento = (catalogo, valor) => {
    let selected = catalogo.find((option) => {
      return option.id === valor
    });
    if (selected === undefined){
      return null;
    }
    return {"value": selected.id, "label":selected.nombre}
  }

  //Ejercicios
  let [catalogoEjercicios, setCatalogoEjercicios] = useState([]);
  let [toggleEjercicio, setToggleEjercicio] = useState(false);
  useEffect(() => {
    let activarEjercicio = tipoDocumento !== "" && [4,5,6,7].includes(tipoDocumento);
    setToggleEjercicio(activarEjercicio)
    if (activarEjercicio) {
      axios
      .get(dominio+catalogoEjerciciosASGEndpoint+queryFilterString(ejercicio))
      .then(response => {
        setCatalogoEjercicios(response.data)
      })
      .catch(err => setCatalogoEjercicios(err.data))
    }
  }, [modalidadListado, tipoBono, clave, tipoDocumento, reset])

  //Fechas
  let [toggleFechas, setToggleFechas] = useState(true);
  let [disableEjercicio, setDisableEjercicio] = useState(false);
  let [validacionFechas, setValidacionFechas] = useState(true);
  useEffect(() => {
    if (!valueUndefinedOrEmpty(fechaInicio) && !valueUndefinedOrEmpty(fechaFin)) {
      setValidacionFechas(fechaInicio < fechaFin);
    } else {
      setValidacionFechas(true);
    }
    setDisableEjercicio(!valueUndefinedOrEmpty(fechaInicio) || !valueUndefinedOrEmpty(fechaFin));
  }, [fechaInicio, fechaFin])
  useEffect(() => {
    if (ejercicio !== "") {
      setFechaInicio("")
      setFechaFin("")
      setToggleFechas(false)
    } else {
      setToggleFechas(true)
    }
  }, [ejercicio])

  //RESET
  function resetFilter(){
    setClave("");
    setTipoDocumento(defaultIdTipoDocumentoASG);
    setFechaInicio("");
    setFechaFin("");
    setEjercicio("");
    clearModalidadListado();
    clearTipoBono();
    setFilters(new Array())
    setReset(new Date().getTime())
  };

  //SOFT RESET
  useEffect(() => {
    if (modalidadListado.length>0 || tipoBono.length>0
      && (!valueUndefinedOrEmpty(clave)
          || !valueUndefinedOrEmpty(tipoDocumento)
          || !valueUndefinedOrEmpty(ejercicio))) {
      setClave("");
      setTipoDocumento(defaultIdTipoDocumentoASG);
      setEjercicio("");
      setReset(new Date().getTime())
    }
  }, [modalidadListado, tipoBono])

  function valueUndefinedOrEmpty(value) {
    return value === undefined || value === "";
  }

  //Query params para filtrar peticiones
  const queryFilterString = (filterToIgnore = null) => {
    let queryParams = "";
    queryParams += !valueUndefinedOrEmpty(clave)? "&clave=" + clave : "";
    queryParams += modalidadListado.length > 0 ? "&idModoListado=" + modalidadListado : "";
    queryParams += tipoBono.length > 0 ? "&idTipoBono=" + tipoBono : "";
    queryParams += !valueUndefinedOrEmpty(tipoDocumento) ? "&idTipoDocumentoASG=" + tipoDocumento : "";
    if (ejercicio!==filterToIgnore) {
      queryParams += !valueUndefinedOrEmpty(ejercicio) ? "&ejercicio=" + ejercicio : "";
    }
    queryParams += !valueUndefinedOrEmpty(fechaInicio) ? "&fechaPublicacionInicio=" + fechaInicio : "";
    queryParams += !valueUndefinedOrEmpty(fechaFin) ? "&fechaPublicacionFin=" + fechaFin : "";
    queryParams = queryParams.replace("&","?");
    return queryParams;
  }
  
  //Lista de filtros seleccionados
  const addElementToFiltersList = (key, label) => {
    let filter = {"key":key,"label":label};
    let newFilters = [...filters]
    newFilters = newFilters.filter((item)=>{return item.key!==key});
    if (!valueUndefinedOrEmpty(label)) {
      newFilters.push(filter)
    }
    setFilters(newFilters)
  }
  const removeKeyFromList = (key, list) => {
    return list.filter((item)=>{return item.key!==key});
  }
  const softResetFiltersList = (key, label) => {
    let newFilters = [...filters]
    newFilters = removeKeyFromList("clave", newFilters);
    newFilters = removeKeyFromList("tipoDocumento", newFilters);
    newFilters = removeKeyFromList("ejercicio", newFilters);
    newFilters = removeKeyFromList(key, newFilters);
    if (!valueUndefinedOrEmpty(label)) {
      newFilters.push({"key":key,"label":label})
    }
    setFilters(newFilters);
  }
  useEffect(() => {
    addElementToFiltersList("clave", clave);
  }, [clave])
  useEffect(() => {
    if (modalidadListado.length>0){
      let labelListado = "";
      for(const element of modalidadListado){
        let found = catalogoModalidadDeListado.find((value) => {return value.id === element});
        labelListado += labelListado.length > 0 ? ", ":"";
        labelListado += found.value;
      }
      softResetFiltersList("modalidadListado", labelListado);
    }
  }, [modalidadListado])
  useEffect(() => {
    if (tipoBono.length>0){
      let labelBono = "";
      for(const element of tipoBono){
        let found = catalogoTiposDeBono.find((value) => {return value.id === element});
        labelBono += labelBono.length > 0 ? ", ":"";
        labelBono += found.desc;
      }
      softResetFiltersList("tipoBono", labelBono);
    }
  }, [tipoBono])
  useEffect(() => {
    let found = catalogoTipoDocumento.find((value) => {return value.id === tipoDocumento});
    let labelDocumento = found!==undefined?found.nombre:"";
    setEjercicio("")
    addElementToFiltersList("tipoDocumento", labelDocumento);
  }, [tipoDocumento])
  useEffect(() => {
    addElementToFiltersList("ejercicio", ejercicio);
  }, [ejercicio])
  useEffect(() => {
    addElementToFiltersList("fechaInicio", fechaInicio);
  }, [fechaInicio])
  useEffect(() => {
    addElementToFiltersList("fechaFin", fechaFin);
  }, [fechaFin])
  const onDeleteFilterHandler = (value, indexToDelete) => {
    let newFilters = [...filters];
    newFilters.splice(indexToDelete,1);
    setFilters(newFilters);
    switch (value.key) {
      case "clave": setClave(""); break;
      case "modalidadListado": clearModalidadListado(); break;
      case "tipoBono": clearTipoBono(); break;
      case "tipoDocumento": setTipoDocumento(defaultIdTipoDocumentoASG); break;
      case "ejercicio": setEjercicio(""); break;
      case "fechaInicio": setFechaInicio(""); break;
      case "fechaFin": setFechaFin(""); break;
    }
  }
  useEffect(() => {
    updateDocumentsList();
  },[clave, modalidadListado, tipoBono, tipoDocumento, ejercicio, fechaInicio, fechaFin])

  function construirObjetoDeFiltros() {
    return {
      "clave":clave, "modalidadListado":modalidadListado, "tipoBono":tipoBono,
      "tipoDocumento":tipoDocumento, "ejercicio":ejercicio, 
      "fechaInicio":fechaInicio, "fechaFin":fechaFin
    }
  };

  function updateDocumentsList() {
    setActivePage(1);
    return getData(1, isHomologadoPage, construirObjetoDeFiltros())
  }

  useEffect(() => {
    let paramsString = queryString.parse(props.location.search);
    let search_field_param = paramsString["search_field"];
    let clave = "";
    let page = null;
    if (props.location.search !== "" && search_field_param === undefined) {            
      clave = paramsString["clave"] === "null"  || paramsString["clave"] === "" || paramsString["clave"] === undefined ? null : paramsString["clave"];
      page = paramsString["page"] === "null"  || paramsString["page"] === "" || paramsString["page"] === undefined ? null : +paramsString["page"];
    }
    setClave(clave);
    let isHomologado = false;
    if(props.location.pathname === "/biva-sostenible/cuestionario-homologado"){
      isHomologado = true;
      setIsHomologado(isHomologado);
    }
    setActivePage(page !== null ? page : 1);
    let filtros = construirObjetoDeFiltros();
    filtros.clave = clave;
    getData(page, isHomologado, filtros);
    dispatch(action.initIntl(103));
  }, []);

  useEffect(() => {
    if (reportesRedux) {
      setBanco(reportesRedux);
      setLoading(false);
    }
  }, [reportesRedux]);
  const handlePageChange = (pageNumber) => {
    if (activePage !== pageNumber) {
      setActivePage(pageNumber);
      getData(pageNumber, isHomologadoPage, construirObjetoDeFiltros());
      props.history.push(`#reportAsg?page=${pageNumber}`);
    }
  };

  const getData = (page = null, isHomologado = false, filtros = null) => {
    setLoading(true);
    const params = {
      page: (page ? page : activePage) - 1,
      clave: filtros.clave,
      size: itemsPerPage,
      idTipoDocumentoASG: isHomologado ? "6" : filtros.tipoDocumento,
      idModoListado: filtros.modalidadListado.length > 0 ? filtros.modalidadListado : "",
      idTipoBono: filtros.tipoBono.length > 0 ? filtros.tipoBono : "",
      ejercicio: filtros.ejercicio,
      fechaPublicacionInicio: filtros.fechaInicio,
      fechaPublicacionFin: filtros.fechaFin
    };
    dispatch(action.getReportesSustentabilidad(params));
  };

  const filterButton = (
    <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
      <button type="button" className="btn" onClick={() => setToggleFilter(!toggleFilter)}>
        <i className="fas fa-filter color_verde_oscuro" />
        <span>
          <span className="color_verde_oscuro">
            {!toggleFilter ? textos.filterBtnText : textos.filterBtnCloseText}
          </span>
        </span>
      </button>
    </div>
  );

  const selectedFilters = (
    <div id="allFitersContainers" className={classes.align_left}>
      {filters.map((value, index) => {
        return (
          <div className={classes.filter_container_model_one} key={`val-${value.key}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>
              {value.label+" "}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => onDeleteFilterHandler(value, index)}/>
            </span>
          </div>
        );
      })}
    </div>
  );

  const filtersOptions = (
    <div className={[classes.secondSectionFilterContainer, !toggleFilter ? classes.hidden : null].join(" ")}>
      <div className={"row texto_14 montse regular"}>
        <div className={"col-6 col-sm-6 col-md-6 col-lg-3 form-group"}>
          <label className="montse texto_14 regular">
            {textos.tickerSelectLabel}
          </label>
          <Select styles={colourStyles} name="clave"
            options={!valueUndefinedOrEmpty(catalogoClaves)?catalogoClaves.map((data, index) => (
              {value: data, label: data, id: index})):null} 
            value={getSelectedOptionClaveEjercicio(catalogoClaves, clave)} 
            onChange={(selected) => setClave(selected.value)}
            placeholder={textos.selectPlaceholder}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-3 form-group">
          <label className={["montse texto_14 regular", classes.hidden].join(" ")}>
            {textos.listingSelectLabel}
          </label>
          {!valueUndefinedOrEmpty(catalogoModalidadDeListado)?catalogoModalidadDeListado.map((item) => (
          <div className={["form-group", classes.hidden].join(" ")} key={"modoListado"+item.id}>
            <input type="checkbox" className='form-check2-input' style={{position: 'absolute'}}
              name="modoListado" id={item.value}
              checked={modalidadListado.includes[item.id]}
              onChange={() => handleModalidadListadoChange(item.id)}/>
            <label htmlFor={item.value} className='texto_14 montse' style={{paddingLeft: '1rem', marginTop: '10px'}}>
            {item.value}
            </label>
          </div>
          )):null}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
          <label className={["montse texto_14 regular", classes.hidden].join(" ")}>
            {textos.bondSelectLabel}
          </label>
          {!valueUndefinedOrEmpty(catalogoTiposDeBono)?catalogoTiposDeBono.map((item) => (
            <div className={["form-group", classes.hidden].join(" ")} key={"tipoBono"+item.id}>
              <input type="checkbox" className='form-check2-input' style={{position: 'absolute'}}
                name="tipoBono" id={item.desc}
                checked={modalidadListado.includes[item.id]}
                onChange={() => handleTipoBonoChange(item.id)}/>
              <label htmlFor={item.desc} className='texto_14 montse' style={{paddingLeft: '1rem', marginTop: '10px'}}>
              {item.desc}
              </label>
            </div>
          )):null}
        </div>
        <div className={"col-6 col-sm-6 col-md-6 col-lg-3 form-group"}>
          <label className="montse texto_14 regular">
            {textos.documentSelectLabel}
          </label>
          <Select styles={colourStyles} name="tipoDocumento"
            options={!valueUndefinedOrEmpty(catalogoTipoDocumento)?catalogoTipoDocumento.map((data, index) => (
              {value: data.id, label: data.nombre, id: index})):null}
            value={getSelectedOptionTipoDocumento(catalogoTipoDocumento, tipoDocumento)} 
            onChange={(selected) => {setTipoDocumento(selected.value)}}
            placeholder={textos.selectPlaceholder}
          />
        </div>
        <div className={"col-6 col-sm-6 col-md-6 col-lg-3 form-group"} style={{visibility: toggleEjercicio ? 'visible' : 'hidden' }}>
          <label className="montse texto_14 regular">
            {textos.yearSelectLabel}
          </label>
          <Select styles={colourStyles} name="ejercicio"
            options={!valueUndefinedOrEmpty(catalogoEjercicios)?catalogoEjercicios.map((data, index) => (
              {value: data, label: data, id: index})):null}
            value={getSelectedOptionClaveEjercicio(catalogoEjercicios, ejercicio)} 
            onChange={(selected) => {setEjercicio(selected.value)}}
            isDisabled={disableEjercicio}
            placeholder={textos.selectPlaceholder}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-3 form-group">
          <label className="montse texto_14 regular">
            {textos.fromSelectLabel}
          </label>
          <input type="date" name="fecha_inicio" id="fecha_inicio" className="form-control"
            key={new Date().getTime()} disabled={!toggleFechas}
            value={fechaInicio} onChange={(e)=>setFechaInicio(e.target.value)}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-3 form-group">
          <label className="montse texto_14 regular">
            {textos.toSelectLabel}
          </label>
          <input type="date" name="fecha_fin" id="fecha_fin" className="form-control"
            key={new Date().getTime()} disabled={!toggleFechas}
            value={fechaFin} onChange={(e)=>setFechaFin(e.target.value)}
          />
        </div>
        <div className={[classes.errorContainer, "col-12 col-lg-6 offset-lg-6",
          validacionFechas ? classes.hidden : null].join(" ")}>
          <p className={"texto_14 montse regular"}> 
            {textos.dateValidationErrorLabel}
          </p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center color_verde_oscuro">
          <button type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14",].join(" ")}
            onClick={() => resetFilter()} onChange={(e) => {}}>
            <i className={"fas fa-redo-alt color_verde_oscuro"} />
            {textos.clearBtnText}
          </button>
        </div>
      </div>
    </div>
  );
  
  const filtersSection = (
    <div className="margin_bottom_20">
      <div className={["row", classes.firstSectionFilterContainer].join(" ")}>
        <div className="col col-lg-3 sin_padding">
          {filterButton}
        </div>
        <div className={["col","col-lg-9","btn"].join(" ")}>
          {selectedFilters}
        </div>
      </div>
      {filtersOptions}
    </div>
  );

  const numRows = bancoInformacion ? bancoInformacion.totalElements : 0;
  let contenidoMostrar = null;
  if (bancoInformacion && bancoInformacion.data && bancoInformacion.totalElements != 0) {
    contenidoMostrar = (
      <>
        <Accordion
          datos={bancoInformacion.data}
          nombre="emisoras"
          icon={""}
          tipo={13}
          subtipo={4}
          isSustentable={true}
        />

        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          activePage={activePage}
          isSustentable={true}
        />
      </>
    );
  } else {
    contenidoMostrar = (
      <p className="montse texto_16 regular">
        {textos.noResultsText}
      </p>
    );
  }
  let loader = null;
  if (loading) {
    loader = <Loader loaderDisable={(disable) => setLoading(disable)} />;
  }
  return (
      <>
        {loader}
        <div className="margen_50" id="filtrosAsg">{filtersSection}</div>
        <div className="margen_50" id="reportAsg">{contenidoMostrar}</div>
      </>
  );
}

import React, { Component } from "react";
import classes from "./Funciones.css";
import { connect } from "react-redux";

import Modal from "../../../components/ui/modal/Modal";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";

import Auxs from "../../../hoc/auxs/Auxs";
import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";
import Boton from "../../../components/ui/boton/Boton";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Funciones extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null,
    showModal: false
  };

  componentDidMount() {
    this.props.onInitIntl();
    this.props.onInitTerminosFunciones();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  onPrintTerminosFuncionesHandler = terminos => {
    return <ToggleBox terminos={terminos} />;
  };
  render() {
    const terminos = this.props.terminos_funciones ? this.onPrintTerminosFuncionesHandler(this.props.terminos_funciones) : null;

    const modal = (
      <React.Fragment>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} onCloseModal={this.onToggleModalHandler} area={"operations"} />
      </React.Fragment>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          {modal}
          <div className="margin_bottom_200">
            <div className="row">
              <div className="col sin_padding_L">
                <div className={[""].join(" ")}>
                  <h1 className={["nunito", "texto_42"].join(" ")}>
                    <FormattedHTMLMessage id="operaciones.vigilanciamercados.titulo1" defaultMessage=" " />
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col sin_padding_L">
                <div className={[classes.text_container, "texto_16", "montse", "margen_top_30"].join(" ")}>
                  <p>
                    <FormattedHTMLMessage id="operaciones.vigilanciamercados.introduccion1" defaultMessage=" " />
                  </p>
                </div>
              </div>
            </div>
            <div className="row margen_top_30">
              <div className="col sin_padding_L">
                <div className={["main_subtitle"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedHTMLMessage id="operaciones.vigilanciamercados.funciones.titulo1" defaultMessage=" " />
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col sin_padding_L">
                <div className={[classes.terminosContainer, "margen_top_50"].join(" ")}>{terminos}</div>
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    terminos_funciones: state.funciones.terminos_funciones
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(38));
    },

    onInitTerminosFunciones: () => {
      dispatch(action.initTerminosFunciones());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Funciones);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  embajador: null,
  grid: null,
  historia: null,
  crecer: null,
  operacion: null,
  lima: null,
  mercado: null,
  consejo: null,
  comiteAdmision: null,
  comiteListado:null,
  comiteAuditoria:null,
  comiteVigilancia: null,
  comiteNormativo:null,
  espacio:null,
  comiteSanciones:null,
  beneficiosModelos: null,
  opel:null,
  caracteristicasOperacion:null,
  conexionItch:null,
  beneficiosBim:null,
  estrategias:null,
  objetivos:null
};

const setCuadriculas = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CUADRICULAS:
      return setCuadriculas(state, action);
    default:
      return state;
  }
};

export default reducer;

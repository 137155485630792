import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/sectores`;

const setSector = (data = null) => {
  return {
    type: actionTypes.FETCH_SECTOR_SUCCESS,
    sectores: data
  };
};

export const filterSector = ({
  instrumento = null,
  tipoValor = null,
  biva = null,
  canceladas = null
}) => {
  let url = base_url;
  url =
    instrumento && instrumento.length > 0
      ? `${url}?tipoInstrumento=${[...instrumento]}`
      : url;
  url =
    tipoValor && tipoValor.length > 0
      ? `${url}${instrumento ? "&" : "?"}tipoValor=${[...tipoValor]}`
      : url;
  url = biva
    ? `${url}${instrumento || tipoValor ? "&" : "?"}biva=${biva}`
    : url;
  url = canceladas
    ? `${url}${instrumento || tipoValor ? "&" : "?"}canceladas=${canceladas}`
    : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSector(response.data));
      })
      .catch(() => dispatch(setSector()));
  };
};

const setSubSectores = (data = null) => {
  return {
    type: actionTypes.FETCH_SUBSECTOR_SUCCESS,
    sub_sectores: data
  };
};

export const filterSubSector = ({
  sector = null,
  instrumento = null,
  tipoValor = null,
  biva = null,
  canceladas = null
}) => {
  let url = base_url;
  url = sector ? `${base_url}/${[...sector]}/subsectores` : url;
  url = instrumento ? `${url}?tipoInstrumento=${[...instrumento]}` : url;
  url = tipoValor ? `${url}&tipoValor=${[...tipoValor]}` : url;
  url = biva
    ? `${url}${instrumento || tipoValor ? "&" : "?"}biva=${biva}`
    : url;
  url = canceladas
    ? `${url}${instrumento || tipoValor ? "&" : "?"}canceladas=${canceladas}`
    : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSubSectores(response.data));
      })
      .catch(() => dispatch(setSubSectores()));
  };
};

import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import LightweightChart from "../lightweightCharts/LightweightChart"
import Filter from "../filterGraficaHistoricoCasaBolsa/FilterGraficaHistoricoCasaBolsa";
import classes from "./GraficaHistoricoCasaBolsa.css";
import {getDateForMonth} from "../../../stores/utils/utilities";
import PropTypes from 'prop-types';

const crucesIndicadores = [
  { nombre: "Total", id: 0 },
  { nombre: "Local", id: 1 },
  { nombre: "SIC", id: 2 },
];

const coloresGrafica = [
  "#00AEEF",
  "#2874A6",
  "#85C1E9",
  "#1ABC9C",
  "#148F77",
  "#76D7C4",
  "#95A5A6",
  "#717D7E",
  "#BFC9CA",
  "#34495E",
  "#85929E",
  "#c1acdc",
  "#54357d",
  "#C39BD3",
  "#9B59B6",
  "#3a3f54",
  "#CD6155",
  "#E6B0AA",
  "#922B21",
  "#E59866",
  "#E67E22",
  "#AF601A",
  "#F39C12",
  "#F1C40F",
  "#239B56",
  "#82E0AA",
  "#117A65",
  "#73C6B6",
  "#2874A6",
  "#A9CCE3",
  "#797D7F",
  "#D7DBDD",
  "#76448A",
  "#EBDEF0",
  "#85C1E9"
];

class GraficaHistoricoCasaBolsa extends Component {
  state = {
    datos: null,
    topSeleccionado: [1, 2, 3, 4, 5],
    anioSeleccionado: null,
    tipoParticipacion: 0,
    tipoParticipacionAntetior: null,
    anios: [],
    datosGrafica: [],
    tops: [],
    tituloGrafica: null,
    top1: null,
    top2: null,
    top3: null,
    top4: null,
    top5: null,
  };

  componentDidMount() {
    this.props.oninitHistoricoTotal();
  }

  componentDidUpdate(nextProps) {
    if (this.props.total !== nextProps.total) {
      this.setState({
        datos: this.props.total,
      });
    }

    if (this.props.local !== nextProps.local) {
      this.setState({
        datos: this.props.local,
      });
    }

    if (this.props.sic !== nextProps.sic) {
      this.setState({
        datos: this.props.sic,
      });
    }
    if (this.props.tabSeleccionado !== nextProps.tabSeleccionado) {
      if (this.state.topSeleccionado && this.state.anioSeleccionado) {
        this.armaGrafica(
          this.state.topSeleccionado,
          this.state.anioSeleccionado
        );
      }
    }
  }

  onSetTop = (topSeleccionado, anio = null) => {
    let top = topSeleccionado;
    if (topSeleccionado === "") {
      top = null;
    }
    this.setState({
      topSeleccionado: top,
    });
    let anioSeleccionado = anio || this.state.anioSeleccionado;

    this.armaGrafica(top, anioSeleccionado);
  };

  onSetFecha = (anioSeleccionado, tops = null) => {
    let anio = null;
    if (anioSeleccionado && anioSeleccionado !== "") {
      anio = anioSeleccionado.nombre
        ? anioSeleccionado.nombre
        : anioSeleccionado;
    }
    this.setState({
      anioSeleccionado: anio,
    });
    let topSeleccionado = tops || this.state.topSeleccionado;
    this.armaGrafica(topSeleccionado, anio);
  };

  armaGrafica = (casasDeBolsaObj = null, anio = null) => {
    let anioDatos = null;
    let datosGrafica = [];
    if (casasDeBolsaObj !== null && casasDeBolsaObj.length > 0) {
      let casasDeBolsa = casasDeBolsaObj.map((itm) => {return itm.nombre ? itm.nombre : itm;});
      if (anio) {
        anioDatos = this.state.datos[anio];
      } else {
        anioDatos = this.state.datos[this.state.anioSeleccionado];
      }

      if (anioDatos !== null && anioDatos !== undefined) {
        let es_operatividad = this.props.tabSeleccionado === 0;
        let meses = Object.keys(anioDatos);
        if (meses) {
          const ultimo_mes = meses.length > 0 ? meses.length : 0;
          meses.forEach((mes) => {
            let datos_mes = anioDatos[mes];
            let time = Math.round(getDateForMonth(mes, anio) / 1000);
            if (anioDatos[ultimo_mes]) {
              anioDatos[ultimo_mes].forEach((dato) => {
                let dato_mes = datos_mes.find((itm) => itm.casaDeBolsa === dato.casaDeBolsa);
                if (dato_mes) {
                  if (casasDeBolsa.find((nombreCasaDeBolsa) => nombreCasaDeBolsa === dato.casaDeBolsa)) {
                    let index = datosGrafica.findIndex((item) => item.legend === dato.casaDeBolsa);
                    if (index === -1) {
                      index = datosGrafica.push({legend: dato.casaDeBolsa, data:[], lineColor: coloresGrafica[datosGrafica.length]}) - 1;
                    }
                    let casaDeBolsa = datosGrafica[index];
                    let valor = es_operatividad
                      ? parseFloat(dato_mes.importeOperado.split(",").join(""))
                      : parseFloat(dato_mes.porcentaje) / 100;
                    casaDeBolsa.data.push({time:time, value: valor});
                  }
                }
              });
            }
          });
        }
      }
    }
    this.setState({
      datosGrafica: datosGrafica,
    });
  };

  seleccionParticipacionMercado = (pm) => {
    let datos = this.state.datos;
    if (pm === 0) {
      //todos
      this.props.oninitHistoricoTotal();
      if (this.props.total) {
        datos = this.props.total;
      }
    } else if (pm === 1) {
      //top 5 local
      this.props.onInitCasaLocal();
      if (this.props.local) {
        datos = this.props.local;
      }
    } else {
      //top 5 SIC
      this.props.onInitCasaSic();
      if (this.props.sic) {
        datos = this.props.sic;
      }
    }
    this.setState({
      tipoParticipacion: pm,
      datos: datos,
    });
  };

  render() {
    let botonesCruces = null;

    if (this.props.tabSeleccionado === 1) {
      botonesCruces = crucesIndicadores.map((item, i) => {
        let cruceActivo = this.state.tipoParticipacion === item.id;
        return (
          <button type="button" key={"cruces" + i}
            onClick={() => this.seleccionParticipacionMercado(item.id)}
            className={[classes.botonCruce, cruceActivo ? classes.btnActivo: null].join(" ")}>
            <span className={"montse texto_16 regular"}>
              {item.nombre}
            </span>{" "}
          </button>
        );
      });
    }

    let charts = null;
    if (this.state.datosGrafica.length > 0) {
      let titulo = "";
      let esPorcentaje = this.props.tabSeleccionado !== 0;
      if (this.props.messages) {
        if (esPorcentaje) {
          titulo = this.props.messages["informacionmercado.historico.casasdebolsa.participacion.participacion"];
        } else {
          titulo = this.props.messages["informacionmercado.historico.casasdebolsa.acumulada.acumulada"]
        }
      }

      charts = (
        <div className={["row", classes.width100].join(" ")}>
          <div className="col-sm-12 margin_top_30 chartContainer">
            <br/>
            <LightweightChart
              key={+new Date()}
              isPointMarkersVisible={true}
              timeVisible={false}
              elements={this.state.datosGrafica}
              isPercentage={esPorcentaje}
              isCurrency={!esPorcentaje}
              isLegendHorizontal={true}
              isTitleCentered={true}
              height={400}
              titulo={titulo}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={["row", classes.contenedorGrafica].join(" ")}>
        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 "}>
          <span className={"texto_30 nunito regular nowrap"}>
            {this.props.tituloGrafica}
          </span>
        </div>
        <div className={["col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sin_padding_RL float-right margin_top_20",
          botonesCruces === null ? classes.hidden : null].join(" ")}>
          <div className={" float-left"}>{botonesCruces}</div>{" "}
        </div>
        <div className="row col-12 sin_padding_RL margin_top_30">
          <Filter
            modelo="modelo_1"
            datos={this.state.datos}
            setTop={(top, anio) => this.onSetTop(top, anio)}
            setFecha={(anio, tops) => this.onSetFecha(anio, tops)}
          />
        </div>
        {charts}
      </div>
    );
  }
}

GraficaHistoricoCasaBolsa.propTypes = {
  total: PropTypes.object,
  sic: PropTypes.object,
  local: PropTypes.object,
  tituloGrafica: PropTypes.string,
  messages: PropTypes.object,
  tabSeleccionado: PropTypes.number,
  oninitHistoricoTotal: PropTypes.func,
  onInitCasaLocal: PropTypes.func,
  onInitCasaSic: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    total: state.informacionMercado.historicoCasaBolsaTotal,
    sic: state.informacionMercado.historicoCasaBolsaSic,
    local: state.informacionMercado.historicoCasaBolsaLocal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    oninitHistoricoTotal: () => {
      dispatch(action.initHistoricoCasaBolsaTotal());
    },
    onInitCasaLocal: () => {
      dispatch(action.initHistoricoCasaBolsaLocal());
    },
    onInitCasaSic: () => {
      dispatch(action.initHistoricoCasaBolsaSic());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraficaHistoricoCasaBolsa);

import React, { Component } from "react";
import Boton from "../../../components/ui/boton/Boton";
import { connect } from "react-redux";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import * as actions from "../../../stores/actions/index";

class AccesoDiv extends Component {

  state = {
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }


  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <div className="row ">
            <div className="col-12 sin_padding_L">
              <div className={[""].join(" ")}>
                <h2 className="nunito texto_42 regular">
                <FormattedHTMLMessage id="empresas.listadodevalores.accesodiv.titulo1" defaultMessage=" " /></h2>
              </div>
            </div>
          </div>
          <div className={"margen_top_30"}>
            <span className={"montse texto_16 regular"}>
            <FormattedHTMLMessage id="empresas.listadodevalores.accesodiv.introduccion1" defaultMessage=" " />
          </span>
          </div>

          <div className="row margen_top_30">
            <div className="col-12 sin_padding_L">
              <Boton
                ruta={"https://div.biva.mx/listing-div/"}
                tipo={1}
                texto={<FormattedHTMLMessage id="empresas.listadodevalores.accesodiv.btn1" defaultMessage=" " />}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>
            <div className="col-12 sin_padding_L">

              <Boton
                ruta={"https://div.biva.mx/div-web/#/login//root/authenticate"}
                tipo={1}
                texto={<FormattedHTMLMessage id="empresas.listadodevalores.accesodiv.btn2" defaultMessage=" " />}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>
            <div className="col-12 sin_padding_L">
              <Boton
                ruta={"https://div.biva.mx/div-web/#/login//root/authenticate"}
                tipo={1}
                texto={<FormattedHTMLMessage id="empresas.listadodevalores.accesodiv.btn3" defaultMessage=" " />}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>


          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(actions.initIntl(68));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccesoDiv);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./CuestionarioAmafore.css";
import * as action from "../../../../stores/actions/index";
import Loader from "../../../../components/loader/Loader";
import Pagination from "../../../../components/ui/pagination/Pagination";
import Accordion from "../../../../components/ui/accordion/Accordion";
import { getLan, getLocal, valueLanguage, valueParamValidation } from "../../../../stores/utils/utilities";
import queryString from "query-string";
import FilterReporteSustentabilidad from "../../../../components/ui/filterReporteSustentabilidad/FilterReporteSustentabilidad";
import Tabs from "../../../../components/ui/tabs/Tabs";
import StringUtils from "../../../../stores/utils/StringUtils";
import Rss from "../../../rss/Rss";

const dominio = `${window.location.protocol}//${window.location.host}`;

const lan = getLocal();

export default function ReportesSustentabilidad(props) {
    const dispatch = useDispatch();

    const [showRssModal, setShowRssModal] = useState();
    const [urlForRss, setUrlForRss] = useState();
    const [cuestionarios, setCuestionarios] = useState([]);
    const itemsPerPage = 10;
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [claveFilter, setClaveFilter] = useState(null);
    const [clave, setClave] = useState(null);
    const [activaTabInicial, setActivaTabInicial] = useState(0);
    const [modoListado, setModoListado] = useState(null);
    const [ejercicio, setEjercicio] = useState(null);
    const [sector, setSector] = useState(null);
    const [tipoEmisora, setTipoEmisora] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [indexSelectedInicial, setindexSelectedInicial] = useState(0);
    const [tabsEmisoras,] = useState([
        {
            icon: "",
            image: "",
            title: valueLanguage(getLan(), "Todas", "All")
        },
        {
            icon: "",
            image: "",
            title: valueLanguage(getLan(), "Listadas en BIVA", "Listed in BIVA")
        },
    ],);

    const reportesRedux = useSelector((state) => state.bivaSustentable.cuestionarios);
    const catalogoSectores = useSelector((state) => state.bivaSustentable.catalogoSectores);
    const catalogoTiposEmisora = useSelector((state) => state.bivaSustentable.catalogoTiposEmisora);

    useEffect(() => {
        let paramsString = queryString.parse(props.location.search);
        let search_field_param = paramsString["search_field"];
        let clave = null;
        let page = null;
        let modoListadoHistory = null;
        let ejercicioHistory = null;
        let sectorHistory = null;
        let tipoEmisoraHistory = null;
        let fechaInicioHistory = null;
        let fechaFinHistory = null;
        let claveFilterHistory = null;
        if (props.location.search !== "" && search_field_param === undefined) {
            clave = paramsString["clave"] === "null" || paramsString["clave"] === "" || paramsString["clave"] === undefined ? null : paramsString["clave"];
            page = paramsString["page"] === "null" || paramsString["page"] === "" || paramsString["page"] === undefined ? null : +paramsString["page"];
            modoListadoHistory = valueParamValidation(paramsString, "modoListado", false, true);
            ejercicioHistory = valueParamValidation(paramsString, "ejercicio");
            sectorHistory = valueParamValidation(paramsString, "sector");
            tipoEmisoraHistory = valueParamValidation(paramsString, "tipoEmisora");
            fechaInicioHistory = valueParamValidation(paramsString, "fechaInicio");
            fechaFinHistory = valueParamValidation(paramsString, "fechaFin");
            claveFilterHistory = valueParamValidation(paramsString, "claveFilter");
        }
        setClave(clave);
        setClaveFilter(claveFilterHistory);
        setModoListado(modoListadoHistory);
        setEjercicio(ejercicioHistory);
        setSector(sectorHistory);
        setTipoEmisora(tipoEmisoraHistory);
        setFechaInicio(fechaInicioHistory);
        setFechaFin(fechaFinHistory);
        setActivePage(page !== null ? page : 1);

        let params={
            page : activePage,
            clave: clave,
            modoListado : modoListado,
            ejercicio : ejercicio,
            sector : sector,
            tipoEmisora : tipoEmisora,
            fechaInicio : fechaInicio,
            fechaFin : fechaFin,
            claveFilter : claveFilter,
        }
        getData(params);
        dispatch(action.initIntl(103));
        dispatch(action.catalogoSectoresAmafore());
        dispatch(action.catalogoTiposEmisoraAmafore());
    }, []);

    useEffect(() => {
        if (reportesRedux) {
            setCuestionarios(reportesRedux);
            setLoading(false);
        }
    }, [reportesRedux]);

    const getData = ({page = null, clave = null, modoListado = null, ejercicio = null, sector = null, tipoEmisora = null, fechaInicio = null, fechaFin = null, claveFilter = null}) => {

        setLoading(true);
        const params = {
            page: (page ? page : activePage) - 1,
            clave: clave,
            modoListado: modoListado,
            ejercicio: ejercicio,
            sector: sector,
            tipoEmisora: tipoEmisora,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            coincidencias: claveFilter,
            size: itemsPerPage,
        };
        dispatch(action.getCuestionariosAmafore(params));
        dispatch(action.filterEjerciciosAmafore(null, modoListado));
        dispatch(action.filterSectoresAmafore(null, modoListado));
        dispatch(action.filterTiposEmisoraAmafore(null, modoListado));
        setUrlForRss(`${dominio}/asg/cuestionarios-amafore/rss`);
    };

    const onSetFilters = ({ modoListadoFilter = null, ejercicio = [], sector = [], tipoEmisora = [], page, fechaInicio = null, fechaFin = null, reset = false, clave = null, claveFilter = null }) => {
        if (reset) {
            let params={
                page : 1,
                clave: clave,
                modoListado : modoListadoFilter,
                ejercicio :  null,
                sector : null,
                tipoEmisora :  null,
                fechaInicio : null,
                fechaFin : null,
                claveFilter : claveFilter,
            }
            getData(params);
            setClaveFilter(clave);
            setEjercicio(null);
            setModoListado(modoListadoFilter);
            setSector(null);
            setTipoEmisora(null);
            setFechaInicio(null);
            setFechaFin(null);
            const queryParams = StringUtils.jsonToQueryString(params);
            props.history.push(`#reportAsg${queryParams}`);
        } else {
            let params={
                page : page,
                clave: clave,
                modoListado : modoListadoFilter,
                ejercicio : ejercicio && ejercicio.length > 0  ? ejercicio.map(item => item.id) : null,
                sector : sector && sector.length > 0  ? sector.map(item => item.id) : null,
                tipoEmisora : tipoEmisora && tipoEmisora.length > 0 ? tipoEmisora.map(item => item.id) : null,
                fechaInicio : fechaInicio,
                fechaFin : fechaFin,
                claveFilter : claveFilter,
            }
            getData(params);
            setClaveFilter(clave);
            setModoListado(modoListadoFilter ? modoListadoFilter : modoListado);
            setEjercicio(ejercicio);
            setSector(sector);
            setTipoEmisora(tipoEmisora);
            setFechaInicio(fechaInicio);
            setFechaFin(fechaFin);
            const queryParams = StringUtils.jsonToQueryString(params);
            props.history.push(`#reportAsg${queryParams}`);
        }
    }

    const handlePageChange = (pageNumber) => {
        if (activePage !== pageNumber) {
            setActivePage(pageNumber);
            let params={
                page : pageNumber,
                clave: clave,
                modoListado : modoListado,
                ejercicio : ejercicio && ejercicio.length > 0  ? ejercicio.map(item => item.id) : null,
                sector : sector && sector.length > 0  ? sector.map(item => item.id) : null,
                tipoEmisora : tipoEmisora && tipoEmisora.length > 0 ? tipoEmisora.map(item => item.id) : null,
                fechaInicio : fechaInicio,
                fechaFin : fechaFin,
                claveFilter : claveFilter,
            }
            getData(params);
            const queryParams = StringUtils.jsonToQueryString(params);
            props.history.push(`#reportAsg${queryParams}`);
        }
    };

    const numRows = cuestionarios && cuestionarios.page ? cuestionarios.page.totalElements : 0;
    let contenidoMostrar = null;
    if (cuestionarios && cuestionarios._embedded) {
        contenidoMostrar = (
            <>
                <Accordion
                    datos={cuestionarios._embedded.content}
                    nombre="emisoras"
                    icon={""}
                    tipo={25}
                    isSustentable={true}
                    key={+new Date()}
                />

                <Pagination
                    claseTipo={2}
                    numRows={numRows}
                    paginas={5}
                    itemsPerPage={itemsPerPage}
                    handlePageChange={handlePageChange}
                    activePage={activePage}
                    isSustentable={true}
                />
            </>
        );
    } else {
        contenidoMostrar = (
            <p className="montse texto_16 regular">
                {getLan() === "es"
                    ? "No existen resultados para la búsqueda."
                    : "No results found."}
            </p>
        );
    }
    let loader = null;
    if (loading) {
        loader = <Loader loaderDisable={(disable) => setLoading(disable)} />;
    }

    const onChangeTabInicial = (index = 0, consulta = false, pageActual = null) => {
        let bivaFilter = null;
        let activePages;
        if (indexSelectedInicial === index) {
            activePages = activePage;
        } else {
            activePages = pageActual ? pageActual : 1;
        }
        if (index === 1) {
            bivaFilter = 1;
        }
        if (consulta) {
            let params={
                page : activePages,
                clave: null,
                modoListado : bivaFilter,
            }
            getData(params);
                // activePages, null, bivaFilter);
            setModoListado(bivaFilter);
            setActivaTabInicial(index);

        }
        setModoListado(bivaFilter);
        setActivaTabInicial(index);
        setindexSelectedInicial(index);
        setActivePage(activePages);
        setLoading(true);
    };

    const showHideRssModal = () => {
        setShowRssModal(current => !current);
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const tabMostrar = (
        <div className="row margen_top_30">
            <div className={["col-12 col-lg-1 sin_padding texto_16 nunito semi_bold text-center", classes.tabsContainer, classes.myCol].join(" ")}>
                <div className={classes.vertical_div}>
                    <span className={classes.vertical_content}>
                        {valueLanguage(getLan(), "Mostrar", "View")}
                    </span>
                </div>
            </div>
            <div className={["col-12 col-lg-11 sin_padding", classes.myCol].join(" ")}>
                <Tabs
                    data={tabsEmisoras}
                    tipo={1}
                    clicked={i => onChangeTabInicial(i, true)}
                    activaTabInicial={activaTabInicial}
                    isSustentable={true}
                />
            </div>
        </div>
    );

    return (
        <>
            {loader}
            {tabMostrar}
            <div className="row margen_top_30 margin_bottom_20">
                <div className="col sin_padding">
                    <FilterReporteSustentabilidad
                        setFilters={filterParams => onSetFilters(filterParams)}
                        emisora_id={clave}
                        biva={modoListado}
                        claveEmisora={clave}
                    />
                </div>
            </div>
            <div>
                <button
                    className={["btn btn-link margen_50", classes.btn_link].join(" ")}
                    type="button"
                    onClick={showHideRssModal}
                >
                    <div className="margen_50 texto_16 montse regular texto_naranja">
                    {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
                    <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                </button>
                <Rss
                    showRssModal={showRssModal}
                    modalTitle={getLan() === "es" ? "Cuestionario homologado ASG" : "ESG standardized questionnaire"}
                    urlRss={urlForRss}
                    rssType={8}
                    optionType={4}
                    multipleOptionsInputs={
                        [{titulo:"Clasificación sectorial AMAFORE", datos:catalogoSectores, queryParam:"sector"},
                        {titulo:"Tipo de emisora", datos:catalogoTiposEmisora, queryParam:"tipoEmisora"}]}
                    customStyle={{left: "10%",
                                width: '80%',
                                transition: 'all 0.2s ease-out',
                                padding: "30px",
                                position: 'absolute',
                                top: '10px',
                                "z-index": '-500',
                                "background-color": "#fff",
                                "border-radius": "4px",}}
                />
            </div>
            <div className="margen_50" id="reportAsg">{contenidoMostrar}</div>
        </>
    );
}
import React, { Component } from "react";

import Item from "./item/Item";
import classes from "./Galeria.css";
import Fotos from "../../ui/fotos/Fotos";

class Galeria extends Component {
  state = {
    item_seleccionado: null,
    es_video: null
  };

  seleccionImagen(event, item_seleccionado, es_video) {
    event.preventDefault();
    this.setState({
      item_seleccionado: item_seleccionado,
      es_video: es_video
    });
  }

  componentDidUpdate(nextProps) {
    if (this.props.tiene_imagenes) {
      if (nextProps.imagenes !== this.props.imagenes) {
        let imagenes = [];
        let es_video = false;
        if (this.props.imagenes) {
          imagenes = this.props.imagenes[0];
           es_video = false;
        }
        if (this.props.imagenes.length === 0 && this.props.videos) {
          imagenes = this.props.videos.items[0];
          es_video = true;
        }
        this.setState({
          item_seleccionado: imagenes,
          es_video: es_video
        });
      }
    }
  }

  componentDidMount() {
    let imagenes = [];
    if (this.props.tiene_imagenes) {
      if (this.props.imagenes) {
        imagenes = this.props.imagenes;
      }
    } else {
      if (this.props.data ) {
        if (this.props.noMostrarPrimerImagen !== undefined && this.props.noMostrarPrimerImagen !== null && this.props.noMostrarPrimerImagen === true) {
          imagenes = null;
        }else{
          imagenes = this.props.data[0];
        }
      }
    }
    this.setState({
      item_seleccionado: imagenes
    });
  }

  render() {
    let imagenes = null,
      videos = null,
      tipo = 1;
    if (this.props.tiene_imagenes) {
      tipo = 4;
      if (this.props.imagenes) {
        imagenes = this.props.imagenes;
      }
      if (this.props.videos) {
        videos = this.props.videos;
      }
    } else {
      if (this.props.data) {
        imagenes = this.props.data;
      }
    }

    if (this.props.tiene_imagenes) {
      if (imagenes !== null || videos !== null) {
        return (
          <React.Fragment>
            <div className={"row"}>
              <div className={["width_cien", classes.relativo].join(" ")}>
                <span>
                  <Fotos
                    imagenes={imagenes}
                    videos={videos}
                    icono={"fas fa-eye"}
                    seleccionImagen={this.seleccionImagen.bind(this)}
                    tipo={tipo}
                  />
                </span>
              </div>
              <div className={["col-12 sin_padding margen_top_50", classes.relativo].join(" ")}>
                <Item data={this.state.item_seleccionado} es_video={this.state.es_video} />
              </div>
            </div>
          </React.Fragment>
        );
      } else {
        return null;
      }
    } else {
      return (
        <React.Fragment>
          <div className={"row"}>
            <div className={["col sin_padding", classes.relativo].join(" ")}>
              <span>
                <Fotos imagenes={imagenes} icono={"fas fa-plus"} seleccionImagen={this.seleccionImagen.bind(this)} tipo={tipo} />
              </span>
            </div>
            <div className={["col-12 sin_padding margen_top_50", classes.relativo].join(" ")}>
              <Item data={this.state.item_seleccionado} />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Galeria;

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../../stores/actions/index";
import classes from "./Preliminares.css";

import FilterPreliminares from "../../../../../components/ui/filterPreliminares/FilterPreliminares";
import Pagination from "../../../../../components/ui/pagination/Pagination";
import Loader from "../../../../../components/loader/Loader";
import DocumentosSolicitudes from "../../documentosSolicitudes/DocumentosSolicitudes";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Rss from "../../../../rss/Rss";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../../stores/utils/utilities";
import Back from "../../../../../components/ui/back/Back";
import StringUtils from "../../../../../stores/utils/StringUtils";

const dominio = `${window.location.protocol}//${window.location.host}`;

class Preliminares extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    razonSocial: null,
    clave: null,
    nombreEmisora: null,
    numeroTramite: null,
    datos: null,
    emisoraSeleccionada: null,
    tipoValorSeleccionado: null,
    fecha_inicio: null,
    fecha_fin: null,
    page: 0,
    pageInitial: 0,
    detalles: [],
    urlForRss: `${dominio}/emisoras/tramites/rss`,
    tipoDocumento: null,
    pageDocumento: null,
    showRssModal: false,
    verDocumentos: false,
    showBack: true,
  };

  componentWillReceiveProps(nextProps) {
    let solicitudes = null;

    if (nextProps.solicitudes && nextProps.solicitudes.content) {
      solicitudes = nextProps.solicitudes.content;
    }

    if (nextProps.solicitudesDetalle !== this.props.solicitudesDetalle) {
      if (nextProps.solicitudesDetalle) {
        let detalles = [];
        if (this.state.detalles !== null) {
          detalles = this.state.detalles.slice();
        }
        detalles.push([
          {
            idTramite: this.state.tramiteDetalle,
            detalle: nextProps.solicitudesDetalle,
          },
        ]);
        this.setState({
          detalles: detalles,
          tramiteSeleccionado: this.state.tramiteDetalle
            ? this.state.tramiteDetalle
            : null,
        });
      }
    }

    let resource = solicitudes;
    this.onPageChangeHandler(resource, null, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.solicitudes !== prevProps.solicitudes) {
      this.setState({
        loader: false,
      });
    }
  }

  onPageChangeHandler = (
    resource = null,
    pageNumber = null,
    seleccionado = null
  ) => {
    let data = resource ? resource : this.props.solicitudes;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado,
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList,
      });
      this.props.history.push(
        `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${pageNumber}&empresaId=${null}&razonSocial=${null}&clave=${null}&numeroTramite=${null}&clave_seleccionada=${
          this.state.clave
        }&emisora_id=${this.state.emisoraSeleccionada}&valores=${
          this.state.tipoValorSeleccionado
        }`
      );
    } else {
      this.setState({
        resourceList: resourceList,
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true,
        pageInitial: pageNumber,
      });
      this.props.onInitSolicitudes(
        this.state.itemsPerPage,
        pageNumber - 1,
        this.state.emisoraSeleccionada,
        this.state.tipoValorSeleccionado,
        this.state.clave,
        this.state.nombreEmisora
      );

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    this.props.onInitIntl();
    this.initData();
  }

  initData(verDocsParam = null, tramiteIdParam = null) {
    let paramsString = queryString.parse(this.props.location.search);
    let tramiteId = null;

    let razonSocial = null,
      clave = null,
      numeroTramite = null;
    let tipoDocumento = null;
    let numEnvio = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      razonSocial =
        paramsString["razonSocial"] === "null"  || paramsString["razonSocial"] === undefined
          ? null
          : paramsString["razonSocial"];
      clave = paramsString["clave"] === "null"  || paramsString["clave"] === undefined ? null : paramsString["clave"];
      numeroTramite =
        paramsString["numeroTramite"] === "null" || paramsString["numeroTramite"] === undefined
          ? null
          : paramsString["numeroTramite"];
      tipoDocumento =
        paramsString["tipoDocumento"] === "null" || paramsString["tipoDocumento"] === undefined
          ? null
          : paramsString["tipoDocumento"].includes(",")
          ? paramsString["tipoDocumento"].split(",")
          : paramsString["tipoDocumento"];
      numEnvio =
        paramsString["numEnvio"] === "null" || paramsString["numEnvio"] === undefined ? null : paramsString["numEnvio"];
    }

    tramiteId =
    paramsString["tramiteId"] !== undefined &&
    paramsString["tramiteId"] !== "null"
      ? paramsString["tramiteId"]
      : null;
    let clave_seleccionada =
      paramsString["clave_seleccionada"] !== undefined &&
      paramsString["clave_seleccionada"] !== "null"
        ? paramsString["clave_seleccionada"]
        : null;
    let emisora_id =
      paramsString["emisora_id"] !== undefined &&
      paramsString["emisora_id"] !== "null"
        ? paramsString["emisora_id"]
        : null;
    let valores =
      paramsString["valores"] !== undefined &&
      paramsString["valores"] !== "null" &&
      paramsString["valores"] !== "undefined"
        ? paramsString["valores"]
        : null;
    let nombreEmisora =
      paramsString["nombreEmisora"] !== undefined &&
      paramsString["nombreEmisora"] !== "null"
        ? paramsString["nombreEmisora"]
        : null;
    let verDocumentos =
      paramsString["verDocumentos"] !== undefined &&
      paramsString["verDocumentos"] !== "null"
        ? paramsString["verDocumentos"]
        : false;
    let page =
      paramsString["page"] !== undefined && paramsString["page"] !== "null"
        ? parseInt(paramsString["page"])
        : 1;

    if (verDocsParam) {
      verDocumentos = verDocsParam;
    }
    if (tramiteIdParam) {
      tramiteId = null;
    }
    this.setState({
      activePage: page,
      verDocumentos: verDocumentos,
      tipoValorSeleccionado: tipoDocumento,
      emisoraSeleccionada: emisora_id,
      clave: clave_seleccionada,
      nombreEmisora: nombreEmisora,
    });
    if (tramiteId !== null) {
      this.setState({
        tramiteDetalle: tramiteId,
        loader: false,
      });
      this.props.onInitSolicitudesDetalle(tramiteId);
      this.updateGrandparent(tramiteId);
      this.updateGrandparentListado(
        tramiteId,
        razonSocial,
        clave,
        numeroTramite,
        tramiteId,
        tipoDocumento,
        numEnvio,
        page,
        false
      );
    } else {
      this.props.onInitSolicitudes(
        this.state.itemsPerPage,
        page - 1,
        emisora_id,
        valores,
        clave_seleccionada,
        nombreEmisora
      );
    }
  }

  updateGrandparentListado = (
    empresaId,
    razonSocial,
    clave,
    numeroTramite,
    tramiteId = null,
    tipoDocumento = null,
    numEnvio = null,
    page = null,
    consulta = null
  ) => {
    this.props.onInitSolicitudesDetalle(empresaId);
    let datos = {
      id: empresaId,
      numeroTramite: numeroTramite,
      clave: clave,
      razonSocial: razonSocial,
    };

    this.setState({
      tramiteSeleccionado: empresaId,
      razonSocial: razonSocial,
      clave: clave,
      numeroTramite: numeroTramite,
      datos: datos,
      tramiteDetalle: empresaId,
      verDocumentos: true,
    });
    if (consulta === null) {
      this.props.history.push(
        `?tramiteId=${
          tramiteId ? tramiteId : empresaId ? empresaId : null
        }&tipoDocumento=${tipoDocumento}&numEnvio=${numEnvio}&page=${
          page === null ? 1 : page
        }&empresaId=${empresaId}&razonSocial=${razonSocial}&clave=${empresaId ? clave : null}&numeroTramite=${numeroTramite}&verDocumentos=${true}&clave_seleccionada=${clave}&emisora_id=${
          this.state.emisoraSeleccionada
        }`
      );
    }
  };

  updateGrandparent = (value) => {
    this.props.onInitSolicitudesDetalle(value);
    this.setState({
      tramiteDetalle: value,
      detalles: [],
    });
  };
  clickHandlerTramite = (
    idTramite,
    numeroTramite,
    emisora_id,
    clave,
    razonSocial
  ) => {
    if (idTramite || numeroTramite || emisora_id || clave || razonSocial) {
      const params = {
        size: 10,
        page: 1,
        tramiteId: idTramite,
        emisora_id: emisora_id,
        clave_seleccionada: clave,
        preliminar: true
      };
      const queryParams = StringUtils.jsonToQueryString(params);

      this.props.history.push(
        `/listado_de_valores/tramites_de_listado/seguimiento_a_solicitudes` +
          queryParams
      );
    }
  };

  onSetPage = (page) => {
    this.setState({
      activePage: page,
      loader: true,
    });
  };

  onSetValor = (valor) => {
    this.setState({
      tipoValorSeleccionado: valor,
      loader: true,
    });
  };

  onSetFechaInicio = (fecha) => {
    this.setState({
      fecha_inicio: fecha,
      loader: true,
    });
  };

  onSetFechaFin = (fecha) => {
    this.setState({
      fecha_fin: fecha,
      loader: true,
    });
  };

  onSetPageDocumento = (page) => {
    this.setState({
      pageDocumento: page,
      loader: true,
    });
  };

  onSetEmisora = (emisora, tipo, consulta = false, claveEmisora, nombreEmisora = null) => {
    let emisoraSeleccionada = null;
    let clave = null;
    this.setState({ loader: true });
    if (tipo === 1) {
      emisoraSeleccionada = emisora;
      clave = claveEmisora;
    }
    if (tipo === 2) {
      clave = emisora;
    }
    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      clave: clave,
      nombreEmisora: emisoraSeleccionada
    });
    if (consulta) {
      this.props.onInitSolicitudes(
        this.state.itemsPerPage,
        0,
        emisoraSeleccionada,
        this.state.tipoValorSeleccionado,
        clave,
        nombreEmisora
      );
      this.onSetPage(1);
      this.props.history.push(
        `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${1}&empresaId=${null}&razonSocial=${null}&clave=${clave}&numeroTramite=${null}&clave_seleccionada=${
          claveEmisora ? claveEmisora : null
        }&emisora_id=${emisoraSeleccionada}&valores=${null}`
      );
    }
  };

  onShowRssModalHandler = () => {
    this.setState((prevState) => {
      return {
        showRssModal: !prevState.showRssModal,
      };
    });
  };

  onBack() {
    this.setState({
      verDocumentos: false,
      tramiteSeleccionado: null,
      clave: null,
    });
    let solicitudes =
      this.props.solicitudes && this.props.solicitudes.content
        ? this.props.solicitudes.content
        : [];

    let paramsString = queryString.parse(this.props.location.search);
    let tramiteId = null;

    let razonSocial = null,
      clave = null,
      numeroTramite = null;
    let valores = null;
    let fecha_ini = null;
    let fecha_f = null;
    let page = 1;
    let emisora_id = null;
    let clave_seleccionada = null;
    let search_field_param = paramsString["search_field"];

    emisora_id =
      paramsString["emisora_id"] !== undefined &&
      paramsString["emisora_id"] !== "null"
        ? paramsString["emisora_id"]
        : null;
    if (emisora_id) {
      clave_seleccionada =
        paramsString["clave_seleccionada"] !== undefined &&
        paramsString["clave_seleccionada"] !== "null"
          ? paramsString["clave_seleccionada"]
          : null;
    }
    if (solicitudes.length === 0) {
      this.setState({ loader: true });
      this.initData(false, true);
    } else {
      if (
        this.props.location.search !== "" &&
        search_field_param === undefined
      ) {
        tramiteId =
          paramsString["tramiteId"] === "null"
            ? null
            : paramsString["tramiteId"];
        razonSocial =
          paramsString["razonSocial"] === "null"
            ? null
            : paramsString["razonSocial"];
            
        clave = paramsString["clave"] === "null" && tramiteId !== null ? null : paramsString["clave"];
        numeroTramite =
          paramsString["numeroTramite"] === "null"
            ? null
            : paramsString["numeroTramite"];
        valores =
          paramsString["valores"] === "null" ? null : paramsString["valores"];
        fecha_ini =
          paramsString["fecha_ini"] !== undefined &&
          paramsString["fecha_ini"] !== "null"
            ? paramsString["fecha_ini"]
            : null;
        fecha_f =
          paramsString["fecha_f"] !== undefined &&
          paramsString["fecha_f"] !== "null"
            ? paramsString["fecha_f"]
            : null;
        //por tener un paginador anidado, la pagina se pierde, entonces es respaldado por pageInitial
        page = this.state.pageInitial ? this.state.pageInitial : 1;
      }
    }
    this.props.history.push(
      `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${page}&empresaId=${null}&razonSocial=${this.state.emisoraSeleccionada ? razonSocial : null}&clave=${this.state.emisoraSeleccionada ? clave : null}&numeroTramite=${null}&clave_seleccionada=${clave_seleccionada}&emisora_id=${emisora_id}&valores=${valores}`
    );
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader
          loaderDisable={(disable) => this.setState({ loader: disable })}
        />
      );
    }
    let contenidoMostrar = null;
    let showData = null;
    let solicitudes =
      this.props.solicitudes && this.props.solicitudes.content
        ? this.props.solicitudes.content
        : [];
    if (solicitudes.length > 0) {
      showData = (
        <Accordion
          datos={solicitudes}
          nombre="solicitudes"
          icon={""}
          tipo={15}
          titulo={getLan() === "es" ? "Más Información" : "More information"}
          subtipo={1}
          documentosDisponibles={true}
          detalles={this.state.detalles}
          updateGrandparentListado={this.updateGrandparentListado.bind(this)}
          updateGrandparent={this.updateGrandparent.bind(this)}
          clickHandlerTramite={this.clickHandlerTramite.bind(this)}
          esTramite={true}
          esPreliminar={true}
        />
      );
    } else if (solicitudes.length === 0) {
      showData = (
        <p className="montse texto_16 regular">
          {getLan() === "es"
            ? "No existen resultados para la búsqueda."
            : "No results found."}
        </p>
      );
    }
    const numRows = this.props.solicitudes
      ? this.props.solicitudes.totalElements
      : 0;

    contenidoMostrar = (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>
          <FormattedHTMLMessage
            id="prospectos-preliminares.titulo"
            defaultMessage=" "
          />
        </h1>
        <div className="margen_top_30 texto_16 montse regular">
          <FormattedHTMLMessage
            id="prospectos-preliminares.introduccion"
            defaultMessage=" "
          />
        </div>
        <br />
        <FilterPreliminares
          itemsPerPage={this.state.itemsPerPage}
          setEmisora={(emisora, tipo, consulta, claveEmisora, nombreEmisora) =>
            this.onSetEmisora(emisora, tipo, consulta, claveEmisora, nombreEmisora)
          }
          setPage={(page) => this.onSetPage(page)}
          setValor={(valor) => this.onSetValor(valor)}
          setFechaInicio={(fecha) => this.onSetFechaInicio(fecha)}
          setFechaFin={(fecha) => this.onSetFechaFin(fecha)}
          preliminar={true}
        />
        <button
          className={["btn btn-link margen_top_30", classes.btn_link].join(" ")}
          type="button"
          onClick={this.onShowRssModalHandler}
        >
          <div className="texto_16 montse regular texto_naranja">
            {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
            <i
              className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(
                " "
              )}
            />
          </div>
        </button>

        <Rss
          showRssModal={this.state.showRssModal}
          modalTitle={
            getLan() === "es"
              ? "Seguimiento Solicitudes"
              : "Applications follow-up"
          }
          urlRss={this.state.urlForRss}
          rssType={0}
          showClassModel1
        />

        <div className="margen_50">
          {" "}
          <div className="margen_top_30">{showData}</div>
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </div>
      </React.Fragment>
    );

    if (
      this.state.tramiteSeleccionado &&
      this.state.detalles &&
      this.state.verDocumentos
    ) {
      contenidoMostrar = (
        <React.Fragment>
          <h1 className={"texto_42 nunito regular"}>
            <FormattedHTMLMessage
              id="empresas.listadodevalores.tramites.seguimiento.documentos"
              defaultMessage=" "
            />
          </h1>
          <div className="margen_top_30 texto_16 montse regular">
            <FormattedHTMLMessage
              id="empresas.listadodevalores.tramites.seguimiento.documentos.intro"
              defaultMessage=" "
            />
          </div>
          <br />

          <div className="margen_50">
            {" "}
            <br />
            <DocumentosSolicitudes
              tramiteSeleccionado={this.state.tramiteSeleccionado}
              datos={this.state.datos}
              detalles={this.state.detalles}
              tipo={2}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          <Back
            show={this.state.verDocumentos ? true : false}
            onBack={(e) => this.onBack(e)}
          />

          {contenidoMostrar}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitSolicitudes: (size, page, emisora_id, valor, clave, nombreEmisora) => {
      dispatch(actions.getProspectosPreliminares(size, page, emisora_id, valor, clave, nombreEmisora));
    },
    onInitSolicitudesDetalle: tramiteId => {
      dispatch(actions.initSolicitudesDetalle(tramiteId));
    },
    onFetchRssDocument: () => {
      dispatch(actions.fetchRssSeguimientosUrlDocument());
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(90));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    solicitudes: state.listadoValores.solicitudes,
    documentos: state.listadoValores.documentos,
    solicitudesDetalle: state.listadoValores.solicitudesDetalle
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Preliminares)
);

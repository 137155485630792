import React, { Component } from "react";
import classes from "./FilterReporteSustentabilidad.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";
import {
  changeDateFormat,
  getLan,
  valueLanguage,
} from "../../../stores/utils/utilities";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isSearch = null,
  options,
  defaultValue = null,
  checked = null,
  campoLabel = null,
  campoLabel2 = null,
  isClearable = false,
  isMultiSelect = null,
  type = null,
  disableMultiValueRemove = null,
  selectLabel,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      isClearable: isClearable,
      checked: checked,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    type: type,
    campoLabel: campoLabel,
    campoLabel2: campoLabel2,
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isSearch: isSearch,
    elementType: elementType,
    isMultiSelect: isMultiSelect,
    disableMultiValueRemove: disableMultiValueRemove,
    options: options,
    selectLabel: selectLabel
      ? selectLabel
      : valueLanguage(getLan(), "Seleccione una opción", "Select an option"),
  };
  return (
    <div className="form-group">
      <label className={["montse", "texto_14", "regular"].join(" ")}>
        {label}
      </label>
      <Input config={params} />
    </div>
  );
};

class FilterReporteSustentabilidad extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    esconderAutoComplete: false,
    toggleFilters: false,
    disableEnableButtons: true,
    selectedFilters: {
      ejercicio: [],
      sector: [],
      tipoEmisora: [],
      fechas: [],
      clave: [],
    },
    form_filters: {
      ejercicio: {
        value: [],
      },
      sector: {
        value: [],
      },
      tipoEmisora: {
        value: [],
      },
      fecha_inicio: {
        value: "",
      },
      fecha_fin: {
        value: "",
      },
      clave: {
        value: [],
      },
      modoListado: {
        value: [],
      },
      tipo: null,
      errorDate: false,
    },
    form_clave: {
      clave: {
        value: "",
      },
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    clave: null,
    tipoValor: [],
    filteredBySearch: false,
  };

  componentDidMount() {
    window.addEventListener("click", (e) => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    window.addEventListener("click", (e) => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.claveEmisora !== this.props.claveEmisora &&
      this.props.emisora_id !== null
    ) {
      let disableEnableButtons = true;
      let toggleFilters = false;
     
      this.setState({
        typeClaveSelected: 1,
        valueClave: this.props.claveEmisora,
        disableEnableButtons: disableEnableButtons,
        toggleFilters: toggleFilters,
        filteredBySearch: false,
      });
    }

    if (
      prevProps.biva !== this.props.biva ||
      prevProps.canceladas !== this.props.canceladas
    ) {
      this.props.setFilters({
        clave: null,
        modoListadoFilter: this.props.biva
      });
      this.onInputChangeHandler(null, false, false, false); // para borrar busqueda aproximada en cambio de tab
      this.setState({
        valueClave: this.props.claveEmisora,
        disableEnableButtons: true,
        toggleFilters: false,
        selectedFilters: {
          ejercicio: [],
          sector: [],
          tipoEmisora: [],
          fechas: [],
          clave: [],
        },
        form_filters: {
          ejercicio: {
            value: [],
          },
          sector: {
            value: [],
          },
          tipoEmisora: {
            value: [],
          },
          fecha_inicio: {
            value: "",
          },
          fecha_fin: {
            value: "",
          },
          clave: {
            value: [],
          },
          modoListado: {
            value: [],
          },
          tipo: null,
          errorDate: false,
        },
        toggleInputs:
          this.props.claveEmisora && this.props.claveEmisora !== ""
            ? true
            : false,
      });
    }
  }

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible,
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible,
    });
  }

  componentWillMount() {
    this.props.onInitEjercicios(null, this.props.biva);
    this.props.onInitSectores(null, this.props.biva);
    this.props.onInitTiposEmisora(null, this.props.biva);

    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  showContainerEmisoraSleccionadaHandler = () => {
    const clave = this.props.claveEmisora;
    return (
      <Auxs>
        <div className={classes.filter_container_emisora_seleccionada}>
          <div className={classes.filter_emisora_seleccionada}>
            <span
              className={[
                "montse color_verde_oscuro",
                classes.filter_text_model_one,
              ].join(" ")}
            >
              {clave}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i
                className="far fa-times-circle color_verde_oscuro"
                onClick={() => this.onDeleteClaveEmisoraHandler()}
              />
            </span>
          </div>
        </div>
      </Auxs>
    );
  };

  printFrameHandler = () => {
    const showContainer =
      this.props.claveEmisora && !this.state.filteredBySearch
        ? this.showContainerEmisoraSleccionadaHandler()
        : this.showInputHandler();

    const input = this.state.toggleFilters
      ? this.showFiltersSelectedHandler()
      : showContainer;
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div
              className={[
                "col",
                "col-lg-3",
                "text-center",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div
                className={[
                  "texto_btn_cerrar color_verde_oscuro",
                  classes.toggle_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_borders_filter_button_model_one
                    : null,
                ].join(" ")}
                onClick={
                  this.state.disableEnableButtons ? this.onToggleFilters : null
                }
              >
                <button
                  type="button"
                  className="btn"
                  disabled={!this.state.disableEnableButtons}
                >
                  <i className="fas fa-filter color_verde_oscuro" />
                  <span className="color_verde_oscuro">
                    {this.state.toggleFilters
                      ? valueLanguage(getLan(), "Cerrar Filtros", "Close")
                      : valueLanguage(getLan(), "Filtrar", "Filter")}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={[
                "col",
                this.state.toggleFilters ? "col-lg-9" : "col-lg-8",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div className={classes.filters_selected_container_model_one}>
                {input}
              </div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col",
                "col-lg-1",
                "text-center",
                "color_verde_oscuro",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_border_search_button_model_one
                    : null,
                ].join(" ")}
              >
                <button type="buttom" className={["btn"].join(" ")} disabled>
                  <i className="fas fa-search color_verde_oscuro" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={[
              "row",
              classes.main_filter_container_model_one,
              !this.state.toggleFilters ? classes.hidden : null,
            ].join(" ")}
          >
            <div
              className={["col", classes.no_col_padding_model_one].join(" ")}
            >
              {this.printModel()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  printModel = () => {
    const opcionesSector = this.props.sectores ? this.props.sectores : [];

    const opcionesTipoEmisora = this.props.tiposEmisora
      ? this.props.tiposEmisora
      : [];

    const opcionesEjercicios = this.props.ejercicios
      ? this.onPrepareOptionHandler(this.props.ejercicios)
      : [];

    const valueOptionsEjercicios = this.state.form_filters.ejercicio.value.map(
      (itm) => {
        return opcionesEjercicios.find((op) => op.id === itm.id);
      }
    );

    const valueOpcionesSector = this.state.form_filters.sector.value.map(
      (itm) => {
        return opcionesSector.find((op) => op.id === itm.id);
      }
    );

    const valueTipoEmisora = this.state.form_filters.tipoEmisora.value.map(
      (itm) => {
        return opcionesTipoEmisora.find((op) => op.id === itm.id);
      }
    );
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              modoListado: this.props.biva,
              ejercicio: valueOptionsEjercicios,
              sector: valueOpcionesSector,
              tipoEmisora: valueTipoEmisora,
              fecha_inicio: this.state.form_filters.fecha_inicio.value,
              fecha_fin: this.state.form_filters.fecha_fin.value,
            }}
            render={({
              optionsEjercicios = opcionesEjercicios,
              optionsTiposEmisora = opcionesTipoEmisora,
              optionsSectores = opcionesSector,
              form,
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                <div className="row">
                  <div className="col col-lg-4">
                    <Field
                      name="sector"
                      id="sector"
                      elementType="select"
                      type="select"
                      label={
                        getLan() === "es"
                          ? "Clasificación sectorial AMAFORE"
                          : "AMAFORE sectoral classification"
                      }
                      isMultiSelect={true}
                      disableMultiValueRemove={true}
                      isClearable={true}
                      options={optionsSectores}
                      classes="custom-select montse texto_14 regular color_gris"
                      campoLabel={"nombre"}
                      onInputChange={(event) => this.onEventMultiple(event, 2)}
                      component={renderInput}
                    />
                  </div>

                  <div className="col col-lg-4">
                    <Field
                      name="ejercicio"
                      id="ejercicio"
                      elementType="select"
                      type="select"
                      label={getLan() === "es" ? "Ejercicio" : "Year"}
                      isMultiSelect={true}
                      disableMultiValueRemove={true}
                      isClearable={true}
                      options={optionsEjercicios}
                      classes="custom-select montse texto_14 regular color_gris"
                      campoLabel={"nombre"}
                      onInputChange={(event) => this.onEventMultiple(event, 0)}
                      component={renderInput}
                    />
                  </div>

                  <div className="col col-lg-4">
                    <Field
                      name="tipoEmisora"
                      id="tipoEmisora"
                      elementType="select"
                      type="select"
                      label={
                        getLan() === "es" ? "Tipo de emisora" : "Issuer Type"
                      }
                      isMultiSelect={true}
                      disableMultiValueRemove={true}
                      isClearable={true}
                      options={optionsTiposEmisora}
                      classes="custom-select montse texto_14 regular color_gris"
                      campoLabel={"nombre"}
                      onInputChange={(event) => this.onEventMultiple(event, 1)}
                      component={renderInput}
                    />
                  </div>
                </div>

                {/* ======================= */}

                <div className="row">
                  <div className={["col", "col-lg-12 sin_padding"].join(" ")}>
                    <div className="row">
                      <div className="col col-lg-4 offset-lg-4">
                        <Field
                          name="fecha_inicio"
                          id="fecha_inicio"
                          type="date"
                          classes={classes.fechaInicio}
                          disabled={
                            this.state.form_filters.fecha_inicio.disabled
                          }
                          label={getLan() === "es" ? "Fecha inicio" : "From"}
                          onInputChange={(event) =>
                            this.onInputSelectedHandler(event, 3)
                          }
                          component={renderInput}
                        />
                      </div>
                      <div className="col col-lg-4">
                        <Field
                          name="fecha_fin"
                          id="fecha_fin"
                          type="date"
                          classes={classes.fechaFin}
                          label={getLan() === "es" ? "Fecha fin" : "To"}
                          disabled={this.state.form_filters.fecha_fin.disabled}
                          onInputChange={(event) =>
                            this.onInputSelectedHandler(event, 4)
                          }
                          component={renderInput}
                        />
                      </div>
                    </div>
                    <div
                      className={[
                        classes.errorContainer,
                        !this.state.form_filters.errorDate
                          ? classes.hidden
                          : null,
                      ].join(" ")}
                    >
                      <p className={"texto_14 montse regular"}>
                        {getLan() === "es"
                          ? "La fecha final debe ser mayor a la fecha inicial."
                          : "End date must be greater than start date."}
                      </p>
                    </div>
                  </div>
                </div>

                <hr />
                {this.sectionButtonHandler(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonHandler = (form) => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center color_verde_oscuro">
          <button
            type="button"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14",
            ].join(" ")}
            onClick={() => this.onResetFilterHandler(form)}
          >
            <i className="fas fa-redo-alt color_verde_oscuro" />
            {getLan() === "es" ? "Limpiar" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onPrepareOptionHandler = (type) => {
    let options = [];
    if (type && type.length > 0) {
      type.forEach((element) => {
        let id = null;
        let nombre = null;
        id = element;
        nombre = element;
        options.push({
          id: id,
          nombre: nombre,
        });
      });
    }
    return options;
  };

  onSubmitFilterHandler = (event) => {
    event.preventDefault();
  };

  onSubmitSearchModelOneHandler = (event) => {
    event.preventDefault();
  };

  printChange = (e) => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = (value) => {
    this.onInputChangeHandler(value);
  };

  onInputChangeHandler = (
    event = null,
    eraser = null,
    tipo = null,
    borraClaveFiltro = true
  ) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = true;
    let filteredBySearch = false;
    if (value !== "") {
      this.props.onFilterEmisorasByClave(value, this.props.biva);
      filteredBySearch = true;
    } else {
      disabled = true;
    }

    if (tipo) {
      const params = {
        clave: null,
        reset: false,
      };
      this.props.setFilters(params);
    }

    if (eraser) {
      this.setState((prevState) => {
        return {
          toggleInputs: !prevState.toggleInputs,
          emisoraSeleccionada: null,
          claveSeleccionada: null,
          form_clave: form_clave,
          disableEnableButtons: true,
          filteredBySearch: filteredBySearch,
        };
      });
    } else {
      this.setState({
        form_clave: form_clave,
        disableEnableButtons: disabled,
        filteredBySearch: filteredBySearch,
      });
    }
  };

  onDeleteClaveEmisoraHandler = () => {
    this.props.onDeleteEmisoraSelecionada();
    const form_clave = this.state.form_clave;
    form_clave.clave.value = null;

    const [
      valuesEjercicios,
      valuesSectores,
      valuesTiposEmisora,
      // valuesFechaInicio,
      // valuesFechaFin
    ] = this.onGetAllValuesFormFilter();

    let paramsForParent = {
      ejercicio: valuesEjercicios,
      sector: valuesSectores,
      tipoEmisora: valuesTiposEmisora,
      modoListadoFilter: this.props.biva,
      page: 1,
      reset: false
    };

    this.props.setFilters(paramsForParent);

    this.setState({
      valueClave: null,
      claveEmisora: null,
      toggleInputs: false,
      disableEnableButtons: true,
      form_clave: form_clave,
    });
  };

  limpiaForms() {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    form.ejercicio.value = [];
    form.sector.value = [];
    form.tipoEmisora.value = [];
    form.fecha_inicio.value = "";
    form.fecha_fin.value = "";
    form.modoListado.value = this.props.biva;
    selectedFilters.ejercicio = [];
    selectedFilters.sector = [];
    selectedFilters.tipoEmisora = [];
    selectedFilters.fechas = [];

    const paramsReset = {
      clave: this.state.valueClave,
      ejercicio: [],
      sector: [],
      tipoEmisora: [],
      fechaInicio: null,
      fechaFin: null,
      modoListadoFilter: this.props.biva,
      reset: true,
    };
    this.props.setFilters(paramsReset);

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });
  }

  onResetFilterHandler = (form) => {
    this.limpiaForms();
  };

  onToggleFilters = () => {
    const selectedFilters = this.state.selectedFilters;
    const form_filters = this.state.form_filters;
    const form_clave = this.state.form_clave;

    if (form_clave.clave.value) {
        selectedFilters.clave = [{ label: this.state.valueClave, value:  this.state.valueClave, type: 6 }]
        form_filters.clave.value = [{ id: this.state.valueClave, nombre:  this.state.valueClave }]
    } else {
        selectedFilters.clave = [];
        form_filters.clave.value = [];
        form_filters.modoListado.value = this.props.biva
        form_clave.clave.value = null;
        this.setState({
              valueClave: null,
              form_clave: form_clave,
              selectedFilters: selectedFilters,
              form_filters: form_filters,
          });
    }
    this.setState((prevState) => {
      return {
        form_clave: form_clave,
        selectedFilters: selectedFilters,
        form_filters: form_filters,
        toggleFilters: !prevState.toggleFilters,
      };
    });
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type) => {
    let newValue = null;
    let newLabel = null;
    let newName = null;
    if (value !== null) {
      if (type !== 3 && type !== 4) {
        newLabel = value.nombre ? value.nombre : null;
        newValue = value.id ? value.id : null;
      } else {
        newValue = value;
        newLabel = type === 3 ? "fecha_inicio" : "fecha_fin";
      }
    }
    return [newValue, newLabel, newName];
  };

  // Retornas los sectores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = (type, value) => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["ejercicio", form.ejercicio.value, selectedFilters.ejercicio];
      case 2:
        return ["sector", form.sector.value, selectedFilters.sector];
      case 1:
        return [
          "tipoEmisora",
          form.tipoEmisora.value,
          selectedFilters.tipoEmisora,
        ];
      case 3:
        return [
          "fecha_inicio",
          form.fecha_inicio.value,
          selectedFilters.fechas,
          "fechas",
        ];
      case 4:
        return [
          "fecha_fin",
          form.fecha_fin.value,
          selectedFilters.fechas,
          "fechas",
        ];
      default:
        return [
          "clave",
          form.clave.value,
          selectedFilters.clave
        ];
    }
  };

  // Retorna todos los sectores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesEjercicios =
      form.ejercicio.value && form.ejercicio.value !== ""
        ? form.ejercicio.value
        : "";

    // Se obtiene el valor de sector
    const valuesSectores =
      form.sector.value && form.sector.value !== "" ? form.sector.value : "";

    // Se obtiene el valor de sector
    const valuesTiposEmisora =
      form.tipoEmisora.value && form.tipoEmisora.value !== ""
        ? form.tipoEmisora.value
        : "";

    const valuesFechaInicio =
      form.fecha_inicio.value && form.fecha_inicio.value !== ""
        ? form.fecha_inicio.value
        : null;
    // Se obtiene el valor de fecha fin
    const valuesFechaFin =
      form.fecha_fin.value && form.fecha_fin.value !== ""
        ? form.fecha_fin.value
        : null;

   const valueClave =
      form.clave.value && form.clave.value.length > 0
        ? form.clave.value[0].id
        : null;

    const valueModoListado = this.props.biva;

    return [
      valuesEjercicios,
      valuesSectores,
      valuesTiposEmisora,
      valuesFechaInicio,
      valuesFechaFin,
      valueClave,
      valueModoListado
    ];
  };

  // Agrega o elimina sectores de estado para un campo del formulario
  onChangeStateHandler = (values = null, type = null, item = null) => {
    let newStateValues =  null;

    if (values !== "") {
      newStateValues = values;
    }
    if (type < 3) {
        let form = this.state.form_filters;
        switch (type) {
            case 0:
                newStateValues = form.ejercicio.value.filter(
                    (option) => option.id !== item.value
                  );
                break;
            case 1:
                newStateValues = form.tipoEmisora.value.filter(
                (option) => option.id !== item.value
                  );
                break;
            case 6:
                //clave
                newStateValues = [];
                const form_clave = this.state.form_clave;
                form_clave.clave.value = null;
                this.setState({
                    valueClave: null,
                    form_clave: form_clave,
                    claveEmisora: null,
                })
                break;
            default:
                newStateValues = form.sector.value.filter(
                (option) => option.id !== item.value
                  );
                break;
        }
        
    }
    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (
    filterOptions = null,
    values = null,
    label = null,
    type = null,
    id = null
  ) => {
    let newFilterValues = [];
    if (type === 3 || type === 4) {
      // Dates
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(
        (option) => option.label === label
      );
      if (filterExists) {
        newFilterValues = newFilterValues.filter(
          (option) => option.label !== label
        );
        if (values !== "") {
          newFilterValues.push({
            label: label,
            value: changeDateFormat(values),
            type: type,
          });
        }
      } else {
        newFilterValues.push({
          label: label,
          value: changeDateFormat(values),
          type: type,
        });
      }
    } else {
        newFilterValues = [...filterOptions];
        const filterExists = newFilterValues.find(
          (option) => option.value === id
        );
        if (filterExists) {
          newFilterValues = newFilterValues.filter(
            (option) => option.value !== id
          );
          if (values !== "") {
            newFilterValues.push({
              label: label,
              value: id,
              type: type,
            });
          }
        } 
    }
    return newFilterValues;
  };

  onFilterDataByEvent = () => {
    // Se obtienen todos los sectores de los campos del formulario (Filtro)
    const [
      valuesEjercicios,
      valuesSectores,
      valuesTiposEmisora,
      valuesFechaInicio,
      valuesFechaFin,
      valueClave,
      valueModoListado
    ] = this.onGetAllValuesFormFilter();

    if (!this.state.form_filters.errorDate) {
      const paramsFilter = {
        clave: valueClave,
        ejercicio: valuesEjercicios,
        sector: valuesSectores,
        tipoEmisora: valuesTiposEmisora,
        fechaInicio: valuesFechaInicio,
        fechaFin: valuesFechaFin,
        modoListadoFilter: valueModoListado
      };
      this.props.setFilters(paramsFilter);
    }
  };

  onEventMultiple(event_ = null, type = null) {
    var last = event_ ? Object.keys(event_).pop() : null;
    // let event = { target: { value: last ? event_[last].id : "" } };
    let event = last ? event_[last] : null;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    const valueFilters = event
      ? { label: event.nombre, value: event.id, type: type }
      : [];
    const value = event ? { id: event.id, nombre: event.nombre } : [];

    switch (type) {
      case 0:
        if (event) {
          const ejercicioExists = selectedFilters.ejercicio.find(
            (option) => option === valueFilters
          );
          if (!ejercicioExists) {
            selectedFilters.ejercicio.push(valueFilters);
          }
          form.ejercicio.value.push(value);
        } else {
          selectedFilters.ejercicio = [];
          form.ejercicio.value = [];
        }

        break;

      case 1:
        if (event) {
          const tipoEmisoraExist = selectedFilters.tipoEmisora.find(
            (option) => option === valueFilters
          );
          if (!tipoEmisoraExist) {
            selectedFilters.tipoEmisora.push(valueFilters);
          }
          form.tipoEmisora.value.push(value);
        } else {
          selectedFilters.tipoEmisora = [];
          form.tipoEmisora.value = [];
        }
        break;

      default:
        if (event) {
          const sectorExist = selectedFilters.sector.find(
            (option) => option === valueFilters
          );
          if (!sectorExist) {
            selectedFilters.sector.push(valueFilters);
          }
          form.sector.value.push(value);
        } else {
          selectedFilters.sector = [];
          form.sector.value = [];
        }

        break;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });

    this.onFilterDataByEvent();
  }

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (event, type = null) => {
    const actualValue = type !== 3 && type !== 4 ? event : event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let errorDate = null;
    let newValue = null;
    let newLabel = null;

    form['modoListado'] = this.props.biva;
    // Solo para los campos distintos a tipo instrumento y tipo valor
    [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(
      actualValue,
      type
    );

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, , filterValues, otherInputName] =
      this.onGetNameStateFilterByInputHandler(type, actualValue);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(newValue, type);
    newFilterValues = this.onChangeFilterHandler(
      filterValues,
      newValue,
      newLabel,
      type
    );

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] =
      newFilterValues;

    if (type === 3) {
      // fecha inicio
      // Se verifican si las fechas osn validas
      if (newValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          newValue,
          form.fecha_fin.value
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 4) {
      // Fecha fin
      // Se verifican si la sfechas osn validas
      if (newValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          form.fecha_inicio.value,
          newValue
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });

    this.onFilterDataByEvent();
  };

  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  onDeleteFilterHandler = (item) => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let [inputName, , filterValues, otherInputName] =
      this.onGetNameStateFilterByInputHandler(item.type, item.id);

    newStateValues = this.onChangeStateHandler("", item.type, item);
    newFilterValues = this.onChangeFilterHandler(
      filterValues,
      "",
      item.label,
      item.type,
      item.value
    ); 

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] =
      newFilterValues;
    if (inputName === "clave") {
        const form_clave = this.state.form_clave;
        form_clave.clave.value = null;
        this.setState({
            valueClave: null,
            form_clave: form_clave,
            claveEmisora: null,
            toggleInputs: false
        })
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    const selectedFilters = this.state.selectedFilters;
    const filterList = [
      ...selectedFilters.ejercicio,
      ...selectedFilters.sector,
      ...selectedFilters.tipoEmisora,
      ...selectedFilters.fechas,
    ];
    let filters = null;
    filters = (
      <div
        className={classes.filter_container_outside_model_one}
        id="allFitersContainers"
      >
        {filterList.map((item, i) => (
          <div className={classes.filter_container_model_one} key={`${item}`}>
            <span
              className={[
                "montse color_verde_oscuro",
                classes.filter_text_model_one,
              ].join(" ")}
            >
              {item.type !== 3 && item.type !== 4 ? item.label : item.value}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i
                className="far fa-times-circle color_verde_oscuro"
                onClick={() => this.onDeleteFilterHandler(item)}
              />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.props.setEmisora(null, 4, true);
      this.props.onInitSectores(null, this.props.tipoBono);

      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null,
      });
    }
    this.setState((prevState) => {
      return {
        toggleInputs: !prevState.toggleInputs,
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map((item, i) => (
      <li
        key={item}
        className="list-group-item"
        onClick={() => this.onClaveSelectedHandler(item, item, 1)}
      >
        {item}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let claveFilter = clave;
    const [
      valuesEjercicios,
      valuesSectores,
      valuesTiposEmisora,
      valuesFechaInicio,
      valuesFechaFin,
      valueModoListado
    ] = this.onGetAllValuesFormFilter();
    if (option === 0 || (clave !== null && id === null)) {
      const params = {
        claveFilter: claveFilter,
        page: 1,
        reset: false,
        ejercicio: valuesEjercicios,
        sector: valuesSectores,
        tipoEmisora: valuesTiposEmisora,
        fechaInicio: valuesFechaInicio,
        fechaFin: valuesFechaFin,
        modoListadoFilter: valueModoListado
      };
      this.props.setFilters(params);
    } else {
      const params = {
        clave: claveFilter,
        page: 1,
        reset: false,
        ejercicio: valuesEjercicios,
        sector: valuesSectores,
        tipoEmisora: valuesTiposEmisora,
        fechaInicio: valuesFechaInicio,
        fechaFin: valuesFechaFin,
        modoListadoFilter: valueModoListado
      };

      this.props.setFilters(params);
    }

    this.setState({ typeClaveSelected: option, valueClave: clave });
  };

  onPrintOptionSelectedHandler = (value) => {
    return (
      <div
        className={[
          classes.selectedOptionContainer,
          !this.state.toggleInputs ? classes.hidden : null,
        ].join(" ")}
      >
        <span className={classes.filterContainer}>
          <span
            className={[
              classes.filterText,
              "montse texto_14 regular color_verde_oscuro",
            ].join(" ")}
          >
            {this.state.typeClaveSelected === 0
              ? valueLanguage(
                  getLan(),
                  `Ver todos los resultados para: ${this.state.valueClave}`,
                  `See all results for: ${this.state.valueClave}`
                )
              : `${this.state.valueClave}`}
          </span>
          <span
            className={classes.filterIconButton}
            onClick={() => this.onInputChangeHandler(null, true, true)}
          >
            <i className="far fa-times-circle color_verde_oscuro" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.listadoClaves
      ? this.onPrintAutoCompleteHandler()
      : null;

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {getLan() === "es"
            ? `Ver todos los resultados para: ${value}`
            : `See all results for: ${value}`}
        </li>
      ) : (
        <li className="list-group-item">
          {getLan() === "es"
            ? "No existen resultados para la búsqueda:"
            : "There are no results for the search:"}{" "}
          {value}
        </li>
      );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div
          id="searchInput"
          className={[
            classes.search_input_container_model_one,
            this.state.toggleInputs ? classes.hidden : null,
          ].join(" ")}
        >
          <Form
            onSubmit={this.onSubmitSearchModelOneHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value,
            }}
            render={() => (
              <form
                ref={this.formSearch}
                onSubmit={this.onSubmitSearchModelOneHandler}
              >
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  placeholder={
                    getLan() === "es"
                      ? "Buscar por clave de cotización"
                      : "Search by ticker"
                  }
                  component={renderInput}
                  className={"color_verde_oscuro"}
                  onInputChange={(event) => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              !this.state.form_clave.clave.value ||
              this.state.form_clave.clave.value === ""
                ? classes.hidden
                : null,
              this.state.esconderAutoComplete ? classes.hidden : null,
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    return this.printFrameHandler();
  }
}

const mapStateToProps = (state) => {
  return {
    ejercicios: state.bivaSustentable.ejercicios,
    tiposEmisora: state.bivaSustentable.tiposEmisora,
    sectores: state.bivaSustentable.sectores,
    listadoClaves: state.bivaSustentable.listadoClaves,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitEjercicios: (mercado = null, modoListado = this.props.biva) => {
      dispatch(actions.filterEjerciciosAmafore(mercado, modoListado));
    },
    onInitSectores: (pais = null, modoListado = this.props.biva) => {
      dispatch(actions.filterSectoresAmafore(pais, modoListado));
    },
    onInitTiposEmisora: (mercado = null, modoListado = this.props.biva) => {
      dispatch(actions.filterTiposEmisoraAmafore(mercado, modoListado));
    },
    onFilterEmisorasByClave: (clave, modoListado = this.props.biva) => {
      dispatch(actions.filterCuestionariAmaforeByClave(clave, modoListado));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterReporteSustentabilidad)
);

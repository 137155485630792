import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const getIdiomaIntl = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_INTL_SUCCESS,
      diccionario: []
    };
  }
  return {
    type: actionTypes.FETCH_INTL_SUCCESS,
    diccionario: data
  };
};

export const initIntl = (pagina) => {
  let url = `${dominio}/cms/o/text/page/${pagina}/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getIdiomaIntl(response.data));
      })
      .catch(err => dispatch(getIdiomaIntl(err.data)));
  };
};


// ==========================

const getIdiomaIntlHome = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_INTL_SUCCESS,
      diccionarioHome: []
    };
  }
  return {
    type: actionTypes.FETCH_INTL_SUCCESS,
    diccionarioHome: data
  };
};

export const initIntlHome = (pagina) => {
  let url = `${dominio}/cms/o/text/page/${pagina}/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getIdiomaIntlHome(response.data));
      })
      .catch(err => dispatch(getIdiomaIntlHome(err.data)));
  };
};
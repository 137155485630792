import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import classes from "./ConcentradoPreciosHistorico.css";

import FilterConcentradoPreciosFondoHistorico from "../../../components/ui/filterConcentradoPreciosFondo/FilterConcentradoPreciosFondoHistorico";
import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
/* import { withRouter } from "react-router-dom"; */
import Table from "../../../components/empresas/Table";
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import ConcentradoPreciosModal from "./ConcentradoPreciosModal";
const dominio = `${window.location.protocol}//${window.location.host}`;
let base_url = `${dominio}/emisoras/fondos/precios/descargar?1=1`;

class ConcentradoPreciosHistorico extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    razonSocial: null,
    clave: null,
    numeroTramite: null,
    datos: null,
    emisoraSeleccionada: null,
    tipoValorSeleccionado: null,
    fecha_inicio: null,
    fecha_fin: null,
    serie: null,
    page: 0,
    detalles: [],
    urlForRss: `${dominio}/emisoras/tramites/rss`,
    tipoDocumento: null,
    pageDocumento: null,
    onShowGraphicModal: false,
    verDocumentos: false,
    claveAfore: false,
    claveSiefore: false,
    tipoSiefore: false,
    clasificacionSiefore: false,
    wasFiltered: false,
    messages: null,
    lan: getLocal(),
    paramsForm:null
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.fondos !== prevProps.fondos) {
      this.setState({
        loader: false,
      });
    }

    if (this.props.serie !== prevProps.serie) {
      this.setState({
        serie: this.props.serie,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let fondos = null;
    let detalles = null;

    if (nextProps.fondos && nextProps.fondos.content) {
      fondos = nextProps.fondos.content;
    }

    if (nextProps.concentradoPrecios !== this.props.concentradoPrecios) {
      if (nextProps.concentradoPrecios) {
        detalles = nextProps.concentradoPrecios;
      }
    } else {
      detalles = this.props.concentradoPrecios;
    }

    this.setState({
      detalles: detalles,
    });

    let resource = fondos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (
    resource = null,
    pageNumber = null,
    seleccionado = null
  ) => {
    let data = resource ? resource : this.props.fondos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado,
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        loader: true,
        resourceList: resourceList,
      });
    } else {
      this.setState({
        resourceList: resourceList,
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.changeHistory(
        this.state.claveOperadora,
        this.state.claveFondo,
        this.state.tipoFondo,
        this.state.clasificacionFondo,
        this.state.serie,
        this.state.fecha_inicio,
        this.state.fecha_fin,
        pageNumber
      );

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let claveOperadora = null;
    let claveFondo = null;
    let tipoFondo = null;
    let clasificacionFondo = null;
    let page = null;
    let fechaInicio = null;
    let fechaFin = null;
    let serie = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      claveOperadora =
        paramsString["claveOperadora"] === "null"
          ? null
          : paramsString["claveOperadora"];
      claveFondo =
        paramsString["claveFondo"] === "null"
          ? null
          : paramsString["claveFondo"];
      tipoFondo =
        paramsString["tipoFondo"] !== undefined &&
        paramsString["tipoFondo"] !== "null"
          ? paramsString["tipoFondo"]
          : null;
      clasificacionFondo =
        paramsString["clasificacionFondo"] !== undefined &&
        paramsString["clasificacionFondo"] !== "null"
          ? paramsString["clasificacionFondo"]
          : null;
      page =
        paramsString["page"] !== undefined && paramsString["page"] !== "null"
          ? parseInt(paramsString["page"])
          : 1;
      serie =
      paramsString["serie"] === "" || paramsString["serie"] === "null" || paramsString["serie"] === undefined
          ? []
          : paramsString["serie"].split(",");
      fechaInicio =
        paramsString["fechaInicio"] !== undefined &&
        paramsString["fechaInicio"] !== "null"
          ? paramsString["fechaInicio"]
          : null;
      fechaFin =
        paramsString["fechaFin"] !== undefined &&
        paramsString["fechaFin"] !== "null"
          ? paramsString["fechaFin"]
          : null;
    }
    if (claveFondo !== null) {
      this.setState({
        tramiteDetalle: claveFondo,
      });
      /*       this.props.onInitConcentradoPrecios(claveFondo);
                  this.updateGrandparent(claveFondo); */

      /*       this.updateGrandparentListado(
                    tramiteId,
                    razonSocial,
                    clave,
                    numeroTramite,
                    tramiteId,
                    tipoDocumento,
                    numEnvio,
                    page,
                    false
                  ); */
    }
    this.props.onInitFondos(
      this.state.itemsPerPage,
      parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      claveOperadora,
      claveFondo,
      tipoFondo,
      clasificacionFondo,
      serie,
      fechaInicio,
      fechaFin
    );

    let paramsForm = {
      claveOperadora:claveOperadora,
      claveFondo:claveFondo,
      tipoFondo:tipoFondo,
      clasificacionFondo:clasificacionFondo,
      serie:serie,
      fechaInicio:fechaInicio,
      fechaFin:fechaFin
  }

    this.setState({
      paramsForm: paramsForm,
      claveOperadora: claveOperadora,
      claveFondo: claveFondo,
      tipoFondo: tipoFondo,
      clasificacionFondo: clasificacionFondo,
      fecha_inicio: fechaInicio,
      fecha_fin: fechaFin,
      serie: serie,
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
    });
  }



  updateGrandparent = (value) => {
    this.props.onInitConcentradoPrecios(value);
    this.setState({
      tramiteDetalle: value,
      detalles: [],
    });
  };

  onSetPage = (page) => {
    this.setState({
      activePage: page,
    });
  };

  onSetValor = (valor) => {
    this.setState({
      tipoValorSeleccionado: valor,
    });
  };

  onSetFechaInicio = (fecha) => {
    this.setState({
      fecha_inicio: fecha,
    });
  };

  onSetFechaFin = (fecha) => {
    this.setState({
      fecha_fin: fecha,
    });
  };

  onSetTipoDocumento = (tipoDocumento, consulta) => {
    this.setState({
      tipoDocumento: tipoDocumento,
    });
  };

  onSetPageDocumento = (page) => {
    this.setState({
      pageDocumento: page,
    });
  };

  onSetEmisora = (emisora, tipo, consulta = false) => {
    let emisoraSeleccionada = null;
    let clave = null;

    if (tipo === 1) {
      emisoraSeleccionada = emisora;
    }
    if (tipo === 2) {
      clave = emisora;
    }

    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      clave: clave,
    });
    if (consulta) {
      this.props.onInitFondos(
        this.state.itemsPerPage,
        0
        /* emisoraSeleccionada,
                this.state.tipoValorSeleccionado,
                this.state.fecha_inicio,
                this.state.fecha_fin,
                clave */
      );
    }
  };
  onSetClaveOperadoraHandler = (claveOperadora) => {
    this.setState({
      claveOperadora: claveOperadora,
    });
  };

  onSetClaveFondoHandler = (claveFondo) => {
    this.setState({
      claveFondo: claveFondo,
    });
  };

  onSetTipoFondohandler = (tipoFondo) => {
    this.setState({
      tipoFondo: tipoFondo,
    });
  };

  onSetClasificacionFondo = (clasificacionFondo) => {
    this.setState({
      clasificacionFondo: clasificacionFondo,
    });
  };

  onSetSerie = (serie) => {
    this.setState({
      serie: serie,
    });
  };

  onSetFechaInicio = (fechaInicio) => {
    this.setState({
      fecha_inicio: fechaInicio,
    });
  };

  onSetFechaFin = (fechaFin) => {
    this.setState({
      fecha_fin: fechaFin,
    });
  };

  onSetWasFilteredHandler = (wasFiltered) => {
    this.setState({
      wasFiltered: wasFiltered,
      loader: true,
    });
  };

  onShowGraphic = () => {
    this.setState((prevState) => {
      return {
        onShowGraphicModal: !prevState.onShowGraphicModal,
      };
    });
  };

  onSetFilters = ({
    claveOperadora = null,
    claveFondo = null,
    tipoFondo = null,
    clasificacionFondo = null,
    serie = null,
    fechaInicio = null,
    fechaFin = null,
    wasFiltered = null,
    page = null,
    reset = false,
  }) => {
    this.setState({
      claveOperadora: claveOperadora ? claveOperadora : null,
      claveFondo: claveFondo ? claveFondo : null,
      tipoFondo: tipoFondo ? tipoFondo : null,
      clasificacionFondo: clasificacionFondo ? clasificacionFondo : null,
      serie: serie ? serie : null,
      fechaInicio: fechaInicio ? fechaInicio : null,
      fechaFin: fechaFin ? fechaFin : null,
      wasFiltered: wasFiltered,
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(
        claveOperadora,
        claveFondo,
        tipoFondo,
        clasificacionFondo,
        serie,
        fechaInicio,
        fechaFin,
        page
      );
    }
  };

  changeHistory(
    claveOperadora = null,
    claveFondo = null,
    tipoFondo = null,
    clasificacionFondo = null,
    serie = null,
    fechaInicio = null,
    fechaFin = null,
    page = null
  ) {
    this.props.history.push(
      `?claveOperadora=${claveOperadora}&claveFondo=${claveFondo}&tipoFondo=${tipoFondo}&clasificacionFondo=${clasificacionFondo}&serie=${serie}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&page=${
        page ? page : this.state.activePage
      }`
    );

    this.props.onInitFondos(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      claveOperadora,
      claveFondo,
      tipoFondo,
      clasificacionFondo,
      serie,
      fechaInicio,
      fechaFin
    );
    let paramsForm = {
        claveOperadora:claveOperadora,
        claveFondo:claveFondo,
        tipoFondo:tipoFondo,
        clasificacionFondo:clasificacionFondo,
        serie:serie,
        fechaInicio:fechaInicio,
        fechaFin:fechaFin
    }
    this.setState({
        paramsForm: paramsForm
    })
  }

  changeHistoryReset() {
    this.props.history.push(
      `?claveOperadora=${null}&claveFondo=${null}&tipoFondo=${null}&clasificacionFondo=${null}&serie=${null}&fechaInicio=${null}&fechaFin=${null}&page=1`
    );
    this.props.onInitFondos(
      this.state.itemsPerPage,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    let paramsForm = {
        claveOperadora:null,
        claveFondo:null,
        tipoFondo:null,
        clasificacionFondo:null,
        serie:null,
        fechaInicio:null,
        fechaFin:null
    }
    this.setState({
        paramsForm: paramsForm
    })
  }

  render() {
    let loader = null;
    let descarga_url = base_url;

    descarga_url =
      this.state.serie && this.state.serie.length > 0
        ? `${descarga_url}&serie=${this.state.serie}`
        : descarga_url;
    descarga_url = this.state.claveOperadora
      ? `${descarga_url}&claveEmisora=${this.state.claveOperadora}`
      : descarga_url;
    descarga_url = this.state.claveFondo
      ? `${descarga_url}&clave=${this.state.claveFondo}`
      : descarga_url;
    descarga_url = this.state.tipoFondo
      ? `${descarga_url}&tipo=${this.state.tipoFondo}`
      : descarga_url;
    descarga_url = this.state.clasificacionFondo
      ? `${descarga_url}&clasificacion=${this.state.clasificacionFondo}`
      : descarga_url;
    descarga_url = this.state.fecha_inicio
      ? `${descarga_url}&fechaInicio=${this.state.fecha_inicio}`
      : descarga_url;
    descarga_url = this.state.fecha_fin
      ? `${descarga_url}&fechaFin=${this.state.fecha_fin}`
      : descarga_url;

    const resultadoDescarga = (
      <div className={"row margen_30"}>
        <div className={[classes.card_header, "sin_padding col-12 col-md-3 col-lg-6 col-xl-7  d-none d-sm-none d-md-flex"].join(" ")}>
          <span className={["regular montse texto_16 "].join(" ")} />
        </div>
        <div className={[classes.card_header2, "sin_padding col-12 col-md-9 col-lg-6 col-xl-5"].join(" ")}>
          <div className={["sin_padding col-12 texto_16 montse regular"].join(" ")}>
            <a
              href={descarga_url}
              target="_self"
              rel="noopener noreferrer"
              className="row"
            >
              <span
                className={[
                  classes.titulo,
                  "texto_16 montse color_azul regular col-12",
                ].join(" ")}
              >
                {getLan() === "es"
                  ? "Listado histórico de precios por fondo y serie"
                  : "Historical fund prices listing"}
                <i
                  className={[
                    "fas fa-arrow-alt-circle-down fa-lg",
                    "Boton__icono_derecho__25mg6",
                  ].join(" ")}
                />
              </span>
              <span
                className={[
                  classes.titulo_ZIP,
                  "semi_bold montse texto_10 col-12",
                ].join(" ")}
              >
                {getLan() === "es" ? "Formato XLS" : "XLS file"}
              </span>
            </a>
          </div>
        </div>
      </div>
    );

    if (this.state.loader) {
      loader = (
        <Loader
          loaderDisable={(disable) => this.setState({ loader: disable })}
        />
      );
    }
    let fondos = null;
    if (this.props.fondos && this.props.fondos.content) {
      fondos = this.props.fondos.content;
    }
    const numRows = this.props.fondos ? this.props.fondos.totalElements : 0;

    let btnGraficar = null;
    if (this.state.paramsForm && this.state.paramsForm.claveFondo) {
      btnGraficar = ( <button
      className={["btn btn-link margen_50", classes.btn_link].join(" ")}
      type="button"
      onClick={this.onShowGraphic}
    >
      <div className="margen_50 texto_16 montse regular texto_naranja">
        {getLan() === "es" ? "Graficar Histórico" : "Historical graph"}
        &nbsp;
        <i
          className={[
            "fas fa-chart-line",
            "Boton__icono_derecho__25mg6",
          ].join(" ")}
        />
      </div>
    </button>)
    }

    let dataShow = null;
    dataShow =
      this.props.fondos && this.props.fondos.length === undefined ? (
        <React.Fragment>
         {btnGraficar}
          {resultadoDescarga}
          <br />
          <Table tipo={4} datos={this.props.fondos} />
        </React.Fragment>
      ) : (
        <p className="montse texto_16 regular">
          {getLan() === "es"
            ? "No existen resultados para la búsqueda."
            : "No results found."}
        </p>
      );

    let contenidoMostrar = (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>
          <FormattedHTMLMessage
            id="empresas.fondos.concentrado.historico.titulo"
            defaultMessage=" "
          />
        </h1>
        <div className="margen_top_30 texto_16 montse regular">
          <FormattedHTMLMessage
            id="empresas.fondos.concentrado.historico.descripcion"
            defaultMessage=" "
          />
        </div>
        <br />
        <FilterConcentradoPreciosFondoHistorico
          itemsPerPage={this.state.itemsPerPage}
          setPage={(page) => this.onSetPage(page)}
          onSetClaveOperadora={(claveOperadora) =>
            this.onSetClaveOperadoraHandler(claveOperadora)
          }
          onSetClaveFondo={(claveFondo) =>
            this.onSetClaveFondoHandler(claveFondo)
          }
          onSetTipoFondo={(tipoFondo) => this.onSetTipoFondohandler(tipoFondo)}
          onSetClasificacionFondo={(clasificacionFondo) =>
            this.onSetClasificacionFondo(clasificacionFondo)
          }
          onSetSerie={(serie) => this.onSetSerie(serie)}
          onSetWasFiltered={(wasFiltered) =>
            this.onSetWasFilteredHandler(wasFiltered)
          }
          onSetFechaInicio={(fechaInicio) => this.onSetFechaInicio(fechaInicio)}
          onSetFechaFin={(fechaFin) => this.onSetFechaFin(fechaFin)}
          setFilters={(filterParams) => this.onSetFilters(filterParams)}
        />
        <br />
        <div className="margen_50">{dataShow}</div>
        {/* <Rss
            onShowGraphicModal={this.state.onShowGraphicModal}
            modalTitle={getLan() === "es" ? "Banco de información" : "Data center"}
            // urlRss={urlForRss}
            optionType={2}
            rssType={4}
          /> */}
        <ConcentradoPreciosModal
          showGraphicModal={this.state.onShowGraphicModal}
          modalTitle={
            getLan() === "es"
              ? "Gráfica Hisórico de Precios"
              : "Historical fund prices chart"
          }
          claveFondo={this.state.claveFondo}
          paramsForm={this.state.paramsForm}
          messages={this.state.messages}
        />
        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          {contenidoMostrar}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitFondos: (
      size,
      page,
      claveOperadora,
      claveFondo,
      tipo,
      clasificacion,
      serie,
      fechaInicio,
      fechaFin
    ) => {
      dispatch(
        actions.initFondosConcentradoPreciosHistorico(
          size,
          page,
          claveOperadora,
          claveFondo,
          tipo,
          clasificacion,
          serie,
          fechaInicio,
          fechaFin
        )
      );
    },
    onInitConcentradoPrecios: (clave) => {
      dispatch(actions.initFondosConcentradoPrecios(clave));
    },
    onFetchRssDocument: () => {
      dispatch(actions.fetchRssSeguimientosUrlDocument());
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(76));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    fondos: state.fondos.fondos,
    concentradoPrecios: state.fondos.concentradoPrecios,
    diccionario: state.intl.diccionario,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConcentradoPreciosHistorico);

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import Tabs from "../../../components/ui/tabs/Tabs";
import { getLan } from "../../../stores/utils/utilities";
import b1 from "../../../assets/icons/sustentable/b1.jpg";
import b2 from "../../../assets/icons/sustentable/b2.jpg";
import b3 from "../../../assets/icons/sustentable/b3.jpg";
import b4 from "../../../assets/icons/sustentable/b4.jpg";

class TabASG extends Component {
  state = {
    asg : false,
    tiposBonos: [],
    tiposBonosInicial: [],
    tiposBonoASG:null,
    tabsBono: [
      {
          color: "#51ad32",
          image: b1,
          icon: "",
          title: getLan() === "es" ? "Bono Verde" : "Green Bond"
      },
      {
          color: "#009ee2",
          image: b2,
          icon: "",
          title: getLan() === "es" ? "Bono Social" : "Social Bond"
      },
      {
          color: "#176937",
          image: b3,
          icon: "",
          title: getLan() === "es" ? "Bono Sustentable" : "Sustainability Bond"
      },
      {
          color: "#009aa8",
          image: b4,
          icon: "",
          title: getLan() === "es" ? "Bono Ligado o Vinculado a la Sostenibilidad" : "Sustainability-Linked Bond"
      },

  ],
  };

  componentWillMount() { 
    this.onActivaTabInicialTipoBono(this.props.tiposBonos);
    this.setState({
      tiposBonos: this.props.tiposBonos,

    });
    
  }

  onActivaTabInicialTipoBono = (tipoBonoInicial = null) => {
    let tiposBonos = tipoBonoInicial!==null ? tipoBonoInicial.map(Number) : [];
    let tiposBonosInicial =
    tiposBonos.map((value,i) =>
        value - 1
     );

    this.setState({
        tiposBonos: tiposBonos,
        tiposBonosInicial: tiposBonosInicial,
    });
}
onChangeTabTipoBono = (index = 0, consulta = false) => {
    let tipoBono = null;
    let tiposBonos = null;

    if (index === 0) {
        tipoBono = 1;
        tiposBonos= this.onCheckTipoBono(tipoBono);
    } else if (index === 1) {
        tipoBono = 2;
        tiposBonos= this.onCheckTipoBono(tipoBono);

    } else if (index === 2) {
        tipoBono = 3;
        tiposBonos= this.onCheckTipoBono(tipoBono);
    } else if (index === 3) {
        tipoBono = 4;
        tiposBonos= this.onCheckTipoBono(tipoBono);

    }
    if(tiposBonos.length>0){
    this.onActivaTabInicialTipoBono(tiposBonos);

    if (consulta) {
        this.props.setTiposBono(tiposBonos)
        this.setState({
            tiposBonos: tiposBonos,
        });
    }
    this.setState({
        tiposBonos:  tiposBonos,
    });
    }
};
onCheckTipoBono = (tipoBono = null) => { 
    let tipoBonoExists=null;
    let tiposBonos=[];

    tiposBonos= this.props.tiposBonos ? this.props.tiposBonos : [];
    tiposBonos = tiposBonos.map(Number);
    tipoBonoExists = tiposBonos.find((itm) => itm === tipoBono);
    if(tipoBonoExists){
        const index = tiposBonos.indexOf(tipoBono);
        if (index > -1) { // only splice array when item is found
            tiposBonos.splice(index, 1); // 2nd parameter means remove one item only
        }
    }else{
        tiposBonos.push(tipoBono);
    }
    return tiposBonos;
}

  render() {
    
    return (
        <Tabs
        data={this.state.tabsBono}
        tipo={5}
        clicked={i => this.onChangeTabTipoBono(i, true)}
        activaTabInicial={this.state.tiposBonosInicial}
        subtipo={1}
        isSustentable={true}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(actions.initIntl(65));
    }
  };
};

const mapStateToProps = state => {
  return {

    tiposBonoASG: state.listadoValores.tiposBonoASG
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabASG);
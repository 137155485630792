import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import classes from "./Bivateca.css";
import Auxs from "../../../../hoc/auxs/Auxs";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import Item from "./item/Item";
import Pagination from "../../../../components/ui/pagination/Pagination";
import Back from "../../../../components/ui/back/Back";
import Loader from "../../../../components/loader/Loader";
import queryString from "query-string";
import FilterBivateca from "./FilterBivateca";

class Bivateca extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    data: [],
    tipo_imagen: null,
    itemsPerPage: 9,
    activePage: 1,
    numRows: 1,
    dataView: [],
    loader: true,
    palabraClave: null
  };

  componentDidMount() {
    this.initData(true);
  }

  initData(init = false) {
      if (init) {
      this.props.onInitIntl(6);
      }
    let paramsString = queryString.parse(this.props.location.search);

    let articulosAcademicosFlag = paramsString["articulos_academicos"] === null ? true : false;
    let revistasFlag = paramsString["revistas"] === null ? true : false;
    let escritosFlag = paramsString["escritos"] === null ? true : false;
    let blogFlag = paramsString["blog"] === null ? true : false;
    let librosFlag = paramsString["libros"] === null ? true : false;
    let diccionarioFlag = paramsString["diccionario"] === null ? true : false;

    if (articulosAcademicosFlag || revistasFlag || escritosFlag || blogFlag || librosFlag || diccionarioFlag) {
      let tipo_imagen = null;
      if (articulosAcademicosFlag) {
        this.props.onArticulosAcademicos();
        tipo_imagen = 1;
      }
      if (revistasFlag) {
        this.props.onRevistas();
        tipo_imagen = 2;
      }
      if (escritosFlag) {
        this.props.onEscritos();
        tipo_imagen = 3;
      }
      if (blogFlag) {
        this.props.onBlog();
        tipo_imagen = 4;
      }
      if (librosFlag) {
        this.props.onLibros();
        tipo_imagen = 5;
      }
      if (diccionarioFlag) {
        this.props.onDiccionario();
        tipo_imagen = 6;
      }
      this.setState({
        articulosAcademicosFlag: articulosAcademicosFlag,
        revistasFlag: revistasFlag,
        escritosFlag: escritosFlag,
        blogFlag: blogFlag,
        librosFlag: librosFlag,
        diccionarioFlag: diccionarioFlag,
        tipo_imagen: tipo_imagen
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionarioIntl !== prevProps.diccionarioIntl) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionarioIntl) {
        const messages = defineMessages(this.props.diccionarioIntl);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.articulos !== prevProps.articulos) {
      if (this.state.articulosAcademicosFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.articulos.slice(index_inicial, index_final);

        this.setState({ loader: false, dataView: lista_ver, tipo_imagen: 1, data: this.props.articulos });
      }
    }
    if (this.props.revistas !== prevProps.revistas) {
      if (this.state.revistasFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.revistas.slice(index_inicial, index_final);

        this.setState({ loader: false, dataView: lista_ver, tipo_imagen: 2, data: this.props.revistas });
      }
    }
    if (this.props.escritos !== prevProps.escritos) {
      if (this.state.escritosFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.escritos.slice(index_inicial, index_final);

        this.setState({ loader: false, dataView: lista_ver, tipo_imagen: 3, data: this.props.escritos });
      }
    }
    if (this.props.blog !== prevProps.blog) {
      if (this.state.blogFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.blog.slice(index_inicial, index_final);

        this.setState({ loader: false, dataView: lista_ver, tipo_imagen: 4, data: this.props.blog });
      }
    }
    if (this.props.libros !== prevProps.libros) {
      if (this.state.librosFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.libros.slice(index_inicial, index_final);

        this.setState({ loader: false, dataView: lista_ver, tipo_imagen: 5, data: this.props.libros });
      }
    }

    if (this.props.diccionario !== prevProps.diccionario) {
      if (this.state.diccionarioFlag) {
        let lista_ver = [];
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = this.props.diccionario.slice(index_inicial, index_final);
        this.setState({ loader: false, dataView: lista_ver, tipo_imagen: 6, data: this.props.diccionario });
      }
    }
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      let lista_ver = [];
      let index_final = pageNumber * this.state.itemsPerPage;
      let index_inicial = index_final - this.state.itemsPerPage;
      lista_ver = this.state.data.slice(index_inicial, index_final);
      this.setState({
        activePage: pageNumber,
        dataView: lista_ver
      });
    }
  }

  onSetPalabraClave = palabra_calve => {
    this.setState({
      palabraClave: palabra_calve
    });
  };
  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };
  onSetParams = ({ keyword, from, to, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(keyword, from, to, page);
    }
  };

  changeHistoryReset() {

    this.getData(null);
  }

  changeHistory(keyword = null) {
    this.setState({
      loader: true
    });

    this.getData(keyword);
  }

  getData(params) {
    if (this.props.articulosAcademicosFlag) {
      this.props.onArticulosAcademicos(params);
    }
    if (this.props.revistasFlag) {
      this.props.onRevistas(params);
    }
    if (this.props.escritosFlag) {
      this.props.onEscritos(params);
    }
    if (this.props.blogFlag) {
      this.props.onBlog(params);
    }
    if (this.props.librosFlag) {
      this.props.onLibros(params);
    }
    if (this.props.diccionarioFlag) {
      this.props.onDiccionario(params);
    }
  }

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };

  render() {
    let titulo = "institutobiva.bivateca.documentos.test";
    let titulo_imagen = "";
    switch (this.state.tipo_imagen) {
      case 1:
        titulo = "institutobiva.bivateca.documentos.subtitulo1";
        titulo_imagen = getLan() === "es" ? "Documentos" : "Documents";
        break;
      case 2:
        titulo = "institutobiva.bivateca.documentos.subtitulo2";
        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.bivateca.documentos.subtitulo2"]
            : null;

        break;
      case 3:
        titulo = "institutobiva.bivateca.documentos.subtitulo3";

        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.bivateca.documentos.subtitulo3"]
            : null;
        break;
      case 4:
        titulo = "institutobiva.bivateca.documentos.subtitulo4";

        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.bivateca.documentos.subtitulo4"]
            : null;
        break;
      case 5:
        titulo = "institutobiva.bivateca.documentos.subtitulo5";

        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.bivateca.documentos.subtitulo5"]
            : null;
        break;
      case 6:
        titulo = "institutobiva.bivateca.documentos.subtitulo6";
        titulo_imagen =
          this.props.diccionarioIntl !== null
            ? this.props.diccionarioIntl["institutobiva.bivateca.documentos.subtitulo6"]
            : null;

        break;

      default:
        break;
    }
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <Auxs>
          {loader}

          <Back show={true} onBack={e => this.props.onBack(e)} />
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1 className={[classes.main_title, "texto_42", "nunito"].join(" ")}>
                  <FormattedMessage id={"institutobiva.bivateca.documentos.titulo1"} defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className={["row"].join(" ")}>
            <div className="col sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage id={titulo} defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className={["col-12 sin_padding_L"].join(" ")}>
              <FilterBivateca
                setPalabraClave={palabra_calve => this.onSetPalabraClave(palabra_calve)}
                setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
                setParams={filterParams => this.onSetParams(filterParams)}
                setPage={page => this.onSetPage(page)}
              />
            </div>

            <div className={["col-12 sin_padding_L", classes.grid].join(" ")}>
              <Item
                key={+new Date()}
                datos={this.state.dataView}
                tipo={1}
                tipo_imagen={this.state.tipo_imagen}
                titulo_slider={titulo_imagen}
              />
            </div>
            <div className={"col-12 sin_padding"}>
              <Pagination
                claseTipo={2}
                numRows={this.state.data.length}
                paginas={5}
                itemsPerPage={this.state.itemsPerPage}
                handlePageChange={this.handlePageChange.bind(this)}
                activePage={this.state.activePage}
              />
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onInitVideosInstituto: () => {
      dispatch(action.initVideosInstituto());
    },
    onArticulosAcademicos: keywords => {
      dispatch(action.getArticulosAcademicos(keywords));
    },
    onBlog: keywords => {
      dispatch(action.getBlogs(keywords));
    },
    onEscritos: keywords => {
      dispatch(action.getEscritos(keywords));
    },
    onDiccionario: keywords => {
      dispatch(action.getDiccionarios(keywords));
    },
    onLibros: keywords => {
      dispatch(action.getLibros(keywords));
    },
    onRevistas: keywords => {
      dispatch(action.getRevistas(keywords));
    }
  };
};

const mapStateToProps = state => {
  return {
    videos: state.videos.videos_instituto,
    diccionarioIntl: state.intl.diccionario,
    articulos: state.documentos.articulosAcademicos,
    revistas: state.documentos.revistas,
    escritos: state.documentos.escritos,
    blog: state.documentos.blog,
    libros: state.documentos.libros,
    diccionario: state.documentos.diccionarios
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bivateca);

import React, { Component } from "react";
import classes from "./FilterDocumentosSolicitudes.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import { getLan } from "../../../stores/utils/utilities";

import Input from "../input/Input";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiple = null,
  options,
  defaultValue = null,
  checked = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      {label && input.type !== "radio" && input.type !== "checkbox" ? (
        <label className="montse texto_14 regular" for={input.name}>
          {label}
        </label>
      ) : null}

      {/* {label ? <label for={input.name}>{label}</label> : null} */}
      <Input config={params} />
    </div>
  );
};

class FilterDocumentosSolicitudes extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    cotizaciones: null,
    tab: null,
    periodoSeleccionado: null,
    toggleFilters: false,
    disableEnableButtons: true,
    allSelelectFilters: [],
    selectedFiltersGroup1: [],
    selectedFiltersGroup2: [],
    selectPivot: false,
    selectedFilters: {
      tipo_documento: []
    },
    form_filters: {
      envios: {
        value: "",
        disabled: false,
        show: false
      },
      tipo_documento: {
        value: "",
        disabled: false,
        show: true
      },
      tipo: null
    },
    form_clave: {
      clave: {
        value: ""
      }
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    clave: null,
    tipoValor: [],
    biva: true,
    canceladas: false,
    envios: []
  };

  componentDidMount() {
    this.props.onInitSolicitudesDetalle(this.props.tramiteId);
    this.props.onInitEnvios(this.props.tramiteId);

    let paramsString = queryString.parse(this.props.location.search);

    let tramiteId = this.props.tramiteSeleccionado ? this.props.tramiteSeleccionado : null;
    let tipoDocumento = null;
    let numEnvio = null;
    let page = 1;
    let empresaId = null,
      razonSocial = null,
      clave = null,
      numeroTramite = null;
    let search_field_param = paramsString["search_field"];
    if (tramiteId === null) {
      if (this.props.location.search !== "" && search_field_param === undefined) {
        tramiteId = paramsString["tramiteId"] === "null" ? null : paramsString["tramiteId"].split(",");
        tipoDocumento = paramsString["tipoDocumento"] === undefined || paramsString["tipoDocumento"] === "null" ? null : paramsString["tipoDocumento"].split(",");
        numEnvio = paramsString["numEnvio"] === "null" ? null : paramsString["numEnvio"];
        page = paramsString["page"] === "null" ? 1 : parseInt(paramsString["page"]);
        empresaId = paramsString["empresaId"] === "null" ? null : paramsString["empresaId"];
        razonSocial = paramsString["razonSocial"] === "null" ? null : paramsString["razonSocial"];
        clave = paramsString["clave"] === "null" ? null : paramsString["clave"];
        numeroTramite = paramsString["numeroTramite"] === "null" ? null : paramsString["numeroTramite"];
      }
    }
    // this.props.onInitDocumentosListado(this.props.itemsPerPage, page - 1, tramiteId, tipoDocumento, numEnvio);
    this.setState({
      empresaId: empresaId,
      razonSocial: razonSocial,
      clave: clave,
      numeroTramite: numeroTramite
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.envios !== this.props.envios) {
      let envios = [];
      if (this.props.envios !== null && this.props.envios !== undefined && this.props.envios.length > 0) {
        this.props.envios.map((envio, i) => {
          envios.push({ nombre: envio, value: envio });
          return null;
        });
      }
      this.setState({
        envios: envios
      });
    }
  }

  formModelHandler = modelo => {
    switch (modelo) {
      case "modelo_1":
        return this.printFrameModelOneHandler();
      default:
        return null;
    }
  };

  printFrameModelOneHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : null;
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.onToggleFilters}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar Filtros"
                        : "Close"
                      : getLan() === "es"
                      ? "Filtrar"
                      : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-9", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              tipo_documento: this.state.form_filters.tipo_documento.value,
              envios: this.state.form_filters.envios.value
            }}
            render={({
              optionsEnvios = this.state.envios ? this.state.envios : [],
              optionsTiposDocumentos = this.props.tiposDocumentos ? this.props.tiposDocumentos : [],
              // tipo_documento = tipo_documento ? tipo_documento : [],
              form
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterModelOneHandler}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 ">
                    <Field
                      name="tipo_documento"
                      id="tipo_documento"
                      label={getLan() === "es" ? "Tipo de documento" : "Document type"}
                      type="select"
                      elementType="select"
                      classes="custom-select"
                      isMultiple={true}
                      options={optionsTiposDocumentos}
                      onInputChange={event => this.onInputSelectedModelOne(event, "tipo_documento")}
                      component={renderInput}
                    />
                  </div>
                  <div className={["col-12 col-sm-12 col-md-6 col-lg-4"].join(" ")}>
                    <Field
                      name="envios"
                      id="envios"
                      label={getLan() === "es" ? "Número de envío" : "Submittal number"}
                      type="select"
                      elementType="select"
                      classes="custom-select"
                      options={optionsEnvios}
                      onInputChange={event => this.onInputSelectedModelOne(event, "envios")}
                      component={renderInput}
                    />
                  </div>
                </div>

                {/* ======================= */}

                <hr />
                {this.sectionButtonsModelOne(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonsModelOne = form => {
    const disabledButton = !this.state.form_filters.errorDate ? false : true;
    return (
      <div className="row">
        
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onFilterDataByEvent = (form) => {
    const tipoDocumento =
      form.tipo_documento.value !== null ? form.tipo_documento.value : null;
    const numEnvio = form.envios.value ? form.envios.value : null;

    this.props.setPageDocumento(1);

    const params = {
      tramiteId: this.props.tramiteId ? this.props.tramiteId : null,
      page: 1,
      tipoDocumento: tipoDocumento,
      numEnvio: numEnvio,
      empresaId: this.state.empresaId,
      razonSocial: this.state.razonSocial,
      clave: this.state.clave,
      numeroTramite: this.state.numeroTramite,
      reset: false
    };

    this.props.setParams(params);

  };

  onSubmitFilterModelOneHandler = event => {
    event.preventDefault();
    // const tipoDocumento =
    //   this.state.form_filters.tipo_documento.value !== null ? this.state.form_filters.tipo_documento.value : null;
    // const numEnvio = this.state.form_filters.envios.value ? this.state.form_filters.envios.value : null;

    // this.props.setPageDocumento(1);

    // const params = {
    //   tramiteId: this.props.tramiteId ? this.props.tramiteId : this.props.empresaId ? this.props.empresaId : null,
    //   page: 1,
    //   tipoDocumento: tipoDocumento,
    //   numEnvio: numEnvio,
    //   empresaId: this.state.empresaId,
    //   razonSocial: this.state.razonSocial,
    //   clave: this.state.clave,
    //   numeroTramite: this.state.numeroTramite,
    //   reset: false
    // };

    // this.props.setParams(params);

  };

  onSubmitSearchModelOneHandler = () => {};

  onInputChangeModelOne = event => {
    const value = event.target.value;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;
    if (value !== "") {
      this.props.onFilterClaves(value);
    } else {
      disabled = true;
    }
    this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
  };

  limpiaForms() {
    let selectPivot = null;
    const form1 = { ...this.state.form_filters };
    const form_clave = this.state.form_clave;
    form_clave.clave.value = "";

    form1.envios.show = false;
    form1.envios.value = "";
    form1.tipo_documento.value = [];

    this.props.setPageDocumento(1);
    this.props.setNumEnvio(null, false);
    this.props.setTipoDocumento(null, true);

    // this.props.onInitDocumentosListado(this.props.itemsPerPage, 0, this.props.tramiteId, null, null);
    const selectedFilters = this.state.selectedFilters;

    const params = {
      tramiteId: this.props.tramiteId ? this.props.tramiteId : null,
      reset: true
    };

    this.props.setParams(params);

    selectedFilters.tipo_documento = [];

    this.setState({
      allSelelectFilters: [],
      selectedFiltersGroup1: [],
      selectedFilters: selectedFilters,
      selectedFiltersGroup2: [],
      selectPivot: selectPivot,
      form_filters: form1,
      periodoSeleccionado: null,
      valueClave: null,
      toggleInputs: false
    });
  }

  onResetFilterModelOneHandler = form => {
    form.reset();
    this.limpiaForms(this.state.biva, this.state.canceladas);
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onInputModelOneChange = event => {
    const value = event.target.value;
    const disabled = value ? false : true;
    this.setState({
      disableEnableButtons: disabled
    });
  };

  onFilterInstrumentoHandler = () => {
    this.props.onFilterenvios(null);
  };

  onFilterTipoValorHandler = (instrumento = null, tipoValor) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterDocumentos = (mantenimientoId = null, listadoId = null) => {
    const params = {
      tipoInformacion: mantenimientoId,
      tipoInformacionListado: listadoId
    };
    this.props.onFilterTipoDocumentos(params);
  };

  onCheckIfDatesAreValid = (inicio, fin) => {
    return inicio > fin;
  };

  onInputSelectedModelOne = (event, type = null) => {
    let selected = [];
    let selectPivot = this.state.selectPivot;
    let group1 = [...this.state.selectedFiltersGroup1];
    let group2 = [...this.state.selectedFiltersGroup2];

    let value = event.target.value;
    const form = { ...this.state.form_filters };
    let periodo_lista = this.state.periodo;
    const selectedFilters = this.state.selectedFilters;

    if (type === "tipo_documento") {
      group1 = group1.filter(item => item.type !== "tipDoc");
      const fullValue = event.target.options;
      const options = [...fullValue];
      const indexSelected = fullValue.selectedIndex && fullValue.selectedIndex !== -1 ? fullValue.selectedIndex : 0;
      const documentoValue = options[indexSelected].value;
      const documentoLabel = options[indexSelected].text;

      let optionsDocumenos = [...form.tipo_documento.value];

      if (documentoValue !== "") {
        // Usado para los valores del select tipo_documento (state)
        const filterExits = optionsDocumenos.find(item => item === documentoValue);
        if (filterExits) {
          optionsDocumenos = optionsDocumenos.filter(item => item !== documentoValue);
        } else {
          optionsDocumenos.push(documentoValue);
        }

        // Usado para los valores del tipo_documento (filtros seleccionados)
        const selectedFilterExists = selectedFilters.tipo_documento.find(filter => filter.nombre === documentoLabel);
        if (selectedFilterExists) {
          selectedFilters.tipo_documento = selectedFilters.tipo_documento.filter(filter => filter.nombre !== documentoLabel);
        } else {
          selectedFilters.tipo_documento.push({
            type: 1,
            nombre: documentoLabel,
            value: documentoValue
          });
        }
      } else {
        optionsDocumenos = [];
        selectedFilters.tipo_documento = [];
      }
      form.tipo_documento.value = optionsDocumenos.length > 0 ? [...optionsDocumenos] : [];
      this.props.setTipoDocumento(form.tipo_documento.value, false);

      selectedFilters.tipo_documento.map(item => {
        item.type = "tipDoc";
        group1.push(item);
        return null;
      });
    }
    if (type === "envios") {
      form.envios.value = value;

      const tipoValor = [...this.state.envios];
      const item = tipoValor.find(tipv => tipv.id === +value);
      group1 = group1.filter(item => item.type !== "tipEnvios");

      if (item) {
        item.type = "tipEnvios";
        group1.push(item);
      }
      this.props.setNumEnvio(value, true);
    }

    selected = [...group1, ...group2];
    this.setState({
      selectPivot: selectPivot,
      selectedFilters: selectedFilters,
      allSelelectFilters: selected,
      selectedFiltersGroup1: group1,
      selectedFiltersGroup2: group2,
      form_filters: form,
      periodo: periodo_lista
    });

    this.onFilterDataByEvent(form);
  };

  onDeleteFilterModelOneHandler = item => {
    let selected = [];
    let group1 = [...this.state.selectedFiltersGroup1];
    let group2 = [...this.state.selectedFiltersGroup2];
    const form = { ...this.state.form_filters };

    if (item.type === "tipDoc") {
      let optionsDocumenos = [...form.tipo_documento.value];

      // Usado para los valores del select tipo_documento (state)
      const filterExits = optionsDocumenos.find(itemd => itemd === item.value);
      if (filterExits) {
        optionsDocumenos = optionsDocumenos.filter(itemd => itemd !== item.value);
      }

      let grupoDoc = group1.filter(itemd => itemd.type === "tipDoc");
      const filterExitsDoc = grupoDoc.find(itemd => itemd.value === item.value);

      if (filterExitsDoc) {
        group1 = group1.filter(itemd => itemd !== filterExitsDoc);
      }
      const selectedFilters = this.state.selectedFilters;

      const selectedFilterExists = selectedFilters.tipo_documento.find(filter => filter.value === item.value);
      if (selectedFilterExists) {
        selectedFilters.tipo_documento = selectedFilters.tipo_documento.filter(filter => filter.value !== item.value);
      }

      form.tipo_documento.value = optionsDocumenos;
      this.props.setTipoDocumento(optionsDocumenos, false);
    }

    if (item.type === "tipEnvios") {
      group1 = group1.filter(itemd => itemd.type !== "tipEnvios");

      form.envios.value = "";
      this.props.setInstrumento(null);
    }

    selected = [...group1, ...group2];
    this.setState({
      allSelelectFilters: selected,
      selectedFiltersGroup1: group1,
      selectedFiltersGroup2: group2,
      form_filters: form
    });
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.allSelelectFilters];

    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map(item => (
          <div className={classes.filter_container_model_one} key={`${item.nombre}-${item.id}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{item.nombre}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterModelOneHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.props.setEmisora(null, 3);

      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null
      });
    }
    this.setState(prevState => {
      if (!prevState.toggleInputs === false) {
      }
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => (
      <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}>
        {item.clave}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let emisoraSeleccionada = null;
    let claveSeleccionada = null;
    if (option === 0) {
      this.props.setEmisora(clave, 2);
      claveSeleccionada = clave;
    } else {
      emisoraSeleccionada = id;
      this.props.setEmisora(emisoraSeleccionada, 1);
    }
    this.props.onFilterenvios(this.state.form_filters.tipo_documento.value, emisoraSeleccionada, claveSeleccionada);

    this.setState({
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: emisoraSeleccionada,
      clave: claveSeleccionada
    });
  };

  onPrintOptionSelectedHandler = value => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
        <span className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onToggleFilterInputOptionSelected(1)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  render() {
    const filter = this.props.modelo ? this.formModelHandler(this.props.modelo) : null;

    return filter;
  }
}

const mapStateToProps = state => {
  return {
    envios: state.listadoValores.numEnvios,
    listadoClaves: state.prospectos.claves,
    tipo_documento: state.prospectos.tipo_documento,
    tiposDocumentos: state.listadoValores.tipoDocumento
    // cotizaciones: state.emisoras.cotizaciones
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitSolicitudesDetalle: tramiteId => {
      dispatch(actions.initTipoDocumentoSolicitud(tramiteId));
    },
    onInitDocumentosListado: (size, page, tramiteId, tipoDocumentoId, numEnvio) => {
      dispatch(actions.initDocumentosListado(size, page, tramiteId, tipoDocumentoId, numEnvio));
    },
    onInitEnvios: tramiteId => {
      dispatch(actions.initNumeroEnvios(tramiteId));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterDocumentosSolicitudes)
);

import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setInitTerminosFunciones = data => {
  return {
    type: actionTypes.FETCH_TERMINOS_FUNCIONES_SUCCESS,
    terminos_funciones: data
  };
};

export const initTerminosFunciones = () => {
  const url = `${dominio}/cms/o/documents/funciones-vigilancia/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInitTerminosFunciones(response.data));
      })
      .catch(err => console.log(err));
  };
};

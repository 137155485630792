import { updateObject } from "../utils/utilities";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  cursos: null,
  checkout: null,
};

const setParams = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CURSOS_SUCCESS:
      return setParams(state, action);
    case actionTypes.FETCH_CHECKOUT_CURSOS_SUCCESS:
      return setParams(state, action);
    default:
      return state;
  }
};

export default reducer;

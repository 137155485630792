import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

// =========================================================================

const getImagenesAlianzasEmp = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ALIANZASEMPIMAGENES_SUCCES,
      alianzas_emp: null
    };
  }
  return {
    type: actionTypes.FETCH_ALIANZASEMPIMAGENES_SUCCES,
    alianzas_emp: data
  };
};

export const initImagenesAlianzasEmp = () => {
  let url = `${dominio}/cms/o/documents/alliances-emp-img/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getImagenesAlianzasEmp(response.data));
      })
      .catch(err => dispatch(getImagenesAlianzasEmp(err.data)));
  };
};

// =========================================================================

const getImagenesAlianzasStates = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_ALIANZASSTATESIMAGENES_SUCCES,
        alianzas_states: []
      };
    }
    return {
      type: actionTypes.FETCH_ALIANZASSTATESIMAGENES_SUCCES,
      alianzas_states: data
    };
  };
  
  export const initImagenesAlianzasStates = () => {
    let url = `${dominio}/cms/o/documents/alliances-states-img/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getImagenesAlianzasStates(response.data));
        })
        .catch(err => dispatch(getImagenesAlianzasStates(err.data)));
    };
  };

// =========================================================================

const getImagenesAlianzasEdu = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_ALIANZASEDUIMAGENES_SUCCES,
        alianzas_edu: null
      };
    }
    return {
      type: actionTypes.FETCH_ALIANZASEDUIMAGENES_SUCCES,
      alianzas_edu: data
    };
  };
  
  export const initImagenesAlianzasEdu = () => {
    let url = `${dominio}/cms/o/documents/alliances-edu-img/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getImagenesAlianzasEdu(response.data));
        })
        .catch(err => dispatch(getImagenesAlianzasEdu(err.data)));
    };
  };
  // =========================================================================

const getImagenesComoInvertir = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_IMAGENES_SUCCES,
      procesoInvertir: null
    };
  }
  return {
    type: actionTypes.FETCH_IMAGENES_SUCCES,
    procesoInvertir: data
  };
};

export const initImagenesComoInvertir = () => {
  let url = `${dominio}/cms/o/documents/proceso-invertir/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getImagenesComoInvertir(response.data));
      })
      .catch(err => dispatch(getImagenesComoInvertir(err.data)));
  };
};
  // =========================================================================

const getCasaBolsaBiva = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_IMAGENES_SUCCES,
      casaBolsaBiva: null
    };
  }
  return {
    type: actionTypes.FETCH_IMAGENES_SUCCES,
    casaBolsaBiva: data
  };
};

export const getImagenesCasaBolsa = () => {
  let url = `${dominio}/cms/o/documents/cb-miembro-biva/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getCasaBolsaBiva(response.data));
      })
      .catch(err => dispatch(getCasaBolsaBiva(err.data)));
  };
};
  // =========================================================================

const getCasaBolsaOtras = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_IMAGENES_SUCCES,
      casaBolsaOtras: null
    };
  }
  return {
    type: actionTypes.FETCH_IMAGENES_SUCCES,
    casaBolsaOtras: data
  };
};

export const getImagenesCasaBolsaOtras = () => {
  let url = `${dominio}/cms/o/documents/cb-otras/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getCasaBolsaOtras(response.data));
      })
      .catch(err => dispatch(getCasaBolsaOtras(err.data)));
  };
};
  // =========================================================================

const getImagenProcesoListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_IMAGENES_SUCCES,
      procesoListado: null
    };
  }
  return {
    type: actionTypes.FETCH_IMAGENES_SUCCES,
    procesoListado: data
  };
};

export const getImagenesProcesoListado = () => {
  let url = `${dominio}/cms/o/documents/img-proceso-listado/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getImagenProcesoListado(response.data));
      })
      .catch(err => dispatch(getImagenProcesoListado(err.data)));
  };
};
  // =========================================================================

const getImagenAuditorium = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_IMAGENES_SUCCES,
      auditorium: null
    };
  }
  return {
    type: actionTypes.FETCH_IMAGENES_SUCCES,
    auditorium: data
  };
};

export const getImagenesAuditorium = () => {
  let url = `${dominio}/cms/o/documents/auditorium/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getImagenAuditorium(response.data));
      })
      .catch(err => dispatch(getImagenAuditorium(err.data)));
  };
};
  // =========================================================================

const getImagenGrito = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_IMAGENES_SUCCES,
      grito: null
    };
  }
  return {
    type: actionTypes.FETCH_IMAGENES_SUCCES,
    grito: data
  };
};

export const getImagenesGrito = () => {
  let url = `${dominio}/cms/o/documents/grito-biva-images/lang/${lang}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getImagenGrito(response.data));
      })
      .catch(err => dispatch(getImagenGrito(err.data)));
  };
};

  // =========================================================================

  const getProcesoListadoSic = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_IMAGENES_SUCCES,
        procesoLisadoSIC: null
      };
    }
    return {
      type: actionTypes.FETCH_IMAGENES_SUCCES,
      procesoLisadoSIC: data
    };
  };
  
  export const getImagenProcesoListadoSic = () => {
    let url = `${dominio}/cms/o/documents/img-proceso-listado-sic/lang/${lang}`;
  
    return dispatch => {
      axios
        .get(url)
        .then(response => {
          dispatch(getProcesoListadoSic(response.data));
        })
        .catch(err => dispatch(getProcesoListadoSic(err.data)));
    };
  };

    // =========================================================================

    const setImagenesQueEstaPasando = data => {
        if (data === undefined || data === null) {
          return {
            type: actionTypes.FETCH_IMAGENES_SUCCES,
            queEstaPasando: []
          };
        }
        return {
          type: actionTypes.FETCH_IMAGENES_SUCCES,
          queEstaPasando: data
        };
      };
      
      export const getImagenesQueEstaPasando = () => {
        let url = `${dominio}/cms/o/documents/embajador-imagenes/lang/${lang}`;
      
        return dispatch => {
          axios
            .get(url)
            .then(response => {
              dispatch(setImagenesQueEstaPasando(response.data));
            })
            .catch(err => dispatch(setImagenesQueEstaPasando(err.data)));
        };
      };
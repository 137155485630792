import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../stores/actions/index";
import classes from "./AnexoAA.css";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import Rss from "../../../rss/Rss";
import queryString from "query-string";
import FilterAnexoAA from "../../../../components/ui/filterAnexoAA/FilterAnexoAA";

const dominio = `${window.location.protocol}//${window.location.host}`;
let base_url = `${dominio}/emisoras/instrumento-alternativo/anexo-aa/xls`;

class AnexoAA extends Component {
    state = {
        itemsPerPage: 10,
        activePage: 1,
        resourceList: [],
        loader: true,
        tramiteSeleccionado: null,
        razonSocial: null,
        clave: null,
        numeroTramite: 0,
        tipoValor: null,
        datos: null,
        emisoraSeleccionada: null,
        ejercicio: null,
        instrumento: null,
        periodo: null,
        urlForRss: `${dominio}/emisoras/anexo/rss`,
        documentos: null,
        detalles: null,
        wasFiltered: false,
        messages: null,
        lan: getLocal()
    };

    componentDidMount() {
        this.props.onInitIntl();

        let paramsString = queryString.parse(this.props.location.search);
        let instrumento = paramsString["instrumento"] !== undefined && paramsString["instrumento"] !== "null" ? paramsString["instrumento"] : null;
        let ejercicio = paramsString["ejercicio"] !== undefined && paramsString["ejercicio"] !== "null" ? paramsString["ejercicio"] : null;
        let periodo = paramsString["periodo"] !== undefined && paramsString["periodo"] !== "null" ? paramsString["periodo"] : null;
        let emisora = paramsString["emisora"] !== undefined && paramsString["emisora"] !== "null" ? paramsString["emisora"] : null;
        let clave = paramsString["clave"] !== undefined && paramsString["clave"] !== "null" ? paramsString["clave"] : null;
        let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;

        this.setState({
            instrumento: instrumento,
            clave: clave,
            emisoraSeleccionada: emisora,
            activePage: parseInt(page) > 0 ? parseInt(page) : 1,
            ejercicio: ejercicio,
            periodo: periodo
        });

        let params = {
            size: this.state.itemsPerPage,
            page: page > 0 ? page - 1 : 0,
            instrumento: instrumento,
            clave: clave,
            ejercicio: ejercicio,
            periodo: periodo,
            emisora: emisora

        };
        this.props.onInitAnexo(params);
        //   this.state.itemsPerPage,
        //   page >= 1 ? page - 1 : 0,
        //   emisora ? null : mercado,
        //   emisora ? null : instrumento,
        //   emisora,
        //   clave
        // );
    }

    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages
                });
            }
        }
        if (this.props.anexo !== prevProps.anexo) {
            if (this.state.emisoraSeleccionada !== null && this.props.anexo.length !== 0) {
                // this.updateGrandparentListado(this.props.anexo.content[0].idPublicacion);
            } else if (this.props.anexo.length !== 0) {
                // this.updateGrandparentListado(this.props.anexo.content[this.state.numeroTramite].idPublicacion);
            }
            // }
            this.setState({
                loader: false
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let anexo = null;
        let documentos = null;
        let detalles = null;

        if (nextProps.anexo && nextProps.anexo.content) {
            anexo = nextProps.anexo.content;
        }
        if (nextProps.documentos !== this.props.documentos) {
            documentos = nextProps.documentos;
        }
        if (nextProps.detalleProspectos !== this.props.detalleProspectos) {
            if (nextProps.detalleProspectos) {
                detalles = nextProps.detalleProspectos;
            }
        } else {
            detalles = this.props.detalleProspectos;
        }

        this.setState({
            documentos: documentos,
            detalles: detalles
        });

        let resource = anexo;
        this.onPageChangeHandler(resource, null, 0);
    }

    onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
        let data = resource ? resource : this.props.anexo;
        let resourceList = [];
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                resourceList: resourceList,
                itemSeleccionado: urlSeleccionado
            });
        } else if (pageNumber) {
            this.setState({
                activePage: pageNumber,
                resourceList: resourceList
            });
        } else {
            this.setState({
                resourceList: resourceList
            });
        }
    };

    handlePageChange(pageNumber) {
        if (this.state.activePage !== pageNumber) {
            this.setState({
                loader: true
            });
            this.changeHistory(
                pageNumber,
                this.state.instrumento,
                this.state.emisoraSeleccionada,
                this.state.clave,
                this.state.ejercicio,
                this.state.periodo
            );

            this.onPageChangeHandler(null, pageNumber);
        }
    }

    updateGrandparentListado = (empresaId, razonSocial, clave, numeroTramite, tipoValor) => {
        // let datos = {
        //   id: empresaId,
        //   numeroTramite: numeroTramite,
        //   tipoValor: tipoValor,
        //   clave: clave,
        //   razonSocial: razonSocial
        // };
        // this.props.onInitDetalleProspectos(empresaId);
        // this.setState({
        //   tramiteSeleccionado: empresaId,
        //   razonSocial: razonSocial,
        //   numeroTramite: numeroTramite !== undefined ? numeroTramite : this.state.numeroTramite,
        //   tipoValor: tipoValor,
        //   datos: datos,
        //   detalles: null
        // });
    };

    onSetMercado = mercado => {
        this.setState({
            mercado: mercado
        });
    };

    onSetInstrumento = instrumento => {
        this.setState({
            instrumento: instrumento
        });
    };

    onSetEmisora = (emisora, tipo) => {
        let emisoraSeleccionada = null;
        let clave = null;

        if (tipo === 1) {
            emisoraSeleccionada = emisora;
        }
        if (tipo === 2) {
            clave = emisora;
        }

        this.setState({
            emisoraSeleccionada: emisoraSeleccionada,
            clave: clave
        });
    };

    onSetPage = page => {
        this.setState({
            activePage: page
        });
    };

    onSetWasFiltered = wasFiltered => {
        this.setState({
            wasFiltered: wasFiltered
        });
    };

    onShowRssModalHandler = () => {
        this.setState(prevState => {
            return {
                showRssModal: !prevState.showRssModal
            };
        });
    };

    onSetParams = ({ page, instrumento, emisora, clave, ejercicio, periodo, reset = false }) => {
        this.setState({
            instrumento: instrumento ? [...instrumento] : null,
            periodo: periodo ? periodo : null,
            ejercicio: ejercicio ? ejercicio : null,
            emisoraSeleccionada: emisora ? emisora : null,
            clave: clave ? clave : null,
            activePage: page
        });

        if (reset) {
            this.changeHistoryReset();
        } else {
            this.changeHistory(page, instrumento, emisora, clave, ejercicio, periodo);
        }
    };

    changeHistory(page = null, instrumento = null, emisora = null, clave = null, ejercicio = null, periodo = null) {
        this.props.history.push(
            `?instrumento=${emisora ? null : instrumento
            }&emisora=${emisora}&clave=${clave}&periodo=${periodo}&ejercicio=${ejercicio}&page=${page ? page : this.state.activePage}`
        );

        this.setState({
            loader: true
        });

        let params = {
            size: this.state.itemsPerPage,
            page: page >= 1 ? page - 1 : 0,
            instrumento: instrumento,
            clave: clave,
            ejercicio: ejercicio,
            periodo: periodo

        };
        this.props.onInitAnexo(params
            //   this.state.itemsPerPage,
            //   page >= 1 ? page - 1 : 0,
            //   emisora ? null : mercado,
            //   emisora ? null : instrumento,
            //   emisora,
            // clave
        );
    }

    changeHistoryReset() {
        this.props.history.push(`?instrumento=${null}}&periodo=${null}&emisora=${null}&clave=${null}&page=${1}`);
        this.setState({
            loader: true
        });
        let params = {
            size: this.state.itemsPerPage,
            page: 0,
            instrumento: null,
            clave: null,
            ejercicio: null,
            periodo: null

        };
        this.props.onInitAnexo(params);
    }

    onSetActivePage = activePage => {
        this.setState({
            activePage: activePage
        });
    };

    render() {
        let resultadoDescarga = null;
        let descarga_url = base_url;

        descarga_url = `${descarga_url}?1=1`;
        descarga_url = this.state.instrumento && this.state.instrumento.length > 0 ? `${descarga_url}&tipoInstrumentoAlternativo=${this.state.instrumento}` : descarga_url;
        descarga_url = this.state.periodo ? `${descarga_url}&periodo=${this.state.periodo}` : descarga_url;
        descarga_url = this.state.ejercicio ? `${descarga_url}&ejercicio=${this.state.ejercicio}` : descarga_url;
        descarga_url = this.state.emisoraSeleccionada ? `${descarga_url}&empresa=${this.state.emisoraSeleccionada}` : descarga_url;
        descarga_url = this.state.clave ? `${descarga_url}&clave=${this.state.clave}` : descarga_url;

        let loader = null;
        if (this.state.loader) {
            loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
        }

        let contenidoMostrar = null;

        let anexo = this.props.anexo && this.props.anexo.content ? this.props.anexo.content : [];
        if (anexo.length > 0) {
            anexo = this.props.anexo.content;

            const numRows = this.props.anexo ? this.props.anexo.totalElements : 0;

            contenidoMostrar = (
                <React.Fragment>
                    <Accordion
                        datos={anexo}
                        nombre="anexo"
                        icon={""}
                        tipo={22}
                        subtipo={3}
                    />
                    <Pagination
                        claseTipo={2}
                        numRows={numRows}
                        paginas={5}
                        itemsPerPage={this.state.itemsPerPage}
                        handlePageChange={this.handlePageChange.bind(this)}
                        activePage={this.state.activePage}
                    />
                </React.Fragment>
            );

            resultadoDescarga = (
                <div className={"row margen_30"}>
                    <div className={[classes.card_header, classes.titulo_60pct].join(" ")}>
                        <span className={["regular montse texto_16 "].join(" ")} />
                    </div>
                    <div className={[classes.card_header2, classes.titulo_40pct, "padding_left_5"].join(" ")}>
                        <span
                            className={[classes.titulo_80pct, "texto_16 montse regular"].join(
                                " "
                            )}
                        >
                            <a
                                href={descarga_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="row"
                            >
                                <span
                                    className={[
                                        classes.titulo,
                                        "texto_16 montse color_azul regular"
                                    ].join(" ")}
                                >
                                    {getLan() === "es" ? "Anexo AA" : "Annex AA"}
                                    <i
                                        className={[
                                            "fas fa-arrow-alt-circle-down fa-lg",
                                            "Boton__icono_derecho__25mg6"
                                        ].join(" ")}
                                    />
                                </span>
                                <span
                                    className={[
                                        classes.titulo_ZIP,
                                        "semi_bold montse texto_10"
                                    ].join(" ")}
                                >
                                    {getLan() === "es" ? "Formato XLS" : "XLS file"}
                                </span>
                            </a>
                        </span>
                    </div>
                </div>
            );
        } else if (anexo.length === 0) {
            contenidoMostrar = <p className="montse texto_16 regular"> {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
        } else {
            if (this.state.wasFiltered) {
                contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
            }
        }



        let filtro = (
            <div className="margen_top_30">
                <FilterAnexoAA
                    itemsPerPage={this.state.itemsPerPage}
                    setInstrumento={instrumento => this.onSetInstrumento(instrumento)}
                    setMercado={mercado => this.onSetMercado(mercado)}
                    setPage={page => this.onSetPage(page)}
                    setEmisora={(emisora, tipo) => this.onSetEmisora(emisora, tipo)}
                    tab={this.state.indexSelected}
                    wasFiltered={wasFilteres => this.onSetWasFiltered(wasFilteres)}
                    setParams={filterParams => this.onSetParams(filterParams)}
                    setActivePage={activePage => this.onSetActivePage(activePage)}
                    location={this.props.location}
                />
            </div>
        );

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages}>
                <React.Fragment>
                    {loader}
                    <h1 className={"texto_42 nunito regular"}>
                        <FormattedHTMLMessage id="instrumentosalternativos.anexoaa.titulo1" defaultMessage="Anexo AA" />
                    </h1>
                    {/* <div className="margen_top_30 texto_16 montse regular">
                        <FormattedHTMLMessage id="instrumentosalternativos.anexoaa.introduccion1" defaultMessage=" " />
                    </div> */}
                    <div className="row margen_top_30">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container, "montse"].join(" ")}>
                                <div className="montse">
                                    <span className={["montse"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "instrumentosalternativos.anexoaa.introduccion1"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {filtro}
                    <br />
                    {/* <button
            className={["btn btn-link margen_50", classes.btn_link].join(" ")}
            type="button"
            onClick={this.onShowRssModalHandler}
          >
            <div className="margen_50 texto_16 montse regular texto_naranja">
              {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
              <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </button>
          <Rss
            showRssModal={this.state.showRssModal}
            modalTitle={getLan() === "es" ? "Prospectos de colocación" : "Prospectus"}
            urlRss={this.state.urlForRss}
            rssType={1}
            showClassModel1
          /> */}
                    <div id="anexo" className="margen_50">
                        {resultadoDescarga}
                        <br />
                        {contenidoMostrar}
                    </div>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitAnexo: (params) => {
            dispatch(actions.initAnexoAA(params));
        },
        onInitIntl: () => {
            dispatch(actions.initIntl(86));
        }
    };
};

const mapStateToProps = state => {
    return {
        anexo: state.instrumentosAlternativos.anexoaa,
        diccionario: state.intl.diccionario
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnexoAA);

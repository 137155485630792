import React, { Component } from "react";
import classes from "./Eventos.css";
import { getRealDate, getRealDateMillies, getLan } from "../../stores/utils/utilities";

import Item from "./item/Item";
import Pagination from "../ui/pagination/Pagination";

class Eventos extends Component {
  state = {
    itemsPerPage: 8, 
    activePage: 1,
    resourceList: []
  };

  componentDidMount() {
    let resource = this.props.datos;
    this.onPageChangeHandler(resource, this.props.activePage);
  }

  componentDidUpdate(prevProps){
    if (this.props.activePage !== prevProps.activePage) {
      this.onPageChangeHandler(this.props.datos, this.props.activePage);
    }
    if (this.props.datos !== prevProps.datos) {
      this.onPageChangeHandler(this.props.datos, this.props.activePage);
    }
  }


  onPageChangeHandler = (resource = null, pageNumber = null, videoSeleted = null) => {
    let index_final = null;
    let index_inicial = null;
    let data = resource ? resource : this.props.datos;
    let resourceList = [];
    if (pageNumber) {
      index_final = pageNumber * this.state.itemsPerPage;
      index_inicial = index_final - this.state.itemsPerPage;
    } else {
      index_final = this.state.itemsPerPage;
      index_inicial = 0;
    }
    resourceList = data.slice(index_inicial, index_final);

    if (videoSeleted) {
      const urlVieo = resource[0].url;
      this.setState({
        resourceList: resourceList,
        selectedVideo: urlVieo
      });
    } else if (pageNumber) {

      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  onGetDateFormatHandler = item => {
    if (this.props.es_evento) {
      return new Date(getRealDate(item.eventDate));
    } else {
      return new Date(getRealDateMillies(item.creationDateInMillis));
    }
  };

  onPrintResourceHandler = () => {
    return this.state.resourceList.map((resource, i) => {
      const fecha = this.onGetDateFormatHandler(resource);
      return (
        <Item
          key={i}
          datos={resource}
          fecha={fecha}
          id={resource.id}
          es_evento={this.props.es_evento}
          redirecciona={this.props.redirecciona}
          eventSelected={(eventId) => this.props.eventSelected(eventId)}
        />
      );
    });
  };

  handlePageChange(pageNumber) {
    this.props.pageSelected(pageNumber);
    
    this.onPageChangeHandler(null, pageNumber);
  }

  render() {
    const numRows = this.props.datos ? this.props.datos.length : 0;
    const items = this.props.datos && this.props.datos.length > 0 ? this.onPrintResourceHandler() : null;

    const contenidoMostrar =
      this.props.datos && this.props.datos.length === 0 ? (
        <span className={[classes.message, "texto_16_17 montse regular color_negro"].join(" ")}>
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </span>
      ) : null;

    return (
      <React.Fragment>
        {contenidoMostrar}
        <div className={["col-sm-12 sin_padding", classes.relativo].join(" ")}>
          <div className={"row"}>{items}</div>
          <div className={"col-sm-12 sin_padding"}>
            <Pagination
              claseTipo={2}
              numRows={numRows}
              paginas={5}
              itemsPerPage={this.state.itemsPerPage}
              handlePageChange={this.handlePageChange.bind(this)}
              activePage={this.state.activePage}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Eventos;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../stores/actions/index";
import BackgroundContainer from "../ui/backgroundContainer/BackgroundContainer";
import classes from "./Reporteo.css";
import EsgContainer from "./EsgContainer";


const PlataformaReporteo = () => {
  const dispatch = useDispatch();

  const [reporteo, setReporteo] = useState([]);

  const reporteoRedux = useSelector(
    (state) => state.bivaSustentable.reporteo
  );

  useEffect(() => {
    dispatch(actions.getPlataformaReporteo());
  }, []);

  useEffect(() => {
    if (reporteoRedux) {
      setReporteo(reporteoRedux);
    }
  }, [reporteoRedux]);

  return <>
  <EsgContainer  page="esg-experience"/>
  <div className={classes.imgBackground}>
    <BackgroundContainer divs={reporteo} />;
  </div>
  </>
};

export default PlataformaReporteo;

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils/utilities';

const initialState = {
    terminos_funciones: null
}

const setInitTerminosFunciones = (state, action) => {
    return updateObject(state, action);
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.FETCH_TERMINOS_FUNCIONES_SUCCESS: return setInitTerminosFunciones(state, action);
        default: return state;
    }
}

export default reducer;
import React, { Component } from "react";
import classes from "./Box.css";
import { getLan, getUrlDocument } from "../../../stores/utils/utilities";

class Box extends Component {
  boxModelHandler = () => {
    switch (this.props.modelo) {
      case "modelo_1":
        return this.firtsModeloBoxOne(this.props.data);
      default:
        return this.firtsModeloBoxOne();
    }
  };

  firtsModeloBoxOne = data => {
    let ruta = null;
    let rightIcon = null;
    let leftIcon = null;
    let subtitle = null;
    if (data.rightIcon) {
      rightIcon = data.rightIcon;
    } else {
      rightIcon = "fas fa-arrow-down";
    }
    if (data.leftIcon) {
      leftIcon = data.leftIcon;
    } else {
      leftIcon = "far fa-file-pdf";
    }

    if (data.docType) {
      subtitle = data.docType.toUpperCase();
    } else {
      subtitle = "PDF";
    }

    if (this.props.formato) {
      subtitle = getLan() === "es" ? `Formato ${this.props.formato}` : `${this.props.formato} Format`;
    }

    let liga_pagina = false;
    if (data.url) {
      if (data.url !== "/") {
        ruta = getUrlDocument(data.url);
        
          if (ruta.includes("/cms/")) {
            if (ruta.includes("?")) {
              ruta = `${ruta}&download=true`;
            } else {
              ruta = `${ruta}?download=true`;
            }
          } else if (ruta.includes("http")) {
            liga_pagina = true;
          }
         
      } else {
        ruta = data.url;
      }
    }

    let contenido = (
      <div className={classes.boxContainer}>
        <div className={[classes.vertical].join(" ")}>
          <div className={["row"].join(" ")}>
            <div className={["text-center", classes.vertical_div, classes.columna].join(" ")}>
              <div className={classes.vertical}>
                <div className={classes.leftIconContainer}>
                  <i className={leftIcon} />
                </div>
              </div>
            </div>
            <div className={["", classes.vertical_div, classes.columna].join(" ")}>
              <div className={classes.vertical}>
                <div className={[classes.infoContainer].join(" ")}>
                  <p className={["nunito", "texto_18", "bold", classes.title].join(" ")}>{data.title}</p>
                  <p className={["nunito", "texto_10 semi_bold", classes.subtitle].join(" ")}>{subtitle}</p>
                </div>
              </div>
            </div>
            <div className={["text-center", classes.vertical_div, classes.columna].join(" ")}>
              <div className={classes.vertical}>
                <div className={classes.rigthIconContainer}>
                  <i className={rightIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (liga_pagina) {
      return (
        <a href={ruta} target="_blank" rel="noopener noreferrer" className={classes.link}>
          {contenido}
        </a>
      );
    }

    return (
      <a href={ruta} rel="noopener noreferrer" className={classes.link}>
        {contenido}
      </a>
    );
  };

  render() {
    const box = this.boxModelHandler();
    return box;
  }
}
export default Box;

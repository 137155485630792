import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;
const context = "/cms/o"

const setInitBlogs = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BLOGS_SUCCESS,
      blogs: null
    };
  }
  return {
    type: actionTypes.FETCH_BLOGS_SUCCESS,
    blogs:data
  };
};

export const getBlogsLifeRay = (params = null) => {
  const queryParams = StringUtils.jsonToQueryString(params);

  return dispatch => {
    axios
      .get(dominio + context +  "/blog/entries" + queryParams)
      .then(response => {
        dispatch(setInitBlogs(response.data));
      })
      .catch(err => dispatch(setInitBlogs(err.data)));
  };
};

const setInitBlog = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BLOG_SUCCESS,
      blog: null
    };
  }
  return {
    type: actionTypes.FETCH_BLOG_SUCCESS,
    blog:data
  };
};

export const getBlogLifeRay = (id) => {
  return dispatch => {
    axios
      .get(dominio + context +  "/blog/entries/" + id)
      .then(response => {
        dispatch(setInitBlog(response.data));
      })
      .catch(err => dispatch(setInitBlog(err.data)));
  };
};

import React, { Component } from "react";
import * as actions from "../../../stores/actions/index";
import Galeria from '../../../components/ui/galeria/Galeria';
import { connect } from "react-redux";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class Auditorio extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };
  componentDidMount(){
    this.props.onInitIntl(16);
    this.props.onImagenes();
    this.props.onVideos();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}><FormattedMessage id="nosotros.auditorio.titulo1" defaultMessage=" " /></h1>
        <div className={"margen_top_30"}>
        <p className={"montse texto_16 regular"}>
        <FormattedMessage id="nosotros.auditorio.introduccion1" defaultMessage=" " />
        </p>
        </div>
        <div className={"margen_top_50"}>
         <Galeria data={null}  tiene_imagenes={true} imagenes={this.props.imagenes} videos={this.props.videos}/>
        </div>
        
        <div className="margen_top_100">
          <span className={"color_azul texto_24"}>/</span>{" "}
          <span className={"texto_24 nunito regular"}><FormattedMessage id="nosotros.auditorio.subtitulo1" defaultMessage=" " /></span>
          <div className={"margen_top_50 row"}>
            <div className="row sin_padding">
              <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
                <div className={"icono_izquierdo"}>
                  <i className={["far fa-envelope icon"].join(" ")} />
                </div>
                <div className="col">
                  <p className={["montse texto_16 negrita"].join(" ")}><FormattedMessage id="nosotros.auditorio.subtitulo.correo" defaultMessage=" " /></p>
                  <p className={"color_azul semi_bold montse texto_16"}><FormattedMessage id="nosotros.auditorio.direccion.correo" defaultMessage=" " /></p>
                </div>
              </div>

              <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
                <div className={"icono_izquierdo"}>
                  <i className={["fas fa-phone-alt icon"].join(" ")} />
                </div>
                <div className="col">
                  <p className={["montse texto_16 negrita"].join(" ")}><FormattedMessage id="nosotros.auditorio.subtitulo.telefono" defaultMessage=" " /></p>
                  <p className={"color_azul semi_bold montse texto_16"}><FormattedMessage id="nosotros.auditorio.numero.telefono" defaultMessage=" " /></p>
                </div>
              </div>


            </div>
          </div>
        </div>

      </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    imagenes: state.documentos.auditorio_imagenes,
    videos: state.documentos.auditorio_videos,
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    },
    onImagenes: () => {
      dispatch(actions.initAuditorioDocumentos());
    },
    onVideos: () => {
      dispatch(actions.initAuditorioVideosDocumentos());
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auditorio);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  bancoInformacion: null,
  tipos_informacion: null,
  tipos_documentos: null,
  tipos_instrumentos: null,
  claves: null,
  periodos_bi: null,
  ejercicios_bi: null,
  emisoras_bancos: null,
  bancoInformacionSearch: null,
  modoListado: null
};

const setBancosInformacion = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BANCOINFORMACION_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_BANCOINFORMACION_TIPOINFORMACION_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_BANCOINFORMACION_TIPODOCUMENTO_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_BANCOINFORMACION_TIPOISTRUMENTO_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_BANCOINFORMACION_BYCLAVE_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_BANCOINFORMACION_ALLBYCLAVE_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_PERIODOSBI_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_EJERCICIOSBI_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_EMISORASBANCOS_SUCCESS:
      return setBancosInformacion(state, action);
    case actionTypes.FETCH_MODO_LISTADO_SUCCESS:
      return setBancosInformacion(state, action);
    default:
      return state;
  }
};

export default reducer;

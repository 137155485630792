import React, { Component } from "react";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Fotos from "../../../components/ui/fotos/Fotos";
import Box from "../../../components/ui/box/Box";
import Boton from "../../../components/ui/boton/Boton";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";

class CasasBolsa extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    descarga: {
      leftIcon: "far fa-file-pdf",
      title: "Descargar solicitud",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url: "https://www.cnbv.gob.mx/Normatividad/Ley%20del%20Mercado%20de%20Valores.pdf"
    }
  };
  componentDidMount() {
    this.props.onInitIntl();
    this.props.onEnlaces();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {

    let solicitud = null;
    if (this.props.solicitudes) {
      solicitud = this.props.solicitudes.map((solicitud, i) => {
        return <Box key={"solicitud_" + i} modelo="modelo_1" data={solicitud}   />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col-12 sin_padding_L">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.casasdebolsa.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.casasdebolsa.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row">
              <span className={"texto_16 montse regular"}>
                <FormattedHTMLMessage id="operaciones.casasdebolsa.casasdebolsa.introduccion1" defaultMessage=" " />
              </span>
            </div>
          </div>
          <div className="margen_top_50">
            <div className={"row relativo zindex1000 "}>
              <Fotos imagenes={this.props.casasBolsaBiva} icono={"fas fa-plus"} tieneHover={false} tipo={3} />
            </div>
          </div>
          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.casasdebolsa.subtitulo2" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row margen_top_30">
              <div className={"relativo zindex1000 "}>
                <Fotos imagenes={this.props.casasBolsaOtras} icono={"fas fa-plus"} tieneHover={false} tipo={3} />
              </div>
            </div>
          </div>
          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.casasdebolsa.subtitulo3" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row ">
              <div className={"relativo zindex1000 "}>
                {solicitud}
              </div>
            </div>
          </div>
          <div className="row margen_top_50 margin_bottom_200">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.casasdebolsa.subtitulo4" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row ">
              <Boton
                ruta={"/operaciones/casas_de_bolsa/formador_de_mercado"}
                tipo={2}
                texto={ getLan() === "es" ? "Conoce como hacerlo" : "know how to do it"}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    terminos: state.accordion.seguimientoInversiones,
    diccionario: state.intl.diccionario,
    casasBolsaBiva: state.imagenes.casaBolsaBiva,
    casasBolsaOtras: state.imagenes.casaBolsaOtras,
    solicitudes: state.documentos.solicitudMiembroBiva,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(25));
    },
    onEnlaces: () => {
      dispatch(action.getImagenesCasaBolsa());
      dispatch(action.getImagenesCasaBolsaOtras());
      dispatch(action.getSolicitudMiembroBiva());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CasasBolsa);

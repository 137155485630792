import axios from "axios";
import * as actionTypes from "./actionTypes";
/* import { getInitDate } from "../utils/utilities"; */
import { getLan } from "../utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setEventos = data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_EVENTOS_SUCCESS,
            eventos: []
          };
    }
  return {
    type: actionTypes.FETCH_EVENTOS_SUCCESS,
    eventos: data
  };
};

export const initEventos = (fecha_inicio = null, fecha_fin = null) => {


  const from = fecha_inicio; 
  const to = fecha_fin ? fecha_fin : new Date().toISOString().slice(0, 10);

  let url = `${dominio}/cms/o/content/events/biva/lang/${lang}`;
  url = from ? url.includes("?") ? `${url}&from=${from}` : `${url}?from=${from}` : url;
  url = to ? url.includes("?") ? `${url}&to=${to}` : `${url}?to=${to}` : url;


  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEventos(response.data));
      })
      .catch(err => dispatch(setEventos(err.data)));
  };
};

const setInitEventoById = evento => {
  return {
    type: actionTypes.FETCH_EVENTOBYID_SUCCESS,
    evento: evento
  };
};

export const initEventoById = id => {
  let url = dominio + "/cms/o/content/events/biva/" + id + "/lang/" + lang;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInitEventoById(response.data));
      })
      .catch(err => console.log(err));
  };
};

import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import classes from "./Evento.css";
import { Link } from "react-router-dom";
import { FormattedDate } from "react-intl";
import { getRealDate, getUrlDocument, getLan } from "../../../../stores/utils/utilities";
import Loader from "../../../../components/loader/Loader";

const dominio = process.env.REACT_APP_DOMINIO;

class Evento extends Component {
  state = {
    loader: false,
    eventos: null

  };
  componentDidMount() {
    if (this.props.eventoId && this.props.eventos) {
      let eventos = this.props.eventos.find(itm => itm.id === parseInt(this.props.eventoId))
      this.setState({
        eventos: eventos
      })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.eventoId !== prevProps.eventoId) {
      window.scrollTo(0, 0);
      if (this.props.eventoId && this.props.eventos) {
        let eventos = this.props.eventos.find(itm => itm.id === this.props.eventoId)
        this.setState({
          eventos: eventos
        })
      }
    }
    if (this.props.eventos !== prevProps.eventos) {
      window.scrollTo(0, 0);
      if (this.props.eventoId && this.props.eventos) {
        let eventos = this.props.eventos.find(itm => itm.id === this.props.eventoId)
        this.setState({
          eventos: eventos
        })
      }
    }

  }
  render() {
    var dato = null;
    let fecha = null;
    let urlImagen = null;
    let title = null;
    let descripcion = null;
    let shortDescription = null;
    if (this.state.eventos !== undefined && this.state.eventos !== null) {
      dato = this.state.eventos;
      shortDescription = dato.description;
      title = dato.title;
      urlImagen = getUrlDocument(dato.pictureRelativeUrl);

      if (shortDescription !== null && shortDescription !== "") {
        descripcion = (
          <div className={[classes.div_contenido].join(" ")}>
            <span className={"color_azul nunito semi_bold texto_16"}>{getLan() === "es" ? "Descripción" : "Description"}</span>
            <br/>
            <div
            className={["montse texto_16 regular"].join(" ")}
            dangerouslySetInnerHTML={{ __html: shortDescription ? shortDescription : "" }}
          />
          </div>
        );
      }
      fecha = <FormattedDate value={getRealDate(dato.eventDate)} year="numeric" month="long" day="2-digit" />;
      let loader = null;
      if (this.state.loader) {
        loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
      }
      return (
        <React.Fragment>
          {loader}
          <div className={this.state.loader ? "d-none" : null}>
            <div className={[classes.regresar_div].join(" ")}>
              <div className={classes.centrar_div}>
                <div onClick={e => this.props.eventSelected(null)}>
                  <div className={[classes.icono_regresar_div].join(" ")}>
                    <i className={["fas fa-arrow-circle-left color_azul", classes.icono2].join(" ")} />
                  </div>
                  <div className={[classes.texto_regresar_div].join(" ")}>
                    <span className={["color_azul texto_14 montse negrita"].join(" ")}>
                      {getLan() === "es" ? " Regresar a eventos" : "Return"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <img className={classes.imagen} src={urlImagen} alt={title} />

            <div className={[classes.titulo_div].join(" ")}>
              <div className="relativo margen_top_30">
                <div className={["main_subtitle"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>{title}</h2>
                </div>
              </div>
            </div>
            <br />

            <div className={[classes.div_contenido].join(" ")}>
              <span className={"color_azul nunito semi_bold texto_16"}>
                {getLan() === "es" ? "Fecha del evento" : "Event date"}
              </span>
              <p className={"montse texto_16 regular"}>{fecha}</p>
            </div>
            {descripcion}
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = state => {
  return {
    eventos: state.eventos.eventos
  };
};

const mapDispatchToProps = dispatch => {
  return {
  
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Evento);

import { updateObject } from "../utils/utilities";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  indice: null, 
  indices: null, 
  indiceTiempoReal:null
};

const setIndice = (state, action) => {
  const newProperties = {
    indice: action.indice
  };
  return updateObject(state, newProperties);
};

const setIndice2 = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INDICE_SUCCESS:
      return setIndice(state, action);
    case actionTypes.FETCH_INDICES_SUCCESS:
      return setIndice2(state, action);
    case actionTypes.FETCH_INDICETIEMPOREAL_SUCCESS:
      return setIndice2(state, action);
    default:
      return state;
  }
};

export default reducer;

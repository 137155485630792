import React, { Component } from "react";
import classes from "./VigilanciaMercado.css";

import Auxs from "../../../hoc/auxs/Auxs";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import Modal from "../../../components/ui/modal/Modal";
import Boton from "../../../components/ui/boton/Boton";

import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
class VigilanciaMercado extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null,
    showModal: false
  };

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    const modal = (
      <Auxs>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} onCloseModal={this.onToggleModalHandler} />
      </Auxs>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="margin_bottom_200">
            <div className="row">
              <div className="col sin_padding_L">
                <div className={[""].join(" ")}>
                  <h1 className={["nunito", "texto_42"].join(" ")}>
                    <FormattedHTMLMessage id="operaciones.vigilanciamercados.titulo1" defaultMessage=" " />
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col sin_padding_L">
                <div className={[classes.text_container, "texto_16", "montse", "margen_top_30"].join(" ")}>
                  <p>
                    <FormattedHTMLMessage id="operaciones.vigilanciamercados.introduccion1" defaultMessage=" " />
                  </p>
                </div>
              </div>
            </div>
          </div>

          {modal}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(37));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VigilanciaMercado);

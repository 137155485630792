import React, { Component } from "react";
import classes from "./FilterSeguimientoSolicitudes.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";
import { changeDateFormat, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isSearch = null,
  options,
  defaultValue = null,
  checked = null,
  campoLabel = null,
  campoLabel2 = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    campoLabel: campoLabel,
    campoLabel2: campoLabel2,
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isSearch: isSearch,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      <label className={["montse", "texto_14", "regular"].join(" ")}>{label}</label>
      <Input config={params} />
    </div>
  );
};

class FilterSeguimientoSolicitudes extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    esconderAutoComplete: false,
    toggleFilters: false,
    disableEnableButtons: true,
    selectedFilters: {
      clave_emisora: [],
      nombre_emisora: [],
      tipo_valor: [],
      tipo_instrumento: [],
      fechas: []
    },
    form_filters: {
      clave_emisora: {
        value: ""
      },
      nombre_emisora: {
        value: ""
      },
      tipo_valor: {
        value: ""
      },
      fecha_inicio: {
        value: ""
      },
      fecha_fin: {
        value: ""
      },
      tipo: null,
      errorDate: false
    },
    form_clave: {
      clave: {
        value: ""
      }
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    clave: null,
    tipoValor: []
  };

  componentDidMount() {
    let paramsString = queryString.parse(this.props.location.search);
  
      let emisora = paramsString["emisora_id"] !== undefined && paramsString["emisora_id"] !== "null" ? paramsString["emisora_id"] : null;
      let clave = paramsString["clave_seleccionada"] !== undefined && paramsString["clave_seleccionada"] !== "null" ? paramsString["clave_seleccionada"] : null;
      if (clave) {
        this.setState({
          toggleInputs: true,
          toggleFilters: false,
          disableEnableButtons: false,
          typeClaveSelected: emisora ? 1 : 0,
          valueClave: clave,
        });
      }
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }
  componentDidUpdate(prevProps) {
    if (this.props.tipoBono !== prevProps.tipoBono){
      if (this.props.tipoBono !==null && prevProps.tipoBono !== null){
        if (this.props.tipoBono.length!== prevProps.tipoBono.length){
          this.limpiaForms();
        }
      }
    }
  }
  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible
    });
  }

  componentWillMount() {
    this.props.onInitEmisorasClave(this.props.tipoBono);
    this.props.onInitEmisorasNombre(this.props.tipoBono);
    this.props.onInitValores(null,this.props.tipoBono);

    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  printFrameHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : this.showInputHandler();
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar Filtros"
                        : "Close"
                      : getLan() === "es"
                      ? "Filtrar"
                      : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-8", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col",
                "col-lg-1",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters ? classes.no_border_search_button_model_one : null
                ].join(" ")}
              >
                <button type="buttom" className={["btn"].join(" ")} disabled>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.printModel()}</div>
          </div>
        </div>
      </div>
    );
  };

  printModel = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              clave_emisora: this.state.form_filters.clave_emisora.value,
              nombre_emisora: this.state.form_filters.nombre_emisora.value,
              tipo_valor: this.state.form_filters.tipo_valor.value,
              fecha_inicio: this.state.form_filters.fecha_inicio.value,
              fecha_fin: this.state.form_filters.fecha_fin.value
            }}
            render={({
              optionsClavesEmisoras = this.props.emisoras ? this.props.emisoras : [],
              optionsNombreEmisoras = this.props.emisorasSortNombre ? this.props.emisorasSortNombre : [],
              optionsValores = this.props.valores ? this.props.valores : [],
              form
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                <div className="row">
                  <div className="col col-lg-4">
                    <Field
                      name="clave_emisora"
                      id="clave_emisora"
                      label={getLan() === "es" ? "Clave de la emisora" : "Ticker"}
                      type="select"
                      elementType="select"
                      isSearch={true}
                      campoLabel={"clave"}
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsClavesEmisoras}
                      onInputChange={event => this.onInputSelectedHandler(event, 0)}
                      component={renderInput}
                    />
                  </div>
                  <div className={["col col-lg-4"].join(" ")}>
                    <Field
                      name="nombre_emisora"
                      id="nombre_emisora"
                      label={getLan() === "es" ? "Nombre de la emisora" : "Corporate name"}
                      type="select"
                      elementType="select"
                      isSearch={true}
                      campoLabel={"nombre"}
                      // campoLabel2={"clave"}
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsNombreEmisoras}
                      onInputChange={event => this.onInputSelectedHandler(event, 1)}
                      component={renderInput}
                    />
                  </div>
                  <div className="col col-lg-4">
                    <Field
                      name="tipo_valor"
                      id="tipo_valor"
                      label={getLan() === "es" ? "Tipo de valor" : "Instrument type"}
                      type="select"
                      elementType="select"
                      isSearch={true}
                      campoLabel={"nombre"}
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsValores}
                      onInputChange={event => this.onInputSelectedHandler(event, 2)}
                      component={renderInput}
                    />
                  </div>
                </div>

                {/* ======================= */}
                <div className="row">
                  <div className={["col", "col-lg-12 sin_padding"].join(" ")}>
                    <div className="row">
                      <div className="col col-lg-4 offset-lg-4">
                        <Field
                          name="fecha_inicio"
                          id="fecha_inicio"
                          type="date"
                          classes={classes.fechaInicio}
                          disabled={this.state.form_filters.fecha_inicio.disabled}
                          label={getLan() === "es" ? "Fecha inicio" : "From"}
                          onInputChange={event => this.onInputSelectedHandler(event, 3)}
                          component={renderInput}
                        />
                      </div>
                      <div className="col col-lg-4">
                        <Field
                          name="fecha_fin"
                          id="fecha_fin"
                          type="date"
                          classes={classes.fechaFin}
                          label={getLan() === "es" ? "Fecha fin" : "To"}
                          disabled={this.state.form_filters.fecha_fin.disabled}
                          onInputChange={event => this.onInputSelectedHandler(event, 4)}
                          component={renderInput}
                        />
                      </div>
                    </div>
                    <div
                      className={[classes.errorContainer, !this.state.form_filters.errorDate ? classes.hidden : null].join(" ")}
                    >
                      <p className={"texto_14 montse regular"}>
                            {getLan() === "es"
                              ? "La fecha final debe ser mayor a la fecha inicial."
                              : "End date must be greater than start date."}
                          </p>
                    </div>
                  </div>
                </div>

                {/* ======================= */}

                <hr />
                {this.sectionButtonHandler(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonHandler = form => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterHandler = event => {
    event.preventDefault();
  };

  onSubmitSearchModelOneHandler = event => {
    event.preventDefault();
  };

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = value => {
    this.onInputChangeHandler(value);
  };

  onInputChangeHandler = (event = null, eraser = null, tipo = null) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;

    if (value !== "") {
      this.props.onFilterEmisorasByClave(value, true, false);
    } else {
      disabled = true;
    }

    if (tipo) {
      this.props.setEmisora(null, 4, true);
      this.props.onInitValores(null, this.props.tipoBono);
    }

    if (eraser) {
      this.setState(prevState => {
        return {
          toggleInputs: !prevState.toggleInputs,
          emisoraSeleccionada: null,
          claveSeleccionada: null,
          form_clave: form_clave,
          disableEnableButtons: true
        };
      });
    } else {
      this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
    }
  };

  limpiaForms() {
    this.props.onInitEmisorasClave(this.props.tipoBono);
    this.props.onInitEmisorasNombre(this.props.tipoBono);
    this.props.onInitValores(null,this.props.tipoBono);

    const form = this.state.form_filters;
    const selectedFilters = this.state.form_filters;

    form.clave_emisora.value = "";
    selectedFilters.clave_emisora = [];
    form.nombre_emisora.value = "";
    selectedFilters.nombre_emisora = [];
    form.tipo_valor.value = "";
    selectedFilters.tipo_valor = [];
    form.fecha_inicio.value = "";
    form.fecha_fin.value = "";
    selectedFilters.fechas = [];


    // this.props.setTipoInstrumento(null, 1);
    // this.props.setTipoValor(null);

    this.props.onInitSolicitudes(this.props.itemsPerPage, 0, null, null, null, null, null,null, this.props.tipoBono);
    this.props.setPage(1);
    this.props.setValor(null);
    this.props.setFechaInicio(null);
    this.props.setFechaFin(null);
    this.props.setEmisora(null, 3, false);

    this.props.history.push(
      `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${1}&empresaId=${null}&razonSocial=${null}&clave=${null}&numeroTramite=${null}&clave_seleccionada=${null}&emisora_id=${null}&valores=${null}&tipoBono=${this.props.tipoBono}&fecha_ini=${null}&fecha_f=${null}`
    );

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });
  }

  onResetFilterHandler = form => {
    form.reset();
    this.limpiaForms();
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type) => {
    let newValue = null;
    let newLabel = null;
    let newName = null;
    if (value !== null) {
      if (type === 0) {
        newLabel = value && value.clave ? value.clave : null;
        newValue = value && value.id ? value.id : null;
        newName = value.nombre ? value.nombre : null;
      } else if (type === 1) {
        newLabel = value && value.clave ? `${value.nombre} - ${value.clave}` : null;
        newValue = value && value.id ? value.id : null;
        newName = value.clave;
      } else if (type === 2) {
        newLabel = value && value.nombre ? value.nombre : null;
        newValue = value && value.id ? value.id : null;
      } else {
        newValue = value;
        newLabel = type === 3 ? "fecha_inicio" : "fecha_fin";
      }
    }

    return [newValue, newLabel, newName];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["clave_emisora", form.clave_emisora.value, selectedFilters.clave_emisora];
      case 1:
        return ["nombre_emisora", form.nombre_emisora.value, selectedFilters.nombre_emisora];
      case 2:
        return ["tipo_valor", form.tipo_valor.value, selectedFilters.tipo_valor];
      case 3:
        return ["fecha_inicio", form.fecha_inicio.value, selectedFilters.fechas, "fechas"];
      case 4:
        return ["fecha_fin", form.fecha_fin.value, selectedFilters.fechas, "fechas"];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesClaveEmisora = form.clave_emisora.value && form.clave_emisora.value !== "" ? form.clave_emisora.value : "";
    // Se obtiene el nombre de la emisora
    const valuesNombreEmisora = form.nombre_emisora.value && form.nombre_emisora.value !== "" ? form.nombre_emisora.value : "";

    // Se obtiene el valor de la emisora
    const valuesTipoValor = form.tipo_valor.value && form.tipo_valor.value !== "" ? form.tipo_valor.value : "";
    // Se obtiene el valor de fecha inicio
    const valuesFechaInicio = form.fecha_inicio.value && form.fecha_inicio.value !== "" ? form.fecha_inicio.value : null;
    // Se obtiene el valor de fecha fin
    const valuesFechaFin = form.fecha_fin.value && form.fecha_fin.value !== "" ? form.fecha_fin.value : null;

    return [valuesClaveEmisora, valuesNombreEmisora, valuesTipoValor, valuesFechaInicio, valuesFechaFin];
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (values = null) => {
    let newStateValues = null;

    if (values !== "") {
      newStateValues = values;
    }
    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];
    if (type === 3 || type === 4) {
      // Dates
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(option => option.label === label);
      if (filterExists) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
        if (values !== "") {
          newFilterValues.push({
            label: label,
            value: changeDateFormat(values),
            type: type
          });
        }
      } else {
        newFilterValues.push({
          label: label,
          value: changeDateFormat(values),
          type: type
        });
      }
    } else {
      if (values !== null && values !== "") {
        newFilterValues.push({
          label: label,
          value: values,
          type: type
        });
      }
    }
    return newFilterValues;
  };

  onFilterDataByEvent = () => {
    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveEmisora,
      valuesNombreEmisora,
      valuesTipoValor,
      valuesFechaInicio,
      valuesFechaFin
    ] = this.onGetAllValuesFormFilter();

    if (!this.state.form_filters.errorDate) {
      this.props.onInitSolicitudes(
        this.props.itemsPerPage,
        0,
        valuesClaveEmisora,
        valuesTipoValor,
        valuesFechaInicio,
        valuesFechaFin,
        null,
        null,
        this.props.tipoBono
      );

      this.props.setPage(1);
      this.props.history.push(
        `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${1}&empresaId=${null}&razonSocial=${null}&clave=${null}&numeroTramite=${null}&clave_seleccionada=${null}&emisora_id=${valuesClaveEmisora}&valores=${valuesTipoValor}&fecha_ini=${valuesFechaInicio}&fecha_f=${valuesFechaFin}`
      );
    }

    // Se invoca la funcion del componente padre (SeguimientosSolicitudes.js) para setear los valores correspondientes
    // Se configura el valor de la emisora seleccionada
    if (!this.state.form_filters.errorDate) {
      this.props.setEmisora(valuesClaveEmisora, 1);
      // Se configura el tipo valor seleccionado
      this.props.setValor(valuesTipoValor);
      // Se configura el valor de la fecha inicio seleccionada
      this.props.setFechaInicio(valuesFechaInicio);
      // Se configura el valor de la fecha fin seleccionada
      this.props.setFechaFin(valuesFechaFin);
    }
  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (event, type = null) => {
    const actualValue = type !== 3 && type !== 4 ? event : event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let errorDate = form.errorDate;

    let newValue = null;
    let newLabel = null;
    let newName = null;

    // Solo para los campos distintos a tipo instrumento y tipo valor
    [newValue, newLabel, newName] = this.onGetValueLabelAndTypeHandler(actualValue, type);

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(newValue);

    if (newLabel !== null) {
      if (type === 0) {
        newLabel = `Clave : ${newLabel}`;
      } else if (type === 1) {
        newLabel = `Emisora : ${newLabel}`;
      }
    }

    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveEmisora,
      valuesNombreEmisora,
      valuesTipoValor,
      valuesFechaInicio,
      valuesFechaFin
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      // Clave emisora
      // Limpiando valores para campo tipo valor
      form.tipo_valor.value = "";
      selectedFilters.tipo_valor = [];
      if (valuesClaveEmisora !== "") {
        // Filtrando campo tipo valor
        this.props.onInitValores(valuesClaveEmisora,this.props.tipoBono);
      } else {
        // Filtrando campo tipo valor
        this.props.onInitValores(null, this.props.tipoBono);
      }

      let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(1);

      // Configura el estado y el filtro seleccionado para cada input
      newStateValues = this.onChangeStateHandler(newValue);

      if (newLabel !== null) {
        newName = `Emisora : ${newName}`;
      }
      newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newName, type);

      form[`${inputName}`].value = newStateValues;
      selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;
    } else if (type === 1) {
      let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(0);

      // Configura el estado y el filtro seleccionado para cada input
      newStateValues = this.onChangeStateHandler(newValue);

      newName = `Clave : ${newName}`;
      newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newName, type);

      form[`${inputName}`].value = newStateValues;
      selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;
    } else if (type === 2) {
      // Tipo valor
      // Pass
    } else if (type === 3) {
      // fecha inicio
      // Se verifican si las fechas osn validas
      if (newValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(newValue, form.fecha_fin.value);
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 4) {
      // Fecha fin
      // Se verifican si la sfechas osn validas
      if (newValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(form.fecha_inicio.value, newValue);
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  onDeleteFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(item.type);

    newStateValues = this.onChangeStateHandler();
    newFilterValues = this.onChangeFilterHandler(filterValues, "", item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;

    if (item.type === 0) {
      // Clave emisora
      // Limpiando valores para campo tipo valor
      form.tipo_valor.value = "";
      selectedFilters.tipo_valor = [];
      // Limpiando valores para campo nombre emisora
      form.nombre_emisora.value = "";
      selectedFilters.nombre_emisora = [];
      // Filtrando campo tipo valor
      this.props.onInitValores(null, this.props.tipoBono);
    } else if (item.type === 1) {
      // Nombre emisora
      // Limpiando valores para campo clave emisora
      form.clave_emisora.value = "";
      selectedFilters.clave_emisora = [];
    } else if (item.type === 2) {
      // Tipo valor
      // Pass
    } else if (item.type === 3) {
      // fecha inicio
      form.errorDate = false;
    } else if (item.type === 4) {
      // Fecha fin
      form.errorDate = false;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    const selectedFilters = this.state.selectedFilters;
    const filterList = [
      ...selectedFilters.clave_emisora,
      ...selectedFilters.nombre_emisora,
      ...selectedFilters.tipo_valor,
      ...selectedFilters.fechas
    ];
    let filters = null;
    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map((item, i) => (
          <div className={classes.filter_container_model_one} key={`${item.label}-${item.id}-${i}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>
              {item.type !== 3 && item.type !== 4 ? item.label : item.value}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.props.setEmisora(null, 4, true);
      this.props.onInitValores(null,this.props.tipoBono);

      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null
      });
    }
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map((item, i) => (
      <li key={i} className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}>
        {item.clave}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    let emisoraSeleccionada = null;
    let claveSeleccionada = null;

    const form = this.state.form_clave;
    const selectedFilters = this.state.selectedFilters;

    this.onToggleFilterInputOptionSelected();

    if (option === 0) {
      this.props.setEmisora(clave, 2, true, clave);
      claveSeleccionada = clave;
    } else {
      emisoraSeleccionada = id;
      claveSeleccionada = clave;
      this.props.setEmisora(emisoraSeleccionada, 1, true, claveSeleccionada);

      form.clave.value = id;
      this.props.onInitValores(id,this.props.tipoBono);
    }

    selectedFilters.emisora = claveSeleccionada;

    this.setState({
      form_clave: form,
      selectedFilters: selectedFilters,
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: emisoraSeleccionada,
      clave: claveSeleccionada
    });
  };

  onPrintOptionSelectedHandler = value => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
        <span className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onInputChangeHandler(null, true, true)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.listadoClaves ? this.onPrintAutoCompleteHandler() : null;

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {getLan() === "es" ? `Ver todos los resultados para: ${value}` : `See all results for: ${value}`}
        </li>
      ) : (
        <li className="list-group-item">
          {getLan() === "es" ? "No existen resultados para la búsqueda:" : "There are no results for the search:"} {value}
        </li>
      );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div
          id="searchInput"
          className={[classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null].join(" ")}
        >
          <Form
            onSubmit={this.onSubmitSearchModelOneHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value
            }}
            render={() => (
              <form ref={this.formSearch} onSubmit={this.onSubmitSearchModelOneHandler}>
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  placeholder={getLan() === "es" ? "Buscar por clave de cotización" : "Search by ticker"}
                  component={renderInput}
                  onInputChange={event => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              !this.state.form_clave.clave.value || this.state.form_clave.clave.value === "" ? classes.hidden : null,
              this.state.esconderAutoComplete ? classes.hidden : null
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    return this.printFrameHandler();
  }
}

const mapStateToProps = state => {
  return {
    emisoras: state.tramites.emisoras,
    emisorasSortNombre: state.tramites.emisorasSortNombre,
    valores: state.tramites.valores,
    listadoClaves: state.tramites.claves
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitSolicitudes: (size, page, emisora_id, valor, fechaInicio, fechaFin, clave, idTramite, tipoBono = null) => {
      dispatch(actions.initSolicitudes(size, page, emisora_id, valor, fechaInicio, fechaFin, clave, idTramite, tipoBono));
    },
    onInitEmisorasClave: (tipoBono = null) => {
      dispatch(actions.initEmisorasTramites(null, tipoBono));
    },
    onInitEmisorasNombre: (tipoBono = null ) => {
      dispatch(actions.initEmisorasTramites("nombre", tipoBono));
    },
    onInitValores: (emisora, tipoBono = null ) => {
      dispatch(actions.initValoresTramites(emisora, tipoBono));
    },
    onFilterEmisorasByClave: clave => {
      dispatch(actions.initClavesTramites(clave));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterSeguimientoSolicitudes)
);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  ramos: null,
  sub_ramos: null
};

const setRamos = (state, action) => {
  return updateObject(state, action);
};

const setSubRamos = (state, action) => {
    return updateObject(state, action);
} 

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RAMOS_SUCCESS:
      return setRamos(state, action);
    case actionTypes.FETCH_SUBRAMOS_SUCCESS:
      return setSubRamos(state, action); 
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./Estatales.css";
import { connect } from "react-redux";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import * as action from "../../../stores/actions/index";
import { getLocal, getUrlDocument } from "../../../stores/utils/utilities";

import Auxs from "../../../hoc/auxs/Auxs";

class Estatales extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };
  componentDidMount() {
    this.props.onInitIntl(2);

    this.props.onImagenesEstatales();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);

        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }
  }

  render() {
   let imagenes = null;
    if (this.props.imagenes_estatales) {

      imagenes = this.props.imagenes_estatales.map((img, i) => {
        let ruta = null;
        if (img.url) {
          ruta = getUrlDocument(img.url);
        }

        return (<img key={i} src={ruta} alt={img.title} className={classes.img} />);
      });
    }

    if (this.state.messages) {
      return (
        <IntlProvider locale={this.state.lan} messages={this.state.messages}>
          <Auxs>
            <div className="row">
              <div className="col sin_padding_L">
                <div>
                  <h1 className={[classes.main_title, "texto_42", "nunito"].join(" ")}>
                    <FormattedMessage id="alianzas.convenioyasociaciones.estatales.titulo" defaultMessage=" " />
                  </h1>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col sin_padding_L">
                <div className={[classes.text_container, "texto_16", "montse"].join(" ")}>
                  <p>
                    <FormattedMessage id="alianzas.convenioyasociaciones.estatales.introduccion" defaultMessage=" " />
                  </p>
                </div>
              </div>
            </div>
            <div className="row margin_bottom_300 margin_top_50">
              <div className="col sin_padding_L">
                <div className={classes.imgContainer}>{imagenes}</div>
              </div>
            </div>
          </Auxs>
        </IntlProvider>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    imagenes_estatales: state.imagenes.alianzas_states
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
        dispatch(action.initIntl(pagina));
      },
    onImagenesEstatales: () => {
      dispatch(action.initImagenesAlianzasStates());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Estatales);

import React, { Component } from "react";
import classes from "./Table.css";
import { formatNumber, getRealDateMilliesDayMonthYearLocale, formatNumberDecimal6, formatInt } from "../../stores/utils/utilities";
import { getLan } from "../../stores/utils/utilities";


class Table extends Component {
    state = {


    };

    render() {
        let report = null;
        if (this.props.datos) {
            if (this.props.tipo === 1) {
                report = (
                    <div className={classes.footer}>
                        <div className={classes.encabezado_footer}>
                            <h1 className={"texto_30 nunito regular color_white"}>
                                {getLan() === "es" ? "Resumen Financiero" : "FINANCIAL SUMMARY"}
                            </h1>
                        </div>
                        <div className="row">
                            <div className={classes.titulo_footer}>
                                <table className={classes.table_footer}>
                                    <thead>
                                        <br />
                                        <tr className={classes.tr_header}>
                                            <td className={"texto_16 nunito semi_bold "}>
                                                {getLan() === "es" ? "Actividad Económica" : "ECONOMIC ACTIVITY"}
                                            </td>
                                            {
                                                this.props.datos.map((dato, i) =>
                                                    <td className={"texto_16 nunito semi_bold "}>{dato.title}</td>
                                                )
                                            }
                                        </tr>
                                        <br />
                                    </thead>
                                </table>
                            </div>
                            <div className={classes.titulo_body}>
                                <br />
                                <table className={classes.table_footer}>
                                    <tbody>
                                        <tr className={classes.tr_footer}>
                                            <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Ingresos" : "Revenue"}</td>
                                            {
                                                this.props.datos.map((dato, i) =>
                                                    <td className={"nunito texto_24 bold"}>{dato.revenue}</td>
                                                )
                                            }
                                        </tr>
                                        <br />
                                        <tr className={classes.tr_footer}>
                                            <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Utilidad bruta" : "Gross Profit"}</td>
                                            {
                                                this.props.datos.map((dato, i) =>
                                                    <td className={"nunito texto_24 bold"}>{dato.grossProfit}</td>
                                                )
                                            }
                                        </tr>
                                        <br />
                                        <tr className={classes.tr_footer}>
                                            <td className={"montse texto_16 regular"}>
                                                {getLan() === "es" ? "Utilidad por operación" : "Operating Income"}
                                            </td>
                                            {
                                                this.props.datos.map((dato, i) =>
                                                    <td className={"nunito texto_24 bold"}>{dato.operationIncome}</td>
                                                )
                                            }
                                        </tr>
                                        <br />
                                        <tr className={classes.tr_footer}>
                                            <td className={"montse texto_16 regular"}>
                                                {getLan() === "es" ? "Utilidad antes de impuestos" : "Income Before Tax"}
                                            </td>
                                            {
                                                this.props.datos.map((dato, i) =>
                                                    <td className={"nunito texto_24 bold"}>{dato.netIncomeBeforeTaxes}</td>
                                                )
                                            }
                                        </tr>
                                        <br />
                                        <tr className={classes.tr_footer}>
                                            <td className={"montse texto_16 regular"}>
                                                {getLan() === "es" ? "Utilidad neta" : "Net Income"}
                                            </td>
                                            {
                                                this.props.datos.map((dato, i) =>
                                                    <td className={"nunito texto_24 bold"}>{dato.netIncomeBeforeExtraItems}</td>
                                                )
                                            }
                                        </tr>
                                        <br />
                                        <tr className={classes.tr_footer}>
                                            <td className={"montse texto_16 regular"}>
                                                {getLan() === "es" ? "Utilidad por acción" : "Earnings Per Share"}
                                            </td>
                                            {
                                                this.props.datos.map((dato, i) =>
                                                    <td className={"nunito texto_24 bold"}>{dato.dilutedEPSExcludingExtraOrdItems}</td>
                                                )
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                );
            } else if (this.props.tipo === 2) {
                report = (
                    <div className={classes.footer}>
                        <div className={classes.encabezado_footer}>
                            <h1 className={"texto_30 nunito regular color_white"}>
                                {getLan() === "es" ? "Cotización" : "Quotation"}
                            </h1>
                        </div>
                        <div className={classes.titulo_body}>
                            <br />
                            <table className={classes.table_footer}>
                                <tr className={classes.tr_footer}>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Apertura" : "Open"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.open}</td>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Relación p/e" : "P/E Ratio"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.pe}</td>
                                </tr>
                                <br />
                                <tr className={classes.tr_footer}>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Cierre previo" : "Previous close"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.nPrice}</td>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Volumen" : "Volume"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.volume}</td>
                                </tr>
                                <br />
                                <tr className={classes.tr_footer}>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Rango del día" : "Day range"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.nLow} - {this.props.datos.nHigh}</td>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Volumen promedio" : "Average volume"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.vol3mAverage}</td>
                                </tr>
                                <br />
                                <tr className={classes.tr_footer}>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Rango a 52 semanas" : "52 week range"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.nLow52Weeks} - {this.props.datos.nHigh52Weeks}</td>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Precio / Valor en libros" : "Price to Book Value"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.bookValue}</td>
                                </tr>
                                <br />
                                <tr className={classes.tr_footer}>
                                    <td className={"montse texto_16 regular"}>{getLan() === "es" ? "Mercado" : "Market"}</td>
                                    <td className={"nunito texto_24 bold"}>{this.props.datos.marketCap}</td>
                                    <td className={"montse texto_16 regular d-none"}>{getLan() === "es" ? "Ev / EBITDA" : "Ev / EBITDA"}</td>
                                    <td className={"nunito texto_24 bold d-none"}>{this.props.datos.ebitda}</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                );
            } else if (this.props.tipo === 3) {
                report = (
                    <div className={classes.footer2}>
                        <div className={classes.encabezado_footer}>
                            <h1 className={"texto_30 nunito regular color_gris"}>
                            </h1>
                        </div>
                        <div className="row">
                            <div className={[classes.tbody_100].join(" ")}>
                                <table className={[classes.tbody_100].join(" ")}>
                                    <tr className={[classes.tbody_100].join(" ")}>
                                        <td className={[classes.tbody_10, "texto_16 nunito semi_bold color_azul "].join(" ")}>
                                            {getLan() === "es" ? "Serie" : "Serie"}
                                        </td>
                                        <td className={[classes.tbody_20, "texto_16 nunito semi_bold color_azul "].join(" ")}>
                                            {getLan() === "es" ? "Valor contable" : "Accounting value"}
                                        </td>
                                        <td className={[classes.tbody_10, "texto_16 nunito semi_bold color_azul "].join(" ")}>

                                        </td>
                                        <td className={[classes.tbody_20, "texto_16 nunito semi_bold color_azul "].join(" ")}>
                                            {getLan() === "es" ? "Precio" : "Price"}
                                        </td>
                                        <td className={[classes.tbody_20, "texto_16 nunito semi_bold color_azul "].join(" ")}>
                                            {getLan() === "es" ? "No. operación" : "Transactions"}
                                        </td>
                                        <td className={[classes.tbody_20, "texto_16 nunito semi_bold color_azul "].join(" ")}>
                                            {getLan() === "es" ? "Volumen" : "Volume"}
                                        </td>
                                    </tr>
                                    <tr className={[classes.tbody_100].join(" ")}>
                                        {
                                            this.props.datos.map((dato, i) =>
                                                <React.Fragment>
                                                    <td className={[classes.tr_header2, classes.tbody_10, "texto_22 nunito semi_bold"].join(" ")}>{dato.serie}</td>
                                                    <td className={[classes.tr_header2, classes.tbody_20, "texto_16 montse regular"].join(" ")}>{dato.valorContable}</td>
                                                    <td className={[classes.tr_header2, classes.tbody_70].join(" ")}>
                                                        <tr className={[classes.tr_header2, classes.tbody_100].join(" ")}>
                                                            <td className={[classes.tr_body, classes.tbody_10, "texto_14 nunito italic "].join(" ")}>{getLan() === "es" ? "COMPRA" : "ASK"}</td>
                                                            <td className={[classes.tr_body, classes.tbody_30, "texto_16 montse regular "].join(" ")}>{dato.precioCompra}</td>
                                                            <td className={[classes.tr_body, classes.tbody_30, "texto_16 montse regular "].join(" ")}>{dato.operacionCompra}</td>
                                                            <td className={[classes.tr_body, classes.tbody_30, "texto_16 montse regular "].join(" ")}>{formatNumber(dato.volumenCompra)}</td>
                                                        </tr>
                                                        <tr className={[classes.tr_header2, classes.tbody_100].join(" ")}>
                                                            <td className={[classes.tr_bodyBackground, classes.tbody_10, "texto_14 nunito italic "].join(" ")}>{getLan() === "es" ? "VENTA" : "BID"}</td>
                                                            <td className={[classes.tr_bodyBackground, classes.tbody_30, "texto_16 montse regular "].join(" ")}>{dato.precioVenta}</td>
                                                            <td className={[classes.tr_bodyBackground, classes.tbody_30, "texto_16 montse regular "].join(" ")}>{dato.operacionVenta}</td>
                                                            <td className={[classes.tr_bodyBackground, classes.tbody_30, "texto_16 montse regular "].join(" ")}>{formatNumber(dato.volumenVenta)}</td>
                                                        </tr>
                                                    </td>
                                                </React.Fragment>
                                            )
                                        }
                                    </tr>


                                </table>
                            </div>



                        </div>
                    </div>
                );

            } else if (this.props.tipo === 4) {
                report = (
                    <div className={[classes.table_wrapper_2].join(" ")}>
                        {/* <h2>Striped Rows</h2>
                        <p>The .table-striped class adds zebra-stripes to a table:</p> */}
                        {/* <h2>Serie {this.props.serie}</h2> */}
                        <table className={["table table-hover table-bordered table-striped  ", classes.table].join(" ")}>
                            <thead className={classes.thead}>
                                <tr>
                                    <th className={["texto_16 nunito bold color_azul"].join(" ")}>{getLan() === "es" ? "Fondo" : "Ticker fund"}</th>
                                    <th className={["texto_16 nunito bold color_azul"].join(" ")}>{getLan() === "es" ? "Serie" : "Serie"}</th>
                                    <th className={["texto_16 nunito bold color_azul"].join(" ")}>{getLan() === "es" ? "Fecha" : "Date"}</th>
                                    <th className={["texto_16 nunito semi_bold color_azul"].join(" ")}>{getLan() === "es" ? "Valor contable" : "Accounting value"}</th>
                                    <th className={["texto_16 nunito semi_bold color_azul"].join(" ")}>{getLan() === "es" ? "Precio compra" : "Ask price"}</th>
                                    <th className={["texto_16 nunito semi_bold color_azul"].join(" ")}>{getLan() === "es" ? "Precio venta" : "Bid price"}</th>
                                    <th className={["texto_16 nunito semi_bold color_azul"].join(" ")}>{getLan() === "es" ? "Operaciones Compra" : "Ask transactions"}</th>
                                    <th className={["texto_16 nunito semi_bold color_azul"].join(" ")}>{getLan() === "es" ? "Operaciones Venta" : "Bid transactions"}</th>
                                    <th className={["texto_16 nunito semi_bold color_azul"].join(" ")}>{getLan() === "es" ? "Volumen Compra" : "Ask volume"}</th>
                                    <th className={["texto_16 nunito semi_bold color_azul"].join(" ")}>{getLan() === "es" ? "Volumen Venta" : "Bid volume"}</th>
                                </tr>
                            </thead>
                            <tbody className={classes.tbody}>

                                {
                                    this.props.datos.content.map((dato, i) =>
                                        <React.Fragment>
                                            <tr className={classes.tr}>
                                                <td className={["texto_16 nunito semi_bold nowrap"].join(" ")}>{dato.clave}</td>
                                                <td className={["texto_16 nunito regular "].join(" ")}>{dato.serie}</td>
                                                <td className={["texto_16 nunito semi_bold "].join(" ")}>{getRealDateMilliesDayMonthYearLocale(dato.fechaPrecio)}</td>
                                                <td className={["texto_16 montse regular "].join(" ")}>{dato.valorContable}</td>
                                                <td className={["texto_16 montse regular "].join(" ")}>{dato.precioCompra}</td>
                                                <td className={["texto_16 montse regular "].join(" ")}>{dato.precioVenta}</td>
                                                <td className={["texto_16 montse regular "].join(" ")}>{dato.operacionCompra}</td>
                                                <td className={["texto_16 montse regular "].join(" ")}>{dato.operacionVenta}</td>
                                                <td className={["texto_16 montse regular "].join(" ")}>{formatNumber(dato.volumenCompra)}</td>
                                                <td className={["texto_16 montse regular "].join(" ")}>{formatNumber(dato.volumenVenta)}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                );
            } else if (this.props.tipo === 5) {
                let index_final_temp = (this.props.activePage * 10);
                let indexFinal = (this.props.activePage * 10) <= this.props.datos.length ? (this.props.activePage * 10) : this.props.datos.length;;
                let indexInicial = (index_final_temp) - 10 ;

                let datos = this.props.datos.slice(indexInicial, indexFinal);
                report = (
                    <div className={[classes.table_wrapper_2].join(" ")}>
                        {/* <h2>Striped Rows</h2>
                        <p>The .table-striped class adds zebra-stripes to a table:</p> */}
                        {/* <h2>Serie {this.props.serie}</h2> */}
                        <table className={["table table-hover table-bordered table-striped  ", classes.table].join(" ")}>
                            <thead className={classes.thead}>
                                <tr>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Emisora" : "Issuer"}</th>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Serie" : "Serie"}</th>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Tipo de valor" : "Instrument type"}</th>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Títulos operados" : "Traded securities"}</th>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Títulos en circulación" : "Outstanding securities"}</th>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Precio promedio" : "Average price"}</th>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Valor razonable" : "Fair value"}</th>
                                    <th className={["texto_16 nunito bold color_azul texto_centro"].join(" ")}>{getLan() === "es" ? "Días por vencer" : "Days to maturity"}</th>
                                </tr>
                            </thead>
                            <tbody className={classes.tbody}>

                                {
                                    datos.map((dato, i) =>
                                        <React.Fragment>
                                            <tr className={classes.tr}>
                                                <td className={["texto_16 nunito regular texto_centro "].join(" ")}>{dato.emisora}</td>
                                                <td className={["texto_16 nunito regular texto_centro"].join(" ")}>{dato.serie}</td>
                                                <td className={["texto_16 nunito regular texto_centro"].join(" ")}>{dato.tipoValor}</td>
                                                <td className={["texto_16 nunito regular texto_derecha"].join(" ")}>{formatInt(dato.titulosOperados)}</td>
                                                <td className={["texto_16 nunito regular texto_derecha"].join(" ")}>{formatInt(dato.titulosCirculacion)}</td>
                                                <td className={["texto_16 nunito regular texto_derecha"].join(" ")}>{formatNumberDecimal6(dato.precioPromedio)}</td>
                                                <td className={["texto_16 nunito regular texto_derecha"].join(" ")}>{formatNumberDecimal6(dato.valorRazonable)}</td>
                                                <td className={["texto_16 nunito regular texto_centro"].join(" ")}>{dato.diasVencer}</td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                );
            }

        }

        return (
            <React.Fragment>
                {report}
            </React.Fragment>


        )

    }

}

export default Table;

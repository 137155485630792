import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/empresas`;

const setEmisoras = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORAS_SUCCESS,
      emisoras: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORAS_SUCCESS,
    emisoras: data
  };
};

const setEmisoraDetalle = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORADETALLE_SUCCESS,
      emisoraDetalle: null
    };
  }
  return {
    type: actionTypes.FETCH_EMISORADETALLE_SUCCESS,
    emisoraDetalle: data
  };
};

export const initEmisorasDetail = empresaId => {
  let url = `${dominio}/emisoras/empresas/${empresaId}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisoraDetalle(response.data));
      })
      .catch(err =>  dispatch(setEmisoraDetalle(err.data)));
  };
};

const setEmisoraIntegrantes = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORAINTEGRANTES_SUCCESS,
      emisoraIntegrantes: null
    };
  }
  return {
    type: actionTypes.FETCH_EMISORAINTEGRANTES_SUCCESS,
    emisoraIntegrantes: data
  };
};

export const initEmisorasIntegrantes = empresaId => {
  let url = `${dominio}/emisoras/empresas/${empresaId}/integrantes`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisoraIntegrantes(response.data));
      })
      .catch(err => dispatch(setEmisoraIntegrantes(err.data)));
  };
};

const setDocumentos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_DOCUMENTOS_SUCCESS,
      documentos: []
    };
  }
  return {
    type: actionTypes.FETCH_DOCUMENTOS_SUCCESS,
    documentos: data
  };
};

export const initDocumentos = (size, page, empresaId, tipoDocumento, periodo, ejercicio) => {
  let url = `${dominio}/emisoras/empresas/${empresaId}/documentos?size=${size}&page=${page}`;
  url = tipoDocumento && tipoDocumento.length > 0 ? `${url}&tipoDocumento=${tipoDocumento}` : url;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDocumentos(response.data));
      })
      .catch(err => dispatch(setDocumentos(err.data)));
  };
};

export const filterDocumentos = ({
  size,
  page,
  empresaId,
  tipoInformacion = null,
  tipoDocumento = null,
  fechaInicio = null,
  fechaFin = null,
  periodo = null,
  ejercicio = null,
  tipo = null
}) => {
  console.log(ejercicio);
  let url = `${dominio}/emisoras/empresas/${empresaId}/documentos?size=${size}&page=${page}`;

  url = tipoInformacion
    ? `${url}&${tipo === "listado" ? "tipoInformacionListado" : "tipoInformacion"}=${tipoInformacion}`
    : url;
  url =
    tipoDocumento && tipoDocumento.length > 0
      ? `${url}&${tipo === "listado" ? "tipoDocumentoListado" : "tipoDocumento"}=${[...tipoDocumento]}`
      : url;
  url = fechaInicio ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin ? `${url}&fechaFin=${fechaFin}` : url;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setDocumentos(response.data));
      })
      .catch(err => dispatch(setDocumentos(err.data)));
  };
};

const setReporteMensual = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_REPORTEMENSUAL_SUCCESS,
      reporteMensual: []
    };
  }
  return {
    type: actionTypes.FETCH_REPORTEMENSUAL_SUCCESS,
    reporteMensual: data
  };
};

export const initDocumentosReporteMensual = (size, page, empresaId, periodo, ejercicio) => {
  let url = `${dominio}/emisoras/empresas/calificadoras/${empresaId}/reportes?size=${size}&page=${page}`;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setReporteMensual(response.data));
      })
      .catch(err => dispatch(setReporteMensual(err.data)));
  };
};

const setValoresListados = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VALORESLISTADOS_SUCCESS,
      valoresListados: []
    };
  }
  return {
    type: actionTypes.FETCH_VALORESLISTADOS_SUCCESS,
    valoresListados: data
  };
};

export const initValoresListados = (size, page, empresaId, cotizacion=false) => {
  let url = `${dominio}/emisoras/empresas/${empresaId}/emisiones?size=${size}&page=${page}`;
  url = cotizacion === true ? `${url}&cotizacion=true` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setValoresListados(response.data));
      })
      .catch(err => dispatch(setValoresListados(err.data)));
  };
};

const setValores = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VALORESLISTADOS_SUCCESS,
      valoresListadosMin: null
    };
  }
  return {
    type: actionTypes.FETCH_VALORESLISTADOS_SUCCESS,
    valoresListadosMin: data
  };
};

export const initValoresListadosMin = empresaId => {
  let url = `${dominio}/emisoras/empresas/${empresaId}/valores?cotizacion=true`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setValores(response.data));
      })
      .catch(err => dispatch(setValores(err.data)));
  };
};

export const filterEmisoras = ({
  size,
  page,
  instrumento,
  tipo_valor,
  sector,
  sub_sector,
  ramo,
  sub_ramo,
  biva,
  canceladas,
  clave
}) => {
  let url = `${base_url}?size=${size}&page=${page}`;

  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumento=${[...instrumento]}` : url;
  url = tipo_valor && tipo_valor.length > 0 ? `${url}&tipoValor=${[...tipo_valor]}` : url;
  url = sector ? `${url}&sector=${sector}` : url;
  url = sub_sector ? `${url}&subsector=${sub_sector}` : url;
  url = ramo ? `${url}&ramo=${ramo}` : url;
  url = sub_ramo ? `${url}&subramo=${sub_ramo}` : url;
  url = biva ? `${url}&biva=${biva}` : url;
  url = canceladas ? `${url}&canceladas=${canceladas}` : url;
  url = clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisoras(response.data));
      })
      .catch(err => dispatch(setEmisoras(err.data)));
  };
};

const setFilterClavesEmisoras = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVESEMISORAS_SUCCESS,
      listadoClaves: []
    };
  }
  return {
    type: actionTypes.FETCH_CLAVESEMISORAS_SUCCESS,
    listadoClaves: data
  };
};

export const filterEmisorasByClave = (clave, biva = null, canceladas = null) => {
  let url = `${dominio}/emisoras/empresas/busqueda/${clave}`;
  url = canceladas === true || biva === true ? `${url}?` : url;
  url = biva ? `${url}biva=${biva}` : url;
  url = canceladas ? `${url}canceladas=${canceladas}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterClavesEmisoras(response.data));
      })
      .catch(err => dispatch(setFilterClavesEmisoras(err.data)));
  };
};

export const fetchAllEmisorasByClave = ({ clave, page, size }) => {
  let url = `${dominio}/emisoras/empresas?clave=${clave}&page=${page}&size=${size}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisoras(response.data));
      })
      .catch(err => dispatch(setEmisoras(err.data)));
  };
};

const setGraficaInstrument = instrument => {
  if (instrument === undefined || instrument === null) {
    return {
      type: actionTypes.FETCH_GRAFICAINSTRUMENTEMISORA_SUCCESS,
      graficaInstrument: []
    };
  }
  return {
    type: actionTypes.FETCH_GRAFICAINSTRUMENTEMISORA_SUCCESS,
    graficaInstrument: instrument
  };
};

export const initGraficaInstrument = (isin = null, period = null, quantity = null) => {
  let url = dominio + "/quotes/instrument/isin/" + isin + "/period/" + period + "/quantity/" + quantity;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setGraficaInstrument(response.data));
      })
      .catch(err => dispatch(setGraficaInstrument(err.data)));
  };
};

const setGraficaInstrument5Y = instrument => {
  if (instrument === undefined || instrument === null) {
    return {
      type: actionTypes.FETCH_GRAFICAINSTRUMENTEMISORA_SUCCESS,
      graficaInstrument5Y: []
    };
  }
  return {
    type: actionTypes.FETCH_GRAFICAINSTRUMENTEMISORA_SUCCESS,
    graficaInstrument5Y: instrument
  };
};

export const initGraficaInstrument5Y = (isin = null, period = null, quantity = null) => {
  let url = dominio + "/quotes/instrument/isin/" + isin + "/period/" + period + "/quantity/" + quantity;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setGraficaInstrument5Y(response.data));
      })
      .catch(err => dispatch(setGraficaInstrument5Y(err.data)));
  };
};

export const initAgendaDerechos = (
  size = null,
  page = null,
  nacional = null,
  sic = null,
  tipoDerecho = null,
  tipoInstrumento = null,
  estatus = null,
  fechaInicio = null,
  fechaFin = null,
  emisora = null,
  clave = null,
  modoListado= null,
  instrumentosAlternativos=false
) => {
  if (size === null) {
    size = 10;
  }
  if (page === null) {
    page = 0;
  }

  let url = `${dominio}/emisoras/agendas?size=${size}&page=${page}&recientes=true`;

  url = nacional ? `${url}&nacional=${nacional}` : url;
  url = sic ? `${url}&sic=${sic}` : url;

  url = tipoDerecho !== null && tipoDerecho.length > 0 ? `${url}&tipoDerecho=${tipoDerecho}` : url;
  // url = tipoInstrumento !== null && tipoInstrumento.length > 0 ? `${url}&tipoInstrumento=${tipoInstrumento}` : url;
  url =
    instrumentosAlternativos === true && tipoInstrumento && tipoInstrumento.length > 0
      ? `${url}&tipoInstrumentoAlternativo=${tipoInstrumento}`
      : instrumentosAlternativos === false && tipoInstrumento && tipoInstrumento.length > 0
        ? `${url}&tipoInstrumento=${tipoInstrumento}`
        : url;

  url = estatus !== null && estatus.length > 0 ? `${url}&estatus=${estatus}` : url;
  url = fechaInicio ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin ? `${url}&fechaFin=${fechaFin}` : url;
  url = emisora ? `${url}&claveEmisora=${emisora}` : url;
  url = clave ? `${url}&clave=${clave}` : url;
  url = modoListado && modoListado.length > 0 ? `${url}&modalidadListado=${[...modoListado]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAgendaDerechos(response.data));
      })
      .catch(err => dispatch(setAgendaDerechos(err.data)));
  };
};

const setAgendaDerechos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AGENDADERECHOS_SUCCESS,
      agendaDerechos: []
    };
  }
  return {
    type: actionTypes.FETCH_AGENDADERECHOS_SUCCESS,
    agendaDerechos: data
  };
};

export const initSic = (
  size = null,
  page = null,
  biva = null,
  canceladas = null,
  tipoValor = null,
  mercadoPrincipal = null,
  emisora = null,
  clave = null
) => {
  let url = `${dominio}/emisoras/sic?size=${size}&page=${page}`;
  url = biva ? `${url}&biva=${biva}` : url;
  url = canceladas ? `${url}&canceladas=${canceladas}` : url;

  url = tipoValor !== null && tipoValor.length > 0 ? `${url}&tipoInstrumento=${tipoValor}` : url;
  url = mercadoPrincipal ? `${url}&mercadoPrincipal=${mercadoPrincipal}` : url;
  url = emisora ? `${url}&empresa=${emisora}` : url;
  url = clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSic(response.data));
      })
      .catch(err => dispatch(setSic(err.data)));
  };
};

const setSic = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SIC_SUCCESS,
      cotizaciones: []
    };
  }
  return {
    type: actionTypes.FETCH_SIC_SUCCESS,
    cotizaciones: data
  };
};

export const initSicValoresListados = (size, page, empresaId) => {
  let url = `${dominio}/emisoras/sic/${empresaId}/emisiones?size=${size}&page=${page}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSicValoresListados(response.data));
      })
      .catch(err =>  dispatch(setSicValoresListados(err.data)));
  };
};

const setSicAvisosDerecho = (data = null) => {
  return {
    type: actionTypes.FETCH_SICAVISOSDERECHO_SUCCESS,
    avisosDerecho: data
  };
};

export const initSicAvisosDerecho = (size, page, empresaId, tipoDerecho = null, fechaInicio = null, fechaFin = null) => {
  let url = `${dominio}/emisoras/sic/empresa/${empresaId}/aviso-derecho?size=${size}&page=${page}`;
  url = tipoDerecho ? `${url}&tipoDerecho=${tipoDerecho}` : url;
  url = fechaInicio ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin ? `${url}&fechaFin=${fechaFin}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSicAvisosDerecho(response.data));
      })
      .catch(err => dispatch(setSicAvisosDerecho()));
  };
};

const setSicValoresListados = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SICVALORESLISTADOS_SUCCESS,
      valoresListados: null
    };
  }
  return {
    type: actionTypes.FETCH_SICVALORESLISTADOS_SUCCESS,
    valoresListados: data
  };
};

const setCalificadoras = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CALIFICADORAS_SUCCESS,
      calificadoras: []
    };
  }
  return {
    type: actionTypes.FETCH_CALIFICADORAS_SUCCESS,
    calificadoras: data
  };
};

export const initCalificadoras = (size, page) => {
  let url = `${dominio}/emisoras/empresas/calificadoras?size=${size}&page=${page}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCalificadoras(response.data));
      })
      .catch(err =>  dispatch(setCalificadoras(err.data)));
  };
};

export const initCalificadoraDetail = empresaId => {
  let url = `${dominio}/emisoras/empresas/calificadoras/${empresaId}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCalificadoraDetail(response.data));
      })
      .catch(err =>  dispatch(setCalificadoraDetail(err.data)));
  };
};

const setCalificadoraDetail = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CALIFICADORADETALLE_SUCCESS,
      calificadoraDetail: null
    };
  }
  return {
    type: actionTypes.FETCH_CALIFICADORADETALLE_SUCCESS,
    calificadoraDetail: data
  };
};

const setEventosCalificadoras = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EVENTOSCALIFICADORAS_SUCCESS,
      eventosCalificadoras: []
    };
  }
  return {
    type: actionTypes.FETCH_EVENTOSCALIFICADORAS_SUCCESS,
    eventosCalificadoras: data
  };
};

export const filterEventosCalificadoras = ({ size, page, calificadoraId, emisora, cliente, fechaInicio, fechaFin }) => {
  let url = `${dominio}/emisoras/empresas/calificadoras/${calificadoraId}/eventos?size=${size}&page=${page}`;

  url = emisora ? `${url}&empresa=${emisora}` : url;
  url = cliente === false ? `${url}&emisores=${cliente}` : url;
  url = fechaInicio ? `${url}&fechaInicio=${fechaInicio}` : url;
  url = fechaFin ? `${url}&fechaFin=${fechaFin}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEventosCalificadoras(response.data));
      })
      .catch(err => dispatch(setEventosCalificadoras(err.data)));
  };
};

const setClavesEventosByCalificadoras = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVESEVENTOSCALIFICADORAS_SUCCESS,
      claveEventos: null
    };
  }
  return {
    type: actionTypes.FETCH_CLAVESEVENTOSCALIFICADORAS_SUCCESS,
    claveEventos: data
  };
};

export const fetchClavesEventosByCalificadoras = calificadoraId => {
  let url = `${dominio}/emisoras/empresas/calificadoras/${calificadoraId}/eventos/claves`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setClavesEventosByCalificadoras(response.data));
      })
      .catch(err =>  dispatch(setClavesEventosByCalificadoras(err.data)));
  };
};

const setFinancialReport = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FINANCIALREPORT_SUCCESS,
      financialReport: []
    };
  }
  return {
    type: actionTypes.FETCH_FINANCIALREPORT_SUCCESS,
    financialReport: data
  };
};

export const initFinancialReport = isin => {
  let url = `${dominio}/quotes/financialReport/isin/${isin}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFinancialReport(response.data));
      })
      .catch(err =>  dispatch(setFinancialReport(err.data)));
  };
};

const setRatiosReport = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_RATIOSREPORT_SUCCESS,
      ratiosReport: null
    };
  }
  return {
    type: actionTypes.FETCH_RATIOSREPORT_SUCCESS,
    ratiosReport: data
  };
};

export const initRatiosReport = isin => {
  let url = `${dominio}/quotes/ratiosReport/isin/${isin}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setRatiosReport(response.data));
      })
      .catch(err =>  dispatch(setRatiosReport(err.data)));
  };
};

const setQuotas = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_QUOTAS_SUCCESS,
      quotas: null
    };
  }
  return {
    type: actionTypes.FETCH_QUOTAS_SUCCESS,
    quotas: data
  };
};

export const initQuotas = idMarketType => {
  let url = `${dominio}/emisoras/quota/marketType/${idMarketType}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setQuotas(response.data));
      })
      .catch(err =>  dispatch(setQuotas(err.data)));
  };
};

const setQuotasCalculation = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_QUOTASCALCULATION_SUCCESS,
      quota: null
    };
  }
  return {
    type: actionTypes.FETCH_QUOTASCALCULATION_SUCCESS,
    quota: data
  };
};

export const initQuotasCalculation = (needParams, id, amount, days) => {
  let url;
  if (needParams) {
    url = `${dominio}/emisoras/fee_calc/${id}/amount/${amount}/days/${days}`;
  } else {
    url = `${dominio}/emisoras/fee_calc/${id}`;
  }
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setQuotasCalculation(response.data));
      })
      .catch(err =>  dispatch(setQuotasCalculation(err.data)));
  };
};

const setTipoMercado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPOMERCADO_SUCCESS,
      tipoMercado: null
    };
  }
  return {
    type: actionTypes.FETCH_TIPOMERCADO_SUCCESS,
    tipoMercado: data
  };
};

export const initAllTipoMercado = () => {
  let url = `${dominio}/emisoras/prospectos/mercados`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoMercado(response.data));
      })
      .catch(err =>  dispatch(setTipoMercado(err.data)));
  };
};

// Periodo calificadora
const setPeriodoCalificadora = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PERIODOCALIFICADORA_SUCCESS,
      periodo_calificadora: data
    };
  }
  return {
    type: actionTypes.FETCH_PERIODOCALIFICADORA_SUCCESS,
    periodo_calificadora: data
  };
};

export const fetchPeriodoByCalificadora = calificadoraId => {
  let url = `${base_url}/${calificadoraId}/periodo`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPeriodoCalificadora(response.data));
      })
      .catch(err => dispatch(setPeriodoCalificadora(err.data)));
  };
};

// Ejercicio calificadora
const setEjercicioCalificadora = (data = null) => {
  if (data === undefined || data === null) {
  }
  return {
    type: actionTypes.FETCH_EJERCICIOCALIFICADORA_SUCCESS,
    ejercicio_calificadora: data
  };
};

export const fetchEjercicioByCalificadora = (calificadoraId, periodo = null) => {
  let url = `${base_url}/${calificadoraId}/ejercicio`;
  url = periodo ? `${url}?periodo=${periodo}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjercicioCalificadora(response.data));
      })
      .catch(err => dispatch(setEjercicioCalificadora(err.data)));
  };
};
// ==================================================

// Inidice emisora
const setIndiceEmisora = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORAS_SUCCESS,
      indiceIsin: null
    };
  }
  return {
    type: actionTypes.FETCH_EMISORAS_SUCCESS,
    indiceIsin: data
  };
};

export const getIndiceEmisora = isin => {
  let url = `${dominio}/quotes/quote/isin/${isin}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setIndiceEmisora(response.data));
      })
      .catch(err => dispatch(setIndiceEmisora(err.data)));
  };
};

const setRepresentantes = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_REPRESENTANTES_SUCCESS,
      representantes: []
    };
  }
  return {
    type: actionTypes.FETCH_REPRESENTANTES_SUCCESS,
    representantes: data
  };
};

export const filterRepresentantes = (size, page, empresaId = null, nombre = null) => {
  let url = `${base_url}/participantes/representante-comun?size=${size}&page=${page}`;
  url = empresaId ? `${url}&empresa=${empresaId}` : url;
  url = nombre ? `${url}&nombre=${nombre}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setRepresentantes(response.data));
      })
      .catch(err => dispatch(setRepresentantes(err.data)));
  };
};

const setFiduciarios = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FIDUCIARIOS_SUCCESS,
      fiduciarios: []
    };
  }
  return {
    type: actionTypes.FETCH_FIDUCIARIOS_SUCCESS,
    fiduciarios: data
  };
};

export const filterFiduciarios = (size, page, empresaId = null, nombre = null) => {
  let url = `${base_url}/participantes/fiduciario?size=${size}&page=${page}`;
  url = empresaId ? `${url}&empresa=${empresaId}` : url;
  url = nombre ? `${url}&nombre=${nombre}` : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFiduciarios(response.data));
      })
      .catch(err => dispatch(setFiduciarios(err.data)));
  };
};

const setFilterRazonSocial = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FIDUCIARIOSRAZONES_SUCCESS,
      razones: []
    };
  }
  return {
    type: actionTypes.FETCH_FIDUCIARIOSRAZONES_SUCCESS,
    razones: data
  };
};

export const filterEmisorasByRazonSocial = (razonSocial = 0) => {
  let url = `${dominio}/emisoras/empresas/participantes/fiduciario/busqueda/${razonSocial ? razonSocial : 0}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterRazonSocial(response.data));
      })
      .catch(err => dispatch(setFilterRazonSocial(err.data)));
  };
};

const setFilterRazonSocialRepresentantes = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FIDUCIARIOSRAZONES_SUCCESS,
      razonesRepresentantes: []
    };
  }
  return {
    type: actionTypes.FETCH_FIDUCIARIOSRAZONES_SUCCESS,
    razonesRepresentantes: data
  };
};

export const filterEmisorasByRazonSocialRepresentantes = (razonSocial = 0) => {
  let url = `${dominio}/emisoras/empresas/participantes/representante-comun/busqueda/${razonSocial ? razonSocial : 0}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterRazonSocialRepresentantes(response.data));
      })
      .catch(err => dispatch(setFilterRazonSocialRepresentantes(err.data)));
  };
};

// EVENTOS RELEVANTES

const setEventosRelevantes = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EVENTOSRELEVANTES_SUCCESS,
      eventosRelevantes: null
    };
  }
  return {
    type: actionTypes.FETCH_EVENTOSRELEVANTES_SUCCESS,
    eventosRelevantes: data
  };
};

export const initEventosRelevantes= () => {
  let url = `${dominio}/emisoras/eventos-relevantes`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEventosRelevantes(response.data));
      })
      .catch(err =>  dispatch(setEventosRelevantes(err.data)));
  };
};


const setAnalistas = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ANALISTAS_SUCCESS,
      analistas: []
    };
  }
  return {
    type: actionTypes.FETCH_ANALISTAS_SUCCESS,
    analistas: data
  };
};

export const getAnalistasIndependientes = (size, page) => {
  let url = `${dominio}/emisoras/analista-independiente?size=${size}&page=${page}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAnalistas(response.data));
      })
      .catch(err => dispatch(setAnalistas(err.data)));
  };
};

const setCobertura = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ANALISTAS_SUCCESS,
      cobertura: []
    };
  }
  return {
    type: actionTypes.FETCH_ANALISTAS_SUCCESS,
    cobertura: data
  };
};

export const getCoberturaAnalista = (idAnalista) => {
  let url = `${dominio}/emisoras/analista-independiente/${idAnalista}/cobertura`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCobertura(response.data));
      })
      .catch(err => dispatch(setCobertura(err.data)));
  };
};


//INSTRUMENTOS ALTERNATIVOS

export const filterEmisiones = ({
  size,
  page,
  instrumento,
  tipo_valor,
  sector,
  sub_sector,
  ramo,
  sub_ramo,
  biva,
  canceladas
}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/emisiones?size=${size}&page=${page}`;

  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumento=${[...instrumento]}` : url;
  url = tipo_valor && tipo_valor.length > 0 ? `${url}&tipoValor=${[...tipo_valor]}` : url;
  url = sector ? `${url}&sector=${sector}` : url;
  url = sub_sector ? `${url}&subsector=${sub_sector}` : url;
  url = ramo ? `${url}&ramo=${ramo}` : url;
  url = sub_ramo ? `${url}&subramo=${sub_ramo}` : url;
  url = biva ? `${url}&biva=${biva}` : url;
  url = canceladas ? `${url}&canceladas=${canceladas}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisiones(response.data));
      })
      .catch(err => dispatch(setEmisiones(err.data)));
  };
};

const setEmisiones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORAS_SUCCESS,
      emisiones: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORAS_SUCCESS,
    emisiones: data
  };
};
import React, { Component } from "react";
import classes from "./EspacioPublicitario.css";
import Slider from "react-slick";
import { getUrlDocument } from "../../../../stores/utils/utilities";
import { NavLink } from "react-router-dom";

let settings = {
  className: "center",
  centerMode: false,
  dots: true,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: "ease-out",
  variableWidth: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1894,
      settings: {
        slidesToShow: 4,
        slidesToScroll:1,
        infinite: false
      }
    },
    {
      breakpoint: 1533,
      settings: {
        slidesToShow: 3,
        slidesToScroll:1,
        infinite: true
      }
    },
    {
      breakpoint: 1148,
      settings: {
        slidesToShow: 2,
        slidesToScroll:1,
        infinite: true
      }
    },
    {
      breakpoint: 775,
      settings: {
        slidesToShow: 1,
        slidesToScroll:1,
        infinite: true
      }
    }
  ]
};

class EspacioPublicitario extends Component {
  componentDidMount() {}

  render() {
    if (this.props.datos) {
      if (this.props.datos.length > 4) {
        settings.infinite = true
      } else {
        settings.slidesToShow = this.props.datos.length;
      }
      const contenido = this.props.datos.map((dato, i) => {
        let ruta = null;

        ruta = getUrlDocument(dato.pictureRelativeUrl);
        const style = {
          backgroundImage: `url(${ruta})`,
          backgroundRepeat: "round"
        };
        if (dato.link.includes("http")) {
          return (
            <a
              href={getUrlDocument(dato.link)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <div>
                <div className={["card", classes.card].join(" ")} style={style}>
                  <div className={classes.fondoDifuminado} />
                  <div className={["card-body text-center"].join(" ")}>
                    <p
                      className={[
                        "card-text bold nunito texto_40 bold color_blanco",
                        classes.texto_titulo
                      ].join(" ")}
                    >
                      {dato.title}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          );
        }
        return (
          <NavLink
            to={getUrlDocument(dato.link)}
            className={[classes.link].join(" ")}
            onClick={this.props.onClick}
          >
            <div>
              <div className={["card", classes.card].join(" ")} style={style}>
                <div className={classes.fondoDifuminado} />
                <div className={["card-body text-center"].join(" ")}>
                  <p
                    className={[
                      "card-text bold nunito texto_40 bold color_blanco",
                      classes.texto_titulo
                    ].join(" ")}
                  >
                    {dato.title}
                  </p>
                </div>
              </div>
            </div>
          </NavLink>
        );
      });

      return (
        <React.Fragment>
          <div className={classes.contenedorSlider}>
            <Slider {...settings}>{contenido}</Slider>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default EspacioPublicitario;

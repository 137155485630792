import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getLan, getKeyYoutube } from "../utils/utilities";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

//Indices
const setIndices = data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_INDICES_DICCIONARIO_SUCCESS,
            indices: []
        };
    }
    return {
        type: actionTypes.FETCH_INDICES_DICCIONARIO_SUCCESS,
        indices: data
    };
};

export const getIndices = (type) => {
    const params = {
        lang: lang
    }
    const queryParams = StringUtils.jsonToQueryString(params);
    return dispatch => {
        axios
            .get(`${dominio}/cms/o/finantial-dictionary/glossary/${type}/index${queryParams}`)
            .then(response => {
                dispatch(setIndices(response.data));
            })
            .catch(err => dispatch(setIndices(err.data)));
    };
};


//  Palabras

const setPalabrasDiccionario = data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_PALABRAS_SUCCESS,
            palabras: []
        };
    }
    return {
        type: actionTypes.FETCH_PALABRAS_SUCCESS,
        palabras: data
    };
};

// export const filterPalabrasDiccionario = ({ size, page,contains, starts }) => {
    export const filterPalabrasDiccionario = ({ contains = null , starts = null }, type) => {
    const params = {
        contains: contains,
        "starts-with": starts,
        lang: lang
    }
    const queryParams = StringUtils.jsonToQueryString(params);

    let url = `${dominio}/cms/o/finantial-dictionary/glossary/${type}${queryParams}`;

    return dispatch => {
        axios
            .get(url)
            .then(response => {
                dispatch(setPalabrasDiccionario(response.data));
            })
            .catch(err => dispatch(setPalabrasDiccionario(err.data)));
    };
};




const setPalabrasFilter = data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_PALABRAS_SUCCESS,
            palabrasFilter: []
        };
    }
    return {
        type: actionTypes.FETCH_PALABRAS_SUCCESS,
        palabrasFilter: data
    };
};

export const filterPalabrasPreview = ({ contains = null , starts = null }, type) => {
    const params = {
        contains: contains,
        "starts-with": starts,
        lang: lang
    }
    const queryParams = StringUtils.jsonToQueryString(params);

    let url = `${dominio}/cms/o/finantial-dictionary/glossary/${type}${queryParams}`;

    return dispatch => {
        axios
            .get(url)
            .then(response => {
                dispatch(setPalabrasFilter(response.data));
            })
            .catch(err => dispatch(setPalabrasFilter(err.data)));
    };
};
//StringUtils metodo para obtener los queryParams
// let params = {
//   param1: "value",
//   param2: 2,
//   param3: "",    <- agregara paramm3=
//   param4: null,  <- sera ignorado
//   param5: "value",
// }
//import StringUtils
//jsonToQueryString(params);
// respuesta: ?param1=value&param2=value&param3=value&param5=value
export default class StringUtils {
    static jsonToQueryString(obj) {
        var pairs = [];
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "" ) {
                    pairs.push(key + "=" + obj[key]);
                }
            }
        }
        if (pairs.length) {
            return "?" + pairs.join("&");
        }
        return "";
    }
  }


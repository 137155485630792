import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";

import FilterAnunciosBiva from "./FilterAnunciosBiva";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";
import Rss from "../../rss/Rss";
const dominio = `${window.location.protocol}//${window.location.host}`;

class AnunciosBiva extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    messages: null,
    palabraClave: null,
    fechaInicio: null,
    fechaFin: null,
    wasFiltered: false,
    lan: getLocal(),
    urlForRss: `${dominio}/avisos/anuncio-biva/rss`,
    showRssModal: false
  };


  componentDidMount() {
    this.props.onInitIntl(3);
    let paramsString = queryString.parse(this.props.location.search);
    let keyword =
      paramsString["keyword"] !== undefined && paramsString["keyword"] !== "null"
        ? paramsString["keyword"]
        : null;
    let from =
      paramsString["from"] !== undefined && paramsString["from"] !== "null" ? paramsString["from"] : null;
    let to =
      paramsString["to"] !== undefined && paramsString["to"] !== "null" ? paramsString["to"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? paramsString["page"] : 1;


    const params = {
      size: this.state.itemsPerPage,
      page: parseInt(page) - 1,
      keyword: keyword,
      from: from,
      to: to
    };
    this.props.filterAnunciosBiva(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }
    if (this.props.anunciosBiva !== prevProps.anunciosBiva) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let anunciosBiva = null;


    if (nextProps.anunciosBiva && nextProps.anunciosBiva.content) {
      anunciosBiva = nextProps.anunciosBiva.content;
    }
    let resource = anunciosBiva;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.anunciosBiva;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.props.history.push("#biva_anuncios");

      this.setState({
        loader: true
      });

      this.changeHistory(
        this.state.palabraClave ? this.state.palabraClave : null,
        this.state.from ? this.state.from : null,
        this.state.to ? this.state.to : null,
        pageNumber
      );

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onSetPalabraClave = palabra_calve => {
    this.setState({
      palabraClave: palabra_calve
    });
  };

  onSetFechaIncio = fecha_inicio => {
    this.setState({
      fechaInicio: fecha_inicio
    });
  };

  onSetFechaFin = fecha_fin => {
    this.setState({
      fechaFin: fecha_fin
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };
  onSetParams = ({ keyword, from, to, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(keyword, from, to, page);
    }
  };

  changeHistory(keyword = null, from = null, to = null, page = 1) {
    // this.props.history.push(`?keyword=${keyword}&from=${from}&to=${to}&page=${this.state.activePage}`);

    this.setState({
      loader: true
    });

    const params = {
      size: this.state.itemsPerPage,
      page: page - 1 < 0 ? 0 : page - 1,
      keyword: keyword,
      from: from,
      to: to
    };

    this.props.filterAnunciosBiva(params);

    // this.props.onInitRequisitos(params);
  }

  changeHistoryReset() {
    // this.props.history.push(`?keyword=${null}&from=${null}&fechaFin=${null}&page=1`);

    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      keyword: null,
      from: null,
      to: null
    };

    this.props.filterAnunciosBiva(params);

    // this.props.onInitRequisitos(params);
  }

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    let contenidoMostrar = null;
    let anunciosBiva = null;
    if (this.props.anunciosBiva && this.props.anunciosBiva.content) {
      anunciosBiva = this.props.anunciosBiva.content;

      const numRows = this.props.anunciosBiva ? this.props.anunciosBiva.totalElements : 0;

      contenidoMostrar = (
        <React.Fragment>
          {loader}

          <Accordion datos={anunciosBiva} nombre="biva_anuncios_accordion" icon={""} tipo={12} subtipo={13} />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );
    } else if (this.state.wasFiltered) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={["nunito", "texto_42"].join(" ")}>
            <FormattedMessage id="avisosyreportes.listado.anunciosBiva.titulo" defaultMessage=" " />
          </h1>
          <br />
          <div id="biva_anuncios">
            <FilterAnunciosBiva
              setPalabraClave={palabra_calve => this.onSetPalabraClave(palabra_calve)}
              setFechaInicio={fecha_inicio => this.onSetFechaIncio(fecha_inicio)}
              setFechaFin={fecha_fin => this.onSetFechaFin(fecha_fin)}
              setPage={page => this.onSetPage(page)}
              setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
              setParams={filterParams => this.onSetParams(filterParams)}
            />
            <button
              className={["btn btn-link"].join(" ")}
              type="button"
              onClick={this.onShowRssModalHandler}
            >
              <div className="texto_16 montse regular texto_naranja">
                {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
            <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
              </div>
            </button>
            <Rss
              showRssModal={this.state.showRssModal}
              modalTitle={getLan() === "es" ? "Anuncios BIVA" : "BIVA Announcement"}
              urlRss={this.state.urlForRss}
              rssType={6}
              showClassModel1
            />
            <div className="margen_top_30 margin_bottom_200">{contenidoMostrar}</div>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterAnunciosBiva: params => {
      dispatch(actions.filterAnunciosBiva(params));
    },
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    }
  };
};

const mapStateToProps = state => {
  return {
    anunciosBiva: state.avisosReportes.anunciosBiva,
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnunciosBiva);

import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";

import classes from "./Videos.css";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLocal } from "../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import Pagination from "../../../components/ui/pagination/Pagination";

class Videos extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    selectedVideo: null,
    itemsPerPage: 9,
    activePage: 1,
    lista_ver: [],
    videos: []
  };
  componentDidMount() {
    this.props.onInitIntl(7);
    this.props.onInitVideosInstituto();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.videos !== prevProps.videos) {
      let lista_ver = [];
      const videos = this.props.videos && this.props.videos.items && this.props.videos.items.length > 0 ? this.props.videos.items.filter(video => video.snippet.thumbnails.default !== undefined) : [];
      if (this.props.videos && this.props.videos.items.length > 0) {
        let index_final = this.state.itemsPerPage;
        let index_inicial = 0;
        lista_ver = videos.slice(index_inicial, index_final);
      }
      this.setState({
        videos: videos,
        lista_ver: lista_ver,
        selectedVideo:
          lista_ver && lista_ver.length > 0
            ? lista_ver[0].snippet.resourceId.videoId
            : null,
      });
    }
  }

  changeCurrentVideo = (video) => {
    this.setState({ selectedVideo: video.snippet.resourceId.videoId });
    this.props.history.push("#video");
  };

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      const videos = this.state.videos;
      let lista_ver = [];
      let index_final = pageNumber * this.state.itemsPerPage;
      let index_inicial = index_final - this.state.itemsPerPage;
      lista_ver = videos.slice(index_inicial, index_final);
      this.setState({
        activePage: pageNumber,
        lista_ver: lista_ver,
      });
    }
  }

  render() {
    let videos = null;

    if (this.state.lista_ver.length > 0) {
      videos = this.state.lista_ver.map((video) => {
        if (video.snippet.thumbnails.default === undefined) {
          return null;
        }
        return (
          <div
            className={["col-12 col-sm-6 col-md-4"].join(" ")}
            onClick={this.changeCurrentVideo.bind(this, video)}
          >
            <div className={[classes.containerDiv].join(" ")}>
              <div className={["row", classes.container].join(" ")}>
                <div
                  className={[
                    " sin_padding col-12 sin_padding",
                    classes.videoContainer,
                  ].join(" ")}
                >
                  <div className={classes.thumbanil}>
                    <div
                      className={
                        video.snippet.thumbnails &&
                        video.snippet.thumbnails.medium !== undefined
                          ? "d-none"
                          : video.snippet.thumbnails.default !== undefined
                          ? "d-none"
                          : null
                      }
                    >
                      {" "}
                    </div>
                    <img
                      className={
                        video.snippet.thumbnails &&
                        video.snippet.thumbnails.medium !== undefined
                          ? null
                          : video.snippet.thumbnails.default !== undefined
                          ? null
                          : "d-none"
                      }
                      src={
                        video.snippet.thumbnails &&
                        video.snippet.thumbnails.medium !== undefined
                          ? video.snippet.thumbnails.medium.url
                          : video.snippet.thumbnails.default !== undefined
                          ? video.snippet.thumbnails.default.url
                          : null
                      }
                      alt={video.snippet.title}
                    />
                  </div>
                </div>
                <div
                  className={[
                    " sin_padding col-12 sin_padding",
                    classes.textoVideoContainer,
                  ].join(" ")}
                >
                  <div
                    className={[" sin_padding", classes.contenedorTitulo].join(
                      " "
                    )}
                  >
                    <span
                      className={[
                        classes.titulo_video,
                        "color_azul montse texto_18",
                      ].join(" ")}
                    >
                      {video.snippet.title}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    let paginador = (
      <div className={"col-12 sin_padding"}>
        <Pagination
          claseTipo={2}
          numRows={
            this.state.videos && this.state.videos.length > 0
              ? this.state.videos.length
              : 0
          }
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </div>
    );
    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1
                  className={[classes.main_title, "texto_42", "nunito"].join(
                    " "
                  )}
                >
                  <FormattedMessage
                    id="institutobiva.bivateca.videos.titulo1"
                    defaultMessage=" "
                  />
                </h1>
              </div>
            </div>
            <div className="margin_top_30 width_cien" id="video">
              <iframe
                width="100%"
                height="415"
                src={
                  "https://www.youtube.com/embed/" + this.state.selectedVideo
                }
                frameBorder="0"
                title="videos"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </div>
            <div
              className={["row  relativo margin_top_30", classes.fondo].join(
                " "
              )}
            >
              {videos}
              {paginador}
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    onInitVideosInstituto: () => {
      dispatch(action.initVideosInstituto());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    videos: state.videos.videos_instituto,
    diccionario: state.intl.diccionario,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import * as action from "../../../stores/actions/index";
import Capitulo from "./capitulos/Capitulo";
import hojaGuia from "../../../assets/images/sustentable/hojaGuia.png";

import classes from "./Guia.css";
import { getLocal } from "../../../stores/utils/utilities";

const lan = getLocal();
const coloresObjetivos = ["#009ee2", "#029b78", "#93c023", "#ccd428"];

export default function Guia(props) {
  const dispatch = useDispatch();

  const [messages, setMessages] = useState(null);
  const [objetivos, setObjetivos] = useState([]);
  const [togglesOpens, setTogglesOpens] = useState([]);

  const traduccionRedux = useSelector((state) => state.intl.diccionario);
  const objetivosRedux = useSelector(
    (state) => state.sustentable.objetivosGuia
  );

  useEffect(() => {
    dispatch(action.initIntl(102));
    dispatch(action.getObjetivosGuia());
  }, []);

  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);
  useEffect(() => {
    if (objetivosRedux) {
      setObjetivos(objetivosRedux);
    }
  }, [objetivosRedux]);

  async function openToggleEvent(indxToggle) {
    let options = togglesOpens;
    const exist = options.includes(indxToggle);
    if (exist) {
      options = options.filter((itm) => itm !== indxToggle);
    } else {
      options.push(indxToggle);
    }
    setTogglesOpens(options);
    const objetivosObj = objetivos.map((itm, i) => {
      itm.show = options.includes("toggle" + i);
      return itm;
    });
    setObjetivos(objetivosObj);
  }

  const getObjetivos = () => {
    let objetivosDivs = null;
    if (objetivos && objetivos.length > 0) {
      objetivosDivs = objetivos.map((itm, i) => {
        const iEnlace = i > coloresObjetivos.length ? 0 : i;
        const color = coloresObjetivos[iEnlace];
        const isShow = itm.show;
        const style = {
          backgroundImage: `url(${itm.imagen})`,
        };
        const styleTitulo = {
          color: color,
        }
        return (
          <div className={["col-12 sin_padding margin_top_20", classes.rowObjetivos].join(" ")} key={"toggle" + i}>
            <div className="row">
              <div
                onClick={() => openToggleEvent("toggle" + i)}
                className={["col-12 sin_padding", classes.imgContainer].join(
                  " "
                )}
              >
                <div style={style} className={[classes.divImagen].join(" ")} />
                <div className={[classes.tituloContainer].join(" ")}>
                  <div className={classes.vertical}>
                    <span
                      className={[" nunito texto_30", classes.titulo,].join(" ")} style={styleTitulo}
                    >
                      {itm.titulo_encabezado}
                    </span>
                    <i
                      className={[
                        isShow ? "fas fa-caret-down" : "fas fa-caret-left",
                        classes.iconTitulo,
                      ].join(" ")} style={styleTitulo}
                    ></i>
                  </div>
                </div>
              </div>
              <div
                className={[
                  isShow ? null : "d-none",
                  "col-12 sin_padding", classes.texto_container
                ].join(" ")}
              >
                <div
                  className={[" montse texto_16"].join(" ")}
                  dangerouslySetInnerHTML={{ __html: itm.texto_html }}
                />
              </div>
            </div>
          </div>
        );
      });
    }
    return objetivosDivs;
  };




  return (
    <IntlProvider locale={lan} messages={messages}>
      <>
        <img src={hojaGuia} className={classes.imagenInicial} alt="" />
        <div
          className={["container margin_bottom_200", classes.container].join(
            " "
          )}
        >
          <div className="row margen_top_30">
            <div className="col-12 sin_padding">
              <h1
                className={[
                  classes.title,
                  "nunito",
                  "texto_50",
                  "texto_verde_oscuro  bold",
                ].join(" ")}
              >
                <FormattedHTMLMessage id="titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          <div className="row margen_top_30">
            <div className="col-12 sin_padding">
              <div
                className={[classes.text_container, "justificado"].join(" ")}
              >
                <div
                  className={["montse texto_16", classes.card_body].join(" ")}
                >
                  <FormattedHTMLMessage id="introduccion" defaultMessage=" " />
                </div>
              </div>
            </div>
          </div>
          <div className=" row margen_top_30">
            <div className="col-12 sin_padding">
              <div
                className={[classes.text_container, "justificado"].join(" ")}
              >
                <div className={["montse ", classes.card_body].join(" ")}>
                  <span className="texto_azul texto_50">A</span>
                  <span className="texto_gris texto_30">/ </span>
                  <span className="texto_verde texto_30 bold">
                    {messages ? messages["subtitulo.objetivos"] : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="margen_top_30 col-12 sin_padding">
              {getObjetivos()}
            </div>
          </div>
              <Capitulo />
        
        </div>
      </>
    </IntlProvider>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../stores/actions/index";
import classes from "./Search.css";
import textura from "../../assets/images/textura.png";
import { Form, Field } from "react-final-form";
import { getLan } from "../../stores/utils/utilities";
import Input from "../ui/input/Input";
import Result from "./result/Result";
import Boton from "../../components/ui/boton/Boton";
import Loader from "../../components/loader/Loader";

import useDebounce from "../../hooks/useDebounce";

const size = 10;
const page = 0;

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  options,
  isMultiple = null,
  maxCharacter = null,
  selectLabel,
  autoFocus = false,
}) => {
  const chooseOptionLabel =
    getLan() === "es" ? "Seleccione una opción" : "Select an option";
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes
        ? [classes, "form-control montse"].join(" ")
        : "form-control",
      ref: ref,
      disabled: disabled,
      autoFocus: autoFocus,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    autoFocus: autoFocus,
    label: label,
    isMultiple: isMultiple,
    maxCharacter: maxCharacter,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel,
  };
  return (
    <div className="form-group">
      {label ? (
        <label
          for={input.name}
          className={["texto_14", "montse", "regular"].join(" ")}
        >
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};
const references = [];

const SearchWithHooks = (props) => {
  const text_input = React.createRef();
  const formSearch = React.createRef();
  const inputRef = React.createRef();
  const refResultEmisorasInscritas = React.createRef();
  const refBtns = React.createRef();
  const refResultSic = React.createRef();
  const refResultInstrumentosAlternativos = React.createRef();
  const refResultSeguimientoSolicitudes = React.createRef();
  const refResultProspectosColocacion = React.createRef();
  const refResultPreliminares = React.createRef();
  const refResultBancoInformacion = React.createRef();
  const refResultAvisos = React.createRef();
  const refResultAvisosSuspencion = React.createRef();
  const refResultEmisionesAsg = React.createRef();
  references.push(formSearch);
  references.push(text_input);
  references.push(inputRef);
  references.push(refResultEmisorasInscritas);
  references.push(refBtns);
  references.push(refResultSic);
  references.push(refResultInstrumentosAlternativos);
  references.push(refResultSeguimientoSolicitudes);
  references.push(refResultProspectosColocacion);
  references.push(refResultPreliminares);
  references.push(refResultBancoInformacion);
  references.push(refResultAvisos);
  references.push(refResultAvisosSuspencion);
  references.push(refResultEmisionesAsg);

  const [loading, setLoading] = useState(false);
  const [formClave, setFormClave] = useState(null);
  const [formClaveTemp, setFormClaveTemp] = useState(null);
  const [solicitudes, setSolicitudes] = useState(null);
  const [emisoras, setEmisoras] = useState(null);
  const [emisiones, setEmisiones] = useState(null);
  const [cotizaciones, setCotizaciones] = useState(null);
  const [prospectos, setProspectos] = useState(null);
  const [preliminares, setPreliminares] = useState(null);
  const [bancoInformacion, setBancoInformacion] = useState(null);
  const [avisosSuspencion, setAvisosSuspencion] = useState(null);
  const [sic, setSic] = useState(null);
  const [avisos, setAvisos] = useState(null);
  const [emisionesAsg, setEmisionesAsg] = useState(null);
  const debouncedSearch = useDebounce(formClaveTemp, 500);

  const avisosSuspencionRedux = useSelector(
    (state) => state.search.avisosSuspencion
  );
  const solicitudesRedux = useSelector((state) => state.search.solicitudes);
  const prospectosRedux = useSelector((state) => state.search.prospectos);
  const preliminaresRedux = useSelector((state) => state.search.preliminares);
  const cotizacionesRedux = useSelector((state) => state.search.cotizaciones);
  const sicRedux = useSelector((state) => state.search.sic);
  const avisosOfertasPublicasRedux = useSelector(
    (state) => state.search.avisosOfertasPublicas
  );
  const emisorasRedux = useSelector((state) => state.search.emisoras);
  const emisionesRedux = useSelector((state) => state.search.emisiones);
  const emisionesAsgRedux = useSelector((state) => state.search.emisionesAsg);
  const bancoInformacionRedux = useSelector(
    (state) => state.search.bancoInformacion
  );

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (debouncedSearch) {
        callReduxAction(formClaveTemp);
      }
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );
  useEffect(() => {
    if (avisosSuspencionRedux) {
      let avisosSuspencionResult = null;
      if (
        avisosSuspencionRedux &&
        avisosSuspencionRedux.content &&
        avisosSuspencionRedux.content.length > 0
      ) {
        avisosSuspencionResult = avisosSuspencionRedux.content.map((item) => {
          return {
            nombre: item.descripcion,
            archivosXbrl: item.archivosXbrl,
            id: item.id,
            clave: item.claveCotizacion,
            documento: item.urlDocumento ? item.urlDocumento : null,
            docType: item.docType,
          };
        });
      }
      setAvisosSuspencion(avisosSuspencionResult);
    }
  }, [avisosSuspencionRedux]);

  useEffect(() => {
    if (bancoInformacionRedux) {
      let bancoInformacionResult = null;
      if (
        bancoInformacionRedux &&
        bancoInformacionRedux.content &&
        bancoInformacionRedux.content.length > 0
      ) {
        bancoInformacionResult = bancoInformacionRedux.content.map((item) => {
          return {
            nombre: item.tipoDocumento,
            archivosXbrl: item.archivosXbrl,
            id: item.id,
            clave: item.clave,
            documento: item.nombreArchivo ? item.nombreArchivo : null,
            docType: item.docType,
            fileName: item.fileName,
          };
        });
      }
      setBancoInformacion(bancoInformacionResult);
    }
  }, [bancoInformacionRedux]);

  useEffect(() => {
    if (emisionesRedux) {
      let emisionesResult = null;
      if (
        emisionesRedux &&
        emisionesRedux.content &&
        emisionesRedux.content.length > 0
      ) {
        emisionesResult = emisionesRedux.content.map((item) => {
          return {
            nombre: item.nombre,
            archivosXbrl: item.archivosXbrl,
            id: item.idEmpresa,
            clave: item.clave,
            instrumento: item.tipoInstrumento,
            numeroFideicomiso: item.numeroFideicomiso,
          };
        });
      }
      setEmisiones(emisionesResult);
    }
  }, [emisionesRedux]);

  useEffect(() => {
    if (emisorasRedux) {
      let emisorasResult = null;
      if (
        emisorasRedux &&
        emisorasRedux.content &&
        emisorasRedux.content.length > 0
      ) {
        emisorasResult = emisorasRedux.content.map((item) => {
          return {
            nombre: item.nombre,
            archivosXbrl: item.archivosXbrl,
            id: item.id,
            clave: item.clave,
            instrumento: null,
            fecha: null,
          };
        });
      }
      setEmisoras(emisorasResult);
    }
  }, [emisorasRedux]);

  useEffect(() => {
    if (avisosOfertasPublicasRedux) {
      let avisosOfertasPublicasResult = null;
      if (
        avisosOfertasPublicasRedux &&
        avisosOfertasPublicasRedux.content &&
        avisosOfertasPublicasRedux.content.length > 0
      ) {
        avisosOfertasPublicasResult = avisosOfertasPublicasRedux.content.map(
          (item) => {
            const series = item.series.map((itm, i) => {
              if (i === 0) {
                return itm;
              }
              return ", " + itm;
            });
            return {
              nombre:
                "Serie: " +
                series +
                (getLan() === "es" ? ", Tipo de valor: " : ", Value type: ") +
                item.claveTipoValor,
                id: item.id,
                clave: item.claveCotizacion,
              archivosXbrl: item.archivosXbrl,
              documento: item.urlDocumento ? item.urlDocumento : null,
              docType: item.docType,
            };
          }
        );
      }
      setAvisos(avisosOfertasPublicasResult);
    }
  }, [avisosOfertasPublicasRedux]);

  useEffect(() => {
    if (sicRedux) {
      let sicResult = null;
      if (sicRedux && sicRedux.content && sicRedux.content.length > 0) {
        sicResult = sicRedux.content.map((item) => {
          return {
            nombre: item.nombre,
            archivosXbrl: item.archivosXbrl,
            id: item.id,
            clave: item.clave,
          };
        });
      }
      setSic(sicResult);
    }
  }, [sicRedux]);

  useEffect(() => {
    if (solicitudesRedux) {
      let solicitudesResult = null;
      if (
        solicitudesRedux &&
        solicitudesRedux.content &&
        solicitudesRedux.content.length > 0
      ) {
        solicitudesResult = solicitudesRedux.content.map((item) => {
          return {
            nombre: getTipoValor(item.tiposValor),
            numeroTramite: item.numeroTramite ? item.numeroTramite : null,
            archivosXbrl: item.archivosXbrl,
            id: item.id,
            clave: item.clave,
            razonSocial: item.razonSocial ? item.razonSocial : null,
            instrumento: null,
            fecha: null,
          };
        });
      }
      setSolicitudes(solicitudesResult);
    }
  }, [solicitudesRedux]);

  useEffect(() => {
    if (prospectosRedux) {
      let prospectosResult = null;
      if (
        prospectosRedux &&
        prospectosRedux.content &&
        prospectosRedux.content.length > 0
      ) {
        prospectosResult = prospectosRedux.content.map((item) => {
          return getProspecto(item);
        });
      }
      setProspectos(prospectosResult);
    }
  }, [prospectosRedux]);

  useEffect(() => {
    if (emisionesAsgRedux) {
      let prospectosResult = null;
      if (
        emisionesAsgRedux &&
        emisionesAsgRedux.length > 0
      ) {
        prospectosResult = emisionesAsgRedux.map((item) => {
          return {
            nombre: item.nombre,
            archivosXbrl: item.archivosXbrl,
            id: item.id === null ? +new Date() : item.id,
            instrumento: null,
          };
        });
      }
      setEmisionesAsg(prospectosResult);
    }
  }, [emisionesAsgRedux]);

  useEffect(() => {
    if (cotizacionesRedux) {
      let cotizacionesResult = null;
      if (
        cotizacionesRedux &&
        cotizacionesRedux.content &&
        cotizacionesRedux.content.length > 0
      ) {
        cotizacionesResult = cotizacionesRedux.content.map((item) => {
          return {
            nombre: item.displayName,
            archivosXbrl: item.archivosXbrl,
            id: item.isin,
            clave: null,
          };
        });
      }
      setCotizaciones(cotizacionesResult);
    }
  }, [cotizacionesRedux]);

  useEffect(() => {
    if (preliminaresRedux) {
      let preliminaresResult = null;
      if (
        preliminaresRedux &&
        preliminaresRedux.content &&
        preliminaresRedux.content.length > 0
      ) {
        preliminaresResult = preliminaresRedux.content.map((item) => {
          return getProspecto(item);
        });
      }
      setPreliminares(preliminaresResult);
    }
  }, [preliminaresRedux]);


  const getProspecto = (prospecto) => {
    return {
      nombre: getTipoValor(prospecto.tiposValor),
      razonSocial: prospecto.razonSocial ? prospecto.razonSocial : null,
      archivosXbrl: prospecto.archivosXbrl,
      id: prospecto.id,
      clave: prospecto.clave,
      instrumento: null,
      fecha: prospecto.fechaPublicacion,
      documento: prospecto.urlDocumento ? prospecto.urlDocumento : null,
      docType: prospecto.docType ? prospecto.docType : null,
      fileName: prospecto.descripcion ? prospecto.descripcion : null,
    };

  }
  const getTipoValor = (tiposValor) => {
    let tipoValor = "";
    if (tiposValor && tiposValor.length > 0) {
      tiposValor.forEach((itm) => {
        tipoValor += "<p>" + itm.nombre + "</p>";
      });
    }
    return tipoValor;
  };

  const callReduxAction = (value) => {
    onInputChangeHandler(value);
  };

  const onInputChangeHandler = (valueText = null, eraser = null) => {
    const value = valueText && valueText.trim().length > 0 ? valueText : null;
    setFormClave(value);
    setLoading(value ? true : false);
    if (eraser) {
      setFormClave(null);
      setFormClaveTemp(null);
      return null;
    }
    const params = {
      clave: value,
      page: page,
      size: 10,
    };
    const paramsCoincidencias = {
      coincidencias: encodeURIComponent(value),
      page: page,
      size: 10,
    };

    setSolicitudes(null);
    setEmisoras(null);
    setEmisiones(null);
    setCotizaciones(null);
    setProspectos(null);
    setPreliminares(null);
    setBancoInformacion(null);
    setAvisosSuspencion(null);
    setSic(null);
    setAvisos(null);
    setEmisionesAsg(null);

    dispatch(actions.getSolicitudesSearch(paramsCoincidencias));
    dispatch(actions.getProspectosColocacionSearch(paramsCoincidencias));
    dispatch(actions.getProspectosPreliminaresSearch(paramsCoincidencias));
    dispatch(actions.getSicSearch(params));
    dispatch(actions.getEmisorasByClaveSearch(params));
    dispatch(actions.getAllEmisionesByClaveSearch(params));
    dispatch(actions.getBancoInformacionSearch(params));
    dispatch(actions.getAvisosSuspencion(paramsCoincidencias));
    dispatch(actions.getAvisosOfertasPublicasSearch(size, page, value));
    dispatch(actions.getCotizacionEmisorasByNameSearch(size, page, value));
    dispatch(actions.getEmisionesAsgSearch(value));
  };

  const handleClickOutside = (event) => {
    if (validateOutSideReference(event)) {
      props.hiddenSearchForce();
    }
  };
  const setValueText = (event) => {
    const value = event.target.value;
    setFormClaveTemp(value);
    if (value === null || (value !== null && value.trim().length === 0)) {
      setFormClave(null);
    }
  };

  const validateOutSideReference = (event) => {
    for (let reference of references) {
      if (
        reference &&
        reference.current &&
        reference.current.contains(event.target)
      ) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const onSubmitSearchHandler = (event) => {
    event.preventDefault();
  };
  let style = {
    backgroundImage: `url(${textura})`,
  };

  const onClickBtn = (event) => {
    return null;
  };
  let resultados = null;
  const botones = (
    <div
      className={["row margen_top_80", classes.btn_container].join(" ")}
      innerRef={refBtns}
    >
      <div className="col-12 text-center">
        <span className="montse texto_16 texto_blanco">
          {" "}
          {getLan() == "es" ? "Productos BIVA" : "BIVA products"}
        </span>
      </div>
      <div className="col-12 block-center margen_top_30">
        <div className={classes.botones}>
          <Boton
            tipo={2}
            onClick={(e) => onClickBtn(e)}
            texto={"BIVA CLOUD"}
            icono_derecho="fas fa-long-arrow-alt-right"
            colorBoton={""}
            ruta={"/biva_cloud"}
          />

          <Boton
            tipo={2}
            onClick={(e) => onClickBtn(e)}
            texto={"Market data"}
            icono_derecho="fas fa-long-arrow-alt-right"
            colorBoton={""}
            ruta={"/informacion_de_mercado/market_data/productos_biva"}
          />
        </div>
      </div>
    </div>
  );
  const showAlert =
    emisoras ||
    sic ||
    emisiones ||
    solicitudes ||
    prospectos ||
    preliminares ||
    avisos ||
    avisosSuspencion ||
    emisionesAsg ||
    bancoInformacion
      ? true
      : false;
  if (loading) {
    resultados = (
      <Loader loaderDisable={(disable) => setLoading(disable)} time={2500} />
    );
  } else {
    if (formClave) {
      resultados = (
        <>
          <div className="row">
            <Result
              innerRef={refResultEmisorasInscritas}
              data={emisoras}
              key={+new Date() + "EmisoraInscrita"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es" ? "Emisoras Inscritas" : "Registered Issuers"
              }
              urlRedirect={"/empresas/emisoras_inscritas/emisoras_inscritas"}
            />
            <Result
              innerRef={refResultSic}
              data={sic}
              key={+new Date() + "sic"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={"SIC"}
              urlRedirect={"/empresas/emisoras_inscritas/sic"}
            />
            {/* <Result innerRef={refResult3} data={cotizaciones} 
              hiddenSearch={e => props.hiddenSearchForce(e)} titulo={"Cotizaciones"} urlRedirect={"/"}/> */}
            <Result
              innerRef={refResultInstrumentosAlternativos}
              data={emisiones}
              key={+new Date() + "emisiones"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es"
                  ? "Instrumentos Alternativos"
                  : "Alternative investment instruments"
              }
              urlRedirect={"/empresas/instrumentos_alternativos"}
            />
            <Result
              innerRef={refResultEmisionesAsg}
              data={emisionesAsg}
              key={+new Date() + "emisionesAsg"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es"
                  ? "Emisiones ASG"
                  : "ESG"
              }
              urlRedirect={"/biva-sostenible/emisiones-asg/emisiones"}
            />
            <Result
              innerRef={refResultSeguimientoSolicitudes}
              data={solicitudes}
              key={+new Date() + "solicitudes"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es"
                  ? "Seguimiento a solicitudes"
                  : "Applications follow-up"
              }
              urlRedirect={
                "/listado_de_valores/tramites_de_listado/seguimiento_a_solicitudes"
              }
            />

            <Result
              innerRef={refResultAvisos}
              data={avisos}
              key={+new Date() + "_avisos"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es"
                  ? "Avisos de ofertas públicas"
                  : "Public offerings"
              }
              urlRedirect={null}
            />

            <Result
              innerRef={refResultProspectosColocacion}
              data={prospectos}
              key={+new Date() + "prospectos"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es"
                  ? "Prospectos de colocacion definitivos"
                  : "Prospectus"
              }
              urlRedirect={null}
            />

            <Result
              innerRef={refResultPreliminares}
              data={preliminares}
              key={+new Date() + "preliminares"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es"
                  ? "Prospectos de colocacion preliminares"
                  : "Preliminary prospectus"
              }
              urlRedirect={null}
            />

            <Result
              innerRef={refResultBancoInformacion}
              data={bancoInformacion}
              key={+new Date() + "bancoInformacion"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es" ? "Banco de Información" : "Data center"
              }
              urlRedirect={null}
            />
            <Result
              innerRef={refResultAvisosSuspencion}
              data={avisosSuspencion}
              key={+new Date() + "avisosSuspencion"}
              hiddenSearch={(e) => props.hiddenSearchForce(e)}
              titulo={
                getLan() === "es"
                  ? "Avisos de suspensión"
                  : "Trading halt announcement"
              }
              urlRedirect={null}
            />
          </div>
        </>
      );
      if (!showAlert) {
        resultados = (
          <>
            <div className="row text-center">
              <div className="col-12 text-center texto_blanco montse">
                <span className="bold">
                  {" "}
                  {getLan() === "es"
                    ? "No existen resultados para la búsqueda: "
                    : "There are no results for the search: "}
                </span>
                <span className="normal texto_azul">{formClave}</span>
              </div>
            </div>
            {botones}
          </>
        );
      }
    }
  }
  if (props.hidden) {
    return null;
  }
  return (
    <div className={classes.textura} style={style}>
      <div className={classes.searchContainer}>
        <div className={classes.close} onClick={(e) => props.hiddenSearch(e)}>
          <i className="far fa-times-circle"></i>
        </div>
        <div
          ref={inputRef}
          className={[
            classes.inputContainerSearch,
            formClave ? classes.moveInput : null,
          ].join(" ")}
        >
          <div className={classes.textSearch}>
            <Form
              onSubmit={(e) => onSubmitSearchHandler(e)}
              initialValues={{
                search_field: formClave,
              }}
              render={() => (
                <form
                  ref={formSearch}
                  onSubmit={(e) => onSubmitSearchHandler(e)}
                >
                  <Field
                    name="search_field"
                    id="search_input_menu"
                    type="text"
                    elementType="input"
                    maxCharacter={12}
                    placeholder={
                      getLan() === "es"
                        ? "Buscar por clave de cotización o ISIN"
                        : "Search by ticker or ISIN"
                    }
                    component={renderInput}
                    onInputChange={(event) => setValueText(event)}
                    classes={classes.inputText}
                    autoFocus={true}
                  />
                </form>
              )}
            />
            <div
              className={[
                classes.clear,
                "texto_blanco noselect",
                formClave ? null : "d-none",
              ].join(" ")}
              onClick={(e) => onInputChangeHandler(null, true)}
            >
              <span className="texto_blanco nunito bold">
                {getLan() === "es" ? "Limpiar" : "Clear"}
              </span>
              <i class="fas fa-eraser"></i>
            </div>
          </div>
        </div>
        <div className={classes.resultContainer}>{resultados}</div>
      </div>
    </div>
  );
};

export default SearchWithHooks;

import React, { Component } from "react";
import classes from "./FilterCotizacionEmisora.css";
import { Form, Field } from "react-final-form";
import { debounce } from "throttle-debounce";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLan } from "../../../stores/utils/utilities";

import PropTypes from 'prop-types';

const mercados = [
  { nombre: getLan() === "es" ? "Todos" : "All", id: 0 },
  { nombre: "SIC", id: 3 },
  { nombre: getLan() === "es" ? "Capitales" : "Equity", id: 1 },
  { nombre: getLan() === "es" ? "Deuda" : "Debt", id: 2 }
];

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiple = null,
  options,
  defaultValue = null,
  checked = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      {label && input.type !== "radio" && input.type !== "checkbox" ? (
        <label className="montse texto_14 regular" htmlFor={input.name}>
          {label}
        </label>
      ) : null}

      {/* {label ? <label for={input.name}>{label}</label> : null} */}
      <Input config={params} />
    </div>
  );
};

class FilterCotizacionEmisora extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    cotizaciones: null,
    tab: null,
    periodoSeleccionado: null,
    toggleFilters: false,
    disableEnableButtons: true,
    allSelelectFilters: [],
    selectedFiltersGroup1: [],
    selectedFiltersGroup2: [],
    selectPivot: false,
    form_filters: {
      instrumentos: {
        value: null,
        disabled: false,
        show: true
      },
      mercados: {
        value: 0,
        disabled: false,
        show: true
      },
      tipo: null
    },
    form_clave: {
      clave: {
        value: ""
      }
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    clave: null,
    tipoValor: [],
    biva: true,
    canceladas: false
  };

  componentWillMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = value => {
    this.onInputChangeModelOne(value, null, 2);
  };

  onSubmitSearchModelOneHandler = event => {
    event.preventDefault();
  };

  onInputChangeModelOne = (event = null, eraser = null, tipo = null) => {
    const value = tipo === 2 ? event : event ? event.target.value : "";
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;

    if (value === "") {
      disabled = true;
    }
    if (tipo === 2) {
      this.props.onInitCotizacionesClaves(this.props.itemsPerPage, 0, value);
    }

    if (eraser) {
      this.setState(prevState => {
        return {
          toggleInputs: !prevState.toggleInputs,
          emisoraSeleccionada: null,
          claveSeleccionada: null,
          form_clave: form_clave,
          disableEnableButtons: disabled
        };
      });
      this.props.setLoader(true);
      this.props.setClave(null, false);
    } else {
      this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
    }
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null
      });
    }
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let emisoraSeleccionada = null;
    let claveSeleccionada = null;
    if (option === 0) {
      claveSeleccionada = clave;
    } else {
      emisoraSeleccionada = id;
    }
    this.props.setClave(clave, true);
    this.props.setLoader(true);
    if (this.props.setId) {
      this.props.setId(id);
    }

    this.setState({
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: emisoraSeleccionada,
      clave: claveSeleccionada
    });
  };

  claveSeleccionada = () => { return this.state.toggleInputs ?
    <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
      <span className={classes.filterContainer}>
        <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
          {`${this.state.valueClave}`}
        </span>
        <span className={classes.filterIconButton} onClick={() => this.onInputChangeModelOne(null, true, true)}>
          <i className="far fa-times-circle" />
        </span>
      </span>
    </div> 
  : null};

  resultadosDeBusqueda = () => {
    return <div
      className={[
        classes.autoCompleteContainer,
        "list-group",
        !this.state.form_clave.clave.value || this.state.form_clave.clave.value === "" ? classes.hidden : null,
        this.state.esconderAutoComplete ? classes.hidden : null
      ].join(" ")}
    >
      {this.props.listadoClaves && this.props.listadoClaves.length <= 0 ? (
        <li className="list-group-item">
          {getLan() === "es" ? "No existen resultados para la búsqueda" 
          : "There are no search results"}: {this.state.form_clave.clave.value}
        </li>) : null}
      {this.props.listadoClaves ? this.props.listadoClaves.map(item => (
        <li key={item.id} className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}>
          {item.clave}
        </li>
      )) : null}
    </div>
  }

  render() {
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            {/* Input para consultar por clave o ISIN */}
            <div className={["col-9", classes.no_col_padding_model_one].join(" ")}>
              <div className={classes.filters_selected_container_model_one}>
                <Auxs>
                  {this.claveSeleccionada()}
                  {!this.state.toggleInputs ? (
                      <div className={[classes.search_input_container_model_one].join(" ")}>
                        <Form
                          onSubmit={this.onSubmitSearchModelOneHandler}
                          initialValues={{
                            search_field: this.state.form_clave.clave.value
                          }}
                          render={() => (
                            <form ref={this.formSearch} onSubmit={this.onSubmitSearchModelOneHandler}>
                              <Field
                                name="search_field"
                                id="search_field"
                                type="text"
                                elementType="input"
                                placeholder={
                                  getLan() === "es"
                                  ? "Buscar por clave de cotización o ISIN"
                                  : "Search by ticker or ISIN"
                                }
                                component={renderInput}
                                onInputChange={event => this.printChange(event)}
                              />
                            </form>
                          )}
                        />
                        {this.resultadosDeBusqueda()}
                      </div>
                    )
                  : null}
                </Auxs>
              </div>
            </div>
            {/* Boton de busqueda sin funcionalidad */}
            <div className={["col-2 col-sm-1","text-center", classes.no_col_padding_model_one].join(" ")}>
              <div className={[classes.search_button_container_model_one, classes.no_border_search_button_model_one].join(" ")}>
                <button type="buttom" className="btn" disabled>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FilterCotizacionEmisora.propTypes = {
  setId: PropTypes.func,
  setLoader: PropTypes.func,
  setClave: PropTypes.func,
  listadoClaves: PropTypes.array,
  onInitCotizacionesClaves: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    listadoClaves: state.informacionMercado.claves ? state.informacionMercado.claves : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitCotizacionesClaves: (size, page, clave) => {
      dispatch(actions.initCotizacionEmisorasClaves(size, page, clave));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCotizacionEmisora);

import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import classes from "./SymbolInfoWidget.css"

export default function SymbolInfoWidget(props) {
  const container = useRef();

  useEffect(() => {
    let serie;
    if (container.current.children.length > 0) {
      container.current.removeChild(container.current.children[0])
    }
    if (props.symbol) {
      serie = props.symbol.split(" ")[2];
      if (serie === undefined) {
        let [first, ...rest] = props.symbol.split('-');
        serie = rest.join('-')
      }
    }
    let symbol = "BIVA:" + props.claveEmisora + (serie && serie !== "*" ? "/" + serie : "");
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
    {
      "symbol": ${JSON.stringify(symbol)},
      "width": "100%",
      "locale": ${JSON.stringify(props.locale)},
      "colorTheme": "light",
      "isTransparent": false,
      "customer": "bivacom"
    }`;
    container.current.appendChild(script);

    const delay = ms => new Promise(res => setTimeout(res, ms));
    script.onload = () => {
      let iframe = document.querySelectorAll('[title="symbol info TradingView widget"]')[0];
      if (iframe) {
        iframe.onload = async function(){
          await delay(1000);
          //Por algun motivo en la primer busqueda no se tiene el valor de los atributos 
          //correctos del iframe, por eso se hace la misma busqueda 2 veces.
          iframe = document.querySelectorAll('[title="symbol info TradingView widget"]')[0];
          if (iframe) {
            let styleStr = iframe.getAttribute("style");
            if (styleStr.indexOf("height: 100%;") !== -1) {
              document.getElementById('tvWidgetContainerSI').remove();
            }
          }
        };
      }
    }
  }, [props.symbol]);

  return (
    <div class={["tradingview-widget-container", classes.TVSymbolInfoContainer].join(" ")} id='tvWidgetContainerSI'>
      <div class="tradingview-widget-container__widget" ref={container} ></div>
      <div class="tradingview-widget-copyright"><a href="https://es.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Siga los mercados en TradingView</span></a></div>
    </div>
  );

}

SymbolInfoWidget.propTypes = {
  claveEmisora: PropTypes.string,
  symbol: PropTypes.string,
  locale: PropTypes.string
}


import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";

import classes from "./Glosario.css";
import NotFound from "../../components/notFound/NotFound";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";
import DocumentMeta from "react-document-meta";
import { getLan } from "../../stores/utils/utilities";
import Diccionario from "../instituto/diccionario/Diccionario";

const metaEs = {
  title: "Portal BIVA - Instituto BIVA",
  description:
    "Glosario financiero BIVA",
  meta: {
    name: {
      keywords:
        "glosario, convenios, alianzas, organismos, universidades, gobierno, cursos, embajador, biva, universitario, bivateca, documentos, videos, universidad",
    },
  },
};

const metaEn = {
  title: "Portal BIVA - BIVA Institute",
  description:
    "Glosario financiero BIVA",
  meta: {
    name: {
      keywords:
        "glosario, institute, biva, agreements, alliances, courses, university, ambassador, bivateca, documents, videos, universities, government",
    },
  },
};
class Glosario extends Component {
  state = {
    menu: null,
    id_menu_activo: null,
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage: "glosario-financiero",
    };

    this.props.onInitGenericos(params);
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu,
      });
    } else {
      this.setState({
        id_menu_activo: menu,
      });
    }
  };
  render() {
    const routes = (
      <Switch>
        <Route path="/glosario-financiero" exact component={Diccionario} />
        <Route component={NotFound} />
      </Switch>
    );

    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
        <div className="container">
          <div className="row">
            <div className={["d-none"].join(" ")}>
              <Sidebar
                classIcon={["fas fa-gavel"].join(" ")}
                setMenu={(menu, tipo) => this.onSetMenu(menu, tipo)}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30 padding_bottom_200">
              <div className="row margin_bottom_30">
                <div className="col-12 sin_padding_L">
                  <div className={classes.breadcums}>
                    <p>
                      <Breadcrum
                        idPage={this.state.id_menu_activo}
                        menu={this.state.menu}
                      />
                    </p>
                  </div>
                </div>
              </div>
              {routes}
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitGenericos: (params) => {
      dispatch(action.initGenericos(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(Glosario);

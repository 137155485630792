import React, { Component } from "react";
import * as actions from "../../../stores/actions/index";
import { connect } from "react-redux";
import { IntlProvider, defineMessages} from "react-intl";
import Loader from "../../../components/loader/Loader";
import Pagination from "../../../components/ui/pagination/Pagination";
import Accordion from "../../../components/ui/accordion/Accordion";

class AnalistaIndependiente extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    messages: null,
    loader: false,
  };
  componentDidMount(){
    this.props.onInitIntl();
    this.props.onInitConcentradoPrecios(this.state.itemsPerPage, this.state.activePage - 1)
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.analistas !== prevProps.analistas) {
      this.setState({
        loader: false,
      });
    }
    if (this.props.cobertura !== prevProps.cobertura) {
      this.setState({
        detalles: this.props.cobertura,
      });
    }
  }

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.fondos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        loader: true,
        resourceList: resourceList
      });
      this.props.onInitConcentradoPrecios(this.state.itemsPerPage, pageNumber - 1)
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  updateGrandparent = value => {
    this.props.getCoberturaAnalista(value);
    this.setState({
      tramiteDetalle: value,
      detalles: null
    });
  };
  render() {
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader
          loaderDisable={(disable) => this.setState({ loader: disable })}
        />
      );
    }

    let analistas = null;
    if (this.props.analistas && this.props.analistas.content) {
      analistas = this.props.analistas.content;
    }
    
    let dataShow = (
      <Accordion
          key={this.state.activePage}
          datos={analistas}
          nombre="analistas"
          icon={""}
          tipo={20}
          titulo={this.state.messages
            ? this.state.messages[
                "empresas.analistaindependiente.toggle.titulo"
              ]
            : ""}
          detallesProspectos={this.state.detalles}
          updateGrandparent={this.updateGrandparent.bind(this)}
          fondo={true}
        />
    );
    let contenidoMostrar = (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>
        {this.state.messages
            ? this.state.messages[
                "empresas.analistaindependiente.titulo1"
              ]
            : ""}
        </h1>
       
        <div className="margen_top_30">{dataShow}</div>
      </React.Fragment>
    );

    
    const numRows = this.props.analistas ? this.props.analistas.totalElements : 0;
    
    let pagination = (
      <Pagination
        claseTipo={2}
        numRows={numRows}
        paginas={5}
        itemsPerPage={this.state.itemsPerPage}
        handlePageChange={this.handlePageChange.bind(this)}
        activePage={this.state.activePage}
      />
    );
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          {contenidoMostrar}
          {pagination}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitConcentradoPrecios: (size, page) => {
      dispatch(actions.getAnalistasIndependientes(size, page));
    },
    getCoberturaAnalista: (idAnalista) => {
      dispatch(actions.getCoberturaAnalista(idAnalista));
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(82));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    analistas: state.emisoras.analistas,
    diccionario: state.intl.diccionario,
    cobertura: state.emisoras.cobertura
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalistaIndependiente);

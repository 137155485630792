import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./TiposPosturas.css";

import Auxs from "../../../hoc/auxs/Auxs";
import Tabs from "../../../components/ui/tabs/Tabs";
import Rectangle from "../../../components/ui/rectangle/Rectangle";

import Accordion from "../../../components/ui/accordion/Accordion";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
const tabsInfo = [
  {
    image: "tresflechas.svg",
    icon: "tresflechas.svg",
    title: getLan() ===  "es" ? "Posturas por libro" : "Order Types"
  },
  {
    image: "calendar.svg",
    icon: "calendar.svg",
    title: getLan() ===  "es" ? "Tipo de vigencia" : "Validity Type"
  },
  {
    image: "documento.svg",
    icon: "documento.svg",
    title: getLan() ===  "es" ? "Atributos generales" : "General Attributes"
  }
];

class TiposPosturas extends Component {
  state = {
    indexSelected: null
  };

  componentWillMount() {
    this.onChangeItemSelected();
  }
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null,
    posturas: null
  };

  componentDidMount() {
    this.props.onInitIntl();
    this.props.onInitPosturas();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.posturas !== prevProps.posturas) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.posturas) {
        let posturas = JSON.parse(this.props.posturas[0].content);
        this.setState({
          posturas: posturas
        });
      }
    }
  }
  onChangeItemSelected = (index = 0) => {
    this.setState({ indexSelected: index });
  };

  onGetDataHandler = index => {
    let modelo = null;

    if (index === 0) {
      modelo = "modelo_1";
    } else if (index === 1) {
      modelo = "modelo_2";
    } else {
      modelo = "modelo_3";
    }
    return [this.state.posturas.tipos_postura[index].data, modelo];
  };

  render() {
    let posturas = null;
    if (this.state.posturas) {
      const [data, modelo] = this.onGetDataHandler(this.state.indexSelected);
      posturas = <Rectangle data={data} modelo={modelo} />;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.tiposdepostura.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className=" margen_top_30">
            <div className="">
              <Accordion
                datos={this.props.accordion}
                nombre={"cursos"}
                icon={""}
                imagen_icono={"graficaLap.svg"}
                tipo={6}
                titulo_encabezado_estatico={this.state.messages ? this.state.messages["operaciones.modelosdenegociacion.tiposdepostura.sistemaopel"] : ""}
              />
            </div>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.modelosdenegociacion.tiposdepostura.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>

          <div className="row margen_top_30 ">
            <div className={["col sin_padding_L", classes.myCol].join(" ")}>
              <Tabs data={tabsInfo} tipo={3} clicked={i => this.onChangeItemSelected(i)} />
            </div>
          </div>
          <div className="margen_top_50 margin_bottom_200">{posturas}</div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    posturas: state.posturas.posturas,
    accordion: state.accordion.tiposPostura
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(33));
    },
    onInitPosturas: () => {
      dispatch(action.initPosturas());
      dispatch(action.getAccordionTiposPostura());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TiposPosturas);

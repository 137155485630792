import React, { Component } from "react";
import { connect } from "react-redux";

import Auxs from "../../hoc/auxs/Auxs";
import classes from "./Banner.css";
import { getLan } from "../../stores/utils/utilities";
import logoES from "../../assets/images/logo2.png";
import logoEN from "../../assets/images/logo2.png";
import Metrics from "../metrics/Metrics"
class Banner extends Component {
  render() {
    return (
      <Auxs>
        <div>
          <div className={[classes.contenedor, ""].join(" ")}>
              <Metrics />
            <div className={[classes.margenes, classes.vertical_div].join(" ")}>
              <div className={[classes.vertical].join(" ")}>
                <img src={getLan() === "es" ? logoES : logoEN} alt="" className={[classes.logo, classes.vertical].join(" ")} />
              </div>
            </div>
          </div>
        </div>
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  return {
    banner: state.banner.banner
  };
};

export default connect(mapStateToProps)(Banner);

import React, { Component } from "react";
import classes from "./EspacioBiva.css";

import Auxs from "../../../hoc/auxs/Auxs";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
import TabsVideos from "../../../components/ui/tabsVideos/TabsVideos";
class EspacioBiva extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={["nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="invierte.espaciobiva.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          <div className="margen_top_50">
              <TabsVideos history={this.props.history} location={this.props.location} maxResults={50} itemsPerPage={12} />
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.getTabsCategorias());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EspacioBiva);

import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getLan } from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();
const base_url = `${dominio}/emisoras/tipo-documento/rss`;

const setFetchRssInputs = data => {
  return {
    type: actionTypes.FETCH_RSS_SUCCESS,
    rss: data
  };
};

function dispatchRssDocument(url) {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setRssDocument(response.data));
      })
      .catch(err => console.log(err));
  };
};

export const fetchAllRssInputs = (instrumentosAlternativos = null) => {
  let url = null;
  if (instrumentosAlternativos !== null) {
    url = `${dominio}/emisoras/tipo-documento/instrumento-alternativo/rss`;
  } else {
    url = base_url;
  }
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFetchRssInputs(response.data));
      })
      .catch(err => console.log(err));
  };
};

const setRssDocument = (data = null) => {
  return {
    type: actionTypes.FETCH_RSSDOCUMENT_SUCCESS,
    rssUrlDocument: data
  };
};

// Fetch url for rss document (empresas)
export const fetchRssEmpresasUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-empresas/lang/${lang}`;

  return dispatchRssDocument(url);
};

// Fetch url for rss document (bancos)
export const fetchRssBancosUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-banco/lang/${lang}`;

  return dispatchRssDocument(url);
};

export const fetchRssCuestionarioASGUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-cuestionario-amafore/lang/${lang}`;

  return dispatchRssDocument(url);
};

// Fetch url for rss document (seguimientos)
export const fetchRssSeguimientosUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-seguimiento/lang/${lang}`;

  return dispatchRssDocument(url);
};

// Fetch url for rss document (prospectos)
export const fetchRssProspectosUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-prospectos/lang/${lang}`;

  return dispatchRssDocument(url);
};

// Fetch url for rss document (avisos oferta publica)
export const fetchRssAvisosUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-avisos/lang/${lang}`;

  return dispatchRssDocument(url);
};

// Fetch url for rss document (calificadoras)
export const fetchRssCalificadorasUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-calificadoras/lang/${lang}`;

  return dispatchRssDocument(url);
};

// Fetch url for rss document (avisos generales)
export const fetchRssAvisosGeneralesUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-avisos-grales/lang/${lang}`;

  return dispatchRssDocument(url);
};

// Fetch url for rss document (avisos suspension)
export const fetchRssAvisosSuspensionUrlDocument = () => {
  let url = `${dominio}/cms/o/documents/manual-rss-avisos-susp/lang/${lang}`;

  return dispatchRssDocument(url);
};

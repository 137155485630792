import React from "react";
import classes from './Items.css'

const Items = props => (
  <div  className={classes.panel_grid_cell}>
  <div className={[classes.push_box, classes.panel_cell_style].join(" ")}>
    <div  className={[classes.so_panel, classes.widget, classes.widget_icon_box, classes.panel_first_child, classes.panel_last_child].join(" ")} data_index="3">
      <div className={[classes.thim_widget_icon_box, classes.thim_widget_icon_box_base].join(" ")}>
        <div className={[classes.wrapper_box_icon, classes.has_read_more, classes.text_center, classes.circle].join(" ")} data_btn_bg="#005b8b">
          <div className={[classes.smicon_box, classes.iconbox_push].join(" ")}>
           
            <div className={[classes.wrap_icon].join(" ")}>
              <div className={classes.div_icono_plus}>
              <i className={["fas fa-plus-circle", classes.icono_plus].join(" ")}></i>
              </div>
              <div className={[classes.boxes_icon, classes.circle].join(" ")}><span className={[classes.inner_icon].join(" ")}><span className={[classes.icon].join(" ")}><i className={"fas fa_hand_pointer"} ></i></span></span>
              </div>
            </div>
            <div className={[classes.content_inner]}>
              <div className={[classes.sc_heading, classes.article_heading].join(" ")}>
                  <div className={[classes.div_anio].join(" ")}>
                    <div className={[classes.div_circulo_anio]}>
                      <span className={[classes.anio, " nunito", "titulo"].join(" ")}>
                      {props.anio}
                      </span>
                    </div>
                  </div>
                  <div className={classes.linea_punteada}>

                  </div>
              </div>

              <div className={[classes.desc_icon_box]}>
                <p className={"montse texto_16"}>{props.texto}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
);

export default Items;

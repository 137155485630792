import React, { Component } from "react";
import classes from "./ErrorCatch.css";
import Boton from "../boton/Boton"
import { getLan } from "../../../stores/utils/utilities";
class ErrorCatch extends Component {
  constructor(props) {
      super(props);
      this.state = {
          hasError: false
      }
  }
  componentDidCatch(error, errorInfo) {
      this.setState({ hasError: true});
      if (errorInfo) {
        console.log("Error: " + errorInfo.componentStack)
      }
  }
  render() {
      const { children } = this.props;
      const { hasError } = this.state;
      if (hasError) {
          return (
            <div className={classes.notfoundID}>
            <div className={classes.notfound}>
              <div className={classes.notfound_404}>
                <h1 className={"nunito"}>Oops!</h1>
                <h2>{getLan() === "es" ? "Algo raro sucedio, no te preocupes ya lo estamos revisando. Intentalo más tarde.": "Something went wrong, We will be back soon!"}</h2>
              </div>
              <Boton
                      ruta={"/"}
                      tipo={6}
                      texto={getLan() === "es" ? "Ir a inicio" : "Go to Homepage"}
                      icono_derecho="fas fa-long-arrow-alt-right"
                      colorBoton={"blanco"}
                    />
            </div>
          </div>
          )
      }
      return children
  }
  }


  export default ErrorCatch;
  
import React, { Component } from "react";
import classes from "./Normatividad.css";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";

import Auxs from "../../../hoc/auxs/Auxs";
import Box from "../../../components/ui/box/Box";
import { getLocal } from "../../../stores/utils/utilities";

class Normatividad extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    leyes: {
      links: [
        {
          leftIcon: "far fa-file-pdf",
          title: "Ley de mercado de valores",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url: "https://www.cnbv.gob.mx/Normatividad/Ley%20del%20Mercado%20de%20Valores.pdf"
        },
        {
          leftIcon: "far fa-file-pdf",
          title: "Ley de fondos de inversión",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url: "https://www.cnbv.gob.mx/Normatividad/Ley%20de%20Fondos%20de%20Inversi%C3%B3n.pdf"
        }
      ]
    },
    dispociciones: {
      links: [
        {
          leftIcon: "far fa-file-pdf",
          title: "Circular única de bolsa de valores",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url: "https://biva.mx/documents/30877/34911/CUBV.pdf/adafd434-cbef-2118-28e9-92e49f67ea62"
        },
        {
          leftIcon: "far fa-file-pdf",
          title: "Circular única de emisoras",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url:
            "https://www.cnbv.gob.mx/Normatividad/Disposiciones%20de%20car%C3%A1cter%20general%20aplicables%20a%20las%20emisoras%20de%20valores.pdf"
        },
        {
          leftIcon: "far fa-file-pdf",
          title: "Circular única de casas de bolsa",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url:
            "https://www.cnbv.gob.mx/Normatividad/Disposiciones%20de%20car%C3%A1cter%20general%20aplicables%20a%20las%20casas%20de%20bolsa.pdf"
        },
        {
          leftIcon: "far fa-file-pdf",
          title: "Circular única del sistema internacional cotizaciones SIC",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url:
            "https://www.cnbv.gob.mx/Normatividad/Disposiciones%20de%20car%C3%A1cter%20general%20aplicables%20a%20los%20Sistemas%20Internacionales%20de%20Cotizaciones.pdf"
        },
        {
          leftIcon: "far fa-file-pdf",
          title: "Circular única de indevales",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url:
            "https://www.cnbv.gob.mx/Normatividad/Disposiciones%20de%20car%C3%A1cter%20general%20aplicables%20a%20las%20instituciones%20para%20el%20dep%C3%B3sito%20de%20valores.pdf"
        }
      ]
    }
  };

  componentDidMount() {
    this.props.onInitIntl(0);
    this.props.onLeyes();
    this.props.onDisposiciones();
    // lan es el lenguage local el cual depende los formatos currency y fechas
    this.setState({
      lan: getLocal()
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let leyes = null;
    if (this.props.leyes) {
      leyes = this.props.leyes.map((ley, i) => {
        return <Box key={"ley_" + i} modelo="modelo_1" data={ley}   />;
      });
    }

    let disposiciones = null;
    if (this.props.disposiciones) {
      disposiciones = this.props.disposiciones.map((disposicion, i) => {
        return <Box key={"disposicion_" + i} modelo="modelo_1" data={disposicion}   />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
                <FormattedMessage id="regulacion.normatividad.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.text_container].join(" ")}>
                <p className={["montse", "texto_16"].join(" ")}>
                  <span className={"montse texto_16 regular"}>
                    <FormattedMessage id="regulacion.normatividad.introduccion" defaultMessage=" " />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className={["row margen_top_50", this.props.leyes ? null : "d-none"].join(" ")}>
            <div className="col sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedMessage id="regulacion.normatividad.subtitulo.leyes" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className={"row margen_top_30 width_cien"}>{leyes}</div>
          </div>
          <div className={["row margen_top_50", this.props.disposiciones ? null : "d-none"].join(" ")}>
            <div className="col sin_padding_L">
              <div className={["main_subtitle", "text_30", "nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedMessage id="regulacion.normatividad.subtitulo.disposiciones" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row margen_top_30 width_cien">{disposiciones}</div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    leyes: state.documentos.leyes,
    disposiciones: state.documentos.disposiciones
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    },
    onLeyes: () => {
      dispatch(actions.initLeyesNormatividadDocumentos());
    },
    onDisposiciones: () => {
      dispatch(actions.initDisposicionesNormatividadDocumentos());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Normatividad);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../stores/actions/index";
import classes from "./Esg.css";
import Box from "../../../components/ui/box/Box";


const EsgContainer = (props) => {
  const dispatch = useDispatch();

  const [containers, setContainers] = useState([]);
  const [documentos, setDocumentos] = useState([]);

  const containersRedux = useSelector(
    (state) => state.bivaSustentable.esgContainers
  );
  const documentosRedux = useSelector(
    (state) => state.bivaSustentable.documentos
  );

  useEffect(() => {
    if(props.isCuestionario) {
      dispatch(actions.getCuestionario(props.page));
      if(props.page === "bivaasg-cuestionario-genero") {
        dispatch(actions.getDocumentsCuestionario("bivaasg-docs-cuestionario-genero"));
      } else {
        dispatch(actions.getDocumentsCuestionario("bivaasg-docs-cuestionario-homologado"));
      }
    } else {
      dispatch(actions.getEsgContainers(props.page));
    }
  }, []);

  useEffect(() => {
    if (containersRedux) {
      setContainers(containersRedux);
    }
  }, [containersRedux]);
  useEffect(() => {
    if (documentosRedux) {
      setDocumentos(documentosRedux);
    }
  }, [documentosRedux]);

  const createDivs = () => {
    let html = "";

    for (let itm of containers) {
      const textoHtml = itm.imagen
        ? itm.texto_html.replace(
            "class=",
            'style="background-image:' + `url(${itm.imagen})` + '" class='
          )
        : itm.texto_html;
      html += textoHtml;
    }

    return (
      <div
        className={["row", classes.esgContainer].join(" ")}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  };

  let links = null;
  if (props.isCuestionario && documentos && documentos.length > 0) {
    links = documentos.map((documentos_obj, i) => {
      return <Box key={"documentosLink_" + i} modelo="modelo_1" data={documentos_obj}   />;
    });
  }

  return (
    <>
      <div className={classes.backBlue}>
        <div
          class={[
            "row",
            classes.esgContainer,
            props.page === "esg-scan" ? null : classes.esgExperienceBackground,
            props.isGenero ? classes.esgExperienceBackgroundGenero : null
          ].join(" ")}
        >
           <div className={props.isGenero ? classes.backgroundFilter : null} />
            {createDivs()}
            <div className={classes.contenedorBotones}>
              {links}
            </div>
         
        </div>
      </div>
    </>
  );
};

export default EsgContainer;

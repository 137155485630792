import React, { Component } from "react";
import classes from "./Rectangle.css";

import Tooltip from "../../../components/ui/tooltip/Tooltip";
import { getLan } from "../../../stores/utils/utilities";

class Rectangle extends Component {
  selectModelHandler(modelo, data) {
    switch (modelo) {
      case "modelo_1":
        return this.onPrintPosturasHandler(data);
      case "modelo_2":
        return this.onPrintVigenciaHandler(data);
      case "modelo_3":
        return this.onPrintAtributosHandler(data);
      case "modelo_4":
        return this.onPrintFormadorMercadoHandler(data);
      default:
        return null;
    }
  }

  onPrintPosturasHandler = data => {
    let box = null;

    box = data.map(item => {
      let firstSecction = this.onCreateFirstSectionModelOne(item.data.slice(0, 3));
      let secondSection = this.onCreateSecondSectionModelOne(item.data[3]);
      let thirdSection = this.onCreateThirdSectionModelOne(item.data.slice(4, 6));

      return (
        <div className={["row", classes.posturaContainer, "margen_top_30"].join(" ")}>
          <div className="col col-lg-12">
            {this.onCreateHeader(item.name, item.abbr)}
            <div className="row">
              {firstSecction} {secondSection} {thirdSection}
            </div>
          </div>
        </div>
      );
    });
    return box;
  };

  onPrintVigenciaHandler = data => {
    let box = null;
    box = data.map(item => {
      let firstSection = this.onCreateFirstSectionModelTwo(item.data[0]);
      let secondSection = this.onCreateSecondSectionModelTwo(item.data[1]);

      return (
        <div className={["row", classes.vigenciaContainer, "margen_top_30"].join(" ")}>
          <div className="col col-lg-12">
            {this.onCreateHeader(item.name, item.abbr)}
            <div className="row">
              {firstSection} {secondSection}
            </div>
          </div>
        </div>
      );
    });
    return box;
  };

  onPrintAtributosHandler = data => {
    let box = null;
    box = data.map(item => {
      let firstSection = this.onCreateFirstSectionModelThree(item);

      return (
        <div className={["row", classes.vigenciaContainer, "margen_top_30"].join(" ")}>
          <div className="col col-lg-12">
            {this.onCreateHeader(item.name, item.abbr)}
            <div className="row">{firstSection}</div>
          </div>
        </div>
      );
    });
    return box;
  };

  onCreateHeader = (name, abbr) => {
    return (
      <div className="row">
        <div className="col">
          <div className={classes.headerContainer}>
            <p className={["nunito", "texto_16", classes.colorTitle].join(" ")}> {getLan() === "es" ? "Postura" : "Order"}</p>
            <p>
              <span className={["texto_30 nunito regular margen_derecha_20"].join(" ")}>{abbr}</span>
              <span className={"texto_16 nunito regular"}>{name}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  onGetImage = name => {
    return require(`../../../assets/icons/${name}`);
  };

  onCreateFirstSectionModelOne = data => {
    return (
      <div className={["col", "col-xs-12", "col-sm-12", "col-md-12", "col-lg-5", classes.myCol].join(" ")}>
        <div className="row">
          {data.map(item => {
            let image = this.onGetImage(item.image);
            return (
              <div className={["col", "col-xs-12", "col-sm-12", "col-lg-4", "text-center", classes.myCol].join(" ")}>
                <div className={classes.itemHeader}>
                  <p className="nunito texto_16 semibold">{item.subtitle}</p>
                </div>
                <div className={classes.itemBody}>
                  <img src={image} alt="" />
                </div>
                <div className={classes.footerBkg}>
                  <div className={[classes.itemFooter, classes.borderTop].join(" ")}>
                    <p className="nunito texto_16 semibold">{item.description}</p>
                    <p>
                      <span
                        className={[
                          "nunito",
                          "texto_24",
                          "negrita",
                          item.respuesta.toString() === "Si" ||
                          item.respuesta.toString() === "Yes" ||
                          item.respuesta.toString() === "si" ||
                          item.respuesta.toString() === "yes"
                            ? classes.positivo
                            : classes.negativo
                        ].join(" ")}
                      >
                        {item.respuesta}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  onCreateSecondSectionModelOne = item => {
    let image = this.onGetImage(item.image);
    return (
      <div className={["col", "col-xs-12", "col-sm-12", "col-md-12", "col-lg-3", classes.myCol].join(" ")}>
        <div className="row">
          <div className="col col-lg-12 text-center">
            <div className={classes.itemHeader}>
              <p className="nunito texto_16">{item.subtitle}</p>
            </div>
            <div className={classes.itemBody}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-lg-6 text-center sin_padding">
            <div className={classes.footerBkg}>
              <div className={[classes.itemFooter, classes.borderTop].join(" ")}>
                <p className="nunito texto_16">{item.description}</p>
                <p
                  className={[
                    "nunito",
                    "texto_24",
                    "negrita",
                    classes.secondRes,
                    item.respuesta === "Si" || item.respuesta === "Yes" ? classes.positivo : classes.negativo
                  ].join(" ")}
                >
                  {item.respuesta}
                </p>
              </div>
            </div>
          </div>
          <div className="col col-lg-6 text-center sin_padding">
            <div className={classes.footerBkg}>
              <div className={[classes.itemFooter, classes.borderTop].join(" ")}>
                <p className="nunito texto_16">{item.description2}</p>
                <p
                  className={[
                    "nunito",
                    "texto_24",
                    "negrita",
                    classes.secondRes,
                    item.respuesta2.toString() === "Si" ||
                    item.respuesta2.toString() === "Yes" ||
                    item.respuesta2.toString() === "si" ||
                    item.respuesta2.toString() === "yes"
                      ? classes.positivo
                      : classes.negativo
                  ].join(" ")}
                >
                  {item.respuesta2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  onCreateThirdSectionModelOne = data => {
    return (
      <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <div className="row">
          {data.map(item => {
            let image = this.onGetImage(item.image);
            return (
              <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-6 text-center">
                <div className={classes.itemHeader}>
                  <p className="nunito texto_16">{item.subtitle}</p>
                </div>
                <div className={classes.itemBody}>
                  <img src={image} alt="" />
                </div>
                <div className={classes.footerBkg}>
                  <div className={[classes.itemFooter, classes.borderTop].join(" ")}>
                    <p className="nunito texto_16">{item.description}</p>
                    <p
                      className={[
                        "nunito",
                        "texto_24",
                        "negrita",
                        item.respuesta.toString() === "Si" ||
                        item.respuesta.toString() === "Yes" ||
                        item.respuesta.toString() === "si" ||
                        item.respuesta.toString() === "yes"
                          ? classes.positivo
                          : classes.negativo
                      ].join(" ")}
                    >
                      {item.respuesta}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  onCreateFirstSectionModelTwo = data => {
    return (
      <div
        className={["sin_padding col", "col-xs-12", "col-sm-12", "col-md-12", "col-lg-4", "text-center", classes.myCol].join(
          " "
        )}
      >
        <div className="row">
          <div className="col sin_padding">
            <div className={[classes.itemHeader, "nunito", "texto_20", "negrita"].join(" ")}>{data.subtitle}</div>
          </div>
        </div>
        <div className={classes.footerBkg}>
          <div className="row">
            {data.descriptions.map(item => (
              <div className={["col", "col-lg-4", classes.borderTop, classes.myCol].join(" ")}>
                <div className={classes.itemFooter}>
                  <p className="nunito texto_16 semibold">
                    <span className="nunito texto_16 semibold">{item.abbreviation}</span>
                    <span className={classes.helpIcon}>
                      <Tooltip title={item.text} />
                    </span>
                  </p>
                  <p
                    className={[
                      "nunito",
                      "texto_24",
                      "negrita",
                      item.answer === "Si" || item.answer === "Yes" ? classes.positivo : classes.negativo
                    ].join(" ")}
                  >
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  onCreateSecondSectionModelTwo = data => {
    return (
      <div
        className={[
          "col",
          "col-xs-12",
          "col-sm-12",
          "col-md-12",
          "col-lg-8",
          "text-center",
          classes.myCol,
          classes.mobilMargin
        ].join(" ")}
      >
        <div className="row">
          <div className="col">
            <div className={[classes.itemHeader, "nunito", "texto_20", "negrita"].join(" ")}>{data.subtitle}</div>
          </div>
        </div>
        <div className={classes.footerBkg}>
          <div className="row">
            {data.descriptions.map((item, i) => (
              <div className={["col", "col-lg-2", i === 0 ? "offset-lg-1" : null, classes.borderTop, classes.myCol].join(" ")}>
                <div className={classes.itemFooter}>
                  <p className="nunito texto_16">
                    {item.abbreviation}
                    <span className={classes.helpIcon}>
                      <Tooltip title={item.text} />
                    </span>
                  </p>
                  <p
                    className={[
                      "nunito",
                      "texto_24",
                      "negrita",
                      item.answer === "Si" || item.answer === "Yes" ? classes.positivo : classes.negativo
                    ].join(" ")}
                  >
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  onCreateFirstSectionModelThree = data => {
    const items = data.descriptions.length - 1;
    return (
      <div className={["col", "text-center", classes.myCol].join(" ")}>
        <div className={[classes.footerBkg, classes.borderTop].join(" ")}>
          <div className="row">
            {data.descriptions.map((item, i) => (
              <div
                className={[
                  "col",
                  "col-lg-2",
                  i === 0 ? "offset-lg-1" : null,
                  classes.myCol,
                  i !== items ? classes.borderBottom : null
                ].join(" ")}
              >
                <div className={classes.itemFooter}>
                  <p className="nunito texto_16">
                    {item.abbreviation}
                    <span className={classes.helpIcon}>
                      <Tooltip title={item.text} />
                    </span>
                  </p>
                  <p
                    className={[
                      "nunito",
                      "texto_24",
                      "negrita",
                      item.answer === "Si" || item.answer === "Yes" ? classes.positivo : classes.negativo
                    ].join(" ")}
                  >
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  onPrintFormadorMercadoHandler = data => {
    return data.map(item => (
      <div className="row">
        <div className="col sin_padding">
          <div className={classes.info_casa_container}>
            <div className={classes.secction_1}>
              <div className="row">
                <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <p className={["nunito", "texto_16", classes.colorTitle].join(" ")}>
                    {getLan() === "es" ? "Emisora" : "Issuer"}
                  </p>
                  <p className={["nunito bold", "texto_24"].join(" ")}>{item.emisora}</p>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <p className={["nunito", "texto_16", classes.colorTitle].join(" ")}>
                    {getLan() === "es" ? "Serie" : "Serie"}
                  </p>
                  <p className={["nunito bold", "texto_24"].join(" ")}>{item.serie}</p>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-12 offset-md-0 col-lg-2 offset-lg-4">
                  <span className={item.activo ? classes.activo : classes.inactivo}>
                    <p className="nunito texto_16 semibold">
                      {item.activo ? (getLan() === "es" ? "Activo" : "Active") : getLan() === "es" ? "Inactivo" : "Inactive"}{" "}
                      <i className="fas fa-circle" />
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <hr className={classes.separator} />
            <div className={classes.secction_2}>
              <div className="row">
                <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <p className={[classes.monto_minimo, "nunito semibold", "texto_16", classes.colorTitle].join(" ")}>
                    {getLan() === "es" ? "Monto Mínimo" : "Minimum amount per order"}{" "}
                  </p>
                  <p className={["montse regular", "texto_16"].join(" ")}>{item.montoMinimo}</p>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-2">
                  <p className={[classes.dif, "nunito semibold", "texto_16", classes.colorTitle].join(" ")}>
                    {getLan() === "es" ? "Dif. Max." : "Maximum Spread"}
                  </p>
                  <p className={["montse regular", "texto_16"].join(" ")}>{item.difMax}</p>
                </div>
                <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <p className={[classes.contrato_inicio, "nunito semibold", "texto_16", classes.colorTitle].join(" ")}>
                    {getLan() === "es" ? "Contrato inicio" : "Start contract"}
                  </p>
                  <p className={["montse regular", "texto_16"].join(" ")}>{item.contratoInicio}</p>
                </div>
                <div className="col col-xs-12 col-sm-12col-md-12 col-lg-3">
                  <p className={[classes.contrato_final, "nunito semibold", "texto_16", classes.colorTitle].join(" ")}>
                    {getLan() === "es" ? "Contrato final" : "End contract"}
                  </p>
                  <p className={["montse regular", "texto_16"].join(" ")}>{item.contratoFin}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  render() {
    const info = this.props.modelo ? this.selectModelHandler(this.props.modelo, this.props.data) : null;
    return info;
  }
}

export default Rectangle;

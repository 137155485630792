import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import classes from "./Afores.css";

import FilterAfores from "../../../components/ui/filterAfores/FilterAfores";
import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
/* import { withRouter } from "react-router-dom"; */
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
const dominio = `${window.location.protocol}//${window.location.host}`;

class Afores extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    razonSocial: null,
    clave: null,
    numeroTramite: null,
    datos: null,
    emisoraSeleccionada: null,
    tipoValorSeleccionado: null,
    fecha_inicio: null,
    fecha_fin: null,
    page: 0,
    detalles: [],
    urlForRss: `${dominio}/emisoras/tramites/rss`,
    tipoDocumento: null,
    pageDocumento: null,
    showRssModal: false,
    verDocumentos: false,
    claveAfore: false,
    claveSiefore: false,
    tipoSiefore: false,
    clasificacionSiefore: false,
    wasFiltered: false,
    messages: null,
    lan: getLocal(),
    docs:[]
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }

    if (this.props.afores !== prevProps.afores) {
      this.setState({
        loader: false
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    let afores = null;
    let detalles = null;
    let docs = null;


    if (nextProps.afores && nextProps.afores.content) {
      afores = nextProps.afores.content;
    }

    if (nextProps.aforesDetalle !== this.props.aforesDetalle) {
      if (nextProps.aforesDetalle) {
        detalles = nextProps.aforesDetalle;
      }
    } else {
      detalles = this.props.aforesDetalle;
    }

    if (nextProps.sieforeDocs !== this.props.sieforeDocs) {
      if (nextProps.sieforeDocs) {
        docs = nextProps.sieforeDocs
      }
    } else {
      docs = this.props.sieforeDocs;
    }
    this.setState({
      detalles: detalles,
      docs : docs
    });

    let resource = afores;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (
    resource = null,
    pageNumber = null,
    seleccionado = null
  ) => {
    let data = resource ? resource : this.props.afores;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      this.changeHistory(
        this.state.claveAfore,
        this.state.claveSiefore,
        this.state.tipoSiefore,
        this.state.clasificacionSiefore,
        pageNumber);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let claveAfore = null;
    let claveSiefore = null;
    let tipoSiefore = null;
    let clasificacionSiefore = null;
    let page = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      claveAfore = paramsString["claveAfore"] === "null" ? null : paramsString["claveAfore"];
      claveSiefore = paramsString["claveSiefore"] === "null" ? null : paramsString["claveSiefore"];
      tipoSiefore = paramsString["tipoSiefore"] !== undefined && paramsString["tipoSiefore"] !== "null" ? paramsString["tipoSiefore"] : null;
      clasificacionSiefore = paramsString["clasificacionSiefore"] !== undefined && paramsString["clasificacionSiefore"] !== "null" ? paramsString["clasificacionSiefore"] : null;
      page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    }

    if (claveSiefore !== null) {
      this.setState({
        tramiteDetalle: claveSiefore
      });
      this.props.onInitAforesDetalle(claveSiefore);
      this.props.onInitAforesDocs(claveSiefore);
      this.updateGrandparent(claveSiefore);
    }
    /*       this.updateGrandparentListado(
            tramiteId,
            razonSocial,
            clave,
            numeroTramite,
            tramiteId,
            tipoDocumento,
            numEnvio,
            page,
            false
          ); */
    this.props.onInitAfores(
      this.state.itemsPerPage,
      parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      claveAfore,
      claveSiefore,
      tipoSiefore,
      clasificacionSiefore
    );

    this.setState({
      claveAfore: claveAfore,
      claveSiefore: claveSiefore,
      tipoSiefore: tipoSiefore,
      clasificacionSiefore: clasificacionSiefore,
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
    });
  }

  updateGrandparentListado = (
    empresaId,
    razonSocial,
    clave,
    numeroTramite,
    tramiteId = null,
    tipoDocumento = null,
    numEnvio = null,
    page = null,
    consulta = null
  ) => {
    this.props.onInitAforesDetalle(empresaId);
    let datos = {
      id: empresaId,
      numeroTramite: numeroTramite,
      clave: clave,
      razonSocial: razonSocial
    };

    this.setState({
      tramiteSeleccionado: empresaId,
      razonSocial: razonSocial,
      clave: clave,
      numeroTramite: numeroTramite,
      datos: datos,
      tramiteDetalle: empresaId,
      verDocumentos: true
    });
    if (consulta === null) {
/*       this.props.history.push(
        `?tramiteId=${
        tramiteId ? tramiteId : empresaId ? empresaId : null
        }&tipoDocumento=${tipoDocumento}&numEnvio=${numEnvio}&page=${
        page === null ? 1 : page
        }&empresaId=${empresaId}&razonSocial=${razonSocial}&clave=${clave}&numeroTramite=${numeroTramite}&verDocumentos=${true}`
      ); */
    }
  };

  updateGrandparent = value => {
    this.props.onInitAforesDetalle(value);
    this.props.onInitAforesDocs(value);
    this.setState({
      tramiteDetalle: value,
      detalles: [],
      docs:[]
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetValor = valor => {
    this.setState({
      tipoValorSeleccionado: valor
    });
  };

  onSetFechaInicio = fecha => {
    this.setState({
      fecha_inicio: fecha
    });
  };

  onSetFechaFin = fecha => {
    this.setState({
      fecha_fin: fecha
    });
  };

  onSetTipoDocumento = (tipoDocumento, consulta) => {
    this.setState({
      tipoDocumento: tipoDocumento
    });
  };

  onSetPageDocumento = page => {
    this.setState({
      pageDocumento: page
    });
  };

/*   onSetEmisora = (emisora, tipo, consulta = false) => {
    let emisoraSeleccionada = null;
    let clave = null;

    if (tipo === 1) {
      emisoraSeleccionada = emisora;
    }
    if (tipo === 2) {
      clave = emisora;
    }

    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      clave: clave
    });
    if (consulta) {
      this.props.onInitAfores(
        this.state.itemsPerPage,
        0,
        emisoraSeleccionada,
        this.state.tipoValorSeleccionado,
        this.state.fecha_inicio,
        this.state.fecha_fin,
        clave
      );
    }
  }; */

  onSetClaveAforeHandler = claveAfore => {
    this.setState({
      claveAfore: claveAfore
    });
  };

  onSetClaveSieforeHandler = claveSiefore => {
    this.setState({
      claveSiefore: claveSiefore
    });
  };

  onSetTipoSieforeHandler = tipoSiefore => {
    this.setState({
      tipoSiefore: tipoSiefore
    });
  };

  onSetClasificacionSieforeHandler = clasificacionSiefore => {
    this.setState({
      clasificacionSiefore: clasificacionSiefore
    });
  };

  onSetWasFilteredHandler = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered,
      loader: true
    });
  };

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };
  onSetFilters = ({
    claveAfore = null,
    claveSiefore = null,
    tipoSiefore= null,
    clasificacionSiefore = null,
    wasFiltered = null,
    page = null,
    reset = false
  }) => {
    this.setState({
      claveAfore: claveAfore ? claveAfore : null,
      claveSiefore: claveSiefore ? claveSiefore : null,
      tipoSiefore: tipoSiefore ? tipoSiefore : null,
      clasificacionSiefore: clasificacionSiefore ? clasificacionSiefore : null,
      wasFiltered: wasFiltered
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claveAfore, claveSiefore, tipoSiefore, clasificacionSiefore, page);
    }
  };

  changeHistory(claveAfore = null, claveSiefore = null, tipoSiefore = null, clasificacionSiefore = null, page = null) {
    this.props.history.push(
      `?claveAfore=${claveAfore}&claveSiefore=${claveSiefore}&tipoSiefore=${tipoSiefore}&clasificacionSiefore=${clasificacionSiefore}&page=${page ? page : this.state.activePage}`
    );

    this.props.onInitAfores(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      claveAfore,
      claveSiefore,
      tipoSiefore,
      clasificacionSiefore
    );
  }

  changeHistoryReset() {
    this.props.history.push(
      `?claveAfore=${null}&claveSiefore=${null}&tipoSiefore=${null}&clasificacionSiefore=${null}&page=1`
    );
    this.props.onInitAfores(
      this.state.itemsPerPage,
      0,
      null,
      null,
      null,
      null
    );
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    let dataToShow = null;
    let contenidoMostrar = null;

    const numRows = this.props.afores ? this.props.afores.totalElements : 0;
    let afores_result = this.props.afores && this.props.afores.content ? this.props.afores.content : [];

    if (afores_result.length > 0) {
      dataToShow = (
        <Accordion
          datos={afores_result}
          nombre="afores"
          icon={""}
          tipo={18}
          titulo={getLan() === "es" ? "Características" : "Details"}
          subtipo={1}
          detallesProspectos={this.state.detalles}
          documentos={this.state.docs}
          updateGrandparentListado={this.updateGrandparentListado.bind(this)}
          updateGrandparent={this.updateGrandparent.bind(this)}
          esTramite={true}
          fondo={false}
        />
      );
    } else if (afores_result.length === 0) {
      dataToShow = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }

    contenidoMostrar = (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}><FormattedHTMLMessage id="empresas.siefores.titulo1" defaultMessage=" " /></h1>
        <div className="margen_top_30 texto_16 montse regular">
          <FormattedHTMLMessage id="empresas.siefores.introduccion1" defaultMessage=" " />
        </div>
        <br />
        <FilterAfores
          itemsPerPage={this.state.itemsPerPage}
          onSetClaveAfore={claveAfore =>
            this.onSetClaveAforeHandler(claveAfore)
          }
          onSetClaveSiefore={claveSiefore =>
            this.onSetClaveSieforeHandler(claveSiefore)
          }
          onSetTipoSiefore={tipoSiefore =>
            this.onSetTipoSieforeHandler(tipoSiefore)
          }
          onSetClasificacionSiefore={clasificacionSiefore =>
            this.onSetClasificacionSieforeHandler(clasificacionSiefore)
          }
          onSetWasFiltered={wasFiltered => this.onSetWasFilteredHandler(wasFiltered)}
          setPage={page => this.onSetPage(page)}
          setFilters={filterParams => this.onSetFilters(filterParams)}
        />
        <br />

        <div className="margen_50">
          {" "}

          {dataToShow}
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </div>
      </React.Fragment>
    );


    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          {contenidoMostrar}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitAfores: (size, page) => {
      dispatch(actions.filterAfores(size, page));
    },
    onInitAforesDetalle: fondo => {
      dispatch(actions.initAforesDetail(fondo));
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(72));
    },
    onInitAforesDocs: fondo => {
      dispatch(actions.initFondosDocs(fondo));
    },
  };
};

const mapStateToProps = state => {
  return {
    afores: state.afores.afores,
    aforesDetalle: state.afores.aforeDetail,
    diccionario: state.intl.diccionario,
    sieforeDocs: state.fondos.fondoDocs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Afores);

import React, { Component } from "react";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";
import * as action from "../../../../../stores/actions/index";
import OperacionBox from "../../../../../components/ui/operacionBox/OperacionBox";
import { getLan, getUrlDocument, getLocal } from "../../../../../stores/utils/utilities";
import Box from "../../../../../components/ui/box/Box";

class Biva extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };
  componentDidMount() {
    this.props.oninitUltimoDiaMesBiva();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let ruta = null;
    let box = null;
    let ruta_url = null;
    ruta_url = "/cms/o/statistics/ultimo-mes/biva/csv";
    ruta = getUrlDocument(ruta_url);
    if (ruta.includes("?")) {
      ruta = `${ruta}&download=true`;
    } else {
      ruta = `${ruta}?download=true`;
    }

    const box2 = {
      leftIcon: "far fa-file-excel",
      title:
        getLan() === "es"
          ? "Descargar operación último mes"
          : "Download Last month trading",
      rightIcon: "fas fa-arrow-down",
      url: ruta
    };

    box = <Box key={"enlace"} modelo="modelo_1" data={box2} formato="XLS" />;
    return (
        <React.Fragment>
          <div className="row margen_top_30">
            <OperacionBox data={this.props.ultimoDia} tipo={4} />
          </div>
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage
              id="informacionmercado.ultimomes.biva.nota"
              defaultMessage=" "
            />
          </span>
          <div className="row margin_top_30">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={""}>
                  <FormattedHTMLMessage
                    id="informacionmercado.ultimomes.intersado"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            {box}
          </div>
        </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    oninitUltimoDiaMesBiva: () => {
      dispatch(action.initUltimoDiaMesBiva(2));
    }
  };
};

const mapStateToProps = state => {
  return {
    ultimoDia: state.informacionMercado.ultimoMesBiva,
    diccionario: state.intl.diccionario
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Biva);

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import queryString from "query-string";
import Loader from "../../../components/loader/Loader";

import FiltroCalificadorasReporte from "../../../components/ui/filterCalificadorasReportes/FilterCalificadorasReportes";
import { getLan } from "../../../stores/utils/utilities";

class ReporteMensual extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    periodo: null,
    ejercicio: null,
    filtered: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.documentos !== prevProps.documentos) {
      this.setState({
        loader: false
      });
      this.props.setLoader(false);

    }
  }
  componentWillReceiveProps(nextProps) {
    let documentos = null;

    if (nextProps.documentos && nextProps.documentos.content) {
      documentos = nextProps.documentos.content;
    }
    let resource = documentos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.documentos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      const params = {
        periodo: this.state.periodo,
        ejercicio: this.state.ejercicio,
        page: pageNumber,
        reset: false
      };
      this.onSetParams(params);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentDidMount() {

    let paramsString = queryString.parse(this.props.location.search);
    let periodo = paramsString["periodo"] !== undefined && paramsString["periodo"] !== "null" ? paramsString["periodo"] : null;
    let ejercicio = paramsString["ejercicio"] !== undefined && paramsString["ejercicio"] !== "null" ? paramsString["ejercicio"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;

    this.props.onInitDocumentos(
      this.state.itemsPerPage,
      page >= 1 ? page - 1 : 0,
      this.props.calificadoraSeleccionada,
      periodo,
      ejercicio
    );

    this.setState({
        activePage: page > 0 ? page : 1,
        periodo: periodo,
        ejercicio: ejercicio,
      });
  }

  onSetPeriodoFilter = periodo => {
    this.setState({
      periodo: periodo
    });
  };

  onSetEjercicioFilter = ejercicio => {
    this.setState({
      ejercicio: ejercicio
    });
  };

  setWasFiltered = wasFiltered => {
    this.setState({
      filtered: wasFiltered,
      loader: true
    });
  };

  onSetParams = ({ periodo, ejercicio, page, reset = false }) => {
    this.setState({
      activePage: page,
      periodo: periodo,
      ejercicio: ejercicio,
    });

    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(periodo, ejercicio, page);
    }
  };

  changeHistory(periodo = null, ejercicio = null, page) {
    this.props.history.push(
      `?calificadora=${this.props.calificadoraSeleccionada}&tab=${3}&periodo=${periodo}&ejercicio=${ejercicio}&page=${
        page ? page : null
      }`
    );

    this.setState({
      loader: true
    });

    this.props.onInitDocumentos(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      this.props.calificadoraSeleccionada,
      periodo,
      ejercicio
    );
  }

  changeHistoryReset() {
    this.props.history.push(
      `?calificadora=${this.props.calificadoraSeleccionada}&tab=${3}&periodo=${null}&ejercicio=${null}&page=${1}`
    );
    this.setState({
      loader: true
    });
    this.props.onInitDocumentos( this.state.itemsPerPage, 0, this.props.calificadoraSeleccionada, null, null);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let dataToShow = null;
    let documentos = this.props.documentos && this.props.documentos.content ? this.props.documentos.content : [];

    if (documentos.length > 0) {
      dataToShow = <Accordion datos={documentos} nombre="documentos" icon={""} tipo={13} subtipo={2} />;
    } else if (documentos.length === 0 ) {
      dataToShow = (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
    }

    if (this.props.documentos && this.props.documentos.content) {
      documentos = this.props.documentos.content;
    }
    const numRows = this.props.documentos ? this.props.documentos.totalElements : 0;

    return (
      <React.Fragment>
        {loader}

        <FiltroCalificadorasReporte
          calificadora={this.props.calificadoraSeleccionada}
          setPeriodoFilter={periodo => this.onSetPeriodoFilter(periodo)}
          setEjercicioFilter={ejercicio => this.onSetEjercicioFilter(ejercicio)}
          wasFiltered={wasFiltered => this.setWasFiltered(wasFiltered)}
          setParams={filterParams => this.onSetParams(filterParams)}
        />

        {dataToShow}

        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitDocumentos: (size, page, calificadoraId, periodo, ejercicio) => {
      dispatch(actions.initDocumentosReporteMensual(size, page, calificadoraId, periodo, ejercicio));
    }
  };
};

const mapStateToProps = state => {
  return {
    documentos: state.emisoras.reporteMensual
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReporteMensual);

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

// =========================================================================

const getEstatusAgenda = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ESTATUSAGENDA_SUCCESS,
      estatus: []
    };
  }
  return {
    type: actionTypes.FETCH_ESTATUSAGENDA_SUCCESS,
    estatus: data
  };
};

export const initEstatusAgenda = (nacional = null) => {
  let url = `${dominio}/emisoras/agendas/estatus`;
  url = nacional !== null && nacional === true ? `${url}?nacional=${nacional}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getEstatusAgenda(response.data));
      })
      .catch(err => dispatch(getEstatusAgenda(err.data)));
  };
};

// =========================================================================

const getTipoDerechoAgenda = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPODERECHOAGENDA_SUCCESS,
      tipos_derecho: []
    };
  }
  return {
    type: actionTypes.FETCH_TIPODERECHOAGENDA_SUCCESS,
    tipos_derecho: data
  };
};

export const initTipoDerechoAgenda = (
  nacional = null,
  sic = null,
  emisora = null,
  clave = null,
  instrumentosAlternativos = false
) => {
  let url = `${dominio}/emisoras/agendas/tipo-derecho?1=1`;

  url = nacional !== null && nacional === true ? `${url}&nacional=${nacional}` : url;
  url = sic !== null && sic === true ? `${url}&sic=${sic}` : url;
  url =
    instrumentosAlternativos === true && emisora && emisora.length > 0
      ? `${url}&tipoInstrumentoAlternativo=${emisora}`
      : instrumentosAlternativos === false && emisora && emisora.length > 0
        ? `${url}&tipoInstrumento=${emisora}`
        : url;

  url = clave !== null && clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getTipoDerechoAgenda(response.data));
      })
      .catch(err => dispatch(getTipoDerechoAgenda(err.data)));
  };
};

// =========================================================================

const getTipoInstrumentoAgenda = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPOINSTRUMENTOAGENDA_SUCCESS,
      tipos_instrumento: []
    };
  }
  return {
    type: actionTypes.FETCH_TIPOINSTRUMENTOAGENDA_SUCCESS,
    tipos_instrumento: data
  };
};

export const initTipoInstrumentoAgenda = (
  nacional = null,
  sic = null,
  empresa = null,
  clave = null
) => {
  let url = `${dominio}/emisoras/agendas/tipo-instrumento?1=1`;
  if (nacional === null && sic === null) {
    nacional = true;
  }
  url = nacional !== null && nacional === true ? `${url}&nacional=${nacional}` : url;
  url = sic !== null && sic === true ? `${url}&sic=${sic}` : url;
  url = empresa && empresa.length > 0 ? `${url}&tipoDerecho=${empresa}` : url;
  url = clave !== null && clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getTipoInstrumentoAgenda(response.data));
      })
      .catch(err => dispatch(getTipoInstrumentoAgenda(err.data)));
  };
};

// =========================================================================

const getClavesAgenda = (data = null) => {
  return {
    type: actionTypes.FETCH_CLAVESAGENDA_SUCCESS,
    claves: data
  };
};

export const initClavesAgenda = (clave, nacional, sic) => {
  let url = `${dominio}/emisoras/agendas/busqueda/${clave}?1=1`;

  url =
    nacional
      ? `${url}&nacional=${nacional}`
      : url;
  url = sic ? `${url}&sic=${sic}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getClavesAgenda(response.data));
      })
      .catch(err => dispatch(getClavesAgenda()));
  };
};
// =========================================================================

const getTipoDerechoAgendaFilter = (data = null) => {
  return {
    type: actionTypes.FETCH_TIPODERECHOAGENDA_SUCCESS,
    tipos_derecho_filter: data
  };
};

export const initTipoDerechoAgendaFilter = (idEmisora, nacional, sic) => {
  let url = `${dominio}/emisoras/sic/empresa/${idEmisora}/aviso-derecho/tipo-derecho`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getTipoDerechoAgendaFilter(response.data));
      })
      .catch(err => dispatch(getTipoDerechoAgendaFilter()));
  };
};

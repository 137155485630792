import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import SubFilterDocumentos from "../../../components/ui/subFilterDocumentos/SubFilterDocumentos";
import { getLan } from "../../../stores/utils/utilities";
import Rss from "../../rss/Rss";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { FormattedHTMLMessage } from "react-intl";
const dominio = `${window.location.protocol}//${window.location.host}`;

class DocumentosEmisoras extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    urlForRss: `${dominio}/emisoras/empresas`,
    emisoraSeleccionada: null,
    showRssModal: false,
    tipoInformacion: null,
    tipoDocumento: [],
    fechaInicio: null,
    fechaFin: null,
    periodo: null,
    ejercicio: null,
    tipo: null,
    wasFiltered: false,
    subTab:2
  };

  componentDidUpdate(prevProps) {
    if (this.props.documentos !== prevProps.documentos) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let documentos = null;


    if (nextProps.documentos && nextProps.documentos.content) {
      documentos = nextProps.documentos.content;
    }
    let resource = documentos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.documentos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });

      this.changeHistory(this.props.emisoraSeleccionada, this.state.tipoInformacion, this.state.tipoDocumento, this.state.fechaInicio, this.state.fechaFin, this.state.periodo, this.state.ejercicio, this.state.tipo, this.state.subTab, pageNumber);
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let emisoraId = paramsString["emisora_id"] !== undefined && paramsString["emisora_id"] !== "null" ? paramsString["emisora_id"] : null;
    let tipoInformacion = paramsString["tipoInformacion"] !== undefined && paramsString["tipoInformacion"] !== "null" ? parseInt(paramsString["tipoInformacion"]) : null;
    let tipoDocumento = paramsString["tipoDocumento"] !== undefined && paramsString["tipoDocumento"] !== "null" ? parseInt(paramsString["tipoDocumento"]) : null;
    let fechaInicio = paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
    let fechaFin = paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    let periodo = paramsString["periodo"] !== undefined && paramsString["periodo"] !== "null" ? paramsString["periodo"] : null;
    let ejercicio = paramsString["ejercicio"] !== undefined && paramsString["ejercicio"] !== "null" ? parseInt(paramsString["ejercicio"]) : null;
    let tipo = paramsString["tipo"] !== undefined && paramsString["tipo"] !== "null" ? paramsString["tipo"] : null;
    let subTab = 2;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;

    let emisoraSeleccionada = null;
    if (emisoraId !== null) {
      emisoraSeleccionada = emisoraId;
    } else {
      emisoraSeleccionada = this.props.emisoraSeleccionada;
    }


    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      tipoInformacion: tipoInformacion,
      tipoDocumento: tipoDocumento,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      periodo: periodo,
      ejercicio: ejercicio,
      tipo: tipo,
      activePage: page > 0 ? page : 1
    });

    this.changeHistory(emisoraSeleccionada, tipoInformacion, tipoDocumento, fechaInicio, fechaFin, periodo, ejercicio, tipo, subTab, page);
    
  }

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  onSetTipoInformacion = (values = null, tipo = null) => {
    this.setState({
      tipoInformacion: values,
      tipo: tipo
    });
  };

  onSetTipoDocumento = (values = null) => {
    const options = values ? [...values] : null;
    this.setState({
      tipoDocumento: options
    });
  };

  onSetFechaIncio = values => {
    this.setState({
      fechaInicio: values
    });
  };

  onSetFechaFin = values => {
    this.setState({
      fechaFin: values
    });
  };

  onSetPeriodo = (values = null) => {
    this.setState({
      periodo: values
    });
  };

  onSetEjercicio = (values = null) => {
    this.setState({
      ejercicio: values
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered,
      loader: true
    });
  };

  onSetActivePage = activePage => {
    this.setState({
      activePage: activePage
    });
  };

  onSetFilters = ({
    empresaId = null,
    tipoInformacion = null,
    tipoDocumento = null,
    fechaInicio = null,
    fechaFin = null,
    periodo = null,
    ejercicio = null,
    tipo = null,
    page = null,
    subTab = 2,
    reset = false
  }) => {
    this.setState({
      emisoraSeleccionada: empresaId,
      tipoInformacion: tipoInformacion,
      tipoDocumento: tipoDocumento,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      periodo: periodo,
      ejercicio: ejercicio,
      tipo: tipo,
      activePage: page
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(empresaId, tipoInformacion, tipoDocumento, fechaInicio, fechaFin, periodo, ejercicio, tipo, subTab, page);
    }
  };

  changeHistory(
    emisoraId = null,
    tipoInformacion = null,
    tipoDocumento = null,
    fechaInicio = null,
    fechaFin = null,
    periodo = null,
    ejercicio = 0,
    tipo = null,
    subTab = 2,
    page = null
  ) {
    this.props.history.push(
      `?emisora_id=${emisoraId}&tipoInformacion=${tipoInformacion}&tipoDocumento=${tipoDocumento}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&periodo=${periodo}&ejercicio=${ejercicio}&tipo=${tipo}&subTab=${subTab}&biva=${this.props.biva}&canceladas=${this.props.canceladas}&page=${page ? page : this.state.activePage}`
    );

    this.setState({
      loader: true
    });

    const params = {
      size: this.state.itemsPerPage,
      page: page > 0 ? page - 1 : 0,
      empresaId: emisoraId,
      tipoInformacion: tipoInformacion,
      tipoDocumento: tipoDocumento,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      periodo: periodo,
      ejercicio: ejercicio,
      tipo: tipo
    };
    this.props.onFilterDocumentos(params);

  }

  changeHistoryReset() {
    this.props.history.push(
      `?emisora_id=${null}&tipoInformacion=${null}&tipoDocumento=${null}&fechaInicio=${null}&fechaFin=${null}&periodo=${null}&page=${null}&ejercicio=${null}&tipo=${null}`
    );
    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      empresaId: this.state.emisoraSeleccionada,
      tipoInformacion: null,
      tipoDocumento: null,
      fechaInicio: null,
      fechaFin: null,
      periodo: null,
      ejercicio: null,
      tipo: null
    };

    this.props.onFilterDocumentos(params);

  }

  render() {
    let showData = null;
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    let documentos = this.props.documentos && this.props.documentos.content ? this.props.documentos.content : [];

    if (documentos.length > 0) {
      showData = <Accordion datos={documentos} nombre="emisoraDocumento" icon={""} tipo={12} subtipo={1} key={+new Date()}/>;
    } else if (documentos.length === 0) {
      showData = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }

    const numRows = this.props.documentos ? this.props.documentos.totalElements : 0;

    const url = `${this.state.urlForRss}/${this.props.emisoraSeleccionada}/documentos/rss`;

    return (
      <React.Fragment>
        {loader}
        <div>
          <SubFilterDocumentos
            emisoraId={this.props.emisoraSeleccionada}
            setTipoInformacion={(values = null, tipo = null) => this.onSetTipoInformacion(values, tipo)}
            setTipoDocumento={(values = null) => this.onSetTipoDocumento(values)}
            setFechaInicio={(values = null) => this.onSetFechaIncio(values)}
            setFechaFin={(values = null) => this.onSetFechaFin(values)}
            setPeriodo={values => this.onSetPeriodo(values)}
            setEjercicio={values => this.onSetEjercicio(values)}
            wasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
            setActivePage={activePage => this.onSetActivePage(activePage)}
            setFilters={filterParams => this.onSetFilters(filterParams)}
          />
          <button className={["btn btn-link margen_50"].join(" ")} type="button" onClick={this.onShowRssModalHandler}>
            <div className="margen_50 texto_16 montse regular texto_naranja">
              {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
              <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </button>
          <Rss
            showRssModal={this.state.showRssModal}
            modalTitle={getLan() === "es" ? "Emisoras inscritas - Documentos" : "Registered issuers - Documents"}
            urlRss={url}
            optionType={2}
            rssType={3}
            showClassModel1
          />
          <div className="margen_50 texto_10 montse semi_bold color_gris">
            <FormattedHTMLMessage id="empresas.emisorasinscritas.emisora.documentos.visor" defaultMessage=" " />
          </div>
        </div>
        <div className="margen_50">{showData}</div>

        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitDocumentos: (size, page, emisoraId, tipoDocumento) => {
      dispatch(actions.initDocumentos(size, page, emisoraId, tipoDocumento));
    },
    onFilterDocumentos: params => {
      dispatch(actions.filterDocumentos(params));
    }
  };
};

const mapStateToProps = state => {
  return {
    documentos: state.emisoras.documentos
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DocumentosEmisoras)
);

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
const base_url = `${dominio}/emisoras/sectores`;

const setRamos = (data = null) => {
  return {
    type: actionTypes.FETCH_RAMOS_SUCCESS,
    ramos: data
  };
};

export const filterRamos = ({
  sector = null,
  subSector = null,
  biva = null,
  canceladas = null,
  instrumento = null,
  tipoValor = null
}) => {
  let url = base_url;
  url = `${url}/${[...sector]}/subsectores/${subSector}/ramos`;
  url = instrumento && instrumento.length > 0 ? `${url}${url.includes("?") ? "&" : "?"}tipoInstrumento=${[...instrumento]}` : url;
  url = tipoValor && tipoValor.length > 0 ? `${url}${url.includes("?") ? "&" : "?"}tipoValor=${[...tipoValor]}` : url;
  url = biva ? `${url}${url.includes("?") ? "&" : "?"}biva=${biva}` : url;
  url = canceladas ? `${url}${url.includes("?") ? "&" : "?"}canceladas=${canceladas}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setRamos(response.data));
      })
      .catch(() => dispatch(setRamos()));
  };
};

const setSubRamos = (data = null) => {
  return {
    type: actionTypes.FETCH_SUBRAMOS_SUCCESS,
    sub_ramos: data
  };
};

export const filterSubRamos = ({
  sector = null,
  subSector = null,
  ramos = null,
  instrumento = null,
  tipoValor = null,
  biva = null,
  canceladas = null
}) => {
  let url = base_url;
  url = `${url}/${[
    ...sector
  ]}/subsectores/${subSector}/ramos/${ramos}/subramos`;
  url = instrumento && instrumento.length > 0 ? `${url}${url.includes("?") ? "&" : "?"}tipoInstrumento=${[...instrumento]}` : url;
  url = tipoValor && tipoValor.length > 0 ? `${url}${url.includes("?") ? "&" : "?"}tipoValor=${[...tipoValor]}` : url;
  url = biva ? `${url}${url.includes("?") ? "&" : "?"}biva=${biva}` : url;
  url = canceladas ? `${url}${url.includes("?") ? "&" : "?"}canceladas=${canceladas}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSubRamos(response.data));
      })
      .catch(() => dispatch(setSubRamos()));
  };
};

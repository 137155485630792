import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import classes from "./Organismos.css";
import * as action from "../../../stores/actions/index";
import { getLocal, getUrlDocument } from "../../../stores/utils/utilities";

import Auxs from "../../../hoc/auxs/Auxs";

class Organismos extends Component {
  state = {
    messages: null,
    lan: getLocal(),
  };
  componentDidMount() {
    this.props.onInitIntl(2);

    this.props.onImagenesEdu();
    this.props.onImagenesEmp();
    this.props.onImagenesEstatales();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);

        if (this.state.messages !== messages) {
          this.setState({
            messages: messages,
          });
        }
      }
    }
  }

  render() {
    let imagenes_emp = null,
      imagenes_edu = null;
    if (this.props.imagenes_edu) {
      imagenes_edu = this.props.imagenes_edu.map((img, i) => {
        let ruta = null;
        if (img.url) {
          ruta = getUrlDocument(img.url);
        }
        let style = {
          backgroundImage: `url(${ruta})`
        };
        return (
          <div className="col col-lg-6" key={"img_"+i}>
            <div className={classes.imgContainer}>
            <div  style={style} className={[classes.img]} />
            </div>
          </div>
        );
      });
    }

    if (this.props.imagenes_emp) {
      imagenes_emp = this.props.imagenes_emp.map((img, i) => {
        let ruta = null;
        if (img.url) {
          ruta = getUrlDocument(img.url);
        }

        let style = {
          backgroundImage: `url(${ruta})`
        };

        return (
          <div className="col col-lg-6">
            <div className={classes.imgContainer}>
            <div  style={style} className={[classes.img]} />
            </div>
          </div>
        );
      });
    }

    let imagenes_estatales = null;
    if (this.props.imagenes_estatales && this.props.imagenes_estatales.length > 0) {
      imagenes_estatales = this.props.imagenes_estatales.map((img, i) => {
        let ruta = null;
        if (img.url) {
          ruta = getUrlDocument(img.url);
        }

        let style = {
          backgroundImage: `url(${ruta})`
        };

        return (
          <div  style={style} className={[classes.img]} />

        );
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1
                  className={[classes.main_title, "texto_42", "nunito"].join(
                    " "
                  )}
                >
                  <FormattedMessage
                    id="alianzas.convenioyasociaciones.organismos.titulo"
                    defaultMessage=" "
                  />
                </h1>
              </div>
            </div>
          </div>
          <div className="row" id="empresas">
            <div className="col sin_padding_L">
              <div
                className={["main_subtitle", "texto_30", "nunito"].join(" ")}
              >
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedMessage
                    id="alianzas.convenioyasociaciones.organismos.subTitulo.organismos"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
          </div>
          <div className="row" >
            <div className="col sin_padding_L">
              <div
                className={[classes.text_container, "texto_16", "montse"].join(
                  " "
                )}
              >
                <p>
                  <FormattedMessage
                    id="alianzas.convenioyasociaciones.organismos.introduccion.organismos"
                    defaultMessage=" "
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="row margen_top_50">{imagenes_emp}</div>

          <div className="row margen_top_50" id="universidades">
            <div className="col sin_padding_L">
              <div
                className={["main_subtitle", "texto_30", "nunito"].join(" ")}
              >
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedMessage
                    id="alianzas.convenioyasociaciones.organismos.subTitulo.instituciones"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
          </div>
          <div className="row ">
            <p className={"texto_16 montse regular"}>
              <FormattedMessage
                id="alianzas.convenioyasociaciones.organismos.introduccion.instituciones"
                defaultMessage=" "
              />
            </p>
            <div className="row margen_top_50 col-12 sin_padding_L">
              {imagenes_edu}
            </div>
          </div>
          <div className={imagenes_estatales ? null : "d-none"} id="gobierno">
            <div className="row margen_top_30">
              <div className="col sin_padding_L">
                <div
                  className={["main_subtitle", "texto_30", "nunito"].join(" ")}
                >
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedMessage
                      id="alianzas.convenioyasociaciones.estatales.titulo"
                      defaultMessage=" "
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="row margin_bottom_300">
              <p className={"texto_16 montse regular"}>
                <FormattedMessage
                  id="alianzas.convenioyasociaciones.estatales.introduccion"
                  defaultMessage=" "
                />
              </p>
              <div className="row margen_top_50 col-12 sin_padding_L">
                {imagenes_estatales}
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    diccionario: state.intl.diccionario,
    imagenes_emp: state.imagenes.alianzas_emp,
    imagenes_edu: state.imagenes.alianzas_edu,
    imagenes_estatales: state.imagenes.alianzas_states,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    onImagenesEdu: () => {
      dispatch(action.initImagenesAlianzasEdu());
    },
    onImagenesEmp: () => {
      dispatch(action.initImagenesAlianzasEmp());
    },
    onImagenesEstatales: () => {
      dispatch(action.initImagenesAlianzasStates());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organismos);

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import classes from "./Menu.css";
import logoBiva2 from "../../../assets/images/logo2.png";
import HamburgerMenu from "react-hamburger-menu";
import TickerTapeWidget from "../../../components/ui/tickers/TickerTapeWidget";
import { getLan } from "../../../stores/utils/utilities";
import Search from "../../search/Search";
import $ from "jquery";


function irArriba(duration) {
  let top = window.pageYOffset;
  //cuando la pantalla se encuentre en el limite antes de que se esconda por completo el menu
  if( top <= 155 ){
    $("body, html")
    .stop()
    .animate(
      {
        scrollTop: 0
      },
      duration
    );
  }
}

class Menu extends Component {
  state = {
    hiddenMenu: true,
    hiddenSearchModal: true,
    hiddenSubMenu1: null,
    prevScrollpos: window.pageYOffset,
    visible: true,
    location: null,
    nivelInferior: null
  };
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.hiddenSearch = this.hiddenSearch.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    this.pintaIdioma();
    this.updateWindowDimensions();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("keydown", this.escFunction, false);
    this.setState({
      hiddenMenu: true,
      hiddenSearchModal: true
    });
    this.unlisten = this.props.history.listen((location, action) => {
      this.updatePath();
    });
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.removeEventListener("keydown", this.escFunction, false);
  }

  updatePath = () => {
    let location = window.location.pathname;
    let locations = location.split("/");
    let nivelInferior = null;
    if (locations.length <= 2) {
      nivelInferior = location;
    } else {
      nivelInferior = "/" + locations[1] + "/" + locations[2] + "/" + locations[3];
    }
    this.setState({ location: location, nivelInferior: nivelInferior });
  };
  // Hide or show the menu.
  handleScroll = () => {
    var nav_menu = document.getElementById("nav_menu");
    let currentScrollPos = window.pageYOffset;
    let prevScrollpos = this.state.prevScrollpos;
    if (nav_menu !== null) {
      if (this.state.prevScrollpos > currentScrollPos) {
        prevScrollpos = currentScrollPos;
        nav_menu.style.top = "0";
      } else {
        nav_menu.style.top = "-155px";
      }
    }

    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    if (window.innerWidth > 1199) {
      this.setState({
        hiddenMenu: true,
        hiddenSubMenu1: null
      });
    }
  }

  ocultaMenu() {
    this.setState({
      hiddenMenu: !this.state.hiddenMenu
    });
    if (!this.state.hiddenSearchModal) {
      this.hiddenSearch();
    }
  }

  ocultaMenu2() {
    return null;
  }
  escFunction(event){
    if (event.key === "Escape") {
      if (!this.state.hiddenSearchModal) {
        this.hiddenSearch();
      }
    }
  }

  hiddenSearchForce(event){
   if (!this.state.hiddenSearchModal) {
        this.hiddenSearch();
   }
  }

  hiddenSearch() {
    const hidden = !this.state.hiddenSearchModal;
    if (!hidden) {
      document.body.style.overflowY = "hidden";
      irArriba();
    }else{
      document.body.style.overflowY = "inherit";
    }
    this.setState({ hiddenSearchModal: hidden });
  }
  ocultaSubMenuNivel1(index) {
    let indexSeleccionado = null;
    if (index !== this.state.hiddenSubMenu1) {
      indexSeleccionado = index;
    }
    this.setState({
      hiddenSubMenu1: indexSeleccionado
    });
  }

  pintaIdioma() {
    const idioma = getLan();

    var espanol = document.getElementById("es");
    var ingles = document.getElementById("en");
    if (idioma === "es") {
      espanol.classList.add("activeLenguage");
      ingles.classList.remove("activeLenguage");
    }
    if (idioma === "en") {
      ingles.classList.add("activeLenguage");
      espanol.classList.remove("activeLenguage");
    }
  }

  seleccionIdioma(event, idioma) {
    event.preventDefault();
    if (idioma !== null) {
      localStorage.setItem("idioma", idioma);
      if (idioma === "en") {
        localStorage.setItem("local", "en-US");
      } else {
        localStorage.setItem("local", "es-MX");
      }
      window.location.reload(false);
    }
    this.pintaIdioma();
  }

  render() {
    let menu = null;
    let es_alto_maximo = null;

    if (this.props.menu) {
      menu = this.props.menu.menu.map((item, indexSubmenu) => {
        return (
          <li key={"menu" + indexSubmenu} className={classes.primary_nav__link} itemProp="name">
            <NavLink className={classes.primary_nav__anchor} data-mega-menu-enabled="true" to={item.to} itemProp="url" onClick={this.ocultaMenu.bind(this)}>
              <span
                className={[
                  classes.primary_nav__anchor_text,
                  "nunito texto_14_menu regular color_blanco",
                  this.state.nivelInferior === item.to || (item.to).includes(this.state.nivelInferior) ? classes.activo : null
                ].join(" ")}
              >
                {item.name}
              </span>
            </NavLink>

            <button
              className={classes.primary_nav__expand}
              data-enabled={this.state.hiddenSubMenu1 === indexSubmenu ? "true" : "false"}
              aria-label="Market Activity - Click to open sub_navigation"
              onClick={this.ocultaSubMenuNivel1.bind(this, indexSubmenu)}
            >
              <span className={[classes.primary_nav__expand_caret2, "fas fa-chevron-right"].join(" ")} aria-hidden="true" />
            </button>
            <div
              className={classes.primary_nav__mega}
              aria-hidden={this.state.hiddenSubMenu1 === indexSubmenu ? "false" : "true"}
            >
              <ul className={classes.primary_nav__mega_list}>
                {(() => {
                  if (item.submenu === undefined || item.submenu === null || item.submenu.length > 0) {
                    return null;
                  }
                })()}

                {item.submenu.map((submenu, indexSubMenu2) => {
                  let pinta_alto_maximo = false;
                  if (es_alto_maximo) {
                    pinta_alto_maximo = true;
                    es_alto_maximo = false;
                  }
                  if (submenu.submenu !== undefined && submenu.submenu.length > 6) {
                    es_alto_maximo = true;
                  }
                  let link = null;
                  let spanSubmenu = (
                    <span
                      className={[
                        classes.primary_nav__header_link_text,
                        "nunito texto_16 bold color_azul",
                        this.state.nivelInferior === submenu.to || (submenu.to).includes(this.state.nivelInferior)
                          ? classes.activo_sub
                          : null
                      ].join(" ")}
                    >
                      {submenu.name}
                    </span>
                  );
                  if (submenu.to.includes("http")) {
                    link = (
                      <a href={submenu.to} target="_blank" rel="noopener noreferrer" className={classes.primary_nav__header_link}>
                        {spanSubmenu}
                      </a>
                    )
                  } else {
                    link = (
                      <NavLink className={classes.primary_nav__header_link} to={submenu.to} itemProp="url" onClick={this.ocultaMenu.bind(this)}>
                        {spanSubmenu}
                      </NavLink>
                    );
                  }
                  return (
                    <li
                      key={"submenu" + indexSubMenu2}
                      className={[
                        classes.primary_nav__mega_column,
                        pinta_alto_maximo ? classes.alto_maximo : null,
                        indexSubMenu2 >= 6 ? classes.primary_nav__mega_column_ultimo : null
                      ].join(" ")}
                      itemProp="name"
                    >
                      <span className={classes.primary_nav__header}>
                        {link}
                      </span>

                      {(() => {
                        if (submenu.submenu !== undefined) {
                          return (
                            <ul className={classes.primary_nav__mega_links}>
                              {submenu.submenu.map((subsub, i) => {
                                return (() => {
                                  if (subsub.submenu === undefined) {
                                    return (
                                      <li key={"subsub" + i} className={classes.primary_nav__mega_links} itemProp="name">
                                        <NavLink className={classes.primary_nav__mega_links_link} to={subsub.to} itemProp="url" onClick={this.ocultaMenu.bind(this)}>
                                          <span
                                            className={[
                                              "montse texto_14 regular color_gris",
                                              this.state.nivelInferior === subsub.to || (subsub.to).includes(this.state.nivelInferior) ? classes.activo : null
                                            ].join(" ")}
                                          >
                                            {subsub.name}
                                          </span>
                                        </NavLink>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <React.Fragment key={"subsub" + i}>
                                        <li key={"subsubDatos" + i} className={classes.primary_nav__mega_links} itemProp="name">
                                          <NavLink className={classes.primary_nav__mega_links_link} to={subsub.to} itemProp="url" onClick={this.ocultaMenu.bind(this)}>
                                            <span
                                              className={[
                                                classes.subsub,
                                                "montse texto_14 regular color_gris",
                                                this.state.location === subsub.to ? classes.activo : null
                                              ].join(" ")}
                                            >
                                              {subsub.name}
                                            </span>

                                          </NavLink>
                                        </li>
                                        <ul>
                                          {subsub.submenu.map((subsub2, i) => {
                                            return (
                                              <li
                                                key={"subsub2" + i}
                                                className={[classes.primary_nav__mega_links, classes.subsub_links].join(" ")}
                                                itemProp="name"
                                              >
                                                <NavLink
                                                  className={classes.primary_nav__mega_links_link}
                                                  to={subsub2.to}
                                                  itemProp="url"
                                                  onClick={this.ocultaMenu.bind(this)}
                                                >
                                                  <span className={["montse texto_14 regular color_gris"]}>{subsub2.name}</span>
                                                </NavLink>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </React.Fragment>
                                    );
                                  }
                                })();
                              })}
                            </ul>
                          );
                        }
                      })()}
                    </li>
                  );
                })}
              </ul>
            </div>
          </li>
        );
      });
    }

    return (
      <React.Fragment>
        <header
          id="nav_menu"
          className={[classes.nav_menu, classes.primary_nav, classes.primary_nav_sticky].join(" ")}
          data-mega-menu-disabled="FALSE"
          role="banner"
        >
          <nav className={classes.primary_nav__content} aria-label="Primary Navigation" role="navigation">
            <button
              onClick={this.ocultaMenu.bind(this)}
              className={classes.primary_nav__hamburger}
              aria-label="Toggle mobile navigation menu"
            >
              <HamburgerMenu
                isOpen={!this.state.hiddenMenu}
                menuClicked={this.ocultaMenu2.bind()}
                width={18}
                height={15}
                strokeWidth={1}
                rotate={0}
                color="white"
                borderRadius={0}
                animationDuration={0.5}
              />
            </button>

            <div className={classes.primary_nav__logo}>
              <NavLink to={"/"}>
                <img className={classes.primary_nav__logo_normal} src={getLan() === "es" ? logoBiva2 : logoBiva2} alt="BIVA" />
              </NavLink>
            </div>

            <ul className={classes.primary_nav__links} aria-hidden={this.state.hiddenMenu ? "true" : "false"}>
              {menu}
            </ul>

            <div className={classes.primary_nav__right}>
              <div className={classes.contenedor_idioma}>
                <div id="es" className={[classes.idioma, "nunito"].join(" ")} onClick={e => this.seleccionIdioma(e, "es")}>
                  <span className={"nunito texto_17 color_blanco"}>ES</span>
                </div>
                <div className={[classes.pipe, "nunito"].join(" ")}>&nbsp;</div>
                <div id="en" className={[classes.idioma, "nunito"].join(" ")} onClick={e => this.seleccionIdioma(e, "en")}>
                  <span className={"nunito texto_17 color_blanco"}>EN</span>
                </div>
              </div>
            </div>
          </nav>
          <div id="ticker" className={classes.ticker}>
            <TickerTapeWidget locale={getLan()} />
          </div>
          <div id="search_container" className={classes.search_container}>
          <div id="search_div" className={[classes.search,  "nunito"].join(" ")} onClick={this.hiddenSearch.bind(this)}>
                  <input type="text" className={["form-control montse", classes.inputSearch].join(" ")} id="inputSearchMenu" aria-describedby="search" placeholder={getLan() === "es" ? "Buscar" : "Search"}/>
                  <span className={["nunito texto_17 color_azul", classes.iconSearch].join(" ")}><i className="fas fa-search"></i></span>
                </div>
          </div>
        </header>
          <Search
          hiddenSearch={this.hiddenSearch.bind(this)}
          hiddenSearchForce={this.hiddenSearchForce.bind(this)}
          hidden={this.state.hiddenSearchModal}
          />
      </React.Fragment>
    );
  }
}

const mapStateToPorps = state => {
  return {
    menu: state.menu.menu
  };
};

export default withRouter(connect(mapStateToPorps)(Menu));

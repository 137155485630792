import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import { getLan } from "../../../stores/utils/utilities";

class EmisionesSic extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: false,
    emisoraSeleccionada:null
  };

  componentWillReceiveProps(nextProps) {
    let valoresListados = null;

    this.setState({
      loader: false
    });
    if (nextProps.valoresListados && nextProps.valoresListados.content) {
      valoresListados = nextProps.valoresListados.content;
    }
    let resource = valoresListados;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.valoresListados;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.props.onInitSicValoresListados(this.state.itemsPerPage, pageNumber - 1,this.props.emisoraSeleccionada);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    this.props.onInitSicValoresListados(this.state.itemsPerPage, 0, this.props.emisoraSeleccionada); 
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let showData= null;
    let valoresListados = this.props.valoresListados && this.props.valoresListados.content ? this.props.valoresListados.content : [];
    if (valoresListados.length > 0) {
      showData = <Accordion datos={valoresListados} nombre="sicValores" icon={""} tipo={12} subtipo={4} />;
    } else if (valoresListados.length === 0) {
      showData = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }
    const numRows = this.props.valoresListados ? this.props.valoresListados.totalElements : 0;
 

    return (
        <React.Fragment>
          {loader}
          <div className="margen_top_30">{showData}</div>
           <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          /> 
        </React.Fragment>
    );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitSicValoresListados: (size, page, empresaId) => {
        dispatch(actions.initSicValoresListados(size, page, empresaId));
    }
  };
};

const mapStateToProps = state => {
  return {
    valoresListados:  state.emisoras.valoresListados
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmisionesSic);

import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getLan, getKeyYoutube } from "../utils/utilities";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

//ACERCA DE
const setAcercade = data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_ACERCADE_SUCCESS,
            acercade: []
        };
    }
    return {
        type: actionTypes.FETCH_ACERCADE_SUCCESS,
        acercade: data
    };
};

export const getAcercade= () => {
    return dispatch => {
        axios
            .get(dominio + "/cms/o/documents/bivacloud-acerca-de/lang/" + lang)
            .then(response => {
                dispatch(setAcercade(response.data));
            })
            .catch(err => dispatch(setAcercade(err.data)));
    };
};

//CUOTAS
const setCuotas = data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_CUOTAS_CLOUD_SUCCESS,
            cuotas: []
        };
    }
    return {
        type: actionTypes.FETCH_CUOTAS_CLOUD_SUCCESS,
        cuotas: data
    };
};

export const getCuotas= () => {
    return dispatch => {
        axios
            .get(dominio + "/cms/o/documents/bivacloud-cuotas/lang/" + lang)
            .then(response => {
                dispatch(setCuotas(response.data));
            })
            .catch(err => dispatch(setCuotas(err.data)));
    };
};

//CUOTAS
const setCuotasApi = data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_CUOTAS_API_CLOUD_SUCCESS,
            cuotasApis: []
        };
    }
    return {
        type: actionTypes.FETCH_CUOTAS_API_CLOUD_SUCCESS,
        cuotasApis: data
    };
};

export const getTabsCuotasApi= () => {
    return dispatch => {
        axios
            .get(dominio + "/cms/o/documents/biva-cloud/grupos?lang=" + lang)
            .then(response => {
                dispatch(setCuotasApi(response.data));
            })
            .catch(err => dispatch(setCuotasApi(err.data)));
    };
};


//APIS
const setApis= data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_APIS_SUCCESS,
            apis: []
        };
    }
    return {
        type: actionTypes.FETCH_APIS_SUCCESS,
        apis: data
    };
};

export const getApis= () => {
    return dispatch => {
        axios
            .get(dominio + "/cms/o/documents/bivacloud-apis/lang/" + lang)
            .then(response => {
                dispatch(setApis(response.data));
            })
            .catch(err => dispatch(setApis(err.data)));
    };
};


//LOGO
const setLogo= data => {
    if (data === undefined || data === null) {
        return {
            type: actionTypes.FETCH_LOGO_SUCCESS,
            logo: []
        };
    }
    return {
        type: actionTypes.FETCH_LOGO_SUCCESS,
        logo: data
    };
};

export const getLogo= () => {
    return dispatch => {
        axios
            .get(dominio + "/cms/o/documents/imagen-biva-cloud/lang/" + lang)
            .then(response => {
                dispatch(setLogo(response.data));
            })
            .catch(err => dispatch(setLogo(err.data)));
    };
};
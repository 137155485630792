import React, { Component } from "react";

import classes from "./Cuadricula.css";
import Cuadro from "./cuadro/Cuadro";

class Cuadricula extends Component {
  // <Cuadricula datos={cuadricula1} coloreados={[]} saltarse={[7]} enBlanco={[]} quitar={[]}/>
  // datos = json de los datos que se pintaran
  // coloreados = array de los cuadro que sera coloreado en azul ejemplo [1,2,5,6] nota: el numero no es el indice del array
  // saltarse = array de los cuadros que seran ignorados para pintar datos
  // enBlanco = array de los cuadros que se pintaran en blanco con borde azul
  // quitar = array de los cuadros que seran pintados totalmente en blanco como si no existieran
  // tipo = 1/null es cuadricula normal, 2 cuadricula larga, 3 cuadricula larga con boton
  // sinFondo = true/false
  state = {
    datos: []
  };

  componentWillReceiveProps(props) {
    // metodo que sirve para dejar como antes los json que tenia en un inicio
    if (props.datos) {
      props.datos.map((cuadro, i) => {
        this.eliminaDatoProp(i, props);
        return null;
      });
    }
  }

  eliminaDatoProp(indice, props) {
    let dato_borrar = {};
    dato_borrar = props.datos[indice];
    if (dato_borrar.texto === "" && dato_borrar.clase === "" && dato_borrar.icono_url === "") {
      props.datos.splice(indice, 1);
    }
  }
  eliminaDato(indice) {
    let dato_borrar = {};
    dato_borrar = this.props.datos[indice];
    if (dato_borrar.texto === "" && dato_borrar.clase === "" && dato_borrar.icono_url === "") {
      this.props.datos.splice(indice, 1);
    }
  }
  componentWillUnmount() {
    // metodo que sirve para dejar como antes los json que tenia en un inicio
    if (this.props.datos) {
      this.props.datos.map((cuadro, i) => {
        this.eliminaDato(i);
        return null;
      });
    }
  }

  render() {
    let cuadroItems = null;
    let fondo = [];
    let seSalta = false;
    let seColorea = false;
    let enBlanco = false;
    let quitar = false;
    let datos = [];
    let cuadros = 0;
    let modulo = 3;
    let ultimo_elemento = 0;
    let ultimo_elemento_saltar = 0;
    let ultimo_elemento_quitar = 0;
    let copia_saltarse = [];
    let copia_quitar = [];
    let es_ultimo = false;
    let tipo = 1;
    let justificado = false;
    function eliminaDato(indice) {
      let dato_borrar = {};
      dato_borrar = datos.slice(0);
      dato_borrar = dato_borrar.pop();
      if (dato_borrar.texto === "" && dato_borrar.clase === "" && dato_borrar.icono_url === "") {
        datos.pop();
      } else {
        es_ultimo = true;
      }
    }

    if (this.props.datos) {
      if (this.props.modulo) {
        modulo = this.props.modulo;
      }
      cuadros = this.props.datos.length;
      while (cuadros % modulo !== 0) {
        if (cuadros > modulo) {
          cuadros++;
        }
      }

      copia_saltarse = this.props.saltarse.slice(0);
      copia_quitar = this.props.quitar.slice(0);

      ultimo_elemento_quitar = copia_quitar.length > 0 ? copia_quitar.pop() : 0;
      ultimo_elemento_saltar = copia_saltarse.length > 0 ? copia_saltarse.pop() : 0;
      if (ultimo_elemento_quitar >= ultimo_elemento_saltar) {
        ultimo_elemento = ultimo_elemento_quitar;
      } else {
        ultimo_elemento = ultimo_elemento_saltar;
      }
      datos = this.props.datos;

      while (datos.length < ultimo_elemento) {
        datos.push({ texto: "", clase: "", icono_url: "" });
      }

      cuadros = datos.length;
      if (cuadros > ultimo_elemento) {
        cuadros = ultimo_elemento;
      }
      while (cuadros % modulo !== 0) {
        if (cuadros > modulo) {
          cuadros++;
        }
      }

      datos.map((cuadro, i) => {
        let seSalta2 = this.props.saltarse.indexOf(i + 1) > -1;
        let quitar2 = this.props.quitar.indexOf(i + 1) > -1;
        if (seSalta2 === true || quitar2 === true) {
          datos.splice(i, 0, { texto: "", clase: "", icono_url: "" });
        }
        return null;
      });

      while (!es_ultimo) {
        let indice = datos.length - 1;
        eliminaDato(indice);
      }

      cuadros = datos.length;
      while (cuadros % modulo !== 0) {
        if (cuadros > modulo) {
          cuadros++;
        }
      }

      while (datos.length < cuadros) {
        datos.push({ texto: "", clase: "", icono_url: "" });
      }

      if (modulo === 2) {
        fondo.push(classes.fondo_cuadricula3);
        justificado = true;
      } else if (cuadros > 6) {
        fondo.push(classes.fondo_cuadricula1);
      } else {
        fondo.push(classes.fondo_cuadricula2);
      }

      if (this.props.tipo === 2) {
        fondo = [];
        fondo.push(classes.fondo_cuadricula_tipo_2);
        tipo = 2;
      }
      if (this.props.tipo === 3) {
        fondo = [];
        fondo.push(classes.fondo_cuadricula_tipo_2);
        tipo = 3;
      }

      if (this.props.tipo === 4) {
        tipo = 4;
      }
      if (this.props.tipo === 5) {
        tipo = 5;
      }
      if (this.props.sinFondo) {
        fondo.push(classes.sinFondo);
      }

      if (this.props.tipo === 6 || this.props.tipo === 9) {
        fondo = [];
        fondo.push(classes.fondo_cuadricula4);
        tipo = 1;
      }
      if (this.props.tipo === 7) {
        tipo = 5;
        fondo = [];
        fondo.push(classes.fondo_cuadricula4);
      }
      if (this.props.tipo === 8) {
        //   para tipo con subtitulo
        tipo = 5;
        fondo = [];
        fondo.push(classes.fondo_cuadricula3);
        fondo.push(classes.cuadriculaSubtitulo);
      }

      if (this.props.justificado) {
        justificado = true;
      }

      cuadroItems = datos.map((cuadro, i) => {
        seColorea = this.props.coloreados.indexOf(i + 1) > -1;
        seSalta = this.props.saltarse.indexOf(i + 1) > -1;
        enBlanco = this.props.enBlanco.indexOf(i + 1) > -1;
        quitar = this.props.quitar.indexOf(i + 1) > -1;
        let llevaBoton = false;
        let es_vacio = false;
        if (tipo === 4) {
          llevaBoton = true;
        }
        if (cuadro.texto === "" && cuadro.clase === "" && cuadro.icono_url === "") {
          es_vacio = true;
          llevaBoton = false;
        }

        return (
          <Cuadro
            indice={i}
            salto={seSalta}
            color={seColorea}
            enBlanco={enBlanco}
            quitar={quitar}
            datos={cuadro}
            tipo={tipo}
            type={this.props.tipo}
            key={`${i}`}
            justificado={justificado}
            es_vacio={es_vacio}
            cuadroSeleccion={this.props.cuadroSeleccion}
            llevaBoton={llevaBoton}
            textoChico={this.props.textoChico}
          />
        );
      });
    }
    return (
      <div className={["col-sm-12", classes.relativo, classes.cuadricula_responsiva].join(" ")}>
        <div className={fondo.join(" ")}>{cuadroItems}</div>
      </div>
    );
  }
}

export default Cuadricula;

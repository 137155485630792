import React, { Component } from "react";
import classes from "./BibliotecaDigital.css";

import Auxs from "../../../hoc/auxs/Auxs";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLan, getLocal } from "../../../stores/utils/utilities";
import TabsVideos from "../../../components/ui/tabsVideos/TabsVideos";
import hojaVerde from "../../../assets/icons/sustentable/hoja-verde-2.png";

class BibliotecaDigital extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
          <div
                    className={[
                      "main_subtitle",
                      "nunito",
                      "containerTituloSg",
                      "sin_padding"
                    ].join(" ")}
                  >
                    <img
                      src={hojaVerde}
                      alt="bivasg"
                      className={["slash", "imgSg"].join(" ")}
                    />
                    <h1 className={["tituloSg texto_42 nunito texto_verde_oscuro", classes.texto42].join(" ")}>{getLan() === "es" ? "Biblioteca digital" : "Digital library"}</h1>
                  </div>
          </div>
          <div className="margen_top_20">
            <TabsVideos history={this.props.history}  location={this.props.location} itemsPerPage={6} maxResults={50}/>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.getTabsCategorias("biblioteca-biva-sostenible"));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BibliotecaDigital);

import { updateObject } from "../utils/utilities";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  bibliografia: [],
  acuerdos_img: null,
  cita_maria: null,
  bannerBiblioteca: null,
  banner: null,
  banner360: null,
  botones360: null,
  aliadosSoluciones: null,
  esgContainers: null,
  reporteo: null,
  acuerdosFirmas: null,
  emisionesASG: null,
  emisionASGDetalle: null,
  sicEtfASG: null,
  sicEtfASGDetalle: null,
  alternativosASG: null,
  alternativosASGFicha: null,
  listadoClaves: null,
  team: null,
  objetivos: null,
  objetivosGuia: null,
  capitulosGuia: null,
  materialidad: null,
  contextoGeneral: null,
  despliegueCultura: null,
  clasificacion: null,
  reporteoAsg: null,
  linkGuia: null,
  cuestionario: null,
  documentos: null,
  mercados: null,
  paises: null,
  cuestionarios: null,
  ejercicios: null,
  sectores: null,
  tiposEmisora: null,
  catalogoSectores: null,
  catalogoTiposEmisora: null,
  tiposInstrumento: null,
  emisionASGFicha: null,
};

const setBivaSustentable = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BIBLIOGRAFIA_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_ACUERDOSIMAGENES_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CITA_MARIA_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_BIBLIOTECA_ASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_BANNERASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_BANNER360_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_BUTTONS360_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_ALIADOS_SOLUCIONES_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_ESG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_REPORTEO_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_ACUERDOS_FIRMAS_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_EMISIONES_ASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_EMISION_ASG_DETALLE_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CLAVESEMISIONES_ASG_SUCCESS:
    case actionTypes.FETCH_TEAM_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_OBJETIVOSGUIA_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CAPITULOSGUIA_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_MATERIALIDAD_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CONTEXTO_GENERAL_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_DESPLIEGUECULTURA_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CLASIFICACIONASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_REPORTEOASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_LINK_GUIA_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CUESTIONARIO_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_SIC_ETF_ASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_SIC_ETF_ASG_DETALLE_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_PAIS_SIC_ETF_ASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_MERCADO_SIC_ETF_ASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_ALTERNATIVOS_ASG_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_ALTERNATIVOS_ASG_FICHA_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_ALTERNATIVOS_ASG_TIPO_INSTRUMENTO_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CLAVES_AMAFORE_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_EJERCICIOS_AMAFORE_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_SECTORES_AMAFORE_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_TIPOSEMISORA_AMAFORE_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CATALOGO_SECTORES_AMAFORE_SUCCESS:
      return setBivaSustentable(state, action);
    case actionTypes.FETCH_CATALOGO_TIPOSEMISORA_AMAFORE_SUCCESS:
      return setBivaSustentable(state, action);
    default:
      return state;
  }
};

export default reducer;

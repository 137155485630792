import React from "react";
import Cursos from "../../instituto/cursos/Cursos";


const Educacion = () => {
  return (
   <Cursos type={2} tag="asg"/>
  );
};

export default Educacion;
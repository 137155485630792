import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import { v4 as uuid } from 'uuid';
import classes from "./Toggle.css";
import {
  getRealDateMilliesDayMonthYear,
  getRealDateMilliesHourMinSeg,
  getRealDateMilliesDayMonthYearTimeZone,
  formatDate_ddMMyyy_millies,
  setColorIndice,
  getUrlDocument,
  getLan,
  formatDate,
  formatDateDiaMesAno,
  getTypeLink,
  formatNumber,
  setFormatNumberIntString,
  formatDecimal,
  formatInt,
  getRealDateMilliesDayMonthYearLocale,
  setFormatCurrencyStringTwo,
  setFormatCurrencyStringThree,
  getRealDateIgnoreTimeIfEmpty
} from "../../../../stores/utils/utilities";

import Auxs from "../../../../hoc/auxs/Auxs";
import BackDrop from "../../backdrop/BakcDrop";
import Modal from "../../modal/Modal";
import Box from "../../box/Box";
import Boton from "../../boton/Boton";
import Fotos from "../../fotos/Fotos";
import DocumentosProspectos from "../../../../containers/empresas/tramitesListado/documentosProspectos/DocumentosProspectos";
import loader from "../../../../assets/gif/loader.gif";
import Table from "../../../../components/empresas/Table";
import LoaderBar from "../../../loader/LoaderBar";
import Download from "../../../ui/download/Download";

// {getLan() === "es" ? "": ""}

class Toggle extends Component {
  state = {
    showModal: false,
    open: false,
    open2: false
  };

  togglePanel(e) {
    this.setState({ open: !this.state.open });
  }

  togglePanel2(e) {
    this.setState({ open2: !this.state.open2 });
  }

  togglePanelCollapse(e) {
    this.setState({ open: false });
    this.setState({ open2: false });
  }

  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };
  
  handleScrollColocaciones = event => {
    console.log(event.currentTarget.scrollLeft);
    console.log(event.currentTarget.childNodes[0].childNodes[1]);
    event.currentTarget.childNodes[0].childNodes[1].scrollLeft = event.currentTarget.scrollLeft;
    event.currentTarget.childNodes[0].childNodes[1].style.left = event.currentTarget.scrollLeft+'px';
  };

  handleClick(event) {
    var emisoraId = null;
    if (this.props.idTramite) {
      emisoraId = this.props.idTramite;
    } else if (this.props.claveFondo) {
      emisoraId = this.props.idFondo;
    } else if (this.props.claveSiefore) {
      emisoraId = this.props.idFondo;
    } else if (this.props.idEmision) {
      emisoraId = this.props.idEmision;
    } else if (this.props.ficha) {
      emisoraId = this.props.ficha;
    } else {
      emisoraId = this.props.idEmisora;
    }
    this.props.updateParent(emisoraId);
  }

  handleClickInstrumentos(event) {
    var emisoraId = null;
    if (this.props.idTramite) {
      emisoraId = this.props.idTramite;
    } else if (this.props.claveFondo) {
      emisoraId = this.props.idFondo;
    } else if (this.props.claveSiefore) {
      emisoraId = this.props.idFondo;
    } else {
      emisoraId = this.props.idEmisora;
    }

    var numeroFideicomiso = this.props.numeroFideicomiso;
    this.props.updateParentInstrumentos(emisoraId, numeroFideicomiso);
  }

  clickHandlerTramite(idTramite, numeroTramite, emisora_id, clave, razonSocial) {
    this.props.clickHandlerTramite(idTramite, numeroTramite, emisora_id, clave, razonSocial);
  }

  handleClickListadoValores(event) {
    var empresaId = null;
    if (this.props.idPublicacion) {
      empresaId = this.props.idPublicacion;
    } else if (this.props.esPreliminar) {
      empresaId = this.props.idTramite;
    } else {
      empresaId = this.props.idEmisora;
    }
    var razonSocial = this.props.razonSocial ? this.props.razonSocial : this.props.razonSocialListado;
    var clave = this.props.clave ? this.props.clave : this.props.claveAviso;
    var numeroTramite = this.props.numeroTramite ? this.props.numeroTramite : this.props.indice;
    this.props.updateParentListado(empresaId, razonSocial, clave, numeroTramite);
  }

  handleClickBothActions(event) {
    this.togglePanelCollapse(event);
    this.handleClickListadoValores(event);
    // this.togglePanel(event)
  }

  handleClickBothActionsDocsFondos(event) {
    this.togglePanel2(event);
    this.handleClick(event);
  }

  handleClickBothActionsProspectosInsAlt(event) {
    this.togglePanelCollapse(event);
    this.handleClickListadoValores(event);
    // this.togglePanel(event)
  }

  onSetColorHandlerNumber = value => {
    let number = parseFloat(value);
    if (number > 0.0) {
      return classes.color_verde;
    } else if (number < 0) {
      return classes.color_rojo;
    } else {
      return null;
    }
  };

  onSetColorHandler = value => {
    // {getLan() === "es" ? "" : ""}
    let status = value;
    if (
      status === "En cumplimiento" ||
      status === "Pagado" ||
      status === "Completado" ||
      status === "Aplicado" ||
      status === "Aplicado/Enviado" ||
      status === "Activo" ||
      status === "Activa" ||
      status === "Informativo"
    ) {
      return classes.color_verde;
    } else if (status === "Sub-RM" || status === "Cancelado" || status === "Cancelada") {
      return classes.color_rojo;
    } else if (status === "Pendiente") {
      return classes.color_amarillo;
    } else if (status === "Listado BIVA") {
      return classes.color_azul;
    } else if (status === "Registro" || status === "Registro Secundario") {
      return classes.color_gris;
    } else {
      return null;
    }
  };

  paintNumberHandler = number => {
    const color = this.onSetColorHandlerNumber(number);
    let signo = null;
    if (number > 0.0) {
      signo = "+";
    }
    return (
      <Auxs>
        <span className={[classes.titulo_20pct, color, "texto_24 nunito bold"].join(" ")}>
          {signo}
          {number}
        </span>
      </Auxs>
    );
  };

  paintEstatusHandler = estatus => {
    const color = this.onSetColorHandler(estatus);
    return (
      <Auxs>
        <span className={[color, "texto_14 nunito semi_bold nowrap"].join(" ")}>
          {estatus}
          &nbsp;
          <i className={["fas fa-circle fa-xs", color].join(" ")} />
        </span>
      </Auxs>
    );
  };

  onSetFormatValues = value => {
    return <FormattedNumber currency="USD" value={value} />;
  };

  getDocumentsESGFromType = (documentos, tipoDoc, textoEsp, textoEng) => {
    let docsFiltered = [];
    if (documentos !== null && documentos !== undefined && documentos !== 0) {
      for (let doc of documentos) {
        if (doc.tipoDocumentoASG.id === tipoDoc && doc.razonSocial !== null && doc.razonSocial !== undefined) {
          docsFiltered.push(doc);
        }
      }
    }
    let first = docsFiltered[0];
    return docsFiltered.map((element) => {
      let headerText = "";
      let razonSocial = "";
      if (element === first) {
        headerText = getLan() === "es" ? textoEsp : textoEng;
        razonSocial = element.razonSocial;
      }
      return (
        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
          <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
            <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
              {headerText}
            </span>
          </div>
          <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
            <span className={["texto_16_17 montse regular"].join(" ")}>
              {razonSocial}
            </span>
          </div>
          <div className={"col-6 col-sm-6 col-md-6 col-lg-6"} key={element.idDocumentoASG}>
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <a href={element.url} target="_blank" rel="noopener noreferrer" className="row" >
                <div className="sin_padding_L">
                  <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6",].join(" ")} />
                </div>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {getRealDateMilliesDayMonthYearLocale(element.fechaPublicacion)}
                </span>
              </a>
            </h2>
          </div>
        </div>
      );
    });
  };

  render() {
    const props = this.props;
    let clase_contenedor_card = classes.contenedor_card;
    let boton_email = null;
    let body = null;
    let encabezado = null;
    let imagen = null;
    let icono = null;
    let texto_descarga_1 = null;
    let texto_descarga_2 = null;
    let location = window.location.pathname;
    let button = null;
    let titulo1 = null;
    let titulo2 = null;
    let titulo3 = null;
    let titulo4 = null;
    let item = null;
    let bodyButton = null;
    let bottomButton = null;
    let topButton = null;
    let topBody = null;

    const modal = (
      <Auxs>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} onCloseModal={this.onToggleModalHandler} area={this.props.area} />
      </Auxs>
    );
    button = (
      <div className={["card-header", classes.card_header].join(" ")} id="headingOne">
        <h2 className={["mb-0", classes.titulo].join(" ")}>
          <button
            className={["btn btn-link collapsed", classes.btn_link, classes.btn_link2, classes.btn_link_full].join(" ")}
            type="button"
            data-toggle="collapse"
            data-target={"#" + props.id}
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <span className={[classes.titulo, "semi_bold montse"].join(" ")}>
              {props.subtitulo ? props.subtitulo : props.titulo ? props.titulo : props.titulo_encabezado}
            </span>
            <span> </span>
            <i className={["fas fa-chevron-down", classes.accordion_toggle].join(" ")} />
          </button>
        </h2>
      </div>
    );
    imagen = <i className={["far", "fa-file-image icon", classes.icon].join(" ")} />;
    if (props.imagen_icono && props.imagen_icono !== "") {
      let imgPath = require(`../../../../assets/icons/${props.imagen_icono}`);
      imagen = <img src={imgPath} alt="" className={[classes.icon, this.props.isPng ? classes.img : null].join(" ")} />;
    }
    if (props.imagen && props.imagen !== "") {
      let imgPath = getUrlDocument(props.imagen);
      if (props.icons_tamaño) {
        imagen = <img src={imgPath} alt="" className={classes.icon} />;
      } else {
        imagen = <img src={imgPath} alt="" className={classes.imagen_encabezado} />;
      }
    }

    if (props.icon) {
      icono = <i className={[props.icon, classes.icon].join(" ")} />;
    }
    if (props.email) {
      boton_email = (
        <div className="col margen_top_30 sin_padding">
          <span className={"semi_bold montse texto_14 "}>
            {getLan() === "es" ? "Si deseas nos puedes enviar un mensaje" : "If you wish you can send us a message"}
          </span>
          <br />
          <div className={classes.margenes_boton}>
            <Boton
              onClick={this.onToggleModalHandler.bind(this)}
              ruta={location}
              tipo={4}
              texto={getLan() === "es" ? "Enviar mensaje" : "Send message"}
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>
        </div>
      );
    }
    if (props.tipo === 2) {
      encabezado = (
        <div className={[classes.encabezado_card, "row"].join(" ")}>


          <div className={["col-12 sin_padding"].join(" ")}>
            {icono}
            <span className={["nunito texto_30 regular", classes.texto_encabezado].join(" ")}>   {props.titulo_encabezado}</span>
          </div>

          <span className={["col-12 sin_padding nunito texto_16 regular", classes.descripcionContactanos, classes.texto_encabezado, props.descripcion != null ? null : "d-none"].join(" ")}> {props.descripcion}</span>

        </div>
      );

      body = (
        <div className={["card-body montse texto_14", classes.card_body].join(" ")}>
          <div className="row ">
            <div className="col-sm-4 sin_padding_L">
              <span className={"color_azul montse texto_14 semi_bold"}>{getLan() === "es" ? "Teléfono" : "Phone number"}</span>
              <p className={"montse texto_14 regular"}>{props.telefono}</p>
            </div>
            <div className="col-sm-8">
              <span className={"color_azul montse texto_14 semi_bold"}>{getLan() === "es" ? "Correo" : "Email"}</span>
              <p className={"montse texto_14 regular"}>{props.email}</p>
            </div>
            {boton_email}
          </div>
        </div>
      );
    } else if (props.tipo === 3) {
      encabezado = <div className={classes.encabezado_card}>{imagen}</div>;

      body = (
        <div className={["card-body", classes.card_body].join(" ")}>
          <div className="row montse texto_14">{props.texto}</div>
        </div>
      );
    } else if (props.tipo === 4) {
      if (props.subtipo === 1) {
        if (props.indice === 0) {
          encabezado = (
            <div className={[classes.encabezado_card, classes.tipo4, "texto_24 nunito semi_bold relativo"].join(" ")}>
              <span className={[classes.inlineBlock, "relativo "].join(" ")}>{imagen}</span>
              <div className={[classes.left_125, "vertical_center texto_30 nunito regular"].join(" ")}>
                <div className={[classes.height_22].join(" ")}>
                  <span className="texto_30_only nunito regular width_cien">
                    {props.titulo_encabezado_estatico ? props.titulo_encabezado_estatico : props.titulo_encabezado}
                  </span>
                </div>
                <div className={[""].join(" ")}>
                  <span className="texto_16_only nunito semibold width_cien">
                    {props.titulo_encabezado_2_estatico ? props.titulo_encabezado_2_estatico : props.titulo_encabezado_2}
                  </span>
                </div>
              </div>
            </div>
          );
          clase_contenedor_card = classes.contenedor_card2;
        } else if (props.indice === props.ultimo_dato) {
          clase_contenedor_card = classes.contenedor_card4;
        } else {
          clase_contenedor_card = classes.contenedor_card3;
        }

        let puerto = null;
        if (props.texto.puerto) {
          puerto = (
            <React.Fragment>
              <div className={[classes.titulo_33pct].join(" ")}>
                <span className={"montse texto_16 semi_bold margen_derecha_10 texto_azul"}>
                  {getLan() === "es" ? "Puerto:" : "Port:"}
                </span>
                <br />
                <span className={"montse texto_16 regular"}>{props.texto.puerto}</span>
              </div>
            </React.Fragment>
          );
        }

        body = (
          <div className={["card-body montse texto_14", classes.card_body].join(" ")}>
            <div
              className={["card-body montse texto_14", classes.card_body].join(" ")}
              dangerouslySetInnerHTML={{ __html: props.texto }}
            />
            {/* <div className={[classes.titulo_75pct].join(" ")}>
              <span className={"montse texto_16 regular"}>
                {props.texto.info}
              </span>
            </div>
            <br />
            <br />
            <div className={[classes.titulo_33pct].join(" ")}>
              <span className={"montse texto_16 semi_bold margen_derecha_10 texto_azul"}>
                Costo:
              </span>
              <br />
              <span className={"montse texto_16 regular"}>
                {props.texto.costo}
              </span>
            </div>
            {puerto}
            <br />
            <br />
            <div className={[classes.titulo_75pct].join(" ")}>
              <span className={"montse texto_16 semi_bold margen_derecha_10 texto_azul"}>
                Para información contacta a:
              </span>
              <br />
              <span className={"montse texto_14 regular"}>{props.texto.contacto}</span>
            </div> */}
          </div>
        );
      } else {
        if (props.indice === 0) {
          encabezado = (
            <div className={[classes.encabezado_card, classes.tipo4, "texto_24 nunito semi_bold"].join(" ")}>
              {imagen}
              {props.titulo_encabezado}
            </div>
          );
          clase_contenedor_card = classes.contenedor_card2;
        } else if (props.indice === props.ultimo_dato) {
          clase_contenedor_card = classes.contenedor_card4;
        } else {
          clase_contenedor_card = classes.contenedor_card3;
        }
        body = (
          <div className={["card-body montse texto_14", classes.card_body].join(" ")}>
            <div className="row ">
              <span className={"montse texto_14 semi_bold margen_derecha_10"}>{getLan() === "es" ? "Sede:" : "Campus:"}</span>

              <span className={"montse texto_14 regular"}>{props.texto.sede}</span>
            </div>
            <div className="row ">
              <span className={"montse texto_14 semi_bold margen_derecha_10"}>{getLan() === "es" ? "Inicio:" : "From:"}</span>

              <span className={"montse texto_14 regular"}>{props.texto.inicio}</span>
            </div>
            <div className="row ">
              <span className={"montse texto_14 semi_bold margen_derecha_10"}>{getLan() === "es" ? "Término:" : "To:"}</span>

              <span className={"montse texto_14 regular"}>{props.texto.fin}</span>
            </div>
            <div className="row margen_top_30">
              <Boton
                ruta={props.url}
                tipo={1}
                texto={getLan() === "es" ? "Más información" : "More information"}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>
          </div>
        );
      }
    } else if (props.tipo === 5) {
      encabezado = (
        <div className={[classes.encabezado_card, classes.tipo4].join(" ")}>
          {imagen} <span className={"texto_24 nunito semi_bold"}>{props.titulo_encabezado}</span>
        </div>
      );

      body = (
        <div className={["card-body", classes.card_body].join(" ")}>
          <div className="row  montse texto_14 regular" dangerouslySetInnerHTML={{ __html: props.texto }} />
        </div>
      );

      if (this.props.subtipo === 2) {
        encabezado = (
          <div className={[classes.encabezado_card, classes.tipo4, classes.container_center].join(" ")}>
            {imagen}
            <span className={[classes.verticalTitle, "texto_24 nunito semi_bold"].join(" ")}>{props.titulo_encabezado}</span>
          </div>
        );

      }


    } else if (props.tipo === 6) {
      if (this.props.sinIcono) {
        imagen = null;
      }
      if (props.indice === 0) {
        let texto_derecha_encabezado = "";
        if (this.props.texto_derecha_encabezado_titulo && this.props.texto_derecha_encabezado) {
          texto_derecha_encabezado = (
            <div className={getLan() === "es" ? classes.texto_derecha_div_encabezado : classes.texto_derecha_div_encabezado_en}>
              <div className="row color_azul texto_16 montse regular">
                <span>{this.props.texto_derecha_encabezado_titulo}</span>
              </div>
              <div className="row texto_24 nunito negrita">
                <span>{this.props.texto_derecha_encabezado}</span>
              </div>
            </div>
          );
        }

        encabezado = (
          <div className={[classes.encabezado_card, classes.tipo5, "texto_30 nunito regular"].join(" ")}>
            {imagen}
            <span className={classes.verticalTitle}>
              {props.titulo_encabezado_estatico ? props.titulo_encabezado_estatico : props.titulo_encabezado}
            </span>

            <span className={[classes.titulo_2, "texto_16 nunito semi_bold", classes.verticalTitle].join(" ")}>
              {props.titulo_encabezado_2}
            </span>
            {texto_derecha_encabezado}
          </div>
        );
        if (props.indice === props.ultimo_dato) {
          clase_contenedor_card = classes.contenedor_card4;
        } else {
          clase_contenedor_card = classes.contenedor_card2;
        }
      } else if (props.indice === props.ultimo_dato) {
        clase_contenedor_card = classes.contenedor_card4;
      } else {
        clase_contenedor_card = classes.contenedor_card3;
      }
      let btn = "";
      let btn_descarga_1 = "";
      let btn_descarga_2 = "";
      if (props.url || (props.urlBoton && props.urlBoton !== "")) {
        if (props.tipoBotonDescarga === 2) {
          const box = {
            leftIcon: "far fa-file-pdf",
            title: props.etiquetaBoton ? props.etiquetaBoton : props.mensaje_boton ? props.mensaje_boton : " ",
            subtitle: getLan() === "es" ? "Formato PDF" : "PDF format",
            rightIcon: "fas fa-arrow-down",
            url: props.urlBoton ? props.urlBoton : props.url ? props.url : "/"
          };

          btn = (
            <div className="height_110px margin_top_15">
              <Box modelo="modelo_1" data={box} />
            </div>
          );
        } else {
          let rutaBtn = props.urlBoton ? getUrlDocument(props.urlBoton) : props.url ? getUrlDocument(props.url) : "/";
          if (rutaBtn.includes("/cms")) {
            if (rutaBtn.includes("?")) {
              rutaBtn = `${rutaBtn}&download=true`;
            } else {
              rutaBtn = `${rutaBtn}?download=true`;
            }
          }
          btn = (
            <div className="height_50px margin_top_15">
              <Boton
                ruta={rutaBtn}
                tipo={rutaBtn.includes("http") ? 1 : 6}
                texto={props.etiquetaBoton ? props.etiquetaBoton : props.mensaje_boton}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />
            </div>
          );
        }
      }
      if (props.descarga_url) {
        const box = {
          leftIcon: "far fa-file-pdf",
          title: props.mensaje_boton,
          subtitle: getLan() === "es" ? "Formato PDF" : "PDF format",
          rightIcon: "fas fa-arrow-down",
          url: props.descarga_url
        };
        btn_descarga_1 = (
          <div className="col-12">
            <Box modelo="modelo_1" data={box} />
          </div>
        );
      }

      if (props.descarga_url_2) {
        const box2 = {
          leftIcon: "far fa-file-pdf",
          title: props.mensaje_boton_2,
          subtitle: getLan() === "es" ? "Formato PDF" : "PDF format",
          rightIcon: "fas fa-arrow-down",
          url: props.descarga_url_2
        };
        btn_descarga_2 = (
          <div className="col-12">
            <Box modelo="modelo_1" data={box2} />
          </div>
        );
      }

      if (props.texto_descarga) {
        texto_descarga_1 = (
          <p className={classes.texto_descarga}>
            <div className={[" montse texto_14"].join(" ")} dangerouslySetInnerHTML={{ __html: props.texto_descarga }} />
          </p>
        );
      }

      if (props.texto_descarga_2) {
        texto_descarga_2 = (
          <p className={classes.texto_descarga}>
            <div className={[" montse texto_14"].join(" ")} dangerouslySetInnerHTML={{ __html: props.texto_descarga_2 }} />
          </p>
        );
      }

      body = (
        <div className={["card-body", classes.card_body].join(" ")}>
          <div
            className={[" montse texto_16"].join(" ")}
            dangerouslySetInnerHTML={{ __html: props.texto_html ? props.texto_html : props.texto }}
          />
          {btn}
          <div className="row">
            {texto_descarga_1}
            {btn_descarga_1}
            {texto_descarga_2}
            {btn_descarga_2}
          </div>
        </div>
      );
      if (props.subtipo === 1) {
        let titulo2 = getLan() === "es" ? (getLan() === "es" ? "Formato PDF" : "PDF format") : "PDF format";
        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <div className={[classes.titulo_60pct]}>
                <span className={["col color_azul nunito texto_16 semi_bold"].join(" ")}>{props.titulo_info}</span>
                <br />
                <span className={[classes.titulo2, "col-12 texto_16 montse semi_bold"].join(" ")}>{props.email}</span>
              </div>
              <div className={[classes.titulo_30pct]}>
                <span className={["texto_16 montse color_azul regular"].join(" ")}>{props.titulo}</span>
                <br />
                <span className={"texto_10 montse semi_bold color_gris"}>
                  {titulo2}
                  {/* {props.docType} */}
                  {/* {getLan() === "es" ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}` : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`} */}
                </span>
              </div>
              <a
                href={getUrlDocument(props.url)}
                target="_blank"
                rel="noopener noreferrer"
                className={[classes.titulo_10pct, "row"].join(" ")}
              >
                <div className={[classes.titulo]}>
                  <div className={[classes.titulo_10pct]}>
                    <i className={["fas fa-arrow-alt-circle-down fa-3x", "Boton__icono_derecho__25mg6"].join(" ")} />
                  </div>
                </div>
              </a>
            </h2>
          </div>
        );
      }
      if (props.subtipo === 2) {
        let ruta = null;
        let btn_url = null;
        if (props.url) {
          ruta = getUrlDocument(props.url, false, true);
          texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";

          btn_url = (
            <div className={"col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 sin_padding margin_bottom_10 margin_top_5"}>
              <h2 className={["mb-0", classes.titulo, classes.divDescarga].join(" ")}>
                <a href={ruta} target={getTypeLink(props.url)} rel="noopener noreferrer" className="row">
                  <div className={"sin_padding_L"}>
                    <span className={[classes.titulo, " texto_16_17 montse color_azul regular "].join(" ")}>
                      {texto_descarga_1}
                    </span>
                    <br />
                    <span className={["texto_10 montse semi_bold color_gris"].join(" ")}>
                      {getLan() === "es"
                        ? `Formato ${props.docType === undefined ? "DOCX" : props.docType.toUpperCase()}`
                        : `${props.docType === undefined ? "DOCX" : props.docType.toUpperCase()} format`}
                    </span>
                  </div>
                  <div className={classes.flechaDescarga}>
                    <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                  </div>
                </a>
              </h2>
            </div>
          )
        }

        button = (
          <div className={["card-header row", classes.card_header4].join(" ")} id="headingOne">
            <div className={"col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 sin_padding margin_bottom_10 margin_top_5"}>
              <span className={[classes.titulo, "semi_bold montse margin_top_10"].join(" ")}>{props.titulo}</span>
            </div>
            {btn_url}
          </div>
        );
      }
    } else if (props.tipo === 7) {
      //Emisoras Inscritas
      texto_descarga_1 = getLan() === "es" ? "Consultar perfil" : "View profile";
      button = null;
      //Calificadoras
      if (props.subtipo === 1) {
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-9 col-lg-9 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["col  color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Calificadora" : "Rating agency"}
                </span>
              </div>
              <div className={"col-12"}>
                {props.rutaLogo ? (
                  <img className={classes.imagenes_calificadoras} src={props.rutaLogo} alt={props.nombre} />
                ) : (
                  <span className={["texto_16_17 montse bold"].join(" ")}>{props.nombre}</span>
                )}
              </div>
            </div>

            <div className={["col-12 col-sm-12 col-md-3 col-lg-3 sin_padding margin_bottom_10"].join(" ")}>
              <div className={["sin_padding col-12"].join(" ")}>
                <div className={[classes.margin_top_20_descarga, classes.card_header, " "].join(" ")} id="headingOne">
                  <h2 className={["mb-0", classes.titulo, ""].join(" ")}>
                    <button
                      className={["btn btn-link ", classes.btn_link2].join(" ")}
                      type="button"
                      onClick={this.handleClick.bind(this)}
                    >
                      <span className={[classes.titulo_texto16_17, "regular", "montse texto_16_17"].join(" ")}>{texto_descarga_1}</span>
                      <span></span>
                      <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6", " "].join(" ")} />
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        );
      }

      //Emisoras Inscritas primer accordion subtipo 2
      //Emisoras Inscritas / SIC subtipo 3
      if (props.subtipo === 2 || props.subtipo === 3) {
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "nunito", "texto_24 bold row"].join(" ")}>
            <div className={["col-12 col-sm-12 col-md-3 col-lg-2 row", "sin_padding margin_bottom_10"].join(" ")}>
              <div className={"col-12"}>
                <span className={["col sin_padding_L ", "color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={"col-12 "}>
                <span className={["texto_24 nunito", "bold"].join(" ")}>
                  {props.clave}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-12 col-md-9 col-lg-7 row sin_padding", "margin_bottom_10"].join(" ")}>
              <div className={"col-12 sin_padding"}>
                <span className={["col  color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {props.subtipo === 2 ? getLan() === "es" ? "Razón Social" : "Corporate name" :
                    getLan() === "es" ? "Emisora" : "Corporate name"}
                </span>
              </div>
              <div className={"col-12"}>
                <span className={["texto_16 montse", "regular"].join(" ")}>
                  {props.nombre}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-12  col-md-5  col-lg-3  sin_padding", " margin_bottom_10"].join(" ")}>
              <div className={"col-12 sin_padding_R"}>
                <div className={[classes.margin_top_20_descarga, classes.card_header].join(" ")} id="headingOne">
                  <h2 className={["mb-0", classes.titulo, " "].join(" ")}>
                    <button
                      className={[" btn btn-link ", classes.btn_link2].join(" ")}
                      type="button"
                      onClick={this.handleClick.bind(this)}
                    >
                      <span className={[classes.titulo_texto16_17, "regular montse", "texto_16_17"].join(" ")}>{texto_descarga_1}</span>
                      <span> </span>
                      <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        );
      }
      else if (props.subtipo === 4) { //INSTRUMENTOS ALTERNATIVOS LISTADO
        texto_descarga_1 = getLan() === "es" ? "Consultar ficha" : "View data sheet";
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold", "row"].join(" ")}>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row sin_padding margin_bottom_10"}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Instrumento" : "Instrument"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse", "regular"].join(" ")}>
                  {props.tipoInstrumento}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row ", "sin_padding margin_bottom_10"].join(" ")}>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.clave}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10"].join(" ")}>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["col sin_padding color_azul nunito texto_14 semi_bold", classes.noWrapResponsive].join(" ")}>
                  {getLan() === "es" ? "No. de fideicomiso" : "Trust number"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.numeroFideicomiso}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding ", "margin_bottom_10"].join(" ")}>
              <div className={["col-12 sin_padding text-center", classes.textCenter].join(" ")}>
                <span className={["col sin_padding color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Monto máximo" : "Maximum amount"}
                </span>
              </div>
              <div className={["col-12 sin_padding text-center", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular nowrap"].join(" ")}>
                  {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ? [formatDecimal(props.montoMaximo), " ", props.monedaMontoMaximo] : null}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding", "margin_bottom_10"].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Títulos autorizados" : "Authorized securities"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular nowrap"].join(" ")}>
                  {formatInt(props.titulosAutorizados)}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10", " "].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Monto colocado" : "Placed Amount"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular nowrap"].join(" ")}>
                  {formatDecimal(props.montoColocado)} {props.monedaMontoColocado}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10", " "].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Títulos emitidos" : "Issued securities"}
                </span>
              </div>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ? formatNumber(props.titulosEmitidos) : formatNumber(props.titulosEnCirculacion)}
                </span>
              </div>
            </div>
            <div className={["sin_padding margin_bottom_10  offset-0  offset-md-4 offset-lg-0 offset-xl-10 col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2"].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <div className={[classes.margin_top_20_descarga, classes.card_header, classes.textCenter].join(" ")} id="headingOne">
                  <h2 className={["mb-0", classes.titulo].join(" ")}>
                    <button
                      className={["btn btn-link ", classes.btn_link3, classes.textCenter].join(" ")}
                      type="button"
                      onClick={this.handleClickInstrumentos.bind(this)}
                    >
                      <span className={[classes.titulo_texto14, "regular montse texto_14"].join(" ")}>{texto_descarga_1}</span>
                      <span> </span>
                      <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        );
      }
      else if (props.subtipo === 5) { // EMISIONES ASG BONOS TEMATICOS
        texto_descarga_1 = getLan() === "es" ? "Consultar ficha ASG" : "View data sheet";
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold", "row"].join(" ")}>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row sin_padding margin_bottom_10"}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de bono" : "Bond type"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse", "regular"].join(" ")}>
                  {props.tipoBono}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row ", "sin_padding margin_bottom_10"].join(" ")}>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de valor" : "Instrument type"}
                </span>
              </div>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.tipoValor}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row sin_padding margin_bottom_10"].join(" ")}>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["col sin_padding color_azul nunito texto_14 semi_bold", classes.noWrapResponsive].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.clave}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row sin_padding ", "margin_bottom_10"].join(" ")}>
              <div className={["col-12 sin_padding text-center", classes.textCenter].join(" ")}>
                <span className={["col sin_padding color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Serie" : "Serie"}
                </span>
              </div>
              <div className={["col-12 sin_padding text-center", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular nowrap"].join(" ")}>
                  {props.serie}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding", "margin_bottom_10"].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de colocación" : "Placement date"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular nowrap"].join(" ")}>
                  {getRealDateMilliesDayMonthYearLocale(props.date)}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10", " "].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Títulos de la emisión" : "Issued securities"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular nowrap"].join(" ")}>
                  {formatInt(props.numeroTitulos)}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10", " "].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Monto de la emisión" : "Issued amount"}
                </span>
              </div>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular", props.montoEmision ? null : "d-none"].join(" ")}>
                  {formatDecimal(props.montoEmision)} {" "}{props.moneda}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10", " "].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Plazo de la emisión" : "Term"}
                </span>
              </div>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {formatNumber(props.plazoEmision)} {props.plazoEmision ? getLan() === "es" ? "días" : "days" : null}
                </span>
              </div>
            </div>
            <div className={["sin_padding margin_bottom_10  offset-0  offset-md-4 offset-lg-0 offset-xl-10 col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2"].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <div className={[classes.margin_top_20_descarga, classes.card_header, classes.textCenter].join(" ")} id="headingOne">
                  <h2 className={["mb-0", classes.titulo].join(" ")}>
                    <button
                      className={["btn btn-link ", classes.btn_link3, classes.textCenter].join(" ")}
                      type="button"
                      onClick={this.handleClick.bind(this)}
                    >
                      <span className={[classes.titulo_texto14, "regular montse texto_14"].join(" ")}>{texto_descarga_1}</span>
                      <span> </span>
                      <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (props.subtipo === 6) { // SIC ETF ASG
        texto_descarga_1 = getLan() === "es" ? "Consultar ficha ASG" : "View data sheet";
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold", "row"].join(" ")}>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10"}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse", "regular"].join(" ")}>
                  {props.claveCotizacion}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-4 row ", "sin_padding margin_bottom_10"].join(" ")}>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Razón Social" : "Corporate name"}
                </span>
              </div>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.razonSocial}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 row sin_padding margin_bottom_10"].join(" ")}>
              <div className={["col-12", "sin_padding", classes.textCenter].join(" ")}>
                <span className={["col sin_padding color_azul nunito texto_14 semi_bold", classes.noWrapResponsive].join(" ")}>
                  {getLan() === "es" ? "Mercado principal" : "Main market"}
                </span>
              </div>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.mercadoPrincipalDescripcion}
                </span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 row sin_padding ", "margin_bottom_10"].join(" ")}>
              <div className={["col-12 sin_padding text-center", classes.textCenter].join(" ")}>
                <span className={["col sin_padding color_azul nunito texto_14 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "País de origen" : "Origin country"}
                </span>
              </div>
              <div className={["col-12 sin_padding text-center", classes.textCenter].join(" ")}>
                <span className={["texto_14 montse regular"].join(" ")}>
                  {props.pais}
                </span>
              </div>
            </div>
            <div className={["sin_padding margin_bottom_10  offset-0  offset-md-4 offset-lg-0 offset-xl-10 col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2"].join(" ")}>
              <div className={["col-12 sin_padding", classes.textCenter].join(" ")}>
                <div className={[classes.margin_top_20_descarga, classes.card_header, classes.textCenter].join(" ")} id="headingOne">
                  <h2 className={["mb-0", classes.titulo].join(" ")}>
                    <button
                      className={["btn btn-link ", classes.btn_link3, classes.textCenter].join(" ")}
                      type="button"
                      onClick={this.handleClick.bind(this)}
                    >
                      <span className={[classes.titulo_texto14, "regular montse texto_14"].join(" ")}>{texto_descarga_1}</span>
                      <span> </span>
                      <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (props.subtipo === 7) { // Alternativos ASG
        texto_descarga_1 = getLan() === 'es' ? 'Consultar ficha ASG' : 'View data sheet';
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, 'texto_24 nunito bold', 'row'].join(' ')}>
            <div className={['col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row sin_padding margin_bottom_10'].join(' ')}>
              <div className={['col-12', 'sin_padding', classes.textCenter].join(' ')}>
                <span className={['col sin_padding color_azul nunito texto_14 semi_bold', classes.noWrapResponsive].join(' ')}>
                  {getLan() === 'es' ? 'Clave' : 'Ticker'}
                </span>
              </div>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <span className={['texto_14 montse regular'].join(' ')}>
                  {props.clave}
                </span>
              </div>
            </div>
            <div className={['col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 row sin_padding ', 'margin_bottom_10'].join(' ')}>
              <div className={['col-12 sin_padding text-center', classes.textCenter].join(' ')}>
                <span className={['col sin_padding color_azul nunito texto_14 semi_bold'].join(' ')}>
                  {'Serie'}
                </span>
              </div>
              <div className={['col-12 sin_padding text-center', classes.textCenter].join(' ')}>
                <span className={['texto_14 montse regular nowrap'].join(' ')}>
                  {props.serie}
                </span>
              </div>
            </div>
            <div className={['col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding', 'margin_bottom_10'].join(' ')}>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <span className={['color_azul nunito texto_14 semi_bold'].join(' ')}>
                  {getLan() === 'es' ? 'Fecha de colocación' : 'Placement date'}
                </span>
              </div>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <span className={['texto_14 montse regular nowrap'].join(' ')}>
                  {getRealDateMilliesDayMonthYearLocale(props.date)}
                </span>
              </div>
            </div>
            <div className={['col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10', ' '].join(' ')}>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <span className={['color_azul nunito texto_14 semi_bold'].join(' ')}>
                  {getLan() === 'es' ? 'Títulos en circulación' : 'Issued securities'}
                </span>
              </div>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <span className={['texto_14 montse regular nowrap'].join(' ')}>
                  {formatInt(props.numeroTitulos)}
                </span>
              </div>
            </div>
            <div className={['col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10', ' '].join(' ')}>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <span className={['color_azul nunito texto_14 semi_bold'].join(' ')}>
                  {getLan() === 'es' ? 'Monto de la emisión' : 'Issued amount'}
                </span>
              </div>
              <div className={['col-12', 'sin_padding', classes.textCenter].join(' ')}>
                <span className={['texto_14 montse regular', props.montoEmision ? null : 'd-none'].join(' ')}>
                  {formatDecimal(props.montoEmision)} {' '}{props.moneda}
                </span>
              </div>
            </div>
            <div className={['col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 row sin_padding margin_bottom_10', ' '].join(' ')}>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <span className={['color_azul nunito texto_14 semi_bold'].join(' ')}>
                  {getLan() === 'es' ? 'Plazo de la emisión' : 'Term'}
                </span>
              </div>
              <div className={['col-12', 'sin_padding', classes.textCenter].join(' ')}>
                <span className={['texto_14 montse regular'].join(' ')}>
                  {formatNumber(props.plazoEmision)} {props.plazoEmision ? getLan() === 'es' ? 'días' : 'days' : null}
                </span>
              </div>
            </div>
            <div className={['sin_padding margin_bottom_10  offset-0  offset-md-4 offset-lg-0 offset-xl-10 col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2'].join(' ')}>
              <div className={['col-12 sin_padding', classes.textCenter].join(' ')}>
                <div className={[classes.margin_top_20_descarga, classes.card_header, classes.textCenter].join(' ')} id='headingOne'>
                  <h2 className={['mb-0', classes.titulo].join(' ')}>
                    <button
                      className={['btn btn-link ', classes.btn_link3, classes.textCenter].join(' ')}
                      type='button'
                      onClick={this.handleClick.bind(this)}
                    >
                      <span className={[classes.titulo_texto14, 'regular montse texto_14'].join(' ')}>{texto_descarga_1}</span>
                      <span> </span>
                      <i className={['fas fa-long-arrow-alt-right', 'Boton__icono_derecho__25mg6'].join(' ')} />
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else if (props.tipo === 8) {
      //Emisoras/InformacionGeneral
      if (props.subtipo === 1) {
        //Emisoras/InformacionGeneral1
        titulo1 = getLan() === "es" ? "Bolsa" : "Stock exchange";
        titulo2 = getLan() === "es" ? "Fecha de listado" : "Listing date";
        titulo3 = getLan() === "es" ? "Teléfono" : "Phone number";
        titulo4 = getLan() === "es" ? "Sitio Web" : "Website";
        const fechaListado = getRealDateMilliesDayMonthYear(props.fechaListado);
        let bolsa = null;
        if (props.bolsa !== "BIVA") {
          bolsa = getLan() === "es" ? "Registro secundario" : "Registro Secundario";
        } else {
          bolsa = props.bolsa;
        }
        button = (
          <div className={[classes.encabezado_card3, classes.tipo4, "row blanco-md"].join(" ")}>
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo1}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{bolsa}</span>
              </div>

            </div>
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo2}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{fechaListado}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo3}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{props.telefono}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo4}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>
                  <a href={props.sitioWeb} target="_blank" rel="noopener noreferrer" className="row">
                    <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{props.sitioWeb}</span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        );

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "row visible-lg"].join(" ")}>
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo1}</div>
            </div>
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo2}</div>
            </div>
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo3}</div>
            </div>
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo4}</div>
            </div>
          </div>
        );
      } else if (props.subtipo === 2) {
        //Emisoras/InformacionGeneral2
        let sector = null;
        let subSector = null;
        let ramo = null;
        let subRamo = null;
        let encabezado_sector = null;
        let encabezado_subSector = null;
        let encabezado_ramo = null;
        let encabezado_subRamo = null;
        titulo1 = "Sector";
        titulo2 = "Sub Sector";
        titulo3 = getLan() === "es" ? "Ramo" : "Branch";
        titulo4 = getLan() === "es" ? "Sub Ramo" : "Sub branch";

        if (props.sector !== undefined && props.sector !== null) {
          sector = (
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo1}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{props.sector.nombre}</span>
              </div>

            </div>
          )

          encabezado_sector = (
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo1}</div>
            </div>
          );
        } else {
          sector = null;
          encabezado_sector = null;
        }
        if (props.subsector !== undefined && props.subsector !== null) {
          subSector = (
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo2}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{props.subsector.nombre}</span>
              </div>
            </div>

          );
          encabezado_subSector = (
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo2}</div>
            </div>
          );
        } else {
          subSector = null;
          encabezado_subSector = null;
        }
        if (props.ramo !== undefined && props.ramo !== null) {
          ramo = (
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo3}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{props.ramo.nombre}</span>
              </div>
            </div>
          )

          encabezado_ramo = (
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo3}</div>
            </div>
          );
        } else {
          ramo = null;
          encabezado_ramo = null;
        }
        if (props.subramo !== undefined && props.subramo !== null) {
          subRamo = (
            <div className="col-12 col-md-6 col-lg-3 sin_padding_L row">
              <div className="col-12 sin_padding d-lg-none d-xl-none">
                <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo4}</div>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{props.subramo.nombre}</span>
              </div>
            </div>
          )
          encabezado_subRamo = (
            <div className="col-12 col-lg-3 sin_padding_L">
              <div className={["color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo4}</div>
            </div>
          );
        } else {
          subRamo = null;
          encabezado_subRamo = null;
        }

        button = (
          <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold row blanco-md"].join(" ")}>
            {sector}
            {subSector}
            {ramo}
            {subRamo}
          </div>
        );

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row visible-lg"].join(" ")}>
            {encabezado_sector}
            {encabezado_subSector}
            {encabezado_ramo}
            {encabezado_subRamo}
          </div>
        );

        if (
          encabezado_sector === null &&
          encabezado_subSector === null &&
          encabezado_ramo === null &&
          encabezado_subRamo === null
        ) {
          encabezado = null;
        }
        if (sector === null && subSector === null && ramo === null && subRamo === null) {
          button = null;
        }
        // Calificadoras Perfil Nombre y Telefono
      } else if (props.subtipo === 3) {
        titulo1 = getLan() === "es" ? "Nombre de contacto" : "Contact";
        titulo2 = getLan() === "es" ? "Teléfono" : "Phone number";

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <div className={"row sin_padding margin_bottom_10"}>
              <div className={"col-2 col-sm-12 col-md-2 col-lg-2 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {titulo1}
                </span>
              </div>
              <div className={"col-5 col-sm-12 col-md-5 col-lg-5 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>{props.nombreContacto ? props.nombreContacto : getLan() === "es" ? "No existen resultados para la búsqueda" : "There are no results for the search"}</span>
              </div>
              <div className={"col-2 col-sm-12 col-md-2 col-lg-2 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {titulo2}
                </span>
              </div>
              <div className={"col-3 col-sm-12 col-md-3 col-lg-3 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>{props.telefono}</span>
              </div>
            </div>
          </div>
        );

        button = null;
      } else if (props.subtipo === 4) {
        //SIC /InfoGral1
        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Razón social" : "Corporate name"}
                </span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Listado" : "Listing"}
                </span>
              </div>
            </div>
          </div>
        );

        let bolsa = null;
        if (props.bolsa === "BIVA") {
          bolsa = "SIC - BIVA";
        } else {
          bolsa = getLan() === "es" ? "SIC - Registro secundario" : "SIC - Secondary registration";
        }
        button = (
          <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["col sin_padding_L montse texto_16 regular"].join(" ")}>
                  {props.razonSocial}
                </span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["col sin_padding_L montse texto_16 regular"].join(" ")}>
                  {bolsa}
                </span>
              </div>
            </div>
          </div>
        );
      } else if (props.subtipo === 5) {
        //SIC /InfoGral2
        titulo1 = getLan() === "es" ? "Sitio web" : "Website";
        titulo2 = getLan() === "es" ? "Información financiera" : "Financial information";
        titulo3 = getLan() === "es" ? "Eventos relevantes" : "Relevant events";

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {/* <div className={[classes.titulo_33pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo1}</div>
            <div className={[classes.titulo_33pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo2}</div>
            <div className={[classes.titulo_33pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo3}</div> */}

            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Sitio web" : "Website"}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Información financiera" : "Financial information"}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Eventos relevantes" : "Relevant events"}
                </span>
              </div>
            </div>
          </div>
        );

        texto_descarga_1 = getLan() === "es" ? "Consultar Información" : "View information";
        button = (
          <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["texto_16 montse regular"].join(" ")}>
                  <a href={props.sitioWeb} target="_blank" rel="noopener noreferrer" className="row">
                    <span className={[classes.titulo_texto16_17, "texto_16 montse color_azul regular"].join(" ")}>
                      {props.sitioWeb}
                    </span>
                  </a>
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["texto_16 montse regular"].join(" ")}>
                  <a href={props.informacionFinancieraURL} target="_blank" rel="noopener noreferrer" className="row">
                    <span className={[classes.titulo_texto16_17, "texto_16 montse color_azul regular"].join(" ")}>
                      {texto_descarga_1}
                      <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </span>
                  </a>
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 "}>
                <span className={["texto_16 montse regular"].join(" ")}>
                  <a href={props.eventosRelevantesURL} target="_blank" rel="noopener noreferrer" className="row">
                    <span className={[classes.titulo_texto16_17, "texto_16 montse color_azul regular"].join(" ")}>
                      {texto_descarga_1}
                      <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        );
      } else if (props.subtipo === 6) {
        // Calificadoras perfil -> Razón Social, Direccion 
        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <div className={"row sin_padding margin_bottom_10"}>
              <div className={"col-12 col-sm-12 col-md-2 col-lg-2 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {props.titulo}
                </span>
              </div>
              <div className={"col-12 col-sm-12 col-md-10 col-lg-10 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>{props.titulo_info}</span>
              </div>
            </div>
          </div>
        );

        button = null;
      } else if (props.subtipo === 7) {
        // Calificadoras perfil -> Sitio Web 
        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <div className={"row sin_padding margin_bottom_10"}>
              <div className={"col-12 col-sm-12 col-md-2 col-lg-2 sin_padding"}>
                <div className={"col sin_padding"}>
                  <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {props.titulo}
                  </span>
                </div>
              </div>
              <div className={"col-12 col-sm-12 col-md-10 col-lg-10 sin_padding"}>
                <div className={"col-12 sin_padding"}>
                  <a href={props.sitioWeb} target="_blank" rel="noopener noreferrer" className="row">
                    <span className={"texto_16_17 montse color_azul regular"}>{props.sitioWeb}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        );

        button = null;
      }
    } else if (props.tipo === 9) {
      button = <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold"].join(" ")} />; //Emisoras/InformacionGral
      if (props.subtipo === 1) {
        //Emisoras/InformacionGral3
        titulo1 = getLan() === "es" ? "Actividad económica" : "Economic activity";
        if (props.actividadEconomica) {
          button = (
            <div className={[classes.encabezado_card3, classes.tipo4].join(" ")}>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{props.actividadEconomica}</span>
              </div>
            </div>
          );
        }
      }
      if (props.subtipo === 2) {
        //Calificadoras/InformacionGral2
        titulo1 = getLan() === "es" ? "Dirección" : "Address";
        if (props.direccion) {
          button = (
            <div className={[classes.encabezado_card3, classes.tipo4].join(" ")}>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>
                  {props.direccion ? props.direccion : getLan() === "es" ? "No existen resultados para la búsqueda:" : "There are no results for the search:"}
                </span>
              </div>
            </div>
          );
        }
      } else if (props.subtipo === 3) {
        //Emisoras/InformacionGral4 - Calificadoras/InformacionGral3
        titulo1 = getLan() === "es" ? "Sitio Web" : "Website";
        if (props.sitioWeb) {
          button = (
            <a href={props.sitioWeb} target="_blank" rel="noopener noreferrer" className="row">
              <div className={[classes.encabezado_card3, classes.tipo4].join(" ")}>
                <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{props.sitioWeb}</span>
              </div>
            </a>
          );
        }
      } else if (props.subtipo === 4) {
        //Emisoras/InformacionGral4
        let sector = null;
        let subSector = null;
        let ramo = null;
        let subRamo = null;
        let actividadEconomica = null;
        let encabezado_sector = null;
        let encabezado_subSector = null;
        let encabezado_ramo = null;
        let encabezado_subRamo = null;
        let encabezado_actividadEconomica = null;

        titulo1 = getLan() === "es" ? "Actividad" : "Activity";
        let titulo = "Sector";
        titulo2 = "Sub Sector";
        titulo3 = getLan() === "es" ? "Ramo" : "Branch";
        titulo4 = getLan() === "es" ? "Sub ramo" : "Sub branch";
        let titulo5 = getLan() === "es" ? "Actividad económica" : "Economic activity";

        if (props.sector !== undefined && props.sector !== null) {
          sector = <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.sector.nombre}</span>;

          encabezado_sector = <div className={[classes.titulo_25pct, "texto_16 montse semi_bold"].join(" ")}>{titulo}</div>;
        } else {
          sector = null;
          encabezado_sector = null;
        }
        if (props.subsector !== undefined && props.subsector !== null) {
          subSector = (
            <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.subsector.nombre}</span>
          );
          encabezado_subSector = <div className={[classes.titulo_25pct, "texto_16 montse semi_bold"].join(" ")}>{titulo2}</div>;
        } else {
          subSector = null;
          encabezado_subSector = null;
        }
        if (props.ramo !== undefined && props.ramo !== null) {
          ramo = <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.ramo.nombre}</span>;
          encabezado_ramo = <div className={[classes.titulo_25pct, "texto_16 montse semi_bold"].join(" ")}>{titulo3}</div>;
        } else {
          ramo = null;
          encabezado_ramo = null;
        }
        if (props.subramo !== undefined && props.subramo !== null) {
          subRamo = <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.subramo.nombre}</span>;
          encabezado_subRamo = <div className={[classes.titulo_25pct, "texto_16 montse semi_bold"].join(" ")}>{titulo4}</div>;
        } else {
          subRamo = null;
          encabezado_subRamo = null;
        }

        if (props.actividadEconomica !== undefined && props.actividadEconomica !== null) {
          actividadEconomica = <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.actividadEconomica}</span>;
          encabezado_actividadEconomica = <div className={[classes.titulo_25pct, "texto_16 montse semi_bold"].join(" ")}>{titulo5}</div>;
        } else {
          actividadEconomica = null;
          encabezado_actividadEconomica = null;
        }

        button = (
          <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            {encabezado_sector}
            {sector}
            {encabezado_subSector}
            {subSector}
            {encabezado_ramo}
            {ramo}
            {encabezado_subRamo}
            {subRamo}
            {encabezado_actividadEconomica}
            {actividadEconomica}
          </div>
        );
      } else if (props.subtipo === 6) {
        //Emisoras/InformacionGral3
        if (props.fechaCancelacion && props.fechaCancelacion !== undefined) {
          titulo1 = getLan() === "es" ? "Fecha de cancelación" : "Cancellation date";
          button = (
            <div className={[classes.encabezado_card3, classes.tipo4].join(" ")}>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>{formatDate(props.fechaCancelacion)}</span>
              </div>
            </div>
          );
        }
      } else if (props.subtipo === 0) {
        //Emisoras/InformacionGral4 
        titulo1 = getLan() === "es" ? "Dirección" : "Address";
        if (props.direccion) {
          button = (
            <div className={[classes.encabezado_card3, classes.tipo4].join(" ")}>
              <div className="col-12 sin_padding">
                <span className={["texto_16 montse regular"].join(" ")}>
                  {props.direccion ? props.direccion : getLan() === "es" ? "No existen resultados para la búsqueda:" : "There are no results for the search:"}
                </span>
              </div>
            </div>
          );
        }
      }

      encabezado = (
        <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
          <div className={[classes.titulo_75pct, "color_azul nunito texto_16_17 semi_bold"].join(" ")}>{titulo1}</div>
        </div>
      );

      if (props.subtipo === 5) {
        //Emisoras/InformacionGral0
        if (props.razonComercial) {
          titulo1 = "Razón Comercial";
          button = null;

          encabezado = (
            <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <div className={["row"]}>
                <div className={["col-12 sin_padding"]}>
                  <div className={[classes.titulo_75pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>{titulo1}</div>
                </div>
                <div className={["col-12 sin_padding"]}>
                  <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.razonComercial}</span>
                </div>
              </div>
            </div>
          );
        } else {
          button = null;
          encabezado = null;
        }
      }
    } else if (props.tipo === 10) {
      //Emisoras/InformacionGral5 consejeros y funcionarios
      encabezado = (
        <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
          <span className={[classes.titulo_75pct, "col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
            {getLan() === "es" ? "Consejeros y funcionarios" : "Board members and officers"}
          </span>
        </div>
      );
      if (props.integrantes) {
        let icon = "fa-chevron-down";
        if (this.state.open) {
          icon = "fa-chevron-up";
        }
        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            {/* <h2 className={["mb-0", classes.titulo2].join(" ")} className="cursor"> */}
            <h2 className={["mb-0 cursor", classes.titulo2].join(" ")}>
              <div onClick={e => this.togglePanel(e)}>
                <span className={["semi_bold montse texto_16"].join(" ")}>{props.titulo}</span>
                <span> </span>
                <div className={[classes.titulo_5pct, "semi_bold montse"].join(" ")}>
                  <i className={[`fas ${icon}`, "Boton__icono_derecho__25mg6"].join(" ")} />
                </div>
              </div>
              {this.state.open ? (
                <div>
                  <div className={["card-body montse texto_14", classes.card_body, classes.card_body_2].join(" ")}>
                    {props.integrantes.map((dato, i) => (
                      <React.Fragment>
                        <div className={[classes.titulo_100pct, "nunito texto_16 semi_bold margin_top_20 margin_bottom_10"].join(" ")}>
                          {dato.tipoContacto}
                        </div>
                        <div className="row">
                          {
                            dato.integrantes ? (
                              dato.integrantes.map((integrante, i) => (
                                <React.Fragment>
                                  <div className={["color_azul nunito texto_16 semi_bold row col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4"].join(" ")}>
                                    <span className="color_azul nunito texto_16 semi_bold row col-12">
                                      {integrante.tipoPuesto}
                                    </span>
                                  </div>
                                  <br />
                                  {integrante.integrantes.map((persona, i) => (
                                    <React.Fragment>
                                      <div className={["montse texto_16 regular row col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 sin_padding_R"].join(" ")}>
                                        <span className=" montse texto_16 regular row col-12 sin_padding_R">
                                          {persona.nombre}
                                        </span>
                                      </div>
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ))
                            ) : null
                          }
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ) : null}
            </h2>
          </div>
        );
      } else {
        button = null;
      }
    } else if (props.tipo === 11) {
      //Emisoras/Cotizacion
      let percentageOfChange = 0;
      let lastPrice = 0;
      let netChange = 0;
      let show_indice = true;
      let fechaListado = null;
      let indice = props.indice_emisora;
      if (indice !== undefined && indice !== null) {
        if (indice.percentageOfChange) {
          percentageOfChange = indice.percentageOfChange;
          show_indice = true;
        }
        if (indice.lastPrice) {
          lastPrice = indice.lastPrice;
          show_indice = true;
        }
        if (indice.netChange) {
          netChange = indice.netChange;
          show_indice = true;
        }
        if (indice.tradeDate) {
          fechaListado = formatDate_ddMMyyy_millies(indice.tradeDate, 1);
          show_indice = true;
        }
      }

      const fechaActual = formatDateDiaMesAno(new Date());
      encabezado = (
        <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
          <div className={[classes.titulo_75pct, "nunito texto_30 regular"].join(" ")}>{props.titulo}</div>
          <div className={[classes.titulo_25pct, "nunito texto_14 semi_bold color_gris"].join(" ")}>{props.razonSocial}</div>
        </div>
      );
      // const number = props.estatus ? this.paintNumberHandler(props.estatus): 0;
      button = (
        <div
          className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold row", show_indice ? null : "d-none"].join(
            " "
          )}
        >
          <div className={[classes.leftCotizacion].join(" ")}>
            <span className={["sin_padding_L col-12 texto_24 nunito bold nowrap"].join(" ")}>
              {this.onSetFormatValues(lastPrice)}
            </span>
          </div>
          <div className={[classes.leftCotizacion].join(" ")}>
            <span className={["sin_padding_L col-12  texto_16 nunito semi_bold color_azul"].join(" ")}>Var</span>
            <span className={["nowrap", setColorIndice(netChange)].join(" ")}>${this.onSetFormatValues(netChange)}</span>
          </div>
          <div className={[classes.leftCotizacion].join(" ")}>
            <span className={["sin_padding_L col-12 texto_16 nunito semi_bold color_azul"].join(" ")}>Var %</span>
            <span className={["nowrap", setColorIndice(percentageOfChange)].join(" ")}>
              <FormattedNumber value={percentageOfChange / 100} style={`percent`} minimumFractionDigits={2} />
            </span>
          </div>
          <div className={[classes.leftCotizacion, classes.fechaListado].join(" ")}>
            <span className={["nunito texto_16 semi_bold nowrap"].join(" ")}>
              {fechaActual} {/* antes era fechaListado */}
            </span>
          </div>
        </div>
      );
    } else if (props.tipo === 12) {
      //Emisoras/Documentos y Valores Listados

      if (props.subtipo === 1) {
        //Emisoras inscritas/Documentos
        let ruta_documento = props.docType.toUpperCase() === "XBRL" ? props.nombreArchivo : getUrlDocument(props.nombreArchivo, false, true);
        if (props.docType.toUpperCase() === "XBRL") {
          texto_descarga_1 = getLan() === "es" ? "Ver documento" : "View document";
        } else {
          texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";
        }

        const archivos = <Download key={uuid()} docType={props.docType} url={ruta_documento} fileName={props.fileName} archivos_list={props.dato.archivosXbrl}/>;

        titulo2 = getLan() === "es" ? "Formato" : "Format";
        const fechaCreacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaCreacion);
        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <a href={ruta_documento} target={props.docType.toUpperCase() === "XBRL" ? "_blank" : "_self"} rel="noopener noreferrer" className="row">
                <div className="sin_padding_L">
                  <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{texto_descarga_1}</span>
                  <br />
                  <span className={"texto_10 montse semi_bold color_gris"}>
                    {getLan() === "es"
                      ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                      : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                  </span>
                </div>
                <div className="">
                  <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                </div>
              </a>
            </h2>
          </div>
        );

        button = null;

        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-6 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding_L"}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Descripción" : "Description"}
                </span>
              </div>
              <div className={"col-12 sin_padding_L"}>
                <span className={["texto_16 montse regular"].join(" ")}>
                  {props.tipoDocumento}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding_L"}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                </span>
              </div>
              <div className={"col-12 sin_padding_L"}>
                <span className={["texto_16 montse regular"].join(" ")}>
                  {fechaCreacion}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12  col-md-5  col-lg-3  sin_padding margin_bottom_10"}>
              {archivos}
            </div>
          </div>
        );
      } else if (props.subtipo === 2) {
        //Emisoras Inscritas/Valores Listados
        let fechaEmision = null;
        let fechaVencimiento = null;

        if (props.fechaEmision) {
          fechaEmision = getRealDateMilliesDayMonthYear(props.fechaEmision);
        }
        if (props.fechaVencimiento) {
          fechaVencimiento = getRealDateMilliesDayMonthYear(props.fechaVencimiento);
        }

        let fechaVencimientoLabel = null;
        let fechaVencimientoValue = null;
        let representanteComunLabel = null;
        let representanteComunValue = null;

        let accionLabel = (
          <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
            {getLan() === "es" ? "Acciones en circulación" : "Outsanding shares"}
          </span>
        );
        let accionValue = null;

        if ([3, 5, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(props.idTipoInstrumento)) {
          // 3 ckds - 5 warrants - deuda 4,6,7,8,9,10,11,12,13,14,15,16

          fechaVencimientoLabel = (
            <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Fecha vencimiento" : "Maturity date"}
            </span>
          );
          fechaVencimientoValue = (
            <span className={["texto_14 montse regular"].join(" ")}>{fechaVencimiento}</span>
          );
        }

        if ([2, 3, 5, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 20].includes(props.idTipoInstrumento)) {
          // 2 fibras - 3 ckds - 5 warrants - deuda 4,6,7,8,9,10,11,12,13,14,15,16 - 20 tracs
          representanteComunLabel = (
            <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Rep. común" : "Common rep."}
            </span>
          );
          representanteComunValue = (
            <span className={["texto_14 montse regular"].join(" ")}>{props.representanteComun}</span>
          );

          accionLabel = (
            <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Títulos en circulación" : "Outsanding securities"}
            </span>
          );
        }
        accionValue = (
          <span className={["texto_14 montse regular"].join(" ")}>{setFormatNumberIntString(props.titulosEnCirculacion)}</span>
        );

        let claveWarrant = null;
        let claveWarrantValue = null;
        let claveWarrantEmpty = null;
        let claveWarrantEmptyValue = null;
        if (props.claveWarrant) {
          claveWarrant = (
            <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>{getLan() === "es" ? "Clave" : "Ticker"}</span>
          );

          claveWarrantValue = (
            <span className={["texto_14 nunito regular"].join(" ")}>{props.claveWarrant}</span>
          );
        } else {
          claveWarrantEmpty = null;
          claveWarrantEmptyValue = null;
        }

        let tipoValor = null;
        if ([2, 5, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(props.idTipoInstrumento)) {
          // 2 fibras - 5 warrants - deuda 4,6,7,8,9,10,11,12,13,14,15,16  14 y 16 - 20 tracs
          tipoValor = (
            <span className={["texto_14  color_gris montse regular"].join(" ")}>{props.tipoValor}</span>
          );
        } else {
          tipoValor = null;
        }

        const modoListado = props.modoListado ? this.paintEstatusHandler(props.modoListado) : null;

        encabezado = (
          <div className={[tipoValor ? classes.encabezado_card2 : classes.encabezado_card, tipoValor ? null : classes.radius6, classes.tipo4, "texto_14 nunito bold row"].join(" ")}>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 sin_padding_L", props.claveWarrant ? null : "d-none"].join(" ")}>
              <div className="col-12 sin_padding">
                {claveWarrant}
              </div>
              <div className="col-12 sin_padding">
                {claveWarrantValue}
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1 sin_padding_L">
              <div className="col-12 sin_padding">
                <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {props.serie ? getLan() === "es" ? "Serie" : "Series" : ""}
                </span>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_14 montse regular"].join(" ")}>{props.serie}</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 sin_padding_L">
              <div className="col-12 sin_padding">
                <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {props.isin ? getLan() === "es" ? "ISIN" : "ISIN" : ""}
                </span>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_14 montse regular"].join(" ")}>{props.isin}</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 sin_padding">
              <div className="col-12 sin_padding">
                <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {props.fechaEmision ? getLan() === "es" ? "Fecha emisión" : "Issue date" : ""}
                </span>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_14 montse regular"].join(" ")}>{fechaEmision}</span>
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 sin_padding"].join(" ")}>
              <div className="col-12 sin_padding">
                {props.fechaVencimiento ? fechaVencimientoLabel : ""}
              </div>
              <div className="col-12 sin_padding">
                {props.fechaVencimiento ? fechaVencimientoValue : ""}
              </div>
            </div>
            <div className={["col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 sin_padding"].join(" ")}>
              <div className="col-12 sin_padding nowrap">
                {props.representanteComun ? representanteComunLabel : ""}
              </div>
              <div className="col-12 sin_padding">
                {props.representanteComun ? representanteComunValue : ""}
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 sin_padding">
              <div className="col-12 sin_padding nowrap">
                {props.titulosEnCirculacion ? accionLabel : ""}
              </div>
              <div className="col-12 sin_padding">
                {props.titulosEnCirculacion ? accionValue : ""}
              </div>
            </div>
            <div className={["col-12 top_invisible", classes.modoListado].join(" ")}>
              <div className="col-12 sin_padding end" >
                <span className={["texto_derecha texto_14 nunito semi-bold "].join(" ")}>
                  {" "}
                  {/* // MODO LISTADO */}
                  {modoListado}
                </span>
              </div>
            </div>

          </div>
        )

        button = null;
        if (tipoValor) {
          button = (
            <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
              {tipoValor}
            </div>
          );
        }
      } else if (props.subtipo === 3) {
        //Agendas de Derechos
        const fechaEx = getRealDateMilliesDayMonthYearLocale(props.fechaEx);
        const fechaRegistro = getRealDateMilliesDayMonthYearLocale(props.fechaRegistro);
        const fechaPago = getRealDateMilliesDayMonthYearLocale(props.fechaPago);
        let labelDate = null;
        if (props.sic) {
          labelDate = getLan() === "es" ? "Ex date o efectos" : "Exdate or effective date";
        } else {
          labelDate = getLan() === "es" ? "Ex date" : "Exdate";
        }


        button = (
          <div className={["card-header row", classes.card_header2].join(" ")} id="headingOne">
            <div className={"col-12 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Proporción/importe" : "Proportion/amount"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={[classes.verticalAlign_topText, "texto_16 montse regular"].join(" ")}>
                  {props.proporcion}
                </span>
              </div>
            </div>
          </div>
        );

        const status = props.estatus ? this.paintEstatusHandler(props.estatus) : null;
        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_24 nunito bold"].join(" ")}>{props.clave}</span>
              </div>
            </div>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-1 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Serie" : "Series"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={[classes.verticalAlign_topText, "texto_16 montse regular"].join(" ")}>
                  {props.serie}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {labelDate}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={[classes.verticalAlign_topText, "texto_16 montse regular"].join(" ")}>
                  {fechaEx}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Registro" : "Register"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={[classes.verticalAlign_topText, "texto_16 montse regular"].join(" ")}>
                  {fechaRegistro}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Pago" : "Pay"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={[classes.verticalAlign_topText, "texto_16 montse regular"].join(" ")}>
                  {fechaPago}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de derecho" : "Corporate action type"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={[classes.verticalAlign_topText, "texto_16 montse regular"].join(" ")}>
                  {props.tipoDerecho}
                </span>
              </div>
            </div>

            <div className={["col-12 col-sm-12 col-md-12 col-lg-1 sin_padding"].join(" ")}>
              <div className="col-12 sin_padding end" >
                <span className={["texto_derecha texto_14 nunito semi-bold "].join(" ")}>
                  {status}
                </span>
              </div>
            </div>

          </div>
        );


      } else if (props.subtipo === 4) {
        //Emisoras SIC /Valores listados
        button = (
          <div className={["card-header row", classes.card_header2].join(" ")} id="headingOne">
            <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Pais de origen " : "Country of origin "}
                </span>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {props.pais}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? " Mercado principal " : "Principal market "}
                </span>
                {/* </div>
              <div className={"col-12 sin_padding"}> */}
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {props.mercado}
                </span>
              </div>
            </div>
          </div>
        );

        const listado = props.listado ? this.paintEstatusHandler(props.listado) : null;
        const fechaListado = getRealDateMilliesDayMonthYear(props.fechaListadoBIVA);
        const fechaVencimiento = getRealDateMilliesDayMonthYear(props.fechaVencimiento);

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Serie" : "Series"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_24_17 nunito bold"].join(" ")}>
                  {props.serie}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "ISIN" : "ISIN"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {props.isin}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de Listado" : "Listing date"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {fechaListado}
                </span>
              </div>
            </div>
            {props.fechaVencimiento ?
              <div className={"col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding "}>
                  <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Fecha vencimiento" : "Maturity date"}
                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.fechaVencimientoPerpetua === true ? getLan() === "es" ? "Perpetua" : "Perpetual" : fechaVencimiento}
                  </span>
                </div>
              </div>
              : null}
            {props.tasaInteres ?
              <div className={"col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding "}>
                  <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tasa interés" : "Interest rate"}
                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.tasaInteres}
                  </span>
                </div>
              </div>
              : null}
            <div className={props.tasaInteres || props.fechaVencimiento ? "col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2 offset-lg-0 offset-xl-0 sin_padding margin_bottom_10" : "col-12 col-sm-12 col-md-6 col-lg-1 col-xl-1 offset-lg-4 offset-xl-4 sin_padding margin_bottom_10"}>
              &nbsp;
              <div className={"col-12 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {listado}
                </span>
              </div>
            </div>
          </div>
        );
      } else if (props.subtipo === 5) {
        //Emisoras SIC/Avisos de derechos
        texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";
        titulo2 = "Formato";
        button = null;

        const fechaRegistro = getRealDateMilliesDayMonthYear(props.fechaRegistro);
        encabezado = (
          <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-6 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de derecho" : "Corporate action type"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_24_17 nunito bold"].join(" ")}>
                  {props.tipoDerecho}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {fechaRegistro}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-5 col-lg-3 offset-md-6 offset-lg-0  sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <h2 className={["mb-0", classes.titulo].join(" ")}>
                  <a href={props.nombreArchivo} target="_blank" rel="noopener noreferrer" className="row">
                    <div className="sin_padding_L">
                      <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{texto_descarga_1}</span>
                      <br />
                      <span className={"texto_10 montse semi_bold color_gris"}>
                        {getLan() === "es"
                          ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                          : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                      </span>
                    </div>
                    <div className="">
                      <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                  </a>
                </h2>
              </div>

            </div>
          </div>
        );
      } else if (props.subtipo === 6) {
        //Requisitos de mantenimiento
        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <span className={[classes.titulo_33pct, "col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "12% de capital social pagado" : "12% of equity placed"}
            </span>
            <span className={[classes.titulo_66pct, "col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Comentarios" : "Comments"}
            </span>
            <br />
            <span className={[classes.titulo_33pct, "texto_16 nunito regular"].join(" ")}>{props.capitalSocial}</span>
            <span className={[classes.titulo_66pct, "texto_16 nunito regular"].join(" ")}>{props.observaciones}</span>
          </div>
        );

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_33pct, "col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Emisora" : "Ticker"}
            </span>
            <span className={[classes.titulo_33pct, "col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Serie" : "Series"}
            </span>
            <span className={[classes.titulo_33pct, "col sin_padding color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Periodo" : "Period"}
            </span>
            <br />
            <span className={[classes.titulo_33pct, "texto_24 nunito bold"].join(" ")}>{props.emisora}</span>
            <span className={[classes.titulo_33pct, "texto_24 nunito bold"].join(" ")}>{props.serie}</span>
            <span className={[classes.titulo_33pct, "texto_24 nunito bold"].join(" ")}>{props.periodo}</span>
          </div>
        );
      } else if (props.subtipo === 7) {
        //Listado Valores Documentos Definitivos

        texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";
        button = (
          <div className={["card", classes.card].join(" ")}>
            <div className={[classes.card_header2, classes.tipo4, classes.padding_card, "texto_24 nunito bold row"].join(" ")}>

              <div className={"col-12 col-sm-12 col-md-7 col-lg-9 row sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Nombre" : "Name"}
                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["texto_16 nunito"].join(" ")}>
                    {props.nombreArchivo}
                  </span>
                </div>
              </div>
              <div className={"col-12 col-sm-12 col-md-5 col-lg-3 row sin_padding margin_bottom_10"}>
                <div className={["margin_top_10", classes.card_header2].join(" ")} id="headingOne">
                  <h2 className={["mb-0", classes.titulo].join(" ")}>
                    <a href={props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                      <div className="sin_padding_L">
                        <span className={[classes.titulo, "texto_16 montse color_azul regular"].join("")}>{texto_descarga_1}</span>
                        <br />
                        <span className={"texto_10 montse semi_bold color_gris"}>
                          {getLan() === "es"
                            ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                            : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                        </span>
                      </div>
                      <div className="">
                        <i className={[classes.icono, "fas fa-arrow-alt-circle-down fa-lg"].join(" ")} />
                      </div>
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>

        );

        const fechaPublicacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);
        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_16 nunito"].join(" ")}>
                  {fechaPublicacion}
                </span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-8 col-lg-9 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de documento" : "Document type"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_16 nunito regular"].join(" ")}>
                  {props.tipoAviso}
                </span>
              </div>
            </div>


          </div>
        );
      } else if (props.subtipo === 8) {
        //Avisos LISTADO GENERALES y AVISOS DE SUSPENSION

        let ruta = null;
        if (props.urlDocumento) {
          ruta = getUrlDocument(props.urlDocumento);
        }

        const fecha = getRealDateMilliesDayMonthYear(props.fechaPublicacion);
        const hora = getRealDateMilliesHourMinSeg(props.fechaPublicacion);

        texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";
        if (props.serie) {
          button = (
            <div className={[classes.encabezado_card3, "texto_24 nunito bold row"].join(" ")}>
              <div className={"col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 row sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding"}>
                  <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Fecha" : "Date"}
                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {fecha} {hora}
                  </span>
                </div>
              </div>
              <div className={"col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 row sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding"}>
                  <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}

                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {props.claveCotizacion}

                  </span>
                </div>
              </div>
              <div className={"col-12 col-sm-6 col-md-2 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding"}>
                  <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Serie" : "Series"}
                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {props.serie}
                  </span>
                </div>
              </div>
              <div className={"col-12 col-sm-6 col-md-4 col-lg-3 sin_padding margin_bottom_10 margin_top_5"}>
                <h2 className={["mb-0", classes.titulo, classes.divDescarga].join(" ")}>
                  <a href={ruta} target="_blank" rel="noopener noreferrer" className="row">
                    <div className="sin_padding_L">
                      <span className={[classes.titulo, "texto_16_17 montse color_azul regular"].join(" ")}>{texto_descarga_1}</span>
                      <br />
                      <span className={"texto_10 montse semi_bold color_gris"}>
                        {getLan() === "es"
                          ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                          : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                      </span>
                    </div>
                    <div className={classes.flechaDescarga}>
                      <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                  </a>
                </h2>
              </div>
            </div>
          );
        } else {
          button = (
            <div className={[classes.encabezado_card3, "texto_24 nunito bold row"].join(" ")}>
              <div className={"col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 row sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding"}>
                  <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Fecha" : "Date"}
                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {fecha} {hora}
                  </span>
                </div>
              </div>
              <div className={"col-12 col-sm-6 col-md-4 col-lg-6 col-xl-6 row sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding"}>
                  <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}

                  </span>
                </div>
                <div className={"col-12 sin_padding"}>
                  <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {props.claveCotizacion}

                  </span>
                </div>
              </div>
              <div className={"col-12 col-sm-6 col-md-4 col-lg-3 sin_padding margin_bottom_10 margin_top_5"}>
                <h2 className={["mb-0", classes.titulo, classes.divDescarga].join(" ")}>
                  <a href={ruta} target="_blank" rel="noopener noreferrer" className="row">
                    <div className="sin_padding_L">
                      <span className={[classes.titulo, "texto_16_17 montse color_azul regular"].join(" ")}>{texto_descarga_1}</span>
                      <br />
                      <span className={"texto_10 montse semi_bold color_gris"}>
                        {getLan() === "es"
                          ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                          : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                      </span>
                    </div>
                    <div className={classes.flechaDescarga}>
                      <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                  </a>
                </h2>
              </div>
            </div>
          );
        }


        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row margin_top_5"].join(" ")}>
            <div className={["col-12 sin_padding"].join(" ")}>
              <span className={["texto_18 nunito bold"].join(" ")}>{props.descripcion}</span>
            </div>
          </div>
        );
      } else if (props.subtipo === 9) {
        //Avisos Operaciones Reportes Operaciones
        let fecha = null;
        if (props.fechaPublicacion) {
          const datePub = new Date(props.fechaPublicacion).toISOString().slice(0, 10);
          fecha = formatDate(datePub);
        } else {
          fecha = getRealDateMilliesDayMonthYear(props.creationDateInMillis);
        }
        texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";
        let ruta = null;
        if (props.url || props.urlDocumento) {
          ruta = props.url ? props.url : props.urlDocumento ? props.urlDocumento : "";
          ruta = getUrlDocument(ruta);
        }

        encabezado = (
          <div className={[classes.encabezado_card2, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_75pct, "col color_azul nunito texto_16 semi_bold"].join(" ")} />
            <span className={[classes.titulo_20pct, "texto_16 nunito semi_bold"].join(" ")}>
              {getLan() === "es" ? "Fecha" : "Date"}
            </span>
            <br />
            <span className={[classes.titulo_75pct, "texto_24 nunito bold"].join(" ")}>
              {props.titulo ? props.titulo : props.descripcion ? props.descripcion : null} </span>
            <span className={[classes.titulo_20pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>{fecha}</span>
          </div>
        );

        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <a href={ruta} target="_blank" rel="noopener noreferrer" className="row">
                <div className="sin_padding_L">
                  <span className={[classes.titulo, "texto_16 montse color_azul regular"].join("")}>{texto_descarga_1}</span>
                  <br />
                  <span className={"texto_10 montse semi_bold color_gris"}>
                    {getLan() === "es"
                      ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                      : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                  </span>
                </div>
                <div className="">
                  <i className={[classes.icono, "fas fa-arrow-alt-circle-down fa-lg"].join(" ")} />
                </div>
              </a>
            </h2>
          </div>
        );

      } else if (props.subtipo === 10) {
        const fechaSuspension = getRealDateMilliesDayMonthYearTimeZone(props.fechaSuspension);
        const fechaLevantamiento = getRealDateMilliesDayMonthYearTimeZone(props.fechaLevantamiento);
        //Requisitos de mantenimiento
        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Serie" : "Series"}
            </span>
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Fecha y hora de suspensión" : "suspension date and time"}

            </span>
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Fecha y hora de levantamiento" : "Date and time of survey"}
            </span>
            <br />
            <span className={[classes.titulo_33pct, "texto_16 nunito regular"].join(" ")}>{props.serie}</span>
            <span className={[classes.titulo_66pct, "texto_16 nunito regular"].join(" ")}>{fechaSuspension}</span>
            <span className={[classes.titulo_33pct, "texto_16 nunito regular"].join(" ")}>{fechaLevantamiento}</span>
          </div>
        );

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Clave" : "Ticker"}
            </span>
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Emisora" : "Corporate name"}
            </span>
            <br />
            <span className={[classes.titulo_33pct, "texto_24 nunito bold"].join(" ")}>{props.clave}</span>
            <span className={[classes.titulo_33pct, "texto_24 nunito bold"].join(" ")}>{props.emisora}</span>
          </div>
        );
      } else if (props.subtipo === 11) {
        //Emisoras Inscritas/Participacion por emision FIDUCIARIOS
        button = null;
        encabezado = (
          <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <NavLink
                  to={`/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${props.idEmisora
                    }&clave=${props.clave}&canceladas=null&biva=null&page=1`}
                >
                  <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
                </NavLink>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Serie" : "Series"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_24_17 regular color_gris"].join(" ")}>
                  {props.serie}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de valor" : "Instrument type"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["nunito texto_24_17 regular color_gris"].join(" ")}>
                  {props.tipoValor}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-6 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fiduciario" : "Trustee"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_24_17 regular color_gris"].join(" ")}>
                  {props.fiduciario}
                </span>
              </div>
            </div>
          </div >
        );
      } else if (props.subtipo === 12) {
        //Emisoras Inscritas/Participacion por emision RESPRESENTANTES
        button = null;
        encabezado = (
          <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <NavLink
                  to={`/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${props.idEmisora
                    }&clave=${props.clave}&canceladas=null&biva=null&page=1`}
                >
                  <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
                </NavLink>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Serie" : "Series"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_24_17 regular color_gris"].join(" ")}>
                  {props.serie}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de valor" : "Instrument type"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["nunito texto_24_17 regular color_gris"].join(" ")}>
                  {props.tipoValor}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-3 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Representante común" : "Common representative"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_24_17 regular color_gris"].join(" ")}>
                  {props.representanteComun}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-3 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Emisora" : "Issuer"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_24_17 regular color_gris"].join(" ")}>
                  {props.emisora}
                </span>
              </div>
            </div>
          </div>
        );
      } else if (props.subtipo === 13) {
        //Anuncios Biva 
        let fecha = null;
        if (props.fechaPublicacion) {
          const datePub = new Date(props.fechaPublicacion).toISOString().slice(0, 10);
          const hora = getRealDateMilliesHourMinSeg(props.fechaPublicacion);
          fecha = formatDate(datePub) + " " + hora;
        } else {
          fecha = getRealDateMilliesDayMonthYear(props.creationDateInMillis);
          const hora = getRealDateMilliesHourMinSeg(props.creationDateInMillis);
          fecha = fecha + " " + hora;
        }
        texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";
        let ruta = null;
        if (props.url || props.urlDocumento) {
          ruta = props.url ? props.url : props.urlDocumento ? props.urlDocumento : "";
          ruta = getUrlDocument(ruta);
        }

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <div className={[classes.titulo_10pct].join(" ")}>
              <i className={[classes.icono, "fas fa-file-contract fa-lg"].join(" ")} />
            </div>

            <span className={[classes.titulo_80pct, "texto_18 nunito bold"].join(" ")}>{props.titulo ? props.titulo : props.descripcion ? props.descripcion : null}</span>
            <h2 className={["mb-0", classes.titulo_10pct].join(" ")}>
              <a href={ruta} target="_blank" rel="noopener noreferrer" className="row">
                <div className="sin_padding_L">
                  <i className={[classes.icono, classes.color_azul, "fas fa-arrow-alt-circle-down fa-lg"].join(" ")} />
                  <br />
                  <span className={"texto_10 montse semi_bold color_gris"}>
                    {getLan() === "es"
                      ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                      : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                  </span>
                </div>
              </a>
            </h2>
          </div>

        );

        button = (
          <div className={[classes.encabezado_card3, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_20pct, " col sin_padding texto_16 nunito semi_bold"].join(" ")}>
              {getLan() === "es" ? "Fecha" : "Date"}
            </span>
            <br />
            <span className={[classes.titulo_20pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>{fecha}
              {props.claveCotizacion}
            </span>
          </div>
        );
      } else if (props.subtipo === 14) {
        //listado_de_valores/colocaciones

        let ruta = null;
        let download = null;
        if (props.url) {
          ruta = getUrlDocument(props.url);
          texto_descarga_1 = getLan() === "es" ? "Descargar documento" : "Download document";


          download = (<div className={"col-12 col-sm-6 col-md-4 col-lg-3 sin_padding margin_bottom_10 margin_top_5"}>
            <h2 className={["mb-0", classes.titulo, classes.divDescarga].join(" ")}>
              <a href={ruta} target="_blank" rel="noopener noreferrer" className="row">
                <div className="sin_padding_L">
                  <span className={[classes.titulo, "texto_16_17 montse color_azul regular"].join(" ")}>{texto_descarga_1}</span>
                  <br />
                  <span className={"texto_10 montse semi_bold color_gris"}>
                    {getLan() === "es"
                      ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                      : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                  </span>
                </div>
                <div className={classes.flechaDescarga}>
                  <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                </div>
              </a>
            </h2>
          </div>)
        }

        const fecha = formatDate(props.date);

        button = (
          <div className={[classes.encabezado_card3, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha" : "Date"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {fecha}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave de pizarra" : "Ticker symbol"}

                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {props.clave}

                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-6 col-md-2 col-lg-4 col-xl-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding"}>
                <span className={["col sin_padding texto_16 nunito semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Intermediario" : "Intermediary"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {props.intermediary}
                </span>
              </div>
            </div>
            {download}

          </div>
        );


        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row margin_top_5"].join(" ")}>
            <div className={["col-12 sin_padding"].join(" ")}>
              <span className={["texto_18 nunito bold"].join(" ")}>{props.descripcion}</span>
            </div>
          </div>
        );
      }
    } else if (props.tipo === 13) {
      //Emisoras Inscritas/Banco de Informacion - Calificadoras Eventos relevantes y reporte mensual

      titulo2 = "Formato";
      const fechaPublicacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);
      let encabezado1 = null;
      if (props.docType && props.docType.toUpperCase() === "XBRL") {
        titulo1 = getLan() === "es" ? "Ver documento" : "View document";
      } else {
        titulo1 = getLan() === "es" ? "Descargar documento" : "Download document";
      }

      const archivos = <Download key={uuid()} docType={props.docType} url={props.nombreArchivo} fileName={props.fileName} archivos_list={props.dato.archivosXbrl}/>;

      button = null;
      encabezado1 = (
        <div
          className={[classes.encabezado_card2, classes.tipo4, "row"].join(" ")}
        >
          <div className={"col-12 col-sm-12 col-md-7 col-lg-6 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Clave" : "Ticker"}
              </span>
            </div>
            <div className={"col-12 sin_padding"}>
              <span className={["texto_24_17 nunito bold"].join(" ")}>
                <NavLink
                  to={`/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${props.idEmisora
                    }&clave=${props.clave}&canceladas=null&biva=null&page=1`}>
                  <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
                </NavLink>
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-5 col-lg-3 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Fecha de Publicación" : "Publication date"}
              </span>
            </div>
            <div className={"col-12 sin_padding"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {fechaPublicacion}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-5 col-lg-3 offset-md-7 offset-lg-0  sin_padding margin_bottom_10"}>
            {archivos}
          </div>
          <br />
        </div>
      );

      body = (
        <div className={[classes.encabezado_card, classes.tipo4].join(" ")}>
          <div className={"col-12 sin_padding margin_bottom_10"}>
            <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold margin_bottom_10"].join(" ")}>
              {getLan() === "es" ? "Descripción" : "Description"}
            </span>
          </div>
          <span className={["texto_16_17 montse regular"].join(" ")}>{props.tipoDocumento}</span>
        </div>
      );

      if (props.subtipo === 1) {
        // Calificadoras/Eventos relevantes
        titulo1 = getLan() === "es" ? "Ver documento" : "View document";
        titulo2 = getLan() === "es" ? "Formato" : "format";
        let clave = null;
        const fecha = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);

        if (props.noEmisor) {
          clave = null;
        } else {
          clave = this.props.clave;
        }

        button = null;
        const archivos = <Download key={uuid()} docType={props.docType ? props.docType : "XBRL"} url={props.nombreArchivo} fileName={props.nombre} archivos_list={props.dato.archivosXbrl}/>;

        encabezado1 = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_24 nunito regular"].join(" ")}>
                  {clave}
                </span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-6 col-lg-2 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Serie" : "Series"}
                </span>
              </div>
              <div className={"col-12 sin_padding_L"}>
                <span className={["montse texto_16_17 regular"].join(" ")}>
                  {props.serie}
                </span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-6 col-lg-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["montse texto_16_17 regular"].join(" ")}>{fecha}</span>

              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 offset-lg-0  sin_padding margin_bottom_10"}>
            
              <h2 className={["mb-0", classes.titulo, classes.divDescarga].join(" ")}>
                {archivos}
              </h2>
            </div>

          </div >
        );

        body = (
          <div className={[classes.encabezado_card, classes.tipo4, "row"].join(" ")}>
            <span className={["col-12 sin_padding col color_azul nunito texto_16_17 semi_bold", classes.margin_bottom_label].join(" ")}>
              {getLan() === "es" ? "Descripción" : "Description"}
            </span>
            <br />
            <span className={["texto_16_17 montse regular"].join(" ")}>{props.tipoDocumento}</span>
          </div>
        );
      } else if (props.subtipo === 2) {
        // Calificadoras/ reporte mensual
        titulo2 = getLan() === "es" ? "Formato" : "Format";
        const fecha = getRealDateMilliesDayMonthYearTimeZone(props.fechaCreacion);
        encabezado1 = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={["col-6 col-sm-6 col-md-6 col-lg-3 sin_padding row", classes.margin_bottom_row].join(" ")}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["sin padding color_azul nunito texto_16_17 semi_bold", classes.margin_bottom_label].join(" ")}>
                  {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                </span>
              </div>
              <div className={"col col-12 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>{fecha}</span>
              </div>
            </div>

            <div className={["col-6 col-sm-12 col-md-12 col-lg-5 sin_padding row", classes.margin_bottom_row].join(" ")}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding col color_azul nunito texto_16_17 semi_bold", classes.margin_bottom_label].join(" ")}>
                  {getLan() === "es" ? "Descripción" : "Description"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["col-12 sin_padding texto_16_17 montse regular"].join(" ")}>{props.tipoDocumento}</span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 offset-lg-1  sin_padding margin_bottom_10"}>
              <h2 className={["mb-0", classes.titulo, classes.divDescarga].join(" ")}>
                <a href={props.nombreArchivo} target={props.docType.toUpperCase() === "XBRL" ? "_blank" : "_self"} rel="noopener noreferrer" className="row">
                  <div className="sin_padding_L">
                    <span className={[classes.titulo, "texto_16_17 montse color_azul regular"].join(" ")}>{titulo1}</span>
                    <br />
                    <span className={"texto_10 montse semi_bold color_gris"}>
                      {getLan() === "es"
                        ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                        : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                    </span>
                  </div>
                  <div className={classes.flechaDescarga}>
                    <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                  </div>
                </a>
              </h2>
            </div>
          </div>
        );
        button = null;
        body = null;
      } else if (props.subtipo === 3) {
        // Avisos Ofertas Publicas
        titulo1 = getLan() === "es" ? "Descargar documento" : "Download document";
        titulo2 = getLan() === "es" ? "Formato" : "Format";
        const fecha = getRealDateMilliesDayMonthYear(props.fechaPublicacion);
        const hora = getRealDateMilliesHourMinSeg(props.fechaPublicacion);
        encabezado1 = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_25pct, "col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Clave" : "Ticker"}
            </span>
            <span className={[classes.titulo_25pct, "col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Serie" : "Series"}
            </span>
            <br />
            <span className={[classes.titulo_25pct, "texto_24 nunito bold"].join(" ")}>{props.claveCotizacion}</span>
            {props.dato.series.map((serie, i) => (
              <span className={[classes.titulo_25pct, "texto_24 nunito bold"].join(" ")}>{serie}</span>
            ))}
          </div>
        );

        body = (
          <Auxs>
            <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <span className={[classes.titulo_33pct, "col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Fecha" : "Date"}
              </span>
              <span className={[classes.titulo_33pct, "col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Hora" : "Time"}
              </span>
              <br />
              <span className={[classes.titulo_33pct, "texto_16 montse regular"].join(" ")}>{fecha}</span>
              <span className={[classes.titulo_33pct, "texto_16 montse regular"].join(" ")}>{hora}</span>
              <br />
            </div>
            <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <span className={[classes.titulo_75pct, "col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Razón social" : "Corporate name"}
              </span>
              <br />
              <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.razonSocial}</span>
              <br />
            </div>
            <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <span className={[classes.titulo_75pct, "col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Tipo de valor" : "Instrument type"}
              </span>
              <br />
              <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.tipoValor}</span>
              <br />
            </div>
            <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <span className={[classes.titulo_75pct, "col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Tipo de aviso" : "Notification type"}
              </span>
              <br />
              <span className={[classes.titulo_75pct, "texto_16 montse regular"].join(" ")}>{props.dato.tipoAviso}</span>
            </div>
          </Auxs>
        );

        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <a href={props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                <div className="sin_padding_L">
                  <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{titulo1}</span>
                  <br />
                  <span className={"texto_10 montse semi_bold color_gris"}>
                    {getLan() === "es"
                      ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                      : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                  </span>
                </div>
                <div className="">
                  <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                </div>
              </a>
            </h2>
          </div>
        );
      } else if (props.subtipo === 4) {
        //Documentos ASG
        const fechaPublicacion = getRealDateIgnoreTimeIfEmpty(props.fechaPublicacion, props.dato.tipoDocumentoASG.id);
        const archivos = <Download key={uuid()} docType={props.docType} url={props.nombreArchivo} fileName={props.fileName} archivos_list={props.dato.archivosXbrl}/>;
        encabezado1 = (
          <div
            className={[classes.encabezado_card2, classes.tipo4, "row"].join(" ")}
          >
            <div className={"col-12 col-sm-12 col-md-7 col-lg-6 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_24_17 nunito bold"].join(" ")}>
                  <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-5 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                  {getLan() === "es" ? "Fecha de Publicación" : "Publication date"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {fechaPublicacion}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-5 col-lg-3 offset-md-7 offset-lg-0  sin_padding margin_bottom_10"}>
              {archivos}
            </div>
            <br />
          </div>
        );

        body = (
          <div className={[classes.encabezado_card, classes.tipo4].join(" ")}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold margin_bottom_10"].join(" ")}>
                {getLan() === "es" ? "Descripción" : "Description"}
              </span>
            </div>
            <span className={["texto_16_17 montse regular"].join(" ")}>{props.tipoDocumento}</span>
          </div>
        );
      }
      encabezado = (
        <Auxs>
          {encabezado1}
          {body}
        </Auxs>
      );
    } else if (props.tipo === 14) {
      //Emisoras Inscritas/Descarga Documentos
      texto_descarga_1 = getLan() === "es" ? "Descargar Documentos" : "Download documents";
      button = (
        <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
          <h2 className={["mb-0", classes.titulo].join(" ")}>
            <div>
              <span className={[classes.titulo_60pct, "regular montse texto_16 "].join(" ")}>
                {getLan() === "es" ? "Resultados" : "Results"}
              </span>

              <button className={["btn btn-link ", classes.btn_link, classes.titulo_40pct].join(" ")} type="button">
                <span className={[classes.titulo, "regular montse texto_16 "].join(" ")}>{props.texto_descarga_1}</span>
                <span> </span>
                <i className={["fas fa-arrow-alt-circle-down", "Boton__icono_derecho__25mg6"].join(" ")} />
                <br />
                <div className={[classes.titulo_emisoraPDF, "semi_bold montse texto_10"].join(" ")}>
                  {getLan() === "es"
                    ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                    : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                </div>
              </button>
            </div>
          </h2>
        </div>
      );
    } else if (this.props.tipo === 15) {
      // LISTADO / SEGUIMIENTO SOLICITUDES
      if (this.props.subtipo === 1) {
        titulo1 = getLan() === "es" ? "Descargar documento" : "Download document";
        titulo2 = "Formato";
        const fechaSolicitud = getRealDateMilliesDayMonthYearTimeZone(props.fechaSolicitud);
        let encabezado1 = null;
        let body1 = null;
        let button1 = null;
        encabezado1 = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Clave" : "Ticker"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["nunito texto_24 bold"].join(" ")}>
                  {props.clave}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-8 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Razón social" : "Corporate name"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_16 regular"].join(" ")}>
                  {props.razonSocial}
                </span>
              </div>
            </div>
          </div>
        );
        body1 = (
          <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-4 col-lg-4 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Número de trámite" : "Filing number"}
                </span>
              </div>
              <div className={["col-12 sin_padding",].join(" ")}>
                <div className={[this.props.esPreliminar ? null : "d-none", "row cursor"].join(" ")} onClick={e => this.clickHandlerTramite(props.idTramite, props.numeroTramite, props.idEmisora, props.claveAviso, props.razonSocialListado)} >
                  <span className={["montse texto_16 regular"].join(" ")}>
                    {props.numeroTramite}
                  </span>
                </div>
                <div className={[this.props.esPreliminar ? "d-none" : null, "row"].join(" ")}>
                  <span className={["montse texto_16 regular"].join(" ")}>
                    {props.numeroTramite}
                  </span>
                </div>
              </div>
            </div>
            <div className={["col-12 col-sm-12 col-md-8 col-lg-8 row sin_padding margin_bottom_10", props.fechaSolicitud ? null : "d-none"].join(" ")}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de solicitud" : "Application date"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_16 regular"].join(" ")}>
                  {fechaSolicitud}
                </span>
              </div>
            </div>
          </div>
        );
        if (!this.props.esPreliminar) {
          button = (
            <div className={["card-header", classes.card_header3].join(" ")} id="headingOne">
              <h2 className={["mb-0", classes.titulo].join(" ")}>
                <button
                  className={["btn btn-link collapsed padding_left_5", classes.btn_link].join(" ")}
                  type="button"
                  data-toggle="collapse"
                  data-target={"#" + props.id}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  onClick={this.handleClick.bind(this)}
                >
                  <span className={[classes.titulo, "semi_bold montse"].join(" ")}>{props.titulo}</span>
                  <span> </span>
                  <i className={["fas fa-chevron-down", classes.accordion_toggle].join(" ")} />
                </button>
              </h2>
            </div>
          );
        } else {
          button = (
            <div className={["card-header no_padding", classes.card_header3, classes.card_noPadding].join(" ")} id="headingOne">
            </div>
          );
        }

        if (props.detallesTramite !== null) {
          let fideicomitentes = null;
          if (props.detallesTramite.detalle !== null && props.detallesTramite.detalle !== undefined) {
            if (props.detallesTramite.detalle.tipoMerdado !== null && props.detallesTramite.detalle.tipoTramite !== null) {
              if (props.detallesTramite.detalle.fideicomitentes.length > 0) {
                fideicomitentes = (
                  <React.Fragment>
                    <div className={"col-12 col-sm-12 col-md-4 col-lg-4 row sin_padding margin_bottom_10"}>
                      <div className={"col-12 sin_padding "}>
                        <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                          {getLan() === "es" ? "Fideicomitente" : "Settlor"}
                        </span>
                      </div>
                    </div>
                    <div className={"col-12 col-sm-12 col-md-8 col-lg-8 row sin_padding margin_bottom_10"}>
                      {props.detallesTramite.detalle.fideicomitentes.map((fideicomitente, i) => (
                        <React.Fragment>
                          <div className={"col-12 sin_padding "}>
                            <span className={["montse texto_16 regular"].join(" ")}>{fideicomitente.nombre}</span>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </React.Fragment>
                );
              }
              body = (
                <div className={[classes.encabezado_card6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
                  <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                    <div className={"col-12 sin_padding "}>
                      <span className={["nunito texto_16 semi_bold"].join(" ")}>
                        {getLan() === "es"
                          ? "BIVA informa que se presentó la siguiente solicitud:"
                          : "BIVA announces the application:"}
                      </span>
                    </div>
                  </div>
                  <div className={"col-12 col-sm-12 col-md-4 col-lg-4 row sin_padding margin_bottom_10"}>
                    <div className={"col-12 sin_padding "}>
                      <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                        {getLan() === "es" ? "Mercado" : "Market type"}
                      </span>
                    </div>
                  </div>
                  <div className={"col-12 col-sm-12 col-md-8 col-lg-8 row sin_padding margin_bottom_10"}>
                    {props.detallesTramite.detalle.tiposMercado.map((tipoMercado, i) => (
                      <React.Fragment>
                        <div className={"col-12 sin_padding "}>
                          <span className={["montse texto_16 regular"].join(" ")}>{tipoMercado.nombre}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                  <div className={"col-12 col-sm-12 col-md-4 col-lg-4 row sin_padding margin_bottom_10"}>
                    <div className={"col-12 sin_padding "}>
                      <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                        {getLan() === "es" ? "Tipo de trámite" : "Filing type"}
                      </span>
                    </div>
                  </div>
                  <div className={"col-12 col-sm-12 col-md-8 col-lg-8 row sin_padding margin_bottom_10"}>
                    <div className={"col-12 sin_padding "}>
                      <span className={["montse texto_16 regular"].join(" ")}>
                        {props.detallesTramite.detalle.tipoTramite.nombre}
                      </span>
                    </div>
                  </div>
                  <div className={"col-12 col-sm-12 col-md-4 col-lg-4 row sin_padding margin_bottom_10"}>
                    <div className={"col-12 sin_padding "}>
                      <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                        {getLan() === "es" ? "Instrumento" : "Security type"}
                      </span>
                    </div>
                  </div>
                  <div className={"col-12 col-sm-12 col-md-8 col-lg-8 row sin_padding margin_bottom_10"}>
                    {props.detallesTramite.detalle.tiposValor.map((tipoValor, i) => (
                      <React.Fragment>
                        <div className={"col-12 sin_padding "}>
                          <span className={["montse texto_16 regular"].join(" ")}>{tipoValor.nombre}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                  {fideicomitentes}
                </div>

              );
            }
          }
        } else {
          body = (
            <div className={[classes.encabezado_card6, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <img src={loader} alt="" />
            </div>
          );
        }
        if (this.props.documentosDisponibles) {
          texto_descarga_1 = getLan() === "es" ? "Consultar Documentos" : "Consult documents";
          bottomButton = (
            <div className={["card-header", classes.card_header, classes.card_header_paddings].join(" ")} id="headingOne">
              <h2 className={["mb-0", classes.titulo].join(" ")}>
                <button
                  className={["btn btn-link padding_left_5", classes.btn_link].join(" ")}
                  type="button"
                  onClick={this.handleClickListadoValores.bind(this)}
                >
                  <span className={[classes.titulo, "semi_bold montse"].join(" ")}>{texto_descarga_1}</span>
                  <span> </span>
                  <i className={["fas fa-long-arrow-alt-right", "Boton__icono_derecho__25mg6"].join(" ")} />
                </button>
              </h2>
            </div>
          );
        } else {
          bottomButton = (
            <div className={[classes.encabezado_card4, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <i className={["fas fa-lock color_blanco"].join(" ")} />
              &nbsp;
              <span className={[classes.titulo_75pct, "col montse texto_16 regular texto_blanco"].join(" ")}>
                {getLan() === "es"
                  ? "Documentos no disponibles – Solicitud con confidencialidad parcial."
                  : "Documents not available - Partial confidentiality application"}
              </span>
            </div>
          );
        }

        encabezado = (
          <Auxs>
            {encabezado1}
            {body1}
            {button1}
          </Auxs>
        );
      } else if (this.props.subtipo === 2) {
        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_100pct, "col nunito texto_24 bold"].join(" ")}>{props.razonSocial}</span>
          </div>
        );

        if (props.detallesTramite !== null && props.detallesTramite !== undefined) {
          if (props.detallesTramite.detalle !== null && props.detallesTramite.detalle !== undefined) {
            button = (
              <React.Fragment>
                <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
                  <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}
                  </span>
                  <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Número de trámite" : "Filing number"}
                  </span>
                  <br />
                  <span className={[classes.titulo_33pct, "col texto_16 nunito regular"].join(" ")}>{props.clave}</span>
                  <span className={[classes.titulo_33pct, "col texto_16 nunito regular"].join(" ")}>{props.numeroTramite}</span>
                </div>
                <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
                  <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tipo de valor" : "Instrument type"}
                  </span>
                  <br />
                  {props.detallesTramite.detalle.tiposValor.map((tipoValor, i) => (
                    <React.Fragment>
                      <span className={[classes.titulo_75pct, "col texto_16 nunito regular"].join(" ")}>
                        {tipoValor.nombre}
                      </span>
                    </React.Fragment>
                  ))}
                  <br />
                </div>
              </React.Fragment>
            );
          }
        }
      } else if (this.props.subtipo === 3) {
        //Solicitudes Tramites
        titulo1 = getLan() === "es" ? "Descargar Documento" : "Download document";
        titulo2 = "Formato";
        const fechaRecepcion = getRealDateMilliesDayMonthYearTimeZone(props.fechaRecepcion);
        let encabezado1 = null;
        let body1 = null;
        let button1 = null;
        encabezado1 = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "No. de envío" : "Submittal number"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_14 bold"].join(" ")}>
                  {props.numeroEnvio}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-2 col-lg-2 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha" : "Date"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_14 regular"].join(" ")}>
                  {fechaRecepcion}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-8 col-lg-8 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Tipo de documento" : "Document type"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_14 regular"].join(" ")}>
                  {props.tipoAviso}
                </span>
              </div>
            </div>
          </div>
        );

        body = (
          <div className={[classes.encabezado_card3, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-9 col-lg-9 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Nombre de archivo" : "File name"}
                </span>
              </div>
              <div className={"col-12 sin_padding "}>
                <span className={["montse texto_14 regular"].join(" ")}>
                  {props.nombreArchivo}
                </span>
              </div>
            </div>
            <div className={"col-12 col-sm-12 col-md-3 col-lg-3 offset-lg-0 sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding "}>
                <h2 className={["mb-0", classes.titulo].join(" ")}>
                  <a href={props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                    <div className="sin_padding_L">
                      <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{titulo1}</span>
                      <br />
                      <span className={"texto_10 montse semi_bold color_gris"}>
                        {getLan() === "es"
                          ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                          : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                      </span>
                    </div>
                    <div className="">
                      <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                  </a>
                </h2>
              </div>
            </div>
          </div>
        );

        // body1 = (
        //   <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
        //     <span className={[classes.titulo_100pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
        //       {getLan() === "es" ? "Tipo de documento" : "Document type"}
        //     </span>
        //     <br />
        //     <span className={[classes.titulo_100pct, "col texto_16 nunito regular"].join(" ")}>{props.tipoAviso}</span>
        //   </div>
        // );

        button = null;
        //  (
        //   <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
        //     <h2 className={["mb-0", classes.titulo].join(" ")}>
        //       <a href={props.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
        //         <div className="sin_padding_L">
        //           <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{titulo1}</span>
        //           <br />
        //           <span className={"texto_10 montse semi_bold color_gris"}>
        //             {getLan() === "es"
        //               ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
        //               : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
        //           </span>
        //         </div>
        //         <div className="">
        //           <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
        //         </div>
        //       </a>
        //     </h2>
        //   </div>
        // );

        encabezado = (
          <Auxs>
            { encabezado1 }
            { body }
            { body1 }
            { button1 }
          </Auxs>
        );
      } else if (this.props.subtipo === 4) {
        // PROSPECTOS DE COLOCACION
        titulo1 = getLan() === "es" ? "Descargar prospecto" : "Download prospectus";
        titulo2 = getLan() === "es" ? "Formato" : "Format";
        let titulo_boton1 = getLan() === "es" ? "Clave de cotización" : "Ticker";
        let titulo_boton2 = getLan() === "es" ? "Fecha" : "Date";
        const fechaPublicacion = getRealDateMilliesDayMonthYear(props.fechaPublicacion);
        let button1 = null;

        button = (
          <div className={["card-header", classes.card_header3, classes.padding_card_header].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <button
                className={["btn btn-link collapsed", classes.btn_link].join(" ")}
                type="button"
                data-toggle="collapse"
                data-target={"#" + props.id}
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={this.handleClickBothActions.bind(this)}
              >
                <div className="row">

                  <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 sin_padding_L">
                    <div className="col-12 sin_padding">
                      <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                        {titulo_boton1}
                      </span>
                    </div>
                    <div className="col-12 sin_padding">
                      <span className={[classes.titulo2, "texto_24 montse"].join(" ")}>
                        {props.clave}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 sin_padding_L">
                    <div className="col-12 sin_padding">
                      <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                        {getLan() === "es" ? "Razón social" : "Corporate name"}
                      </span>
                    </div>
                    <div className="col-12 sin_padding">
                      <span className={[classes.titulo2, "texto_24 montse"].join(" ")}>
                        {props.razonSocial}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 sin_padding_L">
                    <div className="col-12 sin_padding">
                      <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                        {titulo_boton2}
                      </span>
                    </div>
                    <div className="col-12 sin_padding">
                      <span className={[classes.titulo2, "texto_24 montse nowrap"].join(" ")}>
                        {fechaPublicacion}
                      </span>
                    </div>
                  </div>

                </div>
                <span> </span>
                <i className={["fas fa-chevron-down", classes.accordion_toggle].join(" ")} />

              </button>
            </h2>
          </div>
        );
        let urlDocumento = null;
        if (props.detallesTramite) {
          urlDocumento = props.detallesTramite.urlDocumento;
        } else {
          urlDocumento = props.urlDocumento;
        }

        if (props.detallesTramite !== null) {
          let fideicomitentes = null;
          if (props.detallesTramite.fideicomitentes) {
            if (props.detallesTramite.fideicomitentes.length > 0) {
              fideicomitentes = (
                <React.Fragment>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sin_padding_L">
                    <div className="col-12 sin_padding">
                      <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                        {getLan() === "es" ? "Fideicomitente" : "Settlor"}
                      </span>
                    </div>
                    <div className="col-12 sin_padding">
                      {props.detallesTramite.fideicomitentes.map((fideicomitente, i) => (
                        <React.Fragment>
                          <span className={[classes.titulo_75pct, "texto_16 nunito regular"].join(" ")}>
                            {fideicomitente.nombre}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          }

          button1 = (
            <div className={["col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 sin_padding"].join(" ")}>
              <div className={["sin_padding no_borders", classes.card_header2].join(" ")} id="headingOne">
                <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                  <a href={urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                    <div className="">
                      <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo1}</span>
                      <br />
                      <span className={"texto_10 montse semi_bold color_gris"}>
                        {getLan() === "es"
                          ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                          : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                      </span>
                    </div>
                    <div className="">
                      <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                  </a>
                </h2>
              </div>
            </div>
          );

          let mercados = "";
          if (props.detallesTramite.tiposMercado.length > 0) {
            props.detallesTramite.tiposMercado.map((tipoMercado, i) => {
              if (tipoMercado.id) {
                if (i > 0) {
                  mercados = mercados + ", ";
                }
                if (tipoMercado.id === 1) {
                  mercados = getLan() === "es" ? mercados + "Capitales" : mercados + "Equity";
                } else if (tipoMercado.id === 2) {
                  mercados = getLan() === "es" ? mercados + "Deuda" : mercados + "Debt";
                } else {
                  mercados = mercados + "SIC-BIVA";
                }
              }
            });
          }

          body = (
            <React.Fragment>
              <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
                {fideicomitentes}

                <div className={["col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 sin_padding_L"].join(" ")}>
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Mercado" : "Market type"}
                    </span>
                  </div>
                  <div className="col-12 sin_padding">
                    <span className={["texto_16 nunito regular"].join(" ")}>{mercados}</span>
                  </div>
                </div>
                <div className={["col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 sin_padding_L"].join(" ")}>
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Tipo de Documento" : "Document type"}
                    </span>
                  </div>
                  <div className="col-12 sin_padding">
                    <span className={["texto_16 nunito regular"].join(" ")}>
                      {props.detallesTramite.tipoDocumentoListado.nombre}
                    </span>
                  </div>
                </div>
                {button1}
                <div className="col-12 sin_padding_L">
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Descripción" : "Description"}
                    </span>
                  </div>
                  <div className="col-12 sin_padding">
                    <span className={["texto_16 nunito regular"].join(" ")}>
                      {props.descripcion}
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          body = (
            <div className={[classes.encabezado_card6, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <img src={loader} alt="" />
            </div>
          );
        }

        texto_descarga_1 = getLan() === "es" ? "Ver documentos asociados (Suplementos y DICI)" : "See related files";
        let icon = "fa-chevron-down";
        if (this.state.open) {
          icon = "fa-chevron-up";
        }

        bodyButton = (
          <div className={[classes.encabezado_card5].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <div onClick={e => this.togglePanel(e)} className="row cursor">
                <span className={["col-11 sin_padding", "semi_bold montse "].join(" ")}>{texto_descarga_1}</span>
                <div className={["col-1 sin_padding", "semi_bold montse float-right"].join(" ")}>
                  <i className={[`fas ${icon}`, "Boton__icono_derecho__25mg6 float-right"].join(" ")} />
                </div>
              </div>
              {this.state.open ? (
                <React.Fragment>
                  <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
                    <h2 className={["mb-0", classes.titulo].join(" ")}>
                      <div className={[classes.titulo, "row d-none d-sm-none d-lg-flex"].join(" ")}>
                        <span
                          className={[classes.titulo, "col-4", "col color_azul nunito texto_16 semi_bold "].join(
                            " "
                          )}
                        >
                          {getLan() === "es" ? "Tipo de documento" : "Document type"}
                        </span>

                        <span
                          className={[classes.titulo, "col-2", "col color_azul nunito texto_16 semi_bold"].join(
                            " "
                          )}
                        >
                          {getLan() === "es" ? "Fecha" : "Date"}
                        </span>
                        <span
                          className={[classes.titulo, "col-4", "col color_azul nunito texto_16 semi_bold"].join(
                            " "
                          )}
                        >
                          {getLan() === "es" ? "Descripción" : "Description"}
                        </span>
                      </div>
                      <DocumentosProspectos tramiteSeleccionado={props.dato.id} idTramite={props.idTramite} tiposBonosASG={props.tiposBonosASG} />
                    </h2>
                  </div>
                </React.Fragment>
              ) : null}
            </h2>
          </div>
        );
      } else if (this.props.subtipo === 5) {
        // Tramites Listado - Documentos Prospectos(Documentos asociados)
        const fechaPublicacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);
        titulo2 = getLan() === "es" ? "Formato" : "Format";

        button = (
          <div className={["card-header relativo", classes.card_header2, classes.cardDocs].join(" ")} id="headingOne">
            <h2 className={["mb-0 row", classes.titulo].join(" ")}>
              <div className="col-12 col-sm-6 col-lg-4 row ">
                <span className={[classes.titulo, "d-lg-none col-12 sin_padding", "col color_azul nunito texto_16 semi_bold "].join(" ")}>
                  {getLan() === "es" ? "Tipo de documento" : "Document type"}
                </span>
                <span className={[classes.titulo2, "col-12 sin_padding", "texto_16 montse semi_bold"].join(" ")}>
                  {props.tipoAviso}
                </span>
              </div>
              <div className="col-12 col-sm-6 col-lg-2 row ">
                <span className={[classes.titulo, " d-lg-none col-12 sin_padding", "col color_azul nunito texto_16 semi_bold "].join(" ")}>
                  {getLan() === "es" ? "Fecha" : "Date"}
                </span>
                <span className={[classes.titulo2, "col-12 sin_padding", "texto_16 montse regular"].join(" ")}>
                  {fechaPublicacion}
                </span>
              </div>
              <div className="col-12 col-lg-4 row ">
                <span className={[classes.titulo, " d-lg-none col-12 sin_padding", "col color_azul nunito texto_16 semi_bold "].join(" ")}>
                  {getLan() === "es" ? "Descripción" : "Description"}
                </span>
                <span className={[classes.titulo2, "col-12 sin_padding", "texto_16 montse regular"].join(" ")}>
                  {props.descripcion}
                </span>
              </div>

              <div className={["col-12 col-lg-2", classes.contenedor_download].join(" ")}>
                <a
                  href={props.urlDocumento}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={[classes.titulo, "row float-right"].join(" ")}
                >
                  <i
                    className={[classes.icon_download, "fas fa-arrow-alt-circle-down fa-3x", "Boton__icono_derecho__25mg6"].join(
                      " "
                    )}
                  />
                  <span className={[classes.titulo_emisoraPDF, classes.texto_download, "semi_bold montse texto_10 "].join(" ")}>
                    {getLan() === "es"
                      ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                      : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                  </span>
                </a>
              </div>
              <br />
            </h2>
          </div>
        );

        encabezado = null;
      } else if (this.props.subtipo === 6) {
        titulo1 = getLan() === "es" ? "Descargar Documento" : "Download document";
        titulo2 = "Formato";

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_75pct, "col nunito texto_24 bold"].join(" ")}>{props.razonSocial}</span>
          </div>
        );

        button = null;

      } else if (this.props.subtipo === 7) {
        let titulo0 = getLan() === "es" ? "DICI" : "DICI";
        titulo1 = getLan() === "es" ? "Prospecto" : "Prospectus";
        titulo2 = getLan() === "es" ? "Formato" : "Format";
        titulo3 = getLan() === "es" ? "Suplemento" : "Supplement";
        let titulo_boton1 = getLan() === "es" ? "Instrumento" : "Security type";
        let titulo_boton2 = getLan() === "es" ? "Serie" : "Series";
        button = (
          <div className={["card-header", classes.card_header3, classes.padding_card_header].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <button
                className={["btn btn-link collapsed", classes.btn_link].join(" ")}
                type="button"
                data-toggle="collapse"
                data-target={"#" + props.id}
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={this.handleClickBothActionsProspectosInsAlt.bind(this)}
              >
                <div className="row">

                  <div className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 sin_padding_L">
                    <div className="col-12 sin_padding">
                      <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                        {titulo_boton1}
                      </span>
                    </div>
                    <div className="col-12 sin_padding">
                      <span className={[classes.titulo2, "texto_24 montse"].join(" ")}>
                        {props.instrumento}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-8 col-lg-2 col-xl-2 sin_padding_L">
                    <div className="col-12 sin_padding">
                      <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                        {getLan() === "es" ? "Clave" : "Ticker"}
                      </span>
                    </div>
                    <div className="col-12 sin_padding">
                      <span className={[classes.titulo2, "texto_24 montse"].join(" ")}>
                        {props.idEmisora}
                      </span>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                    <div className="col-12 sin_padding">
                      <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                        {titulo_boton2}
                      </span>
                    </div>
                    <div className="col-12 sin_padding">
                      <span className={[classes.titulo2, "texto_24 montse nowrap"].join(" ")}>
                        {props.serie}
                      </span>
                    </div>
                  </div>

                  {props.dici != null && props.dici !== undefined ?
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                      <div className="col-12 sin_padding">
                        <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                          {titulo0}
                        </span>
                      </div>
                      <div className="col-12 sin_padding">
                        <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                          <a href={props.dici.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                            <div className="">
                              {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo0}</span>
                              <br /> */}
                              <span className={"texto_10 montse semi_bold color_gris"}>
                                {getLan() === "es"
                                  ? `Formato ${props.dici.docType === undefined ? "PDF" : props.dici.docType.toUpperCase()}`
                                  : `${props.dici.docType === undefined ? "PDF" : props.dici.docType.toUpperCase()} format`}
                              </span>
                            </div>
                            <div className="">
                              <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                            </div>
                          </a>
                        </h2>
                      </div>
                    </div>
                    : null}

                  {props.prospecto != null && props.prospecto !== undefined ?
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                      <div className="col-12 sin_padding">
                        <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                          {titulo1}
                        </span>
                      </div>
                      <div className="col-12 sin_padding">
                        <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                          <a href={props.prospecto.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                            <div className="">
                              {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo1}</span>
                              <br /> */}
                              <span className={"texto_10 montse semi_bold color_gris"}>
                                {getLan() === "es"
                                  ? `Formato ${props.prospecto.docType === undefined ? "PDF" : props.prospecto.docType.toUpperCase()}`
                                  : `${props.prospecto.docType === undefined ? "PDF" : props.prospecto.docType.toUpperCase()} format`}
                              </span>
                            </div>
                            <div className="">
                              <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                            </div>
                          </a>
                        </h2>
                      </div>
                    </div>
                    : null}

                  {props.suplemento != null && props.suplemento !== undefined ?
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                      <div className="col-12 sin_padding">
                        <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                          {titulo3}
                        </span>
                      </div>
                      <div className="col-12 sin_padding">
                        <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                          <a href={props.suplemento.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                            <div className="">
                              {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo3}</span> */}
                              {/* <br /> */}
                              <span className={"texto_10 montse semi_bold color_gris"}>
                                {getLan() === "es"
                                  ? `Formato ${props.suplemento.docType === undefined ? "PDF" : props.suplemento.docType.toUpperCase()}`
                                  : `${props.suplemento.docType === undefined ? "PDF" : props.suplemento.docType.toUpperCase()} format`}
                              </span>
                            </div>
                            <div className="">
                              <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                            </div>
                          </a>
                        </h2>
                      </div>
                    </div>
                    : null}

                </div>
                <span> </span>
                <i className={["fas fa-chevron-down", classes.accordion_toggle].join(" ")} />

              </button>
            </h2>
          </div>
        );

        if (props.anexos.length > 0) {
          body = (
            <React.Fragment>
              <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>


                <div className={["col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 sin_padding_L"].join(" ")}>
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Tipo de documento" : "Document type"}
                    </span>
                  </div>
                </div>
                <div className={["col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 sin_padding_L"].join(" ")}>
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Descripción" : "Description"}
                    </span>
                  </div>
                </div>
                <div className={["col-12 col-sm-12 col-md-8 col-lg-2 col-xl-2 sin_padding_L"].join(" ")}>
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Documento" : "Document"}
                    </span>
                  </div>
                </div>
              </div>
              {props.anexos ? (
                props.anexos.map((anexo, i) => (
                  <React.Fragment>
                    <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
                      <div className={["col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4 sin_padding_L"].join(" ")}>
                        <div className="col-12 sin_padding">
                          <span className={["texto_16 nunito regular"].join(" ")}>
                            {anexo.tipoDocumento}
                          </span>
                        </div>
                      </div>
                      <div className={["col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 sin_padding_L"].join(" ")}>
                        <div className="col-12 sin_padding">
                          <span className={["texto_16 nunito regular"].join(" ")}>
                            {anexo.descripcion}
                          </span>
                        </div>
                      </div>
                      <div className={["col-12 col-sm-12 col-md-8 col-lg-2 col-xl-2 sin_padding_L"].join(" ")}>
                        <div className="col-12 sin_padding">
                          <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                            <a href={anexo.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                              <div className="">
                                {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo3}</span> */}
                                {/* <br /> */}
                                <span className={"texto_10 montse semi_bold color_gris"}>
                                  {getLan() === "es"
                                    ? `Formato ${anexo.docType === undefined ? "PDF" : anexo.docType.toUpperCase()}`
                                    : `${anexo.docType === undefined ? "PDF" : anexo.docType.toUpperCase()} format`}
                                </span>
                              </div>
                              <br />
                              <div className="">
                                <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                              </div>
                            </a>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))
              )
                : null}
            </React.Fragment >

          );
        } else {
          body = (
            <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <LoaderBar></LoaderBar>
            </div>

          );
          button = null;
          encabezado = (
            <div className={[classes.encabezado_card, classes.tipo4, "texto_24 nunito bold relativo row"].join(" ")}>
              <div className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 sin_padding_L">
                <div className="col-12 sin_padding">
                  <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                    {titulo_boton1}
                  </span>
                </div>
                <div className="col-12 sin_padding">
                  <span className={[classes.titulo2, "texto_24 montse"].join(" ")}>
                    {props.instrumento}
                  </span>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-8 col-lg-2 col-xl-2 sin_padding_L">
                <div className="col-12 sin_padding">
                  <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}
                  </span>
                </div>
                <div className="col-12 sin_padding">
                  <span className={[classes.titulo2, "texto_24 montse"].join(" ")}>
                    {props.idEmisora}
                  </span>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                <div className="col-12 sin_padding">
                  <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                    {titulo_boton2}
                  </span>
                </div>
                <div className="col-12 sin_padding">
                  <span className={[classes.titulo2, "texto_24 montse nowrap"].join(" ")}>
                    {props.serie}
                  </span>
                </div>
              </div>

              {props.dici != null && props.dici !== undefined ?
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {titulo0}
                    </span>
                  </div>
                  <div className="col-12 sin_padding">
                    <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                      <a href={props.dici.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                        <div className="">
                          {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo0}</span>
                                <br /> */}
                          <span className={"texto_10 montse semi_bold color_gris"}>
                            {getLan() === "es"
                              ? `Formato ${props.dici.docType === undefined ? "PDF" : props.dici.docType.toUpperCase()}`
                              : `${props.dici.docType === undefined ? "PDF" : props.dici.docType.toUpperCase()} format`}
                          </span>
                        </div>
                        <div className="">
                          <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                        </div>
                      </a>
                    </h2>
                  </div>
                </div>
                : null}

              {props.prospecto != null && props.prospecto !== undefined ?
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {titulo1}
                    </span>
                  </div>
                  <div className="col-12 sin_padding">
                    <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                      <a href={props.prospecto.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                        <div className="">
                          {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo1}</span>
                                <br /> */}
                          <span className={"texto_10 montse semi_bold color_gris"}>
                            {getLan() === "es"
                              ? `Formato ${props.prospecto.docType === undefined ? "PDF" : props.prospecto.docType.toUpperCase()}`
                              : `${props.prospecto.docType === undefined ? "PDF" : props.prospecto.docType.toUpperCase()} format`}
                          </span>
                        </div>
                        <div className="">
                          <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                        </div>
                      </a>
                    </h2>
                  </div>
                </div>
                : null}

              {props.suplemento != null && props.suplemento !== undefined ?
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
                  <div className="col-12 sin_padding">
                    <span className={["col sin_padding color_azul montse texto_16 semi_bold"].join(" ")}>
                      {titulo3}
                    </span>
                  </div>
                  <div className="col-12 sin_padding">
                    <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                      <a href={props.suplemento.urlDocumento} target="_blank" rel="noopener noreferrer" className="row">
                        <div className="">
                          {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo3}</span> */}
                          {/* <br /> */}
                          <span className={"texto_10 montse semi_bold color_gris"}>
                            {getLan() === "es"
                              ? `Formato ${props.suplemento.docType === undefined ? "PDF" : props.suplemento.docType.toUpperCase()}`
                              : `${props.suplemento.docType === undefined ? "PDF" : props.suplemento.docType.toUpperCase()} format`}
                          </span>
                        </div>
                        <div className="">
                          <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                        </div>
                      </a>
                    </h2>
                  </div>
                </div>
                : null}

            </div >
          );
        }
      }
    } else if (props.tipo === 16) {
      encabezado = (
        <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold relativo "].join(" ")}>
          <span className={[classes.inlineBlock, "relativo"].join(" ")}>{imagen}</span>
          <span className={["texto_30 nunito bold vertical_center", classes.left_110].join(" ")}>
            {props.titulo_encabezado_estatico ? props.titulo_encabezado_estatico : props.titulo_encabezado}
          </span>
        </div>
      );
      body = (
        <div className={["card-body montse texto_14", classes.card_body].join(" ")}>
          <div className={"row relativo zindex1000 "}>
            <div
              className={["card-body montse texto_14", classes.card_body].join(" ")}
              dangerouslySetInnerHTML={{ __html: props.texto }}
            />
            <Fotos imagenes={props.proveedores} icono={"fas fa-plus"} tieneHover={false} tipo={5} />
          </div>
        </div>
      );
    } else if (this.props.tipo === 17) {
      if (props.subtipo === 11) {
        // const fechaSuspension = getRealDateMilliesDayMonthYearTimeZone(props.fechaSuspension);
        // const fechaLevantamiento = getRealDateMilliesDayMonthYearTimeZone(props.fechaLevantamiento);
        //suspensiones
        /*         button = (
                  <div
                    className={["card-header", classes.card_header2].join(" ")}
                    id="headingOne"
                  >
        
        
                    <span className={[classes.titulo_99pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Emisora" : "Corporate name"}
                    </span>
                    <br />
        
                    <span className={[classes.titulo_99pct, "texto_16 nunito regular"].join(" ")}>
                      {props.emisora}
                    </span>
                  </div>
                ); */
        button = null;

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <div className={[classes.titulo_33pct, "col sin_padding_L"].join(" ")}>
              <span className={"color_azul nunito texto_16 semi_bold"}>
                {getLan() === "es" ? "Clave" : "Issuer"}
              </span>
            </div>
            <div className={[classes.titulo_33pct, "col sin_padding_L"].join(" ")}>
              <span className={"color_azul nunito texto_16 semi_bold"}>
                {getLan() === "es" ? "Emisora" : "Corporate name"}
              </span>
            </div>
            <div className={[classes.titulo_33pct, "col sin_padding"].join(" ")}>
              <div className={[classes.div_estatus, "float-right"].join(" ")}>
                <span className={"color_azul nunito texto_16 semi_bold"}>
                  {getLan() === "es" ? "Estatus" : "Status"}
                </span>
              </div>
            </div>
            <br />
            <span className={[classes.titulo_33pct, "texto_24 nunito bold"].join(" ")}>{props.clave}</span>
            <span className={[classes.titulo_33pct, "montse texto_16 regular"].join(" ")}>{props.emisora}</span>
            <div className={[classes.titulo_33pct, "montse texto_16 regular"].join(" ")}>
              <div className={[classes.div_estatus, "float-right"].join(" ")}>
                {props.estatus === "SUSPEND" ? (
                  <span className={"montse texto_16 regular color_rojo"}>{getLan() === "es" ? "Suspendida" : "Suspended"}</span>
                ) : (
                  <span className={"montse texto_16 regular color_verde"}>{getLan() === "es" ? "Activa" : "Active"}</span>
                )}
              </div>
            </div>
          </div>
        );
      }
    } else if (this.props.tipo === 18) {
      //FONDOS y AFORES
      if (props.subtipo === 1) {
        let tituloToggle1 = null;
        let tituloToggle2 = null;
        let tituloToggle3 = null;
        let tituloToggle4 = null;
        let tituloToggle5 = null;
        texto_descarga_2 = getLan() === "es" ? "DICI y Prospecto" : "See related files";
        if (props.fondo) {
          tituloToggle1 = getLan() === "es" ? "Clave de fondo" : "Ticker fund";
          tituloToggle2 = getLan() === "es" ? "Operadora" : "Operator ";
          tituloToggle3 = getLan() === "es" ? "Tipo de fondo" : "Fund type";
          tituloToggle4 = getLan() === "es" ? "Clasificación de fondo" : "Fund classification";
          tituloToggle5 = getLan() === "es" ? "Calificación de fondo" : "Fund grade";
          texto_descarga_1 = getLan() === "es" ? "Operadora" : "Operator ";
        } else {
          tituloToggle1 = getLan() === "es" ? "Clave de SIEFORE" : "SIEFORE";
          tituloToggle2 = getLan() === "es" ? "Clave AFORE" : "AFORE";
          tituloToggle3 = getLan() === "es" ? "Tipo de SIEFORE" : "SIEFORE type";
          tituloToggle4 = getLan() === "es" ? "Clasificación de SIEFORE" : "SIEFORE classification";
          tituloToggle5 = getLan() === "es" ? "Calificación de SIEFORE" : "SIEFORE grade";
          texto_descarga_1 = "AFORE";
        }
        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_75pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {tituloToggle1}
            </span>
            <span className={[classes.titulo_25pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {tituloToggle2}
            </span>
            <br />
            <span className={[classes.titulo_75pct, "col texto_24 nunito bold"].join(" ")}>
              {props.fondo ? props.claveFondo : props.claveSiefore}
            </span>
            <span className={[classes.titulo_25pct, "col nunito texto_24 bold"].join(" ")}>
              {props.fondo ? props.claveOperadora : props.claveAfore}
            </span>
          </div>
        );

        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <button
                className={["btn btn-link collapsed", classes.btn_link].join(" ")}
                type="button"
                data-toggle="collapse"
                data-target={"#" + props.id}
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={this.handleClick.bind(this)}
              >
                <span className={[classes.titulo, "semi_bold montse"].join(" ")}>{props.titulo}</span>
                <span> </span>
                <i className={["fas fa-chevron-down", classes.accordion_toggle].join(" ")} />
              </button>
            </h2>
          </div>
        );

        topButton = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingTwo">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <button
                className={["btn btn-link collapsed", classes.btn_link].join(" ")}
                type="button"
                data-toggle="collapse"
                data-target={"#" + props.id + "2"}
                aria-expanded="true"
                aria-controls="collapseTwo"
                onClick={this.handleClick.bind(this)}
              >
                <span className={[classes.titulo, "semi_bold montse"].join(" ")}>{texto_descarga_2}</span>
                <span> </span>
                <i className={["fas fa-chevron-down", classes.accordion_toggle].join(" ")} />
              </button>
            </h2>
          </div>
        );
        if (props.detallesTramite !== null) {
          let calificacion = null;
          if (props.detallesTramite.calificacion) {
            calificacion = (
              <div className={"col-12 col-sm-12 col-md-6 col-lg-6 sin_padding margin_bottom_10"}>
                <div className={"col-12 sin_padding "}>
                  <span className={[classes.titulo_75pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {tituloToggle5}
                  </span>
                </div>
                <div className={"col-12 sin_padding "}>
                  <span className={[classes.titulo_75pct, "texto_16 nunito regular"].join(" ")}>
                    {props.detallesTramite.calificacion}
                  </span>
                </div>
              </div>
            );
          }

          body = (
            <React.Fragment>
              <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>

                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 sin_padding margin_bottom_10"}>
                  <div className={"col-12 sin_padding "}>
                    <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Razón Social" : "Corporate name"}
                    </span>
                  </div>
                  <div className={"col-12 sin_padding "}>
                    <span className={["texto_16 nunito regular"].join(" ")}>
                      {props.detallesTramite.razonSocial}
                    </span>
                  </div>
                </div>

                <div className={"col-12 col-sm-12 col-md-6 col-lg-6 sin_padding margin_bottom_10"}>
                  <div className={"col-12 sin_padding "}>
                    <span className={[classes.titulo_75pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>
                      {tituloToggle3}
                    </span>
                  </div>
                  <div className={"col-12 sin_padding "}>
                    <span className={[classes.titulo_75pct, "texto_16 nunito regular"].join(" ")}>
                      {props.detallesTramite.tipo}
                    </span>
                  </div>
                </div>

                <div className={"col-12 col-sm-12 col-md-6 col-lg-6 sin_padding margin_bottom_10"}>
                  <div className={"col-12 sin_padding "}>
                    <span className={[classes.titulo_75pct, "color_azul nunito texto_16 semi_bold"].join(" ")}>
                      {tituloToggle4}
                    </span>
                  </div>
                  <div className={"col-12 sin_padding "}>
                    <span className={[classes.titulo_75pct, "texto_16 nunito regular"].join(" ")}>
                      {props.detallesTramite.clasificacion}
                    </span>
                  </div>
                </div>
                {calificacion}

                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 sin_padding margin_bottom_10"}>
                  <div className={"col-12 sin_padding "}>
                    <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>Series</span>
                  </div>
                  <div className={"col-12 sin_padding "}>
                    {props.detallesTramite.series
                      ? props.detallesTramite.series.map(itm => {
                        return (
                          <div className={[classes.contornoSerie, classes.vertical_div].join(" ")}>
                            <span className={["texto_16 nunito regular", classes.serieContenido, classes.vertical].join(" ")}>
                              {itm}
                            </span>
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>
                {bodyButton}
              </div>
            </React.Fragment >
          );
        } else {
          body = (
            <div className={[classes.encabezado_card6, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <img src={loader} alt="" />
            </div>
          );
        }

        let icon = "fa-chevron-down";
        if (this.state.open) {
          icon = "fa-chevron-up";
        }

        if (props.detallesTramite !== null && props.detallesTramite !== undefined) {
          if (props.detallesTramite.emisora !== null && props.detallesTramite.emisora !== undefined) {
            bodyButton = (
              <div className={["card-header", classes.encabezado_card5].join(" ")} id="headingOne">
                <h2 className={["mb-0", classes.titulo].join(" ")}>
                  <div onClick={e => this.togglePanel(e)}>
                    <span className={[classes.titulo_90pct, "semi_bold montse"].join(" ")}>{texto_descarga_1}</span>
                    <span> </span>
                    <div className={[classes.titulo_5pct, "semi_bold montse"].join(" ")}>
                      <i className={[`fas ${icon}`, "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                  </div>
                  {this.state.open ? (
                    <React.Fragment>
                      <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>

                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12  sin_padding margin_bottom_10"}>
                          <div className={"col-12 sin_padding "}>
                            <span className={["color_negro montse texto_16_17 regular"].join(" ")}>
                              {props.detallesTramite.emisora.razonSocial}
                            </span>
                          </div>
                        </div>

                        <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2  sin_padding margin_bottom_10"}>
                          <div className={"col-12 sin_padding "}>
                            <span className={["color_gris montse texto_16_17 semi_bold"].join(" ")}>
                              {getLan() === "es" ? "Dirección" : "Address"}
                            </span>
                          </div>
                        </div>

                        <div className={"col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 sin_padding margin_bottom_10"}>
                          <div className={"col-12 sin_padding "}>
                            <span className={["color_gris texto_16_17 montse regular"].join(" ")}>
                              {props.detallesTramite.emisora.direccion}
                            </span>
                          </div>
                        </div>

                        <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
                          <div className={"col-12 sin_padding "}>
                            <span className={["color_gris montse texto_16 semi_bold"].join(" ")}>
                              {getLan() === "es" ? "Sitio web" : "Website"}
                            </span>



                          </div>
                        </div>

                        <div className={"col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 sin_padding margin_bottom_10"}>
                          <div className={"col-12 sin_padding "}>
                            <a href={props.detallesTramite.emisora.sitioWeb} target="_blank" rel="noopener noreferrer" className="row">
                              <span className={"texto_16 color_gris montse regular"}>{props.detallesTramite.emisora.sitioWeb}</span>
                            </a>
                          </div>
                        </div>

                        <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
                          <div className={"col-12 sin_padding "}>
                            <span className={["color_gris montse texto_16 semi_bold"].join(" ")}>
                              {getLan() === "es" ? "Contacto" : "Contact"}
                            </span>
                          </div>
                        </div>

                        <div className={"col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 sin_padding margin_bottom_10"}>
                          <div className={"col-12 sin_padding "}>
                            <span className={["color_gris texto_16 montse regular"].join(" ")}>
                              {props.detallesTramite.emisora.contacto}
                            </span>
                          </div>
                        </div>
                      </div>
                    </React.Fragment >
                  ) : null
                  }
                </h2 >
              </div >
            );
          } else {
            bodyButton = null;
          }
        }

        titulo1 = getLan() === "es" ? "DICI" : "DICI";
        titulo2 = getLan() === "es" ? "Prospecto" : "Prospecto";

        if (props.documentos !== null) {
          topBody = (
            <React.Fragment>
              <div className={["card-header", classes.card_header2].join(" ")} id="headingTwo">
                {this.props.documentos.length > 0 ? (
                  this.props.documentos.map((doc, i) => (
                    <div className={[classes.titulo_25pct].join(" ")}>
                      <h2 className={["mb-0"].join(" ")}>
                        <a href={getUrlDocument(doc.urlDocumento)} target="_blank" rel="noopener noreferrer" className="row">
                          <div className="sin_padding_L">
                            <span className={[classes.titulo, "texto_16 montse color_azul bold"].join(" ")}>
                              {(doc.tipoDocumentoListado.id === 23 || doc.tipoDocumentoListado.id === 195) ? titulo1 : titulo2}
                            </span>
                            <br />
                            <span className={"texto_10 montse semi_bold color_gris"}>
                              {getLan() === "es"
                                ? `Formato ${doc.docType === undefined ? "PDF" : doc.docType}`
                                : `${doc.docType === undefined ? "PDF" : doc.docType} format`}
                            </span>
                          </div>
                          <div className="">
                            <i
                              className={[
                                "fas fa-arrow-alt-circle-down fa-lg",
                                "Boton__icono_derecho__25mg6",
                                "color_azul"
                              ].join(" ")}
                            />
                          </div>
                        </a>
                      </h2>
                    </div>
                  ))
                ) : (
                  <LoaderBar></LoaderBar>

                )}
              </div>
            </React.Fragment>
          );
        }

      } else if (props.subtipo === 2) {
        // FONDOS AFORES CONCENTRADO DE PRECIOS
        let tituloToggle1 = null;
        if (props.fondo) {
          tituloToggle1 = getLan() === "es" ? "Clave fondo" : "Ticker fund";
        } else {
          tituloToggle1 = getLan() === "es" ? "Clave SIEFORE" : "SIEFORE";
        }
        const fechaPrecio = getRealDateMilliesDayMonthYear(props.fechaPrecio);
        const fechaPublicacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {tituloToggle1}
            </span>
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Fecha de precio" : "Price date"}
            </span>
            <span className={[classes.titulo_33pct, "col color_azul nunito texto_16 semi_bold"].join(" ")}>
              {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
            </span>
            <br />
            <span className={[classes.titulo_33pct, "col texto_24 nunito bold"].join(" ")}>
              {props.fondo ? props.claveFondo : props.claveSiefore}
            </span>
            <span className={[classes.titulo_33pct, "col nunito texto_24 regular color_gris"].join(" ")}>{fechaPrecio}</span>
            <span className={[classes.titulo_33pct, "col nunito texto_24 regular color_gris"].join(" ")}>
              {fechaPublicacion}
            </span>
          </div>
        );

        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <button
                className={["btn btn-link collapsed", classes.btn_link].join(" ")}
                type="button"
                data-toggle="collapse"
                data-target={"#" + props.id}
                aria-expanded="true"
                aria-controls="collapseOne"
                onClick={this.handleClick.bind(this)}
              >
                <span className={[classes.titulo, "semi_bold montse"].join(" ")}>{props.titulo}</span>
                <span> </span>
                <i className={["fas fa-chevron-down", classes.accordion_toggle].join(" ")} />
              </button>
            </h2>
          </div>
        );

        if (props.detallesTramite !== null) {
          body = (
            <React.Fragment>
              <Table tipo={3} datos={this.props.detallesTramite} />
            </React.Fragment>
          );
        } else {
          body = (
            <div className={[classes.encabezado_card6, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
              <img src={loader} alt="" />
            </div>
          );
        }
      } else if (props.subtipo === 3) {
        // FONDOS INFORMACION CORPORATIVA

        let tituloToggle1 = null;
        let tituloToggle2 = null;
        if (props.fondo) {
          tituloToggle1 = getLan() === "es" ? "Operadora" : "Operator";
          tituloToggle2 = getLan() === "es" ? "Fondo" : "Fund ticker";
        } else {
          tituloToggle1 = getLan() === "es" ? "AFORE" : "AFORE";
          tituloToggle2 = getLan() === "es" ? "SIEFORE" : "SIEFORE";
        }
        const fechaPublicacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);
        titulo1 = getLan() === "es" ? `${props.docType === undefined ? "Descargar documento" : props.docType.toUpperCase() === "XBRL" ? "Ver documento" : "Descargar documento"}` : `${props.docType === undefined ? "Descargar documento" : props.docType.toUpperCase() === "XBRL" ? "View document" : "Download document"}`

        let ruta_fondo = props.docType.toUpperCase() === "XBRL" ? props.nombreArchivo : getUrlDocument(props.nombreArchivo, false, true);
        titulo2 = "Formato";

        const archivos = <Download key={uuid()} docType={props.docType ? props.docType : "XBRL"} url={ruta_fondo} fileName={props.fileName} archivos_list={props.dato.archivosXbrl}/>;

        encabezado = (
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {tituloToggle1}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["texto_24 nunito bold"].join(" ")}>
                  {props.fondo ? props.claveOperadora : props.claveOperadora}
                </span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {props.claveFondo ? tituloToggle2 : ""}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["montse texto_24_17 regular"].join(" ")}>
                  {props.fondo ? props.claveFondo : props.claveFondo}
                </span>
              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 row sin_padding margin_bottom_10"}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                </span>
              </div>
              <div className={"col-12 sin_padding"}>
                <span className={["montse texto_16_17 regular"].join(" ")}>{fechaPublicacion}</span>

              </div>
            </div>

            <div className={"col-12 col-sm-12 col-md-6 col-lg-3 offset-lg-0  sin_padding margin_bottom_10"}>
              <h2 className={["mb-0", classes.titulo, classes.divDescarga].join(" ")}>
                {archivos}
              </h2>
            </div>

          </div >
        );

        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <h2 className={["mb-0", classes.titulo].join(" ")}>
              <div className={"col-12 sin_padding margin_bottom_10"}>
                <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold margin_bottom_10"].join(" ")}>
                  {getLan() === "es" ? "Descripción" : "Description"}
                </span>
              </div>
              <span className={["color_negro texto_16_17 montse regular"].join(" ")}>{props.descripcion}</span>
            </h2>
          </div>
        );
      }
    } else if (this.props.tipo === 19) {
      // EVENTOS RELEVANTES
      if (props.docType.toUpperCase() === "XBRL") {
        titulo1 = getLan() === "es" ? "Ver documento" : "View document";
      } else {
        titulo1 = getLan() === "es" ? "Descargar documento" : "Download document";
      }
      titulo2 = "Formato";
      const fechaPublicacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);
      let encabezado1 = null;

      button = null;
      let linkEvento = null;
      const archivos = <Download key={uuid()} docType={props.docType} url={props.nombreArchivo} fileName={props.fileName} archivos_list={props.dato.archivosXbrl}/>;


      if (props.seccion === "emisora") {
        linkEvento = (
          <React.Fragment>
            <NavLink
              to={`/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${props.idEmisora
                }&clave=${props.clave}&canceladas=null&biva=null&page=1`}
            >
              <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
            </NavLink>
          </React.Fragment>
        );
      } else if (props.seccion === "calificadora") {
        linkEvento = (
          <React.Fragment>
            <NavLink
              to={`/empresas/emisoras_inscritas/calificadoras?calificadora=${props.idEmisora}&tab=1&page=1`}
            >
              <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
            </NavLink>
          </React.Fragment>
        );

      } else if (props.seccion === "fondo") {
        linkEvento = (
          <React.Fragment>
            <NavLink
              to={`/empresas/fondos?claveOperadora=&claveFondo=${props.idEmisora
                }&tipoFondo=null&clasificacionFondo=null&page=1`}
            >
              <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
            </NavLink>
          </React.Fragment>

        );
      } else if (props.seccion === "operadora") {
        linkEvento = (
          <React.Fragment>
            <NavLink
              to={`/empresas/fondos?claveFondo=&claveOperadora=${props.idEmisora
                }&tipoFondo=null&clasificacionFondo=null&page=1`}
            >
              <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
            </NavLink>
          </React.Fragment>

        );
      } else if (props.seccion === "siefore") {
        linkEvento = (
          <React.Fragment>
            <NavLink
              to={`/empresas/afore?claveAfore=&claveSiefore=${props.idEmisora
                }&tipoSiefore=null&clasificacionSiefore=null&page=1`}
            >
              <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
            </NavLink>
          </React.Fragment>

        );
      } else if (props.seccion === "afore") {
        linkEvento = (
          <React.Fragment>
            <NavLink
              to={`/empresas/afores?claveSiefore=&claveAfore=${props.idEmisora
                }&tipoSiefore=null&clasificacionSiefore=null&page=1`}
            >
              <span className={["texto_24_17 nunito bold"].join(" ")}>{props.clave}</span>
            </NavLink>
          </React.Fragment>

        );
      } else {
        linkEvento = (
          <div className={"col-12 sin_padding"}>
            <span className={["texto_24_17 nunito bold"].join(" ")}>
              {props.clave}
            </span>
          </div>
        );
      }
      encabezado1 = (
        <div
          className={[classes.encabezado_card2, classes.tipo4, "row"].join(" ")}
        >
          <div className={"col-12 col-sm-12 col-md-7 col-lg-6 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Clave" : "Ticker"}
              </span>
            </div>
            <div className={"col-12 sin_padding "}>
              {linkEvento}
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-5 col-lg-3 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Fecha de Publicación" : "Publication date"}
              </span>
            </div>
            <div className={"col-12 sin_padding"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {fechaPublicacion}
              </span>
            </div>
          </div>

          <div className={"col-12 col-sm-12 col-md-5 col-lg-3 offset-md-7 offset-lg-0  sin_padding margin_bottom_10"}>
            {archivos}
          </div>

          <br />
        </div>
      );

      body = (
        <div className={[classes.encabezado_card, classes.tipo4].join(" ")}>
          <div className={"col-12 sin_padding margin_bottom_10"}>
            <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold margin_bottom_10"].join(" ")}>
              {getLan() === "es" ? "Descripción" : "Description"}
            </span>
          </div>
          <span className={["texto_16_17 montse regular"].join(" ")}>{props.tipoDocumento}</span>
        </div>
      );


      encabezado = (
        <Auxs>
          {encabezado1}
          {body}
        </Auxs>
      );
    } else if (this.props.tipo === 20) {

      //Analista independiente
      encabezado = (
        <>
          <div className={[classes.encabezado_card2, classes.tipo4, "texto_24 nunito bold col-12 row"].join(" ")}>
            <div className={"col-12 sin_padding "}>
              <span className={["color_azul nunito texto_16 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Razón social" : "Corporate name"}
              </span>
            </div>
            <div className={"col-12 sin_padding "}>
              <span className={["montse texto_16 semi_bold"].join(" ")}>
                {props.razonSocial}
              </span>
            </div>
          </div>
        </>
      );

      button = (
        <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
          <h2 className={["col-12 sin_padding", classes.titulo].join(" ")}>
            <button
              className={["btn btn-link collapsed sin_padding", classes.btn_link].join(" ")}
              type="button"
              data-toggle="collapse"
              data-target={"#" + props.id}
              aria-expanded="true"
              aria-controls="collapseOne"
              onClick={this.handleClick.bind(this)}
            >
              <span className={[classes.titulo, "semi_bold montse"].join(" ")}>{props.titulo}</span>
              <span> </span>
              <i className={["fas fa-chevron-down", classes.accordion_toggle, classes.accordion_toggle2].join(" ")} />
            </button>
          </h2>
        </div>
      );
      if (props.detallesTramite !== null && props.detallesTramite.length === 0) {
        body = (
          <div className={[classes.encabezado_card6, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <span className={["texto_16_17 montse regular"].join(" ")}>{getLan() === "es" ? "Actualmente no hay emisoras que cuenten con cobertura del analista." : "There are currently no issuers with analyst coverage."}</span>
          </div>
        );
      } else if (props.detallesTramite !== null) {
        let cobertura = null;
        cobertura = props.detallesTramite.map((d, i) => {

          let descargaDoc = null;
          let periodo = null;
          let fecha = null;
          if (d.fechaPublicacion) {
            fecha = (
              <div className={["col-12 col-sm-6 col-lg-3 sin_padding"]}>
                <div className={"col-12 "}>
                  <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold nowrap"].join(" ")}>
                    {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                  </span>
                </div>
                <div className={"col-12 "}>
                  <span className={["texto_16 montse"].join(" ")}>
                    {getRealDateMilliesDayMonthYear(d.fechaPublicacion)}
                  </span>
                </div>
              </div>
            )
          }
          if (d.periodo) {
            periodo = (
              <div className={["col-12 col-sm-6 col-lg-2 sin_padding"]}>
                <div className={"col-12 "}>
                  <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Periodo" : "Period"}
                  </span>
                </div>
                <div className={"col-12 "}>
                  <span className={["texto_16 montse"].join(" ")}>
                    {d.periodo}
                  </span>
                </div>
              </div>
            )
          }
          if (d.urlDocumento) {
            descargaDoc = (
              <div className={["col-12 col-sm-6 col-lg-3", classes.rowDownload].join(" ")}>

                <a href={getUrlDocument(d.urlDocumento)} target="_blank" rel="noopener noreferrer" className="row">
                  <div className={["sin_padding_L", classes.width_80].join(" ")}>
                    <span className={[classes.titulo, "texto_16 nunito color_azul bold"].join(" ")}>
                      {getLan() === "es" ? "Reporte" : "Report"}
                    </span>
                    <br />
                    <span className={"texto_10 montse semi_bold color_gris"}>
                      {getLan() === "es"
                        ? `Formato ${d.docType === undefined ? "PDF" : d.docType}`
                        : `${d.docType === undefined ? "PDF" : d.docType} format`}
                    </span>
                  </div>
                  <div className={classes.arrow_type_20}>
                    <i
                      className={[
                        "fas fa-arrow-alt-circle-down fa-lg",
                        "Boton__icono_derecho__25mg6",
                        "color_azul"
                      ].join(" ")}
                    />
                  </div>
                </a>
              </div>
            )
          }

          let row = (
            <div className={["row col-12", classes.rowDetail].join(" ")}>
              <div className={["col-12 col-sm-6 col-lg-4 sin_padding"]}>
                <div className={"col-12 "}>
                  <span className={["col sin_padding_L color_azul nunito texto_16 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}
                  </span>
                </div>
                <div className={"col-12 "}>
                  <span className={["texto_24 nunito bold"].join(" ")}>
                    {/* {d.claveEmisora}
                     */}
                    <NavLink
                      to={`/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${d.idEmisora
                        }&clave=${d.claveEmisora}&canceladas=null&biva=null&page=1`}
                    >
                      <span className={["texto_24_17 nunito bold"].join(" ")}>{d.claveEmisora}</span>
                    </NavLink>
                  </span>
                </div>
              </div>

              {fecha}
              {periodo}
              {descargaDoc}

            </div>
          )
          return row;
        })

        body = (
          <React.Fragment>
            <div>
              {cobertura}
            </div>
          </React.Fragment >
        );
      } else {
        body = (
          <div className={[classes.encabezado_card6, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
            <img src={loader} alt="" />
          </div>
        );
      }

      let icon = "fa-chevron-down";
      if (this.state.open) {
        icon = "fa-chevron-up";
      }

      if (props.detallesTramite !== null && props.detallesTramite !== undefined) {
        if (props.detallesTramite.emisora !== null && props.detallesTramite.emisora !== undefined) {
          bodyButton = (
            <div className={["card-header", classes.encabezado_card5].join(" ")} id="headingOne">
              <h2 className={["mb-0", classes.titulo].join(" ")}>
                <div onClick={e => this.togglePanel(e)}>
                  <span className={[classes.titulo_90pct, "semi_bold montse"].join(" ")}>{texto_descarga_1}</span>
                  <span> </span>
                  <div className={[classes.titulo_5pct, "semi_bold montse"].join(" ")}>
                    <i className={[`fas ${icon}`, "Boton__icono_derecho__25mg6"].join(" ")} />
                  </div>
                </div>
                {this.state.open ? (
                  <React.Fragment>
                    <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>

                      <div className={"col-12 col-sm-12 col-md-12 col-lg-12  sin_padding margin_bottom_10"}>
                        <div className={"col-12 sin_padding "}>
                          <span className={["color_negro montse texto_16_17 regular"].join(" ")}>
                            {props.detallesTramite.emisora.razonSocial}
                          </span>
                        </div>
                      </div>

                      <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2  sin_padding margin_bottom_10"}>
                        <div className={"col-12 sin_padding "}>
                          <span className={["color_gris montse texto_16_17 semi_bold"].join(" ")}>
                            {getLan() === "es" ? "Dirección" : "Address"}
                          </span>
                        </div>
                      </div>

                      <div className={"col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 sin_padding margin_bottom_10"}>
                        <div className={"col-12 sin_padding "}>
                          <span className={["color_gris texto_16_17 montse regular"].join(" ")}>
                            {props.detallesTramite.emisora.direccion}
                          </span>
                        </div>
                      </div>

                      <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
                        <div className={"col-12 sin_padding "}>
                          <span className={["color_gris montse texto_16 semi_bold"].join(" ")}>
                            {getLan() === "es" ? "Sitio web" : "Website"}
                          </span>



                        </div>
                      </div>

                      <div className={"col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 sin_padding margin_bottom_10"}>
                        <div className={"col-12 sin_padding "}>
                          <a href={props.detallesTramite.emisora.sitioWeb} target="_blank" rel="noopener noreferrer" className="row">
                            <span className={"texto_16 color_gris montse regular"}>{props.detallesTramite.emisora.sitioWeb}</span>
                          </a>
                        </div>
                      </div>

                      <div className={"col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 sin_padding margin_bottom_10"}>
                        <div className={"col-12 sin_padding "}>
                          <span className={["color_gris montse texto_16 semi_bold"].join(" ")}>
                            {getLan() === "es" ? "Contacto" : "Contact"}
                          </span>
                        </div>
                      </div>

                      <div className={"col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 sin_padding margin_bottom_10"}>
                        <div className={"col-12 sin_padding "}>
                          <span className={["color_gris texto_16 montse regular"].join(" ")}>
                            {props.detallesTramite.emisora.contacto}
                          </span>
                        </div>
                      </div>
                    </div>
                  </React.Fragment >
                ) : null
                }
              </h2 >
            </div >
          );
        } else {
          bodyButton = null;
        }
      }

      titulo1 = getLan() === "es" ? "DICI" : "DICI";
      titulo2 = getLan() === "es" ? "Prospecto" : "Prospecto";

      if (props.documentos !== null) {
        topBody = (
          <React.Fragment>
            <div className={["card-header", classes.card_header2].join(" ")} id="headingTwo">
              {this.props.documentos.length > 0 ? (
                this.props.documentos.map((doc, i) => (
                  <div className={[classes.titulo_25pct].join(" ")}>
                    <h2 className={["mb-0"].join(" ")}>
                      <a href={getUrlDocument(doc.urlDocumento)} target="_blank" rel="noopener noreferrer" className="row">
                        <div className="sin_padding_L">
                          <span className={[classes.titulo, "texto_16 montse color_azul bold"].join(" ")}>
                            {(doc.tipoDocumentoListado.id === 23 || doc.tipoDocumentoListado.id === 195) ? titulo1 : titulo2}
                          </span>
                          <br />
                          <span className={"texto_10 montse semi_bold color_gris"}>
                            {getLan() === "es"
                              ? `Formato ${doc.docType === undefined ? "PDF" : doc.docType}`
                              : `${doc.docType === undefined ? "PDF" : doc.docType} format`}
                          </span>
                        </div>
                        <div className="">
                          <i
                            className={[
                              "fas fa-arrow-alt-circle-down fa-lg",
                              "Boton__icono_derecho__25mg6",
                              "color_azul"
                            ].join(" ")}
                          />
                        </div>
                      </a>
                    </h2>
                  </div>
                ))
              ) : (
                <LoaderBar></LoaderBar>

              )}
            </div>
          </React.Fragment>
        );
      }

    } else if (this.props.tipo === 21) {
      //INSTRUMENTOS ALTERNATIVOS
      encabezado = (
        <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
              <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Clave" : "Ticker"}
              </span>
            </div>
            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
              <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                {props.clave}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
              <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Tipo de instrumento" : "Instrument type"}
              </span>
            </div>
            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {props.tipoInstrumento}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
              <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Fiduciario" : "Trustee"}
              </span>
            </div>
            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {props.fiduciario}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
              <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Número de fideicomiso" : "Trust number"}
              </span>
            </div>
            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {props.numeroFideicomiso}
              </span>
            </div>
          </div>
          {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ?
            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
              <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Llamadas de capital" : "Capital call"}
                </span>
              </div>
              <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {props.isLlamadaCapital === true ? getLan() === "es" ? "Sí" : "True" :
                    getLan() === "es" ? "No" : "False"}
                </span>
              </div>
            </div>
            : null}
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
              <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ? getLan() === "es" ? "Sectores de inversión" : "Investment sectors" : getLan() === "es" ? "Tipo de inmueble" : "Property type"}
              </span>
            </div>
            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {props.sectoresInversion}
              </span>
            </div>
          </div>
          {props.sectorPreponderante ?
            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
              <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Sector preponderante" : "Preponderant sector"}
                </span>
              </div>
              <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {props.sectorPreponderante}
                </span>
              </div>
            </div> : null}
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
              <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Bolsa" : "Exchange"}
              </span>
            </div>
            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {props.bolsa}
              </span>
            </div>
          </div>
          {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ?
            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
              <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Plazo" : "Term"}
                </span>
              </div>
              <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {props.plazo} {getLan() === "es" ? " años" : " years"}
                </span>
              </div>
            </div>
            : null}
          {props.representanteComun ?
            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
              <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Representante común" : "Common representative"}
                </span>
              </div>
              <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                <span className={["texto_16_17 montse regular"].join(" ")}>
                  {props.representanteComun}
                </span>
              </div>
            </div>
            : null}
        </div>
      );
      button = null;
      if (props.subtipo === 1) {
        if (props.fideicomitente !== undefined && props.fideicomitente.length > 0) {
          encabezado = [];
          props.fideicomitente.forEach((fideicomitente, index) => {
            if (fideicomitente.fideicomitente !== undefined || fideicomitente.sitioWeb !== undefined || fideicomitente.personasRelevantes !== undefined) {
              encabezado.push(
                <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row margin_top_20"].join(" ")}>
                  {fideicomitente.fideicomitente ?
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                      <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                        <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                          {getLan() === "es" ? "Fideicomitente o Administrador" : "Trustor / Administrator"}
                        </span>
                      </div>
                      <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                        <span className={["texto_16_17 montse regular"].join(" ")}>
                          {fideicomitente.fideicomitente}
                        </span>
                      </div>
                    </div>
                    : null}
                  {props.grupoPerteneciente ?
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                      <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                        <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                          {getLan() === "es" ? "Grupo al que pertenece el administrador" : "Company group to which the administrator belongs"}
                        </span>
                      </div>
                      <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                        <span className={["texto_16_17 montse regular"].join(" ")}>
                          {props.grupoPerteneciente}
                        </span>
                      </div>
                    </div>
                    : null}
                  {fideicomitente.sitioWeb ?
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                      <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                        <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                          {getLan() === "es" ? "Sitio Web" : "Website"}
                        </span>
                      </div>
                      <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                        <span className={["texto_16_17 montse regular"].join(" ")}>
                          <a href={fideicomitente.sitioWeb} target="_blank" rel="noopener noreferrer" className="row">
                            <span className={[classes.titulo, "texto_16 montse color_azul regular"].join(" ")}>{fideicomitente.sitioWeb}</span>
                          </a>
                        </span>
                      </div>
                    </div>
                    : null}
  
                  {fideicomitente.personasRelevantes ?
                    <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                      <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                        <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                          {getLan() === "es" ? "Personas relevantes" : "Relevant persons"}
                        </span>
                      </div>
                      {fideicomitente.personasRelevantes.map((dato, i) => (
                        <div className={[i > 0 ? "col-6 col-sm-6 col-md-7 col-lg-9 offset-6 offset-md-5 offset-lg-3" : "col-6 col-sm-6 col-md-7 col-lg-9"]}>
                          <span className={["texto_16_17 montse regular"].join(" ")}>
                            {dato}
                          </span>
                        </div>
                      ))}
                    </div>
                    : null}
                </div>
              );
            }
          });
        } else {
          encabezado = null;
        }
      } else if (props.subtipo === 2) {
        if (props.emisiones) {
          button = null;
          encabezado = (
            <div>
              <div>
                {props.emisiones.map((dato, i) => (
                  <React.Fragment>
                    <div className={[classes.encabezado_card2, classes.radius6, classes.tipo4, "texto_24 nunito bold row margin_top_20"].join(" ")}>
                      <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                        <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                          <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                            {getLan() === "es" ? "Serie" : "Series"}
                          </span>
                        </div>
                        <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                          <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                            {dato.serie}
                          </span>
                        </div>
                      </div>
                      <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                        <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                          <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                            {getLan() === "es" ? "ISIN" : "ISIN"}
                          </span>
                        </div>
                        <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                          <span className={["texto_16_17 montse regular"].join(" ")}>
                            {dato.isin}
                          </span>
                        </div>
                      </div>
                      {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ?
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                          <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                            <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                              {props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ?
                                getLan() === "es" ? "Monto máximo en moneda de origen" : "Maximum amount in original currency"
                                : getLan() === "es" ? "Monto máximo" : "Maximum amount"}
                            </span>
                          </div>
                          <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                            <span className={["texto_16_17 montse regular"].join(" ")}>
                              $ {formatDecimal(dato.montoMaximo)} {dato.moneda}
                            </span>
                          </div>
                        </div>
                        : null}
                      {(props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI") && dato.moneda === "USD" ?
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                          <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                            <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                              {getLan() === "es" ? "Monto máximo en MXN" : "Maximum amount in MXN"}
                            </span>
                          </div>
                          <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                            <span className={["texto_16_17 montse regular"].join(" ")}>
                              $ {formatDecimal(dato.montoMaximoMXN)}
                            </span>
                          </div>
                        </div>
                        : null}
                      {props.tipoInstrumento === "Fibra" || props.tipoInstrumento === "Fibra E" ?
                        <Fragment>
                          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                              <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                                {getLan() === "es" ? "Títulos autorizados" : "Authorized securities"}
                              </span>
                            </div>
                            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                              <span className={["texto_16_17 montse regular"].join(" ")}>
                                {formatNumber(dato.titulosAutorizados)}
                              </span>
                            </div>
                          </div>

                          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                              <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                                {getLan() === "es" ? "Títulos en circulación" : "Outsanding securities"}
                              </span>
                            </div>
                            <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                              <span className={["texto_16_17 montse regular"].join(" ")}>
                                {formatNumber(dato.titulosEnCirculacion)}
                              </span>
                            </div>
                          </div>
                        </Fragment>
                        : null
                      }
                      {dato.tickerBloomberg ?
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                          <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                            <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                              {getLan() === "es" ? "Ticker en Bloomberg" : "Bloomberg ticker"}
                            </span>
                          </div>
                          <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                            <span className={["texto_16_17 montse regular"].join(" ")}>
                              {dato.tickerBloomberg}
                            </span>
                          </div>
                        </div>
                        : null}
                      {dato.tickersReuters && dato.tickersReuters.length > 0 ?
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                          <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                            <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                              {getLan() === "es" ? "Ticker en Refinitiv" : "Refinitiv ticker"}
                            </span>
                          </div>
                          <div className={"col-6 col-sm-6 col-md-7 col-lg-9 row"}>
                            {dato.tickersReuters.map(ticker => {
                              return (
                                <span className={["col-12 sin_padding texto_16_17 montse regular margin_top_5"].join(" ")}>
                                  {ticker}
                                </span>
                              )
                            })}

                          </div>
                        </div>
                        : null}
                      {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ?
                        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                          <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                            <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                              {getLan() === "es" ? "Serie adicional u optativa" : "Additional or optional series"}
                            </span>
                          </div>
                          <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                            <span className={["texto_16_17 montse regular"].join(" ")}>
                              {dato.isSerieAdicional === true ? getLan() === "es" ? "Sí" : "True" : getLan() === "es" ? "No" : "False"}
                            </span>
                          </div>
                        </div>
                        : null}
                    </div>

                    <div className={[classes.scroll_horizontal].join(" ")} onScroll={this.handleScrollColocaciones}>
                      <div className={["row"].join(" ")}>
                        <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row margin_top_20"].join(" ")}>
                          {/* <div className={["col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"].join(" ")}> */}
                          <div className={["sin_padding margin_bottom_10", classes.rowTable].join(" ")}>
                            <div className={["text-left", classes.col200pxR].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {getLan() === "es" ? "Tipo de colocación" : "Issuance type"}
                              </span>
                            </div>
                            {/* {props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ? */}
                            <div className={["text-center", classes.col150px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {getLan() === "es" ? "Moneda" : "Currency"}
                              </span>
                            </div>
                            {/* : null} */}
                            <div className={["text-center", classes.col200pxTitle].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {getLan() === "es" ? "Títulos emitidos" : "Issued securities"}
                              </span>
                            </div>
                            <div className={[classes.col200pxTitle, "text-center"].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ?
                                  getLan() === "es" ? "Monto colocado en moneda de origen" : "Placed amount in original currency"
                                  :
                                  getLan() === "es" ? "Monto colocado" : "Placed amount"
                                }
                              </span>
                            </div>
                            <div className={["text-center", classes.col200pxTitle].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold texto_centro nowrap"].join(" ")}>
                                {getLan() === "es" ? "Fecha de colocación" : "Placement date"}
                              </span>
                            </div>
                            {props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ?
                              <div className={["text-center", classes.col145pxTitle].join(" ")}>
                                <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                  {
                                    getLan() === "es" ? "Monto colocado en MXN" : "Placed amount in MXN"
                                  }
                                </span>
                              </div>
                              : null
                            }
                            {props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ?
                              <div className={["text-center", classes.col200pxTitle].join(" ")}>
                                <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                  {
                                    getLan() === "es" ? "Precio de colocación en moneda de origen" : "Placement price in original currency"
                                  }
                                </span>
                              </div>
                              : null
                            }
                            {props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ?
                              <div className={["text-center", classes.col200pxTitle].join(" ")}>
                                <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                  {
                                    getLan() === "es" ? "Precio de colocación en MXN" : "Placement price in MXN"
                                  }
                                </span>
                              </div>
                              : null
                            }
                          </div>
                        </div>
                        <div className={[classes.scroll_vertical_colocaciones].join(" ")}>
                        {
                          dato.colocaciones ? (
                            dato.colocaciones.map((colocacion, i) => (
                              <React.Fragment>
                                {/* <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row margin_top_20"].join(" ")}>
                              */}
                                <div className={[classes.encabezado_card7, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
                                  <div className={["sin_padding margin_bottom_10", classes.rowTable].join(" ")}>
                                    <div className={["text-left", classes.col200pxR].join(" ")}>
                                      <span className={["texto_16_17 montse regular"].join(" ")}>
                                        {colocacion.tipoColocacion}
                                      </span>
                                    </div>
                                    <div className={["text-center", classes.col150px].join(" ")}>
                                      <span className={["texto_16_17 montse regular"].join(" ")}>
                                        {colocacion.moneda}
                                      </span>
                                    </div>
                                    <div className={["text-right", classes.col183px].join(" ")}>
                                      <span className={["texto_16_17 montse regular"].join(" ")}>
                                        {formatNumber(colocacion.titulosEmitidos)}
                                      </span>
                                    </div>
                                    <div className={["text-right", props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ? classes.col200px : classes.col200px].join(" ")}>
                                      <span className={["texto_16_17 montse regular"].join(" ")}>
                                        {setFormatCurrencyStringTwo(colocacion.montoColocado)}
                                      </span>
                                    </div>
                                    <div className={["text-center", classes.col200px].join(" ")}>
                                      <span className={["texto_16_17 montse regular"].join(" ")}>
                                        {getRealDateMilliesDayMonthYear(colocacion.fechaColocacion)}
                                      </span>
                                    </div>
                                    {props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ?
                                      (
                                        <React.Fragment>
                                          <div className={["text-right", classes.col145px].join(" ")}>
                                            <span className={["texto_16_17 montse regular"].join(" ")}>
                                              {
                                                colocacion.montoColocadoMXN !== null ? "$ " : null
                                              }{formatDecimal(colocacion.montoColocadoMXN)}
                                            </span>
                                          </div>
                                          <div className={["text-right sin_padding", classes.col200px].join(" ")}>
                                            <span className={["texto_16_17 montse regular"].join(" ")}>
                                              {
                                                colocacion.precioMonedaOrigen !== null ? "$ " : null
                                              }
                                              {formatDecimal(colocacion.precioMonedaOrigen)}
                                            </span>
                                          </div>
                                          <div className={["text-right", classes.col200px].join(" ")}>
                                            <span className={["texto_16_17 montse regular"].join(" ")}>
                                              {
                                                colocacion.precioMXN !== null ? "$ " : null
                                              }
                                              {formatDecimal(colocacion.precioMXN)}
                                            </span>
                                          </div>
                                        </React.Fragment>)
                                      : null
                                    }
                                  </div>
                                </div>

                                <br />
                              </React.Fragment>
                            ))
                          ) : null
                        }
                        </div>
                      </div>
                      <div className={[classes.margenes_30_izq_der, props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ? classes.container_scroll_2000 : classes.container_scroll].join(" ")}>
                        <div className={["margin_bottom_blue "].join(" ")}></div>
                      </div>
                      <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, classes.container_scroll, "texto_24 nunito bold row margin_top_20"].join(" ")}>
                        <div className={["sin_padding margin_bottom_10", classes.rowTable].join(" ")}>
                          <div className={classes.col525px}>
                            {/* <div className={props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ? "offset-lg-4 col-3" : "offset-lg-3 col-3"  }> */}
                            <span className={["texto_16_17 montse semi_bold float-right"].join(" ")}>
                              {formatNumber(dato.titulosEmitidosTotal)}
                            </span>
                          </div>
                          <div className={[classes.col200px, classes.col18padding].join(" ")}>
                            <span className={["texto_16_17 montse semi_bold float-right"].join(" ")}>
                              $ {formatDecimal(dato.montoColocadoTotal)}
                            </span>
                          </div>
                          {props.tipoInstrumento === "CKD" || props.tipoInstrumento === "CERPI" ?
                            (<div className={classes.col420px}>
                              <span className={["texto_16_17 montse semi_bold float-right"].join(" ")}>
                                $ {formatDecimal(dato.montoColocadoMXNTotal)}
                              </span>
                            </div>) : null}
                        </div>
                      </div>

                    </div>
                    <div className={["row", classes.margin_note, props.tipoInstrumento !== "Fibra" && props.tipoInstrumento !== "Fibra E" ? "d-none" : null].join(" ")}>
                      <div className={"col-12"}>
                        <span className={["texto_14 montse regular"].join(" ")}>
                          {getLan() === "es" ? " La información de las colocaciones es obtenida de la información revelada por las emisoras a la CNBV." : "The information on the issuances is obtained from the information disclosed by the issuers to the CNBV."}
                        </span>
                      </div>
                    </div>
                    <div className={["row margin_top_20", classes.margin_bottom, classes.margin_note, dato.moneda !== undefined && dato.moneda !== null && dato.moneda.toUpperCase() ===
                      "USD" ? null : "d-none"].join(" ")}>
                      <div className="col-12">
                        <span className={["texto_14 montse regular"].join(" ")}>
                          {getLan() === "es"
                            ? "*Las equivalencias de MXN corresponden a las divulgadas por la emisora en los documentos de la colocación."
                            : "The equivalence in MXN is disclosed by the issuer on the issuance documents."}
                        </span>
                      </div>
                    </div>
                    {dato.notas ?
                    <Fragment>
                      <div className={[classes.encabezado_card2, classes.radius6, classes.tipo4, "texto_24 nunito bold row margin_top_20"].join(" ")}/>
                      <div className={["texto_24 nunito bold row margin_top_20"].join(" ")}>
                        <Fragment>
                          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                            <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                              <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                                {getLan() === "es" ? "Nota:" : "Note:"}
                              </span>
                            </div>
                          </div>
                          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                            <div className={"col-12"}>
                              <span className={["texto_14 montse regular"].join(" ")}>
                                {dato.notas}
                              </span>
                            </div>
                          </div>
                          </Fragment>
                      </div>
                      </Fragment>
                      : null}
                    <div className={[props.emisiones.length > 1 ? null : "d-none", (i + 1) === props.emisiones.length ? "d-none" : null].join(" ")}>
                      <div className={classes.border_card_repeat_bottom}></div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div >
          );
        }
      } else if (props.subtipo === 3) {
        encabezado = (
          (props.valuadorIndependiente || props.auditorExterno ?
            <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
              {props.valuadorIndependiente ?
                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                  <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                    <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Valuador independiente" : "Independent appraiser"}
                    </span>
                  </div>
                  <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                    <span className={["texto_16_17 montse regular"].join(" ")}>
                      {props.valuadorIndependiente}
                    </span>
                  </div>
                </div>
                : null}

              {props.auditorExterno ?
                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                  <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                    <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                      {getLan() === "es" ? "Auditor externo" : "External auditor"}
                    </span>
                  </div>
                  <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                    <span className={["texto_16_17 montse regular"].join(" ")}>
                      {props.auditorExterno}
                    </span>
                  </div>
                </div>
                : null}
            </div>
            : null)
        );

      } else if (props.subtipo === 4) {
        {/* Derechos */ }
        if (props.derechos && props.derechos !== undefined) {
          button = null;
          encabezado = (
            <div>
              <div>
                {
                  <React.Fragment>
                    <div className={classes.bottomAzul}>
                      <div className={"col-12 margin_top_10"}>
                        <span className={["col sin_padding_L color_azul nunito texto_16_17 regular"].join(" ")}>
                          {getLan() === "es" ? "Distribuciones y reembolsos" : "Distributions and redemptions"}
                        </span>
                      </div>
                    </div>
                    <div className={[classes.scroll_horizontal, classes.scroll_vertical_distribuciones].join(" ")}>
                      <div className={["row", classes.container_scroll].join(" ")}>
                        <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row margin_top_20"].join(" ")}>
                          <div className={["sin_padding margin_bottom_10", classes.rowTable].join(" ")}>
                            <div className={["text-center", classes.col70px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {"Serie"}
                              </span>
                            </div>
                            <div className={["text-center", classes.col150px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {"Ex date"}
                              </span>
                            </div>
                            <div className={["text-center", classes.col150px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {getLan() === "es" ? "Registro" : "Record"}
                              </span>
                            </div>
                            <div className={[classes.col150px, "text-center"].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {getLan() === "es" ? "Pago" : "Payment"}
                              </span>
                            </div>
                            <div className={[classes.col235px, "text-center"].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                                {getLan() === "es" ? "Tipo de derecho" : "Corporate action type"}
                              </span>
                            </div>
                            <div className={["text-center", classes.col150px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold texto_centro nowrap"].join(" ")}>
                                {getLan() === "es" ? "Estatus" : "Status"}
                              </span>
                            </div>
                            <div className={["text-center", classes.col100px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold texto_centro nowrap"].join(" ")}>
                                {getLan() === "es" ? "Moneda" : "Currency"}
                              </span>
                            </div>
                            <div className={["text-center", classes.col235px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold texto_centro nowrap"].join(" ")}>
                                {getLan() === "es" ? "Distribución por título" : "Distribution by title"}
                              </span>
                            </div>
                            <div className={["text-center", classes.col150px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold texto_centro nowrap"].join(" ")}>
                                {getLan() === "es" ? "Títulos" : "Titles"}
                              </span>
                            </div>
                            <div className={["text-center", classes.col200px].join(" ")}>
                              <span className={["sin_padding color_azul nunito texto_16_17 semi_bold texto_centro nowrap"].join(" ")}>
                                {getLan() === "es" ? "Monto de la distribución" : "Distribution amount"}
                              </span>
                            </div>

                          </div>
                        </div>
                        {
                          props.derechos ? (
                            props.derechos.map((derecho, i) => {

                              return (

                                <React.Fragment>
                                  {/* <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row margin_top_20"].join(" ")}>
                              */}
                                  <div className={[classes.encabezado_card7, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
                                    <div className={["sin_padding margin_bottom_10", classes.rowTable].join(" ")}>
                                      <div className={["text-center", classes.col70px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {derecho.serie}
                                        </span>
                                      </div>
                                      <div className={["text-center", classes.col150px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {formatDate(derecho.fechaEx)}
                                        </span>
                                      </div>
                                      <div className={["text-center", classes.col150px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {formatDate(derecho.fechaRegistro)}
                                        </span>
                                      </div>
                                      <div className={["text-center", classes.col150px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {formatDate(derecho.fechaPago)}
                                        </span>
                                      </div>
                                      <div className={["text-center", classes.col235px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {(derecho.tipoDerecho)}
                                        </span>
                                      </div>
                                      <div className={["text-center", classes.col150px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {derecho.estatus}
                                        </span>
                                      </div>
                                      <div className={["text-center", classes.col100px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {derecho.moneda}
                                        </span>
                                      </div>
                                      <div className={["text-right", classes.col200px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {derecho.distribucionPorTitulo}
                                        </span>
                                      </div>

                                      <div className={["text-right", classes.col150px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {derecho.titulos ? formatInt(derecho.titulos) : ""}
                                        </span>
                                      </div>

                                      <div className={["text-right", classes.col200px].join(" ")}>
                                        <span className={["texto_16_17 montse regular"].join(" ")}>
                                          {setFormatCurrencyStringThree(derecho.montoDistribucion)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <br />
                                </React.Fragment>
                              )
                            })
                          ) : null
                        }
                      </div>

                    </div>
                  </React.Fragment>
                }
              </div>
            </div >
          );
        }
      }



    } else if (this.props.tipo === 22) {
      // INSTRUMENTOS ALTERNATIVOS REPORTES ASG
      let titulo = getLan() === "es" ? "Reporte" : "Report";
      if (props.subtipo === 1) {
        titulo = getLan() === "es" ? "Valuación" : "Valuation";
      } else if (props.subtipo === 2) {
        titulo = getLan() === "es" ? "Gastos de administración y mantenimiento" : "Administrative Expenses";
      } else if (props.subtipo === 3) {
        titulo = getLan() === "es" ? "Anexo AA" : "Annex AA";
      } else if (props.subtipo === 4) {
        if (props.typePage === "costos") {
          titulo = getLan() === "es" ? "Costos, gastos y comisiones" : "Costs, expenses and fees";
        }
        if (props.typePage === "patrimonio") {
          titulo = getLan() === "es" ? "Patrimonio del fideicomiso" : "Trust's estate";
        }
        if (props.typePage === "desempenio") {
          titulo = getLan() === "es" ? "Desempeño de los valores emitidos" : "Performance of securities issued";
        }
      } else if (props.subtipo === 5) {
        titulo = getLan() === "es" ? "Patrimonio del fideicomiso" : "Trust's estate";
      } else if (props.subtipo === 6) {
        titulo = getLan() === "es" ? "Desempeño de los valores emitidos" : "Performance of securities issued";
      }

      const fechaPublicacion = getRealDateMilliesDayMonthYear(props.fechaPublicacion);
      encabezado = (
        <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>

          <div className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 sin_padding_L">
            <div className={["col-12 sin_padding", classes.minHeightCell].join(" ")}>
              <span className={["col sin_padding color_azul montse texto_14_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Clave" : "Ticker"}
              </span>
            </div>
            <div className="col-12 sin_padding">
              <span className={["texto_14_17 montse"].join(" ")}>
                {props.clave}
              </span>
            </div>
          </div>
          <div className={[props.subtipo ? "col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 sin_padding_L" : "col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 sin_padding_L"]}>
            <div className={["col-12 sin_padding", classes.minHeightCell].join(" ")}>
              <span className={["col sin_padding color_azul montse texto_14_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Número de fideicomiso" : "Trust number"}
              </span>
            </div>
            <div className="col-12 sin_padding">
              <span className={["texto_14_17 montse"].join(" ")}>
                {props.numeroFideicomiso}
              </span>
            </div>
          </div>
          {props.subtipo && props.typePage !== "desempenio" ?
            <div className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 sin_padding_L">
              <div className={["col-12 sin_padding", classes.minHeightCell].join(" ")}>
                <span className={["col sin_padding color_azul montse texto_14_17 semi_bold"].join(" ")}>
                  {getLan() === "es" ? "Trimestre" : "Quarter"}
                </span>
              </div>
              <div className="col-12 sin_padding">
                <span className={["texto_14_17 montse"].join(" ")}>
                  {props.periodo}
                </span>
              </div>
            </div>
            : null}
          <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding_L">
            <div className={["col-12 sin_padding", classes.minHeightCell].join(" ")}>
              <span className={["col sin_padding color_azul montse texto_14_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Ejercicio" : "Year"}
              </span>
            </div>
            <div className="col-12 sin_padding">
              <span className={["texto_14_17 montse"].join(" ")}>
                {props.ejercicio}
              </span>
            </div>
          </div>
          <div className={[props.subtipo ? "col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 sin_padding_L" : "col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 sin_padding_L"]}>
            <div className={["col-12 sin_padding", classes.minHeightCell].join(" ")}>
              <span className={["col sin_padding color_azul montse texto_14_17 semi_bold"].join(" ")}>
                {getLan() === "es" ? "Fecha de publicación" : "Publication date"}
              </span>
            </div>
            <div className="col-12 sin_padding">
              <span className={["texto_14_17 montse"].join(" ")}>
                {fechaPublicacion}
              </span>
            </div>
          </div>
          {props.nombreArchivo != null && props.nombreArchivo !== undefined ?
            <div className={["col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 sin_padding", this.props.typePage === "desempenio" ? "offset-lg-2 offset-xl-2" : null].join(" ")}>
              <div className="col-12 sin_padding">
                <span className={["col sin_padding color_azul montse texto_14_17 semi_bold"].join(" ")}>
                  {titulo}
                </span>
              </div>
              <div className="col-12 sin_padding">
                <h2 className={["mb-0 nowrap", classes.titulo].join(" ")}>
                  <a href={props.nombreArchivo} target="_blank" rel="noopener noreferrer" className="row">
                    <div className="">
                      {/* <span className={[classes.titulo, "texto_16 montse color_azul"].join(" ")}>{titulo0}</span>
                              <br /> */}
                      <span className={"texto_10 montse semi_bold color_gris"}>
                        {getLan() === "es"
                          ? `Formato ${props.docType === undefined ? "PDF" : props.docType.toUpperCase()}`
                          : `${props.docType === undefined ? "PDF" : props.docType.toUpperCase()} format`}
                      </span>
                    </div>
                    <div className="">
                      <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
                    </div>
                  </a>
                </h2>
              </div>
            </div>
            : null}
        </div>
      );
      button = null;

    } else if (props.tipo === 23) {
      //Instrumentos alternativos/Comite técnico
      encabezado = (
        <div className={[classes.encabezado_card5, classes.tipo4, "texto_24 nunito bold"].join(" ")}>
          <span className={[classes.titulo_75pct, "col-12  color_azul nunito texto_16 semi_bold"].join(" ")}>
            {getLan() === "es" ? "Comité técnico" : "Technical committee"}
          </span>
        </div>
      );
      if (props.integrantes) {
        let icon = "fa-chevron-down";
        if (this.state.open) {
          icon = "fa-chevron-up";
        }
        button = (
          <div className={["card-header", classes.card_header2].join(" ")} id="headingOne">
            <div className="col-12">
              {/* <h2 className={["mb-0 cursor", classes.titulo2].join(" ")} className="cursor"> */}
              <h2 className={["mb-0 cursor", classes.titulo2].join(" ")}>
                <div onClick={e => this.togglePanel(e)}>
                  <span className={["semi_bold montse texto_16"].join(" ")}>{props.titulo}</span>
                  <span> </span>
                  <div className={[classes.titulo_5pct, "semi_bold montse"].join(" ")}>
                    <i className={[`fas ${icon}`, "Boton__icono_derecho__25mg6"].join(" ")} />
                  </div>
                </div>
                {this.state.open ? (
                  <div>
                    {props.integrantes.independientes !== null && props.integrantes.independientes !== undefined && props.integrantes.independientes.length > 0 ?
                      <div className={["card-body montse texto_14", classes.card_body, classes.card_body_2].join(" ")}>
                        <div className={[classes.titulo_100pct, "nunito texto_16 semi_bold margin_top_20 margin_bottom_10"].join(" ")}>
                          {getLan() === "es" ? "Independientes" : "Independent members"}
                        </div>
                        {props.integrantes.independientes.map((integrante, i) => (
                          <React.Fragment>

                            <div className="row">
                              {
                                <React.Fragment>
                                  <div className={["color_azul nunito texto_16 semi_bold row col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4"].join(" ")}>
                                    <span className="color_azul nunito texto_16 semi_bold row col-12">
                                      {integrante.tipoConsejero}
                                    </span>
                                  </div>
                                  <br />
                                  <React.Fragment>
                                    <div className={["montse texto_16 regular row col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 sin_padding_R"].join(" ")}>
                                      <span className=" montse texto_16 regular row col-12 sin_padding_R">
                                        {integrante.nombre}
                                      </span>
                                    </div>
                                  </React.Fragment>
                                </React.Fragment>
                              }
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      : null}
                    {props.integrantes.noIndependientes !== null && props.integrantes.noIndependientes !== undefined && props.integrantes.noIndependientes.length > 0 ?
                      <div className={["card-body montse texto_14", classes.card_body, classes.card_body_2].join(" ")}>
                        <div className={[classes.titulo_100pct, "nunito texto_16 semi_bold margin_top_20 margin_bottom_10"].join(" ")}>

                          {getLan() === "es" ? "No independientes" : "Non-independent members"}
                        </div>
                        {props.integrantes.noIndependientes.map((integrante, i) => (
                          <React.Fragment>

                            <div className="row">
                              {
                                <React.Fragment>
                                  <div className={["color_azul nunito texto_16 semi_bold row col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4"].join(" ")}>
                                    <span className="color_azul nunito texto_16 semi_bold row col-12">
                                      {integrante.tipoConsejero}
                                    </span>
                                  </div>
                                  <br />
                                  <React.Fragment>
                                    <div className={["montse texto_16 regular row col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 sin_padding_R"].join(" ")}>
                                      <span className=" montse texto_16 regular row col-12 sin_padding_R">
                                        {integrante.nombre}
                                      </span>
                                    </div>
                                  </React.Fragment>
                                </React.Fragment>
                              }
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      : null}
                    <div className={["card-body montse texto_14", classes.card_body, classes.card_body_2].join(" ")}>
                      <div className={["row montse texto_16 normal margin_top_50 margin_bottom_10"].join()}>
                        {getLan() === "es" ?
                          "* La integración del comité técnico es obtenida de la información anual reportada por la emisora."
                          : "* The integration of the technical committee is obtained from the annual information reported by the issuer."}
                      </div>
                    </div>
                  </div>
                ) : null}
              </h2>
            </div>
          </div>
        );
      } else {
        button = null;
      }
    } else if (this.props.tipo === 24) { //EMISIONES ASG

      if (props.subtipo === 1) {

        if (props.dato.razonSocial === null
          && props.dato.clave === null
          && props.dato.serie === null
          && props.dato.tipoValor === null
          && props.dato.isin === null
          && props.dato.modoListado === null
          && props.dato.tipoBono === null
          && props.dato.estadoEmision === null) {
          return null;
        }

        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {props.dato.razonSocial ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Razón social" : "Corporate name"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.razonSocial}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.clave ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.clave}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.serie ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {"Serie"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.serie}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.tipoValor ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tipo de valor" : "Value Type"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.tipoValor.nombreCorto} - {props.dato.tipoValor.nombre}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.isin ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "ISIN" : "ISIN"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.isin}
                  </span>
                </div>
              </div>
              : null}
            {/* MODO LISTADO */}
            {props.dato.modoListado ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Modalidad de listado" : "Listing mode"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.modoListado.nombre}
                  </span>
                </div>
              </div>
              : null}
            {/* Tipo de bono */}
            {props.dato.tipoBono ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tipo de bono" : "Bond type"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.tipoBono.desc}
                  </span>
                </div>
              </div>
              : null}
            {/* Estado de la emisión */}
            {props.dato.estadoEmision ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Estado" : "Status"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.estadoEmision}
                  </span>
                </div>
              </div>
              : null}

          </div>
        );
        button = null;
      } else if (props.subtipo === 2) {
        if (props.dato.fechaColocacion === null
          && props.dato.fechaVencimiento === null
          && props.dato.plazoEmision === null
          && props.dato.titulosEmision === null
          && props.dato.montoEmision === null) {
          return null;
        }
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {props.dato.fechaColocacion ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Fecha de colocación" : "Placement date"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse"].join(" ")}>
                    {getRealDateMilliesDayMonthYearLocale(props.dato.fechaColocacion)}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.fechaVencimiento ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Fecha de vencimiento" : "Maturity date"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse"].join(" ")}>
                    {getRealDateMilliesDayMonthYearLocale(props.dato.fechaVencimiento)}
                  </span>
                </div>
              </div>
              : null}

            {props.dato.plazoEmision ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Plazo de la emisión" : "Term"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {formatNumber(props.dato.plazoEmision)} {props.dato.plazoEmision ? getLan() === "es" ? "días" : "days" : null}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.titulosEmision ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Títulos de la emisión" : "Issued securities"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {formatNumber(props.dato.titulosEmision)}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.montoEmision ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Monto de la emisión" : "Placed amount"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {formatDecimal(props.dato.montoEmision)}{" "}{props.dato.moneda}
                  </span>
                </div>
              </div>
              : null}
          </div>
        );
        button = null;
      } else if (props.subtipo === 3) {
        if (props.dato.tipoTasaInteres === null
          && props.dato.tipoRendimiento === null
          && props.dato.tasaReferencia === null
          && props.dato.sobretasa === null) {
          return null;
        }
        // Tasas
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {props.dato.tipoTasaInteres ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tipo de tasa de interés" : "Interest rate"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse"].join(" ")}>
                    {props.dato.tipoTasaInteres}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.tipoRendimiento ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tipo de rendimiento" : "Interest type"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse"].join(" ")}>
                    {props.dato.tipoRendimiento}
                  </span>
                </div>
              </div>
              : null}

            {props.dato.tasaReferencia ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tasa de referencia" : "Reference rate"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse"].join(" ")}>
                    {props.dato.tasaReferencia}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.sobretasa ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Sobretasa" : "Surcharge"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse"].join(" ")}>
                    {props.dato.sobretasa}
                  </span>
                </div>
              </div>
              : null}

          </div>
        );
        button = null;
      } else if (props.subtipo === 4) {
        if ((props.dato.abogados === null || props.dato.abogados === undefined || props.dato.abogados.length === 0)
          && (props.dato.documentos === null || props.dato.documentos === undefined || props.dato.documentos.length === 0)
          && (props.dato.colocadores === null || props.dato.colocadores === undefined || props.dato.colocadores.length === 0)) {
          return null;
        }
        // Tasas
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {/* DESPACHO LEGAL */}
            {props.dato.abogados && props.dato.abogados.length > 0 ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Despacho legal" : "Law firm"}
                  </span>
                </div>
                {props.dato.abogados.map((dato, i) => (
                  <div className={[i > 0 ? "col-6 col-sm-6 col-md-7 col-lg-9 offset-6 offset-md-5 offset-lg-3" : "col-6 col-sm-6 col-md-7 col-lg-9"]}>
                    <span className={["texto_16_17 montse regular"].join(" ")}>
                      {dato.nombre}
                    </span>
                  </div>
                ))}
              </div>
              : null}
            {/* DOCUMENTOS ASG */}
            {props.dato.documentos !== null && props.dato.documentos !== undefined && props.dato.documentos.length > 0 ?
              [this.getDocumentsESGFromType(props.dato.documentos, 1, "Marco de referencia", "Framework"),
              this.getDocumentsESGFromType(props.dato.documentos, 2, "Tercero independiente", "Third party opinion"),
              this.getDocumentsESGFromType(props.dato.documentos, 3, "Segunda opinión (SPO)", "Second-Party Opinion (SPO)"),
              this.getDocumentsESGFromType(props.dato.documentos, 4, "Verificador externo", "External reviews")
              ]
              : null}
            {props.dato.documentos !== null && props.dato.documentos !== undefined && props.dato.documentos.length > 0 ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                  </span>
                </div>
                <div className={["col-6 col-sm-6 col-md-7 col-lg-9"]}>
                  <NavLink
                    to={`/biva-sostenible/reportes-de-sustentabilidad?clave=${props.clave}`}
                  >
                    <span className={["texto_16_17 montse regular color_verde"].join(" ")}>
                      {getLan() === "es" ? "Consulta más documentos ASG" : "More ESG documents"}
                    </span>
                  </NavLink>

                </div>
              </div>
              : null}
            {/* COLOCADORES */}
            {props.dato.colocadores && props.dato.colocadores.length > 0 ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Colocador(es)" : "Placing agent(s)"}
                  </span>
                </div>
                {props.dato.colocadores.map((dato, i) => (
                  <div className={[i > 0 ? "col-6 col-sm-6 col-md-7 col-lg-9 offset-6 offset-md-5 offset-lg-3" : "col-6 col-sm-6 col-md-7 col-lg-9"]}>
                    <span className={["texto_16_17 montse regular"].join(" ")}>
                      {dato.nombre}
                    </span>
                  </div>
                ))}
              </div>
              : null}


          </div>
        );
        button = null;
      } else if (props.subtipo === 5) {
        {/* SIC ETF DETALLE */ }
        if (props.dato.razonSocial === null
          && props.dato.clave === null
          && props.dato.isin === null
          && props.dato.modoListado === null) {
          return null;
        }

        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {props.dato.claveCotizacion ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.claveCotizacion}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.razonSocial ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Razón social" : "Corporate name"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.razonSocial}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.isin ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    ISIN
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.isin}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.mercadoPrincipal ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Mercado Principal" : "Main market"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.mercadoPrincipal}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.monedaMercadoPrincipal ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Moneda" : "Currency"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.monedaMercadoPrincipal}
                  </span>
                </div>
              </div>
              : null}
            {/* MODO LISTADO */}
            {props.dato.modalidadListado ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Modalidad de listado" : "Listing mode"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.modalidadListado}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.paisOrigen ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "País de origen" : "Origin country"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.paisOrigen}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.fechaListado ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Fecha de listado" : "Listing date"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {getRealDateMilliesDayMonthYearLocale(props.dato.fechaListado)}
                  </span>
                </div>
              </div>
              : null}

          </div>
        );
        button = null;
      } else if (props.subtipo === 6) {
        {/* SIC ETF DETALLE 2*/ }
        if (props.dato.administrador === null
          && props.dato.indiceReferencia === null
          && props.dato.claseActivo === null
          && props.dato.enfoqueGeografico === null
          && props.dato.objetivoInversion === null
          && props.dato.objetivoPoliticaInversion === null) {
          return null;
        }

        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {props.dato.administrador ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Admninistrador" : "Admninistrator"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.administrador}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.indiceReferencia ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Índice de Referencia" : "Reference Index"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.indiceReferencia}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.claseActivo ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clase de activo" : "Asset class"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.claseActivo}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.enfoqueGeografico ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Enfoque Geográfico" : "Geo focus"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.enfoqueGeografico}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.objetivoInversion ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Objetivo Inversión del ETF" : "Investment goal"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.objetivoInversion}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.politicaInversionIndice ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Objetivo y política de inversión del índice" : "Index investment policy and goal"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.politicaInversionIndice}
                  </span>
                </div>
              </div>
              : null}
          </div>
        );
        button = null;
      } else if (props.subtipo === 7) {
        {/* ALTERNATIVOS ASG*/ }
        if (props.dato.clave === null
          && props.dato.fiduciario === null
          && props.dato.numeroFideicomiso === null
          && props.dato.serie === null
          && props.dato.tipoValor === null
          && props.dato.isin === null
          && props.dato.modoListado === null
          && props.dato.estadoEmision === null) {
          return null;
        }

        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {props.dato.clave ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Clave" : "Ticker"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.clave}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.fiduciario ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Fiduciario" : "Trustee"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse semi_bold"].join(" ")}>
                    {props.dato.fiduciario}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.numeroFideicomiso ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Número de fideicomiso" : "Trust number"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.numeroFideicomiso}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.serie ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {"Serie"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.serie}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.tipoValor ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Tipo de valor" : "Value Type"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.tipoValor.nombreCorto} - {props.dato.tipoValor.nombre}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.isin ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {"ISIN"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.isin}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.modoListado ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Modalidad de listado" : "Listing mode"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.modoListado.nombre}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.estadoEmision ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Estado" : "Status"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.estadoEmision}
                  </span>
                </div>
              </div>
              : null}

          </div>
        );
        button = null;
      } else {
        if ((props.dato.calificaciones === null || props.dato.calificaciones === undefined || props.dato.calificaciones.length === 0)
          && (props.dato.sobredemanda === null || props.dato.sobredemanda === undefined)
          && (props.dato.destinoRecursos === null || props.dato.destinoRecursos === undefined)
          && (props.dato.kpi === null || props.dato.kpi === undefined)
          && (props.dato.spt === null || props.dato.spt === undefined)) {
          return null;
        }
        encabezado = (
          <div className={[classes.encabezado_card, classes.radius6, classes.tipo4, "texto_24 nunito bold row"].join(" ")}>
            {/* CALIFICADORAS:CALIFICACION */}
            {props.dato.calificaciones && props.dato.calificaciones.length > 0 ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Calificación" : "Rating"}
                  </span>
                </div>
                {props.dato.calificaciones.map((dato, i) => (
                  <div className={[i > 0 ? "col-6 col-sm-6 col-md-7 col-lg-9 offset-6 offset-md-5 offset-lg-3" : "col-6 col-sm-6 col-md-7 col-lg-9"]}>
                    <span className={["texto_16_17 montse regular"].join(" ")}>
                      {dato.claveCalificadora} : {dato.calificacion}
                    </span>
                  </div>
                ))}
              </div>
              : null}
            {props.dato.sobredemanda ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Sobredemanda" : "Sobredemanda"}
                  </span>
                </div>
                <div className={"col-6 col-sm-6 col-md-7 col-lg-9"}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.sobredemanda}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.destinoRecursos ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Destino de los recursos" : "Resources"}
                  </span>
                </div>
                <div className={["col-6 col-sm-6 col-md-7 col-lg-9", classes.scroll_vertical].join(" ")}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.destinoRecursos}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.kpi ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Indicador de desempeño clave" : "Key Performance Indicators (KPI)"}
                  </span>
                </div>
                <div className={["col-6 col-sm-6 col-md-7 col-lg-9", classes.scroll_vertical].join(" ")}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.kpi}
                  </span>
                </div>
              </div>
              : null}
            {props.dato.spt ?
              <div className={"col-12 col-sm-12 col-md-12 col-lg-12 row sin_padding margin_bottom_10"}>
                <div className={"col-6 col-sm-6 col-md-5 col-lg-3"}>
                  <span className={["col sin_padding_L color_azul nunito texto_16_17 semi_bold"].join(" ")}>
                    {getLan() === "es" ? "Objetivos de desempeño de sustentabilidad " : "Sustainability Performance Targets (SPT)"}
                  </span>
                </div>
                <div className={["col-6 col-sm-6 col-md-7 col-lg-9", classes.scroll_vertical].join(" ")}>
                  <span className={["texto_16_17 montse regular"].join(" ")}>
                    {props.dato.spt}
                  </span>
                </div>
              </div>
              : null}
          </div>
        );
        button = null;
      }
    } else if (this.props.tipo === 25) {
      // CUESTIONARIO AMAFORE
      titulo2 = "Formato";
      const fechaPublicacion = getRealDateMilliesDayMonthYearTimeZone(props.fechaPublicacion);
      let encabezado1 = null;
      if (props.docType && props.docType.toUpperCase() === "XBRL") {
        titulo1 = getLan() === "es" ? "Ver documento" : "View document";
      } else {
        titulo1 = getLan() === "es" ? "Descargar documento" : "Download document";
      }

      const archivos = <Download key={uuid()} docType={props.docType ? props.docType : "XBRL"} url={props.url} fileName={props.fileName} archivos_list={props.dato.archivosXbrl}/>;

      button = null;
      encabezado1 = (
        <div
          className={[classes.encabezado_card2, classes.tipo4, "row"].join(" ")}
        >
          <div className={"col-12 col-sm-12 col-md-7 col-lg-2 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Clave" : "Ticker"}
              </span>
            </div>
            <div className={"col-12 sin_padding"}>
              <span className={["texto_24_17 nunito bold"].join(" ")}>
                {/* <NavLink
                     to={`/empresas/emisoras_inscritas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${props.idEmisora
                       }&clave=${props.clave}&canceladas=null&biva=null&page=1`}> */}
                <span className={["texto_24_17 nunito bold"].join(" ")}>{props.claveCotizacion}</span>
                {/* </NavLink> */}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-5 col-lg-3 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Sector AMAFORE" : "AMAFORE sector"}
              </span>
            </div>
            <div className={"col-12 sin_padding"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {props.sector}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-5 col-lg-2 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Ejercicio" : "Year"}
              </span>
            </div>
            <div className={"col-12 sin_padding"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {props.ejercicio}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-5 col-lg-3 row sin_padding margin_bottom_10"}>
            <div className={"col-12 sin_padding margin_bottom_10"}>
              <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold "].join(" ")}>
                {getLan() === "es" ? "Fecha de Publicación" : "Publication date"}
              </span>
            </div>
            <div className={"col-12 sin_padding"}>
              <span className={["texto_16_17 montse regular"].join(" ")}>
                {fechaPublicacion}
              </span>
            </div>
          </div>
          <div className={"col-12 col-sm-12 col-md-5 col-lg-2 offset-md-7 offset-lg-0  sin_padding margin_bottom_10"}>
            {archivos}
          </div>
          <br />
        </div>
      );

      body = (
        <div className={[classes.encabezado_card, classes.tipo4].join(" ")}>
          <div className={"col-12 sin_padding margin_bottom_10"}>
            <span className={["col sin_padding color_azul nunito texto_16_17 semi_bold margin_bottom_10"].join(" ")}>
              {getLan() === "es" ? "Descripción" : "Description"}
            </span>
          </div>
          <span className={["texto_16_17 montse regular"].join(" ")}>{props.descripcion}</span>
        </div>
      );

      encabezado = (
        <Auxs>
          {encabezado1}
          {body}
        </Auxs>
      );

    } else {
      body = (
        <div
          className={["card-body montse texto_14", classes.card_body].join(" ")}
          dangerouslySetInnerHTML={{ __html: props.texto }}
        />
      );
    }

    item = (
      <div className={clase_contenedor_card}>
        {encabezado}
        <div className={["card", classes.card].join(" ")}>
          {topButton}
          <div id={props.id + "2"} className={"collapse"} aria-labelledby="headingTwo" data-parent={"#" + props.nombre_padre}>
            {topBody}
          </div>
        </div>
        <div className={["card", classes.card].join(" ")}>
          {button}
          <div id={props.id} className={"collapse"} aria-labelledby="headingOne" data-parent={"#" + props.nombre_padre}>
            {/* <div
              className={["card-body montse texto_14", classes.card_body].join(
                " "
              )}
              dangerouslySetInnerHTML={{ __html: props.texto }}
            /> */}
            {body}
            {bodyButton}
          </div>
          {bottomButton}
        </div>
      </div>
    );

    return (
      <div className={this.props.isSustentable ? classes.sustentable : null}>
        {item}
        {modal}
      </div>
    );
  }
}

export default Toggle;

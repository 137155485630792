import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import queryString from "query-string";

import FilterCalificadorasEventos from "../../../components/ui/filterCalificadorasEventos/FilterCalificadorasEventos";
import { getLan } from "../../../stores/utils/utilities";

class EventosRelevantes extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    calificadoraSeleccionada: null,
    emisora: null,
    cliente: null,
    fechaInicio: null,
    fechaFin: null,
    filtered: false,
    clave: null
  };

  componentWillReceiveProps(nextProps) {
    let eventosCalificadoras = null;

    if (nextProps.eventosCalificadoras && nextProps.eventosCalificadoras.content) {
      eventosCalificadoras = nextProps.eventosCalificadoras.content;
    }
    let resource = eventosCalificadoras;
    this.onPageChangeHandler(resource, null, 0);
  }
  componentDidUpdate(prevProps) {
    if (this.props.eventosCalificadoras !== prevProps.eventosCalificadoras) {
      this.setState({
        loader: false
      });
      this.props.setLoader(false);
    }
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.eventosCalificadoras;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      let params = {
        calificadoraId: this.props.calificadoraSeleccionada,
        emisora: this.state.emisora ? this.state.emisora : null,
        claveEmisora: this.state.clave ? this.state.clave : null,
        cliente: this.state.cliente ? this.state.cliente : null,
        fechaInicio: this.state.fechaInicio ? this.state.fechaInicio : null,
        fechaFin: this.state.fechaFin ? this.state.fechaFin : null,
        size: this.state.itemsPerPage,
        page: pageNumber,
        reset: false
      };
      this.onSetParams(params);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentDidMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let emisora = paramsString["emisora"] !== undefined && paramsString["emisora"] !== "null" ? paramsString["emisora"] : null;
    let cliente = paramsString["cliente"] !== undefined && paramsString["cliente"] !== "null" ? paramsString["cliente"] === "false" ? false : null : null;
    let fechaInicio =
      paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
    let fechaFin =
      paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    let clave = paramsString["clave"] !== undefined && paramsString["clave"] !== "null" ? paramsString["clave"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;

    this.setState({
        activePage: page > 0 ? page : 1,
        emisora: emisora,
        cliente: cliente,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        clave: clave
      });


    const params = {
      calificadoraId: this.props.calificadoraSeleccionada,
      emisora: emisora,
      cliente: cliente,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      size: this.state.itemsPerPage,
      page: page >= 1 ? page - 1 : 0
    };
    this.props.onFilterEventosCalificadoras(params);
  }

  onSetEmisoraFilter = emisora => {
    this.setState({
      emisora: emisora
    });
  };
  onSetClaveEmisoraFilter = emisora => {
    this.setState({
      clave: emisora
    });
  };

  onSetClienteFilter = cliente => {
    this.setState({
      cliente: cliente
    });
  };

  onSetFechaInicioFilter = fechaInicio => {
    this.setState({
      fechaInicio: fechaInicio
    });
  };

  onSetFechaFinFilter = fechaFin => {
    this.setState({
      fechaFin: fechaFin
    });
  };

  setWasFiltered = wasFiltered => {
    this.setState({
      filtered: wasFiltered,
      loader: true
    });
  };

  onSetParams = ({ emisora, cliente, fechaInicio, fechaFin, claveEmisora, page, reset = false }) => {
    this.setState({
      emisora: emisora,
      cliente: cliente, 
      clave: claveEmisora,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      activePage: page,
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(emisora, cliente, fechaInicio, fechaFin, claveEmisora, page);
    }
  };

  changeHistory(emisora = null, cliente = null, fechaInicio = null, fechaFin = null, claveEmisora = null, page) {
    this.props.history.push(
      `?calificadora=${
        this.props.calificadoraSeleccionada
      }&tab=${2}&emisora=${emisora}&cliente=${cliente}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&clave=${claveEmisora}&page=${
        page ? page : null
      }`
    );

    this.setState({
      loader: true
    });

    let params = {
      calificadoraId: this.props.calificadoraSeleccionada,
      emisora: emisora,
      cliente: cliente,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      size: this.state.itemsPerPage,
      page: page >= 1 ? page - 1 : 0
    };
    this.props.onFilterEventosCalificadoras(params);
  }

  changeHistoryReset() {
    this.props.history.push(
      `?calificadora=${this.props.calificadoraSeleccionada}&tab=${2}&emisora=${null}&cliente=${null}&fechaInicio=${null}&fechaFin=${null}&clave=${null}&page=${1}`
    );
    this.setState({
      loader: true
    });
    let params = {
      calificadoraId: this.props.calificadoraSeleccionada,
      emisora: null,
      cliente: null,
      fechaInicio: null,
      fechaFin: null,
      size: this.state.itemsPerPage,
      page: 0
    };
    this.props.onFilterEventosCalificadoras(params);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let dataToShow = null;
    let eventosCalificadoras =
      this.props.eventosCalificadoras && this.props.eventosCalificadoras.content ? this.props.eventosCalificadoras.content : [];

    if (eventosCalificadoras.length > 0) {
      dataToShow = (
        <Accordion
          datos={eventosCalificadoras}
          nombre="eventosCalificadoras"
          icon={""}
          tipo={13}
          subtipo={1}
          cliente={this.state.cliente}
        />
      );
    } else if (eventosCalificadoras.length === 0) {
      dataToShow = (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
    }

    const numRows = this.props.eventosCalificadoras ? this.props.eventosCalificadoras.totalElements : 0;

    return (
      <React.Fragment>
        {loader}
        <FilterCalificadorasEventos
          calificadora={this.props.calificadoraSeleccionada}
          setEmisoraFilter={emisora => this.onSetEmisoraFilter(emisora)}
          setClaveEmisoraFilter={emisora => this.onSetClaveEmisoraFilter(emisora)}
          setClienteFilter={cliente => this.onSetClienteFilter(cliente)}
          setFechaInicioFilter={fechaInicio => this.onSetFechaInicioFilter(fechaInicio)}
          setFechaFinFilter={fechaFin => this.onSetFechaFinFilter(fechaFin)}
          wasFiltered={wasFiltered => this.setWasFiltered(wasFiltered)}
          setParams={filterParams => this.onSetParams(filterParams)}
        />

        {dataToShow}

        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFilterEventosCalificadoras: params => {
      dispatch(actions.filterEventosCalificadoras(params));
    }
  };
};

const mapStateToProps = state => {
  return {
    eventosCalificadoras: state.emisoras.eventosCalificadoras
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventosRelevantes);

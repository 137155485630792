import React, { Component } from "react";
import classes from "./List.css";

import Auxs from "../../../hoc/auxs/Auxs";
import { FormattedMessage } from "react-intl";

class List extends Component {
  componentDidMount() {
  }

  createItems = () => {
    if (this.props.data) {
      return this.props.data.map((item, i) => {
        let suplentes = null;
        let suplentes_items = null;
        if (item.subsitutes) {
          if (typeof item.subsitutes === "object" && item.subsitutes[0] !== "") {
            suplentes_items = item.subsitutes.map(suplente => {
              let sup = null;
              if (suplente !== null && suplente !== "") {
                sup = <p className={[classes.suplenteInfo, "montse", "texto_16"].join(" ")}>{suplente}</p>
              }
              return sup;
            });
          } 
          if (suplentes_items) {
            suplentes = (
              <Auxs>
                <div className={classes.suplenteContainer}>
                  <p className={[classes.tituloSuplente, "nunito", "texto_16"].join(" ")}>
                  <FormattedMessage id="nosotros.gobiernocorporativo.suplente" defaultMessage=" " />
                  </p>
                  {suplentes_items}
                </div>
              </Auxs>
            );
          }
          
        }

        return (
          <div className={["col-12 col-sm-12 col-md-12 col-lg-6", classes.marginList].join(" ")}>
            <div
              key={i}
              className={["list-group-item ", classes.item_list, item.suplente ? null : classes.paddingBottom].join(" ")}
            >
              <div className="row">
                <div className="col-12 sin_padding_L">
                  <div className={classes.item_container}>
                    <div className="row">
                      <div className="col col-lg-12 sin_padding">
                        <div className={classes.info_container}>
                          <div className={classes.user}>
                            <p className={["nunito", "texto_16 semibold"].join(" ")}>{item.post}</p>
                            <h1 className={["nunito", "texto_24", "negrita"].join(" ")}>{item.name}</h1>
                          </div>
                          <hr className={classes.separator} />
                          {suplentes}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return null;
  };

  render() {
    return <div className="row sin_padding">{this.createItems()}</div>;
  }
}

export default List;

import React, { Component } from "react";
import Auxs from "../../hoc/auxs/Auxs";
import classes from "./Footer.css";
import Cabecera from "./cabecera/Cabecera";
import Contenido from "./contenido/Contenido";
import textura from "../../assets/images/textura.png"

class Footer extends Component {
  
  render() {
    let style = {
      backgroundImage: `url(${textura})`
    };
    return (
      <Auxs>
        <div className={classes.fondo2} style={style}>
        <div className={classes.fondo}>
          <div className={["container"].join(" ")}>
            <Cabecera />
            <div className={[" d-none d-sm-none d-md-none d-lg-block", classes.body].join(" ")}>
            <Contenido />
            </div>
          </div>
        </div>
        </div>
      </Auxs>
    );
  }
}

export default Footer;

import React from "react";
import hojaVerde from "../../../assets/icons/sustentable/hoja-verde-2.png";
import classes from "./LayoutGuia.css";
import ToggleSustentable from "../ui/toggleSustentable/ToggleSustentable";
import Capitulo from "../guia/capitulos/Capitulo";
import Carta from "../../../components/ui/carta/Carta";


export default function LayoutGuia(props) {

  return (
    <>
      <div className="row margen_top_30">
        <div className="margen_top_20 col-12 sin_padding">
          <div
            className={[
              "main_subtitle",
              "nunito",
              "containerTituloSg",
              "sin_padding",
            ].join(" ")}
          >
            <img
              src={hojaVerde}
              alt="bivasg"
              className={["slash", "imgSg"].join(" ")}
            />
            <h1
              className={[
                "tituloSg texto_50 bold montse texto_verde_oscuro",
                classes.textCapitulos,
              ].join(" ")}
            >
              {props.titulo}
            </h1>
          </div>
        </div>
        <div
          className={["margen_top_30 col-12 sin_padding montse texto_16"].join(
            " "
          )}
        >
          <ToggleSustentable
            key={props.mock.length + props.titulo}
            hiddenToggleTitle={props.hiddenToggleTitle}
            data={props.mock}
            colores={props.colores}
            coloresInternos={props.coloresInternos}
            coloresBonos={props.coloresBonos}
          />
          <div className="margin_top_30">
            <Capitulo />
          </div>
          <Carta
              data={[]}
            />
        </div>
      </div>
    </>
  );
}

import React, { Component } from "react";
import classes from "./Modal.css";

import Forms from "./forms/Forms";

class Modal extends Component {


  render() {
    const tiempo = + new Date();

    const showModal = this.props.showModal
      ? [classes.modal, classes.show]
      : [classes.modal, classes.hidden];

      let form = null;

      if (this.props.showModal) {
        form = <Forms key={tiempo} modelo="modelo_1" tipo_mensaje={this.props.tipo_mensaje}  onCloseModal={this.props.onCloseModal} area = {this.props.area}/>
      }

    return (
      <div className={showModal.join(" ")}>
        <div className="row">
          <div className="col text-right">
            <div className={classes.modal_header}>
              <div
                className={classes.close_button_container}
                onClick={this.props.onCloseModal}
              >
                <i className="far fa-times-circle" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={classes.modal_body}>
              {form}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;

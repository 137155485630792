import axios from "axios";
import * as actionTypes from "./actionTypes";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO + "/cursos";
const dominioCheckout = process.env.REACT_APP_DOMINIO + "/checkout";

const setCursos = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CURSOS_SUCCESS,
      cursos: null,
    };
  }
  return {
    type: actionTypes.FETCH_CURSOS_SUCCESS,
    cursos: data,
  };
};

export const getCursos = (params) => {
  const queryParams = StringUtils.jsonToQueryString(params);
  return (dispatch) => {
    axios
      .get(`${dominio}/cursos${queryParams}`)
      .then((response) => {
        dispatch(setCursos(response.data));
      })
      .catch((err) => dispatch(setCursos(err.data)));
  };
};
const setCheckout = (data) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CHECKOUT_CURSOS_SUCCESS,
      checkout: null,
    };
  }
  return {
    type: actionTypes.FETCH_CHECKOUT_CURSOS_SUCCESS,
    checkout: data,
  };
};

export const postCheckout = (params) => {
  return (dispatch) => {
    axios
      .post(`${dominioCheckout}/checkout`, params)
      .then((response) => {
        dispatch(setCheckout(response.data));
      })
      .catch((err) => dispatch(setCheckout(err.data)));
  };
};


export const deletCheckout = () => {
  return (dispatch) => {
        dispatch(setCheckout(null));
  };
}
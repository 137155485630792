import React, { Component } from "react";
import { connect } from "react-redux";

import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";
import Cuadricula from "../../../components/ui/cuadricula/Cuadricula";
import BeneficiosListarse from "./beneficiosListarse/BeneficiosListarse";
import cuadriculaVinculacion from "../../../json/cuadriculaVinculacion.json";
import auditorio_json from "../../../json/auditorio.json";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
function seleccionandoBeneficio(event, beneficio) {
  event.preventDefault();
  this.setState({ beneficioSeleccionado: beneficio });
}

class Listarse extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    beneficioSeleccionado: null,
    toggle: [
      {
        imagen: "piramide.svg",
        icono: "",
        titulo_encabezado: "Fortalece el posicionamiento",
        texto_html:
          "A través del mercado público y la visibilidad que este conlleva, el posicionamiento de la empresa se hace más fuerte."
      },
      {
        imagen: "medalla.svg",
        icono: "",
        titulo_encabezado: "Se obtiene la mejor valuación de la empresa",
        texto_html: "Al tener una mayor liquidez la empresa es premiada por los inversionistas."
      },
      {
        imagen: "graficaExp3.svg",
        icono: "",
        titulo_encabezado: "Acelera el crecimiento de la compañía",
        texto_html:
          "El mercado de valores es una fuente profunda de financiamiento, al acceder a éste la empresa invierte en proyectos y acelera el crecimiento."
      },
      {
        imagen: "graficaArriba.svg",
        icono: "",
        titulo_encabezado: "Fuente profunda de recursos de capital y deuda",
        texto_html:
          "El mercado público está compuesto por diversos inversionistas, lo que hace que el alcance de inversión sea muy amplio."
      },
      {
        imagen: "foco2.svg",
        icono: "",
        titulo_encabezado: "Facilita ventas futuras a los accionistas disminuyendo costos de transacción",
        texto_html:
          "Al cotizar en un mercado de valores los accionistas pueden vender sus acciones fácilmente sin necesidad de incurrir en costos de una venta privada."
      }
    ],
    cuadricula: [
      {
        texto: "360º",
        clase: "titulo",
        icono_url: "vuelta.svg",
        descripcion: "Servicio a las emisoras antes, durante y después de listarse.",
        titulo2: "Grito BIVA",
        descripcion2:
          "Evento a través del cual se busca la mayor proyección posible de la emisora por medio de eventos presenciales, medios de comunicación, plataformas digitales, mercadotecnia y publicidad. ",
        titulo3: "Visibilidad",
        descripcion3:
          "<p>Contamos con un programa en el que integramos la difusión estratégica en medios, evento de listado, a través de redes sociales, foros, analistas y prensa.</p><p>Preparamos eventos para atraer empresas e inversionistas; tenemos el soporte y la disponibilidad del Auditorio BIVA.</p>",
        conocebtnTexto: "",
        tituloAzul: "El evento de listado de una empresa corona una historia de éxito",
        videosJson: [],
        galeriaJson: auditorio_json,
        vinculacionTexto:
          "Ayudamos a las empresas desde un principio a prepararse para participar en el mercado público. Ofrecemos un diagnóstico inicial, guía en el proceso y conexión con los principales participantes de la colocación, generando una experiencia integral al emisor. ",
        vinculacionJson: cuadriculaVinculacion,
        titulo_vinculacion: "Te vinculamos con:"
      },
      {
        texto: "Inteligencia BIVA",
        clase: "titulo",
        icono_url: "inteligenciaBiva.png",
        titulo: "",
        descripcion:
          "Área especializada en el monitoreo del mercado exclusivamente para las emisoras de BIVA. Elabora un reporte que contiene información sobre la industria, valuación, operación, indicadores de liquidez, análisis de tenencia, entre otros.",
        titulo3: "",
        descripcion3: "",
        conocebtnTexto: "",
        tituloAzul: "",
        videosJson: [],
        galeriaJson: auditorio_json,
        vinculacionTexto: "",
        vinculacionJson: "",
        titulo_vinculacion: ""
      },
      {
        texto: "DIV",
        clase: "titulo",
        icono_url: "cartulinaGrafica.svg",
        titulo: "",
        descripcion:
          "Sistema de divulgación de información, plataforma amigable e intuitiva que facilita la carga de documentos. Continente catálogos de fiduciarios, representantes comunes, casas de bolsa, tipos de valor y eventos relevantes. Además, notifica automáticamente el cumplimiento de obligaciones regulatorias.",
        titulo3: "Ingresa al sistema DIV",
        descripcion3: "",
        conocebtnTexto: "Sistema DIV",
        urlBoton: "https://div.biva.mx/div-web/#/login//root/authenticate",
        tituloAzul: "",
        videosJson: [],
        galeriaJson: auditorio_json,
        vinculacionTexto: "",
        vinculacionJson: "",
        titulo_vinculacion: ""
      },
      {
        texto: "BIM",
        clase: "titulo",
        icono_url: "bim.svg",
        titulo: "",
        descripcion:
          "Plataforma que da acceso a la información del mercado en tiempo real. Se puede monitorear la operación y comparar el mercado de BIVA contra otras bolsas. Contiene estadísticas, gráficas, reportes y análisis del mercado, así como información intradía del Índice FTSE BIVA.",
        titulo3: "Conoce los beneficios de BIM",
        descripcion3: "",
        conocebtnTexto: "Beneficios de BIM",
        tituloAzul: "",
        videosJson: [],
        galeriaJson: auditorio_json,
        vinculacionTexto: "",
        vinculacionJson: "",
        titulo_vinculacion: ""
      },
      {
        texto: "Índice FTSE BIVA",
        clase: "titulo",
        icono_url: "ftse.svg",
        titulo: "",
        descripcion:
          "Referencia sobre el mercado mexicano creado en conjunto con FTSE Russell. Está compuesto por empresas de capitalización grande, mediana y pequeña, incluyendo Fibras. No hay un número fijo de participantes y cumple con las mejores prácticas internacionales.",
        titulo3: "Conoce más del Índice FTSE BIVA",
        descripcion3: "",
        conocebtnTexto: "Índice FTSE BIVA",
        tituloAzul: "",
        videosJson: [],
        galeriaJson: [],
        vinculacionTexto: "",
        vinculacionJson: "",
        titulo_vinculacion: ""
      },
      {
        texto: "Cuotas",
        clase: "titulo",
        icono_url: "cuotas.svg",
        titulo: "",
        descripcion: "Ofrecemos tarifas competitivas.",
        titulo3: "Utiliza nuestro simulador de cuotas",
        descripcion3: "",
        conocebtnTexto: "Simulador de cuotas",
        urlBoton: "/empresas/listado_de_valores/como_listarse/cuotas",
        tituloAzul: "",
        videosJson: [],
        galeriaJson: [],
        vinculacionTexto: "",
        vinculacionJson: [],
        titulo_vinculacion: ""
      },
      {
        texto: "Auditorio BIVA",
        clase: "titulo",
        icono_url: "personaGrafica.svg",
        titulo: "",
        descripcion:
          "Te presentamos el Auditorio BIVA, un espacio con la tecnología más avanzada tecnología para llevar a cabo todo tipo de eventos.",
        titulo3: "¿Te interesa?",
        descripcion3: "¿Te interesa?",
        conocebtnTexto: "",
        tituloAzul: "",
        videosJson: [],
        galeriaJson: auditorio_json,
        vinculacionTexto: "",
        vinculacionJson: "",
        titulo_vinculacion: ""
      }
    ]
  };

  regresar = () => {
    this.setState({ beneficioSeleccionado: null });
  };
  componentDidMount() {
    this.props.onFetchVideos();

    this.props.onInitIntl();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let contenidoMostrar = null;
    let beneficios = null;
    if (this.props.toggleBoxBeneficiosListarse) {
      let stringContent = this.props.toggleBoxBeneficiosListarse[0].content.toString();
       beneficios = JSON.parse(stringContent);
    }
    if (this.state.beneficioSeleccionado === null && beneficios !== null) {
      contenidoMostrar = (
        <div className="row relativo margen_top_30">
          <Cuadricula
            datos={beneficios}
            coloreados={[]}
            saltarse={[7]}
            enBlanco={[]}
            quitar={[]}
            modulo={3}
            tipo={4}
            cuadroSeleccion={seleccionandoBeneficio.bind(this)}
          />
        </div>
      );
    } else {
     if (beneficios) {
       contenidoMostrar = (
         <div className="row relativo margen_top_30">
           <BeneficiosListarse datos={this.state.beneficioSeleccionado} onClick={this.regresar.bind(this)} />
         </div>
       );
     }
    }



    let toggle = null;
    if (this.props.toggleBoxPorQueListarse) {
      toggle = <ToggleBox terminos={this.props.toggleBoxPorQueListarse} />;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={"texto_42 nunito regular"}>
            <FormattedHTMLMessage id="empresas.listadodevalores.porquelistarse.titulo1" defaultMessage=" " />
          </h1>
          <div className="margen_top_30">
            {toggle}
          </div>
          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="empresas.listadodevalores.porquelistarse.subtitulotitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
            {contenidoMostrar}
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  let indice = null;
  let videos = null;
  if (state.indice.indice) {
    indice = state.indice.indice;
  }
  if (state.videos.videos_api) {
    videos = state.videos.videos_api.items;
  }
  return {
    diccionario: state.intl.diccionario,
    indice: indice,
    videos: videos,
    toggleBoxPorQueListarse: state.toggleBox.toggleBoxPorQueListarse,
    toggleBoxBeneficiosListarse: state.toggleBox.toggleBoxBeneficiosListarse
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFetchVideos: () => {
      dispatch(action.initVideosAPI(50));
    },
    onInitIntl: () => {
      dispatch(action.initIntl(58));
      dispatch(action.getToggleBoxPorQueListarse());
      dispatch(action.getToggleBoxBeneficiosListarse());
      dispatch(action.getVideosAuditorium());
      dispatch(action.getVideosDIV());
      dispatch(action.initVideoBIM());
      dispatch(action.getImagenesGrito());
      dispatch(action.getImagenesAuditorium());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listarse);

import axios from "axios";
import StringUtils from "../utils/StringUtils";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/instrumento-alternativo`;

const setFilterClavesEmisiones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVESEMISIONES_SUCCESS,
      listadoClaves: []
    };
  }
  return {
    type: actionTypes.FETCH_CLAVESEMISIONES_SUCCESS,
    listadoClaves: data
  };
};

export const filterEmisionesByClave = (clave) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/emisiones/busqueda/${clave}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterClavesEmisiones(response.data));
      })
      .catch(err => dispatch(setFilterClavesEmisiones(err.data)));
  };
};

//INSTRUMENTOS ALTERNATIVOS
export const fetchAllEmisionesByClave = ({ clave, page, size }) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones?clave=${clave}&page=${page}&size=${size}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisiones(response.data));
      })
      .catch(err => dispatch(setEmisiones(err.data)));
  };
};

export const filterEmisiones = ({
  size,
  page,
  instrumento,
  modoListado,
  sector,
  estado,
  empresa
}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones?size=${size}&page=${page}`;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumento=${[...instrumento]}` : url;
  url = modoListado && modoListado.length > 0 ? `${url}&modoListado=${[...modoListado]}` : url;
  url = estado && estado.length > 0 ? `${url}&estatus=${[...estado]}` : url;
  url = sector && sector.length > 0 ? `${url}&sector=${[...sector]}` : url;
  url = empresa ? `${url}&empresa=${empresa}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisiones(response.data));
      })
      .catch(err => dispatch(setEmisiones(err.data)));
  };
};

const setEmisiones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISIONES_SUCCESS,
      emisiones: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISIONES_SUCCESS,
    emisiones: data
  };
};

const setEmisionDetalle = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISIONDETALLE_SUCCESS,
      emisionDetalle: null
    };
  }
  return {
    type: actionTypes.FETCH_EMISIONDETALLE_SUCCESS,
    emisionDetalle: data
  };
};

export const initEmisionDetalle = (empresaId, numeroFideicomiso) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones/ficha/${empresaId}?numeroFideicomiso=${numeroFideicomiso}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisionDetalle(response.data));
      })
      .catch(err => dispatch(setEmisionDetalle(err.data)));
  };
};


const setComite = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_COMITE_TECNICO_SUCCESS,
      comiteTecnico: null
    };
  }
  return {
    type: actionTypes.FETCH_COMITE_TECNICO_SUCCESS,
    comiteTecnico: data
  };
};

export const getComiteTecnico = (empresaId, numeroFideicomiso) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones/ficha/${empresaId}/comite-tecnico?numeroFideicomiso=${numeroFideicomiso}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setComite(response.data));
      })
      .catch(err => dispatch(setComite(err.data)));
  };
};


export const filterTipoInstrumento = () => {
  let url = `${dominio}/emisoras/instrumento-alternativo/emisiones/tipo-instrumento`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoInstrumento(response.data));
      })
      .catch(err => dispatch(setTipoInstrumento(err.data)));
  };
};

const setTipoInstrumento = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPO_INSTRUMENTO_SUCCESS,
      tipoInstrumento: []
    };
  }
  return {
    type: actionTypes.FETCH_TIPO_INSTRUMENTO_SUCCESS,
    tipoInstrumento: data
  };
};

export const filterModoListado = () => {
  let url = `${dominio}/emisoras/instrumento-alternativo/emisiones/modo-listado`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setModoListado(response.data));
      })
      .catch(err => dispatch(setModoListado(err.data)));
  };
};

const setModoListado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MODO_LISTADO_SUCCESS,
      modoListado: []
    };
  }
  return {
    type: actionTypes.FETCH_MODO_LISTADO_SUCCESS,
    modoListado: data
  };
};

export const filterEstado = () => {
  let url = `${dominio}/emisoras/instrumento-alternativo/emisiones/estado`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEstado(response.data));
      })
      .catch(err => dispatch(setEstado(err.data)));
  };
};

const setEstado = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ESTADO_SUCCESS,
      estado: []
    };
  }
  return {
    type: actionTypes.FETCH_ESTADO_SUCCESS,
    estado: data
  };
};

export const filterSectorIA = ({
  instrumento,
  modoListado,
  estado,
}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/emisiones/sector?1=1`;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumento=${[...instrumento]}` : url;
  url = modoListado && modoListado.length > 0 ? `${url}&modoListado=${[...modoListado]}` : url;
  url = estado && estado.length > 0 ? `${url}&estatus=${[...estado]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSector(response.data));
      })
      .catch(err => dispatch(setSector(err.data)));
  };
};

const setSector = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SECTORIA_SUCCESS,
      sector: []
    };
  }
  return {
    type: actionTypes.FETCH_SECTORIA_SUCCESS,
    sector: data
  };
};

//AGENDA

const getClavesAgendaInsAlt = (data = null) => {
  return {
    type: actionTypes.FETCH_CLAVESAGENDAINSALT_SUCCESS,
    listadoClavesAgenda: data
  };
};

export const filterClavesAgendaInsAlt = (clave, nacional, sic) => {
  let url = `${dominio}/emisoras/agendas/busqueda/${clave}?1=1`;

  url =
    nacional
      ? `${url}&nacional=${nacional}`
      : url;
  // url = sic ? `${url}&sic=${sic}` : url;
  url = `${url}&tipoInstrumentoAlternativo=1,2,3,4`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getClavesAgendaInsAlt(response.data));
      })
      .catch(err => dispatch(getClavesAgendaInsAlt()));
  };
};

export const filterModoListadoAgenda = () => {
  let url = `${dominio}/emisoras/agendas/modalidad-listado`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setModoListadoAgenda(response.data));
      })
      .catch(err => dispatch(setModoListadoAgenda(err.data)));
  };
};

const setModoListadoAgenda = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MODO_LISTADO_AGENDA_SUCCESS,
      modoListadoAgenda: []
    };
  }
  return {
    type: actionTypes.FETCH_MODO_LISTADO_AGENDA_SUCCESS,
    modoListadoAgenda: data
  };
};

export const filterTipoInstrumentoAgenda = (tipoDerecho = null) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/emisiones/tipo-instrumento?nacional=true`;
  url = tipoDerecho && tipoDerecho.length > 0 ? `${url}&tipoDerecho=${[...tipoDerecho]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoInstrumentoAgenda(response.data));
      })
      .catch(err => dispatch(setTipoInstrumentoAgenda(err.data)));
  };
};

const setTipoInstrumentoAgenda = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPO_INSTRUMENTO_AGENDA_SUCCESS,
      tipoInstrumentoAgenda: []
    };
  }
  return {
    type: actionTypes.FETCH_TIPO_INSTRUMENTO_AGENDA_SUCCESS,
    tipoInstrumentoAgenda: data
  };
};



const getTipoInstrumentoAgenda = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_TIPOINSTRUMENTOAGENDA_SUCCESS,
      tipos_instrumento: []
    };
  }
  return {
    type: actionTypes.FETCH_TIPOINSTRUMENTOAGENDA_SUCCESS,
    tipos_instrumento: data
  };
};

export const initTipoInstrumentoAgenda = (
  nacional = null,
  sic = null,
  empresa = null,
  clave =null
) => {
  let url = `${dominio}/emisoras/agendas/tipo-instrumento?1=1`;
  if (nacional === null && sic === null) {
    nacional = true;
  }
  url = nacional !== null && nacional === true ? `${url}&nacional=${nacional}` : url;
  url = sic !== null && sic === true ? `${url}&sic=${sic}` : url;
  url = empresa && empresa.length > 0 ? `${url}&tipoDerecho=${empresa}` : url;
  url = clave !== null && clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getTipoInstrumentoAgenda(response.data));
      })
      .catch(err => dispatch(getTipoInstrumentoAgenda(err.data)));
  };
};


// PROSPECTOS

const setProspectosInstrumentosAlternativos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PROSPECTOS_IA_SUCCESS,
      prospectos: []
    };
  }
  return {
    type: actionTypes.FETCH_PROSPECTOS_IA_SUCCESS,
    prospectos: data
  };
};

export const initProspectosInstrumentosAlternativos = ({ size, page, mercado = null, instrumento = null, emisora = null, clave = null }) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/prospectos?size=${size}&page=${page}`;

  url = emisora ? `${url}&idEmisora=${emisora}` : url;
  // url = mercado ? `${url}&idTipoMercado=${mercado}` : url;
  url = instrumento ? `${url}&tipoInstrumento=${instrumento}` : url;
  url = clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setProspectosInstrumentosAlternativos(response.data));
      })
      .catch(err => dispatch(setProspectosInstrumentosAlternativos(err.data)));
  };
};

export const filterTipoInstrumentoProspectos = () => {
  let url = `${dominio}/emisoras/instrumento-alternativo/prospectos/tipos-instrumento`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setTipoInstrumento(response.data));
      })
      .catch(err => dispatch(setTipoInstrumento(err.data)));
  };
};

// export const fetchAllProspectosIAByClave = ({ clave, page, size }) => {
//   let url = `${dominio}/emisoras/instrumento-alternativo/prospectos?clave=${clave}&page=${page}&size=${size}`;

//   return dispatch => {
//     axios
//       .get(url)
//       .then(response => {
//         dispatch(setProspectosInstrumentosAlternativos(response.data));
//       })
//       .catch(err => dispatch(setProspectosInstrumentosAlternativos(err.data)));
//   };
// };

export const filterProspectosByClave = (clave) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/prospectos/claves-cotizacion?clave=${clave}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterClavesProspectos(response.data));
      })
      .catch(err => dispatch(setFilterClavesProspectos(err.data)));
  };
};


const setFilterClavesProspectos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVES_PROSPECTOS_IA_SUCCESS,
      listadoClavesProspectos: []
    };
  }
  return {
    type: actionTypes.FETCH_CLAVES_PROSPECTOS_IA_SUCCESS,
    listadoClavesProspectos: data
  };
};


// REPORTES ASG


const setReportesASG = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_REPORTES_ASG_SUCCESS,
      reportesASG: []
    };
  }
  return {
    type: actionTypes.FETCH_REPORTES_ASG_SUCCESS,
    reportesASG: data
  };
};

export const initReportesASG = ({ size, page, ejercicio = null, instrumento = null, emisora = null, clave = null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/reporte-asg?size=${size}&page=${page}`;

  url = emisora ? `${url}&idEmisora=${emisora}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;
  url = clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setReportesASG(response.data));
      })
      .catch(err => dispatch(setReportesASG(err.data)));
  };
};


export const filterReportesASGByClave = (clave) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/reporte-asg/busqueda/${clave}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterClavesReportesASG(response.data));
      })
      .catch(err => dispatch(setFilterClavesReportesASG(err.data)));
  };
};


const setFilterClavesReportesASG = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVES_REPORTES_ASG_SUCCESS,
      listadoClavesReportesASG: []
    };
  }
  return {
    type: actionTypes.FETCH_CLAVES_REPORTES_ASG_SUCCESS,
    listadoClavesReportesASG: data
  };
};


export const filterEjercicioReportesASG = () => {
  let url = `${dominio}/emisoras/instrumento-alternativo/reporte-asg/ejercicio`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjercicio(response.data));
      })
      .catch(err => dispatch(setEjercicio(err.data)));
  };
};

const setEjercicio = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EJERCICIO_REPORTES_ASG_SUCCESS,
      ejercicio: []
    };
  }
  return {
    type: actionTypes.FETCH_EJERCICIO_REPORTES_ASG_SUCCESS,
    ejercicio: data
  };
};


//GASTOS


const setGastos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_GASTOS_SUCCESS,
      gastos: []
    };
  }
  return {
    type: actionTypes.FETCH_GASTOS_SUCCESS,
    gastos: data
  };
};

export const initGastos = ({ size, page, ejercicio = null, periodo= null, instrumento = null, emisora = null, clave = null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/gastos?size=${size}&page=${page}`;

  url = emisora ? `${url}&empresa=${emisora}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;
  url = clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setGastos(response.data));
      })
      .catch(err => dispatch(setGastos(err.data)));
  };
};


export const filterGastosByClave = (clave) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/gastos/busqueda/${clave}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterClavesGastos(response.data));
      })
      .catch(err => dispatch(setFilterClavesGastos(err.data)));
  };
};


const setFilterClavesGastos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVES_GASTOS_SUCCESS,
      listadoClavesGastos: []
    };
  }
  return {
    type: actionTypes.FETCH_CLAVES_GASTOS_SUCCESS,
    listadoClavesGastos: data
  };
};


export const filterEjercicioGastos = ({ periodo= null,  instrumento= null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/gastos/ejercicio?1=1`;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjercicioGastos(response.data));
      })
      .catch(err => dispatch(setEjercicioGastos(err.data)));
  };
};

const setEjercicioGastos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EJERCICIO_GASTOS_SUCCESS,
      ejercicio: []
    };
  }
  return {
    type: actionTypes.FETCH_EJERCICIO_GASTOS_SUCCESS,
    ejercicio: data
  };
};


export const filterPeriodoGastos = ({ instrumento= null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/gastos/periodo?1=1`;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPeriodoGastos(response.data));
      })
      .catch(err => dispatch(setPeriodoGastos(err.data)));
  };
};

const setPeriodoGastos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PERIODO_GASTOS_SUCCESS,
      periodo: []
    };
  }
  return {
    type: actionTypes.FETCH_PERIODO_GASTOS_SUCCESS,
    periodo: data
  };
};


//ANEXO AA


const setAnexoAA = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ANEXO_AA_SUCCESS,
      anexoaa: []
    };
  }
  return {
    type: actionTypes.FETCH_ANEXO_AA_SUCCESS,
    anexoaa: data
  };
};

export const initAnexoAA = ({ size, page, ejercicio = null, periodo= null, instrumento = null, emisora = null, clave = null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/anexo-aa?size=${size}&page=${page}`;

  url = emisora ? `${url}&empresa=${emisora}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;
  url = clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAnexoAA(response.data));
      })
      .catch(err => dispatch(setAnexoAA(err.data)));
  };
};


export const filterAnexoAAByClave = (clave) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/anexo-aa/busqueda/${clave}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterClavesAnexoAA(response.data));
      })
      .catch(err => dispatch(setFilterClavesAnexoAA(err.data)));
  };
};


const setFilterClavesAnexoAA = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVES_ANEXO_AA_SUCCESS,
      listadoClavesAnexoAA: []
    };
  }
  return {
    type: actionTypes.FETCH_CLAVES_ANEXO_AA_SUCCESS,
    listadoClavesAnexoAA: data
  };
};


export const filterEjercicioAnexoAA = ({ periodo= null,  instrumento= null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/anexo-aa/ejercicio?1=1`;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjercicioAnexoAA(response.data));
      })
      .catch(err => dispatch(setEjercicioAnexoAA(err.data)));
  };
};

const setEjercicioAnexoAA = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EJERCICIO_ANEXO_AA_SUCCESS,
      ejercicio: []
    };
  }
  return {
    type: actionTypes.FETCH_EJERCICIO_ANEXO_AA_SUCCESS,
    ejercicio: data
  };
};


export const filterPeriodoAnexoAA = ({ instrumento= null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/anexo-aa/periodo?1=1`;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPeriodoAnexoAA(response.data));
      })
      .catch(err => dispatch(setPeriodoAnexoAA(err.data)));
  };
};

const setPeriodoAnexoAA = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PERIODO_ANEXO_AA_SUCCESS,
      periodo: []
    };
  }
  return {
    type: actionTypes.FETCH_PERIODO_ANEXO_AA_SUCCESS,
    periodo: data
  };
};


//VALUACIONES


const setValuaciones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VALUACIONES_SUCCESS,
      valuaciones: []
    };
  }
  return {
    type: actionTypes.FETCH_VALUACIONES_SUCCESS,
    valuaciones: data
  };
};

export const initValuaciones = ({ size, page, ejercicio = null, periodo= null, instrumento = null, emisora = null, clave = null}) => {
  let url = `${dominio}/emisoras/instrumento-alternativo/valuaciones?size=${size}&page=${page}`;

  url = emisora ? `${url}&empresa=${emisora}` : url;
  url = ejercicio ? `${url}&ejercicio=${ejercicio}` : url;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;
  url = clave ? `${url}&clave=${clave}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setValuaciones(response.data));
      })
      .catch(err => dispatch(setValuaciones(err.data)));
  };
};


export const filterValuacionesByClave = (clave, typePage = null) => {
  let page = "valuaciones";
  if (typePage === "costos") {
    page = "costos-gastos-comisiones"
  }
  if (typePage === "desempenio") {
    page = "desempeno-valores-emitidos";
  }
  if (typePage === "patrimonio") {
    page = "patrimonio-fideicomiso";
  }
  let url = `${dominio}/emisoras/instrumento-alternativo/${page}/busqueda/${clave}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFilterClavesValuaciones(response.data));
      })
      .catch(err => dispatch(setFilterClavesValuaciones(err.data)));
  };
};


const setFilterClavesValuaciones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CLAVES_VALUACIONES_SUCCESS,
      listadoClavesValuaciones: []
    };
  }
  return {
    type: actionTypes.FETCH_CLAVES_VALUACIONES_SUCCESS,
    listadoClavesValuaciones: data
  };
};


export const filterEjercicioValuaciones = ({ periodo= null,  instrumento= null}, typePage = null) => {
  let page = "valuaciones";
  if (typePage === "costos") {
    page = "costos-gastos-comisiones"
  }
  if (typePage === "desempenio") {
    page = "desempeno-valores-emitidos";
  }
  if (typePage === "patrimonio") {
    page = "patrimonio-fideicomiso";
  }
  let url = `${dominio}/emisoras/instrumento-alternativo/${page}/ejercicio?1=1`;
  url = periodo ? `${url}&periodo=${periodo}` : url;
  url = instrumento && instrumento.length > 0 ? `${url}&tipoInstrumentoAlternativo=${[...instrumento]}` : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjercicioValuaciones(response.data));
      })
      .catch(err => dispatch(setEjercicioValuaciones(err.data)));
  };
};

const setEjercicioValuaciones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EJERCICIO_VALUACIONES_SUCCESS,
      ejercicio: []
    };
  }
  return {
    type: actionTypes.FETCH_EJERCICIO_VALUACIONES_SUCCESS,
    ejercicio: data
  };
};


export const filterPeriodoValuaciones = ({ instrumento = null, ejercicio= null}, typePage = null) => {
  let page = "valuaciones";
  if (typePage === "costos") {
    page = "costos-gastos-comisiones"
  }
  if (typePage === "patrimonio") {
    page = "patrimonio-fideicomiso";
  }

  let url = `${dominio}/emisoras/instrumento-alternativo/${page}/periodo`;
  let params = {
    tipoInstrumentoAlternativo: instrumento && instrumento.length >= 1 ? instrumento : null,
    ejercicio: ejercicio
  }
  const queryParams = StringUtils.jsonToQueryString(params);

  return dispatch => {
    axios
      .get(url + queryParams)
      .then(response => {
        dispatch(setPeriodoValuaciones(response.data));
      })
      .catch(err => dispatch(setPeriodoValuaciones(err.data)));
  };
};

const setPeriodoValuaciones = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PERIODO_VALUACIONES_SUCCESS,
      periodo: []
    };
  }
  return {
    type: actionTypes.FETCH_PERIODO_VALUACIONES_SUCCESS,
    periodo: data
  };
};



//COSTOS, GASTOS Y COMISIONES


const setCostos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_COSTOS_SUCCESS,
      costos: []
    };
  }
  return {
    type: actionTypes.FETCH_COSTOS_SUCCESS,
    costos: data
  };
};

export const initCostosGastos = (params, type) => {
  let endpoint = "costos-gastos-comisiones";
  if (type === "desempenio") {
    endpoint = "desempeno-valores-emitidos";
  }
  if (type === "patrimonio") {
    endpoint = "patrimonio-fideicomiso";
  }
  let url = `${dominio}/emisoras/instrumento-alternativo/${endpoint}`;

  const queryParams = StringUtils.jsonToQueryString(params);


  return dispatch => {
    axios
      .get(url + queryParams)
      .then(response => {
        dispatch(setCostos(response.data));
      })
      .catch(err => dispatch(setCostos(err.data)));
  };
};
import React, { Component } from "react";
import * as action from "../../../stores/actions/index";
import { connect } from "react-redux";

import Fotos from "../../../components/fotos/Fotos";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class Equipo extends Component {
  state = {
    messages: null,
    equipo: [],
    lan: getLocal()
  };
  componentDidMount() {
    this.props.onInitIntl(13);
    this.props.onInitEquipo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.equipo !== prevProps.equipo) {
      let equipo = [];
      if (this.props.equipo && this.props.equipo.length > 0) {
        let identificador = -1;
        this.props.equipo.forEach(e => {
          if (e.foto === null && e.puesto === "") {
            identificador++;
            equipo[identificador] = [];
            equipo[identificador].push(e);
          } else {
            equipo[identificador] = equipo[identificador].concat(e);
          }
        });
      }
      this.setState({
        equipo: equipo
      });
    }
  }

  render() {
    let fotos = null;

    if (this.state.equipo.length > 0) {
      fotos = this.state.equipo.map(item => {
        return (
          <div className={"row relativo zindex1000 "}>
            <Fotos fotos={item} tipo={1} />
          </div>
        );
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <React.Fragment>
          <h1 className={"nunito texto_42 regular"}>
            <FormattedMessage id="nosotros.nuestroequipo.titulo" defaultMessage=" " />
          </h1>
          <div className="margen_top_50">{fotos}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    },
    onInitEquipo: () => {
      dispatch(action.getNuestroEquipo());
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    equipo: state.documentos.equipo
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Equipo);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";

import classes from "./Avisos.css";
import NotFound from "../../components/notFound/NotFound";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";

// paginas
import AvisosSuspension from "./vigilanciaMercados/AvisosSuspension";
import AvisosGenerales from "./avisosAnuncios/AvisosAnuncios";
import AvisosOperaciones from "./operaciones/AvisosOperaciones";
import AvisosReportes from "./operaciones/AvisosReportes";
import AvisosOfertaPublica from "../empresas/tramitesListado/avisosOfertaPublica/AvisosOfertaPublica";
import AvisosListado from "./Listado/AvisosListado";
import DocumentMeta from "react-document-meta";
import { getLan } from "../../stores/utils/utilities";

const metaEs = {
  title: "Portal BIVA - Avisos y reportes",
  description:
    "Despliega los avisos de oferta pública, convocatorias de subasta, resultado de subasta, cierre de libro y avisos con fines informativos, donde se notifican las características de la oferta.",
  meta: {
    name: {
      keywords: "avisos, reportes, ofertas, ofertas públicas,  listado de emisoras, avisos de listado, avisos de oferta pública, operaciones, avisos generales, avisos de suspensión",
    },
  },
};

const metaEn = {
  title: "Portal BIVA - Notices and reports",
  description:
    "Displays the public offer notices, auction calls, auction result, book closing and notices for informational purposes, where the characteristics of the offer are notified.",
  meta: {
    name: {
      keywords: "listing, listing notices, public, public offerings, trading, notices, reports, general announcement, trading halt announcement",
    },
  },
};

class Avisos extends Component {
  state = {
    menu: null,
    id_menu_activo: null
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage:"avisos"
    };

    this.props.onInitGenericos(params);
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu
      });
    }else{
      this.setState({
        id_menu_activo: menu
      });
      
    }
  };

  render() {
    const routes = (
      <Switch>
        <Route path="/avisos_y_reportes" exact component={AvisosOperaciones} />
        <Route path="/avisos_y_reportes/operaciones" exact component={AvisosOperaciones} />
        <Route path="/avisos_y_reportes/operaciones/avisos" exact component={AvisosOperaciones} />
        <Route path="/avisos_y_reportes/operaciones/reportes" exact component={AvisosReportes}/>
        <Route path="/avisos_y_reportes/avisos_de_suspension" exact component={AvisosSuspension}/>
        <Route path="/avisos_y_reportes/vigilancia_de_mercados/avisos_de_suspension" exact component={AvisosSuspension}/>
        <Route path="/avisos_y_reportes/vigilancia_de_mercados/avisos_generales" exact component={AvisosGenerales}/>
        <Route path="/avisos_y_reportes/listado" exact component={AvisosListado}/>
        <Route path="/avisos_y_reportes/listado/avisos_de_listado" exact component={AvisosListado}/>
        <Route  path="/avisos_y_reportes/listado/avisos_de_oferta_publica" exact component={AvisosOfertaPublica}/>
        <Route path="/avisos_y_reportes/listado/avisos_generales" exact component={AvisosGenerales}/>
        <Route path="/avisos_y_reportes/avisos_generales" exact component={AvisosGenerales}/>
        <Route component={NotFound} />
      </Switch>
    );

    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
      <div className="container">
        <div className="row">
          <div className={["d-none"].join(" ")}>
            <Sidebar classIcon={["fas fa-gavel"].join(" ")} setMenu={(menu,tipo) => this.onSetMenu(menu,tipo)}/>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30">
            <div className="row margin_bottom_30">
              <div className="col-12 sin_padding_L">
                <div className={classes.breadcums}>
                  <p>
                    <Breadcrum   idPage={this.state.id_menu_activo} menu={this.state.menu}/>
                  </p>
                </div>
              </div>
            </div>
            {routes}
          </div>
        </div>
      </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitGenericos: params => {
      dispatch(action.initGenericos(params));
    }
   
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Avisos);

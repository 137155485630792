import React, { Component } from "react";

import classes from './Card.css'
// import Toggle from "./toggle/Toggle";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLocal, getLan, getRealDateMilliesDayMonthYear } from "../../../stores/utils/utilities";
import CardColocaciones from "./CardColocaciones";

class Card extends Component {
    updateParentSelectedCard(id, empresaId, razonSocial, clave, urlLogoEmisora, urlSitioWeb, tipoInstrumento, montoTentativoEmision, fechaTentativaEmision, sustentable) {
        this.props.updateGrandParentSelectedCard(id, empresaId, razonSocial, clave, urlLogoEmisora, urlSitioWeb, tipoInstrumento, montoTentativoEmision, fechaTentativaEmision, sustentable);
    }

    render() {
        let item = null;
        if (this.props.datos) {
            item = this.props.datos.map((dato, i) => {
                return (
                    <Auxs>
                        <CardColocaciones
                            id={dato.id}
                            emisora={dato.empresa.id}
                            clave={dato.empresa.clave}
                            razonSocial={dato.empresa.nombre}
                            urlLogoEmisora={dato.urlLogoEmisora}
                            tipoInstrumento={dato.tipoInstrumento.nombre}
                            serie={dato.serie}
                            fechaTentativaEmision={dato.fechaTentativaEmision}
                            montoTentativoEmision={dato.montoTentativoEmision}
                            sustentable={dato.sustentable}
                            urlSitioWeb={dato.urlSitioWeb}
                            updateParentSelectedCard={this.updateParentSelectedCard.bind(this)}
                        />
                    </Auxs>
                );
            });
            return (
                <Auxs>
                    {item}
                </Auxs>
            );
        }
    }
}
export default (Card);

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  formadorMercado: null,
  marketMakers: null,
  infoMarketMakers: null
};

const fetchFormadorMercado = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FORMADOR_MERCADO_SUCCESS:
      return fetchFormadorMercado(state, action);
    default:
      return state;
  }
};

export default reducer;
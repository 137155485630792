import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  eventos: null,
  evento: null
};

const setInitEventos = (state, action) => {
  return updateObject(state, action);
};

const setInitEventoById = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENTOS_SUCCESS:
      return setInitEventos(state, action);
    case actionTypes.FETCH_EVENTOBYID_SUCCESS:
      return setInitEventoById(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./Calendario.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";
import Boton from "../../../components/ui/boton/Boton";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Calendario extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null,
    calendario: [
      {
        titulo: "Dias festivos 2019",
        texto_html:
          "<ul><li>1 de enero.</li><li>El primer lunes de febrero en conmemoraci&oacute;n.</li><li>El tercer lunes de marzo en conmemoraci&oacute;n del 5 de febrero, es decir, el 4 de febrero</li><li>18 y 19 de abril.</li><li>1 de mayo.</li><li>16 de septiembre.</li><li>El 2 de noviembre. Adicionalmente, el tercer lunes de dicho mes en conmemoraci&oacute;n del 20 de noviembre, es decir, el 18 de noviembre.</li><li>12 y 25 de diciembre</li></ul>",
      },
    ],
    opel_e: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p>Instrumentos de renta variable nacional e internacional</p><table style='width: 603px;'><tbody><tr><th style='width: 129px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 460px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 460px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:00 - 8:30&nbsp;</td><td style='width: 460px;'>Subasta de apertura</td></tr><tr><td style='width: 129px;'>8:00 - 14:50</td><td style='width: 460px;'>Subastas de mantenimiento (SUB-RM). Duraci&oacute;n de 1 hora cada subasta</td></tr><tr><td style='width: 129px;'>8:00 - 14:50</td><td style='width: 460px;'>Subastas por inactividad (SUB-20). Duraci&oacute;n de 1 hora cada subasta</td></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 460px;'>Mercado continuo para valores listados en BIVA</td></tr><tr><td style='width: 129px;'>8:30 - 15:00</td><td style='width: 460px;'>Mercado continuo para valores que cotizan en el mercado secundario</td></tr><tr><td style='width: 129px;'>14:50 - 15:00</td><td style='width: 460px;'>Subasta de cierre para valores listados en BIVA</td></tr></tbody></table><p></p><p>Certificados burs&aacute;tiles fiduciarios de desarrollo</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 462px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:30 - 14:00</td><td style='width: 462px;'>Subasta CCD&rsquo;s</td></tr></tbody></table>",
      },
    ],
    opel_c: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p></p><p>Instrumentos de renta variable nacional e internacional</p><table style='width: 432px;'><tbody><tr><th style='width: 154px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 264px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 154px;'>8:00 - 14:50</td><td style='width: 264px;'>Ingreso de postura al precio de cierre</td></tr><tr><td style='width: 154px;'>15:00</td><td style='width: 264px;'>Ejecuci&oacute;n de operaciones al precio de cierre</td></tr></tbody></table>",
      },
    ],
    opel_b: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p></p><p>Instrumentos de renta variable nacional e internacional</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><strong>Horario (CST)</strong></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 462px;'>Mercado continuo bloques</td></tr></tbody></table>",
      },
    ],
    opel_w: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p>T&iacute;tulos opcionales (Warrants)</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><p><strong>Horario (CST)</strong></p></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 462px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:00 - 8:30</td><td style='width: 462px;'>Subasta de apertura</td></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 462px;'>Mercado continuo para valores listados en BIVA</td></tr><tr><td style='width: 129px;'>8:30 - 15:00</td><td style='width: 462px;'>Mercado continuo para valores que cotizan en el mercado secundario</td></tr><tr><td style='width: 129px;'>14:50 - 15:00</td><td style='width: 462px;'>Subasta de cierre para valores listados en BIVA</td></tr></tbody></table>",
      },
    ],
    opel_d: [
      {
        titulo: "Horarios (CST) y fases del mercado",
        texto_html:
          "<p>Instrumentos de deuda</p><table style='width: 597px;'><tbody><tr><th style='width: 129px; text-align: left;'><p><strong>Horario (CST)</strong></p></th><th style='width: 462px; text-align: left;'><strong>Fases del mercado</strong></th></tr><tr><td style='width: 129px;'>7:50 - 8:00</td><td style='width: 462px;'>Per&iacute;odo de cancelaci&oacute;n</td></tr><tr><td style='width: 129px;'>8:00 - 8:30</td><td style='width: 462px;'>Subasta de apertura</td></tr><tr><td style='width: 129px;'>8:30 - 14:50</td><td style='width: 462px;'>Mercado continuo para valores listados en BIVA</td></tr><tr><td style='width: 129px;'>8:30 - 15:00</td><td style='width: 462px;'>Mercado continuo para valores que cotizan en el mercado secundario</td></tr><tr><td style='width: 129px;'>8:00 - 14:50</td><td style='width: 462px;'>Subastas de mantenimiento (SUB-RM). Duraci&oacute;n de 1 hora cada subasta</td></tr><tr><td style='width: 129px;'>14:50 - 15:00</td><td style='width: 462px;'>Subasta de cierre para valores listados en BIVA</td></tr></tbody></table>",
      },
    ],
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
  }
  render() {
    let calendarios = null;
    if (this.props.calendario) {
      calendarios = this.props.calendario.map((itm, i) => {
        return (
          <div className={"accordion"} id={"accordion_calendario_" + i}>
            <div className={[" margen_top_50", classes.accordion].join(" ")}>
              <Accordion
                titulo_encabezado_estatico={""}
                titulo_encabezado_2_estatico={" "}
                sinPadre={true}
                nombreParent={"accordion_calendario_" + i}
                datos={[itm]}
                nombre={"accordion_calendario_" + i}
                icon={""}
                imagen={itm.imagen}
                tipo={6}
                className={classes.accordion}
              />
            </div>
          </div>
        );
      });
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage
                    id="calendario-entregas.titulo"
                    defaultMessage=" "
                  />
                </h1>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <p className={" texto_16 montse regular"}>
              <FormattedHTMLMessage
                id="calendario-entregas.introduccion"
                defaultMessage=" "
              />
            </p>
          </div>
          {calendarios}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    diccionario: state.intl.diccionario,
    calendario: state.accordion.calendario,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(96));
      dispatch(action.getAccordionCalendarioEntregas());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendario);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import SalaPrensa_ from './publicaciones/SalaPrensa';
import Publicaciones from './publicaciones/Publicaciones';

class SalaPrensa extends Component {
  render() {

    const routes = (
      <Switch>
        <Route path="/nosotros/acerca_de/sala_prensa" exact  component={Publicaciones} />
        <Route path="/nosotros/acerca_de/sala_prensa/:publicacionId"  component={SalaPrensa_} />
      </Switch>
    );
    return (
      <React.Fragment>
            {routes}
      </React.Fragment>
    );
  }
}

export default SalaPrensa;

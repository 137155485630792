import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../stores/actions/index";
import hojaVerde from "../../../assets/icons/sustentable/hoja-verde-2.png";
import Capitulo from "../guia/capitulos/Capitulo";
import classes from "./Materialidad.css";
import { getIndices } from "../../../stores/utils/utilities";

const abc = getIndices();


export default function Guia(props) {
  const dispatch = useDispatch();

  const [materialidad, setMaterialidad] = useState(null);

  const materialidadRedux = useSelector(
    (state) => state.sustentable.materialidad
  );

  useEffect(() => {
    dispatch(action.getMaterialidad());
  }, []);

  useEffect(() => {
    if (materialidadRedux) {
      setMaterialidad(materialidadRedux);
    }
  }, [materialidadRedux]);

  const getContent = () => {
    let divs = null;
    if (materialidad) {
      let indx = 0;
      divs = materialidad.map((itm) => {
        let tituloDiv = null;
        let subtituloDiv = null;
        let texto_div = null;
        let img_div = null;
        if (itm.titulo_encabezado !== null && itm.titulo_encabezado !== "") {
          tituloDiv = (
            <div className={["montse row"].join(" ")}>
              <div className={classes.letra}>
                <span className="texto_azul texto_50">{abc[indx]}</span>
                <span className="texto_gris texto_30">/ </span>
              </div>
              <div className={classes.titulo}>
                <span className="texto_verde texto_30 bold">
                  {itm.titulo_encabezado}
                </span>
              </div>
            </div>
          );
          indx++;
        }
        if (itm.subtitulo !== null && itm.subtitulo !== "") {
          subtituloDiv = (
            <div className={["montse row"].join(" ")}>
              <div className={classes.titulo}>
                <span className="texto_verde texto_30 bold">
                  {itm.subtitulo}
                </span>
              </div>
            </div>
          );
        }
        if (itm.texto_html !== null && itm.texto_html !== "") {
          texto_div = (
            <div
              className={[
                "montse texto_16 margin_top_30",
                classes.card_body,
              ].join(" ")}
              dangerouslySetInnerHTML={{ __html: itm.texto_html }}
            />
          );
        }
        if (itm.imagen !== null && itm.imagen !== "") {
          img_div = (
            <div className="margin_top_50 text-center">
              <img src={itm.imagen} alt="imagen" className={classes.img} />
            </div>
          );
        }
        return (
          <div className="row">
            {tituloDiv}
            {subtituloDiv}
            {texto_div}
            {img_div}
          </div>
        );
      });
    }

    return divs;
  };

  return (
    <>
      <div className="row">
        <div
          className={[
            "main_subtitle",
            "nunito",
            "containerTituloSg",
            "sin_padding",
          ].join(" ")}
        >
          <img
            src={hojaVerde}
            alt="bivasg"
            className={["slash", "imgSg"].join(" ")}
          />
          <h1
            className={[
              "tituloSg texto_50 bold montse texto_verde_oscuro",
              classes.textCapitulos,
            ].join(" ")}
          >
            Materialidad y planeación estratégica
          </h1>
        </div>
        {getContent()}
        <Capitulo />
      </div>
    </>
  );
}

import React, { Component } from "react";
import { connect } from "react-redux";

import Filter from "../../../../components/ui/filter/Filter";
import EventosList from "../../../../components/eventos/Eventos";

import * as action from "../../../../stores/actions/index";

import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import Evento from './Evento';
import queryString from "query-string";

class Eventos extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    eventoId: null,
    loader: null,
    page: 1
  };
  componentDidMount() {
    this.props.onInitIntl(11);

    let paramsString = queryString.parse(this.props.location.search);
    let fechaInicio =
    paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
     let fechaFin =
    paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
     let id =
    paramsString["id"] !== undefined && paramsString["id"] !== "null" ? paramsString["id"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    
    this.setState({page : page && page > 0 ? page : 1,
    eventoId: id})

    this.props.onInitEventos(fechaInicio, fechaFin);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  onEventSelected(eventoId){
      this.setState({eventoId: eventoId})
      if (eventoId === null) {
        let timeD = +new Date();
        this.setState({loader:timeD})
        this.props.history.push(
          "?page=1"
        );
      }else{
        this.props.history.push(
          `?id=${eventoId}&page=${1}`
        );
      }
  }
  onPageSelected(page){
    if (this.state.page !== page) {
      this.setState({page: page})
    }
  }

  render() {
    const eventos = this.props.eventos ? (
      <EventosList datos={this.props.eventos} es_evento={false} redirecciona={"/nosotros/acerca_de/eventos/evento/"} eventSelected={(eventoId) => this.onEventSelected(eventoId)} pageSelected={(page) => this.onPageSelected(page)} activePage={this.state.page} />
    ) : (
      <p className="montse texto_16 regular">
        {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
      </p>
    );

    let eventos_container = null;
    if (this.state.eventoId) {
        eventos_container=(<Evento key={+new Date()} tipo={2} eventoId={this.state.eventoId} eventos={this.props.eventos} eventSelected={(eventoId) => {this.onEventSelected(eventoId)}}/>);
    }else{
        eventos_container = (
          <>
            <div className={["row margen_top_50"].join(" ")}>
              <div className="col sin_padding">
                <Filter modelo="modelo_1" target="eventos" loaderDisabled={this.state.loader} history={this.props.history} page={this.state.page} />
              </div>
            </div>
            <div className="row margen_top_50">
              <div className="col sin_padding">{eventos}</div>
            </div>
          </>
        );
    }


    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <React.Fragment>
          <div className="row">
            <div className="col sin_padding">
              <h1 className={"nunito regular texto_42"}>
                <FormattedMessage id="nosotros.acercade.eventos.titulo1" defaultMessage=" " />
              </h1>
            </div>
          </div>
          {eventos_container}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    eventos: state.eventos.eventos,
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitEventos: (fechaInicio,fechaFin) => {
      dispatch(action.initEventos(fechaInicio,fechaFin));
    },
    onInitIntl: pagina => {
      dispatch(action.initIntl(pagina));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Eventos);

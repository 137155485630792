import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  salaPrensa: null,
  salaPrensaUniq: null
};

const setSalaPrensa = (state, action) => {
  return updateObject(state, action);
};

const setSalaPrensaById = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SALAPRENSA_SUCCESS:
      return setSalaPrensa(state, action);
    case actionTypes.FETCH_SALAPRENSABYID_SUCCESS:
      return setSalaPrensaById(state, action);
    default:
      return state;
  }
};

export default reducer;

import * as actionType from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  posturas: null
};

const setInitPosturas = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_POSTURAS_SUCCESS:
      return setInitPosturas(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./Manual.css";

import Auxs from "../../../hoc/auxs/Auxs";
import Box from "../../../components/ui/box/Box";

import { connect } from "react-redux";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class Manual extends Component {
  state = {
    messages: null,
    lan: getLocal(),
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let manual = null;
    if (this.props.manual) {
      manual = this.props.manual.map((manual, i) => {
        return <Box key={"manual" + i} modelo="modelo_1" data={manual}   />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="operaciones.modelosdenegociacion.manualyreglamento.titulo1" defaultMessage=" " />
              </h1>
            </div>
          </div>
          <div className="row margen_top_30 margin_bottom_300">
            <div className="">
              {manual}
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    reglamento: state.documentos.reglamentoInterior,
    manual: state.documentos.manualReglamento
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(34));
      dispatch(action.getManualReglamento());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Manual);

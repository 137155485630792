import React from "react";
import classes from './Items.css';

const Items = props => (
  <li className={["list-group-item", classes.list_group, classes.list_item,classes.row_video].join(' ')} onClick={props.onItemSelected}>
    <div className={["row"].join(" ")}>
      <div className={["col-12 sin_padding", classes.videoContainer].join(" ")}>
        <div className={classes.thumbanil}>
          <img src={props.item.snippet.thumbnails.default.url} alt=""/>
        </div>
      </div>
      <div className={["col-12 sin_padding", classes.textoVideoContainer].join(" ")}>

          <div className={[" sin_padding", classes.contenedorTitulo].join(" ")}>
          <span className={[classes.titulo_video, "color_blanco"].join(" ")}>{props.item.snippet.title}</span>
          </div>

      </div>
    </div>
  </li>
);

export default Items;

import { updateObject } from "../utils/utilities";
import * as actionType from "../actions/actionTypes";

const initialState = {
  videos: null,
  videos_api: null,
  videos_instituto: null,
  videoBIM: null,
  videosAuditorium: null,
  videosDIV: null,
  tabs: null,
  nuevosVideos: null,
  videosByField: null,
  videos_embajador: null
};

const setVideos = (state, action) => {
  const newProperties = {
    videos: action.videos
  };
  return updateObject(state, newProperties);
};

const setVideosApi = (state, action) => {
  const newProperties = {
    videos_api: action.videos_api
  };
  return updateObject(state, newProperties);
};
// videos instituto BOVA
const setVideosInstituto = (state, action) => {
  const newProperties = {
    videos_instituto: action.videos_instituto
  };
  return updateObject(state, newProperties);
};
const SetVideosGeneric = (state, action) => {
  return updateObject(state, action);
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_VIDEO_SUCCESS:
      return setVideos(state, action);
    case actionType.FETCH_VIDEO_API_SUCCESS:
      return setVideosApi(state, action);
    case actionType.FETCH_VIDEO_INSTITUTO_SUCCESS:
      return setVideosInstituto(state, action);
    case actionType.FETCH_VIDEOS_SUCCESS:
      return SetVideosGeneric(state, action);
      
    default:
      return state;
  }
};

export default reducer;

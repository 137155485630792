import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import classes from "./ComposicionCartera.css";

import FilterComposicionCartera from "../../../components/ui/filterComposicionCartera/FilterComposicionCartera";
import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
/* import { withRouter } from "react-router-dom"; */
import Table from "../../../components/empresas/Table";
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import Boton from "../../../components/ui/boton/Boton";

const dominio = `${window.location.protocol}//${window.location.host}`;
let base_url = `${dominio}/emisoras/fondos/precios/descargar?1=1`;

class ComposicionCartera extends Component {
    state = {
        itemsPerPage: 10,
        activePage: 1,
        resourceList: [],
        loader: true,
        tramiteSeleccionado: null,
        razonSocial: null,
        clave: null,
        numeroTramite: null,
        datos: null,
        emisoraSeleccionada: null,
        tipoValorSeleccionado: null,
        fecha_inicio: null,
        fecha_fin: null,
        serie: null,
        page: 0,
        detalles: [],
        urlForRss: `${dominio}/emisoras/tramites/rss`,
        tipoDocumento: null,
        pageDocumento: null,
        onShowGraphicModal: false,
        verDocumentos: false,
        claveAfore: false,
        claveSiefore: false,
        tipoSiefore: false,
        clasificacionSiefore: false,
        wasFiltered: false,
        messages: null,
        lan: getLocal(),
        paramsForm: null,
        jsonTable: null,
    };

    componentDidMount() {
        this.props.onInitIntl();
    }

    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages,
                });
            }
        }
        if (this.props.fondos !== prevProps.fondos) {
            let jsonTable = null
            if (this.props.fondos.length !== 0) {
                jsonTable = this.transformCsvtoJson(this.props.fondos);
            }
            this.setState({
                loader: false,
                jsonTable: jsonTable
            });
        }

        if (this.props.serie !== prevProps.serie) {
            this.setState({
                serie: this.props.serie,
            });
        }

        if (this.props.composicionUrlCsv !== prevProps.composicionUrlCsv) {
            this.props.onInitFondos(
                // this.state.itemsPerPage,
                // page > 0 ? page - 1 : 0,
                // claveOperadora,
                // claveFondo,
                // tipoFondo,
                // clasificacionFondo,
                // serie,
                // fechaInicio,
                // fechaFin
                this.props.composicionUrlCsv ? this.props.composicionUrlCsv.url : null
            );
        }
    }



    transformCsvtoJson = (csv = null) => {
        let lines = csv.trim();
        let result = [];

        // NOTE: If your columns contain commas in their values, you'll need
        // to deal with those before doing the next step 
        // (you might convert them to &&& or something, then covert them back later)
        // jsfiddle showing the issue https://jsfiddle.net/
        let totalLines = lines.split("\n");
        let headers = totalLines[0];

        for (var i = 1; i < totalLines.length; i++) {

            var obj = {};
            var currentline = totalLines[i].split(";");
            var currentheader = headers.split(";");

            for (var j = 0; j < currentheader.length; j++) {
                let titulo;
                if (j === 4) {
                    titulo = "emisora";
                } else if (j === 5) {
                    titulo = "serie";
                } else if (j === 6) {
                    titulo = "tipoValor";
                } else if (j === 11) {
                    titulo = "titulosOperados";
                } else if (j === 12) {
                    titulo = "titulosCirculacion";
                } else if (j === 13) {
                    titulo = "precioPromedio";
                } else if (j === 15) {
                    titulo = "valorRazonable";
                } else if (j === 17) {
                    titulo = "diasVencer";

                }
                obj[titulo] = currentline[j];
            }

            result.push(obj);
        }
        return result;

    }

    onPageChangeHandler = (
        resource = null,
        pageNumber = null,
        seleccionado = null
    ) => {
        // let data = resource ? resource : this.props.fondos;
        let data = resource ? resource : this.state.jsonTable;
        let resourceList = [];
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                resourceList: resourceList,
                itemSeleccionado: urlSeleccionado,
            });
        } else if (pageNumber) {
            this.setState({
                activePage: pageNumber,
                loader: true,
                resourceList: resourceList,
            });
        } else {
            this.setState({
                resourceList: resourceList,
            });
        }
    };

    handlePageChange(pageNumber) {
        if (this.state.activePage !== pageNumber) {
            this.changeHistory(
                this.state.claveOperadora,
                this.state.claveFondo,
                pageNumber
            );

            this.onPageChangeHandler(null, pageNumber);
        }
    }

    componentWillMount() {
        let paramsString = queryString.parse(this.props.location.search);
        let claveOperadora = null;
        let claveFondo = null;
        let tipoFondo = null;
        let clasificacionFondo = null;
        let page = null;
        let fechaInicio = null;
        let fechaFin = null;
        let serie = null;
        let search_field_param = paramsString["search_field"];
        if (this.props.location.search !== "" && search_field_param === undefined) {
            claveOperadora =
                paramsString["claveOperadora"] === "null"
                    ? null
                    : paramsString["claveOperadora"];
            claveFondo =
                paramsString["claveFondo"] === "null"
                    ? null
                    : paramsString["claveFondo"];
            page =
                paramsString["page"] !== undefined && paramsString["page"] !== "null"
                    ? parseInt(paramsString["page"])
                    : 1;

        }
        if (claveOperadora !== null && claveFondo !== null) {
            this.props.onInitUrlCsv(claveOperadora, claveFondo);
            // this.props.onInitFondos(
            //     // this.state.itemsPerPage,
            //     // parseInt(page) > 0 ? parseInt(page) - 1 : 0,
            //     claveOperadora,
            //     claveFondo,
            //     // tipoFondo,
            //     // clasificacionFondo,
            //     // serie,
            //     // fechaInicio,
            //     // fechaFin
            // );
        }

        let paramsForm = {
            claveOperadora: claveOperadora,
            claveFondo: claveFondo,
        }

        this.setState({
            paramsForm: paramsForm,
            claveOperadora: claveOperadora,
            claveFondo: claveFondo,
            activePage: parseInt(page) > 0 ? parseInt(page) : 1,
        });
    }



    updateGrandparent = (value) => {
        this.props.onInitConcentradoPrecios(value);
        this.setState({
            tramiteDetalle: value,
            detalles: [],
        });
    };

    onSetPage = (page) => {
        this.setState({
            activePage: page,
        });
    };

    onSetValor = (valor) => {
        this.setState({
            tipoValorSeleccionado: valor,
        });
    };

    onSetFechaInicio = (fecha) => {
        this.setState({
            fecha_inicio: fecha,
        });
    };

    onSetFechaFin = (fecha) => {
        this.setState({
            fecha_fin: fecha,
        });
    };

    onSetTipoDocumento = (tipoDocumento, consulta) => {
        this.setState({
            tipoDocumento: tipoDocumento,
        });
    };

    onSetPageDocumento = (page) => {
        this.setState({
            pageDocumento: page,
        });
    };

    onSetEmisora = (emisora, tipo, consulta = false) => {
        let emisoraSeleccionada = null;
        let clave = null;

        if (tipo === 1) {
            emisoraSeleccionada = emisora;
        }
        if (tipo === 2) {
            clave = emisora;
        }

        this.setState({
            emisoraSeleccionada: emisoraSeleccionada,
            clave: clave,
        });
        // if (consulta) {
        //     this.props.onInitFondos(
        //         this.state.itemsPerPage,
        //         0
        //         /* emisoraSeleccionada,
        //                 this.state.tipoValorSeleccionado,
        //                 this.state.fecha_inicio,
        //                 this.state.fecha_fin,
        //                 clave */
        //     );
        // }
    };
    onSetClaveOperadoraHandler = (claveOperadora) => {
        this.setState({
            claveOperadora: claveOperadora,
        });
    };

    onSetClaveFondoHandler = (claveFondo) => {
        this.setState({
            claveFondo: claveFondo,
        });
    };

    onSetTipoFondohandler = (tipoFondo) => {
        this.setState({
            tipoFondo: tipoFondo,
        });
    };

    onSetClasificacionFondo = (clasificacionFondo) => {
        this.setState({
            clasificacionFondo: clasificacionFondo,
        });
    };

    onSetSerie = (serie) => {
        this.setState({
            serie: serie,
        });
    };

    onSetWasFilteredHandler = (wasFiltered) => {
        this.setState({
            wasFiltered: wasFiltered,
            loader: true,
        });
    };

    onShowGraphic = () => {
        this.setState((prevState) => {
            return {
                onShowGraphicModal: !prevState.onShowGraphicModal,
            };
        });
    };

    onSetFilters = ({
        claveOperadora = null,
        claveFondo = null,
        // tipoFondo = null,
        // clasificacionFondo = null,
        // serie = null,
        // fechaInicio = null,
        // fechaFin = null,
        // wasFiltered = null,
        page = null,
        reset = false,
    }) => {
        this.setState({
            claveOperadora: claveOperadora ? claveOperadora : null,
            claveFondo: claveFondo ? claveFondo : null,
        });
        if (reset) {
            this.changeHistoryReset();
        } else {
            this.changeHistory(
                claveOperadora,
                claveFondo,
                // tipoFondo,
                // clasificacionFondo,
                // serie,
                // fechaInicio,
                // fechaFin,
                page
            );
        }
    };

    changeHistory(
        claveOperadora = null,
        claveFondo = null,
        // tipoFondo = null,
        // clasificacionFondo = null,
        // serie = null,
        // fechaInicio = null,
        // fechaFin = null,
        page = null
    ) {
        this.props.history.push(
            // `?claveOperadora=${claveOperadora}&claveFondo=${claveFondo}&tipoFondo=${tipoFondo}&clasificacionFondo=${clasificacionFondo}&serie=${serie}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&page=${
            // page ? page : this.state.activePage
            // }`

            `?claveOperadora=${claveOperadora}&claveFondo=${claveFondo}&page=${page ? page : this.state.activePage}`
        );
        this.props.onInitUrlCsv(claveOperadora, claveFondo);
        // this.props.onInitFondos(
        //     // this.state.itemsPerPage,
        //     // page > 0 ? page - 1 : 0,
        //     // claveOperadora,
        //     // claveFondo,
        //     // tipoFondo,
        //     // clasificacionFondo,
        //     // serie,
        //     // fechaInicio,
        //     // fechaFin
        //     this.props.composicionUrlCsv
        // );
        let paramsForm = {
            claveOperadora: claveOperadora,
            claveFondo: claveFondo,
            // tipoFondo: tipoFondo,
            // clasificacionFondo: clasificacionFondo,
            // serie: serie,
            // fechaInicio: fechaInicio,
            // fechaFin: fechaFin
        }
        this.setState({
            paramsForm: paramsForm,
            activePage: parseInt(page) > 0 ? parseInt(page) : this.state.activePage,
        })
    }

    changeHistoryReset() {
        this.props.history.push("/empresas/fondos/composicion_de_cartera");
        this.props.onInitUrlCsv(null, null);
        // this.props.onInitFondos(
        //     // this.state.itemsPerPage,
        //     // 0,
        //     null,
        //     null,
        //     // null,
        //     // null,
        //     // null,
        //     // null,
        //     // null
        // );
        let paramsForm = {
            claveOperadora: null,
            claveFondo: null,
            tipoFondo: null,
            clasificacionFondo: null,
            serie: null,
            fechaInicio: null,
            fechaFin: null
        }
        this.setState({
            paramsForm: paramsForm
        })
    }

    render() {
        let loader = null;
        let descarga_url = base_url;

        descarga_url =
            this.state.serie && this.state.serie.length > 0
                ? `${descarga_url}&serie=${this.state.serie}`
                : descarga_url;
        descarga_url = this.state.claveOperadora
            ? `${descarga_url}&claveEmisora=${this.state.claveOperadora}`
            : descarga_url;
        descarga_url = this.state.claveFondo
            ? `${descarga_url}&clave=${this.state.claveFondo}`
            : descarga_url;
        descarga_url = this.state.tipoFondo
            ? `${descarga_url}&tipo=${this.state.tipoFondo}`
            : descarga_url;
        descarga_url = this.state.clasificacionFondo
            ? `${descarga_url}&clasificacion=${this.state.clasificacionFondo}`
            : descarga_url;
        descarga_url = this.state.fecha_inicio
            ? `${descarga_url}&fechaInicio=${this.state.fecha_inicio}`
            : descarga_url;
        descarga_url = this.state.fecha_fin
            ? `${descarga_url}&fechaFin=${this.state.fecha_fin}`
            : descarga_url;

        const resultadoDescarga = (
            <div className={"row margen_30"}>
                <div className={[classes.card_header, "sin_padding col-12 col-md-3 col-lg-6 col-xl-7  d-none d-sm-none d-md-flex"].join(" ")}>
                    <span className={["regular montse texto_16 "].join(" ")} />
                </div>
                <div className={[classes.card_header2, "sin_padding col-12 col-md-9 col-lg-6 col-xl-5"].join(" ")}>
                    <div className={["sin_padding col-12 texto_16 montse regular"].join(" ")}>
                        <a
                            href={descarga_url}
                            target="_self"
                            rel="noopener noreferrer"
                            className="row"
                        >
                            <span
                                className={[
                                    classes.titulo,
                                    "texto_16 montse color_azul regular col-12",
                                ].join(" ")}
                            >
                                {getLan() === "es"
                                    ? "Listado histórico de precios por fondo y serie"
                                    : "Historical fund prices listing"}
                                <i
                                    className={[
                                        "fas fa-arrow-alt-circle-down fa-lg",
                                        "Boton__icono_derecho__25mg6",
                                    ].join(" ")}
                                />
                            </span>
                            <span
                                className={[
                                    classes.titulo_ZIP,
                                    "semi_bold montse texto_10 col-12",
                                ].join(" ")}
                            >
                                {getLan() === "es" ? "Formato XLS" : "XLS file"}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        );

        if (this.state.loader) {
            loader = (
                <Loader
                    loaderDisable={(disable) => this.setState({ loader: disable })}
                />
            );
        }

        // const numRows = this.props.fondos ? this.props.fondos.totalElements : 0;
        const numRows = this.state.jsonTable ? this.state.jsonTable.length : 0;

        let periodoEjercicio = null;
        if (this.props.composicionUrlCsv && this.props.composicionUrlCsv !== null && this.props.composicionUrlCsv.length !== 0) {
            periodoEjercicio = (
                <div className="row margen_top_10">
                    <div className={"col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 row sin_padding margin_bottom_10"}>
                        <div className="col-12 sin_padding_L">
                            <span className="montse texto_20 semi_bold color_azul">{getLan() === "es"
                                ? `Mes: `
                                : `Period: `}</span>
                            <span className="montse texto_20 semi_bold color_gris">{this.props.composicionUrlCsv.periodo}</span>
                        </div>
                    </div>
                    <div className={"col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 row sin_padding margin_bottom_10"}>
                        <div className="col-12 sin_padding_L">
                            <span className="montse texto_20 semi_bold color_azul">{getLan() === "es"
                                ? ` Año: `
                                : ` Year: `}</span>
                            <span className="montse texto_20 semi_bold color_gris">{this.props.composicionUrlCsv.ejercicio}</span>
                        </div>
                    </div>
                    &nbsp;
                </div>
            );
        }
        let dataShow = null;
        dataShow =
            this.state.jsonTable && this.state.jsonTable.length > 0 ? (
                <React.Fragment>
                    {/* {btnGraficar} */}
                    {/* {resultadoDescarga} */}
                    {/* <br /> */}
                    {periodoEjercicio}
                    <Table tipo={5} datos={this.state.jsonTable} activePage={this.state.activePage} />
                </React.Fragment>
            ) : this.state.jsonTable === null ? (
                null
            )
                    : (
                        <p className="montse texto_16 regular">
                            {getLan() === "es"
                                ? "No existen resultados para la búsqueda."
                                : "No results found."}
                        </p>
                    );

        let contenidoMostrar = (
            <React.Fragment>
                <h1 className={"texto_42 nunito regular"}>
                    <FormattedHTMLMessage
                        id="empresas.fondos.composicion.cartera.titulo"
                        defaultMessage=" "
                    />
                </h1>
                <div className="margen_top_30 texto_16 montse regular">
                    <FormattedHTMLMessage
                        id="empresas.fondos.composicion.cartera.descripcion"
                        defaultMessage=" "
                    />
                </div>
                <br />
                <FilterComposicionCartera
                    itemsPerPage={this.state.itemsPerPage}
                    setPage={(page) => this.onSetPage(page)}
                    onSetClaveOperadora={(claveOperadora) =>
                        this.onSetClaveOperadoraHandler(claveOperadora)
                    }
                    onSetClaveFondo={(claveFondo) =>
                        this.onSetClaveFondoHandler(claveFondo)
                    }
                    onSetTipoFondo={(tipoFondo) => this.onSetTipoFondohandler(tipoFondo)}
                    onSetClasificacionFondo={(clasificacionFondo) =>
                        this.onSetClasificacionFondo(clasificacionFondo)
                    }
                    onSetSerie={(serie) => this.onSetSerie(serie)}
                    onSetWasFiltered={(wasFiltered) =>
                        this.onSetWasFilteredHandler(wasFiltered)
                    }
                    onSetFechaInicio={(fechaInicio) => this.onSetFechaInicio(fechaInicio)}
                    onSetFechaFin={(fechaFin) => this.onSetFechaFin(fechaFin)}
                    setFilters={(filterParams) => this.onSetFilters(filterParams)}
                />
                <br />
                <div className="margen_50">{dataShow}</div>
                <Pagination
                    claseTipo={2}
                    numRows={numRows}
                    paginas={5}
                    itemsPerPage={this.state.itemsPerPage}
                    handlePageChange={this.handlePageChange.bind(this)}
                    activePage={this.state.activePage}
                />
            </React.Fragment>
        );

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages}>
                <React.Fragment>
                    {loader}
                    {contenidoMostrar}
                    <div className="row margen_top_100">
                        <div className="center">
                            <Boton
                                ruta={"/empresas/fondos/informacion_corporativa?claveOperadora=&claveFondo=&tipoDocumento=19002&fechaInicio=&fechaFin=&periodo=&ejercicio=&page=1"}
                                tipo={2}
                                texto={<FormattedHTMLMessage id="empresas.fondos.composicion.cartera.btn1" defaultMessage=" " />}
                                icono_derecho="fas fa-long-arrow-alt-right"
                                colorBoton={""}
                            />
                        </div>
                    </div>
                </React.Fragment >
            </IntlProvider >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitFondos: (
            //   size,
            //   page,
            //   claveOperadora,
            //   claveFondo,
            //   tipo,
            //   clasificacion,
            //   serie,
            //   fechaInicio,
            //   fechaFin
            urlCsv
        ) => {
            dispatch(
                actions.initFondosComposicionCartera(
                    //   size,
                    //   page,
                    urlCsv
                    //   claveFondo,
                    //   tipo,
                    //   clasificacion,
                    //   serie,
                    //   fechaInicio,
                    //   fechaFin
                )
            );
        },
        onInitUrlCsv: (
            claveOperadora,
            claveFondo
        ) => {
            dispatch(actions.initFondosComposicionCarteraUrlCsv(claveOperadora, claveFondo));
        },
        onInitIntl: () => {
            dispatch(actions.initIntl(79));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        fondos: state.fondos.composicionCartera,
        composicionUrlCsv: state.fondos.composicionUrlCsv,
        diccionario: state.intl.diccionario,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComposicionCartera);

import React, { Component } from "react";
import classes from "./Emisoras.css";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Accordion from "../../../components/ui/accordion/Accordion";
import Tabs from "../../../components/ui/tabs/Tabs";
import FilterEmisoras from "../../../components/ui/filterEmisoras/FilterEmisoras";
import SubFiltersCotizacion from "../../../components/ui/subFiltersCotizacion/subFiltersCotizacion";
import Table from "../../../components/empresas/Table";
import Chart from "../../../components/TVChartContainer/Chart";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import DocumentosEmisoras from "./DocumentosEmisoras";
import ValoresListadosEmisoras from "./ValoresListadosEmisoras";
import { setColorEstatus } from "../../../stores/utils/utilities";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/empresas/xls`;

class Emisoras extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    indiceIsin: null,
    emisoraSeleccionada: null,
    indexSelected: 0,
    indexSelectedInicial: 0,
    subFiltros: {
      indicadores: [],
      tipoGrafica: "LineChart"
    },
    tabsEmisoras: [
      {
        image: "",
        icon: "",
        title: ""
      },
      {
        image: "",
        icon: "",
        title: ""
      },
      {
        image: "",
        icon: "",
        title: ""
      }
    ],
    tabsInfo: [
      {
        image: "",
        icon: "",
        title: ""
      },
      {
        image: "",
        icon: "",
        title: ""
      },
      {
        image: "",
        icon: "",
        title: ""
      },
      {
        image: "",
        icon: "",
        title: ""
      }
    ],
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    biva: null,
    canceladas: null,
    isin_seleccionado: null,
    indice_emisora: null,
    instrumento: null,
    tipo_valor: null,
    sector: null,
    sub_sector: null,
    ramo: null,
    sub_ramo: null,
    nombreEmision: null,
    tabsDesabilitados: [],
    activaTabInicial: null,
    claveFilter: null,
    filteredBy: null,
    wasFilteredBySearch: false,
    claveEmisora: null,
    emisoraDetalle: null,
    subtab: 0
  };

  componentWillMount() {
    this.props.onInitIntl();
    this.props.onFetchRssinputs();

    let paramsString = queryString.parse(this.props.location.search);

    let instrumento = null;
    let tipo_valor = null;
    let sector = null;
    let sub_sector = null;
    let ramo = null;
    let sub_ramo = null;
    let emisora_id = null;
    let biva = null;
    let canceladas = null;
    let activaTabInicial = null;
    let tabsDesabilitados = [];
    let subTab = null;
    let clave= null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      instrumento = paramsString["instrumento"] === "null" || paramsString["instrumento"] === undefined ? null : paramsString["instrumento"].split(",");
      tipo_valor = paramsString["tipo_valor"] === "null" || paramsString["tipo_valor"] === undefined ? null : paramsString["tipo_valor"].split(",");
      sector = paramsString["sector"] === "null" || paramsString["sector"] === undefined ? null : paramsString["sector"];
      sub_sector = paramsString["sub_sector"] === "null" || paramsString["sub_sector"] === undefined ? null : paramsString["sub_sector"];
      ramo = paramsString["ramo"] === "null" || paramsString["ramo"] === undefined ? null : paramsString["ramo"];
      sub_ramo = paramsString["sub_ramo"] === "null" || paramsString["sub_ramo"] === undefined ? null : paramsString["sub_ramo"];
      emisora_id = paramsString["emisora_id"] === "null" || paramsString["emisora_id"] === undefined ? null : paramsString["emisora_id"];
      biva = paramsString["biva"] === "null" || paramsString["biva"] === undefined ? null : JSON.parse(paramsString["biva"]);
      canceladas = paramsString["canceladas"] === "null" || paramsString["canceladas"] === undefined ? null : JSON.parse(paramsString["canceladas"]);
      subTab = paramsString["subTab"] !== undefined && paramsString["subTab"] !== "null" ? parseInt(paramsString["subTab"]) : null;
      clave = paramsString["clave"] === "null" || paramsString["clave"] === undefined ? null : paramsString["clave"];
    }
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    if (biva === false && canceladas === false) {
      biva = null;
      canceladas = null;
    } else {
      if (biva) {
        activaTabInicial = 1;
      }
      if (canceladas) {
        activaTabInicial = 2;
        tabsDesabilitados = [1, 3];
      }
    }
    if (page === -1) {
      page = 1;
    } else if (page === null) {
      page = 1;
    }
    let indexSelected = 0;
    if (+subTab !== 0) {
      indexSelected = +subTab;
    }

    this.setState({
      instrumento: instrumento,
      tipo_valor: tipo_valor,
      sector: sector,
      sub_sector: sub_sector,
      ramo: ramo,
      sub_ramo: sub_ramo,
      biva: biva,
      subTab: subTab,
      canceladas: canceladas,
      activaTabInicial: activaTabInicial,
      tabsDesabilitados: tabsDesabilitados,
      activePage: page > 0 ? page : 1,
      indexSelected: indexSelected,
      emisoraSeleccionada: emisora_id,
      claveEmisora: clave //clave
    });

    if (emisora_id) {
      this.updateGrandparent(emisora_id, canceladas, biva, subTab);
      this.onChangeItemSelected(subTab ? subTab : 0);
    } else {
      if (this.props.location.search === "") {
        this.onChangeTabInicial();
      }

      let params = {
        size: this.state.itemsPerPage,
        page: page > 1 ? page - 1 : 0,
        instrumento: instrumento,
        tipo_valor: tipo_valor,
        sector: sector,
        sub_sector: sub_sector,
        ramo: ramo,
        sub_ramo: sub_ramo,
        biva: biva,
        canceladas: canceladas,
        clave: clave
      };
      this.props.onFilterEmisoras(params);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
          tabsEmisoras: [
            {
              image: "",
              icon: "",
              title: messages["empresas.emisorasinscritas.tab.todas"]
            },
            {
              image: "",
              icon: "",
              title: messages["empresas.emisorasinscritas.tab.biva"]
            },
            {
              image: "",
              icon: "",
              title: messages["empresas.emisorasinscritas.tab.canceladas"]
            }
          ],
          tabsInfo: [
            {
              image: "",
              icon: "",
              title: messages["empresas.emisorasinscritas.emisora.infogral"]
            },
            {
              image: "",
              icon: "",
              title: messages["empresas.emisorasinscritas.emisora.cotizacion"]
            },
            {
              image: "",
              icon: "",
              title: messages["empresas.emisorasinscritas.emisora.documentos"]
            },
            {
              image: "",
              icon: "",
              title: messages["empresas.emisorasinscritas.emisora.valoreslistados"]
            }
          ]
        });
      }
    }
    if (this.props.indiceIsin !== prevProps.indiceIsin) {
      this.setState({
        indiceIsin: this.props.indiceIsin
      });
    }
    if (this.props.emisoras !== prevProps.emisoras) {
      let emisoras = null;
      this.setState({
        loader: false
      });

      emisoras = this.props.emisoras !== null ? this.props.emisoras.content : null;

      let resource = emisoras;
      this.onPageChangeHandler(resource, null, 0);
    }
    if (this.props.emisoraDetalle !== prevProps.emisoraDetalle) {
      let claveEmisora = null;
      let cancelada = false;
      let tabsDesabilitados = this.state.tabsDesabilitados;
      if (this.props.emisoraDetalle !== null && this.props.emisoraDetalle !== undefined) {
        claveEmisora = this.props.emisoraDetalle.clave;
        if (this.props.emisoraDetalle.estatus === "Cancelada") {
          cancelada = true;
          tabsDesabilitados = [1, 3];
        } else if (this.props.emisoraDetalle.estatus === "Listado preventivo") {
          tabsDesabilitados = [1, 3];
        }
        if (this.props.emisoraDetalle.estatus === "Activa") {
          tabsDesabilitados = [];
        }
      }
      this.setState({
        emisoraSeleccionada: this.props.emisoraDetalle ? this.props.emisoraDetalle.id : null,
        canceladas: cancelada,
        tabsDesabilitados: tabsDesabilitados,
        claveEmisora: claveEmisora,
        emisoraDetalle: this.props.emisoraDetalle,
        loader: false
      });
    }

    if (prevProps.valoresListados !== this.props.valoresListados) {
      this.setState({
        loader: false
      });
      if (
        this.props.valoresListados &&
        this.props.valoresListados.content &&
        this.props.valoresListados.content.length !== undefined
      ) {
        if (
          this.props.valoresListados !== null &&
          prevProps.valoresListados !== null &&
          this.props.valoresListados.content.length !== undefined &&
          prevProps.valoresListados !== this.props.valoresListados
        ) {
          this.setState({
            isin_seleccionado: this.props.valoresListados.content[0].isin
          });
        }
      } else {
        this.setState({
          isin_seleccionado: null
        });
      }
    }
    if (this.props.documentos !== prevProps.documentos) {
      this.setState({
        loader: false
      });
    }
    if (this.props.financialReport !== prevProps.financialReport) {
      this.setState({
        loader: false
      });
    }
  }

  onSetInitTabs = (index = null, emisora = null, wasFilteredBySearch = null) => {
    this.setState({
      indexSelected: index,
      emisoraSeleccionada: emisora,
      wasFilteredBySearch: wasFilteredBySearch
    });
  };

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.emisoras;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });

      this.props.history.push(
        `?instrumento=${this.state.instrumento}&tipo_valor=${this.state.tipo_valor}&sector=${this.state.sector}&sub_sector=${
        this.state.sub_sector
        }&ramo=${this.state.ramo}&sub_ramo=${this.state.sub_ramo}&emisora_id=${this.state.emisoraSeleccionada}&clave=${this.state.claveEmisora}&canceladas=${ //clave
        this.state.canceladas
        }&biva=${this.state.biva}&subTab=${this.state.subTab ? this.state.subTab : null}&page=${pageNumber}`
      );
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  onChangeItemSelected = (index = 0) => {
    let activePage = null;
    let subTab = 0;
    if (this.state.indexSelected === index) {
      activePage = this.state.activePage;
    } else {
      activePage = 1;
    }
    if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada !== null) {
      if (index === 1) {
        this.setState({ loader: false });
      }

      this.props.onInitEmisorasDetail(this.state.emisoraSeleccionada);
      this.props.onInitValoresListados(this.state.itemsPerPage, 0, this.state.emisoraSeleccionada);
      if (this.props.valoresListados && this.props.valoresListados.length !== undefined && this.props.valoresListados > 0) {
        this.props.onInitFinancialReport(this.props.valoresListados.content[0].isin);
      }
    }
    if (this.state.emisoraSeleccionada !== null) {
      subTab = index;
      // canceladas = false;
      this.setState({
        subTab: subTab
      });
      this.changeHistory(this.state.instrumento, this.state.tipo_valor, this.state.sector, this.state.sub_sector, this.state.ramo, this.state.sub_ramo, subTab, activePage, this.state.biva, this.state.canceladas);
    }
    this.setState({
      indexSelected: index,
      activePage: activePage
    });
  };

  onChangeTabInicial = (index = 0, consulta = false) => {
    let activePage = null;
    let bivaFilter = true;
    let canceladasFilter = true;
    let tabsDesabilitados = null;
    if (this.state.indexSelectedInicial === index) {
      activePage = this.state.activePage;
    } else {
      activePage = 1;
    }
    if (index === 0) {
      bivaFilter = false;
      canceladasFilter = false;
    } else if (index === 1) {
      bivaFilter = true;
      canceladasFilter = false;
    } else if (index === 2) {
      bivaFilter = false;
      canceladasFilter = true;
      tabsDesabilitados = [1, 3];
    }
    /*     this.props.history.push(
          `?instrumento=${this.state.instrumento}&tipo_valor=${this.state.tipo_valor}&sector=${this.state.sector}&sub_sector=${
            this.state.sub_sector
          }&ramo=${this.state.ramo}&sub_ramo=${this.state.sub_ramo}&emisora_id=${
            this.state.emisoraSeleccionada
          }&canceladas=${canceladasFilter}&biva=${bivaFilter}&subTab=${this.state.subTab?this.state.subTab:null}&page=${this.state.activePage}`
        ); */

    if (consulta) {
      this.changeHistory(null, null, null, null, null, null, 0, 1, bivaFilter, canceladasFilter);
      this.setState({
        instrumento: null,
        tipo_valor: null,
        sector: null,
        sub_sector: null,
        ramo: null,
        sub_ramo: null
      });
    }
    this.setState({
      indexSelectedInicial: index,
      activePage: activePage,
      loader: true,
      biva: bivaFilter,
      canceladas: canceladasFilter,
      tabsDesabilitados: tabsDesabilitados,
      currentTab: index
    });
  };

  updateGrandparent = (value, canceladas = null, biva = null, subTab = null) => {
    let subtab = subTab === null ? 0 : subTab;
    this.setState({
      emisoraSeleccionada: value,
      indexSelected: subtab,
      loader: true,
      subTab: subtab
    });

    let cancelada = canceladas;
    let bivas = biva;
    if (this.state.canceladas) {
      cancelada = this.state.canceladas;
    }
    if (this.state.biva) {
      bivas = this.state.biva;
    }

    this.props.history.push(
      `?instrumento=${this.state.instrumento}&tipo_valor=${this.state.tipo_valor}&sector=${this.state.sector}&sub_sector=${
      this.state.sub_sector
      }&ramo=${this.state.ramo}&sub_ramo=${
      this.state.sub_ramo
      }&emisora_id=${value}&canceladas=${cancelada}&biva=${bivas}&subTab=${subtab}&page=${this.state.activePage}`
    );

    this.props.onInitValoresListados(1, 0, value);
    this.props.onInitEmisorasDetail(value);
    this.props.onInitEmisorasIntegrantes(value);
  };

  onSetPeriodoIndicador = indicadores => {
    const subFiltros = { ...this.state.subFiltros };
    subFiltros.indicadores = indicadores;

    this.setState({
      subFiltros: subFiltros
    });
  };

  onSetIsin = isin => {
    this.setState({
      isin_seleccionado: isin
    });
  };

  onSetNombreEmision = nombre => {
    this.setState({
      nombreEmision: nombre,
      loader: false
    });
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      if (this.state.filteredBy === 0) {
        let params = {
          clave: this.state.claveFilter,
          size: this.state.itemsPerPage,
          page: pageNumber - 1
        };
        this.props.onFetchAllEmisorasByClave(params);
      } else {
        let params = {
          size: this.state.itemsPerPage,
          page: pageNumber - 1,
          instrumento: this.state.instrumento,
          tipo_valor: this.state.tipo_valor,
          sector: this.state.sector,
          sub_sector: this.state.sub_sector,
          ramo: this.state.ramo,
          sub_ramo: this.state.sub_ramo,
          biva: this.state.biva,
          canceladas: this.state.canceladas,
          clave: this.state.claveEmisora //clave
        };
        this.props.onFilterEmisoras(params);
      }

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onSetActivePage = activePage => {
    this.setState({
      activePage: activePage
    });
  };

  onSetFilters = ({ instrumento, tipo_valor, sector, sub_sector, ramo, sub_ramo, page, reset = false }) => {
    this.setState({
      instrumento: instrumento ? [...instrumento] : null,
      tipo_valor: tipo_valor ? [...tipo_valor] : null,
      sector: sector,
      sub_sector: sub_sector,
      ramo: ramo,
      sub_ramo: sub_ramo,
      activePage: page
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(instrumento, tipo_valor, sector, sub_sector, ramo, sub_ramo, 0, page, this.state.biva, this.state.canceladas);
    }
  };

  onSetFilterClave = clave => {
    this.setState({
      claveFilter: clave,
      loader: true
    });
  };

  onSetFilteredBy = type => {
    this.setState({
      filteredBy: type
    });
  };

  onSetClaveEmisoraFilter = value => {
    this.setState({
      claveEmisora: value
    });
  };

  onDeleteEmisoraSeleccionadaHandler = () => {
    this.setState({
      claveEmisora: null,
      emisoraDetalle: null,
      nombreEmision: null,
      indiceIsin: null,
      loader: true,
      activePage: 1
    });
  };

  changeHistory(
    instrumento = null,
    tipo_valor = null,
    sector = null,
    sub_sector = null,
    ramo = null,
    sub_ramo = null,
    subTab = 0,
    page = 1,
    biva = null,
    canceladas = null
  ) {
    this.props.history.push(
      `?instrumento=${instrumento}&tipo_valor=${tipo_valor}&sector=${sector}&sub_sector=${sub_sector}&ramo=${ramo}&sub_ramo=${sub_ramo}&emisora_id=${
      this.state.emisoraSeleccionada
      }&canceladas=${canceladas}&biva=${biva}&subTab=${subTab}&page=${page ? page : this.state.activePage}`
    );
    let params = {
      size: this.state.itemsPerPage,
      page: page > 0 ? page - 1 : 0,
      instrumento: instrumento,
      tipo_valor: tipo_valor,
      sector: sector,
      sub_sector: sub_sector,
      ramo: ramo,
      sub_ramo: sub_ramo,
      biva: biva,
      canceladas: canceladas
    };
    this.props.onFilterEmisoras(params);
    this.setState({ loader: true });
  }

  changeHistoryReset() {
    this.props.history.push(
      `?instrumento=${null}&tipo_valor=${null}&sector=${null}&sub_sector=${null}&ramo=${null}&sub_ramo=${null}&emisora_id=${null}&canceladas=${null}&biva=${null}&subTab=${null}&page=1`
    );
    this.setState({
      loader: true
    });
    let params = {
      size: this.state.itemsPerPage,
      page: 0,
      instrumento: null,
      tipo_valor: null,
      sector: null,
      sub_sector: null,
      ramo: null,
      sub_ramo: null,
      biva: this.state.biva,
      canceladas: this.state.canceladas
    };
    this.props.onFilterEmisoras(params);
  }

  render() {
    let dataToShow = null;
    let contenidoMostrar = null;
    let descarga_url = base_url;

    descarga_url = `${descarga_url}?biva=${this.state.biva}`;
    descarga_url = `${descarga_url}&canceladas=${this.state.canceladas}`;
    descarga_url = this.state.instrumento && this.state.instrumento.length > 0 ? `${descarga_url}&tipoInstrumento=${this.state.instrumento}`: descarga_url;
    descarga_url = this.state.tipo_valor && this.state.tipo_valor.length > 0 ? `${descarga_url}&tipoValor=${this.state.tipo_valor}`: descarga_url;
    descarga_url = this.state.sector ? `${descarga_url}&sector=${this.state.sector}`: descarga_url;
    descarga_url = this.state.sub_sector ? `${descarga_url}&subsector=${this.state.sub_sector}`: descarga_url;
    descarga_url = this.state.ramo ? `${descarga_url}&ramo=${this.state.ramo}`: descarga_url;
    descarga_url = this.state.sub_ramo ? `${descarga_url}&subramo=${this.state.sub_ramo}`: descarga_url;

    const resultadoDescarga = (
      <div className={"row margen_30"}>
        <div className={[classes.card_header, classes.titulo_60pct].join(" ")}>
          <span className={["regular montse texto_16 "].join(" ")} />
        </div>
        <div className={[classes.card_header2, classes.titulo_40pct].join(" ")}>
          <span
            className={[classes.titulo_75pct, "texto_16 montse regular"].join(
              " "
            )}
          >
            <a
              href={descarga_url}
              target="_blank"
              rel="noopener noreferrer"
              className="row"
            >
              <span
                className={[
                  classes.titulo,
                  "texto_16 montse color_azul regular"
                ].join(" ")}
              >
                {getLan() === "es" ? "Listado de emisoras" : "Issuers listing"}
                <i
                  className={[
                    "fas fa-arrow-alt-circle-down fa-lg",
                    "Boton__icono_derecho__25mg6"
                  ].join(" ")}
                />
              </span>
              <span
                className={[
                  classes.titulo_ZIP,
                  "semi_bold montse texto_10"
                ].join(" ")}
              >
                {getLan() === "es" ? "Formato XLS" : "XLS file"}
              </span>
            </a>
          </span>
        </div>
      </div>
    );

    let tabMostrar = null;
    const numRows = this.props.emisoras ? this.props.emisoras.totalElements : 0;

    let emisoras = this.props.emisoras && this.props.emisoras.content ? this.props.emisoras.content : [];
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    if (emisoras.length > 0) {
      dataToShow = (
        <Accordion
          datos={emisoras}
          nombre="emisorasShow"
          icon={""}
          tipo={7}
          subtipo={2}
          updateGrandparent={this.updateGrandparent.bind(this)}
        />
      );
    } else if (emisoras.length === 0) {
      dataToShow = (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
    }

    const emisorasInscritas = (
      <React.Fragment>
        {resultadoDescarga}
        <br />
        {dataToShow}
        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
    //Bolsa, Fecha Listado, Telefono, Sitio Web
    const emisoraInfo = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras1" icon={""} tipo={8} subtipo={1} />;
    //Fecha de cancelación
    let emisoraInfoCancel = null;
    if (this.props.emisoraDetalle && this.props.emisoraDetalle.fechaCancelacion) {
      emisoraInfoCancel = <Accordion datos={this.props.emisoraDetalle} nombre="emisorasCanceladas" icon={""} tipo={9} subtipo={6} />;
    }
    // Ramo, SubRamo, Sector, SubSector
    const emisoraInfo2 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras2" icon={""} tipo={8} subtipo={2} />;
    // Actividad Economica
    const emisoraInfo3 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras3" icon={""} tipo={9} subtipo={1} />;
    // Direccion
    const emisoraInfo4 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras4" icon={""} tipo={9} subtipo={0} />;
    // Consejeros y Funcionarios
    const emisoraInfo5 = (
      <Accordion
        integrantes={this.props.emisoraIntegrantes}
        nombre="emisoraIntegrantes"
        icon={""}
        tipo={10}
        titulo={getLan() === "es" ? "Ver Integrantes" : "View members"}
      />
    );
    //Razon Comercial
    const emisoraInfo0 = <Accordion datos={this.props.emisoraDetalle} nombre="emisoras" icon={""} tipo={9} subtipo={5} />;

    let emisoraCotizacion = null;
    // if (this.state.indiceIsin) {
    //accordion que estara sobre arriba de la grafica
    emisoraCotizacion = (
      <div className="margin_top_30">
        <Accordion
          key={this.state.nombreEmision}
          datos={this.state.indiceIsin ? this.state.indiceIsin : [0]}
          titulo={this.state.nombreEmision ? this.state.nombreEmision : null}
          razonSocial={this.state.emisoraDetalle ? this.state.emisoraDetalle.razonSocial : null}
          indice_emisora={this.state.indiceIsin}
          fechaListado={this.state.emisoraDetalle !== null ? this.state.emisoraDetalle.fechaListado : null}
          nombre="emisoras"
          icon={""}
          tipo={11}
        />
      </div>
    );
    // }

    let resumenFinanciero = null;
    if (this.props.financialReport) {
      resumenFinanciero = <Table tipo={1} datos={this.props.financialReport} />;
    }
    let cotizacion = null;

    if (this.state.isin_seleccionado !== null) {
      cotizacion = <Table tipo={2} datos={this.props.ratiosReport} />;
    } else {
      cotizacion = null;
    }
    let estatus = null;
    if (this.state.emisoraDetalle) {
      if (this.props.emisoraDetalle.estatus === "Cancelada" || this.props.emisoraDetalle.estatus === "Listado preventivo") {
        if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada !== null) {
          estatus = (
            <span
              className={[
                classes.titulo_25pct,
                setColorEstatus(this.props.emisoraDetalle.estatus),
                "texto_14 nunito semi_bold"
              ].join(" ")}
            >
              <i className={["fas fa-circle fa-xs"].join(" ")} />
              &nbsp;
              {this.props.emisoraDetalle.estatus}
            </span>
          );
        } else {
          estatus = null;
        }
      }
    }
    tabMostrar = (
      <div>
        <div className={["width_cien", classes.vertical_div].join(" ")}>
          <div className={[classes.titulo_25pct, classes.vertical].join(" ")}>
            <div className={["width_cien", classes.vertical_div].join(" ")}>
              <img
                className={["width_cien", classes.vertical_img].join(" ")}
                src={this.props.emisoraDetalle ? this.props.emisoraDetalle.logo : null}
                alt=""
              />
            </div>
          </div>
          <div className={[classes.titulo_75pct, classes.vertical].join(" ")}>
            <div className={["width_cien", classes.vertical_div].join(" ")}>
              <span className={["texto_30 nunito", classes.vertical].join(" ")}>
                {this.props.emisoraDetalle ? this.props.emisoraDetalle.razonSocial : null}
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="row margen_top_30">
          <br />
          <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
            {getLan() === "es" ? "Clave" : "Ticker"}:&nbsp;
            <span className={["texto_16 nunito semi_bold color_azul", classes.myCol].join(" ")}>
              {this.props.emisoraDetalle ? this.props.emisoraDetalle.clave : null}
            </span>
          </div>
          <div className={["col sin_padding", classes.myCol].join(" ")}>
            <Tabs
              key={this.state.tabsDesabilitados}
              data={this.state.tabsInfo}
              tipo={1}
              sub={true}
              activaTabInicial={this.state.subTab}
              tabsDesabilitados={this.state.tabsDesabilitados}
              wasFilteredBySearch={this.state.wasFilteredBySearch}
              clicked={i => this.onChangeItemSelected(i)}
            />
          </div>
        </div>
        <br />
        {estatus}
      </div>
    );

    if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada === null) {
      contenidoMostrar = <div className="margen_50">{emisorasInscritas}</div>;
      tabMostrar = (
        <div className="row margen_top_30">
          <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
            {getLan() === "es" ? "Mostrar" : "View"}:
          </div>
          <div className={["col sin_padding", classes.myCol].join(" ")}>
            <Tabs
              data={this.state.tabsEmisoras}
              tipo={1}
              clicked={i => this.onChangeTabInicial(i, true)}
              activaTabInicial={this.state.activaTabInicial}
            />
          </div>
        </div>
      );
    } else if (this.state.indexSelected === 1 && this.state.emisoraSeleccionada === null) {
      contenidoMostrar = <div className="margen_50">{emisorasInscritas}</div>;

      tabMostrar = (
        <div className="row margen_top_30">
          <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
            {getLan() === "es" ? "Mostrar" : "View"}:
          </div>
          <div className={["col sin_padding", classes.myCol].join(" ")}>
            <Tabs
              data={this.state.tabsEmisoras}
              tipo={1}
              clicked={i => this.onChangeTabInicial(i, true)}
              activaTabInicial={this.state.indexSelected}
            />
          </div>
        </div>
      );
    } else if (this.state.indexSelected === 2 && this.state.emisoraSeleccionada === null) {
      contenidoMostrar = <div className="margen_50">{emisorasInscritas}</div>;

      tabMostrar = (
        <div className="row margen_top_30">
          <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
            {getLan() === "es" ? "Mostrar" : "View"}:
          </div>
          <div className={["col sin_padding", classes.myCol].join(" ")}>
            <Tabs
              data={this.state.tabsEmisoras}
              tipo={1}
              clicked={i => this.onChangeTabInicial(i, true)}
              activaTabInicial={this.state.indexSelected}
            />
          </div>
        </div>
      );
    } else if (this.state.indexSelected === 0 && this.state.emisoraSeleccionada !== null) {
      contenidoMostrar = (
        <div className="margen_top_50">
          {emisoraInfo0} {/* Razon Comercial*/}
          {emisoraInfo} {/* Bolsa, Fecha Listado, Telefono, Sitio Web */}
          {emisoraInfoCancel} {/* fecha cancelación */}
          {emisoraInfo2} {/* Ramo, SubRamo, Sector, SubSector */}
          {emisoraInfo3} {/* Actividad Economica */}
          {emisoraInfo4} {/* Direccion */}
          {emisoraInfo5} {/* Consejeros y Funcionarios */}
          {resumenFinanciero} {/* Resumen Financiero */}
        </div>
      );
    } else if (this.state.indexSelected === 1 && this.state.emisoraSeleccionada !== null) {
      const bolsa = (
        <span className={[classes.titulo_75pct, "texto_30 nunito"].join(" ")}>
          {/* {this.props.emisoraDetalle ? this.props.emisoraDetalle.bolsa : null} */}
          BIVA
        </span>
      );
      let graficas = null;
      if (this.state.canceladas === true) {
        graficas = null;
        contenidoMostrar = null;
      } else {
        if (this.state.isin_seleccionado && this.state.nombreEmision && this.props.emisoraDetalle) {
          graficas = (
            <>
              <Chart
                isin={this.state.isin_seleccionado}
                nombreEmision={this.state.nombreEmision}
                claveEmisora={this.props.emisoraDetalle.clave}
              />
              <span className={["texto_14 montse regular", classes.margin_left].join(" ")}>
                {getLan() === "es"
                  ? "*La información presentada cuenta con un retraso de 15-20 minutos y pertenece a la operación en BIVA."
                  : "*This information is delayed by 20 minutes and belongs to BIVA."}
              </span>
            </>
          );
        }
        contenidoMostrar = (
          <div className="margen_50">
            {bolsa}
            <SubFiltersCotizacion
              key={this.state.emisoraSeleccionada}
              emisoraId={this.state.emisoraSeleccionada}
              emisoraDetalle={this.state.emisoraDetalle ? this.state.emisoraDetalle.clave : this.props.emisoraDetalle ? this.props.emisoraDetalle.clave : null}
              setNombreEmision={nombreEmision => this.onSetNombreEmision(nombreEmision)}
              setIsin={isin => this.onSetIsin(isin)}
              nombreEmision={this.state.nombreEmision}
            />
            {/* {emisoraCotizacion} */}
            {graficas}
            {cotizacion}
          </div>
        );
      }
    } else if (this.state.indexSelected === 2 && this.state.emisoraSeleccionada !== null) {
      contenidoMostrar = <DocumentosEmisoras emisoraSeleccionada={this.state.emisoraSeleccionada} biva={this.state.biva} canceladas={this.state.canceladas} />;
    } else if (this.state.indexSelected === 3 && this.state.emisoraSeleccionada !== null) {
      if (this.state.canceladas === true) {
        contenidoMostrar = null;
      } else {
        contenidoMostrar = <ValoresListadosEmisoras emisoraSeleccionada={this.state.emisoraSeleccionada} biva={this.state.biva} canceladas={this.state.canceladas} />;
      }
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <React.Fragment>
          {loader}

          <h1 className={"texto_42 nunito regular"}>
            {" "}
            <FormattedHTMLMessage id="empresas.emisorasinscritas.titulo1" defaultMessage=" " />
          </h1>
          <div className="margen_50 margin_bottom_20 texto_16 montse regular">
            <FormattedHTMLMessage id="empresas.emisorasinscritas.introduccion1" defaultMessage=" " />

            {/* Selecciona el perfil de una emisora para consultar su información
          general, cotización, documentos y valores listados. */}
          </div>
          <div className="row">
            <div className="col sin_padding">
              <FilterEmisoras
                setInitTabs={(index, emisora, wasFilteredBySearch) => this.onSetInitTabs(index, emisora, wasFilteredBySearch)}
                setActivePage={activePage => this.onSetActivePage(activePage)}
                setFilters={filterParams => this.onSetFilters(filterParams)}
                currentTab={this.state.indexSelectedInicial}
                updateEmisorasParent={clave => this.updateGrandparent(clave)}
                emisora_id={this.state.emisoraSeleccionada}
                biva={this.state.biva}
                canceladas={this.state.canceladas}
                setFilterClave={clave => this.onSetFilterClave(clave)}
                wasFilteredBy={type => this.onSetFilteredBy(type)}
                claveEmisora={this.state.claveEmisora}
                onDeleteEmisoraSelecionada={() => this.onDeleteEmisoraSeleccionadaHandler()}
                borraClaveFiltro={param => this.onSetClaveEmisoraFilter(param)}
              />
            </div>
          </div>
          <br />
          {tabMostrar}
          <br />
          <div className="margen_50">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(actions.initIntl(39));
    },
    onFilterEmisoras: params => {
      dispatch(actions.filterEmisoras(params));
    },
    onInitEmisorasDetail: emisoraId => {
      dispatch(actions.initEmisorasDetail(emisoraId));
    },
    onInitEmisorasIntegrantes: emisoraId => {
      dispatch(actions.initEmisorasIntegrantes(emisoraId));
    },
    onInitValoresListados: (size, page, emisoraId) => {
      dispatch(actions.initValoresListados(size, page, emisoraId, true));
      dispatch(actions.initValoresListadosMin(emisoraId));
    },
    onInitBancoInformacion: params => {
      dispatch(actions.initBancoInformacion(params));
    },
    onFetchRssinputs: () => {
      dispatch(actions.fetchAllRssInputs());
    },
    onFetchAllEmisorasByClave: params => {
      dispatch(actions.fetchAllEmisorasByClave(params));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    emisoras: state.emisoras.emisoras,
    bancoInformacion: state.emisoras.bancoInformacion,
    emisoraDetalle: state.emisoras.emisoraDetalle,
    emisoraIntegrantes: state.emisoras.emisoraIntegrantes,
    documentos: state.emisoras.documentos,
    valoresListados: state.emisoras.valoresListados,
    valoresListadosMin: state.emisoras.valoresListadosMin,
    financialReport: state.emisoras.financialReport,
    ratiosReport: state.emisoras.ratiosReport,
    indiceIsin: state.emisoras.indiceIsin,
    tipo_informacion: state.tipoInformacion.tipo_informacion,
    graficaInstrument: state.emisoras.graficaInstrument
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Emisoras)
);

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";

import FilterAvisosListados from "../../../components/ui/filterAvisosListados/FilterAvisosListados";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";

class AvisosListado extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    claveCotizacion: null,
    tipoAvisos: null,
    fechaInicio: null,
    fechaFin: null,
    wasFiltered: false,
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl(3);
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }

    if (prevProps.avisos_listados !== this.props.avisos_listados) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let avisos_listados = null;

    if (nextProps.avisos_listados && nextProps.avisos_listados.content) {
      avisos_listados = nextProps.avisos_listados.content;
    }
    let resource = avisos_listados;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.avisos_listados;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
      this.changeHistory(
        this.state.claveCotizacion,
        this.state.fechaInicio,
        this.state.fechaFin,
        this.state.tipoAvisos,
        pageNumber
      );
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let claveCotizacion =
      paramsString["claveCotizacion"] !== undefined && paramsString["claveCotizacion"] !== "null"
        ? paramsString["claveCotizacion"]
        : null;
    let fechaInicio =
      paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
    let fechaFin =
      paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
    let tipoAvisoListado =
      paramsString["tipoAvisoListado"] !== undefined && paramsString["tipoAvisoListado"] !== "null"
        ? paramsString["tipoAvisoListado"].includes(",")
          ? paramsString["tipoAvisoListado"].split(",")
          : paramsString["tipoAvisoListado"]
        : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    let params = {
      size: this.state.itemsPerPage,
      page: parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      claveCotizacion: claveCotizacion,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      tipoAvisoListado: tipoAvisoListado
    };
    this.props.onInitFilterAvisosListado(params);

    this.setState({
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
      claveCotizacion: claveCotizacion,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      tipoAvisos: tipoAvisoListado
    });
  }

  onSetClaveCotizacion = clave_cotizacion => {
    this.setState({
      claveCotizacion: clave_cotizacion
    });
  };

  onSetTipoAvisos = tipo_avisos => {
    this.setState({
      tipoAvisos: tipo_avisos
    });
  };

  onSetFechaIncio = fecha_inicio => {
    this.setState({
      fechaInicio: fecha_inicio
    });
  };

  onSetFechaFin = fecha_fin => {
    this.setState({
      fechaFin: fecha_fin
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered,
      loader: true
    });
  };
  onSetParams = ({ claveCotizacion, fechaInicio, fechaFin, tipoAvisoListado, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claveCotizacion, fechaInicio, fechaFin, tipoAvisoListado, page);
    }
  };

  changeHistory(claveCotizacion = null, fechaInicio = null, fechaFin = null, tipoAvisoListado = null, page = null) {
    this.props.history.push(
      `?claveCotizacion=${claveCotizacion}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&tipoAvisoListado=${tipoAvisoListado}&page=${
        page ? page : this.state.activePage
      }`
    );

    this.setState({
      loader: true
    });
    const params = {
      size: this.state.itemsPerPage,
      page: page > 0 ? page - 1 : 0,
      claveCotizacion: claveCotizacion,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      tipoAvisoListado: tipoAvisoListado
    };

    this.props.onInitFilterAvisosListado(params);

    // this.props.onInitRequisitos(params);
  }

  changeHistoryReset() {
    this.props.history.push(`?claveCotizacion=${null}&fechaInicio=${null}&fechaFin=${null}&tipoAvisoListado=${null}&page=1`);

    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      claveCotizacion: null,
      fechaInicio: null,
      fechaFin: null,
      tipoAvisoListado: null
    };

    this.props.onInitFilterAvisosListado(params);

    // this.props.onInitRequisitos(params);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let contenidoMostrar = null;
    let avisos_listados = null;
    if (this.props.avisos_listados && this.props.avisos_listados.content) {
      avisos_listados = this.props.avisos_listados.content;
      const numRows = this.props.avisos_listados ? this.props.avisos_listados.totalElements : 0;

      contenidoMostrar = (
        <React.Fragment>
          <Accordion datos={avisos_listados} nombre="avisos_listados" icon={""} tipo={12} subtipo={8} />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );
    } else if (this.state.wasFiltered) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda.": "No results found."}</p>;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}

          <h1 className={["nunito", "texto_42"].join(" ")}>
            <FormattedMessage id="avisosyreportes.listado.avisosdelistado.titulo" defaultMessage=" " />
          </h1>
          <br />
          <FilterAvisosListados
            setClaveCotizacion={clave_cotizacion => this.onSetClaveCotizacion(clave_cotizacion)}
            setTipoAvisos={tipo_avisos => this.onSetTipoAvisos(tipo_avisos)}
            setFechaInicio={fecha_inicio => this.onSetFechaIncio(fecha_inicio)}
            setFechaFin={fecha_fin => this.onSetFechaFin(fecha_fin)}
            setPage={page => this.onSetPage(page)}
            setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
            setParams={filterParams => this.onSetParams(filterParams)}
          />
          <div className="margen_50 margin_bottom_200">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitFilterAvisosListado: params => {
      dispatch(actions.filterAvisosListados(params));
    },
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    }
  };
};

const mapStateToProps = state => {
  return {
    avisos_listados: state.avisosReportes.avisos_listados,
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvisosListado);

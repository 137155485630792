import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";

import FilterOperacionesReportes from "../../../components/ui/filterOperacionesReportes/FilterOperacionesReportes";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";

class AvisosReportes extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: false,
    palabraClave: null,
    tipoArchivo: null,
    fechaInicio: null,
    fechaFin: null,
    wasFiltered: false,
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl(3);
    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      keyword: null,
      fileTypes: null,
      from: null,
      to: null
    };
    this.props.onFilterOperacionesReportes(params);
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let operacionesReportes = null;

    this.setState({
      loader: false
    });
    if (
      nextProps.operacionesReportes &&
      nextProps.operacionesReportes.content
    ) {
      operacionesReportes = nextProps.operacionesReportes.content;
    }
    let resource = operacionesReportes;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (
    resource = null,
    pageNumber = null,
    seleccionado = null
  ) => {
    let data = resource ? resource : this.props.operacionesReportes;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      const params = {
        size: this.state.itemsPerPage,
        page: pageNumber - 1,
        keyword: this.state.palabraClave,
        fileTypes: this.state.tipoArchivo,
        from: this.state.fechaInicio,
        to: this.state.fechaFin
      };
      this.props.onFilterOperacionesReportes(params);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onSetPalabraClave = palabra_calve => {
    this.setState({
      palabraClave: palabra_calve
    });
  };

  onSetTipoDeArchivo = tipo_archivo => {
    this.setState({
      tipoArchivo: tipo_archivo
    });
  };

  onSetFechaIncio = fecha_inicio => {
    this.setState({
      fechaInicio: fecha_inicio
    });
  };

  onSetFechaFin = fecha_fin => {
    this.setState({
      fechaFin: fecha_fin
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader loaderDisable={disable => this.setState({ loader: disable })} />
      );
    }
    let contenidoMostrar = null;
    let operacionesReportes = null;
    if (
      this.props.operacionesReportes &&
      this.props.operacionesReportes.content
    ) {
      operacionesReportes = this.props.operacionesReportes.content;
      const numRows = this.props.operacionesReportes
        ? this.props.operacionesReportes.totalElements
        : 0;

      contenidoMostrar = (
        <React.Fragment>
          {loader}

          <Accordion
            datos={operacionesReportes}
            nombre="operacionesReportes"
            icon={""}
            tipo={12}
            subtipo={9}
          />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );
    } else if (this.state.wasFiltered) {
      contenidoMostrar = (
        <p className="montse texto_16 regular">
          {getLan() === "es"
            ? "No existen resultados para la búsqueda."
            : "No results found."}
        </p>
      );
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={["nunito", "texto_42"].join(" ")}>
            <FormattedMessage
              id="avisosyreportes.operaciones.reportes.titulo"
              defaultMessage=" "
            />
          </h1>
          <br />
          <FilterOperacionesReportes
            setPalabraClave={palabra_calve =>
              this.onSetPalabraClave(palabra_calve)
            }
            setTipoDeArchivo={tipo_archivo =>
              this.onSetTipoDeArchivo(tipo_archivo)
            }
            setFechaInicio={fecha_inicio => this.onSetFechaIncio(fecha_inicio)}
            setFechaFin={fecha_fin => this.onSetFechaFin(fecha_fin)}
            setPage={page => this.onSetPage(page)}
            setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
          />
          <div className="margen_50 margin_bottom_200">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFilterOperacionesReportes: params => {
      dispatch(actions.filterOperacionesReportes(params));
    },
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    }
  };
};

const mapStateToProps = state => {
  return {
    operacionesReportes: state.avisosReportes.operaciones_reportes,
    diccionario: state.intl.diccionario
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AvisosReportes);

import React, { Component } from "react";
import classes from "./DocumentosDefinitivos.css";
import { connect } from "react-redux";
import Accordion from "../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../stores/actions/index";
import { withRouter, NavLink } from "react-router-dom";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";

import FilterDocumentosDefinitivos from "../../../../components/ui/filterDocumentosDefinitivos/FilterDocumentosDefinitivos";
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import loading from "../../../../assets/gif/loader.gif";
import TabASG from "../../../sustentable/tabASG/TabASG";
import TituloAsg from "../../../sustentable/ui/tituloAsg/TituloAsg";

class DocumentosDefinitivos extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    documentosDefinitivos: [],
    resourceList: [],
    loader: true,
    claves_cotizacion: null,
    claves_label: null,
    claves_warrant: null,
    instrumentos: null,
    tipo_documentos: null,
    serie: null,
    wasFiltered: false,
    messages: null,
    lan: getLocal(),
    emisoraSic: false,
    emisoraFondo: false,
    asg : false,
    tiposBonos:[],
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (prevProps.documentosDefinitivos !== this.props.documentosDefinitivos) {
      this.setState({
        loader: false,
        documentosDefinitivos: this.props.documentosDefinitivos
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let documentosDefinitivos = null;

    if (nextProps.documentosDefinitivos && nextProps.documentosDefinitivos.content) {
      documentosDefinitivos = nextProps.documentosDefinitivos.content;
    }

    let resource = documentosDefinitivos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.state.documentosDefinitivos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });

      this.changeHistory(
        this.state.claves_cotizacion,
        this.state.instrumentos,
        this.state.serie,
        this.state.tipo_documentos,
        this.state.claves_warrant,
        pageNumber,
        this.state.tiposBonos
      );
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {

      if (this.state.claves_cotizacion) {
        this.setState({
          loader: true
        });
      } else {
        this.setState({
          loader: false,
          documentosDefinitivos: []
        });
      }

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let emisora = paramsString["emisora"] !== undefined && paramsString["emisora"] !== "null" ? paramsString["emisora"] : null;
    let clave_label = paramsString["clave"] !== undefined && paramsString["clave"] !== "null" ? paramsString["clave"] : null;
    let idTipoInstrumento =
      paramsString["tipoInstrumento"] !== undefined && paramsString["tipoInstrumento"] !== "null"
        ? paramsString["tipoInstrumento"]
        : null;
    let serie = paramsString["serie"] !== undefined && paramsString["serie"] !== "null" ? paramsString["serie"] : null;
    let idTipoDocumentoListado =
      paramsString["tipoDocumento"] !== undefined &&
        paramsString["tipoDocumento"] !== "null" &&
        paramsString["tipoDocumento"] !== ""
        ? paramsString["tipoDocumento"].split(",")
        : null;
    let claveWarrant =
      paramsString["claveWarrant"] !== undefined && paramsString["claveWarrant"] !== "null"
        ? paramsString["claveWarrant"]
        : null;
    let page = paramsString["page"] === "null" ? 1 : parseInt(paramsString["page"]);
    let tipoBono = paramsString["tipoBono"] === "null" || paramsString["tipoBono"] === undefined ? null : paramsString["tipoBono"].split(",");  

    tipoBono = this.onInitTipoBonoASG(tipoBono, this.props.location.pathname);

    this.setState({
      emisora: emisora,
      claves_label: clave_label,
      tipo_documentos: idTipoDocumentoListado,
      claves_cotizacion: emisora,
      claves_warrant: claveWarrant,
      instrumentos: idTipoInstrumento,
      activePage: page ,
      tiposBonos: tipoBono,
    });

    let params = {
      size: this.state.itemsPerPage,
      page: page - 1,
      idEmisora: emisora,
      idTipoInstrumento: idTipoInstrumento,
      serie: serie,
      idTipoDocumentoListado: idTipoDocumentoListado,
      claveWarrant: claveWarrant,
      tipoBono: tipoBono,
    };

    this.props.onFilterDocumentosDefinitivos(params);
  }
  onInitTipoBonoASG(tipoBono, path){
    let asg = false;
    if( path === "/biva-sostenible/documentos-definitivos"){
      asg = true;
      if (tipoBono === null) 
        tipoBono=[1,2,3,4];
    }
    this.setState({
      tiposBonos: tipoBono,
      asg:asg,
    });

    return tipoBono;

  }

  onSetClaveCotizacionFilter = (claves_cotizacion = null) => {
    this.setState({
      claves_cotizacion: claves_cotizacion
    });
    this.changeHistory(
      claves_cotizacion === null || claves_cotizacion === "" ? null : claves_cotizacion,
      null,
      null,
      null,
      null,
      null,
      this.state.tiposBonos
    );
  };

  onSetClaveCotizacionLabelFilter = (clave_label = null) => {
    this.setState({
      claves_label: clave_label
    });
  };

  onSetClaveWarrantFilter = (claves_warrant = null) => {
    this.setState({
      claves_warrant: claves_warrant
    });
    this.changeHistory(null, null, null, null, claves_warrant === null || claves_warrant === "" ? null : claves_warrant, null,this.state.tiposBonos);
  };

  onSetInstrumentos = (instrumento = null) => {
    this.setState({
      instrumentos: instrumento
    });

    this.changeHistory(null, instrumento === null || instrumento === "" ? null : instrumento, null, null, null, null, this.state.tiposBonos);
  };

  onSetTipoDocumento = (tipoDocumento = null) => {
    this.setState({
      tipo_documentos: tipoDocumento
    });
    this.changeHistory(null, null, null, tipoDocumento === null || tipoDocumento === "" ? null : tipoDocumento, null, null,this.state.tiposBonos);
  };

  onSetSerie = (serie = null) => {
    this.setState({
      serie: serie
    });
    this.changeHistory(null, null, serie === null || serie === "" ? null : serie, null, null, null,this.state.tiposBonos);
  };

  onWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
    this.changeHistory(null, null, null, null, null, page, this.state.tiposBonos);
  };

  onSetEmisoraSic = emisoraSic => {
    this.setState({
      emisoraSic: emisoraSic
    });
  };

  onSetEmisoraFondo = emisoraFondo => {
    this.setState({
      emisoraFondo: emisoraFondo
    });
  };

  onSetParams = (
    claves_cotizacion = null,
    instrumento = null,
    serie = null,
    tipoDocumento = null,
    claves_warrant = null,
    page = null,
    wasFiltered = null,
    clave_label = null,
    reset = false
  ) => {
    this.setState({
      claves_cotizacion: claves_cotizacion,
      instrumentos: instrumento,
      claves_warrant: claves_warrant,
      tipo_documentos: tipoDocumento,
      serie: serie,
      activePage: page,
      clave_label: clave_label,
      emisoraSeleccionada:clave_label,
      wasFiltered: wasFiltered
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claves_cotizacion, instrumento, serie, tipoDocumento, claves_warrant, page, this.state.tiposBonos);
    }
  };

  onSetTiposBono= (tipos) => {
    this.changeHistory(this.state.emisoraSeleccionada,this.state.instrumentos,this.state.serie,this.state.tipo_documentos,this.state.claves_warrant,1,tipos);
    this.onSetPage(1);

    this.setState({
      tiposBonos: tipos,
    });
  };

  changeHistory(
    emisora = null,
    tipoInstrumento = null,
    serie = null,
    tipoDocumento = null,
    claveWarrant = null,
    page = null,
    tipoBono= null
  ) {
    this.props.history.push(
      `?emisora=${emisora}&clave=${emisora}&tipoInstrumento=${tipoInstrumento}&serie=${serie}&tipoDocumento=${tipoDocumento}&claveWarrant=${claveWarrant}&tipoBono=${tipoBono}&page=${page}`
    );

    let params = {
      size: this.state.itemsPerPage,
      page: page >= 1 ? page - 1 : 0,
      idEmisora: emisora,
      idTipoInstrumento: tipoInstrumento,
      serie: serie,
      idTipoDocumentoListado: tipoDocumento,
      claveWarrant: claveWarrant,
      tipoBono: tipoBono,
    };
    if (emisora) {
      this.setState({
        loader: true
      });
      this.props.onFilterDocumentosDefinitivos(params);
    } else {
      this.setState({
        loader: false,
        documentosDefinitivos: []
      });
    }
  }

  changeHistoryReset() {
    this.props.history.push(
      `?emisora=${null}&tipoInstrumento=${null}&serie=${null}&tipoDocumento=${null}&claveWarrant=${null}&page=${1}&tipoBono=${this.state.tiposBonos}`
    );
    this.setState({
      loader: true
    });
    let params = {
      size: this.state.itemsPerPage,
      page: 0,
      idEmisora: null,
      idTipoInstrumento: null,
      serie: null,
      idTipoDocumentoListado: null,
      claveWarrant: null,
      tipoBono: this.state.tiposBonos,
    };
    this.props.onFilterDocumentosDefinitivos(params);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let contenidoMostrar = null;
    let tabBonoASGMostrar = null;
    let documentosDefinitivos = null;
    if (
      this.state.documentosDefinitivos &&
      this.state.documentosDefinitivos.content !== undefined &&
      this.state.documentosDefinitivos.content
    ) {
      documentosDefinitivos = this.state.documentosDefinitivos.content;
      const numRows = this.state.documentosDefinitivos ? this.state.documentosDefinitivos.totalElements : 0;
      contenidoMostrar = (
        <React.Fragment>
          <Accordion datos={documentosDefinitivos} nombre="documentosDefinitivos" icon={""} tipo={12} subtipo={7} 
          isSustentable={this.state.asg ? true : false} />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
            isSustentable={this.state.asg ? true : false}
          />
        </React.Fragment>
      );
    } else if (
      this.state.documentosDefinitivos &&
      this.state.documentosDefinitivos.content !== undefined &&
      this.state.documentosDefinitivos.content.length === 0 &&
      this.state.wasFiltered
    ) {
      contenidoMostrar = (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
    } else if (this.state.documentosDefinitivos === null && this.state.wasFiltered) {
      contenidoMostrar = (
        <div>
          <img src={loading} alt="" />
        </div>
      );
    } else if (this.state.documentosDefinitivos === null) {
      contenidoMostrar = (
        <p className="montse texto_16 regular">
          {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
        </p>
      );
    }

    if (this.state.asg === true){
      tabBonoASGMostrar = (
        <div className="row margen_top_30">
          <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
            <div className={classes.vertical_div}>
                          <span className={classes.vertical_content}>
                          {getLan() === "es" ? "Mostrar" : "View"}:
                          </span>
            </div>
          </div>
          <div className={["col sin_padding", classes.myCol].join(" ")}>
            <TabASG
              tiposBonosInicial={this.state.tiposBonosInicial}
              tiposBonos={this.state.tiposBonos}
              setTiposBono={tipos => this.onSetTiposBono(tipos)}
            />
          </div>
        </div>
      );
    }

    const titleAsg = this.state.messages && this.state.messages !== undefined ? this.state.messages["empresas.listadodevalores.tramites.documentos.titulo1"] + " ASG" : null;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}

          <h1 className={"texto_42 nunito regular"}>
          {this.state.asg === true ? 
            <TituloAsg titulo={titleAsg} />
          : 
            <FormattedHTMLMessage id="empresas.listadodevalores.tramites.documentos.titulo1" defaultMessage=" " />
          }
          </h1>
          <div className="margen_50 texto_16 montse regular">
            <FormattedHTMLMessage id="empresas.listadodevalores.tramites.documentos.introduccion" defaultMessage=" " />
          </div>
          {tabBonoASGMostrar}
          <br />
          <FilterDocumentosDefinitivos
            setClaveCotizacionFilter={claves_cotizacion => this.onSetClaveCotizacionFilter(claves_cotizacion)}
            setClaveCotizacionLabel={clave_label => this.onSetClaveCotizacionLabelFilter(clave_label)}
            setClaveWarrantFilter={claves_warrant => this.onSetClaveWarrantFilter(claves_warrant)}
            setInstrumentosFilter={instrumento => this.onSetInstrumentos(instrumento)}
            setTipoDocumentosFilter={tipoDocumento => this.onSetTipoDocumento(tipoDocumento)}
            setSerieFilter={serie => this.onSetSerie(serie)}
            setWasFiltered={wasFiltered => this.onWasFiltered(wasFiltered)}
            setPage={page => this.onSetPage(page)}
            setEmisoraSic={emisoraSic => this.onSetEmisoraSic(emisoraSic)}
            setEmisoraFondo={emisoraFondo => this.onSetEmisoraFondo(emisoraFondo)}
            tipoBono={this.state.tiposBonos}
            isSustentable={this.state.asg ? true : false}
            setParams={(
              claves_cotizacion,
              instrumento,
              serie,
              tipoDocumento,
              claves_warrant,
              page,
              wasFiltered,
              clave_label,
              reset
            ) =>
              this.onSetParams(
                claves_cotizacion,
                instrumento,
                serie,
                tipoDocumento,
                claves_warrant,
                page,
                wasFiltered,
                clave_label,
                reset
              )
            }
            location={this.props.location}
          />

          <div
            className={[
              !this.state.claves_label ? classes.hidden : null,
              this.state.claves_label ? "margin_bottom_30" : ""
            ].join(" ")}
          >
            <div className={["row", classes.claveContainer].join(" ")}>
              <div className="col col-lg-9">
                <div className={["nunito", classes.textContainer].join(" ")}>{this.state.claves_label}</div>
              </div>
              <div className="col col-lg-3">
                <div className={classes.buttonContainer}>
                  <NavLink
                    to={
                      this.state.emisoraFondo === true ? `/empresas/fondos?claveOperadora=&claveFondo=${this.state.claves_cotizacion}&tipoFondo=&clasificacionFondo=&page=1`
                        : this.state.emisoraSic === true ? `/empresas/emisoras_inscritas/sic?tipoValor=null&mercadoPrincipal=null&tab=0&subTab=0&clave=${this.state.claves_label}&emisora=${this.state.claves_cotizacion}&page=1&tipoDerecho=null&fechaInicio=null&fechaFin=null`
                          : `/empresas/emisoras_inscritas?instrumento=null&tipo_valor=null&sector=null&sub_sector=null&ramo=null&sub_ramo=null&emisora_id=${this.state.claves_cotizacion
                          }&canceladas=null&biva=null&page=1`}
                    className={[classes.link2].join(" ")}
                    onClick={this.props.onClick}
                  >
                    <span className="montse texto_16">
                      {getLan() === "es" ? "Consultar perfil" : "View profile"}
                      <i class="fas fa-long-arrow-alt-right" />
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="margen_50">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFilterDocumentosDefinitivos: params => {
      dispatch(actions.filterDocumentosDefinitivos(params));
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(67));
    }
  };
};

const mapStateToProps = state => {
  return {
    documentosDefinitivos: state.listadoValores.documentosDefinitivos,
    diccionario: state.intl.diccionario
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DocumentosDefinitivos)
);

import React, { Component } from "react";
import classes from "./FilterEmisionesASG.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { getLan } from "../../../stores/utils/utilities";
import { debounce } from "throttle-debounce";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";

const renderInput = ({
    input,
    id = null,
    classes = null,
    elementType = "input",
    ref,
    onInputChange,
    label,
    placeholder = null,
    disabled = false,
    options,
    isMultiple = null,
    maxCharacter = null,
    checked = null,
    selectLabel
}) => {
    const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";
    const params = {
        input: {
            ...input,
            id: id ? id : input.name,
            placeholder: placeholder,
            className:
                classes && elementType === "checkbox"
                    ? classes
                    : [classes, "form-control"].join(" "),
            ref: ref,
            disabled: disabled,
            checked: checked,
            onChange: e => {
                input.onChange(e);
                onInputChange && onInputChange(e);
            }
        },
        label: label,
        isMultiple: isMultiple,
        maxCharacter: maxCharacter,
        elementType: elementType,
        options: options,
        selectLabel: selectLabel ? selectLabel : chooseOptionLabel
    };
    return (
        <div className="form-group">
            {label && input.type !== "radio" && input.type !== "checkbox" ? (
                <label
                    htmlFor={input.name}
                    className={["texto_14", "montse", "regular nowrap"].join(" ")}
                >
                    {label}
                </label>
            ) : null}
            <Input config={params} />
        </div>
    );
};

class FilterEmisionesASG extends Component {
    constructor(props) {
        super(props);
        this.escFunction = this.escFunction.bind(this);
    }

    state = {
        esconderAutoComplete: false,
        toggleFilters: false,
        disableEnableButtons: true,
        selectedFilters: {
            instrumentos: [],
            modoListado: [],
            sector: [],
            estado: [],
            ramos: [],
            sub_ramos: []
        },
        form_filters: {
            instrumentos: {
                value: []
            },
            modoListado: {
                value: []
            },
            sector: {
                value: []
            },
            ramos: {
                value: ""
            },
            sub_ramos: {
                value: ""
            },
            listado: {
                value: []
            },
            estado: {
                value: []
            }
        },
        form_clave: {
            clave: {
                value: []
            }
        },
        selectPivot: false,
        selectSubPivot: false,
        prevInputSelected: false,
        toggleInputs: false,
        itemsPerPage: 10,
        valueClave: null,
        claveEmisora: null,
        filteredBySearch: false,
        typeClaveSelected: null
    };

    componentWillMount() {
        this.callReduxAction = debounce(500, this.callReduxAction);
    }

    componentDidMount() {
        window.addEventListener("click", e => this.collapseSearch(e));
        window.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        window.addEventListener("click", e => this.collapseSearch(e));
        window.addEventListener("keydown", this.escFunction, false);
    }

    escFunction(event) {
        let visible = false;

        if (event.keyCode === 27) {
            visible = true;
        }
        this.setState({
            esconderAutoComplete: visible
        });
    }

    collapseSearch(event) {
        let visible = false;
        if (event.target.name !== "search_field") {
            visible = true;
        }

        this.setState({
            esconderAutoComplete: visible
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.claveEmisora !== this.props.claveEmisora) {
            let disableEnableButtons = true;
            let toggleFilters = false;
            if (this.props.claveEmisora) {
                disableEnableButtons = false;
            }
            this.setState({
                typeClaveSelected: 1,
                valueClave: this.props.claveEmisora,
                disableEnableButtons: disableEnableButtons,
                toggleFilters: toggleFilters
            });
        }

        if (prevProps.biva !== this.props.biva || prevProps.canceladas !== this.props.canceladas) {
            this.onInputChangeHandler(null, false, false, false); // para borrar busqueda aproximada en cambio de tab
            this.setState({
                valueClave: this.props.claveEmisora,
                disableEnableButtons: true,
                toggleFilters: false,
                toggleInputs: this.props.claveEmisora && this.props.claveEmisora !== "" ? true : false
            });
        }
    }

    onDeleteClaveEmisoraHandler = () => {
        this.props.onDeleteEmisoraSelecionada();

        this.setState({
            claveEmisora: null,
            valueClave: null,
            toggleInputs: false
        });

    };

    showContainerEmisoraSleccionadaHandler = () => {
        const clave = this.props.claveEmisora;
        return (
            <Auxs>
                <div className={classes.filter_container_emisora_seleccionada}>
                    <div className={classes.filter_emisora_seleccionada}>
                        <span className={["montse color_verde_oscuro", classes.filter_text_model_one].join(" ")}>{clave}</span>
                        <span className={classes.filter_icon_button_model_one}>
                            <i className="far fa-times-circle color_verde_oscuro" onClick={() => this.onDeleteClaveEmisoraHandler()} />
                        </span>
                    </div>
                </div>
            </Auxs>
        );
    };

    printFrameHandler = () => {
        const showContainer =
            this.props.claveEmisora && !this.state.filteredBySearch
                ? this.showContainerEmisoraSleccionadaHandler()
                : this.showInputHandler();

        const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : showContainer;


        return (
            <div className="row">
                <div className="col sin_padding">
                    <div className="row">
                        {/* <div className={["col-4 col-sm-3", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}> */}
                            {/* <div
                                className={[
                                    "texto_btn_cerrar",
                                    classes.toggle_button_container_model_one,
                                    this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                                ].join(" ")}
                                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
                            >
                                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                                    <i className="fas fa-filter" />
                                    <span>{this.state.toggleFilters ? closeLabel : filterLabel}</span>
                                </button>
                            </div> */}
                        {/* </div> */}
                        <div
                            className={["col", 
                            // this.state.toggleFilters ? "col-lg-9" : "col-lg-8",
                             classes.no_col_padding_model_one].join(
                                " "
                            )}
                        >
                            <div className={classes.filters_selected_container_model_one}>{input}</div>
                        </div>
                        <div
                            className={[
                                this.state.toggleFilters ? classes.hidden : null,
                                "col-2 col-md-1",
                                "col-lg-1",
                                "text-center color_verde_oscuro",
                                classes.no_col_padding_model_one
                            ].join(" ")}
                        >
                            <div
                                className={[
                                    classes.search_button_container_model_one,
                                    this.state.toggleFilters ? classes.no_border_search_button_model_one : null
                                ].join(" ")}
                            >
                                <button type="buttom" className={["btn"].join(" ")} disabled>
                                    <i className="fas fa-search color_verde_oscuro" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? "d-none" : null].join(" ")}
                    >
                        {/* <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div> */}
                    </div>
                </div>
            </div>
        );
    };

    onSubmitSearchHandler = event => {
        event.preventDefault();
    };

    printChange = e => {
        this.callReduxAction(e.target.value);
    };

    callReduxAction = value => {
        this.onInputChangeHandler(value);
    };

    onInputChangeHandler = (event = null, eraser = null, consulta = true, borraClaveFiltro = true) => {
        const value = event;
        const form_clave = this.state.form_clave;
        form_clave.clave.value = value;
        let disabled = false;
        let filteredBySearch = false;
        if (value && value !== "") {
            this.props.onFilterEmisionesByClave(value);
            filteredBySearch = true;
            this.props.setActivePage(1);
        } else {
            disabled = true;
            if (borraClaveFiltro) {
                this.props.borraClaveFiltro(null);
                this.props.updateEmisorasParent(null);
                this.props.wasFilteredBy(null);
                this.props.setFilterClave(null);
                this.props.setActivePage(1);
                this.props.onDeleteEmisoraSelecionada();
            }
        }

        if (eraser) {
            this.setState(prevState => {
                return {
                    toggleInputs: !prevState.toggleInputs,
                    form_clave: form_clave,
                    disableEnableButtons: disabled,
                    valueClave: true,
                    filteredBySearch: filteredBySearch
                };
            });
        } else {
            this.setState({
                form_clave: form_clave,
                disableEnableButtons: disabled,
                filteredBySearch: filteredBySearch
            });
        }
    };


    // Retorna el id
    onSplitValueHandler = options => {
        let newValues = null;

        if (!Array.isArray(options)) {
            newValues = options.split("_")[0];
            return newValues;
        } else {
            newValues = [];
            options.forEach(option => {
                newValues.push(option.split("_")[0]);
            });
            return [...newValues];
        }
    };

    onPrepareValueHandler = item => {
        item.value = `${item.value}_${item.label}`;        
    };


    // Retorna todos los valores de los campos del formulario.
    onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
        const form = this.state.form_filters;

        if (inputName && newStateValues) {
            form[`${inputName}`].value = newStateValues;
        }

        // Se obtiene el valor del tipo de instrumento
        const valuesTipoInstrumentos =
            form.instrumentos.value && form.instrumentos.value.length > 0 ? this.onSplitValueHandler(form.instrumentos.value) : [];
        // Se obtiene el valor de tipo valor
        const valuesModoListado =
            form.modoListado.value && form.modoListado.value.length > 0 ? this.onSplitValueHandler(form.modoListado.value) : [];
        // Se obtiene el valor del sector
        const valuesSector = form.sector.value && form.sector.value.length > 0 ? this.onSplitValueHandler(form.sector.value) : [];
        // Se obtiene el valor del subSector
        const valuesEstado =
            form.estado.value && form.estado.value.length > 0 ? this.onSplitValueHandler(form.estado.value) : [];

        return [valuesTipoInstrumentos, valuesModoListado, valuesSector, valuesEstado];
    };

    // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
    onFilterDataByEvent = () => {
        const [
            valuesTipoInstrumentos,
            valuesModoListado,
            valuesSector,
            valuesEstado,
            valuesRamos,
            valuesSubRamos
        ] = this.onGetAllValuesFormFilter();

        // Se invoca la funcion del componente padre (Emisoras.js) para setear los valores correspondientes
        // Configurando el valor de la emisora seleccionada
        // Valores para el campo instrumento
    };


    // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
    onGetNameStateFilterByInputHandler = type => {
        const form = this.state.form_filters;
        const selectedFilters = this.state.selectedFilters;

        switch (type) {
            case 0:
                return ["sector", form.sector.value, selectedFilters.sector];
            case 1:
                return ["instrumentos", form.instrumentos.value, selectedFilters.instrumentos];
            case 2:
                return ["modoListado", form.modoListado.value, selectedFilters.modoListado];
            case 3:
                return ["estado", form.estado.value, selectedFilters.estado];
            case 4:
                return ["ramos", form.ramos.value, selectedFilters.ramos];
            case 5:
                return ["sub_ramos", form.sub_ramos.value, selectedFilters.sub_ramos];
        }
    };

    // Agrega o elimina valores de estado para un campo del formulario
    onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
        let newStateValues = null;

        if (type !== 0 && type !== 1 && type !== 3) {
            if (values !== "") {
                newStateValues = values;
            }
        } else {
            newStateValues = [];
            newStateValues = [...inputOptions];
            const stateExists = newStateValues.find(option => option === values);
            if (stateExists) {
                newStateValues = newStateValues.filter(option => option !== values);
            } else {
                if (values !== "") {
                    newStateValues.push(values);
                } else {
                    newStateValues = [];
                }
            }
        }

        return newStateValues;
    };

    // Agrega o elimina filtros a la barra de filtro seleccionados
    onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
        let newFilterValues = [];

        if (type !== 0 && type !== 1 && type !== 3) {
            if (values !== "") {
                newFilterValues.push({ label: label, value: values, type: type });
            }
        } else {
            newFilterValues = [...filterOptions];
            const filterExist = newFilterValues.find(option => option.label === label);
            if (filterExist) {
                newFilterValues = newFilterValues.filter(option => option.label !== label);
            } else {
                if (values !== "") {
                    newFilterValues.push({ label: label, value: values, type: type });
                } else {
                    newFilterValues = [];
                }
            }
        }

        return newFilterValues;
    };

    // Elimina el elemento seleccionado (estado y en la barra de filtros)
    onDeleteFilterHandler = item => {
        let form = this.state.form_filters;
        let selectedFilters = this.state.selectedFilters;

        let newStateValues = null;
        let newFilterValues = null;

        let selectPivot = this.state.selectPivot;
        let selectSubPivot = this.state.selectSubPivot;
        let prevInputSelected = this.state.prevInputSelected;

        this.onPrepareValueHandler(item);

        let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

        const auxValue = item.type !== 0 && item.type !== 1 && item.type !== 3 ? "" : item.value;
        newStateValues = this.onChangeStateHandler(stateValues, auxValue, item.type);

        newFilterValues = this.onChangeFilterHandler(filterValues, auxValue, item.label, item.type);

        form[`${inputName}`].value = newStateValues;
        selectedFilters[`${inputName}`] = newFilterValues;

        // Se obtienen todos los valores de los campos del formulario (Filtro)
        let [
            valuesTipoInstrumentos,
            valuesModoListado,
            valuesEstado
        ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

        if (item.type !== 0) {
            // Tipo instrumentos
            // Limpiando valores para campo sector
            form.sector.value = [];
            selectedFilters.sector = [];
            // Filtrando el campo sector
            this.onFilterSectorHandler(valuesTipoInstrumentos, valuesModoListado, valuesEstado);
        }

        this.setState({
            form_filters: form,
            selectPivot: selectPivot,
            selectSubPivot: selectSubPivot,
            selectedFilters: selectedFilters,
            prevInputSelected: prevInputSelected
        });
        this.onFilterDataByEvent();
    };

    showFiltersSelectedHandler = () => {
        let filters = null;
        const filterList = [
            ...this.state.selectedFilters.instrumentos,
            ...this.state.selectedFilters.modoListado,
            ...this.state.selectedFilters.sector,
            ...this.state.selectedFilters.estado,
            ...this.state.selectedFilters.ramos,
            ...this.state.selectedFilters.sub_ramos
        ];

        filters = (
            <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
                {filterList.map((item, i) => (
                    <div className={classes.filter_container_model_one} key={`${item.label}-${i}`}>
                        <span className={["montse color_verde_oscuro", classes.filter_text_model_one].join(" ")}>{item.label}</span>
                        <span className={classes.filter_icon_button_model_one}>
                            <i className="far fa-times-circle color_verde_oscuro" onClick={() => this.onDeleteFilterHandler(item)} />
                        </span>
                    </div>
                ))}
            </div>
        );
        return filters;
    };

    onToggleFilterInputOptionSelected = () => {
        this.setState(prevState => {
            return {
                toggleInputs: !prevState.toggleInputs
            };
        });
    };

    onPrintAutoCompleteHandler = () => {
        const listado = this.props.listadoClaves;
        return listado.map(item => (
            <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.nombre, item.id, 1)}>
                {item.nombre}
            </li>
        ));
    };

    onClaveSelectedHandler = (clave, id = null, option = 0) => {
        this.onToggleFilterInputOptionSelected();
        let claveFilter = clave;

        if (option === 0 || clave!==null && id === null) {
            this.props.setFilterClave(claveFilter);
        } else {
            this.props.setInitTabs(0, id, true);
            this.props.updateEmisorasParent(id, clave);
        } 
        this.props.wasFilteredBy(0);

        this.setState({ typeClaveSelected: option, valueClave: clave });
    };

    onPrintOptionSelectedHandler = value => {
        return (
            <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? "d-none" : null].join(" ")}>
                <span className={[classes.filterContainer, this.state.valueClave === null ? "d-none" : null].join(" ")}>
                    <span className={[[classes.filterText, "montse texto_14 regular color_verde_oscuro"].join(" ")].join(" ")}>
                        {this.state.typeClaveSelected === 0
                            ? getLan() === "es"
                                ? `Ver todos los resultados para: ${this.state.valueClave}`
                                : `See all results for: ${this.state.valueClave}`
                            : `${this.state.valueClave}`}
                    </span>
                    <span className={classes.filterIconButton} onClick={() => this.onInputChangeHandler(null, true)}>
                        <i className="far fa-times-circle color_verde_oscuro" />
                    </span>
                </span>
            </div>
        );
    };

    showInputHandler = () => {
        const value = this.state.form_clave.clave.value;

        const listado = this.props.listadoClaves ? this.onPrintAutoCompleteHandler() : null;

        const firstlistItem =
            listado && listado.length > 0 ? (
                <li
                    className="list-group-item"
                    onClick={() => {
                        this.onClaveSelectedHandler(value);
                    }}
                >
                    {getLan() === "es" ? "Ver todos los resultados para: " : "See all results for: "} {value}
                </li>
            ) : (
                <li className="list-group-item"> {getLan() === "es" ? "No existen resultados para la búsqueda:" : "There are no results for the search: "} {value}</li>
            );

        return (
            <Auxs>
                {this.onPrintOptionSelectedHandler()}
                <div className={[classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null].join(" ")}>
                    <Form
                        onSubmit={this.onSubmitSearchHandler}
                        initialValues={{
                            search_field: this.state.form_clave.clave.value
                        }}
                        render={() => (
                            <form ref={this.formSearch} onSubmit={this.onSubmitSearchHandler}>
                                <Field
                                    name="search_field"
                                    id="search_field"
                                    type="text"
                                    elementType="input"
                                    maxCharacter={12}
                                    placeholder={getLan() === "es" ? "Buscar por clave de cotización o ISIN" : "Search by ticker or ISIN"}
                                    component={renderInput}
                                    className={"color_verde_oscuro"}
                                    onInputChange={event => this.printChange(event)}
                                />
                            </form>
                        )}
                    />
                    <div
                        className={[
                            classes.autoCompleteContainer,
                            "list-group",
                            this.state.form_clave.clave.value === null || this.state.form_clave.clave.value.length === 0
                                ? classes.hidden
                                : null,
                            this.state.esconderAutoComplete ? classes.hidden : null
                        ].join(" ")}
                    >
                        {firstlistItem}
                        {listado}
                    </div>
                </div>
            </Auxs>
        );
    };

    render() {
        const filter = this.printFrameHandler();
        return filter;
    }
}

const mapStateToProps = state => {
    return {
        listadoClaves: state.bivaSustentable.listadoClaves
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFilterEmisionesByClave: (clave) => {
            dispatch(actions.filterEmisionesASGByClave(clave));
        }
      
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FilterEmisionesASG)
);

// Menu data
export const FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS";
export const FETCH_AVISOFOOTER_SUCCESS = "FETCH_AVISOFOOTER_SUCCESS";
export const FETCH_PRIVACIDADFOOTER_SUCCESS = "FETCH_PRIVACIDADFOOTER_SUCCESS";
export const FETCH_CENCORFOOTER_SUCCESS = "FETCH_CENCORFOOTER_SUCCESS";
export const FETCH_FOOTER_SUCCESS = "FETCH_FOOTER_SUCCESS";

// Submenu data
export const FETCH_SUBMENU_SUCCESS = "FETCH_SUBMENU_SUCCESS";

// Trkd data
export const FETCH_TRKD_SUCCESS = "FETCH_TRKD_SUCCESS";

// Videos Espacio Biva
export const FETCH_VIDEO_SUCCESS = "FETCH_VIDEO_SUCCESS";

// Videos Biva URL Google api
export const FETCH_VIDEO_API_SUCCESS = "FETCH_VIDEO_API_SUCCESS";

// Videos Instituto Biva URL Google api
export const FETCH_VIDEO_INSTITUTO_SUCCESS = "FETCH_VIDEO_INSTITUTO_SUCCESS";
export const FETCH_VIDEOS_SUCCESS = "FETCH_VIDEOS_SUCCESS";

// Activas
export const FETCH_ACTIVAS_SUCCESS = "FETCH_ACTIVAS_SUCCESS";

// Indice
export const FETCH_INDICE_SUCCESS = "FETCH_INDICE_SUCCESS";
export const FETCH_INDICES_SUCCESS = "FETCH_INDICES_SUCCESS";
export const FETCH_INDICETIEMPOREAL_SUCCESS = "FETCH_INDICETIEMPOREAL_SUCCESS";

// Noticias Reuter
export const FETCH_NOTICIAS_SUCCESS = "FETCH_NOTICIAS_SUCCESS";

// Noticias BIVA
export const FETCH_NOTICIAS_BIVA_SUCCESS = "FETCH_NOTICIAS_BIVA_SUCCESS";

// Genericos
export const SET_INIT_GENRICOS_SUCCESS = "FETCH_GENRICOS_SUCCESS";

// Cuadriculas para seccion nosotros
export const FETCH_CUADRICULAS_NOSOTROS_DATA = "FETCH_CUADRICULAS_NOSOTROS_DATA";

//Gobierno corporativo consejos
export const FETCH_GCCONSEJO_SUCCESS = "FETCH_GCCONSEJO_SUCCESS";

//Gobierno corporativo comites
export const FETCH_GCCOMITE_SUCCESS = "FETCH_GCCOMITES_SUCCESS";

// Terminos data
export const FETCH_TERMINOS_SUCCESS = "FETCH_TERMINOS_SUCCESS";

// Casas de bolsa data
export const FETCH_CASAS_SUCCESS = "FETCH_CASAS_SUCCESS";

// Terminos funciones
export const FETCH_TERMINOS_FUNCIONES_SUCCESS = "FETCH_TERMINOS_FUNCIONES_SUCCESS";

// Posturas data
export const FETCH_POSTURAS_SUCCESS = "FETCH_POSTURAS_SUCCESS";

// Formador de mercados
export const FETCH_FORMADOR_MERCADO_SUCCESS = "FETCH_FORMADOR_MERCADO_SUCCESS";

// Cambio data
export const FETCH_CAMBIO_SUCCESS = "FETCH_CAMBIO_SUCCESS";

// Eventos data
export const FETCH_EVENTOS_SUCCESS = "FETCH_EVENTOS_SUCCESS";

// Other Noticias data
export const FETCH_OTRASNOTICIAS_SUCCESS = "FETCH_OTHERNOTICIAS_SUCCESS";

// Noticias por id data
export const FETCH_NOTICIABYID_SUCCESS = "FETCH_NOTICIABYID_SUCCESS";

// Eventos por id data
export const FETCH_EVENTOBYID_SUCCESS = "FETCH_EVENTOBYID_SUCCESS";

// Prensa por id data
export const FETCH_SALAPRENSABYID_SUCCESS = "FETCH_SALAPRENSABYID_SUCCESS";

// Cambio data
export const FETCH_BANNER_SUCCESS = "FETCH_BANNER_SUCCESS";

// Sala prensa data
export const FETCH_SALAPRENSA_SUCCESS = "FETCH_SALAPRENSA_SUCCESS";

// Instrumentos data
export const FETCH_INSTRUMENTOS_SUCCESS = "FETCH_INSTRUMENTOS_SUCCESS";

// Tipo valor data
export const FETCH_TIPOVALOR_SUCCESS = "FETCH_TIPOVALOR_SUCCESS";

// Sector Subsector data
export const FETCH_SECTOR_SUCCESS = "FETCH_SECTOR_SUCCESS";

// Subsector  data
export const FETCH_SUBSECTOR_SUCCESS = "FETCH_SUBSECTOR_SUCCESS";

// Ramos data
export const FETCH_RAMOS_SUCCESS = "FETCH_RAMOS_SUCCESS";

// Subramos data
export const FETCH_SUBRAMOS_SUCCESS = "FETCH_SUBRAMOS_SUCCESS";

// Emisoras data
export const FETCH_EMISORAS_SUCCESS = "FETCH_EMISORAS_SUCCESS";

// EmisoraDetalle data
export const FETCH_EMISORADETALLE_SUCCESS = "FETCH_EMISORADETALLE_SUCCESS";

// EmisoraDetalleIntegrantes data
export const FETCH_EMISORAINTEGRANTES_SUCCESS = "FETCH_EMISORAINTEGRANTES_SUCCESS";

// Emisoras Documentos data
export const FETCH_DOCUMENTOS_SUCCESS = "FETCH_DOCUMENTOS_SUCCESS";

// Emisoras Valores Listados data
export const FETCH_VALORESLISTADOS_SUCCESS = "FETCH_VALORESLISTADOS_SUCCESS";
export const FETCH_PRELIMINARES_SUCCESS = "FETCH_PRELIMINARES_SUCCESS";

// Tipo valor data
export const FETCH_GRAFICAINSTRUMENTEMISORA_SUCCES = "FETCH_GRAFICAINSTRUMENTEMISORA_SUCCES";

// Tipo Informacion data
export const FETCH_TIPOINFORMACION_SUCCESS = "FETCH_TIPOINFORMACION_SUCCESS";
export const FETCH_ALLTIPOINFORMACION_SUCCESS = "FETCH_ALLTIPOINFORMACION_SUCCESS";
export const FETCH_TIPODOCUMENTO_TIPOINFORMACION_SUCCESS = "FETCH_TIPODOCUMENTO_TIPOINFORMACION_SUCCESS";

export const FETCH_GRAFICAINSTRUMENTEMISORA_SUCCESS = "FETCH_GRAFICAINSTRUMENTEMISORA_SUCCESS";

// Envio de correos a un area correspondiente
export const FETCH_SENDEMAILAREA_SUCCESS = "FETCH_SENDEMAILAREA_SUCCESS";
export const FETCH_SENDEMAILAREA_ERROR = "FETCH_SENDEMAILAREA_ERROR";

// Cuadriculas
export const FETCH_CUADRICULAS = "FETCH_CUADRICULAS";

// Envio de correos anonimo
export const FETCH_SENDEMAILANONIMO_SUCCESS = "FETCH_SENDEMAILANONIMO_SUCCESS";

// Banco Informacion data
export const FETCH_BANCOINFORMACION_SUCCESS = "FETCH_BANCONINFORMACION_SUCCESS";

// Agendas de Derechos data
export const FETCH_AGENDADERECHOS_SUCCESS = "FETCH_AGENDADERECHOS_SUCCESS";

// SIC data
export const FETCH_SIC_SUCCESS = "FETCH_SIC_SUCCESS";

// SIC Valores Listados data
export const FETCH_SICVALORESLISTADOS_SUCCESS = "FETCH_SICVALORESLISTADOS_SUCCESS";

// SIC data
export const FETCH_SICAVISOSDERECHO_SUCCESS = "FETCH_SICAVISOSDERECHO_SUCCESS";

// Calificadoras data
export const FETCH_CALIFICADORAS_SUCCESS = "FETCH_CALIFICADORAS_SUCCESS";

// CalificadoraDetalle data
export const FETCH_CALIFICADORADETALLE_SUCCESS = "FETCH_CALIFICADORADETALLE_SUCCESS";
export const FETCH_SENDEMAILANONIMO_ERROR = "FETCH_SENDEMAILANONIMO_ERROR";

// EmisorasReporteFinanciero y Cotizacion
export const FETCH_FINANCIALREPORT_SUCCESS = "FETCH_FINANCIALREPORT_SUCCESS";
export const FETCH_RATIOSREPORT_SUCCESS = "FETCH_RATIOSREPORT_SUCCESS";

// Emisoras Requisitos Mantenimiento
export const FETCH_REQUISITOS_SUCCESS = "FETCH_REQUISITOS_SUCCESS";

// Listado de Valores Solicitudes Mantenimiento
export const FETCH_SOLICITUDES_SUCCESS = "FETCH_SOLICITUDES_SUCCESS";

// Listado de Valores Documentos
export const FETCH_DOCUMENTOSLISTADO_SUCCESS = "FETCH_DOCUMENTOSLISTADO_SUCCESS";

// Listado de Valores Avisos Oferta Publica
export const FETCH_AVISOSOFERTASPUBLICAS_SUCCESS = "FETCH_AVISOSOFERTASPUBLICAS_SUCCESS";

// Listado de Valores Documentos Definitivos
export const FETCH_DOCUMENTOSDEFINITIVOS_SUCCESS = "FETCH_DOCUMENTOSDEFINITIVOS_SUCCESS";

// Listado de Valores Prospectos Colocacion
export const FETCH_PROSPECTOSCOLOCACION_SUCCESS = "FETCH_PROSPECTOSCOLOCACION_SUCCESS";

// Listado de Valores Documentos
export const FETCH_DOCUMENTOSPROSPECTOS_SUCCESS = "FETCH_DOCUMENTOSPROSPECTOS_SUCCESS";

// Listado de Valores Detalle Prospectos
export const FETCH_DETALLEPROSPECTOS_SUCCESS = "FETCH_DETALLEPROSPECTOS_SUCCESS";

// Emisoras QUOTAS
export const FETCH_QUOTAS_SUCCESS = "FETCH_QUOTAS_SUCCESS";
export const FETCH_QUOTASCALCULATION_SUCCESS = "FETCH_QUOTASCALCULATION_SUCCESS";

// Emisoras Tipo Mercado
export const FETCH_TIPOMERCADO_SUCCESS = "FETCH_TIPOMERCADO_SUCCESS";

// Emisoras ToggleBox
export const FETCH_TOGGLEBOX_SUCCESS = "FETCH_TOGGLEBOX_SUCCESS";

// Claves emisoras
export const FETCH_CLAVESEMISORAS_SUCCESS = "FETCH_CLAVESEMISORAS_SUCCESS";

// Bancos informacion
export const FETCH_EMISORASBANCOS_SUCCESS = "FETCH_EMISORASBANCOS_SUCCESS";
export const FETCH_BANCOINFORMACION_TIPOINFORMACION_SUCCESS = "FETCH_BANCOINFORMACION_TIPOINFORMACION_SUCCESS";
export const FETCH_BANCOINFORMACION_TIPODOCUMENTO_SUCCESS = "FETCH_BANCOINFORMACION_TIPODOCUMENTO_SUCCESS";
export const FETCH_BANCOINFORMACION_TIPOISTRUMENTO_SUCCESS = "FETCH_BANCOINFORMACION_TIPOISTRUMENTO_SUCCESS";
export const FETCH_BANCOINFORMACION_BYCLAVE_SUCCESS = "FETCH_BANCOINFORMACION_BYCLAVE_SUCCESS";
export const FETCH_BANCOINFORMACION_ALLBYCLAVE_SUCCESS = "FETCH_BANCOINFORMACION_ALLBYCLAVE_SUCCESS";

// Agendas
export const FETCH_ESTATUSAGENDA_SUCCESS = "FETCH_ESTATUSAGENDA_SUCCESS";
export const FETCH_TIPODERECHOAGENDA_SUCCESS = "FETCH_TIPODERECHOAGENDA_SUCCESS";
export const FETCH_TIPOINSTRUMENTOAGENDA_SUCCESS = "FETCH_TIPOINSTRUMENTOAGENDA_SUCCESS";
export const FETCH_CLAVESAGENDA_SUCCESS = "FETCH_CLAVESAGENDA_SUCCESS";

// SIC
export const FETCH_MERCADOSSIC_SUCCESS = "FETCH_MERCADOSSIC_SUCCESS";
export const FETCH_CLAVESSIC_SUCCESS = "FETCH_CLAVESSIC_SUCCESS";

// INFORMACION DE MERCADO
export const FETCH_ULTIMODIABIVA_SUCCESS = "FETCH_ULTIMODIABIVA_SUCCESS";
export const FETCH_ULTIMODIACASABOLSA_SUCCESS = "FETCH_ULTIMODIACASABOLSA_SUCCESS";
export const FETCH_ULTIMODIAEMISORASTOTAL_SUCCESS = "FETCH_ULTIMODIAEMISORASTOTAL_SUCCESS";
export const FETCH_ULTIMODIAEMISORASLOCAL_SUCCESS = "FETCH_ULTIMODIAEMISORASLOCAL_SUCCESS";
export const FETCH_ULTIMODIAEMISORASSIC_SUCCESS = "FETCH_ULTIMODIAEMISORASSIC_SUCCESS";

export const FETCH_ULTIMOMESBIVA_SUCCESS = "FETCH_ULTIMOMESBIVA_SUCCESS";
export const FETCH_ULTIMOMESCASABOLSA_SUCCESS = "FETCH_ULTIMOMESCASABOLSA_SUCCESS";
export const FETCH_ULTIMOMESEMISORASTOTAL_SUCCESS = "FETCH_ULTIMOMESEMISORASTOTAL_SUCCESS";
export const FETCH_ULTIMOMESEMISORASLOCAL_SUCCESS = "FETCH_ULTIMOMESEMISORASLOCAL_SUCCESS";
export const FETCH_ULTIMOMESEMISORASSIC_SUCCESS = "FETCH_ULTIMOMESEMISORASSIC_SUCCESS";
export const FETCH_ULTIMOMESCASABOLSALOCAL_SUCCESS = "FETCH_ULTIMOMESCASABOLSALOCAL_SUCCESS";
export const FETCH_ULTIMOMESCASABOLSASIC_SUCCESS = "FETCH_ULTIMOMESCASABOLSASIC_SUCCESS";

export const FETCH_CRUCESTOTALES_SUCCESS = "FETCH_CRUCESTOTALES_SUCCESS";
export const FETCH_CRUCESINTENCIONALES_SUCCESS = "FETCH_CRUCESINTENCIONALES_SUCCESS";
export const FETCH_CRUCESEXCEPCIONALES_SUCCESS = "FETCH_CRUCESEXCEPCIONALES_SUCCESS";

export const FETCH_HISTORICOBIVA_SUCCESS = "FETCH_HISTORICOBIVA_SUCCESS";

export const FETCH_HISTORICOCASABOLSATOTAL_SUCCESS = "FETCH_HISTORICOCASABOLSATOTAL_SUCCESS";
export const FETCH_HISTORICOCASABOLSALOCAL_SUCCESS = "FETCH_HISTORICOCASABOLSALOCAL_SUCCESS";
export const FETCH_HISTORICOCASABOLSASIC_SUCCESS = "FETCH_HISTORICOCASABOLSASIC_SUCCESS";

export const FETCH_COTIZACIONESEMISORAS_SUCCESS = "FETCH_COTIZACIONESEMISORAS_SUCCESS";
export const FETCH_SUSPENSIONES_SUCCESS = "FETCH_SUSPENSIONES_SUCCESS";
export const FETCH_OPERACIONESMERCADO_SUCCESS = "FETCH_OPERACIONESMERCADO_SUCCESS";

// Documentos
export const FETCH_AUTORREGULACIONDOCUMENTOS_SUCCESS = "FETCH_AUTORREGULACIONDOCUMENTOS_SUCCESS";
export const FETCH_NORMATIVIDADLEYESDOCUMENTOS_SUCCESS = "FETCH_NORMATIVIDADLEYESDOCUMENTOS_SUCCESS";
export const FETCH_NORMATIVIDADDISPOSICIONESDOCUMENTOS_SUCCESS = "FETCH_NORMATIVIDADDISPOSICIONESDOCUMENTOS_SUCCESS";
export const FETCH_ABOUTDOCUMENTOS_SUCCESS = "FETCH_ABOUTDOCUMENTOS_SUCCESS";
export const FETCH_PRENSADOCUMENTOS_SUCCESS = "FETCH_PRENSADOCUMENTOS_SUCCESS";
export const FETCH_AUDITORIODOCUMENTOS_SUCCESS = "FETCH_AUDITORIODOCUMENTOS_SUCCESS";
export const FETCH_AUDITORIOVIDEOS_SUCCESS = "FETCH_AUDITORIOVIDEOS_SUCCESS";
export const FETCH_ALIANZASDOCUMENTOS_SUCCESS = "FETCH_ALIANZASDOCUMENTOS_SUCCESS";
export const FETCH_METODOLOGIASDOCUMENTOS_SUCCESS = "FETCH_METODOLOGIASDOCUMENTOS_SUCCESS";

export const FETCH_ULTIMODIADOCUMENTOS_SUCCESS = "FETCH_ULTIMODIADOCUMENTOS_SUCCESS";
export const FETCH_ULTIMOMESDOCUMENTOS_SUCCESS = "FETCH_ULTIMOMESDOCUMENTOS_SUCCESS";
export const FETCH_OPERACIONDIARIADOCUMENTOS_SUCCESS = "FETCH_OPERACIONDIARIADOCUMENTOS_SUCCESS";

export const FETCH_CUOTAS_SUCCESS = "FETCH_CUOTAS_SUCCESS";
export const FETCH_REPORTEMENSUAL_SUCCESS = "FETCH_REPORTEMENSUAL_SUCCESS";
export const FETCH_DOCUMENTOS_ENLACES = "FETCH_DOCUMENTOS_ENLACES";

// Imagenes
export const FETCH_ALIANZASEMPIMAGENES_SUCCES = "FETCH_ALIANZASEMPIMAGENES_SUCCES";
export const FETCH_ALIANZASSTATESIMAGENES_SUCCES = "FETCH_ALIANZASSTATESIMAGENES_SUCCES";
export const FETCH_ALIANZASEDUIMAGENES_SUCCES = "FETCH_ALIANZASEDUIMAGENES_SUCCES";
export const FETCH_IMAGENES_SUCCES = "FETCH_IMAGENES_SUCCES";

// Tramites
export const FETCH_EMISORASTRAMITES_SUCCESS = "FETCH_EMISORASTRAMITES_SUCCESS";
export const FETCH_VALORESTRAMITES_SUCCESS = "FETCH_VALORESTRAMITES_SUCCESS";
export const FETCH_EMISORASCLAVESTRAMITES_SUCCESS = "FETCH_EMISORASCLAVESTRAMITES_SUCCESS";

//Prospectos
export const FETCH_MERCADOPROSPECTOS_SUCCESS = "FETCH_MERCADOPROSPECTOS_SUCCESS";
export const FETCH_INSTRUMENTOSPROSPECTOS_SUCCESS = "FETCH_INSTRUMENTOSPROSPECTOS_SUCCESS";

// Internacionalizacion
export const FETCH_INTL_SUCCESS = "FETCH_INTL_SUCCESS";

// Tipos documentos
export const FETCH_TIPODOCUMENTOS_SUCCESS = "FETCH_TIPODOCUMENTOS_SUCCESS";

// Listado de Valores Solicitudes Detalle
export const FETCH_SOLICITUDESDETALLE_SUCCESS = "FETCH_SOLICITUDESDETALLE_SUCCESS";
export const FETCH_SOLICITUDESTIPODOC_SUCCESS = "FETCH_SOLICITUDESTIPODOC_SUCCESS";
export const FETCH_COLOCACIONESLISTADO_SUCCESS = "FETCH_COLOCACIONESLISTADO_SUCCESS";

// Claves eventos calificadoras
export const FETCH_CLAVESEVENTOSCALIFICADORAS_SUCCESS = "FETCH_CLAVESEVENTOSCALIFICADORAS_SUCCESS";

//Eventos calificadoras
export const FETCH_EVENTOSCALIFICADORAS_SUCCESS = "FETCH_EVENTOSCALIFICADORAS_SUCCESS";

// Periodo
export const FETCH_PERIODO_SUCCESS = "FETCH_PERIODO_SUCCESS";

// Ejercicio
export const FETCH_EJERCICIO_SUCCESS = "FETCH_EJERCICIO_SUCCESS";

// Emisora Requisitos Mantenimiento
export const FETCH_EMISORASREQUISITOS_SUCCESS = "FETCH_EMISORASREQUISITOS_SUCCESS";

// Emisora Requisitos Periodos
export const FETCH_PERIODOSREQUISITOS_SUCCESS = "FETCH_PERIODOSREQUISITOS_SUCCESS";

// Requisitos Capital social
export const FETCH_CAPITALSOCIAL_SUCCESS = "FETCH_CAPITALSOCIAL_SUCCESS";

// Claves cotizacion para avisos y ofertas
export const FETCH_CLAVECOTIZACION_SUCCESS = "FETCH_CLAVECOTIZACION_SUCCESS";

// tipo instrumentos para avisos y ofertas
export const FETCH_TIPOINSTRUMENTOS_SUCCESS = "FETCH_TIPOINSTRUMENTOS_SUCCESS";

// serie para avisos y ofertas
export const FETCH_SERIE_SUCCESS = "FETCH_SERIE_SUCCESS";

// Claves cotizacion para documentos definitivos
export const FETCH_DDCLAVESCOTIZACION_SUCCESS = "FETCH_DDCLAVESCOTIZACION_SUCCESS";

// Instrumentos para documentos definitivos
export const FETCH_DDINSTRUMENTO_SUCCESS = "FETCH_DDINSTRUMENTO_SUCCESS";

// Tipo documentos para documentos definitivos
export const FETCH_DDTIPODOCUMENTOS_SUCCESS = "FETCH_DDTIPODOCUMENTOS_SUCCESS";

// serie para documentos definitivos
export const FETCH_DDSERIE_SUCCESS = "FETCH_DDSERIE_SUCCESS";

// claves warrant para documentos definitivos
export const FETCH_DDCLAVESWARRANT_SUCCESS = "FETCH_DDCLAVESWARRANT_SUCCESS";

// avisos suspencion
export const FETCH_AVISOS_SUCCESS = "FETCH_AVISOS_SUCCESS";

// claves cotizacion
export const FETCH_ARCLAVESCOTIZACION_SUCCESS = "FETCH_ARCLAVESCOTIZACION_SUCCESS";

// avisos listados
export const FETCH_AVISOSLISTADOS_SUCCESS = "FETCH_AVISOSLISTADOS_SUCCESS";

// claves cotizacion avisos listados
export const FETCH_ALCLAVESCOTIZACION_SUCCESS = "FETCH_ALCLAVESCOTIZACION_SUCCESS";

// tipo avisos avisos listados
export const FETCH_ALTIPOAVISOS_SUCCESS = "FETCH_ALTIPOAVISOS_SUCCESS";

// Operaciones avisos
export const FETCH_OPERACIONESAVISOS_SUCCESS = "FETCH_OPERACIONESAVISOS_SUCCESS";

// Operaciones avisos
export const FETCH_ANUNCIOSBIVA_SUCCESS = "FETCH_ANUNCIOSBIVA_SUCCESS";

// Operaciones reportes
export const FETCH_OPERACIONESREPORTES_SUCCESS = "FETCH_OPERACIONESREPORTES_SUCCESS";

// Operaciones reportes tipo archivos
export const FETCH_TIPOARCHIVOS_SUCCESS = "FETCH_TIPOARCHIVOS_SUCCESS";

// Periodos Banco de Informacion
export const FETCH_PERIODOSBI_SUCCESS = "FETCH_PERIODOSBI_SUCCESS";

// Ejercicios Banco de Informacion
export const FETCH_EJERCICIOSBI_SUCCESS = "FETCH_EJERCICIOSBI_SUCCESS";

// Rss
export const FETCH_RSS_SUCCESS = "FETCH_RSS_SUCCESS";

// Rss Documents
export const FETCH_RSSDOCUMENT_SUCCESS = "FETCH_RSSDOCUMENT_SUCCESS";

// Periodo para calificadora
export const FETCH_PERIODOCALIFICADORA_SUCCESS = "FETCH_PERIODOCALIFICADORA_SUCCESS";

// Ejercicio para calificadora
export const FETCH_EJERCICIOCALIFICADORA_SUCCESS = "FETCH_EJERCICIOCALIFICADORA_SUCCESS";

// Participacion Emision Fiduciarios
export const FETCH_FIDUCIARIOS_SUCCESS = "FETCH_FIDUCIARIOS_SUCCESS";
export const FETCH_FIDUCIARIOSRAZONES_SUCCESS = "FETCH_FIDUCIARIOSRAZONES_SUCCESS";

// Participacion Emision  Representantes
export const FETCH_REPRESENTANTES_SUCCESS = "FETCH_REPRESENTANTES_SUCCESS";
export const FETCH_REPRESENTANTESRAZONES_SUCCESS = "FETCH_REPRESENTANTESRAZONES_SUCCESS";

//FONDOS
export const FETCH_FONDOS_SUCCESS = "FETCH_FONDOS_SUCCESS";
export const FETCH_FONDOSDETAIL_SUCCESS = "FETCH_FONDOSDETAIL_SUCCESS";
export const FETCH_FONDOSDOCS_SUCCESS = "FETCH_FONDOSDOCS_SUCCESS";
export const FETCH_FONDOSINFORMACIONCORPORATIVA_SUCCESS = "FETCH_FONDOSINFORMACIONCORPORATIVA_SUCCESS";
export const FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS = "FETCH_FONDOSCONCENTRADOPRECIOS_SUCCESS";
export const FETCH_FONDOSCONCENTRADOPRECIOSGRAL_SUCCESS = "FETCH_FONDOSCONCENTRADOPRECIOSGRAL_SUCCESS";
export const FETCH_FONDOSCONCENTRADOPRECIOSHISTORICO_SUCCESS = "FETCH_FONDOSCONCENTRADOPRECIOSHISTORICO_SUCCESS";
export const FETCH_FONDOSCOMPOSICIONCARTERA_SUCCESS = "FETCH_FONDOSCOMPOSICIONCARTERA_SUCCESS";
export const FETCH_FONDOSURLCSV_SUCCESS = "FETCH_FONDOSURLCSV_SUCCESS";
// FILTRO FONDOS
export const FETCH_FONDOSCVEOPERADORA_SUCCESS = "FETCH_FONDOSCVEOPERADORA_SUCCESS";
export const FETCH_FONDOSCVEFONDO_SUCCESS = "FETCH_FONDOSCVEFONDO_SUCCESS";
export const FETCH_FONDOSTIPOFONDO_SUCCESS = "FETCH_FONDOSTIPOFONDO_SUCCESS";
export const FETCH_FONDOSCLASIFICACIONFONDO_SUCCESS = "FETCH_FONDOSCLASIFICACIONFONDO_SUCCESS";
export const FETCH_FONDOSCVEOPERADORAINFORMACIONCORPORATIVA_SUCCESS = "FETCH_FONDOSCVEOPERADORAINFORMACIONCORPORATIVA_SUCCESS";
export const FETCH_FONDOSCVEFONDOINFORMACIONCORPORATIVA_SUCCESS = "FETCH_FONDOSCVEFONDOINFORMACIONCORPORATIVA_SUCCESS";
export const FETCH_FONDOSTIPODOCUMENTO_SUCCESS = "FETCH_FONDOSTIPODOCUMENTO_SUCCESS";
export const FETCH_FONDOSSERIE_SUCCESS = "FETCH_FONDOSSERIE_SUCCESS";
export const FETCH_HISTORICOCONCENTRADOPRECIOS_SUCCESS = "FETCH_HISTORICOCONCENTRADOPRECIOS_SUCCESS";
export const FETCH_FONDOSPERIODO_SUCCESS = "FETCH_FONDOSPERIODO_SUCCESS";
export const FETCH_FONDOSEJERCICIO_SUCCESS = "FETCH_FONDOSEJERCICIO_SUCCESS";

//AFORES
export const FETCH_AFORES_SUCCESS = "FETCH_AFORES_SUCCESS";
export const FETCH_AFORESDETAIL_SUCCESS = "FETCH_AFORESDETAIL_SUCCESS";
export const FETCH_AFORESINFORMACIONCORPORATIVA_SUCCESS = "FETCH_AFORESINFORMACIONCORPORATIVA_SUCCESS";
export const FETCH_AFORESCONCENTRADOPRECIOS_SUCCESS = "FETCH_AFORESCONCENTRADOPRECIOS_SUCCESS";
export const FETCH_AFORESCONCENTRADOPRECIOSGRAL_SUCCESS = "FETCH_AFORESCONCENTRADOPRECIOSGRAL_SUCCESS";

// FILTRO AFORES
export const FETCH_AFORESCVEAFORE_SUCCESS = "FETCH_AFORESCVEAFORE_SUCCESS";
export const FETCH_AFORESCVESIEFORE_SUCCESS = "FETCH_AFORESCVESIEFORE_SUCCESS";
export const FETCH_AFORESTIPOSIEFORE_SUCCESS = "FETCH_AFORESTIPOSIEFORE_SUCCESS";
export const FETCH_AFORESCLASIFICACION_SUCCESS = "FETCH_AFORESCLASIFICACION_SUCCESS";
export const FETCH_AFORESCVEAFOREINFORMACIONCORPORATIVA_SUCCESS = "FETCH_AFORESCVEAFOREINFORMACIONCORPORATIVA_SUCCESS";
export const FETCH_AFORESCVESIEFOREINFORMACIONCORPORATIVA_SUCCESS = "FETCH_AFORESCVESIEFOREINFORMACIONCORPORATIVA_SUCCESS";
export const FETCH_AFORESTIPODOCUMENTO_SUCCESS = "FETCH_AFORESTIPODOCUMENTO_SUCCESS";

//ACCORDIONS
export const FETCH_ACCORDION_SUCCESS = "FETCH_ACCORDION_SUCCESS";


// CONTACTANOS
export const FETCH_CONTACTOS_SUCCESS = "FETCH_CONTACTOS_SUCCESS";

// EVENTOS RELEVANTES
export const FETCH_EVENTOSRELEVANTES_SUCCESS = "FETCH_EVENTOSRELEVANTES_SUCCESS";

//DICCIONARIO
export const FETCH_INDICES_DICCIONARIO_SUCCESS = "FETCH_INDICES_DICCIONARIO_SUCCESS";
export const FETCH_PALABRAS_SUCCESS = "FETCH_PALABRAS_SUCCESS";

//BIVA Cloud
export const FETCH_CUOTAS_CLOUD_SUCCESS = "FETCH_CUOTAS_CLOUD_SUCCESS";
export const FETCH_CUOTAS_API_CLOUD_SUCCESS = "FETCH_CUOTAS_API_CLOUD_SUCCESS";
export const FETCH_APIS_SUCCESS = "FETCH_APIS_SUCCESS";
export const FETCH_ACERCADE_SUCCESS = "FETCH_ACERCADE_SUCCESS";
export const FETCH_LOGO_SUCCESS = "FETCH_LOGO_SUCCESS";

// Analistas independientes
export const FETCH_ANALISTAS_SUCCESS = "FETCH_ANALISTAS_SUCCESS";

// COLOCACIONES
export const FETCH_COLOCACIONES_SUCCESS = "FETCH_COLOCACIONES_SUCCESS";
export const FETCH_COLOCACIONESDOCS_SUCCESS = "FETCH_COLOCACIONESDOCS_SUCCESS";
export const FETCH_COLOCACIONESINTERMEDIARIOS_SUCCESS = "FETCH_COLOCACIONESINTERMEDIARIOS_SUCCESS";
export const FETCH_COLOCACIONES_TIPOINSTRUMENTO_SUCCESS = "FETCH_COLOCACIONES_TIPOINSTRUMENTO_SUCCESS";

//INSTRUMENTOS ALTERNATIVOS

export const FETCH_EMISIONES_SUCCESS = "FETCH_EMISIONES_SUCCESS";
export const FETCH_TIPO_INSTRUMENTO_SUCCESS = "FETCH_TIPO_INSTRUMENTO_SUCCESS";
export const FETCH_MODO_LISTADO_SUCCESS = "FETCH_MODO_LISTADO_SUCCESS";
export const FETCH_ESTADO_SUCCESS = "FETCH_ESTADO_SUCCESS";
export const FETCH_SECTORIA_SUCCESS = "FETCH_SECTORIA_SUCCESS";
export const FETCH_CLAVESEMISIONES_SUCCESS = "FETCH_CLAVESEMISIONES_SUCCESS";
export const FETCH_EMISIONDETALLE_SUCCESS = "FETCH_EMISIONDETALLE_SUCCESS";
export const FETCH_COMITE_TECNICO_SUCCESS = "FETCH_COMITE_TECNICO_SUCCESS";
export const FETCH_CLAVESAGENDAINSALT_SUCCESS = "FETCH_CLAVESAGENDAINSALT_SUCCESS";
export const FETCH_MODO_LISTADO_AGENDA_SUCCESS = "FETCH_MODO_LISTADO_AGENDA_SUCCESS";
export const FETCH_TIPO_INSTRUMENTO_AGENDA_SUCCESS = "FETCH_TIPO_INSTRUMENTO_AGENDA_SUCCESS";

//INSTRUMENTOS ALTERNATIVOS PROSPECTOS
export const FETCH_PROSPECTOS_IA_SUCCESS = "FETCH_PROSPECTOS_IA_SUCCESS";
export const FETCH_CLAVES_PROSPECTOS_IA_SUCCESS = "FETCH_CLAVES_PROSPECTOS_IA_SUCCESS";
export const FETCH_TIPOS_BONO_PROSPECTOS_ASG = "FETCH_TIPOS_BONO_PROSPECTOS_ASG";

//INSTRUMENTOS ALTERNATIVOS REPORTES ASG
export const FETCH_REPORTES_ASG_SUCCESS = "FETCH_REPORTES_ASG_SUCCESS";
export const FETCH_CLAVES_REPORTES_ASG_SUCCESS = "FETCH_CLAVES_REPORTES_ASG_SUCCESS";
export const FETCH_EJERCICIO_REPORTES_ASG_SUCCESS = "FETCH_EJERCICIO_REPORTES_ASG_SUCCESS";

//INSTRUMENTOS ALTERNATIVOS GASTOS
export const FETCH_GASTOS_SUCCESS = "FETCH_GASTOS_SUCCESS";
export const FETCH_CLAVES_GASTOS_SUCCESS = "FETCH_CLAVES_GASTOS_SUCCESS";
export const FETCH_EJERCICIO_GASTOS_SUCCESS = "FETCH_EJERCICIO_GASTOS_SUCCESS";
export const FETCH_PERIODO_GASTOS_SUCCESS = "FETCH_PERIODO_GASTOS_SUCCESS";

//INSTRUMENTOS ALTERNATIVOS ANEXO AA
export const FETCH_ANEXO_AA_SUCCESS = "FETCH_ANEXO_AA_SUCCESS";
export const FETCH_CLAVES_ANEXO_AA_SUCCESS = "FETCH_CLAVES_ANEXO_AA_SUCCESS";
export const FETCH_EJERCICIO_ANEXO_AA_SUCCESS = "FETCH_EJERCICIO_ANEXO_AA_SUCCESS";
export const FETCH_PERIODO_ANEXO_AA_SUCCESS = "FETCH_PERIODO_ANEXO_AA_SUCCESS";

//INSTRUMENTOS ALTERNATIVOS VALUACIONES
export const FETCH_VALUACIONES_SUCCESS = "FETCH_VALUACIONES_SUCCESS";
export const FETCH_CLAVES_VALUACIONES_SUCCESS = "FETCH_CLAVES_VALUACIONES_SUCCESS";
export const FETCH_EJERCICIO_VALUACIONES_SUCCESS = "FETCH_EJERCICIO_VALUACIONES_SUCCESS";
export const FETCH_PERIODO_VALUACIONES_SUCCESS = "FETCH_PERIODO_VALUACIONES_SUCCESS";


//COSTOS, GASTOS Y COMISIONES
export const FETCH_COSTOS_SUCCESS = "FETCH_COSTOS_SUCCESS";


// BLOGS
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";

// BIVA SUSTENTABLE
export const FETCH_BIBLIOGRAFIA_SUCCESS = "FETCH_BIBLIOGRAFIA_SUCCESS";
export const FETCH_ACUERDOSIMAGENES_SUCCESS = "FETCH_ACUERDOSIMAGENES_SUCCESS";
export const FETCH_CITA_MARIA_SUCCESS = "FETCH_CITA_MARIA_SUCCESS";
export const FETCH_BIBLIOTECA_ASG_SUCCESS = "FETCH_BIBLIOTECA_ASG_SUCCESS";
export const FETCH_BANNERASG_SUCCESS = "FETCH_BANNERASG_SUCCESS";
export const FETCH_BANNER360_SUCCESS = "FETCH_BANNER360_SUCCESS";
export const FETCH_BUTTONS360_SUCCESS = "FETCH_BUTTONS360_SUCCESS";
export const FETCH_ALIADOS_SOLUCIONES_SUCCESS = "FETCH_ALIADOS_SOLUCIONES_SUCCESS";
export const FETCH_ESG_SUCCESS = "FETCH_ESG_SUCCESS";
export const FETCH_REPORTEO_SUCCESS = "FETCH_REPORTEO_SUCCESS";
export const FETCH_ACUERDOS_FIRMAS_SUCCESS = "FETCH_ACUERDOS_FIRMAS_SUCCESS";
export const FETCH_EMISIONES_ASG_SUCCESS = "FETCH_EMISIONES_ASG_SUCCESS";
export const FETCH_EMISION_ASG_DETALLE_SUCCESS = "FETCH_EMISION_ASG_DETALLE_SUCCESS";
export const FETCH_CLAVESEMISIONES_ASG_SUCCESS = "FETCH_CLAVESEMISIONES_ASG_SUCCESS";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";
export const FETCH_OBJETIVOS_SUCCESS = "FETCH_OBJETIVOS_SUCCESS";
export const FETCH_OBJETIVOSGUIA_SUCCESS = "FETCH_OBJETIVOSGUIA_SUCCESS";
export const FETCH_CAPITULOSGUIA_SUCCESS = "FETCH_CAPITULOSGUIA_SUCCESS";
export const FETCH_MATERIALIDAD_SUCCESS = "FETCH_MATERIALIDAD_SUCCESS";
export const FETCH_SIC_ETF_ASG_SUCCESS = "FETCH_SIC_ETF_ASG_SUCCESS";
export const FETCH_SIC_ETF_ASG_DETALLE_SUCCESS = "FETCH_SIC_ETF_ASG_DETALLE_SUCCESS";
export const FETCH_PAIS_SIC_ETF_ASG_SUCCESS = "FETCH_PAIS_SIC_ETF_ASG_SUCCESS";
export const FETCH_MERCADO_SIC_ETF_ASG_SUCCESS = "FETCH_MERCADO_SIC_ETF_ASG_SUCCESS";
export const FETCH_ALTERNATIVOS_ASG_SUCCESS = "FETCH_ALTERNATIVOS_ASG_SUCCESS";
export const FETCH_ALTERNATIVOS_ASG_FICHA_SUCCESS = "FETCH_ALTERNATIVOS_ASG_FICHA_SUCCESS";
export const FETCH_ALTERNATIVOS_ASG_TIPO_INSTRUMENTO_SUCCESS = "FETCH_ALTERNATIVOS_ASG_TIPO_INSTRUMENTO_SUCCESS";

export const FETCH_CLAVES_AMAFORE_SUCCESS = "FETCH_CLAVES_AMAFORE_SUCCESS";
export const FETCH_EJERCICIOS_AMAFORE_SUCCESS = "FETCH_EJERCICIOS_AMAFORE_SUCCESS";
export const FETCH_SECTORES_AMAFORE_SUCCESS = "FETCH_SECTORES_AMAFORE_SUCCESS";
export const FETCH_TIPOSEMISORA_AMAFORE_SUCCESS = "FETCH_TIPOSEMISORA_AMAFORE_SUCCESS";
export const FETCH_CATALOGO_SECTORES_AMAFORE_SUCCESS = "FETCH_CATALOGO_SECTORES_AMAFORE_SUCCESS";
export const FETCH_CATALOGO_TIPOSEMISORA_AMAFORE_SUCCESS = "FETCH_CATALOGO_TIPOSEMISORA_AMAFORE_SUCCESS";

export const FETCH_DESPLIEGUECULTURA_SUCCESS = "FETCH_DESPLIEGUECULTURA_SUCCESS";
export const FETCH_CLASIFICACIONASG_SUCCESS = "FETCH_CLASIFICACIONASG_SUCCESS";
export const FETCH_REPORTEOASG_SUCCESS = "FETCH_REPORTEOASG_SUCCESS";
export const FETCH_CONTEXTO_GENERAL_SUCCESS = "FETCH_CONTEXTO_GENERAL_SUCCESS";
export const FETCH_LINK_GUIA_SUCCESS = "FETCH_LINK_GUIA_SUCCESS";
export const FETCH_CUESTIONARIO_SUCCESS = "FETCH_CUESTIONARIO_SUCCESS";


// BUSCADOR
export const FETCH_SEARCH_SOLICITUDES = "FETCH_SEARCH_SOLICITUDES";
export const FETCH_SEARCH_PROSPECTOS = "FETCH_SEARCH_PROSPECTOS";
export const FETCH_PRELIMINARES_SEARCH = "FETCH_PRELIMINARES_SEARCH";
export const FETCH_SIC_SEARCH = "FETCH_SIC_SEARCH";
export const FETCH_COTIZACIONESEMISORAS_SEARCH = "FETCH_COTIZACIONESEMISORAS_SEARCH";
export const FETCH_AVISOSOFERTASPUBLICAS_SEARCH = "FETCH_AVISOSOFERTASPUBLICAS_SEARCH";
export const FETCH_EMISORAS_SEARCH = "FETCH_EMISORAS_SEARCH";
export const FETCH_EMISIONES_SEARCH = "FETCH_EMISIONES_SEARCH";
export const FETCH_BANCOINFORMACION_SEARCH = "FETCH_BANCOINFORMACION_SEARCH";
export const FETCH_AVISOSSUSPENCION_SEARCH = "FETCH_AVISOSSUSPENCION_SEARCH";
export const FETCH_EMISIONES_ASG_SEARCH = "FETCH_EMISIONES_ASG_SEARCH";



//CURSOS
export const FETCH_CURSOS_SUCCESS = "FETCH_CURSOS_SUCCESS";
export const FETCH_CHECKOUT_CURSOS_SUCCESS = "FETCH_CHECKOUT_CURSOS_SUCCESS";

import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();


const setMenu = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_MENU_SUCCESS,
      menu: null
    };
  }
  return {
    type: actionTypes.FETCH_MENU_SUCCESS,
    menu: data
  };
};

export const initMenu = () => {
  let url = `${dominio}/cms/o/text/menu/lang/${lang}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setMenu(response.data));
      })
      .catch(err => dispatch(setMenu(err.data)));
  };
};


// ===================================================
const setAvisoLegalFooter = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AVISOFOOTER_SUCCESS,
      avisoLegal: null
    };
  }
  return {
    type: actionTypes.FETCH_AVISOFOOTER_SUCCESS,
    avisoLegal: data
  };
};

export const initFooterAvisoLegal = () => {
  let url = `${dominio}/cms/o/documents/aviso-legal/lang/${lang}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAvisoLegalFooter(response.data));
      })
      .catch(err => dispatch(setAvisoLegalFooter(err.data)));
  };
};

// ===================================================
const setPrivacidadFooter = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PRIVACIDADFOOTER_SUCCESS,
      privacidad: null
    };
  }
  return {
    type: actionTypes.FETCH_PRIVACIDADFOOTER_SUCCESS,
    privacidad: data
  };
};

export const initFooterPrivacidad = () => {
  let url = `${dominio}/cms/o/documents/politicas-privacidad/lang/${lang}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPrivacidadFooter(response.data));
      })
      .catch(err => dispatch(setPrivacidadFooter(err.data)));
  };
};

// ===================================================
const setEnlaceFooter = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CENCORFOOTER_SUCCESS,
      enlace: null
    };
  }
  return {
    type: actionTypes.FETCH_CENCORFOOTER_SUCCESS,
    enlace: data
  };
};

export const initFooterEnlace = () => {
  let url = `${dominio}/cms/o/documents/enlace-cencor/lang/${lang}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEnlaceFooter(response.data));
      })
      .catch(err => dispatch(setEnlaceFooter(err.data)));
  };
};

// ==============================================

const setFooter = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_FOOTER_SUCCESS,
      footer: null
    };
  }
  return {
    type: actionTypes.FETCH_FOOTER_SUCCESS,
    footer: data
  };
};

export const initFooter = () => {
  let url = `${dominio}/cms/o/text/footer/lang/${lang}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setFooter(response.data));
      })
      .catch(err => dispatch(setFooter(err.data)));
  };
};


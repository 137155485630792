import React, { Component } from "react";
import classes from "./Negociacion.css";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class NegociacionBiva extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  onPrintTerminosHandler = terminos => {
    return <ToggleBox terminos={terminos} />;
  };
  componentDidMount() {
    this.props.onInitIntl();
    this.props.onTogglebox();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    const terminos = this.props.toggleBox ? this.onPrintTerminosHandler(this.props.toggleBox) : null;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
      <Auxs>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={["margen_top_30"].join(" ")}>
              <h1 className={["nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="invertir.negociacionenbiva.titulo1" defaultMessage=" " />
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={[classes.text_container, "margen_top_50"].join(" ")}>
              <p className={["texto_16", "montse"].join(" ")}>
              <FormattedHTMLMessage id="invertir.negociacionenbiva.introduccion1" defaultMessage=" " />
                {/* En BIVA existen varios modelos de negociación para la compra-venta de valores: */}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={[classes.terminosContainer, "margen_top_50"].join(" ")}>{terminos}</div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={[classes.text_container, "margen_top_30"].join(" ")}>
              <p className={["texto_16", "montse"].join(" ")}>
              <FormattedHTMLMessage id="invertir.negociacionenbiva.introduccion2" defaultMessage=" " />

              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={["margen_top_50"].join(" ")}>
              <h1 className={["nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="invertir.negociacionenbiva.titulo2" defaultMessage=" " />
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={[classes.text_container, classes.contenedor_posturas,"texto_16", "montse regular", "margen_top_30"].join(" ")}>
              <FormattedHTMLMessage id="invertir.negociacionenbiva.introduccion3" defaultMessage=" " />
            
            </div>
          </div>
        </div>
      </Auxs>
      </IntlProvider>
      );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    toggleBox: state.toggleBox.negociacionBiva
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(22));
    },
    onTogglebox: () => {
      dispatch(action.getNegociacionBivaToggleBox());
    }
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NegociacionBiva);

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../stores/actions/index";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";
import FilterDocumentosSolicitudes from "../../../../components/ui/filterDocumentosSolicitudes/FilterDocumentosSolicitudes";
import { getLan } from "../../../../stores/utils/utilities";

class DocumentosSolicitudes extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    page: 0,
    tipoDocumento: null,
    numEnvio: null,
    empresaId: null,
    razonSocial: null,
    clave: null,
    numeroTramite: null
  };

  componentWillReceiveProps(nextProps) {
    let documentos = null;

    if (nextProps.documentos && nextProps.documentos.content) {
      documentos = nextProps.documentos.content;
    }
    let resource = documentos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.documentos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
      this.props.history.push(
        `?tramiteId=${this.state.empresaId ? this.state.empresaId : null}&tipoDocumento=${this.state.tipoDocumento}&numEnvio=${
          this.state.numEnvio
        }&page=${pageNumber}&empresaId=${this.state.empresaId}&razonSocial=${this.state.razonSocial}&clave=${
          this.state.clave
        }&numeroTramite=${this.state.numeroTramite}`
      );
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.props.onInitDocumentosListado(
        this.state.itemsPerPage,
        pageNumber - 1,
        this.props.tramiteSeleccionado,
        this.state.tipoDocumento,
        this.state.numEnvio,
        this.props.tipo
      );
      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.documentos !== prevProps.documentos) {
      this.setState({
        loader: false
      });
    }
    if (this.props.location.search !== prevProps.location.search) {
      this.initData();
    }
  }

  componentDidMount() {
    this.initData();
  }

  initData(){
    let paramsString = queryString.parse(this.props.location.search);

    let tramiteId = this.props.tramiteSeleccionado ? this.props.tramiteSeleccionado : null;
    let tipoDocumento = null;
    let numEnvio = null;
    let page = 1;
    let empresaId = null,
      razonSocial = null,
      clave = null,
      numeroTramite = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      if (tramiteId === null) {
        tramiteId = paramsString["tramiteId"] === "null" ? null : paramsString["tramiteId"].split(",");
      }
      tipoDocumento =
      paramsString["tipoDocumento"] === undefined || paramsString["tipoDocumento"] === "null"
          ? null
          : paramsString["tipoDocumento"].includes(",")
          ? paramsString["tipoDocumento"].split(",")
          : paramsString["tipoDocumento"];
      numEnvio = paramsString["numEnvio"] === "null" ? null : paramsString["numEnvio"];
      page = paramsString["page"] === "null" ? 1 : parseInt(paramsString["page"]);
      empresaId = paramsString["empresaId"] === "null" ? null : paramsString["empresaId"];
      razonSocial = paramsString["razonSocial"] === "null" ? null : paramsString["razonSocial"];
      clave = paramsString["clave"] === "null" ? null : paramsString["clave"];
      numeroTramite = paramsString["numeroTramite"] === "null" ? null : paramsString["numeroTramite"];
    }
    this.props.onInitDocumentosListado(this.state.itemsPerPage, page - 1, tramiteId, tipoDocumento, numEnvio, this.props.tipo);
    this.setState({
      empresaId: empresaId,
      razonSocial: razonSocial,
      clave: clave,
      numeroTramite: numeroTramite,
      activePage: page
    });
  }

  onSetTipoDocumento = (tipoDocumento, consulta) => {
    this.setState({
      tipoDocumento: tipoDocumento
    });
  };
  onSetNumEnvio = (numEnvio, consulta) => {
    this.setState({
      numEnvio: numEnvio
    });
  };

  onSetPageDocumento = page => {
    this.setState({
      activePage: page
    });
  };

  onSetParams = ({ tramiteId, page, tipoDocumento, numEnvio, empresaId, razonSocial, clave, numeroTramite, reset = false }) => {
    if (reset) {
      this.changeHistoryReset(this.props.tramiteSeleccionado, this.state.clave, this.state.numeroTramite);
    } else {
      this.changeHistory(this.props.tramiteSeleccionado, tipoDocumento, numEnvio, empresaId, razonSocial, clave, numeroTramite, page);
    }
  };

  changeHistory(
    tramiteId = null,
    tipoDocumento = null,
    numEnvio = null,
    empresaId = null,
    razonSocial = null,
    clave = null,
    numeroTramite = null,
    page = 1
  ) {
    this.props.history.push(
      `?tramiteId=${tramiteId}&tipoDocumento=${tipoDocumento}&numEnvio=${numEnvio}&page=${page}&empresaId=${empresaId}&razonSocial=${razonSocial}&clave=${clave}&numeroTramite=${numeroTramite}`
    );

    this.setState({
      loader: true
    });
    this.props.onInitDocumentosListado(this.state.itemsPerPage, page - 1, tramiteId, tipoDocumento, numEnvio, this.props.tipo);
  }

  changeHistoryReset(tramiteId, clave, numeroTramite) {
    this.props.history.push(
      `?tramiteId=${tramiteId}&tipoDocumento=${null}&numEnvio=${null}&page=${1}&empresaId=${null}&razonSocial=${null}&clave=${clave}&numeroTramite=${numeroTramite}`
    );

    this.props.onInitDocumentosListado(this.state.itemsPerPage, 0, tramiteId, null, null, this.props.tipo);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let dataToShow = null;
    let documentos = this.props.documentos && this.props.documentos.content ? this.props.documentos.content : [];

    if (documentos.length > 0) {
      dataToShow = (
        <div>
          <Accordion
            datos={documentos}
            nombre="documentos"
            icon={""}
            tipo={15}
            titulo={getLan() === "es" ? "Más Información" : "More information"}
            subtipo={3}
          />
        </div>
      );
    } else if (documentos.length === 0) {
      dataToShow = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda.": "No results found."}</p>;
    }

    const numRows = this.props.documentos ? this.props.documentos.totalElements : 0;

    let filter = null;
    if(this.props.tipo !== 2){
      filter = (
        <FilterDocumentosSolicitudes
        modelo="modelo_1"
        tramiteId={this.props.tramiteSeleccionado}
        itemsPerPage={this.state.itemsPerPage}
        setTipoDocumento={(tipoDocumento, consulta) => this.onSetTipoDocumento(tipoDocumento, consulta)}
        setNumEnvio={(numEnvio, consulta) => this.onSetNumEnvio(numEnvio, consulta)}
        setPageDocumento={page => this.onSetPageDocumento(page)}
        empresaId={this.state.empresaId}
        razonSocial={this.state.razonSocial}
        clave={this.state.clave}
        numeroTramite={this.state.numeroTramite}
        setParams={filterParams => this.onSetParams(filterParams)}
      />
      )

    }
    return (
      <React.Fragment>
        {loader}

        <div className="">
          <Accordion
            datos={this.props.datos}
            detalles={this.props.detalles}
            nombre="documentos"
            icon={""}
            tipo={15}
            titulo={getLan() === "es" ? "Más Información" : "More information"}
            subtipo={2}
          />
        </div>

       {filter}

        <div className="margen_top_30">{dataToShow}</div>
        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitDocumentosListado: (size, page, tramiteId, tipoDocumentoId, numEnvio, tipo) => {
      dispatch(actions.initDocumentosListado(size, page, tramiteId, tipoDocumentoId, numEnvio, tipo));
    }
  };
};

const mapStateToProps = state => {
  return {
    documentos: state.listadoValores.documentos
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DocumentosSolicitudes)
);

import { combineReducers } from "redux";

import Menu from "./reducers/menu";
import Videos from "./reducers/videos";
import Noticias from "./reducers/noticias";
import Trkd from "./reducers/trkd";
import Activas from "./reducers/activas";
import Indice from "./reducers/indice";
import Genericos from "./reducers/genericos";
import Casas from "./reducers/casas";
import Cursos from "./reducers/cursos";
import Funciones from "./reducers/funciones";
import Posturas from "./reducers/posturas";
import FormadorMercado from "./reducers/formadorMercado";
import Cambio from "./reducers/cambio";
import Eventos from "./reducers/eventos";
import Banner from "./reducers/banner";
import SalaPrensa from "./reducers/salaPrensa";
import Instrumentos from "./reducers/instrumentos";
import TipoValor from "./reducers/tipoValor";
import SectorSubSector from "./reducers/sectorSubSector";
import RamosSubramos from "./reducers/ramoSubRamo";
import Emisoras from "./reducers/emisoras";
import TipoInformacion from "./reducers/tipoInformacion";
import TipoDocumento from "./reducers/tipoDocumento";
import SendEmail from "./reducers/sendEmail";
import Agenda from "./reducers/agendas"
import Tramites from "./reducers/tramites"
import BancosInformacion from "./reducers/bancosInformacion"
import ListadoValores from "./reducers/listadoValores";
import Documentos from "./reducers/documentos";
import Prospectos from "./reducers/prospectos";
import PeriodoEjercicio from "./reducers/periodoEjercicio";
import Internacionalizacion from "./reducers/internacionalizacion";
import RequisitosMantenimiento from "./reducers/requisitosMantenimiento";
import Sic from "./reducers/sic";
import ToggleBox from "./reducers/toggleBox";
import Imagenes from "./reducers/imagenes";
import InformacionMercado from "./reducers/informacionMercado";
import AvisosReportes from "./reducers/avisosReportes";
import Cuadriculas from "./reducers/cuadriculas";
import Rss from "./reducers/rss";
import Fondos from "./reducers/fondos";
import Afores from "./reducers/afores";
import Accordion from "./reducers/accordion";
import Contactos from "./reducers/contactos";
import Diccionario from "./reducers/diccionario";
import BIVACloud from  "./reducers/bivaCloud";
import Colocaciones from "./reducers/colocaciones";
import InstrumentosAlternativos from "./reducers/instrumentosAlternativos";
import Blog from "./reducers/blog";
import BivaSustentable from "./reducers/bivaSustentable";
import Search from "./reducers/Search";

const rootReducer = combineReducers({
  menu: Menu,
  blog: Blog,
  bivaSustentable: BivaSustentable,
  sustentable: BivaSustentable,
  trkd: Trkd,
  videos: Videos,
  noticias: Noticias,
  activas: Activas,
  indice: Indice,
  genericos: Genericos,
  casas: Casas,
  funciones: Funciones,
  posturas: Posturas,
  formadorMercado: FormadorMercado,
  cambio: Cambio,
  eventos: Eventos,
  banner: Banner,
  salaPrensa: SalaPrensa,
  instrumentos: Instrumentos,
  tipoValor: TipoValor,
  emisoras: Emisoras,
  requisitosMantenimiento: RequisitosMantenimiento,
  sectorSubSector: SectorSubSector,
  ramoSubRamo: RamosSubramos,
  tipoInformacion: TipoInformacion,
  tipoDocumento: TipoDocumento,
  sendEmail: SendEmail,
  listadoValores: ListadoValores,
  bancosInformacion: BancosInformacion,
  sic: Sic,
  tramites: Tramites,
  agenda: Agenda,
  prospectos: Prospectos,
  documentos: Documentos,
  imagenes: Imagenes,
  informacionMercado: InformacionMercado,
  intl: Internacionalizacion,
  periodoEjercicios: PeriodoEjercicio,
  avisosReportes: AvisosReportes,
  cuadriculas: Cuadriculas,
  toggleBox: ToggleBox,
  rss: Rss,
  fondos: Fondos,
  afores: Afores,
  accordion: Accordion,
  contactos: Contactos,
  diccionario: Diccionario,
  bivacloud : BIVACloud,
  colocaciones : Colocaciones,
  instrumentosAlternativos : InstrumentosAlternativos,
  search : Search,
  cursos : Cursos
});

export default rootReducer;

import React, { Component } from "react";
import classes from "./FilterConcentradoPreciosAfores.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { changeDateFormat } from "../../../stores/utils/utilities";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import { getLan } from "../../../stores/utils/utilities";

import Input from "../input/Input";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  label,
  onInputChange,
  placeholder = null,
  options,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    label: label,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      <label className={["montse", "texto_14", "regular"].join(" ")}>
        {label}
      </label>
      <Input config={params} />
    </div>
  );
};

class FilterConcentradoPreciosAfores extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    toggleFilters: true,
    disableEnableButtons: true,
    selectedFilters: {
      clave_afore: [],
      clave_siefore: [],
      tipo_siefore: [],
      clasificacion_siefore: [],
      fechas: []
    },
    form_filters: {
      clave_afore: {
        value: ""
      },
      clave_siefore: {
        value: ""
      },
      tipo_siefore: {
        value: ""
      },
      clasificacion_siefore: {
        value: ""
      },
      fecha_inicio: {
        value: ""
      },
      fecha_fin: {
        value: ""
      },
      errorDate: false
    },
    inputPivot: false,
    inputSubPivot: false,
    prevInputSelected: false
  };

  componentDidMount() {
    this.onFilterClaveAforeHandler();
    this.onFilterClaveSieforeHandler();
    this.onFilterTipoSieforeHandler();
    this.onFilterClasificacionSieforeHandler();
  }

  onFilterClaveAforeHandler = (
    claveSiefore = null,
    tipoSiefore = null,
    clasificacionSiefore = null
  ) => {
    let params = {
      claveSiefore: claveSiefore,
      tipoSiefore: tipoSiefore,
      clasificacionSiefore: clasificacionSiefore,
      precios:true
    };
    this.props.onFilterClaveAfore(params);
  };

  onFilterClaveSieforeHandler = (
    claveAfore = null,
    tipoSiefore = null,
    clasificacionSiefore = null
  ) => {
    let params = {
      claveAfore: claveAfore,
      tipoSiefore: tipoSiefore,
      clasificacionSiefore: clasificacionSiefore,
      precios:true
    };
    this.props.onFilterClaveSiefore(params);
  };

  onFilterTipoSieforeHandler = (
    claveAfore = null,
    claveSiefore = null,
    clasificacionSiefore = null
  ) => {
    let params = {
      claveAfore: claveAfore,
      claveSiefore: claveSiefore,
      clasificacionSiefore: clasificacionSiefore,
      precios:true
    };
    this.props.onFilterTipoSiefore(params);
  };

  onFilterClasificacionSieforeHandler = (
    claveAfore = null,
    claveSiefore = null,
    tipoSiefore = null
  ) => {
    let params = {
      claveAfore: claveAfore,
      claveSiefore: claveSiefore,
      tipoSiefore: tipoSiefore,
      precios:true
    };
    this.props.onFilterClasificacionSiefore(params);
  };

  printFrameHandler = () => {
    const input = this.showFiltersSelectedHandler();
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div
              className={[
                "col",
                "col-lg-3",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_borders_filter_button_model_one
                    : null
                ].join(" ")}
                onClick={
                  this.state.disableEnableButtons ? this.onToggleFilters : null
                }
              >
                <button
                  type="button"
                  className="btn"
                  disabled={!this.state.disableEnableButtons}
                >
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar Filtros"
                        : "Close"
                      : getLan() === "es"
                      ? "Filtrar"
                      : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={[
                "col",
                "col-lg-9",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div className={classes.filters_selected_container_model_one}>
                {input}
              </div>
            </div>
          </div>
          <div
            className={[
              "row",
              classes.main_filter_container_model_one,
              !this.state.toggleFilters ? classes.hidden : null
            ].join(" ")}
          >
            <div
              className={["col", classes.no_col_padding_model_one].join(" ")}
            >
              {this.printModel()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  printModel = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              clave_afore: this.state.form_filters.clave_afore.value,
              clave_siefore: this.state.form_filters.clave_siefore.value,
              tipo_siefore: this.state.form_filters.tipo_siefore.value,
              clasificacion_siefore: this.state.form_filters
                .clasificacion_siefore.value,
              fecha_inicio: this.state.form_filters.fecha_inicio.value,
              fecha_fin: this.state.form_filters.fecha_fin.value
            }}
            render={({
              optionsClaveAfore = this.props.clave_afore
                ? this.onPrepareOptionHandler(this.props.clave_afore)
                : [],
              optionsClaveSiefore = this.props.clave_siefore
                ? this.onPrepareOptionHandler(this.props.clave_siefore)
                : [],
              optionsTipoSiefore = this.props.tipo_siefore
                ? this.onPrepareOptionHandler(this.props.tipo_siefore)
                : [],
              optionClasificacionSiefore = this.props.clasificacion_siefore
                ? this.onPrepareOptionHandler(this.props.clasificacion_siefore)
                : [],
              form
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-3  ">
                    <Field
                      name="clave_afore"
                      id="clave_afore"
                      label={getLan() === "es" ? "Clave de AFORE" : "AFORE"}
                      type="select"
                      elementType="select"
                      classes="custom-select"
                      options={optionsClaveAfore}
                      onInputChange={event =>
                        this.onInputSelectedHandler(event, 0)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className={["col-12 col-sm-12 col-md-6 col-lg-3"].join(" ")}>
                    <Field
                      name="clave_siefore"
                      id="clave_siefore"
                      label={getLan() === "es" ? "Clave de SIEFORE": "SIEFORE"}
                      type="select"
                      elementType="select"
                      classes="custom-select"
                      options={optionsClaveSiefore}
                      onInputChange={event =>
                        this.onInputSelectedHandler(event, 1)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-3  ">
                    <Field
                      name="tipo_siefore"
                      id="tipo_siefore"
                      label={getLan() === "es" ? "Tipo de SIEFORE" : "SIEFORE type"}
                      type="select"
                      elementType="select"
                      classes="custom-select"
                      options={optionsTipoSiefore}
                      onInputChange={event =>
                        this.onInputSelectedHandler(event, 2)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className={["col-12 col-sm-12 col-md-6 col-lg-3"].join(" ")}>
                    <Field
                      name="clasificacion_siefore"
                      id="clasificacion_siefore"
                      label={getLan() === "es" ? "Clasificación de la SIEFORE":"SIEFORE classification"}
                      type="select"
                      elementType="select"
                      classes="custom-select"
                      options={optionClasificacionSiefore}
                      onInputChange={event =>
                        this.onInputSelectedHandler(event, 3)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 offset-lg-6 sin_padding">
                    <div className="row">
                      <div
                        className={["col col-lg-6"].join(" ")}
                      >
                        <Field
                          name="fecha_inicio"
                          id="fecha_inicio"
                          type="date"
                          classes={classes.fechaInicio}
                          label={getLan() === "es" ? "Fecha inicio": "From"}
                          onInputChange={event =>
                            this.onInputSelectedHandler(event, 4)
                          }
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={["col col-lg-6"].join(" ")}
                      >
                        <Field
                          name="fecha_fin"
                          id="fecha_fin"
                          type="date"
                          classes={classes.fechaFin}
                          label={getLan() === "es" ? "Fecha fin" : "To"}
                          onInputChange={event =>
                            this.onInputSelectedHandler(event, 5)
                          }
                          component={renderInput}
                        />
                      </div>
                      <div
                        className={[
                          classes.errorContainer,
                          !this.state.form_filters.errorDate
                            ? classes.hidden
                            : null
                        ].join(" ")}
                      >
                         <p className={"texto_14 montse regular"}>
                            {getLan() === "es"
                              ? "La fecha final debe ser mayor a la fecha inicial."
                              : "End date must be greater than start date."}
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {this.sectionButtonHandler(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonHandler = form => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14"
            ].join(" ")}
            onClick={() => this.onResetFilterHandler()}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Permite ocultar o mostrar la seccion del formulario del filtro
  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onSubmitFilterHandler = event => {
    event.preventDefault();
  };

  // Limpia le formulario
  onResetFilterHandler = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    // Limpiando el filtro clave_afore
    form.clave_afore.value = "";
    selectedFilters.clave_afore = [];
    // Limpiando el filtro clave_siefore
    form.clave_siefore.value = "";
    selectedFilters.clave_siefore = [];
    // Limpiando el filtro tipo_siefore
    form.tipo_siefore.value = "";
    selectedFilters.tipo_siefore = [];
    // Limpiando el filtro clasificacion_siefore
    form.clasificacion_siefore.value = "";
    selectedFilters.clasificacion_siefore = [];
    // Se limpia errorData para validacion de fechas
    form.fecha_inicio.value = "";
    form.fecha_inicio.disabled = false;
    form.fecha_fin.value = "";
    form.fecha_fin.disabled = false;
    selectedFilters.fechas = [];
    form.errorDate = false;

    let inputPivot = false;
    let inputSubPivot = false;
    let prevInputSelected = false;
    

    this.onFilterClaveAforeHandler();
    this.onFilterClaveSieforeHandler();
    this.onFilterTipoSieforeHandler();
    this.onFilterClasificacionSieforeHandler();

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      inputPivot: inputPivot,
      inputSubPivot: inputSubPivot,
      prevInputSelected: prevInputSelected
    });

    this.onFilterDataByEvent();
  };

  // Formatea los valores de los campos select
  onPrepareOptionHandler = type => {
    let options = [];
    if (type && type.length > 0) {
      type.forEach(element => {
        options.push({
          nombre: element.nombre,
          id: `${element.id}_${element.nombre}`
        });
      });
    }
    return options;
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    newValues = options.split("_")[0];
    return newValues;
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type) => {
    let newValue = null;
    let newLabel = null;

    if (type !== 4 && type !== 5) {
      newValue = value.split("_")[0];
      newLabel = value.split("_")[1];
    } else {
      // Fechas inicio o fin
      [newValue] = changeDateFormat(value);
      newLabel = type === 4 ? "fecha_inicio" : "fecha_fin";
    }

    return [newValue, newLabel];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return [
          "clave_afore",
          form.clave_afore.value,
          selectedFilters.clave_afore
        ];
      case 1:
        return [
          "clave_siefore",
          form.clave_siefore.value,
          selectedFilters.clave_siefore
        ];
      case 2:
        return [
          "tipo_siefore",
          form.tipo_siefore.value,
          selectedFilters.tipo_siefore
        ];
      case 3:
        return [
          "clasificacion_siefore",
          form.clasificacion_siefore.value,
          selectedFilters.clasificacion_siefore
        ];
      case 4:
        return [
          "fecha_inicio",
          form.fecha_inicio.value,
          selectedFilters.fechas,
          "fechas"
        ];
      case 5:
        return [
          "fecha_fin",
          form.fecha_fin.value,
          selectedFilters.fechas,
          "fechas"
        ];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor del campo clave afore
    const valuesClaveAfore =
      form.clave_afore.value && form.clave_afore.value !== ""
        ? this.onSplitValueHandler(form.clave_afore.value)
        : "";
    // Se obtiene el valor del campo clave siefore
    const valuesClaveSiefore =
      form.clave_siefore.value && form.clave_siefore.value !== ""
        ? this.onSplitValueHandler(form.clave_siefore.value)
        : "";
    // Se obtiene el valor del campo tipo siefore
    const valuesTipoSiefore =
      form.tipo_siefore.value && form.tipo_siefore.value !== ""
        ? this.onSplitValueHandler(form.tipo_siefore.value)
        : "";
    // Se obtiene el valor del campo clasificacion siefore
    const valuesClasificacionSiefore =
      form.clasificacion_siefore.value &&
      form.clasificacion_siefore.value !== ""
        ? this.onSplitValueHandler(form.clasificacion_siefore.value)
        : "";
    // Se obtiene el valor del campo fecha inicio
    const valuesFechaInicio =
      form.fecha_inicio.value && form.fecha_inicio.value !== ""
        ? form.fecha_inicio.value
        : "";
    // Se obtiene el valor del campo fecha fin
    const valuesFechaFin =
      form.fecha_fin.value && form.fecha_fin.value !== ""
        ? form.fecha_fin.value
        : "";

    return [
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoSiefore,
      valuesClasificacionSiefore,
      valuesFechaInicio,
      valuesFechaFin
    ];
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (values && values !== "") {
      newStateValues = values;
    } 
    return newStateValues;
  };


  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (
    filterOptions = null,
    values = null,
    label = null,
    type = null
  ) => {
    let newFilterValues = [];

    if (type !== 4 && type !== 5) {
      if (values && values !== "") {
        newFilterValues.push({
          label: label,
          value: values,
          type: type
        });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(
        option => option.label === label
      );
      if (filterExists) {
        newFilterValues = newFilterValues.filter(
          option => option.label !== label
        );
        if (values && values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      } else {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    }

    return newFilterValues;
  };

  onFilterDataByEvent = () => {
    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoSiefore,
      valuesClasificacionSiefore,
      valuesFechaInicio,
      valuesFechaFin
    ] = this.onGetAllValuesFormFilter();

    const params = {
      size: this.props.itemsPerPage,
      page: 1,
      claveAfore: valuesClaveAfore,
      claveSiefore: valuesClaveSiefore,
      tipoSiefore: valuesTipoSiefore,
      clasificacionSiefore: valuesClasificacionSiefore,
      fechaInicio:valuesFechaInicio,
      fechaFin :valuesFechaFin
    };

    this.props.setFilters(params);

    this.props.setPage(1);

    let wasFiltered = false;
    if (!this.state.form_filters.errorDate) {

    // Se invoca la funcion del componente padre (ConcentradoPreciosAfores.js) para setear los valores correspondientes
    // Configurando valor del campo clave_afore
    this.props.onSetClaveAfore(valuesClaveAfore);
    // Configurando valor del campo clave_siefore
    this.props.onSetClaveSiefore(valuesClaveSiefore);
    // Configurando valor del campo tipo_siefore
    this.props.onSetTipoSiefore(valuesTipoSiefore);
    // Configurando valor del campo clasificacion_siefore
    this.props.onSetClasificacionSiefore(valuesClasificacionSiefore);
    // Configurando valor del campo fecha_inicio
    this.props.onSetFechaInicio(valuesFechaInicio);
    // Configurando valor del campo fecha_fin
    this.props.onSetFechaFin(valuesFechaFin);

    if (
      (valuesClaveAfore !== "" ||
        valuesClaveSiefore !== "" ||
        valuesTipoSiefore !== "" ||
        valuesClasificacionSiefore !== "" ||
        valuesFechaInicio !== "",
      valuesFechaFin !== "")
    ) {
      wasFiltered = true;
    }
    this.props.onSetWasFiltered(wasFiltered);
  }

  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (
    event = null,
    type = null,
    item = null,
    eraser = null
  ) => {
    let actualValue = eraser ? event : event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let inputPivot = this.state.inputPivot;
    let inputSubPivot = this.state.inputSubPivot;
    let prevInputSelected = this.state.prevInputSelected;
    let errorDate = form.errorDate;

    let newValue = null;
    let newLabel = null;

    if (eraser) {
      actualValue = "";
      newValue = "";
      newLabel = item.label;
      type = item.type;
    } else {
      // Solo para los campos distintos a tipo instrumento y tipo valor
      [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(
        actualValue,
        type
      );
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [
      inputName,
      stateValues,
      filterValues,
      otherInputName
    ] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(
      filterValues,
      newValue,
      newLabel,
      type
    );

    form[`${inputName}`].value = newStateValues;
    selectedFilters[
      `${otherInputName ? otherInputName : inputName}`
    ] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoSiefore,
      valuesClasificacionSiefore,
      valuesFechaInicio,
      valuesFechaFin
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      // Clave Afore
      // Cuando el campo clave afore es seleccionado de primero
      if (!inputPivot || inputPivot === "firstPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
        if (eraser) {
          inputPivot = false;
          inputSubPivot = false;
          prevInputSelected = false;
        } else {
          // Se configura a null inputPivot si el valor de clave_afore es vacio
          inputPivot = valuesClaveAfore !== "" ? "firstPath" : null;
        }
        // Limpiando valores campo clave siefore
        form.clave_siefore.value = "";
        selectedFilters.clave_siefore = [];
        // Filtrando valores para campo clave siefore
        this.onFilterClaveSieforeHandler(valuesClaveAfore);
        // Limpiando valores campo tipo siefore
        form.tipo_siefore.value = "";
        selectedFilters.tipo_siefore = [];
        // Filtrando valores para campo tipo siefore
        this.onFilterTipoSieforeHandler(valuesClaveAfore);
        // Limpiando valores campo clasificacion siefore
        form.clasificacion_siefore.value = "";
        selectedFilters.clasificacion_siefore = [];
        // Filtrando valores para campo clasificacion siefore
        this.onFilterClasificacionSieforeHandler(valuesClaveAfore);
      } else if (inputPivot === "secondPath") {
        if (!inputSubPivot || inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clave afore es vacio
            inputSubPivot = valuesClaveAfore !== "" ? "clave_afore" : null;
          }
          // Limpiando valores campo tipo siefore
          form.tipo_siefore.value = "";
          selectedFilters.tipo_siefore = [];
          // Filtrando valores para campo tipo siefore
          this.onFilterTipoSieforeHandler(valuesClaveAfore, valuesClaveSiefore);
          // Limpiando valores campo clasificacion siefore
          form.clasificacion_siefore.value = "";
          selectedFilters.clasificacion_siefore = [];
          // Filtrando valores para campo clasificacion siefore
          this.onFilterClasificacionSieforeHandler(
            valuesClaveAfore,
            valuesClaveSiefore
          );
        } else if (inputSubPivot === "tipo_siefore") {
          // Cuando el campo tipo_siefore es seleccionado de segundo y el campo clave_afore de tercero
          // Si se el campo clave_afore se setea a vacio.
          if (eraser && prevInputSelected === "clave_afore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_afore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clasificacion_siefore"
                ? "clasificacion_siefore"
                : "clave_afore";
          }
          // Si el campo clave_afore es el penultimo seleccionado se filtra el campo clasificacion_siefore
          if (
            !prevInputSelected ||
            prevInputSelected !== "clasificacion_siefore"
          ) {
            // Limpiando valores campo clasificacion siefore
            form.clasificacion_siefore.value = "";
            selectedFilters.clasificacion_siefore = [];
            // Filtrand valores para campo clasificacion siefore
            this.onFilterClasificacionSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesTipoSiefore
            );
          }
        } else if (inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo y el campo clave_afore de tercero
          // Si se el campo clave_afore se setea a vacio.
          if (eraser && prevInputSelected === "clave_afore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_afore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "tipo_siefore"
                ? "tipo_siefore"
                : "clave_afore";
          }
          // Si el campo clave_afore es el penultimo seleccionado se filtra el campo tipo_siefore
          if (!prevInputSelected || prevInputSelected !== "tipo_siefore") {
            // Limpiando valores campo tipo siefore
            form.tipo_siefore.value = "";
            selectedFilters.tipo_siefore = [];
            // Filtrand valores para campo tipo siefore
            this.onFilterTipoSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "thirdPath") {
        if (!inputSubPivot || inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clave afore es vacio
            inputSubPivot = valuesClaveAfore !== "" ? "clave_afore" : null;
          }
          // Limpiando valores campo clave siefore
          form.clave_siefore.value = "";
          selectedFilters.clave_siefore = [];
          // Filtrando valores para campo clave siefore
          this.onFilterClaveSieforeHandler(valuesClaveAfore, valuesTipoSiefore);
          // Limpiando valores campo clasificacion siefore
          form.clasificacion_siefore.value = "";
          selectedFilters.clasificacion_siefore = [];
          // Filtrando valores para campo clasificacion siefore
          this.onFilterClasificacionSieforeHandler(
            valuesClaveAfore,
            null,
            valuesTipoSiefore
          );
        } else if (inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo y el campo clave_afore de tercero.
          // Si se el campo clave_afore se setea a vacio.
          if (eraser && prevInputSelected === "clave_afore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_afore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clasificacion_siefore"
                ? "clasificacion_siefore"
                : "clave_afore";
          }
          // Si el campo clave_afore es el penultimo seleccionado se filtra el campo clasificacion_siefore.
          if (
            !prevInputSelected ||
            prevInputSelected !== "clasificacion_siefore"
          ) {
            // Limpiando valores campo clasificacion siefore
            form.clasificacion_siefore.value = "";
            selectedFilters.clasificacion_siefore = [];
            // Filtrando valores para campo clasificacion siefore
            this.onFilterClasificacionSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesTipoSiefore
            );
          }
        } else if (inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo y el campo clave_afore de tercero
          // Si se el campo clave_afore se setea a vacio.
          if (eraser && prevInputSelected === "clave_afore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_afore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_siefore"
                ? "clave_siefore"
                : "clave_afore";
          }
          // Si el campo clave_afore es el penultimo seleccionado se filtra el campo clave_siefore.
          if (!prevInputSelected || prevInputSelected !== "clave_siefore") {
            // Limpiando valores campo clave siefore
            form.clave_siefore.value = "";
            selectedFilters.clave_siefore = [];
            // Filtrando valores para campo clave siefore
            this.onFilterClaveSieforeHandler(
              valuesClaveAfore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "fourthPath") {
        if (!inputSubPivot || inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser || valuesClaveAfore === "") {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clave afore es vacio
            inputSubPivot = valuesClaveAfore !== "" ? "clave_afore" : null;
          }
          // Limpiando valores campo clave siefore
          form.clave_siefore.value = "";
          selectedFilters.clave_siefore = [];
          // Filtrando valores para campo clave siefore
          this.onFilterClaveSieforeHandler(
            valuesClaveAfore,
            null,
            valuesClasificacionSiefore
          );
          // Limpiando valores campo tipo siefore
          form.tipo_siefore.value = "";
          selectedFilters.tipo_siefore = [];
          // Filtrando valores para campo tipo siefore
          this.onFilterTipoSieforeHandler(
            valuesClaveAfore,
            null,
            valuesClasificacionSiefore
          );
        } else if (inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo y el campo clave_afore de tercero
          // Si se el campo clave_afore se setea a vacio.
          if (eraser && prevInputSelected === "clave_afore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_afore es el penultimo seleccionad.
            prevInputSelected =
              prevInputSelected === "tipo_siefore"
                ? "tipo_siefore"
                : "clave_afore";
          }
          // Si el campo clave_afore es el penultimo se filtra el campo tipo_siefore
          if (!prevInputSelected || prevInputSelected !== "tipo_siefore") {
            // Limpiando valores campo tipo siefore
            form.tipo_siefore.value = "";
            selectedFilters.tipo_siefore = [];
            // Filtrand valores para campo tipo siefore
            this.onFilterTipoSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesClasificacionSiefore
            );
          }
        } else if (inputSubPivot === "tipo_siefore") {
          // Cuando el campo tipo_siefore es seleccionado de segundo y el campo clave_afore de tercero
          // Si se el campo clave_afore se setea a vacio.
          if (eraser && prevInputSelected === "clave_afore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_afore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_siefore"
                ? "clave_siefore"
                : "clave_afore";
          }
          // Si el campo clave_afore es el penultimo se filtra el campo clave_siefore
          if (!prevInputSelected || prevInputSelected !== "clave_siefore") {
            // Limpiando valores campo clave siefore
            form.clave_siefore.value = "";
            selectedFilters.clave_siefore = [];
            // Filtrando valores para campo clave siefore
            this.onFilterClaveSieforeHandler(
              valuesClaveAfore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      }
    } else if (type === 1) {
      // Clave Siefore
      // Cuando el campo clave_siefore es seleccionado de primero
      if (!inputPivot || inputPivot === "secondPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
        if (eraser) {
          inputPivot = false;
          inputSubPivot = false;
          prevInputSelected = false;
        } else {
          // Se configura a null inputPivot si el valor de clave_siefore es vacio
          inputPivot = valuesClaveSiefore !== "" ? "secondPath" : null;
        }
        // Limpiando valores campo clave_afore
        form.clave_afore.value = "";
        selectedFilters.clave_afore = [];
        // Filtrando valores para campo clave_afore
        this.onFilterClaveAforeHandler(valuesClaveSiefore);
        // Limpiando valores campo tipo siefore
        form.tipo_siefore.value = "";
        selectedFilters.tipo_siefore = [];
        // Filtrando valores para campo tipo siefore
        this.onFilterTipoSieforeHandler(null, valuesClaveSiefore);
        // Limpiando valores campo clasificacion_siefore
        form.clasificacion_siefore.value = "";
        selectedFilters.clasificacion_siefore = [];
        // Filtrando valores para campo clasificacion_siefore
        this.onFilterClasificacionSieforeHandler(null, valuesClaveSiefore);
      } else if (inputPivot === "firstPath") {
        if (!inputSubPivot || inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clave afore es vacio
            inputSubPivot = valuesClaveSiefore !== "" ? "clave_siefore" : null;
          }
          // Limpiando valores campo tipo siefore
          form.tipo_siefore.value = "";
          selectedFilters.tipo_siefore = [];
          // Filtrando valores para campo tipo siefore
          this.onFilterTipoSieforeHandler(valuesClaveAfore, valuesClaveSiefore);
          // Limpiando valores campo clasificacion_siefore
          form.clasificacion_siefore.value = "";
          selectedFilters.clasificacion_siefore = [];
          // Filtrando valores para campo clasificacion_siefore
          this.onFilterClasificacionSieforeHandler(
            valuesClaveAfore,
            valuesClaveSiefore
          );
        } else if (inputSubPivot === "tipo_siefore") {
          // Cuando el campo tipo_siefore es seleccionado de segundo y el campo clave_siefore de tercero
          // Si se el campo clave_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clave_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clasificacion_siefore"
                ? "clasificacion_siefore"
                : "clave_siefore";
          }
          // Si el campo clave_siefore es el penultimo se filtra el campo clasificacion_siefore
          if (
            !prevInputSelected ||
            prevInputSelected !== "clasificacion_siefore"
          ) {
            // Limpiando valores campo clasificacion_siefore
            form.clasificacion_siefore.value = "";
            selectedFilters.clasificacion_siefore = [];
            // Filtrando valores para campo clasificacion_siefore
            this.onFilterClasificacionSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesTipoSiefore
            );
          }
        } else if (inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo y el campo clave_siefore de tercero
          // Si se el campo clave_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clave_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "tipo_siefore"
                ? "tipo_siefore"
                : "clave_siefore";
          }
          // Si el campo clave_siefore es el penultimo se filtra el campo tipo_siefore
          if (!prevInputSelected || prevInputSelected !== "tipo_siefore") {
            // Limpiando valores campo tipo siefore
            form.tipo_siefore.value = "";
            selectedFilters.tipo_siefore = [];
            // Filtrando valores para campo tipo siefore
            this.onFilterTipoSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "thirdPath") {
        if (!inputSubPivot || inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clave afore es vacio
            inputSubPivot = valuesClaveSiefore !== "" ? "clave_siefore" : null;
          }
          // Limpiando valores campo clave_afore
          form.clave_afore.value = "";
          selectedFilters.clave_afore = [];
          // Filtrando valores para campo clave_afore
          this.onFilterClaveAforeHandler(valuesClaveSiefore, valuesTipoSiefore);
          // Limpiando valores campo clasificacion_siefore
          form.clasificacion_siefore.value = "";
          selectedFilters.clasificacion_siefore = [];
          // Filtrando valores para campo clasificacion_siefore
          this.onFilterClasificacionSieforeHandler(
            null,
            valuesClaveSiefore,
            valuesTipoSiefore
          );
        } else if (inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo y el campo clave_siefore de tercero
          // Si se el campo clave_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clave_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clasificacion_siefore"
                ? "clasificacion_siefore"
                : "clave_siefore";
          }
          // Si el campo clave_siefore es el penultimo se filtr el campo clasificacion_siefore
          if (
            !prevInputSelected ||
            prevInputSelected !== "clasificacion_siefore"
          ) {
            // Limpiando valores campo clasificacion_siefore
            form.clasificacion_siefore.value = "";
            selectedFilters.clasificacion_siefore = [];
            // Filtrando valores para campo clasificacion_siefore
            this.onFilterClasificacionSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesTipoSiefore
            );
          }
        } else if (inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo y el campo clave_siefore de tercero
          // Si se el campo clave_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clave_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_afore"
                ? "clave_afore"
                : "clave_siefore";
          }
          // Si el campo clave_siefore es el penultimo se filtra el campo clave_afore
          if (!prevInputSelected || prevInputSelected !== "clave_afore") {
            // Limpiando valores campo clave_afore
            form.clave_afore.value = "";
            selectedFilters.clave_afore = [];
            // Filtrando valores para campo clave_afore
            this.onFilterClaveAforeHandler(
              valuesClaveSiefore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "fourthPath") {
        if (!inputSubPivot || inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_afore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clave afore es vacio
            inputSubPivot = valuesClaveSiefore !== "" ? "clave_siefore" : null;
          }
          // Limpiando valores campo clave_afore
          form.clave_afore.value = "";
          selectedFilters.clave_afore = [];
          // Filtrando valores para campo clave_afore
          this.onFilterClaveAforeHandler(
            valuesClaveSiefore,
            null,
            valuesClasificacionSiefore
          );
          // Limpiando valores campo tipo_siefore
          form.tipo_siefore.value = "";
          selectedFilters.tipo_siefore = [];
          // Filtrando valores para campo tipo_siefore
          this.onFilterTipoSieforeHandler(
            null,
            valuesClaveSiefore,
            valuesClasificacionSiefore
          );
        } else if (inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo y el campo clave_siefore de tercero
          // Si se el campo clave_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clave_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "tipo_siefore"
                ? "tipo_siefore"
                : "clave_siefore";
          }
          // Si el campo clave_siefore es el penultimo se filtra el campo tipo_siefore
          if (!prevInputSelected || prevInputSelected !== "tipo_siefore") {
            // Limpiando valores campo tipo siefore
            form.tipo_siefore.value = "";
            selectedFilters.tipo_siefore = [];
            // Filtrand valores para campo tipo siefore
            this.onFilterTipoSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesClasificacionSiefore
            );
          }
        } else if (inputSubPivot === "tipo_siefore") {
          // Cuando el campo tipo_siefore es seleccionado de segundo y el campo clave_afore de tercero
          // Si se el campo clave_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clave_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clave_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_afore"
                ? "clave_afore"
                : "clave_siefore";
          }
          // Si el campo clave_siefore es el penultimo se filtra el campo clave_afore
          if (!prevInputSelected || prevInputSelected !== "clave_afore") {
            // Limpiando valores campo clave_afore
            form.clave_afore.value = "";
            selectedFilters.clave_afore = [];
            // Filtrando valores para campo clave_afore
            this.onFilterClaveAforeHandler(
              valuesClaveSiefore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      }
    } else if (type === 2) {
      // tipo de siefore
      // Cuando el campo tipo_siefore es seleccionado de primero
      if (!inputPivot || inputPivot === "thirdPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
        if (eraser) {
          inputPivot = false;
          inputSubPivot = false;
          prevInputSelected = false;
        } else {
          // Se configura a null inputPivot si el valor de tipo_siefore es vacio
          inputPivot = valuesTipoSiefore !== "" ? "thirdPath" : null;
        }
        // Limpiando valores campo clave_afore
        form.clave_afore.value = "";
        selectedFilters.clave_afore = [];
        // Filtrando valores para campo clave_afore
        this.onFilterClaveAforeHandler(null, valuesTipoSiefore);
        // Limpiando valores campo clave_siefore
        form.clave_siefore.value = "";
        selectedFilters.clave_siefore = [];
        // Filtrando valores para campo clave_siefore
        this.onFilterClaveSieforeHandler(null, valuesTipoSiefore);
        // Limpiando valores campo clasificacion_siefore
        form.clasificacion_siefore.value = "";
        selectedFilters.clasificacion_siefore = [];
        // Filtrando valores para campo clasificacion_siefore
        this.onFilterClasificacionSieforeHandler(
          null,
          null,
          valuesTipoSiefore
        );
      } else if (inputPivot === "firstPath") {
        if (!inputSubPivot || inputSubPivot === "tipo_siefore") {
          // Cuando el campo tipo_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de tipo_siefore es vacio
            inputSubPivot = valuesTipoSiefore !== "" ? "tipo_siefore" : null;
          }
          // Limpiando valores campo clave_siefore
          form.clave_siefore.value = "";
          selectedFilters.clave_siefore = [];
          // Filtrando valores para campo clave_siefore
          this.onFilterClaveSieforeHandler(valuesClaveAfore, valuesTipoSiefore);
          // Limpiando valores campo clasificacion_siefore
          form.clasificacion_siefore.value = "";
          selectedFilters.clasificacion_siefore = [];
          // Filtrando valores para campo clasificacion_siefore
          this.onFilterClasificacionSieforeHandler(
            valuesClaveAfore,
            null,
            valuesTipoSiefore
          );
        } else if (inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo y el campo tipo_siefore de tercero
          // Si se el campo tipo_siefore se setea a vacio.
          if (eraser && prevInputSelected === "tipo_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo tipo_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clasificacion_siefore"
                ? "clasificacion_siefore"
                : "tipo_siefore";
          }
          // Si el campo tipo_siefore es el penultimo se filtra el campo clasificacion_siefore
          if (
            !prevInputSelected ||
            prevInputSelected !== "clasificacion_siefore"
          ) {
            // Limpiando valores campo clasificacion_siefore
            form.clasificacion_siefore.value = "";
            selectedFilters.clasificacion_siefore = [];
            // Filtrando valores para campo clasificacion_siefore
            this.onFilterClasificacionSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesTipoSiefore
            );
          }
        } else if (inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo y el campo tipo_siefore de tercero
          // Si se el campo tipo_siefore se setea a vacio.
          if (eraser && prevInputSelected === "tipo_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo tipo_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_siefore"
                ? "clave_siefore"
                : "tipo_siefore";
          }
          // Si el campo tipo_siefore es el penultimo se filtra el campo clave_siefore
          if (!prevInputSelected || prevInputSelected !== "clave_siefore") {
            // Limpiando valores campo clave_siefore
            form.clave_siefore.value = "";
            selectedFilters.clave_siefore = [];
            // Filtrando valores para campo clave_siefore
            this.onFilterClaveSieforeHandler(
              valuesClaveAfore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "secondPath") {
        if (!inputSubPivot || inputSubPivot === "tipo_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de tipo_siefore es vacio
            inputSubPivot = valuesTipoSiefore !== "" ? "tipo_siefore" : null;
          }
          // Limpiando valores campo clave_afore
          form.clave_afore.value = "";
          selectedFilters.clave_afore = [];
          // Filtrando valores para campo clave_siefore
          this.onFilterClaveAforeHandler(valuesClaveSiefore, valuesTipoSiefore);
          // Limpiando valores campo clasificacion_siefore
          form.clasificacion_siefore.value = "";
          selectedFilters.clasificacion_siefore = [];
          // Filtrando valores para campo clasificacion_siefore
          this.onFilterClasificacionSieforeHandler(
            null,
            valuesClaveSiefore,
            valuesTipoSiefore
          );
        } else if (inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo y el campo tipo_siefore de tercero
          // Si se el campo tipo_siefore se setea a vacio.
          if (eraser && prevInputSelected === "tipo_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo tipo_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clasificacion_siefore"
                ? "clasificacion_siefore"
                : "tipo_siefore";
          }
          // Si el campo tipo_siefore es el penultimo se filtra el campo cclasificacion_siefore
          if (
            !prevInputSelected ||
            prevInputSelected !== "clasificacion_siefore"
          ) {
            // Limpiando valores campo clasificacion_siefore
            form.clasificacion_siefore.value = "";
            selectedFilters.clasificacion_siefore = [];
            // Filtrando valores para campo clasificacion_siefore
            this.onFilterClasificacionSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesTipoSiefore
            );
          }
        } else if (inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo y el campo tipo_siefore de tercero
          // Si se el campo tipo_siefore se setea a vacio.
          if (eraser && prevInputSelected === "tipo_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo tipo_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_afore"
                ? "clave_afore"
                : "tipo_siefore";
          }
          // Si el campo tipo_siefore es el penultimo se filtra el campo clave_afore
          if (!prevInputSelected || prevInputSelected !== "clave_afore") {
            // Limpiando valores campo clave_afore
            form.clave_afore.value = "";
            selectedFilters.clave_afore = [];
            // Filtrando valores para campo clave_siefore
            this.onFilterClaveAforeHandler(
              valuesClaveSiefore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "fourthPath") {
        if (!inputSubPivot || inputSubPivot === "tipo_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de tipo_siefore es vacio
            inputSubPivot = valuesTipoSiefore !== "" ? "tipo_siefore" : null;
          }
          // Limpiando valores campo clave_afore
          form.clave_afore.value = "";
          selectedFilters.clave_afore = [];
          // Filtrando valores para campo clave_afore
          this.onFilterClaveAforeHandler(
            null,
            valuesTipoSiefore,
            valuesClasificacionSiefore
          );
          // Limpiando valores campo clave_siefore
          form.clave_siefore.value = "";
          selectedFilters.clave_siefore = [];
          // Filtrando valores para campo clave_siefore
          this.onFilterClaveSieforeHandler(
            null,
            valuesTipoSiefore,
            valuesClasificacionSiefore
          );
        } else if (inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo y el campo tipo_siefore de tercero
          // Si se el campo tipo_siefore se setea a vacio.
          if (eraser && prevInputSelected === "tipo_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo tipo_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_siefore"
                ? "clave_siefore"
                : "tipo_siefore";
          }
          // Si el campo tipo_siefore es el penultimo se filtra el campo clave_siefore
          if (!prevInputSelected || prevInputSelected !== "clave_siefore") {
            // Limpiando valores campo clave_siefore
            form.clave_siefore.value = "";
            selectedFilters.clave_siefore = [];
            // Filtrando valores para campo clave_siefore
            this.onFilterClaveSieforeHandler(
              valuesClaveAfore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        } else if (inputSubPivot === "clave_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo y el campo tipo_siefore de tercero
          // Si se el campo tipo_siefore se setea a vacio.
          if (eraser && prevInputSelected === "tipo_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo tipo_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_afore"
                ? "clave_afore"
                : "tipo_siefore";
          }
          // Si el campo tipo_siefore es el penultimo se filtra el campo clave_afore
          if (!prevInputSelected || prevInputSelected !== "clave_afore") {
            // Limpiando valores campo clave_afore
            form.clave_afore.value = "";
            selectedFilters.clave_afore = [];
            // Filtrando valores para campo clave_afore
            this.onFilterClaveAforeHandler(
              valuesClaveSiefore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      }
    } else if (type === 3) {
      // Clasificacion Siefore
      // Cuando el campo clasificacion_siefore es seleccionado de primero
      // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
      if (!inputPivot || inputPivot === "fourthPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados
        if (eraser) {
          inputPivot = false;
          inputSubPivot = false;
          prevInputSelected = false;
        } else {
          // Se configura a null inputPivot si el valor de clasificacion_siefore es vacio
          inputPivot = valuesClasificacionSiefore !== "" ? "fourthPath" : null;
        }
        // Limpiando valores campo clave_afore
        form.clave_afore.value = "";
        selectedFilters.clave_afore = [];
        // Filtrando valores para campo clave_afore
        this.onFilterClaveAforeHandler(null, null, valuesClasificacionSiefore);
        // Limpiando valores campo clave_siefore
        form.clave_siefore.value = "";
        selectedFilters.clave_siefore = [];
        // Filtrando valores para campo clave_siefore
        this.onFilterClaveSieforeHandler(
          null,
          null,
          valuesClasificacionSiefore
        );
        // Limpiando valores campo tipo_siefore
        form.tipo_siefore.value = "";
        selectedFilters.tipo_siefore = [];
        // Filtrando valores para campo tipo_siefore
        this.onFilterTipoSieforeHandler(null, null, valuesClasificacionSiefore);
      } else if (inputPivot === "firstPath") {
        if (!inputSubPivot || inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clasificacion_siefore es vacio
            inputSubPivot =
              valuesClasificacionSiefore !== ""
                ? "clasificacion_siefore"
                : null;
          }
          // Limpiando valores campo clave_siefore
          form.clave_siefore.value = "";
          selectedFilters.clave_siefore = [];
          // Filtrando valores para campo clave_siefore
          this.onFilterClaveSieforeHandler(
            valuesClaveAfore,
            null,
            valuesClasificacionSiefore
          );
          // Limpiando valores campo tipo_siefore
          form.tipo_siefore.value = "";
          selectedFilters.tipo_siefore = [];
          // Filtrando valores para campo tipo_siefore
          this.onFilterTipoSieforeHandler(
            valuesClaveAfore,
            null,
            valuesClasificacionSiefore
          );
        } else if (inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_afore es seleccionado de segundo y el campo clasificacion_siefore de tercero
          // Si se el campo clasificacion_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clasificacion_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clasificacion_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "tipo_siefore"
                ? "tipo_siefore"
                : "clasificacion_siefore";
          }
          // Si el campo clasificacion_siefore es el penultimo se filtra el campo tipo_siefore
          if (!prevInputSelected || prevInputSelected !== "tipo_siefore") {
            // Limpiando valores campo tipo_siefore
            form.tipo_siefore.value = "";
            selectedFilters.tipo_siefore = [];
            // Filtrando valores para campo tipo_siefore
            this.onFilterTipoSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesClasificacionSiefore
            );
          }
        } else if (inputSubPivot === "tipo_siefore") {
          // Cuando el campo tipo_siefore es seleccionado de segundo y el campo clasificacion_siefore de tercero
          // Si se el campo clasificacion_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clasificacion_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clasificacion_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_siefore"
                ? "clave_siefore"
                : "clasificacion_siefore";
          }
          // Si el campo clasificacion_siefore es el penultimo se filtra el campo clave_siefore
          if (!prevInputSelected || prevInputSelected !== "clave_siefore") {
            // Limpiando valores campo clave_siefore
            form.clave_siefore.value = "";
            selectedFilters.clave_siefore = [];
            // Filtrando valores para campo clave_siefore
            this.onFilterClaveSieforeHandler(
              valuesClaveAfore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "secondPath") {
        if (!inputSubPivot || inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clasificacion_siefore es vacio
            inputSubPivot =
              valuesClasificacionSiefore !== ""
                ? "clasificacion_siefore"
                : null;
          }
          // Limpiando valores campo clave_afore
          form.clave_afore.value = "";
          selectedFilters.clave_afore = [];
          // Filtrando valores para campo clave_afore
          this.onFilterClaveAforeHandler(
            valuesClaveSiefore,
            null,
            valuesClasificacionSiefore
          );
          // Limpiando valores campo tipo_siefore
          form.tipo_siefore.value = "";
          selectedFilters.tipo_siefore = [];
          // Filtrando valores para campo tipo_siefore
          this.onFilterTipoSieforeHandler(
            null,
            valuesClaveSiefore,
            valuesClasificacionSiefore
          );
        } else if (inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo y el campo clasificacion_siefore de tercero
          // Si se el campo clasificacion_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clasificacion_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clasificacion_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "tipo_siefore"
                ? "tipo_siefore"
                : "clasificacion_siefore";
          }
          // Si el campo clasificacion_siefore es el penultimo se filtra el campo tipo_siefore
          if (!prevInputSelected || prevInputSelected !== "tipo_siefore") {
            // Limpiando valores campo tipo_siefore
            form.tipo_siefore.value = "";
            selectedFilters.tipo_siefore = [];
            // Filtrando valores para campo tipo_siefore
            this.onFilterTipoSieforeHandler(
              valuesClaveAfore,
              valuesClaveSiefore,
              valuesClasificacionSiefore
            );
          }
        } else if (inputSubPivot === "tipo_siefore") {
          // Cuando el campo tipo_siefore es seleccionado de segundo y el campo clasificacion_siefore de tercero
          // Si se el campo clasificacion_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clasificacion_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clasificacion_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_afore"
                ? "clave_afore"
                : "clasificacion_siefore";
          }
          // Si el campo clasificacion_siefore es el penultimo se filtra el campo clave_afore
          if (!prevInputSelected || prevInputSelected !== "clave_afore") {
            // Limpiando valores campo clave_afore
            form.clave_afore.value = "";
            selectedFilters.clave_afore = [];
            // Filtrando valores para campo clave_afore
            this.onFilterClaveAforeHandler(
              valuesClaveSiefore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      } else if (inputPivot === "thirdPath") {
        if (!inputSubPivot || inputSubPivot === "clasificacion_siefore") {
          // Cuando el campo clasificacion_siefore es seleccionado de segundo
          // Cuando se elimina el filtro desde la barrra de filtros seleccionados
          if (eraser) {
            inputSubPivot = false;
            prevInputSelected = false;
          } else {
            // Se configura a null inputSubPivot si el valor de clasificacion_siefore es vacio
            inputSubPivot =
              valuesClasificacionSiefore !== ""
                ? "clasificacion_siefore"
                : null;
          }
          // Limpiando valores campo clave_afore
          form.clave_afore.value = "";
          selectedFilters.clave_afore = [];
          // Filtrando valores para campo clave_afore
          this.onFilterClaveAforeHandler(
            null,
            valuesTipoSiefore,
            valuesClasificacionSiefore
          );
          // Limpiando valores campo clave_siefore
          form.clave_siefore.value = "";
          selectedFilters.clave_siefore = [];
          // Filtrando valores para campo clave_siefore
          this.onFilterClaveSieforeHandler(
            null,
            valuesTipoSiefore,
            valuesClasificacionSiefore
          );
        } else if (inputSubPivot === "clave_siefore") {
          // Cuando el campo clave_siefore es seleccionado de segundo y el campo clasificacion_siefore de tercero
          // Si se el campo clasificacion_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clasificacion_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clasificacion_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_afore"
                ? "clave_afore"
                : "clasificacion_siefore";
          }
          // Si el campo clasificacion_siefore es el penultimo se filtra el campo clave_afore
          if (!prevInputSelected || prevInputSelected !== "clave_afore") {
            // Limpiando valores campo clave_afore
            form.clave_afore.value = "";
            selectedFilters.clave_afore = [];
            // Filtrando valores para campo clave_afore
            this.onFilterClaveAforeHandler(
              valuesClaveSiefore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        } else if (inputSubPivot === "clave_afore") {
          // Cuando el campo clave_afore es seleccionado de segundo y el campo clasificacion_siefore de tercero
          // Si se el campo clasificacion_siefore se setea a vacio.
          if (eraser && prevInputSelected === "clasificacion_siefore") {
            prevInputSelected = false;
          } else {
            // Se verifica si el campo clasificacion_siefore es el penultimo seleccionado.
            prevInputSelected =
              prevInputSelected === "clave_siefore"
                ? "clave_siefore"
                : "clasificacion_siefore";
          }
          // Si el campo clasificacion_siefore es el penultimo se filtra el campo clave_siefore
          if (!prevInputSelected || prevInputSelected !== "clave_siefore") {
            // Limpiando valores campo clave_siefore
            form.clave_siefore.value = "";
            selectedFilters.clave_siefore = [];
            // Filtrando valores para campo clave_siefore
            this.onFilterClaveSieforeHandler(
              valuesClaveAfore,
              valuesTipoSiefore,
              valuesClasificacionSiefore
            );
          }
        }
      }
    } else if (type === 4) {
      // Fecha inicio
      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          actualValue,
          form.fecha_fin.value
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 5) {
      //Fecha fin
      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          form.fecha_inicio.value,
          actualValue
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      inputPivot: inputPivot,
      inputSubPivot: inputSubPivot,
      prevInputSelected: prevInputSelected
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    const selectedFilters = this.state.selectedFilters;
    const filterList = [
      ...selectedFilters.clave_afore,
      ...selectedFilters.clave_siefore,
      ...selectedFilters.tipo_siefore,
      ...selectedFilters.clasificacion_siefore,
      ...selectedFilters.fechas
    ];
    let filters = null;
    filters = (
      <div
        className={classes.filter_container_outside_model_one}
        id="allFitersContainers"
      >
        {filterList.map((item, i) => (
          <div
            className={classes.filter_container_model_one}
            key={`${item.label}-${item.id}-${i}`}
          >
            <span className={["montse", classes.filter_text_model_one].join(" ")}>
              {item.type === 4 || item.type === 5 ? item.value : item.label}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i
                className="far fa-times-circle"
                onClick={() =>
                  this.onInputSelectedHandler(null, null, item, true)
                }
              />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  render() {
    return this.printFrameHandler();
  }
}

const mapStateToProps = state => {
  return {
    clave_afore: state.afores.clavesAfore,
    clave_siefore: state.afores.clavesSiefore,
    tipo_siefore: state.afores.tiposSiefore,
    clasificacion_siefore: state.afores.clasificacionesSiefore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterClaveAfore: params => {
      dispatch(actions.initClavesAfore(params));
    },
    onFilterClaveSiefore: params => {
      dispatch(actions.initClavesSiefore(params));
    },
    onFilterTipoSiefore: params => {
      dispatch(actions.initTiposSiefore(params));
    },
    onFilterClasificacionSiefore: params => {
      dispatch(actions.initClasificacionesSiefore(params));
    },
    onFilterAfores: (size, page, claveEmisora, clave, tipo, clasificacion, fechaInicio, fechaFin) => {
      dispatch(
        actions.initAforesConcentradoPreciosGeneral(
          size,
          page,
          claveEmisora,
          clave,
          tipo,
          clasificacion,
          fechaInicio,
          fechaFin
        )
      );
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterConcentradoPreciosAfores)
);

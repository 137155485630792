import React, { Component } from "react";
import classes from "./Cuotas.css";
import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";
import lista_json from "../../../json/cuotas.json";

import { connect } from "react-redux";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
class Cuotas extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    accordion: [
      {
        urlDocumento:
          "https://biva.mx/documents/30877/786705/BIVA+Solicitud+de+Terminal+de+Operaciones.pdf/e68a59da-b498-1856-9eca-526569d5af44",
        nombreArchivo: "Perfiles y Tarifas BIM",
        contacto: "operaciones@biva.mx"
      }
    ]
  };
  componentDidMount() {
    this.props.onInitIntl();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let item2 = null;
    if (this.props.cuotasItch) {
    item2 = (
        <Accordion
          datos={this.props.cuotasItch}
          nombre={"cuotas"}
          icon={""}
          imagen_icono={"itch_cuotas.svg"}
          tipo={4}
          subtipo={1}
          titulo_encabezado_estatico={"ITCH"}
          titulo_encabezado_2_estatico={this.state.messages ? this.state.messages["informacionmercado.cuotas.itch.subtitulo"] : ""}
        />
      )
  }

    let  bottom = null
    if (this.props.tarifasBim) {
      bottom =  (
        <Accordion
          datos={this.props.tarifasBim}
          nombre={"bim"}
          icon={""}
          imagen_icono={"bim_cuotas.svg"}
          tipo={6}
          subtipo={1}
          email={this.state.messages ? this.state.messages["informacionmercado.cuotas.direccioncontacto"] : ""}
          titulo_info={this.state.messages ? this.state.messages["informacionmercado.cuotas.masinfo"] : ""}
          titulo_encabezado={"BIM"}
        />
      );
    }
   
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1 className={[classes.main_title, "texto_42", "nunito"].join(" ")}>
                  <FormattedHTMLMessage id="informacionmercado.cuotas.titulo" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>

          <div className="row margen_top_30">
            <p className={"texto_16 montse regular"}>
              <FormattedHTMLMessage id="informacionmercado.cuotas.intro" defaultMessage=" " />
            </p>
          </div>
          <div className="margen_50">
            {item2}
            <br />
            {bottom}
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    cuotasItch: state.accordion.cuotasItch,
    tarifasBim: state.accordion.tarifasBim
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(46));
      dispatch(action.getAccordionTarifasBim());
      dispatch(action.getAccordionCuotasITCH());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cuotas);

import React, { Component } from "react";
import classes from "./Tabs.css";

class Tabs extends Component {
  state = {
    tabActive: [],
    tabsDesabilitados: [],
    indexCurrentTab: 0,
    activaTabInicial: 0,
  };

  componentDidMount() {
    let items = [...Array(this.props.data.length)].map((_, i) =>
      i === 0 ? true : false
    );
    let tabsDesabilitados = [];
    let activaTabInicial = 0;
    if (this.props.tabsDesabilitados) {
      tabsDesabilitados = this.props.tabsDesabilitados;
    }
    items = [...Array(this.props.data.length)].map((_, i) =>
      i === 0 ? true : false
    );
    if (this.props.activaTabInicial) {
      if(this.props.subtipo === 1){
        items = [...Array(this.props.data.length)].map((_, i) =>
        i=== this.props.activaTabInicial.find((itm) => itm === i)
        );
      }else{
        items = [...Array(this.props.data.length)].map((_, i) =>
          i === +this.props.activaTabInicial ? true : false
        );
      }
    }
    if (
      this.props.activaTabInicial !== undefined &&
      this.props.activaTabInicial
    ) {
      activaTabInicial = parseInt(this.props.activaTabInicial);
    }
    this.setState({
      tabActive: items,
      tabsDesabilitados: tabsDesabilitados,
      activaTabInicial: activaTabInicial,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let tabsDesabilitados = [];
    if (this.props.tabsDesabilitados !== this.state.tabsDesabilitados) {
      if (
        this.props.tabsDesabilitados !== null &&
        this.props.tabsDesabilitados !== undefined &&
        this.props.tabsDesabilitados.length > 0
      ) {
        tabsDesabilitados = this.props.tabsDesabilitados;
        this.setState({ tabsDesabilitados: tabsDesabilitados });
      }
    }

    let updateActiveState = [];
    if (prevProps.wasFilteredBySearch !== this.props.wasFilteredBySearch) {
      const tabsActiveState = [...this.state.tabActive];
      updateActiveState = tabsActiveState.map((_, i) =>
        i === 0 ? true : false
      );
      this.setState({ tabActive: updateActiveState });
    }

    if (
      this.props.activaTabInicial !== null &&
      this.props.activaTabInicial !== undefined &&
      parseInt(this.props.activaTabInicial) !==
        parseInt(this.state.activaTabInicial)
        && this.props.subtipo !== 1
    ) {
      let items = [...Array(this.props.data.length)].map((_, i) =>
        i === parseInt(this.props.activaTabInicial) ? true : false
      );
      this.setState({
        tabActive: items,
        activaTabInicial: this.props.activaTabInicial,
      });
    }
    
  }

  onToggleActivetab = (index) => {
    const tabsActiveState = [...this.state.tabActive];
    if (this.props.subtipo===1){
      let tabActiveExists=null;
      tabsActiveState[index]=!tabsActiveState[index];
      tabActiveExists = tabsActiveState.find((itm) => itm === true);
      if(tabActiveExists){
        this.setState({ tabActive: tabsActiveState });
      }
    }else{

        const updateActiveState = tabsActiveState.map((_, i) =>
        index === i ? true : false
        );
        this.setState({ tabActive: updateActiveState });
      }
  };

  getColumnsSize = (numberOfTabs) => {
    let colsize = null;

    if (numberOfTabs <= 12) {
      if (numberOfTabs === 1) {
        colsize = 12;
      } else if (numberOfTabs === 2) {
        colsize = 6;
      } else if (numberOfTabs === 3) {
        colsize = 4;
      } else if (numberOfTabs === 4) {
        colsize = 3;
      } else if (numberOfTabs === 5) {
        colsize = 2;
      } else {
        colsize = 2;
      }
    }
    return colsize;
  };

  onPrintTabsHandler = (data) => {
    const colsize = this.getColumnsSize(data.length);
    let tabs = null;
    if (colsize) {
      tabs = (
        <div className="row">
          {data.map((item, i) => {
            let esDesabilitado = false;
            if (
              this.state.tabsDesabilitados !== null &&
              this.state.tabsDesabilitados.length > 0
            ) {
              const tabEncontrado = this.state.tabsDesabilitados.find(
                (item) => item === i
              );
              if (tabEncontrado) {
                esDesabilitado = true;
              }
            }

            let img = <i className={item.icon} />;
            if (item.image && item.image !== "") {
              // let img = require(`../../../assets/images/${item.imagen}`); Usar en caso de que la imagen sea guardada en el directorio assets
              img = <img src={item.image} alt="" />;
            }

            if (this.props.tipo === 1) {
              if (esDesabilitado) {
                return (
                  <div
                    key={"tab" + i}
                    className={[
                      "col sin_padding",
                      `col-lg-${colsize}`,
                      classes.tab2,
                      classes.myCol,
                      classes.unable,
                    ].join(" ")}
                  >
                    <div className={["row", classes.vertical_div_1].join(" ")}>
                      <div
                        className={[
                          "col text-center sin_padding",
                          classes.vertical,
                        ].join(" ")}
                      >
                        <div className={classes.titleContainer}>
                          <span
                            className={[
                              "nunito",
                              "texto_16",
                              "semi_bold texto_gris",
                            ].join(" ")}
                          >
                            {item.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={"tab" + i}
                    className={[
                      "col sin_padding",
                      `col-lg-${colsize}`,
                      classes.tab2,
                      classes.myCol,
                      this.state.tabActive && this.state.tabActive[i]
                        ? classes.active
                        : null,
                    ].join(" ")}
                    onClick={() => {
                      this.onToggleActivetab(i);
                      this.props.clicked(i);
                    }}
                  >
                    <div className={["row", classes.vertical_div_1].join(" ")}>
                      <div
                        className={[
                          "col text-center sin_padding",
                          classes.vertical,
                        ].join(" ")}
                      >
                        <div className={classes.titleContainer}>
                          <span
                            className={[
                              "nunito",
                              "texto_16",
                              "semi_bold texto_gris",
                            ].join(" ")}
                          >
                            {item.title}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            } else if (this.props.tipo === 2) {
              return (
                <div
                  key={"tab" + i}
                  className={[
                    "col sin_padding",
                    `col-lg-${colsize}`,
                    classes.tab,
                    classes.myCol,
                    this.state.tabActive[i] ? classes.active : null,
                  ].join(" ")}
                  onClick={() => {
                    this.onToggleActivetab(i);
                    this.props.clicked(i);
                  }}
                >
                  <div className="row">
                    <div className="col text-center sin_padding">
                      <div className={classes.imgContainer}>{img}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center sin_padding">
                      <div className={classes.titleContainer}>
                        <span
                          className={[
                            "nunito",
                            "texto_16",
                            "semi_bold texto_gris",
                          ].join(" ")}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (this.props.tipo === 5) {
              // TABS BIVA ASG
              let style = {
                color: item.color
              };
              return (
                <div
                  key={"tab" + i}
                  className={[
                    "col sin_padding",
                    `col-lg-${colsize}`,
                    classes.tab,
                    classes.myCol,
                    this.state.tabActive[i] ? classes.active : null,
                  ].join(" ")}
                  onClick={() => {
                    this.onToggleActivetab(i);
                    this.props.clicked(i);
                  }}
                >
                  <div className="row">
                    <div className="col text-center sin_padding">
                      <div className={classes.imgContainerAsg}>{img}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center sin_padding">
                      <div className={classes.titleContainer}>
                        <span
                        style={style}
                          className={[
                            "nunito",
                            "texto_16",
                            "bold",
                          ].join(" ")}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (this.props.tipo === 0) {
              const color = this.props.color === "verde" ? classes.sustentable : null ;
              return (
                <div
                  key={"tab" + i}
                  className={[
                    "col sin_padding",
                    `col-lg-${colsize}`,
                    classes.tab2,
                    classes.myCol,
                    this.state.tabActive && this.state.tabActive[i]
                      ? classes.active
                      : null, 
                    color
                  ].join(" ")}
                  onClick={() => {
                    this.onToggleActivetab(i);
                    this.props.clicked(i, item.fieldId);
                  }}
                >
                  <div className={["row", classes.vertical_div_1].join(" ")}>
                    <div
                      className={[
                        "col text-center sin_padding",
                        classes.vertical,
                      ].join(" ")}
                    >
                      <div className={classes.titleContainer}>
                        <span
                          className={[
                            "nunito",
                            "texto_16",
                            "semi_bold texto_gris",
                          ].join(" ")}
                        >
                          {item.text}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (this.props.tipo === 4) {
              return (
                <div
                  className={[classes.circle_container, "noselect"].join(" ")}
                  onClick={() => {
                    this.onToggleActivetab(i);
                    this.props.clicked(i);
                  }}
                >
                  <div
                    className={[
                      classes.circle_main,
                      this.state.tabActive && this.state.tabActive[i]
                        ? classes.active_circle_main
                        : null,
                    ].join(" ")}
                  >
                    <div className={classes.circle_text_container}>
                      <div className={classes.circle_text}>
                        <span
                          className={[
                            "texto_centro texto_22 montse semi_bold texto_blanco",
                            classes.text_circle,
                          ].join(" ")}
                        >
                          {item.titulo_encabezado}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              let img = (
                <i
                  className={["far", "fa-file-image", classes.iconClass].join(
                    " "
                  )}
                />
              );
              if (this.props.data[i].image && this.props.data[i].image !== "") {
                let imgPath = require(`../../../assets/icons/${this.props.data[i].image}`);
                img = <img src={imgPath} alt="" className={classes.imgClass} />;
              }
              if (this.props.data[i].iconFont && this.props.data[i].iconFont !== "") {
                 img = (
                  <i
                    className={[this.props.data[i].iconFont, classes.iconFont].join(
                      " "
                    )}
                  />
                );
              }

              return (
                <div
                  key={"tab" + i}
                  className={[
                    "col sin_padding",
                    `col-lg-${colsize}`,
                    classes.tab3,
                    classes.myCol,
                    this.state.tabActive[i] ? classes.active2 : null,
                  ].join(" ")}
                  onClick={() => {
                    this.onToggleActivetab(i);
                    this.props.clicked(i);
                  }}
                >
                  <div className={["row", classes.vertical_div].join(" ")}>
                    <div
                      className={[
                        "col text-center sin_padding",
                        classes.vertical,
                      ].join(" ")}
                    >
                      {img}
                      <div className={classes.titleContainer}>
                        <span
                          className={[
                            "nunito",
                            "texto_16",
                            "semi_bold texto_gris",
                          ].join(" ")}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    }
    return tabs;
  };

  render() {
    let claseContainer = this.props.tipo === 4 ? null : classes.tabsContainer;
    const tabs = this.props.data
      ? this.onPrintTabsHandler(this.props.data)
      : null;
    return <div className={[claseContainer, this.props.isSustentable ? classes.sustentableContainer : null].join(" ")}>{tabs}</div>;
  }
}

export default Tabs;

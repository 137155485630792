import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Boton.css";

// colorBoton= azul por defecto, "blanco"
//<Boton ruta={"/"} tipo={1} texto={"Conoce nuestras cuotas"} icono_derecho="fas fa-long-arrow-alt-right" icono_izquierdo="fas fa-long-arrow-alt-left" colorBoton={"blanco"}/> */
// tipo 1 es un link normalize
// tipo 2 es un navlink
class Box extends Component {
  render() {
    let tipo = 1;
    let color_boton = [];
    let texto = "Agrega texto del boton";
    let ruta = "/";
    let icono_derecho = "fas fa-long-arrow-alt-right";
    let icono_izquierdo = "";
    let idModal = "#";
    let dataDismiss = null;

    if (this.props.idModal !== undefined && this.props.idModal !== null) {
      idModal = this.props.idModal;
    }
    if (this.props.dataDismiss !== undefined && this.props.dataDismiss !== null) {
      dataDismiss = this.props.dataDismiss;
    }
    if (this.props.texto !== undefined && this.props.texto !== "") {
      texto = this.props.texto;
    }
    if (this.props.texto === undefined || this.props.texto === "") {
      texto = "Regresar";
    }
    if (this.props.ruta !== undefined && this.props.ruta !== "") {
      ruta = this.props.ruta;
    }
    if (this.props.icono_izquierdo !== undefined) {
      icono_izquierdo = this.props.icono_izquierdo;
    }

    if (this.props.icono_derecho !== undefined) {
      icono_derecho = this.props.icono_derecho;
    }
    if (this.props.colorBoton === "blanco") {
      color_boton.push(classes.btn_blanco);
    }
    if (this.props.colorBoton === "verde") {
      color_boton.push(classes.btn_verde);
    }
    if (this.props.colorBoton === "blanco_verde") {
      color_boton.push(classes.btn_blanco_verde);
    }
    if (this.props.tipo !== undefined) {
      tipo = this.props.tipo;
    }

    let tipoBoton = "";
    if ((this.props.icono_derecho === undefined || this.props.icono_derecho === "") && tipo === 3) {
      icono_derecho = "";
      tipoBoton = classes.botonRegresar;
    }

    const boton = (
      <button className={[classes.my_btn, color_boton, tipoBoton, classes.vertical_div].join(" ")}>
        <div className={classes.vertical}>
          <i className={[icono_izquierdo, classes.icono_izquierdo].join(" ")} />
          <span className={"nunito texto_16 semibold"}>{texto}</span>
          <i className={[icono_derecho, classes.icono_derecho].join(" ")} />
        </div>
      </button>
    );

    if (tipo === 1) {
      return (
        <a href={ruta} target="_blank" rel="noopener noreferrer" className={classes.link}>
          {boton}
        </a>
      );
    } else if (tipo === 2) {
      return (
        <NavLink to={ruta} className={[classes.link].join(" ")} onClick={this.props.onClick}>
          {boton}
        </NavLink>
      );
    } else if (tipo === 3) {
      return (
        <NavLink to={ruta} className={[classes.link2].join(" ")} onClick={this.props.onClick}>
          {boton}
        </NavLink>
      );
    } else if (tipo === 4) {
      // este tipo es para cuando no quieres redirigir hacia un lugar
      return (
        <div onClick={this.props.onClick} className={classes.link} data-toggle="modal" data-target={idModal} data-dismiss={dataDismiss}>
          {boton}
        </div>
      );
    } else if (tipo === 5) {
      // Abrir aplicacion de correos
      return (
        <a href={"mailto:" + this.props.correo} className={classes.link}>
          {boton}
        </a>
      );
    } else if (tipo === 6) {
      // Abrir aplicacion de correos
      return (
        <a href={ruta}  rel="noopener noreferrer" className={classes.link}>
          {boton}
        </a>
      );
    } 
  }
}
export default Box;

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  menu: null,
  submenu: null,
  avisoLegal: null,
  privacidad: null,
  enlace:null,
  footer:null
};

const setMenu = (state, action) => {
  const newProperties = {
    menu: action.menu
  };
  return updateObject(state, newProperties);
};

const setMenu2 = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MENU_SUCCESS:
      return setMenu(state, action);
    case actionTypes.FETCH_AVISOFOOTER_SUCCESS:
      return setMenu2(state, action);
    case actionTypes.FETCH_PRIVACIDADFOOTER_SUCCESS:
      return setMenu2(state, action);
    case actionTypes.FETCH_CENCORFOOTER_SUCCESS:
      return setMenu2(state, action);
    case actionTypes.FETCH_FOOTER_SUCCESS:
      return setMenu2(state, action);
    default:
      return state;
  }
};

export default reducer;

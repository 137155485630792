import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../stores/actions/index";
import queryString from "query-string";
import classes from "./AvisosOfertaPublica.css";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";

import FilterAvisosOfertasPublicas from "../../../../components/ui/filterAvisosOfertasPublicas/FilterAvisosOfertasPublicas";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";

import Rss from "../../../rss/Rss";
import TabASG from "../../../sustentable/tabASG/TabASG";
import TituloAsg from "../../../sustentable/ui/tituloAsg/TituloAsg";
const dominio = `${window.location.protocol}//${window.location.host}`;

class AvisosOfertaPublica extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    messages: null,
    lan: getLocal(),
    showRssModal: false,
    urlForRss: `${dominio}/emisoras/avisos-oferta-publica/rss`,
    clave_cotizacion: null,
    instrumento: null,
    serie: null,
    wasFiltered: false,
    asg : false,
    tiposBonos:[],
  };

  componentWillMount() {
    this.props.getIntl();
    this.initData();
  }

  initData(){
    let paramsString = queryString.parse(this.props.location.search);
    let claveCotizacion =
        paramsString["claveCotizacion"] === "null" || paramsString["claveCotizacion"] === "" || paramsString["claveCotizacion"] === undefined
          ? null
          : paramsString["claveCotizacion"].split(",");
    let idTipoInstrumento =
      paramsString["idTipoInstrumento"] === undefined || paramsString["idTipoInstrumento"] === "null" || paramsString["idTipoInstrumento"] === ""
          ? null
          : paramsString["idTipoInstrumento"].split(",");
    let serie = paramsString["serie"] === "null" || paramsString["serie"] === "" || paramsString["serie"] === undefined? null : paramsString["serie"];
    let page = paramsString["page"] === "null" || paramsString["page"] === "" || paramsString["page"] === undefined? 1 : parseInt(paramsString["page"]);
    let tipoBono = paramsString["tipoBono"] === "null" || paramsString["tipoBono"] === undefined ? null : paramsString["tipoBono"].split(",");  
  
      tipoBono = this.onInitTipoBonoASG(tipoBono, this.props.location.pathname);

    const params = {
      size: this.state.itemsPerPage,
      page: page -1 ,
      claveCotizacion: claveCotizacion ? claveCotizacion : null,
      idTipoInstrumento: idTipoInstrumento ? idTipoInstrumento : null,
      serie: serie,
      tipoBono: tipoBono,
    };
    this.props.onInitAvisosOfertasPublicas(params);
    this.setState({
      activePage: page,
      clave_cotizacion: claveCotizacion ? claveCotizacion : null,
      instrumento: idTipoInstrumento ? idTipoInstrumento : null,
      serie: serie ? serie : null
    });
  }
  onInitTipoBonoASG(tipoBono, path){
    let asg = false;
    if( path === "/biva-sostenible/avisos-oferta-publica"){
      asg = true;
      tipoBono = tipoBono === null ? [1,2,3,4]:tipoBono.map(Number) ;
    }
    this.setState({
      tiposBonos: tipoBono,
      asg:asg,
    });

    return tipoBono;
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }
    if (this.props.avisos !== prevProps.avisos) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let avisos = null;

    if (nextProps.avisos && nextProps.avisos.content) {
      avisos = nextProps.avisos.content;
    }
    let resource = avisos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.avisos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList,
        loader: true
      });
      this.props.history.push(
        `?claveCotizacion=${this.state.clave_cotizacion}&idTipoInstrumento=${this.state.instrumento}&serie=${
          this.state.serie
        }&page=${pageNumber}`
      );
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      this.changeHistory(
        this.state.clave_cotizacion ? this.state.clave_cotizacion : null,
        this.state.instrumento ? this.state.instrumento : null,
        this.state.serie ? this.state.serie : null,
        pageNumber,
        this.state.tiposBonos? this.state.tiposBonos : null,

      );

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  onSetClaveCotizacionFilter = clave => {
    this.setState({
      clave_cotizacion: clave,
      loader: true
    });
  };

  onSetInstrumentosFilter = instrumento => {
    this.setState({
      instrumento: instrumento,
      loader: true
    });
  };

  onSetSerieFilter = serie => {
    this.setState({
      serie: serie,
      loader: true
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };
  onSetParams = ({ claveCotizacion, idTipoInstrumento, serie, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claveCotizacion, idTipoInstrumento, serie, page, this.state.tiposBonos);
    }
  };

  onSetTiposBono= (tipos) => {
    this.setState({
      tiposBonos: tipos,
    });
  };

  changeHistory(claveCotizacion = null, idTipoInstrumento = null, serie = null, page = null, tipoBono= null) {
    this.props.history.push(
      `?claveCotizacion=${claveCotizacion}&idTipoInstrumento=${idTipoInstrumento}&serie=${serie}&tipoBono=${tipoBono}&page=${
        page ? page : this.state.activePage
      }`
    );

    this.setState({
      loader: true
    });

    const params = {
      size: this.state.itemsPerPage,
      page: page > 0 ? page - 1 : 0,
      claveCotizacion: claveCotizacion,
      idTipoInstrumento: idTipoInstrumento,
      serie: serie,
      tipoBono:tipoBono
    };
    this.props.onInitAvisosOfertasPublicas(params);

    // this.props.onInitRequisitos(params);
  }

  changeHistoryReset() {
    this.props.history.push(`?claveCotizacion=${null}&idTipoInstrumento=${null}&serie=${null}&tipoBono=${this.state.tiposBonos}&page=${1}`);
    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      claveCotizacion: null,
      idTipoInstrumento: null,
      serie: null,
      tipoBono:this.state.tiposBonos
    };
    this.props.onInitAvisosOfertasPublicas(params);

    // this.props.onInitRequisitos(params);
  }
  render() {
    let avisos = null;
    let contenidoMostrar = null;
    let tabBonoASGMostrar = null;
    if (this.props.avisos && this.props.avisos.content && this.props.avisos.content.length > 0) {
      avisos = this.props.avisos.content;

      const numRows = this.props.avisos ? this.props.avisos.totalElements : 0;
      contenidoMostrar = (
        <React.Fragment>
          <Accordion datos={avisos} nombre="avisos" icon={""} tipo={13} subtipo={3} 
          isSustentable={this.state.asg ? true : false}
          />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
            isSustentable={this.state.asg ? true : false}
          />
        </React.Fragment>
      );
    } else{
        contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda.": "No results found."}</p>;
    }
    if (this.state.asg === true){
      tabBonoASGMostrar = (
        <div className="row margen_top_30">
          <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>
          <div className={classes.vertical_div}>
                        <span className={classes.vertical_content}>
                        {getLan() === "es" ? "Mostrar" : "View"}:
                        </span>
          </div>
          </div>
          <div className={["col sin_padding", classes.myCol].join(" ")}>
            <TabASG
              tiposBonosInicial={this.state.tiposBonosInicial}
              tiposBonos={this.state.tiposBonos}
              setTiposBono={tipos => this.onSetTiposBono(tipos)}
            />
          </div>
        </div>
      );
    }
    
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    const titleAsg = this.state.messages && this.state.messages !== undefined ? this.state.messages["avisosyreportes.listado.avisosdeofertapublica.titulo"] + " ASG" : null;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={["nunito", "texto_42"].join(" ")}>
          {this.state.asg === true ? 
            <TituloAsg titulo={titleAsg} />
          : 
            <FormattedMessage id="avisosyreportes.listado.avisosdeofertapublica.titulo" defaultMessage=" " />
          }
           
          </h1>
          <div className="margen_50 texto_16 montse regular">
            <FormattedMessage id="avisosyreportes.listado.avisosdeofertapublica.introduccion" defaultMessage=" " />
          </div>
          {tabBonoASGMostrar}
          <br />
          <FilterAvisosOfertasPublicas
            setClaveCotizacionFilter={clave => this.onSetClaveCotizacionFilter(clave)}
            setInstrumentosFilter={instrumento => this.onSetInstrumentosFilter(instrumento)}
            setSerieFilter={serie => this.onSetSerieFilter(serie)}
            setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
            setParams={filterParams => this.onSetParams(filterParams)}
            tipoBono={this.state.tiposBonos}
            clave={this.state.clave_cotizacion}
            instrumento={this.state.instrumento}
            isSustentable={this.state.asg ? true : false}
          />
          <button
            className={["btn btn-link margen_50", classes.btn_link].join(" ")}
            type="button"
            onClick={this.onShowRssModalHandler}
          >
            <div className="margen_50 texto_16 montse regular texto_naranja">
              {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
              <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </button>
          <Rss
            showRssModal={this.state.showRssModal}
            modalTitle={getLan() === "es" ? "Avisos de ofertas públicas" : "Public offerings"}
            urlRss={this.state.urlForRss}
            optionType={1}
            rssType={2}
            showClassModel1
          />
          <div className="margen_50 margin_bottom_200">{contenidoMostrar}</div>
          {loader}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIntl: params => {
      dispatch(actions.initIntl(3));
    },
    onInitAvisosOfertasPublicas: params => {
      dispatch(actions.filterAvisosOfertasPublicas(params));
    }
  };
};

const mapStateToProps = state => {
  return {
    avisos: state.listadoValores.avisosOfertasPublicas,
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvisosOfertaPublica);

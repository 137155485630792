import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../../stores/actions/index";
import OperacionBox from "../../../../../components/ui/operacionBox/OperacionBox";
import Box from "../../../../../components/ui/box/Box";
import Tabs from "../../../../../components/ui/tabs/Tabs";
import classes from "./Tabs.css";
import { FormattedHTMLMessage } from "react-intl";
import { getLan } from "../../../../../stores/utils/utilities";

const tabs = [
  {
    image: "",
    icon: "",
    title: "Top 5"
  },
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Todas" : "All"
  }
];

class CasasBolsa extends Component {
  state = {
    top: 5
  };

  componentDidMount() {
    this.props.oninitUltimoDiaMesBiva();
  }

  onChangeItemSelected = (index = 0) => {
    let top = null;
    if (index === 0) {
      //top 5
      top = 5;
    }
    this.setState({
      top: top
    });
  };

  render() {
    const box2 = {
      leftIcon: "far fa-file-excel",
      title: getLan() === "es" ? "Descargar operación último día" : "Download Last day trading",
      subtitle: "Formato XLS",
      rightIcon: "fas fa-arrow-down",
      url: this.props.descargaDocumento
    };
    let tabMostrar = (
      <div className="margen_top_30">
        <div className={["row", classes.tabsContainer].join(" ")}>
          <div className={["texto_14 montse regular", classes.myCol].join(" ")}>{getLan() === "es" ? "Mostrar:" : "View:"}</div>
          <div className={["col", classes.myCol2].join(" ")}>
            <Tabs data={tabs} tipo={1} clicked={i => this.onChangeItemSelected(i)} />
          </div>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        {tabMostrar}
        <div className="row margen_top_30">
          <OperacionBox data={this.props.ultimoDia} tipo={2} top={this.state.top} />
        </div>
        <div className={""}>
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimodia.frecactualizacion" defaultMessage=" " />
          </span>
          <br />
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimodia.consideraciones" defaultMessage=" " />
          </span>
          <br />
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimodia.exclusiones" defaultMessage=" " />
          </span>
        </div>

        <div className="row margin_top_30">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2 className={""}>
                <FormattedHTMLMessage id="informacionmercado.ultimodia.interesado.reporte" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <Box modelo="modelo_1" data={box2} />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    oninitUltimoDiaMesBiva: () => {
      dispatch(action.initUltimoDiaMesCasaBolsa(1));
    }
  };
};

const mapStateToProps = state => {
  return {
    ultimoDia: state.informacionMercado.ultimoDiaCasabolsa
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CasasBolsa);

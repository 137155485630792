import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setEmbajadorBiva = data => {
    return{
        type: actionTypes.FETCH_CUADRICULAS,
        embajador: data
    }
};

export const initCuadriculaEmbajador = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/objetivos-embajador-univ/lang/" + lang)
      .then(response => {
        dispatch(setEmbajadorBiva(response.data));
      })
      .catch(err => console.log(err));
  };
};

// ==============================================================

const setInitBoxGrid = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      grid: null
    };
  }
  return {
    type: actionTypes.FETCH_CUADRICULAS,
    grid:data
  };
};

export const initBoxGrid = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/grid-acerca-de/lang/"+lang)
      .then(response => {
        dispatch(setInitBoxGrid(response.data));
      })
      .catch(err => dispatch(setInitBoxGrid(err.data)));
  };
};

// =======================================================

const setInitBoxHistoria = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        historia: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      historia:data
    };
  };
  
  export const initBoxHistoria = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/acerca-de-historia/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxHistoria(response.data));
        })
        .catch(err => dispatch(setInitBoxHistoria(err.data)));
    };
  };


// =======================================================

const setInitBoxCrecer = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        crecer: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      crecer:data
    };
  };
  
  export const initBoxCrecer = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/acerca-de-crecer/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxCrecer(response.data));
        })
        .catch(err => dispatch(setInitBoxCrecer(err.data)));
    };
  };


// =======================================================

const setInitBoxOperacion = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        operacion: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      operacion:data
    };
  };
  
  export const initBoxOperacion = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/acerca-de-operacion/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxOperacion(response.data));
        })
        .catch(err => dispatch(setInitBoxOperacion(err.data)));
    };
  };


// =======================================================

const setInitBoxLima = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        lima: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      lima:data
    };
  };
  
  export const initBoxLima = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/acerca-de-lima/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxLima(response.data));
        })
        .catch(err => dispatch(setInitBoxLima(err.data)));
    };
  };


// =======================================================

const setInitBoxMercado = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        mercado: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      mercado:data
    };
  };
  
  export const initBoxMercado = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/acerca-de-mercado/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxMercado(response.data));
        })
        .catch(err => dispatch(setInitBoxMercado(err.data)));
    };
  };
// =======================================================

const setInitBoxConsejo = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        consejo: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      consejo:data
    };
  };
  
  export const initBoxConsejo = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/consejo-admin/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxConsejo(response.data));
        })
        .catch(err => dispatch(setInitBoxConsejo(err.data)));
    };
  };
// =======================================================

const setInitBoxAdmision = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        comiteAdmision: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      comiteAdmision:data
    };
  };
  
  export const initBoxAdmision = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/comite-admision/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxAdmision(response.data));
        })
        .catch(err => dispatch(setInitBoxAdmision(err.data)));
    };
  };
// =======================================================

const setInitBoxListado = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        comiteListado: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      comiteListado:data
    };
  };
  
  export const initBoxListado = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/comite-listado/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxListado(response.data));
        })
        .catch(err => dispatch(setInitBoxListado(err.data)));
    };
  };
// =======================================================

const setInitAuditoria = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        comiteAuditoria: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      comiteAuditoria:data
    };
  };
  
  export const initBoxAuditoria = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/comite-auditoria/lang/"+lang)
        .then(response => {
          dispatch(setInitAuditoria(response.data));
        })
        .catch(err => dispatch(setInitAuditoria(err.data)));
    };
  };
// =======================================================

const setInitBoxVigilancia = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        comiteVigilancia: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      comiteVigilancia:data
    };
  };
  
  export const initBoxVigilancia = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/comite-vigilancia/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxVigilancia(response.data));
        })
        .catch(err => dispatch(setInitBoxVigilancia(err.data)));
    };
  };
// =======================================================

const setInitBoxNormativo = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        comiteNormativo: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      comiteNormativo:data
    };
  };
  
  export const initBoxNormativo = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/comite-normativo/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxNormativo(response.data));
        })
        .catch(err => dispatch(setInitBoxNormativo(err.data)));
    };
  };
// =======================================================

const setInitBoxSanciones = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        comiteSanciones: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      comiteSanciones:data
    };
  };
  
  export const initBoxSanciones = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/comite-sanciones/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxSanciones(response.data));
        })
        .catch(err => dispatch(setInitBoxSanciones(err.data)));
    };
  };

  // =======================================================

const setInitEspacio = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      espacio: []
    };
  }
  return {
    type: actionTypes.FETCH_CUADRICULAS,
    espacio:data
  };
};

export const initBoxEspacio = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/espacio-publicitario/lang/"+lang)
      .then(response => {
        dispatch(setInitEspacio(response.data));
      })
      .catch(err => dispatch(setInitEspacio(err.data)));
  };
};
  // =======================================================

const setInitBeneficiosModelosNegocio = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      beneficiosModelos: []
    };
  }
  return {
    type: actionTypes.FETCH_CUADRICULAS,
    beneficiosModelos:data
  };
};

export const initBoxBeneficiosModelosNegocio = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/beneficios-mod-negociacion/lang/"+lang)
      .then(response => {
        dispatch(setInitBeneficiosModelosNegocio(response.data));
      })
      .catch(err => dispatch(setInitBeneficiosModelosNegocio(err.data)));
  };
};
  // =======================================================

const setOpel = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      opel: []
    };
  }
  return {
    type: actionTypes.FETCH_CUADRICULAS,
    opel:data
  };
};

export const initBoxOpel = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/caracteristicas-opel/lang/"+lang)
      .then(response => {
        dispatch(setOpel(response.data));
      })
      .catch(err => dispatch(setOpel(err.data)));
  };
};

  // =======================================================

const setCaracteristicasOperacion = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      caracteristicasOperacion: []
    };
  }
  return {
    type: actionTypes.FETCH_CUADRICULAS,
    caracteristicasOperacion:data
  };
};

export const initBoxCaracteristicasOperacion = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/caracteristicas-block-trading/lang/"+lang)
      .then(response => {
        dispatch(setCaracteristicasOperacion(response.data));
      })
      .catch(err => dispatch(setCaracteristicasOperacion(err.data)));
  };
};

  // =======================================================

  const setCuadriculaConexionItch = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        conexionItch: []
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      conexionItch:data
    };
  };
  
  export const initCuadriculaConexionItch = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/conexion-itch/lang/"+lang)
        .then(response => {
          dispatch(setCuadriculaConexionItch(response.data));
        })
        .catch(err => dispatch(setCuadriculaConexionItch(err.data)));
    };
  };
  // =======================================================

  const setCuadriculaBeneficiosBIM = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        beneficiosBim: []
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      beneficiosBim:data
    };
  };
  
  export const initCuadriculaBeneficiosBIM = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/beneficios-bim/lang/"+lang)
        .then(response => {
          dispatch(setCuadriculaBeneficiosBIM(response.data));
        })
        .catch(err => dispatch(setCuadriculaBeneficiosBIM(err.data)));
    };
  };
// =======================================================================
// BIVA SUSTENTABLE
  const setInitBoxEstrategia = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        estrategias: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      estrategias:data
    };
  };
  
  export const initBoxEstrategia = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/estrategia-biva-sustentable/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxEstrategia(response.data));
        })
        .catch(err => dispatch(setInitBoxEstrategia(err.data)));
    };
  };

  // =======================================================================
  // BIVA SUSTENTABLE
  const setInitBoxObjetivos = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_CUADRICULAS,
        objetivos: null
      };
    }
    return {
      type: actionTypes.FETCH_CUADRICULAS,
      objetivos:data
    };
  };
  
  export const initBoxObjetivos = () => {
    return dispatch => {
      axios
        .get(dominio + "/cms/o/documents/objetivos-sustentabilidad/lang/"+lang)
        .then(response => {
          dispatch(setInitBoxObjetivos(response.data));
        })
        .catch(err => dispatch(setInitBoxObjetivos(err.data)));
    };
  };
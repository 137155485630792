import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getInitDate } from "../utils/utilities";
import { getLan } from "../utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setNoticiasBiva = noticia => {
  return {
    type: actionTypes.FETCH_NOTICIAS_BIVA_SUCCESS,
    noticias_biva: noticia
  };
};

export const initNoticiasBiva = () => {
  const { inicio, fin } = getInitDate();

  return dispatch => {
    axios
      .get(dominio + "/cms/o/content/news/biva/lang/" + lang + "?from=" + inicio + "&to=" + fin)
      .then(response => {
        dispatch(setNoticiasBiva(response.data));
      })
      .catch(err => {});
  };
};

const setInitOtrasNoticias = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_OTRASNOTICIAS_SUCCESS,
      otrasNoticias: []
    };
  }
  return {
    type: actionTypes.FETCH_OTRASNOTICIAS_SUCCESS,
    otrasNoticias: data
  };
};

export const initOtrasNoticias = (fecha_inicio = null, fecha_fin = null) => {
  const from = fecha_inicio; 
  const to = fecha_fin ? fecha_fin : new Date().toISOString().slice(0, 10);

  let url = `${dominio}/cms/o/content/news/lang/${lang}`;
  // let url = `${dominio}/cms/o/content/news/press/lang/${lang}`;
  url = from ? url.includes("?") ? `${url}&from=${from}` : `${url}?from=${from}` : url;
  url = to ? url.includes("?") ? `${url}&to=${to}` : `${url}?to=${to}` : url;



  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInitOtrasNoticias(response.data));
      })
      .catch(err => dispatch(setInitOtrasNoticias(err.data)));
  };
};

//noticia de prensa por id (nosotros/acercade/noticias)

const setInitNoticiaById = noticia => {
  return {
    type: actionTypes.FETCH_NOTICIABYID_SUCCESS,
    noticia: noticia
  };
};

export const initNoticiaById = id => {
  let url = dominio + "/cms/o/content/news/press/" + id + "/lang/" + lang;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInitNoticiaById(response.data));
      })
      .catch(err => console.log(err));
  };
};

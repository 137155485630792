import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  emisoras: null,
  graficaInstrument: null,
  graficaInstrument5Y: null,
  emisoraDetalle: null,
  emisoraIntegrantes: null,
  documentos: null,
  valoresListados: null,
  avisosDerecho: null,
  agendaDerechos: null,
  cotizaciones: null,
  calificadoras: null,
  calificadoraDetail: null,
  financialReport: null,
  ratiosReport: null,
  quotas: null,
  quota: null,
  tipoMercado: null,
  listadoClaves: null,
  eventosCalificadoras: null,
  claveEventos: null,
  periodo_calificadora: null,
  ejercicio_calificadora: null,
  reporteMensual: null,
  indiceIsin: null,
  fiduciarios: null,
  representantes: null,
  razones: null,
  razonesRepresentantes: null,
  valoresListadosMin: null,
  eventosRelevantes: null,
  analistas: null,
  cobertura: null,
  emisiones: null
};

const setEmisoras = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMISORAS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_EMISORADETALLE_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_EMISORAINTEGRANTES_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_DOCUMENTOS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_VALORESLISTADOS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_GRAFICAINSTRUMENTEMISORA_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_AGENDADERECHOS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_SIC_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_SICVALORESLISTADOS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_SICAVISOSDERECHO_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_CALIFICADORAS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_CALIFICADORADETALLE_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_FINANCIALREPORT_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_RATIOSREPORT_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_QUOTAS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_QUOTASCALCULATION_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_TIPOMERCADO_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_CLAVESEMISORAS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_EVENTOSCALIFICADORAS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_CLAVESEVENTOSCALIFICADORAS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_PERIODOCALIFICADORA_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_EJERCICIOCALIFICADORA_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_REPORTEMENSUAL_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_FIDUCIARIOS_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_REPRESENTANTES_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_FIDUCIARIOSRAZONES_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_REPRESENTANTESRAZONES_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_EVENTOSRELEVANTES_SUCCESS:
      return setEmisoras(state, action);
    case actionTypes.FETCH_ANALISTAS_SUCCESS:
      return setEmisoras(state, action);
    default:
      return state;
  }
};

export default reducer;

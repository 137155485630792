import React, { Component } from "react";
import { connect } from "react-redux";
import Auxs from "../../../hoc/auxs/Auxs";
import conectividadjson from "../../../json/conectividadProveedores.json";
import Box from "../../../components/ui/box/Box";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class ConectividadProveedores extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };
  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  render() {
    let box = null;
    if (this.props.enlacesConectividad) {
      box = this.props.enlacesConectividad.map((enlace, i) => {
        return <Box key={"enlace" + i} modelo="modelo_1" data={enlace} />;
      });
    }
    let toggleProveedores = null;
    if (this.props.toggleboxProveedores) {
        toggleProveedores = (
        <Accordion
          sinPadre={true}
          nombreParent={"accordion"}
          datos={this.props.toggleboxProveedores}
          nombre={"bivaTotal"}
          imagen_icono={"biva_total.svg"}
          icons_tamaño={true}
          tipo={16}
        />
      );
    }
    let bivaTotal = null;
    if (this.props.toggleboxTotal) {
      bivaTotal = (
        <Accordion
          sinPadre={true}
          nombreParent={"accordion"}
          datos={this.props.toggleboxTotal}
          nombre={"bivaTotal"}
          imagen_icono={"biva_total.svg"}
          tipo={16}
          titulo_encabezado_estatico={
            this.state.messages ? this.state.messages["informacionmercado.conectividad.proveedores.biva.total"] : ""
          }
        />
      );
    }
    let bivaBasico = null;
    if (this.props.toggleboxBasico) {
      bivaBasico = (
        <Accordion
          sinPadre={true}
          nombreParent={"accordion"}
          datos={this.props.toggleboxBasico}
          nombre={"bivaBasico"}
          imagen_icono={"biva_basico.svg"}
          tipo={16}
          titulo_encabezado_estatico={
            this.state.messages ? this.state.messages["informacionmercado.conectividad.proveedores.biva.basico"] : ""
          }
        />
      );
    }
    let bivaUltimoHecho = null;
    if (this.props.toggleboxUltHecho) {
      bivaUltimoHecho = (
        <Accordion
          sinPadre={true}
          nombreParent={"accordion"}
          datos={this.props.toggleboxUltHecho}
          nombre={"bivaUltimoHecho"}
          imagen_icono={"biva_ultimo.svg"}
          tipo={16}
          titulo_encabezado_estatico={
            this.state.messages ? this.state.messages["informacionmercado.conectividad.proveedores.biva.ultimohecho"] : ""
          }
        />
      );
    }
    let bivaNoticias = null;
    if (this.props.toggleboxNoticias) {
      bivaNoticias = (
        <Accordion
          sinPadre={true}
          nombreParent={"accordion"}
          datos={this.props.toggleboxNoticias}
          nombre={"bivaNoticias"}
          imagen_icono={"biva_noticias.svg"}
          tipo={16}
          titulo_encabezado_estatico={
            this.state.messages ? this.state.messages["informacionmercado.conectividad.proveedores.biva.noticias"] : ""
          }
        />
      );
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="informacionmercado.conectividad.proveedores.titulo" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <p className={"texto_16 montse regular"}>
              <FormattedHTMLMessage id="informacionmercado.conectividad.proveedores.intro" defaultMessage=" " />
            </p>
          </div>
          <div className="margin_top_30">
            <div className={"accordion"} id={"accordion"}>
              {toggleProveedores}
            </div>
          </div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="informacionmercado.conectividad.proveedores.certificado" defaultMessage=" " />
                </h2>
              </div>
            </div>
            <div className="row margen_top_30">
              <div className={"relativo zindex1000 "}>{box}</div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    toggleboxTotal: state.toggleBox.conectividadBivaTotal,
    toggleboxBasico: state.toggleBox.conectividadBivaBasico,
    toggleboxUltHecho: state.toggleBox.conectividadBivaUltimoHecho,
    toggleboxNoticias: state.toggleBox.conectividadBivaNoticias,
    toggleboxProveedores: state.toggleBox.conectividadProveedores,
    enlacesConectividad: state.documentos.enlacesConectividad
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(45));
      dispatch(action.getToggleBoxConectividadProveedores());
      dispatch(action.getEnlacesConectividad());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConectividadProveedores);

import React, { Component } from "react";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { connect } from "react-redux";
import * as action from "../../../../../stores/actions/index";
import OperacionBox from "../../../../../components/ui/operacionBox/OperacionBox";
import { getLocal } from "../../../../../stores/utils/utilities";

class Biva extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };
  componentDidMount(){
    this.props.oninitUltimoDiaMesBiva();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
      <React.Fragment>
        <div className="row margen_top_30">
        <OperacionBox data={this.props.ultimoDia} tipo={1} urlDescarga={this.props.descargaDocumento}></OperacionBox>
        </div>
        <div className={""}>
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimodia.biva.nota1" defaultMessage=" " />
          </span>
          <br />
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimodia.biva.nota2" defaultMessage=" " />
          </span>
        </div>
      </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    ultimoDia: state.informacionMercado.ultimoDiaBiva,
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    oninitUltimoDiaMesBiva: () => {
      dispatch(action.initUltimoDiaMesBiva(1));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Biva);

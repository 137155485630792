import React, { Component } from "react";
import classes from "./FilterAlternativosASG.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";
import { changeDateFormat, getLan, valueLanguage} from "../../../stores/utils/utilities";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";

const renderInput = ({
    input,
    id = null,
    classes = null,
    elementType = "input",
    ref,
    onInputChange,
    label,
    placeholder = null,
    disabled = false,
    options,
    isMultiple = null,
    maxCharacter = null,
    checked = null,
    selectLabel
}) => {
    const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";
    const params = {
        input: {
            ...input,
            id: id ? id : input.name,
            placeholder: placeholder,
            className:
                classes && elementType === "checkbox"
                    ? classes
                    : [classes, "form-control"].join(" "),
            ref: ref,
            disabled: disabled,
            checked: checked,
            onChange: e => {
                input.onChange(e);
                onInputChange && onInputChange(e);
            }
        },
        label: label,
        isMultiple: isMultiple,
        maxCharacter: maxCharacter,
        elementType: elementType,
        options: options,
        selectLabel: selectLabel ? selectLabel : chooseOptionLabel
    };
    return (
        <div className="form-group">
            {label && input.type !== "radio" && input.type !== "checkbox" ? (
                <label
                    htmlFor={input.name}
                    className={["texto_14", "montse", "regular nowrap"].join(" ")}
                >
                    {label}
                </label>
            ) : null}
            <Input config={params} />
        </div>
    );
};

class FilterAlternativosASG extends Component {
    constructor(props) {
        super(props);
        this.formRef1 = React.createRef();
        this.escFunction = this.escFunction.bind(this);
    }

    state = {
        esconderAutoComplete: false,
        toggleFilters: false,
        disableEnableButtons: true,
        selectedFilters: {
            tiposInstrumento: []
        },
        form_filters: {
            tiposInstrumento: {
                value: []
            }
        },
        form_clave: {
            clave: {
                value: ""
            }
        },
        toggleInputs: false,
        valueClave: null,
        typeClaveSelected: null,
        emisoraSeleccionada: null,
        clave: null,
        filteredBySearch: false,
    };

    componentDidMount() {
        window.addEventListener("click", e => this.collapseSearch(e));
        window.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        window.addEventListener("click", e => this.collapseSearch(e));
        window.addEventListener("keydown", this.escFunction, false);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.claveEmisora !== this.props.claveEmisora && this.props.emisora_id !== null) {
            let disableEnableButtons = true;
            let toggleFilters = false;
            if (this.props.claveEmisora) {
                disableEnableButtons = false;
            }
            this.setState({
                typeClaveSelected: 1,
                valueClave: this.props.claveEmisora,
                disableEnableButtons: disableEnableButtons,
                toggleFilters: toggleFilters,
                filteredBySearch: false
            });
        }

        if (prevProps.biva !== this.props.biva || prevProps.canceladas !== this.props.canceladas) {
            this.onInputChangeHandler(null, false, false, false); // para borrar busqueda aproximada en cambio de tab
            this.setState({
                valueClave: this.props.claveEmisora,
                disableEnableButtons: true,
                toggleFilters: false,
                toggleInputs: this.props.claveEmisora && this.props.claveEmisora !== "" ? true : false
            });
        }
    }

    escFunction(event) {
        let visible = false;

        if (event.keyCode === 27) {
            visible = true;
        }
        this.setState({
            esconderAutoComplete: visible
        });
    }

    collapseSearch(event) {
        let visible = false;
        if (event.target.name !== "search_field") {
            visible = true;
        }

        this.setState({
            esconderAutoComplete: visible
        });
    }

    componentWillMount() {
        this.props.onInitTiposInstrumento();

        this.callReduxAction = debounce(500, this.callReduxAction);
    }

    showContainerEmisoraSleccionadaHandler = () => {
        const clave = this.props.claveEmisora;
        return (
          <Auxs>
            <div className={classes.filter_container_emisora_seleccionada}>
              <div className={classes.filter_emisora_seleccionada}>
                <span className={["montse color_verde_oscuro", classes.filter_text_model_one].join(" ")}>{clave}</span>
                <span className={classes.filter_icon_button_model_one}>
                  <i className="far fa-times-circle color_verde_oscuro" onClick={() => this.onDeleteClaveEmisoraHandler()} />
                </span>
              </div>
            </div>
          </Auxs>
        );
      };

    printFrameHandler = () => {
        const showContainer =
            this.props.claveEmisora && !this.state.filteredBySearch
            ? this.showContainerEmisoraSleccionadaHandler()
            : this.showInputHandler();

        const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : showContainer;
        return (
            <div className="row">
                <div className="col sin_padding">
                    <div className="row">
                        <div className={["col", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
                            <div
                                className={[
                                    "texto_btn_cerrar color_verde_oscuro", classes.toggle_button_container_model_one,
                                    this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                                ].join(" ")}
                                onClick={this.state.disableEnableButtons ? this.onToggleFilters : null}
                            >
                                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                                    <i className="fas fa-filter color_verde_oscuro" />
                                    <span className="color_verde_oscuro">
                                        {this.state.toggleFilters
                                            ? valueLanguage(getLan(),"Cerrar Filtros","Close")
                                            : valueLanguage(getLan(),"Filtrar","Filter")
                                            }
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div
                            className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-8", classes.no_col_padding_model_one].join(
                                " "
                            )}
                        >
                            <div className={classes.filters_selected_container_model_one}>{input}</div>
                        </div>
                        <div
                            className={[
                                this.state.toggleFilters ? classes.hidden : null,
                                "col",
                                "col-lg-1",
                                "text-center",
                                "color_verde_oscuro",
                                classes.no_col_padding_model_one
                            ].join(" ")}
                        >
                            <div
                                className={[
                                    classes.search_button_container_model_one,
                                    this.state.toggleFilters ? classes.no_border_search_button_model_one : null
                                ].join(" ")}
                            >
                                <button type="buttom" className={["btn"].join(" ")} disabled>
                                    <i className="fas fa-search color_verde_oscuro" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
                            " "
                        )}
                    >
                        <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.printModel()}</div>
                    </div>
                </div>
            </div>
        );
    };

    printModel = () => {
        return (
            <div className={classes.filters_container_model_one}>
                <div className={classes.filter_container_model_one}>
                    <Form
                        onSubmit={this.onSubmitFilterHandler}
                        initialValues={{
                            tipoInstrumento: this.state.form_filters.tiposInstrumento.value,
                        }}
                        render={({
                            optionsTiposInstrumento = this.props.tiposInstrumento ? this.props.tiposInstrumento : [],
                            form
                        }) => (
                            <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                                <div className="row">
                                    <div
                                        className={["col-12 col-sm-12 col-md-6 col-lg-3"].join(" ")}
                                    >
                                        <div className="row">
                                            <div className="col-12 sin_padding_L sin_padding_R">
                                                <span className="montse texto_14 texto_negro regular">
                                                    {getLan() === "es" ? "Tipo de instrumento" : "Instrument type"}
                                                </span>
                                            </div>
                                            <div
                                                className={[
                                                    "col-12 sin_padding_L sin_padding_R",
                                                    classes.padding_izquierdo
                                                ].join(" ")}
                                            >
                                                {optionsTiposInstrumento.map((tipo, i) => {
                                                    return (
                                                        <div key={"tipo" + i} className="row">
                                                            {this.onPrintTiposInstrumentoHandler(tipo)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* ======================= */}

                                <hr />
                                {this.sectionButtonHandler(form)}
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    };

    onPrintTiposInstrumentoHandler = tipoInstrumento => {
        const form = this.state.form_filters;
        const optionValue = `${tipoInstrumento.id}_${tipoInstrumento.nombre}`;

        return (
            <Field
                name="TipoInstrumento"
                id={tipoInstrumento.nombre}
                type="checkbox"
                elementType="checkbox"
                value={optionValue}
                classes="form-check-input"
                label={tipoInstrumento.nombre}
                checked={form.tiposInstrumento.value.find(item => item === optionValue)}
                onInputChange={event => this.onInputSelectedHandler(event, 1)}
                component={renderInput}
            />
        );
    };

    sectionButtonHandler = form => {
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center color_verde_oscuro">
                    <button
                        type="button"
                        className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
                        onClick={() => this.onResetFilterHandler(form)}
                    >
                        <i className="fas fa-redo-alt color_verde_oscuro" />
                        {getLan() === "es" ? "Limpiar" : "Clear"}
                    </button>
                </div>
            </div>
        );
    };

    onSubmitFilterHandler = event => {
        event.preventDefault();
    };

    onSubmitSearchModelOneHandler = event => {
        event.preventDefault();
    };

    printChange = e => {
        this.callReduxAction(e.target.value);
    };

    callReduxAction = value => {
        this.onInputChangeHandler(value);
    };

    onInputChangeHandler = (event = null, eraser = null, tipo = null, borraClaveFiltro = true) => {
        const value = event;
        const form_clave = this.state.form_clave;
        form_clave.clave.value = value;
        let disabled = false;
        let filteredBySearch = false;

        if (value !== "") {
            this.props.onFilterEmisorasByClave(value, this.props.biva);
            filteredBySearch = true;
        } else {
            disabled = true;
            if (borraClaveFiltro) {
                this.props.borraClaveFiltro(null);
                this.props.updateEmisorasParent(null);
                this.props.wasFilteredBy(null);
                this.props.setFilterClave(null);
                this.props.setActivePage(1);
                this.props.onDeleteEmisoraSelecionada();
            }
        }

        if (tipo) {
            this.props.onDeleteEmisoraSelecionada();
        }

        if (eraser) {
            this.setState(prevState => {
                return {
                    toggleInputs: !prevState.toggleInputs,
                    emisoraSeleccionada: null,
                    claveSeleccionada: null,
                    form_clave: form_clave,
                    disableEnableButtons: true,
                    filteredBySearch: filteredBySearch
                };
            });
        } else {
            this.setState({ form_clave: form_clave, disableEnableButtons: disabled , filteredBySearch: filteredBySearch});
        }
    };

    onDeleteClaveEmisoraHandler = () => {
        this.props.onDeleteEmisoraSelecionada();
        const form_clave = this.state.form_clave;
        form_clave.clave.value = null;

        const [
          valuesTiposInstrumento,
        ] = this.onGetAllValuesFormFilter();
    
    
        let paramsForParent = {
          tipoInstrumento: valuesTiposInstrumento,
          page: 1,
          reset: false
        };
    
        this.props.setFilters(paramsForParent);
    
        this.setState({
          claveEmisora: null,
          toggleInputs: false,
          disableEnableButtons: true,
          form_clave: form_clave,
        });
    };

    limpiaForms() {
        this.props.onInitTiposInstrumento();

        const form = this.state.form_filters;
        const selectedFilters = this.state.selectedFilters;

        form.tiposInstrumento.value = [];
        selectedFilters.tiposInstrumento = [];

        const paramsReset={
            tipoInstrumento: null,
            reset : true

        }
        this.props.setFilters(paramsReset)
        this.props.setActivePage(1);

        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters
        });
    }

    onResetFilterHandler = form => {
        form.reset();
        this.limpiaForms();
    };

    onToggleFilters = () => {
        this.setState(prevState => {
            return {
                toggleFilters: !prevState.toggleFilters
            };
        });
    };


    // Retorna el valor, etiqueta de una valor recibido como parametro
    onGetValueLabelAndTypeHandler = (value, type) => {
        let newValue = null;
        let newLabel = null;

        newValue = value.split("_")[0];
        newLabel = value.split("_")[1];

        return [newValue, newLabel];
    };

    // Retornas los mercados actuales del input seleccionado (state y filtros seleccionados)
    onGetNameStateFilterByInputHandler = type => {
        const form = this.state.form_filters;
        const selectedFilters = this.state.selectedFilters;

        switch (type) {
            case 1:
                return ["tiposInstrumento", form.tiposInstrumento.value, selectedFilters.tiposInstrumento];
        }
    };

    // Retorna el id
    onSplitValueHandler = options => {
        let newValues = null;

        if (!Array.isArray(options)) {
            newValues = options.split("_")[0];
            return newValues;
        } else {
            newValues = [];
            options.forEach(option => {
                newValues.push(option.split("_")[0]);
            });
            return [...newValues];
        }
    };

    onPrepareValueHandler = item => {
        item.value = `${item.value}_${item.label}`;
    };

    onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
        const form = this.state.form_filters;

        if (inputName && newStateValues) {
            form[`${inputName}`].value = newStateValues;
        }

        const valuesTiposInstrumento = form.tiposInstrumento.value && form.tiposInstrumento.value.length > 0 ? this.onSplitValueHandler(form.tiposInstrumento.value) : [];

        return [valuesTiposInstrumento];
    };

    // Agrega o elimina mercados de estado para un campo del formulario
    onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
        let newStateValues = null;

        newStateValues = [];
        newStateValues = [...inputOptions];
        const stateExists = newStateValues.find(option => option === values);
        if (stateExists) {
            newStateValues = newStateValues.filter(option => option !== values);
        } else {
            if (values !== "") {
                newStateValues.push(values);
            } else {
                newStateValues = [];
            }
        }
        return newStateValues;
      };

    // Agrega o elimina filtros a la barra de filtro seleccionados
    onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
        let newFilterValues = [];
        if (type === 3 || type === 4) {
            // Dates
            newFilterValues = [...filterOptions];
            const filterExists = newFilterValues.find(option => option.label === label);
            if (filterExists) {
                newFilterValues = newFilterValues.filter(option => option.label !== label);
                if (values !== "") {
                    newFilterValues.push({
                        label: label,
                        value: changeDateFormat(values),
                        type: type
                    });
                }
            } else {
                newFilterValues.push({
                    label: label,
                    value: changeDateFormat(values),
                    type: type
                });
            }
        } else {
            newFilterValues = [...filterOptions];
            const filterExist = newFilterValues.find(option => option.label === label);
            if (filterExist) {
                newFilterValues = newFilterValues.filter(option => option.label !== label);
            } else {
                if (values !== "") {
                    newFilterValues.push({ label: label, value: values, type: type });
                } else {
                    newFilterValues = [];
                }
            }
        }
        return newFilterValues;
    };

    onFilterDataByEvent = () => {
        // Se obtienen todos los tipos de instrumento de los campos del formulario (Filtro)
        const [
            valuesTiposInstrumento
        ] = this.onGetAllValuesFormFilter();

        if (!this.state.form_filters.errorDate) {
            const paramsFilter = {
                tipoInstrumento: valuesTiposInstrumento
            }
            this.props.setFilters(paramsFilter);
            this.props.setActivePage(1);
        }
    };

    // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
    onInputSelectedHandler = (event, type = null) => {
        let actualValue = event.target.value;

        let form = this.state.form_filters;
        let selectedFilters = this.state.selectedFilters;

        let newValue = null;
        let newLabel = null;

        // Solo para los campos distintos a tipo instrumento y tipo valor
        [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue, type);

        let newStateValues = null;
        let newFilterValues = null;
        let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

        // Configura el estado y el filtro seleccionado para cada input
        newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
        newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

        form[`${inputName}`].value = newStateValues;
        selectedFilters[`${inputName}`] = newFilterValues;

        const [
            valuesTiposInstrumento
          ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters
        });

        this.onFilterDataByEvent();
    };

    onDeleteFilterHandler = item => {
        let form = this.state.form_filters;
        let selectedFilters = this.state.selectedFilters;

        let newStateValues = null;
        let newFilterValues = null;

        this.onPrepareValueHandler(item);

        let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);
        const auxValue = item.type === 1 ? item.value : "";
        newStateValues = this.onChangeStateHandler(stateValues, auxValue, item.type);
        newFilterValues = this.onChangeFilterHandler(filterValues, auxValue, item.label, item.type);

        form[`${inputName}`].value = newStateValues;
        selectedFilters[`${inputName}`] = newFilterValues;

        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters
        });

        this.onFilterDataByEvent();
    };

    showFiltersSelectedHandler = () => {
        const selectedFilters = this.state.selectedFilters;
        const filterList = [
            ...selectedFilters.tiposInstrumento
        ];
        let filters = null;
        filters = (
            <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
                {filterList.map((item, i) => (
                    <div className={classes.filter_container_model_one} key={`${item}`}>
                        <span className={["montse color_verde_oscuro", classes.filter_text_model_one].join(" ")}>
                            {item.type !== 3 && item.type !== 4 ? item.label : item.value}
                        </span>
                        <span className={classes.filter_icon_button_model_one}>
                            <i className="far fa-times-circle color_verde_oscuro" onClick={() => this.onDeleteFilterHandler(item)} />
                        </span>
                    </div>
                ))}
            </div>
        );
        return filters;
    };

    onToggleFilterInputOptionSelected = (tipo = null) => {
        if (tipo) {
            this.props.setEmisora(null, 4, true);

            this.setState({
                emisoraSeleccionada: null,
                claveSeleccionada: null
            });
        }
        this.setState(prevState => {
            return {
                toggleInputs: !prevState.toggleInputs
            };
        });
    };

    onPrintAutoCompleteHandler = () => {
        const listado = this.props.listadoClaves;
        return listado.map((item, i) => (
            <li key={item} className="list-group-item" onClick={() => this.onClaveSelectedHandler(item, item, 1)}>
                {item}
            </li>
        ));
    };

    onClaveSelectedHandler = (clave, id = null, option = 0) => {
        this.onToggleFilterInputOptionSelected();
        let claveFilter = clave;

        if (option === 0 || clave !== null && id === null) {
            this.props.setFilterClave(claveFilter);
        } else {
            this.props.setInitTabs(0, id, true);
            this.props.updateEmisorasParent(id, clave);
        }
        this.props.wasFilteredBy(0);

        this.setState({ typeClaveSelected: option, valueClave: clave });
    };

    onPrintOptionSelectedHandler = value => {
        return (
            <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
                <span className={classes.filterContainer}>
                    <span className={[classes.filterText, "montse texto_14 regular color_verde_oscuro"].join(" ")}>
                        {this.state.typeClaveSelected === 0
                            ? valueLanguage(getLan(),`Ver todos los resultados para: ${this.state.valueClave}`,`See all results for: ${this.state.valueClave}`)
                            : `${this.state.valueClave}`}
                    </span>
                    <span className={classes.filterIconButton} onClick={() => this.onInputChangeHandler(null, true, true)}>
                        <i className="far fa-times-circle color_verde_oscuro" />
                    </span>
                </span>
            </div>
        );
    };

    showInputHandler = () => {
        const value = this.state.form_clave.clave.value;

        const listado = this.props.listadoClaves ? this.onPrintAutoCompleteHandler() : null;

        const firstlistItem =
            listado && listado.length > 0 ? (
                <li
                    className="list-group-item"
                    onClick={() => {
                        this.onClaveSelectedHandler(value);
                    }}
                >
                    {getLan() === "es" ? `Ver todos los resultados para: ${value}` : `See all results for: ${value}`}
                </li>
            ) : (
                <li className="list-group-item">
                    {getLan() === "es" ? "No existen resultados para la búsqueda:" : "There are no results for the search:"} {value}
                </li>
            );

        return (
            <Auxs>
                {this.onPrintOptionSelectedHandler()}
                <div
                    id="searchInput"
                    className={[classes.search_input_container_model_one, this.state.toggleInputs ? classes.hidden : null].join(" ")}
                >
                    <Form
                        onSubmit={this.onSubmitSearchModelOneHandler}
                        initialValues={{
                            search_field: this.state.form_clave.clave.value
                        }}
                        render={() => (
                            <form ref={this.formSearch} onSubmit={this.onSubmitSearchModelOneHandler}>
                                <Field
                                    name="search_field"
                                    id="search_field"
                                    type="text"
                                    elementType="input"
                                    placeholder={getLan() === "es" ? "Buscar por clave de cotización" : "Search by ticker"}
                                    component={renderInput}
                                    className={"color_verde_oscuro"}
                                    onInputChange={event => this.printChange(event)}
                                />
                            </form>
                        )}
                    />
                    <div
                        className={[
                            classes.autoCompleteContainer,
                            "list-group",
                            !this.state.form_clave.clave.value || this.state.form_clave.clave.value === "" ? classes.hidden : null,
                            this.state.esconderAutoComplete ? classes.hidden : null
                        ].join(" ")}
                    >
                        {firstlistItem}
                        {listado}
                    </div>
                </div>
            </Auxs>
        );
    };

    render() {
        return this.printFrameHandler();
    }
}

const mapStateToProps = state => {
    return {
        tiposInstrumento: state.bivaSustentable.tiposInstrumento,
        emisorasSortNombre: state.tramites.emisorasSortNombre,
        listadoClaves: state.bivaSustentable.listadoClaves
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitTiposInstrumento: (tipoInstrumento = null) => {
            dispatch(actions.filterTiposInstrumentoAlternativosASG());
        },
        onFilterEmisorasByClave: (clave, modoListado) => {
            dispatch(actions.filterAlternativosASGByClave(clave, modoListado));
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FilterAlternativosASG)
);

import React, { Component } from "react";
import classes from "./FilterGraficaHistoricoBiva.css";
import { Form, Field } from "react-final-form";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";

import { getLan } from "../../../stores/utils/utilities"

const valoresIndicadores = [
  { nombre: getLan() === "es" ? "Participación de mercado" : "Market Share", id: "participacionBIVA" },
  { nombre: getLan() === "es" ? "Volumen" : "Volume", id: "volumen" },
  { nombre: getLan() === "es" ? "Acumulado de cruces" : "Crosses accumulated", id: "crucesTotal" },
  { nombre: getLan() === "es" ? "Importe" : "Amount", id: "importe" },
  { nombre: getLan() === "es" ? "Acumulado de bloques" : "Blocks accumulated", id: "bloques" }
];

const renderInput = ({
  input,
  id = null,
  label,
  classes = null,
  options = null,
  defaultValue = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel = null,
  onInputChange
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? classes : null,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : "Seleccione una opción"
  };
  return (
    <React.Fragment>
      {label && input.type !== "radio" && input.type !== "checkbox" && input.type !== "radio_cuadro" ? <label for={input.name}>{label}</label> : null}
      <Input config={params} />
    </React.Fragment>
  );
};

class FilterGraficaHistoricoBiva extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    opcionSeleccionada: "participacionBIVA",
    selectedFiltersGroup1: [],
    tab: null,
    periodoSeleccionado: null,
    toggleFilters: false,
    disableEnableButtons: true,
    allSelelectFilters: [],
    form_filters: {
      opcionesIndicadores: {
        value: "participacionBIVA",
        disabled: false,
        show: false
      },
      tipo: null
    },
    toggleInputs: false
  };

  componentWillMount() {
    let group1 = [];
    const valores = [...valoresIndicadores];
    const item = valores.find(tipv => tipv.id === this.state.opcionSeleccionada);
    if (item) {
      item.type = "opcion";
      group1.push(item);
    }

    this.props.setTipoIndicador(item.id);

    this.setState({
      allSelelectFilters: group1,
      selectedFiltersGroup1: group1
    });
  }

  componentWillReceiveProps(nextProps) { }

  formModelHandler = modelo => {
    switch (modelo) {
      case "modelo_1":
        return this.printFrameModelOneHandler();
      default:
        return null;
    }
  };

  printFrameModelOneHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : this.showFiltersSelectedHandler();
    return (
      <div className="col-12 sin_padding_L sin_padding_R row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.onToggleFilters}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>{this.state.toggleFilters ? getLan() === "es" ? "Cerrar Filtro" : "Close filter" : getLan() === "es" ? "Personalizar gráfica" : "Customize chart"}</span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-9", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              opcionesIndicadores: this.state.form_filters.opcionesIndicadores.value
            }}
            render={({ optionsIndicadores = valoresIndicadores ? valoresIndicadores : [], form }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterModelOneHandler}>
                <div className="row">
                  <div className="col col-lg-11 offset-lg-1 margen_derecha_15 row">
                    {optionsIndicadores.map((opcion, i) => (
                      <div className={"col-12 col-sm-12 col-md-6 col-lg-4 " + classes.radio_padding} key={i + "check"}>
                        <Field
                          key={"check" + i}
                          name="graficas"
                          id={opcion.id}
                          type="radio"
                          elementType="radio_cuadro"
                          checked={this.state.opcionSeleccionada === opcion.id}
                          value={opcion.id}
                          classes={"radio_cuadro"}
                          label={opcion.nombre}
                          onInputChange={event => this.onInputSelectedModelOne(event, "opcionIndicador")}
                          component={renderInput}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* ======================= */}

                <hr />
                {this.sectionButtonsModelOne(form)}
                <br />
              </form>
            )}
          />
      </div>
    );
  };

  sectionButtonsModelOne = form => {
    const disabledButton = !this.state.form_filters.errorDate ? false : true;
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12  col-lg-4 offset-lg-4 text-center">
          <button
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            disabled={disabledButton} onClick={this.onToggleFilters}
          >
            {getLan() === "es" ? "Cerrar Filtros" : "Close"}
          </button>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? " Limpiar filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterModelOneHandler = event => {
    event.preventDefault();
  };

  onSubmitSearchModelOneHandler = () => { };

  onInputChangeModelOne = event => {
    const value = event.target.value;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;
    if (value !== "") {
      this.props.onFilterClaves(value);
    } else {
      disabled = true;
    }
    this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
  };

  limpiaForms() {
    const form1 = { ...this.state.form_filters };
    form1.opcionesIndicadores.show = false;
    form1.opcionesIndicadores.value = "";
    let group1 = [];
    const valores = [...valoresIndicadores];
    const item = valores.find(tipv => tipv.id === "participacionBIVA");
    this.props.setTipoIndicador("participacionBIVA");
    if (item) {
      item.type = "opcion";
      group1.push(item);
    }

    this.setState({
      opcionSeleccionada: "participacionBIVA",
      allSelelectFilters: group1,
      selectedFiltersGroup1: group1,
      form_filters: form1,
      toggleInputs: false
    });
  }

  onResetFilterModelOneHandler = form => {
    form.reset();
    this.limpiaForms();
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onInputModelOneChange = event => {
    const value = event.target.value;
    const disabled = value ? false : true;
    this.setState({
      disableEnableButtons: disabled
    });
  };

  onFilterInstrumentoHandler = () => {
    this.props.onFilterenvios(null);
  };

  onFilterTipoValorHandler = (instrumento = null, tipoValor) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterDocumentos = (mantenimientoId = null, listadoId = null) => {
    const params = {
      tipoInformacion: mantenimientoId,
      tipoInformacionListado: listadoId
    };
    this.props.onFilterTipoDocumentos(params);
  };

  onCheckIfDatesAreValid = (inicio, fin) => {
    return inicio > fin;
  };

  onInputSelectedModelOne = (event, type = null) => {
    let group1 = [...this.state.selectedFiltersGroup1];
    let value = event.target.value;
    let opcionSeleccionada = this.state.opcionSeleccionada;

    if (type === "opcionIndicador") {
      opcionSeleccionada = value;
      const valores = [...valoresIndicadores];
      const item = valores.find(tipv => tipv.id === value);
      group1 = group1.filter(item => item.type !== "opcion");

      if (item) {
        item.type = "opcion";
        group1.push(item);
      }

      this.props.setTipoIndicador(value);

    }

    this.setState({
      allSelelectFilters: group1,
      opcionSeleccionada: opcionSeleccionada
    });
  };

  onDeleteFilterModelOneHandler = item => {
    let group1 = [...this.state.selectedFiltersGroup1];
    let opcionSeleccionada = this.state.opcionSeleccionada;
    group1 = group1.filter(itemd => itemd.type !== "opcion");
    opcionSeleccionada = "participacionBIVA";
    this.props.setTipoIndicador(opcionSeleccionada);


    const valores = [...valoresIndicadores];
    const itemEncontrado = valores.find(tipv => tipv.id === opcionSeleccionada);
    if (itemEncontrado) {
      itemEncontrado.type = "opcion";
      group1.push(itemEncontrado);
    }

    this.setState({
      allSelelectFilters: group1,
      selectedFiltersGroup1: group1,
      opcionSeleccionada: opcionSeleccionada
    });
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.allSelelectFilters];

    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map(item => (
          <div className={classes.filter_container_model_one} key={`${item.nombre}-${item.id}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{item.nombre}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterModelOneHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    this.setState(prevState => {
      if (!prevState.toggleInputs === false) {
      }
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => (
      <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}>
        {item.clave}
      </li>
    ));
  };



  onPrintOptionSelectedHandler = value => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
        <span className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onToggleFilterInputOptionSelected(1)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  render() {
    const filter = this.props.modelo ? this.formModelHandler(this.props.modelo) : null;

    return filter;
  }
}

const mapStateToProps = state => {
  return {
    envios: state.prospectos.envios,
    listadoClaves: state.prospectos.claves,
    tipo_documento: state.prospectos.tipo_documento,
    tiposDocumentos: state.listadoValores.tipoDocumento
    // cotizaciones: state.emisoras.cotizaciones
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitSolicitudesDetalle: tramiteId => {
      dispatch(actions.initTipoDocumentoSolicitud(tramiteId));
    },
    onInitDocumentosListado: (size, page, tramiteId, tipoDocumentoId) => {
      dispatch(actions.initDocumentosListado(size, page, tramiteId, tipoDocumentoId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterGraficaHistoricoBiva);

import * as actionType from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  tipo_documentos: null
};

const setTipoDocumento = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_TIPODOCUMENTOS_SUCCESS:
      return setTipoDocumento(state, action);
    default:
      return state;
  }
};

export default reducer;

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  rss: null,
  rssUrlDocument: null
};

const setFetchRssInputs = (state, action) => {
  return updateObject(state, action);
};

const setFetchRssDocument = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_RSS_SUCCESS:
      return setFetchRssInputs(state, action);
    case actionTypes.FETCH_RSSDOCUMENT_SUCCESS:
      return setFetchRssDocument(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./Cuotas.css";
import Slider from "react-slick";
import { getLan, getUrlDocument } from "../../../../stores/utils/utilities";

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  speed: 200,
  autoplaySpeed: 0,
  cssEase: "linear",
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};

class Cuotas extends Component {
  componentDidMount() {}

  render() {
    if (this.props.datos) {
      // const titulo_slider = this.props.titulo_slider ? this.props.titulo_slider : "";


      const contenido = this.props.datos.map((dato, i) => {
        return (
          <>
            <div className={[classes.card, "card"].join(" ")}>
              <div className={["texto_centro margin_top_20"].join(" ")}>
                <span
                  className={[
                    "texto_centro texto_30 montse semi_bold texto_azul",
                  ]}
                >
                  {dato.titulo}
                </span>
              </div>
              <div
                className={[classes.card_body, "card-body margin_top_10"].join(
                  " "
                )}
              >
                <span
                  className={[
                    "texto_16 montse semi_bold texto_gris", this.props.justificado ? classes.justificado : "texto_centro"
                  ].join(" ")}
                  dangerouslySetInnerHTML={{
                    __html: dato.descripcion !== null ? dato.descripcion : null,
                  }}
                />
              </div>
            </div>
          </>
        );
      });

      let banner = null;

      if (this.props.banner) {
        banner = (
          <div
            className={[
              "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margin_top_20 sin_padding texto_centro bold nunito texto_16 texto_blanco",
            ].join(" ")}
          >
            <h2>
            <span
                className={[
                  "texto_16 center montse regular", classes.banner
                ].join(" ")}
                dangerouslySetInnerHTML={{
                  __html:
                  this.props.banner
                }}
              /> </h2>
          </div>
        );
      }

      return (
        <div className={classes.cuotas}>
          <Slider {...settings}>{contenido}</Slider>
          {banner}
        </div>
      );
    }
  }
}

export default Cuotas;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
import TituloAsg from "../ui/tituloAsg/TituloAsg";
import Reporte from "../layoutReporte/LayoutReporte";


const lan = getLocal();

export default function ReportesSustentabilidad(props) {
  const dispatch = useDispatch();

  const traduccionRedux = useSelector((state) => state.intl.diccionario);

  const [messages, setMessages] = useState(null);


  useEffect(() => {
    dispatch(action.initIntl(103));
  }, []);

  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);

  return (
    <IntlProvider locale={lan} messages={messages}>
      <>
        <TituloAsg titulo={messages && messages !== undefined ? messages["titulo"] : null} />
        <div className="margin_bottom_20 texto_16 montse regular">
          <FormattedHTMLMessage id="introduccion" defaultMessage=" " />
        </div>
        <br />
        <div className="margen_50">{<Reporte location={props.location} history={props.history}/>}</div>
      </>
    </IntlProvider>
  );
}

import React, { Component } from "react";
import * as action from "../../../../stores/actions/index";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import {
  formatDate,
  getLan,
  getLocal,
  getUrlDocument,
} from "../../../../stores/utils/utilities";
import classes from "./Blog.css";
import Back from "../../../../components/ui/back/Back";

class Blog extends Component {
  state = {
    filter: {
      yearMonth: null,
      date: null,
      keyword: null,
      lang: getLan() !== "es" ? "en" : "es",
      page: 0,
    },
    messages: null,
    equipo: [],
    lan: getLocal(),
  };
  componentDidMount() {
    this.getBlog(this.props.id);
  }

  getBlog(id) {
    this.props.getBlog(id);
  }

  render() {
    const blog = this.props.blog;
    let contenido = null;
    let style = null;
    if (blog) {
      const ruta = getUrlDocument(blog.urlImagenPortada);
      style = {
        backgroundImage: `url(${ruta})`,
        zIndex: 0,
      };
      contenido = (
        <div className={classes.contenedor}>
          <Back show={true} onBack={(e) => this.props.onBack(e)} />
          <div
            style={style}
            className={[classes.cover, classes.divImagen, ruta === "" ? classes.sinImagen : null].join(" ")}
          />
          <div
            className={[
              classes.leyendaPortada,
              "text-center",
            ].join(" ")}
          >
            <span
              className={["texto_negro  texto_12 montse"].join(" ")}
              dangerouslySetInnerHTML={{ __html: blog.leyendaImagenPortada }}
            ></span>
          </div>
          <div className={[classes.usuario].join(" ")}>
            <span className="texto_azul texto_12 montse bold float-left">
              {blog.publicador}
            </span>
            <span className="texto_azul texto_12 montse bold float-right">
              {formatDate(blog.fecha)}
            </span>
          </div>

          <div
            className={[classes.titulo, "text-center margin_top_30"].join(" ")}
          >
            <span className="texto_negro bold  nunito">
              {blog.titulo}
            </span>
          </div>

          <div
            className={["text-center margin_top_30"].join(" ")}
          >
            <span className="texto_negro texto_18 montse">
              {blog.subtitulo}
            </span>
          </div>
          <div
            className={["text-justify margin_top_30"].join(" ")}
          >
            <span
              className="texto_negro  texto_16 montse"
              dangerouslySetInnerHTML={{ __html: blog.contenido }}
            ></span>
          </div>
          <div className="margin_top_30">
            <Back show={true} onBack={(e) => this.props.onBack(e)} />
          </div>
        </div>
      );
    }

    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <React.Fragment>{contenido}</React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    getBlog: (id) => {
      dispatch(action.getBlogLifeRay(id));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    diccionario: state.intl.diccionario,
    blog: state.blog.blog ? state.blog.blog : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);

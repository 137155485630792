import React, { Component } from "react";
import classes from "./OperacionBloques.css";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import Auxs from "../../../../hoc/auxs/Auxs";
import Modal from "../../../../components/ui/modal/Modal";
import BackDrop from "../../../../components/ui/backdrop/BakcDrop";
import Boton from "../../../../components/ui/boton/Boton";
import OperacionBox from "../../../../components/ui/operacionBox/OperacionBox";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getUrlDocument } from "../../../../stores/utils/utilities";
class OperacionBloques extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    showModal: false
  };
  componentDidMount() {
    this.props.onInitIntl();
    this.props.getOperacionesMercado();
    this.props.onDocumentos();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  render() {
    const modal = (
      <React.Fragment>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} onCloseModal={this.onToggleModalHandler} area={"operations"} />
      </React.Fragment>
    );
    let ruta = null;
    if (this.props.doc) {
      ruta = getUrlDocument(this.props.doc[0].url);
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="informacionmercado.bloques.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>

          <div className="row margen_top_50">
            <OperacionBox data={this.props.operaciones} tipo={6} />
          </div>
          <div className={""}>
            <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.bloques.freqactualizacion" defaultMessage=" " />
            </span>
            <br />
          </div>

          <div className="row margen_top_50 margin_bottom_300">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={""}>
              <FormattedHTMLMessage id="informacionmercado.bloques.interesado" defaultMessage=" " />
                </h2>
              </div>
            </div>

            <div className="row margen_top_30">
              <Boton
                onClick={this.onToggleModalHandler.bind(this)}
                tipo={4}
                texto={this.state.messages ? this.state.messages["informacionmercado.bloques.interesado.solicita"] : ""}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
              />

              <Boton
                tipo={1}
                texto={this.state.messages ? this.state.messages["informacionmercado.bloques.conoce.reporte"] : ""}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={"blanco"}
                ruta={"/cms/o/operaciones/reportes/bloque"}
              />
            </div>
          </div>

          {modal}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(52));
    },
    getOperacionesMercado: () => {
      dispatch(action.initOperacionesMercado());
    },
    onDocumentos: () => {
      dispatch(action.initOperacionDiariaDocumento());
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    operaciones: state.informacionMercado.operaciones,
    doc: state.documentos.operacionDiaria
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperacionBloques);

import axios from "axios";
import * as actionTypes from "./actionTypes";
import StringUtils from "../utils/StringUtils";

const dominio = process.env.REACT_APP_DOMINIO;

export const getSolicitudesSearch = (params) => {
  let url = `${dominio}/emisoras/tramites`;

  const queryString = StringUtils.jsonToQueryString(params);

  return (dispatch) => {
    axios
      .get(url + queryString)
      .then((response) => {
        dispatch(setSolicitudesSearch(response.data));
      })
      .catch((err) => {
        dispatch(setSolicitudesSearch(err.data));
      });
  };
};


const setSolicitudesSearch = data => {
    if (data === undefined || data === null) {
      return {
        type: actionTypes.FETCH_SEARCH_SOLICITUDES,
        solicitudes: [],
      };
    }
    return {
      type: actionTypes.FETCH_SEARCH_SOLICITUDES,
      solicitudes: data
    };
  };


// ================================================================


const setProspectosColocacionSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SEARCH_PROSPECTOS,
      prospectos: []
    };
  }
  return {
    type: actionTypes.FETCH_SEARCH_PROSPECTOS,
    prospectos: data
  };
};

export const getProspectosColocacionSearch = (params) => {
  // ?size=${size}&page=${page}`;
  const queryString = StringUtils.jsonToQueryString(params);
  
  let url = `${dominio}/emisoras/prospectos${queryString}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setProspectosColocacionSearch(response.data));
      })
      .catch(err => dispatch(setProspectosColocacionSearch(err.data)));
  };
};

// ================================================================


export const getProspectosPreliminaresSearch = (params) => {

  const queryString = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/emisoras/prospectos-preliminares` + queryString;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPreliminaresSearch(response.data));
      })
      .catch(err => {
        dispatch(setPreliminaresSearch(err.data))
      });
  };
};

const setPreliminaresSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PRELIMINARES_SEARCH,
      preliminares: []
    };
  }
  return {
    type: actionTypes.FETCH_PRELIMINARES_SEARCH,
    preliminares: data
  };
};


// ================================================================


export const getSicSearch = (
 params
) => {
  let queryString = StringUtils.jsonToQueryString(params);
  if (queryString !== null) {
    queryString = queryString + "&buscador=true";
  }
  let url = `${dominio}/emisoras/sic${queryString}`;


  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setSicSearch(response.data));
      })
      .catch(err => dispatch(setSicSearch(err.data)));
  };
};

const setSicSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_SIC_SEARCH,
      sic: []
    };
  }
  return {
    type: actionTypes.FETCH_SIC_SEARCH,
    sic: data
  };
};


// ================================================================

const setCotizacionesByName = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_COTIZACIONESEMISORAS_SEARCH,
      cotizaciones: []
    };
  }
  return {
    type: actionTypes.FETCH_COTIZACIONESEMISORAS_SEARCH,
    cotizaciones: data
  };
};

export const getCotizacionEmisorasByNameSearch = (size, page, startsWith) => {
  const base_url = `${dominio}/quotes/quote/starts-with/${startsWith}`;
  let url = `${base_url}?size=${size}&page=${page}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCotizacionesByName(response.data));
      })
      .catch(err => dispatch(setCotizacionesByName(err.data)));
  };
};

// ================================================================

export const getAvisosOfertasPublicasSearch = (size, page, palabraClave = null) => {
  const params = {
    size: size,
    page: page,
    coincidencias: encodeURIComponent(palabraClave)
  }
  const queryParams = StringUtils.jsonToQueryString(params);
  let url = `${dominio}/emisoras/avisos-oferta-publica${queryParams}`;


  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAvisosOfertasPublicasSearch(response.data));
      })
      .catch(err => dispatch(setAvisosOfertasPublicasSearch(err.data)));
  };
};

const setAvisosOfertasPublicasSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SEARCH,
      avisosOfertasPublicas: []
    };
  }
  return {
    type: actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SEARCH,
    avisosOfertasPublicas: data
  };
};

// ================================================================

export const getEmisorasByClaveSearch = (params) => {
  let queryString = StringUtils.jsonToQueryString(params);
  if (queryString !== null) {
    queryString = queryString + "&buscador=true";
  }

  let url = `${dominio}/emisoras/empresas${queryString}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisorasSearch(response.data));
      })
      .catch(err => dispatch(setEmisorasSearch(err.data)));
  };
};


const setEmisorasSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORAS_SEARCH,
      emisoras: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORAS_SEARCH,
    emisoras: data
  };
};


// ================================================================


export const getAllEmisionesByClaveSearch = (params) => {
  const queryString = StringUtils.jsonToQueryString(params);

  let url = `${dominio}/emisoras/instrumento-alternativo/v2/emisiones${queryString}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisionesSearch(response.data));
      })
      .catch(err => dispatch(setEmisionesSearch(err.data)));
  };
};


const setEmisionesSearch = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISIONES_SEARCH,
      emisiones: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISIONES_SEARCH,
    emisiones: data
  };
};

// ================================================================

export const getBancoInformacionSearch = (params) => {
  
  const queryParams = StringUtils.jsonToQueryString(params);
  
  const url = `${dominio}/emisoras/banco-informacion${queryParams}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setBancoInformacionSearch(response.data));
      })
      .catch(err => dispatch(setBancoInformacionSearch(err.data)));
  };
};

const setBancoInformacionSearch = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_BANCOINFORMACION_SEARCH,
      bancoInformacion: []
    };
  }
  return {
    type: actionTypes.FETCH_BANCOINFORMACION_SEARCH,
    bancoInformacion: data
  };
};

// ================================================================

export const getAvisosSuspencion = (params) => {
  
  const queryParams = StringUtils.jsonToQueryString(params);
  
  const url = `${dominio}/avisos/vigilancia${queryParams}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setAvisosSuspencion(response.data));
      })
      .catch(err => dispatch(setAvisosSuspencion(err.data)));
  };
};

const setAvisosSuspencion = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_AVISOSSUSPENCION_SEARCH,
      avisosSuspencion: []
    };
  }
  return {
    type: actionTypes.FETCH_AVISOSSUSPENCION_SEARCH,
    avisosSuspencion: data
  };
};

// ================================================================

export const getEmisionesAsgSearch = (clave) => {
  const url = `${dominio}/emisiones/emisiones-asg/busqueda/${clave}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisionesAsg(response.data));
      })
      .catch(err => dispatch(setEmisionesAsg(err.data)));
  };
};

const setEmisionesAsg = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISIONES_ASG_SEARCH,
      emisionesAsg: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISIONES_ASG_SEARCH,
    emisionesAsg: data
  };
};
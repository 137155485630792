import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./ParticipacionEmision.css";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import queryString from "query-string";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import { getLan } from "../../../stores/utils/utilities";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/empresas/participantes/representante-comun/csv`;

class Representantes extends Component {
  state = {
    indexSelected: 0,
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: false,
    emisoraSeleccionada: null,
    razonSocial: null,
    tab: 0
  };

  componentDidMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let emisora =
      paramsString["emisora"] !== undefined &&
      paramsString["emisora"] !== "null"
        ? paramsString["emisora"]
        : null;
    let razonSocial =
      paramsString["razonSocial"] !== undefined &&
      paramsString["razonSocial"] !== "null"
        ? paramsString["razonSocial"]
        : null;
    let razonSocialNombre =
      paramsString["razonSocialNombre"] !== undefined &&
      paramsString["razonSocialNombre"] !== "null"
        ? paramsString["razonSocialNombre"]
        : null;

    let page =
      paramsString["page"] !== undefined && paramsString["page"] !== "null"
        ? parseInt(paramsString["page"])
        : 1;
    this.props.onInitRepresentantes(
      this.state.itemsPerPage,
      page ? (page > 0 ? page - 1 : 0) : 0,
      emisora,
      razonSocial
    );
    this.setState({
      activePage: page,
      emisoraSeleccionada: emisora,
      razonSocial: razonSocial,
      razonSocialNombre: razonSocialNombre
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.emisoraSeleccionada !== prevProps.emisoraSeleccionada) {
      this.props.onInitRepresentantes(
        this.state.itemsPerPage,
        0,
        this.props.emisoraSeleccionada,
        null
      );
      this.setState({
        emisoraSeleccionada: this.props.emisoraSeleccionada
      });
    }
    if (this.props.razonSocial !== prevProps.razonSocial) {
      if (this.props.razonSocial) {
        this.props.onInitRepresentantes(
          this.state.itemsPerPage,
          0,
          null,
          this.props.razonSocial
        );
      } else {
        if (this.props.emisoraSeleccionada === null) {
          this.props.onInitRepresentantes(
            this.state.itemsPerPage,
            0,
            this.props.emisoraSeleccionada,
            null
          );
        }
      }
      this.setState({
        razonSocial: this.props.razonSocial
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let representantes = null;
    this.setState({
      loader: false
    });
    if (nextProps.representantes && nextProps.representantes.content) {
      representantes = nextProps.representantes.content;
    }
    let resource = representantes;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (
    resource = null,
    pageNumber = null,
    seleccionado = null
  ) => {
    let data = resource ? resource : this.props.representantes;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.props.onInitRepresentantes(
        this.state.itemsPerPage,
        pageNumber - 1,
        this.props.emisoraSeleccionada,
        this.props.razonSocial
      );
      this.onPageChangeHandler(null, pageNumber);
      this.props.changePage(pageNumber);
    }
  }

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };
  onSetEmisora = (emisora, tipo) => {
    let emisoraSeleccionada = null;
    let clave = null;

    if (tipo === 1) {
      emisoraSeleccionada = emisora;
    }
    if (tipo === 2) {
      clave = emisora;
    }

    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      clave: clave
    });
    this.props.onInitRepresentantes(
      this.state.itemsPerPage,
      0,
      this.state.emisoraSeleccionada,
      this.state.razonSocial
    );
  };

  render() {
    let contenidoMostrar = null;
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader loaderDisable={disable => this.setState({ loader: disable })} />
      );
    }

    let representantes = null;
    if (this.props.representantes && this.props.representantes.content) {
      representantes = this.props.representantes.content;
    }
    const numRows = this.props.representantes
      ? this.props.representantes.totalElements
      : 0;

    const representantesShow = (
      <React.Fragment>
        {loader}
        <Accordion
          datos={representantes}
          nombre="agendas"
          icon={""}
          tipo={12}
          subtipo={12}
        />
        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );

    let descarga_url = this.props.emisoraSeleccionada
      ? `${base_url}?empresa=${this.props.emisoraSeleccionada}`
      : base_url;

    const resultadoDescarga = (
      <div className={"row margen_30"}>
        <div className={[classes.card_header, classes.titulo_60pct].join(" ")}>
          <span className={["regular montse texto_16 "].join(" ")}>
            {getLan() === "es" ? "Resultados" : "Results"}
          </span>
        </div>
        <div className={[classes.card_header2, classes.titulo_40pct].join(" ")}>
          <span
            className={[classes.titulo_75pct, "texto_16 montse regular"].join(
              " "
            )}
          >
            <a
              href={descarga_url}
              target="_blank"
              rel="noopener noreferrer"
              className="row"
            >
              <span
                className={[
                  classes.titulo,
                  "texto_16 montse color_azul regular"
                ].join(" ")}
              >
                {getLan() === "es" ? "Descargar listado" : "Download listing"}
                <i
                  className={[
                    "fas fa-arrow-alt-circle-down fa-lg",
                    "Boton__icono_derecho__25mg6"
                  ].join(" ")}
                />
              </span>
              <span
                className={[
                  classes.titulo_ZIP,
                  "semi_bold montse texto_10"
                ].join(" ")}
              >
                {getLan() === "es" ? "Formato CSV" : "CSV file"}
              </span>
            </a>
          </span>
        </div>
      </div>
    );

    contenidoMostrar = <div className="margen_50">{representantesShow}</div>;
    return (
      <React.Fragment>
        <br />
        <div className="margen_50">
          {resultadoDescarga}
          <br />
          {contenidoMostrar}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitRepresentantes: (size, page, emisora, nombre) => {
      dispatch(actions.filterRepresentantes(size, page, emisora, nombre));
    }
  };
};

const mapStateToProps = state => {
  return {
    representantes: state.emisoras.representantes
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Representantes);

import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../../../stores/actions/index";
import OperacionBox from "../../../../../components/ui/operacionBox/OperacionBox";
import classes from "./Tabs.css";
import { Form, Field } from "react-final-form";
import Input from "../../../../../components/ui/input/Input";
import { FormattedHTMLMessage } from "react-intl";
import { getLan, getUrlDocument } from "../../../../../stores/utils/utilities";
import Box from "../../../../../components/ui/box/Box";

const tabs = [
  {
    value: 0,
    image: "",
    icon: "",
    title: "Total"
  },
  {
    value: 1,
    image: "",
    icon: "",
    title: "Local"
  },
  {
    value: 2,
    image: "",
    icon: "",
    title: "SIC"
  }
];

const renderInput = ({
  input,
  id = null,
  label,
  classes = null,
  options = null,
  defaultValue = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel = null,
  onInputChange
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? classes : null,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : "Seleccione una opción"
  };
  return (
    <React.Fragment>
      {label && input.type !== "radio" && input.type !== "checkbox" ? <label for={input.name}>{label}</label> : null}
      <Input config={params} />
    </React.Fragment>
  );
};

class CasasBolsa extends Component {
  state = {
    top: null,
    datos: null,
    tabSeleccionado: 0
  };

  componentDidMount() {
    this.props.oninitUltimoDiaMesBiva();
  }

  componentDidUpdate(nextProps) {
    if (this.props.total !== nextProps.total) {
      this.setState({
        datos: this.props.total
      });
    }

    if (this.props.local !== nextProps.local) {
      this.setState({
        datos: this.props.local
      });
    }

    if (this.props.sic !== nextProps.sic) {
      this.setState({
        datos: this.props.sic
      });
    }
  }
  onChangeItemSelected = (index = 0) => {
    let datos = null;
    let top = null;

    if (index === 0) {
      //todos
      this.props.oninitUltimoDiaMesBiva();
      if (this.props.total) {
        datos = this.props.total;
      }
    } else if (index === 1) {
      //top 5 local
      top = 5;
      this.props.onInitCasaLocal();
      if (this.props.local) {
        datos = this.props.local;
      }
    } else {
      //top 5 SIC
      top = 5;
      this.props.onInitCasaSic();
      if (this.props.sic) {
        datos = this.props.sic;
      }
    }

    this.setState({
      top: top,
      datos: datos,
      tabSeleccionado: index
    });
  };

  render() {
    let checked = this.state.tabSeleccionado;

    let tabMostrar = (
      <div className="row margen_top_30">
        <div className={["texto_14 montse regular", classes.tabsContainer, classes.myCol].join(" ")}>
          {getLan() === "es" ? "Filtar:" : "Filter:"}
        </div>
        <div className={["col", classes.myCol3].join(" ")}>
          {/* <Tabs data={tabs} tipo={1} clicked={i => this.onChangeItemSelected(i)} /> */}
          <Form
            onSubmit={values => this.onSubmitSubFiltersHandler(values)}
            initialValues={{
              tabs: tabs
            }}
            render={form => (
              <form ref={this.formRef}>
                {tabs.map((opcion, i) => (
                  <Field
                    key={i}
                    name="tipos"
                    id={opcion.title}
                    type="radio"
                    elementType="radio_cuadro"
                    classes="radio_cuadro"
                    checked={checked === opcion.value}
                    value={opcion.value}
                    label={opcion.title}
                    onInputChange={event => this.onChangeItemSelected(opcion.value)}
                    component={renderInput}
                  />
                ))}
              </form>
            )}
          />
        </div>
      </div>
    );
    let ruta = null;
    let box = null;
    let ruta_url = null;
    ruta_url = "/cms/o/statistics/ultimo-mes/casas-de-bolsa/csv";
    if (this.state.tabSeleccionado === 1) {
      ruta_url = "/cms/o/statistics/ultimo-mes/casas-de-bolsa/local/csv";
    }
    if (this.state.tabSeleccionado === 2) {
      ruta_url = "/cms/o/statistics/ultimo-mes/casas-de-bolsa/sic/csv";
    }
    ruta = getUrlDocument(ruta_url);
    if (ruta.includes("?")) {
      ruta = `${ruta}&download=true`;
    } else {
      ruta = `${ruta}?download=true`;
    }

    const box2 = {
      leftIcon: "far fa-file-excel",
      title: getLan() === "es" ? "Descargar operación último mes" : "Download Last month trading",
      rightIcon: "fas fa-arrow-down",
      url: ruta
    };

    box = <Box key={"enlace"} modelo="modelo_1" data={box2} formato="XLS" />;

    return (
      <React.Fragment>
        {tabMostrar}
        <div className="row margen_top_30">
          <OperacionBox data={this.state.datos} tipo={5} top={this.state.top} />
        </div>
        <div className={""}>
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimomes.casasbolsa.freqactualizacion" defaultMessage=" " />
          </span>
          <br />
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimomes.casasbolsa.consideraciones" defaultMessage=" " />
          </span>
          <br />
          <span className={"texto_12 montse regular"}>
            <FormattedHTMLMessage id="informacionmercado.ultimomes.casasbolsa.exclusiones" defaultMessage=" " />
          </span>
        </div>
        <div className="row margin_top_30">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2 className={""}>
                <FormattedHTMLMessage id="informacionmercado.ultimomes.intersado" defaultMessage=" " />
              </h2>
            </div>
          </div>
          {box}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    total: state.informacionMercado.ultimoMesCasabolsa,
    sic: state.informacionMercado.ultimoMesCasabolsaSic,
    local: state.informacionMercado.ultimoMesCasabolsaLocal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    oninitUltimoDiaMesBiva: () => {
      dispatch(action.initUltimoDiaMesCasaBolsa(2));
    },
    onInitCasaSic: () => {
      dispatch(action.initUltimoMesCasaBolsaSic());
    },
    onInitCasaLocal: () => {
      dispatch(action.initUltimoMesCasaBolsaLocal());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CasasBolsa);

import React, { Component } from "react";
import classes from "./Stepper.css";
import botonSuma from "../../../assets/icons/botonMas.svg";
import flechaAbajo from "../../../assets/icons/flechaAbajo.svg";
class Stepper extends Component {
  componentDidMount() {}

  render() {
    let imgPath = null;
    let titulo = null;
    let contenido = null;
    let contenido1 = null;
    let contenido2 = null;
    let contenido3 = null;
    let contenido4 = null;
    let contenido5 = null;

    imgPath = require(`../../../assets/icons/antesIpo.svg`);

    if (this.props.data) {
      contenido = this.props.data.map((item, i) => {
        contenido1 = null;
        contenido2 = null;
        contenido3 = null;
        contenido4 = null;
        contenido5 = null;
        if (item.tituloPaso) {
          titulo = item.tituloPaso;
        }

        if (item.imagen && item.imagen !== "") {
          imgPath = require(`../../../assets/icons/${item.imagen}`);
        }

        let numero = i + 1;
        let es_modulo = false;
        let modulo = numero % 2;
        if (modulo === 1 || this.props.tipo === 2) {
          es_modulo = true;
        }
        
        if (item.texto_html_1) {
          let titulo_x = null;
          if (item.titulo_1) {
            titulo_x = (
              <div className={classes.titulo_contenido}>
                <i class="far fa-calendar-alt texto_22"></i>
                <span
                  className={[
                    "nunito texto_22 regular",
                    classes.texto_titulo_contenido
                  ].join(" ")}
                >
                  {item.titulo_1}
                </span>
              </div>
            );
          }
          contenido1 = (
            <div>
              {titulo_x}
              <div
                className={classes.texto_contenido}
                dangerouslySetInnerHTML={{ __html: item.texto_html_1 }}
              ></div>
            </div>
          );
        }

        if (item.texto_html_2) {
          let titulo_x = null;
          if (item.titulo_2) {
            titulo_x = (
              <div className={classes.titulo_contenido}>
                <i class="far fa-calendar-alt texto_22"></i>
                <span
                  className={[
                    "nunito texto_22 regular",
                    classes.texto_titulo_contenido
                  ].join(" ")}
                >
                  {item.titulo_2}
                </span>
              </div>
            );
          }
          contenido2 = (
            <div>
              {titulo_x}

              <div
                className={classes.texto_contenido}
                dangerouslySetInnerHTML={{ __html: item.texto_html_2 }}
              ></div>
            </div>
          );
        }

        if (item.texto_html_3) {
          let titulo_x = null;
          if (item.titulo_3) {
            titulo_x = (
              <div className={classes.titulo_contenido}>
                <i class="far fa-calendar-alt texto_22"></i>
                <span
                  className={[
                    "nunito texto_22 regular",
                    classes.texto_titulo_contenido
                  ].join(" ")}
                >
                  {item.titulo_3}
                </span>
              </div>
            );
          }
          contenido3 = (
            <div>
              {titulo_x}
           
              <div
                className={classes.texto_contenido}
                dangerouslySetInnerHTML={{ __html: item.texto_html_3 }}
              ></div>
            </div>
          );
        }

        if (item.texto_html_4) {
          let titulo_x = null;
          if (item.titulo_4) {
            titulo_x = (
              <div className={classes.titulo_contenido}>
                <i class="far fa-calendar-alt texto_22"></i>
                <span
                  className={[
                    "nunito texto_22 regular",
                    classes.texto_titulo_contenido
                  ].join(" ")}
                >
                  {item.titulo_4}
                </span>
              </div>
            );
          }
          contenido4 = (
            <div>
              {titulo_x}

              <div
                className={classes.texto_contenido}
                dangerouslySetInnerHTML={{ __html: item.texto_html_4 }}
              ></div>
            </div>
          );
        }

        if (item.texto_html_5) {
          let titulo_x = null;
          if (item.titulo_5) {
            titulo_x = (
              <div className={classes.titulo_contenido}>
                <i class="far fa-calendar-alt texto_22"></i>
                <span
                  className={[
                    "nunito texto_22 regular",
                    classes.texto_titulo_contenido
                  ].join(" ")}
                >
                  {item.titulo_5}
                </span>
              </div>
            );
          }

          contenido5 = (
            <div>
              {titulo_x}
              <div
                className={classes.texto_contenido}
                dangerouslySetInnerHTML={{ __html: item.texto_html_5 }}
              ></div>
            </div>
          );
        }

        if (es_modulo) {
          //izquierdo
          return (
            <li className={["col-sm-12 row relativo",  classes.cien, this.props.tipo === 2 ? classes.minimoLinea : null].join(" ")}>
              <div className={[ this.props.tipo === 2 ? "col-md-3":"col-md-6", classes.left, this.props.tipo === 2 ? classes.imagen_minimo : null].join(" ")}>
                <img src={imgPath} alt="" className={[classes.imagenIpo, this.props.tipo === 2 ?  "collapse collapseExample"+i : null,  this.props.tipo === 2 ? classes.imagen_minimo : null].join(" ")} />

                <a
                  className={["", classes.botonLinkSuma].join(" ")}
                  data-toggle="collapse"
                  href={".collapseExample" + i}
                  role="button"
                  aria-expanded="false"
                  aria-controls={"collapseExample" + i}
                >
                  <img src={botonSuma} alt="" className={classes.btnSuma} />
                </a>
                <div
                  className={[
                    "d-md-none d.lg-none  d-xl-none",
                    classes.linea
                  ].join(" ")}
                ></div>
                <img src={flechaAbajo} alt="" className={[classes.flechaAbajo, this.props.tipo === 2 ? "d-none" : null].join(" ")} />
              </div>
              <div className={["col-md-9 ", classes.right].join(" ")}>
                <span
                  className={[
                    "nunito texto_24 regular",
                    classes.tituloStep
                  ].join(" ")}
                >
                  {titulo}
                </span>
                <div
                  className={["collapse", "collapseExample"+i, classes.contenido].join(" ")}
                  id={"collapseExample" + i}
                >
                  <div className={["card card-body", classes.card].join(" ")}>
                    {contenido1}
                    {contenido2}
                    {contenido3}
                    {contenido4}
                    {contenido5}
                  </div>
                </div>
              </div>
            </li>
          );
        } else {
          //derecho
          return (
            <li className={["col-sm-12 row relativo", classes.cien].join(" ")}>
              <div
                className={["col-md-6", classes.left, classes.left_lg].join(
                  " "
                )}
              >
                <span
                  className={[
                    "nunito texto_24 regular",
                    classes.tituloStep
                  ].join(" ")}
                >
                  {titulo}
                </span>
                <div
                  className={["collapse", classes.contenido].join(" ")}
                  id={"collapseExample" + i}
                >
                  <div className={["card card-body", classes.card].join(" ")}>
                    {contenido1}
                    {contenido2}
                    {contenido3}
                    {contenido4}
                    {contenido5}
                  </div>
                </div>
              </div>
              <div className={["col-md-6", classes.right].join(" ")}>
                <img src={imgPath} alt="" className={classes.imagenIpo} />

                <a
                  className={["", classes.botonLinkSuma].join(" ")}
                  data-toggle="collapse"
                  href={"#collapseExample" + i}
                  role="button"
                  aria-expanded="false"
                  aria-controls={"collapseExample" + i}
                >
                  <img src={botonSuma} alt="" className={classes.btnSuma} />
                </a>
                <div
                  className={[
                    "d-md-none d.lg-none  d-xl-none",
                    classes.linea
                  ].join(" ")}
                ></div>
                <img src={flechaAbajo} alt="" className={classes.flechaAbajo} />
              </div>

              <div
                className={["col-md-6", classes.left, classes.left_md].join(
                  " "
                )}
              >
                <span
                  className={[
                    "nunito texto_24 regular",
                    classes.tituloStep
                  ].join(" ")}
                >
                  {titulo}
                </span>
                <div
                  className={["collapse", classes.contenido].join(" ")}
                  id={"collapseExample" + i}
                >
                  <div className={["card card-body", classes.card].join(" ")}>
                    {contenido1}
                    {contenido2}
                    {contenido3}
                    {contenido4}
                    {contenido5}
                  </div>
                </div>
              </div>
            </li>
          );
        }
      });
    }

    return <React.Fragment>{contenido}</React.Fragment>;
  }
}

export default Stepper;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../stores/actions/index";
import LayoutGuia from "../layoutGuiaToggle/LayoutGuia";

export default function Estructura(props) {
  const dispatch = useDispatch();

  const [despliegue, setDespliegue] = useState([]);

  const despliegueRedux = useSelector(
    (state) => state.sustentable.despliegueCultura
  );

  useEffect(() => {
    dispatch(action.getDespliegueCultura());
  }, []);

  useEffect(() => {
    if (despliegueRedux) {
      setDespliegue(despliegueRedux);
    }
  }, [despliegueRedux]);

  return (
    <>
      <LayoutGuia
        hiddenToggleTitle={true}
        titulo={"Despliegue y cultura organizacional"}
        mock={despliegue}
        colores={["#aec925", "#51ad32", "#017830", "#029791"]}
        coloresInternos={["#aec925", "#51ad32", "#017830", "#029791"]}
      />
    </>
  );
}

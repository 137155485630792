import React, { Component } from "react";

import classes from "./Back.css";
import { getLan } from "../../../stores/utils/utilities";
 
class Back extends Component {


    backClick(e){
        e.preventDefault();
        this.props.onBack(e);
    }



  render() {


    return (
      <React.Fragment>
        <div className={classes.btnBackDiv} onClick={e => this.backClick(e)}>
        <span className={["nunito texto_14 regular", this.props.show ? null : "d-none"].join(" ")}>
        <i class="fas fa-arrow-left"></i>
            {getLan() === "es" ? "Regresar" : "Back"}
        </span>
        </div>
      </React.Fragment>
    );
  }
}

export default Back;

import React, { Component } from "react";
import classes from "./ProductosBiva.css";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import Auxs from "../../../hoc/auxs/Auxs";
import Tabs from "../../../components/ui/tabs/Tabs";
import Boton from "../../../components/ui/boton/Boton";

import Itch from "./tabs/Itch";
import Bim from "./tabs/Bim";
import Reportes from "./tabs/Reportes";

import { connect } from "react-redux";

import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class ProductosBiva extends Component {
  state = {
    messages: null,
    activaTabInicial: 0,
    lan: getLocal(),
    tab: 0,
    tabs_informacion: [
      {
        image: "bimGrafica.svg",
        icon: "bimGrafica.svg",
        title: ""
      },
      {
        image: "itch.svg",
        icon: "itch.svg",
        title: ""
      },
      {
        image: "reporte.svg",
        icon: "reporte.svg",
        title: ""
      }
    ]
  };

  componentDidMount() {
    this.props.onInitIntl();
    let paramsString = queryString.parse(this.props.location.search);
    let tab = 0;
    tab = paramsString["tab"] !== undefined && paramsString["tab"] !== "null" ? parseInt(paramsString["tab"]) - 1 : 0;
    this.setState({
      tab: tab,
      activaTabInicial: tab
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
          tabs_informacion: [
            {
              image: "bimGrafica.svg",
              icon: "bimGrafica.svg",
              title: messages["informacionmercado.productos.titulo.itch"]
            },
            {
              image: "itch.svg",
              icon: "itch.svg",
              title: messages["informacionmercado.productos.titulo.bim"]
            },
            {
              image: "reporte.svg",
              icon: "reporte.svg",
              title: messages["informacionmercado.productos.titulo.personalizado"]
            }
          ]
        });
      }
    }
  }

  onChangeItemSelected = (index = 0) => {
    let tab = 0;
    tab = index;

    this.setState({
      tab: tab
    });
    this.props.history.push(
      `?tab=${index + 1}`
    );
  };

  render() {
    const tabMostrar = (
      <div className="row margen_top_30">
        <div className={["col-sm-12 sin_padding_L sin_padding_R", classes.myCol].join(" ")}>
          <Tabs
            data={this.state.tabs_informacion}
            tipo={3}
            clicked={i => this.onChangeItemSelected(i)}
            activaTabInicial={this.state.activaTabInicial}
          />
        </div>
      </div>
    );

    let tabSeleccionado = null;
    if (this.state.tab === 0) {
      tabSeleccionado = <Itch messages={this.state.messages} />;
    } else if (this.state.tab === 1) {
      tabSeleccionado = <Bim />;
    } else {
      tabSeleccionado = <Reportes />;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={[classes.title, "nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.productos.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          <div className="row margen_top_30">
            <div className="col sin_padding_L">
              <div className={[classes.text_container].join(" ")}>
                <span className="montse texto_16 regular">
                  <FormattedHTMLMessage id="informacionmercado.productos.intro" defaultMessage=" " />
                </span>
              </div>
            </div>
          </div>
          <div className=" margen_top_50">{tabMostrar}</div>
          <div className=" margen_top_100">{tabSeleccionado}</div>

          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <h2 className={"montse texto_18 semi_bold"}>
                <FormattedHTMLMessage id="informacionmercado.productos.enviar" defaultMessage=" " />
              </h2>
            </div>
            <div className="row margen_top_30">
              <Boton
                tipo={5}
                area={"operations"}
                texto={"operaciones@biva.mx"}
                icono_derecho="fas fa-long-arrow-alt-right"
                colorBoton={""}
                correo={"operaciones@biva.mx"}
              />
            </div>
          </div>

          <div className="row margen_top_50 margin_bottom_300">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={""}>
                  <FormattedHTMLMessage id="informacionmercado.productos.dudas" defaultMessage=" " />
                </h2>
              </div>
            </div>

            <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
              <div className={"icono_izquierdo"}>
                <i className={["fas fa-phone-alt icon"].join(" ")} />
              </div>
              <div className="col sin_padding_L">
                <p className={["montse texto_16 negrita"].join(" ")}>
                  <FormattedHTMLMessage id="informacionmercado.productos.comunicate" defaultMessage=" " />
                </p>
                <p className={"color_azul semi_bold montse texto_16"}>
                  <FormattedHTMLMessage id="informacionmercado.productos.comunicate.tel" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(47));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductosBiva));

import React, { useState, useEffect } from "react";
import { getLan } from "../../../stores/utils/utilities";
import classes from "./Download.css";

const availableList = process.env.REACT_APP_ARCHIVOS_LIST;

    const getArchivos = (archivos) => {
    return archivos.map(file => {
      return (
        getArchivo(file.fileType, file.url, file.fileName)
      )
    });
  }

  const getArchivo = (docType, url, fileName) => {
    let titulo = ""; 
      if (docType && docType.toUpperCase() === "XBRL") {
        titulo = getLan() === "es" ? "Ver documento" : "View document";
      } else {
        titulo = getLan() === "es" ? "Descargar documento" : "Download document";
      }
      return (
        <div className={["mb-0", classes.titulo, classes.divDescarga, classes.descargaMultiple].join(" ")}>
          <a href={url} {...(fileName ? { download: fileName } : {})} target="_blank" rel="noopener noreferrer"  className={["row", classes.pointer].join(" ")}    >
            <div className="sin_padding_L">
              <span className={[classes.titulo, "texto_16_17 montse color_azul regular"].join(" ")}>{titulo}</span>
              <br />
              <span className={"texto_10 montse semi_bold color_gris"}>
                {getLan() === "es"
                  ? `Formato ${docType === undefined ? "PDF" : docType.toUpperCase()}`
                  : `${docType === undefined ? "PDF" : docType.toUpperCase()} format`}
              </span>
            </div>
            <div className={classes.flechaDescarga}>
              <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
            </div>
          </a>
        </div>
      )
  }




const Download = (props) => {
    const [selectFile, setSelectFile] = useState(null);
    const [files, setFiles] = useState([]);
    let archivos = null;

    useEffect(() => {
      let isXbrl = props.docType && props.docType.toUpperCase() === "XBRL";
      let isAvailableList = props.archivos_list !== undefined && props.archivos_list !== null && availableList === "true";
      
      if(isXbrl && isAvailableList) {
        getFormats(props.archivos_list);
      }
      
    }, []);

    const getFormats = (archivos) => {
      const files_list = archivos.map(file => {
        return {docType: file.extension !== undefined && file.extension !== null ? file.extension : "Unknown", url: file.url};
      });
      setFiles(files_list);
    }

    const selectFileCombo = (item) => {
      setSelectFile(item);

      if (item) {
        window.open(item.url, '_self', 'noopener noreferrer');
      }
    }

    const getArchivosCombo = () => {
      if (files.length === 0) {
        return <></>;
      }
      const items = (<div className="dropdown-menu" aria-labelledby="formatos">
      {
        files.map((item, i) => {
          return <div className={["dropdown-item", selectFile === item ? classes.active : null].join(" ")} onClick={() => selectFileCombo(item)} key={"file"+item.docType} >
            <span className="montse texto_12">{item.docType}</span></div>
        }
        )
      } </div>);


    let titulo = getLan() === "es" ? "Descargar documento" : "Download document";
    const link = selectFile ? (<a href={selectFile.url}  target="_self" rel="noopener noreferrer"  className={["row", classes.pointer].join(" ")}    >
    <span className={[classes.titulo, "texto_16_17 montse color_azul regular"].join(" ")}>{titulo}</span>
    <br />
    </a>) : (<div className={["row"].join(" ")}    >
      <span className={[classes.titulo, "texto_16_17 montse color_azul regular"].join(" ")}>{titulo}</span>
      <br />
    </div>);
    const linkImg = selectFile ? (<a href={selectFile.url}  target="_self" rel="noopener noreferrer"  className={["row", classes.pointer].join(" ")}    >
       <i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />
        </a>) : (<i className={["fas fa-arrow-alt-circle-down fa-lg", "Boton__icono_derecho__25mg6"].join(" ")} />);

    const labelDownload = selectFile ? getLan() === "es"
    ? `Formato ${selectFile.docType.toUpperCase()}`
    : `${selectFile.docType.toUpperCase()} format` : getLan() === "es" ? "Selecciona un formato" : "Select format";
      return (
        
        <div className={["mb-0", classes.titulo, classes.divDescarga, classes.descargaMultiple].join(" ")}>
            <div className="sin_padding_L">
              {link}

              <div class={["dropdown", classes.dropdown].join(" ")}>
                  <button className={["btn btn-secondary dropdown-toggle", classes.btnFiles].join(" ")} type="button" id="formatos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className={["texto_10 montse semi_bold", props.isSearch ? classes.blank : "color_gris"].join(" ")}>
                      {labelDownload}
                    </span>
                  </button>
                 {items}
              </div>
             
            </div>
            <div className={classes.flechaDescarga}>
              {linkImg}
            </div>
        </div>
      )
  }
  let isXbrl = props.docType && props.docType.toUpperCase() === "XBRL";
  let isAvailableList = props.archivos_list !== undefined && props.archivos_list !== null && availableList === "true";
  
  if(isXbrl && isAvailableList) {
      return getArchivosCombo();
    } else if (props.docType && props.url) {
        archivos = getArchivo(props.docType, props.url, props.fileName);
    } else {
        if (props.archivos && props.archivos.length > 0) {
            archivos = getArchivos(props.archivos);
        }
    }
  return <div className={props.isSearch ? classes.texto_blanco : null}>
    {archivos}
  </div>;
};

export default Download;

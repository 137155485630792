import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../stores/actions/index";
import classes from "./AliadosSoluciones.css";
import { defineMessages, IntlProvider } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";
import hojaVerde from "../../../assets/icons/sustentable/hoja-verde-2.png";
import Accordion from "../../../components/ui/accordion/Accordion";


const lan = getLocal();

const Biva360 = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getTogglesAliadosSoluciones(props.page));
    dispatch(actions.initIntl(props.idTranslate));
  }, []);

  const [messages, setMessages] = useState(null);
  const [toggles, setToggles] = useState([]);
  const traduccionRedux = useSelector((state) => state.intl.diccionario);
  const togglesRedux = useSelector(
    (state) => state.bivaSustentable.aliadosSoluciones
  );

  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);

  useEffect(() => {
    if (togglesRedux) {
      setToggles(togglesRedux);
    }
  }, [togglesRedux]);

  const printToggle = () => {
    return <ToggleBox terminos={toggles} key={+new Date()} isVerde={true}/>;
  };


  const printAliados = () => {
    return (
      <Accordion datos={toggles} nombre="aliadosToggle" icon={""} tipo={5} subtipo={2} icons_tamaño={false} isSustentable={true}/>
    );
  };

  return (
    <IntlProvider locale={lan} messages={messages}>
      <>
        <div className={[classes.intro, "margin_top_50"].join(" ")}>
          <div
                  className={[
                    "main_subtitle",
                    "texto_40 ",
                    "nunito",
                    "containerTituloSg",
                    "sin_padding"
                  ].join(" ")}
                >
                  <img
                    src={hojaVerde}
                    alt="bivasg"
                    className={["slash", "imgSg"].join(" ")}
                  />
                  <h1 className={"tituloSg nunito texto_42 bold texto_verde_oscuro"}>{messages ? messages[`biva360${props.page}.titulo`] : null}</h1>
                </div>
          <div
            className={["montse texto_16 margin_top_30"].join(" ")}
            dangerouslySetInnerHTML={{
              __html: messages
                ? messages[`biva360${props.page}.introduccion`]
                : null,
            }}
          />
        </div>
        <div className={["margin_top_30"].join(" ")}>
          <div className={["row"].join(" ")}>
            <div className={["container", classes.alto].join(" ")}>
              {props.page === "aliados"  ? printAliados() : printToggle()}
            </div>
          </div>
        </div>
      </>
    </IntlProvider>
  );
};

export default Biva360;

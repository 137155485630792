import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setInitPosturas = data => {
  return {
    type: actionTypes.FETCH_POSTURAS_SUCCESS,
    posturas: data
  };
};

export const initPosturas = () => {
  const url = `${dominio}/cms/o/documents/tipos-postura/lang/${lang}`;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setInitPosturas(response.data));
      })
      .catch(err => console.log(err));
  };
};

import React, { Component } from "react";
import classes from "./FilterBibliografia.css";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import { getLan } from "../../../../stores/utils/utilities";
import { Form, Field } from "react-final-form";

import Auxs from "../../../../hoc/auxs/Auxs";
import Input from "../../../../components/ui/input/Input";

import * as actions from "../../../../stores/actions/index";

const renderInput = ({
  input,
  label,
  id = null,
  options = null,
  clases = null,
  elementType,
  type = null,
  checked = null,
  placeholder = null,
  onInputChange,
  selectLabel,
}) => {
  const chooseOptionLabel =
    getLan() === "es" ? "Seleccione una opción" : "Select an option";

  const params = {
    input: {
      ...input,
      placeholder: placeholder,
      id: id ? id : label,
      className: clases
        ? clases
        : ["form-control", classes.inputText].join(" "),
      checked: checked,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    type: type,
    options: options,
    label: label,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel,
    elementType: elementType,
  };

  return (
    <div className={"form-group"}>
      {label ? (
        <label
          className={[
            elementType === "checkbox" ? classes.myLabel : null,
            "texto_14 regular montse",
          ].join(" ")}
          for={input.name}
        >
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterBibliografia extends Component {
  constructor(props) {
    super(props);
    this.formFilterAvisos = React.createRef();
  }

  state = {
    toggleFilter: false,
    selectedFilters: {
      fechas: [],
      palabra_clave: [],
    },
    form_filters: {
      errorDate: false,
      palabra_clave: {
        value: "",
      },
    },
    itemsPerPage: 10,
  };

  componentDidMount() {
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  printChange = (e) => {
    this.callReduxAction(e.target);
  };

  callReduxAction = (value) => {
    let evento = {};
    evento.target = value;
    this.onInputSelectedHandlerValue(evento, 0);
  };

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
  onFilterDataByEvent = () => {
    let [valuesPalabraClave, valuesFechaInicio, valuesFechaFin] =
      this.onGetAllValuesForm();

    /* [valuesFechaInicio, valuesFechaFin] = changeDateFormat(valuesFechaInicio, valuesFechaFin); Se elimina para dejar el formato yyy-mm-dd*/

    if (!this.state.form_filters.errorDate) {
      const params = {
        size: this.state.itemsPerPage,
        page: 0,
        keyword: valuesPalabraClave,
        from: valuesFechaInicio,
        to: valuesFechaFin,
        reset: false,
      };
      
      this.props.setParams(params);
      this.props.setPage(1);

      let wasFilteredWord = false;
      // Se invocan a las funciones el componente padre (BancoInformacion.js) para setear los valores correspondientes
      // Configurando el valor del campo palabra clave
      this.props.setPalabraClave(valuesPalabraClave);

      if (
        (valuesFechaInicio && valuesFechaInicio !== "") ||
        (valuesPalabraClave && valuesPalabraClave !== "") ||
        (valuesFechaFin && valuesFechaFin !== "")
      ) {
        wasFilteredWord = true;
      }

      this.props.setWasFiltered(wasFilteredWord);
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesForm = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;
    //setvalue
    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }
    const valuesPalabraClave =
      form.palabra_clave.value && form.palabra_clave.value !== ""
        ? form.palabra_clave.value
        : "";
    return [valuesPalabraClave];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInput = (type) => {
    const selectedFilters = this.state.selectedFilters;
    const form = this.state.form_filters;
    //tyoe0
    if (type === 0) {
      return [
        "palabra_clave",
        form.palabra_clave.value,
        selectedFilters.palabra_clave,
      ];
    }
  };

  // Retorna el valor, etiqueta y tipo  de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newLabel = null;
    let newValue = null;

    const labelText = getLan() === "es" ? "Palabra clave" : "Keyword";

    if (type === 0) {
      // Resto de campos
      newValue = value;
      newLabel = `${labelText}: ${value}`;
    }

    return [newValue, newLabel];
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilter = (
    filterOptions = null,
    values = null,
    label = null,
    type = null
  ) => {
    //set value []
    let newFilterValue = [];

    if (type === 0) {
      if (values !== "") {
        newFilterValue.push({ label: label, value: values, type: type });
      }
    } else {
      // Fechas
      newFilterValue = [...filterOptions];
      const filterExists = newFilterValue.find(
        (option) => option.label === label
      );
      if (filterExists) {
        newFilterValue = newFilterValue.filter(
          (option) => option.label !== label
        );
        if (values && values !== "") {
          newFilterValue.push({ label: label, value: values, type: type });
        }
      } else {
        newFilterValue.push({ label: label, value: values, type: type });
      }
    }
    return newFilterValue;
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandlerValue = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (values !== "") {
      newStateValues = values;
    }
    return newStateValues;
  };

  onInputSelectedHandlerValue = (
    event = null,
    type = null,
    item = null,
    eraser = null
  ) => {
    let actualValues = eraser ? null : event.target.value;
    eraser = eraser || (actualValues && actualValues === "") ? true : false;

    let selectedFilters = this.state.selectedFilters;
    let form = this.state.form_filters;

    let newLabel = null;
    let newValue = null;

    if (eraser) {
      newValue = "";
      actualValues = "";
      type = item.type;
      newLabel = item.label;
    } else {
      // Solo para los campos distintos a tipo instrumento y tipo valor
      [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(
        actualValues,
        type
      );
    }

    let newFilterValues = null;
    let newStateValues = null;
    //getState
    let [inputName, stateValues, filterValues, otherInputName] =
      this.onGetNameStateFilterByInput(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandlerValue(stateValues, actualValues, type);
    newFilterValues = this.onChangeFilter(
      filterValues,
      newValue,
      newLabel,
      type
    );

    //set inputForm
    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] =
      newFilterValues;

    this.setState({
      selectedFilters: selectedFilters,
      form_filters: form,
    });

    //event
    this.onFilterDataByEvent();
  };

  onPrintSelectedHandlerFilter = () => {
    const selectedFiltersState = [
      ...this.state.selectedFilters.palabra_clave,
      ...this.state.selectedFilters.fechas,
    ];
    return (
      <div className={[classes.filterContainerOutside].join(" ")}>
        {selectedFiltersState.map((filter, i) => {
          return (
            <span key={i} className={[classes.filterContainer].join(" ")}>
              <span
                className={[classes.filterText, "montse texto_14 regular"].join(
                  " "
                )}
              >
                {filter.type !== 0 ? filter.value : filter.label}
              </span>
              <span
                className={[classes.filterIconButton].join(" ")}
                onClick={() =>
                  this.onInputSelectedHandlerValue(null, null, filter, true)
                }
              >
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onSubmitFilter = () => {
    // Pass
  };

  onResetFilter = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form.palabra_clave.value = "";
    selectedFilters.palabra_clave = [];
    form.errorDate = false;

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });
    const params = {
      page: 0,
      size: this.state.itemsPerPage,
      from: null,
      keyword: null,
      reset: true,
      to: null,
    };
    this.props.setPage(1);
    this.props.setParams(params);
  };

  onToggleFilterHandlerEvent = () => {
    this.setState((prevState) => {
      return {
        toggleFilter: !prevState.toggleFilter,
      };
    });
  };

  printFrameModelHandlerFunction = () => {
    const filterLabelText = getLan() === "es" ? "Filtrar" : "Filter";
    const closeLabelText = getLan() === "es" ? "Cerrar Filtros" : "Close";

    const selectedValues =
      this.state.selectedFilters.palabra_clave.length > 0 ||
      this.state.selectedFilters.fechas.length > 0
        ? this.onPrintSelectedHandlerFilter()
        : null;

    return (
      <Auxs>
        <div
          className={[
            classes.firstSectionFilterContainer,
            " row ",
            !this.state.toggleFilter ? classes.noBorderRadius : null,
          ].join(" ")}
        >
          <div className={"col col-lg-3 sin_padding_L text-center "}>
            <div
              className={[
                classes.toogleButtonContainer,
                "texto_verde_oscuro texto_btn_cerrar ",
              ].join(" ")}
            >
              <button
                className="btn"
                type="button"
                onClick={this.onToggleFilterHandlerEvent}
              >
                <i className={"fas fa-filter"} />
                <span>
                  {this.state.toggleFilter ? filterLabelText : closeLabelText}
                </span>
              </button>
            </div>
          </div>
          <div className={"col col-lg-9"}>{selectedValues}</div>
        </div>
        <div
          className={[
            classes.secondSectionFilterContainer,
            "row",
            this.state.toggleFilter ? classes.hidden : null,
          ].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    return (
      <Auxs>
        <div className={[classes.mainSubFilterContainer, "class"].join(" ")}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitFilter}
              initialValues={{
                palabra_clave: this.state.form_filters.palabra_clave.value,
              }}
              render={({
                handleSubmit,
                form,
              }) => (
                <form ref={this.formFilterAvisos} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <Field
                        name="palabra_clave"
                        id="palabra_clave"
                        elementType="input"
                        label={getLan() === "es" ? "Palabra clave" : "Keyword"}
                        onInputChange={(event) => this.printChange(event)}
                        // onInputChange={event =>
                        //   this.onInputSelectedHandlerValue(event, 0)
                        // }
                        component={renderInput}
                      />
                    </div>
                  </div>
                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  sectionButtonsHandler = (form) => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(
              " "
            )}
            onClick={() => this.onResetFilter()}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandlerFunction();
    return (
      <Auxs>
        <div className="row margin_bottom_30">
          <div className="col sin_padding">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFilterOperacionesAvisos: (params) => {
      dispatch(actions.filterOperacionesAvisos(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(FilterBibliografia);

import React, { Component } from "react";
import classes from "./BeneficiosListarse.css";
import Boton from "../../../../components/ui/boton/Boton";
import Videos from "../../../../components/videos/Videos";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import Cuadricula from "../../../../components/ui/cuadricula/Cuadricula";
import Forms from "../../../../components/ui/modal/forms/Forms";
import Galeria from "../../../../components/ui/galeria/Galeria";
import * as action from "../../../../stores/actions/index";
import {
  getLan,
  getUrlDocument,
  getLocal
} from "../../../../stores/utils/utilities";

import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import Charts from "../../../../components/ui/charts/Charts";

class BeneficiosListarse extends Component {
  seleccionImagen(event, item_seleccionado) {}

  state = {
    messages: null,
    lan: getLocal(),
    videos: null,
    cuadriculaVinculacion: [
      {
        texto: getLan() === "es" ? "Casas de bolsa" : "Broker Dealers",
        clase: "titulo",
        icono_url: "casa.svg"
      },
      {
        texto: getLan() === "es" ? "Calificadoras" : "Rating agencies",
        clase: "titulo",
        icono_url: "calificadora.svg"
      },
      {
        texto: getLan() === "es" ? "Auditores" : "Auditors",
        clase: "titulo",
        icono_url: "digital_signature.svg"
      },
      {
        texto: getLan() === "es" ? "Estructuradores" : "Structuring",
        clase: "titulo",
        icono_url: "estructura.svg"
      },
      {
        texto: getLan() === "es" ? "Consultores" : "Consultants",
        clase: "titulo",
        icono_url: "consultor.svg"
      },
      {
        texto: getLan() === "es" ? "Bancos de inversión" : "Investment Banking",
        clase: "titulo",
        icono_url: "dollar_coins.svg"
      },
      {
        texto: getLan() === "es" ? "Abogados" : "lawyers",
        clase: "titulo",
        icono_url: "juez.svg"
      }
    ]
  };
  componentDidMount() {
    if (this.props.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      const messages = defineMessages(this.props.diccionario);
      this.setState({
        messages: messages
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }
  onSetColorHandler = value => {
    let number = parseFloat(value);
    if (number > 0.1) {
      return classes.alza;
    } else if (number < 0) {
      return classes.baja;
    } else {
      return null;
    }
  };

  onSetFormatValues = value => {
    return <FormattedNumber currency="USD" value={value} />;
  };

  paintGrapHandler = (timeSeries, width = null, height = null) => {
    let dataChart = [];

    for (let key in timeSeries) {
      const date = new Date(timeSeries[key].dateInMillis);
      const hours = date.getHours();
      dataChart.push([
        hours,
        parseFloat(timeSeries[key].open),
        parseFloat(timeSeries[key].close)
      ]);
    }

    dataChart = dataChart.reverse();
    return (
      <Charts
        data={dataChart}
        width={width ? width : "190px"}
        height={height ? height : "120px"}
        chartType="AreaChart"
      />
    );
  };

  onToggleModalHandler = () => {};

  render() {
    let icono = null;
    let id = null;
    let titulo = "Titulo del beneficio";
    let titulo2 = null;
    let titulo3 = null;
    let descripcion = "Descripción del beneficio";
    let descripcion2 = null;
    let descripcion3 = null;
    let tituloAzul = "";
    let galeria = null;
    let videos = null;
    let vinculacion = null;
    let indice = null;
    let boton = null;
    let boxes1 = null;
    let boxes2 = null;
    let contacto = null;
    icono = <i className={["far", "fa-file-image", classes.icon].join(" ")} />;
    if (this.props.datos.icono_url && this.props.datos.icono_url !== "") {
      let imgPath = require(`../../../../assets/icons/${this.props.datos.icono_url}`);
      icono = <img src={imgPath} alt="" className={classes.icon} />;
    }

    if (this.props.datos.texto) {
      titulo = this.props.datos.texto;
    }
    if (this.props.datos.id) {
      id = this.props.datos.id;
    }

    if (this.props.datos.titulo2) {
      titulo2 = (
        <div className="relativo margen_top_30">
          <div className={["main_subtitle"].join(" ")}>
            <span className={"slash"}>/</span>
            <h2>{this.props.datos.titulo2}</h2>
          </div>
        </div>
      );
    }

    if (this.props.datos.descripcion2) {
      descripcion2 = (
        <div className="row relativo">
          <span className={["montse texto_16 regular"]}>
            {this.props.datos.descripcion2}
          </span>
        </div>
      );
    }
    if (this.props.datos.descripcion) {
      descripcion = this.props.datos.descripcion;
    }
    if (this.props.datos.tituloAzul) {
      tituloAzul = (
        <div className="row relativo margen_top_20">
          <span className={["nunito texto_24 regular color_azul"]}>
            "{this.props.datos.tituloAzul}"
          </span>
        </div>
      );
    }

    if (id === 1) {
      if (
        this.props.videos !== undefined &&
        this.props.videos !== null &&
        this.props.videos.length > 0
      ) {
        videos = (
          <div className={["row margen_top_50", classes.fondo].join(" ")}>
            <Videos videos={this.props.videos} />
          </div>
        );
      }
      if (
        this.props.datos.galeriaJson !== null &&
        this.props.datos.galeriaJson !== undefined &&
        this.props.datos.galeriaJson.length > 0
      ) {
        galeria = (
          <div className={"margen_top_30"}>
            <Galeria
              data={this.props.imagenesGrito}
              tiene_imagenes={false}
              imagenes={null}
              videos={null}
              noMostrarPrimerImagen={true}
            />
          </div>
        );
      }
    }

    if (id === 4) {
      galeria = null;
      let idVideo = "";

      if (this.props.videoBIM.length > 0) {
        idVideo = this.props.videoBIM[0].url.split("=")[1];
        videos = (
          <div className={["row margen_top_50 width_cien"].join(" ")}>
            <iframe
              title="BIM"
              width="100%"
              height="476px"
              src={`https://www.youtube.com/embed/${idVideo}`}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>
        );
      }
    }

    if (id === 7) {
      if (
        this.props.videosAuditorium !== undefined &&
        this.props.videosAuditorium !== null
      ) {
        videos = (
          <div className={["row margen_top_50", classes.fondo].join(" ")}>
            <Videos videos={this.props.videosAuditorium.items} />
          </div>
        );
      }

      if (
        this.props.datos.galeriaJson !== null &&
        this.props.datos.galeriaJson !== undefined &&
        this.props.datos.galeriaJson.length > 0
      ) {
        galeria = (
          <div className={"margen_top_30"}>
            <Galeria
              data={this.props.imagenes}
              tiene_imagenes={false}
              imagenes={null}
              videos={null}
              noMostrarPrimerImagen={true}
            />
          </div>
        );
      }
    }

    if (
      id === 2 ||
      id === 5 ||
      id === 6
    ) {
      videos = null;
    }

    if (id === 6) {//Cuotas || Fees
      boxes1 = (
        <div className={"row relativo margen_top_30"}>
          <div className={"margen_top_50 row"}>
            <div className="row sin_padding">
              <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
                <div className={"icono_izquierdo"}>
                  <i className={["far fa-envelope icon"].join(" ")} />
                </div>
                <div className="col">
                  <p className={["montse texto_16 negrita"].join(" ")}>
                    <FormattedHTMLMessage
                      id="empresas.listadodevalores.porquelistarse.correo.leyenda"
                      defaultMessage=" "
                    />
                  </p>
                  <p className={"color_azul semi_bold montse texto_16"}>
                    <FormattedHTMLMessage
                      id="empresas.listadodevalores.porquelistarse.cuotas.correo"
                      defaultMessage=" "
                    />
                  </p>
                </div>
              </div>

              <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
                <div className={"icono_izquierdo"}>
                  <i className={["fas fa-phone-alt icon"].join(" ")} />
                </div>
                <div className="col">
                  <p className={["montse texto_16 negrita"].join(" ")}>
                    <FormattedHTMLMessage
                      id="empresas.listadodevalores.porquelistarse.telefono.leyenda"
                      defaultMessage=" "
                    />
                  </p>
                  <p className={"color_azul semi_bold montse texto_16"}>
                    <FormattedHTMLMessage
                      id="empresas.listadodevalores.porquelistarse.cuotas.telefono"
                      defaultMessage=" "
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      contacto = (
        <div
          className={["row relativo margen_top_30", classes.fondoContacto].join(
            " "
          )}
        >
          <div className="row margin_bottom_20">
            <div className="col-12">
              <span className="color_blanco nunito texto_30 regular">
                
                <FormattedHTMLMessage
                    id="empresas.listadodevalores.porquelistarse.contactanos.leyenda"
                    defaultMessage=" "
                  />
              </span>
            </div>
          </div>
          < Forms modelo="modelo_1" onCloseModal={this.onToggleModalHandler} area={"issuers"}/>
        </div>
      );
    }

    if (id === 7) {
      boxes2 = (
        <div className={"margen_top_50 row"}>
          <div className="row sin_padding">
            <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
              <div className={"icono_izquierdo"}>
                <i className={["far fa-envelope icon"].join(" ")} />
              </div>
              <div className="col">
                <p className={["montse texto_16 negrita"].join(" ")}>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.porquelistarse.correo.leyenda"
                    defaultMessage=" "
                  />
                </p>
                <p className={"color_azul semi_bold montse texto_16"}>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.porquelistarse.auditorio.correo"
                    defaultMessage=" "
                  />
                </p>
              </div>
            </div>

            <div className={["row sin_padding contenedor_mensaje"].join(" ")}>
              <div className={"icono_izquierdo"}>
                <i className={["fas fa-phone-alt icon"].join(" ")} />
              </div>
              <div className="col">
                <p className={["montse texto_16 negrita"].join(" ")}>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.porquelistarse.telefono.leyenda"
                    defaultMessage=" "
                  />
                </p>
                <p className={"color_azul semi_bold montse texto_16"}>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.porquelistarse.auditorio.telefono"
                    defaultMessage=" "
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (
      this.props.datos.id === 1 &&
      this.props.datos.vinculacionJson.length > 0
    ) {
      vinculacion = (
        <div className="relativo margen_top_30">
          <div className={["main_subtitle"].join(" ")}>
            <span className={"slash"}>/</span>
            <h2>{this.props.datos.titulo_vinculacion}</h2>
          </div>

          <div className="row margen_top_30">
            <Cuadricula
              datos={this.state.cuadriculaVinculacion}
              coloreados={[]}
              saltarse={[]}
              enBlanco={[]}
              quitar={[7, 9]}
              modulo={3}
              sinFondo={true}
            />
          </div>
          <div className="row">
            <span className={"montse texto_16 regular"}>
              {this.props.datos.vinculacionTexto}
            </span>
          </div>
        </div>
      );
    }
    if (this.props.datos.descripcion3) {
      descripcion3 = (
        <div className="row relativo">
          <span
            className={["montse texto_16 regular"]}
            dangerouslySetInnerHTML={{ __html: this.props.datos.descripcion3 }}
          />
        </div>
      );
    }

    if (this.props.datos.conocebtnTexto) {
      let tipoBtn = 1;
      let ruta = this.props.datos.urlBoton;
      if (ruta) {
        ruta = getUrlDocument(ruta);
        tipoBtn = 2;
      }
      if (tipoBtn === 1) {
        boton = (
          <Boton
            ruta={this.props.datos.urlBoton}
            tipo={2}
            texto={this.props.datos.conocebtnTexto}
            icono_derecho="fas fa-long-arrow-alt-right"
            colorBoton={""}
          />
        );
      } else {
        boton = (
          <Boton
            ruta={this.props.datos.urlBoton}
            tipo={1}
            texto={this.props.datos.conocebtnTexto}
            icono_derecho="fas fa-long-arrow-alt-right"
            colorBoton={""}
          />
        );
      }
    }
    if (this.props.datos.titulo3) {
      titulo3 = (
        <div className="relativo margen_top_30">
          <div className={["main_subtitle"].join(" ")}>
            <span className={"slash"}>/</span>
            <h2>{this.props.datos.titulo3}</h2>
          </div>
          <div>{descripcion3}</div>
          <div>{boton}</div>
        </div>
      );
    }

    if (id === 5) { //"Índice FTSE BIVA"
      const graphindice = this.props.indice
        ? this.paintGrapHandler(this.props.indice.timeSeries, "345px", "160px")
        : null;
      const color = this.onSetColorHandler(
        this.props.indice.percentageOfChange
      );

      indice = (
        <div className="relativo row margen_top_50">
          <div className={classes.indiceContainer}>
            <div className="row">
              <div className="col">
                <p
                  className={["nunito", "texto_30", classes.titleColor].join(
                    " "
                  )}
                >
                  FTSE BIVA
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col col-lg-6">
                <p className={["nunito", "texto_30"].join(" ")}>
                  {this.onSetFormatValues(this.props.indice.lastPrice)}
                </p>
              </div>
              <div className="col col-lg-3">
                <p
                  className={["nunito", "texto_16", classes.titleColor].join(
                    " "
                  )}
                >
                  Var
                </p>
                <p className={["nunito", "texto_16", color].join(" ")}>
                  ${this.onSetFormatValues(this.props.indice.netChange)}
                </p>
              </div>
              <div className="col col-lg-3">
                <p
                  className={["nunito", "texto_16", classes.titleColor].join(
                    " "
                  )}
                >
                  Var%
                </p>
                <p className={["nunito", "texto_16", color].join(" ")}>
                  <FormattedNumber
                    value={this.props.indice.percentageOfChange / 100}
                    style={`percent`}
                    minimumFractionDigits={2}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className={[classes.chartInfoContainer, ""].join(" ")}>
            {graphindice}
          </div>
        </div>
      );
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <div
            className={[
              "row col-sm-12 sin_padding relativo",
              classes.contenedor_primero
            ].join(" ")}
          >
            <div
              className={classes.contenedor_cuadro}
              onClick={this.props.onClick}
            >
              <div className={[classes.cuadro].join(" ")}>
                <div className={classes.contenido}>
                  <div className={[classes.texto].join(" ")}>
                    <span
                      className={[
                        classes.texto,
                        "nunito texto_24 regular"
                      ].join(" ")}
                    >
                     {getLan() === "es" ? "Más beneficios" : "More benefits"} 
                    </span>
                    <div className={classes.contenedor_icon}>
                      <i class="fas fa-plus icon3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relativo margen_top_30">
            <div className={["main_subtitle"].join(" ")}>
              {icono}
              <span className={"slash"}>/</span>
              <h2>{titulo}</h2>
            </div>
          </div>
          <div className="row relativo">
            <span className={["montse texto_16 regular"]}>{descripcion}</span>
          </div>

          {titulo2}
          {descripcion2}
          {tituloAzul}

          {boxes1}
          {galeria}

          {videos}

          {indice}

          {vinculacion}

          {titulo3}
          {boxes2}

          {contacto}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  let indice = null;
  let videos = null;
  if (state.indice.indice) {
    indice = state.indice.indice;
  }
  if (state.videos.videos_api) {
    videos = state.videos.videos_api.items;
  }

  return {
    diccionario: state.intl.diccionario,
    indice: indice,
    videos: videos,
    videoBIM: state.videos.videoBIM,
    videosDIV: state.videos.videosDIV,
    videosAuditorium: state.videos.videosAuditorium,
    imagenes: state.imagenes.auditorium,
    imagenesGrito: state.imagenes.grito
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchVideos: () => {
      dispatch(action.initVideosAPI(50));
    }, 
    getImagenesGrito:() =>{
      dispatch(action.getImagenesGrito());
    },
    getImagenesAuditorio:() =>{
      dispatch(action.getImagenesAuditorium());
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiosListarse);

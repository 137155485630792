import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { getLan } from "../../../stores/utils/utilities";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";

class ValoresListadosEmisoras extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    emisoraSeleccionada:null
  };
componentDidUpdate(prevProps){
  if (this.props.valoresListados !== prevProps.valoresListados) {
    this.setState({
      loader: false
    });
  }
}
  componentWillReceiveProps(nextProps) {
    let valoresListados = null;


    if (nextProps.valoresListados && nextProps.valoresListados.content) {
      valoresListados = nextProps.valoresListados.content;
    }
    let resource = valoresListados;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.valoresListados;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {

      this.props.history.push(
        `?emisora_id=${this.props.emisoraSeleccionada}&subTab=3&biva=${this.props.biva}&canceladas=${this.props.canceladas}&page=${pageNumber}`
      );

      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.props.onInitValoresListados(this.state.itemsPerPage, pageNumber - 1,this.props.emisoraSeleccionada);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let emisora_id = null;
    let page = null;

    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      emisora_id = paramsString["emisora_id"] === "null" || paramsString["emisora_id"] === undefined ? null : paramsString["emisora_id"];
      page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    }
    this.setState({
      activePage: page > 0 ? page : 1,
      emisoraSeleccionada : emisora_id
    });

    this.props.onInitValoresListados(this.state.itemsPerPage, page > 1 ? page - 1 : 0, this.props.emisoraSeleccionada); 
  }

  render() {
    let showData = null;
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let valoresListados = this.props.valoresListados && this.props.valoresListados.content ? this.props.valoresListados.content : [];
    if (valoresListados.length > 0) {
      showData = <Accordion datos={valoresListados} nombre="emisoraValoresListados" icon={""} tipo={12} subtipo={2} />;
    } else if (valoresListados.length === 0) {
      showData = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }
    const numRows = this.props.valoresListados ? this.props.valoresListados.totalElements : 0;
 

    return (
        <React.Fragment>
          {loader}
      <div className="margen_top_30">{showData}</div>
           <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          /> 
        </React.Fragment>
    );
    }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitValoresListados: (size, page, empresaId) => {
        dispatch(actions.initValoresListados(size, page, empresaId));
    }
  };
};

const mapStateToProps = state => {
  return {
    valoresListados:  state.emisoras.valoresListados
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ValoresListadosEmisoras)
);


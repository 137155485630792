import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./containers/app/App";
import * as serviceWorker from "./serviceWorker";
import packageJson from '../package.json';

// Router
import { BrowserRouter } from "react-router-dom";

// Redux
import { createStore, applyMiddleware, compose } from "redux";
// import { createStore, applyMiddleware } from "redux";

//React redux
import { Provider } from "react-redux";

// Redux thunk
import thunk from "redux-thunk";

// Reducers
import reducers from "./stores/rootReducer";

import { IntlProvider } from "react-intl";
import messages_es from "./intl/es.json";
import messages_en from "./intl/en.json";
import { addLocaleData } from "react-intl";

import locale_en from "react-intl/locale-data/en";
import locale_es from "react-intl/locale-data/es";
addLocaleData([...locale_en, ...locale_es]);

const messages = {
  es: messages_es,
  en: messages_en
};
// Redux DevTools
const composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

//se quita redux devtools
// const store = createStore(reducers, applyMiddleware(thunk));
let language = localStorage.getItem("idioma");
let local = localStorage.getItem("local");
if (language === null || language === undefined) {
  localStorage.setItem("idioma", "es");
  localStorage.setItem("local", "es-MX");
  language = "es";
}

if (local === null || local === undefined) {
  local = "es-mx";
  localStorage.setItem("local", "es-MX");

  if (language !== null && language !== undefined && language=== "en") {
    local = "en-US";
    localStorage.setItem("local", "en-US");

  }
}
console.log("Name: " + packageJson.name + " Version: " + packageJson.version);

const app = (
  <IntlProvider locale={local} messages={messages[language]}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </IntlProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from "react";

import classes from "./FilterGraficaHistoricoCasaBolsa.css";
import { Form, Field } from "react-final-form";

import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";

import Input from "../input/Input";
import { getLan } from "../../../stores/utils/utilities";
const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiSelect = null,
  isSingle = null,
  isClearable = false,
  options,
  defaultValue = null,
  checked = null,
  campoLabel = null,
  campoLabel2 = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      checked: checked,
      isClearable: isClearable,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    campoLabel: campoLabel,
    campoLabel2: campoLabel2,
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isSingle: isSingle,
    isMultiSelect: isMultiSelect,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };
  return (
    <div className="form-group">
      <label className={["montse", "texto_14", "regular"].join(" ")}>{label}</label>
      <Input config={params} />
    </div>
  );
};

class FilterGraficaHistoricoCasaBolsa extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    topSeleccionado: [],
    topsValores: [],
    anios: [],
    datos: null,
    selectedFiltersGroup1: [],
    tab: null,
    periodoSeleccionado: null,
    clean: false,
    toggleFilters: false,
    disableEnableButtons: true,
    allSelelectFilters: [],
    form_filters: {
      tops: {
        value: -1,
        disabled: false,
        show: false
      },
      anios: {
        value: "",
        disabled: false,
        show: false
      },
      tipo: null
    },
    toggleInputs: false
  };

  componentDidUpdate(prevProps, prevState) {
    const form1 = { ...this.state.form_filters };
    if (prevState.clean !== this.state.clean) {

      form1.tops.value = form1.tops.value.sort(this.compare);

      this.props.setTop(form1.tops.value, form1.anios.value)
        this.onInputSelectedModelOne(form1.tops.value, "tops");

    }
    let anios = [];
    let casas = [];
    if (this.props.datos && this.props.datos !== prevProps.datos) {
      let datos = this.props.datos;
      if (this.props.datos.length !== undefined) {
        datos = this.props.datos;
      } else {
        datos = [this.props.datos];
      }
      let objAnio = [];
      datos.map((anio, i) => {
        let key = Object.keys(anio);
        if(key && key.length !== undefined){

          anios =   key.map(itmAnio => {
            return {
              nombre:itmAnio,
              id: itmAnio
            };
          });
        }
        return null;
      });
      const ultimo_dato = datos && datos.length ?  datos.length -1 : 0 ;
      const ultimoAnio = anios && anios.length ? anios.length -1 : 0;
      let meses = datos[ultimo_dato][anios[ultimoAnio].id];
      form1.anios.value = anios[ultimoAnio].id;
      let key_casas = Object.keys(meses);
      const ultimoKeyCasas = key_casas && key_casas.length ? key_casas.length -1 : 0;

      casas.push({ nombre: "Top 5", id: -1 });
      casas.push({ nombre: getLan() === "es" ? "Todas" : "All", id: -2 });
      datos[ultimo_dato][anios[ultimoAnio].id][key_casas[ultimoKeyCasas]].map((casa, i) => {
        let objCasa = [];
        objCasa = {
          nombre: casa.casaDeBolsa,
          id: casa.lugar
        };
        casas.push(objCasa);

        return null;
      });
      let top5 = [];
      let group1 = [];
      casas.map((valor, i) => {
        if (valor.id < 6 && valor.id > 0) {
          top5.push(valor);
          valor.type = "tipTops";
          group1.push(valor);
        }
        return null;
      });
      let values = [...top5];
      if (values.length > 5) {
        values = values.filter(itm => itm.id !== -1);
      }
      form1.tops.value = this.getUnique(values, "id");
      form1.tops.value = form1.tops.value.sort(this.compare);

      this.props.setTop(form1.tops.value);
      let anio_seleccion = anios[ultimoAnio];
      if (anio_seleccion) {
        form1.anios.value = anio_seleccion;

        anio_seleccion.type = "tipAnio";
        group1.push(anio_seleccion);
        this.props.setFecha(anio_seleccion, form1.tops.value ? form1.tops.value : "");
      }
      this.setState({
        datos: this.props.datos.total,
        anios: anios,
        topsValores: casas,
        form_filters: form1,
        allSelelectFilters: group1,
        topSeleccionado: top5
      });
    }
  }

  setDatoCombos(){


  }

  formModelHandler = modelo => {
    switch (modelo) {
      case "modelo_1":
        return this.printFrameModelOneHandler();
      default:
        return null;
    }
  };

  printFrameModelOneHandler = () => {
    const input = this.state.toggleFilters ? this.showFiltersSelectedHandler() : this.showFiltersSelectedHandler();
    return (
      <div className="col-12 sin_padding_L sin_padding_R row">
        <div className="col sin_padding">
          <div className="row">
            <div className={["col", "col-lg-3", "text-center", classes.no_col_padding_model_one].join(" ")}>
              <div
                className={[
                  "texto_btn_cerrar",
                  classes.toggle_button_container_model_one,
                  this.state.toggleFilters ? classes.no_borders_filter_button_model_one : null
                ].join(" ")}
                onClick={this.onToggleFilters}
              >
                <button type="button" className="btn" disabled={!this.state.disableEnableButtons}>
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar filtro"
                        : "Close filter"
                      : getLan() === "es"
                      ? "Personalizar gráfica"
                      : "Customize chart"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={["col", this.state.toggleFilters ? "col-lg-9" : "col-lg-9", classes.no_col_padding_model_one].join(
                " "
              )}
            >
              <div className={classes.filters_selected_container_model_one}>{input}</div>
            </div>
          </div>
          <div
            className={["row", classes.main_filter_container_model_one, !this.state.toggleFilters ? classes.hidden : null].join(
              " "
            )}
          >
            <div className={["col", classes.no_col_padding_model_one].join(" ")}>{this.modelOne()}</div>
          </div>
        </div>
      </div>
    );
  };

  modelOne = () => {
    const form_filters = this.state.form_filters;
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              tops: form_filters.tops.value,
              anios: form_filters.anios.value
            }}
            render={({
              optionsTop = this.state.topsValores ? this.state.topsValores : [],
              optionsAnios = this.state.anios ? this.state.anios : [],
              form
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterModelOneHandler}>
                <div className="row">
                  <div className={["col-lg-6", "", "margen_derecha_15"].join(" ")}>
                    <Field
                      name="tops"
                      id="tops"
                      label=""
                      type="select"
                      elementType="select"
                      isMultiSelect={true}
                      isClearable={true}
                      campoLabel={"nombre"}
                      classes="custom-select"
                      options={optionsTop}
                      onInputChange={event => this.onInputSelectedModelOne(event, "tops")}
                      component={renderInput}
                    />
                  </div>
                  <div
                    className={["margen_top_20 col-12 col-sm-12", this.state.topSeleccionado.length > 0 ? null : "d-none"].join(
                      " "
                    )}
                  >
                    {this.state.topSeleccionado.map((top, i) => {
                      return (
                        <div key={"top_" + i} className={[classes.top, `top_${i + 1}`, classes.vertical_div].join(" ")}>
                          <div className={classes.vertical}>
                            <span className={"montse texto_12 color_blanco"}>{top.nombre}</span>
                            <span className={[classes.filter_icon_button_model_one, classes.derecha_close].join(" ")}>
                              <i
                                className="fas fa-times color_blanco"
                                onClick={() => this.onDeleteFilterModelOneHandler(top)}
                              />
                            </span>
                          </div>{" "}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={["row margen_top_20", this.state.form_filters.tops.value !== "" ? null : "d-none"].join(" ")}>
                  <div className={["col-lg-6", "", "margen_derecha_15"].join(" ")}>
                    <Field
                      name="anios"
                      id="anios"
                      label=""
                      value={this.state.form_filters.anios.value}
                      campoLabel={"nombre"}
                      type="select"
                      elementType="select"
                      isSingle={true}
                      isClearable={false}
                      classes="custom-select"
                      options={optionsAnios}
                      onInputChange={event => this.onInputSelectedModelOne(event, "anios")}
                      component={renderInput}
                    />
                  </div>
                  <div
                    className={["margen_top_20 col-12 col-sm-12", this.state.form_filters.anios.value ? null : "d-none"].join(
                      " "
                    )}
                  >
                    <div className={[classes.top, classes.top_fecha, classes.vertical_div].join(" ")}>
                      <div className={classes.vertical}>
                        <span className={"montse texto_12 color_blanco"}>{this.state.form_filters.anios.value.nombre}</span>
                        <span className={[classes.filter_icon_button_model_one, classes.derecha_close].join(" ")}>
                          <i
                            className="fas fa-times color_blanco"
                            onClick={() => this.onDeleteFilterModelOneHandler(this.state.form_filters.anios.value.id)}
                          />
                        </span>
                      </div>{" "}
                    </div>
                  </div>
                </div>

                {/* ======================= */}

                <hr />
                {this.sectionButtonsModelOne(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonsModelOne = form => {
    const disabledButton = !this.state.form_filters.errorDate ? false : true;
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12  col-lg-4 offset-lg-4 text-center">
          <button
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            disabled={disabledButton}
            onClick={this.onToggleFilters}
          >
            {getLan() === "es" ? "Cerrar Filtros" : "Close"}
          </button>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center">
          <button
            type="button"
            className={["btn", classes.button_model_one, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilterModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? " Limpiar filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterModelOneHandler = event => {
    event.preventDefault();
  };

  onSubmitSearchModelOneHandler = () => {};

  onInputChangeModelOne = event => {
    const value = event.target.value;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;
    if (value !== "") {
      this.props.onFilterClaves(value);
    } else {
      disabled = true;
    }
    this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
  };

  limpiaForms() {
    const form1 = { ...this.state.form_filters };
    let group1 = [...this.state.allSelelectFilters];

    form1.tops.value = "";
    form1.anios.value = "";

    const ultimoAnio = this.state.anios && this.state.anios.length ? this.state.anios.length -1 : 0;
    this.onInputSelectedModelOne(null, "tops", [{nombre: "Top 5", id: -1}]);

    group1 = group1.filter(itemd => itemd.type !== "tipAnio");
    let a = this.state.anios[ultimoAnio];
    this.onInputSelectedModelOne(null, "tipAnio", a.id);
    a.type = "tipAnio";
      form1.anios.value = a;

    group1.push(a);
    this.props.setFecha(a.id);
    
    this.props.setFecha(a.id);
    this.setState({
      allSelelectFilters: group1,
      clean: !this.state.clean,
      form_filters: form1,
      toggleInputs: false
    });
  }

  onResetFilterModelOneHandler = form => {
    form.reset();
    this.limpiaForms();
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onInputModelOneChange = event => {
    const value = event.target.value;
    const disabled = value ? false : true;
    this.setState({
      disableEnableButtons: disabled
    });
  };

  onFilterInstrumentoHandler = () => {
    this.props.onFilterenvios(null);
  };

  onFilterTipoValorHandler = (instrumento = null, tipoValor) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterDocumentos = (mantenimientoId = null, listadoId = null) => {
    const params = {
      tipoInformacion: mantenimientoId,
      tipoInformacionListado: listadoId
    };
    this.props.onFilterTipoDocumentos(params);
  };

  onCheckIfDatesAreValid = (inicio, fin) => {
    return inicio > fin;
  };

  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e])
      .map(e => arr[e]);

    return unique;
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const A = parseInt(a.id);
    const B = parseInt(b.id);

    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }
    return comparison;
  }

  onInputSelectedModelOne = (event = null, type = null, valor_inicial = null) => {
    let group1 = [...this.state.allSelelectFilters];
    let value = [];
    if (valor_inicial !== null) {
      value = valor_inicial;
    } else {
      value = event;
    }
    const form1 = { ...this.state.form_filters };

    let top5 = this.state.topSeleccionado;
    const valores = [...this.state.topsValores];
    const anios_valores = [...this.state.anios];

    if (type === "tops") {
      group1 = group1.filter(item => item.type !== "tipTops");
      let esTop5 = false;
      top5 = [];
      if (value.find(itm => itm.id === -2)) {
        esTop5 = false;
        const valores_sort = valores.sort(this.compare);
        
        valores_sort.map((valor, i) => {
            top5.push(valor);
          return null;
        });
        let values = value.concat(...top5);
        if (values.length > 5) {
          values = values.filter(itm => itm.id > 0);
        }
        let can = true;

        form1.tops.value = this.getUnique(values, "id");
        can = form1.tops.value.length > 35 ? false : true;

        form1.tops.value = can ? this.getUnique(values, "id") : this.getUnique(values, "id").slice(0, 10);
        form1.tops.value = form1.tops.value.sort(this.compare);
        this.props.setTop(form1.tops.value, form1.anios.value);
        top5 = [];
        form1.tops.value.map((valor, i) => {
          valor.type = "tipTops";
          group1.push(valor);
          top5.push(valor);
          return null;
        });

        // this.setState({ topsValores: topsValores.colores.splice(0, 1) });
      } else if (value.find(itm => itm.id === -1)) {
        esTop5 = true;
        valores.map((valor, i) => {
          if (valor.id < 6 && valor.id > 0) {
            top5.push(valor);
          }
          return null;
        });
        let values = value.concat(...top5);
        if (values.length > 5) {
          values = values.filter(itm => itm.id !== -1);
        }
        let can = true;

        form1.tops.value = this.getUnique(values, "id");
        can = form1.tops.value.length > 35 ? false : true;

        form1.tops.value = can ? this.getUnique(values, "id") : this.getUnique(values, "id").slice(0, 10);
        form1.tops.value = form1.tops.value.sort(this.compare);
        this.props.setTop(form1.tops.value, form1.anios.value);
        top5 = [];
        form1.tops.value.map((valor, i) => {
          valor.type = "tipTops";
          group1.push(valor);
          top5.push(valor);
          return null;
        });

        // this.setState({ topsValores: topsValores.colores.splice(0, 1) });
      } else {
        form1.tops.value = this.getUnique(value, "id");

        let top5Values = valores.filter(itm => itm.id < 6 && itm.id > 0);
        let existTop5 = false;
        let can = true;
        let topCount = 0;
        if (top5Values) {
          for (let index = 0; index < top5Values.length; index++) {
            let itemExist = form1.tops.value.find(item => item.id === top5Values[index].id);
            if (itemExist) {
              topCount++;
              existTop5 = true;
            }
          }
        }
        if (existTop5) {
          let values = value;
          values = values.filter(itm => itm.id !== -1);
          let values_top = this.getUnique(values, "id");
          can = values_top.length + (esTop5 ? 5 - topCount : 0) > 35 ? false : true;
          if (!can) {
            form1.tops.value.pop();
          }
          form1.tops.value = can ? this.getUnique(values, "id") : form1.tops.value;
        }
        form1.tops.value = form1.tops.value.sort(this.compare);
        this.props.setTop(form1.tops.value, form1.anios.value);

        form1.tops.value.map((valor, i) => {
          valor.type = "tipTops";
          group1.push(valor);
          top5.push(valor);
          return null;
        });
      }
      form1.tops.value = form1.tops.value.sort(this.compare);
      this.props.setTop(form1.tops.value);

    }
    if (type === "anios") {
      form1.anios.value = value;
      this.props.setFecha(value);

      const item = anios_valores.find(tipA => tipA.id === value.id);
      group1 = group1.filter(item => item.type !== "tipAnio");
      if (item) {
        item.type = "tipAnio";
        group1.push(item);
      }
    }

    this.setState({
      allSelelectFilters: group1,
      form_filters: form1,
      topSeleccionado: top5
    });
  };

  onDeleteFilterModelOneHandler = item => {
    let group1 = [...this.state.allSelelectFilters];
    const form1 = { ...this.state.form_filters };
    let top5 = this.state.topSeleccionado;

    const anios_valores = [...this.state.anios];
    if (item.type === "tipTops") {
      const valores = [...this.state.topsValores];
      const itemEncontrado = valores.find(tipv => tipv.id === item.id);
      top5 = [];
      if (itemEncontrado) {
        if (form1.tops.value === "-1") {
          group1 = group1.filter(itemd => itemd.type !== "tipTops");
        } else {
          group1 = group1.filter(itemd => itemd.id !== item.id);
          form1.tops.value = form1.tops.value.filter(itemd => itemd.id !== item.id);
        }
        form1.tops.value = form1.tops.value;
        form1.anios.value = form1.anios.value;
        this.props.setTop(form1.tops.value);
        this.props.setTop(form1.tops.value);
      }
    }
    if (item.type === "tipAnio") {
      const ultimoAnio = this.state.anios && this.state.anios.length ? this.state.anios.length -1 : 0;
      
      group1 = group1.filter(itemd => itemd.type !== "tipAnio");
      let a = this.state.anios[ultimoAnio];
      this.onInputSelectedModelOne(null, "tipAnio", a.id);
      a.type = "tipAnio";
        form1.anios.value = a;

      group1.push(a);
      this.props.setFecha(a.id);
    }

    this.setState({
      allSelelectFilters: group1,
      topSeleccionado: form1.tops.value,
      form_filters: form1
    });
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.allSelelectFilters];

    filters = (
      <div className={classes.filter_container_outside_model_one} id="allFitersContainers">
        {filterList.map(item => (
          <div className={classes.filter_container_model_one_alt} key={`${item.nombre}-${item.id}`}>
            <span className={["montse", classes.filter_text_model_one].join(" ")}>{item.nombre}</span>
            <span className={classes.filter_icon_button_model_one}>
              <i className="far fa-times-circle" onClick={() => this.onDeleteFilterModelOneHandler(item)} />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    this.setState(prevState => {
      if (!prevState.toggleInputs === false) {
      }
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => (
      <li className="list-group-item" onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}>
        {item.clave}
      </li>
    ));
  };

  onPrintOptionSelectedHandler = value => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div className={[classes.selectedOptionContainer, !this.state.toggleInputs ? classes.hidden : null].join(" ")}>
        <span className={classes.filterContainer}>
          <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span className={classes.filterIconButton} onClick={() => this.onToggleFilterInputOptionSelected(1)}>
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  render() {
    const filter = this.props.modelo ? this.formModelHandler(this.props.modelo) : null;

    return filter;
  }
}

const mapStateToProps = state => {
  return {
    total: state.informacionMercado.historicoCasaBolsaTotal,
    sic: state.informacionMercado.historicoCasaBolsaSic,
    local: state.informacionMercado.historicoCasaBolsaLocal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    oninitHistoricoTotal: () => {
      dispatch(action.initHistoricoCasaBolsaTotal());
    },
    onInitCasaLocal: () => {
      dispatch(action.initHistoricoCasaBolsaLocal());
    },
    onInitCasaSic: () => {
      dispatch(action.initHistoricoCasaBolsaSic());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterGraficaHistoricoCasaBolsa);

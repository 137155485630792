import React from "react";
import classes from "./Carousel.css";
import imgEmpty from "../../../assets/images/noticia.jpg";
import logo from "../../../assets/images/sustentable/logoAsg.png";

const createDivImage = (props, item, style, styleTraslucido) => {
  if (props.isImageBackground) {
    return (
      <div className={classes.fondo} style={style}>
        <div className={classes.fondo_traslucido} style={styleTraslucido}>
          <div className="container">
            <div className={classes.center}>
              <div
                className={"texto_70 nunito texto_blanco"}
                dangerouslySetInnerHTML={{ __html: item.texto_html }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={[classes.fondo, classes.fondoImagen].join(" ")}>
        <img src={item.imagen} alt="BIVA 360" className={classes.imagenBackground}/>
      </div>
    );
  }
};
const Carousel = (props) => {
  const indicators = props.data.map((item, i) => {
    const claseActive = i === 0 ? "active" : null;
    return (
      <li
        data-target="#carouselExampleIndicators"
        data-slide-to={i}
        className={claseActive}
      ></li>
    );
  });

  const data = props.data.map((item, i) => {
    let style = {
      backgroundImage: `url(${
        item.imagen && item.imagen !== "" ? item.imagen : imgEmpty
      })`,
      height: props.height ? props.height + "px" : "700px",
      backgroundSize: props.sizeOption ? props.sizeOption : null,
      backgroundPosition: "center",
      backgroundPositionY: props.positiony ? props.positiony + "px" : null,
    };
    let styleTraslucido = {
      height: props.height ? props.height + "px" : "700px",
    };
    const claseActive = i === 0 ? "active" : null;
    return (
      <div
        key={i + "carousel"}
        className={["carousel-item", claseActive].join(" ")}
      >
        <div className={classes.contenedor_noticia}>
          {createDivImage(props, item, style, styleTraslucido)}
        </div>
      </div>
    );
  });
  let logoAsg = null;
  if(props.isSustentable){
    logoAsg = ( <div className={classes.logoContainer}>
      <img
       src={logo}
       alt="ASG"
       className={[classes.logo].join(" ")}/>
    </div>);
  }
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol
          className={[
            "carousel-indicators",
            props.data.length > 1 ? null : "d-none",
          ].join(" ")}
        >
          {indicators}
        </ol>
        <div className="carousel-inner">{data}
        {logoAsg}
        </div>
        <a
          className={[
            "carousel-control-prev",
            classes.flechaIzq,
            props.data.length > 1 ? null : "d-none",
          ].join(" ")}
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Anterior</span>
        </a>
        <a
          className={[
            "carousel-control-next",
            classes.flechaDer,
            props.data.length > 1 ? null : "d-none",
          ].join(" ")}
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Siguiente</span>
        </a>
      </div>
    </>
  );
};

export default Carousel;

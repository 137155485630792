import React, { Component } from "react";
import classes from "./Fotos.css";

import Foto from "./foto/Foto";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  speed: 2000,
  variableWidth: true
};

class Fotos extends Component {
  render() {
    let foto = null;
    let item_fotos = [];
    let embajador = this.props.embajador ? true : false;
    

    if (this.props.fotos) {
      if (this.props.tipo === 1 && !embajador) {
        this.props.fotos.map((dato, i) => {
          //   titulo = dato.
          let es_persona = true;

          if (dato.foto === null && dato.puesto === "") {
             es_persona = false;
          }
          item_fotos.push([{ info: dato, es_persona: es_persona }]);


          return foto;
        });
        foto = item_fotos.map((info, i) => {
          return (
            <div key={i} className={[classes.grid_push_box].join(" ")}>
              <Foto
                key={i}
                persona={info[0].info}
                es_persona={info[0].es_persona}
              />
            </div>
          );
        });
      } else if (this.props.tipo === 1 && embajador) {
        this.props.fotos.map((dato, i) => {
          //   titulo = dato.
          let es_persona = false;

          if (dato.picture) {
             es_persona = true;
          }
          item_fotos.push([{ info: dato, es_persona: es_persona }]);


          return foto;
        });
        foto = item_fotos.map((info, i) => {
          return (
            <div key={i} className={[classes.grid_push_box].join(" ")}>
              <Foto
                key={i}
                persona={info[0].info}
                es_persona={info[0].es_persona}
              />
            </div>
          );
        });
      }
      
      
      else {
        this.props.fotos.map((dato, i) => {
          dato.data.map((info, i) => {
            let es_persona = false;
            if (i === 0) {
              item_fotos.push([{ info: dato.estado, es_persona: false }]);
            }
            if (typeof info === "object") {
              es_persona = true;
            }

            if (info !== "") {
              item_fotos.push([{ info: info, es_persona: es_persona, estado: dato.estado }]);
            }

            return null;
          });
          return foto;
        });

        foto = item_fotos.map((info, i) => {
          return (
            <div key={i} className={[classes.grid_push_box].join(" ")}>
              <Foto
                key={i}
                persona={info[0].info}
                es_persona={info[0].es_persona}
                estado={info[0].estado}
                tipo={this.props.tipo}
              />
            </div>
          );
        });
      }
    }

    if (this.props.tipo === 1 ) {
      return (
        <div className={["col sin_padding", classes.relativo].join(" ")}>
          <div
            className={[classes.fondo_cuadricula1, classes.at_grid].join(" ")}
          >
            {foto}
          </div>
        </div>
      );
    } else {
      return <Slider {...settings}>{foto}</Slider>;
    }
  }
}

export default Fotos;

import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import Accordion from "../../../../components/ui/accordion/Accordion";
import * as actions from "../../../../stores/actions/index";
import classes from "./InformacionPeriodicaCorporativa.css";

import Pagination from "../../../../components/ui/pagination/Pagination";
import Loader from "../../../../components/loader/Loader";

import Rss from "../../../rss/Rss";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../../stores/utils/utilities";
import FilterInformacionPeriodica from "../../../../components/ui/filterInformacionPeriodicaInstrumentosAlternativos/FilterInformacionPeriodica";
const dominio = `${window.location.protocol}//${window.location.host}`;

class InformacionPeriodicaCorporativa extends Component {
    state = {
        messages: null,
        lan: getLocal(),
        itemsPerPage: 10,
        activePage: 1,
        resourceList: [],
        loader: true,
        tipoInformacion: null,
        typeInfo: null,
        tipoDocumento: null,
        tipoInstrumento: null,
        fechaInicio: null,
        fechaFin: null,
        periodo: null,
        ejercicio: null,
        urlForRss: `${dominio}/emisoras/banco-informacion/rss`,
        emisoraSeleccionada: null,
        clave: null,
        showRssModal: false,
        wasFiltered: false
    };

    componentWillReceiveProps(nextProps) {
        let bancoInformacion = null;

        if (nextProps.bancoInformacion && nextProps.bancoInformacion.content) {
            bancoInformacion = nextProps.bancoInformacion.content;
        }
        let resource = bancoInformacion;
        this.onPageChangeHandler(resource, null, 0);
    }

    onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
        let data = resource ? resource : this.props.bancoInformacion;
        let resourceList = [];
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                resourceList: resourceList,
                itemSeleccionado: urlSeleccionado
            });
        } else if (pageNumber) {
            this.setState({
                activePage: pageNumber,
                resourceList: resourceList
            });
            this.changeHistory(
                this.state.emisoraSeleccionada,
                this.state.tipoInstrumento,
                this.state.tipoInformacion,
                this.state.typeInfo,
                this.state.tipoDocumento,
                this.state.fechaInicio,
                this.state.fechaFin,
                this.state.periodo,
                this.state.ejercicio,
                pageNumber
            );
        } else {
            this.setState({
                resourceList: resourceList
            });
        }
    };

    handlePageChange(pageNumber) {
        if (this.state.activePage !== pageNumber) {
            this.onPageChangeHandler(null, pageNumber);
        }
    }

    componentWillMount() {
        this.props.onInitIntl();
        let paramsString = queryString.parse(this.props.location.search);

        let tipoInstrumento = null;
        let tipoInformacion = null;
        let page = null;
        let type = null;
        let tipoDocumento = null;
        let fechaInicio = null;
        let fechaFin = null;
        let emisora_id = null;
        let periodo = null;
        let ejercicio = null;
        let search_field_param = paramsString["search_field"];
        if (this.props.location.search !== "" && search_field_param === undefined) {
            tipoInstrumento = paramsString["tipoInstrumentoAlternativo"] !== undefined && paramsString["tipoInstrumentoAlternativo"] !== "null" && paramsString["tipoInstrumentoAlternativo"] !== "" ? paramsString["tipoInstrumentoAlternativo"].split(",") : null;
            tipoInformacion = paramsString["tipoInformacion"] !== undefined && paramsString["tipoInformacion"] !== "null" && paramsString["tipoInformacion"] !== "" ? paramsString["tipoInformacion"].split(",") : null;
            type = paramsString["type"] === "null" || paramsString["type"] === "" ? null : paramsString["type"];
            tipoDocumento = paramsString["tipoDocumento"] === "null" || paramsString["tipoDocumento"] === "" ? null : paramsString["tipoDocumento"];
            fechaInicio = paramsString["fechaInicio"] === "null" || paramsString["fechaInicio"] === "" ? null : paramsString["fechaInicio"];
            fechaFin = paramsString["fechaFin"] === "null" || paramsString["fechaFin"] === "" ? null : paramsString["fechaFin"];
            page = paramsString["page"] === "null" || paramsString["page"] === "" ? 1 : parseInt(paramsString["page"]);
            emisora_id = paramsString["emisora_id"] === "null" || paramsString["emisora_id"] === "" ? null : paramsString["emisora_id"];
            periodo = paramsString["periodo"] === "null" || paramsString["periodo"] === "" ? null : paramsString["periodo"];
            ejercicio = paramsString["ejercicio"] === "null" || paramsString["ejercicio"] === "" ? null : paramsString["ejercicio"];
        }

        if (tipoInstrumento === null) {
            tipoInstrumento = ["1", "2", "3", "4"];
        }
        const paramsBancoInformacion = {
            size: this.state.itemsPerPage,
            page: parseInt(page) > 0 ? parseInt(page) - 1 : 0,
            emisora: emisora_id,
            tipoInstrumento: tipoInstrumento,
            tipoInformacion: tipoInformacion,
            type: type,
            tipoDocumento: tipoDocumento,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            periodo: periodo,
            ejercicio: ejercicio,
            instrumentosAlternativos: true
        };
        this.setState({
            activePage: parseInt(page) > 0 ? parseInt(page) : 1,
            emisoraSeleccionada: emisora_id,
            tipoInstrumento: tipoInstrumento,
            tipoInformacion: tipoInformacion,
            typeInfo: type,
            tipoDocumento: tipoDocumento,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            periodo: periodo,
            ejercicio: ejercicio
        });

        this.props.onInitBancoInformacion(paramsBancoInformacion);
        this.props.onInitAllTipoInformacion();
        this.props.onFetchRssinputs();
    }

    onSetTipoInformacion = tipoInformacion => {
        this.setState({
            tipoInformacion: tipoInformacion
        });
    };

    onSetType = type => {
        this.setState({
            typeInfo: type
        });
    };

    onSetTipoDocumento = tipoDocumento => {
        this.setState({
            tipoDocumento: tipoDocumento
        });
    };

    onSetTipoInstrumento = tipoInstrumento => {
        this.setState({
            tipoInstrumento: tipoInstrumento
        });
    };

    onSetFechaInicio = fecha_inicio => {
        this.setState({
            fechaInicio: fecha_inicio
        });
    };

    onSetFechafin = fecha_fin => {
        this.setState({
            fechaFin: fecha_fin
        });
    };

    onSetPeriodo = periodo => {
        this.setState({
            periodo: periodo
        });
    };

    onSetEjercicio = ejercicio => {
        this.setState({
            ejercicio: ejercicio
        });
    };

    onSetEmisora = emisora => {
        this.setState({
            emisoraSeleccionada: emisora
        });

        /* const paramsBancoInformacion = {
          size: this.state.itemsPerPage,
          page: 0,
          emisora: this.state.emisoraSeleccionada,
          tipoInstrumento: this.state.tipoInstrumento,
          tipoInformacion: this.state.tipoInformacion,
          type: this.state.typeInfo,
          tipoDocumento: this.state.tipoDocumento,
          fechaInicio: this.state.fechaInicio,
          fechaFin: this.state.fechaFin,
          periodo: this.state.periodo,
          ejercicio: this.state.ejercicio
        };
    
        this.props.onInitBancoInformacion(paramsBancoInformacion); */
    };
    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages
                });
            }
        }
        if (this.props.bancoInformacion !== prevProps.bancoInformacion) {
            this.setState({
                loader: false
            });
        }
    }
    onWasFiltered = filtered => {
        this.setState({
            wasFiltered: filtered
        });
    };

    onSetPage = page => {
        this.setState({
            activePage: page
        });
    };

    onSetFilters = ({
        emisora,
        tipoInstrumento,
        tipoInformacion,
        type,
        tipoDocumento,
        fechaInicio,
        fechaFin,
        periodo,
        ejercicio,
        page,
        reset = false
    }) => {
        this.setState({
            emisoraSeleccionada: emisora ? emisora : null,
            tipoInstrumento: tipoInstrumento ? tipoInstrumento : null,
            // tipoInstrumento: ["2", "3"],
            tipoInformacion: tipoInformacion ? tipoInformacion : null,
            typeInfo: type ? type : null,
            tipoDocumento: tipoDocumento ? tipoDocumento : null,
            fechaInicio: fechaInicio ? fechaInicio : null,
            fechaFin: fechaFin ? fechaFin : null,
            periodo: periodo ? periodo : null,
            activePage: page > 0 ? page : 1,
            ejercicio: ejercicio ? ejercicio : null
        });
        if (reset) {
            this.changeHistoryReset();
        } else {
            this.changeHistory(
                emisora,
                tipoInstrumento,
                // ["2", "3"],
                tipoInformacion,
                type,
                tipoDocumento,
                fechaInicio,
                fechaFin,
                periodo,
                ejercicio,
                page > 0 ? page : 1
            );
        }
    };

    changeHistory(
        emisora = null,
        tipoInstrumento = null,
        tipoInformacion = null,
        type = null,
        tipoDocumento = null,
        fechaInicio = null,
        fechaFin = null,
        periodo = null,
        ejercicio = null,
        page = 1
    ) {
        this.props.history.push(
            `?tipoInstrumentoAlternativo=${tipoInstrumento}&tipoInformacion=${tipoInformacion}&type=${type}&tipoDocumento=${tipoDocumento}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&emisora_id=${emisora}&periodo=${periodo}&ejercicio=${ejercicio}&page=${page ? page : this.state.activePage}`
        );
        this.setState({ loader: true });
        let params = {
            size: this.state.itemsPerPage,
            page: page > 0 ? page - 1 : 0,
            emisora: emisora,
            tipoInstrumento: tipoInstrumento && tipoInstrumento.length > 0 ? tipoInstrumento : ["1","2","3","4"],
            tipoInformacion: tipoInformacion,
            type: type,
            tipoDocumento: tipoDocumento,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            periodo: periodo,
            ejercicio: ejercicio,
            instrumentosAlternativos: true
        };
        this.props.onInitBancoInformacion(params);
    }

    changeHistoryReset() {
        this.props.history.push(
            `?tipoInstrumentoAlternativo=${null}&tipoInformacion=${null}&type=${null}&tipoDocumento=${null}&fechaInicio=${null}&fechaFin=${null}&emisora_id=${null}&periodo=${null}&ejercicio=${null}&page=${this.state.activePage
            }`
        );
        this.setState({
            loader: true
        });

        let params = {
            size: this.state.itemsPerPage,
            page: 0,
            emisora: null,
            // tipoInstrumento: null,
            tipoInstrumento: ["1","2","3","4"],
            tipoInformacion: null,
            type: null,
            tipoDocumento: null,
            fechaInicio: null,
            fechaFin: null,
            periodo: null,
            ejercicio: null,
            instrumentosAlternativos: true
        };
        this.props.onInitBancoInformacion(params);
        /*   this.setState({
            indexSelected: 0,
            indexSelectedInicial: 0
          }); */
    }
    onShowRssModalHandler = () => {
        this.setState(prevState => {
            return {
                showRssModal: !prevState.showRssModal
            };
        });
    };

    render() {
        let loader = null;
        if (this.state.loader) {
            loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
        }

        let contenidoMostrar = null;
        let bancoInformacion = null;

        const numRows = this.props.bancoInformacion ? this.props.bancoInformacion.totalElements : 0;

        if (this.props.bancoInformacion && this.props.bancoInformacion.content) {
            bancoInformacion = this.props.bancoInformacion.content;

            contenidoMostrar = (
                <React.Fragment>
                    <Accordion datos={bancoInformacion} nombre="emisoras" icon={""} tipo={13} key={+new Date()}/>

                    <Pagination
                        claseTipo={2}
                        numRows={numRows}
                        paginas={5}
                        itemsPerPage={this.state.itemsPerPage}
                        handlePageChange={this.handlePageChange.bind(this)}
                        activePage={this.state.activePage}
                    />
                </React.Fragment>
            );
        } else {
            if (this.state.wasFiltered) {
                contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
            }
        }
        let urlForRss = this.state.urlForRss;
        if (this.state.emisoraSeleccionada) {
            urlForRss = urlForRss + `?empresa=${this.state.emisoraSeleccionada}`;
        } if (this.state.tipoInstrumento) {
            urlForRss = urlForRss.includes("?") ? urlForRss + `&tipoInstrumentoAlternativo=${this.state.tipoInstrumento}` : urlForRss + `?tipoInstrumentoAlternativo=${this.state.tipoInstrumento}`;
        }
        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages}>
                <React.Fragment>
                    {loader}

                    <h1 className={"texto_42 nunito regular"}>
                        <FormattedHTMLMessage id="empresas.instrumentosalternativos.informacionperiodicacorporativa.titulo" defaultMessage=" " />
                    </h1>
                    <div className="margen_50 texto_16 montse regular">
                        <FormattedHTMLMessage id="empresas.instrumentosalternativos.informacionperiodicacorporativa.descripcion" defaultMessage=" " />
                    </div>
                    <br />
                    <div className="row">
                        <div className="col sin_padding">
                            <FilterInformacionPeriodica
                                modelo="modelo_1"
                                itemsPerPage={this.state.itemsPerPage}
                                setTipoInformacion={tipoInformacion => this.onSetTipoInformacion(tipoInformacion)}
                                setType={type => this.onSetType(type)}
                                setTipoDocumento={tipoDocumento => this.onSetTipoDocumento(tipoDocumento)}
                                setTipoInstrumento={tipoInstrumento => this.onSetTipoInstrumento(tipoInstrumento)}
                                setFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
                                setFechaFin={fechaFin => this.onSetFechafin(fechaFin)}
                                setPeriodo={periodo => this.onSetPeriodo(periodo)}
                                setEjercicio={ejercicio => this.onSetEjercicio(ejercicio)}
                                setPage={page => this.onSetPage(page)}
                                setEmisora={emisora => this.onSetEmisora(emisora)}
                                wasFiltered={filtered => this.onWasFiltered(filtered)}
                                setFilters={filterParams => this.onSetFilters(filterParams)}
                            />
                        </div>
                    </div>
                    <button
                        className={["btn btn-link margen_50", classes.btn_link].join(" ")}
                        type="button"
                        onClick={this.onShowRssModalHandler}
                    >
                        <div className="margen_50 texto_16 montse regular texto_naranja">
                            {getLan() === "es" ? "Suscripción RSS" : "RSS Feed"}&nbsp;
              <i className={["fas fa-rss", "Boton__icono_derecho__25mg6"].join(" ")} />
                        </div>
                    </button>
                    <Rss
                        showRssModal={this.state.showRssModal}
                        modalTitle={getLan() === "es" ? "Información periódica y corporativa" : "Corporate and periodical information"}
                        urlRss={urlForRss}
                        optionType={2}
                        rssType={4}
                        instrumentMessage={true}
                    />
                    <div className="margen_50 texto_16 montse regular color_gris">
                        <FormattedHTMLMessage id="empresas.instrumentosalternativos.informacionperiodicacorporativa.informativo" defaultMessage=" " />
                    </div>
                    <div className="margen_50">{contenidoMostrar}</div>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitIntl: () => {
            dispatch(actions.initIntl(85));
        },
        onInitBancoInformacion: params => {
            dispatch(actions.initBancoInformacion(params));
        },
        onInitAllTipoInformacion: () => {
            dispatch(actions.initAllTipoInformacion());
        },
        onFetchRssinputs: () => {
            dispatch(actions.fetchAllRssInputs(true));
        }
    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        bancoInformacion: state.bancosInformacion.bancoInformacion,
        tipos_informacion: state.tipoInformacion.tipos_informacion
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(InformacionPeriodicaCorporativa)
);

import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import SymbolOverviewWidget from "../tradingviewWidget/SymbolOverviewWidget";
import classes from "./TiempoReal.css";
import { getLan } from "../../../stores/utils/utilities";
import HeatMapWidget from "../tradingviewWidget/HeatMapWidget";

class TiempoReal extends Component {
  componentDidMount() {
    this.props.onInitIndice();
  }
  render() {
    return (
      <React.Fragment>
        <div className={["row", classes.row].join(" ")}>
          <div className={["col-12 col-sm-12 col-md-12 col-lg-6", classes.left].join(" ")}>
            <SymbolOverviewWidget symbols={[["FTSE:FTBIVA|1D|MXN"]]} locale={getLan()} hideTVFooter={true}/>
            <div className="margin_top_10">
              <HeatMapWidget dataSource="FTBIVA" locale={getLan()} hideTVFooter={false}/>
            </div>
          </div>
          <div className={["col-12 col-sm-12 col-md-12 col-lg-6", classes.right].join(" ")}>
            <SymbolOverviewWidget symbols={[["FTSE:F4GBIVA|1D|MXN"]]} locale={getLan()} hideTVFooter={true}/>
            <div className="margin_top_10">
              <HeatMapWidget dataSource="F4GBIVA" locale={getLan()} hideTVFooter={false}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIndice: () => dispatch(action.initIndice())
  };
};

const mapStateToProps = state => {
 
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TiempoReal);

import React, { Component } from "react";
import classes from "./Historico.css";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import Auxs from "../../../hoc/auxs/Auxs";
import Tabs from "../../../components/ui/tabs/Tabs";
import CasasBolsas from "./tabs/CasasBolsa";
import Biva from "./tabs/Biva";
import Boton from "../../../components/ui/boton/Boton";
import Modal from "../../../components/ui/modal/Modal";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class Historico extends Component {
  state = {
    tab: 0,
    showModal: false,
    messages: null,
    lan: getLocal(),
    tabs_informacion: [
      {
        image: "biva_v.svg",
        icon: "biva_v.svg",
        title: ""
      },
      {
        image: "casas_bolsa.svg",
        icon: "casas_bolsa.svg",
        title: ""
      }
    ]
  };
  componentDidMount() {
    this.props.onInitIntl();
    // this.props.onHistoricoBiva();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
          tabs_informacion: [
            {
              image: "biva_v.svg",
              icon: "biva_v.svg",
              title: messages["informacionmercado.historico.biva"]
            },
            {
              image: "casas_bolsa.svg",
              icon: "casas_bolsa.svg",
              title: messages["informacionmercado.historico.casasdebolsa"]
            }
          ]
        });
      }
    }
  }
  onToggleModalHandler = () => {
    this.setState(prevState => {
      return {
        showModal: !prevState.showModal
      };
    });
  };

  onChangeItemSelected = (index = 0) => {
    let tab = 0;
    tab = index;
    if (index === 0) {
      //CasasBolsas
    } else {
      //biva
    }
    this.setState({
      tab: tab
    });
  };

  render() {
    const modal = (
      <React.Fragment>
        <BackDrop show={this.state.showModal} clicked={this.onToggleModalHandler} />
        <Modal showModal={this.state.showModal} onCloseModal={this.onToggleModalHandler} area={"operations"} />
      </React.Fragment>
    );

    let tabSeleccionado = null;
    if (this.state.tab === 0) {
      tabSeleccionado = <Biva messages={this.state.messages} />;
    } else {
      tabSeleccionado = <CasasBolsas messages={this.state.messages} />;
    }

    const tabMostrar = (
      <div className="row margen_top_50">
        <div className={["col-sm-12 sin_padding_L sin_padding_R", classes.myCol].join(" ")}>
          <Tabs data={this.state.tabs_informacion} tipo={3} clicked={i => this.onChangeItemSelected(i)} />
        </div>
      </div>
    );
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <h1 className={["nunito", "texto_42"].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.historico.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
          {tabMostrar}
          {tabSeleccionado}

          <div className="row margen_top_50 margin_bottom_300">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="informacionmercado.historico.personalizado" defaultMessage=" " />
                </h2>
              </div>
            </div>

            <Boton
              onClick={this.onToggleModalHandler.bind(this)}
              tipo={4}
              texto={this.state.messages ? this.state.messages["informacionmercado.historico.personalizado.solicita"] : ""}
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>
          <div className="row margen_top_30">{/* {graficas} */}</div>
          {modal}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(48));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Historico);

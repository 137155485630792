import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";

import FilterOperacionesAvisos from "../../../components/ui/filterOperacionesAvisos/FilterOperacionesAvisos";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";

class AvisosOperaciones extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: false,
    messages: null,
    palabraClave: null,
    fechaInicio: null,
    fechaFin: null,
    wasFiltered: false,
    lan: getLocal()
  };

  componentWillMount() {
    
  }

  componentDidMount() {
    this.props.onInitIntl(3);
    let paramsString = queryString.parse(this.props.location.search);
    let keyword =
      paramsString["keyword"] !== undefined && paramsString["keyword"] !== "null"
        ? paramsString["keyword"]
        : null;
    let from =
      paramsString["from"] !== undefined && paramsString["from"] !== "null" ? paramsString["from"] : null;
    let to =
      paramsString["to"] !== undefined && paramsString["to"] !== "null" ? paramsString["to"] : null;
    let page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? paramsString["page"] : 1;


    const params = {
      size: this.state.itemsPerPage,
      page: parseInt(page) - 1,
      keyword: keyword,
      from: from,
      to: to
    };

    this.setState({
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
      palabraClave: keyword,
      fechaInicio: from,
      fechaFin: to,
    })

    this.props.onFilterOperacionesAvisos(params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    } else {
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        if (this.state.messages !== messages) {
          this.setState({
            messages: messages
          });
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let operacionesAvisos = null;

    this.setState({
      loader: false
    });
    if (nextProps.operacionesAvisos && nextProps.operacionesAvisos.content) {
      operacionesAvisos = nextProps.operacionesAvisos.content;
    }
    let resource = operacionesAvisos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.operacionesAvisos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      this.changeHistory(
        this.state.palabraClave ? this.state.palabraClave : null,
        this.state.from ? this.state.from : null,
        this.state.to ? this.state.to : null,
        pageNumber
      );

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onSetPalabraClave = palabra_calve => {
    this.setState({
      palabraClave: palabra_calve
    });
  };

  onSetFechaIncio = fecha_inicio => {
    this.setState({
      fechaInicio: fecha_inicio
    });
  };

  onSetFechaFin = fecha_fin => {
    this.setState({
      fechaFin: fecha_fin
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetWasFiltered = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered
    });
  };
  onSetParams = ({ keyword, from, to, page, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(keyword, from, to, page);
    }
  };

  changeHistory(keyword = null, from = null, to = null, page = 1) {
    this.props.history.push(`?keyword=${keyword}&from=${from}&to=${to}&page=${page}`);

    this.setState({
      loader: true
    });

    const params = {
      size: this.state.itemsPerPage,
      page: page - 1 < 0 ? 0 : page - 1,
      keyword: keyword,
      from: from,
      to: to
    };

    this.props.onFilterOperacionesAvisos(params);

    // this.props.onInitRequisitos(params);
  }

  changeHistoryReset() {
    this.props.history.push(`?keyword=${null}&from=${null}&fechaFin=${null}&page=1`);

    const params = {
      size: this.state.itemsPerPage,
      page: 0,
      keyword: null,
      from: null,
      to: null
    };

    this.props.onFilterOperacionesAvisos(params);

    // this.props.onInitRequisitos(params);
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({loader: disable})}/>;
    }
    let contenidoMostrar = null;
    let operacionesAvisos = null;
    if (this.props.operacionesAvisos && this.props.operacionesAvisos.content) {
      operacionesAvisos = this.props.operacionesAvisos.content;

      const numRows = this.props.operacionesAvisos ? this.props.operacionesAvisos.totalElements : 0;

      contenidoMostrar = (
        <React.Fragment>
          {loader}

          <Accordion datos={operacionesAvisos} nombre="operacionesAvisos" icon={""} tipo={12} subtipo={9} />

          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );
    } else if (this.state.wasFiltered) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda.": "No results found."}</p>;
    }

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={["nunito", "texto_42"].join(" ")}>
            <FormattedMessage id="avisosyreportes.operaciones.avisos.titulo" defaultMessage=" " />
          </h1>
          <br />
          <FilterOperacionesAvisos
            setPalabraClave={palabra_calve => this.onSetPalabraClave(palabra_calve)}
            setFechaInicio={fecha_inicio => this.onSetFechaIncio(fecha_inicio)}
            setFechaFin={fecha_fin => this.onSetFechaFin(fecha_fin)}
            setPage={page => this.onSetPage(page)}
            setWasFiltered={wasFiltered => this.onSetWasFiltered(wasFiltered)}
            setParams={filterParams => this.onSetParams(filterParams)}
          />
          <div className="margen_top_30 margin_bottom_200">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFilterOperacionesAvisos: params => {
      dispatch(actions.filterOperacionesAvisos(params));
    },
    onInitIntl: pagina => {
      dispatch(actions.initIntl(pagina));
    }
  };
};

const mapStateToProps = state => {
  return {
    operacionesAvisos: state.avisosReportes.operaciones_avisos,
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvisosOperaciones);

import React, { Component } from "react";
import classes from "./Foto.css";
import {getUrlDocument} from "../../../stores/utils/utilities"
class Foto extends Component {
  render() {
    let es_persona = this.props.es_persona;

    let item = null;
    if (es_persona) {
      let linkedin = "";
      let email = "";
      let claseTipo = "";
      if (this.props.tipo === 2) {
        claseTipo = [classes.at_grid_item_2, classes.at_grid_item]
      }else{
        claseTipo = [classes.at_grid_item]
      }
      if (this.props.persona.linkedinProfile) {

      linkedin = (
        <a
          className={classes.link_in}
          href={this.props.persona.linkedinProfile}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={"fab fa-linkedin"} />
          <span
            className={[classes.texto_linkedin, "texto_10 montse"].join(" ")}
          >
            {this.props.persona.linkedinUsername}
          </span>
        </a>
      );
      }

      if (this.props.persona.email) {
        email = (
          <a className={classes.link_in} href={`mailto:${this.props.persona.email}`}>
            <span
            className={[classes.texto_linkedin, "texto_10 montse"].join(" ")}
          >
            {this.props.persona.email}
          </span>
            </a>
  
  
        );
      }

      let ruta = null;
      let foto = null;
      if (this.props.persona.picture !== undefined && this.props.persona.picture !== undefined) {
        foto = this.props.persona.picture;
      }
      if (this.props.persona.foto !== undefined && this.props.persona.foto !== undefined) {
        foto = this.props.persona.foto;
      }


      ruta = getUrlDocument(foto);
      let style = {
        backgroundImage: `url(${ruta})`,
        zIndex: 0
      };
      item = (
        <div className={claseTipo.join(" ")}>
          <div>
            <div>
              <h5 className={"texto_14 nunito semi_bold"}>
                {this.props.persona.name} {this.props.persona.nombre}
              </h5>
              <h6 className={"texto_10 montse"}>
                {this.props.persona.escuela}
              </h6>
              <h6 className={"texto_10 montse"}>
                {this.props.persona.estado}
              </h6>
            </div>
            {linkedin}
            {email}
          </div>

          <div  style={style} className={[classes.imagen_persona, classes.divImagen].join(" ")} />

          {/* <img
            
            src={ruta}
            alt={this.props.persona.name}
          /> */}
        </div>
      );
    } else {
      let claseTipo = "";
      if (this.props.tipo === 2) {
        claseTipo = [classes.at_grid_item_titulo_2, classes.at_grid_item_titulo]
      }else{
        claseTipo = [classes.at_grid_item_titulo]
      }
      if (this.props.persona !== "") {
        let nombre = this.props.persona;
        if (this.props.persona.nombre !== undefined && this.props.persona.nombre !== null) {
          nombre = this.props.persona.nombre;
        }
        item = (
          <div className={claseTipo.join(" ")}>
            <div>
              <div>
                <span
                  className={[
                    classes.titulo_equipo,
                    "nunito texto_16 semi_bold"
                  ].join(" ")}
                >
                  {nombre}
                </span>
              </div>
            </div>
          </div>
        );
      }
    }

    return item;
  }
}

export default Foto;

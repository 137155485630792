import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";

import classes from "./Empresas.css";
import NotFound from "../../components/notFound/NotFound";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";

// paginas
import Emisoras from "./emisoras/Emisoras";
import BancoInformacion from "./bancoInformacion/BancoInformacion";
import AgendaDerechos from "./agendaDerechos/AgendaDerechos";
import Sic from "./sic/Sic";

import Listarse from "./listarse/Listarse";
import ProcesoListarse from "./comoListarse/procesoListado/ProcesoListado";
import Requisitos from "./comoListarse/requisitosListado/RequisitosListarse";
import FasesIpo from "./comoListarse/fasesIpo/FasesIpo";
import CambioListado from "./cambioListado/CambioListado";
import AccesoDiv from "./accesoDiv/AccesoDiv";
import Calificadoras from "./calificadoras/Calificadoras";
import RequisitosMantenimiento from "./requisitosMantenimiento/requisitosMantenimiento";
import SeguimientoSolicitudes from "./tramitesListado/seguimientoSolicitudes/SeguimientoSolicitudes";
import AvisosOfertaPublica from "./tramitesListado/avisosOfertaPublica/AvisosOfertaPublica";
import DocumentosDefinitivos from "./tramitesListado/documentosDefinitivos/DocumentosDefinitivos";

import Cuotas from "./cuotas/Cuotas";
import ProspectosColocacion from "./tramitesListado/prospectosColocacion/ProspectosColocacion";
import ParticipacionEmision from "./participacionEmision/ParticipacionEmision";
import Fondos from "./fondos/Fondos";
import ConcentradoPrecios from "./fondos/ConcentradoPrecios";
import ConcentradoPreciosHistorico from "./fondos/ConcentradoPreciosHistorico";
import InformacionCorporativa from "./fondos/InformacionCorporativa";
import ComposicionCartera from "./fondos/ComposicionCartera";
import ConcentradoPreciosAfores from "./afores/ConcentradoPreciosAfores";
import InformacionCorporativaAfores from "./afores/InformacionCorporativaAfores";
import Afores from "./afores/Afores";
import EventosRelevantes from "./eventosRelevantes/EventosRelevantes";
import DocumentMeta from "react-document-meta";
import { getLan } from "../../stores/utils/utilities";
import NuevaSeccion from "./sic/NuevaSeccion";
import AnalistaIndependiente from "./analistaIndependiente/AnalistaIndependiente";
import Emisiones from "./instrumentosAlternativos/Emisiones";
import AgendaDerechosInstrumentosAlternativos from "./agendaDerechosInstrumentosAlternativos/AgendaDerechosInstrumentosAlternativos";
import Prospectos from "./instrumentosAlternativos/prospectos/Prospectos";
import InformacionPeriodicaCorporativa from "./instrumentosAlternativos/informacionPeriodicaCorporativa/InformacionPeriodicaCorporativa";
import EventosRelevantesIA from "./instrumentosAlternativos/eventosRelevantes/EventosRelevantesIA";
import ReportesASG from "./instrumentosAlternativos/reportesASG/ReportesASG";
import Valuaciones from "./instrumentosAlternativos/valuaciones/Valuaciones";
import CostosGastosComisiones from "./instrumentosAlternativos/CostosPatrimonioDesempeno/CostosGastosComisiones";
import Patrimonio from "./instrumentosAlternativos/CostosPatrimonioDesempeno/Patrimonio";
import Desempenio from "./instrumentosAlternativos/CostosPatrimonioDesempeno/Desempenio";
import GastosAdministracionMantenimiento from "./instrumentosAlternativos/gastosAdministracionMantenimiento/GastosAdministracionMantenimiento";
import AnexoAA from "./instrumentosAlternativos/anexoAA/AnexoAA";
import Calendario from "./calendario/Calendario";

const metaEs = {
  title: "Portal BIVA - Empresas",
  description:
    "Selecciona el perfil de una emisora para consultar su información general, cotización, documentos y valores listados. Consulta los documentos enviados a BIVA por las emisoras y otros participantes del mercado de valores. Selecciona el perfil de una emisora para consultar su información general, documentos y valores listados. Anualmente verificamos que las emisoras de acciones o títulos de crédito que las representen (excepto SAPIB) cumplan con los requisitos establecidos en el Reglamento interior de BIVA. BIVA cuenta con un sistema de divulgación de información de valores (DIV) a través del cual los participantes del mercado pueden realizar sus solicitudes de trámites de listado, así como dar cumplimiento a las obligaciones de mantenimiento. Damos a conocer los instrumentos y los tiempos de respuesta para las solicitudes del listado presentadas en forma electrónica a través del sistema de divulgación de información (DIV).",
  meta: {
    name: {
      keywords: "emisoras, inscritas, emisoras inscritas, banco, banco de información, agenda de derechos, sic, participacion, emisión, calificadoras, requisitos, mantenimiento, listado, listado de valores, proceso de listado, requisitos, cuotas, fases, ipo, tramites, trámites, prospectos, colocación, avisos, ofertas, ofertas públicas, div, fondos, siefores, eventos, relevantes, concentrado, concentrado de precios, información, corporativa",
    },
  },
};

const metaEn = {
  title: "Portal BIVA - Companies",
  description:
    "Select the issuer profile to consult general information, quotation, documents and listed securities. Consult documents sent to BIVA by issuers and other market participants. Select the issuer profile to consult general information, quotation, documents and listed securities. Annually we verify that listed issuers of shares or credit instruments they represent (with the exception of Stock Investment Promotion Companies, SAPIB) comply with the listing requirements set forth by BIVA's internal regulation. BIVA has a system for the disclosure of securities' information (DIV) through which market participants make their requests for listing, as well as complying with maintenance obligations. We publish the times we take to comment on the electronic documents through the DIV system.",
  meta: {
    name: {
      keywords:
        "registered issuers, data center, corporate, action, sic, participation, issuance, rating, agencies, maintance requirements, securities listing, listing, listed, process, requirements, fees, ipo, stagesphases, switches, applications, prspectus, public, offerings, div, mutual funds, daily, prices, historical, information, siedores,relevant, events",
    },
  },
};

class Empresas extends Component {
  state = {
    menu: null,
    id_menu_activo: null,
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage: "empresas",
    };

    this.props.onInitGenericos(params);
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu,
      });
    } else {
      this.setState({
        id_menu_activo: menu,
      });
    }
  };

  render() {
    const routes = (
      <Switch>
        <Route path="/empresas" exact component={Emisoras} />
        <Route path="/empresas/emisoras_inscritas" exact component={Emisoras} />
        <Route
          path="/empresas/emisoras_inscritas/emisoras_inscritas"
          exact
          component={Emisoras}
        />
        <Route
          path="/empresas/listado_de_valores/por_que_listarse"
          exact
          component={Listarse}
        />
        <Route
          path="/empresas/emisoras_inscritas/banco_de_informacion"
          exact
          component={BancoInformacion}
        />
        <Route
          path="/empresas/emisoras_inscritas/agenda_de_derechos"
          exact
          component={AgendaDerechos}
        />
        <Route path="/empresas/emisoras_inscritas/sic" exact component={Sic} />
        <Route
          path="/empresas/emisoras_inscritas/participacion_emision"
          exact
          component={ParticipacionEmision}
        />
        <Route
          path="/empresas/listado_de_valores"
          exact
          component={ProcesoListarse}
        />
        <Route
          path="/empresas/listado_de_valores/como_listarse"
          exact
          component={ProcesoListarse}
        />
        <Route
          path="/empresas/listado_de_valores/como_listarse/proceso_de_listado"
          exact
          component={ProcesoListarse}
        />
        <Route
          path="/empresas/listado_de_valores/como_listarse/requisitos_de_listado"
          exact
          component={Requisitos}
        />
        <Route
          path="/empresas/listado_de_valores/como_listarse/fases_ipo"
          exact
          component={FasesIpo}
        />
        <Route
          path="/empresas/listado_de_valores/como_listarse/cuotas"
          exact
          component={Cuotas}
        />
        <Route
          path="/empresas/listado_de_valores/cambio_de_listado"
          exact
          component={CambioListado}
        />
        <Route
          path="/empresas/listado_de_valores/tramites_de_listado"
          exact
          component={SeguimientoSolicitudes}
        />
        <Route
          path="/empresas/listado_de_valores/tramites_de_listado/seguimiento_a_solicitudes"
          exact
          component={SeguimientoSolicitudes}
        />
        <Route
          path="/empresas/listado_de_valores/tramites_de_listado/prospectos_de_colocacion"
          exact
          component={ProspectosColocacion}
        />
        <Route
          path="/empresas/listado_de_valores/tramites_de_listado/avisos_de_ofertas_publicas"
          exact
          component={AvisosOfertaPublica}
        />
        <Route
          path="/empresas/listado_de_valores/tramites_de_listado/documentos_definitivos"
          exact
          component={DocumentosDefinitivos}
        />
        <Route
          path="/empresas/listado_de_valores/acceso_al_sistema_div"
          exact
          component={AccesoDiv}
        />
        <Route
          path="/empresas/emisoras_inscritas/calificadoras"
          exact
          component={Calificadoras}
        />
        <Route
          path="/empresas/emisoras_inscritas/analista_independiente"
          exact
          component={AnalistaIndependiente}
        />
        <Route
          path="/empresas/emisoras_inscritas/requisitos_de_mantenimiento"
          exact
          component={RequisitosMantenimiento}
        />
        <Route path="/empresas/instrumentos_alternativos/" exact component={Emisiones} />

        <Route
          path="/empresas/instrumentos_alternativos/agenda_de_derechos"
          exact
          component={AgendaDerechosInstrumentosAlternativos}
        />
        <Route
          path="/empresas/instrumentos_alternativos/prospectos"
          exact
          component={Prospectos}
        />
        <Route
          path="/empresas/instrumentos_alternativos/informacion_periodica_corporativa"
          exact
          component={InformacionPeriodicaCorporativa}
        />
        <Route
          path="/empresas/instrumentos_alternativos/eventos_relevantes"
          exact
          component={EventosRelevantesIA}
        />

        <Route
          path="/empresas/instrumentos_alternativos/reportes_asg"
          exact
          component={ReportesASG}
        />

        <Route
          path="/empresas/instrumentos_alternativos/valuaciones"
          exact
          component={Valuaciones}
        />

        <Route
          path="/empresas/instrumentos_alternativos/gastos_administracion_mantenimiento"
          exact
          component={GastosAdministracionMantenimiento}
        />

        <Route
          path="/empresas/instrumentos_alternativos/anexo_aa"
          exact
          component={AnexoAA}
        />
        <Route
          path="/empresas/instrumentos_alternativos/costos-gastos-y-comisiones"
          exact
          component={CostosGastosComisiones}
        />
        <Route
          path="/empresas/instrumentos_alternativos/patrimonio-del-fideicomiso"
          exact
          component={Patrimonio}
        />
        <Route
          path="/empresas/instrumentos_alternativos/desempeno-de-los-valores-emitidos"
          exact
          component={Desempenio}
        />

        <Route path="/empresas/fondos/" exact component={Fondos} />
        <Route
          path="/empresas/fondos/concentrado_de_precios"
          exact
          component={ConcentradoPrecios}
        />
        <Route
          path="/empresas/fondos/concentrado_de_precios_historico"
          exact
          component={ConcentradoPreciosHistorico}
        />
        <Route
          path="/empresas/fondos/informacion_corporativa"
          exact
          component={InformacionCorporativa}
        />
        <Route
          path="/empresas/fondos/composicion_de_cartera"
          exact
          component={ComposicionCartera}
        />
        <Route path="/empresas/afores/" exact component={Afores} />
        <Route
          path="/empresas/afores/concentrado_de_precios"
          exact
          component={ConcentradoPreciosAfores}
        />
        <Route
          path="/empresas/afores/informacion_corporativa"
          exact
          component={InformacionCorporativaAfores}
        />
        <Route
          path="/empresas/eventos_relevantes/"
          exact
          component={EventosRelevantes}
        />
        <Route
          path="/empresas/calendario-entrega"
          exact
          component={Calendario}
        />
        <Route component={NotFound} />
      </Switch>
    );
    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>

        <div className="container">
          <div className="row">
            <div className={["d-none"].join(" ")}>
              <Sidebar
                classIcon={["fas fa-gavel"].join(" ")}
                setMenu={(menu, tipo) => this.onSetMenu(menu, tipo)}
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30 padding_bottom_200">
              <div className="row margin_bottom_30">
                <div className="col-12 sin_padding_L">
                  <div className={classes.breadcums}>
                    <p>
                      <Breadcrum
                        idPage={this.state.id_menu_activo}
                        menu={this.state.menu}
                      />
                    </p>
                  </div>
                </div>
              </div>
              {routes}
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitGenericos: (params) => {
      dispatch(action.initGenericos(params));
    },
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
  };
};

export default connect(null, mapDispatchToProps)(Empresas);

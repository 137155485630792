import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../stores/actions/index";
import LayoutGuia from "../layoutGuiaToggle/LayoutGuia";


export default function Reporteo() {
  const dispatch = useDispatch();

  const [reporteo, setReporteo] = useState([]);

  const reporteoRedux = useSelector(
    (state) => state.sustentable.reporteoAsg
  );

  useEffect(() => {
    dispatch(action.getReporteoAsg());
  }, []);

  useEffect(() => {
    if (reporteoRedux) {
      setReporteo(reporteoRedux);
    }
  }, [reporteoRedux]);

  return (
    <>
      <LayoutGuia
        hiddenToggleTitle={true}
        titulo={"Reporteo"}
        mock={reporteo}
        colores={["#1ca4c9", "#029791", "#029791", "#017830", "#4fad26"]}
        coloresBonos={["#51ad32", "#009ee2", "#176937", "#009aa8"]}
        coloresInternos={[
          "#51ad32",
          "#51ad32",
          "#51ad32",
          "#51ad32",
          "#51ad32",
          "#51ad32",
          "#51ad32"
        ]}
      />
    </>
  );
}

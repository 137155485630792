import React, { Component } from "react";
import Container from "./Container";

class CostosGastosComisiones extends Component {
   render(){
        return (
            <Container type={"costos"} location={this.props.location} history={this.props.history}/>
        );
    }
}

export default CostosGastosComisiones;
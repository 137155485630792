import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";
const initialState = {
    colocaciones: null,
    documentos: null,
    intermediarios: null,
    instrumentos:null
};

const setColocaciones = (state, action) => {
    return updateObject(state, action);
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_COLOCACIONES_SUCCESS:
            return setColocaciones(state, action);
        case actionTypes.FETCH_COLOCACIONESDOCS_SUCCESS:
            return setColocaciones(state, action);
        case actionTypes.FETCH_COLOCACIONESINTERMEDIARIOS_SUCCESS:
            return setColocaciones(state, action);
        case actionTypes.FETCH_COLOCACIONES_TIPOINSTRUMENTO_SUCCESS:
            return setColocaciones(state, action);
        default:
            return state;
    }
};

export default reducer;
import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";
import FilterInformacionCorporativaFondo from "../../../components/ui/filterInformacionCorporativaFondo/FilterInformacionCorporativaFondo";

class InformacionCorporativa extends Component {
  state = {
    indexSelected: 0,
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    claveOperadora: null,
    claveFondo: null,
    tipoDocumento: null,
    fechaInicio: null,
    fechaFin: null,
    periodo: null,
    ejercicio: null,
    wasFiltered: false,
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.infromacionCorporativa !== prevProps.infromacionCorporativa) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let claveOperadora = null;
    let claveFondo = null;
    let tipoDocumento = null;
    let page = null;
    let fechaInicio = null;
    let fechaFin = null;
    let periodo = null;
    let ejercicio = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      claveOperadora = paramsString["claveOperadora"] === "null" ? null : paramsString["claveOperadora"];
      claveFondo = paramsString["claveFondo"] === "null" ? null : paramsString["claveFondo"];
      tipoDocumento = paramsString["tipoDocumento"] !== undefined && paramsString["tipoDocumento"] !== "null"  && paramsString["tipoDocumento"] !== "" ? paramsString["tipoDocumento"].split(",") : null;
      page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
      fechaInicio = paramsString["fechaInicio"] !== undefined && paramsString["fechaInicio"] !== "null" ? paramsString["fechaInicio"] : null;
      fechaFin = paramsString["fechaFin"] !== undefined && paramsString["fechaFin"] !== "null" ? paramsString["fechaFin"] : null;
      periodo = paramsString["periodo"] !== undefined && paramsString["periodo"] !== "null" ? paramsString["periodo"] : null;
      ejercicio = paramsString["ejercicio"] !== undefined && paramsString["ejercicio"] !== "null" ? paramsString["ejercicio"] : null;
    }

    this.props.onInitInformacionCorporativa(
      this.state.itemsPerPage,
      parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      claveOperadora,
      claveFondo,
      tipoDocumento,
      fechaInicio,
      fechaFin,
      periodo,
      ejercicio
    );

    this.setState({
      claveOperadora: claveOperadora,
      claveFondo: claveFondo,
      tipoDocumento: tipoDocumento,
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      periodo:periodo,
      ejercicio: ejercicio,
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
    });
  }

  componentWillReceiveProps(nextProps) {
    let infromacionCorporativa = null;


    if (
      nextProps.infromacionCorporativa &&
      nextProps.infromacionCorporativa.content
    ) {
      infromacionCorporativa = nextProps.infromacionCorporativa.content;
    }
    let resource = infromacionCorporativa;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (
    resource = null,
    pageNumber = null,
    seleccionado = null
  ) => {
    let data = resource ? resource : this.props.infromacionCorporativa;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });

      this.changeHistory(
        this.state.claveOperadora,
        this.state.claveFondo,
        this.state.tipoDocumento,
        this.state.fechaInicio,
        this.state.fechaFin,
        this.state.periodo,
        this.state.ejercicio,
        pageNumber);

      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetClaveOperadoraHandler = claveOperadora => {
    this.setState({
      claveOperadora: claveOperadora
    });
  };

  onSetClaveFondoHandler = claveFondo => {
    this.setState({
      claveFondo: claveFondo
    });
  };

  onSetTipoDocumentoHandler = tipoDocumento => {
    this.setState({
      tipoDocumento: tipoDocumento
    });
  };

  onSetFechaInicio = fechaInicio => {
    this.setState({
      fecha_inicio: fechaInicio
    });
  };

  onSetFechaFin = fechaFin => {
    this.setState({
      fecha_fin: fechaFin
    });
  };

  onSetPeriodo = periodo => {
    this.setState({
      periodo: periodo
    });
  };

  onSetEjercicio = ejercicio => {
    this.setState({
      ejercicio: ejercicio
    });
  };

  onSetWasFilteredHandler = wasFiltered => {
    this.setState({
      wasFiltered: wasFiltered,
      loader: true
    });
  };

  onSetFilters = ({
    claveOperadora = null,
    claveFondo = null,
    tipoDocumento = null,
    fechaInicio = null,
    fechaFin = null,
    periodo = null,
    ejercicio = null,
    wasFiltered = null,
    page = null,
    reset = false
  }) => {
    this.setState({
      claveOperadora: claveOperadora ? claveOperadora : null,
      claveFondo: claveFondo ? claveFondo : null,
      tipoDocumento: tipoDocumento ? tipoDocumento : null,
      fechaInicio: fechaInicio ? fechaInicio : null,
      fechaFin: fechaFin ? fechaFin : null,
      periodo: periodo || null,
      ejercicio: ejercicio || null,
      wasFiltered: wasFiltered
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claveOperadora, claveFondo, tipoDocumento, fechaInicio, fechaFin, periodo, ejercicio, page);
    }
  }

  changeHistory(claveOperadora = null, claveFondo = null, tipoDocumento = null, fechaInicio = null, fechaFin = null, periodo = null, ejercicio = null, page = null) {
    this.props.history.push(
      `?claveOperadora=${claveOperadora}&claveFondo=${claveFondo}&tipoDocumento=${tipoDocumento}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&periodo=${periodo}&ejercicio=${ejercicio}&page=${page ? page : this.state.activePage}`
    );

    this.props.onInitInformacionCorporativa(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      claveOperadora,
      claveFondo,
      tipoDocumento,
      fechaInicio,
      fechaFin,
      periodo,
      ejercicio
    );
  }

  changeHistoryReset() {
    this.props.history.push(
      `?claveOperadora=${null}&claveFondo=${null}&tipoDocumento=${null}&fechaInicio=${null}&fechaFin=${null}}&periodo=${null}&ejercicio=${null}&page=1`
    );
    this.props.onInitInformacionCorporativa(
      this.state.itemsPerPage,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  render() {
    let contenidoMostrar = null;
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let dataToShow = null;
    let requisitos_result =
      this.props.infromacionCorporativa &&
        this.props.infromacionCorporativa.content
        ? this.props.infromacionCorporativa.content
        : [];
    if (requisitos_result.length > 0) {
      dataToShow = (
        <Accordion
          datos={requisitos_result}
          nombre="infromacionCorporativa"
          icon={""}
          tipo={18}
          subtipo={3}
          fondo={true}
        />
      );
    } else if (requisitos_result.length === 0 && this.state.wasFiltered) {
      dataToShow = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    } else if (requisitos_result.length === 0) {
      dataToShow = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }

    const numRows = this.props.infromacionCorporativa
      ? this.props.infromacionCorporativa.totalElements
      : 0;

    const infromacionCorporativa = (
      <React.Fragment>
        {loader}

        {dataToShow}
        <Pagination
          claseTipo={2}
          numRows={numRows}
          paginas={5}
          itemsPerPage={this.state.itemsPerPage}
          handlePageChange={this.handlePageChange.bind(this)}
          activePage={this.state.activePage}
        />
      </React.Fragment>
    );
    contenidoMostrar = (
      <div className="margen_50">{infromacionCorporativa}</div>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <h1 className={"texto_42 nunito regular"}>
            <FormattedHTMLMessage id="empresas.fondos.informacioncorporativa.titulo1" defaultMessage=" " /></h1>
          <div className="margen_50 texto_16 montse regular">
            <FormattedHTMLMessage id="empresas.fondos.informacioncorporativa.introduccion1" defaultMessage=" " />
          </div>
          <br />
          <FilterInformacionCorporativaFondo
            itemsPerPage={this.state.itemsPerPage}
            setPage={page => this.onSetPage(page)}
            onSetClaveOperadora={claveOperadora =>
              this.onSetClaveOperadoraHandler(claveOperadora)
            }
            onSetClaveFondo={claveFondo =>
              this.onSetClaveFondoHandler(claveFondo)
            }
            onSetTipoDocumento={tipoDocumento =>
              this.onSetTipoDocumentoHandler(tipoDocumento)
            }
            onSetFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
            onSetFechaFin={fechaFin => this.onSetFechaFin(fechaFin)}
            onSetPeriodo={periodo => this.onSetPeriodo(periodo)}
            onSetEjercicio={ejercicio => this.onSetEjercicio(ejercicio)}
            onSetWasFiltered={wasFiltered =>
              this.onSetWasFilteredHandler(wasFiltered)
            }
            setFilters={filterParams => this.onSetFilters(filterParams)}
          />
          <br />
          <div className="margen_50">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitInformacionCorporativa: (size, page, claveOperadora, claveFondo, tipoDocumento, fechaInicio, fechaFin, periodo, ejercicio) => {
      dispatch(actions.initFondosInformacionCorporativa(size, page,  claveOperadora, claveFondo, tipoDocumento, fechaInicio, fechaFin, periodo, ejercicio));
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(71));
    }
  };
};

const mapStateToProps = state => {
  return {
    infromacionCorporativa: state.fondos.informacion,
    diccionario: state.intl.diccionario
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformacionCorporativa);

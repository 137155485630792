import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import styles from "./SymbolOverviewWidget.css";

function SymbolOverviewWidget(props) {
    const container = useRef();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
        "symbols": ${JSON.stringify(props.symbols)},
        "chartOnly": false,
        "width": "100%",
        "height": "100%",
        "locale": ${JSON.stringify(props.locale)},
        "colorTheme": "light",
        "autosize": true,
        "showVolume": true,
        "showMA": false,
        "hideDateRanges": false,
        "hideMarketStatus": false,
        "hideSymbolLogo": false,
        "scalePosition": "right",
        "scaleMode": "Normal",
        "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
        "fontSize": "12",
        "noTimeScale": false,
        "valuesTracking": "1",
        "changeMode": "price-and-percent",
        "chartType": "area",
        "maLineColor": "#2962FF",
        "maLineWidth": 1,
        "maLength": 9,
        "lineWidth": 2,
        "lineType": 0,
        "dateRanges": [
            "1d|1",
            "1m|30",
            "3m|60",
            "12m|1D",
            "60m|1W",
            "all|1M"
        ],
        "customer": "bivacom"
        }`;
        container.current.appendChild(script);
    }, []
    );

    return (
        <div className={["tradingview-widget-container", styles.widget].join(" ")} ref={container}>
            <div className="tradingview-widget-container__widget"></div>
            {!props.hideTVFooter ? 
                <div className="tradingview-widget-copyright"><a href="https://es.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Siga los mercados en TradingView</span></a></div>
                : null
            }
        </div>
    );
}

SymbolOverviewWidget.propTypes = {
    symbols: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    locale: PropTypes.string,
    hideTVFooter: PropTypes.bool
}
 //symbols expected format ex.
//   [
//     [
//       "FTSE:FTBIVA|1D"
//     ],...
//   ]

export default memo(SymbolOverviewWidget);
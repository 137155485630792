import React, { Component } from "react";
import classes from "./Loader.css";
import biva_logo from "../../assets/gif/biva.gif";

class Loader extends Component {
  state = {
    intervalId: null
  };

  constructor() {
    super();
    this.timer = this.timer.bind(this);
  }
  componentDidMount() {
    var time = this.props.time ? this.props.time : 5000; 
    var intervalId = setInterval(this.timer, time);
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }
  timer() {
    this.props.loaderDisable(false);
  }
  render() {
    return (
      <div className={[classes.fondo, classes.vertical_div].join(" ")}>
        <div className={[classes.centrado, classes.vertical].join(" ")}>
          <div className={[classes.centrado_img, "text-center"].join(" ")}>
            <img src={biva_logo} alt="logo" className={classes.img} />
          </div>
        </div>
      </div>
    );
  }
}
export default Loader;

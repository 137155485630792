import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
let base_url = `${dominio}/emisoras/empresas`;

const setPeriodos = data => {
  return {
    type: actionTypes.FETCH_PERIODO_SUCCESS,
    periodos: data
  };
};

export const filterPeriodo = ({
  empresaId,
  tipoInformacion,
  tipoDocumentos,
  tipo
}) => {
  let url = `${base_url}/${empresaId}/periodo`;

  url = tipoInformacion
    ? `${url}?${
        tipo === "listado" ? "tipoInformacionListado" : "tipoInformacion"
      }=${tipoInformacion}`
    : url;
  url = tipoDocumentos
    ? `${url}${tipoInformacion ? "&" : "?"}${
        tipo === "listado" ? "tipoDocumentoListado" : "tipoDocumento"
      }=${[...tipoDocumentos]}`
    : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPeriodos(response.data));
      })
      .catch(err => console.log(err));
  };
};

const setEjercicios = data => {
  return {
    type: actionTypes.FETCH_EJERCICIO_SUCCESS,
    ejercicios: data
  };
};

export const filterEjercicio = ({
  empresaId,
  tipoInformacion,
  tipoDocumentos,
  tipo
}) => {
  let url = `${base_url}/${empresaId}/ejercicio`;

  url = tipoInformacion
    ? `${url}?${
        tipo === "listado" ? "tipoInformacionListado" : "tipoInformacion"
      }=${tipoInformacion}`
    : url;

  url = tipoDocumentos
    ? `${url}&${
        tipo === "listado" ? "tipoDocumentoListado" : "tipoDocumento"
      }=${[...tipoDocumentos]}`
    : url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEjercicios(response.data));
      })
      .catch(err => console.log(err));
  };
};

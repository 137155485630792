import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../stores/actions/index";
import classes from "./Introduccion.css";
import Carousel from "../../../components/ui/carouselBanner/Carousel";
import { formatDecimal, getLan, getLocal, getUrlDocument } from "../../../stores/utils/utilities";
import Charts from "../../../components/ui/charts/Charts";
import { defineMessages, IntlProvider } from "react-intl";
import { NavLink } from "react-router-dom";
import Boton from "../../../components/ui/boton/Boton";
import hojaVerde from "../../../assets/icons/sustentable/hoja-verde.png";

const lan = getLocal();
const paintGrapHandler = (
  indice,
  width = null,
  height = null,
  titulo = null
) => {
  let dataChart = [];
  let timeSeries = indice.timeSeries;
  for (let key in timeSeries) {
    const date = new Date(timeSeries[key].dateInMillis);
    dataChart.push([date, parseFloat(timeSeries[key].close)]);
  }

  dataChart = dataChart.reverse();
  if (dataChart.length === 0) {
    dataChart.push([new Date(), parseFloat(indice.lastPrice)]);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-3">
          <div className={["row", classes.price].join(" ")}>
            <div className="col-12 col-lg-12">
              <div className={classes.titulo_ftse_contenedor}>
                <div className={""}>
                  <span
                    className={[
                      "nunito bold row",
                      "texto_blanco",
                      classes.ftse_titulo,
                      classes.titulo_indices,
                    ].join(" ")}
                  >
                    {titulo}
                  </span>
                  <span
                    className={[
                      "montse row",
                      "texto_azul",
                      classes.ftse,
                      classes.titulo_indices,
                    ].join(" ")}
                  >
                    {formatDecimal(indice.lastPrice)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-9">
          {dataChart && dataChart.length > 0 ? (
            <Charts
              key={+new Date()}
              data={dataChart}
              width={width ? width : "190px"}
              height={height ? height : "120px"}
              chartType="LineChart"
              onlyClose={true}
              colorLabels={"#FFF"}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
const Introduccion = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.initIndice());
    dispatch(actions.getCitaMaria());
    dispatch(actions.getBannerBibliotecaAsg());
    dispatch(actions.getBannerAsg());
    dispatch(actions.getTeamSg());
    dispatch(actions.getDataSheetInformacionGeneral());
    dispatch(actions.initIntl(97));
  }, []);

  const [indices, setIndices] = useState(null);
  const [messages, setMessages] = useState(null);
  const [cita, setCita] = useState(null);
  const [bibliotecaBanner, setBibliotecaBanner] = useState([]);
  const [banner, setBanner] = useState([]);
  const [team, setTeam] = useState([]);
  const [dataSheet, setDataSheet] = useState(false);

  const indicesRedux = useSelector((state) => state.indice.indices);
  const traduccionRedux = useSelector((state) => state.intl.diccionario);
  const bannerRedux = useSelector((state) => state.bivaSustentable.banner);
  const teamRedux = useSelector((state) => state.bivaSustentable.team);
  const dataSheetRedux = useSelector((state) => state.documentos.dataSheet);
  const citaMariaRedux = useSelector(
    (state) => state.bivaSustentable.cita_maria
  );
  const bannerBibliotecaAsgRedux = useSelector(
    (state) => state.bivaSustentable.bannerBiblioteca
  );

  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);
  useEffect(() => {
    if (indicesRedux) {
      let indiceSelected = null;
      const FTSE4Good = 1;
      if (indicesRedux.length > 0) {
        indiceSelected = indicesRedux[FTSE4Good];
      }
      setIndices(indiceSelected);
    }
  }, [indicesRedux]);
  useEffect(() => {
    if (teamRedux) {
      let teamResponse = [];
      if (teamRedux.length > 0) {
        teamResponse = teamRedux;
      }
      setTeam(teamResponse);
    }
  }, [teamRedux]);
  useEffect(() => {
    if (citaMariaRedux) {
      setCita(citaMariaRedux[0]);
    }
  }, [citaMariaRedux]);
  useEffect(() => {
    if (bannerBibliotecaAsgRedux) {
      setBibliotecaBanner(bannerBibliotecaAsgRedux);
    }
  }, [bannerBibliotecaAsgRedux]);
  useEffect(() => {
    if (bannerRedux) {
      setBanner(bannerRedux);
    }
  }, [bannerRedux]);
  useEffect(() => {
    if (dataSheetRedux) {
      setDataSheet(dataSheetRedux);
    }
  }, [dataSheetRedux]);

  let botones = null;
  if (dataSheet) {
    botones = dataSheet.map((dataSheet, i) => {
      let isCMS = false;
      let ruta = getUrlDocument(dataSheet.url);
      if (ruta.includes("/cms/")) {
        if (ruta.includes("?")) {
          ruta = `${ruta}&download=true`;
          isCMS = true;
        } else {
          ruta = `${ruta}?download=true`;
          isCMS = true;
        }
      }
      return (
        <Boton key={dataSheet.id}
          tipo={isCMS ? 6 : 1}
          texto={dataSheet.title}
          icono_derecho="fas fa-long-arrow-alt-right"
          ruta={ruta}
          colorBoton={i % 2 ? "blanco_verde" : "verde"}
        />
      );
    });
  }

  return (
    <IntlProvider locale={lan} messages={messages}>
      <>
        <div className={[classes.fondo].join(" ")}>
          <Carousel data={banner} height={700} isImageBackground={true} isSustentable={true}/>
        </div>
        <div className={[classes.fondoIndice].join(" ")}>
          <div className={classes.container_index}>
            {indices
              ? paintGrapHandler(
                indices,
                "100%",
                "350px",
                messages ? messages["bivasg.indice.titulo"] : null
              )
              : null}
          </div>
        </div>
        <div className={"container"}>
          <div className="col-12 margen_top_50 sin_padding_L">
            <div
                className={[
                  "main_subtitle",
                  "nunito",
                  "containerTituloSg",
                  "sin_padding"
                ].join(" ")}
              >
                <img
                  src={hojaVerde}
                  alt="bivasg"
                  className={["slash", "imgSg"].join(" ")}
                />
                <h2 className={"tituloSg nunito texto_30 bold texto_verde_oscuro"}>{messages ? messages[`bivasg.indice.mayorinformacion`] : null}</h2>
            </div>
          </div>
          <div className="row margen_top_30">{botones}</div>
        </div>
        <div className={[classes.fondoMaria, "relativo"].join(" ")}>
          <div className={["container", classes.fondoMaria].join(" ")}>
            <div className={["row", classes.fondoMaria].join(" ")}>
              <div className={["col-12 col-lg-6"].join(" ")}>
                <div className={"relativo"}>
                  <div
                    className={[
                      "nunito text-center",
                      classes.cita,
                      classes.centrar,
                    ].join(" ")}
                    dangerouslySetInnerHTML={{
                      __html: cita ? cita.texto_html : null,
                    }}
                  />
                </div>
              </div>
              <div className={["col-12 col-lg-6 text-center"].join(" ")}>
                <div className="relativo">
                  <div className={classes.centrar}>
                    <img src={cita ? cita.imagen : null} alt="Maria Ariza" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={[classes.fondo, classes.imagenArbol].join(" ")}>
          <div className={classes.fondo_traslucido}>
            <div className={"row"}>
              <div
                className={[
                  "col-12 col-md-6",
                  classes.cuadro,
                  classes.cuadroVerde,
                ].join(" ")}
              >
                <div>
                  <div
                    className={[
                      "main_subtitle",
                      "nunito",
                      "containerTituloSg",
                      "sin_padding"
                    ].join(" ")}
                  >
                    <img
                      src={hojaVerde}
                      alt="bivasg"
                      className={["slash", "imgSg"].join(" ")}
                    />
                    <h2 className={"tituloSg texto_30 montse texto_blanco"}>{messages ? messages["bivasg.titulo"] : null}</h2>
                  </div>
                </div>
                <div>
                  <span className="texto_42 nunito bold margin_top_10">
                    {messages ? messages["bivasg.vision.titulo"] : null}
                  </span>
                </div>
                <div className="">
                  <div
                    className={[
                      "texto_18 montse margin_top_10",
                      classes.textos_html,
                    ].join(" ")}
                    dangerouslySetInnerHTML={{
                      __html: messages ? messages["bivasg.vision.texto"] : null,
                    }}
                  />
                </div>
              </div>
              <div className={["col-12 col-md-6", classes.cuadro].join(" ")}>
                <div className={classes.margenCuadro}>
                  <span className="texto_42 nunito bold">
                    {messages ? messages["bivasg.enfoque.titulo"] : null}
                  </span>
                </div>
                <div className="">
                  <div
                    className={[
                      "texto_18 montse margin_top_10",
                      classes.textos_html,
                    ].join(" ")}
                    dangerouslySetInnerHTML={{
                      __html: messages
                        ? messages["bivasg.enfoque.texto"]
                        : null,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={"container"}>
              <div className={"row"}>
                <div className="col-12  offset-xl-1 col-xl-11 text-right margin_top_250">
                  <span className={["texto_blanco nunito texto_50"].join(" ")}>
                    {messages ? messages["bivasg.descanso-visual.texto"] : null}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={[classes.fondoBlanco2, "row", team.length > 0 ? null : "d-none"].join(" ")}>
          <div className={["container", classes.alto].join(" ")}>
              <div
                className={[
                  "col-12 sin_padding_L",
                ].join(" ")}
              >
                <div
                  className={[
                    "main_subtitle",
                    "texto_40 ",
                    "nunito",
                    "containerTituloSg",
                  ].join(" ")}
                >
                  <img
                    src={hojaVerde}
                    alt="bivasg"
                    className={["slash", "imgSg"].join(" ")}
                  />
                  <h1 className={"tituloSg texto_verde_oscuro nunito"}>{getLan() === "es" ? "Equipo" : "Team"}</h1>
                </div>
              </div>
            <div className={["row margin_top_30", classes.alto, classes.alto2].join(" ")}>
              {team.map((itm, i) => {
                return (
                  <div className={["col-12 col-md-6 col-lg-4", classes.user].join(" ")} key={i + "btn_intro"}>
                    <div className="col-12 text-center" >
                      <img src={itm.foto} alt={itm.nombre} className={classes.imgTeam} />
                    </div>
                    <div className={["row col-12", classes.texto_team].join(" ")}>
                      <div className="col-12">
                        <span className="nunito texto_20 bold texto_verde_oscuro">
                          {itm.nombre}
                        </span>
                      </div>
                      <div className="col-12">
                        <span className="montse texto_14">
                          {itm.puesto}
                        </span>
                      </div>
                      <div className="col-12">
                        <span className="nunito texto_14">
                          {itm.linkedinProfile}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={[classes.fondoBlanco, "row"].join(" ")}>
          <div className={["container", classes.alto].join(" ")}>
            <div className={["row", classes.alto].join(" ")}>
              {bibliotecaBanner.map((itm, i) => {
                const imgn =
                  itm.imagen && itm.imagen !== "" ? itm.imagen : null;
                return (
                  <div className="col-12 col-md-6" key={i + "btn_intro"}>
                    <NavLink to={itm.urlBoton}>
                      <div className={classes.cuadroBanner}>
                        {imgn ? <img src={imgn} alt="" /> : null}
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    </IntlProvider>
  );
};

export default Introduccion;
import React, { Component } from "react";
import { connect } from "react-redux";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import Auxs from "../../hoc/auxs/Auxs";
import * as action from "../../stores/actions/index";
import classes from "./Metrics.css";
import { getLocal } from "../../stores/utils/utilities";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class Metrics extends Component {
  state = {
    messages: null,
    lan: getLocal(),
  };

  componentDidMount(){
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
  }

  render() {
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className={classes.container}>
            <div className={classes.container_2}>
              <table className={["table", classes.table].join(" ")}>
                <thead>
                  <tr>
                    <th scope="col" colSpan="2" className={[classes.th, "montse"].join(" ")}>
                      <FormattedHTMLMessage
                        id="estadistica.latencia.titulo"
                        defaultMessage=" "
                      />
                    </th>
                  </tr>
                  <tr>
                    <th scope="col" className={[classes.th, "montse"].join(" ")}>
                      <FormattedHTMLMessage
                        id="estadistica.latencia.fix99.titulo"
                        defaultMessage=" "
                      />
                    </th>
                    <th scope="col" className={[classes.th, "montse"].join(" ")}>
                      <FormattedHTMLMessage
                        id="estadistica.latencia.ouch99.titulo"
                        defaultMessage=" "
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={[classes.td, "nunito"].join(" ")}>
                      <FormattedHTMLMessage
                        id="estadistica.latencia.fix99"
                        defaultMessage=" "
                      />
                    </td>
                    <td className={[[classes.td, "nunito"].join(" ")].join(" ")}>
                      <FormattedHTMLMessage
                        id="estadistica.latencia.ouch99"
                        defaultMessage=" "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={classes.container_2}>
              <table className={["table", classes.table_last].join(" ")}>
                <tbody>
                  <tr>
                    <td className={[classes.th, "montse"].join(" ")}>
                      <FormattedHTMLMessage
                        id="estadistica.disponibilidad.titulo"
                        defaultMessage=" "
                      />
                    </td>
                    <td className={[classes.td, "nunito"].join(" ")}>
                      {" "}
                      <FormattedHTMLMessage
                        id="estadistica.disponibilidad"
                        defaultMessage=" "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={classes.container_button_mapa_calor}>
              <Link to="/informacion_de_mercado/cotizacion_emisoras/mapa_de_calor" className={classes.button_mapa_calor}>
                <FormattedHTMLMessage id="home.mapacalor" defaultMessage=" " />
              </Link>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    diccionario: state.intl.diccionarioHome,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntlHome(19));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps)(Metrics);

import { updateObject } from "../utils/utilities";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  trkd: null
};

const setTrkd = (state, action) => {
  const newProperties = {
    trkd: action.trkd
}
  return updateObject(state, newProperties);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRKD_SUCCESS:
      return setTrkd(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";

import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import FilterDiccionario from "../../../components/ui/filterDiccionario/FilterDiccionario";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import hojaVerde from "../../../assets/icons/sustentable/hoja-verde-2.png";

class Diccionario extends Component {
    state = {
        messages: null,
        lan: getLocal(),
        indexSelected: 0,
        itemsPerPage: 5,
        activePage: 1,
        resourceList: [],
        loader: true,
        periodos: null,
        capital_social: null,
        filtered: false,
        palabras : []
    };

    componentWillMount() {
        this.props.onInitIntl(this.props.idTranslate);
        let paramsString = queryString.parse(this.props.location.search);

        let periodos = null;
        let capitalSocial = null;
        let page = null;
        let search_field_param = paramsString["search_field"];
        if (this.props.location.search !== "" && search_field_param === undefined) {
            periodos =
                paramsString["periodos"] === "" || paramsString["periodos"] === "null" ? null : paramsString["periodos"].split(",");
            capitalSocial =
                paramsString["capitalSocial"] === "null" || paramsString["capitalSocial"] === ""
                    ? null
                    : paramsString["capitalSocial"].split(",");
            page = paramsString["page"] === "null" ? 1 : parseInt(paramsString["page"]);
        }

        const params = {
            size: this.state.itemsPerPage,
            page: parseInt(page) > 0 ? parseInt(page) - 1 : 0,
            periodo: periodos,
            capitalSocial: capitalSocial
        };
        this.props.onInitPalabras(params, this.props.type);
        this.setState({
            periodo: periodos,
            capital_social: capitalSocial,
            activePage: parseInt(page) > 0 ? parseInt(page) : 1
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages
                });
            }
        }
        if (this.props.palabras !== prevProps.palabras) {
            const palabras = this.props.palabras.map(itm => {
                return {
                    texto_html: itm.definition,
                    titulo_encabezado: itm.word
                }
            })
            this.setState({
                loader: false,
                palabras: palabras
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let palabras = null;

        if (nextProps.palabras && nextProps.palabras.content) {
            palabras = nextProps.palabras.content;
        }
        let resource = palabras;
        this.onPageChangeHandler(resource, null, 0);
    }
    onPrintTerminosHandler = terminos => {
       return <Accordion
        datos={terminos}
        nombre="listado"
        icon={""}
        tipo={1}
        isSustentable={this.props.idTranslate === 80 ? false : true }
      />
       
    };

    onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
        let data = resource ? resource : this.props.palabras;
        let resourceList = [];
        if (data) {
            resourceList = data;
        }
        if (seleccionado) {
            const urlSeleccionado = resource[0].url;
            this.setState({
                resourceList: resourceList,
                itemSeleccionado: urlSeleccionado
            });
        } else if (pageNumber) {
            this.setState({
                activePage: pageNumber,
                resourceList: resourceList
            });
            this.changeHistory(this.state.periodos, this.state.capital_social, pageNumber);
        } else {
            this.setState({
                resourceList: resourceList
            });
        }
    };

    handlePageChange(pageNumber) {
        if (this.state.activePage !== pageNumber) {
            this.setState({
                loader: true
            });

            const params = {
                size: this.state.itemsPerPage,
                page: pageNumber - 1,
                periodo: this.state.periodos ? this.state.periodos : null,
                capitalSocial: this.state.capital_social ? this.state.capital_social : null
            };

            this.props.onInitPalabras(params, this.props.type);

            this.onPageChangeHandler(null, pageNumber);
        }
    }

    onSetPeriodosFilter = periodos => {
        this.setState({
            periodos: periodos
        });
    };

    onSetCapitalSocialFilter = capital_social => {
        this.setState({
            capital_social: capital_social
        });
    };

    onSetPage = page => {
        this.setState({
            activePage: page
        });
    };

    setWasFiltered = value => {
        this.setState({
            filtered: value
        });
    };

    onSetParams = ({ periodo, capitalSocial, page, reset = false }) => {
        this.setState({
            periodo: periodo,
            capitalSocial: capitalSocial
        });
        if (reset) {
            this.changeHistoryReset();
        } else {
            this.changeHistory(periodo, capitalSocial, page);
        }
    };

    changeHistory(periodo = null, capitalSocial = null, page = null) {
        this.props.history.push(`?periodos=${periodo}&capitalSocial=${capitalSocial}&page=${page ? page : this.state.activePage}`);

        this.setState({
            loader: true
        });
        let params = {
            size: this.state.itemsPerPage,
            page: page > 0 ? page - 1 : 0,
            periodo: periodo,
            capitalSocial: capitalSocial
        };
        this.props.onInitPalabras(params, this.props.type);
    }

    changeHistoryReset() {
        this.props.history.push(`?periodos=${null}&capitalSocial=${null}&page=1`);
        let params = {
            size: this.state.itemsPerPage,
            page: 0,
            periodo: null,
            capitalSocial: null
        };
        this.props.onInitPalabras(params, this.props.type);
    }

    render() {
        let contenidoMostrar = null;
        let loader = null;
        if (this.state.loader) {
            loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
        }

        let dataToShow = null;
        let palabras_result = this.props.palabras ? this.props.palabras : [];

        if (palabras_result.length > 0) {
            dataToShow= this.props.palabras ? this.onPrintTerminosHandler(this.state.palabras) : null;
        } else {
            dataToShow = (
                <p className="montse texto_16 regular">
                    {getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}
                </p>
            );
        }

        const numRows = this.props.palabras ? this.props.palabras.totalElements : 0;

        const palabras = (
            <React.Fragment>
                {dataToShow}
                <Pagination
                    claseTipo={2}
                    numRows={numRows}
                    paginas={5}
                    itemsPerPage={this.state.itemsPerPage}
                    handlePageChange={this.handlePageChange.bind(this)}
                    activePage={this.state.activePage}
                    isSustentable={this.props.idTranslate === 80 ? false : true }
                />
            </React.Fragment>
        );
        contenidoMostrar = <div className="margen_50">{palabras}</div>;

        const titulo = this.props.idTranslate === 80 ? 
                       ( <h1 className={"texto_42 nunito regular"}>
                        <FormattedHTMLMessage id={"diccionariofinanciero.titulo" } defaultMessage=" " />
                    </h1> ):
                        (<div
                            className={[
                            "main_subtitle",
                            "nunito",
                            "containerTituloSg",
                            "sin_padding"
                            ].join(" ")}
                            >
                            <img
                            src={hojaVerde}
                            alt="bivasg"
                            className={["slash", "imgSg"].join(" ")}
                            />
                            <h1 className={["tituloSg texto_42 nunito"].join(" ")}>
                            <FormattedHTMLMessage id={"titulo"} defaultMessage=" " />
                            </h1>
                            </div>);

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages}>
                <React.Fragment>
                    {loader}

                    {titulo}
                    <div className="margen_top_30 margin_bottom_30 texto_16 montse regular">
                        <FormattedHTMLMessage id={this.props.idTranslate === 80 ? "diccionariofinanciero.introduccion" : "introduccion"} defaultMessage=" " />
                    </div>
                    <br />
                    <FilterDiccionario
                        setPeriodosFilter={periodos => this.onSetPeriodosFilter(periodos)}
                        setCapitalSocialFilter={capital_social => this.onSetCapitalSocialFilter(capital_social)}
                        wasFiltered={value => this.setWasFiltered(value)}
                        setPage={page => this.onSetPage(page)}
                        setParams={filterParams => this.onSetParams(filterParams)}
                        type={this.props.type}
                        isSustentable={this.props.idTranslate === 80 ? false : true }
                    />
                    <div className="margen_50">{contenidoMostrar}</div>
                </React.Fragment>
            </IntlProvider>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitPalabras: (params, type) => {
            dispatch(actions.filterPalabrasDiccionario(params, type));
        },
        onInitIntl: (id) => {
            dispatch(actions.initIntl(id));
        }
    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        palabras: state.diccionario.palabras
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Diccionario)
);

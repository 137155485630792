import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  periodos: null,
  ejericicios: null
};

const setPeriodos = (state, action) => {
  return updateObject(state, action);
};

const setEjercicios = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERIODO_SUCCESS:
      return setPeriodos(state, action);
    case actionTypes.FETCH_EJERCICIO_SUCCESS:
      return setEjercicios(state, action);
    default:
      return state;
  }
};

export default reducer;

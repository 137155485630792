import React, { Component } from "react";
import classes from "./FilterPreliminares.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";
import { changeDateFormat, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isSearch = null,
  options,
  defaultValue = null,
  checked = null,
  campoLabel = null,
  campoLabel2 = null,
  selectLabel,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      checked: checked,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    campoLabel: campoLabel,
    campoLabel2: campoLabel2,
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isSearch: isSearch,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel
      ? selectLabel
      : getLan() === "es"
      ? "Seleccione una opción"
      : "Select an option",
  };
  return (
    <div className="form-group">
      <label className={["montse", "texto_14", "regular"].join(" ")}>
        {label}
      </label>
      <Input config={params} />
    </div>
  );
};

class FilterPreliminares extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    esconderAutoComplete: false,
    toggleFilters: false,
    disableEnableButtons: true,
    selectedFilters: {
      clave_emisora: [],
      nombre_emisora: [],
      tipo_valor: [],
      tipo_instrumento: [],
    },
    form_filters: {
      clave_emisora: {
        value: "",
      },
      nombre_emisora: {
        value: "",
      },
      tipo_valor: {
        value: "",
      },
      tipo: null,
      errorDate: false,
    },
    form_clave: {
      clave: {
        value: "",
      },
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    clave: null,
    tipoValor: [],
    emisoras: [],
    emisorasNombre: [],
  };

  componentDidMount() {
    let paramsString = queryString.parse(this.props.location.search);

    let emisora =
      paramsString["emisora_id"] !== undefined &&
      paramsString["emisora_id"] !== "null"
        ? paramsString["emisora_id"]
        : null;
    let clave =
      paramsString["clave"] !== undefined && paramsString["clave"] !== "null"
        ? paramsString["clave"]
        : null;
    if (clave) {
      this.setState({
        toggleInputs: true,
        toggleFilters: false,
        disableEnableButtons: false,
        typeClaveSelected: emisora ? 1 : 0,
        valueClave: clave,
      });
    }
    window.addEventListener("click", (e) => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    window.addEventListener("click", (e) => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.emisorasNombre !== this.props.emisorasNombre) {
      let emisoras = [];
      const selectedFilters = this.state.selectedFilters;
      const form = this.state.form_filters;
      if (this.props.emisorasNombre.length > 0) {
        emisoras = this.props.emisorasNombre.map((e, i) => {
          return { id: i + 1, nombre: e };
        });
        if (selectedFilters.nombre_emisora.length > 0) {
          const nombreEmisoraSelected =
            selectedFilters.nombre_emisora.length > 0
              ? selectedFilters.nombre_emisora[0].value
              : null;
          const nombreFilter = emisoras.find(
            (e) => e.nombre === nombreEmisoraSelected
          );
          if (nombreFilter) {
            form.nombre_emisora.value = nombreFilter.id;
          }
        }
        if (form.nombre_emisora.value === 1 && emisoras.length === 1) {
          selectedFilters.nombre_emisora = this.onChangeFilterHandler(
            emisoras[0].nombre,
            getLan() === "es"
              ? "Emisora: " + emisoras[0].nombre
              : "Corporate name: " + emisoras[0].nombre,
            2
          );
        }
      }
      this.setState({
        emisorasNombre: emisoras,
        form: form,
      });
    }
    if (prevProps.emisoras !== this.props.emisoras) {
      let emisoras = [];
      const selectedFilters = this.state.selectedFilters;
      const form = this.state.form_filters;
      if (this.props.emisoras.length > 0) {
        emisoras = this.props.emisoras.map((e, i) => {
          return { id: i + 1, clave: e };
        });
        if (selectedFilters.clave_emisora.length > 0) {
          const emisoraExist = emisoras.find(e => e.clave === selectedFilters.clave_emisora[0].value);
          if(emisoraExist){
            form.clave_emisora.value = emisoraExist.id;
          }
          
        }
      }
      this.setState({ emisoras: emisoras, form_filters: form });
    }
  }

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible,
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible,
    });
  }

  componentWillMount() {
    this.props.onInitEmisorasClave();
    this.props.onInitEmisorasNombre();
    this.props.onInitValores(null, null);

    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  printFrameHandler = () => {
    const input = this.state.toggleFilters
      ? this.showFiltersSelectedHandler()
      : this.showInputHandler();
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div
              className={[
                "col",
                "col-lg-3",
                "text-center",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div
                className={[
                  "texto_btn_cerrar",
                  classes.toggle_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_borders_filter_button_model_one
                    : null,
                ].join(" ")}
                onClick={
                  this.state.disableEnableButtons ? this.onToggleFilters : null
                }
              >
                <button
                  type="button"
                  className="btn"
                  disabled={!this.state.disableEnableButtons}
                >
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar Filtros"
                        : "Close"
                      : getLan() === "es"
                      ? "Filtrar"
                      : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={[
                "col",
                this.state.toggleFilters ? "col-lg-9" : "col-lg-8",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div className={classes.filters_selected_container_model_one}>
                {input}
              </div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col",
                "col-lg-1",
                "text-center",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_border_search_button_model_one
                    : null,
                ].join(" ")}
              >
                <button type="buttom" className={["btn"].join(" ")} disabled>
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={[
              "row",
              classes.main_filter_container_model_one,
              !this.state.toggleFilters ? classes.hidden : null,
            ].join(" ")}
          >
            <div
              className={["col", classes.no_col_padding_model_one].join(" ")}
            >
              {this.printModel()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  printModel = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              clave_emisora: this.state.form_filters.clave_emisora.value,
              nombre_emisora: this.state.form_filters.nombre_emisora.value,
              tipo_valor: this.state.form_filters.tipo_valor.value,
            }}
            render={({
              optionsClavesEmisoras = this.state.emisoras
                ? this.state.emisoras
                : [],
              optionsNombreEmisoras = this.state.emisorasNombre
                ? this.state.emisorasNombre
                : [],
              optionsValores = this.props.valores ? this.props.valores : [],
              form,
            }) => (
              <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                <div className="row">
                  <div className="col col-lg-4">
                    <Field
                      name="clave_emisora"
                      id="clave_emisora"
                      label={
                        getLan() === "es" ? "Clave de la emisora" : "Ticker"
                      }
                      type="select"
                      elementType="select"
                      isSearch={true}
                      campoLabel={"clave"}
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsClavesEmisoras}
                      onInputChange={(event) =>
                        this.onInputSelectedHandler(event, 1)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className={["col col-lg-4"].join(" ")}>
                    <Field
                      name="nombre_emisora"
                      id="nombre_emisora"
                      label={
                        getLan() === "es"
                          ? "Nombre de la emisora"
                          : "Corporate name"
                      }
                      type="select"
                      elementType="select"
                      isSearch={true}
                      campoLabel={"nombre"}
                      // campoLabel2={"clave"}
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsNombreEmisoras}
                      onInputChange={(event) =>
                        this.onInputSelectedHandler(event, 2)
                      }
                      component={renderInput}
                    />
                  </div>
                  <div className="col col-lg-4">
                    <Field
                      name="tipo_valor"
                      id="tipo_valor"
                      label={
                        getLan() === "es" ? "Tipo de valor" : "Instrument type"
                      }
                      type="select"
                      elementType="select"
                      isSearch={true}
                      campoLabel={"nombre"}
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsValores}
                      onInputChange={(event) =>
                        this.onInputSelectedHandler(event, 3)
                      }
                      component={renderInput}
                    />
                  </div>
                </div>

                <hr />
                {this.sectionButtonHandler(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonHandler = (form) => {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14",
            ].join(" ")}
            onClick={() => this.onResetFilterHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterHandler = (event) => {
    event.preventDefault();
  };

  onSubmitSearchModelOneHandler = (event) => {
    event.preventDefault();
  };

  printChange = (e) => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = (value) => {
    this.onInputChangeHandler(value);
  };

  onInputChangeHandler = (event = null, eraser = null, tipo = null) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;

    if (value !== "") {
      this.props.onFilterEmisorasByClave(value, true, false);
    } else {
      disabled = true;
    }

    if (tipo) {
      this.props.setEmisora(null, 4, true);
      this.props.onInitValores(null);
    }

    if (eraser) {
      this.setState((prevState) => {
        return {
          toggleInputs: !prevState.toggleInputs,
          emisoraSeleccionada: null,
          claveSeleccionada: null,
          form_clave: form_clave,
          disableEnableButtons: true,
        };
      });
    } else {
      this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
    }
  };

  limpiaForms() {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form.clave_emisora.value = null;
    form.nombre_emisora.value = null;
    form.tipo_valor.value = null;
    
    selectedFilters.nombre_emisora = [];
    selectedFilters.tipo_valor = [];
    selectedFilters.clave_emisora = [];
    
    this.props.onInitSolicitudes(this.props.itemsPerPage, 0);
    this.props.setPage(1);
    this.props.setValor(null);
    this.props.setEmisora(null, 3, false);

    this.props.history.push(
      `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${1}&empresaId=${null}&razonSocial=${null}&clave=${null}&numeroTramite=${null}&clave_seleccionada=${null}&emisora_id=${null}&valores=${null}&nombreEmisora=${null}`
    );
    this.props.onInitEmisorasClave();
    this.props.onInitEmisorasNombre();
    this.props.onInitValores(null, null);

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });
  }

  onResetFilterHandler = (form) => {
    form.reset();
    this.limpiaForms();
  };

  onToggleFilters = () => {
    this.setState((prevState) => {
      return {
        toggleFilters: !prevState.toggleFilters,
      };
    });
  };

  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = (type) => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    switch (type) {
      case 1:
        return [
          "clave_emisora",
          form.clave_emisora.value,
          selectedFilters.clave_emisora,
        ];
      case 2:
        return [
          "nombre_emisora",
          form.nombre_emisora.value,
          selectedFilters.nombre_emisora,
        ];
      case 3:
        return [
          "tipo_valor",
          form.tipo_valor.value,
          selectedFilters.tipo_valor,
        ];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor de la emisora
    const valuesClaveEmisora =
      form.clave_emisora.value && form.clave_emisora.value !== ""
        ? form.clave_emisora.value
        : "";
    // Se obtiene el nombre de la emisora
    const valuesNombreEmisora =
      form.nombre_emisora.value && form.nombre_emisora.value !== ""
        ? form.nombre_emisora.value
        : "";

    // Se obtiene el valor de la emisora
    const valuesTipoValor =
      form.tipo_valor.value && form.tipo_valor.value !== ""
        ? form.tipo_valor.value
        : "";

    return [valuesClaveEmisora, valuesNombreEmisora, valuesTipoValor];
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (values = null) => {
    let newStateValues = null;

    if (values !== "") {
      newStateValues = values;
    }
    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (values = null, label = null, type) => {
    let newFilterValues = [];
    if (values !== null && values !== "") {
      newFilterValues.push({
        label: label,
        value: values,
        type: type,
      });
    }
    return newFilterValues;
  };

  onFilterDataByEvent = () => {
    // Se obtienen todos los valores de los campos del formulario (Filtro)
    let [
      valuesClaveEmisora,
      valuesNombreEmisora,
      valuesTipoValor,
      valuesFechaInicio,
      valuesFechaFin,
    ] = this.onGetAllValuesFormFilter();

    if (!this.state.form_filters.errorDate) {
      const emisora = this.state.emisoras.find(
        (e) => e.id === valuesClaveEmisora
      );
      const emisoraNombre = this.state.emisorasNombre.find(
        (e) => e.id === valuesNombreEmisora
      );
      if (emisora) {
        valuesClaveEmisora = emisora.clave;
      }
      if (emisoraNombre) {
        valuesNombreEmisora = emisoraNombre.nombre;
      }
      this.props.onInitSolicitudes(
        this.props.itemsPerPage,
        0,
        null,
        valuesTipoValor,
        valuesClaveEmisora,
        valuesNombreEmisora
      );

      this.props.setPage(1);
      this.props.history.push(
        `?tramiteId=${null}&tipoDocumento=${null}&numEnvio=${null}&page=${1}&empresaId=${null}&razonSocial=${null}&nombreEmisora=${valuesNombreEmisora}&clave=${null}&numeroTramite=${null}&clave_seleccionada=${valuesClaveEmisora}&emisora_id=${null}&valores=${valuesTipoValor}`
      );
    }

    // Se invoca la funcion del componente padre (SeguimientosSolicitudes.js) para setear los valores correspondientes
    // Se configura el valor de la emisora seleccionada
    if (!this.state.form_filters.errorDate) {
      this.props.setEmisora(
        valuesClaveEmisora,
        1,
        null,
        null,
        valuesNombreEmisora
      );
      // Se configura el tipo valor seleccionado
      this.props.setValor(valuesTipoValor);
      // Se configura el valor de la fecha inicio seleccionada
      this.props.setFechaInicio(valuesFechaInicio);
      // Se configura el valor de la fecha fin seleccionada
      this.props.setFechaFin(valuesFechaFin);
    }
  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (event, type = null) => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;
    let value = event ? event.id : null;
    let emisoraSelected = [];
    switch (type) {
      case 1:
        //clave de la emisora
        form.clave_emisora.value = value;
        let valueNombre = null;
        let clave = null;
        if (value) {
          const emisora = this.state.emisoras.find((e) => e.id === value);
          if (emisora) {
            clave = emisora.clave;
            valueNombre = 1;
            emisoraSelected = this.onChangeFilterHandler(
              clave,
              getLan() === "es" ? "Clave: " + clave : "Ticker: " + clave,
              type
            );
          }
        }
        this.props.onInitEmisorasNombre(clave);
        selectedFilters.clave_emisora = emisoraSelected;
        this.setState({ emisorasNombre: [] });
        form.nombre_emisora.value = valueNombre;
        const nombreEmisoraSelected =
          selectedFilters.nombre_emisora.length > 0
            ? selectedFilters.nombre_emisora[0].value
            : null;
        this.props.onInitValores(clave, nombreEmisoraSelected);
        break;
      case 2:
        // nombre de la emisora
        let nombreEmisora = null;
        if (value) {
          const emisora = this.state.emisorasNombre.find((e) => e.id === value);
          if (emisora) {
            nombreEmisora = emisora.nombre;
            emisoraSelected = this.onChangeFilterHandler(
              nombreEmisora,
              getLan() === "es"
                ? "Emisora: " + nombreEmisora
                : "Corporate name: " + nombreEmisora,
              type
            );
          }
        } else {
          let claveFilter = null;
          if (selectedFilters.clave_emisora.length > 0) {
           claveFilter = selectedFilters.clave_emisora[0].value;
          }
          this.props.onInitEmisorasNombre(claveFilter);
        }
        this.props.onInitEmisorasClave(nombreEmisora);
        selectedFilters.nombre_emisora = emisoraSelected;
        const claveSelected =
          selectedFilters.clave_emisora.length > 0
            ? selectedFilters.clave_emisora[0].value
            : null;
        this.props.onInitValores(claveSelected, nombreEmisora);
        form.nombre_emisora.value = value;
        break;

      default:
        // tipo de valor
        let tipoValorSelected = [];
        const tipoValorExist = this.props.valores.find((e) => e.id === value);
        if (tipoValorExist) {
          tipoValorSelected = this.onChangeFilterHandler(
            tipoValorExist.nombre,
            getLan() === "es"
              ? "Tipo de valor: " + tipoValorExist.nombre
              : "Instrument type: " + tipoValorExist.nombre,
            type
          );
        }
        selectedFilters.tipo_valor = tipoValorSelected;
        form.tipo_valor.value = value;
        break;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });

    this.onFilterDataByEvent();
  };

  onDeleteFilterHandler = (item) => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let [inputName, stateValues, filterValues, otherInputName] =
      this.onGetNameStateFilterByInputHandler(item.type);

    newStateValues = this.onChangeStateHandler();
    newFilterValues = []

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] =
      newFilterValues;

    if (item.type === 1) {
      // Clave emisora
      // Limpiando valores para campo nombre emisora
      let nombreFilter = null;
      if (selectedFilters.nombre_emisora.length > 0) {
        //si contiene filtro de nombre emisora, se obtiene nombre
        nombreFilter = selectedFilters.nombre_emisora[0].value;
      }
      this.props.onInitEmisorasNombre();
      this.props.onInitEmisorasClave(nombreFilter);
      this.props.onInitValores(null, null);
    } else if (item.type === 2) {
      // Nombre emisora
      // Limpiando valores para campo clave emisora
      let claveFilter = null;
      if (selectedFilters.clave_emisora.length > 0) {
        //si contiene filtro de clave emisora, se obtiene clave
        claveFilter = selectedFilters.clave_emisora[0].value;
      }
      this.props.onInitEmisorasNombre(claveFilter);
      this.props.onInitEmisorasClave();
      this.props.onInitValores(null, null);

    } else if (item.type === 3) {
      // Tipo valor
      form.tipo_valor.value = null;
      selectedFilters.tipo_valor = [];
    } 

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    const selectedFilters = this.state.selectedFilters;
    const filterList = [
      ...selectedFilters.clave_emisora,
      ...selectedFilters.nombre_emisora,
      ...selectedFilters.tipo_valor,
    ];
    let filters = null;
    filters = (
      <div
        className={classes.filter_container_outside_model_one}
        id="allFitersContainers"
      >
        {filterList.map((item, i) => (
          <div
            className={classes.filter_container_model_one}
            key={`${item.label}-${item.id}-${i}`}
          >
            <span
              className={["montse", classes.filter_text_model_one].join(" ")}
            >
              {item.type !== 3 && item.type !== 4 ? item.label : item.value}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i
                className="far fa-times-circle"
                onClick={() => this.onDeleteFilterHandler(item)}
              />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.props.setEmisora(null, 4, true);
      this.props.onInitValores(null);

      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null,
      });
    }
    this.setState((prevState) => {
      return {
        toggleInputs: !prevState.toggleInputs,
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    // en este filtro la respuesta es un listado de claves y no de objetos
    const listado = this.props.listadoClaves;
    return listado.map((item, i) => (
      <li
        key={i}
        className="list-group-item"
        onClick={() => this.onClaveSelectedHandler(item, i, 1)}
      >
        {item}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    let emisoraSeleccionada = null;
    let claveSeleccionada = null;

    const form = this.state.form_clave;
    const selectedFilters = this.state.selectedFilters;

    this.onToggleFilterInputOptionSelected();

    if (option === 0) {
      this.props.setEmisora(clave, 2, true, clave);
      claveSeleccionada = clave;
    } else {
      emisoraSeleccionada = id;
      claveSeleccionada = clave;
      this.props.setEmisora(emisoraSeleccionada, 1, true, claveSeleccionada);
      const claveExist = this.state.emisoras.find((e) => e.clave === clave);
      let idClave = null;
      if (claveExist) {
        idClave = claveExist.id;
      }

      form.clave.value = idClave;

      this.props.onInitValores(clave);
    }

    selectedFilters.emisora = claveSeleccionada;

    this.setState({
      form_clave: form,
      selectedFilters: selectedFilters,
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: emisoraSeleccionada,
      clave: claveSeleccionada,
    });
  };

  onPrintOptionSelectedHandler = (value) => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div
        className={[
          classes.selectedOptionContainer,
          !this.state.toggleInputs ? classes.hidden : null,
        ].join(" ")}
      >
        <span className={classes.filterContainer}>
          <span
            className={[classes.filterText, "montse texto_14 regular"].join(
              " "
            )}
          >
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span
            className={classes.filterIconButton}
            onClick={() => this.onInputChangeHandler(null, true, true)}
          >
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.listadoClaves
      ? this.onPrintAutoCompleteHandler()
      : null;

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value);
          }}
        >
          {getLan() === "es"
            ? `Ver todos los resultados para: ${value}`
            : `See all results for: ${value}`}
        </li>
      ) : (
        <li className="list-group-item">
          {getLan() === "es"
            ? "No existen resultados para la búsqueda:"
            : "There are no results for the search:"}{" "}
          {value}
        </li>
      );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div
          id="searchInput"
          className={[
            classes.search_input_container_model_one,
            this.state.toggleInputs ? classes.hidden : null,
          ].join(" ")}
        >
          <Form
            onSubmit={this.onSubmitSearchModelOneHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value,
            }}
            render={() => (
              <form
                ref={this.formSearch}
                onSubmit={this.onSubmitSearchModelOneHandler}
              >
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  placeholder={
                    getLan() === "es"
                      ? "Buscar por clave de cotización"
                      : "Search by ticker"
                  }
                  component={renderInput}
                  onInputChange={(event) => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              !this.state.form_clave.clave.value ||
              this.state.form_clave.clave.value === ""
                ? classes.hidden
                : null,
              this.state.esconderAutoComplete ? classes.hidden : null,
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    return this.printFrameHandler();
  }
}

const mapStateToProps = (state) => {
  return {
    emisoras: state.tramites.emisorasPreliminar,
    emisorasNombre: state.tramites.emisorasNombrePreliminar,
    valores: state.tramites.valores,
    listadoClaves: state.tramites.claves,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitSolicitudes: (
      size,
      page,
      emisora_id,
      valor,
      clave,
      nombreEmisora
    ) => {
      dispatch(
        actions.getProspectosPreliminares(
          size,
          page,
          emisora_id,
          valor,
          clave,
          nombreEmisora
        )
      );
    },
    onInitEmisorasClave: (nombreEmisora) => {
      dispatch(actions.initEmisorasTramitesPreliminar(nombreEmisora));
    },
    onInitEmisorasNombre: (clave) => {
      dispatch(actions.initEmisorasNombresTramitesPreliminar(clave));
    },
    onInitValores: (clave, nombreEmisora) => {
      dispatch(actions.initValoresTramitesPreliminar(clave, nombreEmisora));
    },
    onFilterEmisorasByClave: (clave) => {
      dispatch(actions.initClavesPreliminar(clave));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterPreliminares)
);

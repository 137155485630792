import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import styles from "./HeatMapWidget.css";

function HeatMapWidget(props) {
    const container = useRef();

    useEffect(
        () => {
          const script = document.createElement("script");
          script.src = "https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js";
          script.type = "text/javascript";
          script.async = true;
          script.innerHTML = `
            {
              "exchanges": [
                "BIVA"
              ],
              "dataSource": ${JSON.stringify(props.dataSource)},
              "grouping": "sector",
              "blockSize": "Value.Traded",
              "blockColor": "change",
              "locale": ${JSON.stringify(props.locale)},
              "symbolUrl": "",
              "colorTheme": "light",
              "hasTopBar": true,
              "isDataSetEnabled": false,
              "isZoomEnabled": true,
              "hasSymbolTooltip": true,
              "isMonoSize": false,
              "width": "100%",
              "height": "100%",
              "customer": "bivacom"
            }`;
          container.current.appendChild(script);
        },
        []
      );

    return (
        <div className={["tradingview-widget-container", styles.widget].join(" ")} ref={container}>
            <div className="tradingview-widget-container__widget"></div>
            {!props.hideTVFooter ? 
                <div className="tradingview-widget-copyright"><a href="https://es.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Siga los mercados en TradingView</span></a></div>
                : null
            }
        </div>
    );
}

HeatMapWidget.propTypes = {
    dataSource: PropTypes.string,
    locale: PropTypes.string,
    hideTVFooter: PropTypes.bool
}
 //dataSource ex.
//  "FTBIVA"
//  "F4GBIVA"

export default memo(HeatMapWidget);
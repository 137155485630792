import { updateObject } from "../utils/utilities";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activas: null
};

const setActivas = (state, action) => {
  const newProperties = {
    activas: action.activas
  };
  return updateObject(state, newProperties);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACTIVAS_SUCCESS:
      return setActivas(state, action);
    default:
      return state;
  }
};

export default reducer;

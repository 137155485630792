import { createChart, ColorType } from '../../../charting_library/lightweight-charts.development.mjs';
import React, { useEffect, useRef } from 'react';
import classes from './LightweightChart.css';
import PropTypes from 'prop-types';

const formatDecimal = Intl.NumberFormat('es-MX', {
    style: "decimal",
    }).format;

const formatPercent = Intl.NumberFormat('es-MX', {
    style: "percent",
    minimumFractionDigits: 2
  }).format;

const formatCurrency = Intl.NumberFormat('es-MX', {
    style: "currency",
    currency: "MXN",
    maximumFractionDigits: 2
    }).format;

const defaultLineColor = '#00aeef';
const defaultBackgroundColor = '#FFFFFF';
const defaultTextColor = '#000000';

const ChartComponent = props => {
    const {
        elements = [{
            data: [],
            legend: 'Empty'
        }],
        titulo,
        timeVisible = true,
        isPercentage = false,
        isPointMarkersVisible = false,
        isCurrency = false,
        isLegendHorizontal = false,
        isTitleCentered = false,
        height,
    } = props;
    const chartContainerRef = useRef();
    let series = [];
    let leyendas = [];
    let chart;

    useEffect(() => {
        const handleResize = () => {
            pintarGrafica(chart, chartContainerRef, elements, isPointMarkersVisible, isLegendHorizontal);
            chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
            chart.timeScale().fitContent();
        };
        
        chart = createChart(chartContainerRef.current, {
            layout: {
                background: { type: ColorType.Solid, color: defaultBackgroundColor },
                textColor: defaultTextColor
            },
            timeScale: {
                timeVisible: timeVisible,
            },
            width: chartContainerRef.current.clientWidth,
        });
        
        pintarGrafica(chart, chartContainerRef, elements, isPointMarkersVisible, isLegendHorizontal);
        
        if (isPercentage) {
            chart.applyOptions({localization: {priceFormatter: formatPercent}});
        } else if (isCurrency) {
            chart.applyOptions({localization: {priceFormatter: formatCurrency}});
        } else {
            chart.applyOptions({localization: {priceFormatter: formatDecimal}});
        }
        
        window.addEventListener('resize', handleResize);
        chart.timeScale().fitContent();

        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    },[elements, defaultLineColor, defaultBackgroundColor, defaultTextColor]);

    function pintarGrafica(chart, chartContainerRef, elements, isPointMarkersVisible, isLegendHorizontal, tiutlo) {
        limpiarLeyendas(chart);
        let legendCount = 0;
        let itrCount = 0;
        let legendsPerLine = getAvgLegendsPerLine(chartContainerRef.current.clientWidth, 90);
        series = [];
        leyendas = [];
        elements.forEach((element, index) => {
            let lineColor = element.lineColor ? element.lineColor : defaultLineColor;
            let newLine = chart.addLineSeries({ color:lineColor, priceLineColor:lineColor, baseLineColor:lineColor, pointMarkersVisible: isPointMarkersVisible });
            newLine.setData(element.data);
            series.push(newLine);
    
            let ypos, xpos;
            if (isLegendHorizontal) {
                ypos = 12 + (itrCount * 18);
                xpos = 10 + (legendCount * 95);
            } else {
                ypos = 12 + (index * 18);
                xpos = 10;
            }
            if (legendCount >= legendsPerLine - 1) {
                legendCount = 0;
                itrCount++;
            } else {
                legendCount++;
            }
            pintarLeyenda(chartContainerRef, element.legend, xpos, ypos, lineColor);
        });
        if (titulo) {
            pintarTitulo(chartContainerRef, titulo, isTitleCentered);
        }
    }
    
    function limpiarLeyendas(chart) {
        for (const serie of series) {
            chart.removeSeries(serie);
        }
        for (const leyenda of leyendas) {
            leyenda.remove();
        }
    }

    function pintarTitulo(chartContainerRef, text, centered = false) {
        let legend = document.createElement('div');
        let left = 45;
        if (centered) {
            let chartScaleWidth = chart.priceScale("right").width();
            chartScaleWidth = chartScaleWidth > 0 ? chartScaleWidth : 80;
            left = (chartContainerRef.current.clientWidth / 2) - (text.length + chartScaleWidth);
        }
        legend.style = `position: absolute; left: ` + left + `px; top: -15px; margin: 0 auto; z-index: 2; font-size: 15px; font-family: sans-serif; font-weight: 300; font-style: italic;`;
        
        chartContainerRef.current.appendChild(legend);
        leyendas.push(legend);
    
        let row = document.createElement('div');
        row.innerHTML = text;
        row.style.color = defaultTextColor;
        legend.appendChild(row);
    }
    
    function pintarLeyenda(chartContainerRef, text, xpos, ypos, lineColor) {
        let line = document.createElement('div');
        line.style = `position: absolute; left: ` + xpos + `px; top: ` + ypos + `px; z-index: 2; width: 28px; border: 2px solid ` + lineColor + `; margin: 3px;`;
        let legend = document.createElement('div');
        legend.style = `position: absolute; left: ` + (xpos + 35) + `px; top: ` + ypos + `px; z-index: 2; font-size: 14px; font-family: sans-serif; font-weight: 300;`;
        
        chartContainerRef.current.appendChild(line);
        chartContainerRef.current.appendChild(legend);
        leyendas.push(line);
        leyendas.push(legend);
    
        let row = document.createElement('div');
        row.innerHTML = text;
        row.style.color = defaultTextColor;
        legend.appendChild(row);
    }
    
    function getAvgLegendsPerLine(containerWidth, avgLegendWidthInPx) {
        let rightSpace = chart.priceScale("right").width();
        rightSpace = rightSpace > 0 ? rightSpace : 150;
        return Math.floor((containerWidth - (rightSpace + 20)) / avgLegendWidthInPx);;
    }

    return (
        <div ref={chartContainerRef} className={height === undefined ? [classes.contenedor].join(" "):""} style={{height: height+"px"}}/>
    );
};

ChartComponent.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
            time: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired
        })),
        legend: PropTypes.string.isRequired,
        lineColor: PropTypes.string
      })),
    titulo: PropTypes.string,
    height: PropTypes.number,
    timeVisible: PropTypes.bool,
    isLegendHorizontal: PropTypes.bool,
    isPercentage: PropTypes.bool,
    isPointMarkersVisible: PropTypes.bool,
    isCurrency: PropTypes.bool,
    isTitleCentered: PropTypes.bool,
};

export default function LightweightChart(props) {
    return (
        <div>
            <ChartComponent {...props}/><br/>
            <p className="nunito texto_10">
                TradingView Lightweight Charts™<br/>
                Copyright (с) 2024 TradingView, Inc. <a href="https://www.tradingview.com/">  https://www.tradingview.com/</a><br/>
            </p>
        </div>
    );
}


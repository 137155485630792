import React, { Component } from "react";
import classes from "./InstrumentosDisponibles.css";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getUrlDocument } from "../../../stores/utils/utilities";

import Auxs from "../../../hoc/auxs/Auxs";
import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";
import Accordion from "../../../components/ui/accordion/Accordion";

class InstrumentosDisponibles extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
    this.props.onToggles();
  }
  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  onPrintTerminosHandler = terminos => {
    return <ToggleBox terminos={terminos} />;
  };

  onPrintInstrumentos = terminos => {
    return (
      <Accordion datos={terminos} nombre="terminos" icon={""} tipo={1}  icons_tamaño={true} sinIcono={true}/>
    );
  };

  render() {
    let capitales = null;
    let deudas = null;
    capitales = this.onPrintTerminosHandler(this.props.capitales ? this.props.capitales : []);
    deudas = this.onPrintTerminosHandler(this.props.deuda ? this.props.deuda : []);
    const instrumentos = this.props.instrumentos
    ? this.onPrintInstrumentos(this.props.instrumentos)
    : null;
    let imagenes = null;
    if (this.props.procesoLisadoSIC) {
      imagenes = this.props.procesoLisadoSIC.map((img, i) => {
        if (img.url && img.url !== "") {
          let ruta = getUrlDocument(img.url);
          return (<img key={i} src={ruta} alt={img.title} className={classes.img} width="100%" />);
        }
        return null;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={["margen_top_30"].join(" ")}>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.instrumentosdisponibles.titulo1" defaultMessage=" " />
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.text_container, "margen_top_30"].join(" ")}>
                <p className={["texto_16", "montse regular"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.instrumentosdisponibles.introduccion1" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
          <div className="row margin_top_50">
            <div className="col sin_padding_L">
              <div className={["nunito", "texto_30", "", "main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={["nunito"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.instrumentosdisponibles.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.terminosContainer].join(" ")}>{capitales}</div>
            </div>
          </div>
          <div className="row margin_top_50s">
            <div className="col sin_padding_L">
              <div className={["nunito", "texto_30", "margen_top_50", "main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={["nunito", "texto_30"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.instrumentosdisponibles.subtitulo2" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.terminosContainer].join(" ")}>{deudas}</div>
            </div>
          </div>
          <div className="row margin_top_50">
            <div className="col sin_padding_L">
              <div className={["nunito", "texto_30", "", "main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className={["nunito"].join(" ")}>
                  <FormattedHTMLMessage id="invertir.instrumentosdisponibles.titulo2" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col sin_padding_L">
            <div
              className={[classes.terminosContainer].join(" ")}
            >
              {instrumentos}
            </div>
          </div>
        </div>
          <div className="row">
          <div className="col sin_padding">
            <div
              className={[classes.terminosContainer, "margen_top_10"].join(" ")}
            >
             {imagenes}
            </div>
          </div>
        </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    casas: state.casas.casas,
    diccionario: state.intl.diccionario,
    capitales: state.toggleBox.instrumentosCapital,
    deuda: state.toggleBox.instrumentosDeuda,
    instrumentos: state.toggleBox.toggleBoxListadoInstrumentos,
    procesoLisadoSIC: state.imagenes.procesoLisadoSIC
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(21));
    },
    onToggles: () => {
      dispatch(action.getInstrumentosDeudaToggleBox());
      dispatch(action.getInstrumentosCapitalToggleBox());
      dispatch(action.getToggleBoxListadoInstrumentos());
      dispatch(action.getImagenProcesoListadoSic());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstrumentosDisponibles);

import React, { useState, useEffect } from "react";
import { getIndices } from "../../../../stores/utils/utilities";
import classes from "./ToggleSustentable.css";

export default function ToggleSustentable(props) {
  const [togglesOpens, setTogglesOpens] = useState([]);
  const [toggles, setToggles] = useState([]);

  useEffect(() => {
    let indxPrincipal = -1;
    let newToggle = [];
    let newToggleInternos = [];
    if (props.data !== undefined) {
      props.data.forEach((itm, i) => {
        if (
          (itm.titulo_encabezado && itm.titulo_encabezado !== "") ||
          (itm.subtitulo && itm.subtitulo !== "")
        ) {
          if (itm.titulo_encabezado && itm.titulo_encabezado !== "") {
            if (newToggleInternos.length > 0) {
              newToggle[newToggle.length - 1].toggleInterno = newToggleInternos;
            }
            newToggle.push(itm);
            indxPrincipal++;
            newToggleInternos = [];
          } else {
            if (itm.haveInterno === undefined) {
              itm.haveInterno = true;
              newToggleInternos.push(itm);
            } else {
              if (itm.haveInterno) {
                // se setea de nuevo en undefined para evitar duplicidad
                itm.haveInterno = undefined;
              }

              newToggle.push(itm);
              newToggleInternos = [];
            }
          }
        } else {
          if (newToggleInternos.length > 0) {
            newToggle[newToggle.length - 1].toggleInterno = newToggleInternos;
          }
          newToggle.push(itm);
          indxPrincipal++;
          newToggleInternos = [];
        }

        if (
          i === props.data.length - 1 &&
          newToggle &&
          newToggle !== undefined
        ) {
          if (newToggleInternos.length > 0) {
            newToggle[indxPrincipal].toggleInterno = newToggleInternos;
          }
        }
      });
    }

    setToggles(newToggle);
  }, []);

  async function openToggleEvent(indxToggle) {
    let options = togglesOpens;
    const exist = options.includes(indxToggle);
    if (exist) {
      options = options.filter((itm) => itm !== indxToggle);
    } else {
      options.push(indxToggle);
    }
    setTogglesOpens(options);
    const togglesObj = toggles.map((itm, i) => {
      itm.show = options.includes("toggle" + i);
      return itm;
    });
    setToggles(togglesObj);
  }

  const getDivs = (toggleInterno = null, isInterno = false) => {
    const data = toggleInterno ? toggleInterno : toggles;
    let togglesDivs = null;
    let indx = 0;
    if (data && data.length > 0) {
      togglesDivs = data.map((itm, i) => {
        const iEnlace = i > props.colores.length ? 0 : i;
        const color = props.colores[iEnlace];
        const isShow = itm.show;
        let imageDiv = null;
        const style = {
          backgroundImage: `url(${itm.imagen})`,
        };
        const styleTitulo = {
          color: color,
        };
        let tituloDiv = null;
        if (itm.titulo_encabezado !== null && itm.titulo_encabezado !== "") {
          tituloDiv = (
            <div className={["montse row bold", classes.itemCenter].join(" ")}>
              <div className={classes.letra}>
                <span className="texto_azul texto_50">
                  {getIndices()[indx]}
                </span>
                <span className="texto_gris texto_30">/ </span>
              </div>
              <div className={[classes.titulo, classes.titulo_encabezado].join(" ")}>
                <span className="texto_verde texto_30 bold">
                  {itm.titulo_encabezado}
                </span>
              </div>
              <i
                className={[
                  props.hiddenToggleTitle ? "d-none" : null,
                  isShow ? "fas fa-caret-down" : "fas fa-caret-left",
                  classes.iconTitulo,
                ].join(" ")}
                style={styleTitulo}
              ></i>
            </div>
          );
          indx++;

          if (itm.imagen && itm.imagen !== undefined && itm.imagen !== "") {
            imageDiv = (
              <div className="margin_top_50 text-center">
                <img
                  src={itm.imagen}
                  alt={itm.titulo_encabezado}
                  className={classes.imagenToggle}
                />
              </div>
            );
          }
        } else {
          tituloDiv = (
            <>
              <div style={style} className={[classes.divImagen].join(" ")} />
              <div className={[classes.tituloContainer].join(" ")}>
                <div className={classes.vertical}>
                  <span
                    className={[
                      " nunito texto_30 bold",
                      classes.titulo,
                      classes.itemCenter,
                    ].join(" ")}
                    style={styleTitulo}
                  >
                    {itm.subtitulo}
                  </span>
                  <i
                    className={[
                      isShow ? "fas fa-caret-down" : "fas fa-caret-left",
                      classes.iconTitulo,
                      classes.itemCenter,
                    ].join(" ")}
                    style={styleTitulo}
                  ></i>
                </div>
              </div>
            </>
          );
        }
        if (
          (itm.titulo_encabezado === null || itm.titulo_encabezado === "") &&
          (itm.subtitulo === null || itm.subtitulo === "")
        ) {
          return (
            <>
              <div
                className={[" montse texto_16"].join(" ")}
                dangerouslySetInnerHTML={{ __html: itm.texto_html }}
              />
              <ToggleSustentable
                hiddenToggleTitle={false}
                data={itm.toggleInterno}
                colores={
                  itm.titulo_encabezado === "Tipos de Bonos"
                    ? props.coloresBonos
                      ? props.coloresBonos
                      : props.coloresInternos
                    : props.coloresInternos
                }
              />
            </>
          );
        }

        return (
          <div
            className={[
              "col-12 sin_padding margin_top_20",
              classes.rowObjetivos,
            ].join(" ")}
            key={"toggle" + i}
          >
            <div className="row">
              <div
                onClick={() =>
                  props.hiddenToggleTitle
                    ? "margin_top_50"
                    : openToggleEvent("toggle" + i)
                }
                className={["col-12 sin_padding", classes.imgContainer].join(
                  " "
                )}
              >
                {tituloDiv}
              </div>
              <div
                className={[
                  props.hiddenToggleTitle
                    ? "margin_top_30"
                    : isShow
                    ? null
                    : "d-none",
                  "col-12 sin_padding",
                  classes.texto_container,
                ].join(" ")}
              >
                <div
                  className={[" montse texto_16"].join(" ")}
                  dangerouslySetInnerHTML={{ __html: itm.texto_html }}
                />
                <div
                  className={[
                    props.hiddenToggleTitle ? null : isShow ? null : "d-none",
                    "col-12 sin_padding",
                    classes.texto_container,
                  ].join(" ")}
                >
                  {itm.toggleInterno && itm.toggleInterno.length > 0 ? (
                    <ToggleSustentable
                      hiddenToggleTitle={false}
                      data={itm.toggleInterno}
                      colores={
                        itm.titulo_encabezado === "Tipos de Bonos"
                          ? props.coloresBonos
                            ? props.coloresBonos
                            : props.coloresInternos
                          : props.coloresInternos
                      }
                    />
                  ) : null}
                </div>
                {imageDiv}
              </div>
            </div>
          </div>
        );
      });
    }
    return togglesDivs;
  };

  return getDivs(null, false);
}

import React, { Component } from "react";

import classes from "./Embajador.css";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import Cuadricula from "../../../components/ui/cuadricula/Cuadricula";
import Fotos from "../../../components/fotos/Fotos";
import Videos from "../../../components/videos/Videos";
import Box from "../../../components/ui/box/Box";
import ToggleBox from "../../../components/ui/toggleBox/ToggleBox";
import QueEstaPasando from "../../../components/ui/queEstaPasando/QueEstaPasando";
import { getLan, getLocal } from "../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import queryString from "query-string";
import QueEstaPasandoDocs from "./queEstaPasandoDocs/QueEstaPasandoDocs";

class Embajador extends Component {
  seleccionImagen(event, item_seleccionado) {
    event.preventDefault();
  }
  state = {
    messages: null,
    lan: getLocal(),
    equipo: [],
    destacados: [],
    showInitPage: false,
    videos: null,
    imagenes: null,
    documentos: null,
  };

  componentDidMount() {
    this.props.onInitIntl(5);

    this.initData(true);
  }

  initData(init = false) {
    let paramsString = queryString.parse(this.props.location.search);

    let videos = paramsString["videos"] === null ? true : false;
    let imagenes = paramsString["imagenes"] === null ? true : false;
    let documentos = paramsString["documentos"] === null ? true : false;

    if (videos || imagenes || documentos) {
      this.setState({
        videos: videos,
        imagenes: imagenes,
        documentos: documentos,
        showInitPage: false,
      });
    } else {
      this.props.onInitVideosInstituto();
      this.props.onDocumentos();
      this.props.onCuadricula();
      this.props.onToggleBox();
      this.props.onEmbajadores();
      if (init) {
        this.props.onInitIntl(5);
      }
      this.setState({
        showInitPage: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.initData();
    }
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.embajadores !== prevProps.embajadores) {
      let equipo = [];
      let destacados = [];
      destacados[0] = [{data:[], estado:"Destacados", nombre: getLan() === "es" ? "Embajadores destacados" : "Best ambassadors"}];
      if (this.props.embajadores && this.props.embajadores.length > 0) {
        let identificador = -1;
        this.props.embajadores.forEach((e) => {
          identificador++;
          equipo[identificador] = [];
          e.nombre = e.estado;
          equipo[identificador].push(e);
          if (e.data) {
            e.data.forEach((d) => {
              equipo[identificador].push(d);
              if (d.destacado) {
                destacados[0].push(d);
              }
            });
          }
        });
      }
      this.setState({
        equipo: equipo,
        destacados: destacados[0].length > 1 ? destacados : []
      });
    }
  }

  onBack(e) {
    e.preventDefault();

    this.props.history.push(`/instituto_biva/embajador_biva_universitario`);

    this.setState({ showInitPage: true, loader: false });
  }

  render() {
    if (this.state.showInitPage) {
      let videos = null;
      if (this.props.videos_api) {
        videos = <Videos videos={this.props.videos_api} />;
      }

      let cuadricula = null;
      if (this.props.cuadricula) {
        cuadricula = (
          <Cuadricula
            datos={this.props.cuadricula}
            coloreados={[]}
            saltarse={[]}
            enBlanco={[]}
            quitar={[]}
            modulo={3}
            tipo={2}
          />
        );
      }

      let boxes = null;
      if (this.props.doc) {
        boxes = this.props.doc.map((doc, i) => {
          return <Box key={"doc_" + i} modelo="modelo_1" data={doc} />;
        });
      }

      let toggle = null;
      if (this.props.toggle) {
        toggle = <ToggleBox terminos={this.props.toggle} />;
      }

      let fotos = null;
      let destacados = null;

      if (this.state.equipo.length > 0) {
        fotos = this.state.equipo.map((item, i) => {
          return (
            <div
              className={"row relativo zindex1000 sin_padding "}
              key={i + "fotoEmbajador"}
            >
              <Fotos fotos={item} tipo={1} embajador={true} />
            </div>
          );
        });

       
      }
      if (this.state.destacados.length > 0) {
        destacados = this.state.destacados.map((item, i) => {
          return (
            <div
              className={"row relativo zindex1000 sin_padding "}
              key={i + "fotoEmbajador"}
            >
              <Fotos fotos={item} tipo={1} embajador={true} />
            </div>
          );
        });

       
      }
      return (
        <IntlProvider
          locale={this.state.lan}
          messages={this.state.messages}
          defaultMessage=" "
        >
          <React.Fragment>
            <span className={"texto_42 nunito regular"}>
              <FormattedHTMLMessage
                id="institutobiva.embajador.titulo1"
                defaultMessage=" "
              />
            </span>
            <div className="row margen_top_30">
              <p>
                <span className="texto_16 montse regular">
                  <FormattedHTMLMessage
                    id="institutobiva.embajador.introduccion1"
                    defaultMessage=" "
                  />
                </span>
              </p>
            </div>
            <div className="row">
              <div className="col sin_padding_L">
                <div className={["main_subtitle"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedHTMLMessage
                      id="institutobiva.embajador.subtitulo1"
                      defaultMessage=" "
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="row margen_top_20">{cuadricula}</div>
            <div className="margen_top_30">{toggle}</div>
            <div className="margen_top_20 sin_padding">{destacados}</div>
            <div
              className={[
                "row margen_top_50",
                this.props.doc
                  ? this.props.doc.length > 0
                    ? null
                    : "d-none"
                  : "d-none",
              ].join(" ")}
            >
              <div className="col sin_padding_L">
                <div className={["main_subtitle"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedHTMLMessage
                      id="institutobiva.embajador.subtitulo2"
                      defaultMessage=" "
                    />
                  </h2>
                </div>
              </div>

              <div className="margen_top_10 col-sm-12">{boxes}</div>
            </div>
            <div className={["row margen_top_50", classes.fondo].join(" ")}>
              {videos}
            </div>
            <div className="margen_top_20 sin_padding">
              <QueEstaPasando
                tab1={"VIDEOS"}
                tab2={"IMAGENES"}
                tab3={"DOCUMENTOS"}
              />
            </div>
            <div className="row margen_top_50">
              <div className="col sin_padding_L">
                <div className={["main_subtitle"].join(" ")}>
                  <span className={"slash"}>/</span>
                  <h2>
                    <FormattedHTMLMessage
                      id="institutobiva.embajador.subtitulo3"
                      defaultMessage=" "
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div className="margin_top_50 sin_padding">{fotos}</div>
            <div className="margin_top_80 sin_padding texto_16 montse regular">
            <FormattedHTMLMessage
                      id="institutobiva.embajador.disclaimer"
                      defaultMessage=" "
                    />
            </div>
            <div className="margin_bottom_200" />
          </React.Fragment>
        </IntlProvider>
      );
    } else {
      return (
        <QueEstaPasandoDocs
          location={this.props.location}
          videosFlag={this.state.videos}
          imagenesFlag={this.state.imagenes}
          documentosFlag={this.state.documentos}
          onBack={(e) => this.onBack(e)}
          history={this.props.history}
        />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitVideosInstituto: () => {
      dispatch(action.initVideosInstituto());
    },
    onDocumentos: () => {
      dispatch(action.initDocumentosInstitutoBiva());
    },
    onEmbajadores: () => {
      dispatch(action.getEmbajadoresBivaFotos());
    },
    onCuadricula: () => {
      dispatch(action.initCuadriculaEmbajador());
    },
    onToggleBox: () => {
      dispatch(action.getEmbajadorBivaToggleBox());
    },
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
  };
};

const mapStateToProps = (state) => {
  let videos_api = null;
  if (state.videos.videos_instituto) {
    videos_api = state.videos.videos_instituto.items;
  }

  return {
    videos_api: videos_api,
    doc: state.documentos.instituto,
    embajadores: state.documentos.embajadores,
    cuadricula: state.cuadriculas.embajador,
    toggle: state.toggleBox.embajadorBiva,
    diccionario: state.intl.diccionario,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Embajador);

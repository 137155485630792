import React, { Component } from "react";
import Box from "../../../components/ui/box/Box";
import Stepper from "../../../components/ui/stepper/Stepper";

import ipoJson from "../../../json/empresas/pasos.json";

import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";

class CambioListado extends Component {
  state = {
    messages: null,
    lan: getLocal()
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
          lan: getLocal()
        });
      }
    }
  }

  render() {
    let box = null;
    if (this.props.archivos) {
      box = this.props.archivos.map((enlace, i) => {
        return <Box key={"enlace" + i} modelo="modelo_1" data={enlace} />;
      });
    }
    let stepper = null;
    if (this.props.toggle) {
      let content = this.props.toggle[0].content;
      content = JSON.parse(content);
      stepper = <Stepper data={content} tipo={2} />;
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          <div className="row ">
            <div className="col-12 sin_padding_L">
              <div className={[""].join(" ")}>
                <h2 className="nunito texto_42 regular">
                  <FormattedHTMLMessage id="empresas.listadodevalores.cambiodelistado.titulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className={"margen_top_30"}>
            <span className={"montse texto_16 regular"}>
              <FormattedHTMLMessage id="empresas.listadodevalores.cambiodelistado.introduccion1" defaultMessage=" " />
            </span>
          </div>

          <div className="row margen_top_30">{box}</div>

          <div className="row margen_top_50 width_cien">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="empresas.listadodevalores.cambiodelistado.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>

            <div className="row margen_top_50">{stepper}</div>
          </div>
          <div className={"margen_top_30"}>
            <span className={"montse texto_16 regular justificado"}>
              <FormattedHTMLMessage id="empresas.listadodevalores.cambiodelistado.conclusion1" defaultMessage=" " />
            </span>
          </div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    archivos: state.documentos.enlacesArchivosCambioListado,
    toggle: state.toggleBox.toggleBoxProcesoCambioListado
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(61));
      dispatch(action.getEnlacesArchivosCambioListado());
      dispatch(action.getToggleBoxProcesoCambioListado());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CambioListado);

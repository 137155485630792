import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import Charts from "../../../components/ui/charts/Charts";
import classes from "./GraficaHistoricoFondos.css";
import {
  getDateForMonth,
  setFormatPercentString,
  setFormatCurrencyString,
  getLan,
  sumaDia
} from "../../../stores/utils/utilities";
import FilterGraficaHistoricoConcentradoPreciosFondos from "../../../components/ui/filterGraficaHistoricoConcentradoPreciosFondos/FilterGraficaHistoricoConcentradoPreciosFondos";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";

const crucesIndicadores = [
  { nombre: "Total", id: 0 },
  { nombre: "Local", id: 1 },
  { nombre: "SIC", id: 2 },
];
class GraficaHistoricoFondos extends Component {
  state = {
    datos: null,
    topSeleccionado: [],
    anioSeleccionado: null,
    tipoParticipacion: 0,
    tipoParticipacionAntetior: null,
    anios: [],
    datos_anios: null,
    indicadores: null,
    tops: [],
    tituloGrafica: null,
    top1: null,
    top2: null,
    top3: null,
    top4: null,
    top5: null,
    fechaInicio: null,
    fechaFin: null,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tituloGrafica !== prevProps.tituloGrafica) {
      this.setState({
        tituloGrafica: this.props.tituloGrafica,
      });
    }
    if (this.props.total !== prevProps.total) {
      this.setState({
        datos: this.props.total,
      });
    }

    if (this.props.local !== prevProps.local) {
      this.setState({
        datos: this.props.local,
      });
    }

    if (this.props.sic !== prevProps.sic) {
      this.setState({
        datos: this.props.sic,
      });
    }
    // if (this.state.topSeleccionado !== prevState.topSeleccionado) {
    //   this.armaGrafica(this.props.historico);
    // }
    if (this.props.historico !== prevProps.historico) {
      this.armaGrafica(this.props.historico);
    }
  }

  onSetTop = (topSeleccionado) => {
    let top = topSeleccionado;
    if (topSeleccionado === "") {
      top = null;
    }
    this.setState({
      topSeleccionado: top,
    });
  };

  onSetFecha = (fechaInicio = null, fechaFin = null) => {
   let fechaFinDia = sumaDia(fechaFin, 1);
    this.setState({
      fechaInicio: fechaInicio,
      fechaFin: fechaFinDia,
    });
  };

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const A = parseInt(a.fechaPrecio);
    const B = parseInt(b.fechaPrecio);

    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }
    return comparison;
  }

  agrupar(array) {
    //Creamos un nuevo objeto donde vamos a almacenar por fechaPrecio.
    let nuevoObjeto = [];
    let nuevoObjetoFechas = [];
    //Recorremos el arreglo
    array.forEach((x) => {
      //Si la fechaPrecio no existe en nuevoObjeto entonces
      //la creamos e inicializamos el arreglo de fecha.
      if (!nuevoObjeto.hasOwnProperty(x.fechaPrecio)) {
        nuevoObjeto[x.fechaPrecio] = [];
      }
      //Agregamos los datos de propesionales.
      nuevoObjeto[x.fechaPrecio].push(x);
      nuevoObjetoFechas.push(x.fechaPrecio);
    });

    return { array: nuevoObjeto, fechas: nuevoObjetoFechas };
  }

  armaGrafica = (datos = null) => {
    let tops = datos && datos.content ? datos.content : [];
    let anioDatos = null;
    let indicadores_grafica = [getLan() === "es" ? "Fecha" : "Date"];
    let datos_anios = [];
    let top = [];
    let nuevoObjeto = [];
    if (tops !== null && tops.length > 0) {
      tops = tops.sort(this.compare);
      let topsAgrupados = this.agrupar(tops);

      this.state.topSeleccionado.forEach((element) => {
        for (const prop in topsAgrupados.array) {
          const serie_obj = topsAgrupados.array[prop];
          let existSerie = serie_obj.find(
            (serieFind) => serieFind.serie === element
          );
          let milliSeconds = parseFloat(prop);
          const dateSerie = new Date(milliSeconds);

          if (!nuevoObjeto.hasOwnProperty(prop)) {
            nuevoObjeto[prop] = [];
            nuevoObjeto[prop].push(dateSerie);
          }
          //Agregamos los datos de propesionales.

          if (existSerie) {
            nuevoObjeto[prop].push(existSerie.valorContable);
            const textoHtml = `${setFormatCurrencyString(
              existSerie.valorContable
            )}`;
            nuevoObjeto[prop].push(textoHtml);
          } else {
            nuevoObjeto[prop].push(null);
            const textoHtml = ``;
            nuevoObjeto[prop].push(textoHtml);
          }
        }

        indicadores_grafica.push({ label: element, type: "number" });
        indicadores_grafica.push({
          role: "tooltip",
          type: "string",
          p: { html: true },
        });
      });
    } else {
      indicadores_grafica.push("");
      datos_anios.push([], []);
    }
    for (const prop in nuevoObjeto) {
      const serie_obj = nuevoObjeto[prop];
      datos_anios.push(serie_obj);
    }
    let datos_anios_obj = datos_anios ? datos_anios : [];

    this.setState({
      datos_anios: datos_anios_obj,
      indicadores: indicadores_grafica,
    });
  };

  seleccionParticipacionMercado = (pm) => {
    let datos = this.state.datos;
    if (pm === 0) {
      //todos
      this.props.oninitHistoricoTotal();
      if (this.props.total) {
        datos = this.props.total;
      }
    } else if (pm === 1) {
      //top 5 local
      this.props.onInitCasaLocal();
      if (this.props.local) {
        datos = this.props.local;
      }
    } else {
      //top 5 SIC
      this.props.onInitCasaSic();
      if (this.props.sic) {
        datos = this.props.sic;
      }
    }
    this.setState({
      tipoParticipacion: pm,
      datos: datos,
    });
  };

  render() {
    let tituloGrafica = this.state.tituloGrafica;
    let botonesCruces = null;

    // let indicadoresGrafica = ["Fecha", "Participación", { role: "tooltip", type: "string", p: { html: true } }];

    let datosGrafica = [];

    if (this.state.datos_anios) {
      datosGrafica = this.state.datos_anios;
    }

    let charts = null;
    if (datosGrafica.length > 0) {
      let tituloV = "";
      let esPorcentaje = this.props.tabSeleccionado === 0 ? false : true;
      tituloV = this.props.messages[
        "empresas.fondos.concentrado.historico.grafica.etiqueta.y"
      ];

      let indicadoresGrafica = [];
      if (this.state.indicadores) {
        indicadoresGrafica = this.state.indicadores;
      } else {
        indicadoresGrafica = ["Fecha", "Participación"];
      }

      const coloresGrafica = [
        "#00aeef",
        "#194CD6",
        "#EF0053",
        "#6187ED",
        "#072E3C",
        "#af05e8",
        "#f26900",
        "#226022",
        "#ee06ff",
        "#49ba03",
        "#ff0000",
        "#ddca22",
        "#1f4474",
        "#804925",
        "#ac3880",
        "#62d488",
        "#fa8888",
        "#7303db",
        "#ba033a",
        "#636e72",
        "#225702",
        "#585003",
        "#03e9fa",
        "#6829fa",
        "#ff4181",
        "#263258",
        "#fd7d06",
        "#18b933",
        "#f4f813",
        "#e279dd",
        "#21fca8",
        "#756da1",
        "#dda863",
        "#35581f",
        "#476369",
      ];

      const tiempo = +new Date();


      charts = (
        <div className={["row", classes.width100].join(" ")}>
          <div className="col-sm-12 margin_top_30">
            <Charts
              key={tiempo}
              tiene_puntos={true}
              indicadores={[indicadoresGrafica]}
              data={datosGrafica}
              focusTarget="category"
              width={"100%"}
              height={"500px"}
              chartType={"LineChart"}
              colores={coloresGrafica}
              tituloV={tituloV}
              esPorcentaje={esPorcentaje}
              esCurrency={!esPorcentaje}
              // anioInicio={this.state.anioSeleccionado}
              // anioFin={this.state.anioSeleccionado}
              fechaInicio={this.state.fechaInicio}
              fechaFin={this.state.fechaFin}
              lineWidth={1}
              pointSize={5}
              />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div
          className={["row margen_top_30", classes.contenedorGrafica].join(" ")}
        >
          <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 "}>
            <span className={"texto_30 nunito regular nowrap"}>
              {tituloGrafica}
            </span>
          </div>
          <div
            className={
              "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sin_padding_RL float-right"
            }
          >
            <div className={" float-left"}>{botonesCruces}</div>{" "}
          </div>
          <div className="row col-12 sin_padding_RL">
            <FilterGraficaHistoricoConcentradoPreciosFondos
              modelo="modelo_1"
              datos={this.state.datos}
              setTop={(top) => this.onSetTop(top)}
              setFecha={(fechaInicio, fechaFin) =>
                this.onSetFecha(fechaInicio, fechaFin)
              }
              claveFondo={this.props.claveFondo}
              paramsForm={this.props.paramsForm}
            />
          </div>
          {charts}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    historico: state.fondos.fondosHistoricoGrafica,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraficaHistoricoFondos);

import React, { Component } from "react";
import classes from "./FormadorMercado.css";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";

import Auxs from "../../../hoc/auxs/Auxs";
import Rectangle from "../../../components/ui/rectangle/Rectangle";
import Box from "../../../components/ui/box/Box";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getUrlDocument } from "../../../stores/utils/utilities";
class FormadorMercado extends Component {
  state = {
    itemSelected: null,
    indexSelected: 0,
    solicitud: {
      leftIcon: "far fa-file-pdf",
      title: "Solicitud formador de mercado",
      subtitle: "Formato PDF",
      rightIcon: "fas fa-arrow-down",
      url: "https://biva.mx/documents/30877/34256/BIVA+Manual+de+Reglas+Operativas.pdf/d9391d6f-4bf4-5e76-3b33-19a1f89f0d5f"
    },
    messages: null,
    lan: getLocal(),
    togglebox: null,
    marketMakers: null,
    marketMakersSelected: null,
    infoMarketMakers: null
  };

  componentDidMount() {
    this.props.onFetchFormadorData();
    this.props.onInitIntl();
    this.props.onSolicitud();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.marketMakers !== prevProps.marketMakers) {
      let marketMakersSelected = null;
      if (this.props.marketMakers !== null && this.props.marketMakers.length !== undefined) {
        marketMakersSelected = this.props.marketMakers[0].identificador;
        this.props.getInfoMarketMakers(marketMakersSelected);
      }
      this.setState({
        marketMakers: this.props.marketMakers,
        marketMakersSelected: marketMakersSelected
      });
    }
    if (this.props.infoMarketMakers !== prevProps.infoMarketMakers) {
      this.setState({
        infoMarketMakers: this.props.infoMarketMakers
      });
    }
  }

  onGetDataCasaSelected = (i, identificador) => {
    this.props.getInfoMarketMakers(identificador);
    let indexSelected = this.state.indexSelected;
    if (i !== this.state.indexSelected) {
      indexSelected = i;
    }
    this.setState({ marketMakersSelected: identificador, indexSelected: indexSelected });
  };

  onPrintCasasInfo = data => {
    let casas = null;
    casas = data.map((item, i) => {
      return (
        <div
          className="col col-lg-3 text-center sin_padding_L"
          onClick={() => this.onGetDataCasaSelected(i, item.identificador)}
          key={i}
        >
          <div className={[classes.item_container, this.state.indexSelected === i ? classes.casaActiva : null].join(" ")}>
            <div className={["row"]}>
              <div className="col sin_padding_L">
                <div className={classes.img_container}>
                  {item.imagen ? <img src={getUrlDocument(item.imagen)} alt="" /> : <i class="far fa-file-image" />}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="width_cien sin_padding_L">
                <div className={classes.footer_container}>
                  <p className="montse regular texto_16">
                    <FormattedHTMLMessage id="operaciones.casasdebolsa.formadordemercado.titulo1" defaultMessage=" " />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return casas;
  };

  render() {
    const casas = this.props.marketMakers ? this.onPrintCasasInfo(this.props.marketMakers) : null;

    const infoCasas = this.state.infoMarketMakers ? <Rectangle data={this.state.infoMarketMakers} modelo="modelo_4" /> : null;

    let solicitud = null;
    if (this.props.solicitudes) {
      solicitud = this.props.solicitudes.map((solicitud, i) => {
        return <Box key={"solicitud_" + i} modelo="modelo_1" data={solicitud} />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1 className={["nunito", "texto_42"].join(" ")}>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.formadordemercado.titulo1" defaultMessage=" " />
                </h1>
              </div>
              <div>
                <div className="margen_top_30 row">
                  <div className={["texto_16 montse regular"].join(" ")}>
                    <FormattedHTMLMessage id="operaciones.casasdebolsa.formadordemercado.introduccion" defaultMessage=" " />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <div className="col sin_padding_L">
              <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.formadordemercado.subtitulo.nuestrosformadores" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className={["row"].join(" ")}>{casas}</div>
          <div className={["row", "margen_top_30"].join(" ")}>
            <div className="col sin_padding_L">{infoCasas}</div>
          </div>
          <div className="row margen_top_30">
            <div className="col sin_padding_L">
              <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.formadordemercado.subtitulo1" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col sin_padding_L">
              <div className={[classes.text_container, "texto_16", "montse"].join(" ")}>
                <p>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.formadordemercado.introduccion1" defaultMessage=" " />
                </p>
              </div>
            </div>
          </div>
          <div className="row margen_top_30">
            <div className="col sin_padding_L">
              <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage id="operaciones.casasdebolsa.formadordemercado.subtitulo2" defaultMessage=" " />
                </h2>
              </div>
            </div>
          </div>
          <div className="row margin_bottom_100">
            <div className="col sin_padding_L">
              <div className={[classes.boxContainer].join(" ")}>{solicitud}</div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    marketMakers: state.formadorMercado.marketMakers,
    infoMarketMakers: state.formadorMercado.infoMarketMakers,
    diccionario: state.intl.diccionario,
    solicitudes: state.documentos.solicitudFormadorMercado
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchFormadorData: () => {
      dispatch(action.getMarketMakers());
    },
    onInitIntl: () => {
      dispatch(action.initIntl(27));
    },
    onSolicitud: () => {
      dispatch(action.getSolicitudFormadorMercado());
    },
    getInfoMarketMakers: maker => {
      dispatch(action.getInfoMarketMakers(maker));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormadorMercado);

import React from "react";
import Auxs from "../auxs/Auxs";

import classes from "./Layout.css";

import Navigation from "../../components/ui/menu/Menu";
import RedesSociales from "../../components/ui/redesSociales/RedesSociales";
import ErrorCatch from "../../components/ui/errorCatch/ErrorCatch";
import Footer from "../../components/footer/Footer";

const Layout = props => (
  <Auxs>
    <Navigation />
    <ErrorCatch>
      <main className={classes.Main}>
        <RedesSociales />
        {props.children}
      </main>
    </ErrorCatch>
    <Footer />
  </Auxs>
);

export default Layout;

import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./ParticipacionEmision.css";
import Tabs from "../../../components/ui/tabs/Tabs";
import * as actions from "../../../stores/actions/index";
import Loader from "../../../components/loader/Loader";
import Fiduciarios from "./Fiduciarios";
import Representantes from "./Representantes";
import FilterFiduciarios from "../../../components/ui/filterParticipacionEmision/FilterFiduciarios";
import FilterRepresentantes from "../../../components/ui/filterParticipacionEmision/FilterRepresentantes";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import queryString from "query-string";

const tabsParticipantes = [
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Fiduciarios" : "Trustees "
  },
  {
    image: "",
    icon: "",
    title:
      getLan() === "es" ? "Representantes comunes" : "Common representative"
  }
];

class ParticipacionEmision extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    indexSelected: 0,
    activaTabInicial: 0,
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    nacional: true,
    sic: false,
    estatus_agenda: null,
    tipoDerecho: null,
    tipoInstrumento: null,
    fechaInicio: null,
    fechaFin: null,
    razonSocialSeleccionada: null,
    emisoraSeleccionada: null,
    razonSocialNombre: null,
    clave: null,
    tab: 0
  };

  componentDidMount() {
    this.props.onInitIntl();
    let paramsString = queryString.parse(this.props.location.search);

   
    
    let tab =
      paramsString["tab"] !== undefined && paramsString["tab"] !== "null"
        ? parseInt(paramsString["tab"])
        : 0;

    this.setState({
      indexSelected: tab,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.fiduciarios !== prevProps.fiduciarios) {
      this.setState({
        loader: false
      });
    }
    if (this.props.representantes !== prevProps.representantes) {
      this.setState({
        loader: false
      });
    }
  }

  onChangeItemSelected = (index = 0) => {
    let activePage = null;
    if (this.state.indexSelected === index) {
      activePage = this.state.activePage;
    } else {
      activePage = 1;
      this.changeHistory(null, null, index, null, 1);
    }

    this.setState({
      tab: index,
      indexSelected: index,
      activePage: activePage,
      loader: true,
      emisoraSeleccionada: null,
      razonSocialSeleccionada: null
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };
  onSetEmisora = (emisora, tipo, razonSocialNombre = null) => {
    let emisoraSeleccionada = null;
    let razonSocialSeleccionada = null;

    if (tipo === 1) {
      emisoraSeleccionada = emisora;
    } else if (tipo === 0) {
      razonSocialSeleccionada = emisora;
    }

    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      razonSocialSeleccionada: razonSocialSeleccionada,
      razonSocialNombre: razonSocialNombre,
      loader: true
    });
    this.changeHistory(
      emisoraSeleccionada,
      razonSocialSeleccionada,
      this.state.indexSelected,
      razonSocialNombre,
      1
    );
  };

  changeHistory(emisora, razonSocial, tab, razonSocialNombre, page) {
    this.props.history.push(
      `?emisora=${emisora}&razonSocial=${razonSocial}&razonSocialNombre=${
        razonSocialNombre ? razonSocialNombre : razonSocial ? razonSocial : null
      }&tab=${tab}&page=${page ? page : this.state.activePage}`
    );
  }

  changeHistoryReset(tab) {
    this.props.history.push(
      `?emisora=${null}&razonSocial=${null}&razonSocialNombre=${null}&tab=${tab}&page=1`
    );
  }

  changePage(page) {
    this.changeHistory(
      this.state.emisoraSeleccionada,
      this.state.razonSocialSeleccionada,
      this.state.indexSelected,
      this.state.razonSocialNombre,
      page
    );
  }

  render() {
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader loaderDisable={disable => this.setState({ loader: disable })} />
      );
    }

    let participacion = null;
    let filtro = null;
    if (this.state.indexSelected === 0) {
      participacion = (
        <Fiduciarios
          emisoraSeleccionada={this.state.emisoraSeleccionada}
          razonSocial={this.state.razonSocialSeleccionada}
          changePage={page => this.changePage(page)}
          location={this.props.location}
        />
      );
      filtro = (
        <div className="margen_top_10">
          <FilterFiduciarios
            modelo="modelo_1"
            itemsPerPage={this.state.itemsPerPage}
            setPage={page => this.onSetPage(page)}
            setEmisora={(emisora, tipo, razonSocialNombre) =>
              this.onSetEmisora(emisora, tipo, razonSocialNombre)
            }
            setRazonSocial={(emisora, tipo) => this.onSetEmisora(emisora, tipo)}
            changeHistoryReset={() => this.changeHistoryReset(0)}
            location={this.props.location}
          />
        </div>
      );
    } else {
      participacion = (
        <Representantes
          emisoraSeleccionada={this.state.emisoraSeleccionada}
          razonSocial={this.state.razonSocialSeleccionada}
          changePage={page => this.changePage(page)}
          location={this.props.location}
        />
      );
      filtro = (
        <div className="margen_top_10">
          <FilterRepresentantes
            modelo="modelo_1"
            itemsPerPage={this.state.itemsPerPage}
            setPage={page => this.onSetPage(page)}
            setEmisora={(emisora, tipo, razonSocialNombre) =>
              this.onSetEmisora(emisora, tipo, razonSocialNombre)
            }
            setRazonSocial={(emisora, tipo) => this.onSetEmisora(emisora, tipo)}
            changeHistoryReset={() => this.changeHistoryReset(1)}
            location={this.props.location}
          />
        </div>
      );
    }

    const tabMostrar = (
      <div className="row margen_top_30">
        <div
          className={[
            "texto_16 nunito semi_bold",
            classes.tabsContainer,
            classes.myCol
          ].join(" ")}
        >
          {getLan() === "es" ? "Mostrar:" : "View:"}
        </div>
        <div className={["col sin_padding", classes.myCol].join(" ")}>
          <Tabs
            data={tabsParticipantes}
            tipo={1}
            clicked={i => this.onChangeItemSelected(i)}
            activaTabInicial={this.state.indexSelected}
          />
        </div>
      </div>
    );

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          <h1 className={"texto_42 nunito regular"}>
            <FormattedHTMLMessage
              id="empresas.participacionporemision.titulo1"
              defaultMessage=" "
            />
          </h1>
          <div className="margen_top_30 margin_bottom_20 texto_30 nunito regular">
            <FormattedHTMLMessage
              id="empresas.participacionporemision.subtitulo1"
              defaultMessage=" "
            />
          </div>
          {filtro}
          <br />
          {tabMostrar}
          {participacion}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(actions.initIntl(55));
    },
    onInitFiduciarios: (size, page, emisora, nombre) => {
      dispatch(actions.filterFiduciarios(size, page, emisora, nombre));
    },
    onInitRepresentantes: (size, page, emisora) => {
      dispatch(actions.filterRepresentantes(size, page, emisora));
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    fiduciarios: state.emisoras.fiduciarios,
    representantes: state.emisoras.representantes
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipacionEmision);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../stores/actions/index";
import classes from "./CotizacionEmisoras.css";
import Boton from "../../../components/ui/boton/Boton";
import Filter from "../../../components/ui/filterCotizacionEmisora/FilterCotizacionEmisoras";
import Modal from "../../../components/ui/modal/Modal";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLan } from "../../../stores/utils/utilities";
import Loader from "../../../components/loader/Loader";
import Chart from "../../../components/TVChartContainer/Chart";
import SubFiltersCotizacion from "./subFiltersCotizacion";
import axios from "axios";
import queryString from "query-string"
import  { Redirect } from 'react-router-dom'

const dominio = process.env.REACT_APP_DOMINIO;

export default function CotizacionEmisoras(props) {
  const dispatch = useDispatch();
  const diccionario = useSelector((state) => state.intl.diccionario);
  const itemsPerPage = useSelector((state) => state.itemsPerPage);
  const [cotizaciones, setCotizaciones] = useState();
  const [cotizacionesSimpleList, setCotizacionesSimpleList] = useState();
  const [messages, setMessages] = useState();
  const [clave, setClave] = useState();
  const [idEmisora, setIdEmisora] = useState();
  const [nombreEmision, setNombreEmision] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isin, setIsin] = useState();
  const [isCotizacionesVisible, setIsCotizacionesVisible] = useState(false);
  const [redirect, setRedirect] = useState();

  function onToggleModalHandler() {
    setShowModal(prevShowModal => !prevShowModal);
  }
  function onSetClave(clave = "") {
    setClave(clave);
  };

  useEffect(() => {
    dispatch(actions.initIntl(44));
    let paramsString = queryString.parse(document.location.search);
    let fromTVTickerTape = paramsString['tvwidgetsymbol'];
    if (fromTVTickerTape) {
      let clave = fromTVTickerTape.split(":")[1];
      if (clave === 'F4GBIVA' || clave === 'FTBIVA') {
        setRedirect(<Redirect to="/informacion_de_mercado/indices"/>);
      }
    }
  }, []);

  useEffect(() => {
    setMessages(defineMessages(diccionario));
  }, [diccionario]);

  useEffect(() => {
    if (clave) {
      let url = `${dominio}/emisoras/empresas/${idEmisora}/emisiones?todos=true&cotizacion=true`;
      axios
        .get(url)
        .then(response => {
          setCotizaciones(response.data);
        })
        .catch(err => setCotizaciones([]));

      url = `${dominio}/emisoras/empresas/${idEmisora}/valores?cotizacion=true`;
      axios
        .get(url)
        .then(response => {
          setCotizacionesSimpleList(response.data);
        })
        .catch(err => setCotizacionesSimpleList([]));
      setIsCotizacionesVisible(true);
    } else {
      setIsCotizacionesVisible(false);
    }
  }, [clave]);


  let loader = null;
  if (loading) {
    loader = <Loader loaderDisable={onOff => setLoading(onOff)} time={1000}/>;
  }

  return (
    <IntlProvider locale={getLan()} messages={messages}>
      <React.Fragment>
        {redirect}
        {loader}
        <div className="row">
          <div className="col sin_padding_L">
            <div>
              <h1 className={[classes.main_title, "texto_42", "nunito"].join(" ")}>
                <FormattedHTMLMessage id="informacionmercado.cotizacion.titulo" defaultMessage=" " />
              </h1>
            </div>
          </div>
        </div>
        <div className="margen_top_30">
          <Filter
            modelo="modelo_1"
            itemsPerPage={itemsPerPage}
            setLoader={isLoader => setLoading(isLoader)}
            setClave={(clave) => onSetClave(clave)}
            setId={(id) => setIdEmisora(id)}
          />
        </div>
        {isCotizacionesVisible ? 
          <div className="margen_50">
            <span className={[classes.titulo_75pct, "texto_30 nunito"].join(" ")}>
              BIVA
            </span>
            <SubFiltersCotizacion
              key={idEmisora}
              emisoraId={idEmisora}
              emisoraDetalle={clave}
              setNombreEmision={nombreEmision => setNombreEmision(nombreEmision)}
              setIsin={isin => setIsin(isin)}
              valores_listados = {cotizaciones}
              valores_listados_min = {cotizacionesSimpleList}
            />
            <Chart
              isin={isin}
              nombreEmision={nombreEmision}
              claveEmisora={clave}
            />
            <span className={["texto_14 montse regular", classes.margin_left].join(" ")}>
              {getLan() === "es"
                ? "*La información presentada cuenta con un retraso de 15-20 minutos y pertenece a la operación en BIVA."
                : "*This information is delayed by 20 minutes and belongs to BIVA."}
            </span>
          </div>
        : null}

        <div className="margen_top_100 margin_bottom_300">
          <div className="col-12 sin_padding_L">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2 className={""}>
                <FormattedHTMLMessage id="informacionmercado.cotizacion.subtitulo" defaultMessage=" " />
              </h2>
            </div>
          </div>
          <div className="row margen_top_30">
            <Boton
              onClick={onToggleModalHandler.bind(this)}
              tipo={4}
              texto={messages ? messages["informacionmercado.cotizacion.btn1"] : ""}
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>
          <BackDrop show={showModal} clicked={onToggleModalHandler} />
          <Modal showModal={showModal} onCloseModal={onToggleModalHandler} area={"operations"} />
        </div>
      </React.Fragment>
    </ IntlProvider>
  );
  
}
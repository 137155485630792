import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";
import classes from "./Invertir.css";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";

// Pages
import ComoInvertir from "./comoInvertir/ComoInvertir";
import InstrumentosDisponibles from "./instrumentosDisponibles/InstrumentosDisponibles";
import NegociacionBiva from "./negociacionBiva/NegociacionBiva";
import SeguimientoInversiones from "./seguimientoInversiones/SeguimientoInversiones";
import EducacionBursatil from "./educacionBursatil/EducacionBursatil";
import Simulador from "./simulador/Simulador";
import Espacio from "./espacioBiva/EspacioBiva";
import DocumentMeta from "react-document-meta";
import Colocaciones from "./colocaciones/Colocaciones";
import { getLan } from "../../stores/utils/utilities";

const metaEs = {
  title: "Portal BIVA - Invierte",
  description:
    "Para invertir en el mercado de valores, el primer paso es elegir una casa de bolsa y abrir una cuenta de inversión, ellos te ayudaran a determinar tu perfil de riesgo y qué tipo de inversiones son adecuadas para ti. A través de tu cuenta de casa de bolsa podrás hacer la compra-venta de valores y a través de la misma se harán los movimientos de efectivo necesarios para que puedas comprar o vender. Al abrir tu cuenta deberás informarte de las comisiones que serán aplicables y los servicios contenidos en relación con la casa de bolsa.",
  meta: {
    name: {
      keywords:
        "invierte, invertir, mercado, valores, acciones, cotización, proceso, inversión, perfiles, inversionistas, costo, cuesta, cuánto, instrumentos, disponibles, negociación, inversiones, espacio, biva",
    },
  },
};

const metaEn = {
  title: "Portal BIVA - Invest",
  description:
    "In order to invest in the stock market, the first step is to choose a broker-dealer and open an investment account, they will help you determine your risk profile and what type of investments are right for you. Through your investment account you can buy and sell securities and the necessary cash movements will be made so that you can buy or sell. When you open your account, you must learn about the fees and the services that will be provided by the broker-dealer.",
  meta: {
    name: {
      keywords:
        "invest, stock market, process, investor, profiles, cost, available securities, trading in biva, investments, newcast, biva, how",
    },
  },
};

class Invertir extends Component {
  state = {
    menu: null,
    id_menu_activo: null,
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage: "invertir",
    };

    this.props.onInitGenericos(params);
    this.props.onInitCasas();
  }
  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu,
      });
    } else {
      this.setState({
        id_menu_activo: menu,
      });
    }
  };
  render() {
    const routes = (
      <Switch>
        <Route path="/invertir" exact component={ComoInvertir} />
        <Route path="/invertir/como_invertir" exact component={ComoInvertir} />
        <Route
          path="/invertir/instrumentos_disponibles"
          component={InstrumentosDisponibles}
        />
        <Route path="/invertir/negociacion_biva" component={NegociacionBiva} />
        <Route
          path="/invertir/seguimiento_inversiones"
          component={SeguimientoInversiones}
        />
        <Route
          path="/invertir/educacion_bursatil"
          component={EducacionBursatil}
        />
        <Route path="/invertir/simulador" component={Simulador} />
        <Route path="/invertir/espacio_biva" component={Espacio} />
        <Route path="/invertir/colocaciones" component={Colocaciones} />
      </Switch>
    );

    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
      <div className="container">
        <div className="row">
          <div className={["d-none"].join(" ")}>
            <Sidebar
              classIcon={["fas fa-gavel"].join(" ")}
              setMenu={(menu, tipo) => this.onSetMenu(menu, tipo)}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30 padding_bottom_200">
            <div className="row margin_bottom_30">
              <div className="col-12 sin_padding_L">
                <div className={classes.breadcums}>
                  <p>
                    <Breadcrum
                      idPage={this.state.id_menu_activo}
                      menu={this.state.menu}
                    />
                  </p>
                </div>
              </div>
            </div>
            {routes}
          </div>
        </div>
      </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitGenericos: (params) => {
      dispatch(action.initGenericos(params));
    },
    onInitCasas: () => {
      dispatch(action.initCasas());
    },
  };
};

export default connect(null, mapDispatchToProps)(Invertir);

import * as actionType from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  noticias: null,
  noticias_biva: null,
  otrasNoticias: null,
  Noticia: null
};

const setNoticias = (state, action) => {
  const newProperties = {
    noticias: action.noticias
  };
  return updateObject(state, newProperties);
};

const setNoticiasBiva = (state, action) => {
  const newProperties = {
    noticias_biva: action.noticias_biva
  };
  return updateObject(state, newProperties);
};

const setOtrasNoticias = (state, action) => {
  return updateObject(state, action);
};

const setNoticiaById = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FETCH_NOTICIAS_SUCCESS:
      return setNoticias(state, action);
    case actionType.FETCH_NOTICIAS_BIVA_SUCCESS:
      return setNoticiasBiva(state, action);
    case actionType.FETCH_OTRASNOTICIAS_SUCCESS:
      return setOtrasNoticias(state, action);
    case actionType.FETCH_NOTICIABYID_SUCCESS:
      return setNoticiaById(state, action);
    default:
      return state;
  }
};

export default reducer;

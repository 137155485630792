import React from "react";
import Construccion from "../../../components/construccion/Construccion";

export default function Amafore() {
  return (
    <Construccion
      titulo="Cuestionario AMAFORE"
      mensaje="Pronto podrás consultar los cuestionarios de la AMAFORE"
    />
  );
}

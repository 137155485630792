import React, { Component } from "react";
import classes from "./FilterAgendaInstrumentosAlternativos.css";
import { Form, Field } from "react-final-form";
import { changeDateFormat, getLan, sumaMes, formatDate, formatDateReverse, restaMes } from "../../../stores/utils/utilities";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";
import queryString from "query-string";

const periodos = [
    {
        id: 1,
        nombre: getLan() === "es" ? "Ultimo mes" : "Last month",
        checked: false
    },
    {
        id: 3,
        nombre: getLan() === "es" ? "Ultimos 3 meses" : "Last 3 months",
        checked: false
    },
    {
        id: 6,
        nombre: getLan() === "es" ? "Ultimos 6 meses" : "Last 6 months",
        checked: false
    }
];

const renderInput = ({
    input,
    id = null,
    classes = null,
    elementType = "input",
    ref,
    onInputChange,
    label,
    placeholder = null,
    disabled = false,
    isMultiple = null,
    options,
    defaultValue = null,
    checked = null,
    selectLabel,
    max = null,
    min = null,
    key = null,
}) => {
    const chooseOptionLabel =
        getLan() === "es" ? "Seleccione una opción" : "Select an option";

    const params = {
        input: {
            ...input,
            id: id ? id : input.name,
            placeholder: placeholder,
            max: max,
            min: min,
            className:
                classes && elementType === "checkbox"
                    ? classes
                    : [classes, "form-control"].join(" "),
            ref: ref,
            disabled: disabled,
            checked: checked,
            onChange: e => {
                input.onChange(e);
                onInputChange && onInputChange(e);
            }
        },
        defaultValue: defaultValue ? defaultValue : "",
        label: label,
        isMultiple: isMultiple,
        elementType: elementType,
        key: key,
        options: options,
        selectLabel: selectLabel ? selectLabel : chooseOptionLabel
    };

    return (
        <div className="form-group">
            {label && input.type !== "radio" && input.type !== "checkbox" ? (
                <label
                    htmlFor={input.name}
                    className={["texto_14", "montse", "regular nowrap"].join(" ")}
                >
                    {label}
                </label>
            ) : null}
            <Input config={params} />
        </div>
    );
};
var date_actual = new Date();
let fecha_minima = restaMes(date_actual, 6);
fecha_minima = formatDateReverse(fecha_minima.toLocaleString("en-GB").split(",")[0])
class FilterAgendaInstrumentosAlternativos extends Component {
    constructor(props) {
        super(props);
        this.formRef1 = React.createRef();
        this.fechaFinRef = React.createRef();
        this.escFunction = this.escFunction.bind(this);
    }


    state = {
        changeFechaFin: "fechafin1",
        esconderAutoComplete: false,
        tab: 0,
        periodoSeleccionado: null,
        toggleFilters: false,
        disableEnableButtons: true,
        inputPivot: false,
        selectedFilters: {
            estatus_agenda: [],
            tipos_derecho: [],
            tipos_instrumento: [],
            fechas: [],
            periodos: [],
            modoListado: []
        },
        form_filters: {
            tipos_instrumento: {
                value: []
            },
            tipos_derecho: {
                value: []
            },
            estatus_agenda: {
                value: []
            },
            fecha_inicio: {
                value: "",
                disabled: false,
                max: null,
                min: fecha_minima
            },
            fecha_fin: {
                value: "",
                disabled: false,
                max: null,
                min: fecha_minima
            },
            periodo: {
                value: "",
                disabled: false
            },
            modoListado: {
                value: []
            },
            errorDate: false,
            errorDateMonth: false,
            errorDateMonthMin: false,
            tipo: null
        },
        form_clave: {
            clave: {
                value: ""
            }
        },
        toggleInputs: false,
        valueClave: null,
        typeClaveSelected: null,
        emisoraSeleccionada: null,
        clave: null,
        estatus_agenda: [],
        nacional: true,
        sic: false
    };

    escFunction(event) {
        let visible = false;

        if (event.keyCode === 27) {
            visible = true;
        }
        this.setState({
            esconderAutoComplete: visible
        });
    }

    collapseSearch(event) {
        let visible = false;
        if (event.target.name !== "search_field") {
            visible = true;
        }

        this.setState({
            esconderAutoComplete: visible
        });
    }

    componentDidMount() {
        window.addEventListener("click", e => this.collapseSearch(e));
        window.addEventListener("keydown", this.escFunction, false);
        let paramsString = queryString.parse(this.props.location.search);
        let emisora =
            paramsString["emisora_id"] !== undefined &&
                paramsString["emisora_id"] !== "null"
                ? paramsString["emisora_id"]
                : null;
        let clave =
            paramsString["clave"] !== undefined && paramsString["clave"] !== "null"
                ? paramsString["clave"]
                : null;
        let nacional =
            paramsString["nacional"] === "null"
                ? null
                : paramsString["nacional"] === "true"
                    ? true
                    : false;
        let sic =
            paramsString["sic"] === "null"
                ? null
                : paramsString["sic"] === "true"
                    ? true
                    : false;
        if (clave) {
            this.setState({
                toggleInputs: true,
                toggleFilters: false,
                disableEnableButtons: false,
                typeClaveSelected: 1,
                typeClaveSelected: emisora ? 1 : 0,
                valueClave: clave
            });
        }
        if (!nacional && !sic) {
            nacional = true;
            sic = false;
        }
        this.setState({
            tab: nacional ? 0 : 1,
            nacional: nacional,
            sic: sic,
            disableEnableButtons: true
        });
        this.props.onFilterEstatus(null);
        this.onGetinitValuesForDerIns(nacional, sic);
        this.onFilterModoListadoHandler();
    }
    componentWillUnmount() {
        window.addEventListener("click", e => this.collapseSearch(e));
        window.addEventListener("keydown", this.escFunction, false);
    }

    componentWillMount() {
        this.callReduxAction = debounce(500, this.callReduxAction);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.estatus_agenda !== this.props.estatus_agenda) {
            this.setState({
                estatus_agenda: this.props.estatus_agenda
            });
        }

        if (prevProps.tab !== this.props.tab) {
            let nacional = false;
            let sic = false;
            if (this.props.tab === 0) {
                nacional = true;
            }
            if (this.props.tab === 1) {
                sic = true;
            }

            this.setState({
                tab: this.props.tab,
                nacional: nacional,
                sic: sic,
                disableEnableButtons: true
            });

            this.onResetFilterHandler(false, false);
            this.onInputChangeModelOne(null, true, true, true, nacional, sic);
            // this.onGetinitValuesForDerIns(nacional, sic);
        }
    }

    printFrameHandler = () => {
        const input = this.state.toggleFilters
            ? this.showFiltersSelectedHandler()
            : this.showInputHandler();

        const closeLabel = getLan() === "es" ? "Cerrar Filtros" : "Close";
        const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";

        return (
            <div className="row">
                <div className="col sin_padding">
                    <div className="row">
                        <div
                            className={[
                                "col-3",
                                "col-lg-3",
                                "text-center",
                                classes.no_col_padding_model_one
                            ].join(" ")}
                        >
                            <div
                                className={[
                                    "texto_btn_cerrar",
                                    classes.toggle_button_container_model_one,
                                    this.state.toggleFilters
                                        ? classes.no_borders_filter_button_model_one
                                        : null
                                ].join(" ")}
                                onClick={
                                    this.state.disableEnableButtons ? this.onToggleFilters : null
                                }
                            >
                                <button
                                    type="button"
                                    className="btn"
                                    disabled={!this.state.disableEnableButtons}
                                >
                                    <i className="fas fa-filter" />
                                    <span>
                                        {this.state.toggleFilters ? closeLabel : filterLabel}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div
                            className={[
                                "col",
                                this.state.toggleFilters ? "col-lg-9" : "col-lg-8",
                                classes.no_col_padding_model_one
                            ].join(" ")}
                        >
                            <div className={classes.filters_selected_container_model_one}>
                                {input}
                            </div>
                        </div>
                        <div
                            className={[
                                this.state.toggleFilters ? classes.hidden : null,
                                "col-1",
                                "col-lg-1",
                                "text-center",
                                classes.no_col_padding_model_one
                            ].join(" ")}
                        >
                            <div
                                className={[
                                    classes.search_button_container_model_one,
                                    this.state.toggleFilters
                                        ? classes.no_border_search_button_model_one
                                        : null
                                ].join(" ")}
                            >
                                <button type="buttom" className={["btn"].join(" ")} disabled>
                                    <i className="fas fa-search" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={[
                            "row",
                            classes.main_filter_container_model_one,
                            !this.state.toggleFilters ? classes.hidden : null
                        ].join(" ")}
                    >
                        <div
                            className={["col", classes.no_col_padding_model_one].join(" ")}
                        >
                            {this.modelOne()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    onPrintEstatusHandler = estatus => {
        const form = this.state.form_filters;
        const optionValue = `${estatus.id}_${estatus.nombre}`;

        return (
            <Field
                name="Estatus"
                id={estatus.nombre}
                type="checkbox"
                elementType="checkbox"
                value={optionValue}
                classes="form-check-input"
                label={estatus.nombre}
                checked={form.estatus_agenda.value.find(item => item === optionValue)}
                onInputChange={event => this.onInputSelectHandler(event, 2)}
                component={renderInput}
            />
        );
    };

    onPrintPeriodoHandler = periodo => {
        const form = this.state.form_filters;
        const optionValue = `${periodo.id}_${periodo.nombre}`;

        return (
            <Field
                name="Periodo"
                id={periodo.nombre}
                type="radio"
                disabled={this.state.form_filters.periodo.disabled}
                elementType="radio2"
                value={optionValue}
                classes="form-check-input"
                label={periodo.nombre}
                checked={form.periodo.value === optionValue}
                onInputChange={event =>
                    this.onInputSelectHandler(event, 3, null, false)
                }
                component={renderInput}
            />
        );
    };


    onPrintTipoInstrumentoHandler = tipoInstrumento => {
        const form = this.state.form_filters;
        const optionValue = `${tipoInstrumento.id}_${tipoInstrumento.nombre}`;

        return (
            <Field
                name="TipoInstrumento"
                id={tipoInstrumento.nombre}
                type="checkbox"
                elementType="checkbox"
                value={optionValue}
                classes="form-check-input"
                label={tipoInstrumento.nombre}
                checked={form.tipos_instrumento.value.find(item => item === optionValue)}
                onInputChange={event => this.onInputSelectHandler(event, 1)}
                component={renderInput}
            />
        );
    };

    onPrintListadoHandler = listado => {
        const form = this.state.form_filters;
        const optionValue = `${listado.id}_${listado.nombre}`;

        return (
            <Field
                name="ModalidadListado"
                id={listado.nombre}
                type="radio"
                // disabled={this.state.form_filters.periodo.disabled}
                elementType="radio2"
                value={optionValue}
                classes="form-check-input"
                label={listado.nombre}
                checked={form.modoListado.value === optionValue}
                onInputChange={event => this.onInputSelectHandler(event, 6)}
                component={renderInput}
            />
        );
    };

    onPrepareOptionHandler = type => {
        let options = [];
        if (type && type.length > 0) {
            type.forEach(element => {
                options.push({
                    nombre: element.nombre,
                    id: `${element.id}_${element.nombre}`
                });
            });
        }
        return options;
    };

    modelOne = () => {
        let labelDate = null;
        if (this.state.nacional) {
            labelDate = getLan() === "es" ? "Fecha ex date" : "Exdate";
        } else {
            labelDate =
                getLan() === "es"
                    ? "Fecha ex date o Efectos"
                    : "Exdate or Effective date";
        }
        return (
            <div className={classes.filters_container_model_one}>
                <div className={classes.filter_container_model_one}>
                    <Form
                        onSubmit={this.onSubmitFilterModelOneHandler}
                        initialValues={{
                            tipos_instrumento: this.state.form_filters.tipos_instrumento
                                .value,
                            tipos_derecho: this.state.form_filters.tipos_derecho.value,
                            estatus_agenda: this.state.form_filters.estatus_agenda.value,
                            fecha_inicio: this.state.form_filters.fecha_inicio.value,
                            fecha_fin: this.state.form_filters.fecha_fin.value,
                            periodo: this.state.form_filters.periodo.value,
                            modoListado: this.state.form_filters.modoListado.value,
                        }}
                        render={({
                            optionsInstrumentos = this.props.instrumentos
                                // ? this.onPrepareOptionHandler(this.props.instrumentos)
                                ? this.props.instrumentos
                                : [],
                            optionsTipoDerecho = this.props.tipos_derecho
                                ? this.onPrepareOptionHandler(this.props.tipos_derecho)
                                : [],
                            estatus_agenda = this.props.estatus_agenda
                                ? this.props.estatus_agenda
                                : [],
                            periodos_agenda = periodos ? periodos : [],
                            optionsmodoListado = this.props.modoListado ? this.props.modoListado : [],
                            form
                        }) => (
                            <form
                                ref={this.formRef1}
                                onSubmit={this.onSubmitFilterModelOneHandler}
                            >
                                <div className="row">
                                    <div
                                        className={[
                                            "col-12",
                                            "col-sm-6 col-md-6 col-lg-4",
                                            this.props.tab === 1 ? classes.hidden : null
                                        ].join(" ")}
                                    >
                                        <Field
                                            name="tipos_derecho"
                                            id="tipos_derecho"
                                            label={
                                                getLan() === "es"
                                                    ? "Tipo de derecho"
                                                    : "Corporate action type"
                                            }
                                            elementType="select"
                                            isMultiple={true}
                                            classes="custom-select montse texto_14 regular color_gris"
                                            options={optionsTipoDerecho}
                                            onInputChange={event =>
                                                this.onInputSelectHandler(event, 0)
                                            }
                                            component={renderInput}
                                        />
                                    </div>
                                    {/* <div
                                        className={[
                                            "col-12",
                                            "col-sm-6 col-md-6 col-lg-4",
                                            optionsTipoDerecho.length === 0 ||
                                                this.state.form_filters.tipos_derecho.value === ""
                                                ? classes.hidden
                                                : classes.hidden //null
                                        ].join(" ")}
                                    >
                                        <Field
                                            name="tipos_instrumento"
                                            id="tipos_instrumento"
                                            label={
                                                getLan() === "es"
                                                    ? "Tipo de instrumento"
                                                    : "Security type"
                                            }
                                            elementType="select"
                                            type="select"
                                            isMultiple={true}
                                            classes="custom-select montse texto_14 regular color_gris"
                                            options={optionsInstrumentos}
                                            onInputChange={event =>
                                                this.onInputSelectHandler(event, 1)
                                            }
                                            component={renderInput}
                                        />
                                    </div> */}

                                    <div
                                        className={["col-12 col-sm-12 col-md-6 col-lg-4"].join(" ")}
                                    >
                                        <div className="row">
                                            <div className="col-12 sin_padding_L sin_padding_R">
                                                <span className="montse texto_14 texto_negro regular">
                                                    {getLan() === "es" ? "Tipo de instrumento" : "Security type"}
                                                </span>
                                            </div>
                                            <div
                                                className={[
                                                    "col-12 sin_padding_L sin_padding_R",
                                                    classes.padding_izquierdo
                                                ].join(" ")}
                                            >
                                                {optionsInstrumentos.map((tipo, i) => {
                                                    return (
                                                        <div key={"tipo" + i} className="row">
                                                            {this.onPrintTipoInstrumentoHandler(tipo)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={[
                                            "col-12",
                                            "col-sm-12 col-md-6 col-lg-4 sin_padding"
                                        ].join(" ")}
                                    >
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-lg-6 sin_padding_R">
                                                <Field
                                                    name="fecha_inicio"
                                                    id="fecha_inicio"
                                                    type="date"
                                                    classes={classes.fechaInicio}
                                                    disabled={
                                                        this.state.form_filters.fecha_inicio.disabled
                                                    }
                                                    max={
                                                        this.state.form_filters.fecha_inicio.max
                                                    }
                                                    min={
                                                        this.state.form_filters.fecha_inicio.min
                                                    }
                                                    label={labelDate}
                                                    onInputChange={event =>
                                                        this.onInputSelectHandler(event, 4)
                                                    }
                                                    component={renderInput}
                                                />
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-6 sin_padding_L">
                                                <Field
                                                    name="fecha_fin"
                                                    id="fecha_fin"
                                                    type="date"
                                                    ref={this.fechaFinRef}
                                                    classes={[
                                                        classes.fechaFin,
                                                        !this.state.nacional ? classes.moveFechFin : null
                                                    ].join(" ")}
                                                    label="Fecha fin"
                                                    disabled={this.state.form_filters.fecha_fin.disabled}
                                                    max={
                                                        this.state.form_filters.fecha_fin.max
                                                    }
                                                    min={
                                                        this.state.form_filters.fecha_fin.min
                                                    }
                                                    onInputChange={event =>
                                                        this.onInputSelectHandler(event, 5)
                                                    }
                                                    component={renderInput}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={[
                                                "col",
                                                classes.errorContainer,
                                                !this.state.form_filters.errorDate
                                                    ? classes.hidden
                                                    : null
                                            ].join(" ")}
                                        >
                                            <p className={"texto_14 montse regular"}>
                                                {getLan() === "es"
                                                    ? "La fecha final debe ser mayor o igual a la fecha inicial."
                                                    : "End date must be greater than or equal to start date."}
                                            </p>
                                        </div>
                                        <div
                                            className={[
                                                "col",
                                                classes.errorContainer,
                                                !this.state.form_filters.errorDateMonth
                                                    ? classes.hidden
                                                    : null
                                            ].join(" ")}
                                        >
                                            <p className={"texto_14 montse regular"}>
                                                {getLan() === "es"
                                                    ? "La fecha final debe ser menor o igual a "
                                                    : "End date must be less than or equal to "}
                                                {this.state.form_filters.fecha_fin ? formatDate(this.state.form_filters.fecha_fin.max) + "." : null}
                                            </p>
                                        </div>
                                        <div
                                            className={[
                                                "col",
                                                classes.errorContainer,
                                                !this.state.form_filters.errorDateMonthMin
                                                    ? classes.hidden
                                                    : null
                                            ].join(" ")}
                                        >
                                            <p className={"texto_14 montse regular"}>
                                                {getLan() === "es"
                                                    ? "Las fechas deben ser mayor o igual a "
                                                    : "Dates must be greater than or equal to "}
                                                {formatDate(fecha_minima) + "."}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={["margin_top_30 col-12 col-sm-12 col-md-6 col-lg-4"].join(" ")}
                                    >
                                        <div className="row">
                                            <div className="col-12 sin_padding_L sin_padding_R">
                                                <span className="montse texto_14 regular">
                                                    {getLan() === "es" ? "Estado" : "Status"}
                                                </span>
                                            </div>
                                            <div
                                                className={[
                                                    "col-12 sin_padding_L sin_padding_R",
                                                    classes.padding_izquierdo
                                                ].join(" ")}
                                            >
                                                {estatus_agenda.map(est => {
                                                    return (
                                                        <div className="row">
                                                            {this.onPrintEstatusHandler(est)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="margin_top_30 col-12 col-sm-12 col-md-6 col-lg-4">
                                        <div className="row">
                                            <div className="col-12 sin_padding_L sin_padding_R">
                                                <span className="montse texto_14 regular">
                                                    {getLan() === "es" ? "Periodo" : "Period"}
                                                </span>
                                            </div>
                                            <div
                                                className={[
                                                    "col-12 sin_padding_L sin_padding_R",
                                                    classes.padding_izquierdo
                                                ].join(" ")}
                                            >
                                                {periodos_agenda.map((per, i) => {
                                                    return (
                                                        <div key={"periodo" + i} className="row">
                                                            {this.onPrintPeriodoHandler(per)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="margin_top_30 col-12 col-sm-12 col-md-6 col-lg-4">
                                        <div className="row">
                                            <div className="col-12 sin_padding_L sin_padding_R">
                                                <span className="montse texto_14 texto_negro regular">
                                                    {getLan() === "es" ? "Modalidad de listado" : "Listing mode"}
                                                </span>
                                            </div>
                                            <div
                                                className={[
                                                    "col-12 sin_padding_L sin_padding_R",
                                                    classes.padding_izquierdo
                                                ].join(" ")}
                                            >
                                                {optionsmodoListado.map((listado, i) => {
                                                    return (
                                                        <div key={"listado" + i} className="row">
                                                            {this.onPrintListadoHandler(listado)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {this.sectionButtonHandler(form)}
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    };

    sectionButtonHandler = form => {
        const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
                    <button
                        type="button"
                        className={[
                            "btn",
                            classes.button_model_one,
                            "montse",
                            "texto_14"
                        ].join(" ")}
                        onClick={() => this.onResetFilterHandler(true)}
                    >
                        <i className="fas fa-redo-alt" />
                        {clearLabel}
                    </button>
                </div>
            </div>
        );
    };

    onSubmitFilterModelOneHandler = event => {
        event.preventDefault();
    };

    onSubmitSearchModelOneHandler = event => {
        event.preventDefault();
    };

    printChange = e => {
        this.callReduxAction(e.target.value);
    };

    callReduxAction = value => {
        this.onInputChangeModelOne(value);
    };

    onInputChangeModelOne = (
        event = null,
        eraser = null,
        tipo = null,
        consulta = true,
        nacional = null,
        sic = null
    ) => {
        const value = event;
        const form_clave = this.state.form_clave;
        form_clave.clave.value = value;
        let disabled = false;
        if (value !== "" && value !== null) {
            this.props.onFilterAgendaByClave(
                value,
                this.state.nacional,
                this.state.sic
            );
        } else {
            disabled = true;
        }

        if (tipo) {
            this.props.setEmisora(null, 3);
            // this.onResetFilterHandler(true, false)
            this.props.onFilterTipoDerecho(nacional === null ? this.state.nacional : nacional, sic === null ? this.state.sic : sic, null);
            this.props.onFilterInstrumentos(nacional === null ? this.state.nacional : nacional, sic === null ? this.state.sic : sic, null);
        }

        if (eraser) {
            this.setState(prevState => {
                return {
                    toggleInputs: !prevState.toggleInputs,
                    form_clave: form_clave,
                    disableEnableButtons: disabled,
                    typeClaveSelected: null,
                    valueClave: null,
                    emisoraSeleccionada: null,
                    clave: null,
                    toggleInputs: false
                };
            });

            const [
                valuesTipoDerecho,
                valuesTipoInstrumentos,
                valuesEstatusAgenda,
                valuesPeriodos,
                valuesFechaInicio,
                valuesFechaFin,
                valuesModoListado
            ] = this.onGetAllValuesFormFilter();

            const params = {
                tipoDerecho: valuesTipoDerecho,
                tipoInstrumento: valuesTipoInstrumentos,
                estatusAgenda: valuesEstatusAgenda,
                periodo: valuesPeriodos,
                fechaInicio: valuesFechaInicio,
                fechaFin: valuesFechaFin,
                emisoraSeleccionada: null,
                clave: null,
                size: this.props.itemsPerPage,
                page: 1
            };

            if (consulta) {
                this.props.setFilters(params);
            }
        } else {
            this.setState({ form_clave: form_clave, disableEnableButtons: true });
        }
    };

    onResetFilterHandler = (reset = null, setFilters = true) => {
        const form = this.state.form_filters;
        const selectedFilters = this.state.selectedFilters;
        let inputPivot = false;

        // Limpiando valores para campo tipo de derecho
        form.tipos_derecho.value = [];
        selectedFilters.tipos_derecho = [];
        // Limpiando valores para campo tipo de instrumentos
        form.tipos_instrumento.value = [];
        selectedFilters.tipos_instrumento = [];
        // Limpiando valores para campo estatus agenda
        form.estatus_agenda.value = [];
        selectedFilters.estatus_agenda = [];
        // Limpiando valores para campo periodo
        form.periodo.value = "";
        form.periodo.disabled = false;
        selectedFilters.periodos = [];
        // Limpiando valores para campo fechainicio y fecha fin
        form.fecha_inicio.value = "";
        form.fecha_inicio.disabled = false;
        form.fecha_inicio.max = null;
        form.fecha_inicio.min = fecha_minima;
        form.fecha_fin.max = null;
        form.fecha_fin.min = fecha_minima;
        form.fecha_fin.value = "";
        form.fecha_fin.disabled = false;
        selectedFilters.fechas = [];
        form.errorDate = false;
        form.errorDateMonth = false;
        form.errorDateMonthMin = false;
        //Limpiando valores modo listado
        form.modoListado.value = [];
        selectedFilters.modoListado = [];

        // Filtra los valores por defecto cuando se limpia el formulario haciendo click en el boton limpiar
        if (reset) {
            this.onGetinitValuesForDerIns(this.state.nacional, this.state.sic);
            this.onInputChangeModelOne(null, true, null, null);
        }

        const params = {
            tipoDerecho: null,
            tipoInstrumento: null,
            estatusAgenda: null,
            periodo: null,
            fechaInicio: null,
            fechaFin: null,
            modoListado: null,
            emisoraSeleccionada: null,
            clave: null,
            size: this.props.itemsPerPage,
            page: 1
        };
        if (setFilters) {
            this.props.setFilters(params);
        }
        this.props.setPage(1);

        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters,
            inputPivot: inputPivot
        });
    };

    onToggleFilters = () => {
        this.setState(prevState => {
            return {
                toggleFilters: !prevState.toggleFilters
            };
        });
    };

    onGetinitValuesForDerIns = (nacional = null, sic = null, clave = null) => {
        // Filtrando valores para campo tipo de derecho
        this.props.onFilterTipoDerecho(nacional, sic, ["1", "2", "3", "4"], null);
        // Filtrando valores para campo tipo de instrumento
        this.props.onFilterInstrumentos(nacional, sic, null, null);
    };

    // Verifica si las fechas son validas
    // Verifica si las fechas ingresadas soon validas para realizar la busqueda
    onCheckIfDatesAreValidHandler = (inicio, fin) => {
        return inicio > fin;
    };

    // Retorna la fecha formateada
    devolverFechaFormateada = fecha => {
        const fecha_r = new Date(fecha).toISOString().split("T")[0];
        return fecha_r;
    };

    // Retorna el id
    onSplitValueHandler = options => {
        let newValues = null;

        if (!Array.isArray(options)) {
            newValues = options.split("_")[0];
            return newValues;
        } else {
            newValues = [];
            options.forEach(option => {
                newValues.push(option.split("_")[0]);
            });
            return [...newValues];
        }
    };

    onFilterModoListadoHandler = (instrumento = null, sector = null, subSector = null, ramo = null, subRamo = null) => {
        const params = {
            instrumento: instrumento,
            sector: sector,
            subSector: subSector,
            ramo: ramo,
            subRamo: subRamo,
            biva: this.props.biva,
            canceladas: this.props.canceladas
        };
        this.props.onFilterModoListado(params);
    };

    // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
    onFilterDataByEvent = () => {
        const [
            valuesTipoDerecho,
            valuesTipoInstrumentos,
            valuesEstatusAgenda,
            valuesPeriodos,
            valuesFechaInicio,
            valuesFechaFin,
            valuesModoListado
        ] = this.onGetAllValuesFormFilter();
        let valuesTipoInstrumentos2 = valuesTipoInstrumentos;
        const params = {
            size: this.props.itemsPerPage,
            page: 1,
            tipoDerecho: valuesTipoDerecho,
            tipoInstrumento: valuesTipoInstrumentos2,
            estatusAgenda: valuesEstatusAgenda,
            periodo: valuesPeriodos,
            fechaInicio: valuesFechaInicio,
            fechaFin: valuesFechaFin,
            modoListado: valuesModoListado,
            emisoraSeleccionada: this.state.emisoraSeleccionada,
            clave: this.state.clave,
            tab: this.props.tab
        };
        if (!this.state.form_filters.errorDate && !this.state.form_filters.errorDateMonth && !this.state.form_filters.errorDateMonthMin) {
            this.props.setFilters(params);
            this.props.setPage(1);
        }

        let wasFiltered = false;
        // Se invocan a las funciones el componente padre (BancoInformacion.js) para setear los valores correspondientes
        // Configurando el valor del campo tipo de derecho
        this.props.setTipoDerecho(valuesTipoDerecho);
        // Configurando el valor del campo tipo de instrumentos
        this.props.setTipoInstrumento(valuesTipoInstrumentos2);
        // Configurando el valor del campo estatus agenda
        this.props.setEstatus(valuesEstatusAgenda);
        // Configurando el valor del campo fecha inicio
        this.props.setFechaInicio(valuesFechaInicio);
        // Configurando el valor del campo fecha fin
        this.props.setFechaFin(valuesFechaFin);

        if (
            valuesTipoDerecho.length > 0 ||
            valuesTipoInstrumentos2.length > 0 ||
            valuesEstatusAgenda.length > 0 ||
            valuesFechaInicio !== "" ||
            valuesFechaFin !== ""
        ) {
            wasFiltered = true;
        }

        this.props.onSetWasFiltered(wasFiltered);
    };

    // Retorna todos los valores de los campos del formulario.
    onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
        const form = this.state.form_filters;

        if (inputName && newStateValues) {
            form[`${inputName}`].value = newStateValues;
        }

        // Se obtiene el valor del campo tipo derecho
        const valuesTipoDerecho =
            form.tipos_derecho.value && form.tipos_derecho.value.length > 0
                ? this.onSplitValueHandler(form.tipos_derecho.value)
                : [];
        // Se obtiene el valor del campo minstrumentos
        const valuesTipoInstrumentos =
            form.tipos_instrumento.value && form.tipos_instrumento.value.length > 0
                ? this.onSplitValueHandler(form.tipos_instrumento.value)
                : [];
        // Se obtiene el valor del campo estatus agenda
        const valuesEstatusAgenda =
            form.estatus_agenda.value && form.estatus_agenda.value.length > 0
                ? this.onSplitValueHandler(form.estatus_agenda.value)
                : [];
        // Se obtiene el valor del campo periodos
        const valuesPeriodos =
            form.periodo.value && form.periodo.value !== ""
                ? this.onSplitValueHandler(form.periodo.value)
                : "";
        // Se obtiene el valor del fecha inicio
        const valuesFechaInicio =
            form.fecha_inicio.value && form.fecha_inicio.value !== ""
                ? form.fecha_inicio.value
                : "";
        // Se obtiene el valor del campo fecha fin
        const valuesFechaFin =
            form.fecha_fin.value && form.fecha_fin.value !== ""
                ? form.fecha_fin.value
                : "";

        // Se obtiene el valor de tipo valor
        const valuesModoListado =
            form.modoListado.value && form.modoListado.value.length > 0 ? this.onSplitValueHandler(form.modoListado.value) : [];


        return [
            valuesTipoDerecho,
            valuesTipoInstrumentos,
            valuesEstatusAgenda,
            valuesPeriodos,
            valuesFechaInicio,
            valuesFechaFin,
            valuesModoListado
        ];
    };

    // Retorna el valor, etiqueta de una valor recibido como parametro
    onGetValueLabelAndTypeHandler = (value, type) => {
        let newValue = null;
        let newLabel = null;

        if (type === 4 || type === 5) {
            [newValue] = changeDateFormat(value);
            newLabel = type !== 4 ? "Fecha inicio" : "Fecha fin";
        } else {
            newValue = value.split("_")[0];
            newLabel = value.split("_")[1];
        }
        return [newValue, newLabel];
    };

    // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
    onGetNameStateFilterByInputHandler = type => {
        const form = this.state.form_filters;
        const selectedFilters = this.state.selectedFilters;

        switch (type) {
            case 0:
                return [
                    "tipos_derecho",
                    form.tipos_derecho.value,
                    selectedFilters.tipos_derecho
                ];
            case 1:
                return [
                    "tipos_instrumento",
                    form.tipos_instrumento.value,
                    selectedFilters.tipos_instrumento
                ];
            case 2:
                return [
                    "estatus_agenda",
                    form.estatus_agenda.value,
                    selectedFilters.estatus_agenda
                ];
            case 3:
                return [
                    "periodo",
                    form.periodo.value,
                    selectedFilters.periodo,
                    "periodos"
                ];
            case 4:
                return [
                    "fecha_inicio",
                    form.fecha_inicio.value,
                    selectedFilters.fechas,
                    "fechas"
                ];
            case 5:
                return [
                    "fecha_fin",
                    form.fecha_fin.value,
                    selectedFilters.fechas,
                    "fechas"
                ];
            case 6:
                return ["modoListado", form.modoListado.value, selectedFilters.modoListado];
        }
    };

    // Agrega o elimina valores de estado para un campo del formulario
    onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
        let newStateValues = null;

        if (type === 3 || type === 4 || type === 5 || type === 6) {
            if (values !== "") {
                newStateValues = values;
            }
        } else {
            newStateValues = [];
            newStateValues = [...inputOptions];
            const stateExists = newStateValues.find(option => option === values);
            if (stateExists) {
                newStateValues = newStateValues.filter(option => option !== values);
            } else {
                if (values && values !== "") {
                    newStateValues.push(values);
                } else {
                    newStateValues = [];
                }
            }
        }

        return newStateValues;
    };

    // Agrega o elimina filtros a la barra de filtro seleccionados
    onChangeFilterHandler = (
        filterOptions = null,
        values = null,
        label = null,
        type = null
    ) => {
        let newFilterValues = [];

        if (type === 3 || type === 6) {
            if (values !== "") {
                newFilterValues.push({ label: label, value: values, type: type });
            }
        } else if (type === 4 || type === 5) {
            // Fechas y periodos
            newFilterValues = [...filterOptions];
            const filterExists = newFilterValues.find(
                option => option.label === label
            );
            if (filterExists) {
                newFilterValues = newFilterValues.filter(
                    option => option.label !== label
                );
                if (values && values !== "") {
                    newFilterValues.push({ label: label, value: values, type: type });
                }
            } else {
                newFilterValues.push({ label: label, value: values, type: type });
            }
        } else {
            newFilterValues = [...filterOptions];
            const filterExists = newFilterValues.find(
                option => option.label === label
            );
            if (filterExists) {
                newFilterValues = newFilterValues.filter(
                    option => option.label !== label
                );
            } else {
                if (values !== "") {
                    newFilterValues.push({ label: label, value: values, type: type });
                } else {
                    newFilterValues = [];
                }
            }
        }
        return newFilterValues;
    };

    onInputSelectHandler = (
        event,
        type = null,
        recursive = null,
        consultar = true,
        disablePeriod = true
    ) => {
        let actualValue = null;
        if (!recursive) {
            actualValue =
                type !== 0 && type !== 1 ? event.target.value : event.target.options;
        } else {
            actualValue = event;
        }
        if (type === 1) {
            actualValue = event.target.value;
        }
        let form = this.state.form_filters;
        let selectedFilters = this.state.selectedFilters;
        let inputPivot = this.state.inputPivot;
        let errorDate = form.errorDate;
        let errorDateMonth = form.errorDateMonth;
        let errorDateMonthMin = form.errorDateMonthMin;

        let newValue = null;
        let newLabel = null;
        // Solo para el campo tipo de instrumento y tipo derecho
        if (type !== 0) {
            [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(
                actualValue,
                type
            );
        }

        let newStateValues = null;
        let newFilterValues = null;
        let [
            inputName,
            stateValues,
            filterValues,
            otherInputName
        ] = this.onGetNameStateFilterByInputHandler(type);

        // Solo para el campo tipo de instrumento y tipo derecho
        if (type === 0) {
            const options = [...actualValue];
            const indexSelected =
                actualValue.selectedIndex && actualValue.selectedIndex !== -1
                    ? actualValue.selectedIndex
                    : 0;

            newValue = options[indexSelected].value;
            newLabel = options[indexSelected].value.split("_")[1];
            actualValue = newValue;
        }

        // Configura el estado y el filtro seleccionado para cada input
        newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);

        newValue = type === 4 || type === 5 ? newValue : actualValue;
        newFilterValues = this.onChangeFilterHandler(
            filterValues,
            newValue,
            newLabel,
            type
        );

        form[`${inputName}`].value = newStateValues;
        if (type === 4) {
            errorDateMonth = false;

            // fecha inicio añade max y min a fecha fin
            form.fecha_fin.min = newStateValues ? form.fecha_inicio.value : fecha_minima;
            const fecha_max = newStateValues ? sumaMes(form.fecha_inicio.value, 6) : null;
            form.fecha_fin.max = null;
            form.fecha_inicio.min = fecha_minima;
            form.fecha_inicio.max = null;
            form.errorDateMonth = false;
            form.errorDateMonthMin = false;

        }
        if (type === 5) {
            errorDateMonth = false;
            form.errorDateMonth = false;
            form.errorDateMonthMin = false;

            // fecha fin añade max y min a fecha inicio
            form.fecha_inicio.min = fecha_minima;
            form.fecha_inicio.max = null;
            const fecha_max = form.fecha_inicio.value ? sumaMes(form.fecha_inicio.value, 6) : null;
            form.fecha_fin.max = null;
            form.fecha_fin.min = form.fecha_inicio.value ? form.fecha_inicio.value : fecha_minima;

        }

        if (form.fecha_inicio.value) {
            if (new Date(form.fecha_inicio.value) < new Date(fecha_minima)) {
                form.errorDateMonthMin = true;
            }
        }
        if (form.fecha_fin.value) {
            if (new Date(form.fecha_fin.value) < new Date(fecha_minima)) {
                form.errorDateMonthMin = true;
            }
        }
        selectedFilters[
            `${otherInputName ? otherInputName : inputName}`
        ] = newFilterValues;

        // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
        const [
            valuesTipoDerecho,
            valuesTipoInstrumentos,
            valuesEstatusAgenda,
            valuesPeriodos,
            valuesFechaInicio,
            valuesFechaFin,
            valuesModoListado
        ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

        if (type === 0) {
            // Tipo derecho
            if (!inputPivot || inputPivot === "firstpath") {
                inputPivot = "firstpath";
                // Limpiando valores para campo tipo de instrumento
                form.tipos_instrumento.value = [];
                selectedFilters.tipos_instrumento = [];
                // Filtrando valores para campo tipo de instrumentos
                this.props.onFilterInstrumentos(
                    this.state.nacional,
                    this.state.sic,
                    valuesTipoDerecho,
                    this.state.clave
                );

                if (valuesTipoDerecho.length === 0) {
                    inputPivot = null;
                }
            }
        } else if (type === 1) {
            // Tipo de documento
            if (!inputPivot || inputPivot === "secondpath") {
                inputPivot = "secondpath";
                // Limpiando valores para campo tipo de derecho
                form.tipos_derecho.value = [];
                selectedFilters.tipos_derecho = [];
                // Filtrando valores para campo tipo de derecho
                this.props.onFilterTipoDerecho(
                    this.state.nacional,
                    this.state.sic,
                    valuesTipoInstrumentos,
                    this.state.clave
                );

                if (valuesTipoInstrumentos.length === 0) {
                    inputPivot = null;
                }
            }
        } else if (type === 2) {
            // Estatus agenda
            // Pass
        } else if (type === 3) {
            // Periodos
            if (valuesPeriodos !== "") {
                let fechaFinal = new Date();
                let fechaInicial = fechaFinal.setMonth(
                    fechaFinal.getMonth() - parseInt(valuesPeriodos)
                );
                fechaFinal = this.devolverFechaFormateada(new Date());
                fechaInicial = this.devolverFechaFormateada(fechaInicial);

                // Desabilitando campo de fecha inicio y fecha fin
                const disabledDates = valuesPeriodos === "" ? false : true;
                form.fecha_inicio.disabled = disabledDates;
                form.fecha_fin.disabled = disabledDates;

                form.periodo.disabled = false;
                // configurando valores para fecha inicio
                this.onInputSelectHandler(fechaInicial, 4, true, false, false);
                // configurando valores para fecha fin
                this.onInputSelectHandler(fechaFinal, 5, true, true, false);
            }
        } else if (type === 4) {
            // Fecha inicio
            // Se verifica si los valores actuales para las fechas son distintos de vacio, de ser así se bloquea el
            // campos de periodo
            const disableValue =
                actualValue === "" &&
                    (!form.fecha_fin.value || form.fecha_fin.value === "")
                    ? false
                    : true;
            form.periodo.disabled = disablePeriod ? disableValue : false;
            // Se verifican si la sfechas osn validas
            if (actualValue !== "" && form.fecha_fin.value !== "") {
                errorDate = this.onCheckIfDatesAreValidHandler(
                    actualValue,
                    form.fecha_fin.value
                );
            } else {
                errorDate = false;
            }
            form.errorDate = errorDate;
        } else if (type === 5) {
            // Fecha fin
            // Se verifica si los valores actuales para las fechas son distintos de vacio, de ser así se bloquea el
            // campos de periodo
            const disableValue =
                actualValue === "" &&
                    (!form.fecha_inicio.value || form.fecha_inicio.value === "")
                    ? false
                    : true;
            form.periodo.disabled = disablePeriod ? disableValue : false;

            // Se verifican si la sfechas osn validas
            if (form.fecha_inicio.value !== "" && actualValue !== "") {
                errorDate = this.onCheckIfDatesAreValidHandler(
                    form.fecha_inicio.value,
                    actualValue
                );
            } else {
                errorDate = false;
            }
            form.errorDate = errorDate;
        }


        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters,
            inputPivot: inputPivot
        });
        if (consultar) {
            this.onFilterDataByEvent();
        }
        return event;
    };

    onDeleteFilterHandler = item => {
        let form = this.state.form_filters;
        let selectedFilters = this.state.selectedFilters;
        let inputPivot = this.state.inputPivot;

        let newStateValues = null;
        let newFilterValues = null;
        let [
            inputName,
            stateValues,
            filterValues,
            otherInputName
        ] = this.onGetNameStateFilterByInputHandler(item.type);

        // Configura el estado y el filtro seleccionado para cada input
        const auxValue =
            item.type === 3 || item.type === 4 || item.type === 5 || item.type === 6 ? "" : item.value;
        newStateValues = this.onChangeStateHandler(
            stateValues,
            auxValue,
            item.type
        );
        newFilterValues = this.onChangeFilterHandler(
            filterValues,
            auxValue,
            item.label,
            item.type
        );

        form[`${inputName}`].value = newStateValues;
        selectedFilters[
            `${otherInputName ? otherInputName : inputName}`
        ] = newFilterValues;

        // Filtra por evento. Cada vez que hay un cambio en cualquiera de los inputs del formulario
        const [
            valuesTipoDerecho,
            valuesTipoInstrumentos,
            valuesEstatusAgenda,
            valuesPeriodos,
            valuesFechaInicio,
            valuesFechaFin,
            valuesModoListado
        ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

        if (item.type === 0) {
            // Tipo derecho
            if (inputPivot === "firstpath") {
                inputPivot = false;
                // Limpiando valores para campo tipo de instrumento
                form.tipos_instrumento.value = [];
                selectedFilters.tipos_instrumento = [];
                // Filtrando valores para campo tipo de instrumentos
                this.props.onFilterInstrumentos(
                    this.state.nacional,
                    this.state.sic,
                    valuesTipoDerecho,
                    this.state.clave
                );
            }
        } else if (item.type === 1) {
            // Tipo de documento
            if (inputPivot === "secondpath") {
                inputPivot = false;
                // Limpiando valores para campo tipo de derecho
                form.tipos_derecho.value = [];
                selectedFilters.tipos_derecho = [];
                // Filtrando valores para campo tipo de derecho
                this.props.onFilterTipoDerecho(
                    this.state.nacional,
                    this.state.sic,
                    valuesTipoInstrumentos,
                    this.state.clave
                );
            }
        } else if (item.type === 2) {
            // Estatus agenda
            // Pass
        } else if (item.type === 3) {
            // Periodos
            // Configurando valores para campo fecha inicio y fecha fin
            form.fecha_inicio.value = "";
            form.fecha_fin.value = "";
            selectedFilters.fechas = [];
            // Desabilitando campo de fecha inicio y fecha fin
            form.fecha_inicio.disabled = false;
            form.fecha_fin.disabled = false;
        } else if (item.type === 4 || item.type === 5) {
            // Fecha inicio y Fecha fin
            form.periodo.disabled = false;
            form.errorDate = false;
            // Fecha inicio y Fecha fin
            form.periodo.disabled =
                (form.fecha_inicio.value === null || form.fecha_inicio.value === "") && (form.fecha_fin.value === null || form.fecha_fin.value === "")
                    ? false
                    : true;
            form.errorDate = false;
        }

        this.setState({
            form_filters: form,
            selectedFilters: selectedFilters,
            inputPivot: inputPivot
        });

        this.onFilterDataByEvent();
    };

    showFiltersSelectedHandler = () => {
        const selectedFilters = this.state.selectedFilters;

        let filters = null;
        const filterList = [
            ...selectedFilters.tipos_derecho,
            ...selectedFilters.tipos_instrumento,
            ...selectedFilters.estatus_agenda,
            ...selectedFilters.periodos,
            ...selectedFilters.fechas,
            ...selectedFilters.modoListado
        ];

        filters = (
            <div
                className={classes.filter_container_outside_model_one}
                id="allFitersContainers"
            >
                {filterList.map((item, i) => {
                    let closeButton = null;
                    let valueToshow = item.label;

                    if (item.type === 4 || item.type === 5) {
                        valueToshow = item.value;

                        if (selectedFilters.periodos.length === 0) {
                            closeButton = (
                                <span className={classes.filter_icon_button_model_one}>
                                    <i
                                        className="far fa-times-circle"
                                        onClick={() => this.onDeleteFilterHandler(item)}
                                    />
                                </span>
                            );
                        }
                    } else {
                        closeButton = (
                            <span className={classes.filter_icon_button_model_one}>
                                <i
                                    className="far fa-times-circle"
                                    onClick={() => this.onDeleteFilterHandler(item)}
                                />
                            </span>
                        );
                    }

                    return (
                        <div
                            className={classes.filter_container_model_one}
                            key={`val-${i}`}
                        >
                            <span
                                className={["montse", classes.filter_text_model_one].join(" ")}
                            >
                                {valueToshow}
                            </span>
                            {closeButton}
                        </div>
                    );
                })}
            </div>
        );
        return filters;
    };

    onToggleFilterInputOptionSelected = (tipo = null) => {
        if (tipo) {
            this.props.setEmisora(null, 3);
            this.props.onFilterTipoDerecho(this.state.nacional, this.state.sic, null);
            this.props.onFilterInstrumentos(
                this.state.nacional,
                this.state.sic,
                null
            );
        }
        this.setState(prevState => {
            return {
                toggleInputs: !prevState.toggleInputs
            };
        });
    };

    onPrintAutoCompleteHandler = () => {
        const listado = this.props.listadoClaves;
        return listado.map(item => (
            <li
                className="list-group-item"
                onClick={() => this.onClaveSelectedHandler(item[1], item[0], 1)}
            >
                {item[1]}
            </li>
        ));
    };

    onClaveSelectedHandler = (clave, id = null, option = 0) => {
        this.onToggleFilterInputOptionSelected();
        let emisoraSeleccionada = null;
        let claveSeleccionada = null;
        if (option === 0) {
            this.props.setEmisora(clave, 2);
            this.props.onFilterTipoDerecho(this.state.nacional, this.state.sic, null);
            this.props.onFilterInstrumentos(
                this.state.nacional,
                this.state.sic,
                null
            );
            claveSeleccionada = clave;
            emisoraSeleccionada = null;
        } else {
            this.props.onFilterTipoDerecho(this.state.nacional, this.state.sic, null, clave);
            this.props.onFilterInstrumentos(this.state.nacional, this.state.sic, null, clave);
            this.props.setEmisora(id, 1);
            claveSeleccionada = clave;
            emisoraSeleccionada = clave;
        }
        const params = {
            tipoDerecho: null,
            tipoInstrumento: ["1", "2", "3", "4"],
            estatusAgenda: null,
            periodo: null,
            fechaInicio: null,
            fechaFin: null,
            modoListado: null,
            emisoraSeleccionada: emisoraSeleccionada,
            clave: claveSeleccionada,
            size: this.props.itemsPerPage,
            page: 1
        };

        this.props.setFilters(params);
        this.props.setPage(1);

        this.setState({
            typeClaveSelected: option,
            valueClave: clave,
            emisoraSeleccionada: emisoraSeleccionada,
            clave: claveSeleccionada
        });
    };

    onPrintOptionSelectedHandler = value => {
        let infoToShow = null;
        const textFound =
            getLan() === "es"
                ? "Ver todos los resultados para"
                : "See all results for";
        if (this.state.typeClaveSelected === 0) {
            infoToShow = `${textFound}: ${this.state.valueClave}`;
        } else if (this.state.valueClave && this.state.valueClave !== "") {
            infoToShow = this.state.valueClave;
        }

        return (
            <div
                className={[
                    classes.selectedOptionContainer,
                    !this.state.toggleInputs ? classes.hidden : null
                ].join(" ")}
            >
                <span className={classes.filterContainer}>
                    <span
                        className={[classes.filterText, "montse texto_14 regular"].join(
                            " "
                        )}
                    >
                        {infoToShow}
                    </span>
                    <span
                        className={classes.filterIconButton}
                        onClick={() => this.onInputChangeModelOne(null, true, true)}
                    >
                        <i className="far fa-times-circle" />
                    </span>
                </span>
            </div>
        );
    };

    showInputHandler = () => {
        const value = this.state.form_clave.clave.value;

        const listado = this.props.listadoClaves
            ? this.onPrintAutoCompleteHandler()
            : null;

        const textFound =
            getLan() === "es"
                ? "Ver todos los resultados para"
                : "See all results for";
        const textNotFound =
            getLan() === "es"
                ? "No existen resultados para la búsqueda"
                : "There are no search results";

        const firstlistItem =
            listado && listado.length > 0 ? (
                <li
                    className="list-group-item"
                    onClick={() => {
                        this.onClaveSelectedHandler(value);
                    }}
                >
                    {`${textFound}: ${value}`}
                </li>
            ) : (
                <li className="list-group-item">{`${textNotFound}: ${value}`}</li>
            );

        return (
            <Auxs>
                {this.onPrintOptionSelectedHandler()}
                <div
                    className={[
                        classes.search_input_container_model_one,
                        this.state.toggleInputs ? classes.hidden : null
                    ].join(" ")}
                >
                    <Form
                        onSubmit={this.onSubmitSearchModelOneHandler}
                        initialValues={{
                            search_field: this.state.form_clave.clave.value
                        }}
                        render={() => (
                            <form
                                ref={this.formSearch}
                                onSubmit={this.onSubmitSearchModelOneHandler}
                            >
                                <Field
                                    name="search_field"
                                    id="search_field"
                                    type="text"
                                    elementType="input"
                                    placeholder={
                                        getLan() === "es"
                                            ? "Buscar por clave de cotización o ISIN"
                                            : "Search by ticker or ISIN"
                                    }
                                    component={renderInput}
                                    onInputChange={event => this.printChange(event)}
                                />
                            </form>
                        )}
                    />
                    <div
                        className={[
                            classes.autoCompleteContainer,
                            "list-group",
                            !this.state.form_clave.clave.value ||
                                this.state.form_clave.clave.value === ""
                                ? classes.hidden
                                : null,
                            this.state.esconderAutoComplete ? classes.hidden : null
                        ].join(" ")}
                    >
                        {firstlistItem}
                        {listado}
                    </div>
                </div>
            </Auxs>
        );
    };

    render() {
        const filter = this.printFrameHandler();
        return filter;
    }
}

const mapStateToProps = state => {
    return {
        tipos_derecho: state.agenda.tipos_derecho,
        // instrumentos: state.agenda.tipos_instrumento,
        instrumentos: state.instrumentosAlternativos.tipoInstrumentoAgenda,
        listadoClaves: state.instrumentosAlternativos.listadoClavesAgenda,
        estatus_agenda: state.agenda.estatus,
        modoListado: state.instrumentosAlternativos.modoListadoAgenda,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onInitAgendaDerechos: (
            size,
            page,
            nacional,
            sic,
            tipoDerecho,
            tipoInstrumento,
            estatus,
            fechaInicio,
            fechaFin,
            emisora,
            clave,
            modoListado
        ) => {
            dispatch(
                actions.initAgendaDerechos(
                    size,
                    page,
                    nacional,
                    sic,
                    tipoDerecho,
                    tipoInstrumento,
                    estatus,
                    fechaInicio,
                    fechaFin,
                    emisora,
                    clave,
                    modoListado,
                    true
                )
            );
        },
        onFilterEstatus: () => {
            dispatch(actions.initEstatusAgenda(true)); //nacional = true para traer estatus solo nacionales
        },
        onFilterTipoDerecho: (nacional, sic, emisora, clave) => {
            dispatch(actions.initTipoDerechoAgenda(nacional, sic, emisora, clave, true));
        },
        onFilterInstrumentos: (nacional, sic, empresa, clave) => {
            dispatch(actions.filterTipoInstrumentoAgenda(empresa));
        },
        onFilterAgendaByClave: (clave, nacional, sic) => {
            dispatch(actions.filterClavesAgendaInsAlt(clave, nacional, sic));
        },
        onFilterModoListado: params => {
            dispatch(actions.filterModoListadoAgenda(params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterAgendaInstrumentosAlternativos);

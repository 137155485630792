import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider, defineMessages } from "react-intl";
import * as action from "../../../../stores/actions/index";
import { NavLink } from "react-router-dom";
import hojaVerde from "../../../../assets/icons/sustentable/hoja-verde-2.png";

import classes from "./Capitulo.css";
import { getLocal } from "../../../../stores/utils/utilities";
import Box from "../../../../components/ui/box/Box";
const lan = getLocal();


const coloresEnlaces = ["#009ee2", "#029aa8", "#029773", "#00893b", "#aec925"];

export default function Guia(props) {
  const dispatch = useDispatch();

  const [messages, setMessages] = useState(null);
  const [capitulos, setCapitulos] = useState([]);
  const [guia, setGuia] = useState(null);

  const traduccionRedux = useSelector((state) => state.intl.diccionario);
  const guiaRedux = useSelector((state) => state.sustentable.guiaLink);

  const capitulosRedux = useSelector(
    (state) => state.sustentable.capitulosGuia
  );

  useEffect(() => {
    dispatch(action.initIntl(102));
    dispatch(action.getCapitulosGuia());
    dispatch(action.getLinkGuiaAsg());
  }, []);

  useEffect(() => {
    if (traduccionRedux) {
      setMessages(defineMessages(traduccionRedux));
    }
  }, [traduccionRedux]);


  useEffect(() => {
    if (capitulosRedux) {
      setCapitulos(capitulosRedux);
    }
  }, [capitulosRedux]);

  useEffect(() => {
    if (guiaRedux) {
      setGuia(guiaRedux);
    }
  }, [guiaRedux]);



  const getEnlaces = () => {
    let divs = null;

    if (capitulos && capitulos.length > 0) {
      divs = capitulos.map((itm, i) => {
        const iEnlace = i > coloresEnlaces.length ? 0 : i;
        const color = coloresEnlaces[iEnlace];
        const style = {
          backgroundColor: color
        }
        return (
          <NavLink to={itm.url} className={classes.cuadroEnlace} style={style}>
            <span className={"texto_blanco bold nunito texto_24"}>
              {itm.title}
            </span>
          </NavLink>
        );
      });
    }

    return divs;
  };

  let links = null;
    if (guia) {
      links = guia.map((guia_obj, i) => {
        return <Box key={"guiaLink_" + i} modelo="modelo_1" data={guia_obj}   />;
      });
    }

  return (
    <IntlProvider locale={lan} messages={messages}>
      <div className="row margen_top_30">
        <div className="margen_top_20 col-12 sin_padding">
          <div
            className={[
              "main_subtitle",
              "nunito",
              "containerTituloSg",
              "sin_padding",
            ].join(" ")}
          >
            <img
              src={hojaVerde}
              alt="bivasg"
              className={["slash", "imgSg"].join(" ")}
            />
            <h1
              className={[
                "tituloSg texto_50 bold montse texto_verde_oscuro",
                classes.textCapitulos,
              ].join(" ")}
            >
              {messages ? messages["subtitulo.capitulos"] : null}
            </h1>
          </div>
        </div>
        <div
          className={["margen_top_30 col-12 sin_padding", classes.flex].join(
            " "
          )}
        >
          {getEnlaces()}
        </div>
        <div className="sin_padding margen_top_50">
          <div className={[classes.text_container, "justificado"].join(" ")}>
            <span className="texto_verde_oscuro bold montse texto_16">
              {messages ? messages["nota"] : ""}
            </span>
          </div>
        </div>
        <div className="sin_padding margen_top_50 width_cien">
          <div className="float-left">
            {links}
          </div>
        </div>
      </div>
    </IntlProvider>
  );
}

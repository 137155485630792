import React, { Component } from "react";
import classes from "./Item.css";
import { NavLink } from "react-router-dom";

class Items extends Component {
  createMenuHandler = (menu, label = "submenu") => {
    let tag = label;
    if (this.hasSubMenu(menu)) {
      return this.processMenu(menu, tag);
    }
    return null;
  };

  processMenu = (menu, label) => {
    return menu.submenu.map((item, index) => {
      return this.hasSubMenu(item)
        ? this.printItemsWithSubMenu(index, item, label)
        : this.printItemsWithOutSubMenu(index, item);
    });
  };

  printItemsWithSubMenu = (index, item, label) => {
    const params = this.getParams();
    const tag = label.concat(`-${index}`);
    const subMenu = this.printSubMenuItems(item, tag, index);
    return (
      <li key={index} className={classes.li_sidebar}>
        <a href={`#${tag}`} {...params.data} {...params.classes} >
          {item.name}
        </a>
        {subMenu}
      </li>
    );
  };

  printItemsWithOutSubMenu = (index, item) => {
    return (
      <li key={index} className={[classes.li_sidebar_sub, "nunito texto_16 bold"].join(" ")}>
        <NavLink to={item.to}>{item.name}</NavLink>
      </li>
    );
  };

  printSubMenuItems = (item, label) => {
    return (
      <ul className="collapse list-unstyled" id={label}>
        {item.submenu.map((itemData, index) => {
          return this.createMenuHandler(itemData, label)
            ? this.printItemsWithSubMenu(index, itemData, label)
            : this.printItemsWithOutSubMenu(index, itemData);
        })}
      </ul>
    );
  };

  getParams = () => {
    return {
      data: { "data-toggle": "collapse", "aria-expanded": "false" },
      classes: { className: [classes.caret, classes.dropdown_toggle, "nunito texto_16 bold"].join(" ") }
    };
  };

  hasSubMenu = item => {
    return item.submenu ? true : false;
  };

  render() {
    const bodySidebar = this.props.data
      ? this.createMenuHandler(this.props.data)
      : null;

    let headerSidebar = null;
    if (this.props.data) {
      const to = this.props.data.to;
      headerSidebar = (
        <div className={classes.sidebar_header}>
          <div className="row">
            <div className="col col-lg-2">
              <div className={classes.logo_container}>
                <i className={[this.props.iconClass, "icon"].join(" ")} />
              </div>
            </div>
            <div className="col col-lg-10">
              <h3 className={[classes.title, "nunito texto_20 bold"].join(" ")}>
                <NavLink to={to}>{this.props.data.name}</NavLink>
              </h3>
            </div>
          </div>
          <hr className={classes.separator} />
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col col-lg-12">
          <div className={classes.components_container}>
            <div className={classes.wrapper}>
              <nav id="sidebar" className={classes.sidebar}>
                <ul className={["list-unstyled", classes.ul_parent].join(" ")}>
                  {headerSidebar}
                  {bodySidebar}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Items;

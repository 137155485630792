import * as actionTypes from './actionTypes';

const setGenericos= (genericos) => {
    return {
        type: actionTypes.SET_INIT_GENRICOS_SUCCESS,
        genericos: genericos
    }
}

export const initGenericos = (genericos) => {
    return setGenericos(genericos)
}
import React, { Component } from "react";
import classes from "./FilterCalificadorasReportes.css";
import { connect } from "react-redux";

import { Form, Field } from "react-final-form";
import { getLan } from "../../../stores/utils/utilities";

import Auxs from "../../../hoc/auxs/Auxs";
import Input from "../input/Input";

import * as action from "../../../stores/actions/index";

const renderInput = ({
  input,
  id = null,
  label,
  clases = null,
  options = null,
  type = null,
  elementType,
  placeholder = null,
  selectLabel,
  onInputChange
}) => {
  const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";

  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: clases ? clases : null,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    type: type,
    label: label,
    options: options,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel
  };

  return (
    <div className="form-group">
      {label ? (
        <label className={["texto_14", "montse", "regular"].join(" ")} for={input.name}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterCalificadorasReportes extends Component {
  constructor(props) {
    super(props);
    this.filterCalificadoras = React.createRef();
  }

  state = {
    filterLoading: false,
    toggleFilter: false,
    emisoraId: null,
    selectedFilters: {
      periodo: [],
      ejercicio: []
    },
    form_filters: {
      periodo: {
        value: ""
      },
      ejercicio: {
        value: ""
      }
    },
    calificadoraId: null,
    itemsPerPage: 10
  };

  componentDidMount() {
    const calificadoraId = this.props.calificadora;

    this.setState({
      calificadoraId: calificadoraId
    });
  }

  // Retorna el valor, etiqueta y tipo  de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newValue = null;
    let newLabel = null;

    newValue = value;

    return [newValue, newLabel];
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = () => {
    const form = this.state.form_filters;

    // Se obtiene el valor del periodo
    const valuesPeriodo = form.periodo.value && form.periodo.value !== "" ? form.periodo.value : null;
    // Se obtiene el valor del ejercicio
    const valuesEjercicio = form.ejercicio.value && form.ejercicio.value !== "" ? form.ejercicio.value : null;

    return [valuesPeriodo, valuesEjercicio];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["periodo", form.periodo.value, selectedFilters.periodo];
      case 1:
        return ["ejercicio", form.ejercicio.value, selectedFilters.ejercicio];
    }
  };

  // Se ejecuta una consulta cada vez que se realiza un cambio en cualquiera de los inputs del formulrio
  onFilterDataByEvent = () => {
    const form = this.state.form_filters;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [valuesPeriodo, valuesEjercicio] = this.onGetAllValuesFormFilter();

    const params = {
      periodo: valuesPeriodo,
      ejercicio: valuesEjercicio,
      page: 1,
      reset: false
    };
    this.props.setParams(params);

    let wasFiltered = false;
    // Se invoca la funcion del componente padre (ReporteMensual.js) para setear los valores correspondientes
    // Se configura el valor del periodo
    if (valuesPeriodo !== "") {
      this.props.setPeriodoFilter(valuesPeriodo);
      wasFiltered = true;
    }
    // Se configura el valor del ejercicio
    if (valuesEjercicio !== "") {
      this.props.setEjercicioFilter(valuesEjercicio);
      wasFiltered = true;
    }

    this.props.wasFiltered(wasFiltered);
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (values !== "") {
      newStateValues = values;
    }
    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];

    if (values !== "") {
      newFilterValues.push({ label: label, value: values, type: type });
    }
    return newFilterValues;
  };

  onInputSelectedHandler = (event, type = null) => {
    let actualValue = event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newValue = null;
    let newLabel = null;
    let newStateValues = null;
    let newFilterValues = null;

    [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(actualValue, type);

    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, newValue, type);
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newValue, type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

     // Se obtienen todos los valores de los campos del formulario (Filtro)
     const [valuesPeriodo, valuesEjercicio] = this.onGetAllValuesFormFilter();
    
    if (type===0){
      // Periodo actualiza valores de Ejercicio
      if (valuesEjercicio !== "") {
        // Limpiando valores campo ejercicio
        form.ejercicio.value = "";
        selectedFilters.ejercicio = [];
        // Filtrando valores ejercicio
        this.props.onFilterEjercicioCalificadoras(this.state.calificadoraId,valuesPeriodo);
      }
    }
    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  onDeleteSelectedFilterHandler = item => {
    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;

    let newStateValues = null;
    let newFilterValues = null;

    let [inputName, stateValues, filterValues] = this.onGetNameStateFilterByInputHandler(item.type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, "", item.type);
    newFilterValues = this.onChangeFilterHandler(filterValues, "", item.label, item.type);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${inputName}`] = newFilterValues;

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });

    this.onFilterDataByEvent();
  };

  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [...this.state.selectedFilters.periodo, ...this.state.selectedFilters.ejercicio];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>{filter.label}</span>
              <span className={classes.filterIconButton} onClick={() => this.onDeleteSelectedFilterHandler(filter)}>
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onSubmitFilter = event => {};

  onResetFilter = form_filter => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    form.periodo.value = "";
    selectedFilters.periodo = [];
    form.ejercicio.value = "";
    selectedFilters.ejercicio = [];

    const params = {
      periodo: null,
      ejercicio: null,
      page: 1,
      reset: true
    };
    this.props.setParams(params);

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters
    });
  };

  onToggleFilterHandler = () => {
      if (!this.state.toggleFilter && !this.state.filterLoading) {
        const calificadoraId = this.props.calificadora;

        this.props.onFilterEjercicioCalificadoras(calificadoraId);
        this.props.onFilterPeriodoCalificadoras(calificadoraId);
        this.setState({
            filterLoading:true
        })
      }
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  printFrameModelHandler = () => {
    const selectedFilters =
      this.state.selectedFilters.periodo.length > 0 || this.state.selectedFilters.ejercicio.length > 0
        ? this.onPrintSelectedFiltersHandler()
        : null;

    const closeLabel = getLan() === "es" ? "Cerrar Filtros" : "Close";
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";

    return (
      <Auxs>
        <div
          className={["row", classes.firstSectionFilterContainer, this.state.toggleFilter ? classes.noBorderRadius : null].join(
            " "
          )}
        >
          <div className="col col-lg-3 text-center sin_padding_L">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button type="button" className="btn" onClick={this.onToggleFilterHandler}>
                <i className="fas fa-filter" />
                <span>{!this.state.toggleFilter ? filterLabel : closeLabel}</span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={["row", classes.secondSectionFilterContainer, !this.state.toggleFilter ? classes.hidden : null].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrepareSelectoptionsHandler = selectOptions => {
    let options = [];
    const items = selectOptions;
    items.forEach(item => {
      options.push({ nombre: item, id: item });
    });

    return options;
  };

  onGetOptionsEjercicioHandler = () => {
    let options = [];
    const items = this.props.ejercicioCalificadora;
    items.forEach(item => {
      options.push({ nombre: item, id: item });
    });

    return options;
  };

  onPrintSubFiltersHandler = () => {
    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitFilter}
              initialValues={{
                periodo: this.state.form_filters.periodo.value,
                ejercicio: this.state.form_filters.ejercicio.value
              }}
              render={({
                optionsPeriodo = this.props.periodoCalificadora
                  ? this.onPrepareSelectoptionsHandler(this.props.periodoCalificadora)
                  : [],
                optionsEjercicio = this.props.ejercicioCalificadora
                  ? this.onPrepareSelectoptionsHandler(this.props.ejercicioCalificadora)
                  : [],
                handleSubmit,
                form
              }) => (
                <form ref={this.filterCalificadoras} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 ">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4">
                          <Field
                            name="periodo"
                            id="periodo"
                            elementType="select"
                            clases="custom-select"
                            label={getLan() === "es" ? "Mes" : "Month"}
                            options={optionsPeriodo}
                            onInputChange={event => this.onInputSelectedHandler(event, 0)}
                            component={renderInput}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <Field
                            name="ejercicio"
                            id="ejercicio"
                            elementType="select"
                            clases="custom-select"
                            label={getLan() === "es" ? "Año" : "Year"}
                            options={optionsEjercicio}
                            onInputChange={event => this.onInputSelectedHandler(event, 1)}
                            component={renderInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  sectionButtonsHandler = form => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilter(form)}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandler();
    return <div className="margin_bottom_30">{filters}</div>;
  }
}

const mapStateToProps = state => {
  return {
    periodoCalificadora: state.emisoras.periodo_calificadora,
    ejercicioCalificadora: state.emisoras.ejercicio_calificadora
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterEjercicioCalificadoras: (calificadoraId, periodo) => {
      dispatch(action.fetchEjercicioByCalificadora(calificadoraId, periodo));
    },
    onFilterPeriodoCalificadoras: calificadoraId => {
      dispatch(action.fetchPeriodoByCalificadora(calificadoraId));
    },
    onFilterDocumentosCalificadoras: (size, page, empresaId, periodo, ejercicio) => {
      dispatch(action.initDocumentosReporteMensual(size, page, empresaId, periodo, ejercicio));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCalificadorasReportes);

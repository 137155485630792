import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./AgendaDerechosInstrumentosAlternativos.css";
import Accordion from "../../../components/ui/accordion/Accordion";
import Tabs from "../../../components/ui/tabs/Tabs";
import * as actions from "../../../stores/actions/index";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import Pagination from "../../../components/ui/pagination/Pagination";
import FilterAgenda from "../../../components/ui/filterAgenda/FilterAgenda";
import Loader from "../../../components/loader/Loader";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import FilterAgendaInstrumentosAlternativos from "../../../components/ui/filterAgendaInstrumentosAlternativos/FilterAgendaInstrumentosAlternativos";
const dominio = `${window.location.protocol}//${window.location.host}`;
let base_url = `${dominio}/emisoras/agendas/xls?recientes=true`;
const tabsAgendas = [
  {
    image: "",
    icon: "",
    title: getLan() === "es" ? "Nacional" : "National"
  },
  {
    image: "",
    icon: "",
    title: "SIC"
  }
];

class AgendaDerechosInstrumentosAlternativos extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    indexSelected: 0,
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    nacional: true,
    sic: true,
    estatus_agenda: null,
    tipoDerecho: null,
    tipoInstrumento: null,
    fechaInicio: null,
    fechaFin: null,
    emisoraSeleccionada: null,
    clave: null,
    tab: 0,
    wasFiltered: false,
    activaTabInicial: null,
    modoListado:null
  };

  componentWillMount() {
    this.props.onInitIntl();
    let paramsString = queryString.parse(this.props.location.search);
    let tipoInstrumento = null;
    let tipoDerecho = null;
    let page = null;
    let estatusAgenda = null;
    let fechaInicio = null;
    let fechaFin = null;
    let emisora_id = null;
    let clave = null;
    let nacional = null;
    let modoListado= null;
    let sic = null;
    let tab = null;
    let activaTabInicial = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      tipoInstrumento = paramsString["tipoInstrumento"] === null || paramsString["tipoInstrumento"] === "null" || paramsString["tipoInstrumento"] === "" ? null : paramsString["tipoInstrumento"].split(",");
      tipoDerecho = paramsString["tipoDerecho"] === null || paramsString["tipoDerecho"] === "null" || paramsString["tipoDerecho"] === "" ? null : paramsString["tipoDerecho"].split(",");
      estatusAgenda = paramsString["estatusAgenda"] === "null" ? null : paramsString["estatusAgenda"];
      fechaInicio = paramsString["fechaInicio"] === "null" ? null : paramsString["fechaInicio"];
      fechaFin = paramsString["fechaFin"] === "null" ? null : paramsString["fechaFin"];
      page = paramsString["page"] === "null" ? 1 : parseInt(paramsString["page"]);
      emisora_id = paramsString["emisora_id"] === "null" ? null : paramsString["emisora_id"];
      clave = paramsString["clave"] === "null" ? null : paramsString["clave"];
      nacional = paramsString["nacional"] === "null" ? null : paramsString["nacional"] === "true" ? true : false;
      sic = paramsString["sic"] === "null" ? null : paramsString["sic"] === "true" ? true : false;
      modoListado = paramsString["modoListado"] === "null" ? null : paramsString["modoListado"];
    }
    if (nacional === null && sic === null || nacional === undefined && sic === undefined) {
      nacional = true;
      sic = false;
    }
    if (nacional === false && sic === false) {
      nacional = true;
      sic = null;
    } else {
      if (nacional) {
        activaTabInicial = 0;
      }
      if (sic) {
        activaTabInicial = 1;
      }
    }
    if (tipoInstrumento === null) {
      tipoInstrumento = ["1", "2", "3", "4"];
    }

    this.props.onInitAgendaDerechos(
      this.state.itemsPerPage,
      parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      nacional,
      sic,
      tipoDerecho,
      tipoInstrumento,
      estatusAgenda,
      fechaInicio,
      fechaFin,
      emisora_id,
      clave,
      modoListado);

    this.setState({
      tipoDerecho: tipoDerecho,
      tipoInstrumento: tipoInstrumento,
      estatusAgenda: estatusAgenda,
      fechaInicio: fechaInicio,
      sic: sic,
      nacional: nacional,
      modoListado: modoListado,
      activaTabInicial: activaTabInicial,
      tab: activaTabInicial,
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
    });

  }



  componentWillReceiveProps(nextProps) {
    let agendaDerechos = null;
    if (this.state.estatus_agenda === []) {
      this.setState({
        estatus_agenda: nextProps.estatus_agenda
      });
    }

    if (nextProps.agendaDerechos && nextProps.agendaDerechos.content) {
      agendaDerechos = nextProps.agendaDerechos.content;
    }
    let resource = agendaDerechos;
    this.onPageChangeHandler(resource, null, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.agendaDerechos !== prevProps.agendaDerechos) {
      this.setState({
        loader: false
      });
    }
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.agendaDerechos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        activePage: pageNumber,
        resourceList: resourceList
      });

      this.changeHistory(
        this.state.tipoDerecho,
        this.state.tipoInstrumento,
        this.state.estatusAgenda,
        this.state.periodo,
        this.state.fechaInicio,
        this.state.fechaFin,
        this.state.emisoraSeleccionada,
        this.state.clave,
        this.state.modoListado,
        pageNumber);

    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.onPageChangeHandler(null, pageNumber);
    }
  }

  onChangeItemSelected = (index = 0) => {
    let nacional = null;
    let sic = null;
    let activePage = null;

    if (this.state.indexSelected === index) {
      activePage = this.state.activePage;
    } else {
      activePage = 1;
    }
    if (index === 0) {
      //Nacional
      nacional = true;
      sic = false;
    } else {
      //SIC
      nacional = false;
      sic = true;
    }
    this.setState({
      tab: index,
      indexSelected: index,
      nacional: nacional,
      sic: sic,
      activePage: activePage,
      loader: true
    });
  };

  onSetEstatus = estatus_agenda => {
    this.setState({
      estatus_agenda: estatus_agenda
    });
  };
  onSetTipoDerecho = tipoDerecho => {
    this.setState({
      tipoDerecho: tipoDerecho
    });
  };
  onSetTipoInstrumento = tipoInstrumento => {
    this.setState({
      tipoInstrumento: tipoInstrumento
    });
  };

  onSetFechaInicio = fecha_inicio => {
    this.setState({
      fechaInicio: fecha_inicio
    });
  };

  onSetFechafin = fecha_fin => {
    this.setState({
      fechaFin: fecha_fin
    });
  };

  onWasFiltered = wasFiltered => {
    this.setState({ wasFiltered: wasFiltered });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };
  onSetEmisora = (emisora, tipo) => {
    let emisoraSeleccionada = null;
    let clave = null;

    if (tipo === 1) {
      emisoraSeleccionada = emisora;
    }
    if (tipo === 2) {
      clave = emisora;
      emisoraSeleccionada = null;
    }

    this.setState({
      emisoraSeleccionada: emisoraSeleccionada,
      clave: clave,
      loader: true
    });

  };

  onSetFilters = ({
    tipoDerecho = null,
    tipoInstrumento = null,
    estatusAgenda = null,
    periodo = null,
    fechaInicio = null,
    fechaFin = null,
    emisoraSeleccionada = null,
    clave = null,
    modoListado = null,
    wasFiltered = null,
    page = null,
    reset = false
  }) => {
    this.setState({
      tipoDerecho: tipoDerecho > 0 ? tipoDerecho : null,
      tipoInstrumento: tipoInstrumento ? tipoInstrumento : null,
      estatusAgenda: estatusAgenda ? estatusAgenda : null,
      periodo: periodo ? periodo : null,
      fechaInicio: fechaInicio ? fechaInicio : null,
      fechaFin: fechaFin ? fechaFin : null,
      emisoraSeleccionada: emisoraSeleccionada ? emisoraSeleccionada : null,
      clave: clave ? clave : null,
      modoListado: modoListado ? modoListado :null,
      wasFiltered: wasFiltered,
      loader: true
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(tipoDerecho, tipoInstrumento, estatusAgenda, periodo, fechaInicio, fechaFin, emisoraSeleccionada, clave, modoListado, page);
    }
  };

  changeHistory(tipoDerecho = null, tipoInstrumento = null, estatusAgenda = null, periodo = null, fechaInicio = null, fechaFin = null, emisoraSeleccionada = null, clave = null, modoListado= null, page = null) {
    this.props.history.push(
      `?tipoDerecho=${tipoDerecho}&tipoInstrumento=${tipoInstrumento}&estatusAgenda=${estatusAgenda}&periodo=${periodo}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&modoListado=${modoListado}&emisora_id=${emisoraSeleccionada}&clave=${clave}&sic=${this.state.sic}&nacional=${this.state.nacional}&page=${page ? page : this.state.activePage}`
    );

    this.props.onInitAgendaDerechos(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      this.state.nacional,
      this.state.sic,
      tipoDerecho,
      tipoInstrumento && tipoInstrumento.length > 0 ? tipoInstrumento : ["1","2","3","4"],
      estatusAgenda,
      fechaInicio,
      fechaFin,
      emisoraSeleccionada,
      clave,
      modoListado);
  }

  changeHistoryReset() {
    this.props.history.push(
      `?tipoDerecho=${null}&tipoInstrumento=${null}&estatusAgenda=${null}&periodo=${null}&fechaInicio=${null}&fechaFin=${null}&modoListado=${null}&sic=${null}&nacional=${null}&page=1`
    );
    this.props.onInitAgendaDerechos(
      this.state.itemsPerPage,
      0,
      null,
      null,
      null,
      ["1","2","3","4"],
      null,
      null,
      null,
      null,
      null,
      null);
  }


  render() {
    let contenidoMostrar = null;
    let resultadoDescarga = null;
    let loader = null;
    let descarga_url = base_url;
    descarga_url =
      this.state.tipoInstrumento && this.state.tipoInstrumento.length > 0
        ? `${descarga_url}&tipoInstrumentoAlternativo=${this.state.tipoInstrumento}`
        : `${descarga_url}&tipoInstrumentoAlternativo=1,2,3,4`;
    descarga_url = this.state.tipoDerecho && this.state.tipoDerecho.length > 0
      ? `${descarga_url}&tipoDerecho=${this.state.tipoDerecho}`
      : descarga_url;
    descarga_url = this.state.estatus_agenda !== null && this.state.estatus_agenda.length > 0
      ? `${descarga_url}&estatus=${this.state.estatus_agenda}`
      : descarga_url;
    descarga_url = this.state.periodo
      ? `${descarga_url}&periodo=${this.state.periodo}`
      : descarga_url;
    descarga_url = this.state.emisoraSeleccionada
      ? `${descarga_url}&claveEmisora=${this.state.emisoraSeleccionada}`
      : descarga_url;
    descarga_url = this.state.clave
      ? `${descarga_url}&clave=${this.state.clave}`
      : descarga_url;
    descarga_url = this.state.nacional
      ? `${descarga_url}&nacional=${this.state.nacional}`
      : descarga_url;
    descarga_url = this.state.sic
      ? `${descarga_url}&sic=${this.state.sic}`
      : descarga_url;
    descarga_url = this.state.fechaInicio
      ? `${descarga_url}&fechaInicio=${this.state.fechaInicio}`
      : descarga_url;
    descarga_url = this.state.fechaFin
      ? `${descarga_url}&fechaFin=${this.state.fechaFin}`
      : descarga_url;
      descarga_url = this.state.modoListado  && this.state.modoListado.length > 0
      ? `${descarga_url}&modalidadListado=${this.state.modoListado}`
      : descarga_url;

    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }

    let agendaDerechos = null;
    if (this.props.agendaDerechos && this.props.agendaDerechos.content) {
      agendaDerechos = this.props.agendaDerechos.content;
      const numRows = this.props.agendaDerechos ? this.props.agendaDerechos.totalElements : 0;

      contenidoMostrar = (
        <React.Fragment>
          <Accordion datos={agendaDerechos} nombre="agendas" icon={""} tipo={12} subtipo={3} sic={this.state.sic} />
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </React.Fragment>
      );

      resultadoDescarga = (
        <div className={"row margen_30"}>
          <div className={[classes.card_header, "sin_padding col-12 col-md-3 col-lg-6 col-xl-7  d-none d-sm-none d-md-flex"].join(" ")}>
            <span className={["regular montse texto_16 "].join(" ")} />
          </div>
          <div className={[classes.card_header2, "sin_padding col-12 col-md-9 col-lg-6 col-xl-5"].join(" ")}>
            <div className={["sin_padding col-12 texto_16 montse regular"].join(" ")}>
              <a
                href={descarga_url}
                target="_self"
                rel="noopener noreferrer"
                className="row"
              >
                <span
                  className={[
                    classes.titulo,
                    "texto_16 montse color_azul regular col-12",
                  ].join(" ")}
                >
                  {getLan() === "es"
                    ? "Listado agenda de derechos"
                    : "Corporate action agenda listing"}
                  <i
                    className={[
                      "fas fa-arrow-alt-circle-down fa-lg",
                      "Boton__icono_derecho__25mg6",
                    ].join(" ")}
                  />
                </span>
                <span
                  className={[
                    classes.titulo_ZIP,
                    "semi_bold montse texto_10 col-12",
                  ].join(" ")}
                >
                  {getLan() === "es" ? "Formato XLS" : "XLS file"}
                </span>
              </a>
            </div>
          </div>
        </div>
      );

    } else if (!this.props.agendaDerechos && this.state.wasFiltered) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    } else if (agendaDerechos === null && this.state.wasFiltered) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    } else if (agendaDerechos === null) {
      contenidoMostrar = <p className="montse texto_16 regular">{getLan() === "es" ? "No hay derechos agendados dentro de los 5 días hábiles siguientes." : "No rights scheduled within 5 business days."}</p>;
    }

    const tabMostrar = (
      <div className="row margen_top_30">
        <div className={["texto_16 nunito semi_bold", classes.tabsContainer, classes.myCol].join(" ")}>{getLan() === "es" ? "Mostrar:" : "View:"}</div>
        <div className={["col sin_padding", classes.myCol].join(" ")}>
          <Tabs data={tabsAgendas} tipo={1} clicked={i => this.onChangeItemSelected(i)} activaTabInicial={this.state.activaTabInicial} />
        </div>
      </div>
    );

    let filtro = null;
    filtro = (
      <div className="margen_top_10">
        <FilterAgendaInstrumentosAlternativos
          itemsPerPage={this.state.itemsPerPage}
          setTipoInstrumento={tipoInstrumento => this.onSetTipoInstrumento(tipoInstrumento)}
          setTipoDerecho={tipoDerecho => this.onSetTipoDerecho(tipoDerecho)}
          setFechaInicio={fechaInicio => this.onSetFechaInicio(fechaInicio)}
          setFechaFin={fechaFin => this.onSetFechafin(fechaFin)}
          setEstatus={estatus => this.onSetEstatus(estatus)}
          setPage={page => this.onSetPage(page)}
          setEmisora={(emisora, tipo) => this.onSetEmisora(emisora, tipo)}
          estatus_agenda={this.state.estatus_agenda}
          tab={this.state.tab}
          onSetWasFiltered={wasFiltered => this.onWasFiltered(wasFiltered)}
          setFilters={filterParams => this.onSetFilters(filterParams)}
          location={this.props.location}
        />
      </div>
    );
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}

          <h1 className={"texto_42 nunito regular"}>
            <FormattedHTMLMessage id="empresas.emisorasinscritas.agendaderechos.titulo1" defaultMessage=" " />
          </h1>
          <br />
          {/* {tabMostrar} */}
          {filtro}
          {resultadoDescarga}
          <br />
          <div className="margen_50">{contenidoMostrar}</div>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(actions.initIntl(53));
    },
    onInitAgendaDerechos: (
      size,
      page,
      nacional,
      sic,
      tipoDerecho,
      tipoInstrumento,
      estatus,
      fechaInicio,
      fechaFin,
      emisora,
      clave,
      modoListado
    ) => {
      dispatch(
        actions.initAgendaDerechos(
          size,
          page,
          nacional,
          sic,
          tipoDerecho,
          tipoInstrumento,
          estatus,
          fechaInicio,
          fechaFin,
          emisora,
          clave,
          modoListado,
          true
        )
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    agendaDerechos: state.emisoras.agendaDerechos
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AgendaDerechosInstrumentosAlternativos)
);

import React, { Component } from "react";
import classes from "./SubFilterDocumentos.css";
import { connect } from "react-redux";

import { changeDateFormat, getLan } from "../../../stores/utils/utilities";

import { Form, Field } from "react-final-form";

import Auxs from "../../../hoc/auxs/Auxs";
import Input from "../input/Input";

import * as action from "../../../stores/actions/index";

const idDependientesTipoInformacion = [16, 17];

const idDependientesTipoDocumentos = [
  185,
  182,
  17,
  168,
  53,
  170,
  186,
  169,
  2,
  1,
  156,
  157,
  158,
  184,
  159,
  160,
  161,
  162,
  79,
  66,
  170
];

const renderInput = ({
  input,
  id = null,
  label,
  classes = null,
  options = null,
  type = null,
  elementType,
  placeholder = null,
  checked = null,
  selectLabel,
  isMultiple = null,
  disabled = false,
  onInputChange
}) => {
  const chooseOptionLabel = getLan() === "es" ? "Seleccione una opción" : "Select an option";

  const params = {
    input: {
      ...input,
      id: id ? id : label,
      placeholder: placeholder,
      className: classes ? classes : null,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    type: type,
    label: label,
    options: options,
    isDisabled: disabled,
    isMultiple: isMultiple,
    elementType: elementType,
    selectLabel: selectLabel ? selectLabel : chooseOptionLabel
  };

  return (
    <div className="form-group">
      {label ? (
        <label for={input.name} className={["texto_14", "montse", "regular nowrap"].join(" ")}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class SubFiltersCotizacion extends Component {
  constructor(props) {
    super(props);
    this.subFilterDocumento = React.createRef();
  }

  state = {
    toggleFilter: false,
    emisoraId: null,
    selectedFilters: {
      tipo_informacion: [],
      tipo_documento: [],
      fechas: [],
      periodos: [],
      ejercicios: []
    },
    form_filters: {
      tipo_informacion: {
        value: ""
      },
      tipo_documento: {
        value: []
      },
      fecha_inicio: {
        value: "",
        disabled: false
      },
      fecha_fin: {
        value: "",
        disabled: false
      },
      periodos: {
        value: "",
        disabled: false
      },
      ejercicios: {
        value: "",
        disabled: false
      },
      errorDate: false
    },
    idIsFoundByInfo: false,
    idIsFoundByDoc: false,
    itemsPerPage: 10,
    tipo: null
  };

  componentDidMount() {
    const emisoraId = this.props.emisoraId;
    this.props.onFilterTipoInformacion(emisoraId);
    this.setState({ emisoraId: emisoraId });
  }

  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  onCheckIfPeriodoAndEjercicioExistsHandler = (value, type) => {
    let exists = false;
    let val = null;

    if (type === 0) {
      val = +value;
      idDependientesTipoInformacion.forEach(item => {
        if (item === val) exists = true;
      });
    } else {
      // val = [...value];
      val = value[0].split(",");
      val.forEach(v => {
        idDependientesTipoDocumentos.forEach(item => {
          v = +v;
          if (item === v) exists = true;
        });
      });
    }
    return exists;
  };

  // Retorna el valor, etiqueta y tipo  de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type = null) => {
    let newValue = null;
    let newLabel = null;
    let newType = null;

    if (type === 2 || type === 3) {
      // Fechas inicio o fin
      [newValue] = changeDateFormat(value);
      newLabel = type === 2 ? "fecha_inicio" : "fecha_fin";
    } else if (type === 4 || type === 5) {
      // Periodo o ejercicio
      newValue = value.split("_")[0];
      newLabel = type === 4 ? "periodo" : "ejercicio";
    } else {
      // Resto de campo
      newValue = value;
      newLabel = type === 0 ? value.split("_")[2] : value.split("_")[1];
      newType = type === 0 ? value.split("_")[1] : null;
    }

    return [newValue, newLabel, newType];
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    const valuesTipoInformacion =
      form.tipo_informacion.value && form.tipo_informacion.value !== ""
        ? this.onSplitValueHandler(form.tipo_informacion.value)
        : "";
    // Se obtiene el valor del tipo de informacion
    const valuesTipoDocumentos =
      form.tipo_documento.value.length && form.tipo_documento.value.length > 0
        ? this.onSplitValueHandler(form.tipo_documento.value)
        : [];
    // Se obtiene el valor de fecha inicio
    const valuesFechaInicio = form.fecha_inicio.value && form.fecha_inicio.value !== "" ? form.fecha_inicio.value : "";
    // Se obtiene el valor de fecha fin
    const valuesFechaFin = form.fecha_fin.value && form.fecha_fin.value !== "" ? form.fecha_fin.value : "";
    // Se obtiene el valor de periodo
    const valuesPeriodo =
      form.periodos.value && form.periodos.value !== "" ? this.onSplitValueHandler(form.periodos.value) : "";
    // Se obtiene el valor de ejercicio
    const valuesEjercicio =
      form.ejercicios.value && form.ejercicios.value !== "" ? this.onSplitValueHandler(form.ejercicios.value) : "";

    return [valuesTipoInformacion, valuesTipoDocumentos, valuesFechaInicio, valuesFechaFin, valuesPeriodo, valuesEjercicio];
  };

  onFilterDataByEvent = (tipo = null) => {
    let [
      valuesTipoInformacion,
      valuesTipoDocumentos,
      valuesFechaInicio,
      valuesFechaFin,
      valuesPeriodo,
      valuesEjercicio
    ] = this.onGetAllValuesFormFilter();

    const params = {
      size: this.state.itemsPerPage,
      page: 1,
      empresaId: this.state.emisoraId,
      tipoInformacion: valuesTipoInformacion,
      tipoDocumento: valuesTipoDocumentos,
      fechaInicio: valuesFechaInicio,
      fechaFin: valuesFechaFin,
      periodo: valuesPeriodo,
      ejercicio: valuesEjercicio,
      tipo: tipo ? tipo : this.state.tipo
    };

    if (!this.state.form_filters.errorDate) {
      this.props.setFilters(params);
    }

    let wasFiltered = true;
    // Se invocan a las funciones el componente padre (DocumentosEmisoras.js) para setear los valores correspondientes
    // Configurando el valor de tipo de informacion seleccionado y tipo (listado o matenimiento)
    this.props.setTipoInformacion(valuesTipoInformacion, this.state.tipo);
    // Configurando el valor de tipo de documento seleccionado
    this.props.setTipoDocumento(valuesTipoDocumentos);
    // Confifurndo el valor de tipo fecha inicio seleccionado
    this.props.setFechaInicio(valuesFechaInicio);
    // Confifurndo el valor de tipo fecha fin seleccionado
    this.props.setFechaFin(valuesFechaFin);
    // Configurando el valor de tipo periodo seleccionado
    this.props.setPeriodo(valuesPeriodo);
    // Configurando el valor de tipo ejercicio seleccionado
    this.props.setEjercicio(valuesEjercicio);
    this.props.setActivePage(1);
    if (
      valuesTipoInformacion !== "" ||
      valuesTipoDocumentos.length > 0 ||
      valuesFechaInicio !== "" ||
      valuesFechaFin !== "" ||
      valuesPeriodo !== "" ||
      valuesEjercicio !== ""
    ) {
      wasFiltered = true;
    }

    // Configurando la primera página de la busqueda
    //this.props.setPage(1);

    //Configurando si se ha realizado algun filtrado en el componente padre
    this.props.wasFiltered(wasFiltered);
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return ["tipo_informacion", form.tipo_informacion.value, selectedFilters.tipo_informacion];
      case 1:
        return ["tipo_documento", form.tipo_documento.value, selectedFilters.tipo_documento];
      case 2:
        return ["fecha_inicio", form.fecha_inicio.value, selectedFilters.fechas, "fechas"];
      case 3:
        return ["fecha_fin", form.fecha_fin.value, selectedFilters.fechas, "fechas"];
      case 4:
        return ["periodos", form.periodos.value, selectedFilters.periodos];
      case 5:
        return ["ejercicios", form.ejercicios.value, selectedFilters.ejercicios];
    }
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (type !== 1) {
      if (values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];
      const stateExists = newStateValues.find(option => option === values);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== values);
      } else {
        if (values !== "") {
          newStateValues.push(values);
        } else {
          newStateValues = [];
        }
      }
    }

    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (filterOptions = null, values = null, label = null, type = null) => {
    let newFilterValues = [];

    if (type === 0) {
      if (values !== "") {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else if (type === 2 || type === 3 || type === 4 || type === 5) {
      // Fechas y periodos
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(option => option.label === label);
      if (filterExists) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
        if (values && values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      } else {
        newFilterValues.push({ label: label, value: values, type: type });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(option => option.label === label);
      if (filterExists) {
        newFilterValues = newFilterValues.filter(option => option.label !== label);
      } else {
        if (values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        } else {
          newFilterValues = [];
        }
      }
    }
    return newFilterValues;
  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (event = null, type = null, item = null, eraser = null) => {
    let actualValue = null;
    if (eraser || (actualValue && actualValue === "")) {
      eraser = true;
    } else {
      actualValue = type !== 1 ? event.target.value : event.target.options;
    }

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let isFoundByInfo = this.state.idIsFoundByInfo;
    let isFoundByDoc = this.state.idIsFoundByDoc;
    let errorDate = form.errorDate;
    let tipo = this.state.tipo;

    let newValue = null;
    let newLabel = null;
    let newType = null;

    if (eraser) {
      actualValue = item.type === 1 ? item.value : "";
      newValue = "";
      newLabel = item.label;
      type = item.type;
    } else {
      // Solo para los campos distintos a tipo documento
      if (type !== 1) {
        [newValue, newLabel, newType] = this.onGetValueLabelAndTypeHandler(actualValue, type);
      } else {
        // Solo para el campo tipo de documento.
        const options = [...actualValue];
        const indexSelected = actualValue.selectedIndex && actualValue.selectedIndex !== -1 ? actualValue.selectedIndex : 0;
        newValue = options[indexSelected].value;
        newLabel = options[indexSelected].value.split("_")[1];

        actualValue = newValue;
      }
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [inputName, stateValues, filterValues, otherInputName] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(filterValues, newValue, newLabel, type);

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesTipoInformacion,
      valuesTipoDocumentos,
      valuesFechaInicio,
      valuesFechaFin,
      valuesPeriodo,
      valuesEjercicio
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    form[`${inputName}`].value = newStateValues;
    selectedFilters[`${otherInputName ? otherInputName : inputName}`] = newFilterValues;

    if (type === 0) {
      // Tipo de información
      // Configurado el tipo de informacion seleccionado (listado o mantenimiento)
      tipo = newType ? newType : null;
      // Limpiando valores para campo tipo_documento
      form.tipo_documento.value = [];
      selectedFilters.tipo_documento = [];
      // Filtrando valores para campo tipo_documento
      this.props.onFilterTipoDcumento(this.state.emisoraId, valuesTipoInformacion, tipo);

      // Limpiando valores para campo periodo
      form.periodos.value = "";
      selectedFilters.periodos = [];
      // Limpiando valores para campo ejercicio
      form.ejercicios.value = "";
      selectedFilters.ejercicios = [];

      if (valuesTipoInformacion !== "") {
        const exists = this.onCheckIfPeriodoAndEjercicioExistsHandler(valuesTipoInformacion, type);

        if (exists && tipo === "mantenimiento") {
          const params = {
            empresaId: this.state.emisoraId,
            tipoInformacion: valuesTipoInformacion,
            tipoDocumento: null,
            tipo: tipo
          };
          // Filtrando valores para el campo periodo
          this.props.onFilterPeriodo(params);
          // Filtrando valores para el campo ejercicio
          this.props.onFilterEjercicio(params);

          isFoundByInfo = true;
        } else {
          isFoundByInfo = false;
        }
        form.idIsFoundByInfo = isFoundByInfo;
      }
    } else if (type === 1) {
      // Tipo Documento
      if (valuesTipoDocumentos.length > 0) {
        const params = {
          empresaId: this.state.emisoraId,
          tipoInformacion: valuesTipoInformacion,
          tipoDocumentos: valuesTipoDocumentos,
          tipo: tipo
        };
        // Filtrando valores para el campo periodo
        this.props.onFilterPeriodo(params);
        // Filtrando valores para el campo ejercicio
        this.props.onFilterEjercicio(params);

        if (!isFoundByInfo) {
          const exists = this.onCheckIfPeriodoAndEjercicioExistsHandler(valuesTipoDocumentos, 1);
          if (exists && tipo === "mantenimiento") {
            isFoundByDoc = true;
          } else {
            isFoundByDoc = false;
          }
        }
      } else {
        isFoundByDoc = false;
      }
    } else if (type === 2) {
      // Fecha inicio
      // Se verifica si los valores actuales para las fechas son distintos de vacio, de ser así se bloquean los
      // campos de periodo y ejercicio
      const disableValue = actualValue === "" && (!form.fecha_fin.value || form.fecha_fin.value === "") ? false : true;
      form.periodos.disabled = disableValue;
      form.ejercicios.disabled = disableValue;

      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(actualValue, form.fecha_fin.value);
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 3) {
      // Fecha fin
      // Se verifica si los valores actuales para las fechas son distintos de vacio, de ser así se bloquean los
      // campos de periodo y ejercicio
      const disableValue = actualValue === "" && (!form.fecha_inicio.value || form.fecha_inicio.value === "") ? false : true;
      form.periodos.disabled = disableValue;
      form.ejercicios.disabled = disableValue;

      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(form.fecha_inicio.value, actualValue);
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 4) {
      // Periodo
      const disableValue = actualValue === "" && (!form.ejercicios.value || form.ejercicios.value === "") ? false : true;
      form.fecha_inicio.disabled = disableValue;
      form.fecha_fin.disabled = disableValue;
    } else if (type === 5) {
      // Ejercicio
      const disableValue = actualValue === "" && (!form.periodos.value || form.periodos.value === "") ? false : true;
      form.fecha_inicio.disabled = disableValue;
      form.fecha_fin.disabled = disableValue;
    }

    this.setState({
      tipo: tipo,
      form_filters: form,
      selectedFilters: selectedFilters,
      idIsFoundByInfo: isFoundByInfo,
      idIsFoundByDoc: isFoundByDoc
    });

    this.onFilterDataByEvent(tipo);
  };

  onPrintSelectedFiltersHandler = () => {
    const selectedFilters = [
      ...this.state.selectedFilters.tipo_informacion,
      ...this.state.selectedFilters.tipo_documento,
      ...this.state.selectedFilters.fechas,
      ...this.state.selectedFilters.periodos,
      ...this.state.selectedFilters.ejercicios
    ];
    return (
      <div className={classes.filterContainerOutside}>
        {selectedFilters.map((filter, i) => {
          return (
            <span key={i} className={classes.filterContainer}>
              <span className={[classes.filterText, "montse texto_14 regular"].join(" ")}>
                {filter.type === 2 || filter.type === 3 || filter.type === 4 || filter.type === 5 ? filter.value : filter.label}
              </span>
              <span className={classes.filterIconButton} onClick={() => this.onInputSelectedHandler(null, null, filter, true)}>
                <i className="far fa-times-circle" />
              </span>
            </span>
          );
        })}
      </div>
    );
  };

  onSubmitSubFiltersHandler = event => {
    // Pass
  };

  onResetFilter = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    // Limpiando valores campo tipo informacion
    form.tipo_informacion.value = "";
    selectedFilters.tipo_informacion = [];
    // Limpiando valores campo tipo documento
    form.tipo_documento.value = [];
    selectedFilters.tipo_documento = [];
    // Limpiando valores campo fecha inicio y fecha fin
    form.fecha_inicio.value = "";
    form.fecha_inicio.disabled = false;
    form.fecha_fin.value = "";
    form.fecha_fin.disabled = false;
    selectedFilters.fechas = [];
    // limpiando valores campo periodo
    form.periodos.value = "";
    form.periodos.disabled = false;
    selectedFilters.periodos = [];
    // limpiando valores campo ejercicio
    form.ejercicios.value = "";
    form.ejercicios.disabled = false;
    selectedFilters.ejercicios = [];

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      idIsFoundByDoc: false,
      idIsFoundByInfo: false
    });

    this.onFilterDataByEvent();
  };

  onToggleFilterHandler = () => {
    this.setState(prevState => {
      return {
        toggleFilter: !prevState.toggleFilter
      };
    });
  };

  onPrepareOptionHandler = (type, option = null, label = null) => {
    let options = [];
    type.forEach(element => {
      if (option === 0) {
        options.push({
          nombre: element.nombre,
          id: `${element.id}_${element.tipo}_${element.nombre}`
        });
      } else if (option === 1) {
        options.push({
          nombre: element.nombre,
          id: `${element.id}_${element.nombre}`
        });
      } else {
        options.push({
          nombre: element,
          id: `${element}_${label}`
        });
      }
    });
    return options;
  };

  printFrameModelHandler = () => {
    const selectedFilters =
      this.state.selectedFilters.tipo_informacion.length > 0 || this.state.selectedFilters.fechas.length > 0
        ? this.onPrintSelectedFiltersHandler()
        : null;

    const closeLabel = getLan() === "es" ? "Cerrar filtros" : "Close";
    const filterLabel = getLan() === "es" ? "Filtrar" : "Filter";
    return (
      <Auxs>
        <div
          className={["row", classes.firstSectionFilterContainer, this.state.toggleFilter ? classes.noBorderRadius : null].join(
            " "
          )}
        >
          <div className="col col-lg-3 text-center sin_padding_L">
            <div className={[classes.toogleButtonContainer, "texto_btn_cerrar"].join(" ")}>
              <button type="button" className="btn" onClick={this.onToggleFilterHandler}>
                <i className="fas fa-filter" />
                <span>{!this.state.toggleFilter ? filterLabel : closeLabel}</span>
              </button>
            </div>
          </div>
          <div className="col col-lg-9">{selectedFilters}</div>
        </div>
        <div
          className={["row", classes.secondSectionFilterContainer, !this.state.toggleFilter ? classes.hidden : null].join(" ")}
        >
          <div className="col">{this.onPrintSubFiltersHandler()}</div>
        </div>
      </Auxs>
    );
  };

  onPrintSubFiltersHandler = () => {
    return (
      <Auxs>
        <div className={classes.mainSubFilterContainer}>
          <div className={[classes.filterSections].join(" ")}>
            <Form
              onSubmit={this.onSubmitSubFiltersHandler}
              initialValues={{
                tipo_informacion: this.state.form_filters.tipo_informacion.value,
                tipo_documento: this.state.form_filters.tipo_documento.value,
                fecha_inicio: this.state.form_filters.fecha_inicio.value,
                fecha_fin: this.state.form_filters.fecha_fin.value,
                periodos: this.state.form_filters.periodos.value,
                ejercicios: this.state.form_filters.ejercicios.value
              }}
              render={({
                optionsTipoInformacion = this.props.tipo_informacion
                  ? this.onPrepareOptionHandler(this.props.tipo_informacion, 0)
                  : [],
                optionsTipoDocumento = this.props.tipo_documento
                  ? this.onPrepareOptionHandler(this.props.tipo_documento, 1)
                  : [],
                optionsPeriodo = this.props.periodos && this.props.periodos.length > 0
                  ? this.onPrepareOptionHandler(this.props.periodos, null, "Periodo")
                  : [],
                optionsEjercicio = this.props.ejercicios && this.props.ejercicios.length > 0
                  ? this.onPrepareOptionHandler(this.props.ejercicios, null, "Ejercicio")
                  : [],
                handleSubmit,
                form
              }) => (
                <form ref={this.subFilterDocumento} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4  ">
                      <Field
                        name="tipo_informacion"
                        id="tipo_informacion"
                        elementType="select"
                        classes="custom-select"
                        label={getLan() === "es" ? "Tipo de información" : "Information type"}
                        options={optionsTipoInformacion}
                        onInputChange={event => this.onInputSelectedHandler(event, 0)}
                        component={renderInput}
                      />
                    </div>
                    <div
                      className={[
                        "col-12 col-sm-6 col-md-6 col-lg-4",
                        optionsTipoDocumento.length === 0 ||
                        !this.state.form_filters.tipo_informacion.value ||
                        this.state.form_filters.tipo_informacion.value === ""
                          ? classes.hidden
                          : null
                      ].join(" ")}
                    >
                      <Field
                        name="tipo_documento"
                        id="tipo_documento"
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        label={getLan() === "es" ? "Tipo de documento" : "Document type"}
                        isMultiple={true}
                        options={optionsTipoDocumento}
                        onInputChange={event => this.onInputSelectedHandler(event, 1)}
                        component={renderInput}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 ">
                      <div className={[classes.datesSection, "row"].join(" ")}>
                        <div className={["col", "col-lg-6", classes.no_col_padding].join(" ")}>
                          <Field
                            name="fecha_inicio"
                            id="fecha_inicio"
                            type="date"
                            elementType="date"
                            classes={classes.fechaInicio}
                            disabled={this.state.form_filters.fecha_inicio.disabled}
                            label={getLan() === "es" ? "Fecha de publicación" : "Publication date"}
                            onInputChange={event => this.onInputSelectedHandler(event, 2)}
                            component={renderInput}
                          />
                        </div>
                        <div className={["col", "col-lg-6", classes.no_col_padding].join(" ")}>
                          <Field
                            name="fecha_fin"
                            id="fecha_fin"
                            type="date"
                            elementType="date"
                            classes={classes.fechaFin}
                            disabled={this.state.form_filters.fecha_fin.disabled}
                            label="Fecha fin"
                            onInputChange={event => this.onInputSelectedHandler(event, 3)}
                            component={renderInput}
                          />
                        </div>
                        <div
                          className={[classes.errorContainer, !this.state.form_filters.errorDate ? classes.hidden : null].join(
                            " "
                          )}
                        >
                           <p className={"texto_14 montse regular"}>
                            {getLan() === "es"
                              ? "La fecha final debe ser mayor a la fecha inicial."
                              : "End date must be greater than start date."}
                          </p>
                        </div>
                      </div>
                    </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 ">
                        <div className="row">
                          <div
                            className={[
                              "col-12 col-sm-6 col-lg-6 sin_padding_L",
                              !this.state.idIsFoundByInfo && !this.state.idIsFoundByDoc ? null : "",
                              !this.state.idIsFoundByInfo ? "d-none" : null
                            ].join(" ")}
                          >
                            <Field
                              name="periodos"
                              id="periodos"
                              elementType="select"
                              classes="custom-select"
                              label={getLan() === "es" ? "Periodo" : "Period"}
                              disabled={this.state.form_filters.periodos.disabled}
                              options={optionsPeriodo}
                              onInputChange={event => this.onInputSelectedHandler(event, 4)}
                              component={renderInput}
                            />
                          </div>
                          <div
                            className={[
                              "col-12 col-sm-6 col-lg-6 sin_padding",
                              !this.state.idIsFoundByInfo && this.state.idIsFoundByDoc ? "" : "",
                              !this.state.idIsFoundByInfo &&
                                !this.state.idIsFoundByDoc
                                ? "d-none"
                                : null
                            ].join(" ")}
                          >
                            <Field
                              name="ejercicios"
                              id="ejercicios"
                              elementType="select"
                              classes="custom-select"
                              label={getLan() === "es" ? "Ejercicio" : "Year"}
                              disabled={this.state.form_filters.ejercicios.disabled}
                              options={optionsEjercicio}
                              onInputChange={event => this.onInputSelectedHandler(event, 5)}
                              component={renderInput}
                            />
                          </div>
                        </div>
                        </div>
                  </div>
                  <hr />
                  {this.sectionButtonsHandler(form)}
                </form>
              )}
            />
          </div>
        </div>
      </Auxs>
    );
  };

  sectionButtonsHandler = form => {
    const clearLabel = getLan() === "es" ? "Limpiar Filtros" : "Clear";

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={["btn", classes.buttonModel, "montse", "texto_14"].join(" ")}
            onClick={() => this.onResetFilter()}
          >
            <i className="fas fa-redo-alt" />
            {clearLabel}
          </button>
        </div>
      </div>
    );
  };

  render() {
    const filters = this.printFrameModelHandler();
    return (
      <Auxs>
        <div className="row">
          <div className="col margin_bottom_50">{filters}</div>
        </div>
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  return {
    tipo_informacion: state.tipoInformacion.tipo_informacion,
    tipo_documento: state.tipoDocumento.tipo_documento,
    periodos: state.periodoEjercicios.periodos,
    ejercicios: state.periodoEjercicios.ejercicios
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterTipoInformacion: emisoraId => {
      dispatch(action.filterTipoInformacion(emisoraId));
    },
    onFilterTipoDcumento: (emisoraId, tipoInformacionId, tipo) => {
      dispatch(action.filterTipoDocumentos(emisoraId, tipoInformacionId, tipo));
    },
    onFilterDocumentos: params => {
      dispatch(action.filterDocumentos(params));
    },
    onFilterPeriodo: params => {
      dispatch(action.filterPeriodo(params));
    },
    onFilterEjercicio: params => {
      dispatch(action.filterEjercicio(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubFiltersCotizacion);

import React, { Component } from "react";

import Pagination from "react-js-pagination";
import classes from "./Pagination.css";

class Paginations extends Component {
  state = {
    lista_ver: [],
    activePage: 1
  };

  //  Estados necesarios desde componente a llamar
  // state = {
  //   itemsPerPage: 3,
  //   activePage: 1,
  //   lista_ver: []
  // };

  render() {
    let claseTipo = [];
    let pag = null;
    if (this.props.numRows === null || this.props.numRows === undefined || this.props.numRows === 0) {
        return <div className={claseTipo}>{pag}</div>;
      }

    if (this.props.claseTipo === 2) {
      claseTipo = classes.tipo2;

      pag = (
        <Pagination
          prevPageText={<i className={"fas fa-caret-left"} />}
          nextPageText={<i className={"fas fa-caret-right"} />}
          firstPageText={<i className={"fas fa-step-backward"} />}
          lastPageText={<i className={"fas fa-step-forward"} />}
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.itemsPerPage}
          totalItemsCount={this.props.numRows}
          pageRangeDisplayed={this.props.paginas}
          activeClass={classes.pagination_activa}
          activeLinkClass={classes.activo}
          innerClass={classes.pagination}
          itemClass={classes.pagination_inactivo}
          itemClassFirst={classes.first}
          itemClassPrev={classes.anterior}
          itemClassNext={classes.siguiente}
          itemClassLast={classes.last}
          disabledClass={classes.disable}
          onChange={this.props.handlePageChange}
        />
      );
    } else {
      claseTipo = [];
      pag = (
        <Pagination
          prevPageText={<i className={"fas fa-long-arrow-alt-left"} />}
          nextPageText={<i className={"fas fa-long-arrow-alt-right"} />}
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.itemsPerPage}
          totalItemsCount={this.props.numRows}
          pageRangeDisplayed={this.props.paginas}
          hideFirstLastPages
          activeClass={classes.pagination_activa}
          activeLinkClass={classes.activo}
          innerClass={[classes.pagination, classes.pagination_circle].join(" ")}
          itemClass={classes.pagination_inactivo}
          itemClassPrev={classes.anterior}
          itemClassNext={classes.siguiente}
          disabledClass={classes.disable}
          onChange={this.props.handlePageChange}
        />
      );
    }
      return <div className={[claseTipo, this.props.isSustentable ? classes.sustentable : null].join(" ")}>{pag}</div>;
  }
}

export default Paginations;

import React, { Component } from "react";

import classes from "./FilterGraficaHistoricoConcentradoPreciosFondos.css";
import { Form, Field } from "react-final-form";

import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import Loader from "../../../components/loader/Loader";

import Input from "../input/Input";
import {
  getLan,
  formatDate,
  restaAnio,
  restaMes,
  getRealDateMilliesDayMonthYear,
  formatDateReverse,
} from "../../../stores/utils/utilities";
const perioddummy = [
  {
    id: 1,
    nombre: "1M",
    type: "tipPeriod",
  },
  {
    id: 2,
    nombre: "1Y",
    type: "tipPeriod",
  },
  {
    id: 3,
    nombre: "5Y",
    type: "tipPeriod",
  },
];

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiSelect = null,
  isSingle = null,
  isClearable = false,
  options,
  defaultValue = null,
  checked = null,
  campoLabel = null,
  campoLabel2 = null,
  isGroup = false,
  selectLabel,
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      checked: checked,
      isClearable: isClearable,
      onChange: (e) => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      },
    },
    isGroup: isGroup,
    campoLabel: campoLabel,
    campoLabel2: campoLabel2,
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isSingle: isSingle,
    isMultiSelect: isMultiSelect,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel
      ? selectLabel
      : getLan() === "es"
      ? "Seleccione una opción"
      : "Select an option",
  };
  return (
    <div className="form-group">
      <label className={["montse", "texto_14", "regular"].join(" ")}>
        {label}
      </label>
      <Input config={params} />
    </div>
  );
};

class FilterGraficaHistoricoConcentradoPreciosFondos extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    topSeleccionado: [],
    series: [],
    periods: perioddummy,
    clean: false,
    toggleFilters: false,
    disableEnableButtons: true,
    allSelelectFilters: [],
    form_filters: {
      series: {
        value: [],
        disabled: false,
        show: false,
      },
      period: {
        value: "",
        disabled: false,
        show: false,
      },
      tipo: null,
      fechaInicio: null,
      fechaFin: null,
    },
    toggleInputs: false,
    loader: true,
  };

  componentDidMount() {
    if (this.props.paramsForm) {
      const paramsForm = this.props.paramsForm;
      // Obtenemos claves Fondo
      this.onFilterClaveFondoHandler(
        paramsForm.claveOperadora,
        paramsForm.tipoFondo,
        paramsForm.clasificacionFondo
      );
      // obtenemos las series
      this.onFilterSerieFondoHandler(
        paramsForm.claveOperadora,
        paramsForm.claveFondo,
        paramsForm.tipoFondo,
        paramsForm.clasificacionFondo
      );
      if (paramsForm.serie && paramsForm.serie.length !== 0) {
        this.props.setTop(paramsForm.serie);
      }

      this.getHistorico();
      let group1 = [...this.state.allSelelectFilters];
      const form = this.state.form_filters;
      let periods = this.state.periods;
      form.fechaInicio = paramsForm.fechaInicio;
      form.fechaFin = paramsForm.fechaFin;

      periods = periods.filter((itm) => itm.id !== 4);
      if (paramsForm.fechaInicio || paramsForm.fechaFin) {
        if (paramsForm.fechaInicio && paramsForm.fechaFin) {
          const datePeriod = {
            id: 4,
            type: "tipPeriod",
            nombre:
              formatDate(paramsForm.fechaInicio) +
              " - " +
              formatDate(paramsForm.fechaFin),
          };

          periods.push(datePeriod);
          form.period.value = datePeriod;
          group1 = group1.filter((item) => item.type !== "tipPeriod");
          let item = {};
          item.nombre = form.period.value.nombre;
          item.type = "tipPeriod";
          group1.push(item);
        }

        if (paramsForm.fechaInicio && paramsForm.fechaFin === "") {
          const datePeriod = {
            id: 4,
            type: "tipPeriod",
            nombre:
              (getLan() === "es" ? "Desde " : "From ") +
              formatDate(paramsForm.fechaInicio),
          };

          periods.push(datePeriod);
          form.period.value = datePeriod;
          group1 = group1.filter((item) => item.type !== "tipPeriod");
          let item = {};
          item.nombre = form.period.value.nombre;
          item.type = "tipPeriod";
          group1.push(item);
        }
        if (paramsForm.fechaFin && paramsForm.fechaInicio === "") {
          const datePeriod = {
            id: 4,
            type: "tipPeriod",
            nombre:
              (getLan() === "es" ? "Hasta " : "To ") +
              formatDate(paramsForm.fechaFin),
          };
          periods.push(datePeriod);
          form.period.value = datePeriod;
          group1 = group1.filter((item) => item.type !== "tipPeriod");
          let item = {};
          item.nombre = form.period.value.nombre;
          item.type = "tipPeriod";
          group1.push(item);
        }
      } else {
        form.period.value = "";
      }

      this.setState({
        form_filters: form,
        allSelelectFilters: group1,
        periods: periods,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.series != prevProps.series) {
      if (this.props.series && this.props.series.length > 0) {
        let series = this.props.series.map((element) => {
          return {
            nombre: element.nombre,
            id: `${element.id}_${element.nombre}`,
            // fondo: this.props.claveFondo,
            type: "tipSeries",
          };
        });

        if (
          this.props.paramsForm &&
          this.props.paramsForm.serie &&
          this.props.paramsForm.serie.length === 0
        ) {
          let group1 = [...this.state.allSelelectFilters];
          group1 = group1.filter((item) => item.type !== "tipSeries");
          this.props.series.map((element) => {
            let item = {};
            item.nombre = element.nombre;
            item.id = `${element.id}_${element.nombre}`;
            item.type = "tipSeries";
            group1.push(item);

            return null;
          });

          const form = this.state.form_filters;

          form.series.value = series;
          if (this.props.paramsForm.serie.length === 0) {
            this.props.setTop(series.map((itm) => itm.nombre));
          }
          this.setState({
            allSelelectFilters: group1,
            form_filters: form,
            series: series,
          });
        } else {
          let group1 = [...this.state.allSelelectFilters];
          group1 = group1.filter((item) => item.type !== "tipSeries");
          this.props.paramsForm.serie.map((element) => {
            let item = {};
            item.nombre = element;
            item.id = `${element}_${element}`;
            item.type = "tipSeries";
            group1.push(item);

            return null;
          });

          const form = this.state.form_filters;
          const serie = series.filter((ser) =>
            this.props.paramsForm.serie.find((ser2) => ser2 === ser.nombre)
          );

          form.series.value = serie;
          this.setState({
            allSelelectFilters: group1,
            form_filters: form,
          });
        }
        // if (this.props.claveFondo && this.props.fondos) {
        //   let fondo = this.props.fondos.find(
        //     (itm) => itm.id === parseInt(this.props.claveFondo)
        //   );
        //   series = [
        //     {
        //       label: fondo ? fondo.nombre : null,
        //       sublabel: getLan() === "es" ? "Clave de fondo: " : "Tiker Fund: ",
        //       options: series,
        //     },
        //   ];
        // }
        this.setState({
          series: series,
        });
      }
    }
  }

  getHistorico(form = null) {
    const paramsForm = this.props.paramsForm;
    let series = [];
    if (form && form.series.value) {
      series = form.series.value.map((itm) => {
        return itm.nombre;
      });
      this.props.setTop(series);
    }

    let fechaInicio =
      form && form.period.value === ""
        ? null
        : form && form.fechaInicio !== null
        ? form.fechaInicio
        : paramsForm.fechaInicio;
    let fechaFin =
      form && form.period.value === ""
        ? null
        : form && form.fechaFin !== null
        ? form.fechaFin
        : paramsForm.fechaFin;

        if (fechaInicio !== null && fechaInicio !==  "" && fechaFin === null || fechaFin === "") {
          fechaFin = formatDateReverse(getRealDateMilliesDayMonthYear(new Date()));
        }
        this.props.setFecha(fechaInicio, fechaFin);

    this.props.oninitHistorico(
      null,
      null,
      paramsForm.claveOperadora,
      paramsForm.claveFondo,
      paramsForm.tipoFondo,
      paramsForm.clasificacionFondo,
      series.length > 0 ? [...series] : paramsForm.serie,
      form && form.period.value === ""
        ? null
        : form && form.fechaInicio !== null
        ? form.fechaInicio 
        : paramsForm.fechaInicio,
      form && form.period.value === ""
        ? null
        : form && form.fechaFin !== null
        ? form.fechaFin
        : paramsForm.fechaFin
    );
    this.setState({
      loader: true
    })
  }

  onFilterClaveFondoHandler = (
    claveOperadora = null,
    tipoFondo = null,
    clasificacionFondo = null
  ) => {
    let params = {
      claveOperadora: claveOperadora,
      tipoFondo: tipoFondo,
      clasificacionFondo: clasificacionFondo,
      precios: true,
    };
    this.props.onFilterClaveFondo(params);
  };

  onFilterSerieFondoHandler = (
    claveOperadora = null,
    claveFondo = null,
    tipoFondo = null,
    clasificacionFondo = null
  ) => {
    let params = {
      claveOperadora: claveOperadora,
      claveFondo: claveFondo,
      tipoFondo: tipoFondo,
      clasificacionFondo: clasificacionFondo,
      precios: true,
    };
    this.props.onFilterSerie(params);
  };

  setDatoCombos() {}

  formModelHandler = (modelo) => {
    switch (modelo) {
      case "modelo_1":
        return this.printFrameModelOneHandler();
      default:
        return null;
    }
  };

  printFrameModelOneHandler = () => {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    const input = this.state.toggleFilters
      ? this.showFiltersSelectedHandler()
      : this.showFiltersSelectedHandler();
    return (
      <div className="col-12 sin_padding_L sin_padding_R row">
        <div className="col sin_padding">
          <div className="row">
            <div
              className={[
                "col",
                "col-lg-3",
                "text-center",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div
                className={[
                  "texto_btn_cerrar",
                  classes.toggle_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_borders_filter_button_model_one
                    : null,
                ].join(" ")}
                onClick={this.onToggleFilters}
              >
                <button
                  type="button"
                  className="btn"
                  disabled={!this.state.disableEnableButtons}
                >
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar filtro"
                        : "Close filter"
                      : getLan() === "es"
                      ? "Personalizar gráfica"
                      : "Customize chart"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={[
                "col",
                this.state.toggleFilters ? "col-lg-9" : "col-lg-9",
                classes.no_col_padding_model_one,
              ].join(" ")}
            >
              <div className={classes.filters_selected_container_model_one}>
                {input}
              </div>
            </div>
          </div>
          <div
            className={[
              "row",
              classes.main_filter_container_model_one,
              !this.state.toggleFilters ? classes.hidden : null,
            ].join(" ")}
          >
            <div
              className={["col", classes.no_col_padding_model_one].join(" ")}
            >
              {this.modelOne()}
            </div>
            {loader}
          </div>
        </div>
      </div>
    );
  };

  modelOne = () => {
    const form_filters = this.state.form_filters;

    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              series: form_filters.series.value,
              period: form_filters.period.value,
            }}
            render={({
              optionsSeries = this.state.series ? this.state.series : [],
              optionsPeriod = this.state.periods ? this.state.periods : [],
              form,
            }) => (
              <form
                ref={this.formRef1}
                onSubmit={this.onSubmitFilterModelOneHandler}
              >
                {/* fondos */}
                {/* <div className="row">
                <div className={["col-12 col-sm-12 col-md-9 col-lg-7 col-xl-5", "", "margen_derecha_15"].join(" ")}>
                    <Field
                      name="tops"
                      id="tops"
                      label=""
                      type="select"
                      elementType="select"
                      isMultiSelect={true}
                      isClearable={true}
                      campoLabel={"nombre"}
                      classes="custom-select"
                      options={optionsTop}
                      onInputChange={event => this.onInputSelectedModelOne(event, "tops")}
                      component={renderInput}
                    />
                  </div>
                </div> */}
                <div className="row">
                  <div
                    className={[
                      "col-12 col-sm-12 col-md-9 col-lg-7 col-xl-5",
                      "",
                      "margen_derecha_15",
                    ].join(" ")}
                  >
                    <Field
                      name="series"
                      id="series"
                      label="Series"
                      type="select"
                      elementType="select"
                      isMultiSelect={true}
                      isClearable={true}
                      campoLabel={"nombre"}
                      classes="custom-select"
                      options={optionsSeries}
                      onInputChange={(event) =>
                        this.onInputSelectedModelOne(event, "series")
                      }
                      component={renderInput}
                      isGroup={true}
                    />
                  </div>

                  <div
                    className={[
                      "margen_top_20 col-12 col-sm-12",
                      this.state.form_filters.series.value.length > 0
                        ? null
                        : "d-none",
                    ].join(" ")}
                  >
                    {this.state.form_filters.series.value.map((top, i) => {
                      return (
                        <div
                          key={"top_" + i}
                          className={[
                            classes.top,
                            `top_${i + 1}`,
                            classes.vertical_div,
                          ].join(" ")}
                        >
                          <div className={classes.vertical}>
                            <span className={"montse texto_12 color_blanco"}>
                              {top.nombre}
                            </span>
                            <span
                              className={[
                                classes.filter_icon_button_model_one,
                                classes.derecha_close,
                              ].join(" ")}
                            >
                              <i
                                className="fas fa-times color_blanco"
                                onClick={() =>
                                  this.onDeleteFilterModelOneHandler(top)
                                }
                              />
                            </span>
                          </div>{" "}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className={["row margen_top_20"].join(" ")}>
                  <div
                    className={["col-lg-5", "", "margen_derecha_15"].join(" ")}
                  >
                    <Field
                      name="period"
                      id="period"
                      label={getLan() === "es" ? "Periodo" : "Period"}
                      value={this.state.form_filters.period.value}
                      campoLabel={"nombre"}
                      type="select"
                      elementType="select"
                      isSingle={true}
                      isClearable={false}
                      classes="custom-select"
                      options={optionsPeriod}
                      // {optionsperiod}
                      onInputChange={(event) =>
                        this.onInputSelectedModelOne(event, "period")
                      }
                      component={renderInput}
                    />
                  </div>
                  <div
                    className={[
                      "margen_top_20 col-12 col-sm-12",
                      this.state.form_filters.period.value &&
                      this.state.form_filters.period.value !== "" &&
                      this.state.form_filters.period.value.nombre
                        ? null
                        : "d-none",
                    ].join(" ")}
                  >
                    <div
                      className={[
                        classes.top,
                        classes.top_fecha,
                        classes.vertical_div,
                      ].join(" ")}
                    >
                      <div className={classes.vertical}>
                        <span className={"montse texto_12 color_blanco"}>
                          {this.state.form_filters.period.value.nombre}
                        </span>
                        <span
                          className={[
                            classes.filter_icon_button_model_one,
                            classes.derecha_close,
                          ].join(" ")}
                        >
                          <i
                            className="fas fa-times color_blanco"
                            onClick={() =>
                              this.onDeleteFilterModelOneHandler(
                                this.state.form_filters.period.value
                              )
                            }
                          />
                        </span>
                      </div>{" "}
                    </div>
                  </div>
                </div>

                {/* ======================= */}

                <hr />
                {this.sectionButtonsModelOne(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonsModelOne = (form) => {
    const disabledButton = !this.state.form_filters.errorDate ? false : true;
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12  col-lg-4 offset-lg-4 text-center">
          <button
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14",
            ].join(" ")}
            disabled={disabledButton}
            onClick={this.onToggleFilters}
          >
            {getLan() === "es" ? "Cerrar Filtros" : "Close"}
          </button>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center">
          <button
            type="button"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14",
            ].join(" ")}
            onClick={() => this.onResetFilterModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? " Limpiar filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterModelOneHandler = (event) => {
    event.preventDefault();
  };

  onSubmitSearchModelOneHandler = () => {};

  onInputChangeModelOne = (event) => {
    const value = event.target.value;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;
    if (value !== "") {
      this.props.onFilterClaves(value);
    } else {
      disabled = true;
    }
    this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
  };

  limpiaForms() {
    const form1 = { ...this.state.form_filters };
    let group1 = [...this.state.allSelelectFilters];

    form1.period.value = "";
    form1.series.value = [];

    group1 = group1.filter((itemd) => itemd.type !== "tipPeriod");
    group1 = group1.filter((itemd) => itemd.type !== "tipSeries");

    // this.onInputSelectedModelOne(null, "tops", [{ nombre: "Top 5", id: -1 }]);
    this.getHistorico(form1);

    this.setState({
      allSelelectFilters: group1,
      clean: !this.state.clean,
      form_filters: form1,
      toggleInputs: false,
    });
  }

  onResetFilterModelOneHandler = (form) => {
    form.reset();
    this.limpiaForms();
  };

  onToggleFilters = () => {
    this.setState((prevState) => {
      return {
        toggleFilters: !prevState.toggleFilters,
      };
    });
  };

  onInputModelOneChange = (event) => {
    const value = event.target.value;
    const disabled = value ? false : true;
    this.setState({
      disableEnableButtons: disabled,
    });
  };

  onFilterInstrumentoHandler = () => {
    this.props.onFilterenvios(null);
  };

  onFilterTipoValorHandler = (instrumento = null, tipoValor) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor,
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterDocumentos = (mantenimientoId = null, listadoId = null) => {
    const params = {
      tipoInformacion: mantenimientoId,
      tipoInformacionListado: listadoId,
    };
    this.props.onFilterTipoDocumentos(params);
  };

  onCheckIfDatesAreValid = (inicio, fin) => {
    return inicio > fin;
  };

  getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const A = parseInt(a.id);
    const B = parseInt(b.id);

    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }
    return comparison;
  }

  onInputSelectedModelOne = (
    event = null,
    type = null,
    valor_inicial = null
  ) => {
    let group1 = [...this.state.allSelelectFilters];
    let value = [];
    if (valor_inicial !== null) {
      value = valor_inicial;
    } else {
      value = event;
    }
    const form1 = { ...this.state.form_filters };
    let top5 = this.state.topSeleccionado;

    if (type === "series") {
      form1.series.value = value;

      group1 = group1.filter((item) => item.type !== "tipSeries");

      form1.series.value.map((valor, i) => {
        valor.type = "tipSeries";
        group1.push(valor);
        return null;
      });

      this.getHistorico(form1);
    }
    if (type === "period") {
      value.type = "tipPeriod";
      form1.period.value = value;

      group1 = group1.filter((item) => item.type !== "tipPeriod");
      let item = {};
      item.nombre = value.nombre;
      item.type = "tipPeriod";
      group1.push(item);
      switch (value.id) {
        case 1:
          form1.fechaInicio = restaMes(new Date(), 1)
            .toISOString()
            .slice(0, 10);
          form1.fechaFin = "";
          break;
        case 2:
          form1.fechaInicio = restaAnio(new Date(), 1)
            .toISOString()
            .slice(0, 10);
          form1.fechaFin = "";
          break;
        case 3:
          form1.fechaInicio = restaAnio(new Date(), 5)
            .toISOString()
            .slice(0, 10);
          form1.fechaFin = "";
          break;
        case 4:
          form1.fechaInicio = this.props.paramsForm.fechaInicio;
          form1.fechaFin = this.props.paramsForm.fechaFin;
          break;

        default:
          break;
      }
      this.getHistorico(form1);
    }

    this.setState({
      allSelelectFilters: group1,
      form_filters: form1,
      topSeleccionado: top5,
    });
  };

  onDeleteFilterModelOneHandler = (item) => {
    let group1 = [...this.state.allSelelectFilters];
    const form1 = { ...this.state.form_filters };
    let top5 = this.state.topSeleccionado;

    if (item.type === "tipSeries") {
      group1 = group1.filter((itemd) => itemd.id !== item.id);
      const seriesSobrantes = form1.series.value.filter(
        (tipv) => tipv.id !== item.id
      );

      form1.series.value = seriesSobrantes;
    }
    if (item.type === "tipPeriod") {
      group1 = group1.filter((itemd) => itemd.type !== "tipPeriod");
      form1.period.value = "";
    }

    this.getHistorico(form1);
    this.setState({
      allSelelectFilters: group1,
      form_filters: form1,
    });
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.allSelelectFilters];
    filters = (
      <div
        className={classes.filter_container_outside_model_one}
        id="allFitersContainers"
      >
        {filterList.map((item) => (
          <div
            className={classes.filter_container_model_one}
            key={`${item.nombre}-${item.id}`}
          >
            <span
              className={["montse", classes.filter_text_model_one].join(" ")}
            >
              {item.nombre}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i
                className="far fa-times-circle"
                onClick={() => this.onDeleteFilterModelOneHandler(item)}
              />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    this.setState((prevState) => {
      if (!prevState.toggleInputs === false) {
      }
      return {
        toggleInputs: !prevState.toggleInputs,
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map((item) => (
      <li
        className="list-group-item"
        onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}
      >
        {item.clave}
      </li>
    ));
  };

  onPrintOptionSelectedHandler = (value) => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div
        className={[
          classes.selectedOptionContainer,
          !this.state.toggleInputs ? classes.hidden : null,
        ].join(" ")}
      >
        <span className={classes.filterContainer}>
          <span
            className={[classes.filterText, "montse texto_14 regular"].join(
              " "
            )}
          >
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span
            className={classes.filterIconButton}
            onClick={() => this.onToggleFilterInputOptionSelected(1)}
          >
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  render() {
    const filter = this.props.modelo
      ? this.formModelHandler(this.props.modelo)
      : null;

    return filter;
  }
}

const mapStateToProps = (state) => {
  return {
    fondos: state.fondos.clavesFondoHistorico,
    series: state.fondos.seriesHistorico,
    historico: state.fondos.historicoConcentradoPrecios,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    oninitHistorico: (
      size,
      page,
      claveOperadora,
      claveFondo,
      tipo,
      clasificacion,
      serie,
      fechaInicio,
      fechaFin
    ) => {
      dispatch(
        action.initFondosConcentradoPreciosHistoricoGrafica(
          size,
          page,
          claveOperadora,
          claveFondo,
          tipo,
          clasificacion,
          serie,
          fechaInicio,
          fechaFin
        )
      );
    },
    onFilterClaveFondo: (params) => {
      dispatch(action.initClavesFondosHistorico(params));
    },
    onFilterSerie: (params) => {
      dispatch(action.initSerieFondosHistorico(params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterGraficaHistoricoConcentradoPreciosFondos);

import React, { Component } from "react";
import classes from "./EducacionBursatil.css";
import { connect } from "react-redux";

import Auxs from "../../../hoc/auxs/Auxs";
import Cuadricula from '../../../components/ui/cuadricula/Cuadricula';

class EducacionBursatil extends Component {
  onPrintTerminos = terminos => {
    return (
      <Cuadricula
        datos={terminos}
        coloreados={[]}
        saltarse={[]}
        enBlanco={[]}
        quitar={[]}
        modulo={3}
        tipo={3}
      />
    );
  };
  render() {
    const terminos = null;
    // this.props.terminos
    //   ? this.onPrintTerminos(this.props.terminos[4].data)
    //   : null;
    return (
      <Auxs>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={["margen_top_30"].join(" ")}>
              <h1 className={["nunito", "texto_42"].join(" ")}>Educación bursátil</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div
              className={[
                classes.text_container,
                "margen_top_50"
              ].join(" ")}
            >
              <p className={["texto_16",
                "montse"].join(" ")}>
                Los expertos de BIVA comparten su experiencia y conocimiento
                mediante cursos impartidos en alianza con destacadas
                instituciones de educación superior, puedes conocerlos en
                nuestra sección Instituto BIVA.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={["margen_top_50"].join(" ")}>
              {terminos}
            </div>
          </div>
        </div>
        
      </Auxs>
    );
  }
}

const mapStateToProps = state => {
  // return {
  // };
};

export default connect(mapStateToProps)(EducacionBursatil);

import React, { Component } from "react";
import classes from "./FilterInformacionCorporativaAfores.css";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";

import { changeDateFormat } from "../../../stores/utils/utilities";

import { connect } from "react-redux";
import * as actions from "../../../stores/actions/index";
import { getLan } from "../../../stores/utils/utilities";

import Input from "../input/Input";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiple = null,
  options,
  defaultValue = null,
  checked = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className:
        classes && elementType === "checkbox"
          ? classes
          : [classes, "form-control"].join(" "),
      ref: ref,
      disabled: disabled,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : getLan() === "es" ? "Seleccione una opción" : "Select an option"
  };

  return (
    <div className="form-group">
      {label && input.type !== "radio" && input.type !== "checkbox" ? (
        <label for={input.name} className={["texto_14", "montse", "regular"].join(" ")}>
          {label}
        </label>
      ) : null}
      <Input config={params} />
    </div>
  );
};

class FilterInformacionCorporativaAfores extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
  }

  state = {
    toggleFilters: true,
    disableEnableButtons: true,
    selectedFilters: {
      clave_afore: [],
      clave_siefore: [],
      tipo_documento: [],
      fechas: []
    },
    form_filters: {
      clave_afore: {
        value: ""
      },
      clave_siefore: {
        value: ""
      },
      tipo_documento: {
        value: []
      },
      fecha_inicio: {
        value: ""
      },
      fecha_fin: {
        value: ""
      },
      errorDate: false
    },
    inputPivot: false,
  };

  componentDidMount() {
    this.onFilterClaveAforeHandler();
    this.onFilterClaveSieforeHandler();
    this.onFilterTipoDocumentoHandler();
  }

  onFilterClaveAforeHandler = (claveSiefore = null, tipoDocumento = null) => {
    let params = {
      empresa: claveSiefore,
      tipoDocumento: tipoDocumento
    };
    this.props.onFilterClaveAfore(params);
  };

  onFilterClaveSieforeHandler = (claveAfore = null, tipoDocumento = null) => {
    let params = {
      empresaAsociada: claveAfore,
      tipoDocumento: tipoDocumento
    };
    this.props.onFilterClaveSiefore(params);
  };

  onFilterTipoDocumentoHandler = (claveAfore = null, claveSiefore = null) => {
    let params = {
      empresaAsociada: claveAfore,
      empresa: claveSiefore
    };
    this.props.onFilterTipoDocumentos(params);
  };

  printFrameHandler = () => {
    const input = this.showFiltersSelectedHandler();
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div
              className={[
                "col",
                "col-lg-3",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  "texto_btn_cerrar", classes.toggle_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_borders_filter_button_model_one
                    : null
                ].join(" ")}
                onClick={
                  this.state.disableEnableButtons ? this.onToggleFilters : null
                }
              >
                <button
                  type="button"
                  className="btn"
                  disabled={!this.state.disableEnableButtons}
                >
                  <i className="fas fa-filter" />
                  <span>
                    {this.state.toggleFilters
                      ? getLan() === "es"
                        ? "Cerrar Filtros"
                        : "Close"
                      : getLan() === "es"
                        ? "Filtrar"
                        : "Filter"}
                  </span>
                </button>
              </div>
            </div>
            <div
              className={[
                "col",
                "col-lg-9",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div className={classes.filters_selected_container_model_one}>
                {input}
              </div>
            </div>
          </div>
          <div
            className={[
              "row",
              classes.main_filter_container_model_one,
              !this.state.toggleFilters ? classes.hidden : null
            ].join(" ")}
          >
            <div
              className={["col", classes.no_col_padding_model_one].join(" ")}
            >
              {this.printModel()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  printModel = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterHandler}
            initialValues={{
              clave_afore: this.state.form_filters.clave_afore.value,
              clave_siefore: this.state.form_filters.clave_siefore.value,
              tipo_documento: this.state.form_filters.tipo_documento.value,
              fecha_inicio: this.state.form_filters.fecha_inicio.value,
              fecha_fin: this.state.form_filters.fecha_fin.value
            }}
            render={({
              optionsClaveAfore = this.props.clave_afore
                ? this.onPrepareOptionHandler(this.props.clave_afore)
                : [],
              optionsClaveSiefore = this.props.clave_siefore
                ? this.onPrepareOptionHandler(this.props.clave_siefore)
                : [],
              optionsDocumento = this.props.tipo_documento
                ? this.onPrepareOptionHandler(this.props.tipo_documento)
                : [],
              form
            }) => (
                <form ref={this.formRef1} onSubmit={this.onSubmitFilterHandler}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <Field
                        name="clave_afore"
                        id="clave_afore"
                        label={getLan() === "es" ? "Clave de AFORE" : "AFORE"}
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        options={optionsClaveAfore}
                        onInputChange={event =>
                          this.onInputSelectedHandler(event, 0)
                        }
                        component={renderInput}
                      />
                    </div>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-4"].join(" ")}>
                      <Field
                        name="clave_siefore"
                        id="clave_siefore"
                        label={getLan() === "es" ? "Clave de SIEFORE" : "SIEFORE"}
                        type="select"
                        elementType="select"
                        classes="custom-select"
                        options={optionsClaveSiefore}
                        onInputChange={event =>
                          this.onInputSelectedHandler(event, 1)
                        }
                        component={renderInput}
                      />
                    </div>
                    <div className={["col-12 col-sm-12 col-md-12 col-lg-4 sin_padding"].join(" ")} >
                      <div className="row">
                        <div
                          className={["col", "col-lg-6"].join(" ")}
                        >
                          <Field
                            name="fecha_inicio"
                            id="fecha_inicio"
                            type="date"
                            classes={classes.fechaInicio}
                            label={getLan() === "es" ? "Fecha inicio" : "From"}
                            onInputChange={event =>
                              this.onInputSelectedHandler(event, 3)
                            }
                            component={renderInput}
                          />
                        </div>
                        <div
                          className={["col", "col-lg-6"].join(" ")}
                        >
                          <Field
                            name="fecha_fin"
                            id="fecha_fin"
                            type="date"
                            classes={classes.fechaFin}
                            label={getLan() === "es" ? "Fecha fin" : "To"}
                            onInputChange={event =>
                              this.onInputSelectedHandler(event, 4)
                            }
                            component={renderInput}
                          />
                        </div>
                        <div
                          className={[
                            classes.errorContainer,
                            !this.state.form_filters.errorDate
                              ? classes.hidden
                              : null
                          ].join(" ")}
                        >
                          <p className={"texto_14 montse regular"}>
                            {getLan() === "es"
                              ? "La fecha final debe ser mayor a la fecha inicial."
                              : "End date must be greater than start date."}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={["col-12 col-sm-12 col-md-6 col-lg-4", optionsDocumento !== null && optionsDocumento.length !== undefined && optionsDocumento.length > 0 ? null : "d-none"].join(" ")}>
                      <span className="montse texto_14 regular color_negro">
                        {getLan() === "es" ? "Tipo de documento" : "Document type"}
                      </span>
                      {optionsDocumento.map(est => {
                        return (
                            <div className={["row", classes.margin_check].join(" ")}>
                            {this.onPrintTipoDocumentos(est)}
                          </div>
                        );
                      })}
                    </div>
                    
                  </div>

                  <hr />
                  {this.sectionButtonHandler(form)}
                </form>
              )}
          />
        </div>
      </div>
    );
  };

  onPrintTipoDocumentos = tipo => {
    const form = this.state.form_filters;
    const optionValue = `${tipo.id}_${tipo.nombre}`;

    return (
      <Field
        name="tipo_documento"
        id={tipo.nombre}
        type="checkbox"
        elementType="checkbox"
        value={optionValue}
        classes="form-check-input"
        label={tipo.nombre}
        checked={form.tipo_documento.value.find(item => item === optionValue)}
        onInputChange={event => this.onInputSelectedHandler(event, 2)}
        component={renderInput}
      />
    );
  };

  sectionButtonHandler = form => {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-8 text-center">
          <button
            type="button"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14"
            ].join(" ")}
            onClick={() => this.onResetFilterHandler()}
          >
            <i className="fas fa-redo-alt" />
            {getLan() === "es" ? "Limpiar Filtros" : "Clear"}
          </button>
        </div>
      </div>
    );
  };

  // Verifica si las fechas ingresadas soon validas para realizar la busqueda
  onCheckIfDatesAreValidHandler = (inicio, fin) => {
    return inicio > fin;
  };

  // Permite ocultar o mostrar la seccion del formulario del filtro
  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onSubmitFilterHandler = event => {
    event.preventDefault();
  };

  // Limpia le formulario
  onResetFilterHandler = () => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    // Limpiando el filtro clave_afore
    form.clave_afore.value = "";
    selectedFilters.clave_afore = [];
    // Limpiando el filtro clave_siefore
    form.clave_siefore.value = "";
    selectedFilters.clave_siefore = [];
    // Limpiando el filtro tipo_documento
    form.tipo_documento.value = [];
    selectedFilters.tipo_documento = [];
    // Limpiando el filtro fecha_inicio y fecha_fin
    form.fecha_inicio.value = "";
    form.fecha_fin.value = "";
    selectedFilters.fechas = [];
    // Se limpia errorData para validacion de fechas
    form.errorDate = false;

    let inputPivot = false;

    this.onFilterClaveAforeHandler();
    this.onFilterClaveSieforeHandler();
    this.onFilterTipoDocumentoHandler();

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      inputPivot: inputPivot
    });

    this.onFilterDataByEvent();
  };

  // Formatea los valores de los campos select
  onPrepareOptionHandler = type => {
    let options = [];
    if (type && type.length > 0) {
      type.forEach(element => {
        options.push({
          nombre: element.nombre,
          id: `${element.id}_${element.nombre}`
        });
      });
    }
    return options;
  };

  // Retorna el id
  onSplitValueHandler = options => {
    let newValues = null;

    if (!Array.isArray(options)) {
      newValues = options.split("_")[0];
      return newValues;
    } else {
      newValues = [];
      options.forEach(option => {
        newValues.push(option.split("_")[0]);
      });
      return [...newValues];
    }
  };

  // Retorna el valor, etiqueta de una valor recibido como parametro
  onGetValueLabelAndTypeHandler = (value, type) => {
    let newValue = null;
    let newLabel = null;

    if (type !== 3 && type !== 4) {
      newValue = value.split("_")[0];
      newLabel = value.split("_")[1];
    } else {
      // Fechas inicio o fin
      [newValue] = changeDateFormat(value);
      newLabel = type === 3 ? "fecha_inicio" : "fecha_fin";
    }

    return [newValue, newLabel];
  };

  // Retornas los valores actuales del input seleccionado (state y filtros seleccionados)
  onGetNameStateFilterByInputHandler = type => {
    const form = this.state.form_filters;
    const selectedFilters = this.state.selectedFilters;

    switch (type) {
      case 0:
        return [
          "clave_afore",
          form.clave_afore.value,
          selectedFilters.clave_afore
        ];
      case 1:
        return [
          "clave_siefore",
          form.clave_siefore.value,
          selectedFilters.clave_siefore
        ];
      case 2:
        return [
          "tipo_documento",
          form.tipo_documento.value,
          selectedFilters.tipo_documento
        ];
      case 3:
        return [
          "fecha_inicio",
          form.fecha_inicio.value,
          selectedFilters.fechas,
          "fechas"
        ];
      case 4:
        return [
          "fecha_fin",
          form.fecha_fin.value,
          selectedFilters.fechas,
          "fechas"
        ];
    }
  };

  // Retorna todos los valores de los campos del formulario.
  onGetAllValuesFormFilter = (inputName = null, newStateValues = null) => {
    const form = this.state.form_filters;

    if (inputName && newStateValues) {
      form[`${inputName}`].value = newStateValues;
    }

    // Se obtiene el valor del campo clave afore
    const valuesClaveAfore =
      form.clave_afore.value && form.clave_afore.value !== ""
        ? this.onSplitValueHandler(form.clave_afore.value)
        : "";
    // Se obtiene el valor del campo clave siefore
    const valuesClaveSiefore =
      form.clave_siefore.value && form.clave_siefore.value !== ""
        ? this.onSplitValueHandler(form.clave_siefore.value)
        : "";
    // Se obtiene el valor del campo tipo documento
    const valuesTipoDocumento =
      form.tipo_documento.value && form.tipo_documento.value.length > 0
        ? this.onSplitValueHandler(form.tipo_documento.value)
        : [];
    // Se obtiene el valor del campo fecha inicio
    const valuesFechaInicio =
      form.fecha_inicio.value && form.fecha_inicio.value !== ""
        ? form.fecha_inicio.value
        : "";
    // Se obtiene el valor del campo fecha fin
    const valuesFechaFin =
      form.fecha_fin.value && form.fecha_fin.value !== ""
        ? form.fecha_fin.value
        : "";

    return [
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoDocumento,
      valuesFechaInicio,
      valuesFechaFin
    ];
  };

  // Agrega o elimina valores de estado para un campo del formulario
  onChangeStateHandler = (inputOptions = null, values = null, type = null) => {
    let newStateValues = null;

    if (type !== 2) {
      if (values && values !== "") {
        newStateValues = values;
      }
    } else {
      newStateValues = [];
      newStateValues = [...inputOptions];
      const stateExists = newStateValues.find(option => option === values);
      if (stateExists) {
        newStateValues = newStateValues.filter(option => option !== values);
      } else {
        if (values && values !== "") {
          newStateValues.push(values);
        } else {
          newStateValues = [];
        }
      }
    }

    return newStateValues;
  };

  // Agrega o elimina filtros a la barra de filtro seleccionados
  onChangeFilterHandler = (
    filterOptions = null,
    values = null,
    label = null,
    type = null
  ) => {
    let newFilterValues = [];

    if (type === 0 || type === 1) {
      if (values && values !== "") {
        newFilterValues.push({
          label: label,
          value: values,
          type: type
        });
      }
    } else {
      newFilterValues = [...filterOptions];
      const filterExists = newFilterValues.find(
        option => option.label === label
      );
      if (filterExists) {
        newFilterValues = newFilterValues.filter(
          option => option.label !== label
        );
        if (values && values !== "" && (type === 3 || type === 4)) {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      } else {
        if (values && values !== "") {
          newFilterValues.push({ label: label, value: values, type: type });
        }
      }
    }

    return newFilterValues;
  };

  onFilterDataByEvent = () => {
    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoDocumento,
      valuesFechaInicio,
      valuesFechaFin
    ] = this.onGetAllValuesFormFilter();


    this.props.onFilterAfores(
      this.props.itemsPerPage,
      0,
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoDocumento,
      valuesFechaInicio,
      valuesFechaFin
    );

    /* this.props.setPage(1); */

    let wasFiltered = false;
    if (!this.state.form_filters.errorDate) {
      // Se invoca la funcion del componente padre (ConcentradoPreciosAfores.js) para setear los valores correspondientes
      // Configurando valor del campo clave_afore
      this.props.onSetClaveAfore(valuesClaveAfore);
      // Configurando valor del campo clave_siefore
      this.props.onSetClaveSiefore(valuesClaveSiefore);
      // Configurando valor del campo tipo_siefore
      this.props.onSetTipoDocumento(valuesTipoDocumento);
      // Configurando valor del campo fecha_inicio
      this.props.onSetFechaInicio(valuesFechaInicio);
      // Configurando valor del campo fecha_fin
      this.props.onSetFechaFin(valuesFechaFin);

      if (
        (valuesClaveAfore !== "" ||
          valuesClaveSiefore !== "" ||
          valuesTipoDocumento !== "" ||
          valuesFechaInicio !== "",
          valuesFechaFin !== "")
      ) {
        wasFiltered = true;
      }
      this.props.onSetWasFiltered(wasFiltered);
    }

  };

  // Configura un nuevo estado del elemento seleccionado y lo coloca ne la barra de filtros
  onInputSelectedHandler = (
    event = null,
    type = null,
    item = null,
    eraser = null
  ) => {
    let actualValue = eraser ? event : event.target.value;

    let form = this.state.form_filters;
    let selectedFilters = this.state.selectedFilters;
    let inputPivot = this.state.inputPivot;
    let errorDate = form.errorDate;

    let newValue = null;
    let newLabel = null;

    if (eraser) {
      actualValue = item.type === 2 ? item.value : "";
      newValue = "";
      newLabel = item.label;
      type = item.type;
    } else {
      // Solo para los campos distintos a tipo instrumento y tipo valor
      [newValue, newLabel] = this.onGetValueLabelAndTypeHandler(
        actualValue,
        type
      );
    }

    let newStateValues = null;
    let newFilterValues = null;
    let [
      inputName,
      stateValues,
      filterValues,
      otherInputName
    ] = this.onGetNameStateFilterByInputHandler(type);

    // Configura el estado y el filtro seleccionado para cada input
    newStateValues = this.onChangeStateHandler(stateValues, actualValue, type);
    newFilterValues = this.onChangeFilterHandler(
      filterValues,
      actualValue,
      newLabel,
      type
    );

    form[`${inputName}`].value = newStateValues;
    selectedFilters[
      `${otherInputName ? otherInputName : inputName}`
    ] = newFilterValues;

    // Se obtienen todos los valores de los campos del formulario (Filtro)
    const [
      valuesClaveAfore,
      valuesClaveSiefore,
      valuesTipoDocumento,
      valuesFechaInicio,
      valuesFechaFin
    ] = this.onGetAllValuesFormFilter(inputName, newStateValues);

    if (type === 0) {
      // Clave Afore
      // Cuando el campo clave afore es seleccionado de primero
      if (!inputPivot || inputPivot === "firstPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
        if (eraser) {
          inputPivot = false;
        } else {
          // Se configura a null inputPivot si el valor de clave_afore es vacio
          inputPivot = valuesClaveAfore !== "" ? "firstPath" : null;
        }
        // Limpiando valores campo clave siefore
        form.clave_siefore.value = "";
        selectedFilters.clave_siefore = [];
        // Filtrando valores para campo clave siefore
        this.onFilterClaveSieforeHandler(valuesClaveAfore);
      } else if (inputPivot === "secondPath") {
        // Pass
      }
    } else if (type === 1) {
      // Clave Siefore
      // Cuando el campo clave_siefore es seleccionado de primero
      if (!inputPivot || inputPivot === "secondPath") {
        // Cuando se elimina el filtro desde la barrra de filtros seleccionados o se setea a vacio
        if (eraser) {
          inputPivot = false;
        } else {
          // Se configura a null inputPivot si el valor de clave_siefore es vacio
          inputPivot = valuesClaveSiefore !== "" ? "secondPath" : null;
        }
        // Limpiando valores campo clave_afore
        form.clave_afore.value = "";
        selectedFilters.clave_afore = [];
        // Filtrando valores para campo clave_afore
        this.onFilterClaveAforeHandler(valuesClaveSiefore);
      } else if (inputPivot === "firstPath") {
        // Pass
      }
    } else if (type === 2) {
      // Tipo de Documento
      // Pass
    } else if (type === 3) {
      // Fecha inicio
      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_fin.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          actualValue,
          form.fecha_fin.value
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    } else if (type === 4) {
      //Fecha fin
      // Se verifican si la sfechas osn validas
      if (actualValue !== "" && form.fecha_inicio.value !== "") {
        errorDate = this.onCheckIfDatesAreValidHandler(
          form.fecha_inicio.value,
          actualValue
        );
      } else {
        errorDate = false;
      }
      form.errorDate = errorDate;
    }

    this.setState({
      form_filters: form,
      selectedFilters: selectedFilters,
      inputPivot: inputPivot
    });

    this.onFilterDataByEvent();
  };

  showFiltersSelectedHandler = () => {
    const selectedFilters = this.state.selectedFilters;
    const filterList = [
      ...selectedFilters.clave_afore,
      ...selectedFilters.clave_siefore,
      ...selectedFilters.tipo_documento,
      ...selectedFilters.fechas
    ];
    let filters = null;
    filters = (
      <div
        className={classes.filter_container_outside_model_one}
        id="allFitersContainers"
      >
        {filterList.map((item, i) => (
          <div
            className={classes.filter_container_model_one}
            key={`${item.label}-${item.id}-${i}`}
          >
            <span className={["montse", classes.filter_text_model_one].join(" ")}>
              {item.type === 3 || item.type === 4 ? item.value : item.label}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i
                className="far fa-times-circle"
                onClick={() =>
                  this.onInputSelectedHandler(null, null, item, true)
                }
              />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  render() {
    return this.printFrameHandler();
  }
}

const mapStateToProps = state => {
  return {
    clave_afore: state.afores.clavesAfore,
    clave_siefore: state.afores.clavesSiefore,
    tipo_documento: state.afores.tiposDocumento
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFilterClaveAfore: params => {
      dispatch(actions.initClavesAforeInformacionCorporativa(params));
    },
    onFilterClaveSiefore: params => {
      dispatch(actions.initClavesSieforeInformacionCorporativa(params));
    },
    onFilterTipoDocumentos: params => {
      dispatch(actions.initTipoDocumentoSiefores(params));
    },
    onFilterAfores: (size, page, claveAfore, claveSiefore, tipoDocumento, fechaInicio, fechaFin) => {
      dispatch(
        actions.initAforesInformacionCorporativa(
          size,
          page,
          claveAfore,
          claveSiefore,
          tipoDocumento,
          fechaInicio,
          fechaFin
        )
      );
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterInformacionCorporativaAfores)
);

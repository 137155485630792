import React, { Component } from "react";
import classes from "./Cuadro.css";
import { getUrlDocument } from "../../../../stores/utils/utilities";

class Cuadro extends Component {
  render() {
    const datos = this.props.datos;
    const clases_cuadro = [classes.cuadro];
    const clases_cuadro_div = [classes.cuadro_div];
    const clase_texto = [];
    const clase_imagen = [classes.imagen];
    let clase_texto_div = [];
    let clase_subtitulo_div = [];
    let clase_span = [];
    if (this.props.datos.clase === "titulo" || (this.props.datos.titulo_encabezado !== undefined && this.props.datos.titulo_encabezado !== null && this.props.datos.titulo_encabezado !== "")) {
      if (this.props.tipo === 2) {
        clase_texto_div.push(classes.texto_tipo_titulo_tipo2);
      } else if (this.props.tipo === 3) {
        clase_texto_div.push(classes.texto_tipo_titulo_tipo3);
      } else if (this.props.tipo === 4) {
        clase_texto_div.push(classes.texto_tipo_titulo_tipo4);
      } else if (this.props.tipo === 5) {
        clase_texto_div.push(classes.texto_tipo_titulo_tipo5);
      } else {
        clase_texto_div.push(classes.texto);
        clase_texto_div.push(classes.texto_tipo_titulo);
      }
    } else {
      clase_texto_div.push(classes.texto_tipo_normal);
      if (this.props.tipo === 3) {
        clase_texto_div.push(classes.texto_tipo_titulo_tipo3);
      }
      if (this.props.tipo === 5) {
        clase_texto_div.push(classes.texto_tipo_titulo_tipo5);
      }

    }
    if (this.props.justificado === true) {
      clase_span.push(classes.texto_justificado);

    }

    if (this.props.datos.icono_url === "") {
      clase_imagen.push(classes.no_mostrar);
      clase_texto.push(classes.margen_texto);
    }

    if (this.props.color) {
      clases_cuadro.push(classes.cuadro_azul);
    }
    if (this.props.salto) {
      clases_cuadro.push(classes.sin_fondo);
    }
    if (this.props.es_vacio) {
      clases_cuadro_div.push(classes.quitar_cuadro_div);
    }
    if (this.props.enBlanco) {
      clases_cuadro.push(classes.blanco_borde);
    }

    if (this.props.quitar) {
      clases_cuadro.push(classes.blanco_sin_borde);
    }

    if (this.props.tipo === 2) {
      clases_cuadro_div.push(classes.tipo2);
    }
    let btnSeleccion = null;
    if (this.props.llevaBoton) {
      btnSeleccion = (
        <div className={classes.contenedor_icon} onClick={e => this.props.cuadroSeleccion(e, this.props.datos)}>
          <i className={["fas fa-plus icon3", classes.icono_plus].join(" ")} />
        </div>
      );
    }
    let btn_enlace = null;
    if (this.props.tipo === 3) {
      clases_cuadro_div.push(classes.tipo2);
      btn_enlace = (
        <a href={this.props.datos.url_boton} target="_blank" rel="noopener noreferrer">
          <button className={[classes.btn_enlace, "texto_blanco"].join(" ")}>
            <span className={"bold"}>{this.props.datos.texto_boton}</span>
            <i className={["fas fa-long-arrow-alt-right"].join(" ")} />
          </button>
        </a>
      );
    }
    let ruta = null;
    if (datos.imagen) {

      ruta = getUrlDocument(datos.imagen);
    }
    let img = null;
    if (ruta === null) {

      if (this.props.datos.icono_url && this.props.datos.icono_url !== "") {
        let imgPath = require(`../../../../assets/icons/${this.props.datos.icono_url}`);
        img = <img src={imgPath} alt="" className={classes.icono} />;
      }
    } else {
      img = <img src={ruta} alt="" className={classes.icono} />;
    }
    let titulo = null;
    if (datos.texto) {
      titulo = datos.texto;
    }
    if (datos.texto_html) {
      titulo = datos.texto_html;
    }
    if (datos.titulo_encabezado) {
      titulo = datos.titulo_encabezado;
    }
    let titulo_opcional = null;
    let titulo_encabezado = null;
    if (datos.titulo) {
      titulo_encabezado = datos.titulo;
    }
    if (datos.titulo_encabezado) {
      titulo_encabezado = datos.titulo_encabezado;
    }
    if (this.props.datos.titulo) {
      titulo_opcional = (
        <p>
          <span className={"montse texto_16 bold"}>{titulo_encabezado}</span>
        </p>
      );
    }
    let subtitulo_opcional = null;
    if (datos.subtitulo) {

      subtitulo_opcional = datos.subtitulo;
      clase_subtitulo_div.push(classes.texto_tipo_subtitulo);
      clase_texto_div.push(classes.texto_tipo_normal_small);
      clases_cuadro.push(classes.cuadro_gris);
    }
    if (this.props.tipo === 5) {
      clase_texto_div.push(classes.texto_tipo_normal);
      clase_texto_div.push(classes.texto_tipo_titulo_tipo5);
      titulo_opcional = (
        <p className="text-center">
          <span className={"montse texto_16 bold"}>{titulo_encabezado}</span>
        </p>
      );
      if (datos.texto_html) {
        titulo = datos.texto_html;
      }
    }
    let clase_justificada = [classes.contenido];
    if (this.props.justificado) {
        clase_justificada = [classes.contenido_justificado];
    }
    if (this.props.type === 9 ) {
      clase_justificada = [classes.contenido_arriba];
    }
    if (datos.subtitulo) {
        clase_justificada.push(classes.justificada_top)
    }
    if (this.props.textoChico) {
      clase_span.push(classes.texto_tipo_normal_small);
    }
    const item = (
      <div className={clases_cuadro_div.join(" ")}>
        <div className={clases_cuadro.join(" ")}>
          <div className={clase_justificada.join(" ")}>
            <div className={clase_subtitulo_div.join(" ")}>
              {subtitulo_opcional}
            </div>
            <div className={clase_imagen.join(" ")}>{img}</div>
            <div className={clase_texto_div.join(" ")}>
              {titulo_opcional}
              <span className={clase_span.join(" ")}>{titulo}</span>
              {btnSeleccion}
            </div>
          </div>
        </div>
        {btn_enlace}
      </div>
    );

    return item;
  }
}

export default Cuadro;

import React, { Component } from "react";
import { connect } from "react-redux";
import Accordion from "../../../components/ui/accordion/Accordion";
import * as actions from "../../../stores/actions/index";
import classes from "./Fondos.css";

import FilterFondos from "../../../components/ui/filterFondos/FilterFondos";
import Pagination from "../../../components/ui/pagination/Pagination";
import Loader from "../../../components/loader/Loader";
/* import { withRouter } from "react-router-dom"; */
import queryString from "query-string";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
const dominio = `${window.location.protocol}//${window.location.host}`;

class Fondos extends Component {
  state = {
    itemsPerPage: 10,
    activePage: 1,
    resourceList: [],
    loader: true,
    tramiteSeleccionado: null,
    razonSocial: null,
    clave: null,
    numeroTramite: null,
    datos: null,
    emisoraSeleccionada: null,
    tipoValorSeleccionado: null,
    claveOperadora: null,
    claveFondo: null,
    clasificacionFondo: null,
    tipoFondo: null,
    fecha_inicio: null,
    fecha_fin: null,
    page: 0,
    detalles: [],
    docs: [],
    urlForRss: `${dominio}/emisoras/tramites/rss`,
    tipoDocumento: null,
    pageDocumento: null,
    showRssModal: false,
    verDocumentos: false,
    wasFiltered: false,
    messages: null,
    lan: getLocal()
  };

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.fondos !== prevProps.fondos) {
      this.setState({
        loader: false
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let fondos = null;
    let detalles = null;
    let docs = null;


    if (nextProps.fondos && nextProps.fondos.content) {
      fondos = nextProps.fondos.content;
    }

    if (nextProps.fondosDetalle !== this.props.fondosDetalle) {
      if (nextProps.fondosDetalle) {
        detalles = nextProps.fondosDetalle
      }
    } else {
      detalles = this.props.fondosDetalle;
    }

    if (nextProps.fondoDocs !== this.props.fondoDocs) {
      if (nextProps.fondoDocs) {
        docs = nextProps.fondoDocs
      }
    } else {
      docs = this.props.fondoDocs;
    }


    this.setState({
      detalles: detalles,
      docs: docs
    });

    let resource = fondos;
    this.onPageChangeHandler(resource, null, 0);
  }

  onPageChangeHandler = (resource = null, pageNumber = null, seleccionado = null) => {
    let data = resource ? resource : this.props.fondos;
    let resourceList = [];
    if (data) {
      resourceList = data;
    }
    if (seleccionado) {
      const urlSeleccionado = resource[0].url;
      this.setState({
        resourceList: resourceList,
        itemSeleccionado: urlSeleccionado
      });
    } else if (pageNumber) {
      this.setState({
        loader: true,
        activePage: pageNumber,
        resourceList: resourceList
      });
    } else {
      this.setState({
        resourceList: resourceList
      });
    }
  };

  handlePageChange(pageNumber) {
    if (this.state.activePage !== pageNumber) {
      this.setState({
        loader: true
      });
      this.changeHistory(
        this.state.claveOperadora,
        this.state.claveFondo,
        this.state.tipoFondo,
        this.state.clasificacionFondo,
        pageNumber);


      this.onPageChangeHandler(null, pageNumber);
    }
  }

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentWillMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let claveOperadora = null;
    let claveFondo = null;
    let tipoFondo = null;
    let clasificacionFondo = null;
    let page = null;
    let search_field_param = paramsString["search_field"];
    if (this.props.location.search !== "" && search_field_param === undefined) {
      claveOperadora = paramsString["claveOperadora"] === "null" ? null : paramsString["claveOperadora"];
      claveFondo = paramsString["claveFondo"] === "null" ? null : paramsString["claveFondo"];
      tipoFondo = paramsString["tipoFondo"] !== undefined && paramsString["tipoFondo"] !== "null" ? paramsString["tipoFondo"] : null;
      clasificacionFondo = paramsString["clasificacionFondo"] !== undefined && paramsString["clasificacionFondo"] !== "null" ? paramsString["clasificacionFondo"] : null;
      page = paramsString["page"] !== undefined && paramsString["page"] !== "null" ? parseInt(paramsString["page"]) : 1;
    }

    if (claveFondo !== null) {
      this.setState({
        tramiteDetalle: claveFondo
      });
      this.props.onInitFondosDetalle(claveFondo);
      this.props.onInitFondosDocs(claveFondo);
      this.updateGrandparent(claveFondo);
    }

    this.props.onInitFondos(
      this.state.itemsPerPage,
      parseInt(page) > 0 ? parseInt(page) - 1 : 0,
      claveOperadora,
      claveFondo,
      tipoFondo,
      clasificacionFondo
    );

    this.setState({
      claveOperadora: claveOperadora,
      claveFondo: claveFondo,
      tipoFondo: tipoFondo,
      clasificacionFondo: clasificacionFondo,
      activePage: parseInt(page) > 0 ? parseInt(page) : 1,
    });
  }

  updateGrandparentListado = (
    empresaId,
    razonSocial,
    clave,
    numeroTramite,
    tramiteId = null,
    tipoDocumento = null,
    numEnvio = null,
    page = null,
    consulta = null
  ) => {
    this.props.onInitFondosDetalle(empresaId);
    let datos = {
      id: empresaId,
      numeroTramite: numeroTramite,
      clave: clave,
      razonSocial: razonSocial
    };

    this.setState({
      tramiteSeleccionado: empresaId,
      razonSocial: razonSocial,
      clave: clave,
      numeroTramite: numeroTramite,
      datos: datos,
      tramiteDetalle: empresaId,
      verDocumentos: true
    });
    if (consulta === null) {
      this.setState({
        loader: true
      })
    }
  };

  updateGrandparent = value => {
    this.props.onInitFondosDetalle(value);
    this.props.onInitFondosDocs(value);
    this.setState({
      tramiteDetalle: value,
      detalles: [],
      docs: []
    });
  };

  onSetPage = page => {
    this.setState({
      activePage: page
    });
  };

  onSetClasificacionFondo = clasificacionFondo => {
    this.setState({
      clasificacionFondo: clasificacionFondo
    });
  };

  onSetTipoFondo = tipoFondo => {
    this.setState({
      tipoFondo: tipoFondo
    });
  };

  onSetClaveFondo = claveFondo => {
    this.setState({
      claveFondo: claveFondo
    });
  };

  onSetClaveOperadora = (claveOperadora, consulta = false) => {
    this.setState({
      claveOperadora: claveOperadora
    });
  };

  setWasFiltered = wasFiltered => {

    this.setState({
      wasFiltered: wasFiltered, loader: true
    })
  }

  onShowRssModalHandler = () => {
    this.setState(prevState => {
      return {
        showRssModal: !prevState.showRssModal
      };
    });
  };

  onSetFilters = ({
    claveOperadora = null,
    claveFondo = null,
    tipoFondo = null,
    clasificacionFondo = null,
    wasFiltered = null,
    page = null,
    reset = false
  }) => {
    this.setState({
      claveOperadora: claveOperadora ? claveOperadora : null,
      claveFondo: claveFondo ? claveFondo : null,
      tipoFondo: tipoFondo ? tipoFondo : null,
      clasificacionFondo: clasificacionFondo ? clasificacionFondo : null,
      wasFiltered: wasFiltered
    });
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(claveOperadora, claveFondo, tipoFondo, clasificacionFondo, page);
    }
  }

  changeHistory(claveOperadora = null, claveFondo = null, tipoFondo = null, clasificacionFondo = null, page = null) {
    this.props.history.push(
      `?claveOperadora=${claveOperadora}&claveFondo=${claveFondo}&tipoFondo=${tipoFondo}&clasificacionFondo=${clasificacionFondo}&page=${page ? page : this.state.activePage}`
    );

    this.props.onInitFondos(
      this.state.itemsPerPage,
      page > 0 ? page - 1 : 0,
      claveOperadora,
      claveFondo,
      tipoFondo,
      clasificacionFondo
    );
  }

  changeHistoryReset() {
    this.props.history.push(
      `?claveOperadora=${null}&claveFondo=${null}&tipoFondo=${null}&clasificacionFondo=${null}&page=1`
    );
    this.props.onInitFondos(
      this.state.itemsPerPage,
      0,
      null,
      null,
      null,
      null
    );
  }


  render() {
    let loader = null;
    if (this.state.loader) {
      loader = <Loader loaderDisable={disable => this.setState({ loader: disable })} />;
    }
    let dataToShow = null;
    let contenidoMostrar = null; 

    const numRows = this.props.fondos ? this.props.fondos.totalElements : 0;
    let fondos_result = this.props.fondos && this.props.fondos.content ? this.props.fondos.content : [];

     if (fondos_result.length > 0) {
      dataToShow=(
        <Accordion
        datos={fondos_result}
        nombre="fondos"
        icon={""}
        tipo={18}
        titulo={getLan() === "es" ? "Características" : "Details"}
        subtipo={1}
        detallesProspectos={this.state.detalles}
        documentos={this.state.docs}
        updateGrandparentListado={this.updateGrandparentListado.bind(this)}
        updateGrandparent={this.updateGrandparent.bind(this)}
        esTramite={true}
        fondo={true}
      />
      );
     
    }else if (fondos_result.length === 0 ) {
      dataToShow = <p className="montse texto_16 regular">{getLan() === "es" ? "No existen resultados para la búsqueda." : "No results found."}</p>;
    }

    contenidoMostrar = (
      <React.Fragment>
        <h1 className={"texto_42 nunito regular"}>
          <FormattedHTMLMessage id="empresas.fondos.titulo1" defaultMessage=" " /></h1>
        <div className="margen_top_30 margin_bottom_30 texto_16 montse regular">
          <FormattedHTMLMessage id="empresas.fondos.introduccion1" defaultMessage=" " />
        </div>
        <br />
        <FilterFondos
          modelo="modelo_1"
          itemsPerPage={this.state.itemsPerPage}
          setClaveOperadora={(claveOperadora, consulta) => this.onSetClaveOperadora(claveOperadora, consulta)}
          setPage={page => this.onSetPage(page)}
          setTipoFondo={tipoFondo => this.onSetTipoFondo(tipoFondo)}
          setClasificacionFondo={clasificacionFondo => this.onSetClasificacionFondo(clasificacionFondo)}
          setClaveFondo={claveFondo => this.onSetClaveFondo(claveFondo)}
          setFechaInicio={fecha => this.onSetFechaInicio(fecha)}
          setFechaFin={fecha => this.onSetFechaFin(fecha)}
          onSetWasFiltered={wasFiltered => this.setWasFiltered(wasFiltered)}
          setFilters={filterParams => this.onSetFilters(filterParams)}
        />
        <br />
        <div className="margen_50">
          {" "}
         
          {dataToShow}
          <Pagination
            claseTipo={2}
            numRows={numRows}
            paginas={5}
            itemsPerPage={this.state.itemsPerPage}
            handlePageChange={this.handlePageChange.bind(this)}
            activePage={this.state.activePage}
          />
        </div>
      </React.Fragment>
    );



    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        <React.Fragment>
          {loader}
          {contenidoMostrar}
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitFondos: (size, page, claveOperadora, claveFondo, tipo, clasificacion) => {
      dispatch(actions.filterFondos(size, page, claveOperadora, claveFondo, tipo, clasificacion));
    },
    onInitFondosDetalle: fondo => {
      dispatch(actions.initFondosDetail(fondo));
    },
    onInitFondosDocs: fondo => {
      dispatch(actions.initFondosDocs(fondo));
    },
    onInitIntl: () => {
      dispatch(actions.initIntl(69));
    }
  };
};

const mapStateToProps = state => {
  return {
    fondos: state.fondos.fondos,
    fondosDetalle: state.fondos.fondoDetail,
    fondoDocs: state.fondos.fondoDocs,
    diccionario: state.intl.diccionario
  };
};

export default (
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Fondos)
);

import React, { Component } from "react";
import classes from "./BtnArriba.css";
import $ from "jquery";

function irArriba(duration) {
  $("body, html")
    .stop()
    .animate(
      {
        scrollTop: "0px"
      },
      duration
    );
}

class BtnArriba extends Component {
  componentDidMount() {
    var toggleSticky = document.getElementById("toggleSticky");
    var contenedorHeader = document.getElementById("contenedorHeader");

    function lugarTop() {
      if (window.pageYOffset > 200) {
        toggleSticky.classList.add("fixed");
        toggleSticky.classList.remove("oculto");
      } else {
        toggleSticky.classList.remove("fixed");
        toggleSticky.classList.add("oculto");
      }

      if (window.pageYOffset < 250) {
        contenedorHeader.classList.remove("containerFixed");
      }
    }

    window.onscroll = function(event) {
      event.stopPropagation();
      lugarTop();
    };

    toggleSticky = document.getElementById("toggleSticky");
  }

  render() {
    return (
      <div className={[classes.btn, "oculto"].join(" ")} id="toggleSticky" onClick={() => irArriba(300)}>
        <i className={["fas fa-chevron-circle-up icon2", classes.icono].join(" ")} />
      </div>
    );
  }
}

export default BtnArriba;

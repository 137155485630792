import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  embajadorBiva: null,
  comoInvertir:null,
  negociacionBiva:null,
  instrumentosCapital:null,
  instrumentosDeuda:null,
  conectividadBivaTotal:null,
  conectividadBivaBasico:null,
  conectividadBivaUltimoHecho:null,
  conectividadBivaNoticias:null,
  toggleBoxPorQueListarse:null,
  toggleBoxBeneficiosListarse:null,
  toggleBoxInstrumentosListadoDeuda:null,
  toggleBoxInstrumentosListadoCapitales:null,
  
  toggleBoxListadoSab:null,
  toggleBoxListadoSapib:null,
  toggleBoxListadoDeuda:null,
  toggleBoxListadoFibra:null,
  toggleBoxFasesIpo:null,
  toggleBoxProcesoCambioListado:null,
  conectividadProveedores:null,
  toggleBoxListadoInstrumentos:null,
  toggleBoxInformacionEmisora:null,
};

const setToggle = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOGGLEBOX_SUCCESS:
      return setToggle(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
// import { connect } from "react-redux";
import classes from "./ProcesoListado.css";

import Box from "../../../../components/ui/box/Box";
import ToggleBox from "../../../../components/ui/toggleBox/ToggleBox";

import imagen from "../../../../assets/images/procesolistado.png";
import { connect } from "react-redux";
import * as action from "../../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal, getLan, getUrlDocument } from "../../../../stores/utils/utilities";
class ProcesoListado extends Component {
  state = {
    messages: null,
    lan: getLocal(),

    circular: {
      links: [
        {
          leftIcon: "far fa-file-pdf",
          title: "Circular única de emisoras",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url:
            "https://www.cnbv.gob.mx/Normatividad/Disposiciones%20de%20car%C3%A1cter%20general%20aplicables%20a%20las%20emisoras%20de%20valores.pdf"
        }
      ]
    },
    reglamento: {
      links: [
        {
          leftIcon: "far fa-file-pdf",
          title: "Reglamento interior de BIVA",
          subtitle: "Formato PDF",
          rightIcon: "fas fa-arrow-down",
          url:
            "https://biva.mx/documents/30877/1070814/Reglamento+BIVA+%281a.+Reforma+23+Agosto+2019%29.pdf/febc30c8-113a-8b0a-f3a4-7527c3e750cc"
        }
      ]
    },
    deuda: [
      {
        imagen: "certificado_bursatiles.svg",
        icono: "",
        titulo_encabezado: "Certificados bursátiles",
        texto_html:
          "Instrumentos ágiles y flexibles que se adaptan a las necesidades de financiamiento de corto y largo plazo de las empresas."
      },
      {
        imagen: "medalla_bursatil.svg",
        icono: "",
        titulo_encabezado: "Certificados bursátiles fiduciarios",
        texto_html:
          "Permiten a las empresas el financiamiento a largo plazo mediante la bursatilización de activos como: cartera hipotecaria, cuentas por cobrar, proyectos inmobiliarios y de infraestructura"
      },
      {
        imagen: "bonos.svg",
        icono: "",
        titulo_encabezado: "Bonos estructurados",
        texto_html: "Son títulos emitidos por los bancos cuyo rendimiento está en función de un activo subyacente."
      },
      {
        imagen: "sic.svg",
        icono: "",
        titulo_encabezado: "SIC deuda",
        texto_html: "Son títulos de crédito emitidos en mercados internacionales que pueden ser operados en México."
      }
    ],
    capitales: [
      {
        imagen: "acciones.svg",
        icono: "",
        titulo_encabezado: "Acciones",
        texto_html:
          "Capitalizan a la empresa obteniendo recursos sin apalancamiento, comúnmente utilizados para el desarrollo de proyectos, pagos de deuda y adquisición de negocios estratégicos."
      },
      {
        imagen: "certificados_capital.svg",
        icono: "",
        titulo_encabezado: "Certificados de capital",
        texto_html:
          "Financian proyectos de inversión en distintos sectores: infraestructura, inmobiliarios, capital privado, energía y activos financieros. Entre ellos destacan los CCD’s y los CERPI’s."
      },
      {
        imagen: "fibras.svg",
        icono: "",
        titulo_encabezado: "FIBRAs inmobiliarias",
        texto_html:
          "Fideicomisos cuyos activos están compuestos por: centros comerciales, hoteleros, parques industriales y usos mixtos. Se caracterizan porque al menos el 70% del patrimonio del fideicomiso se encuentra invertido en bienes inmuebles."
      },
      {
        imagen: "fibrasE.svg",
        icono: "",
        titulo_encabezado: "FIBRAs E",
        texto_html:
          "Fideicomisos cuyos activos están compuestos por proyectos enfocados en sectores de energía e infraestructura."
      },
      {
        imagen: "titulos.svg",
        icono: "",
        titulo_encabezado: "Títulos opcionales",
        texto_html:
          "Otorgan a los tenedores el derecho de comprar o vender un activo subyacente, a un precio de ejercicio en un período determinado, a cambio del pago de una prima + de emisión."
      },
      {
        imagen: "tracs.svg",
        icono: "",
        titulo_encabezado: "TRACs",
        texto_html: "Replican el comportamiento de un índice de deuda o de capital nacional o extranjero."
      },
      {
        imagen: "sic_capitales.svg",
        icono: "",
        titulo_encabezado: "SIC capitales",
        texto_html: "Son acciones o ETFs listados en mercados internacionales que pueden ser operados en México."
      }
    ]
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  render() {
    let box = null;
    if (this.props.enlacesGuiaProcesoListado) {
      box = this.props.enlacesGuiaProcesoListado.map((enlace, i) => {
        return <Box key={"enlace" + i} modelo="modelo_1" data={enlace} />;
      });
    }
    let box2 = null;
    if (this.props.enlacesArchivosProcesoListado) {
      box2 = this.props.enlacesArchivosProcesoListado.map((enlace, i) => {
        return <Box key={"enlaceArchivos" + i} modelo="modelo_1" data={enlace} />;
      });
    }
    let imagenes = null;
    if (this.props.procesoListado) {

      imagenes = this.props.procesoListado.map((img, i) => {
        let ruta = getUrlDocument(img.url);
        return (<img key={i} src={ruta} alt={img.title} className={classes.img} width="100%" />);
      });
    }
    let toggleDeuda = null;
    if (this.props.toggleBoxInstrumentosListadoDeuda) {
      toggleDeuda = <ToggleBox terminos={this.props.toggleBoxInstrumentosListadoDeuda} />;
    }
    let toggleCapitales = null;
    if (this.props.toggleBoxInstrumentosListadoCapitales) {
      toggleCapitales = <ToggleBox terminos={this.props.toggleBoxInstrumentosListadoCapitales} />;
    }
    let documentoEmisionesSimplificadas = null;
    if (this.props.enlacesDocumentosEmisionesSimplificadas) {
      documentoEmisionesSimplificadas = this.props.enlacesDocumentosEmisionesSimplificadas.map((enlace, i) => {
        return <Box key={"enlaceArchivos" + i} modelo="modelo_1" data={enlace} />;
      });
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
        {this.state.messages ?
        <React.Fragment>
          <div className="row ">
            <div className="col-12 sin_padding_L">
              <div className={[""].join(" ")}>
                <h2 className="nunito texto_42 regular">
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.procesodelistado.titulo1"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
          </div>

          <div className="row margen_top_30">
            {imagenes}
          </div>
          <div className="row margen_top_50">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.procesodelistado.subtitulo1"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>

            {box}

            <div className="margen_top_30">
              <p>
                <span className="montse texto_16">
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.procesodelistado.introduccion1"
                    defaultMessage=" "
                  />
                </span>
              </p>
            </div>
          </div>

          <div className="row margen_top_30 margin_bottom_30">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.procesodelistado.subtitulo2"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>

            <p>
              <span className="montse texto_16 regular">
                <FormattedHTMLMessage
                  id="empresas.listadodevalores.comolistarse.procesodelistado.introduccion2"
                  defaultMessage=" "
                />
              </span>
            </p>
          </div>

          <div className={["row", classes.fondo_toggle, classes.margen_toggle].join(" ")}>
            <div className="margen_top_30 row margin_bottom_20">
              <span className="texto_blanco texto_15 montse regular">
                <FormattedHTMLMessage id="empresas.listadodevalores.comolistarse.procesodelistado.Deuda" defaultMessage=" " />
              </span>
            </div>
            {toggleDeuda}
            {/* <ToggleBox terminos={this.props.getToggleBoxInstrumentosListadoDeuda ? this.props.getToggleBoxInstrumentosListadoDeuda : []} moveToLeftClass /> */}
            <FormattedHTMLMessage id="empresas.listadodevalores.comolistarse.procesodelistado.Deuda.texto" defaultMessage=" " />
          </div>

          <div className={["row", classes.margen_toggle].join(" ")}>
            <FormattedHTMLMessage id="empresas.listadodevalores.comolistarse.procesodelistado.Capitales" defaultMessage=" " />
            {/* <ToggleBox terminos={this.state.capitales} moveToLeftClass /> */}
            {toggleCapitales}
            <FormattedHTMLMessage
              id="empresas.listadodevalores.comolistarse.procesodelistado.Capitales.texto"
              defaultMessage=" "
            />
          </div>

          <div className="row margen_top_30">
            <FormattedHTMLMessage
              id="empresas.listadodevalores.comolistarse.procesodelistado.introduccion3"
              defaultMessage=" "
            />
          </div>
          <div className="row margen_top_50">
            {box2}
          </div>

          <div className="row margen_top_30 ">
            <div className="col-12 sin_padding_L">
              <div className={["main_subtitle"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2>
                  <FormattedHTMLMessage
                    id="empresas.listadodevalores.comolistarse.procesodelistado.emisionessimplificadas"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            <div className="row margen_top_30">
              {documentoEmisionesSimplificadas}
            </div>
          </div>
        </React.Fragment>
        : <div></div>}
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    diccionario: state.intl.diccionario,
    enlacesGuiaProcesoListado: state.documentos.enlacesGuiaProcesoListado,
    enlacesArchivosProcesoListado: state.documentos.enlacesArchivosProcesoListado,
    enlacesDocumentosEmisionesSimplificadas: state.documentos.enlacesDocumentosEmisionesSimplificadas,
    procesoListado: state.imagenes.procesoListado,
    toggleBoxInstrumentosListadoDeuda: state.toggleBox.toggleBoxInstrumentosListadoDeuda,
    toggleBoxInstrumentosListadoCapitales: state.toggleBox.toggleBoxInstrumentosListadoCapitales
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(59));
      dispatch(action.getEnlacesGuiaProcesoListado());
      dispatch(action.getEnlacesArchivosProcesoListado());
      dispatch(action.getEnlacesDocumentosEmisionesSimplificadas());
      dispatch(action.getImagenesProcesoListado());
      dispatch(action.getToggleBoxInstrumentosListadoDeuda());
      dispatch(action.getToggleBoxInstrumentosListadoCapitales());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcesoListado);

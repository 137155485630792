import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;

const setActivas = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_ACTIVAS_SUCCESS,
      activas: null
    };
  }
  return {
    type: actionTypes.FETCH_ACTIVAS_SUCCESS,
    activas:data
  };
};

export const initActivas = keyWord => {
  return dispatch => {
    axios
      .get(dominio+"/quotes/"+keyWord)
      .then(response => {
        dispatch(setActivas(response.data));
      })
      .catch(err => dispatch(setActivas(err.data)));
  };
};



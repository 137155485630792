import React, { Component } from "react";
import classes from "./FilterFiduciarios.css";
import { Form, Field } from "react-final-form";
import queryString from "query-string";

import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

import * as actions from "../../../stores/actions/index";

import Input from "../input/Input";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLan } from "../../../stores/utils/utilities";

const renderInput = ({
  input,
  id = null,
  classes = null,
  elementType = "input",
  ref,
  onInputChange,
  label,
  placeholder = null,
  disabled = false,
  isMultiple = null,
  options,
  defaultValue = null,
  checked = null,
  selectLabel
}) => {
  const params = {
    input: {
      ...input,
      id: id ? id : input.name,
      placeholder: placeholder,
      className: classes ? [classes, "form-control"].join(" ") : "form-control",
      ref: ref,
      disabled: disabled,
      checked: checked,
      onChange: e => {
        input.onChange(e);
        onInputChange && onInputChange(e);
      }
    },
    defaultValue: defaultValue ? defaultValue : "",
    label: label,
    isMultiple: isMultiple,
    elementType: elementType,
    options: options,
    selectLabel: selectLabel ? selectLabel : "Seleccione una opción"
  };
  return (
    <div className="form-group">
      {label && input.type !== "radio" && input.type !== "checkbox" ? (
        <label
          className={["montse", "texto_14", "regular"].join(" ")}
          for={input.name}
        >
          {label}
        </label>
      ) : null}

      {/* {label ? <label for={input.name}>{label}</label> : null} */}
      <Input config={params} />
    </div>
  );
};

class FilterRepresentantes extends Component {
  constructor(props) {
    super(props);
    this.formRef1 = React.createRef();
    this.escFunction = this.escFunction.bind(this);
  }

  state = {
    esconderAutoComplete: false,
    cotizaciones: null,
    tab: null,
    periodoSeleccionado: null,
    toggleFilters: false,
    disableEnableButtons: true,
    allSelelectFilters: [],
    selectedFiltersGroup1: [],
    selectedFiltersGroup2: [],
    selectPivot: false,
    form_filters: {
      instrumentos: {
        value: "",
        disabled: false,
        show: false
      },
      mercados: {
        value: "",
        disabled: false,
        show: true
      },
      tipo: null
    },
    form_clave: {
      clave: {
        value: ""
      }
    },
    toggleInputs: false,
    valueClave: null,
    typeClaveSelected: null,
    emisoraSeleccionada: null,
    razonSocial: null,
    clave: null,
    tipoValor: [],
    biva: true,
    canceladas: false
  };

  componentDidMount() {
    let paramsString = queryString.parse(this.props.location.search);
    let razonSocialNombre =
      paramsString["razonSocialNombre"] !== undefined &&
      paramsString["razonSocialNombre"] !== "null"
        ? paramsString["razonSocialNombre"]
        : null;

    if (razonSocialNombre && razonSocialNombre !== "") {
      this.setState({
        toggleInputs: true,
        disableEnableButtons: false,
        typeClaveSelected: 1,
        valueClave: razonSocialNombre
      });
    }
  }
  componentWillMount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    window.addEventListener("click", e => this.collapseSearch(e));
    window.addEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    let visible = false;

    if (event.keyCode === 27) {
      visible = true;
    }
    this.setState({
      esconderAutoComplete: visible
    });
  }

  collapseSearch(event) {
    let visible = false;
    if (event.target.name !== "search_field") {
      visible = true;
    }

    this.setState({
      esconderAutoComplete: visible
    });
  }

  componentWillMount() {
    this.props.onFilterMercadosProspectos();
    this.callReduxAction = debounce(500, this.callReduxAction);
  }

  componentWillReceiveProps(nextProps) {}

  formModelHandler = modelo => {
    switch (modelo) {
      case "modelo_1":
        return this.printFrameModelOneHandler();
      default:
        return null;
    }
  };

  printFrameModelOneHandler = () => {
    const input = this.showInputHandler();
    return (
      <div className="row">
        <div className="col sin_padding">
          <div className="row">
            <div
              className={[
                "col",
                /*  this.state.toggleFilters ? "col-lg-9" : "col-lg-8", */
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div className={classes.filters_selected_container_model_one}>
                {input}
              </div>
            </div>
            <div
              className={[
                this.state.toggleFilters ? classes.hidden : null,
                "col-1",
                "col-lg-1",
                "text-center",
                classes.no_col_padding_model_one
              ].join(" ")}
            >
              <div
                className={[
                  classes.search_button_container_model_one,
                  this.state.toggleFilters
                    ? classes.no_border_search_button_model_one
                    : null
                ].join(" ")}
              >
                <button
                  type="buttom"
                  className={["btn"].join(" ")}
                  /* disabled={this.state.disableEnableButtons} */
                  disabled
                >
                  <i className="fas fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={[
              "row",
              classes.main_filter_container_model_one,
              !this.state.toggleFilters ? classes.hidden : null
            ].join(" ")}
          >
            <div
              className={["col", classes.no_col_padding_model_one].join(" ")}
            >
              {this.modelOne()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  onPrintValoresHandler = tipo => {
    return (
      <Field
        name="mercados"
        id={tipo.nombre}
        type="radio"
        elementType="radio2"
        value={tipo.id}
        classes="form-check-input"
        label={tipo.nombre}
        checked={
          this.state.form_filters.mercados.value === tipo.id ? true : false
        }
        onInputChange={event => this.onInputSelectedModelOne(event, "mercados")}
        component={renderInput}
      />
    );
  };

  modelOne = () => {
    return (
      <div className={classes.filters_container_model_one}>
        <div className={classes.filter_container_model_one}>
          <Form
            onSubmit={this.onSubmitFilterModelOneHandler}
            initialValues={{
              mercados: this.state.form_filters.mercados.value,
              instrumentos: this.state.form_filters.instrumentos.value
            }}
            render={({
              optionsInstrumentos = this.props.instrumentos
                ? this.props.instrumentos
                : [],
              optionsMercados = this.props.mercados ? this.props.mercados : [],
              // mercados = mercados ? mercados : [],
              form
            }) => (
              <form
                ref={this.formRef1}
                onSubmit={this.onSubmitFilterModelOneHandler}
              >
                <div className="row">
                  <div className="col col-lg-5 offset-lg-1 margen_derecha_15">
                    <div className="row">
                      <div className="col-12 sin_padding_L sin_padding_R">
                        <span className="montse texto_14 regular color_negro">
                          Mercado
                        </span>
                      </div>
                      <div
                        className={[
                          "col-12 sin_padding_L sin_padding_R",
                          classes.padding_izquierdo
                        ].join(" ")}
                      >
                        {optionsMercados.map(tipo => {
                          return (
                            <div className={classes.radioB}>
                              {this.onPrintValoresHandler(tipo)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className={[
                      "col",
                      "col-lg-5",
                      !this.state.form_filters.instrumentos.show
                        ? classes.hidden
                        : null
                    ].join(" ")}
                  >
                    <Field
                      name="instrumentos"
                      id="instrumentos"
                      label="Instrumento"
                      type="select"
                      elementType="select"
                      classes="custom-select montse texto_14 regular color_gris"
                      options={optionsInstrumentos}
                      onInputChange={event =>
                        this.onInputSelectedModelOne(event, "instrumentos")
                      }
                      component={renderInput}
                    />
                  </div>
                </div>

                {/* ======================= */}

                <hr />
                {this.sectionButtonsModelOne(form)}
              </form>
            )}
          />
        </div>
      </div>
    );
  };

  sectionButtonsModelOne = form => {
    const disabledButton = !this.state.form_filters.errorDate ? false : true;
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12  col-lg-4 offset-lg-4 text-center">
          <button
            type="submit"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14"
            ].join(" ")}
            disabled={disabledButton}
          >
            <i className="fas fa-search" />
            Buscar
          </button>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center">
          <button
            type="button"
            className={[
              "btn",
              classes.button_model_one,
              "montse",
              "texto_14"
            ].join(" ")}
            onClick={() => this.onResetFilterModelOneHandler(form)}
          >
            <i className="fas fa-redo-alt" />
            Limpiar Filtros
          </button>
        </div>
      </div>
    );
  };

  onSubmitFilterModelOneHandler = event => {
    event.preventDefault();
    const mercado =
      this.state.form_filters.mercados.value !== null
        ? this.state.form_filters.mercados.value === ""
          ? null
          : this.state.form_filters.mercados.value
        : null;
    const instrumento = this.state.form_filters.instrumentos.value
      ? this.state.form_filters.instrumentos.value
      : null;

    this.props.onInitProspectosColocacion(
      this.props.itemsPerPage,
      0,
      mercado,
      instrumento,
      this.state.emisoraSeleccionada,
      this.state.clave
    );
    this.props.setPage(1);
  };

  onSubmitSearchModelOneHandler = event => {
    event.preventDefault();
  };

  printChange = e => {
    this.callReduxAction(e.target.value);
  };

  callReduxAction = value => {
    this.onInputChangeModelOne(value);
  };

  onInputChangeModelOne = (event = null, eraser = null, tipo = null) => {
    const value = event;
    const form_clave = this.state.form_clave;
    form_clave.clave.value = value;
    let disabled = false;

    if (value !== "") {
      /* this.props.onFilterClaves(value); */
      this.props.onFilterRazonSocial(value);
    } else {
      disabled = true;
    }

    if (tipo) {
      this.props.setEmisora(null, 3);
    }

    if (eraser) {
      this.setState(prevState => {
        return {
          toggleInputs: !prevState.toggleInputs,
          emisoraSeleccionada: null,
          claveSeleccionada: null,
          form_clave: form_clave,
          disableEnableButtons: disabled
        };
      });
    } else {
      this.setState({ form_clave: form_clave, disableEnableButtons: disabled });
    }
  };

  limpiaForms() {
    let selectPivot = null;
    const form1 = { ...this.state.form_filters };
    const form_clave = this.state.form_clave;
    form_clave.clave.value = "";

    form1.instrumentos.show = false;
    form1.instrumentos.value = "";
    form1.mercados.value = "";

    this.props.setEmisora(null);
    this.props.setInstrumento(null);
    this.props.setMercado(null);
    this.props.setPage(1);
    this.onFilterInstrumentoHandler();

    this.props.onInitProspectosColocacion(
      this.props.itemsPerPage,
      0,
      null,
      null,
      null,
      null
    );

    this.setState({
      allSelelectFilters: [],
      selectedFiltersGroup1: [],
      selectedFiltersGroup2: [],
      selectPivot: selectPivot,
      form_filters: form1,
      periodoSeleccionado: null,
      valueClave: null,
      toggleInputs: false
    });
  }

  onResetFilterModelOneHandler = form => {
    form.reset();
    this.limpiaForms(this.state.biva, this.state.canceladas);
  };

  onToggleFilters = () => {
    this.setState(prevState => {
      return {
        toggleFilters: !prevState.toggleFilters
      };
    });
  };

  onInputModelOneChange = event => {
    const value = event.target.value;
    const disabled = value ? false : true;
    this.setState({
      disableEnableButtons: disabled
    });
  };

  onFilterInstrumentoHandler = () => {
    this.props.onFilterInstrumentos(null);
  };

  onFilterTipoValorHandler = (instrumento = null, tipoValor) => {
    const params = {
      instrumento: instrumento,
      tipoValor: tipoValor
    };
    this.props.onFilterTipoValor(params);
  };

  onFilterDocumentos = (mantenimientoId = null, listadoId = null) => {
    const params = {
      tipoInformacion: mantenimientoId,
      tipoInformacionListado: listadoId
    };
    this.props.onFilterTipoDocumentos(params);
  };

  onCheckIfDatesAreValid = (inicio, fin) => {
    return inicio > fin;
  };

  onInputSelectedModelOne = (event, type = null) => {
    let selected = [];
    let selectPivot = this.state.selectPivot;
    let group1 = [...this.state.selectedFiltersGroup1];
    let group2 = [...this.state.selectedFiltersGroup2];

    let value = event.target.value;
    const form = { ...this.state.form_filters };
    let periodo_lista = this.state.periodo;

    if (type === "mercados") {
      if (form.mercados.value === value) {
        value = null;
      }
      const tipoValor_ = [...this.props.mercados];
      const item = tipoValor_.find(tipv => tipv.id === value);

      group1 = group1.filter(item => item.type !== "tipMercado");
      form.instrumentos.value = "";
      group1 = group1.filter(item => item.type !== "tipInst");
      this.props.setInstrumento(null);
      if (item) {
        item.type = "tipMercado";
        group1.push(item);
        form.instrumentos.show = true;
      } else {
        form.instrumentos.show = false;
      }
      form.mercados.value = value;
      this.props.setMercado(value);
      this.props.onFilterInstrumentos(
        value,
        this.state.emisoraSeleccionada,
        this.state.clave
      );
    }

    if (type === "instrumentos") {
      form.instrumentos.value = value;

      const tipoValor = [...this.props.instrumentos];
      const item = tipoValor.find(tipv => tipv.id === +value);
      group1 = group1.filter(item => item.type !== "tipInst");

      if (item) {
        item.type = "tipInst";
        group1.push(item);
      }
      this.props.setInstrumento(value);
    }

    selected = [...group1, ...group2];
    this.setState({
      selectPivot: selectPivot,
      allSelelectFilters: selected,
      selectedFiltersGroup1: group1,
      selectedFiltersGroup2: group2,
      form_filters: form,
      periodo: periodo_lista
    });
  };

  onDeleteFilterModelOneHandler = item => {
    let selected = [];
    let group1 = [...this.state.selectedFiltersGroup1];
    let group2 = [...this.state.selectedFiltersGroup2];
    const form = { ...this.state.form_filters };

    if (item.type === "tipMercado") {
      group1 = group1.filter(itemd => itemd.type !== "tipMercado");

      form.mercados.value = "";
      this.props.setInstrumento(null);
      group1 = group1.filter(itemd => itemd.type !== "tipInst");

      form.instrumentos.value = "";
      this.props.setInstrumento(null);
      form.instrumentos.show = false;
    }

    if (item.type === "tipInst") {
      group1 = group1.filter(itemd => itemd.type !== "tipInst");

      form.instrumentos.value = "";
      this.props.setInstrumento(null);
    }

    selected = [...group1, ...group2];
    this.setState({
      allSelelectFilters: selected,
      selectedFiltersGroup1: group1,
      selectedFiltersGroup2: group2,
      form_filters: form
    });
  };

  showFiltersSelectedHandler = () => {
    let filters = null;
    const filterList = [...this.state.allSelelectFilters];

    filters = (
      <div
        className={classes.filter_container_outside_model_one}
        id="allFitersContainers"
      >
        {filterList.map(item => (
          <div
            className={classes.filter_container_model_one}
            key={`${item.nombre}-${item.id}`}
          >
            <span
              className={["montse", classes.filter_text_model_one].join(" ")}
            >
              {item.nombre}
            </span>
            <span className={classes.filter_icon_button_model_one}>
              <i
                className="far fa-times-circle"
                onClick={() => this.onDeleteFilterModelOneHandler(item)}
              />
            </span>
          </div>
        ))}
      </div>
    );
    return filters;
  };

  onToggleFilterInputOptionSelected = (tipo = null) => {
    if (tipo) {
      this.props.setEmisora(null, 3);

      this.setState({
        emisoraSeleccionada: null,
        claveSeleccionada: null
      });
    }
    this.setState(prevState => {
      return {
        toggleInputs: !prevState.toggleInputs
      };
    });
  };

  onPrintAutoCompleteHandler = () => {
    const listado = this.props.listadoClaves;
    return listado.map(item => (
      <li
        className="list-group-item"
        onClick={() => this.onClaveSelectedHandler(item.clave, item.id, 1)}
      >
        {item.clave}
      </li>
    ));
  };

  onClaveSelectedHandler = (clave, id = null, option = 0) => {
    this.onToggleFilterInputOptionSelected();
    let emisoraSeleccionada = null;
    let claveSeleccionada = null;
    let razonSocialSeleccionada = null;
    if (option === 3) {
      this.props.setRazonSocial(clave, 0);
      razonSocialSeleccionada = clave;
    } else if (option === 0) {
      this.props.setEmisora(clave, 2);
      claveSeleccionada = clave;
    } else {
      emisoraSeleccionada = id;
      this.props.setEmisora(emisoraSeleccionada, 1, clave);
    }
    this.props.onFilterInstrumentos(
      this.state.form_filters.mercados.value,
      emisoraSeleccionada,
      claveSeleccionada
    );

    this.setState({
      typeClaveSelected: option,
      valueClave: clave,
      emisoraSeleccionada: emisoraSeleccionada,
      razonSocial: razonSocialSeleccionada,
      clave: claveSeleccionada
    });
  };

  onPrintOptionSelectedHandler = value => {
    if (!this.state.toggleInputs) {
    }
    return (
      <div
        className={[
          classes.selectedOptionContainer,
          !this.state.toggleInputs ? classes.hidden : null
        ].join(" ")}
      >
        <span className={classes.filterContainer}>
          <span
            className={[classes.filterText, "montse texto_14 regular"].join(
              " "
            )}
          >
            {this.state.typeClaveSelected === 0
              ? getLan() === "es"
                ? `Ver todos los resultados para: ${this.state.valueClave}`
                : `See all results for: ${this.state.valueClave}`
              : `${this.state.valueClave}`}
          </span>
          <span
            className={classes.filterIconButton}
            onClick={() => this.onInputChangeModelOne(null, true, true)}
          >
            <i className="far fa-times-circle" />
          </span>
        </span>
      </div>
    );
  };

  showInputHandler = () => {
    const value = this.state.form_clave.clave.value;

    const listado = this.props.listadoClaves
      ? this.onPrintAutoCompleteHandler()
      : null;

    const firstlistItem =
      listado && listado.length > 0 ? (
        <li
          className="list-group-item"
          onClick={() => {
            this.onClaveSelectedHandler(value, null, 3);
          }}
        >
          {getLan() === "es"
            ? `Ver todos los resultados para: ${value}`
            : `See all results for: ${value}`}
        </li>
      ) : (
        <li className="list-group-item">
          {getLan() === "es"
            ? "No existen resultados para la búsqueda:"
            : "There are no results for the search:"}{" "}
          {value}
        </li>
      );

    return (
      <Auxs>
        {this.onPrintOptionSelectedHandler()}
        <div
          className={[
            classes.search_input_container_model_one,
            this.state.toggleInputs ? classes.hidden : null
          ].join(" ")}
        >
          <Form
            onSubmit={this.onSubmitSearchModelOneHandler}
            initialValues={{
              search_field: this.state.form_clave.clave.value
            }}
            render={() => (
              <form
                ref={this.formSearch}
                onSubmit={this.onSubmitSearchModelOneHandler}
              >
                <Field
                  name="search_field"
                  id="search_field"
                  type="text"
                  elementType="input"
                  placeholder={
                    getLan() === "es"
                      ? "Buscar por razón social"
                      : "Search by corporate name"
                  }
                  component={renderInput}
                  onInputChange={event => this.printChange(event)}
                />
              </form>
            )}
          />
          <div
            className={[
              classes.autoCompleteContainer,
              "list-group",
              !this.state.form_clave.clave.value ||
              this.state.form_clave.clave.value === ""
                ? classes.hidden
                : null,
              this.state.esconderAutoComplete ? classes.hidden : null
            ].join(" ")}
          >
            {firstlistItem}
            {listado}
          </div>
        </div>
      </Auxs>
    );
  };

  render() {
    const filter = this.props.modelo
      ? this.formModelHandler(this.props.modelo)
      : null;

    return filter;
  }
}

const mapStateToProps = state => {
  return {
    instrumentos: state.prospectos.instrumentos,
    listadoClaves: state.emisoras.razonesRepresentantes,
    mercados: state.prospectos.mercados
    // cotizaciones: state.emisoras.cotizaciones
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitProspectosColocacion: (
      size,
      page,
      mercado,
      instrumento,
      emisora,
      clave
    ) => {
      dispatch(
        actions.initProspectosColocacion(
          size,
          page,
          mercado,
          instrumento,
          emisora,
          clave
        )
      );
    },
    onFilterInstrumentos: (mercado, emisora, clave) => {
      dispatch(actions.initValoresProspectos(mercado, emisora, clave));
    },
    onFilterClaves: clave => {
      dispatch(actions.initClavesProspectos(clave));
    },

    onFilterMercadosProspectos: emisora => {
      dispatch(actions.initMercadoProspectos(emisora));
    },

    onFilterRazonSocial: razonSocial => {
      dispatch(actions.filterEmisorasByRazonSocialRepresentantes(razonSocial));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterRepresentantes);

import axios from "axios";
import * as actionTypes from "./actionTypes";

const dominio = process.env.REACT_APP_DOMINIO;
const base_url = `${dominio}/cms/o/listado/mantenimiento/requisitos`;

const setRequisitos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_REQUISITOS_SUCCESS,
      requisitos: []
    };
  }
  return {
    type: actionTypes.FETCH_REQUISITOS_SUCCESS,
    requisitos: data
  };
};

export const filterRequisitos = ({ size = 5, page = 0, periodo = null, capitalSocial=null }) => {
  let url = `${base_url}?size=${size}&page=${page}`;
  url = periodo && periodo !=="" ? `${url}&periodo=${periodo}` : url;
  url =
    capitalSocial && capitalSocial.length > 0
      ? `${url}&capitalSocial=${[...capitalSocial]}`
      : url;
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setRequisitos(response.data));
      })
      .catch((err) => dispatch(setRequisitos(err.data)));
  };
};

const setEmisorasRequisitos = (data = null) => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_EMISORASREQUISITOS_SUCCESS,
      emisoras_requisitos: []
    };
  }
  return {
    type: actionTypes.FETCH_EMISORASREQUISITOS_SUCCESS,
    emisoras_requisitos: data
  };
};

export const filterEmisorasRequisitos = clave => {
  let url = clave ? `${base_url}/emisoras/buscar-por-clave/${clave}` : base_url;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setEmisorasRequisitos(response.data));
      })
      .catch(err => dispatch(setEmisorasRequisitos(err.data)));
  };
};

const setPeriodosRequisitos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_PERIODOSREQUISITOS_SUCCESS,
      periodos_requisitos: null
    };
  }
  return {
    type: actionTypes.FETCH_PERIODOSREQUISITOS_SUCCESS,
    periodos_requisitos: data
  };
};

export const filterPeriodosRequisitos = () => {
  let url = `${base_url}/periodos`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setPeriodosRequisitos(response.data));
      })
      .catch(err => dispatch(setPeriodosRequisitos(err.data)));
  };
};

const setCapitalSocial = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_CAPITALSOCIAL_SUCCESS,
      capital_social: null
    };
  }
  return {
    type: actionTypes.FETCH_CAPITALSOCIAL_SUCCESS,
    capital_social: data
  };
};

export const filterCapitalSocial = () => {
  let url = `${base_url}/capital-social`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setCapitalSocial(response.data));
      })
      .catch(err => dispatch(setCapitalSocial(err.data)));
  };
};

export const filterRequisitosByEmisora = ({ size, page, clave }) => {
  let url = `${base_url}/emisoras/id/${[...clave]}?size=${size}&page=${page}`;

  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setRequisitos(response.data));
      })
      .catch(err => dispatch(setRequisitos(err.data)));
  };
};

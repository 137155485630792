import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utils/utilities";

const initialState = {
  autorregulacion: null,
  leyes: null,
  disposiciones: null,
  about_us: null,
  prensa: null,
  auditorio_imagenes: null,
  auditorio_videos: null,
  alianzas: null,
  metodologias: null,
  ultimoDia: null,
  ultimoMes: null,
  operacionDiaria: null,
  cuotas: null,
  fichaTecnica: null,
  instituto: null,
  embajadores: null,
  cursosPresenciales: null,
  cursosRemotos: null,
  articulosAcademicos: null,
  revistas: null,
  escritos: null,
  blog: null,
  libros: null,
  diccionarios: null,
  preguntasFrecuentes: null,
  equipo: null,
  solicitudFormadorMercado: null,
  solicitudMiembroBiva: null,
  manualReglamento: null,
  lineamientos: null,
  enlacesConectividad: null,
  enlacesContratarItch: null,
  enlacesContratarBim: null,
  enlacesReporteEspecializado: null,
  enlacesEspecificacionesItch: null,
  enlacesGuiaProcesoListado: null,
  enlacesArchivosProcesoListado: null,
  enlacesArchivosCambioListado: null,
  enlacesCuotaListado: null,
  enlacesListadoFsCap: null,
  enlacesListadoFsDeuda: null,
  enlacesListadoFsSic: null,
  dataSheet: null,
  infoGeneral: null,
  queEstaPasando: null
};

const setDocumentos = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_AUTORREGULACIONDOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_NORMATIVIDADLEYESDOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_NORMATIVIDADDISPOSICIONESDOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_ABOUTDOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_AUDITORIODOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_AUDITORIOVIDEOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_PRENSADOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_ALIANZASDOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_METODOLOGIASDOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_OPERACIONDIARIADOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_ULTIMODIADOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_ULTIMOMESDOCUMENTOS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_CUOTAS_SUCCESS:
      return setDocumentos(state, action);
    case actionTypes.FETCH_DOCUMENTOS_ENLACES:
      return setDocumentos(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import classes from "./SeguimientoInversiones.css";
import { connect } from "react-redux";

import Auxs from "../../../hoc/auxs/Auxs";
import Accordion from "../../../components/ui/accordion/Accordion";



import * as action from "../../../stores/actions/index";
import { IntlProvider, defineMessages, FormattedHTMLMessage } from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";

class SeguimientoInversiones extends Component {
  onPrintTerminos = terminos => {
    return (
      <Accordion datos={terminos} nombre="terminos" icon={""} tipo={5} icons_tamaño={true}/>
    );
  };
  state = {
    messages: null,
    lan: getLocal(),
    togglebox: null
  };

  componentDidMount() {
    this.props.onInitIntl();
    this.props.onAccordion();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages
        });
      }
    }
  }

  onPrintInformacion = terminos => {
    return (
      <Accordion datos={terminos} nombre="terminos" icon={""} tipo={1}  icons_tamaño={true} sinIcono={true}/>
    );
  };

  render() {
    const terminos = this.props.terminos
      ? this.onPrintTerminos(this.props.terminos)
      : null;

      const informacion = this.props.informacion
      ? this.onPrintInformacion(this.props.informacion)
      : null;

    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages}>
      <Auxs>
        <div className="row">
          <div className="col sin_padding_L">
            <div className={["margen_top_30"].join(" ")}>
              <h1 className={["nunito", "texto_42"].join(" ")}>
              <FormattedHTMLMessage id="invertir.comodarseguimientoamisinversiones.titulo1" defaultMessage=" " />
                
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div
              className={[
                classes.text_container,
                "text_30",
                "montse",
                "margen_top_50"
              ].join(" ")}
            >
              <p className="texto_16 montse regular">
              <FormattedHTMLMessage id="invertir.comodarseguimientoamisinversiones.introduccion1" defaultMessage=" " />

               
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div
              className={[
                classes.text_container,
                "text_30",
                "montse",
                ].join(" ")}
            >
           {informacion}
           <p className="texto_16 montse regular">
              <FormattedHTMLMessage id="invertir.comodarseguimientoamisinversiones.introduccion2" defaultMessage=" " />

               
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col sin_padding_L">
            <div
              className={[classes.terminosContainer, "margen_top_10"].join(" ")}
            >
              {terminos}
            </div>
          </div>
        </div>
      </Auxs>
      </IntlProvider>
      );
  }
}

const mapStateToProps = state => {
  return {
    terminos: state.accordion.seguimientoInversiones,
    diccionario: state.intl.diccionario,
    informacion: state.toggleBox.toggleBoxInformacionEmisora,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntl(23));
    },
    onAccordion: () => {
      dispatch(action.getAccordionSeguimientoInversiones());
      dispatch(action.getToggleBoxInformacionEmisora());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeguimientoInversiones);

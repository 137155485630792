import React, { Component } from "react";
import classes from "./BivaSustentable.css";
import { connect } from "react-redux";

import Auxs from "../../../hoc/auxs/Auxs";
import List from "../../../components/ui/list/List";
import * as action from "../../../stores/actions/index";
import { NavLink } from "react-router-dom";

import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import Cuadricula from "../../../components/ui/cuadricula/Cuadricula";

class BivaSustentable extends Component {
    state = {
        messages: null,
        lan: getLocal(),
        estrategia: null,
        objetivos: null,
    };

    componentDidMount() {
        this.props.onInitIntl(78);
        this.props.onFolleto();
    }
    componentDidUpdate(prevProps) {
        if (this.props.diccionario !== prevProps.diccionario) {
            //se obtiene el diccionario del endpoint de traducciones
            if (this.props.diccionario) {
                const messages = defineMessages(this.props.diccionario);
                this.setState({
                    messages: messages
                });
            }
        }
        if (this.props.estrategia !== prevProps.estrategia) {
            this.setState({
                estrategia: this.props.estrategia
            });
        }
        if (this.props.objetivos !== prevProps.objetivos) {
            this.setState({
                objetivos: this.props.objetivos
            });
        }
    }
    render() {
        let estrategia = null;
        let objetivos = null;

        if (this.state.estrategia) {
            estrategia = this.state.estrategia;
        }
        if (this.state.objetivos) {
            objetivos = this.state.objetivos;
        }

        return (
            <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
                <Auxs>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div>
                                <h1 className={[classes.main_title, "texto_42", "nunito"].join(" ")}>
                                    <FormattedMessage id="nosotros.biva.sustentable.titulo" defaultMessage={getLan() === "es" ? "BIVA Sustentable" : "BIVA Sustainable"} />
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={"slash_green"}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.compromiso.titulo" defaultMessage=" " />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container, "text_30", "montse"].join(" ")}>
                                <div className="montse texto_16 regular">
                                    {/* <FormattedMessage id="nosotros.biva.sustentable.compromiso.texto" defaultMessage=" " /> */}
                                    <span className={["texto_16 montse regular"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "nosotros.biva.sustentable.compromiso.texto"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={["slash_green"]}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.objetivos.titulo" defaultMessage=" " />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row margen_top_50">
                        <div className="center">
                            <Cuadricula datos={objetivos} coloreados={[1, 2]} saltarse={[]} enBlanco={[]} quitar={[]} modulo={2} tipo={8}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={["slash_green"]}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.estrategia.titulo" defaultMessage="Estrategia" />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container, "text_30", "montse"].join(" ")}>
                                <div className="montse texto_16 regular">
                                    {/* <FormattedMessage id="nosotros.biva.sustentable.estrategia.texto" defaultMessage=" " /> */}
                                    <span className={["texto_16 montse regular"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "nosotros.biva.sustentable.estrategia.texto"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row margen_top_50">
                        <div className="center">
                            <Cuadricula datos={estrategia} coloreados={[]} saltarse={[1, 3, 7]} enBlanco={[]} quitar={[]} modulo={3} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={"slash_green"}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.apego.titulo" defaultMessage=" " />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container_blue, "text_30", "montse"].join(" ")}>
                                <div className="montse texto_16 regular texto_blanco">
                                    {/* <FormattedMessage id="nosotros.biva.sustentable.apego.texto" defaultMessage=" " /> */}
                                    <span className={["texto_16 montse regular"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "nosotros.biva.sustentable.apego.texto"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={"slash_green"}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.indices.titulo" defaultMessage=" " />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container_blue, "text_30", "montse"].join(" ")}>
                                <div className="montse texto_16 regular texto_blanco">
                                    {/* <FormattedMessage id="nosotros.biva.sustentable.indices.texto" defaultMessage=" " /> */}
                                    <span className={["texto_16 montse regular"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "nosotros.biva.sustentable.indices.texto"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"margin_top_30 col-12 sin_padding_L offset-md-10 offset-lg-10"}>
                            <NavLink
                                to={`/informacion_de_mercado/indices/informacion_general`}>
                                <span className={["texto_16_17 nunito bold color_azul"].join(" ")}>{getLan() === "es" ? "FTSE4Good" : "FTSE4Good"}</span>
                                <i className={["fas fa-long-arrow-alt-right color_azul", "Boton__icono_derecho__25mg6"].join(" ")} />
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={"slash_green"}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.mercados.titulo" defaultMessage=" " />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container_blue, "text_30", "montse"].join(" ")}>
                                <div className="montse texto_16 regular texto_blanco">
                                    {/* <FormattedMessage id="nosotros.biva.sustentable.mercados.texto" defaultMessage=" " /> */}
                                    <span className={["texto_16 montse regular"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "nosotros.biva.sustentable.mercados.texto"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={"slash_green"}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.eduacion.titulo" defaultMessage=" " />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container_blue, "text_30", "montse"].join(" ")}>
                                <div className="montse texto_16 regular texto_blanco">
                                    {/* <FormattedMessage id="nosotros.biva.sustentable.eduacion.texto" defaultMessage=" " /> */}
                                    <span className={["texto_16 montse regular"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "nosotros.biva.sustentable.eduacion.texto"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"margin_top_30 col-12 sin_padding_L offset-md-10 offset-lg-10"}>
                            <NavLink
                                to={`/instituto_biva`}>
                                <span className={["texto_16_17 nunito bold color_azul"].join(" ")}>{getLan() === "es" ? "Instituto BIVA" : "BIVA Institute"}</span>
                                <i className={["fas fa-long-arrow-alt-right color_azul", "Boton__icono_derecho__25mg6"].join(" ")} />
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={["main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <span className={"slash_green"}>/</span>
                                <h2>
                                    <FormattedMessage id="nosotros.biva.sustentable.equipo.titulo" defaultMessage=" " />
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.text_container_blue, "text_30", "montse"].join(" ")}>
                                <div className="montse texto_16 regular texto_blanco">
                                    {/* <FormattedMessage id="nosotros.biva.sustentable.equipo.texto" defaultMessage=" " /> */}
                                    <span className={["texto_16 montse regular"]} dangerouslySetInnerHTML={{
                                        __html:
                                            this.props.diccionario !== null
                                                ? this.props.diccionario[
                                                "nosotros.biva.sustentable.equipo.texto"
                                                ]
                                                : null,
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col sin_padding_L">
                            <div className={[classes.margin_leaf, "main_subtitle", "texto_30", "nunito"].join(" ")}>
                                <i class="fa fa-leaf slash_green fa-2x" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </Auxs>
            </IntlProvider >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitIntl: pagina => {
            dispatch(action.initIntl(pagina));
        },
        onFolleto: () => {
            dispatch(action.initBoxEstrategia());
            dispatch(action.initBoxObjetivos());
        }
    };
};

const mapStateToProps = state => {
    return {
        diccionario: state.intl.diccionario,
        estrategia: state.cuadriculas.estrategias,
        objetivos: state.cuadriculas.objetivos
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BivaSustentable);

import React, { Component } from "react";
import classes from "./InstitutoBiva.css";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import Auxs from "../../../hoc/auxs/Auxs";
import SliderDocument from "../../../components/ui/sliderDocument/SliderDocument";
import {
  IntlProvider,
  defineMessages,
  FormattedMessage,
  FormattedHTMLMessage,
} from "react-intl";
import { getLocal } from "../../../stores/utils/utilities";
import Modal from "../../../components/ui/modal/Modal";
import BackDrop from "../../../components/ui/backdrop/BakcDrop";
import Boton from "../../../components/ui/boton/Boton";

class InstitutoBiva extends Component {
  state = {
    messages: null,
    showModal: false,
    lan: getLocal(),
  };
  componentDidMount() {
    this.props.onInitIntl(8);
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionario !== prevProps.diccionario) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionario) {
        const messages = defineMessages(this.props.diccionario);
        this.setState({
          messages: messages,
        });
      }
    }
  }

  onToggleModalHandler = () => {
    this.setState((prevState) => {
      return {
        showModal: !prevState.showModal,
      };
    });
  };

  render() {
    const modal = (
      <React.Fragment>
        <BackDrop
          show={this.state.showModal}
          clicked={this.onToggleModalHandler}
        />
        <Modal
          showModal={this.state.showModal}
          onCloseModal={this.onToggleModalHandler}
          tipo_mensaje={2}
          area={"ib"}
        />
      </React.Fragment>
    );

    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1
                  className={[classes.main_title, "texto_42", "nunito"].join(
                    " "
                  )}
                >
                  <FormattedMessage
                    id="institutobiva.cursos.titulo1"
                    defaultMessage=" "
                  />
                </h1>
              </div>
            </div>
          </div>

          <div className="row margen_top_30">
            <span
              className={["texto_16 montse regular"]}
              dangerouslySetInnerHTML={{
                __html:
                  this.props.diccionario !== null
                    ? this.props.diccionario[
                        "institutobiva.cursos.introduccion1"
                      ]
                    : null,
              }}
            />
          </div>
          <div className={classes.video_container}>
            <iframe
              className={classes.iframe}
              src={
                "https://www.youtube.com/embed/" +
                (this.props.diccionario !== null
                  ? this.props.diccionario["institutobiva.cursos.video"]
                  : "")
              }
              frameborder="0"
              title="videos"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          </div>

          <div className="margin_top_80">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedHTMLMessage
                  id="institutobiva.titulo2.cursos"
                  defaultMessage=" "
                />
              </h2>
            </div>
            <div className={""}>
              <span className={"montse texto_16 regular"}>
                <FormattedHTMLMessage
                  id="institutobiva.introduccion2.cursos"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className="margin_top_30">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedHTMLMessage
                  id="institutobiva.titulo3.embajador"
                  defaultMessage=" "
                />
              </h2>
            </div>
            <div className={""}>
              <span className={"montse texto_16 regular"}>
                <FormattedHTMLMessage
                  id="institutobiva.introduccion3.embajador"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className="margin_top_30">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedHTMLMessage
                  id="institutobiva.titulo4.bivateca"
                  defaultMessage=" "
                />
              </h2>
            </div>
            <div className={""}>
              <span className={"montse texto_16 regular"}>
                <FormattedHTMLMessage
                  id="institutobiva.introduccion4.bivateca"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className="margin_top_30">
            <div className={["main_subtitle"].join(" ")}>
              <span className={"slash"}>/</span>
              <h2>
                <FormattedHTMLMessage
                  id="institutobiva.titulo5.convenios"
                  defaultMessage=" "
                />
              </h2>
            </div>
            <div className={""}>
              <span className={"montse texto_16 regular"}>
                <FormattedHTMLMessage
                  id="institutobiva.introduccion5.convenios"
                  defaultMessage=" "
                />
              </span>
            </div>
          </div>
          <div className="row margin_top_80">
            <Boton
              onClick={this.onToggleModalHandler.bind(this)}
              tipo={4}
              texto={
                this.state.messages
                  ? this.state.messages["institutobiva.boton.contactanos"]
                  : " "
              }
              icono_derecho="fas fa-long-arrow-alt-right"
              colorBoton={""}
            />
          </div>
          {/* <FormattedMessage id="institutobiva.cursos.introduccion1" defaultMessage=" " /> */}
          {modal}
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    cursosPresenciales: state.documentos.cursosPresenciales,
    cursosRemotos: state.documentos.cursosRemotos,
    diccionario: state.intl.diccionario,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutoBiva);

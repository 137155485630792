import React, { Component } from "react";
import Chart from "react-google-charts";
import loader from "../../../assets/gif/loader.gif";

class Charts extends Component {
  state = {
    widthDisplay: 0,
    heightDisplay: 0,
    data: [["Hours", "Open", "Close"]],
    data2: [["Hours", "Close"]],
    dataFilter: [[]],
    width: null,
    height: null,
    chartType: null,
  };

  componentDidMount() {
    let indicadores = null;
    let data = null;
    if (this.props.indicadores !== undefined) {
      indicadores = this.props.indicadores.concat(this.props.data);
    } else {
      indicadores = this.props.data;
    }
    if (this.props.onlyClose) {
        data = this.state.data2.concat(this.props.data)
    }else{
        data = this.state.data.concat(this.props.data)
    }
    this.setState({
      data: data,
      dataFilter: indicadores,
      width: this.props.width ? this.props.width : "300px",
      height: this.props.height ? this.props.height : "400px",
      chartType: this.props.chartType ? this.props.chartType : "AreaChart",
    });
    window.addEventListener("resize", this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({
      widthDisplay: window.innerWidth,
      heightDisplay: window.innerHeight,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    let primerFecha = null;
    let ultimaFecha = null;

    if (this.props.esVacio) {
      let today = new Date();
      let anio = today.getFullYear();
      return (
        <Chart
          width={this.state.width}
          height={this.state.height}
          chartType={this.state.chartType}
          loader={
            <div>
              <img src={loader} alt=" " />
            </div>
          }
          data={[...this.state.dataFilter]}
          options={{
            legend: { position: "top", maxLines: 0 },
            colors: ["#00AEEF"],
            chartArea: { height: "80%", width: "90%" },
            tooltip: { isHtml: false, trigger: "visible" },
            hAxis: {
              format: "MMM yyyy",
              viewWindow: {
                min: new Date(anio, 0, 1),
                max: today,
              },
            },
            vAxis: {
              ticks: [0, 25, 50, 75, 100],
            },
          }}
        />
      );
    }

    //@deprecated Todo este 'if' lo utiliza el componente ChartFilter, es cual pareciera que no se utiliza.
    if (this.props.tieneFiltro) {
      if (this.props.data.length > 0) {
        primerFecha = this.props.data[this.props.lastData][0];

        ultimaFecha = this.props.data[0][0];

        let series = null;
        if (this.props.lineas) {
          series = [];
          this.props.lineas.map((item, i) => {
            series.push({ lineWidth: item });
            return null;
          });
        }

        let ticks = null;
        if (this.props.ticks) {
          ticks = this.props.ticks;
        }
        let format_number = "###,##0.00";
        if (this.props.ticks_int) {
          format_number = "###,##0";
        }
        return (
          <React.Fragment>
            <Chart
              graphID={this.props.graph_id}
              graph_id={this.props.graph_id}
              chartType={this.props.chartType}
              loader={
                <div>
                  <img src={loader} alt=" " />
                </div>
              }
              data={[...this.state.dataFilter]}
              options={{
                // Use the same chart area width as the control for axis alignment.
                chartArea: { height: "80%", width: "80%" },
                hAxis: {
                  slantedText: false,
                  gridlines: {
                    count: -1,
                    units: {
                      years: { format: ["MMM yyyy"] },
                      months: { format: ["MMM yyyy"] },
                      days: { format: ["dd MMM"] },
                      hours: { format: ["HH:mm"] },
                      minutes: { format: ["HH:mm"] },
                    },
                  },
                  minorGridlines: {
                    color: "transparent",
                    units: {
                      years: { format: ["MMM yyyy"] },
                      months: { format: ["MMM yyyy"] },
                      days: { format: ["dd MMM yyyy"] },
                      hours: { format: ["dd MMM HH:mm"] },
                      minutes: { format: ["HH:mm"] },
                    },
                  },
                },
                legend: { position: "top", maxLines: 0 },
                series,
                colors: this.props.colores,
                vAxis: {
                  viewWindowMode: "continuous",
                  scaleType: "linear",
                  ticks: ticks,
                  format: format_number,
                  minorGridlines: {
                    color: "#f3f3f3",
                  },
                },
              }}
              rootProps={{ "data-testid": "1" }}
              chartPackages={["corechart", "controls"]}
              controls={[
                {
                  controlType: "ChartRangeFilter",
                  options: {
                    filterColumnIndex: 0,
                    ui: {
                      chartType: "AreaChart",
                      chartOptions: {
                        chartArea: { width: "80%", height: "30%" },
                        hAxis: {
                          baselineColor: "none",
                          gridlines: {
                            count: -1,
                            units: {
                              years: { format: ["MMM yyyy"] },
                              months: { format: ["MMM yyyy"] },
                              days: { format: ["dd MMM yyyy"] },
                              hours: { format: ["hh:mm a"] },
                            },
                          },
                          minorGridlines: {
                            units: {
                              years: { format: ["MMM yyyy"] },
                              months: { format: ["MMM yyyy"] },
                              days: { format: ["dd MMM yyyy"] },
                              hours: { format: ["hh:mm a"] },
                              minutes: { format: ["hh:mm a"] },
                            },
                          },
                        },
                        colors: this.props.colores,
                      },
                    },
                  },
                  controlPosition: "bottom",
                  controlWrapperParams: {
                    state: {
                      range: {
                        start: primerFecha,
                        end: ultimaFecha,
                      },
                    },
                  },
                },
              ]}
            />
          </React.Fragment>
        );
      }
    } else if (this.props.es_operacion) {
      // let colores = ["#00aeef"];
      let colores = ["#00AEEF", "#EAEDFA"];
      if (this.props.es_negativo) {
        colores = ["#EAEDFA", "#e22929"];
      }
      return (
        <Chart
          chartType="PieChart"
          width={"100%"}
          height={"100%"}
          data={this.props.data}
          options={{
            pieHole: 0.6,
            is3D: false,
            slices: {
              0: { offset: 0.0 },
              1: { offset: 0.0 },
            },
            colors: colores,
            backgroundColor: "transparent",
            legend: "none",
            pieSliceText: "none",
            tooltip: { text: "percentage", textStyle: { fontSize: 12 } },
          }}
        />
      );
    } else if (this.props.tiene_puntos) {
      let series = null;
      let colores = null;
      if (this.props.lineas) {
        series = [];
        this.props.lineas.map((item) => {
          series.push({ lineWidth: item });
          return null;
        });
      }
      if (this.props.colores) {
        colores = this.props.colores;
      }
      let ticks = null;
      if (this.props.es_porcentaje) {
        // ticks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
      }
      if (this.props.esPorcentaje) {
        let viewWindow = null;
        if (this.props.anioInicio && this.props.anioFin) {
          viewWindow = {
            min: new Date(this.props.anioInicio, 0, 1),
            max: new Date(this.props.anioFin, 11, 1),
          };
        }
        let focusTarget = null;
        let isHtml = false;
        if (this.props.focusTarget) {
          focusTarget = "category";
          isHtml = true;
        }
        return (
          <Chart
            width={this.state.width}
            height={this.state.height}
            chartType={this.state.chartType}
            loader={
              <div>
                <img src={loader} alt=" " />
              </div>
            }
            data={[...this.state.dataFilter]}
            options={{
              chartArea: { height: "70%", width: "70%" },
              legend: { position: "top", maxLines: 0 },
              focusTarget: focusTarget,
              series,
              colors: colores,
              pointSize: 5,
              hAxis: {
                format: "MMM yyyy",
                viewWindow: viewWindow,
                gridlines: {
                  color:'transparent',
                  count: -1,
                  units: {
                    years: { format: ["MMM yyyy"] },
                    months: { format: ["MMM yyyy"] },
                    days: { format: ["dd MMM yyyy"] },
                  },
                },
                minorGridlines: {
                  color:'transparent',
                  units: {
                    years: { format: ["MMM yyyy"] },
                    months: { format: ["MMM yyyy"] },
                    days: { format: ["dd MMM yyyy"] },
                  },
                },
              },
              tooltip: {
                isHtml: isHtml,
                text: "percentage",
                textStyle: { fontSize: 12 },
              },
              vAxis: {
                format: "percent",
                title: this.props.tituloV,
                scaleType: "linear",
                minorGridlines: {
                  color:'transparent',
                },
              },
            }}
          />
        );
      } else if (this.props.esCurrency) {
        let viewWindow = null;
        let pointSize = this.props.pointSize ? this.props.pointSize :5;
        let lineWidth = this.props.lineWidth ? this.props.lineWidth :2;
        if (this.props.anioInicio && this.props.anioFin) {
          viewWindow = {
            min: new Date(this.props.anioInicio, 0, 1),
            max: new Date(this.props.anioFin, 11, 1),
          };
        }
        if (this.props.fechaInicio && this.props.fechaFin) {
          viewWindow = {
            min: new Date(this.props.fechaInicio),
            max: new Date(this.props.fechaFin),
          };
        }
        let focusTarget = null;
        let isHtml = false;
        if (this.props.focusTarget) {
          focusTarget = "category";
          isHtml = true;
        }
        return (
          <Chart
            width={this.state.width}
            height={this.state.height}
            chartType={this.state.chartType}
            loader={
              <div>
                <img src={loader} alt=" " />
              </div>
            }
            data={[...this.state.dataFilter]}
            options={{
              chartArea: { height: "70%", width: "70%" },
              interpolateNulls: true, // si existe nulo une puntos
              legend: { position: "top", maxLines: 0 },
              focusTarget: focusTarget,
              series,
              colors: colores,
              lineWidth: lineWidth,
              pointSize: pointSize,
              hAxis: {
                format: "MMM yyyy",
                viewWindow: viewWindow,
                slantedText: false,
                gridlines: {
                  color:'transparent',
                  count: -1,
                  units: {
                    years: { format: ["MMM yyyy"] },
                    months: { format: ["MMM yyyy"] },
                    days: { format: ["dd MMM"] },
                    hours: { format: ["dd MMM"] },
                  },
                },
                minorGridlines: {
                  color:'transparent',
                },
              },
              tooltip: {
                isHtml: isHtml,
                textStyle: { fontSize: 12 },
              },
              vAxis: {
                format: "currency",
                maxValue: 1,
                title: this.props.tituloV,
                scaleType: "linear",
                ticks: ticks,
              },
            }}
          />
        );
      } else if (this.props.anio) {
        return (
          <Chart
            width={this.state.width}
            height={this.state.height}
            chartType={this.state.chartType}
            loader={
              <div>
                <img src={loader} alt=" " />
              </div>
            }
            data={[...this.state.dataFilter]}
            options={{
              chartArea: { height: "70%", width: "70%" },
              legend: { position: "top", maxLines: 0 },
              series,
              colors: colores,
              pointSize: 10,
              hAxis: {
                format: "MMM yyyy",
                viewWindow: {
                  min: new Date(this.props.anio, 0, 1),
                  max: new Date(this.props.anio, 11, 1),
                },
              },
              tooltip: { isHtml: false, trigger: "visible" },
              vAxis: {
                title: this.props.tituloV,
                scaleType: "linear",
                ticks: ticks,
              },
            }}
          />
        );
      }
      return (
        <Chart
          width={this.state.width}
          height={this.state.height}
          chartType={this.state.chartType}
          loader={
            <div>
              <img src={loader} alt=" " />
            </div>
          }
          data={[...this.state.dataFilter]}
          options={{
            chartArea: { height: "70%", width: "70%" },
            legend: { position: "top", maxLines: 0 },
            series,
            colors: colores,
            pointSize: 10,
            hAxis: {
              format: "MMM yyyy",
            },
            tooltip: { isHtml: false, trigger: "visible" },
            vAxis: {
              title: this.props.tituloV,
              scaleType: "linear",
              ticks: ticks,
            },
          }}
        />
      );
    }

    return (
      <Chart
        width={this.state.width}
        height={this.state.height}
        chartType={this.state.chartType}
        loader={
          <div>
            <img src={loader} alt=" " />
          </div>
        }
        data={[...this.state.data]}
        options={{
          height: "300px",
          backgroundColor: "transparent",
          colors: ["#00AEEF"],
          hAxis: {
            slantedText: false,
            textStyle: {color: this.props.colorLabels ? this.props.colorLabels : '#000',fontSize:'15',fontName:'"Montserrat",  sans-serif'},
            gridlines: {
              color: "#f3f3f3",
              count: 2,
              units: {
                years: { format: ["MMM yyyy"] },
                months: { format: ["MMM yyyy"] },
                days: { format: ["dd MMM"] },
                hours: { format: ["HH:mm"] },
                minutes: { format: ["HH:mm"] },
              },
            },
            minorGridlines: {
              color: "transparent",
              units: {
                years: { format: ["MMM yyyy"] },
                months: { format: ["MMM yyyy"] },
                days: { format: ["dd MMM yyyy"] },
                hours: { format: ["dd MMM HH:mm"] },
                minutes: { format: ["HH:mm"] },
              },
            },
          },
          vAxis: {
            textStyle: {color: this.props.colorLabels ? this.props.colorLabels : '#000',fontSize:'15',fontName:'"Montserrat",  sans-serif'},
          },
          legend: { position: "top",  
          textStyle: {color: this.props.colorLabels ? this.props.colorLabels : '#000',fontSize:'15',fontName:'"Montserrat",  sans-serif'},
        },
        }}
      />
    );
  }
}

export default Charts;

import axios from "axios";
import * as actionTypes from "./actionTypes";
import {getLan, getKeyYoutube} from "../utils/utilities"

const dominio = process.env.REACT_APP_DOMINIO;
const lang = getLan();

const setVideosAPI = data => {
  return {
    type: actionTypes.FETCH_VIDEO_API_SUCCESS,
    videos_api: data
  };
};

// Espacio Biva
export const initVideosAPI = (maxResult = 50) => {
  return dispatch => {
    axios
      .get(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=${maxResult}&playlistId=PL73n8KHqsRNEdtHOviva2FJKpZ9vYL7jT&key=${getKeyYoutube()}`
      )
      .then(response => {
        dispatch(setVideosAPI(response.data));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

// Videos Instituto BIVA
const setVideosInstituto = data => {
  return {
    type: actionTypes.FETCH_VIDEO_INSTITUTO_SUCCESS,
    videos_instituto: data
  };
};

export const initVideosInstituto = (fecha_inicio = null, fecha_fin = null) => {
  let url =
  `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=PL73n8KHqsRNEUaQfnGAQRtZ4UPgIZ0QnE&key=${getKeyYoutube()}`;
  if (fecha_fin || fecha_fin) {
    fecha_inicio = fecha_inicio ? fecha_inicio + "T00%3A00%3A00Z" : "";
    fecha_fin = fecha_fin ? fecha_fin + "T00%3A00%3A00Z" : "";

    url = `https://content.googleapis.com/youtube/v3/search?channelId=UCTkKbvqTmH0Znzlpbzzu8Fg&maxResults=50&part=snippet&playlistId=PL73n8KHqsRNEUaQfnGAQRtZ4UPgIZ0QnE&publishedAfter=${fecha_inicio}&publishedBefore=${fecha_fin}&key=${getKeyYoutube()}`;
  }
  console.log(url);
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(setVideosInstituto(response.data));
      })
      .catch(err => {
        console.log(err);
      });
  };
};


const getVideoBIM = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VIDEOS_SUCCESS,
      videoBIM: null
    };
  }
  return {
    type: actionTypes.FETCH_VIDEOS_SUCCESS,
    videoBIM:data
  };
};

export const initVideoBIM = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/video-bim/lang/"+lang)
      .then(response => {
        dispatch(getVideoBIM(response.data));
      })
      .catch(err => dispatch(getVideoBIM(err.data)));
  };
};


const setVideosAuditorium = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VIDEOS_SUCCESS,
      videosAuditorium: null
    };
  }
  return {
    type: actionTypes.FETCH_VIDEOS_SUCCESS,
    videosAuditorium:data
  };
};

export const getVideosAuditorium = () => {
  return dispatch => {
    axios
      .get(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=50&playlistId=PL6qQudeqow2RUm870uwBl9QTL-W0DaWLt&key=${getKeyYoutube()}`)
      .then(response => {
        dispatch(setVideosAuditorium(response.data));
      })
      .catch(err => dispatch(setVideosAuditorium(err.data)));
  };
};

const setVideosDIV = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VIDEOS_SUCCESS,
      videosDIV: null
    };
  }
  return {
    type: actionTypes.FETCH_VIDEOS_SUCCESS,
    videosDIV:data
  };
};

export const getVideosDIV = () => {
  return dispatch => {
    axios
      .get(dominio + "/cms/o/documents/div-videos/lang/"+lang)
      .then(response => {
        dispatch(setVideosDIV(response.data));
      })
      .catch(err => dispatch(setVideosDIV(err.data)));
  };
};

// ================================================================

const setTabsVideos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VIDEOS_SUCCESS,
      tabs: null
    };
  }
  return {
    type: actionTypes.FETCH_VIDEOS_SUCCESS,
    tabs:data
  };
};

export const getTabsCategorias = (categoria = "categorias-espacio-biva") => {
  return dispatch => {
    axios
      .get(dominio + `/cms/o/documents/${categoria}/lang/`+lang)
      .then(response => {
        dispatch(setTabsVideos(response.data));
      })
      .catch(err => dispatch(setTabsVideos(err.data)));
  };
};


// ================================================================

const setNuevosVideos = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VIDEOS_SUCCESS,
      nuevosVideos: null
    };
  }
  return {
    type: actionTypes.FETCH_VIDEOS_SUCCESS,
    nuevosVideos:data
  };
};

const escapePipe = (field) =>{
  field = field.split("|").join("%7C");
  return field;
};

export const getNuevosVideos = (maxResults = 6) => {
  const field = "EspacioBIVA";
  const url = `${dominio}/biva-channel/videos?q=${field}&maxResults=${maxResults}`;
  
  return dispatch => {
    axios
    .get(url)
    .then(response => {
        dispatch(setNuevosVideos(response.data));
      })
      .catch(err => dispatch(setNuevosVideos(err.data)));
  };
};

const setVideosByField = data => {
  if (data === undefined || data === null) {
    return {
      type: actionTypes.FETCH_VIDEOS_SUCCESS,
      videosByField: null
    };
  }
  return {
    type: actionTypes.FETCH_VIDEOS_SUCCESS,
    videosByField:data
  };
};
export const getVideosByField = (field, maxResults = 50) => {
  field = escapePipe(field);
  const url = `${dominio}/biva-channel/videos?q=${field}&maxResults=${maxResults}`;
  return dispatch => {
    axios
    .get(url)
      .then(response => {
        dispatch(setVideosByField(response.data));
      })
      .catch(err => dispatch(setVideosByField(err.data)));
  };
};


const setVideosEmbajador = data => {
    if (data === undefined || data === null) {
        return {
          type: actionTypes.FETCH_VIDEOS_SUCCESS,
          videos_embajador: null
        };
      }
      return {
        type: actionTypes.FETCH_VIDEOS_SUCCESS,
        videos_embajador:data
      };
  };
  
  // Espacio Biva
  export const getVideosEmbajador = (maxResult = 50) => {
    return dispatch => {
      axios
        .get(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=${maxResult}&playlistId=PL73n8KHqsRNHa0YHY6KvvnzzWDN6kRF9a&key=${getKeyYoutube()}`
        )
        .then(response => {
          dispatch(setVideosEmbajador(response.data));
        })
        .catch(err => {
          console.log(err);
        });
    };
  };
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import classes from "./Documentos.css";
import SliderDocument from "../../../components/ui/sliderDocument/SliderDocument";
import Auxs from "../../../hoc/auxs/Auxs";
import { getLocal, getLan } from "../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import queryString from "query-string";
import Bivateca from "./bivateca/Bivateca";
import Loader from "../../../components/loader/Loader";
import FilterDocuments from "./FilterDocuments";

const label_see_more = getLan() === "es" ? "Ver mas" : "See More";
class Documentos extends Component {
  state = {
    results: false,
    palabraClave: null,
    messages: null,
    lan: getLocal(),
    showGrids: false,
    articulosAcademicos: false,
    revistas: false,
    escritos: false,
    blog: false,
    libros: false,
    diccionario: false,
    articulosAcademicosData: [],
    revistasData: [],
    escritosData: [],
    blogData: [],
    librosData: [],
    diccionarioData: [],
    loader: true,
    flagsFilter: {
      all: true,
      articulos: false,
      revistas: false,
      escritos: false,
      blog: false,
      libros: false,
      diccionario: false,
    },
    filterChecks: {
      all: true,
      articulos: false,
      revistas: false,
      escritos: false,
      blog: false,
      libros: false,
      diccionario: false,
    },
  };
  componentDidMount() {
    this.props.onInitVideosInstituto();

    this.initData(true);
  }

  initData(init = false) {
    let paramsString = queryString.parse(this.props.location.search);

    let articulosAcademicos =
      paramsString["articulos_academicos"] === null ? true : false;
    let revistas = paramsString["revistas"] === null ? true : false;
    let escritos = paramsString["escritos"] === null ? true : false;
    let blog = paramsString["blog"] === null ? true : false;
    let libros = paramsString["libros"] === null ? true : false;
    let diccionario = paramsString["diccionario"] === null ? true : false;

    if (
      articulosAcademicos ||
      revistas ||
      escritos ||
      blog ||
      libros ||
      diccionario
    ) {
      this.setState({
        articulosAcademicos: articulosAcademicos,
        revistas: revistas,
        escritos: escritos,
        blog: blog,
        libros: libros,
        diccionario: diccionario,
        showGrids: false,
      });
    } else {
      this.props.onDocumentos();
      if (init) {
        this.props.onInitIntl(6);
      }
      this.setState({
        showGrids: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.initData();
    }
    if (this.props.diccionarioIntl !== prevProps.diccionarioIntl) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionarioIntl) {
        const messages = defineMessages(this.props.diccionarioIntl);
        this.setState({
          messages: messages,
        });
      }
    }
    if (this.props.articulos !== prevProps.articulos) {
      let articulos = [];
      if (this.props.articulos) {
        const flagsFilter = this.state.flagsFilter;
        if (this.props.articulos.length > 0) {
          this.setState({ results: true });
        }
        if (!flagsFilter.articulos && this.props.articulos.length > 0) {
          flagsFilter.articulos = true;
          this.setState({ flagsFilter: flagsFilter, results: true });
        }
        articulos = [];
        this.props.articulos.forEach((e, i) => {
          if (i < 12) {
            articulos.push(e);
          }
        });
      }
      this.setState({ articulosAcademicosData: articulos });
    }
    if (this.props.revistas !== prevProps.revistas) {
      let revistas = [];
      if (this.props.revistas) {
        const flagsFilter = this.state.flagsFilter;
        if (this.props.revistas.length > 0) {
          this.setState({ results: true });
        }
        if (!flagsFilter.revistas && this.props.revistas.length > 0) {
          flagsFilter.revistas = true;
          this.setState({ flagsFilter: flagsFilter, results: true });
        }
        this.props.revistas.forEach((e, i) => {
          if (i < 12 && this.props.revistas) {
            revistas.push(e);
          }
        });
      }
      this.setState({ revistasData: revistas });
    }
    if (this.props.escritos !== prevProps.escritos) {
      let escritos = [];
      if (this.props.escritos) {
        const flagsFilter = this.state.flagsFilter;
        if (this.props.escritos.length > 0) {
          this.setState({ results: true });
        }
        if (!flagsFilter.escritos && this.props.escritos.length > 0) {
          flagsFilter.escritos = true;
          this.setState({ flagsFilter: flagsFilter, results: true });
        }
        escritos = [];
        this.props.escritos.forEach((e, i) => {
          escritos = [];
          if (i < 12 && this.props.escritos) {
            escritos.push(e);
          }
        });
      }
      this.setState({ escritosData: escritos });
    }
    if (this.props.blog !== prevProps.blog) {
      let blog = [];
      if (this.props.blog) {
        const flagsFilter = this.state.flagsFilter;
        if (this.props.blog.length > 0) {
          this.setState({ results: true });
        }
        if (!flagsFilter.blog && this.props.blog.length > 0) {
          flagsFilter.blog = true;
          this.setState({ flagsFilter: flagsFilter, results: true });
        }
        blog = [];
        this.props.blog.forEach((e, i) => {
          if (i < 12 && this.props.blog) {
            blog.push(e);
          }
        });
      }
      this.setState({ blogData: blog });
    }

    if (this.props.diccionario !== prevProps.diccionario) {
      let diccionario = [];
      if (this.props.diccionario) {
        const flagsFilter = this.state.flagsFilter;
        if (this.props.diccionario.length > 0) {
          this.setState({ results: true });
        }
        if (!flagsFilter.diccionario && this.props.diccionario.length > 0) {
          flagsFilter.diccionario = true;
          this.setState({ flagsFilter: flagsFilter, results: true });
        }
        diccionario = [];
        this.props.diccionario.forEach((e, i) => {
          if (i < 12 && this.props.diccionario) {
            diccionario.push(e);
          }
        });
      }
      this.setState({ diccionarioData: diccionario });
    }
    if (this.props.libros !== prevProps.libros) {
      let libros = [];
      if (this.props.libros) {
        const flagsFilter = this.state.flagsFilter;
        if (this.props.libros.length > 0) {
          this.setState({ results: true });
        }
        if (!flagsFilter.libros && this.props.libros.length > 0) {
          flagsFilter.libros = true;
          this.setState({ flagsFilter: flagsFilter, results: true });
        }
        libros = [];
        this.props.libros.forEach((e, i) => {
          if (i < 12 && this.props.libros) {
            libros.push(e);
          }
        });
      }
      this.setState({ librosData: libros });
    }
  }
  onBack() {
    this.props.history.push(`/instituto_biva/bivateca/documentos`);

    this.setState({ loader: true });
  }

  onSetParams = ({ keyword, filterChecks, reset = false }) => {
    if (reset) {
      this.changeHistoryReset();
    } else {
      this.changeHistory(keyword, filterChecks);
    }
  };

  changeHistoryReset() {
    this.getData(null);
  }

  changeHistory(keyword = null, filterChecks = null) {
    this.setState({
      loader: true,
    });
    this.getData(keyword, filterChecks);
  }

  onSetWasFiltered = (wasFiltered) => {
    this.setState({
      wasFiltered: wasFiltered,
    });
  };

  onSetPalabraClave = (palabra_calve) => {
    this.setState({
      palabraClave: palabra_calve,
    });
  };

  onSetPage = (page) => {
    this.setState({
      activePage: page,
    });
  };

  getData(params, filterChecks) {
    let results = false;
    if (params === null) {
      this.setState({
        filterChecks: {
          all: true,
          articulos: false,
          revistas: false,
          escritos: false,
          blog: false,
          libros: false,
          diccionario: false,
        },
      });
      this.props.onDocumentos();
      return null;
    }
    this.setState({ filterChecks: filterChecks, results: results });
    if (filterChecks["all"]) {
      this.props.onDocumentos(params);
    }
    if (filterChecks["articulos"]) {
      this.props.onArticulosAcademicos(params);
    }
    if (filterChecks["revistas"]) {
      this.props.onRevistas(params);
    }
    if (filterChecks["escritos"]) {
      this.props.onEscritos(params);
    }
    if (filterChecks["blog"]) {
      this.props.onBlog(params);
    }
    if (filterChecks["libros"]) {
      this.props.onLibros(params);
    }
    if (filterChecks["diccionario"]) {
      this.props.onDiccionario(params);
    }
  }

  isVisibleCheck(key = null) {
    const filterChecks = this.state.filterChecks;
    let isVisible = false;
    if (filterChecks.all) {
      return true;
    }
    isVisible = filterChecks[key];

    return isVisible;
  }

  render() {
    let showData = null;
    let loader = null;
    if (this.state.loader) {
      loader = (
        <Loader
          loaderDisable={(disable) => this.setState({ loader: disable })}
          time={3000}
        />
      );
    }
    if (this.state.showGrids) {
      showData = (
        <Auxs>
          <div className="row">
            <div className="col sin_padding_L">
              <div>
                <h1
                  className={[classes.main_title, "texto_42", "nunito"].join(
                    " "
                  )}
                >
                  <FormattedMessage
                    id="institutobiva.bivateca.documentos.titulo1"
                    defaultMessage=" "
                  />
                </h1>
              </div>
            </div>
          </div>
          <div className={["col-12 sin_padding_L"].join(" ")}>
            <FilterDocuments
              setPalabraClave={(palabra_calve) =>
                this.onSetPalabraClave(palabra_calve)
              }
              setWasFiltered={(wasFiltered) =>
                this.onSetWasFiltered(wasFiltered)
              }
              setParams={(filterParams) => this.onSetParams(filterParams)}
              setPage={(page) => this.onSetPage(page)}
              flagsFilter={this.state.flagsFilter}
              messages={this.state.messages}
            />
          </div>
          <div
            className={[
              "row",
              this.isVisibleCheck("articulos") === false
                ? "d-none"
                : this.props.articulos === null
                ? "d-none"
                : this.props.articulos.length === 0
                ? "d-none"
                : null,
            ].join(" ")}
          >
            <div className="col sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage
                    id="institutobiva.bivateca.documentos.subtitulo1"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>

            <div className={["col-12 sin_padding_L"]}>
              <SliderDocument
                tipo_imagen={1}
                titulo_slider={getLan() === "es" ? "Documentos" : "Documents"}
                datos={this.state.articulosAcademicosData}
                tipo={1}
              />
            </div>
          </div>
          <div className="text-center">
            <NavLink
              to={"/instituto_biva/bivateca/documentos?articulos_academicos"}
              className={[
                classes.link,
                "nunito",
                this.isVisibleCheck("articulos") === false
                  ? "d-none"
                  : this.state.articulosAcademicosData.length > 0
                  ? null
                  : "d-none",
              ].join(" ")}
              onClick={this.props.onClick}
            >
              {label_see_more}
            </NavLink>
          </div>

          <div
            className={[
              "row",
              this.isVisibleCheck("revistas") === false
                ? "d-none"
                : this.props.revistas === null
                ? "d-none"
                : this.props.revistas.length === 0
                ? "d-none"
                : null,
            ].join(" ")}
          >
            <div className="col sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage
                    id="institutobiva.bivateca.documentos.subtitulo2"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            <div className={["col-12 sin_padding_L"]}>
              <SliderDocument
                tipo_imagen={2}
                titulo_slider={
                  this.props.diccionarioIntl !== null
                    ? this.props.diccionarioIntl[
                        "institutobiva.bivateca.documentos.subtitulo2"
                      ]
                    : null
                }
                datos={this.state.revistasData}
                tipo={1}
              />
            </div>
          </div>
          <div className="text-center">
            <NavLink
              to={"/instituto_biva/bivateca/documentos?revistas"}
              className={[
                classes.link,
                "nunito",
                this.isVisibleCheck("revistas") === false
                  ? "d-none"
                  : this.state.revistasData.length > 0
                  ? null
                  : "d-none",
              ].join(" ")}
              onClick={this.props.onClick}
            >
              {label_see_more}
            </NavLink>
          </div>

          <div
            className={[
              "row",
              this.isVisibleCheck("escritos") === false
                ? "d-none"
                : this.props.escritos === null
                ? "d-none"
                : this.props.escritos.length === 0
                ? "d-none"
                : null,
            ].join(" ")}
          >
            <div className="col sin_padding_L">
              <div className={["main_subtitle nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage
                    id="institutobiva.bivateca.documentos.subtitulo3"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            <div className={["col-12 sin_padding_L"]}>
              <SliderDocument
                tipo_imagen={3}
                titulo_slider={
                  this.props.diccionarioIntl !== null
                    ? this.props.diccionarioIntl[
                        "institutobiva.bivateca.documentos.subtitulo3"
                      ]
                    : null
                }
                datos={this.state.escritosData}
                tipo={1}
              />
            </div>
          </div>
          <div className="text-center">
            <NavLink
              to={"/instituto_biva/bivateca/documentos?escritos"}
              className={[
                classes.link,
                "nunito",
                this.isVisibleCheck("escritos") === false
                  ? "d-none"
                  : this.state.escritosData.length > 0
                  ? null
                  : "d-none",
              ].join(" ")}
              onClick={this.props.onClick}
            >
              {label_see_more}
            </NavLink>
          </div>

          <div
            className={[
              "row",
              this.isVisibleCheck("blog") === false
                ? "d-none"
                : this.props.blog === null
                ? "d-none"
                : this.props.blog.length === 0
                ? "d-none"
                : null,
            ].join(" ")}
          >
            <div className="col sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage
                    id="institutobiva.bivateca.documentos.subtitulo4"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            <div className={["col-12 sin_padding_L"]}>
              <SliderDocument
                tipo_imagen={4}
                titulo_slider={
                  this.props.diccionarioIntl !== null
                    ? this.props.diccionarioIntl[
                        "institutobiva.bivateca.documentos.subtitulo4"
                      ]
                    : null
                }
                datos={this.state.blogData}
                tipo={1}
              />
            </div>
          </div>
          <div className="text-center">
            <NavLink
              to={"/instituto_biva/bivateca/documentos?blog"}
              className={[
                classes.link,
                "nunito",
                this.isVisibleCheck("blog") === false
                  ? "d-none"
                  : this.state.blogData.length > 0
                  ? null
                  : "d-none",
              ].join(" ")}
              onClick={this.props.onClick}
            >
              {label_see_more}
            </NavLink>
          </div>

          <div
            className={[
              "row",
              this.isVisibleCheck("libros") === false
                ? "d-none"
                : this.props.libros === null
                ? "d-none"
                : this.props.libros.length === 0
                ? "d-none"
                : null,
            ].join(" ")}
          >
            <div className="col sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage
                    id="institutobiva.bivateca.documentos.subtitulo5"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            <div className={["col-12 sin_padding_L"]}>
              <SliderDocument
                tipo_imagen={5}
                titulo_slider={
                  this.props.diccionarioIntl !== null
                    ? this.props.diccionarioIntl[
                        "institutobiva.bivateca.documentos.subtitulo5"
                      ]
                    : null
                }
                datos={this.state.librosData}
                tipo={1}
              />
            </div>
          </div>
          <div className="text-center">
            <NavLink
              to={"/instituto_biva/bivateca/documentos?libros"}
              className={[
                classes.link,
                "nunito",
                this.isVisibleCheck("libros") === false
                  ? "d-none"
                  : this.state.librosData.length > 0
                  ? null
                  : "d-none",
              ].join(" ")}
              onClick={this.props.onClick}
            >
              {label_see_more}
            </NavLink>
          </div>

          <div
            className={[
              "row",
              this.isVisibleCheck("diccionario") === false
                ? "d-none"
                : this.props.diccionario === null
                ? "d-none"
                : this.props.diccionario.length === 0
                ? "d-none"
                : null,
            ].join(" ")}
          >
            <div className="col sin_padding_L">
              <div className={["main_subtitle  nunito"].join(" ")}>
                <span className={"slash"}>/</span>
                <h2 className="">
                  <FormattedMessage
                    id="institutobiva.bivateca.documentos.subtitulo6"
                    defaultMessage=" "
                  />
                </h2>
              </div>
            </div>
            <div className={["col-12 sin_padding_L"]}>
              <SliderDocument
                tipo_imagen={6}
                titulo_slider={
                  this.props.diccionarioIntl !== null
                    ? this.props.diccionarioIntl[
                        "institutobiva.bivateca.documentos.subtitulo6"
                      ]
                    : null
                }
                datos={this.state.diccionarioData}
                tipo={1}
              />
            </div>
          </div>
          <div className="text-center">
            <NavLink
              to={"/instituto_biva/bivateca/documentos?diccionario"}
              className={[
                classes.link,
                "nunito",
                this.isVisibleCheck("diccionario") === false
                  ? "d-none"
                  : this.state.diccionarioData.length > 0
                  ? null
                  : "d-none",
              ].join(" ")}
              onClick={this.props.onClick}
            >
              {label_see_more}
            </NavLink>
          </div>
        </Auxs>
      );
    } else {
      showData = (
        <Bivateca
          location={this.props.location}
          history={this.props.history}
          articulosAcademicosFlag={this.state.articulosAcademicos}
          revistasFlag={this.state.revistas}
          escritosFlag={this.state.escritos}
          blogFlag={this.state.blog}
          librosFlag={this.state.libros}
          diccionarioFlag={this.state.diccionario}
          onBack={this.onBack.bind(this)}
        />
      );
    }
    let noResults = null;

    if (this.state.results === false) {
      noResults = (
        <div className="row">
          <span
            className={[
              classes.message,
              "texto_16_17 montse regular color_negro",
            ].join(" ")}
          >
            {getLan() === "es"
              ? "No existen resultados para la búsqueda."
              : "No results found."}
          </span>
        </div>
      );
    }
    return (
      <IntlProvider
        locale={this.state.lan}
        messages={this.state.messages}
        defaultMessage=" "
      >
        <>
          {loader}
          {showData}
          {noResults}
        </>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInitIntl: (pagina) => {
      dispatch(action.initIntl(pagina));
    },
    onInitVideosInstituto: () => {
      dispatch(action.initVideosInstituto());
    },
    onDocumentos: (keywords) => {
      dispatch(action.getRevistas(keywords));
      dispatch(action.getArticulosAcademicos(keywords));
      dispatch(action.getBlogs(keywords));
      dispatch(action.getEscritos(keywords));
      dispatch(action.getDiccionarios(keywords));
      dispatch(action.getLibros(keywords));
    },
    onArticulosAcademicos: (keywords) => {
      dispatch(action.getArticulosAcademicos(keywords));
    },
    onBlog: (keywords) => {
      dispatch(action.getBlogs(keywords));
    },
    onEscritos: (keywords) => {
      dispatch(action.getEscritos(keywords));
    },
    onDiccionario: (keywords) => {
      dispatch(action.getDiccionarios(keywords));
    },
    onLibros: (keywords) => {
      dispatch(action.getLibros(keywords));
    },
    onRevistas: (keywords) => {
      dispatch(action.getRevistas(keywords));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    videos: state.videos.videos_instituto,
    diccionarioIntl: state.intl.diccionario,
    articulos: state.documentos.articulosAcademicos,
    revistas: state.documentos.revistas,
    escritos: state.documentos.escritos,
    blog: state.documentos.blog,
    libros: state.documentos.libros,
    diccionario: state.documentos.diccionarios,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentos);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../stores/actions/index";
import classes from "./Operaciones.css";
import Sidebar from "../../components/ui/sidebar/Sidebar";
import Breadcrum from "../../components/ui/breadcrum/Breadcrum";
import NotFound from "../../components/notFound/NotFound";

// Pages
import CasasBolsa from "./casasBolsa/CasasBolsa";
import Membresia from "./membresia/Membresia";
import Funciones from "./funciones/Funciones";
import TiposPosturas from "./tiposPosturas/TiposPosturas";
import FormadorMercado from "./formadorMercado/FormadorMercado";
import ModelosNegociacion from "./modelosNegociacion/ModelosNegociacion";
import Bloques from "./bloques/Bloques";
import Cuotas from "./cuotas/Cuotas";
import Calendario from "./calendario/Calendario";
import Fases from "./fases/Fases";
import Manual from "./manual/Manual";
import Conectividad from "./conectividad/Conectividad";
import Proveedores from "./proveedores/Proveedores";
import VigilanciaMercado from "./vigilanciaMercado/VigilanciaMercado";
import Estrategicas from '../alianzas/estrategicas/Estrategicas';
import DocumentMeta from "react-document-meta";
import { getLan } from "../../stores/utils/utilities";

const metaEs = {
  title: "Portal BIVA - Operaciones",
  description:
    "Son intermediarios del mercado de valores que pueden celebrar operaciones de capitales y deuda conforme a lo establecido en el reglamento interior.",
  meta: {
    name: {
      keywords: "casas de bolsa, bolsa, casas, miembros de biva, mercado, mercado de valores, formador de mercado, modelos, negociación, modelos de negociación, operación, bloques, cuotas, fases de mercado, fases, tipos de postura, manual y reglamento, conectividad, proveedores tecnológicos, alianzas, estratégicas, vigilancia de mercados",
    },
  },
};
const metaEn = {
  title: "Portal BIVA - Trading",
  description:
    "Broker dealers are intermediary financial institutions that facilitate the buying and selling of securities in the marketplace, conducting activities in the debt and equity markets pursuant to the provisions set forth in BIVA’s Rules",
  meta: {
    name: {
      keywords: "Broker dealers, market place, Market maker, trading models, block trading, fees, trading manual, internal regulation,connectivity, technology vendors, strategic alliances, market, surveillance ",
    },
  },
};
class Operaciones extends Component {
  state = {
    menu: null,
    id_menu_activo: null
  };

  componentDidMount() {
    const params = {
      showCarousel: false,
      showButtonCarousel: false,
      actualPageName: this.props.location.pathname,
      idPage:"operaciones"
    };

    this.props.onInitGenericos(params);
    this.props.onInitFormadorMercados();
  }

  onSetMenu = (menu, tipo) => {
    if (tipo === 1) {
      this.setState({
        menu: menu
      });
    }else{
      this.setState({
        id_menu_activo: menu
      });
      
    }
  };

  render() {
    const routes = (
      <Switch>
        <Route
          path="/operaciones/"
          exact
          component={CasasBolsa}
        />
        <Route
          path="/operaciones/casas_de_bolsa"
          exact
          component={CasasBolsa}
        />
        <Route
          path="/operaciones/casas_de_bolsa/membresia"
          exact
          component={Membresia}
        />
        <Route
          path="/operaciones/modelos_de_negociacion"
          exact
          component={ModelosNegociacion}
        />
        <Route
          path="/operaciones/modelos_de_negociacion/operacion_de_bloques"
          exact
          component={Bloques}
        />
        <Route
          path="/operaciones/modelos_de_negociacion/cuotas"
          exact
          component={Cuotas}
        />
        <Route
          path="/operaciones/modelos_de_negociacion/calendario_y_horarios"
          exact
          component={Calendario}
        />
        <Route
          path="/operaciones/modelos_de_negociacion/fases_del_mercado"
          exact
          component={Fases}
        />
        <Route
          path="/operaciones/modelos_de_negociacion/manual_y_reglamento"
          exact
          component={Manual}
        />
        <Route
          path="/operaciones/conectividad"
          exact
          component={Conectividad}
        />
        <Route
          path="/operaciones/vigilancia_de_mercados/funciones"
          exact
          component={Funciones}
        />
        <Route
          path="/operaciones/modelos_de_negociacion/tipos_de_postura"
          exact
          component={TiposPosturas}
        />
        <Route
          path="/operaciones/proveedores_tecnologicos"
          exact
          component={Proveedores}
        />
        <Route 
          path="/operaciones/proveedores_tecnologicos/acuerdos_alianzas"
          exact
          component={Estrategicas}/>
        <Route
          path="/operaciones/casas_de_bolsa/formador_de_mercado"
          exact
          component={FormadorMercado}
        />
        <Route
          path="/operaciones/vigilancia_de_mercados"
          exact
          component={VigilanciaMercado}
        />
        <Route component={NotFound} />
      </Switch>
    );

    return (
      <DocumentMeta {...(getLan() === "es" ? metaEs : metaEn)}>
      <div className="container">
        <div className="row">
          <div className={["d-none"].join(" ")}>
            <Sidebar classIcon={["fas fa-gavel"].join(" ")} setMenu={(menu,tipo) => this.onSetMenu(menu,tipo)}/>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 margen_top_30">
            <div className="row margin_bottom_30">
              <div className="col-12 sin_padding_L">
                <div className={classes.breadcums}>
                  <p>
                    <Breadcrum   idPage={this.state.id_menu_activo} menu={this.state.menu}/>
                  </p>
                </div>
              </div>
            </div>
            {routes}
          </div>
        </div>
      </div>
      </DocumentMeta>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitGenericos: params => {
      dispatch(action.initGenericos(params));
    },
    onInitFormadorMercados: () => {
      action.initFormadorMercado();
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Operaciones);

import React, { Component } from "react";
import Auxs from "../../../hoc/auxs/Auxs";
import classes from "./Cabecera.css";
import { getLocal, getUrlDocument } from "../../../stores/utils/utilities";
import { IntlProvider, defineMessages, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as action from "../../../stores/actions/index";
import img_cencor from "../../../assets/images/logoCencor.png"

class Cabecera extends Component {
  state = {
    messages: null,
    lan: getLocal(),
    avisoLegal: null,
    privacidad: null,
    enlace: null
  };

  componentDidMount() {
    this.props.onInitIntl();
  }

  componentDidUpdate(prevProps) {
    if (this.props.diccionarioHome !== prevProps.diccionarioHome) {
      //se obtiene el diccionario del endpoint de traducciones
      if (this.props.diccionarioHome) {
        const messages = defineMessages(this.props.diccionarioHome);
        this.setState({
          messages: messages
        });
      }
    }
    if (this.props.avisoLegal !== prevProps.avisoLegal) {
      this.setState({
        avisoLegal: this.props.avisoLegal
      });
    }
    if (this.props.privacidad !== prevProps.privacidad) {
      this.setState({
        privacidad: this.props.privacidad
      });
    }
    if (this.props.enlace !== prevProps.enlace) {
      this.setState({
        enlace: this.props.enlace
      });
    }
  }

  render() {
    let privacidad = null;
    let rutaPrivacidad = null;
    if (this.state.privacidad !== null) {
      privacidad = this.state.privacidad[0].title;
      rutaPrivacidad = this.state.privacidad[0].url;
      rutaPrivacidad = getUrlDocument(rutaPrivacidad)
    }
    let avisoLegal = null;
    let rutaAvisoLegal = null;
    if (this.state.avisoLegal !== null) {
      avisoLegal = this.state.avisoLegal[0].title;

      rutaAvisoLegal = this.state.avisoLegal[0].url;
      rutaAvisoLegal = getUrlDocument(rutaAvisoLegal);
    }
    let rutaEnlace = null;
    let rutaEnlaceImagen = null;
    if (this.state.enlace !== null) {
      rutaEnlace = this.state.enlace[0].url;
      
      rutaEnlace = getUrlDocument(rutaEnlace);

      rutaEnlaceImagen = this.state.enlace[0].image;
      rutaEnlaceImagen = getUrlDocument(rutaEnlaceImagen);
    }
    return (
      <IntlProvider locale={this.state.lan} messages={this.state.messages} defaultMessage=" ">
        <Auxs>
          <div className={["row"].join(" ")}>
            <div className={["sin_padding col-sm-12 col-md-5 col-lg-3 col-xl-3", classes.margen_footer_cabecera].join(" ")}>
              <div className={classes.avisoPolitica}>
                <div
                  className={["sin_padding col-sm-12 col-md-12 col-lg-12 col-xl-12 sin_padding_L", classes.sin_padding].join(
                    " "
                  )}
                >
                  <a href={rutaAvisoLegal} target="_blank" rel="noopener noreferrer">
                    <span className={["color_blanco nunito texto_16", classes.link].join(" ")}>{avisoLegal}</span>
                  </a>
                </div>
                <div className={"sin_padding col-sm-12 col-md-12 col-lg-12 col-xl-12 sin_padding_L"}>
                  <a href={rutaPrivacidad} target="_blank" rel="noopener noreferrer">
                    <span className={["color_blanco nunito texto_16", classes.link].join(" ")}>{privacidad}</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className={[
                classes.sin_padding,
                "sin_padding col-sm-12 col-md-7 col-lg-6 col-xl-6",
                classes.margen_footer_cabecera
              ].join(" ")}
            >
              <div className={["row"].join(" ")}>
                <div className={["sin_padding col-sm-12 col-md-12 col-lg-12  text-center"].join(" ")}>
                  <span className={[classes.link_blanco2, "color_blanco nunito texto_16", classes.centrado].join(" ")}>
                    ©{new Date().getFullYear()} - Bolsa Institucional de Valores S.A. de C.V.
                  </span>
                </div>
                <div className={["sin_padding col-sm-12 col-md-12 col-lg-12  text-center"].join(" ")}>
                  <span className={[classes.link_blanco2, "color_blanco nunito texto_16", classes.centrado].join(" ")}>
                    <FormattedMessage id="home.derechos" defaultMessage=" " />
                  </span>
                </div>
              </div>
            </div>
            <div className={"sin_padding col-sm-12 col-md-12 col-lg-3 col-xl-3  center-block"}>
              <div className={classes.derecha}>
                {/* <div>
                      <span className={classes.letras_cencor}>una empresa</span>
                    </div> */}
                <a href={rutaEnlace} target="_blank" rel="noopener noreferrer">
                  <img src={img_cencor} alt="Cencor" className={[classes.img_cencor].join(" ")} />
                </a>
              </div>
            </div>
          </div>
        </Auxs>
      </IntlProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitIntl: () => {
      dispatch(action.initIntlHome(19));
      dispatch(action.initFooterAvisoLegal());
      dispatch(action.initFooterPrivacidad());
      dispatch(action.initFooterEnlace());
    }
  };
};

const mapStateToProps = state => {
  return {
    diccionarioHome: state.intl.diccionarioHome,
    avisoLegal: state.menu.avisoLegal,
    enlace: state.menu.enlace,
    privacidad: state.menu.privacidad
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cabecera);

import { updateObject } from "../utils/utilities";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  solicitudes: null,
  prospectos: null,
  preliminares: null,
  cotizaciones: null,
  sic: null,
  avisosOfertasPublicas: null,
  emisoras: null,
  emisiones: null,
  bancoInformacion: null,
  avisosSuspencion: null,
  emisionesAsg: null,
};

const setSearch = (state, action) => {
  return updateObject(state, action);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_SOLICITUDES:
      return setSearch(state, action);
    case actionTypes.FETCH_SEARCH_PROSPECTOS:
      return setSearch(state, action);
    case actionTypes.FETCH_PRELIMINARES_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_SIC_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_COTIZACIONESEMISORAS_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_AVISOSOFERTASPUBLICAS_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_EMISORAS_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_EMISIONES_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_BANCOINFORMACION_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_AVISOSSUSPENCION_SEARCH:
      return setSearch(state, action);
    case actionTypes.FETCH_EMISIONES_ASG_SEARCH:
      return setSearch(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect } from "react";
import {
  getLan,
  setFormatCurrencyStringOrZero,
} from "../../../../stores/utils/utilities";

import classes from "./GaleriaCursos.css";

const GaleriaCursos = (props) => {
  const data = props.data ? props.data : [];
  const [temario, setTemario] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
     removeProductFromCart(data);
    }
  }, [data]);

  const addCart = (e, item) => {
    e.preventDefault();
    let products = JSON.parse(localStorage.getItem("shoppingCart"));
    if (products == null) products = [];
    const existProduct = products.find((product) => product.id === item.id);
    let productsFilters = products.filter((p) => p.id !== item.id);
    if (existProduct) {
      if (+existProduct.vacantes > 1) {
        existProduct.qty = existProduct.qty
          ? existProduct.qty >= existProduct.vacantes
            ? existProduct.vacantes
            : existProduct.qty + 1
          : 1;
        productsFilters.push(existProduct);
      }
    } else {
      if (item.vacantes > 0) {
        item.qty = 1;
        productsFilters.push(item);
      }
    }

    localStorage.setItem("shoppingCart", JSON.stringify(productsFilters));
  };
  const selectTemario = (e, temario) => {
    e.preventDefault();
    setTemario(temario);
  };

  const removeProductFromCart = (products) => {
    let cart = JSON.parse(localStorage.getItem("shoppingCart"));

    let newCart = [];
    if (cart === null || cart === undefined) {
      return null;
    }
    cart.forEach(product => {
      const existProduct = products.find((itemCart) => itemCart.id === product.id);
      if (existProduct != null) {
          product.qty = +product.qty > existProduct.vacantes ? existProduct.vacantes : product.qty;
          product.vacantes = existProduct.vacantes;
          if (+product.vacantes > 0) {
            newCart.push(product);
          }
      }
      
    });
    localStorage.setItem("shoppingCart", JSON.stringify(newCart));

  }

  const recuadros = data.map((itm) => {
    const producto = (
      <div
        className={[
          (props.type === 2 ?  "col-12 col-md-6 col-lg-4" : 
          "col-12 col-md-6 col-lg-4 col-xl-3"),
          classes.margin_curso,
          (props.type === 2 ?  classes.type2 : 
          null),
        ].join(" ")}
        key={itm.id}
      >
        <div className={classes.productoContainer}>
          <div
            className={[classes.ribbon, itm.nuevo ? null : "d-none"].join(" ")}
          >
            <span className={"nunito texto_10 bold"}>
              {getLan() === "es" ? "Nuevo" : "New"}
            </span>
          </div>

          <div className={classes.imagenContainer}>
            <img
              src={itm.imagen}
              alt={itm.titulo}
              className={["width_cien"].join(" ")}
            />
          </div>
          <div className={classes.detailContainer}>
            <div className={[classes.title, "text-center"].join(" ")}>
              <div
                className="nunito texto_14 bold text-center texto_azul"
                dangerouslySetInnerHTML={{ __html: itm.sede }}
              ></div>
            </div>
            <div className={[classes.title, "text-center"].join(" ")}>
              <div
                className="nunito texto_14 bold text-center"
                dangerouslySetInnerHTML={{ __html: itm.titulo }}
              ></div>
            </div>
            <div className={classes.detail}>
              <div
                className="montse texto_14 regular"
                dangerouslySetInnerHTML={{ __html: itm.detalle }}
              ></div>
            </div>
            <div
              className={["row margin_top_10", classes.containerFooter].join(
                " "
              )}
            >
              <div className={[classes.footer, "row"].join(" ")}>
                <div className="col-12 sin_padding_L">
                  <span
                    className={[
                      "nunito texto_14 color_azul bold",
                      classes.pointer,
                      itm.vacantes && +itm.vacantes > 0 ? null : "texto_rojo"].join(" ")}
                  >
                    {(getLan() === "es" ? 
                    (itm.vacantes && +itm.vacantes > 0 ? "Disponible: " :  "") 
                    : (itm.vacantes && +itm.vacantes > 0 ? "Available: " :  ""))}
                    </span>
                </div>
                <div className="col-5 sin_padding_L">
                  <span
                    className={[
                      "nunito texto_14 color_azul bold",
                      classes.pointer,
                      itm.temario ? null : "d-none",
                    ].join(" ")}
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={(e) => selectTemario(e, itm.temario)}
                  >
                    {getLan() === "es" ? "Ver temario" : "Overview"}
                  </span>
                </div>
                <div className="col-7">
                  <div className="float-left">
                    <span
                      className={[
                        "nunito texto_14  bold",
                        itm.precio && itm.precio > 0
                          ? "color_azul"
                          : "color_verde",
                        classes.priceItm,
                      ].join(" ")}
                    >
                      {itm.precio && itm.precio > 0
                        ? setFormatCurrencyStringOrZero(itm.precio)
                        : ""}
                    </span>
                  </div>
                  <div
                    className={[classes.cart, classes.pointer, itm.vacantes && +itm.vacantes > 0 ? null : classes.disabled].join(" ")}
                    onClick={(e) => itm.vacantes && +itm.vacantes > 0 ? addCart(e, itm) : null}
                  >
                    <i className="fas fa-cart-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return producto;
  });
  return (
    <>
      <div className="row">{recuadros}</div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title montse" id="exampleModalLabel">
                Temario
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={temario} alt="Temario" className="width_cien" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GaleriaCursos;

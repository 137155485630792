import React, { Component } from "react";
import classes from "./NotFound.css";
import biva_logo from '../../assets/images/logobiva.svg';
import {getLan} from "../../stores/utils/utilities"

class NotFound extends Component {

  state = {
    intervalId: null
  };

  constructor() {
    super();
    this.timer = this.timer.bind(this);
  }
  componentDidMount() {
    var intervalId = setInterval(this.timer, 15000);
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }
  timer() {
    this.props.history.push('/')
  }

  render(){
  return (
    <div className={[classes.fondo, classes.vertical_div].join(" ")}>
      <div className={[classes.centrado, classes.vertical].join(" ")}>
        <h1 className={[classes.numeros, "nunito bold"].join(" ")}>404</h1>
        <h2 className={"montse bold"}>{getLan() === "es" ? "Esta página no está disponible" : "This page isn't available"}</h2>
        <h3 className={"montse semi_bold"}>{getLan() === "es" ? "Es posible que el enlace que has seguido esté roto o que se haya eliminado la página." : "The link you followed may be broken, or the page may have been removed."}</h3>
        <div className={[classes.centrado_img, "text-center"].join(" ")}>
        <img src={biva_logo} alt="logo" className={classes.img}/>
        </div>

      </div>
    </div>
  );}
}

export default NotFound;
